import { getConfigModules } from 'src/siteConfig/utils.js'

const contentRiversPath = `river/contentRivers.js`
const filterLabelsPath = `river/filterLabels.js`
const labelReplacementsPath = `river/labelReplacements.js`
const riverConfigPath = `river/riverConfig.js`
const externalSitesConfigPath = `river/externalSitesConfig.js`

export const contentRiversConfigModule = getConfigModules(contentRiversPath)
export const filterLabelsConfigModule = getConfigModules(filterLabelsPath)
export const labelReplacementsConfigModule = getConfigModules(
  labelReplacementsPath
)
export const riverConfigModule = getConfigModules(riverConfigPath)
export const externalSitesConfigModule = getConfigModules(
  externalSitesConfigPath
)
