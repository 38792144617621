import { reduxForm, isDirty } from 'redux-form'
import { compose } from 'redux'
import { connect } from 'react-redux'
import withCatalogs from 'src/modules/Catalogs/withCatalogs'
import MyAccountForm from '../components/MyAccountForm'
import validate from '../components/MyAccountForm/validate'
import {
  licenseTypes,
  audienceTypes
} from 'src/services/FW5ML/parsers/UserParser.js'
import { actions } from '../reducer.js'
import * as flags from 'src/modules/AsyncFlags/selectors'
import dayjs from 'dayjs'

const mapStateToProps = state => {
  const expirationDate = state.session.user.expiration
    ? dayjs(state.session.user.expiration).format('MMMM DD, YYYY')
    : null
  return {
    initialValues: {
      salutation: state.session.user.salutation,
      email: state.session.user.email,
      first_name: state.session.user.firstName,
      last_name: state.session.user.lastName,
      company: state.session.user.company,
      phone_number: state.session.user.phone_number,
      job_title: state.session.user.job_title,
      country: state.session.user.address.country,
      job_function: state.session.user.job_function,
      subscription_type:
        state.session.user.license === licenseTypes.LICENSE_PLUS
          ? audienceTypes.PLUS
          : audienceTypes.FREE,
      subscription_expiry: expirationDate
    },
    error: flags.getError('ACCOUNT_UPDATE')(state),
    busy: flags.isBusy('ACCOUNT_UPDATE')(state),
    isDirty: isDirty('updateAccount')(state),
    settings: state.mySettings.settings,
    settingsBusy:
      flags.isBusy('ACCOUNT_SETTINGS_LOAD')(state) ||
      flags.isBusy('ACCOUNT_SETTINGS_UPDATE')(state)
  }
}

const mapDispatchToProps = dispatch => ({
  onSubmit: account => dispatch(actions.requestAccountUpdate(account))
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withCatalogs({
    jobFunctions: 'jobFunction',
    country: 'country'
  }),
  reduxForm({
    form: 'updateAccount',
    validate,
    touchOnBlur: false,
    destroyOnUnmount: false,
    enableReinitialize: false
  })
)(MyAccountForm)
