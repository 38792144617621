import { compose } from 'redux'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import withCatalogs from 'src/modules/Catalogs/withCatalogs'
import RequestAccessForm from 'src/components/RequestAccessForm'
import { actions as registerActions } from 'src/modules/Register/reducer'
import validate from 'src/modules/Register/components/RegisterForm/validate'
import * as flags from 'src/modules/AsyncFlags/selectors'
import { audienceTypes } from 'src/services/FW5ML/parsers/UserParser'
import { actions as ContactActions } from 'src/modules/ContactUs/reducer'

const mapStateToProps = state => {
  const user = state?.session?.user || {}

  return {
    initialValues: {
      first_name: user?.firstName,
      email: null,
      last_name: user?.lastName,
      company: user?.company,
      country: user?.address?.country,
      job_title: user?.job_title,
      job_function: user?.job_function,
      license: audienceTypes.FREE,
      register_pharma: false,
      register_healthtech: false,
      register_edge: false,
      register_reports: false
    },
    error: flags.getError('USER_REGISTRATION')(state),
    busy: flags.isBusy('USER_REGISTRATION')(state)
  }
}

const mapDispatchToProps = dispatch => ({
  requestUserRegistration: data =>
    dispatch(registerActions.requestUserRegistration(data)),
  requestConsultantContact: data =>
    dispatch(
      ContactActions.requestConsultantContact({
        ...data,
        hideNotifications: true
      })
    )
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'request_access',
    validate,
    touchOnBlur: false
  }),
  withCatalogs({
    jobFunctions: 'jobFunction',
    therapy: 'therapy',
    country: 'country',
    heardAbout: 'heardAboutOptions'
  })
)(RequestAccessForm)
