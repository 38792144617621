import React from 'react'
import DealsFlowComponent from './components/DealsDBComponent'

import { deal } from './s3/biometrics/data'
import {
  columns,
  columnsMap,
  mobileColumns,
  columnsMobileMap
} from './s3/biometrics/settings'

const Deals = () => (
  <DealsFlowComponent
    deal={deal}
    text={
      'FirstWord Biosimilar Index is a comprehensive drug intelligence database that provides a complete, up-to-date and highly detailed insight into the status of over 600 biosimilar and non-comparable biologic (NCB) drugs in development from over 160 companies worldwide. Stay up-to-date with key changes to trials and quickly sort and filter through data for a clear window on the world of biosimilar drugs and their developers.'
    }
    columns={columns}
    columnsMap={columnsMap}
    mobileColumns={mobileColumns}
    columnsMobileMap={columnsMobileMap}
  />
)

export default Deals
