import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import { notificationsEnqueue } from 'src/modules/Notifications/reducer'
import { actions as historyActions } from './HistoryReducer'
import { actions as chatActions } from 'src/modules/DougallChat/ChatReducer'
import { getRawHistory } from './HistorySelector'
import { Fragment } from 'react'
import RoutedLink from 'src/components/RoutedLink'
import { piwikSiteId } from 'src/constants'

const fragmentedNotification = (message, type, duration) => {
  return {
    message: (
      <Fragment>
        {message || 'Something went wrong!'} <RoutedLink label="Contact us" />
      </Fragment>
    ),
    duration
  }
}

/**
 * Request history
 * @param services
 * @param payload
 */
function* historyRequest(services) {
  const DougallService = services('DougallService')
  const SessionService = services('SessionService')

  try {
    // Get device
    // Call endpoint
    const deviceId = yield call(
      [SessionService, 'getCookie'],
      'V1_PSL_DEVICE_ID'
    )

    if (!deviceId) return
    const { history, raw: rawHistory } = yield call(
      [DougallService, 'historyRequest'],
      `${deviceId}`
    )
    yield put(historyActions.requestHistorySuccess({ history, rawHistory }))
  } catch (e) {
    console.error(e)
    yield put(historyActions.requestHistoryFail())
  }
}

/**
 * Request prompt history
 * @param services
 * @param payload
 */
function* promptHistoryRequest(services, { payload }) {
  const DougallService = services('DougallService')
  const SessionService = services('SessionService')
  const deviceId = yield call([SessionService, 'getCookie'], 'V1_PSL_DEVICE_ID')

  const { promptId = undefined, pId = undefined } = payload
  try {
    yield put(chatActions.resetMessages())
    yield put(chatActions.resetLinkedPromptId())
    yield put(chatActions.promptRequestStart())

    // Call endpoint
    const { messages, tags, lastAnswerTags } = yield call(
      [DougallService, 'promptHistoryRequest'],
      deviceId,
      promptId,
      pId,
      pId ? true : undefined
    )
    yield put(chatActions.setLinkedPromptId(promptId))
    yield put(chatActions.addMessages(messages))
    yield call([SessionService, 'saveToCache'], 'tags', lastAnswerTags)
    yield put(chatActions.addTags({ tags }))

    yield put(chatActions.promptSuccess())
    yield put(chatActions.showInterests())
  } catch (e) {
    // Do not display error notification message for sharedPrompt flow
    if (!payload.pId || [400, 500].includes(e.getCode())) {
      yield put(
        notificationsEnqueue(
          fragmentedNotification(
            'There was a problem with the request.',
            'error'
          )
        )
      )
    }
    yield put(chatActions.promptFail())
  } finally {
    yield put(chatActions.setUrlRequested(true))
  }
}

/**
 * Request history
 * @param services
 * @param payload
 */
function* deleteHistoryPromptRequest(services, { payload }) {
  const DougallService = services('DougallService')
  const SessionService = services('SessionService')
  const DougallParser = services('DougallParser')
  const { id = undefined } = payload
  try {
    // Get device, we can assume the deviceId is ready for anon users since the history will load only when device id is ready
    const deviceId = yield call(
      [SessionService, 'getCookie'],
      'V1_PSL_DEVICE_ID'
    )

    const source = parseInt(piwikSiteId)

    // Call endpoint
    yield call([DougallService, 'deleteHistoryPrompt'], id, deviceId, source)

    yield put(historyActions.deleteHistorySuccess())
    yield put(historyActions.setClearAllOpen({ open: false }))
    // Update History list without
    let newHistory = [],
      rawHistory = []
    // If single id, remove entry from history list
    if (id) {
      rawHistory = yield select(getRawHistory())
      newHistory = rawHistory.filter(h => h.applicationPromptId !== id)
    }
    const parsedHistory = yield call([DougallParser, 'historyParser'], {
      history: newHistory
    })
    // Update history state
    yield put(
      historyActions.requestHistorySuccess({
        history: parsedHistory,
        rawHistory: newHistory
      })
    )
  } catch (e) {
    yield put(historyActions.deleteHistoryFail())
    yield put(notificationsEnqueue(fragmentedNotification()))
  }
}

/**
 * Request History Title Change
 * @param {*} services
 * @param {*} payload
 */
function* changeTitleRequest(services, { payload }) {
  const DougallService = services('DougallService')
  const SessionService = services('SessionService')
  const { id, title } = payload

  try {
    const deviceId = yield call(
      [SessionService, 'getCookie'],
      'V1_PSL_DEVICE_ID'
    )
    const source = parseInt(piwikSiteId)

    yield call([DougallService, 'titleChangeRequest'], {
      deviceId,
      applicationLinkedPromptId: id,
      title,
      source
    })
  } catch (e) {
    console.error(e)
  }
}

export default function* watchHistoryRequest(services) {
  yield all([takeEvery('HISTORY_REQUEST', historyRequest, services)])
  yield all([takeEvery('HISTORY_DELETE', deleteHistoryPromptRequest, services)])
  yield all([
    takeEvery('PROMPT_HISTORY_REQUEST', promptHistoryRequest, services)
  ])
  yield all([
    takeEvery('HISTORY_REQUEST_TITLE_CHANGE', changeTitleRequest, services)
  ])
}
