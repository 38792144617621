import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import MediaEventExcerpt from 'src/components/MediaEventExcerpt/index.js'
import Typography from 'src/components/ui/Typography/index.js'
import styles from './MediaEventWrapper.module.sass'
import useWindowWidth from 'src/utils/useWindowWidth.js'
import _ from 'lodash'
import dayjs from 'dayjs'
import Ad from 'src/modules/Ads/components/Ad'
import ShowForMediaSize from 'src/components/Responsive/ShowForMediaSize.js'

const MediaEventWrapper = ({ events, config, ads = {} }) => {
  const { groupByDates = false } = config
  const width = useWindowWidth()
  const isMobile = width <= 600
  if (groupByDates) {
    const now = dayjs()
    events.sort((a, b) => {
      a = new Date(parseInt(a.mediaEvent.event_start_timestamp)).getTime()
      b = new Date(parseInt(b.mediaEvent.event_start_timestamp)).getTime()
      return b < a ? 1 : -1
    })
    let [pastEvents, activeEvents] = _.partition(events, function (element) {
      return dayjs.unix(element.mediaEvent.event_start_timestamp).isBefore(now)
    })
    pastEvents = pastEvents.reverse()
    activeEvents = _.orderBy(
      activeEvents,
      ['mediaEvent.featured_content'],
      ['desc']
    )
    return (
      <Fragment>
        {activeEvents.length ? (
          <div>
            {activeEvents.map(event => (
              <MediaEventExcerpt
                key={event.id}
                event={event}
                isActive
                isMobile={isMobile}
              />
            ))}
          </div>
        ) : (
          <div className={styles.emptyContent}>
            <Typography type="subheading">No upcoming events.</Typography>
          </div>
        )}
        <ShowForMediaSize showFor={['large', 'medium']}>
          <Ad className={styles.houseBannerAd} {...ads.eventsBillboardAd} />
        </ShowForMediaSize>
        <ShowForMediaSize showFor={['small']}>
          <Ad
            className={styles.houseBannerAd}
            {...ads.eventsBillboardMobileAd}
          />
        </ShowForMediaSize>
        <Typography className={styles.headline} type="headline">
          PAST EVENTS
        </Typography>
        {pastEvents.length ? (
          <div>
            {pastEvents.map(event => (
              <MediaEventExcerpt
                key={event.id}
                event={event}
                isMobile={isMobile}
              />
            ))}
          </div>
        ) : (
          <div className={styles.emptyContent}>
            <Typography type="subheading">No past events.</Typography>
          </div>
        )}
      </Fragment>
    )
  } else {
    return events.map(event => (
      <MediaEventExcerpt key={event.id} event={event} isMobile={isMobile} />
    ))
  }
}

MediaEventWrapper.propTypes = {
  events: PropTypes.array,
  config: PropTypes.object,
  ads: PropTypes.object
}

export default MediaEventWrapper
