export const columnsMap = {
  'Date Announced': true,
  'Deal type': true,
  'Acquiring Company/Partner': true,
  'Target Company/Partner': true,
  'Total Deal Value ($m)': true,
  'Drug Modality/Technology': true,
  'Lead Asset Stage/Phase': true
}

export const columnsMobileMap = {
  'Date Announced': true,
  'Acquiring Company/Partner': true
}

export const columns = [
  {
    Header: 'Date Announced',
    style: { fontSize: '12px' },
    accessor: 'Date Announced',
    headerClassName: 'input-deals-db',
    sortType: 'date',
    filterMap: 'date'
  },
  {
    Header: 'Deal type',
    style: { fontSize: '12px' },
    accessor: 'Deal type',
    headerClassName: 'select-deals-db',
    sortType: 'equals',
    filterMap: 'equals'
  },
  {
    Header: 'Acquiring Company/Partner',
    style: { fontSize: '12px' },
    accessor: 'Acquiring Company/Partner',
    headerClassName: 'input-deals-db',
    sortType: 'text',
    filterMap: 'text'
  },
  {
    Header: 'Target Company/Partner',
    style: { fontSize: '12px' },
    accessor: 'Target Company/Partner',
    headerClassName: 'input-deals-db',
    sortType: 'text',
    filterMap: 'text'
  },
  {
    Header: 'Total Deal Value ($m)',
    style: { fontSize: '12px' },
    accessor: 'Total Deal Value ($m)',
    headerClassName: 'input-deals-db',
    sortType: 'number',
    filterMap: 'number'
  },
  {
    Header: 'Drug Modality/Technology',
    style: { fontSize: '12px' },
    accessor: 'Drug Modality/Technology',
    headerClassName: 'select-deals-db',
    sortType: 'equals',
    filterMap: 'equals'
  },
  {
    Header: 'Lead Asset Stage/Phase',
    style: { fontSize: '12px' },
    accessor: 'Lead Asset Stage/Phase',
    headerClassName: 'select-deals-db',
    sortType: 'equals',
    filterMap: 'equals'
  }
]

export const mobileColumns = [
  {
    Header: 'Date Announced',
    style: { fontSize: '12px' },
    accessor: 'Date Announced',
    headerClassName: 'input-deals-db',
    sortType: 'date',
    filterMap: 'date'
  },
  {
    Header: 'Acquiring Company/Partner',
    style: { fontSize: '12px' },
    accessor: 'Acquiring Company/Partner',
    headerClassName: 'input-deals-db',
    sortType: 'text',
    filterMap: 'text'
  }
]
