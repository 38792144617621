import axios from 'axios'

class FW5ML {
  constructor(SessionInterceptors, FW5MLInterceptors, config) {
    this.interceptors = {
      ...SessionInterceptors,
      ...FW5MLInterceptors
    }
    this.config = config
  }

  createClient() {
    const instance = axios.create({
      baseURL: this.config.ML_URL
    })
    instance.interceptors.request.use(this.interceptors.setToken)
    instance.interceptors.request.use(this.interceptors.invalidateCache)
    instance.interceptors.response.use(
      null,
      this.interceptors.catchNetworkErrors
    )
    instance.interceptors.response.use(
      null,
      this.interceptors.killSessionIfTokenExpired
    )
    instance.interceptors.response.use(
      null,
      this.interceptors.retryIfUnavailable
    )
    instance.interceptors.response.use(
      this.interceptors.detectMLfailuresOnSuccess,
      this.interceptors.detectMLfailuresOnFail
    )
    return instance
  }

  async fetch(endpoint, method = 'GET', data = {}, extra = {}) {
    const { headers = {} } = extra
    const conf = {
      url: endpoint,
      method,
      data,
      ...extra,
      headers
    }
    const client = this.createClient()
    return client.request(conf)
  }
}

export default FW5ML
