import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Typography from 'src/components/ui/Typography/index.js'
import Newsletter from 'src/modules/MyNewsletters/containers/NewsletterContainer'
import styles from '../BrowseNewsletters/BrowseNewsletters.module.sass'

export const NewsletterSection = ({
  title,
  newslettersSection = [],
  pendingForInsertion,
  pendingForDeletion,
  subscribed,
  hndNewsletterButtonClicked,
  showThumbnail = true,
  topItem = false,
  style = ''
}) => {
  return (
    <Fragment>
      {title && (
        <Typography className={styles.nlsTitle} type="subheading">
          {title}
        </Typography>
      )}
      <div className={styles[style]}>
        {newslettersSection?.map((newsletter, index) => {
          const busy =
            pendingForInsertion.includes(newsletter.id) ||
            pendingForDeletion.includes(newsletter.id)
          const isSubscribed = subscribed.reduce(
            (prev, nl) => nl.id === newsletter.id || prev,
            false
          )
          return (
            <Newsletter
              topItem={topItem}
              key={`${index + 1}`}
              showThumbnail={showThumbnail}
              newsletter={newsletter}
              subscribed={isSubscribed}
              busy={busy}
              onButtonClick={hndNewsletterButtonClicked(
                newsletter,
                isSubscribed
              )}
            />
          )
        })}
      </div>
    </Fragment>
  )
}

NewsletterSection.propTypes = {
  title: PropTypes.string,
  newslettersSection: PropTypes.array,
  pendingForInsertion: PropTypes.array,
  pendingForDeletion: PropTypes.array,
  subscribed: PropTypes.array,
  hndNewsletterButtonClicked: PropTypes.func,
  showThumbnail: PropTypes.bool,
  topItem: PropTypes.bool,
  style: PropTypes.string
}
