import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import FullImage from 'src/components/FullImage/index.js'
import styles from '../Ad/Ad.module.sass'

const AdHardcoded = ({
  width = '300px',
  height = '250px',
  src = 'https://placehold.it/300x250?text=aiMatch',
  link = '/',
  ready = true,
  className
}) => (
  <div className={cx(styles.ad, className)}>
    {ready ? (
      <a href={link}>
        <FullImage src={src} width={width} height={height} />
      </a>
    ) : (
      <div style={{ width, height, background: '#ccc' }}></div>
    )}
  </div>
)

AdHardcoded.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  src: PropTypes.string,
  link: PropTypes.string,
  ready: PropTypes.bool,
  className: PropTypes.string
}
export default AdHardcoded
