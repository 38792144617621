import React from 'react'
import Page from 'src/components/Page/index.js'
import SectionHeading from 'src/components/SectionHeading/index.js'
import styles from 'src/modules/Static/Static.module.sass'
import Typography from 'src/components/ui/Typography/index.js'
import Metadata from 'src/components/Metadata/index.js'

const FirstAnswersPage = () => {
  return (
    <Page className={styles.static}>
      <Metadata
        title="FirstAnswers"
        description="FirstAnswers is powered by the LiMA Bureau of Advisors.  LiMA Bureau Advisors are recognised medical thought-leaders from over 30 countries and 40 specialties."
      />
      <SectionHeading title="FirstAnswers" gutterBottom paragraph />
      <Typography type="body1" className={styles.paragraph} paragraph>
        FirstAnswers is powered by the LiMA Bureau of Advisors. LiMA Bureau
        Advisors are recognised medical thought-leaders from over 30 countries
        and 40 specialties. Every LiMA Advisor is a cited author, a recognised
        CME/IME course director, a principal investigator and/or a recent
        speaker at one or more major national or international conferences. With
        over a thousand Bureau members, no matter what medical communications,
        education or marketing challenge you face, there's a LiMA Bureau Advisor
        who can assist or advise you.
      </Typography>
      <Typography type="body1" className={styles.paragraph} paragraph>
        FirstAnswers sessions are included in some FirstWord Pharma+
        subscription licences.{' '}
        <a
          className={styles.link}
          href="mailto:firstanswers@firstwordpharma.com?subject=FirstAnswers%20Inquiry"
        >
          Contact us
        </a>{' '}
        to learn more about FirstAnswers and how you can benefit.
      </Typography>
    </Page>
  )
}

export default FirstAnswersPage
