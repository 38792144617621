import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import StorySpinner from 'src/components/StorySpinner/index.js'
import SponsoredHeadline from 'src/components/SponsoredHeadline/index.js'
import StoryExcerpt from 'src/components/StoryExcerpt/index.js'
import InfiniteLoader from 'src/components/InfiniteLoader/index.js'
import RiverLoader from 'src/components/RiverLoader/index.js'
import Ad from 'src/modules/Ads/components/Ad'
import styles from './RiverBody.module.sass'
import cx from 'classnames'
import commonStyles from 'src/styles/common.module.sass'
import useWindowWidth from 'src/utils/useWindowWidth.js'
import { siteName } from 'src/utils/oneCodeBase.js'

const RiverBody = props => {
  const width = useWindowWidth()

  const {
    loading,
    results,
    sponsoredHeadlines,
    loadMore,
    infiniteScrollerEnabled,
    busyLoadMore,
    pageStart,
    isRiverPage,
    riverStateConfig,
    ads,
    children
  } = props

  if (loading) {
    return <StorySpinner />
  }

  if (results) {
    const headline = i => (
      <Fragment key={`sponsored-${i}`}>
        {sponsoredHeadlines[i] && (
          <SponsoredHeadline
            to={sponsoredHeadlines[i].cta}
            title={`Sponsored by FirstWord ${siteName}`}
            body={sponsoredHeadlines[i].body}
          />
        )}
      </Fragment>
    )

    // Aimatch ads validation
    const { flashAd, riverLeaderboard2, riverLeaderboard3 } = ads

    const checkEmptyAd = ad =>
      isRiverPage &&
      ad &&
      ad.emptyContent !== undefined &&
      ad.emptyContent === false &&
      !ad.error
    const checkFlashAd = checkEmptyAd(flashAd)
    const checkLeaderboard2 = checkEmptyAd(riverLeaderboard2)
    const checkLeaderboard3 = checkEmptyAd(riverLeaderboard3)

    let firstAd

    if (checkFlashAd) {
      firstAd = flashAd
    } else if (checkLeaderboard2) {
      firstAd = riverLeaderboard2
    } else {
      firstAd = null
    }

    const firstAdHTML =
      !firstAd || !!firstAd.emptyContent ? (
        headline(0)
      ) : (
        <article className={styles.adContainer} key="first_advertorial_ad">
          <Ad
            className={cx(
              styles.adContent,
              firstAd.area === 'fw_flash'
                ? styles.flashAd
                : styles.defaultFlashAd
            )}
            {...firstAd}
          />
          {firstAd.area === 'fw_flash' && (
            <p className={styles.sponsored}>Sponsored</p>
          )}
        </article>
      )

    const secondAdHTML = (
      <div key="second_advertorial_ad">
        {checkLeaderboard3 ? (
          <Ad
            className={cx(
              styles.riverNewsletterAd,
              commonStyles.newsletterSignUpAd
            )}
            {...riverLeaderboard3}
          />
        ) : (
          <>{headline(firstAd ? 0 : 1)}</>
        )}
      </div>
    )

    return (
      <InfiniteLoader
        isLoading={busyLoadMore}
        loadMore={loadMore}
        hasMore={infiniteScrollerEnabled}
        pageStart={pageStart}
        loader={<RiverLoader />}
      >
        {children}
        {results
          .map(result => {
            return (
              <StoryExcerpt
                key={result.id}
                id={result.id}
                to={`/story/${result.id}`}
                type={result.type}
                typeTo={`/river/type/${result.type}`}
                title={result.title}
                published={result.published}
                tags={result.tags}
                congress={result.congress}
                plus={result.flags.isPaid}
                source={result.source}
                image={result.image}
                body={result.teaser}
                origin={result.origin}
                reportSettings={result?.reportSettings}
                likes={result.likes}
                likedByProfile={result.likedByProfile}
                {...riverStateConfig}
              />
            )
          })
          .reduce((acc, cur, index) => {
            // Inject sponsored headline after the 5th article (first ad)
            if (index === 4) {
              return [...acc, cur, firstAdHTML]
            }

            // Inject sponsored headline after the 15th article (second ad)
            if (index === 14) {
              return [...acc, cur, secondAdHTML]
            }

            // Inject sponsored headline after each 10 articles starting on 25
            if ((index - 4) % 10 === 0) {
              let prevAdInstance

              if (firstAd !== null) {
                if (checkLeaderboard3 && width <= 550) {
                  prevAdInstance = 2
                } else {
                  prevAdInstance = 1
                }
              } else {
                prevAdInstance = 0
              }

              const instance = Math.floor((index - 4) / 10 - prevAdInstance)
              return [...acc, cur, headline(instance)]
            }
            return [...acc, cur]
          }, [])}
      </InfiniteLoader>
    )
  }

  return null
}

RiverBody.propTypes = {
  loading: PropTypes.bool,
  results: PropTypes.array,
  sponsoredHeadlines: PropTypes.array,
  loadMore: PropTypes.func,
  infiniteScrollerEnabled: PropTypes.bool,
  busyLoadMore: PropTypes.bool,
  pageStart: PropTypes.number,
  isRiverPage: PropTypes.bool,
  riverStateConfig: PropTypes.object,
  ads: PropTypes.object,
  children: PropTypes.node
}

export default RiverBody
