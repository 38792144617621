import React from 'react'
import Page from 'src/components/Page/index.js'
import RegisterEmailSent from 'src/modules/Static/components/RegisterEmailSentPage/components/RegisterEmailSent'
import cx from 'classnames'
import styles from 'src/modules/Static/Static.module.sass'

const EmailSentPage = () => {
  return (
    <Page className={cx(styles.static, styles.centered)}>
      <RegisterEmailSent />
    </Page>
  )
}

export default EmailSentPage
