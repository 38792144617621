import { notificationsEnqueue } from 'src/modules/Notifications/reducer'
import DougallMLError from './DougallMLError'
import { Fragment } from 'react'

const DougallMLInterceptors = dispatch => {
  return {
    catchNetworkErrors: async error => {
      if (error.code === 'ERR_CANCELED') return
      if (!error.response) {
        console.error('The MiddleLayer is unreachable.', error)
        const errorMessage =
          'Something went wrong! Please refresh the page and try again.'
        dispatch(
          notificationsEnqueue({
            message: errorMessage,
            type: 'error'
          })
        )
        throw new Error(errorMessage)
      }
      throw error
    },

    detectMLfailuresOnFail: async error => {
      console.log(error)
      if (error.code === 'ERR_CANCELED') return
      if (error.response) {
        const { data: responseError, status } = error.response
        const err = new DougallMLError(
          responseError?.message || '',
          responseError || {},
          status
        )
        throw err
      }
      throw error
    }
  }
}

export default DougallMLInterceptors
