export * from './apiConstants.js'
export const notificationDuration = 3000
export const noticeContent =
  '### Update\n\nFirstWord is updating its Privacy Policy, as of May 25, 2018, to reflect changes in global data protection and data privacy laws, including the GDPR in the European Union. [Access our policy here&nbsp;>](/privacy)'

export const screenSizesMapping = {
  desktop: ['large'],
  mobile: ['small', 'medium'],
  shared: ['small', 'medium', 'large']
}

export const ANON_MAX_ARTICLES = 5 // Max number of articles an anon user can read without registering
export const ANON_MAX_ARTICLES_EXPIRE_DAYS = 7 // Time span for anon article limit in ms

export const PRESTITITAL_AD_COOKIE_EXPIRE_MINUTES = 5 // Time span to hide prestitial ad

import siteConfig from 'src/site.config.json'
const { sections, configuration } = siteConfig

const basename = process.env.REACT_APP_BASEPATH

/**
 * User Flow
 */

const userFlowToken = process.env.REACT_APP_USER_FLOW_TOKEN

/**
 * Used for unsubscribe feature
 */
const ntkDispatchSiteId = process.env.REACT_APP_IDH_NTK_DISPATCH_SITEID
const ntkTodaySiteId = process.env.REACT_APP_IDH_NTK_TODAY_SITEID
const ntkToday = sections.unsubscribe.nl_sites.ntkToday
const ntkDispatch = sections.unsubscribe.nl_sites.ntkDispatch

/**
 * For PHNX API
 */
const phnxSiteID = process.env.REACT_APP_PHNX_SITEID
const phnxSiteIDv1 = process.env.REACT_APP_PHNX_SITEID_V1
const phnxSiteIDv2 = process.env.REACT_APP_PHNX_SITEID_V2

/**
 * IDH setup
 */
const idhBrandId = process.env.REACT_APP_IDH_BRANDID
const idhCampaignID = process.env.REACT_APP_IDH_CAMPAIGNID
const idhSiteId = process.env.REACT_APP_IDH_SITEID
const brandCode = process.env.REACT_APP_BRAND_CODE

/**
 * Video component
 */
const jwpLicense = process.env.REACT_APP_JWPLAYER_LICENSE_KEY

/**
 * Piwik Constants
 */
const piwikSiteId = process.env.REACT_APP_PIWIK_SITEID
const siteUrl = process.env.REACT_APP_PIWIK_URL
const scriptUrl = process.env.REACT_APP_PIWIK_SCRIPT_URL
const piwikEnabled = process.env.REACT_APP_PIWIK_DEV_ENABLED
const scriptName = process.env.REACT_APP_PIWIK_SCRIPT_NAME
const nodeEnv = process.env.NODE_ENV
const piwikNlAlertsSiteId = process.env.REACT_APP_PIWIK_NL_ALERTS_SITEID
const piwikNlLessonsSiteId = process.env.REACT_APP_PIWIK_NL_LESSONS_SITEID
const piwikNlAlertsBrandId = process.env.REACT_APP_PIWIK_NL_ALERTS_BRANDID
const piwikNlLessonsBrandId = process.env.REACT_APP_PIWIK_NL_LESSONS_BRANDID

/**
 * Ads
 */
const adsProperty = process.env.REACT_APP_ADS_PROPERTY
const adsActionUrl = process.env.REACT_APP_AIMATCH_ACTION

/**
 * Constants for routing and UI navigation.
 */
const sessionStorage = process.env.REACT_APP_SESSION_STORAGE
const defaultSpecialty = configuration.profile.defSpecialty
const defaultProfession = configuration.profile.defProfession

/**
 * Auth constants
 */
const paramsDuration = configuration.auth.cacheStateLife

/**
 * Recaptcha key
 */
const recaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY

const authVer = {
  v1: 'V1',
  v2: 'V2'
}
const authRoles = ['auth', 'soft', 'limited', 'limitedReg', 'anonymous']
const anonRoles = ['default', 'anon', 'anonymous']
const limitedRoles = ['limited', 'limitedReg']

// Roles Hierarchy, used to compare between cache & url tokens
const roleHierarchy = {
  auth: {
    key: 'auth',
    caption: 'Auth',
    priority: 1
  },
  soft: {
    key: 'soft',
    caption: 'Soft/UBQ-3',
    priority: 2
  },
  limitedReg: {
    key: 'limitedReg',
    caption: 'LimitedReg',
    priority: 3
  },
  limited: {
    key: 'limited',
    caption: 'Limited',
    priority: 4
  },
  anonymous: {
    key: 'anonymous',
    caption: 'Anonymous',
    priority: 5
  },
  default: {
    key: 'default',
    caption: 'Default',
    priority: 6
  }
}

export {
  authVer,
  phnxSiteIDv1,
  phnxSiteIDv2,
  phnxSiteID,
  piwikSiteId,
  siteUrl,
  scriptUrl,
  scriptName,
  piwikEnabled,
  nodeEnv,
  piwikNlAlertsSiteId,
  piwikNlLessonsSiteId,
  piwikNlAlertsBrandId,
  piwikNlLessonsBrandId,
  adsProperty,
  adsActionUrl,
  idhCampaignID,
  ntkTodaySiteId,
  jwpLicense,
  ntkDispatchSiteId,
  ntkToday,
  ntkDispatch,
  brandCode,
  sessionStorage,
  idhBrandId,
  idhSiteId,
  authRoles,
  anonRoles,
  limitedRoles,
  defaultSpecialty,
  defaultProfession,
  paramsDuration,
  basename,
  recaptchaKey,
  roleHierarchy,
  userFlowToken
}
