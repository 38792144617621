export const columnsMap = {
  Name: true,
  'Molecule Company Name': true,
  'Molecule Brand Name': true,
  'Reference Product': true,
  'Therapy Area': true
}

export const columnsMobileMap = { Name: true }

export const columns = [
  {
    Header: 'Name',
    style: { 'font-size': '12px' },
    accessor: 'Name',
    headerClassName: 'input-deals-db',
    sortType: 'text',
    filterMap: 'text'
  },
  {
    Header: 'Molecule Company Name',
    style: { 'font-size': '12px' },
    accessor: 'Molecule Company Name',
    headerClassName: 'input-deals-db',
    sortType: 'text',
    filterMap: 'text'
  },
  {
    Header: 'Molecule Brand Name',
    style: { 'font-size': '12px' },
    accessor: 'Molecule Brand Name',
    headerClassName: 'input-deals-db',
    sortType: 'text',
    filterMap: 'text'
  },
  {
    Header: 'Reference Product',
    style: { 'font-size': '12px' },
    accessor: 'Reference Product',
    headerClassName: 'input-deals-db',
    sortType: 'text',
    filterMap: 'text'
  },
  {
    Header: 'Therapy Area',
    style: { 'font-size': '12px' },
    accessor: 'Therapy Area',
    headerClassName: 'select-deals-db',
    sortType: 'equals',
    filterMap: 'equals'
  }
]

export const mobileColumns = [
  {
    Header: 'Name',
    style: { 'font-size': '12px' },
    accessor: 'Name',
    headerClassName: 'input-deals-db',
    sortType: 'text',
    filterMap: 'text'
  }
]
