import { compose } from 'redux'
import { connect } from 'react-redux'
import RecommendedForYouSidebar from 'src/modules/River/components/RiverSidebar/Sidebars/RecommendedForYouSidebar'

const mapDispatchToProps = dispatch => ({})

const mapStateToProps = state => ({
  ads: state.ads
})

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  RecommendedForYouSidebar
)
