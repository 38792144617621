import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { actions as notificationActions } from 'src/modules/Notifications/reducer.js'
import { Link } from 'react-router-dom'
import { ConnectedRouter as Router } from 'connected-react-router'
import { history } from 'src/Router.js'

const mapDispatchToProps = dispatch => ({
  notificationsDismiss: query => {
    dispatch(notificationActions.notificationsDismiss())
  }
})
const Notification = ({
  label,
  to = '/contact-us',
  notificationsDismiss,
  ...rest
}) => (
  <Router history={history}>
    <Link onClick={notificationsDismiss} to={to} {...rest}>
      {label}
    </Link>
  </Router>
)

Notification.propTypes = {
  label: PropTypes.string,
  to: PropTypes.string,
  notificationsDismiss: PropTypes.func
}

export default connect(null, mapDispatchToProps)(Notification)
