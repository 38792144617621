import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'src/components/ui/Modal/index.js'
import styles from './DeliveryTime.module.sass'
import Typography from 'src/components/ui/Typography/Typography.js'
import DeliveryTimeForm from './form'

const DeliveryTime = ({ ...props }) => {
  const { open, newsletter, onDismiss = () => {} } = props

  return (
    <Modal
      modalOpen={open}
      maxWidth={275}
      className={styles.modalContainer}
      onDismiss={onDismiss}
    >
      <div className={styles.modal}>
        <Typography type="title" gutterBottom>
          {newsletter?.name}
        </Typography>
        <Typography>
          Select the time at which you would like to receive your newsletter
        </Typography>
        <DeliveryTimeForm {...props} />
      </div>
    </Modal>
  )
}

DeliveryTime.propTypes = {
  open: PropTypes.bool,
  newsletter: PropTypes.object,
  onDismiss: PropTypes.func
}

export default DeliveryTime
