import React from 'react'
import PropTypes from 'prop-types'
import Link from 'src/components/Link/index.js'
import styles from './HeaderButton.module.sass'
import cx from 'classnames'

const HeaderButton = ({ color = 'primary', label = '', to, onClick }) => {
  const btnColor = styles[color] || ''

  return !to ? (
    <button className={cx(styles.button, btnColor)} onClick={onClick}>
      {label}
    </button>
  ) : (
    <Link className={cx(styles.link, styles.button, btnColor)} to={to}>
      {label}
    </Link>
  )
}

HeaderButton.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
  to: PropTypes.string,
  onClick: PropTypes.func
}

export default HeaderButton
