import {
  takeEvery,
  takeLatest,
  all,
  call,
  select,
  put
} from 'redux-saga/effects'
import { LOCATION_CHANGE } from 'connected-react-router'
import { actions } from './reducer.js'
import { licenseTypes } from 'src/services/FW5ML/parsers/UserParser.js'
import { getPageFound } from 'src/modules/Config/utils'
import { arrayify } from 'src/utils/index.js'
import { getAnonArticleLimitSettings } from 'src/modules/Config/selectors'
// import { configs } from 'src/constants/testEdge'
// import { configs } from 'src/constants/testph'
// import { configs } from 'src/constants/testHT'
//import { configs } from 'src/constants/testFWReports'

function* getConfigRequest(services, { payload: { key, data = null } }) {
  const CatalogsRepository = services('CatalogsRepository')
  try {
    if (!data) {
      data = yield call([CatalogsRepository, 'getCatalog'], key)
    }
    yield put(actions.getConfigSuccess({ key, data }))
  } catch (e) {
    console.error(e)
    yield put(actions.getConfigError({ key, error: e.message }))
  }
}

function* getConfigListRequest(services, { payload }) {
  const CatalogsRepository = services('CatalogsRepository')
  const configs = yield call([CatalogsRepository, 'getCatalog'], 'configs') //actual ML requesƒt
  for (const key of Object.keys(configs)) {
    const data = configs[key]

    if (key === 'menus') {
      yield call(menuConfigRequest, services, { payload: { data } })
      continue
    }

    yield call(getConfigRequest, services, { payload: { key, data } })
  }
  yield put(actions.getConfigListSuccessRequest())
}

function* updateConfigRequest(services, { payload: { key, data } }) {
  try {
    yield put(actions.updateConfigSuccess({ key, updatedData: data }))
  } catch (e) {
    console.error(e)
    yield put(actions.updateConfigError({ key, error: e.message }))
  }
}

function* menuConfigRequest(services, { payload: { data = null } }) {
  const CatalogsRepository = services('CatalogsRepository')
  const Session = services('Session')
  const key = 'menus'

  try {
    if (!data) {
      data = yield call([CatalogsRepository, 'getCatalog'], key)
    }
    const token = yield call([Session, 'tokenLoad'])
    const user = yield call([Session, 'userLoad'])

    if (token && user) {
      // Set internal token to state to use in different components
      yield put(
        actions.getConfigSuccess({ key: 'internal_token', data: token })
      )
    }
    data = yield call(recursiveMenuConfigUpdate, data, { token, user })
    yield put(actions.getConfigSuccess({ key, data }))
  } catch (e) {
    console.error(e)
    yield put(actions.getConfigError({ key, error: e.message }))
  }
}

function* recursiveMenuConfigUpdate(dataSet, { token, user }) {
  const keys = Object.keys(dataSet)
  for (let key of keys) {
    const data = dataSet[key]

    if (typeof data === 'object') {
      if (data?.to) {
        // Add internal token to external links
        if (data?.to.includes('{it}')) {
          if (token && user) {
            const separator = data?.to.includes('?') ? '&' : '?'
            data.to = data?.to.replace('{it}', `${separator}it=${token}`)
          } else {
            data.to = data?.to.replace('{it}', '')
          }
        }
      } else {
        yield call(recursiveMenuConfigUpdate, data, { token, user })
      }
    }
  }
  return dataSet
}

function* handleRegisterModalURLChange(services, action) {
  const Session = services('Session')
  const user = yield call([Session, 'userLoad'])
  const { license = '' } = user

  // Reset Paywall/Register Modal showing up
  // Show register popup if the flow defines it for free users
  const anonArticleLimitSettings = yield select(getAnonArticleLimitSettings())
  const { registerPopUpFlow = {} } = anonArticleLimitSettings

  const showRegisterModalForFree = registerPopUpFlow?.show_for_free || false

  const showRegisterModalForAnonymous =
    registerPopUpFlow?.show_for_anon || false

  const pagesToInclude = registerPopUpFlow?.uri_path
    ? arrayify(registerPopUpFlow.uri_path)
    : []
  const pageFound = getPageFound(pagesToInclude)

  const showRegisterPaywall =
    (showRegisterModalForFree &&
      pageFound &&
      license === licenseTypes.LICENSE_FREE) ||
    (showRegisterModalForAnonymous && pageFound && !user?.profile_id) ||
    false

  yield put(
    actions.updateConfigRequest({
      key: 'account_settings',
      data: {
        showRegisterPaywall
      }
    })
  )
}

export default function* watchConfig(services) {
  yield all([
    takeEvery('GET_CONFIG_REQUEST', getConfigRequest, services),
    takeLatest('GET_CONFIG_LIST_REQUEST', getConfigListRequest, services),
    takeLatest('UPDATE_CONFIG_REQUEST', updateConfigRequest, services),
    takeLatest(LOCATION_CHANGE, handleRegisterModalURLChange, services),
    takeLatest(
      'GET_CONFIG_REGISTER_MODAL_VALIDATION',
      handleRegisterModalURLChange,
      services
    )
  ])
}
