import React from 'react'
import PropTypes from 'prop-types'
import FullImage from 'src/components/FullImage/index.js'
import styles from './Teaser.module.sass'

const Teaser = ({ children, ...other }) => (
  <div className={styles.container}>
    <FullImage width="100%" height="100%" {...other} />
    {children && <div className={styles.child}>{children}</div>}
  </div>
)

Teaser.propTypes = {
  children: PropTypes.node
}

export default Teaser
