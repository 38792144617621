import React from 'react'
import styles from './SearchBarOptions.module.sass'
import Tab from 'src/components/ui/Tabs/Tab.js'
import Tabs from 'src/components/ui/Tabs/Tabs.js'

export const SortTabs = ({ options, value, onClick }) =>
  Object.keys(options).map(key => (
    <Tab
      className={styles.tab}
      key={key}
      selected={key === value}
      onClick={onClick(key)}
      data-piwik-class="sort-button"
      showsActiveBar
    >
      {options[key]}
    </Tab>
  ))
const SearchByOptions = props => (
  <Tabs className={styles.container}>
    <SortTabs {...props} />
  </Tabs>
)

export default SearchByOptions
