import React from 'react'
import { useSelector } from 'react-redux'
import HeaderItem from 'src/components/HeaderItem/index.js'
import Link from 'src/components/Link/index.js'
import styles from './HeaderLinks.module.sass'
import { getConfig } from 'src/modules/Config/selectors'
import Tag from 'src/components/Tag'
import { useFlags } from 'launchdarkly-react-client-sdk'

const HeaderLinks = () => {
  const menuConfig = useSelector(getConfig('menus'))
  const menuItems = menuConfig?.main || []
  const flags = useFlags()

  return menuItems.map((item, index) => {
    const shouldDisplay = !item.featureFlag || flags[item.featureFlag]

    return (
      shouldDisplay &&
      (item.group ? (
        <HeaderItem key={index} links={item.content}>
          {item.group}
        </HeaderItem>
      ) : (
        <HeaderItem key={index}>
          <Link className={styles.link} to={item.to}>
            {item.label}
          </Link>
          {item.tag && <Tag className={styles.auxTag} label={item.tag} />}
        </HeaderItem>
      ))
    )
  })
}

export default HeaderLinks
