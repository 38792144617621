import { useState, useEffect } from 'react'
import throttle from 'lodash/throttle'
import useEventListener from 'src/hooks/useEventListener.js'

function useMediaSize({
  mediaQueries = {
    small: '(max-width: 550px)',
    medium: '(max-width: 900px)'
  },
  defaultMediaSize = 'large',
  throttleTime = 25
} = {}) {
  const [mediaSize, setMediaSize] = useState(defaultMediaSize)

  const updateWidth = () => {
    const mqs = Object.keys(mediaQueries).reduce(
      (prev, curr) => ({
        ...prev,
        [curr]: window.matchMedia(mediaQueries[curr]).matches
      }),
      {}
    )
    const size =
      Object.keys(mqs).filter(size => mqs[size])[0] || defaultMediaSize
    setMediaSize(size)
  }

  // get initial size when the component mounts
  useEffect(() => {
    updateWidth()
  }, [])

  // add event listener to update the size when screen is resized
  useEventListener('resize', throttle(updateWidth, throttleTime))

  return mediaSize
}

export default useMediaSize
