import React from 'react'
import Spinner from 'src/components/ui/Spinner/index.js'
import styles from './SpinnerPage.module.sass'

const SpinnerPage = () => (
  <div className={styles.fixedPage}>
    <div className={styles.logoutPage}>
      <Spinner fillColor="transparent" strokeColor="#fff" />
    </div>
  </div>
)

export default SpinnerPage
