import React, { Fragment, useRef } from 'react'
import _get from 'lodash/get'
import Spinner from 'src/components/ui/Spinner/index.js'
import Checkbox from 'src/components/ui/Checkbox/index.js'
import PropTypes from 'prop-types'
import { tagWithCategory, interestTree } from 'src/utils/propTypes.js'
import ViewMoreBox from 'src/components/ViewMoreBox/index.js'
import InterestGroup from '../InterestGroup'
import Autosuggest from '../Autosuggest'
import Interest from 'src/components/Interest/SingleStoryInterest.js'
import styles from './MyInterests.module.sass'
import useComponentSize from '@rehooks/component-size'
import throttle from 'lodash/throttle'
import classNames from 'classnames/bind'
import Uncap from 'src/components/Uncap/index.js'
import { INCLUDE_MEDICAL_ABSTRACT } from 'src/services/FW5ML/constants/settings.js'
import { filterLabelsConfigModule } from 'src/siteConfig/river.js'
const { filterLabels, excludedCategories } = filterLabelsConfigModule

const cx = classNames.bind(styles)

// @TODO: Remove use of messilyConsolidateInterests when the ML no longer sends duplicate groups
const messilyConsolidateInterests = mergedInterests => {
  // Map all interests to flat array of objects - retaining the original category value as object property
  const mappedInterests = Object.keys(mergedInterests).reduce(
    (accumulator, category) =>
      accumulator.concat(
        mergedInterests[category].map(interest => ({ interest, category }))
      ),
    []
  )
  let interestsGroup = {}
  for (let intendedCategory in mergedInterests) {
    // Regroup the initial interests along with those from the matched duplicate categories under the initial category
    interestsGroup[intendedCategory] = mappedInterests.filter(
      ({ category: actualCategory }) =>
        actualCategory === intendedCategory ||
        (intendedCategory === 'fw_company' &&
          actualCategory === 'fw_companies') ||
        (intendedCategory === 'fw_regulatory_qualifier' &&
          actualCategory === 'fw_regulatory_agency') ||
        (intendedCategory === 'conditions' && actualCategory === 'fw_condition')
    )
  }
  // Delete duplicate categories
  delete interestsGroup.fw_companies
  delete interestsGroup.fw_regulatory_agency
  delete interestsGroup.fw_condition
  return interestsGroup
}

const MyInterests = ({
  interests = {},
  suggestions = [],
  suggestionsMessage,
  pendingForInsertion = [],
  pendingForDeletion = [],
  requestDeleteInterest = () => {
    /* NOOP */
  },
  requestInsertInterest = () => {
    /* NOOP */
  },
  requestSuggestInterest = () => {
    /* NOOP */
  },

  clearSuggestInterest = () => {
    /* NOOP */
  },
  partial = false,
  busy = false,
  updateSettings,
  settingsBusy,
  settings,
  myInterestsConfig
}) => {
  // Merge user selected interests with all possible categories
  const { showMedical = true, autoSuggest = null } = myInterestsConfig ?? {}
  let emptyCategories = {}
  for (let label in filterLabels) {
    if (!excludedCategories.includes(label)) {
      emptyCategories[label] = []
    }
  }
  const mergedInterests = { ...emptyCategories, ...interests }
  let interestTotal = 0

  // @TODO: Remove use of messilyConsolidateInterests when the ML no longer sends duplicate groups
  /**
   * The following is a temporary fix to accomodate the duplicate interest groups coming from the ML
   * When this is corrected, we can use `mergedInterests` directly in the `Object.keys().map`
   * Without use of messilyConsolidateInterests
   */
  const interestsGroup = messilyConsolidateInterests(mergedInterests)
  const mappedInterestGroups = Object.keys(interestsGroup).map(
    (groupName, categoryIndex) => {
      const mappedInterest = interestsGroup[groupName]
      return (
        <InterestGroup
          key={groupName}
          interests={mappedInterest}
          category={groupName}
          pendingForDeletion={pendingForDeletion}
          partial={partial}
          render={({ interest, category }, interestIndex) => {
            interestTotal++ // Total number of interests rendered across all categories
            return (
              !(partial && interestTotal > 6) && (
                <Interest
                  key={`${categoryIndex}-${interestIndex}`}
                  interest={{ category, tag: interest }}
                  onDeleteInterest={requestDeleteInterest}
                  active
                />
              )
            )
          }}
        />
      )
    }
  )
  const ref = useRef(null)
  const throttledComponentSize = throttle(useComponentSize, 250)
  const size = throttledComponentSize(ref)
  const { width } = size
  const sidebar = width < 420
  return (
    <Fragment>
      <Autosuggest
        suggestions={suggestions}
        loadingSuggestions={busy}
        insertingInterest={!!pendingForInsertion.length}
        onSuggestionRequested={requestSuggestInterest}
        onAddInterest={requestInsertInterest}
        clearSuggestInterest={clearSuggestInterest}
        suggestionsMessage={suggestionsMessage}
        autoSuggestConfig={autoSuggest}
      />
      {!partial && showMedical && (
        <div className={styles.checkboxContainer}>
          <Checkbox
            label="Show Medical Abstracts in MyFW+ and Search Results"
            name="settings_show_medical_abstracts"
            className={styles.checkbox}
            hasDivider={false}
            disabled={settingsBusy}
            checked={_get(settings, INCLUDE_MEDICAL_ABSTRACT, true)}
            onChange={e =>
              updateSettings({
                [INCLUDE_MEDICAL_ABSTRACT]: e.currentTarget.checked
              })
            }
          />
          <div className={styles.settingsLoading}>
            {settingsBusy && <Spinner size={24} fillColor="transparent" />}
          </div>
        </div>
      )}
      {partial ? (
        <Fragment>
          <div
            ref={ref}
            className={cx(styles.partial, {
              sidebar
            })}
          >
            {mappedInterestGroups}
          </div>
          <ViewMoreBox to="/my-account/interests">
            View All <Uncap>MyFW+</Uncap> Interests
          </ViewMoreBox>
        </Fragment>
      ) : (
        mappedInterestGroups
      )}
    </Fragment>
  )
}

MyInterests.propTypes = {
  /**
   * Called when "delete interest" button is clicked
   */
  requestDeleteInterest: PropTypes.func,
  /**
   * Called when "add interest" button is clicked
   */
  requestInsertInterest: PropTypes.func,
  /**
   * Called when autocomplete changes. Debounced.
   */
  requestSuggestInterest: PropTypes.func,
  /**
   * Object holding saved interests for current user
   */
  interests: interestTree,
  /**
   * Array of suggestions based on the interest typed in
   */
  suggestions: PropTypes.arrayOf(tagWithCategory),
  /**
   * The interest(s) pending deletion
   */
  pendingForDeletion: PropTypes.arrayOf(tagWithCategory),
  /**
   * The interest(s) pending insertion
   */
  pendingForInsertion: PropTypes.arrayOf(tagWithCategory),
  /**
   * If `true`, shows only a snippet of full interests
   */
  partial: PropTypes.bool,
  /**
   * If `true`, suggestions are being loaded
   */
  busy: PropTypes.bool,
  /**
   * Other props
   */
  suggestionsMessage: PropTypes.string,
  clearSuggestInterest: PropTypes.func,
  updateSettings: PropTypes.func,
  settingsBusy: PropTypes.bool,
  settings: PropTypes.object,
  myInterestsConfig: PropTypes.object
}

export default MyInterests
