import React, { useState, useEffect, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import Button from 'src/components/ui/Buttons/Button.js'
import styles from './OnboardingNewsletters.module.sass'
import Tag from 'src/components/Tag/index.js'
import { SessionContext } from 'src/modules/Session/context'
import Typography from 'src/components/ui/Typography/index'
import { historyHandler } from 'src/utils/index'
import { useHistory } from 'react-router-dom'
import BasicLoader from 'src/components/ui/BasicLoader/BasicLoader'
import { actions as newsletterActions } from 'src/modules/MyNewsletters/reducer'

import usePiwik from 'src/hooks/usePiwik'
import {
  siteName,
  getSubscriptionTypes,
  getFreeSubscriptionTypes
} from 'src/utils/oneCodeBase.js'

const ToggleLabel = ({
  checked,
  name,
  id,
  frequency,
  type = 'free',
  disabled = false,
  setCheckedParent = () => {}
}) => {
  const textBoxStyle = frequency ? styles.campName : styles.campName1
  return (
    <div className={styles.toggleContainer}>
      <div className={styles.toggle}>
        <input
          checked={checked}
          disabled={disabled}
          value={id}
          type="checkbox"
          id={id}
          onChange={e =>
            setCheckedParent({ status: e.target.checked, id, type })
          }
        />
        <label htmlFor={id} />
      </div>
      <div className={styles.text}>
        <div>
          {name && <Typography className={textBoxStyle}>{name}</Typography>}
          {type === 'plus' && <Tag className={styles.plus} label="PLUS" />}
        </div>
        {frequency && (
          <Typography className={styles.frequency}>{frequency}</Typography>
        )}
      </div>
    </div>
  )
}

ToggleLabel.propTypes = {
  checked: PropTypes.bool,
  name: PropTypes.string,
  id: PropTypes.string,
  frequency: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  setCheckedParent: PropTypes.func
}

const Newsletters = ({ content }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [onboardingFreeNewsletters, setOnboardingFreeNewsletters] =
    useState(null)
  const [onboardingPlusNewsletters, setOnboardingPlusNewsletters] =
    useState(null)

  const { handleTracking } = usePiwik()
  const { user } = useContext(SessionContext)

  const subscribedNewsletters = useSelector(
    state => state.myNewsletters.subscribed
  )
  const allNewsletters = useSelector(
    state => state.myNewsletters.all_newsletters
  )

  // Fetch user's newsletters
  useEffect(() => {
    dispatch(newsletterActions.requestListAllNewsletters())
    dispatch(newsletterActions.requestListSubscribedNewsletters())
  }, [])

  // Check that config newsletters exists in newsletters list
  useMemo(() => {
    if (content?.length > 0 && allNewsletters?.length > 0) {
      const freeSubscriptions = getFreeSubscriptionTypes()
      const plusSiteSubscription = getSubscriptionTypes()?.PLUS
      const freeSiteSubscription = getSubscriptionTypes()?.FREE
      const freeNewsletters = []
      const plusNewsletters = []

      for (const contentItem of content) {
        contentItem.found = false
        for (const newsletter of allNewsletters) {
          if (contentItem.found) {
            break
          }

          if (contentItem.uid === newsletter.id) {
            // Plus only newsletters
            if (
              newsletter?.audience?.subscription_types[0] ===
              plusSiteSubscription
            ) {
              contentItem.id = contentItem.uid
              contentItem.status =
                user.subscription_type === plusSiteSubscription
              contentItem.disabled =
                user.subscription_type !== plusSiteSubscription
              plusNewsletters.push(contentItem)
              break
            } else {
              // Free newsletters
              for (const freeSubscriptionType of freeSubscriptions) {
                if (
                  newsletter?.audience?.subscription_types.includes(
                    freeSubscriptionType
                  )
                ) {
                  contentItem.status =
                    newsletter?.audience?.subscription_types.includes(
                      freeSiteSubscription
                    )
                  contentItem.id = contentItem.uid
                  contentItem.disabled = false
                  freeNewsletters.push(contentItem)

                  break
                }
              }
            }
          }
        }
      }

      setOnboardingFreeNewsletters(freeNewsletters)
      setOnboardingPlusNewsletters(plusNewsletters)
      setLoading(false)
    }
  }, [content, allNewsletters])

  const history = useHistory()
  const [emptyChecked, setEmptyChecked] = useState(false)

  const setCheckedParent = data => {
    setOnboardingFreeNewsletters(newsletters =>
      newsletters.map(item => {
        return item.id === data?.id && data.type === 'free'
          ? { ...item, status: data.disabled ? false : data?.status }
          : item
      })
    )
    setOnboardingPlusNewsletters(newsletters =>
      newsletters.map(item => {
        return item.id === data?.id && data.type === 'plus'
          ? { ...item, status: data.disabled ? false : data?.status }
          : item
      })
    )
  }
  const processNewsletters = () => {
    const newsletters = [
      ...onboardingFreeNewsletters,
      ...onboardingPlusNewsletters
    ]

    handleTracking('button', 'click', 'newsletter-onboarding_confirm')
    const isEmpty = newsletters.every(nl => nl.status === false)
    setEmptyChecked(isEmpty)

    if (isEmpty) {
      return
    }

    const mappedNewsletters = newsletters.map(item => {
      const mapped = allNewsletters.find(subitem => subitem.id === item.id)
      return { ...mapped, status: item.status }
    })

    const newslettersToSubscribe = mappedNewsletters.filter(item => {
      return (
        item.status === true &&
        !item.disabled &&
        !subscribedNewsletters.find(subitem => subitem.id === item.id)
      )
    })

    const newslettersToUnsubscribe = mappedNewsletters.filter(item => {
      return (
        item.status === false &&
        !item.disabled &&
        subscribedNewsletters.find(subitem => subitem.id === item.id)
      )
    })

    if (newslettersToSubscribe?.length > 0) {
      dispatch(
        newsletterActions.requestInsertNewsletters(newslettersToSubscribe)
      )
    }

    for (let unsubscription of newslettersToUnsubscribe) {
      dispatch(newsletterActions.requestDeleteNewsletter(unsubscription))
    }

    historyHandler(
      '/register-newsletter-subscribe-success',
      '',
      history,
      'replace'
    )
  }

  return (
    <>
      {loading && <BasicLoader />}

      <div>
        {onboardingFreeNewsletters?.length > 0 && (
          <div className={styles.nlSections}>
            {onboardingFreeNewsletters?.map(item => (
              <ToggleLabel
                checked={item.status}
                setCheckedParent={setCheckedParent}
                type="free"
                key={`free-${item.id}-toggle`}
                name={item.name}
                id={item.id}
                frequency={item.frequency_description}
                disabled={item.disabled}
              />
            ))}
          </div>
        )}

        {onboardingPlusNewsletters?.length > 0 && (
          <>
            <Typography className={styles.description}>
              Exclusive to FirstWord {siteName}+ subscribers
            </Typography>
            <div className={styles.nlSections}>
              {onboardingPlusNewsletters?.map(item => (
                <ToggleLabel
                  checked={item.status}
                  setCheckedParent={setCheckedParent}
                  type="plus"
                  key={`plus-${item.id}-toggle`}
                  name={item.name}
                  id={item.id}
                  frequency={item.frequency_description}
                  disabled={item.disabled}
                />
              ))}
            </div>
          </>
        )}

        {(onboardingFreeNewsletters?.length > 0 ||
          onboardingPlusNewsletters?.length > 0) && (
          <>
            {emptyChecked && (
              <Typography className={styles.emptyChecked}>
                * Please select a newsletter
              </Typography>
            )}
            <Button
              className={styles.button}
              tier={'secondary'}
              onClick={processNewsletters}
              textCase="uppercase"
              text={'Confirm'}
            />
          </>
        )}
      </div>
    </>
  )
}

Newsletters.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string,
      name: PropTypes.string,
      frequency_description: PropTypes.string
    })
  )
}

export default Newsletters
