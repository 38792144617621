import { createAction, handleActions } from 'redux-actions'
import asyncMeta from 'src/modules/AsyncFlags/metaCreator'

export const requestListNewsletters = createAction(
  'ACCOUNT_LIST_NEWSLETTER__REQUEST',
  null,
  asyncMeta
)
export const errorListNewsletters = createAction(
  'ACCOUNT_LIST_NEWSLETTER__ERROR',
  null,
  asyncMeta
)
export const successListNewsletters = createAction(
  'ACCOUNT_LIST_NEWSLETTER__SUCCESS',
  null,
  asyncMeta
)

export const requestListAllNewsletters = createAction(
  'ACCOUNT_LIST_ALL_NEWSLETTER__REQUEST',
  null,
  asyncMeta
)
export const errorListAllNewsletters = createAction(
  'ACCOUNT_LIST_ALL_NEWSLETTER__ERROR',
  null,
  asyncMeta
)
export const successListAllNewsletters = createAction(
  'ACCOUNT_LIST_ALL_NEWSLETTER__SUCCESS',
  null,
  asyncMeta
)

export const requestListSubscribedNewsletters = createAction(
  'ACCOUNT_LIST_SUBSCRIBED_NEWSLETTER__REQUEST',
  null,
  asyncMeta
)
export const errorListSubscribedNewsletters = createAction(
  'ACCOUNT_LIST_SUBSCRIBED_NEWSLETTER__ERROR',
  null,
  asyncMeta
)
export const successListSubscribedNewsletters = createAction(
  'ACCOUNT_LIST_SUBSCRIBED_NEWSLETTER__SUCCESS',
  null,
  asyncMeta
)

export const requestDeleteNewsletter = createAction(
  'ACCOUNT_DELETE_NEWSLETTER__REQUEST',
  null,
  asyncMeta
)
export const errorDeleteNewsletter = createAction(
  'ACCOUNT_DELETE_NEWSLETTER__ERROR',
  null,
  asyncMeta
)
export const successDeleteNewsletter = createAction(
  'ACCOUNT_DELETE_NEWSLETTER__SUCCESS',
  null,
  asyncMeta
)

export const requestInsertNewsletter = createAction(
  'ACCOUNT_INSERT_NEWSLETTER__REQUEST',
  null,
  asyncMeta
)
export const errorInsertNewsletter = createAction(
  'ACCOUNT_INSERT_NEWSLETTER__ERROR',
  null,
  asyncMeta
)
export const successInsertNewsletter = createAction(
  'ACCOUNT_INSERT_NEWSLETTER__SUCCESS',
  null,
  asyncMeta
)
export const requestInsertNewsletters = createAction(
  'ACCOUNT_INSERT_NEWSLETTERS__REQUEST',
  null,
  asyncMeta
)
export const errorInsertNewsletters = createAction(
  'ACCOUNT_INSERT_NEWSLETTERS__ERROR',
  null,
  asyncMeta
)
export const successInsertNewsletters = createAction(
  'ACCOUNT_INSERT_NEWSLETTERS__SUCCESS',
  null,
  asyncMeta
)

export const setNewsletterSettings = createAction(
  'ACCOUNT_SET_NEWSLETTER_SETTINGS',
  null,
  asyncMeta
)

export const requestUpdateDeliveryTime = createAction(
  'ACCOUNT_UPDATE_DELIVERY_TIME__REQUEST',
  null,
  asyncMeta
)
export const errorUpdateDeliveryTime = createAction(
  'ACCOUNT_UPDATE_DELIVERY_TIME__ERROR',
  null,
  asyncMeta
)
export const successUpdateDeliveryTime = createAction(
  'ACCOUNT_UPDATE_DELIVERY_TIME__SUCCESS',
  null,
  asyncMeta
)
export const openUpdateDeliveryTime = createAction(
  'ACCOUNT_UPDATE_DELIVERY_TIME__OPEN',
  null,
  asyncMeta
)
export const closeUpdateDeliveryTime = createAction(
  'ACCOUNT_UPDATE_DELIVERY_TIME__CLOSE',
  null,
  asyncMeta
)
export const requestGetRegionByCountry = createAction(
  'ACCOUNT_GET_REGION_BY_COUNTRY__REQUEST',
  null,
  asyncMeta
)
export const errorGetRegionByCountry = createAction(
  'ACCOUNT_GET_REGION_BY_COUNTRY__ERROR',
  null,
  asyncMeta
)
export const successGetRegionByCountry = createAction(
  'ACCOUNT_GET_REGION_BY_COUNTRY__SUCCESS',
  null,
  asyncMeta
)

export const handleFilterSelect = createAction('ACCOUNT_SELECT_FILTER')

export const actions = {
  requestListNewsletters,
  successListNewsletters,
  errorListNewsletters,

  requestListAllNewsletters,
  successListAllNewsletters,
  errorListAllNewsletters,

  requestListSubscribedNewsletters,
  errorListSubscribedNewsletters,
  successListSubscribedNewsletters,

  requestDeleteNewsletter,
  errorDeleteNewsletter,
  successDeleteNewsletter,

  requestInsertNewsletter,
  errorInsertNewsletter,
  successInsertNewsletter,

  requestInsertNewsletters,
  errorInsertNewsletters,
  successInsertNewsletters,

  setNewsletterSettings,
  requestUpdateDeliveryTime,
  errorUpdateDeliveryTime,
  successUpdateDeliveryTime,
  openUpdateDeliveryTime,
  closeUpdateDeliveryTime,

  requestGetRegionByCountry,
  errorGetRegionByCountry,
  successGetRegionByCountry
}

const defaultState = {
  newsletters: [],
  all_newsletters: [],
  subscribed: [],
  pendingForDeletion: [],
  pendingForInsertion: [],
  newsletterSettings: {
    delivery_time: [],
    busy: false,
    error: ''
  },
  region: { busy: false, error: '', userRegion: '' }
}

export default handleActions(
  {
    ACCOUNT_LIST_NEWSLETTER__REQUEST: state => ({
      ...state,
      newsletters: []
    }),
    ACCOUNT_LIST_NEWSLETTER__SUCCESS: (state, { payload: newsletters }) => ({
      ...state,
      newsletters
    }),

    ACCOUNT_LIST_ALL_NEWSLETTER__REQUEST: state => ({
      ...state,
      all_newsletters: []
    }),
    ACCOUNT_LIST_ALL_NEWSLETTER__SUCCESS: (
      state,
      { payload: all_newsletters }
    ) => ({
      ...state,
      all_newsletters
    }),

    ACCOUNT_LIST_SUBSCRIBED_NEWSLETTER__REQUEST: state => ({
      ...state,
      subscribed: []
    }),
    ACCOUNT_LIST_SUBSCRIBED_NEWSLETTER__SUCCESS: (
      state,
      { payload: subscribed }
    ) => ({
      ...state,
      subscribed
    }),

    ACCOUNT_DELETE_NEWSLETTER__REQUEST: (state, { payload: { id } }) => ({
      ...state,
      pendingForDeletion: [...state.pendingForDeletion, id]
    }),
    ACCOUNT_DELETE_NEWSLETTER__SUCCESS: (state, { payload: { id } }) => ({
      ...state,
      pendingForDeletion: state.pendingForDeletion.filter(
        pending => pending !== id
      ),
      subscribed: state.subscribed.filter(
        subscribedNewsletter => subscribedNewsletter.id !== id
      )
    }),
    ACCOUNT_DELETE_NEWSLETTER__ERROR: (state, { payload: { id, error } }) => ({
      ...state,
      pendingForDeletion: state.pendingForDeletion.filter(
        pending => pending !== id
      )
    }),

    ACCOUNT_INSERT_NEWSLETTER__REQUEST: (state, { payload: { id } }) => ({
      ...state,
      pendingForInsertion: [...state.pendingForInsertion, id]
    }),
    ACCOUNT_INSERT_NEWSLETTER__SUCCESS: (state, { payload: newsletter }) => ({
      ...state,
      pendingForInsertion: state.pendingForInsertion.filter(
        pending => pending !== newsletter.id
      ),
      subscribed: [...state.subscribed, newsletter]
    }),
    ACCOUNT_INSERT_NEWSLETTER__ERROR: (state, { payload: { id, error } }) => ({
      ...state,
      pendingForInsertion: state.pendingForInsertion.filter(
        pending => pending !== id
      )
    }),
    ACCOUNT_INSERT_NEWSLETTERS__REQUEST: (state, { payload: { id } }) => ({
      ...state,
      pendingForInsertion: [...state.pendingForInsertion, id]
    }),
    ACCOUNT_INSERT_NEWSLETTERS__SUCCESS: (state, { payload: newsletter }) => ({
      ...state,
      pendingForInsertion: state.pendingForInsertion.filter(
        pending => pending !== newsletter.id
      ),
      subscribed: [...state.subscribed, newsletter]
    }),
    ACCOUNT_INSERT_NEWSLETTERS__ERROR: (state, { payload: { id, error } }) => ({
      ...state,
      pendingForInsertion: state.pendingForInsertion.filter(
        pending => pending !== id
      )
    }),
    ACCOUNT_SET_NEWSLETTER_SETTINGS: (
      state,
      { payload: { newsletterSettings } }
    ) => ({
      ...state,
      newsletterSettings: {
        ...state.newsletterSettings,
        ...newsletterSettings
      }
    }),
    ACCOUNT_UPDATE_DELIVERY_TIME__REQUEST: state => ({
      ...state,
      newsletterSettings: {
        ...state.newsletterSettings,
        busy: true
      }
    }),
    ACCOUNT_UPDATE_DELIVERY_TIME__SUCCESS: (
      state,
      { payload: { newsletterUuid, deliveryTime } }
    ) => {
      const {
        newsletterSettings: { delivery_time }
      } = state
      const newDeliveryTimes = delivery_time.map(d =>
        d.newsletter_uuid === newsletterUuid
          ? { ...d, user_time: deliveryTime }
          : d
      )
      return {
        ...state,
        newsletterSettings: {
          ...state.newsletterSettings,
          delivery_time: newDeliveryTimes,
          busy: false,
          error: ''
        }
      }
    },
    ACCOUNT_UPDATE_DELIVERY_TIME__ERROR: (state, { payload: { error } }) => ({
      ...state,
      newsletterSettings: {
        ...state.newsletterSettings,
        busy: false,
        error
      }
    }),
    ACCOUNT_UPDATE_DELIVERY_TIME__OPEN: (
      state,
      { payload: newsletterUuid }
    ) => {
      const {
        newsletterSettings: { delivery_time }
      } = state
      const newDeliveryTimes = delivery_time.map(d =>
        d.newsletter_uuid === newsletterUuid ? { ...d, open: true } : d
      )
      return {
        ...state,
        newsletterSettings: {
          ...state.newsletterSettings,
          delivery_time: newDeliveryTimes,
          busy: false,
          error: ''
        }
      }
    },
    ACCOUNT_UPDATE_DELIVERY_TIME__CLOSE: (
      state,
      { payload: newsletterUuid }
    ) => {
      const {
        newsletterSettings: { delivery_time }
      } = state
      const newDeliveryTimes = delivery_time.map(d =>
        d.newsletter_uuid === newsletterUuid ? { ...d, open: false } : d
      )
      return {
        ...state,
        newsletterSettings: {
          ...state.newsletterSettings,
          delivery_time: newDeliveryTimes,
          busy: false,
          error: ''
        }
      }
    },

    ACCOUNT_GET_REGION_BY_COUNTRY__REQUEST: state => ({
      ...state,
      region: { busy: true, error: '', userRegion: '' }
    }),
    ACCOUNT_GET_REGION_BY_COUNTRY__SUCCESS: (state, { payload: { data } }) => ({
      ...state,
      region: { busy: false, error: '', userRegion: data }
    }),
    ACCOUNT_GET_REGION_BY_COUNTRY__ERROR: (state, { payload: { error } }) => ({
      ...state,
      region: { busy: false, error, userRegion: '' }
    })
  },
  defaultState
)
