export default function validate(values) {
  let errors = {}
  const maxLength = 50
  if (!values.first_name) {
    errors.first_name = 'Your first name is required'
  } else if (values.first_name.trim() === '') {
    errors.first_name = 'Your first name is required'
  } else if (values.first_name.length > maxLength) {
    errors.first_name = `Your first name must contain no more than ${maxLength} characters.`
  }

  if (!values.last_name) {
    errors.last_name = 'Your last name is required'
  } else if (values.last_name.trim() === '') {
    errors.last_name = 'Your last name is required'
  } else if (values.last_name.length > maxLength) {
    errors.last_name = `Your last name must contain no more than ${maxLength} characters.`
  }

  if (!values.country) {
    errors.country = 'Country is required'
  }

  if (!values.email) {
    errors.email = 'Your email address is required'
  } else if (!/^[^@]+@.+\.[a-z]{2,}$/i.test(values.email)) {
    errors.email = 'Invalid email address'
  }

  if (!values.company) {
    errors.company = 'Company is required'
  } else if (values.company.trim() === '') {
    errors.company = 'Company is required'
  } else if (values.company.length > maxLength) {
    errors.company = `Your company must contain no more than ${maxLength} characters.`
  }

  if (!values.job_title) {
    errors.job_title = 'Your job title is required'
  } else if (values.job_title.trim() === '') {
    errors.job_title = 'Your job title is required'
  } else if (values.job_title.length > 80) {
    errors.job_title = 'Your Job Title must contain no more than 80 characters.'
  }

  if (!values.job_function) {
    errors.job_function = 'Your job function is required'
  }

  if (values.phone_number) {
    const justNumbers = values.phone_number.replace(/\D+/g, '')
    if (justNumbers.length < 7 || justNumbers.length > 25) {
      errors.phone_number = 'Invalid telephone number'
    }
    const noNumbers = values.phone_number.replace(/\d+/g, '')
    const allowed = /(ext|x|\.|-|\(|\)|\/|\+)*/gi
    if (noNumbers.length > 0 && !allowed.test(noNumbers)) {
      errors.phone_number = 'Invalid telephone number'
    }
  }

  if (values.heard_about === 'other') {
    if (!values.other) {
      errors.other = 'required'
    }
  }

  if (values.other) {
    if (values.other.length > 100) {
      errors.other = `Other must contain no more than 100 characters`
    }
  }

  if (!values.license) {
    errors.license = 'License is required'
  }

  return errors
}
