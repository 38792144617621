import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import styles from './Newsletter.module.sass'
import Button from 'src/components/ui/Buttons/Button.js'
import Icon from 'src/components/ui/Icon/index.js'
import Typography from 'src/components/ui/Typography/index.js'
import { newsletter as newsletterPropType } from 'src/utils/propTypes.js'
import DeliveryTime from 'src/modules/MyNewsletters/components/DeliveryTime'

const cx = classNames.bind(styles)

const Newsletter = ({
  newsletter,
  topItem,
  showThumbnail,
  busy,
  subscribed,
  deliveryTime,
  region,
  onButtonClick = () => {
    /* NOOP */
  },
  updateDeliveryTime = () => {},
  openUpdateDeliveryTime = () => {},
  closeUpdateDeliveryTime = () => {}
}) => {
  let userRegion = null
  if (Array.isArray(region) && region?.length > 0) {
    for (const entry of region) {
      if (entry.name) {
        userRegion = entry.name
        break
      }
    }
  }
  let hasDeliveryTimes
  let {
    // dbID,
    id,
    name = '',
    description = '',
    frequency_description,
    isRegionBased,
    plusOnly = false,
    newNewsletter = false,
    domain
  } = newsletter
  description = description[userRegion] || description['default'] || ''
  hasDeliveryTimes = newsletter.hasDeliveryTimes

  // const [image, setImage] = useState('')

  const selectedDeliveryTime = deliveryTime?.find(d => d.newsletter_uuid === id)
    ? deliveryTime?.find(d => d.newsletter_uuid === id).user_time
    : null

  const openDeliveryTime = deliveryTime?.find(d => d.newsletter_uuid === id)
    ? deliveryTime?.find(d => d.newsletter_uuid === id).open || false
    : false

  let buttonText

  if (busy) {
    buttonText = ''
  } else if (subscribed) {
    buttonText = 'Subscribed'
  } else {
    buttonText = 'Sign Up'
  }

  // useEffect(() => {
  //   if (showThumbnail) {
  //     async function fetchImage() {
  //       console.log(dbID)
  //       const image = await getImage(dbID)
  //       setImage(image)
  //     }
  //     fetchImage()
  //   }
  // }, [])

  return (
    <div className={cx(styles.newsletter, topItem && styles.topItem)}>
      {showThumbnail && (
        <div className={styles.imageContainer}>
          <div
            style={{
              backgroundColor: domain === 'Reports' ? '#893535' : '#115069ff',
              color: '#fff',
              padding: '10px',
              position: 'relative'
            }}
            className={styles.normalImage}
          >
            <span
              style={{
                position: 'absolute',
                fontFamily: 'Montserrat',
                bottom: '5px',
                left: '10px',
                fontWeight: '450',
                fontSize: '1.2em',
                width: '80%'
              }}
            >
              {name}
            </span>
            {newNewsletter && (
              <span
                style={{
                  padding: '2px 6px',
                  fontSize: '0.83em',
                  backgroundColor: '#ff4133',
                  position: 'absolute',
                  top: '0px',
                  left: '13px'
                }}
              >
                NEW
              </span>
            )}

            {plusOnly && (
              <span
                style={{
                  padding: '2px 6px',
                  fontSize: '0.83em',
                  backgroundColor: '#007e6f',
                  position: 'absolute',
                  fontWeight: 'bold',
                  top: '0px',
                  right: '13px'
                }}
              >
                + PLUS
              </span>
            )}
          </div>
        </div>
      )}
      <div className={cx(topItem && styles.textWrapper)}>
        <Typography type="body2" className={styles.title}>
          {name}
        </Typography>
      </div>
      {!topItem && (
        <Typography type="body1" className={styles.description}>
          {description}
        </Typography>
      )}
      <div className={styles.bottomActions}>
        {!topItem && (
          <Typography type="body2" className={styles.frequency}>
            {frequency_description}{' '}
            {!topItem &&
              hasDeliveryTimes &&
              !isRegionBased &&
              selectedDeliveryTime &&
              subscribed && (
                <>
                  <span>at </span>
                  <span
                    className={styles.deliveryTime}
                    onClick={() => openUpdateDeliveryTime(newsletter.id)}
                  >
                    {selectedDeliveryTime}
                    <Icon
                      icon="svg/custom/edit"
                      colorTier="secondary"
                      iconSize={18}
                    />
                  </span>
                </>
              )}
          </Typography>
        )}
        {!topItem &&
          hasDeliveryTimes &&
          selectedDeliveryTime &&
          subscribed &&
          !isRegionBased && (
            <DeliveryTime
              newsletter={newsletter}
              open={openDeliveryTime}
              onSubmit={updateDeliveryTime}
              onDismiss={() => closeUpdateDeliveryTime(newsletter.id)}
              selectedDeliveryTime={selectedDeliveryTime}
            />
          )}
        <Button
          className={cx(styles.button, {
            changing: busy,
            subscribe: !subscribed,
            wider: topItem
          })}
          hasSpinner={busy} // Toggle hasSpinner in addition to submitting to prevent button width animation
          submitting={busy}
          tier={subscribed ? 'secondary' : 'default'}
          onClick={!busy ? onButtonClick : undefined}
          textCase="none"
          text={buttonText}
        />
      </div>
    </div>
  )
}

Newsletter.propTypes = {
  newsletter: newsletterPropType,
  topItem: PropTypes.bool,
  showThumbnail: PropTypes.bool,
  busy: PropTypes.bool,
  subscribed: PropTypes.bool,
  deliveryTime: PropTypes.arrayOf(
    PropTypes.shape({
      newsletter_uuid: PropTypes.string,
      user_time: PropTypes.string,
      open: PropTypes.bool
    })
  ),
  region: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string
    })
  ),
  updateDeliveryTime: PropTypes.func,
  openUpdateDeliveryTime: PropTypes.func,
  closeUpdateDeliveryTime: PropTypes.func,
  onButtonClick: PropTypes.func
}

export default Newsletter
