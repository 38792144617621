import React, { useRef, useContext } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import Button from 'src/components/ui/Buttons/Button.js'
import styles from './RequestAccessForm.module.sass'
import _get from 'lodash/get'
import {
  Typography,
  Grid,
  Box,
  TextField,
  styled,
  Autocomplete
} from '@mui/material'

import { SessionContext } from 'src/modules/Session/context'
import ReCAPTCHA from 'react-google-recaptcha'

const InputFormText = styled(TextField, {
  name: 'Form Text',
  label: 'form'
})(() => ({
  '& .MuiInputLabel-root': {
    fontWeight: 600
  },
  width: '100%'
}))

const FormText = ({
  input,
  label,
  meta: { touched, error },
  readOnly,
  ...custom
}) => (
  <InputFormText
    label={label}
    {...input}
    {...custom}
    autoComplete="off"
    error={touched && error}
    helperText={touched && error}
    InputProps={{
      readOnly: readOnly
    }}
  />
)

FormText.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  meta: PropTypes.object,
  readOnly: PropTypes.bool
}

const renderAutocompleteField = ({
  input,
  label,
  meta: { touched, error },
  children,
  readOnly,
  ...custom
}) => {
  return (
    <Autocomplete
      {...input}
      onChange={(event, valueObj) => input.onChange(valueObj?.value)}
      renderInput={params => (
        <InputFormText
          {...params}
          label={label}
          variant="standard"
          error={touched && error}
          helperText={touched && error}
          InputProps={{
            readOnly: readOnly
          }}
        />
      )}
      {...custom}
    >
      {children}
    </Autocomplete>
  )
}

const RequestAccessForm = ({
  handleSubmit,
  busy,
  change,
  catalogs = {},
  traditional = false,
  buttonLabel = 'Submit',
  initialValues,
  requestUserRegistration = () => {},
  requestConsultantContact = () => {},
  onSuccess = () => {},
  disableConsultantContact = false,
  withSubmitTracking = () => {}
}) => {
  const jobFunctionOptions = _get(catalogs, 'jobFunctions.options', {})
  const session = useContext(SessionContext)
  const therapyOptions = _get(catalogs, 'therapy.options', {})
  const countryOptions = _get(catalogs, 'country.options', {})

  const recaptchaRef = useRef()
  const handleRecaptcha = value => {
    change('cap', value)
  }

  const handleSubmitWithRecaptcha = async values => {
    const recaptchaValue = await recaptchaRef.current.executeAsync()
    handleRecaptcha(recaptchaValue)
    const value = { cap: recaptchaValue }
    recaptchaRef.current.reset()
    if (!disableConsultantContact) {
      requestConsultantContact({ ...values })
    }
    if (!session.checked || !session.authenticated) {
      requestUserRegistration({ ...values, ...value })
    }
    if (onSuccess) {
      onSuccess()
    }
    if (withSubmitTracking) withSubmitTracking()
  }

  return (
    <form onSubmit={handleSubmit(handleSubmitWithRecaptcha)}>
      <Box
        sx={{
          maxHeight: 'calc(100vh - 200px)',
          overflowY: 'auto',
          padding: '16px',
          '@media (max-width: 600px)': {
            maxHeight: 'calc(100vh - 250px)'
          }
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Field
              component={FormText}
              label="First Name"
              name="first_name"
              variant="standard"
              errorAlign="center"
              readOnly={Boolean(initialValues.first_name)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              component={FormText}
              label="Last Name"
              name="last_name"
              variant="standard"
              errorAlign="center"
              readOnly={Boolean(initialValues.last_name)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              component={FormText}
              label="Work Email"
              variant="standard"
              name="email"
              errorAlign="center"
              type="email"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              name="country"
              component={renderAutocompleteField}
              label="Country"
              autoComplete="disable"
              errorAlign="center"
              options={Object.entries(countryOptions).map(([key, value]) => ({
                label: key,
                value: value
              }))}
              readOnly={Boolean(initialValues.country)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              component={FormText}
              label="Company"
              name="company"
              errorAlign="center"
              variant="standard"
              readOnly={Boolean(initialValues.company)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              component={FormText}
              label="Job Title"
              variant="standard"
              name="job_title"
              errorAlign="center"
              readOnly={Boolean(initialValues.job_title)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              name="job_function"
              component={renderAutocompleteField}
              label="Job Function"
              errorAlign="center"
              autoComplete="disable"
              options={Object.entries(jobFunctionOptions).map(
                ([key, value]) => ({
                  label: key,
                  value: value
                })
              )}
              readOnly={Boolean(initialValues.job_function)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              component={FormText}
              variant="standard"
              label="Phone Number"
              name="phone_number"
              errorAlign="center"
              traditional={traditional}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              component={renderAutocompleteField}
              label="What therapy area do you work in?"
              variant="standard"
              name="therapy_area"
              errorAlign="center"
              autoComplete="disable"
              options={Object.entries(therapyOptions).map(([key, value]) => ({
                label: key,
                value: value
              }))}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              component={FormText}
              label="What products are you responsible for?"
              name="products"
              variant="standard"
              errorAlign="center"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Field
              component={FormText}
              label="Comments"
              name="comments"
              errorAlign="center"
              rows={8}
              multiline
            />
          </Grid>
        </Grid>
        <Field
          name="cap"
          component={() => null} // no need to render anything
        />

        <div className={styles.bottomContainer}>
          <Button
            className={styles.submitButton}
            type="submit"
            style={{
              backgroundColor: '#e27746',
              color: 'white',
              textTransform: 'none !important'
            }}
            disabled={busy}
          >
            {buttonLabel}
          </Button>
        </div>
        <Box>
          <Typography variant="body2" className={styles.termsText}>
            By creating a FirstWord account, you agree to our Terms of Service
            and Privacy Policy. You may also receive promotional emails from
            FirstWord. You can opt out at any time.
          </Typography>
        </Box>
        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
          onChange={handleRecaptcha}
        />
      </Box>
    </form>
  )
}

RequestAccessForm.propTypes = {
  handleSubmit: PropTypes.func,
  busy: PropTypes.bool,
  change: PropTypes.func,
  catalogs: PropTypes.object,
  traditional: PropTypes.bool,
  buttonLabel: PropTypes.string,
  requestUserRegistration: PropTypes.func,
  requestConsultantContact: PropTypes.func,
  onSuccess: PropTypes.func,
  initialValues: PropTypes.object,
  disableConsultantContact: PropTypes.bool,
  withSubmitTracking: PropTypes.func
}

export default RequestAccessForm
