import { createAction, handleActions } from 'redux-actions'
import asyncMeta from 'src/modules/AsyncFlags/metaCreator'

export const registerCatalogs = createAction('CATALOGS__REGISTER')
export const requestCatalogs = createAction(
  'CATALOGS__REQUEST',
  null,
  asyncMeta
)
export const successCatalogs = createAction(
  'CATALOGS__SUCCESS',
  null,
  asyncMeta
)
export const errorCatalogs = createAction('CATALOGS__ERROR', null, asyncMeta)
export const cleanCatalogs = createAction('CATALOGS__CLEAN')

export const actions = {
  registerCatalogs,
  requestCatalogs,
  successCatalogs,
  errorCatalogs,
  cleanCatalogs
}

const defaultState = {}

export default handleActions(
  {
    CATALOGS__REGISTER: (state, { payload: catalogs }) => {
      const newCatalogs = Object.keys(catalogs).reduce(
        (acc, key) => ({
          ...acc,
          [key]: {
            ready: false,
            error: null,
            options: {}
          }
        }),
        {}
      )
      return {
        ...state,
        ...newCatalogs
      }
    },
    CATALOGS__SUCCESS: (state, { payload: { key, catalog } }) => ({
      ...state,
      [key]: {
        ready: true,
        error: null,
        options: catalog
      }
    }),
    CATALOGS__ERROR: (state, { payload: { key, error } }) => ({
      ...state,
      [key]: {
        ready: true,
        error,
        options: {}
      }
    }),
    CATALOGS__CLEAN: (state, { payload: catalogs }) => {
      if (!catalogs) return defaultState
      return {
        ...state,
        ...Object.keys(catalogs).reduce(
          (acc, key) => ({
            ...acc,
            [key]: undefined
          }),
          {}
        )
      }
    }
  },
  defaultState
)
