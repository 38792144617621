import React from 'react'
import PropTypes from 'prop-types'
import Icon from 'src/components/ui/Icon/index.js'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import './table.css'
import cx from 'classnames'
import { formatColumns } from './../filters'

export default class Table extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: props.deal.sort((a, b) => {
        a = new Date(a['Announcement Date']).getTime()
        b = new Date(b['Announcement Date']).getTime()
        return b > a ? 1 : -1
      }),
      cols: formatColumns(props.columns, props.deal),
      expanded: {},
      searchInput: ''
    }
  }

  componentDidMount() {
    this.addTableObserver()
  }

  handleChange = event => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch()
    })
  }

  filteringByDate = date => {
    let filteredData = this.props.deal.filter(obj => {
      if (obj['Announcement Date'].indexOf(date) !== -1) {
        return obj
      }
    })
    this.setState({ data: filteredData })
  }

  globalSearch = () => {
    let { searchInput } = this.state
    let filteredData = this.props.deal.filter(obj => {
      for (const value of Object.values(obj)) {
        if (
          value.toString().toLowerCase().includes(searchInput.toLowerCase())
        ) {
          return obj
        }
      }
    })
    this.setState({ data: filteredData })
  }

  getFwTitle = value => value.split(';;')[0] || ''
  getFwUrl = value => value.split(';;')[1] || ''

  addTableObserver() {
    const observerTable = new MutationObserver(mutations => {
      if (mutations[0].target.className === 'rt-tr-group') {
        const row = mutations[0].target.firstChild
        row.classList.toggle('expandedRow')
      }
    })
    const table = document.querySelector('.rt-tbody')
    observerTable.observe(table, {
      childList: true,
      subtree: true
    })
  }

  render() {
    const { data, searchInput } = this.state
    const { styles } = this.props
    return (
      <div>
        <div className={styles.searchBarContainer}>
          <div className={styles.mobileSearchBar}>
            <TextField
              placeholder="Search"
              value={searchInput || ''}
              type="text"
              onChange={this.handleChange}
              autoFocus
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Icon
                      button
                      icon="svg/material-design-icons/action/search"
                      iconSize={24}
                      colorTier="action"
                    />
                  </InputAdornment>
                )
              }}
            />
          </div>
        </div>
        <ReactTable
          data={data}
          columns={this.state.cols}
          defaultPageSize={50}
          minRows={1}
          filterable
          defaultFilterMethod={(filter, row) =>
            String(`${row[filter.id]}`.toLowerCase()).includes(
              `${filter.value}`.toLowerCase()
            )
          }
          expanded={this.state.expanded}
          className="-striped -highlight"
          getTrProps={(state, rowInfo, column, instance, expanded) => {
            return {
              onClick: e => {
                const expanded = { ...this.state.expanded }
                expanded[rowInfo.viewIndex] = this.state.expanded[
                  rowInfo.viewIndex
                ]
                  ? false
                  : true
                this.setState({ expanded })
              }
            }
          }}
          SubComponent={row => {
            const k = Object.keys(row.original)
            const c = k.filter(c => {
              return this.props.columnsMap[c] === undefined
            })
            return (
              <div style={{ padding: '20px' }}>
                <div>
                  {c.map((c, i) => {
                    if (
                      c === 'FW Link1' ||
                      c === 'FW Link2' ||
                      c === 'FW Link3'
                    ) {
                      // eslint-disable-next-line array-callback-return
                      return
                    }
                    return (
                      <div key={i} className={cx(styles.detailWrapperItem)}>
                        <div className={cx(styles.detailWrapperItemLeft)}>
                          {c}
                        </div>
                        <div className={cx(styles.detailWrapperItemRight)}>
                          {Array.isArray(row.original[c]) ? (
                            row.original[c].map((item, i) => (
                              <p key={i}> {item.split(';;').join(', ')} </p>
                            ))
                          ) : (
                            <p> {row.original[c]} </p>
                          )}
                        </div>
                      </div>
                    )
                  })}
                  {row.original['FW Link1'] && (
                    <div className={cx(styles.detailWrapperItem)}>
                      <div className={cx(styles.detailWrapperItemLeft)}>
                        Related Articles
                      </div>
                      <div className={cx(styles.detailWrapperItemRightLinks)}>
                        {row.original.hasOwnProperty('FW Link1') && (
                          <a
                            href={this.getFwUrl(row.original['FW Link1'])}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {this.getFwTitle(row.original['FW Link1'])}
                          </a>
                        )}
                        {row.original.hasOwnProperty('FW Link2') && (
                          <a
                            href={this.getFwUrl(row.original['FW Link2'])}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {this.getFwTitle(row.original['FW Link2'])}
                          </a>
                        )}
                        {row.original.hasOwnProperty('FW Link3') && (
                          <a
                            href={this.getFwUrl(row.original['FW Link3'])}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {this.getFwTitle(row.original['FW Link3'])}
                          </a>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )
          }}
        />
      </div>
    )
  }
}

Table.propTypes = {
  deal: PropTypes.array,
  columns: PropTypes.any,
  styles: PropTypes.object,
  columnsMap: PropTypes.array
}
