import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import Typography from 'src/components/ui/Typography/index.js'
import commonStyles from 'src/styles/common.module.sass'
import styles from './SectionHeading.module.sass'
import cx from 'classnames'
import Tag from 'src/components/Tag/index.js'
import throttle from 'lodash/throttle'
import useComponentSize from '@rehooks/component-size'

const SectionHeading = ({ title, tag, className = '', ...other }) => {
  const ref = useRef(null)
  const throttledComponentSize = throttle(useComponentSize, 250)
  const size = throttledComponentSize(ref)
  const { width } = size
  const breakpoint = 420

  return (
    <header ref={ref} className={cx(commonStyles.header, className)}>
      <Typography
        className={
          width > breakpoint ? commonStyles.headline : commonStyles.title
        }
        type={width > breakpoint ? 'headline' : 'title'}
        {...other}
      >
        {title}
        {tag && <Tag className={styles.auxTag} label={tag} />}
      </Typography>
    </header>
  )
}

SectionHeading.propTypes = {
  title: PropTypes.any,
  tag: PropTypes.string,
  className: PropTypes.string
}

export default SectionHeading
