import React from 'react'
import PropTypes from 'prop-types'
import ReactHtmlParser from 'react-html-parser'
import FullImage from 'src/components/FullImage/index.js'
import SectionHeading from 'src/components/SectionHeading/index.js'
import styles from 'src/modules/GatedLandingPage/pages/Landing/Landing.module.sass'

const GeneralContent = ({ general, handleTracking = () => {} }) => {
  return (
    <>
      {general.topLogo && (
        <a
          href={general.topLogo.url}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.topLogo}
          onClick={() => handleTracking('page', 'click', 'client-logo_top')}
        >
          <FullImage
            src={general.topLogo.logoUrl}
            alt={`${general.topLogo.name} Logo`}
          />
        </a>
      )}
      {general.topDescription && (
        <div className={styles.subtitle}>
          <h2>{ReactHtmlParser(general.topDescription)}</h2>
        </div>
      )}
      {general.title && (
        <SectionHeading title={general.title} gutterBottom paragraph />
      )}
      {general.subtitle && (
        <div className={styles.subtitle}>
          <h2>{ReactHtmlParser(general.subtitle)}</h2>
        </div>
      )}
    </>
  )
}

GeneralContent.propTypes = {
  general: PropTypes.object,
  handleTracking: PropTypes.func
}

export default GeneralContent
