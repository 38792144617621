import { compose } from 'redux'
import { connect } from 'react-redux'
import * as flags from 'src/modules/AsyncFlags/selectors'
import { actions } from 'src/modules/MyNewsletters/reducer.js'
import MyNewsletters from 'src/modules/MyNewsletters/components/MyNewsletters'

const mapStateToProps = state => ({
  subscribed: state.myNewsletters.subscribed,
  newsletters: state.myNewsletters.newsletters,
  pendingForDeletion: state.myNewsletters.pendingForDeletion,
  pendingForInsertion: state.myNewsletters.pendingForInsertion,
  loadingNewsletters: flags.isBusy('ACCOUNT_LIST_NEWSLETTER')(state),
  loadingMyNewsletters: flags.isBusy('ACCOUNT_LIST_SUBSCRIBED_NEWSLETTER')(
    state
  ),
  newslettersConfig: state?.config?.my_account_settings?.data?.my_newsletters,
  region: state.myNewsletters.region.userRegion.data
})

const mapDispatchToProps = dispatch => ({
  requestListNewsletters: () => dispatch(actions.requestListNewsletters()),
  requestListMyNewsletters: () =>
    dispatch(actions.requestListSubscribedNewsletters()),
  requestDeleteNewsletter: newsletter =>
    dispatch(actions.requestDeleteNewsletter(newsletter)),
  requestInsertNewsletter: newsletter =>
    dispatch(actions.requestInsertNewsletter(newsletter)),
  requestRegion: data => dispatch(actions.requestGetRegionByCountry(data))
})

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  MyNewsletters
)
