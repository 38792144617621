import { takeLatest, put, call, select, all } from 'redux-saga/effects'
import { push, replace } from 'connected-react-router'
import { reauthAndRetryCall } from 'src/utils/sagaHelpers.js'
import { actions } from './reducer.js'
import { notificationsEnqueue } from 'src/modules/Notifications/reducer.js'
import { logPush } from 'src/modules/ContactUs/reducer.js'
import {
  setLastArticleId
  // paywallRedirectFlow
} from 'src/modules/Benefits/reducer.js'
import { logout } from 'src/modules/Session/actions'
import FW5MLError from 'src/services/FW5ML/FW5MLError.js'
import {
  notificationDuration as duration,
  ANON_MAX_ARTICLES,
  ANON_MAX_ARTICLES_EXPIRE_DAYS
} from 'src/constants/index.js'
import { getAnonArticleLimitSettings } from 'src/modules/Config/selectors'
import { actions as paywallActions } from 'src/modules/Paywall/slice.js'

import {
  NOT_ALLOWED,
  SERVER_ERROR,
  ACCESS_DENIED,
  ARTICLE_NOT_PUBLISHED
} from 'src/services/FW5ML/errorCodes.js'

import FW5Error, { ANON_ARTICLE_LIMIT_REACHED } from 'src/FW5Error.js'
import { siteName } from 'src/utils/oneCodeBase.js'

function* requestSingleStory(services, action) {
  const { payload: id } = action
  const Piwik = services('Piwik')
  yield call(trackSingleStory, services, id)
  try {
    yield call([Piwik, 'updateCustomVariable'], 'article_id', id)
    const searchApiValidation = yield call(searchApiFlow, services)
    if (searchApiValidation) {
      return
    }
    const StoriesRepository = services('StoriesRepository')
    const article = yield reauthAndRetryCall(
      services,
      [StoriesRepository, 'single'],
      id
    )
    if (article.type === 'Sponsored Story') {
      window.location.replace(article.url)
      return
    }
    const limitedArticle = yield call(limitAnonViews, services, action, article)

    yield call(notifyMeSubscribe, services, {})
    yield put(actions.successSingleStory(limitedArticle))
  } catch (e) {
    console.log(e)
    if (e instanceof FW5MLError) {
      const code = e.getCode()
      if (code !== ANON_ARTICLE_LIMIT_REACHED) {
        yield call(popAnonView, services, action)
      }
      if (code === NOT_ALLOWED) {
        yield put(
          paywallActions.updatePaywallVisible({
            visible: true,
            type: 'plus_only',
            blurArticle: true,
            subtype: 'plus_article'
          })
        )
      }
      if (code === ACCESS_DENIED) {
        yield put(
          paywallActions.updatePaywallVisible({
            visible: true,
            type: 'plus_only',
            blurArticle: true,
            subtype: 'plus_article'
          })
        )
      }
      if (code === ARTICLE_NOT_PUBLISHED) {
        yield put(
          notificationsEnqueue({
            message: 'The article you are trying to access is not published.'
          })
        )
        return
      }
      if (code === SERVER_ERROR) {
        yield put(
          logPush(
            'Single Story',
            `Error while loading article ${id}: ${e.message}`
          )
        )
        yield put(
          notificationsEnqueue({
            message:
              'Unable to load article. Please refresh the page and try again.'
          })
        )
        yield call(
          [Piwik, 'track'],
          'notification',
          'error',
          'could-not-request-single-story'
        )
        yield put(push('/contact-us'))
      }
    }
    if (e instanceof FW5Error) {
      const code = e.getCode()
      if (code === ANON_ARTICLE_LIMIT_REACHED) {
        yield put(setLastArticleId(id))
        paywallActions.updatePaywallVisible({
          visible: true,
          type: 'free_only',
          blurArticle: true,
          subtype: 'article_limit'
        })
      }
    }
    yield put(actions.errorSingleStory(e.message))
  }
}

function* limitAnonViews(services, action, article) {
  const session = yield select(store => store.session)
  const { checked: sessionReady, authenticated: userIsAuth } = session
  if (sessionReady && !userIsAuth) {
    const Session = services('Session')
    let viewedArticles = yield call([Session, 'getCookie'], 'viewedArticles')
    if (!viewedArticles) {
      viewedArticles = {
        articles: [],
        created: Date.now()
      }
    }
    const { articles, created } = viewedArticles

    const anonArticleLimitSettings = yield select(getAnonArticleLimitSettings())
    const {
      anonArticleLimit = ANON_MAX_ARTICLES,
      anonArticleLimitExpiry = ANON_MAX_ARTICLES_EXPIRE_DAYS
    } = anonArticleLimitSettings

    if (articles.length + 1 > anonArticleLimit) {
      yield put(
        paywallActions.updatePaywallVisible({
          visible: true,
          type: 'free_only',
          blurArticle: true,
          subtype: 'article_limit'
        })
      )
      article.body = article.teaser
      return article
    }

    if (articles.length + 1 == anonArticleLimit) {
      yield put(
        paywallActions.updatePaywallVisible({
          visible: true,
          type: 'free_only',
          subtype: 'article_counter'
        })
      )
    }

    if (articles.length + 1 < anonArticleLimit) {
      yield put(
        paywallActions.updatePaywallVisible({
          visible: true,
          type: 'free_only',
          subtype: 'article_counter'
        })
      )
    }

    const { payload: id } = action
    const updatedArticleArray = [...articles, id]
    const updatedArticles = {
      articles: [...new Set(updatedArticleArray)],
      created
    }

    yield call([Session, 'setCookie'], 'viewedArticles', updatedArticles, {
      expires: anonArticleLimitExpiry
    })
  }

  return article
}

/* The above code is a generator function called `searchApiFlow` that takes in a `services` parameter.*/
function* searchApiFlow(services) {
  try {
    const Session = services('Session')
    const user = yield call([Session, 'userLoad'])
    const queryString = services('queryString')
    const location = yield select(state => state.router.location)
    const { pathname } = location

    const search_api_query = queryString.get('sapi')
    if (search_api_query) {
      let searchApiCookie = yield call(
        [Session, 'getCookie'],
        'search_api_cookie'
      )
      if (!user) {
        // set cookie
        yield call([Session, 'setCookie'], 'search_api_cookie', pathname)
        // redirect
        yield put(replace('/sign-in'))
        return true
      } else if (searchApiCookie) {
        yield call([Session, 'removeCookie'], 'search_api_cookie')
      }
      return false
    }
  } catch (e) {
    console.log(e)
  }
}

function* popAnonView(services, action) {
  const { payload: id } = action
  const Session = services('Session')
  let viewedArticles = yield call([Session, 'getCookie'], 'viewedArticles')
  if (!viewedArticles) {
    viewedArticles = {
      articles: [],
      created: Date.now()
    }
  }
  const { articles, created } = viewedArticles
  const updatedArticles = {
    created,
    articles: articles.filter(article => article !== id)
  }
  yield call([Session, 'setCookie'], 'viewedArticles', updatedArticles)
}

function* trackSingleStory(services, id) {
  const queryString = services('queryString')
  const Piwik = services('Piwik')
  yield call([Piwik, 'updateCustomVariable'], 'article_id', id)
  const nlEventId = queryString.get('nle')
  const nlCampaignId = queryString.get('nlc')
  const nlPosition = queryString.get('nlp')
  // the tracking action by default is the ID of the NL
  let nlAction = id
  if (nlEventId) {
    let newQS = queryString.removeKey('nle')
    yield call([Piwik, 'updateCustomVariable'], 'event_id', nlEventId)
    if (nlCampaignId) {
      newQS = queryString.removeKey('nlc', newQS)
      yield call([Piwik, 'updateCustomVariable'], 'project_id', nlCampaignId)
    }
    if (nlPosition) {
      newQS = queryString.removeKey('nlp', newQS)
      if (siteName === 'Reports') {
        nlAction = nlPosition
      }
    }
    yield put(replace({ search: newQS }))
    yield call([Piwik, 'track'], 'newsletter', 'article-click', nlAction)
  }
  yield call([Piwik, 'track'], 'page', 'view', 'article')
}

function* trackFWRecentReport(services, action) {
  const { payload: id } = action
  const Piwik = services('Piwik')
  yield call(trackSingleStory, services, id)
  try {
    yield call([Piwik, 'updateCustomVariable'], 'article_id', id)
    yield put(
      notificationsEnqueue({
        message: 'Opening Report in a new tab.',
        duration
      })
    )
    const StoriesRepository = services('StoriesRepository')
    const article = yield reauthAndRetryCall(
      services,
      [StoriesRepository, 'single'],
      id
    )

    window.open(article.url, '_blank', 'noopener,noreferrer')
  } catch (e) {
    console.log(e)
    if (e instanceof FW5MLError) {
      const code = e.getCode()
      if (code !== ANON_ARTICLE_LIMIT_REACHED) {
        yield call(popAnonView, services, action)
      }
      if (code === NOT_ALLOWED) {
        yield put(setLastArticleId(id))
        yield put(
          paywallActions.updatePaywallVisible({
            visible: true,
            type: 'plus_only',
            blurArticle: true,
            subtype: 'plus_article'
          })
        )
      }
      if (code === ACCESS_DENIED) {
        yield put(logout({ reload: true }))
        return
      }
      if (code === SERVER_ERROR) {
        yield put(
          logPush(
            'Single Story',
            `Error while loading article ${id}: ${e.message}`
          )
        )
        yield put(
          notificationsEnqueue({
            message:
              'Unable to load article. Please refresh the page and try again.'
          })
        )
        yield call(
          [Piwik, 'track'],
          'notification',
          'error',
          'could-not-request-single-story'
        )
        yield put(push('/contact-us'))
      }
    }
    if (e instanceof FW5Error) {
      const code = e.getCode()
      if (code === ANON_ARTICLE_LIMIT_REACHED) {
        // yield put(setLastArticleId(id))
        // yield put(paywallRedirectFlow('replace'))
      }
    }
    yield put(actions.errorSingleStory(e.message))
  }
}

function* notifyMeResultsRequest(services, action) {
  const StoriesRepository = services('StoriesRepository')
  const { payload } = action
  const { resultType, article } = payload
  try {
    let story = null

    if (article?.id && article?.title && resultType) {
      const searchConfig = {
        source: article,
        type: [resultType]
      }

      story = yield call(
        [StoriesRepository, 'searchNotifyMeResults'],
        searchConfig
      )
    }

    yield put(actions.successNotifyMeResults({ story }))
  } catch (e) {
    console.log(e)
    yield put(actions.errorNotifyMeResults(e.message))
  }
}

function* notifyMeSubscribeRequest(services, action) {
  const Session = services('Session')
  const user = yield call([Session, 'userLoad'])
  const { payload } = action
  const { newsletterUuid, articleId } = payload
  const cookie = `notify-me-subscribe|${articleId}|${newsletterUuid}`

  if (!user) {
    yield call([Session, 'setCookie'], 'current_flow', cookie)
  }
  yield call(notifyMeSubscribe, services, { payload: { cookie } })
}

function* notifyMeSubscribe(services, action) {
  const Session = services('Session')
  const Piwik = services('Piwik')
  const UserRepository = services('UserRepository')
  const { payload = {} } = action || {}

  let prevCookie = payload?.cookie

  if (!prevCookie) {
    prevCookie = yield call([Session, 'getCookie'], 'current_flow')
  }

  const user = yield call([Session, 'userLoad'])

  if (!user || !prevCookie?.includes('notify-me-subscribe')) return

  yield call([Session, 'removeCookie'], 'current_flow')

  const cookieSplit = prevCookie.split('|')
  const articleId = cookieSplit[1]
  const newsletterUuid = cookieSplit[2]
  let { notifyMe = [] } = user

  try {
    if (!articleId) {
      throw new Error('No article id defined.')
    }

    if (!newsletterUuid) {
      throw new Error('No newsletter defined.')
    }

    if (
      notifyMe?.find(item => item?.preview_article_ids?.includes(articleId))
    ) {
      yield put(
        notificationsEnqueue({
          message:
            'It appears that you are already subscribed to be notified of the results.'
        })
      )
      return
    }

    // ML call
    yield call([UserRepository, 'notifyMeSubscribe'], newsletterUuid, articleId)
    yield put(
      notificationsEnqueue({
        message:
          'Thank you. We will notify you via email when the results are published'
      })
    )

    // Update user info for notifyMe
    const currentItemIndex = notifyMe.findIndex(
      item => item.newsletter_uuid === newsletterUuid
    )
    if (currentItemIndex >= 0) {
      const { preview_article_ids } = notifyMe[currentItemIndex]
      notifyMe[currentItemIndex].preview_article_ids = [
        ...preview_article_ids,
        articleId
      ]
    } else {
      notifyMe = [
        ...notifyMe,
        { newsletter_uuid: newsletterUuid, preview_article_ids: [articleId] }
      ]
    }

    yield call([Session, 'userUpdate'], {
      notifyMe
    })
  } catch (e) {
    yield put(
      notificationsEnqueue({
        message:
          'Unable to subscribe to be notified of the results. Please refresh the page and try again.'
      })
    )
    yield call(
      [Piwik, 'track'],
      'notification',
      'error',
      `could-not-update-${articleId}-notify-me`
    )
  }
}

function* simpleNotifyMeSubscribeRequest(services, { payload }) {
  const Session = services('Session')
  const Piwik = services('Piwik')
  const UserRepository = services('UserRepository')
  const { newsletterUuid, articleId } = payload

  const user = yield call([Session, 'userLoad'])

  if (!user || !articleId || !newsletterUuid) {
    throw new Error('Missing payload to be notified.')
  }

  let { notifyMe = [] } = user

  try {
    // ML call
    yield call([UserRepository, 'notifyMeSubscribe'], newsletterUuid, articleId)

    // Update user info for notifyMe
    const currentItemIndex = notifyMe.findIndex(
      item => item?.newsletter_uuid === newsletterUuid
    )
    if (currentItemIndex >= 0) {
      const { preview_article_ids } = notifyMe[currentItemIndex]
      let updatedArticleIds = []

      if (preview_article_ids?.includes(articleId)) {
        updatedArticleIds = preview_article_ids.filter(id => id !== articleId)
      } else {
        updatedArticleIds = [...preview_article_ids, articleId]
      }

      notifyMe[currentItemIndex].preview_article_ids = updatedArticleIds
    } else {
      notifyMe = [
        ...notifyMe,
        { newsletter_uuid: newsletterUuid, preview_article_ids: [articleId] }
      ]
    }

    yield call([Session, 'userUpdate'], {
      notifyMe
    })
  } catch (e) {
    yield put(
      notificationsEnqueue({
        message:
          'Unable to subscribe to be notified. Please refresh the page and try again.'
      })
    )
    yield call(
      [Piwik, 'track'],
      'notification',
      'error',
      `could-not-update-${articleId}-notify-me`
    )
  }
}

export default function* watchSingleStory(services) {
  yield all([
    takeLatest('SINGLE_STORY__REQUEST', requestSingleStory, services),
    takeLatest('FW_REPORT_TRACKING__REQUEST', trackFWRecentReport, services),
    takeLatest('NOTIFY_ME_RESULTS__REQUEST', notifyMeResultsRequest, services),
    takeLatest(
      'SIMPLE_NOTIFY_ME_RESULTS_SUBSCRIBE__REQUEST',
      simpleNotifyMeSubscribeRequest,
      services
    ),
    takeLatest(
      'NOTIFY_ME_RESULTS_SUBSCRIBE__REQUEST',
      notifyMeSubscribeRequest,
      services
    )
  ])
}
