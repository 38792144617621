import _get from 'lodash/get'
import dayjs from 'dayjs'
import axios from 'axios'
import queryString from 'src/services/queryString/index.js'
import CryptoJS from 'crypto-js'

export * from './utils.js'

export * from './php-unserialize.js'
export const any = (arr, fn) => {
  let idx = -1
  while (++idx < arr.length) {
    if (fn(arr[idx], idx, arr)) {
      return true
    }
  }
  return false
}

export const delay =
  time =>
  (...args) =>
    new Promise(resolve => setTimeout(() => resolve(...args), time))

export const fromNow = timestamp => {
  const now = Math.round(Date.now() / 1000)
  const delta = now - timestamp
  let interval = Math.floor(delta / 31536000)
  const pluralize = i => (i === 1 ? '' : 's')
  //One week or more label
  interval = Math.floor(delta / 604800)
  if (interval >= 1) {
    return dayjs.unix(timestamp).format('MMMM DD, YYYY')
  }
  //Two days or more label
  interval = Math.floor(delta / 86400)
  if (interval >= 2) {
    return `${interval} day${pluralize(interval)} ago`
  }
  //Hours label
  interval = Math.floor(delta / 3600)
  if (interval >= 1) {
    return `${interval} hour${pluralize(interval)} ago`
  }
  //Minute label
  interval = Math.floor(delta / 60)
  if (interval >= 1) {
    return `${interval} minute${pluralize(interval)} ago`
  }
  //Seconds label
  return `${delta} second${pluralize(interval)} ago`
}

export const generateUid = () => Math.random().toString(36).substr(2, 9)

export const strToBool = val => {
  if (val.toUpperCase() === 'FALSE') return false
  if (val.toUpperCase() === 'TRUE') return true
  return undefined
}

export const getCurrentYear = () => new Date().getFullYear()

export const arrToObj = arr =>
  arr.reduce((acc, el) => ({ ...acc, [el]: el }), {})

export const insertEveryN =
  (n = 1, cb) =>
  (acc, cur, index, arr) => {
    const instance = Math.floor(index / n)
    if ((index + 1) % n !== 0) return [...acc, cur]
    return [...acc, cur, cb(cur, index, instance)]
  }

export const treeToArray = (tree, fn) =>
  Object.keys(tree).reduce(
    (acc, key) => [...acc, ...tree[key].map(value => fn(key, value))],
    []
  )

export const stringHash = source => {
  let hash = 0
  if (source.length === 0) {
    return hash
  }
  for (let i = 0; i < source.length; i++) {
    const char = source.charCodeAt(i)
    hash = (hash << 5) - hash + char
    hash = hash & hash
  }
  return hash
}

export const switchBySiteId = (choices, defaultValue) => {
  const siteId = process.env.REACT_APP_SITE_ID
  return _get(choices, siteId, defaultValue)
}

export const getVerboseRiverNames = name => {
  const verboseNames = {
    products: 'product news & analysis',
    companies: 'company news & analysis',
    'regulatory-markets-and-agencies': 'regulatory news & analysis',
    conditions: 'condition news & analysis',
    business: 'business area news & analysis'
  }

  return verboseNames[name] || name
}

// All countries
// length 252
export const allCountries = {
  af: 'Afghanistan',
  ax: 'Aland Islands',
  al: 'Albania',
  dz: 'Algeria',
  as: 'American Samoa',
  ad: 'Andorra',
  ao: 'Angola',
  ai: 'Anguilla',
  aq: 'Antarctica',
  ag: 'Antigua and Barbuda',
  ar: 'Argentina',
  am: 'Armenia',
  aw: 'Aruba',
  au: 'Australia',
  at: 'Austria',
  az: 'Azerbaijan',
  bs: 'Bahamas',
  bh: 'Bahrain',
  bd: 'Bangladesh',
  bb: 'Barbados',
  by: 'Belarus',
  be: 'Belgium',
  bz: 'Belize',
  bj: 'Benin',
  bm: 'Bermuda',
  bt: 'Bhutan',
  bo: 'Bolivia',
  bq: 'Bonaire, Sint Eustatius and Saba',
  ba: 'Bosnia and Herzegovina',
  bw: 'Botswana',
  bv: 'Bouvet Island',
  br: 'Brazil',
  io: 'British Indian Ocean Territory',
  bn: 'Brunei Darussalam',
  bg: 'Bulgaria',
  bf: 'Burkina Faso',
  bi: 'Burundi',
  kh: 'Cambodia',
  cm: 'Cameroon',
  ca: 'Canada',
  cv: 'Cape Verde',
  ky: 'Cayman Islands',
  cf: 'Central African Republic',
  td: 'Chad',
  cl: 'Chile',
  cn: 'China',
  cx: 'Christmas Island',
  cc: 'Cocos (Keeling) Islands',
  co: 'Colombia',
  km: 'Comoros',
  cg: 'Congo',
  cd: 'Congo, the Democratic Republic of the',
  ck: 'Cook Islands',
  cr: 'Costa Rica',
  ci: "Cote D'Ivoire",
  hr: 'Croatia',
  cu: 'Cuba',
  cw: 'Curacao',
  cy: 'Cyprus',
  cz: 'Czech Republic',
  dk: 'Denmark',
  dj: 'Djibouti',
  dm: 'Dominica',
  do: 'Dominican Republic',
  ec: 'Ecuador',
  eg: 'Egypt',
  sv: 'El Salvador',
  gq: 'Equatorial Guinea',
  er: 'Eritrea',
  ee: 'Estonia',
  et: 'Ethiopia',
  fk: 'Falkland Islands (Malvinas)',
  fo: 'Faroe Islands',
  fj: 'Fiji',
  fi: 'Finland',
  fr: 'France',
  gf: 'French Guiana',
  pf: 'French Polynesia',
  tf: 'French Southern Territories',
  ga: 'Gabon',
  gm: 'Gambia',
  ge: 'Georgia',
  de: 'Germany',
  gh: 'Ghana',
  gi: 'Gibraltar',
  gr: 'Greece',
  gl: 'Greenland',
  gd: 'Grenada',
  gp: 'Guadeloupe',
  gu: 'Guam',
  gt: 'Guatemala',
  gg: 'Guernsey',
  gn: 'Guinea',
  gw: 'Guinea-Bissau',
  gy: 'Guyana',
  ht: 'Haiti',
  hm: 'Heard Island and Mcdonald Islands',
  va: 'Holy See (Vatican City State)',
  hn: 'Honduras',
  hk: 'Hong Kong',
  hu: 'Hungary',
  is: 'Iceland',
  in: 'India',
  id: 'Indonesia',
  ir: 'Iran, Islamic Republic of',
  iq: 'Iraq',
  ie: 'Ireland',
  im: 'Isle of Man',
  il: 'Israel',
  it: 'Italy',
  jm: 'Jamaica',
  jp: 'Japan',
  je: 'Jersey',
  jo: 'Jordan',
  kz: 'Kazakhstan',
  ke: 'Kenya',
  ki: 'Kiribati',
  kp: "Korea, Democratic People's Republic of",
  kr: 'Korea, Republic of',
  xk: 'Kosovo',
  kw: 'Kuwait',
  kg: 'Kyrgyzstan',
  la: "Lao People's Democratic Republic",
  lv: 'Latvia',
  lb: 'Lebanon',
  ls: 'Lesotho',
  lr: 'Liberia',
  ly: 'Libyan Arab Jamahiriya',
  li: 'Liechtenstein',
  lt: 'Lithuania',
  lu: 'Luxembourg',
  mo: 'Macao',
  mk: 'Macedonia, the Former Yugoslav Republic of',
  mg: 'Madagascar',
  mw: 'Malawi',
  my: 'Malaysia',
  mv: 'Maldives',
  ml: 'Mali',
  mt: 'Malta',
  mh: 'Marshall Islands',
  mq: 'Martinique',
  mr: 'Mauritania',
  mu: 'Mauritius',
  yt: 'Mayotte',
  mx: 'Mexico',
  fm: 'Micronesia, Federated States of',
  md: 'Moldova, Republic of',
  mc: 'Monaco',
  mn: 'Mongolia',
  me: 'Montenegro',
  ms: 'Montserrat',
  ma: 'Morocco',
  mz: 'Mozambique',
  mm: 'Myanmar',
  na: 'Namibia',
  nr: 'Nauru',
  np: 'Nepal',
  nl: 'Netherlands',
  an: 'Netherlands Antilles',
  nc: 'New Caledonia',
  nz: 'New Zealand',
  ni: 'Nicaragua',
  ne: 'Niger',
  ng: 'Nigeria',
  nu: 'Niue',
  nf: 'Norfolk Island',
  mp: 'Northern Mariana Islands',
  no: 'Norway',
  om: 'Oman',
  pk: 'Pakistan',
  pw: 'Palau',
  ps: 'Palestinian Territory, Occupied',
  pa: 'Panama',
  pg: 'Papua New Guinea',
  py: 'Paraguay',
  pe: 'Peru',
  ph: 'Philippines',
  pn: 'Pitcairn',
  pl: 'Poland',
  pt: 'Portugal',
  pr: 'Puerto Rico',
  qa: 'Qatar',
  re: 'Reunion',
  ro: 'Romania',
  ru: 'Russian Federation',
  rw: 'Rwanda',
  bl: 'Saint Barthelemy',
  sh: 'Saint Helena',
  kn: 'Saint Kitts and Nevis',
  lc: 'Saint Lucia',
  mf: 'Saint Martin',
  pm: 'Saint Pierre and Miquelon',
  vc: 'Saint Vincent and the Grenadines',
  ws: 'Samoa',
  sm: 'San Marino',
  st: 'Sao Tome and Principe',
  sa: 'Saudi Arabia',
  sn: 'Senegal',
  rs: 'Serbia',
  cs: 'Serbia and Montenegro',
  sc: 'Seychelles',
  sl: 'Sierra Leone',
  sg: 'Singapore',
  sx: 'Sint Maarten',
  sk: 'Slovakia',
  si: 'Slovenia',
  sb: 'Solomon Islands',
  so: 'Somalia',
  za: 'South Africa',
  gs: 'South Georgia and the South Sandwich Islands',
  ss: 'South Sudan',
  es: 'Spain',
  lk: 'Sri Lanka',
  sd: 'Sudan',
  sr: 'Suriname',
  sj: 'Svalbard and Jan Mayen',
  sz: 'Swaziland',
  se: 'Sweden',
  ch: 'Switzerland',
  sy: 'Syrian Arab Republic',
  tw: 'Taiwan, Province of China',
  tj: 'Tajikistan',
  tz: 'Tanzania, United Republic of',
  th: 'Thailand',
  tl: 'Timor-Leste',
  tg: 'Togo',
  tk: 'Tokelau',
  to: 'Tonga',
  tt: 'Trinidad and Tobago',
  tn: 'Tunisia',
  tr: 'Turkey',
  tm: 'Turkmenistan',
  tc: 'Turks and Caicos Islands',
  tv: 'Tuvalu',
  ug: 'Uganda',
  ua: 'Ukraine',
  ae: 'United Arab Emirates',
  gb: 'United Kingdom',
  us: 'United States',
  um: 'United States Minor Outlying Islands',
  uy: 'Uruguay',
  uz: 'Uzbekistan',
  vu: 'Vanuatu',
  ve: 'Venezuela',
  vn: 'Viet Nam',
  vg: 'Virgin Islands, British',
  vi: 'Virgin Islands, U.s.',
  wf: 'Wallis and Futuna',
  eh: 'Western Sahara',
  ye: 'Yemen',
  zm: 'Zambia',
  zw: 'Zimbabwe'
}

export const getMonths = () => {
  const months = {}
  for (let month = 1; month < 12; month++) {
    months[month] = month.toString()
  }
  return months
}

export const getNextYears = count => {
  const years = {}
  const currentYear = getCurrentYear()
  const currentYearEnd = currentYear + count

  for (let year = getCurrentYear(); year < currentYearEnd; year++) {
    years[year] = year.toString()
  }

  return years
}

export const monetaryMap = {
  usd: '$'
}

export const getImage = (type, imagery, defaultType = null) => {
  //Return an image from the set
  if (imagery[type]) return imagery[type]
  //Return a default image from the set
  if (defaultType) return imagery[defaultType]
  return null
}

export const parseStringToHtml = str => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(str, 'text/html')
  return doc.body
}

export const getHTMLTag = (html, tag) => {
  const element = html?.getElementsByTagName(tag)
  return element ? element[0] : null
}

export const getAxiosRequest = async url => {
  const response = await axios.get(url)
  return response.data
}

export const getURLSortProps = (
  defaultOption,
  defaultValue,
  secondOption,
  secondValue
) => {
  const sortQuery = queryString().get('sort')
  const sortUrlParam =
    sortQuery && [defaultOption, secondOption].includes(sortQuery)
      ? sortQuery
      : defaultOption
  const sort =
    sortUrlParam && sortUrlParam === secondOption ? secondValue : defaultValue
  return { sort, sortUrlParam }
}

export const historyHandler = (pathStr, location, history, method) => {
  if (pathStr === location.pathname) history.go(0)

  if (method === 'replace') {
    history.replace(pathStr)
    return
  }

  history.push(pathStr)
}

export const random = () => {
  const chars = '0123456789'
  let rand = ''
  for (let i = 0; i < 10; i++) {
    rand += chars.charAt(Math.random() * (chars.length - 1))
  }
  return rand
}

// Code shared from phoenix team for viewability
export const handleAxiosGetRequest = async url => {
  let axiosOptions = {
    method: 'get',
    url
  }
  await axios(axiosOptions).catch(e => {
    throw new Error(e)
  })
}

export const arrayify = val => {
  return Array.isArray(val) ? val : [val]
}

export const checkIfArray = val => {
  return val !== null && Array.isArray(val)
}

export const getObjectByProperty = (
  options,
  property,
  defaultOption = null
) => {
  try {
    const foundOption = options[property]
    if (foundOption) return foundOption
    if (defaultOption) return options[defaultOption]
    return null
  } catch (e) {
    console.log(e)
    return null
  }
}

export const extractDomainFromEmail = email => {
  try {
    const regex = /@([a-zA-Z0-9.-]+)$/
    const match = email.match(regex)
    return match[1]
  } catch (e) {
    console.log(e)
    return null
  }
}

// Function to calculate SHA-256 hash
export const calculateSHA256 = input => {
  const hash = CryptoJS.SHA256(input)
  return hash.toString(CryptoJS.enc.Hex)
}
