import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Button from 'src/components/ui/Buttons/Button.js'
import Typography from 'src/components/ui/Typography/index.js'
import { filterLabelsConfigModule } from 'src/siteConfig/river.js'
import styles from './RiverSubHeader.module.sass'

import useFilters from 'src/modules/River/hooks/useFilters'

const { getFilterLabel } = filterLabelsConfigModule

const SearchTerm = ({ filters, query }) => {
  let term = query
  if (!query && filters.length > 0) {
    term = filters[0].value
  }
  const segmentedTerm = term.match(/^(\w+)\/(.*)$/)
  return (
    term && (
      <Fragment>
        for <b>{`${segmentedTerm ? segmentedTerm[2] : term}`}</b>
      </Fragment>
    )
  )
}

SearchTerm.propTypes = {
  query: PropTypes.string,
  filters: PropTypes.array
}

const formatTotal = total => {
  let returnTotal = ''

  for (let index = `${total}`.length - 1; index >= 0; index--) {
    if ((returnTotal.length + 1) % 4 === 0 && returnTotal.length > 0) {
      returnTotal = `${total}`[index].toString() + ',' + returnTotal
    } else {
      returnTotal = `${total}`[index].toString() + returnTotal
    }
  }
  return returnTotal
}

const RiverSubHeader = ({
  total = 0,
  query: actualQuery = '',
  filters: actualFilters = [],
  excludeFilterValues = [],
  onFilterClick = () => () => {},
  viewCount = true,
  hide = false
}) => {
  const { removeSelectedFilter } = useFilters()
  const handleFilterClick = filter => {
    onFilterClick(filter)()
    removeSelectedFilter(filter, filter.field)
  }
  let query = actualQuery
  let filters = actualFilters
  // interest rivers, which are actually filters, must display brief in the same way as a search query - not a filter
  if (viewCount && !actualQuery && actualFilters.length > 1) {
    query = filters.slice(0, 1)[0].value
    filters = filters.slice(1)
  }
  const displayFilters =
    filters &&
    filters.filter(filter => !excludeFilterValues.includes(filter.value))
      .length >= 1
  return (
    !hide && (
      <div className={styles.container}>
        {viewCount && (
          <Typography type="body1">
            {formatTotal(total)} news articles{' '}
            <SearchTerm filters={filters} query={query} />
            {displayFilters && ' filtered by:'}
          </Typography>
        )}
        {displayFilters &&
          filters
            .filter(filter => !excludeFilterValues.includes(filter.value))
            .map(filter => (
              <Button
                key={`${filter.field}-${filter.value}`}
                iconLeft="svg/material-design-icons/navigation/close"
                minWidth="0"
                raised={false}
                text={getFilterLabel(filter.value)}
                onClick={() => handleFilterClick(filter)}
                className={styles.button}
              />
            ))}
      </div>
    )
  )
}

RiverSubHeader.propTypes = {
  total: PropTypes.number,
  query: PropTypes.string,
  filters: PropTypes.array,
  excludeFilterValues: PropTypes.array,
  onFilterClick: PropTypes.func,
  viewCount: PropTypes.bool,
  hide: PropTypes.bool
}

export default RiverSubHeader
