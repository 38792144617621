import React from 'react'
import DealsFlowComponent from './components/DealsDBComponent'

import { deal1 } from './s3/dealsflow/data'
import {
  columns,
  columnsMap,
  mobileColumns,
  columnsMobileMap
} from './s3/dealsflow/settings'

const Deals = () => (
  <DealsFlowComponent
    deal={deal1}
    text={`FirstWord DealFlow is a comprehensive life sciences focused database of deals, including M&A activity, 
    licensing transactions, and more to help you identify potential targets or identify potential investors. Quickly 
    sort and filter through data to find the deals and spot the emerging trends vital to you. In addition to providing 
    the details of each deal, FirstWord DealFlow provides you with access to unique deal-specific insights with related 
    news and analysis content, including physician intelligence (coming soon).  Oncology is the first therapy area to be 
    covered and more therapy areas will launch in the near future.`}
    columns={columns}
    columnsMap={columnsMap}
    mobileColumns={mobileColumns}
    columnsMobileMap={columnsMobileMap}
    notes={[
      "The database has been compiled from content published on FirstWord's news services since January 1, 2021 and will be updated on an ongoing basis.",
      'For deals that cover a number of assets, the lead asset is chosen. For deals that cover multiple therapeutic categories, only the relevant oncology information is included.',
      'Deal values are taken from available details, and are left blank if undisclosed. All values have been converted into $US using the value of the currencies on the date the deal was announced.'
    ]}
  />
)

export default Deals
