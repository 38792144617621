import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Switch, Route } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import ScrollToTop from 'src/components/ScrollToTop'
import routes, { getDynamicRoutes } from 'src/modules/routes'
import { createBrowserHistory } from 'history'
import MobileLeftMenu from 'src/modules/MobileMenu/containers/LeftMenuContainer'
import MobileMenu from 'src/modules/MobileMenu/containers/MenuContainer'
import PrestitialAd from 'src/components/PrestitialAd/PrestitialAdContainer.js'
import LoginRegisterModalPage from 'src/components/LoginRegisterModalPage/LoginRegisterModalPageContainer'
import { getConfig } from 'src/modules/Config/selectors'
import PaywallFlowModal from 'src/modules/Paywall'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { siteName } from 'src/utils/oneCodeBase'

export const history = createBrowserHistory()

const DefaultRoutes = ({ routes }) => {
  return (
    <ConnectedRouter history={history}>
      <ScrollToTop>
        <MobileMenu />
        <MobileLeftMenu />
        <PrestitialAd />
        <LoginRegisterModalPage />
        <PaywallFlowModal />
        <Switch>
          {routes.map((route, i) => (
            <Route key={`route-${route.path}-${i}`} {...route} />
          ))}
        </Switch>
      </ScrollToTop>
    </ConnectedRouter>
  )
}

DefaultRoutes.propTypes = {
  routes: PropTypes.array
}

const ReportsFeatureFlagRouter = () => {
  // Config Selectors
  const configBootstrapped =
    useSelector(state => state?.config?.bootstrapped) || false

  const featureFlagLaunchDarkly =
    useSelector(getConfig('feature_flag_ldk')) || null

  const pagesConfig = useSelector(state => state?.config?.pages?.data) || []

  // Routes set up
  const dynamicRoutes = getDynamicRoutes(pagesConfig)
  const finalRoutes = [...dynamicRoutes, ...routes]

  // Feature flag related variables
  const flags = useFlags()
  const [validReportsFlag, setValidReportsFlag] = useState(false)
  const featureFlag = flags?.newFwReportPlatform

  useEffect(() => {
    if (!featureFlagLaunchDarkly?.initialized) return

    // Feature flag is set as false - no access
    if (featureFlag === false && process.env.NODE_ENV !== 'development') {
      window.location.href = 'https://firstwordreports.com/'
      return
    }

    setValidReportsFlag(true)
  }, [featureFlagLaunchDarkly?.initialized])

  if (!configBootstrapped || !validReportsFlag) {
    return <></>
  }

  return <DefaultRoutes routes={finalRoutes} />
}

const AppRouter = () => {
  // Config Selectors
  const configBootstrapped =
    useSelector(state => state?.config?.bootstrapped) || false

  const featureFlagLaunchDarkly =
    useSelector(getConfig('feature_flag_ldk')) || null

  const pagesConfig = useSelector(state => state?.config?.pages?.data) || []

  // Routes set up
  const dynamicRoutes = getDynamicRoutes(pagesConfig)
  const finalRoutes = [...dynamicRoutes, ...routes]

  if (!featureFlagLaunchDarkly?.initialized || !configBootstrapped) {
    return <></>
  }

  return <DefaultRoutes routes={finalRoutes} />
}

const Router = () => {
  if (siteName === 'Reports') {
    return <ReportsFeatureFlagRouter />
  }
  return <AppRouter />
}

export default Router
