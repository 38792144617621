import { useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import usePiwik from 'src/hooks/usePiwik'

import { styled } from '@mui/material/styles'
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Divider,
  IconButton,
  Typography
} from '@mui/material'
import { CaratUp } from 'src/static/icons'

/**
 * Prompt List Container
 */
const PromptListContainer = styled(Box)(({ theme }) => ({
  margin: theme.active === 'dougallgpt' ? '12px 0px' : '15px 0px'
}))

/**
 * Title Container
 */
const TitleContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: theme.active === 'dougallgpt' ? '0px 12px' : '0px',
  alignItems: 'center',
  '& h1': { fontSize: '18px', lineHeight: '28px' }
}))

/**
 * Accordion Title
 */
const AccordionTitle = styled(Box)(() => ({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  padding: '0px 12px',
  '& p': {
    flex: 1,
    fontSize: '12px',
    lineHeight: '14px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    margin: '9px 0px'
  }
}))

/**
 * Item Container
 */
const ItemContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0px 2px'
}))

/**
 * Item Caption
 */
const ItemCaption = styled(Box)(() => ({
  padding: '11px 0px 10px 0px',
  flex: 1,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}))

/**
 * Prompt List Button
 * forwardProps: nightMode
 */
const PromptListButton = styled(Button, {
  shouldForwardProp: prop => prop !== 'nightMode'
})(({ nightMode }) => ({
  display: 'block',
  margin: '0 11px 0 auto',
  color: nightMode ? '#58b7ff' : '#363f72',
  textTransform: 'capitalize',
  fontSize: '12px',
  fontWeight: '600',
  maxWidth: '88px',
  '&:hover': {
    boxShadow: 'none',
    backgroundColor: 'transparent'
  },
  '&:focus': {
    boxShadow: 'none',
    backgroundColor: 'transparent'
  }
}))

const IconContainer = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#2d3e50',
  borderRadius: '50%',
  width: 26,
  height: 26,
  padding: 1,
  marginRight: 10
}))

/**
 * TitleIcon component
 */
const TitleIcon = ({ icon: IconComponent, ...props }) => {
  return (
    <IconContainer>
      <IconComponent sx={{ color: 'white', fontSize: 18 }} {...props} />
    </IconContainer>
  )
}

TitleIcon.propTypes = {
  icon: PropTypes.elementType // Ensure icon is a component type
}

/**
 * Carat Icon
 */
const CaratIcon = ({ open, nightMode }) => (
  <CaratUp
    sx={{
      color: '#494949',
      fontSize: '20px',
      transform: open ? 'rotate(0)' : 'rotate(180deg)',
      transition: 'transform .3s'
    }}
  />
)
CaratIcon.propTypes = {
  open: PropTypes.bool,
  nightMode: PropTypes.bool
}

const Accordion = ({
  caption,
  isOpen,
  children,
  nightMode,
  wrapText,
  onClick,
  onExpandClick,
  autoIndent,
  autoClose,
  promptId
}) => {
  const { handleTracking } = usePiwik()
  const [expanded, setExpanded] = useState(false)
  const open = autoClose ? isOpen : expanded

  const toggleAccordion = () => {
    if (promptId !== '') {
      if (promptId === 'faq') {
        handleTracking(
          'firstword-ai',
          !open ? 'expand' : 'collapse',
          `faq-question_${caption}`
        )
      } else if (promptId === 'history') {
        handleTracking(
          'firstword-ai',
          !open ? 'expand' : 'collapse',
          `history_${caption}`
        )
      }
    }

    if (!open) {
      onExpandClick()
    }
    onClick()
    if (!autoClose) {
      setExpanded(!expanded)
    }
  }
  return (
    <div data-testid="accordion">
      <AccordionTitle onClick={toggleAccordion}>
        <Typography
          sx={{
            color: nightMode ? '#fff' : '#000',
            whiteSpace: wrapText ? 'break-spaces !important' : 'nowrap'
          }}
        >
          {caption}
        </Typography>
        <CaratIcon open={open} nightMode={nightMode} />
      </AccordionTitle>
      <Collapse in={open} sx={{ marginLeft: autoIndent ? '7%' : '0px' }}>
        {children}
      </Collapse>
    </div>
  )
}

Accordion.propTypes = {
  caption: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  isOpen: PropTypes.bool,
  nightMode: PropTypes.bool,
  wrapText: PropTypes.bool,
  onExpandClick: PropTypes.func,
  autoIndent: PropTypes.bool,
  autoClose: PropTypes.bool,
  promptId: PropTypes.string
}

const ListChildren = ({
  items,
  cycle = 0,
  onExpandClick,
  onExpandItemAttr,
  nightMode,
  wrapText,
  autoIndent,
  dividers,
  customChildrenRender,
  onItemClick,
  actionIcon,
  onActionClick = () => {},
  autoClose,
  autoCloseLevel,
  promptId
}) => {
  // Auto Close condition only for this iteration
  const levelAutoClose =
    autoClose && (autoCloseLevel === -1 || autoCloseLevel === cycle)
  const recursiveProps = {
    onExpandClick,
    onExpandItemAttr,
    nightMode,
    wrapText,
    autoIndent,
    dividers,
    customChildrenRender,
    onItemClick,
    actionIcon,
    onActionClick,
    autoClose,
    autoCloseLevel,
    promptId
  }

  // Accordions controler in order to only have one open at a time
  // When autoClose is true
  const [itemExpanded, setItemExpanded] = useState(-1)

  const onAccordionClick = idx => {
    if (levelAutoClose) {
      if (idx === itemExpanded) {
        setItemExpanded(-1)
      } else {
        setItemExpanded(idx)
      }
    }
  }
  return items.map((item, idx) => {
    if (item.children) {
      return (
        <Fragment key={item.key || item.id}>
          <Accordion
            caption={item.caption}
            isOpen={itemExpanded === idx}
            onClick={() => onAccordionClick(idx)}
            onExpandClick={() => onExpandClick(item[onExpandItemAttr])}
            nightMode={nightMode}
            wrapText={wrapText}
            autoIndent={autoIndent}
            autoClose={levelAutoClose}
            promptId={promptId}
          >
            <ListChildren
              items={item.children}
              cycle={cycle + 1}
              {...recursiveProps}
            />
          </Accordion>
          {dividers && idx !== items.length - 1 && cycle === 0 && (
            <Divider sx={{ color: '#fff' }} />
          )}
        </Fragment>
      )
    }
    if (customChildrenRender && typeof customChildrenRender === 'function') {
      return (
        <div key={item.key || item.id} onClick={onItemClick}>
          {customChildrenRender(item)}
        </div>
      )
    }
    return (
      <ItemContainer key={item.key || item.id}>
        <ItemCaption
          sx={{ cursor: onItemClick ? 'pointer' : 'auto' }}
          onClick={onItemClick}
        >
          <Typography>{item.caption}</Typography>
        </ItemCaption>
        {actionIcon && !customChildrenRender && (
          <IconButton
            onClick={onActionClick}
            sx={{ width: '24px', height: '24px' }}
            size="large"
          >
            {actionIcon}
          </IconButton>
        )}
      </ItemContainer>
    )
  })
}

ListChildren.propTypes = {
  items: PropTypes.array,
  onExpandClick: PropTypes.func,
  onExpandItemAttr: PropTypes.string,
  nightMode: PropTypes.bool,
  wrapText: PropTypes.bool,
  autoIndent: PropTypes.bool,
  cycle: PropTypes.number,
  dividers: PropTypes.bool,
  customChildrenRender: PropTypes.func,
  onItemClick: PropTypes.func,
  actionIcon: PropTypes.object,
  onActionClick: PropTypes.func,
  autoClose: PropTypes.bool,
  autoCloseLevel: PropTypes.number
}

const PromptList = ({
  id,
  title,
  icon,
  items,
  actionIcon,
  onItemClick,
  onActionClick,
  customChildrenRender,
  isFetching = false,
  listStyles = {},
  onExpandClick = () => {},
  onExpandItemAttr = 'caption',
  button = false,
  buttonLabel = 'Submit',
  onButtonClick = () => {},
  collapsibleContent,
  nightMode = false,
  wrapText = false,
  manualOpenCallback,
  isOpen,
  containerStyles = {},
  dividers = true,
  autoIndent = true,
  autoClose = false,
  autoCloseLevel = -1
}) => {
  const [expanded, setExpanded] = useState(!collapsibleContent)

  const hasItems = items?.length > 0
  const clickable = collapsibleContent && hasItems

  const noDataMessages = {
    history: 'No History Found',
    popular: 'No Popular Prompts Found',
    faq: 'No FAQ Found'
  }

  const noDataMessage = noDataMessages[id] || 'No data found'

  const toggleAccordion = () => {
    if (hasItems) {
      if (manualOpenCallback) {
        manualOpenCallback(id)
      } else {
        setExpanded(!expanded)
      }
    }
  }
  const childrenProps = {
    promptId: id,
    items,
    onExpandClick,
    onExpandItemAttr,
    nightMode,
    wrapText,
    autoIndent,
    dividers,
    customChildrenRender,
    onItemClick,
    actionIcon,
    onActionClick,
    autoClose,
    autoCloseLevel
  }

  return (
    <PromptListContainer data-testid="prompt-list" sx={{ ...containerStyles }}>
      <TitleContainer
        sx={{ cursor: clickable ? 'pointer' : 'auto' }}
        onClick={toggleAccordion}
      >
        <Box display="flex" alignItems="center">
          {icon && <TitleIcon icon={icon} />}
          <Typography variant="h1" sx={{ color: '#494949' }}>
            {title}
          </Typography>
        </Box>
        {clickable && <CaratIcon open={expanded || isOpen} />}
      </TitleContainer>
      <Collapse in={expanded || isOpen}>
        {isFetching ? (
          <Box display="flex" justifyContent="center" marginTop="5px">
            <CircularProgress sx={{ color: '#494949' }} />
          </Box>
        ) : hasItems ? (
          <Box sx={{ margin: '0px', padding: '5px 0px', ...listStyles }}>
            <ListChildren {...childrenProps} />
          </Box>
        ) : (
          <Typography
            sx={{
              margin: '20px',
              textAlign: 'center',
              fontSize: '16px',
              color: '#494949'
            }}
          >
            {noDataMessage}
          </Typography>
        )}
        {button && hasItems && (
          <PromptListButton onClick={onButtonClick} nightMode={nightMode}>
            {buttonLabel}
          </PromptListButton>
        )}
      </Collapse>
    </PromptListContainer>
  )
}

PromptList.propTypes = {
  /**
   * List id
   */
  id: PropTypes.string,
  /**
   * List title
   */
  title: PropTypes.string,
  /**
   * Section icon
   */
  icon: PropTypes.object,
  /**
   * Item list
   * Structure: { key, caption }
   */
  items: PropTypes.array,
  /**
   * Action icon for each element
   */
  actionIcon: PropTypes.object,
  /**
   * Function
   * Executed when caption is clicked
   */
  onItemClick: PropTypes.func,
  /**
   * Function
   * Executed when icon is clicked
   */
  onActionClick: PropTypes.func,
  /**
   * Function
   * Custom Rendering for non parent items
   * Receives the item as a parameter
   */
  customChildrenRender: PropTypes.func,
  /**
   * Boolean
   * If true will render a spinner element
   */
  isFetching: PropTypes.bool,
  /**
   * String
   * Styles passed to the children container
   */
  listStyles: PropTypes.object,
  /**
   * Func
   * Accordion on click handler
   */
  onExpandClick: PropTypes.func,
  /**
   * String
   * Item Attribute that will be passed back
   *  in onExpandClick callback
   */
  onExpandItemAttr: PropTypes.string,
  /**
   * Have action button
   */
  button: PropTypes.bool,
  /**
   * Action Button Label
   */
  buttonLabel: PropTypes.string,
  /**
   * Action Button onClick
   */
  onButtonClick: PropTypes.func,
  /**
   * Boolean
   * Hide first items when collapsed
   */
  collapsibleContent: PropTypes.bool,
  /**
   * Boolean
   * Styles for dark backgrounds
   */
  nightMode: PropTypes.bool,
  /**
   * Boolean
   * Wrap words on overflow or trim
   */
  wrapText: PropTypes.bool,
  /**
   * Function
   * When opening needs to be controlled from Parent
   * Just when collapsibleContent = true
   */
  manualOpenCallback: PropTypes.func,
  /**
   * Boolean
   * Open variable when opening is manual
   */
  isOpen: PropTypes.bool,
  /**
   * Object
   * Styles passed to the container
   */
  containerStyles: PropTypes.object,
  /**
   * Boolean
   * List Items should have dividers or not
   */
  dividers: PropTypes.bool,
  /**
   * Boolean
   * Children Items Left Margin
   */
  autoIndent: PropTypes.bool,
  /**
   * Boolean
   * Accordion Siblings will only be one open at a time
   */
  autoClose: PropTypes.bool,
  /**
   * Number
   * Level which should have autoClose
   * -1 : All levels (default)
   *  0 : First Level
   *  1 : ...
   * ...
   */
  autoCloseLevel: PropTypes.number
}

export default PromptList
