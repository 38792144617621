import { createAction, handleActions } from 'redux-actions'
import { checkIfArray } from 'src/utils/index.js'

export const getConfigListRequest = createAction('GET_CONFIG_LIST_REQUEST')
export const getConfigListSuccessRequest = createAction(
  'GET_CONFIG_LIST_SUCCESS_REQUEST'
)
export const getConfigRequest = createAction('GET_CONFIG_REQUEST')
export const getConfigSuccess = createAction('GET_CONFIG_SUCCESS')
export const getConfigError = createAction('GET_CONFIG_ERROR')

export const updateConfigRequest = createAction('UPDATE_CONFIG_REQUEST')
export const updateConfigSuccess = createAction('UPDATE_CONFIG_SUCCESS')
export const updateConfigError = createAction('UPDATE_CONFIG_ERROR')

export const getConfigRegisterModalValidation = createAction(
  'GET_CONFIG_REGISTER_MODAL_VALIDATION'
)

export const actions = {
  getConfigListRequest,
  getConfigListSuccessRequest,

  getConfigRequest,
  getConfigSuccess,
  getConfigError,

  updateConfigRequest,
  updateConfigSuccess,
  updateConfigError,

  getConfigRegisterModalValidation
}

const defaultState = {
  bootstrapped: false
}

export default handleActions(
  {
    GET_CONFIG_LIST_REQUEST: state => {
      return { ...state, bootstrapped: false }
    },
    GET_CONFIG_LIST_SUCCESS_REQUEST: state => {
      return {
        ...state,
        bootstrapped: true
      }
    },
    GET_CONFIG_REQUEST: (state, { payload: { key } }) => {
      return {
        ...state,
        [key]: {
          ready: false,
          error: null,
          data: null
        }
      }
    },
    GET_CONFIG_SUCCESS: (state, { payload: { key, data } }) => {
      return {
        ...state,
        [key]: {
          ready: true,
          error: null,
          data
        }
      }
    },
    GET_CONFIG_ERROR: (state, { payload: { key, error } }) => {
      return {
        ...state,
        [key]: {
          ready: true,
          error,
          data: null
        }
      }
    },

    UPDATE_CONFIG_REQUEST: (state, { payload: { key } }) => {
      const prevData = state[key] ? state[key].data : null
      const data = checkIfArray(prevData) ? [...prevData] : { ...prevData }

      return {
        ...state,
        [key]: {
          ready: false,
          error: null,
          data
        }
      }
    },
    UPDATE_CONFIG_SUCCESS: (state, { payload: { key, updatedData } }) => {
      const prevData = state[key] ? state[key].data : null
      const data = checkIfArray(prevData)
        ? [...prevData, ...updatedData]
        : { ...prevData, ...updatedData }

      return {
        ...state,
        [key]: {
          ready: true,
          error: null,
          data
        }
      }
    },
    UPDATE_CONFIG_ERROR: (state, { payload: { key, error } }) => {
      const prevData = state[key] ? state[key].data : null
      const data = checkIfArray(prevData) ? [...prevData] : { ...prevData }

      return {
        ...state,
        [key]: {
          ready: true,
          error,
          data
        }
      }
    }
  },
  defaultState
)
