import { getHTMLTag, parseStringToHtml } from 'src/utils/index.js'
import countries from 'src/services/AiMatch/constants/countriesISOAlpha2.json'

class AiMatchParser {
  async buildParams({
    aiMatchSite,
    area,
    position,
    profile,
    width,
    height,
    stateBasedConfig,
    getState,
    ...rest
  }) {
    const params = {
      property: aiMatchSite,
      channel: 'website',
      zone: area,
      position
    }

    if (width && height && area !== 'fw_flash') {
      params.size = `${parseInt(width, 10)}x${parseInt(height, 10)}`
    }

    if (profile) {
      const countryShort = countries.find(
        ({ Name }) => Name === profile.address.country
      ) || { Code: 'us' }
      const subscriptions = profile?.subscriptions
        ?.map(item => item?.license_type.replace(/ /g, '_').toLowerCase())
        .join(',')

      Object.assign(params, {
        geography: countryShort.Code.toLowerCase(),
        subscription: subscriptions,
        mid: profile?.party_id || profile?.profile_id || 1,
        job_function: profile.job_function?.replace(/[ /]/g, '_').toLowerCase()
      })
    }

    if (stateBasedConfig) {
      const stateBased = stateBasedConfig(getState())
      Object.keys(stateBased).forEach(key => {
        if (stateBased[key]) params[key] = stateBased[key]
      })
    }

    const articleId = this.getArticleId()

    if (articleId) {
      params.articleId = articleId
    }

    return Object.entries(params)
      .filter(([key, value]) => value !== undefined)
      .map(([key, value]) => `${key}=${value}`)
      .join('/')
  }

  getArticleId = () => {
    if (location.pathname.includes('story')) {
      const splitUrl = location.pathname.match(/story\/(\d+)/)
      if (splitUrl) {
        return splitUrl[1]
      }
    }
  }

  parseCountryImageAd(ad, profile) {
    // Country Ad Flow
    const profileCountry = profile?.address?.country || null
    const countryAd =
      profileCountry && ad?.countries ? ad?.countries[profileCountry] : null

    if (countryAd?.url && countryAd?.image) {
      return {
        emptyContent: false,
        click: countryAd?.url,
        imgId: ad?.id,
        src: countryAd?.image,
        alt: ad?.name
      }
    }

    return null
  }

  parseAimatchImageAd(data) {
    // Aimatch Ad Flow
    const html = parseStringToHtml(data)
    const link = getHTMLTag(html, 'a')
    const img = getHTMLTag(link, 'img')

    const { href } = link
    const { id, src, alt, width, height, className, dataset = {} } = img
    // Code shared from phoenix team for viewability
    const { sasiaViewTime, sasiaViewPercent, sasiaViewUrl } = dataset

    return {
      emptyContent:
        (height && height == 0) ||
        (img && src.includes('aimatch.com/default.gif')),
      click: href,
      imgId: id,
      src,
      alt,
      width,
      height,
      apiClassName: className,
      viewTime: sasiaViewTime,
      viewPercent: sasiaViewPercent,
      viewUrl: sasiaViewUrl
    }
  }

  parseImageAd({ ad, data, profile }) {
    const countryAdFound = this.parseCountryImageAd(ad, profile)
    if (countryAdFound) return countryAdFound

    // Default to aimatch ad
    return this.parseAimatchImageAd(data)
  }

  parseIframeAd(data) {
    // Code shared from phoenix team for viewability
    const {
      viewability = {},
      mediaUrl = null,
      title,
      clickUrl,
      height,
      logo,
      profile
    } = data
    const { fcid, viewTime, viewPercent, viewUrl } = viewability

    // Set tracking params
    const srcMedialUrl = mediaUrl?.includes('?') ? '&' : '?'
    const siteParam = `${srcMedialUrl}site=${window.location.origin}`
    const profileParam = profile?.profile_id ? `&mid=${profile.profile_id}` : ''

    const src = `${mediaUrl}${siteParam}${profileParam}`

    return {
      emptyContent: !mediaUrl || !height || height == 0,
      width: '100%',
      frameBorder: '0',
      marginHeight: '0',
      src,
      title,
      click: clickUrl,
      height,
      icon: logo,
      institute: title,
      fcid,
      viewTime,
      viewPercent,
      viewUrl
    }
  }

  parseHTMLAd(adData) {
    const { data } = adData

    return {
      emptyContent: !data,
      width: '100%',
      data
    }
  }

  parseJSONAd(response) {
    const data = response?.data || null
    const viewability = data?.viewability || {}

    // Remove viewability to avoid duplicated data
    if (data?.viewability) {
      delete data.viewability
    }

    return {
      emptyContent: !response?.data,
      data,
      ...viewability
    }
  }
}

export default AiMatchParser
