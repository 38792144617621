import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import {
  ProtectedArea,
  ShowFor
} from 'src/components/LicenseProtected/index.js'
import HeaderItem from 'src/components/HeaderItem/index.js'
import useMediaSize from 'src/hooks/useMediaSize.js'
import Icon from 'src/components/ui/Icon/index.js'
import styles from './Menu.module.sass'
import HeaderButton from 'src/components/HeaderButton/index.js'

const Menu = ({ handleSlideMenu, hideMenu }) => {
  const mediaSize = useMediaSize({
    mediaQueries: {
      small: '(max-width: 550px)',
      medium: '(max-width: 960px)'
    }
  })

  return (
    <nav className={styles.menu}>
      <ul>
        {!hideMenu && (
          <>
            {mediaSize === 'large' && (
              <ProtectedArea>
                <ShowFor anonymous>
                  <Fragment>
                    <HeaderItem register>
                      <HeaderButton
                        color="secondary"
                        label="Register"
                        to="/register"
                      />
                    </HeaderItem>
                    <HeaderItem register>
                      <HeaderButton
                        color="secondary"
                        label="Sign In"
                        to="/sign-in"
                      />
                    </HeaderItem>
                  </Fragment>
                </ShowFor>
              </ProtectedArea>
            )}
          </>
        )}
        <ProtectedArea>
          <ShowFor authenticated>
            <HeaderItem icon>
              <Icon
                icon="svg/material-design-icons/action/account_circle"
                iconSize={32}
                onClick={handleSlideMenu}
                button
              />
            </HeaderItem>
          </ShowFor>
        </ProtectedArea>
      </ul>
    </nav>
  )
}

Menu.propTypes = {
  handleSlideMenu: PropTypes.func,
  hideMenu: PropTypes.bool
}

export default Menu
