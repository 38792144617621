import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { actions } from 'src/modules/SingleStory/reducer'
import { getConfig } from 'src/modules/Config/selectors'
import PropTypes from 'prop-types'
import ActionIcon from 'src/components/ActionButtons/components/ActionIcon'
import RequestAccessModal from 'src/modules/Paywall/Components/RequestModal.js'

const NotifyButton = ({
  user,
  notified = false,
  productCode = null,
  action = () => {},
  setNotified = () => {},
  articleId = '',
  ...props
}) => {
  const dispatch = useDispatch()
  const notifyMeSettings =
    useSelector(getConfig('notify_me_settings'), shallowEqual) || {}

  const newsletterUuid = notifyMeSettings?.newsletter_notify_me?.newsletter_uuid

  useEffect(() => {
    const userIsNotified = user?.notifyMe?.find(
      item =>
        item?.preview_article_ids?.includes(productCode) &&
        item?.newsletter_uuid === newsletterUuid
    )
    setNotified(!!userIsNotified)
  }, [productCode])

  const iconPath = !notified
    ? 'svg/material-design-icons/content/notify'
    : 'svg/material-design-icons/content/notify_clicked'

  const onClick = () => {
    action()
    dispatch(
      actions.requestSimpleNotifyMeSubscribe({
        newsletterUuid,
        articleId: productCode
      })
    )
  }

  return (
    <ActionIcon
      {...props}
      action={onClick}
      iconPath={iconPath}
      articleId={articleId}
      buttonType={!notified ? 'notify-me' : 'cancel-notify-me'}
    />
  )
}

NotifyButton.propTypes = {
  user: PropTypes.object,
  articleId: PropTypes.any,
  action: PropTypes.func,
  notified: PropTypes.bool,
  setNotified: PropTypes.func,
  productCode: PropTypes.string
}

const RiverNotifyActionButton = ({ action = () => {}, ...props }) => {
  const [notified, setNotified] = useState(false)

  const onNotifyUpdate = () => {
    setNotified(notified => !notified)
    action()
  }

  return (
    <NotifyButton
      {...props}
      notified={notified}
      action={onNotifyUpdate}
      setNotified={setNotified}
    />
  )
}

RiverNotifyActionButton.propTypes = {
  action: PropTypes.func
}

const AnonNotifyActionButton = ({ ...props }) => {
  const [isModalOpen, setModalOpen] = useState(false)

  const handleOpenModal = () => {
    setModalOpen(true)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  return (
    <>
      <ActionIcon
        {...props}
        action={handleOpenModal}
        iconPath="svg/material-design-icons/content/notify"
      />
      <RequestAccessModal open={isModalOpen} onCancel={handleCloseModal} />
    </>
  )
}

const NotifyActionButton = props => {
  if (!props?.authenticated) return <AnonNotifyActionButton {...props} />
  if (props?.singleStory) return <NotifyButton {...props} />
  return <RiverNotifyActionButton {...props} />
}

NotifyActionButton.propTypes = {
  singleStory: PropTypes.bool,
  authenticated: PropTypes.bool
}

export default NotifyActionButton
