import { useSelector, useDispatch } from 'react-redux'
import { actions } from 'src/modules/River/reducer.js'
import * as selectors from 'src/modules/River/selectors'
import { getDefaultFilters } from 'src/modules/River/config/riverConfig'

function useFilters() {
  const dispatch = useDispatch()

  const riverId = useSelector(selectors.getRiverId)
  const usedFilters = useSelector(selectors.getUsedRiverFilters)
  const availableFilters = useSelector(selectors.getAvailableRiverFilters)
  const selectedFilters = useSelector(selectors.getUsedFiltersFormatted)
  const tagsForCategory = useSelector(selectors.getFoundTags)
  const selectedFilterByCategory = useSelector(selectors.getFiltersByCategory)

  return {
    onRemoveFilter: filter => () => {
      dispatch(
        actions.removeFilter({
          riverId,
          filter,
          defaultFilters: getDefaultFilters(riverId, filter.field)
        })
      )
    },
    onRemoveFilterNoSearch: filter => () => {
      dispatch(
        actions.removeFilterWithoutSearch({
          riverId,
          filter,
          defaultFilters: getDefaultFilters(riverId, filter.field)
        })
      )
    },

    onAddFilter: (filterKey, filter) => () => {
      dispatch(
        actions.addFilter({
          key: filterKey,
          filter,
          defaultFilters: getDefaultFilters(riverId, filterKey)
        })
      )
    },
    onSelectFilter:
      (filterKey, filter, singleSelect = false) =>
      () => {
        dispatch(
          actions.selectFilter({
            key: filterKey,
            filter,
            defaultFilters: getDefaultFilters(riverId, filterKey),
            singleSelect
          })
        )
      },
    onTagSearch: (filterKey, category) => () => {
      dispatch(
        actions.requestTagsSearch({
          query_string: filterKey,
          category
        })
      )
    },
    addSelectedFilter: (option = {}, category = '', singleSelect = false) => {
      dispatch(
        actions.addSelectedTag({
          option,
          category,
          riverId,
          singleSelect
        })
      )
    },
    removeSelectedFilter: (option = {}, category = '') => {
      dispatch(
        actions.removeSelectedTag({
          option,
          category,
          riverId
        })
      )
    },
    cleanTagOptions: category => {
      dispatch(actions.cleanTagsSearch({ category, riverId }))
    },
    usedFilters,
    availableFilters,
    selectedFilters,
    tagsForCategory,
    selectedFilterByCategory
  }
}

export default useFilters
