import PropTypes from 'prop-types'
import { useState } from 'react'
// MUI Components
import Button from '@mui/material/Button'
// MUI Icons
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import Checkbox from '@mui/material/Checkbox'
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />
// Styles
import styles from '../FilterInput.module.sass'
import cx from 'classnames'

import { siteName } from 'src/utils/oneCodeBase'

const PredeterminatedListFilters = props => {
  const { options = [], index = '', handleOptionSelect = () => {} } = props

  const [showAdditionalOptions, setShowAdditionalOptions] = useState(false)
  const [visibleOptions, setVisibleOptions] = useState(10)

  const handleViewMore = () => {
    setShowAdditionalOptions(!showAdditionalOptions)
    if (!showAdditionalOptions) {
      setVisibleOptions(10)
    }
  }

  const styleBySite =
    siteName === 'Reports' ? 'reportsFilterContainer' : 'filterContainer'
  const displayedOptions = options.slice(0, visibleOptions)

  return (
    options.length > 0 && (
      <div className={styles.predeterminatedListContainer}>
        <ul className={styles.predeterminatedListOptions}>
          {displayedOptions.map(option => (
            <li
              className={cx(styles.listOption, styles[styleBySite])}
              key={`${index} - ${option?.value ?? ''}`}
            >
              <button
                className={styles.listButton}
                onClick={() => {
                  handleOptionSelect(option)
                }}
              >
                <Checkbox
                  sx={{ padding: '5px', color: '#494949', height: '25px' }}
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={option?.selected}
                />
                <span>{option?.value}</span>
              </button>
            </li>
          ))}
          {showAdditionalOptions &&
            options.slice(visibleOptions).map(option => (
              <li
                className={cx(styles.listOption, styles[styleBySite])}
                key={`${index} - ${option?.value ?? ''}`}
              >
                <button
                  className={styles.listButton}
                  onClick={() => {
                    handleOptionSelect(option)
                  }}
                >
                  <Checkbox
                    sx={{ padding: '5px', color: '#494949' }}
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={option?.selected}
                  />
                  <span>{option?.value}</span>
                </button>
              </li>
            ))}
        </ul>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {options.length > visibleOptions && (
            <Button
              variant="outlined"
              size="medium"
              onClick={handleViewMore}
              sx={{
                width: '100%',
                borderColor: '#e27746',
                color: '#e27746',
                '&:hover': {
                  borderColor: '#DE6427'
                }
              }}
            >
              {showAdditionalOptions
                ? 'Hide'
                : `View ${options.length - visibleOptions} more`}
            </Button>
          )}
        </div>
      </div>
    )
  )
}

PredeterminatedListFilters.propTypes = {
  options: PropTypes.array,
  index: PropTypes.string,
  handleOptionSelect: PropTypes.func
}

export default PredeterminatedListFilters
