import { all, call, put, takeEvery } from 'redux-saga/effects'
import { actions as faqActions } from './FaqReducer'
import siteConfig from 'src/site.config.json'

const { configuration } = siteConfig

function* faqRequest(services, { payload: language }) {
  const DougallService = services('DougallService')
  const DougallParser = services('DougallParser')

  try {
    const faqs = yield call([DougallService, 'faqRequest'], {
      brandCode: 'dougallgpt',
      fileName: 'faq.json'
    })

    if (faqs) {
      const content = faqs[language]
        ? faqs[language]
        : faqs[configuration.localization.fallbackLng]

      const parsedFaqs = yield call([DougallParser, 'faqParser'], content)

      yield put(faqActions.requestSuccess(parsedFaqs))
    } else {
      yield put(faqActions.requestFail())
    }
  } catch (e) {
    yield put(faqActions.requestFail())
  }
}

export default function* watchFaq(services) {
  yield all([takeEvery('FAQ_REQUEST', faqRequest, services)])
}
