import asyncMeta from 'src/modules/AsyncFlags/metaCreator'
import { createReducer, createAction } from '@reduxjs/toolkit'
export const requestDeleteInterest = createAction(
  'ACCOUNT_DELETE_INTEREST__REQUEST',
  null,
  asyncMeta
)
export const errorDeleteInterest = createAction(
  'ACCOUNT_DELETE_INTEREST__ERROR',
  null,
  asyncMeta
)
export const successDeleteInterest = createAction(
  'ACCOUNT_DELETE_INTEREST__SUCCESS',
  null,
  asyncMeta
)

export const requestInsertInterest = createAction(
  'ACCOUNT_INSERT_INTEREST__REQUEST',
  null,
  asyncMeta
)
export const errorInsertInterest = createAction(
  'ACCOUNT_INSERT_INTEREST__ERROR',
  null,
  asyncMeta
)
export const successInsertInterest = createAction(
  'ACCOUNT_INSERT_INTEREST__SUCCESS',
  null,
  asyncMeta
)

export const requestSuggestInterest = createAction(
  'ACCOUNT_SUGGEST_INTEREST__REQUEST',
  null,
  asyncMeta
)
export const errorSuggestInterest = createAction(
  'ACCOUNT_SUGGEST_INTEREST__ERROR',
  null,
  asyncMeta
)
export const successSuggestInterest = createAction(
  'ACCOUNT_SUGGEST_INTEREST__SUCCESS',
  null,
  asyncMeta
)
export const clearSuggestInterest = createAction(
  'ACCOUNT_SUGGEST_INTEREST_CLEAR'
)

export const actions = {
  requestDeleteInterest,
  errorDeleteInterest,
  successDeleteInterest,

  requestInsertInterest,
  errorInsertInterest,
  successInsertInterest,

  requestSuggestInterest,
  errorSuggestInterest,
  successSuggestInterest,
  clearSuggestInterest
}

const initialState = {
  pendingForDeletion: [],
  pendingForInsertion: [],
  suggestions: [],
  message: ''
}

const myInterestsReducer = createReducer(initialState, {
  ACCOUNT_DELETE_INTEREST__REQUEST: (state, { payload: interest }) => {
    state.pendingForDeletion = [...state.pendingForDeletion, interest]
  },
  ACCOUNT_DELETE_INTEREST__SUCCESS: (state, { payload: interest }) => ({
    ...state,
    pendingForDeletion: state.pendingForDeletion.filter(
      ({ category, tag }) =>
        category !== interest.category || tag !== interest.tag
    )
  }),
  ACCOUNT_DELETE_INTEREST__ERROR: (
    state,
    { payload: { interest, error } }
  ) => ({
    ...state,
    pendingForDeletion: state.pendingForDeletion.filter(
      ({ category, tag }) =>
        category !== interest.category || tag !== interest.tag
    )
  }),

  ACCOUNT_INSERT_INTEREST__REQUEST: (state, { payload: interest }) => ({
    ...state,
    pendingForInsertion: [...state.pendingForInsertion, interest]
  }),
  ACCOUNT_INSERT_INTEREST__SUCCESS: (state, { payload: interest }) => ({
    ...state,
    suggestions: [],
    pendingForInsertion: state.pendingForInsertion.filter(
      ({ category, tag }) =>
        category !== interest.category || tag !== interest.tag
    )
  }),
  ACCOUNT_INSERT_INTEREST__ERROR: (
    state,
    { payload: { interest, error } }
  ) => ({
    ...state,
    pendingForInsertion: state.pendingForInsertion.filter(
      ({ category, tag }) =>
        category !== interest.category || tag !== interest.tag
    )
  }),

  ACCOUNT_SUGGEST_INTEREST__SUCCESS: (
    state,
    { payload: { suggestions, message } }
  ) => ({
    ...state,
    suggestions,
    message
  }),
  ACCOUNT_SUGGEST_INTEREST__ERROR: (state, { payload: { query, error } }) => ({
    ...state,
    suggestions: []
  }),
  ACCOUNT_SUGGEST_INTEREST__REQUEST: state => ({
    ...state,
    message: ''
  }),
  ACCOUNT_SUGGEST_INTEREST_CLEAR: state => ({
    ...state,
    suggestions: []
  })
})

export default myInterestsReducer
