import React from 'react'
import PropTypes from 'prop-types'
import Svg from 'src/components/Svg/index.js'
import Twitter from 'src/images/twitter.svg'
import LinkedIn from 'src/images/linkedin.svg'
import LinkIcon from 'src/images/link.svg'
import Bookmark from 'src/images/bookmark.svg'
import Email from 'src/images/email.svg'
import { Link } from 'react-router-dom'
import styles from './SocialButtons.module.sass'
import cx from 'classnames'

const GRAY_ICON_COLOR = '#919191'
const SocialButtons = ({
  onClick = () => () => {
    /* NOOP */
  },
  addTwitter = true,
  addLinkedin = true,
  addEmail = false,
  grayIcons = false,
  align = '',
  order = '',
  twitterText = '',
  linkedinText = '',
  emailText = '',
  linkText = ''
}) => (
  <div className={cx(styles.container, styles[align], styles[order])}>
    {addTwitter && (
      <span className={cx(styles.link, twitterText ? styles.withText : '')}>
        <Svg
          src={Twitter.toString()}
          fill={!grayIcons ? '#5FA9DC' : GRAY_ICON_COLOR}
          width="40"
          height="40"
          alt="Twitter"
          onClick={onClick('twitter')}
        />
        {twitterText && <p>{twitterText}</p>}
      </span>
    )}
    {addLinkedin && (
      <span className={cx(styles.link, linkedinText ? styles.withText : '')}>
        <Svg
          src={LinkedIn.toString()}
          fill={!grayIcons ? '#0177B4' : GRAY_ICON_COLOR}
          width="40"
          height="40"
          alt="LinkedIn"
          onClick={onClick('linkedin')}
        />
        {linkedinText && <p>{linkedinText}</p>}
      </span>
    )}
    {addEmail && (
      <span className={cx(styles.link, emailText ? styles.withText : '')}>
        <Svg
          src={Email.toString()}
          fill={!grayIcons ? '#0F3F77' : GRAY_ICON_COLOR}
          width="40"
          height="40"
          alt="Link"
          onClick={onClick('email')}
        />
        {emailText && <p>{emailText}</p>}
      </span>
    )}
    <span className={cx(styles.link, linkText ? styles.withText : '')}>
      <Svg
        src={LinkIcon.toString()}
        fill={!grayIcons ? '#0F3F77' : GRAY_ICON_COLOR}
        width="40"
        height="40"
        alt="Link"
        onClick={onClick('link')}
      />
      {linkText && <p>{linkText}</p>}
    </span>
    <Link className={styles.hidden} to="/">
      {/* // @TODO - change class to link when feature ready */}
      <Svg
        src={Bookmark.toString()}
        fill="#18385D"
        width="40"
        height="40"
        alt="Bookmark"
        onClick={onClick('bookmark')}
      />
    </Link>
  </div>
)

SocialButtons.propTypes = {
  /**
   * The handler function when clicking on the icons
   */
  onClick: PropTypes.func,
  /**
   * Flag defaulted as true, if false won't add the Twitter Icon
   */
  addTwitter: PropTypes.bool,
  /**
   * Flag defaulted as true, if false won't add the Linkedin Icon
   */
  addLinkedin: PropTypes.bool,
  /**
   * Flag defaulted as false, if true will add the Email Icon
   */
  addEmail: PropTypes.bool,
  /**
   * Flag defaulted as false, if true will make gray all Icons
   */
  grayIcons: PropTypes.bool,
  /**
   * Defines a text for Twitter/X
   */
  twitterText: PropTypes.string,
  /**
   * Defines a text for LinkedIn
   */
  linkedinText: PropTypes.string,
  /**
   * Defines a text for the Clipboard
   */
  linkText: PropTypes.string,
  /**
   * Defines a text for the Email Icon
   */
  emailText: PropTypes.string,
  /**
   * Defines the alignment of the Icons
   */
  align: PropTypes.string,
  /**
   * Defines the position order of the Icons
   */
  order: PropTypes.string
}

export default SocialButtons
