import { withProtectionAndRedirection } from 'src/components/LicenseProtected/index.js'
import { withExtraProps } from 'src/components/ExtraPropsComponent/index.js'
import SimpleStory from 'src/modules/SimpleStory/index.js'
import DealsDB from 'src/modules/DealsDB'
import BiometricsDeal from 'src/modules/DealsDB/biometrics'
import StaticPage from 'src/modules/Static'

export default [
  {
    path: '/print/:id',
    component: withExtraProps(SimpleStory, {
      simplePage: true
    }),
    exact: true
  },
  // TODO Remove this in case FB XML team can produce a /story/simple/:id url with the authlogin at the end
  // Handle the XML feeds url to recreate the url to simple story page
  {
    path: '/:login/story/:id',
    component: withExtraProps(SimpleStory, {
      fromXML: true
    }),
    exact: true
  },
  {
    path: '/fwdealflow',
    component: withProtectionAndRedirection(DealsDB, {
      showFor: { authenticated: true },
      redirectForOthers: '/sign-in'
    }),
    exact: true
  },
  {
    path: '/fwbiosimilarindex',
    component: withProtectionAndRedirection(BiometricsDeal, {
      showFor: { authenticated: true },
      redirectForOthers: '/sign-in'
    }),
    exact: true
  },
  {
    path: '/marketing-specs',
    component: StaticPage('marketingSpecs'),
    exact: true
  },
  {
    path: '/features-and-benefits',
    component: StaticPage('featuresAndBenefits'),
    exact: true
  },
  {
    path: '/perspectives/conference-rooms-to-zooms',
    component: StaticPage('conferenceRoomsToZooms'),
    exact: true
  }
]
