import React from 'react'
import {
  ProtectedArea,
  ShowFor
} from 'src/components/LicenseProtected/index.js'
import { licenseTypes } from 'src/services/FW5ML/parsers/UserParser.js'
import Uncap from 'src/components/Uncap/index.js'

const Title = () => (
  <ProtectedArea>
    <ShowFor license={licenseTypes.LICENSE_PLUS}>
      <Uncap>MyFW+</Uncap> Stories
    </ShowFor>
    <ShowFor license={licenseTypes.LICENSE_FREE} anonymous>
      Recommended for You
    </ShowFor>
  </ProtectedArea>
)
export default Title
