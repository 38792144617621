import { Fragment } from 'react'
import { call, put, takeLatest, takeEvery } from 'redux-saga/effects'
import { notificationsEnqueue } from 'src/modules/Notifications/reducer.js'
import { actions } from './reducer.js'

function* requestArticleShare(services, action) {
  const StoriesRepository = services('StoriesRepository')
  const Piwik = services('Piwik')
  const { payload } = action
  const { data = {} } = payload
  try {
    const Session = services('Session')
    const user = yield call([Session, 'userLoad'])
    const body = { ...data, user }
    yield call([StoriesRepository, 'shareArticle'], body)

    yield call(
      [Piwik, 'track'],
      'pop-up_share-email',
      'button-click',
      'success'
    )
    yield put(
      notificationsEnqueue({
        message: 'Email Sent Successfully'
      })
    )

    yield put(actions.successArticleShare())
  } catch (e) {
    yield put(actions.errorArticleShare(e.message))
    yield call([Piwik, 'track'], 'pop-up_share-email', 'button-click', 'failed')

    yield put(
      notificationsEnqueue({
        message: <Fragment>Unable to share the selected article.</Fragment>
      })
    )
  }
}
function* requestSiteShare(services, action) {
  const StoriesRepository = services('StoriesRepository')
  const Piwik = services('Piwik')
  const { payload } = action
  const { data } = payload
  try {
    const Session = services('Session')
    const user = yield call([Session, 'userLoad'])
    const body = { ...data, user }
    yield call([StoriesRepository, 'shareSite'], body)

    yield put(
      notificationsEnqueue({
        message: 'Email Sent Successfully'
      })
    )
    yield call(
      [Piwik, 'track'],
      'pop-up_share-invite-colleague',
      'button-click',
      'success'
    )
    yield put(actions.successSiteShare())
  } catch (e) {
    yield put(actions.errorSiteShare(e.message))
    yield call(
      [Piwik, 'track'],
      'pop-up_share-invite-colleague',
      'button-click',
      'failed'
    )

    yield put(
      notificationsEnqueue({
        message: <Fragment>Unable to share site.</Fragment>
      })
    )
  }
}

function* reportDownloadRequest(services, { payload }) {
  try {
    const StoriesRepository = services('StoriesRepository')
    yield call([StoriesRepository, 'downloadReport'], payload)
    yield call([Piwik, 'track'], 'action-bar_article', 'download', 'success')
    yield put(actions.reportDownloadSuccess({ url: data.url }))
  } catch (e) {
    console.error('Error downloading report:', e.message)
    yield put(actions.reportDownloadError({ error: e.message }))
    yield call([Piwik, 'track'], 'action-bar_article', 'download', 'failed')
    yield put(
      notificationsEnqueue({
        message: <Fragment>Unable to download the selected article.</Fragment>
      })
    )
  }
}

function* updateLikeRequest(services, { payload: articleId }) {
  const StoriesRepository = services('StoriesRepository')
  try {
    yield call([StoriesRepository, 'updateLike'], articleId)
    yield put(actions.updateLikeSuccess())
  } catch (e) {
    console.error(e)
    yield put(actions.updateLikeError({ error: e.message }))
    yield put(
      notificationsEnqueue({
        message: <Fragment>Unable to like the selected article.</Fragment>
      })
    )
  }
}

export default function* watch(services) {
  yield takeLatest('ARTICLE_SHARE__REQUEST', requestArticleShare, services)
  yield takeLatest('SITE_SHARE__REQUEST', requestSiteShare, services)
  yield takeEvery('UPDATE_LIKE_REQUEST', updateLikeRequest, services)
  yield takeEvery('REPORT_DOWNLOAD__REQUEST', reportDownloadRequest, services)
}
