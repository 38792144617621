import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actions } from 'src/modules/River/reducer.js'
import { paywallRedirectFlow } from 'src/modules/Benefits/reducer.js'
import * as selectors from 'src/modules/River/selectors'
import { push } from 'connected-react-router'
import { getRiverConfig } from 'src/modules/River/config/riverConfig'
import validate from 'src/modules/River/components/Search/SearchForm/validate'
import { getURLSortProps } from 'src/utils/index.js'

function useRiver() {
  const dispatch = useDispatch()
  const riverId = useSelector(selectors.getRiverId)
  const riverKey = useSelector(selectors.getRiverKey)
  const riverArgs = useSelector(selectors.getRiverArgs)
  const riverSlice = useSelector(selectors.getContentRiver)
  const infiniteScrollerEnabled = useSelector(selectors.infiniteScrollerEnabled)
  const loading = useSelector(selectors.getLoading)
  const loadingMore = useSelector(selectors.getLoadingMore)
  const isFeaturedLoading = useSelector(selectors.getFeaturedLoading)
  const currentPage = useSelector(selectors.getPage)

  const {
    title,
    getFeaturedContentTag = false,
    showFeaturedContent = false,
    showSingleFeaturedContent = false
  } = getRiverConfig(riverId)

  const withFeaturedContent = showFeaturedContent
  const withSingleFeaturedContent = showSingleFeaturedContent

  const paywallRedirection = () => dispatch(paywallRedirectFlow('push'))

  const loadMore = page => dispatch(actions.requestLoadMore({ riverId, page }))

  const sortBy = sort => {
    const sortUrlParam = sort === 'publication_date' ? 'date' : 'relevancy'
    dispatch(push(`${window.location.pathname}?rst=1&sort=${sortUrlParam}`))
  }

  const doSearchSorting = () => {
    const { sort } = getURLSortProps(
      'relevancy',
      '_score',
      'date',
      'publication_date'
    )
    dispatch(actions.setSorting({ riverId, sort }))
  }

  const doSearchCall = query => dispatch(actions.redirectSearch(query))

  const doSearch = values => {
    validate(values)
    const { query } = values
    if (query) {
      doSearchCall(query)
    }
  }

  const sortMyFW = key => {
    const sortUrlParam = key === 'byInterest' ? 'interest' : 'all'
    dispatch(push(`${window.location.pathname}?sort=${sortUrlParam}`))
  }

  const doMyFWSorting = () => {
    const { sort } = getURLSortProps('all', 'all', 'interest', 'byInterest')
    dispatch(actions.setMyfwTab(sort))
  }

  const reloadContentRiver = () => {
    dispatch(
      actions.requestSearch({
        withFeaturedContent,
        withSingleFeaturedContent,
        getFeaturedContentTag
      })
    )
  }

  const setMedicalAbstracts = value => {
    if (riverId) {
      dispatch(actions.setMedicalAbstracts({ riverId, value }))
    }
  }

  const setExternalArticlesSearch = (value, searchConfig) => {
    if (riverId) {
      if (value === true) {
        dispatch(
          actions.mergeSearch({
            riverId,
            searchBody: { ...searchConfig }
          })
        )
      } else {
        dispatch(
          actions.removeSearch({
            riverId,
            searchBody: { ...searchConfig }
          })
        )
      }
    }
  }

  useEffect(() => {
    // this will update the loadingFeaturedContent prop for the Featured content loading
    if (withFeaturedContent || withSingleFeaturedContent) {
      dispatch(actions.requestFeaturedContent(riverId))
    }

    if (riverId) {
      dispatch(
        actions.loadContentRiver({
          withFeaturedContent,
          withSingleFeaturedContent,
          getFeaturedContentTag
        })
      )
    }
  }, [riverId])
  useEffect(() => {
    if (withFeaturedContent || withSingleFeaturedContent) {
      if (riverId) {
        dispatch(actions.requestFeaturedContent(riverId))
      }
    }
  }, [withFeaturedContent, withSingleFeaturedContent])
  return {
    doSearch,
    doSearchCall,
    doSearchSorting,
    sortBy,
    sortMyFW,
    doMyFWSorting,
    title,
    loading,
    loadingMore,
    infiniteScrollerEnabled,
    paywallRedirection,
    loadMore,
    currentPage,
    riverId,
    riverKey,
    riverArgs,
    reloadContentRiver,
    setMedicalAbstracts,
    setExternalArticlesSearch,
    riverConfig: getRiverConfig(riverId),
    isFeaturedLoading,
    ...riverSlice
  }
}

export default useRiver
