import React from 'react'
import PropTypes from 'prop-types'
import Button from 'src/components/ui/Buttons/Button.js'
import { Input } from 'src/components/ui/Input/index.js'
import styles from './SearchForm.module.sass'
import { Field, reduxForm } from 'redux-form'
import validate from './validate'

const SearchForm = ({ hide, handleSubmit }) => {
  return (
    !hide && (
      <form onSubmit={handleSubmit}>
        <p className={styles.start}>Start a new search:</p>
        <div className={styles.search}>
          <Field
            component={Input}
            name="query"
            className={styles.input}
            errorAlign="center"
          />
          <Button
            type="submit"
            textCase="none"
            tier="secondary"
            className={styles.button}
          >
            Search
          </Button>
        </div>
      </form>
    )
  )
}

SearchForm.propTypes = {
  hide: PropTypes.bool,
  handleSubmit: PropTypes.func
}

export default reduxForm({
  form: 'search',
  destroyOnUnmount: false,
  enableReinitialize: false,
  touchOnBlur: false,
  initialValues: { query: '' },
  validate
})(SearchForm)
