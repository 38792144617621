import React from 'react'
import PropTypes from 'prop-types'
import Page from 'src/components/Page'
import { componentsByLayout } from '../LayoutMapping'
import { getObjectByProperty } from 'src/utils'

const GeneralLayout = ({ pageTemplateConfig = {}, component, ...props }) => {
  const ChildComponent = component || <></>
  const { content_layout = {}, sub_layout = null } = pageTemplateConfig

  const Component = getObjectByProperty(
    componentsByLayout,
    pageTemplateConfig?.page_layout,
    'main_layout'
  )

  return (
    <Page>
      {Object.keys(content_layout).map((screen, index) => (
        <ChildComponent
          key={`${screen}-${index}`}
          {...props}
          content_layout={content_layout}
          sub_layout={sub_layout}
          screen={screen}
          component={Component}
        />
      ))}
    </Page>
  )
}

GeneralLayout.propTypes = {
  pageTemplateConfig: PropTypes.object,
  component: PropTypes.elementType
}

export default GeneralLayout
