import _set from 'lodash/set'
import dayjs from 'dayjs'
import { getSubscriptionTypes } from 'src/utils/oneCodeBase.js'

export const audienceTypes = getSubscriptionTypes()

export const licenseTypes = {
  LICENSE_PLUS: 'LICENSE_PLUS',
  LICENSE_FREE: 'LICENSE_FREE',
  LICENSE_EDGE: 'LICENSE_EDGE',
  LICENSE_INACTIVE: 'LICENSE_INACTIVE'
}

class UserParser {
  constructor(CatalogsRepository) {
    this.catalogsRepository = CatalogsRepository
  }

  getLicense(license) {
    if (!license) return licenseTypes.LICENSE_FREE
    const { type = '' } = license
    if (type === audienceTypes.PLUS) {
      return licenseTypes.LICENSE_PLUS
    }
    return licenseTypes.LICENSE_FREE
  }

  getExpiration(subscription_expiration) {
    if (!subscription_expiration) return null
    if (typeof subscription_expiration === 'string')
      return dayjs(subscription_expiration).valueOf()
    if (typeof subscription_expiration === 'number')
      return subscription_expiration
    throw new Error('Unknown format for subscription_expiration')
  }

  parseUser(fwdpUser) {
    const {
      profile_id,
      first_name,
      last_name,
      email,
      address,
      interests: originalInterests,
      language,
      subscription,
      subscription_expiration = null,
      status,
      created = null,
      updated = null,
      company,
      phone_number,
      job_title,
      job_function,
      job_function_other,
      premium_account_id,
      heard_about,
      other,
      newsletter_settings = {},
      cap,
      subscriptions = [],
      party_id = null,
      subscription_status,
      report_count = 0
    } = fwdpUser
    const userLicense = this.getLicense(subscription)
    const interests = Object.keys(originalInterests).reduce((acc, key) => {
      const interestTree = originalInterests[key]
      if (Array.isArray(interestTree)) return { ...acc, [key]: {} }
      return {
        ...acc,
        [key]: interestTree
      }
    }, {})
    const { notify_me = [] } = newsletter_settings
    return {
      profile_id,
      firstName: first_name,
      lastName: last_name,
      email,
      address,
      interests,
      language,
      license: userLicense,
      expiration: this.getExpiration(subscription_expiration),
      status,
      created,
      updated: updated || created,
      company,
      job_title,
      job_function: job_function || job_function_other,
      phone_number,
      premiumAccountId: premium_account_id,
      heard_about,
      other,
      notifyMe: notify_me,
      cap,
      subscriptions,
      party_id,
      subscription_status,
      subscription_type: subscription?.type,
      report_count
    }
  }

  async buildFwdpUser(user) {
    let {
      email,
      first_name,
      last_name,
      country,
      company,
      job_title,
      job_function,
      phone_number,
      license,
      premium_account_id,
      heard_about,
      other,
      cap,
      register_healthtech = false,
      register_pharma = false,
      register_edge = false,
      register_reports = false
    } = user

    const fwdpUser = {
      email,
      first_name,
      last_name,
      country,
      company,
      phone_number,
      job_title,
      subscription_type: license,
      interests: [],
      premium_account_id,
      heard_about,
      register_healthtech,
      register_pharma,
      register_edge,
      register_reports,
      cap
    }
    let jobFunctionCatalog = await this.catalogsRepository.getCatalog(
      'jobFunction'
    )
    if (!jobFunctionCatalog) {
      jobFunctionCatalog = {}
    }
    const jobFunctionKey =
      Object.keys(jobFunctionCatalog).indexOf(job_function) > -1
        ? 'job_function'
        : 'job_function_other'
    _set(fwdpUser, jobFunctionKey, job_function)

    if (heard_about) {
      _set(fwdpUser, 'heard_about.from', heard_about.replace(/_/g, ' '))
      heard_about === 'other'
        ? _set(fwdpUser, 'heard_about.other', other || null)
        : _set(fwdpUser, 'heard_about.other', null)
    }

    return fwdpUser
  }
}

export default UserParser
