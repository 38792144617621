import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { logout } from './actions'
import SpinnerPage from 'src/components/SpinnerPage/index.js'

const Page = ({ requestLogout }) => {
  useEffect(() => requestLogout(), [])
  return <SpinnerPage title="Logging out, please wait..." />
}

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({
  requestLogout: () => {
    dispatch(logout({ refresh: true }))
  }
})

Page.propTypes = {
  requestLogout: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(Page)
