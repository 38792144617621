import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { switchBySiteId } from 'src/utils/index.js'

const getDefaultImage = () => {
  const { host, protocol } = window.location
  const file = switchBySiteId(
    {
      1: 'fw_pharma_large_logo.png',
      2: 'fw_healthtech_large_logo.png'
    },
    null
  )
  if (file) {
    return `${protocol}//${host}/assets/${file}`
  }
  return null
}

const Metadata = ({ title = '', description = '', image }) => {
  const finalImage = image || getDefaultImage()
  const safeLocation = window.location.href
  return (
    <Helmet>
      {/* Native */}
      <meta name="title" content={title} />
      <meta name="description" content={description} />

      {/* Facebook / Open Graph */}
      <meta name="og:title" content={title} />
      <meta name="og:description" content={description} />
      <meta name="og:url" content={safeLocation} />
      {finalImage && <meta name="og:image" content={finalImage} />}
      {finalImage && <meta name="og:image:width" content="300" />}
      {finalImage && <meta name="og:image:height" content="300" />}
      <meta name="og:type" content="article" />

      {/* Twitter */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:url" content={safeLocation} />
      {finalImage && <meta name="twitter:image" content={finalImage} />}
    </Helmet>
  )
}

Metadata.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string
}

export default Metadata
