import React from 'react'
import Page from 'src/components/Page/index.js'
import List from 'src/components/ui/List/List.js'
import ListItem from 'src/components/ui/List/ListItem.js'
import Typography from 'src/components/ui/Typography/index.js'
import styles from 'src/modules/Static/Static.module.sass'
import SectionHeading from 'src/components/SectionHeading/index.js'
import Metadata from 'src/components/Metadata/index.js'

const SEEAUKPrivacy = () => {
  return (
    <Page className={styles.static}>
      <Metadata title="Supplemental European Economic Area and United Kingdom Privacy Statement" />
      <SectionHeading
        title="Supplemental European Economic Area and United Kingdom Privacy Statement"
        gutterBottom
        paragraph
      />
      <Typography type="body1" className={styles.paragraph} paragraph>
        The United Kingdom <strong> (“UK”) </strong>and European Economic Area
        <strong> (“EEA”) </strong>laws require us to provide additional and
        different information about our data processing practices to data
        subjects in the UK and EEA. If you are accessing the Services from the
        EEA or the UK, this Supplemental EEA and UK Privacy Statement applies to
        you in addition to the FirstWord Privacy Policy.
      </Typography>
      <Typography type="body1" className={styles.paragraph} paragraph>
        In this Supplemental EEA and UK Privacy Statement, references to the
        <strong>“GDPR” </strong>are references to the General Data Protection
        Regulation as it applies in the country where you are located. If you
        are located in the EEA, the EU GDPR applies to the processing of your
        personal data. As a result of the UK’s departure from the EU, the EU
        GDPR has been incorporated into the domestic laws of the UK, known as
        the “UK GDPR”. If you are located in the UK, the UK GDPR applies to the
        processing of your personal data.
      </Typography>
      <Typography type="body1" className={styles.paragraph} paragraph>
        The controller is Doctor’s Guide Publishing Limited, 1, rue Hildegard
        von Bingen, L-1282 Luxembourg, Luxembourg. Our data protection officer’s
        contact details are as follows: Dr. Sebastian Kraska, Marienplatz 2,
        80331 Munich, Germany;{' '}
        <a className={styles.link} href="mailto:email@iitr.de">
          email@iitr.de
        </a>
        . Our UK GDPR-specific representative’s contact details are as follows:
        Rickert Services Ltd UK, PO Box 1487 Peterborough PE1 9XX United Kingdom{' '}
        <a
          className={styles.link}
          href="mailto:art-27-representative@rickert-services.uk"
        >
          art-27-representative@rickert-services.uk
        </a>
        .
      </Typography>
      <Typography type="subheading">
        1. Legal Bases for Processing Personal Data.
      </Typography>
      <Typography type="body1" className={styles.paragraph} paragraph>
        We rely on the following legal bases to process your personal data, as
        appropriate:
      </Typography>
      <List className={styles.list} listStyle="outside">
        <ListItem>
          <strong>
            Based on necessity to perform contracts with you (see Article
            6(1)(b) of the EU GDPR):
          </strong>{' '}
          When you access, use or register for Service, you form a contract with
          FirstWord based on the applicable terms of use or terms of service. We
          need to process your personal data to discharge our obligations in any
          such contract, fulfill your requests and orders, answer questions and
          requests from you, and provide tailored customer support.
        </ListItem>
        <ListItem>
          <strong>
            Based on compliance with legal obligations (see Article 6(1)(c) of
            the EU GDPR):
          </strong>{' '}
          We may need to process your personal data to comply with relevant
          laws, regulatory requirements and to respond to lawful requests, court
          orders, and legal process.
        </ListItem>
        <ListItem>
          <strong>
            Based on our legitimate interests (see Article 6(1)(f) of the EU
            GDPR):
          </strong>{' '}
          We process your personal data to send you newsletters, understand
          which products and services may be relevant to you and generally
          improve our products, services and business practices.
        </ListItem>
        <ListItem>
          <strong>
            Based on consent (see Article 6(1)(a) of the EU GDPR):
          </strong>{' '}
          We process your personal data on the basis of your consent in various
          instances, such as with respect to cookies that are not strictly
          necessary. You may withdraw such consent at any time, without
          affecting the lawfulness of processing based on consent before such
          withdrawal.
        </ListItem>
      </List>
      <Typography type="subheading" gutterBottom paragraph>
        2. Personal Data Transfers Outside of the EEA and the UK.
      </Typography>
      <Typography type="body1" className={styles.paragraph} paragraph>
        Some recipients of your personal data are located in the following
        country outside of the EEA and the UK for which the European Commission
        has issued adequacy decisions (see{' '}
        <a
          className={styles.link}
          href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_en"
        >
          {' '}
          here
        </a>
        ) or for which the UK has issued adequacy regulations (see{' '}
        <a
          className={styles.link}
          href="https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/international-transfers-after-uk-exit/#adequacy-decision"
        >
          {' '}
          here
        </a>
        ): Canada. In this case, the transfer is thereby recognized as providing
        an adequate level of data protection from an EEA and UK data protection
        law perspective (pursuant to Article 45 of the GDPR).
      </Typography>
      <Typography type="body1" className={styles.paragraph} paragraph>
        Some recipients of your personal data are located in the following
        countries for which the European Commission or UK have not issued an
        adequacy decision or adequacy regulations in respect of the level of
        data protection there: The U.S. and Malaysia. By entering into
        appropriate data transfer agreements based on the Standard Contractual
        Clauses approved by authorities of your jurisdiction, we have
        established that all such recipients will provide an adequate level of
        data protection and that appropriate technical and organizational
        security measures are in place to protect personal data against
        accidental or unlawful destruction, loss or alteration, unauthorized
        disclosure or access, and against all other unlawful forms of
        processing. Any onward transfer is subject to appropriate onward
        transfer requirements as required by the applicable contract or law.
      </Typography>
      <Typography type="body1" className={styles.paragraph} paragraph>
        You can ask for a copy of such appropriate data transfer agreements by
        contacting us as set out at the bottom of this notice.
      </Typography>
      <Typography type="subheading" gutterBottom paragraph>
        3. Data Retention.
      </Typography>
      <Typography type="body1" className={styles.paragraph} paragraph>
        We will delete, erase or anonymize your personal data within one month
        after your personal data is no longer necessary for us to provide you
        with any information or services you have requested, pursue any of the
        legitimate interests specified herein where the legitimate interest is
        not overridden by your fundamental rights or privacy interests, comply
        with any legal obligations to which we are subject, or defend any legal
        claim against us or support any legal claim made by us, including any
        potential appeal.
      </Typography>
      <Typography type="subheading" gutterBottom paragraph>
        4. Data Subject Rights.
      </Typography>
      <Typography type="body1" className={styles.paragraph} paragraph>
        Under the conditions set out under applicable law (i.e., the EU GDPR),
        you have the following rights:
      </Typography>
      <List className={styles.list} listStyle="outside">
        <ListItem>
          <strong>Right to withdraw your consent:</strong> If you have declared
          your consent regarding certain types of processing activities, you can
          withdraw this consent at any time with future effect. Such a
          withdrawal will not affect the lawfulness of the processing prior to
          the consent withdrawal.
        </ListItem>
        <ListItem>
          <strong>Right of access:</strong> You have the right to obtain from us
          confirmation as to whether your personal data is being processed, and,
          where that is the case, to request access to the personal data. The
          access information includes, among other things, the purposes of the
          processing, the categories of personal data concerned, and the
          recipients or categories of recipients to whom the personal data have
          been or will be disclosed. You have the right to obtain a copy of the
          personal data undergoing processing. Subject to applicable law, we may
          charge a reasonable fee for copies, based on administrative costs.
        </ListItem>
        <ListItem>
          <strong>Right to rectification:</strong> You have the right to obtain
          from us the rectification of inaccurate personal data concerning you.
          Depending on the purposes of the processing, you have the right to
          have incomplete personal data completed, including by means of
          providing a supplementary statement.
        </ListItem>
        <ListItem>
          <strong>Right to erasure:</strong> You have the right to ask us to
          erase your personal data to the extent it is not required for legally
          required purposes.
        </ListItem>
        <ListItem>
          <strong>Right to restriction of processing:</strong> You have the
          right to request restriction of processing of your personal data, in
          which case it would be marked and processed by us only for certain
          purposes.
        </ListItem>
        <ListItem>
          <strong>Right to data portability:</strong> You have the right to
          receive your personal data which you have provided to us in a
          structured, commonly used, and machine-readable format and you have
          the right to transmit the personal data to another entity without
          hindrance from us.
        </ListItem>
        <ListItem>
          <strong>Right to object:</strong> You have the right to object, on
          grounds relating to your particular situation, at any time to the
          processing of your personal data by us and we can thereby be required
          to no longer process your personal data. If you have a right to object
          and you exercise this right, your personal data will no longer be
          processed for such purposes by us. Exercising this right will not
          incur any cost to you. Such a right to object may not exist, in
          particular, if the processing of your personal data is necessary to
          take steps prior to entering into a contract or to perform a contract
          already concluded.
        </ListItem>
        <ListItem>
          <strong>Right to Submit Complaints:</strong> You have a right to lodge
          a complaint with a supervisory authority.
        </ListItem>
      </List>
      <Typography type="body1" className={styles.paragraph} paragraph>
        Please note that these rights may be limited under the applicable
        national data protection law. To exercise your rights please contact us
        as stated below.
      </Typography>
      <Typography type="subheading" gutterBottom paragraph>
        5. Your Choices.
      </Typography>
      <Typography type="body1" className={styles.paragraph} paragraph>
        You are not required to provide any personal data to FirstWord, but if
        you do not provide any personal data to us, you may not be able to use
        or receive the Services. You can use the Services without consenting to
        cookies that are not strictly necessary; the only consequence of
        withholding such consent is that the Services will be less tailored to
        you.
      </Typography>
      <Typography type="subheading" gutterBottom paragraph>
        6. Contact Us.
      </Typography>
      <Typography type="body1" className={styles.paragraph} paragraph>
        For more information or to exercise your rights as described herein,
        please contact{' '}
        <a className={styles.link} href="mailto:Privacy@firstwordgroup.com">
          Privacy@firstwordgroup.com
        </a>
        .
      </Typography>
    </Page>
  )
}
export default SEEAUKPrivacy
