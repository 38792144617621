import React from 'react'
import PropTypes from 'prop-types'
import StoryRiver from 'src/components/StoryRiver/index.js'
import {
  ProtectedArea,
  ShowFor
} from 'src/components/LicenseProtected/index.js'
import { Link } from 'react-router-dom'
import styles from 'src/styles/common.module.sass'
import Typography from 'src/components/ui/Typography'
import myReportViewStyles from './MyReportsView.module.sass'
import ReactHtmlParser from 'react-html-parser'

const MyReportsView = ({ title }) => {
  return (
    <StoryRiver title={ReactHtmlParser(title)} titleType="title">
      <ProtectedArea>
        <ShowFor authenticated>
          <div className={myReportViewStyles.description}>
            <Typography variant="body1">
              You currently do not have any FirstWord Reports available to you.{' '}
              <Link to="/contact-us" className={styles.activeLink}>
                Contact Us
              </Link>{' '}
              to learn more about our reports and how you gain access.
            </Typography>
          </div>
        </ShowFor>
        <ShowFor anonymous>
          <div className={myReportViewStyles.description}>
            <Typography variant="body1">
              To view the FirstWord Reports you have access to,{' '}
              <Link to="/sign-in" className={styles.activeLink}>
                sign in
              </Link>{' '}
              or{' '}
              <Link to="/register" className={styles.activeLink}>
                register
              </Link>
              .
            </Typography>
          </div>
        </ShowFor>
      </ProtectedArea>
    </StoryRiver>
  )
}

MyReportsView.propTypes = {
  title: PropTypes.string
}

export default MyReportsView
