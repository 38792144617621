import React from 'react'
import PropTypes from 'prop-types'
import useMediaSize from 'src/hooks/useMediaSize.js'
import FeaturedRiver from 'src/components/FeaturedRiver/index.js'
import RiverHeader from 'src/modules/River/components/RiverHeader'
import styles from './FeaturedContent.module.sass'
import commonStyles from 'src/styles/common.module.sass'
import classNames from 'classnames/bind'

const cx = classNames.bind(styles)

const FeaturedContent = ({ loading, title, views, hide, single = false }) => {
  const mediaSize = useMediaSize()

  return (
    !hide && (
      <div className={styles.container}>
        <section className={cx('content', { [commonStyles.content]: !single })}>
          {!single && <RiverHeader title={title} showFilterMenu={false} />}
          <FeaturedRiver
            view={views}
            loading={loading}
            imageSize={mediaSize}
            getArticleLink={story => `/story/${story.id}`}
            single={single}
          />
        </section>
      </div>
    )
  )
}

FeaturedContent.propTypes = {
  loading: PropTypes.bool,
  title: PropTypes.string,
  views: PropTypes.array,
  hide: PropTypes.bool,
  single: PropTypes.bool
}

export default FeaturedContent
