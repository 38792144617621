import React, { Fragment, useContext, useState } from 'react'
import Typography from 'src/components/ui/Typography/index.js'
import { SessionContext } from 'src/modules/Session/context'
import StorySpinner from 'src/components/StorySpinner/index.js'
import StoryExcerpt from 'src/components/StoryExcerpt/index.js'
import LoadMore from 'src/components/LoadMore/index.js'
import ArticleGroup from 'src/modules/River/components/MyFW/ArticleGroup'
import useViews from 'src/modules/Views/hooks/useViews'
import styles from './GroupedArticles.module.sass'
import { fromNow } from 'src/utils/index.js'
import _get from 'lodash/get'

const VIEWS_PER_PAGE = 10
const INTEREST_REQUEST_CHUNK_SIZE = 10

const buildViewRequest = interests =>
  Object.keys(interests)
    .reduce((prev, category) => {
      const interestValues = interests[category]
      return [
        ...prev,
        ...interestValues.map(interest => ({
          key: `myfw_byGroup::${category}::${interest}`,
          name: 'recommended for you',
          include_related_tags: true,
          tags: {
            [category]: [interest]
          }
        }))
      ]
    }, [])
    .reduce((oldResultArray, item, index) => {
      const resultArray = [...oldResultArray]
      // Get the item chunk position
      const chunkIndex = Math.floor(index / INTEREST_REQUEST_CHUNK_SIZE)

      // Create empty array if it doesn't exist
      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []
      }

      // Add item
      resultArray[chunkIndex].push(item)

      return resultArray
    }, [])

const buildNestedListFromViews = (views, interestRequests) => {
  const requestKeys = interestRequests.map(({ key }) => key)
  return Object.keys(views)
    .filter(view => requestKeys.includes(view))
    .reduce((prev, key) => {
      const { stories } = views[key]
      const [, category, tag] = key.split('::')
      return [...prev, { category, tag, stories }]
    }, [])
    .sort((a, b) => {
      const storiesA = _get(a, `stories.[0].published`, 0)
      const storiesB = _get(b, `stories.[0].published`, 0)
      return storiesB - storiesA
    })
}

const GroupedArticles = props => {
  const [page, setPage] = useState(0)

  const { getInterests } = useContext(SessionContext)
  const interests = getInterests()

  const interestRequests = buildViewRequest(interests)
  const allInterests = [].concat.apply([], interestRequests) // Merge interest chunks
  const { views, viewsError, viewsLoading, appendNewView } = useViews({
    views: interestRequests,
    chunk: true
  })
  const nestedList = React.useMemo(
    () => buildNestedListFromViews(views, allInterests),
    [views, allInterests]
  )

  const hndLoadMore = React.useCallback(page => setPage(page), [page])
  const hasMorePages = React.useMemo(
    () => Math.ceil(nestedList.length / VIEWS_PER_PAGE) > page + 1,
    [nestedList, page]
  )

  React.useEffect(() => {
    appendNewView(buildViewRequest(interests))
  }, [interests])

  if (!Object.keys(interests).length) {
    return (
      <Typography className={styles.emptyNotice}>
        There are no interests.
      </Typography>
    )
  }

  if (viewsLoading) {
    return <StorySpinner />
  }
  if (viewsError) {
    return <p>Please refresh the page and try again</p>
  }
  if (views) {
    return (
      <Fragment>
        {nestedList.slice(0, (page + 1) * VIEWS_PER_PAGE).map(interest => {
          const category =
            interest.category === 'fw_therapeutic_category'
              ? 'drug_classes'
              : interest.category
          const { stories, tag } = interest
          const mostRecentStoryPublishedDate = _get(stories, '[0].published', 0)
          const mostRecentStoryPublishedLabel =
            mostRecentStoryPublishedDate > 0
              ? fromNow(mostRecentStoryPublishedDate)
              : 'Never'
          const groupTitle = `${tag} (Updated: ${mostRecentStoryPublishedLabel})`
          return (
            <Fragment key={groupTitle}>
              <ArticleGroup
                label={groupTitle}
                viewMoreLink={`/river/tag/${category}/${tag}`}
              >
                {stories.length ? (
                  stories
                    .slice(0, 3)
                    .map(story => (
                      <StoryExcerpt
                        key={story.id}
                        id={story.id}
                        to={`/story/${story.id}`}
                        type={story.type}
                        typeTo={`/river/type/${story.type}`}
                        title={story.title}
                        published={story.published}
                        tags={story.tags}
                        congress={story.congress}
                        plus={story.flags.isPaid}
                        source={story.source}
                        likes={story.likes}
                        likedByProfile={story.likedByProfile}
                      />
                    ))
                ) : (
                  <Typography>
                    There are no articles in this interest.
                  </Typography>
                )}
              </ArticleGroup>
            </Fragment>
          )
        })}
        <div className={styles.footer}>
          <LoadMore
            loadMore={hndLoadMore}
            hasMore={hasMorePages}
            isLoading={viewsLoading}
          />
        </div>
      </Fragment>
    )
  }
  return null
}

export default GroupedArticles
