import { useSelector } from 'react-redux'
import useMediaSize from 'src/hooks/useMediaSize'

import PopularPrompts from 'src/modules/popularPrompts/PopularPrompts'
import History from 'src/modules/history/History'
import Faq from 'src/modules/faq/Faq'
import LiveFeedComponent from 'src/modules/LiveFeed/LiveFeed'

import { getDisplayContainer } from '../../ChatPage/ChatPageSelector'

const RightBar = () => {
  const mediaSize = useMediaSize({
    mediaQueries: {
      small: '(max-width: 860px)'
    }
  })
  const displayContainer = useSelector(getDisplayContainer())

  return (
    <div
      data-testid="right-bar"
      style={{
        height: 'calc(100% - 20px)',
        overflowY: 'scroll',
        msOverflowStyle: 'none', // Scrollbar Hidden for IE and Edge
        scrollbarWidth: 'none', // Scrollbar Hidden for Firefox
        '&::-webkit-scrollbar': { display: 'none' } // Scrollbar Hidden for Chrome, Safari and Opera
      }}
    >
      {mediaSize === 'small' ? (
        displayContainer === 'popular' ? (
          <PopularPrompts />
        ) : displayContainer === 'faqs' ? (
          <Faq />
        ) : (
          <History />
        )
      ) : (
        <LiveFeedComponent />
      )}
    </div>
  )
}

export default RightBar
