import Cookies from 'js-cookie'
import { getValue, isEmpty, isInteger, isDefined } from 'src/utils'

import { sessionStorage } from '../constants'

/**
 * Service that handle session management for the entire app.
 * It will use localStorage by default, otherwise it will use cookies.
 */
export class SessionService {
  /**
   * Flag for localStorage support.
   *
   * @type {boolean}
   * @private
   */
  // eslint-disable-next-line no-undef
  _localStorageSupported = true

  /**
   * Root name of the cache.
   *
   * @type {string}
   * @private
   */
  // eslint-disable-next-line no-undef
  _STORE = sessionStorage

  /**
   * Random dummy string to test localStorage support.
   *
   * @type {string}
   * @private
   */
  // eslint-disable-next-line no-undef
  _NULL_DEF = Math.random().toString(36).substring(7)

  /**
   * Singleton service instance.
   *
   * @type {SessionService}
   */

  constructor() {
    this._init()
  }

  /**
   * Expires a Cache.
   *
   * @param cache (string) - The complete cache object
   * @param sectionId - The section of the cache to expire
   * @return void
   * @private
   */
  _expireCache(cache, sectionId) {
    if (sectionId === 'cacheExpirationTime') {
      return
    }

    const cacheExpirationTime = getValue(() => cache['cacheExpirationTime'], {})
    if (isEmpty(cacheExpirationTime[sectionId])) {
      return
    }

    for (const id in cacheExpirationTime[sectionId]) {
      if (cacheExpirationTime[sectionId][id] < Date.now()) {
        delete cache[sectionId][id]
        delete cacheExpirationTime[sectionId][id]
      }
    }
  }

  /**
   * Initialize Session.
   * By default it will try to use the localStorage,
   * if not possible, it will use cookies.
   *
   * @return void
   * @private
   */
  _init() {
    try {
      localStorage.setItem(this._NULL_DEF, this._NULL_DEF)
      localStorage.removeItem(this._NULL_DEF)
      this._localStorageSupported = true
    } catch (e) {
      this._localStorageSupported = false
    }

    this.saveToCache(this._STORE, this._NULL_DEF)
  }

  /**
   * Retrieves a cache section (by reference).
   *
   * @param cache (any) - The complete cache object
   * @param sectionId (string) - The cache section to retrieve
   * @return any - The cache section object
   * @private
   */
  _getCacheSection(cache, sectionId) {
    if (isEmpty(cache[sectionId])) {
      cache[sectionId] = {}
    }

    return cache[sectionId]
  }

  /**
   * Saves the cache to persistent storage.
   *
   * @param cache (any) - The updated cache object to save
   * @return void
   * @private
   */
  _saveCachePersist(cache) {
    for (const sectionId in cache) {
      if (sectionId !== 'cacheExpirationTime') {
        this._expireCache(cache, sectionId)
      }
    }

    const cacheString = JSON.stringify(cache)
    if (this._localStorageSupported) {
      localStorage.setItem(this._STORE, cacheString)
    } else {
      Cookies.set(this._STORE, cacheString)
    }
  }

  /**
   * For any given cached value, set an expiration time.
   *
   * @param id (string) - Identifier of the value
   * @param time (number) - Time in seconds
   * @param cache (any) - The cache object
   * @return void
   * @private
   */
  _setExpiration(id, time, cache) {
    if (!isInteger(time)) {
      return
    }

    const cacheId = 'global'
    const cacheExpirationTime = this._getCacheSection(
      cache,
      'cacheExpirationTime'
    )
    const expireTime = Date.now() + time * 1000
    if (isEmpty(cacheExpirationTime[cacheId])) {
      cacheExpirationTime[cacheId] = {}
    }

    cacheExpirationTime[cacheId][id] = expireTime
  }

  /**
   * Gets cache store name.
   * @returns {string}
   */
  getStoreName() {
    return this._STORE
  }

  /**
   * Delete the complete cache object.
   *
   * @return void
   */
  deleteCacheAll() {
    if (this._localStorageSupported) {
      localStorage.clear()
    } else {
      document.cookie.split(';').forEach(function (c) {
        document.cookie = c
          .replace(/^ +/, '')
          .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`)
      })
    }
  }

  /**
   * Deletes a value from cache.
   *
   * @param id (string) - Identifier of the value to delete
   * @return void
   */
  deleteFromCache(id) {
    const sectionId = 'global'
    const cache = this.getCacheComplete()
    const cacheSection = this._getCacheSection(cache, sectionId)

    if (isDefined(cacheSection[id])) {
      delete cacheSection[id]
    }

    const cacheExpirationTime = this._getCacheSection(
      cache,
      'cacheExpirationTime'
    )
    if (
      isDefined(cacheExpirationTime[sectionId]) &&
      isDefined(cacheExpirationTime[sectionId][id])
    ) {
      delete cacheExpirationTime[sectionId][id]
    }

    this._saveCachePersist(cache)
  }

  /**
   * Deletes a list of values from cache
   * @param idList
   */
  bulkDeleteFromCache(idList = []) {
    return Promise.resolve(idList.forEach(v => this.deleteFromCache(v)))
  }
  /**
   * A debug function to display the cache object.
   *
   * @return void
   */
  debug() {
    console.log('session', {
      cache: this.getCacheComplete(),
      localStorageSupported: this._localStorageSupported
    })
  }

  /**
   * Gets the complete cache object.
   *
   * @return any - The cache object
   */
  getCacheComplete() {
    let cache = '{}'
    let cacheObj

    cache = this._localStorageSupported
      ? getValue(() => localStorage.getItem(this._STORE), cache)
      : getValue(() => Cookies.get(this._STORE), cache)

    try {
      cacheObj = JSON.parse(cache)
    } catch (e) {
      cacheObj = {}
    }

    return cacheObj
  }

  /**
   * Gets a value from the cache.
   *
   * @param id (string) - Identifier of the value to get
   * @param def (any) - The value to get if the required value is found (defaults to null)
   * @return any - Cache item if found, default if not, null if no default
   */
  getFromCache(id, def) {
    const sectionId = 'global'
    const cache = this.getCacheComplete()
    const cacheSection = this._getCacheSection(cache, sectionId)

    if (!isDefined(cacheSection[id])) {
      return getValue(() => def, null)
    }

    return cacheSection[id]
  }
  /**
   * Gets cookie from browser
   *
   * @param cname
   * @returns {string}
   */
  getCookie(cname) {
    let name = cname + '='
    let decodedCookie = decodeURIComponent(document.cookie)
    let ca = decodedCookie.split(';')
    for (const element of ca) {
      let c = element
      while (c.charAt(0) === ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length)
      }
    }
    return ''
  }

  /**
   * Saves a value to the cache.
   *
   * @param id (string) - Identifier of the value to save
   * @param val (any) - The value to save
   * @param expireTime (number) - The number of seconds to store the value, by default it never expires.
   * @return void
   */
  saveToCache(id, val, expireTime = null) {
    const sectionId = 'global'
    const cache = this.getCacheComplete()
    const cacheSection = this._getCacheSection(cache, sectionId)

    if (expireTime) {
      this._setExpiration(id, expireTime, cache)
    }

    cacheSection[id] = val
    this._saveCachePersist(cache)
  }
  /**
   * Function that receives a String with placeholders and replaces them with the provided values
   *
   * @param linkParams
   * linkParams.defaultValues - Obj. with strings used in case CookieService returns empty
   * linkParams.linkVars - String with proper placeholders between brackets {key}
   *
   */
  mapLinkParams(linkParams) {
    let mapObj = {}
    const urlParamsList = linkParams.linkVars?.match(/\{(.*?)\}/g)

    urlParamsList?.forEach(token => {
      let cleanToken = token.replace('{', '').replace('}', '')
      let sessionVal = this.getFromCache(cleanToken, '')
      if (isEmpty(sessionVal))
        mapObj[token] = linkParams.defaultValues[cleanToken] ?? ''
      else mapObj[token] = sessionVal
    })

    return linkParams.linkVars.replace(/{\w+}/g, all => mapObj[all])
  }
}

export default SessionService
