import { createAction, handleActions } from 'redux-actions'
import { generateUid } from 'src/utils/index.js'

export const notificationsEnqueue = createAction('NOTIFICATIONS_ENQUEUE')
export const notificationsDequeue = createAction('NOTIFICATIONS_DEQUEUE')
export const notificationsDismiss = createAction('NOTIFICATIONS_DISMISS')
export const notificationsPop = createAction('NOTIFICATIONS_POP')

export const actions = {
  notificationsEnqueue,
  notificationsDequeue,
  notificationsDismiss,
  notificationsPop
}

const defaultState = {
  notifications: []
}

export default handleActions(
  {
    NOTIFICATIONS_ENQUEUE: (state, { payload: { message, duration } }) => {
      const uid = generateUid()
      return {
        ...state,
        notifications: [{ uid, message, duration }]
      }
    },
    NOTIFICATIONS_DEQUEUE: (state, { payload: uid }) => {
      const idx = state.notifications.findIndex(n => n.uid === uid)
      if (idx === -1) return state
      const notificationsClone = state.notifications.slice()
      notificationsClone.splice(idx, 1)
      return {
        ...state,
        notifications: notificationsClone
      }
    },
    NOTIFICATIONS_DISMISS: state => {
      return {
        ...state,
        notifications: []
      }
    },
    NOTIFICATIONS_POP: state => {
      return {
        ...state,
        notifications: state.notifications.slice(1)
      }
    }
  },
  defaultState
)
