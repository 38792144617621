import { compose } from 'redux'
import { connect } from 'react-redux'
import { actions as landingPageActions } from 'src/modules/GatedLandingPage/reducer.js'
import Landing from 'src/modules/GatedLandingPage/pages/Landing'
import { withServices } from 'src/services/ServiceContext.js'
import { withTitle } from 'src/utils/hocs.js'

const mapStateToProps = state => {
  return {
    content: state.landingPage.content.config,
    consent: state.landingPage.consent,
    busy: state.landingPage.loading
  }
}

const mapDispatchToProps = dispatch => ({
  loadCompanyContent: data =>
    dispatch(landingPageActions.requestLandingCompanyData(data))
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withServices(resolve => ({
    Piwik: resolve('Piwik')
  })),
  withTitle
)(Landing)
