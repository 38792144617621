import React from 'react'
import Page from 'src/components/Page/index.js'
import Typography from 'src/components/ui/Typography/index.js'
import styles from 'src/modules/Static/Static.module.sass'
import cx from 'classnames'
import { siteName } from 'src/utils/oneCodeBase.js'

const DeviceConfirmationSentPage = () => {
  return (
    <Page className={cx(styles.static, styles.centered)}>
      <Typography type="title" className={styles.title} paragraph>
        New device
      </Typography>
      <Typography type="body1" className={styles.paragraph} paragraph>
        It looks like you are accessing FirstWord {siteName} from another
        device. An email has been sent to you. Please click the secure link in
        the email to register this device with FirstWord {siteName}
      </Typography>
    </Page>
  )
}

export default DeviceConfirmationSentPage
