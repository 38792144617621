import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ActionIcon from 'src/components/ActionButtons/components/ActionIcon'
import { actions } from 'src/components/ActionButtons/reducer'
import { useDispatch } from 'react-redux'
import usePiwik from 'src/hooks/usePiwik'

const LikeButton = ({
  articleId,
  liked,
  likeCount,
  actionButtonsSettings = {},
  action = () => {},
  ...props
}) => {
  const dispatch = useDispatch()
  const { like_button = {} } = actionButtonsSettings
  const showCount = like_button?.show_count ?? false
  const displayCount = like_button?.display_count ?? 0
  const iconText =
    !showCount || displayCount >= likeCount ? null : `(${likeCount})`

  const iconPath = !liked
    ? 'svg/material-design-icons/content/like'
    : 'svg/material-design-icons/content/like_clicked'

  const onClick = () => {
    action()
    dispatch(actions.updateLikeRequest(articleId))
  }

  return (
    <ActionIcon
      {...props}
      width="auto"
      action={onClick}
      iconText={iconText}
      iconPath={iconPath}
      articleId={articleId}
      buttonType={!liked ? 'like' : 'unlike'}
    />
  )
}

LikeButton.propTypes = {
  action: PropTypes.func,
  articleId: PropTypes.any,
  likeCount: PropTypes.number,
  liked: PropTypes.bool,
  actionButtonsSettings: PropTypes.object
}

const RiverLikeActionButton = ({
  likes = 0,
  likedByProfile = false,
  action = () => {},
  ...props
}) => {
  const [liked, setLiked] = useState(likedByProfile)
  const [likeCount, setLikeCount] = useState(likes)

  const onLikeUpdate = () => {
    const likeUpdated = !liked
    setLiked(likeUpdated)

    if (likeUpdated) {
      //liked
      setLikeCount(item => item + 1)
    } else {
      //unliked
      setLikeCount(item => item - 1)
    }

    action()
  }

  return (
    <LikeButton
      {...props}
      likeCount={likeCount}
      liked={liked}
      action={onLikeUpdate}
    />
  )
}

RiverLikeActionButton.propTypes = {
  likes: PropTypes.number,
  likedByProfile: PropTypes.bool,
  action: PropTypes.func
}

const LikeActionButton = ({ ...props }) => {
  if (props?.singleStory) return <LikeButton {...props} />
  return <RiverLikeActionButton {...props} />
}

LikeActionButton.propTypes = {
  singleStory: PropTypes.bool
}

export default LikeActionButton
