import { siteName } from 'src/utils/oneCodeBase.js'

export default {
  add_interests: {
    headerColor: '#007e67ff',
    text: `Adding interests is an exclusive feature to FirstWord ${siteName}+ subscribers!`,
    tracking: {
      anon: 'add-interest-article-anon',
      free: 'add-interest-article-free',
      type: 'paywall'
    }
  },
  remove_interests: {
    headerColor: '#007e67ff',
    text: `Deleting interests is an exclusive feature to FirstWord ${siteName}+ subscribers!`,
    tracking: {
      anon: 'remove-interest-article-anon',
      free: 'remove-interest-article-free',
      type: 'paywall'
    }
  },
  search: {
    headerColor: '#2d3e50ff',
    text: 'The Search feature is exclusive to FirstWord subscribers!',
    tracking: {
      anon: 'search-anon',
      free: null,
      type: 'paywall'
    }
  },
  my_reports: {
    headerColor: '#2d3e50ff',
    text: 'My Reports is exclusive to FirstWord subscribers!',
    tracking: {
      anon: 'my_reports_anon',
      free: 'my_reports_free',
      type: 'paywall'
    }
  },
  ai_paywall: {
    headerColor: '#2d3e50ff',
    text: 'FirstWord AI is currently only available for beta testing',
    tracking: {
      anon: 'firstword-ai-anon',
      free: 'firstword-ai-free',
      type: 'paywall'
    }
  },
  plus_newsletter: {
    headerColor: '#007e67ff',
    tracking: {
      free: 'plus-newsletter-free',
      anon: null,
      type: 'paywall'
    }
  },
  plus_article: {
    headerColor: '#007e67ff',
    text: `This article is exclusive to FirstWord ${siteName}+ subscribers!`,
    tracking: {
      anon: 'plus-article-anon',
      free: 'plus-article-free',
      type: 'paywall'
    }
  },
  article_limit: {
    headerColor: '#2d3e50ff',
    text: "You've reached your monthly limit of free articles!",
    tracking: {
      anon: 'free-article-limit-anon',
      free: null,
      type: 'paywall'
    },
    showCounter: true
  },
  article_counter: {
    headerColor: '#2d3e50ff',
    text: 'Become a member for access to free articles and newsletters!',
    tracking: {
      anon: 'free-article-counter-anon',
      free: null,
      type: 'pop-up'
    },
    showCounter: true,
    showRegistrationButton: true
  }
}
