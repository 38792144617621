export const deal1 = [
  {
    'Date Announced': '5-Jan-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Y-Biologics',
    'Country (Acquiring Company/Partner)': 'Korea, South',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': '3D Medicines',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '2',
    'Total Deal Value ($m)': '87',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Bispecific antibodies',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'YBL-013',
    'Indication(s)': 'Cancer',
    'Target(s)': 'CD3xPD-L1',
    'Deal Structure':
      'Under the terms of this Agreement, Y-Biologics will receive an upfront fee of $2 million and is eligible to receive up to $85 million in additional development, regulatory, commercialization and sales milestone payments as well as up to double-digit royalties on net sales in the Territory. Both Parties will share part of IND development cost. In the meantime, 3D Medicines will have an exclusive right to develop, manufacture, and commercialize for YBL-013 in Greater China, with potential expansion collaboration opportunity in US and other regions.',
    'FW Link1':
      'Y-Biologics signed License Agreement with 3D Medicines for T cell bispecific engager in Greater China territory;;https://firstwordpharma.com/story/5191949'
  },
  {
    'Date Announced': '5-Jan-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Blue Earth Diagnostics',
    'Country (Acquiring Company/Partner)': 'Italy',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Scintomics GmBH',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Radiopharmaceuticals',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'F-rhPSMA-7.3',
    'Indication(s)': 'Prostate cancer',
    'Target(s)': 'PSMA',
    'Deal Structure':
      'Under terms of the agreement, Blue Earth Diagnostics has exercised an option to acquire exclusive, worldwide rights to therapeutic applications of novel radiohybrid Prostate-Specific Membrane Antigen (rhPSMA) technology in prostate cancer. Blue Earth previously acquired exclusive rights to rhPSMA imaging technology in 2018 and now has two investigational Phase 3 clinical trials underway to investigate the use of lead candidate 18F-rhPSMA-7.3 for PET imaging in prostate cancer',
    'FW Link1':
      'Blue Earth Diagnostics Acquires Exclusive, Worldwide Rights to Therapeutic Applications of Scintomics’ Radiohybrid Prostate-Specific Membrane Antigen (rhPSMA) Technology for Prostate Cancer;;https://firstwordpharma.com/story/5192181'
  },
  {
    'Date Announced': '5-Jan-2021',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Hologic',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Biotheranostics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '230',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'Lead Asset Stage/Phase': 'Marketed',
    'Indication(s)': 'Women Cancers',
    'FW Link1':
      'Hologic to Acquire Biotheranostics, Leader in Molecular Tests for Breast and Metastatic Cancers, for Approximately $230 Million;;https://firstwordhealthtech.com/story/5192423'
  },
  {
    'Date Announced': '6-Jan-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Targovax',
    'Country (Acquiring Company/Partner)': 'Norway',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'IOVaxis Therapeutics',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'China and Singapore',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Vaccines',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'TG01, TG02',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'RAS',
    'Deal Structure':
      "On 8 January 2020 Targovax and IOVaxis announced that they had entered into an exclusive option agreement with 12-month validity for the development and commercialization of Targovax's TG vaccines in Greater China and Singapore. To accommodate the delay caused by these unforeseen circumstances, Targovax has granted to IOVaxis an extension to the license option period by 3 months. Otherwise, the option agreement remains unchanged and in force.",
    'FW Link1':
      'Targovax grants IOVaxis 3 months extension to the exclusive license option for TG mutant RAS vaccines in Greater China and Singapore;;https://firstwordpharma.com/story/5192747'
  },
  {
    'Date Announced': '6-Jan-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Gilead Sciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Oxford BioTherapeutics',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Hematologic tumours, Solid tumours',
    'Deal Structure':
      'Under the terms of the agreement, OBT will receive an upfront payment and will be eligible to receive additional payments based on achievement of certain discovery, clinical and regulatory milestones, as well as royalties on future potential sales.',
    'FW Link1':
      'Kite and Oxford BioTherapeutics Establish Cell Therapy Research Collaboration in Blood Cancers and Solid Tumors;;https://firstwordpharma.com/story/5192811'
  },
  {
    'Date Announced': '6-Jan-2021',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'Genetron',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner':
      'Chia Tai Tanqing, subsidiary of Sino Biopharmaceutical',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'Lead Asset Stage/Phase': 'Marketed',
    'Indication(s)': 'Hepatocellular carcinoma',
    'Deal Structure':
      'Promotional fee based on a percentage of revenues generated from the collaboration',
    'FW Link1':
      'Genetron Health Announces Exclusive Strategic Partnership with Sino Biopharm Subsidiary for HCCscreen in China;;https://firstwordhealthtech.com/story/5193007'
  },
  {
    'Date Announced': '6-Jan-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Erasca',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'NiKang Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'ERAS-601, ERAS-007',
    'Indication(s)': 'Cancer',
    'Target(s)': 'SHP2, ERK',
    'Deal Structure':
      'Under the terms of the NiKang agreement, Erasca licensed exclusive, worldwide rights to ERAS-601 and all other SHP2 inhibitors developed by NiKang. Under the terms of the Asana agreement, Erasca acquired exclusive, worldwide rights to ERAS-007 and all other ERK inhibitors developed by Asana. Financial terms of both agreements were not disclosed.',
    'FW Link1':
      'Erasca Unveils Its First Strategy to Erase Cancer, Accelerated by Expansion of Precision Oncology Pipeline and Dosing of First Patient;;https://firstwordpharma.com/story/5193053'
  },
  {
    'Date Announced': '6-Jan-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Exacis Biotherapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Factor Bioscience',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy, mRNA',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'FW Link1':
      'Exacis Biotherapeutics Announces Its Launch and mRNA Technology In-Licensing For Targeted CAR-NK And CAR-T Cell Cancer Therapies;;https://firstwordpharma.com/story/5193495'
  },
  {
    'Date Announced': '6-Jan-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Xencor',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner':
      'The University of Texas MD Anderson Cancer Center',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Bispecific antibodies',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Target(s)': 'CD3',
    'Deal Structure':
      'Xencor has certain exclusive options to license worldwide rights to develop and commercialize potential new medicines arising from the research collaboration. For programs not licensed by Xencor, Xencor will receive a portion of future payments received by MD Anderson.',
    'FW Link1':
      'Xencor and MD Anderson Enter Strategic Collaboration to Develop Novel T Cell-Engaging Bispecific Antibodies for Potential Treatment of Patients with Cancer;;https://firstwordpharma.com/story/5193569'
  },
  {
    'Date Announced': '7-Jan-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Soricimed BioPharma',
    'Country (Acquiring Company/Partner)': 'Canada',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Orano Med',
    'Country (Target Company/Partner)': 'Canada',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Radiopharmaceuticals',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'TRPV6',
    'FW Link1':
      'Soricimed and Orano Med Announce Collaboration to Develop New Targeted Cancer Treatments;;https://firstwordpharma.com/story/5194339'
  },
  {
    'Date Announced': '11-Jan-2021',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Sanofi',
    'Country (Acquiring Company/Partner)': 'France',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Kymab',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Upfront Value ($m)': '1,100',
    'Total Deal Value ($m)': '1,450',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'KY1044',
    'Indication(s)': 'Advanced malignancies',
    'Target(s)': 'ICOS agonist',
    'FW Link1':
      'Sanofi to buy Kymab for $1.1 billion upfront;;https://firstwordpharma.com/story/5196033'
  },
  {
    'Date Announced': '11-Jan-2021',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'bluebird bio',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': '2seventy bio',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'CAR T-cell immunotherapy',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'bb21217',
    'Indication(s)': 'multiple myeloma',
    'Target(s)': 'BCMA',
    'Deal Structure':
      'bluebird bio announced its intent to separate its severe genetic disease and oncology businesses into differentiated and independent publicly traded companies. bluebird bio, Inc. will retain focus on severe genetic disease (SGD) and will launch its oncology business (“Oncology Newco”) as a new entity',
    'FW Link1':
      'Bluebird bio to spin off oncology unit as part of split;;https://firstwordpharma.com/story/5196077'
  },
  {
    'Date Announced': '11-Jan-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Turning Point Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Zai Lab',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '25',
    'Total Deal Value ($m)': '336',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'TPX-0022',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'MET, CSF1R, SRC',
    'Deal Structure':
      "Under the terms of the new agreement, Zai Lab will obtain exclusive rights to develop and commercialize TPX-0022, Turning Point's MET, SRC and CSF1R inhibitor, in Greater China, which includes mainland China, Hong Kong, Macau and Taiwan. Turning Point will receive a $25 million upfront payment, with up to approximately $336 million in potential development, regulatory and sales-based milestone payments. Turning Point will also be eligible to receive mid-teen- to low-twenty-percent royalties based on annual net sales of TPX-0022 in Greater China. In addition, Turning Point will have the right of first negotiation to develop and commercialize an oncology drug candidate discovered by Zai Lab.",
    'FW Link1':
      'Turning Point and Zai Lab Broaden Collaboration;;https://firstwordpharma.com/story/5196081'
  },
  {
    'Date Announced': '11-Jan-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'GRAIL',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Amgen, AstraZeneca, BMS',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'MRD',
    'FW Link1':
      'GRAIL Announces Collaborations with Amgen, AstraZeneca, and Bristol Myers Squibb to Evaluate Cancer Early Detection Technology for Minimal Residual Disease;;https://firstwordhealthtech.com/story/5196309'
  },
  {
    'Date Announced': '11-Jan-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Aadi Bioscience',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'EOC Pharma',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Total Deal Value ($m)': '271',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'ABI-009 (FYARRO)',
    'Indication(s)': 'Mmalignant PEComa',
    'Target(s)': 'mTOR',
    'Deal Structure':
      'Under the terms of the agreement, EOC Pharma will obtain exclusive rights to develop and commercialize ABI-009 in Greater China. Aadi will receive an upfront payment, regulatory and sales-based milestone payments totaling up to $271M as well as tiered royalties based on annual net sales of ABI-009.',
    'FW Link1':
      'Aadi Bioscience and EOC Pharma Announce License and Collaboration Agreement for ABI-009 (FYARROTM) in Greater China;;https://firstwordpharma.com/story/5196611'
  },
  {
    'Date Announced': '11-Jan-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'BeiGene',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Strand Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '5',
    'Total Deal Value ($m)': '277',
    'Geographic Remit of Deal':
      'Asia (excluding Japan), Australia, and New Zealand',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'mRNA',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      "Under the terms of the agreement, Strand will receive an upfront cash payment of $5 million and will also be eligible to receive additional near-term payments totaling up to $28 million, inclusive of BeiGene's exercise of its options to the two programs following initial proof-of-concept studies. Additionally, Strand is eligible to receive payments from BeiGene based upon the achievement of certain development, regulatory, and sales milestones for a total deal value of up to $277 million, together with tiered royalties on any product sales in the licensed territory. In connection with the agreement, Strand also received investments of $10 million, including $5 million from BeiGene.",
    'FW Link1':
      'Strand Therapeutics and BeiGene Enter into Agreement to Develop Solid Tumor Immuno-Oncology Therapeutics Based on Strand’s Next-Generation, Multi-Functional mRNA Technology;;https://firstwordpharma.com/story/5196707'
  },
  {
    'Date Announced': '11-Jan-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Illumina',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner':
      'BMS, Kura Oncology, Myriad Genetics, Merck & Co.',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'Lead Asset Stage/Phase': 'Phase III',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "With genetic sequencing at the forefront of precision medicine, Illumina (NASDAQ: ILMN) is pleased to announce a portfolio of new and expanded oncology partnerships that further the company's commitment to develop standardized, globally distributable tools for precision oncology.",
    'FW Link1':
      'Illumina Announces New and Expanded Oncology Partnerships with Bristol Myers Squibb, Kura Oncology, Myriad Genetics, and Merck to Advance Comprehensive Genomic Profiling;;https://firstwordhealthtech.com/story/5196941'
  },
  {
    'Date Announced': '11-Jan-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Mirati Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Resolution Bioscience',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'Indication(s)': 'Non-small cell lung cancer',
    'Target(s)': 'KRAS',
    'FW Link1':
      'Resolution Bioscience Announces Companion Diagnostic Collaboration with Mirati Therapeutics;;https://firstwordhealthtech.com/story/5197029'
  },
  {
    'Date Announced': '11-Jan-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Novartis',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'BeiGene',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '650',
    'Total Deal Value ($m)': '2,200',
    'Geographic Remit of Deal':
      'North America, EU, UK, Norway, Switzerland, Iceland, Liechtenstein, Russia, Japan',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'tislelizumab',
    'Indication(s)':
      "classical Hodgkin's lymphoma, urothelial carcinoma, non-small cell lung cancer, hepatocellular carcinoma, esophageal squamous cell carcinoma, gastric cancer, nasopharyngeal carcinoma",
    'Target(s)': 'PD-1',
    'Deal Structure':
      'Under the terms of the agreement, Novartis will obtain the development and commercialization rights to tislelizumab in the United States, Canada, Mexico, the European Union, United Kingdom, Norway, Switzerland, Iceland, Liechtenstein, Russia, and Japan in exchange for an upfront payment of USD 650 million plus royalties and milestone payments. BeiGene will retain the rights to tislelizumab in China and other countries.',
    'FW Link1':
      "Novartis pays $650 million upfront for rights to BeiGene's anti-PD-1 drug tislelizumab;;https://firstwordpharma.com/story/5197157",
    'FW Link2':
      'Termination of agreement announced September 19, 2023;;https://firstwordpharma.com/story/5781942/'
  },
  {
    'Date Announced': '11-Jan-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Adaptive Biotechnologies',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'AstraZeneca',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Target screening',
    'Lead Asset Stage/Phase': 'Discovery',
    'Deal Structure':
      'Adaptive will receive from AstraZeneca quarterly payments plus sequencing and data mapping fees. In addition, AstraZeneca has an option to enter into a separate agreement with Adaptive for the development and commercialization of a companion diagnostic or therapeutic application based on T-MAP data.',
    'FW Link1':
      'Adaptive Biotechnologies to Collaborate with AstraZeneca in Mapping the Immune Response to Cancer Antigens to Potentially Inform the Development of Novel Diagnostics and Therapeutics;;https://firstwordhealthtech.com/story/5197177'
  },
  {
    'Date Announced': '12-Jan-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Sanofi',
    'Country (Acquiring Company/Partner)': 'France',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Biond Biologics',
    'Country (Target Company/Partner)': 'Israel',
    'Region (Target Company/Partner)': 'Africa',
    'Upfront Value ($m)': '125',
    'Total Deal Value ($m)': '1,125',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'BND-22',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'ILT2',
    'Deal Structure':
      'Development, regulatory, sales milestones, tiered double digit royalty payments',
    'FW Link1':
      'Sanofi, Biond Biologics pair up on ILT2-targeting cancer drug BND-22;;https://firstwordpharma.com/story/5197489'
  },
  {
    'Date Announced': '12-Jan-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Boehringer Ingelheim',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Enara Bio',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Total Deal Value ($m)': '876',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Target screening',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Lung cancer, gastrointestinal cancer',
    'Deal Structure':
      'Under the agreement, Boehringer Ingelheim has the option to license Dark Antigens discovered and validated by Enara Bio. Boehringer Ingelheim will also be responsible for all non-clinical and clinical development, as well as commercialization of associated cancer immunotherapies, including therapeutic vaccines and T-cell redirecting biologics. Enara Bio retains rights to use any discovered antigens for use in cell therapy-based products.',
    'FW Link1':
      'Boehringer, Enara Bio to discover cancer-linked "dark antigens" for immunotherapies;;https://firstwordpharma.com/story/5197665'
  },
  {
    'Date Announced': '12-Jan-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'IGM Biosciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Medivir AB',
    'Country (Target Company/Partner)': 'Sweden',
    'Region (Target Company/Partner)': 'Europe',
    'Upfront Value ($m)': '1',
    'Total Deal Value ($m)': '353',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecule',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'birinapant',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'IAP',
    'Deal Structure':
      'Under terms of the agreement, Medivir will receive an upfront payment of $1 million upon signing the agreement, followed by an additional $1.5 million when birinapant is included by IGM in clinical Phase I studies. The terms of the agreement also entitle Medivir, should birinapant be successfully developed and approved, to receive milestone payments up to a total of approximately $350 million, plus tiered royalties from the mid-single digits up to mid-teens on net sales.',
    'FW Link1':
      'IGM Biosciences Enters into Exclusive Licensing Agreement with Medivir for Birinapant;;https://firstwordpharma.com/story/5197695'
  },
  {
    'Date Announced': '12-Jan-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'POINT Biopharma',
    'Country (Acquiring Company/Partner)': 'Canada',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'CanProbe',
    'Country (Target Company/Partner)': 'Canada',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Radiopharmaceuticals',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Lutetium - 177 Octreotate',
    'Indication(s)': 'neuroendocrine cancer',
    'FW Link1':
      'POINT Biopharma Signs Exclusive License Agreement with CanProbe for the Commercialization of a Neuroendocrine Tumor Treatment;;https://firstwordpharma.com/story/5198317'
  },
  {
    'Date Announced': '12-Jan-2021',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Diaccurate',
    'Country (Acquiring Company/Partner)': 'France',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Biokinesis',
    'Country (Target Company/Partner)': 'France',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecule',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'DIACC2010',
    'Indication(s)': 'hematological cancers',
    'Target(s)': 'KIF20A',
    'FW Link1':
      'DIACCURATE acquires BIOKINESIS to create a sole-in-class biotech player in oncology and immunotherapy;;https://firstwordpharma.com/story/5198459'
  },
  {
    'Date Announced': '13-Jan-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Astellas',
    'Country (Acquiring Company/Partner)': 'Japan',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Actinium Pharmaceuticals',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Radiopharmaceuticals',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Solid tumours',
    'FW Link1':
      'Aiming for the development of theranostics, which integrates diagnostics and therapeutics, as one of the Rx+ businesses;;https://firstwordpharma.com/story/5198947'
  },
  {
    'Date Announced': '13-Jan-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Takeda',
    'Country (Acquiring Company/Partner)': 'Japan',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'KSQ Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Total Deal Value ($m)': '500',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Target screening',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      'Under the terms of the agreement, upfront and potential preclinical milestones have the potential to exceed $100 million. In addition, KSQ will be eligible for additional option payments along with development and commercialization milestone payments. Depending on the target, these option and milestone payments can reach up to more than $400 million per program. KSQ is also eligible to receive tiered royalties on net sales of each approved product. KSQ and Takeda can collaborate on IND-enabling activities, with clinical development led by Takeda to explore multiple modalities. Takeda will assume responsibility for funding all development and commercialization activities. KSQ will have the option to participate in cost/profit sharing on one of the two products based on the T-cell targets previously identified and validated by KSQ, in the U.S. and retain royalties on all ex-U.S. sales for that product.',
    'FW Link1':
      'Takeda, KSQ ink deal to develop immune-based therapies for cancer;;https://firstwordpharma.com/story/5199101'
  },
  {
    'Date Announced': '13-Jan-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Otsuka',
    'Country (Acquiring Company/Partner)': 'Japan',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Merck & Co.',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecule',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'SHP2',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      'In exchange for providing MSD with an exclusive global license to their small molecule SHP2 inhibitor candidates, Taiho and Astex will receive an option-exercise fee payment and will be eligible to receive further undisclosed payments contingent upon the achievement of clinical, regulatory and sales milestones for SHP2 products, as well as tiered royalties on sales.',
    'FW Link1':
      'Taiho and Astex Extend Strategic Oncology Collaboration with MSD Granting Exclusive License to SHP2 Program;;https://firstwordpharma.com/story/5199223'
  },
  {
    'Date Announced': '13-Jan-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Hibercell',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Biodesix',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'ELISA',
    'Indication(s)': 'Breast cancer',
    'Target(s)': 'Anti-β Glucan IgG Antibody',
    'FW Link1':
      'HiberCell and Biodesix Initiate Broad Collaboration for Companion Diagnostic Discovery, Development and Commercialization;;https://firstwordhealthtech.com/story/5199483'
  },
  {
    'Date Announced': '13-Jan-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Exact Sciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'The Translational Genomics Research Institute',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'FW Link1':
      'Exact Sciences Licenses Targeted Digital Sequencing Method to Extend Leadership in Precision Oncology;;https://firstwordhealthtech.com/story/5199753'
  },
  {
    'Date Announced': '14-Jan-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Invitae Corporation',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'BMS, J&J, Novartis, Roche',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'Indication(s)': 'Acute myeloid leukaemia',
    'Target(s)': 'MRD',
    'Deal Structure':
      'The companies are working together to standardize how MRD data is generated and assessed in clinical trials helping to better establish the clinical utility of MRD as a biomarker in AML.',
    'FW Link1':
      'Leading Biopharmaceutical Companies Select Invitae to Develop Standardized Panel to Detect Molecular Residual Disease (MRD) in Patients with Acute Myeloid Leukemia (AML);;https://firstwordhealthtech.com/story/5200379'
  },
  {
    'Date Announced': '14-Jan-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Nucleai',
    'Country (Acquiring Company/Partner)': 'Israel',
    'Region (Acquiring Company/Partner)': 'Africa',
    'Target Company/Partner': 'Debiopharm',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial intelligence',
    'FW Link1':
      "Nucleai and Debiopharm Launch Research Collaboration Utilizing Nucleai's Ai-Powered Biomarker Platform;;https://firstwordhealthtech.com/story/5200629"
  },
  {
    'Date Announced': '14-Jan-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Henlius Biotech',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Chiome Bioscience',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Target(s)': 'TROP-2',
    'Deal Structure':
      'According to the agreement, Henlius will obtain the exclusive right to research, develop, manufacture and commercialize the Licensed Products in all human therapeutics and diagnostics applications (except radioimmunotherapy and photoimmunotherapy) in the licensed territory, and right to sublicense to third parties. Henlius also has an exclusive licensee option to the global right excluding China',
    'FW Link1':
      'Henlius Has Reached an Exclusive License Agreement with Chiome for Antibodies Targeting Human TROP2;;https://firstwordpharma.com/story/5201823'
  },
  {
    'Date Announced': '18-Jan-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Guardant Health',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': "Vall d'Hebron Institute of Oncology",
    'Country (Target Company/Partner)': 'Spain',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Spain',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'Lead Asset Stage/Phase': 'Marketed',
    'FW Link1':
      'Guardant Health and Vall d’Hebron Institute of Oncology Announce Partnership to Establish First Guardant-Based Liquid Biopsy Testing Service in Europe;;https://firstwordhealthtech.com/story/5202249'
  },
  {
    'Date Announced': '19-Jan-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Eli Lilly',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': "Merus' Biclonics",
    'Country (Target Company/Partner)': 'Netherlands',
    'Region (Target Company/Partner)': 'Europe',
    'Upfront Value ($m)': '60',
    'Total Deal Value ($m)': '1,600',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Bispecific antibodies',
    'Lead Asset Stage/Phase': 'Discovery',
    'Target(s)': 'CD3',
    'Deal Structure':
      'Under the terms of the agreement, Merus will lead discovery and early stage research activities while Loxo Oncology at Lilly will be responsible for additional research, development and commercialization activities. Merus will receive an upfront cash payment of $40 million, as well as an equity investment by Lilly of $20 million in Merus common shares. Merus is also eligible to receive up to $540 million in potential development and commercialization milestones per product, for a total of up to approximately $1.6 billion for three products, as well as tiered royalties ranging from the mid-single to low-double digits on product sales should Lilly successfully commercialize a therapy from the collaboration.',
    'FW Link1':
      'Lilly, Merus to jointly develop T-cell re-directing bispecific antibodies;;https://firstwordpharma.com/story/5202953'
  },
  {
    'Date Announced': '19-Jan-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'PT Etana Biotechnologies',
    'Country (Acquiring Company/Partner)': 'Indonesia',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Innovent Biologics',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Indonesia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Byvasda',
    'Indication(s)':
      'non-small cell lung cancer, metastatic colorectal cancer, glioblastoma, renal cell carcinoma, cervical cancer, epithelial ovarian, fallopian tube, primary peritoneal cancer',
    'Target(s)': 'VEGF',
    'Deal Structure':
      'Innovent will receive milestones for development and commercialization as well as double-digit royalties on net sales',
    'FW Link1':
      'Innovent Announces an Out-license Agreement with PT Etana Biotechnologies Indonesia to Launch BYVASDA (Bevacizumab Biosimilar) in Indonesia;;https://firstwordpharma.com/story/5203159'
  },
  {
    'Date Announced': '19-Jan-2021',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'Sirtex Medical',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'OncoSec Medical',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '5',
    'Total Deal Value ($m)': '30',
    'Geographic Remit of Deal': 'United States',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Gene Therapy',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'tavokinogene telseplasmid',
    'Indication(s)': 'Melanoma, breast cancer',
    'Target(s)': 'IL-12',
    'Deal Structure':
      "Under the terms of the agreement, Sirtex will pay OncoSec $5 million for a non-exclusive option to co-promote TAVO in combination with KEYTRUDA® in patients with anti-PD-1 checkpoint refractory metastatic melanoma in the U.S. If exercised, this option would require Sirtex to pay an additional $20 million in cash and purchase $5 million in common stock of the Company at a market price. The option must be exercised by Sirtex within 90 days of OncoSec's filing a Biologics License Application (BLA) with the FDA and carries a co-promote term of eight years. OncoSec reserves the right to grant others co-promotion rights, as well as the right to buy back the co-promotion rights for a certain amount determined by the remaining number of years left in the term, but not to exceed $45 million.",
    'FW Link1':
      'OncoSec Enters into Option Agreement with Sirtex Medical to Co-Promote TAVO;;https://firstwordpharma.com/story/5203797'
  },
  {
    'Date Announced': '20-Jan-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Immunotech Biopharm',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'T-Cure Bioscience',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': '800TCR',
    'Indication(s)': 'Renal cell carcinoma',
    'Target(s)': 'HERV-E',
    'Deal Structure':
      'Under the terms of the agreement, Immunotech will make an upfront payment with T-Cure being eligible to receive additional future development milestone payments and tiered royalties on net sales of the product.',
    'FW Link1':
      'T-Cure Bioscience and Immunotech Biopharm Ltd Announce License Agreement for Novel HERV-E T Cell Receptor Therapy;;https://firstwordpharma.com/story/5204371'
  },
  {
    'Date Announced': '20-Jan-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Elpiscience',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'TRIGR Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '7',
    'Total Deal Value ($m)': '117',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Bispecific antibodies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'TR009',
    'Indication(s)': 'Colorectal cancer, gastric cancer',
    'Deal Structure':
      "Under the terms of the agreement, TRIGR will receive an upfront cash payment of $7 million and is eligible to receive additional development and commercial milestones of $110 million plus royalties on annual net sales of TR009. Elpiscience obtains the exclusive development and commercialization rights of TR009 for Greater China across all oncology indications and will lead the clinical development and commercialization by leveraging on its translational science, clinical and regulatory experience to accelerate the path to approval of TR009 in its territory. A Joint Development Committee (JDC) will be formed to collaborate and harmonize TR009's clinical development globally.",
    'FW Link1':
      'TRIGR Therapeutics and Elpiscience Biopharmaceuticals Announce $117 Million Exclusive China Licensing Transaction for TR009, a Phase 1B Dual Angiogenic Bispecific Antibody Targeting DLL4;;https://firstwordpharma.com/story/5204493'
  },
  {
    'Date Announced': '20-Jan-2021',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Turnstone Biologics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Myst Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy',
    'Lead Asset Stage/Phase': 'Preclinical',
    'FW Link1':
      'Turnstone Biologics Acquires Novel Cell Therapy Platform;;https://firstwordpharma.com/story/5204613'
  },
  {
    'Date Announced': '20-Jan-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Merck & Co.',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'MyID',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'United States',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Digital Health',
    'FW Link1':
      'MyID Launches Partnership with Merck;;https://firstwordhealthtech.com/story/5204821'
  },
  {
    'Date Announced': '26-Jan-2021',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'Asieris Pharmaceuticals',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Photocure ASA',
    'Country (Target Company/Partner)': 'Norway',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Hexvix',
    'Indication(s)': 'Bladder cancer',
    'FW Link1':
      'Asieris and Photocure Expand Strategic Partnership to Bladder Cancer Diagnosis and Surgery in Mainland China and Taiwan;;https://firstwordpharma.com/story/5208959'
  },
  {
    'Date Announced': '26-Jan-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Kiromic BioPharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Longwood University',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'chPD1',
    'Target(s)': 'PD-1',
    'FW Link1':
      'Kiromic Announces License Agreement With Longwood University for chPD1;;https://firstwordpharma.com/story/5209627'
  },
  {
    'Date Announced': '27-Jan-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Forus Therapeutics',
    'Country (Acquiring Company/Partner)': 'Canada',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Karyopharm Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Canada',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Xpovio',
    'Indication(s)': 'Multiple melanoma, lymphoma',
    'FW Link1':
      'New Canadian Pharmaceutical Company Brings Novel Cancer Therapies to Market;;https://firstwordpharma.com/story/5210427'
  },
  {
    'Date Announced': '28-Jan-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Merck & Co.',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Artiva Biotherapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '30',
    'Total Deal Value ($m)': '1,881',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy',
    'Lead Asset Stage/Phase': 'Discovery',
    'Deal Structure':
      'Under the terms of the agreement, Artiva will receive a $30 million upfront payment for the first two programs and an additional $15 million payment if Merck exercises its option for a third program. Artiva is also eligible to receive future development and commercial milestones of up to $612 million per program and royalties are payable by Merck on worldwide sales of any product derived from the collaboration. Merck agreed to provide research funding to Artiva for each of the programs under the collaboration.',
    'FW Link1':
      'Merck & Co. partners with Artiva on NK cell-based treatments for cancer;;https://firstwordpharma.com/story/5211057'
  },
  {
    'Date Announced': '28-Jan-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Neuvogen',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'ImaginAb',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'CD8',
    'Deal Structure':
      "Under the terms of the agreement, ImaginAb will work with NEUVOGEN to implement clinical doses of 89Zr CD8 Immuno-PET imaging agents into their therapeutic vaccine clinical trials, and provide technical and clinical support to NEUVOGEN's development teams. ImaginAb will receive license fees and other contingent payments. No other terms were disclosed.",
    'FW Link1':
      'ImaginAb and NEUVOGEN Announce Licensing Agreement for CD8 ImmunoPET Technology;;https://firstwordpharma.com/story/5211379'
  },
  {
    'Date Announced': '29-Jan-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Recordati',
    'Country (Acquiring Company/Partner)': 'Italy',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Tolmar',
    'Country (Target Company/Partner)': 'Ireland {Republic}',
    'Region (Target Company/Partner)': 'Europe',
    'Upfront Value ($m)': '40',
    'Total Deal Value ($m)': '159',
    'Geographic Remit of Deal': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Eligard',
    'Indication(s)': 'Prostate Cancer',
    'Deal Structure':
      'Recordati will make an upfront payment of € 35 million to Tolmar which will also be eligible to receive additional milestone payments up to a total of € 105 million as well as royalties on sales.',
    'FW Link1':
      'RECORDATI: LICENSE OBTAINED FOR THE COMMERCIALIZATION OF ELIGARD IN EUROPE AND OTHER COUNTRIES;;https://firstwordpharma.com/story/5212703'
  },
  {
    'Date Announced': '1-Feb-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Tolmar',
    'Country (Acquiring Company/Partner)': 'Ireland {Republic}',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Astellas',
    'Country (Target Company/Partner)': 'Japan',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Smal molecules',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Eligard',
    'Indication(s)': 'Prostate Cancer',
    'FW Link1':
      'Astellas Returns Rights to Eligard in Europe, Middle East, CIS, and Asia to Tolmar;;https://firstwordpharma.com/story/5213517'
  },
  {
    'Date Announced': '1-Feb-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Coherus BioSciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Shanghai Junshi Biosciences,',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '150',
    'Total Deal Value ($m)': '530',
    'Geographic Remit of Deal': 'United States, Canada',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'toripalimab',
    'Indication(s)': 'Melanoma',
    'Target(s)': 'PD-1',
    'Deal Structure':
      'Under the agreed terms, Coherus will pay Junshi $150 million upfront for exclusive rights to toripalimab in the US and Canada, plus options in these territories for JS006 and JS018, as well as certain negotiation rights to the two undisclosed preclinical immuno-oncology drug candidates. Coherus will also pay a 20% royalty on net sales of toripalimab and up to an aggregate $380 million in one-time payments for the achievement of various milestones, including up to $290 million if certain sales targets are reached. In addition, the option exercise fee for JS006 and JS018 is $35 million per programme and is exercisable prior to the start of Phase II development. For each, Coherus will pay Junshi an 18% royalty on net sales and up to an aggregate $255 million in potential milestones, including up to $170 million upon the attainment of certain sales thresholds. The companies will partner on developing toripalimab and the other licensed compounds, with Coherus covering for a portion of these co-development activities up to a maximum of $25 million per licensed compound per year.',
    'FW Link1':
      "Coherus nabs US, Canada rights to Junshi Bio's anti-PD-1 antibody toripalimab;;https://firstwordpharma.com/story/5213537"
  },
  {
    'Date Announced': '1-Feb-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Exelixis',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Adagene',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '11',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Deal Structure':
      'Under the terms of the agreement, Exelixis will make an upfront payment of $11 million to Adagene and will have the ability to nominate two targets during the collaboration term. Adagene will be eligible for development and commercialization milestones, as well as royalties on net sales of products developed around each of these targets.',
    'FW Link1':
      'Exelixis and Adagene Enter into Collaboration and License Agreement to Develop Novel Masked Antibody-Drug Conjugate Therapies with Improved Safety and Efficacy Profiles;;https://firstwordpharma.com/story/5213749'
  },
  {
    'Date Announced': '1-Feb-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Alkido Pharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Convergent Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '2',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Antibody-drug conjugates, radiopharmaceuticals',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'CONV 01-α',
    'Indication(s)': 'Prostate Cancer',
    'Target(s)': 'PSMA',
    'Deal Structure':
      'AIkido Pharma Inc. (Nasdaq: AIKI) ("AIkido" or the "Company") today announced that the Company executed a Two Million Dollar Convertible Promissory Note Purchase Agreement with Convergent Therapeutics, Inc., securing an early investment in Convergent.',
    'FW Link1':
      'Aikido Pharma Secures Early Interest in Next Generation Radiotherapy;;https://firstwordpharma.com/story/5214073'
  },
  {
    'Date Announced': '2-Feb-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Ono Pharmaceutical',
    'Country (Acquiring Company/Partner)': 'Japan',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Ribon Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '16',
    'Total Deal Value ($m)': '148',
    'Geographic Remit of Deal': 'Japan, South Korea, Taiwan',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'RBN-2397',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'PARP7',
    'Deal Structure':
      'Under the terms of the agreement, Ribon will receive a one-time upfront payment of approximately $16.3 million from Ono. In addition, Ribon is eligible to receive up to a potential of approximately $132 million at current exchange rates based on the achievement of certain regulatory and commercial milestones, as well as tiered royalties ranging from the high single digits to low teens on net sales of RBN-2397 in the Ono Territory.',
    'FW Link1':
      'Ribon Therapeutics Announces License Agreement with Ono Pharmaceutical Co., Ltd. to Develop and Commercialize RBN-2397 In Japan, South Korea, Taiwan and ASEAN Countries;;https://firstwordpharma.com/story/5214763'
  },
  {
    'Date Announced': '2-Feb-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Roche',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'X-Chem',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Target screening',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Under the terms of the agreement, X-Chem will deploy its proprietary DEL platform to identify novel drug-like leads against multiple oncology targets of interest to Genentech and may also conduct hit-to-lead optimization for the programs. In addition, X-Chem grants Genentech an exclusive license to an existing preclinical, small molecule oncology program, consisting of several series of novel compounds previously identified by X-Chem using its DEL platform.\n \n Genentech retains exclusive global rights to compounds derived from the collaboration and will be responsible for further research, development and commercialization of any potential new medicines emerging from the collaboration. X-Chem will receive an upfront payment and is eligible to receive research, development and regulatory milestone payments, based upon certain predefined events. X-Chem is also eligible to receive royalties from the sales of medicines resulting from the collaboration.',
    'FW Link1':
      'X-Chem Enters into Multitarget Oncology Discovery Research Collaboration and License Agreement with Genentech;;https://firstwordpharma.com/story/5215447'
  },
  {
    'Date Announced': '3-Feb-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Oncology Pharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'NanoSmart Pharmaceuticals',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Drug delivery',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Solid tumours',
    'FW Link1':
      'Oncology Pharma Extends Its Licensing Agreement with NanoSmart Pharmaceuticals, Inc. for Human Oncology;;https://firstwordpharma.com/story/5216047'
  },
  {
    'Date Announced': '4-Feb-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Parexel',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'NeoGenomics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Clinical trial design',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'FW Link1':
      'Parexel and NeoGenomics Announce Strategic Collaboration in Precision Medicine to Improve Study Designs and Accelerate Patient Matching in Oncology Clinical Trials;;https://firstwordpharma.com/story/5217403'
  },
  {
    'Date Announced': '4-Feb-2021',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'Fosun Long March Medical Science',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'bioAffinity Technologies',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'CyPath® Lung',
    'Indication(s)': 'Lung cancer',
    'FW Link1':
      'bioAffinity Technologies Executes Agreement with Fosun Long March to Evaluate CyPath Lung for Commercialization in China;;https://firstwordhealthtech.com/story/5217821'
  },
  {
    'Date Announced': '8-Feb-2021',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'SmartBreast',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'GEHealthcare',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)':
      'Discovery NM750b Molecular Breast Imaging (MBI) assets, ebranded as "EVE CLEAR SCAN e750."',
    'Indication(s)': 'Breast cancer',
    'Deal Structure':
      'SmartBreast Corporation (SmartBreast™), a privately held company focused on breast cancer screening and diagnosis, announced today that it has acquired the Discovery NM750b Molecular Breast Imaging (MBI) assets from GE Healthcare, including the MBI scanner designs, manufacturing tools, instructions, related patents and the installed-base service business.',
    'FW Link1':
      'SmartBreast Acquires Molecular Breast Imaging Assets from GE Healthcare;;https://firstwordhealthtech.com/story/5219549'
  },
  {
    'Date Announced': '8-Feb-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Sorrento Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Mayo Clinic',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antobody-drug conjugates',
    'Lead Asset Stage/Phase': 'Phase I',
    'Indication(s)':
      'Endometrial cancer, ovarian cancer, angiosarcoma, B-Cell lymphomas',
    'FW Link1':
      'Sorrento Announces Subsidiary Company - ADNAB, Inc. - to Develop and Commercialize ADNAB Platform Products for Hematological Malignancies and Solid Tumors Based on an Exclusive Technology License From the Mayo Clinic;;https://firstwordpharma.com/story/5220011'
  },
  {
    'Date Announced': '9-Feb-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Apollomics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Edison Oncology Holding Corp',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global excluding China, Hong Kong, Taiwan',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'EO1001',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'EGFR, Her2, Her4',
    'Deal Structure':
      'Apollomics has the exclusive rights to develop and commercialize EO1001 globally, except in China, Hong Kong and Taiwan. Edison Oncology will receive an upfront cash payment and will be eligible to receive potential development and sales milestone payments, as well as tiered royalties on net sales.',
    'FW Link1':
      'Apollomics and Edison Oncology Announce Licensing Agreement for Novel Protein Tyrosine Kinase Inhibitor Targeting Solid Tumors;;https://firstwordpharma.com/story/5220747'
  },
  {
    'Date Announced': '10-Feb-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Targovax',
    'Country (Acquiring Company/Partner)': 'Norway',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Papyrus Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Gene Therapy',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'RTK',
    'Deal Structure':
      'Under the agreement, Targovax and Papyrus will run a joint pre-clinical research project to evaluate the technical feasibility and anti-cancer activity of combining the ONCOS oncolytic virus platform and recombinant OPCML technology.',
    'FW Link1':
      'Targovax and Papyrus Therapeutics enter research collaboration to develop novel ONCOS viruses with receptor tyrosine kinase (RTK) inhibitor functionality;;https://firstwordpharma.com/story/5221519'
  },
  {
    'Date Announced': '10-Feb-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Procare Health Iberisa SL',
    'Country (Acquiring Company/Partner)': 'Spain',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'BioVaxys',
    'Country (Target Company/Partner)': 'Canada',
    'Region (Target Company/Partner)': 'North America',
    'Total Deal Value ($m)': '1',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Vaccines',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'BVX-0918A',
    'Indication(s)': 'ovarian cancer',
    'Deal Structure':
      'Under the terms of the agreement, the companies will jointly conduct a Phase I Clinical Study of BVX-0918A in Spain, BioVaxys\' autologous haptenized protein vaccine for late-stage ovarian cancer. BioVaxys will be responsible for the core technology and vaccine production, with Procare Health overseeing and making an in-kind investment in the clinical program and regulatory planning, CRO management, patient/clinical center recruitment, marketing, and opinion leader management. Both companies have agreed to equally share costs associated with engaging a European clinical research organization ("CRO") to conduct the study. In return, Procare Health will have exclusive rights to market and distribute BVX-0918A in the European Union ("EU"), and the United Kingdom. Clinical data from the Spanish Phase I study will be used by BioVaxys to support its planned IND for BVX-0918A in the US next year, as well as for all other global markets. The two companies will be working out any remaining details by end of 2Q21.',
    'FW Link1':
      'BioVaxys and Procare Health Announce Broad Co-Development, Joint Commercialization and Marketing Collaboration for Cancer and Viral Vaccines;;https://firstwordpharma.com/story/5221617'
  },
  {
    'Date Announced': '10-Feb-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'AbbVie',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Caribou Bioscience',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '40',
    'Total Deal Value ($m)': '340',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy, gene editing',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Under the deal, AbbVie will make a $40-million upfront cash payment and equity investment in Caribou, with the latter also eligible to receive up to $300 million in various milestones, plus tiered royalties and additional payments if certain sales targets are reached.',
    'FW Link1':
      'AbbVie eyes CAR-T therapies with broader use potential in new Caribou Bio deal;;https://firstwordpharma.com/story/5221687',
    'FW Link2':
      'Termination of agreement announced September 26 2023;;https://firstwordpharma.com/story/5784507/'
  },
  {
    'Date Announced': '10-Feb-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Apollomics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Iterion Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'Tegavivint',
    'Indication(s)': 'progressive desmoid tumors',
    'Target(s)': 'TBL1',
    'Deal Structure':
      "Under the terms of the agreement, Apollomics will be responsible for clinical development and commercialization in Greater China. Apollomics and Iterion will form a joint development committee to ensure ongoing collaboration of Tegavivint across indications and geographies. Iterion has received an initial cash payment for ongoing research and development costs in connection with the company's global development of Tegavivint. Iterion is also eligible to receive potential development and sales milestone payments, as well as tiered royalties on net sales.",
    'FW Link1':
      'Apollomics and Iterion Therapeutics Announce Exclusive Collaboration and License Agreement to Develop and Commercialize Tegavivint in Greater China;;https://firstwordpharma.com/story/5221853'
  },
  {
    'Date Announced': '11-Feb-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'BMS',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Molecular Templates',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '70',
    'Total Deal Value ($m)': '1,370',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Engineered toxin body (ETB) platform',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Under the terms of the agreement, MTEM will conduct research activities for the discovery of next generation ETBs for multiple targets, of which the first target has been selected by Bristol Myers Squibb. Bristol Myers Squibb will have the option to obtain an exclusive worldwide license to develop and commercialize ETBs directed to each selected target. Following the exercise of the option, Bristol Myers Squibb would be solely responsible for developing and commercializing the licensed ETBs.\n \n Bristol Myers Squibb will make an up-front payment of $70 million to MTEM. MTEM is also eligible to receive near-term and development, regulatory and sales milestone payments of up to approximately $1.3 billion as well as tiered royalty payments on future sales.',
    'FW Link1':
      'BMS inks deal with Molecular Templates to develop engineered toxin bodies against cancer;;https://firstwordpharma.com/story/5223037'
  },
  {
    'Date Announced': '15-Feb-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Shepherd Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Oncoheroes Biosciences',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'Volasertib',
    'Indication(s)': 'rhabdomyosarcoma',
    'Target(s)': 'PLK1',
    'FW Link1':
      'SHEPHERD Therapeutics and Oncoheroes Biosciences to Partner for the Discovery, Development and Commercialization of Oncology Therapeutics For Rare Cancers;;https://firstwordpharma.com/story/5225091'
  },
  {
    'Date Announced': '16-Feb-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Cytovia Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Cellectics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '15',
    'Total Deal Value ($m)': '775',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      'The financial terms of the partnership include up to $760 million of development, regulatory, and sales milestones from Cytovia to Cellectis for the first 5 TALEN® gene-edited iPSC-derived NK products ("partnership products"). Cellectis will also receive single-digit royalty payments on the net sales of all partnered products commercialized by Cytovia. Cellectis will receive an equity stake of $15 million in Cytovia stock or an upfront cash payment of $15 million if certain conditions are not met by December 31, 2021, as well as an option to invest in future financing rounds.',
    'FW Link1':
      'Cytovia Therapeutics and Cellectis Partner to Develop TALEN Gene-Edited iPSC-Derived Natural Killer Cells;;https://firstwordpharma.com/story/5225613'
  },
  {
    'Date Announced': '16-Feb-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Onward Therapeutics',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Biomunex Pharmaceuticals',
    'Country (Target Company/Partner)': 'France',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Bispecific antibodies',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Onward will pay upfront, development, regulatory and sales milestones, as well as tiered royalties on net sales. In addition, Onward will make an equity investment in Biomunex',
    'FW Link1':
      'Onward Therapeutics and Biomunex Pharmaceuticals Enter into a Worldwide Exclusive License and Co-development Agreement to a Bispecific Antibody for Immuno-Oncology;;https://firstwordpharma.com/story/5225617'
  },
  {
    'Date Announced': '16-Feb-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Qilu Pharmaceuticals',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Cend Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '10',
    'Total Deal Value ($m)': '235',
    'Geographic Remit of Deal': 'Greater China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Peptides',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'CEND-1',
    'Indication(s)': 'pancreatic cancer',
    'Target(s)': 'alpha-v integrins',
    'Deal Structure':
      'Qilu will pay Cend an up-front license fee of US$10 million. Cend will be eligible to receive up to $225 million in milestones as well as tiered double digit royalties on product sales in the region.',
    'FW Link1':
      'Cend Therapeutics and Qilu Pharmaceutical Announce Partnership;;https://firstwordpharma.com/story/5225921'
  },
  {
    'Date Announced': '16-Feb-2021',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Exact Sciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Ashion Analytics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'GEM ExTra®',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'xact Sciences Corp. (Nasdaq: EXAS) today announced that it has entered into an agreement to acquire Ashion Analytics, LLC (Ashion) from The Translational Genomics Research Institute (TGen), an affiliate of City of Hope. Ashion is a CLIA-certified and CAP-accredited sequencing lab based in Phoenix, Arizona with the genomics testing capabilities necessary to address the increasingly complex needs of clinical, academic, and biopharma customers focused on precision cancer treatments.',
    'FW Link1':
      "Exact Sciences to Acquire Ashion Analytics and Enter Research Collaboration with City of Hope's Genomics Institute, TGen;;https://firstwordhealthtech.com/story/5226579"
  },
  {
    'Date Announced': '17-Feb-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Nektar',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'SFJ Pharmaceuticals',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Total Deal Value ($m)': '150',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'Bempegaldesleukin',
    'Indication(s)': 'Melanoma',
    'Target(s)': 'IL-2',
    'Deal Structure':
      'Nektar Therapeutics (Nasdaq: NKTR), a clinical-stage biopharmaceutical company focused on the development and commercialization of novel therapies for cancer and auto-immune disease, today announced a financing and co-development collaboration with SFJ Pharmaceuticals to support the development of Bempegaldesleukin (BEMPEG), an investigational CD122-preferential IL-2-pathway agonist. SFJ Pharmaceuticals is a global drug development company backed by Abingworth and Blackstone Life Sciences.\n The collaboration between SFJ and Nektar will support a new Phase 2/3 registrational clinical study of BEMPEG plus pembrolizumab in patients with head and neck cancer whose tumors express PD-L1 (Combined Positive Score [CPS] ≥1). Under the terms of the agreement, SFJ has agreed to fund up to $150 million to support the study and manage clinical trial operations. Nektar will serve as the sponsor of the Phase 2/3 study.',
    'FW Link1':
      'Nektar Announces Collaboration with SFJ Pharmaceuticals for Bempegaldesleukin in Head and Neck Cancer;;https://firstwordpharma.com/story/5227089'
  },
  {
    'Date Announced': '17-Feb-2021',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Revive Therapeutics',
    'Country (Acquiring Company/Partner)': 'Canada',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'PharmaTher',
    'Country (Target Company/Partner)': 'Canada',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '2',
    'Total Deal Value ($m)': '8',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Psilocybin',
    'Lead Asset Stage/Phase': 'Discovery',
    'Lead Asset(s)':
      'Liver Carcinoma, Melanoma, Breast Neoplasms, Kidney Neoplasms and Acute Myeloid Leukemia',
    'Deal Structure':
      'Pursuant to the Agreement, Revive will pay aggregate consideration of up to CAD $10 million (the "Purchase Price"). The Purchase Price will be satisfied as follows: (i) $3 million in cash will be paid on the closing date; (ii) $4 million will be satisfied through the issuance of securities in the capital of Revive and (iii) up to $3 million, in either cash or securities in the capital of Revive, in the event that Revive achieves certain milestones, which include Revive obtaining FDA orphan drug designation for psilocybin in the treatment of stroke, traumatic brain injury, or cancer, the commencement of a Phase 2 clinical trial and the regulatory filing for market authorization, such as U.S. Food and Drug Administration ("FDA") approval. In addition to the Purchase Price, Revive will also pay Newscope a low single digit royalty on all future net sales of products derived from the Acquired Assets.',
    'FW Link1':
      'Revive Therapeutics Acquires Unique Psilocybin Assets;;https://firstwordpharma.com/story/5227191'
  },
  {
    'Date Announced': '17-Feb-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'BeiGene',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Boston Immune Technologies & Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '17',
    'Total Deal Value ($m)': '122',
    'Geographic Remit of Deal': 'Asia (ex-Japan), Australia, New Zealand',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'BITR2101',
    'Indication(s)': 'Cancer',
    'Target(s)': 'TNFR2',
    'Deal Structure':
      'BITT is eligible to receive development, regulatory, and sales milestones up to $105 million, together with tiered royalties on any product sales in the licensed territory.',
    'FW Link1':
      'Boston Immune Technologies and Therapeutics and BeiGene Enter into an Exclusive Option and License Agreement to Develop Novel TNFR2 Antagonists;;https://firstwordpharma.com/story/5227817'
  },
  {
    'Date Announced': '19-Feb-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'AmoyDz',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'AstraZeneca',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'ovarian cancer',
    'Target(s)': 'HRD',
    'FW Link1':
      'AmoyDx Collaborates with AstraZeneca to Co-develop HRD Companion Diagnostics for Ovarian Cancer;;https://firstwordhealthtech.com/story/5229543'
  },
  {
    'Date Announced': '19-Feb-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'PT Kalbe Genexine Biologics (KGBio)',
    'Country (Acquiring Company/Partner)': 'India',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Genexine',
    'Country (Target Company/Partner)': 'Korea, South',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '27',
    'Total Deal Value ($m)': '1,100',
    'Geographic Remit of Deal':
      'Middle East, Oceania, India, Africa, Asia (ex-China, Japan and Korea)',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Recombinant proteins',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'GX-17',
    'Indication(s)': 'Cancer',
    'Target(s)': 'IL-7',
    'Deal Structure':
      'The license agreement worth of USD 1.1 billion including an upfront payment of USD 27 million and will be followed by registration and commercialization milestones and a royalty of 10% of sales revenue. The development and commercialization license covers the Middle East, Oceania, India, Africa and all regions of Asia except China, Japan and Korea.',
    'FW Link1':
      'KGBio and Genexine Signed Immuno-Oncology Drug License Agreement GX-17;;https://firstwordpharma.com/story/5229753'
  },
  {
    'Date Announced': '22-Feb-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Surface Oncology',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Vaccinex',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'SRF-114',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'CCR8',
    'Deal Structure':
      'The terms of agreement with Surface Oncology provided that Surface Oncology pay technology access and licensing fees in addition to research funding, and that Vaccinex will qualify for development milestone payments and royalties.',
    'FW Link1':
      'Vaccinex Announces Licensing of Anti-CCR8 Antibody to Surface Oncology;;https://firstwordpharma.com/story/5230645'
  },
  {
    'Date Announced': '22-Feb-2021',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Hologic',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Biotheranostics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Total Deal Value ($m)': '230',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Breast Cancer Index®; CancerTYPE ID®',
    'Indication(s)': 'Breast cancer',
    'FW Link1':
      'Hologic Completes Acquisition of Biotheranostics, Enabling Entry into Growing Oncology Market;;https://firstwordhealthtech.com/story/5231115'
  },
  {
    'Date Announced': '23-Feb-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'One Biopharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Merck KGaA',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'pimasertib; msc2015104B',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'MEK',
    'Deal Structure':
      'Under the terms of the agreement, Day One Biopharma will make an upfront payment to Merck, in addition to potential regulatory, approval and sales-based milestones. Merck will also receive royalties on net sales of pimasertib and MSC2015103B, if approved.',
    'FW Link1':
      'Merck KGaA outlicenses two clinical-stage cancer drugs to Day One Biopharma;;https://firstwordpharma.com/story/5231411'
  },
  {
    'Date Announced': '24-Feb-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'INOVIO',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'QIAGEN',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'cervical dysplasia with HPV',
    'Deal Structure':
      "QIAGEN (NYSE: QGEN; Frankfurt Prime Standard: QIA) and INOVIO Pharmaceuticals (NASDAQ: INO) today announced an extension of their partnership with a new master collaboration agreement to develop liquid biopsy-based companion* diagnostic products based on next-generation sequencing (NGS) technology to complement INOVIO's therapies",
    'FW Link1':
      "INOVIO and QIAGEN expand collaboration to develop next generation sequencing (NGS) companion diagnostic for INOVIO's VGX-3100 for advanced cervical dysplasia;;https://firstwordhealthtech.com/story/5232955"
  },
  {
    'Date Announced': '25-Feb-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Pierre Fabre',
    'Country (Acquiring Company/Partner)': 'France',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Puma Biotechnology',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '50',
    'Total Deal Value ($m)': '290',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Nerlynx',
    'Indication(s)': 'HER2+ Breast Cancer',
    'Target(s)': 'HER2',
    'Deal Structure':
      'Puma will receive an upfront payment of $50 million, as well as additional regulatory and sales-based milestone payments that could add up to an additional $240 million. These milestones will be based solely on regulatory and sales achievements in Greater China. In addition, Puma will receive significant double-digit tiered royalties on the sales of NERLYNX in Greater China.',
    'FW Link1':
      'Puma Biotechnology and Pierre Fabre Amend NERLYNX License Agreement to Include Greater China;;https://firstwordpharma.com/story/5234947'
  },
  {
    'Date Announced': '26-Feb-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Hoth Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'North Carolina State University',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Discovery',
    'Lead Asset(s)': 'HT-004',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'IgE',
    'FW Link1':
      'Hoth Therapeutics Expands License Agreement to Include Innovative Cancer and Anaphylactic Treatment;;https://firstwordpharma.com/story/5235555'
  },
  {
    'Date Announced': '26-Feb-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Oncology Pharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Sybleu Inc',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'AQ4 & AQ4N',
    'Indication(s)': 'Cancer',
    'Target(s)': 'TNF-alpha, GM-CSF, IL-4, IL-2, IL-12, IL-23',
    'FW Link1':
      'Oncology Pharma Signs Worldwide Licensing Agreement with Sybleu, Inc. for Commercialization of Chemotherapeutic Agent in Combination with a Bioactive Immunostimulatory Agent;;https://firstwordpharma.com/story/5235611'
  },
  {
    'Date Announced': '28-Feb-2021',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'AstraZeneca',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Shanghai Junshi Biosciences,',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Greater China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'toripalimab',
    'Indication(s)': 'urothelial carcinoma',
    'Target(s)': 'PD-1',
    'FW Link1':
      'AstraZeneca to Market Junshi Bio’s PD-1 Cancer Drug in China;;https://firstwordpharma.com/story/5236617'
  },
  {
    'Date Announced': '1-Mar-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Merck KGaA',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Debiopharma',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Upfront Value ($m)': '227',
    'Total Deal Value ($m)': '1,083',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'Debio 1143',
    'Indication(s)':
      'advanced squamous cell carcinoma of the head and neck (SCCHN)',
    'Target(s)': 'IAP',
    'Deal Structure':
      'The agreement also includes development rights for preclinical follow-on compounds to xevinapant. Debiopharm will receive €188 million in upfront payments and up to €710 million in regulatory and commercial milestones, as well as royalty payments. The parties anticipate the closing of the transaction in early Q2 2021.',
    'FW Link1':
      "Merck KGaA gains rights to Debiopharm's experimental cancer drug xevinapant;;https://firstwordpharma.com/story/5236757"
  },
  {
    'Date Announced': '1-Mar-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Phio Pharmaceuticals',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'AgonOx',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'RNAi, cell therapy',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'PH-762',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'CD8',
    'Deal Structure':
      "Phio Pharmaceuticals Corp. (Nasdaq: PHIO), a biotechnology company developing the next generation of immuno-oncology therapeutics based on its proprietary self-delivering RNAi (INTASYL™) therapeutic platform, today announced it has entered into a clinical development collaboration with AgonOx, Inc. to develop novel T cell-based cancer immunotherapies using Phio's lead INTASYL based product candidate PH-762 and AgonOx's \"double positive\" (DP) tumor-infiltrating lymphocyte (TIL) technology. Under the terms of the collaboration agreement, AgonOx will receive financial support for the clinical trial from Phio and Phio is entitled to certain future development milestones and sales related royalty payments from AgonOx's DP TIL technology.",
    'FW Link1':
      'Phio Pharmaceuticals and AgonOx, Inc. Announce Collaboration on Clinical Development of Novel T Cell-based Cancer Immunotherapies;;https://firstwordpharma.com/story/5237053'
  },
  {
    'Date Announced': '1-Mar-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Kazia Therapeutics',
    'Country (Acquiring Company/Partner)': 'Australia',
    'Region (Acquiring Company/Partner)': 'Austrailia',
    'Target Company/Partner': 'Oasmia Pharmaceutical AB',
    'Country (Target Company/Partner)': 'Sweden',
    'Region (Target Company/Partner)': 'Europe',
    'Upfront Value ($m)': '4',
    'Total Deal Value ($m)': '42',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'Cantrixil (TRX-E-002-1)',
    'Indication(s)': 'ovarian cancer',
    'Deal Structure':
      'Under the terms of the agreement, Oasmia will make an upfront payment of\n US$ 4 million to Kazia, with contingent milestone payments of up to US$ 42 million, and double-digit royalties on commercial sales',
    'FW Link1':
      'Kazia Licenses Cantrixil, a Clinical-stage, First-in-class Ovarian Cancer Drug Candidate, to Oasmia Pharmacetical AB;;https://firstwordpharma.com/story/5237077'
  },
  {
    'Date Announced': '1-Mar-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Qualigen Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'University College London (UCL) Business Ltd',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'DNA/G-quadruplex binders (GQBs)',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)':
      'AS1411-GNP, also known as ALAN (Aptamer-Linked Anti-Nucleolin)',
    'Indication(s)': 'pancreatic cancer',
    'Deal Structure':
      'In collaboration with the University of Louisville, Qualigen will evaluate the use of ALAN with G-quadruplex binders (GQBs) developed by Professor Stephen Neidle and colleagues from UCL. Research at UCL, supported by the UCL Technology Fund, has shown encouraging results using GQBs and targeted cancer therapeutics, particularly for the treatment of pancreatic cancer. Qualigen believes the combination of these GQBs with ALAN may increase the cancer selectivity, and therefore also the efficacy, of ALAN. Qualigen has secured an option to pursue a worldwide commercial license for this promising technology from UCL Business Ltd, the commercialization company of UCL.',
    'FW Link1':
      'Qualigen Therapeutics Secures Option to Negotiate License for G-Quadruplex Anti-Cancer Technology from UCL;;https://firstwordpharma.com/story/5237477'
  },
  {
    'Date Announced': '2-Mar-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Oscotec Inc',
    'Country (Acquiring Company/Partner)': 'Korea, South',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Beactica Therapeutics',
    'Country (Target Company/Partner)': 'Sweden',
    'Region (Target Company/Partner)': 'Europe',
    'Total Deal Value ($m)': '180',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'LSD1',
    'Deal Structure':
      'Beactica is eligible to receive up to EUR 149 million in potential preclinical, clinical and regulatory milestones, including an upfront and equity payment from Oscotec upon signing of the agreement. Furthermore, Beactica is eligible to receive double-digit royalties on commercial sales of the products resulting from the partnership. Beactica is also entitled to revenue shares from any related future licensing activities by Oscotec. Full financial details remain undisclosed.',
    'FW Link1':
      'Oscotec and Beactica Therapeutics announce license and collaboration agreement to develop new cancer drug;;https://firstwordpharma.com/story/5238073',
    'FW Link2':
      'Termination of collaboration announced April 4, 2023;;https://firstwordpharma.com/story/5724215/'
  },
  {
    'Date Announced': '2-Mar-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Emmaus Life Sciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Kainos Medicines',
    'Country (Target Company/Partner)': 'Korea South',
    'Region (Target Company/Partner)': 'Asia',
    'Total Deal Value ($m)': '1',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'KM10544',
    'Indication(s)': 'solid cancers, blood cancers and lymphoma',
    'Target(s)': 'IRAK4 (interleukin-1 receptor-associated kinase 4)',
    'Deal Structure':
      "Kainos will receive $500,000 in cash and $500,000 in shares of Emmaus common stock in consideration for entering into the agreement. Emmaus, in turn, has been granted rights of first negotiation and first refusal for an exclusive license regarding the development and commercialization of products based on the intellectual property resulting from the agreement. In the event a license agreement is executed between Kainos and Emmaus, Kainos' exclusive license to patent rights to KM10544 will be transferred to Emmaus on terms and conditions to be determined after further discussions.",
    'FW Link1':
      'Emmaus Life Sciences Announces Collaboration with Kainos Medicine for Preclinical Development of IRAK4 Inhibitor as Potential Anti-Cancer Drug;;https://firstwordpharma.com/story/5238785'
  },
  {
    'Date Announced': '2-Mar-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'LIDDS',
    'Country (Acquiring Company/Partner)': 'Sweden',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'J&J',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Drug delivery',
    'Lead Asset Stage/Phase': 'Discovery',
    'FW Link1':
      'LIDDS enters R&D agreement with potential exclusive, global product license option;;https://firstwordpharma.com/story/5238973'
  },
  {
    'Date Announced': '2-Mar-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Wugen',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'HCW Biologics Inc',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy',
    'Lead Asset Stage/Phase': 'Discovery',
    'FW Link1':
      'Wugen Announces Exclusive License Agreement With HCW Biologics For Cell Therapies to Treat Cancer;;https://firstwordpharma.com/story/5238991'
  },
  {
    'Date Announced': '2-Mar-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Akoya Biosciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Johns Hopkins University School of Medicine',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Drug screening',
    'Lead Asset Stage/Phase': 'Discovery',
    'FW Link1':
      'Akoya and Johns Hopkins’ Collaborative Agreement Supports Pioneering New Approach to Immunotherapy Biomarker Discovery and Validation;;https://firstwordpharma.com/story/5239097'
  },
  {
    'Date Announced': '3-Mar-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Vaxalto Biotherapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner':
      'Mount Sinai Innovation Partners (MSIP), part of the Icahn School of Medicine at Mount Sinai,',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Oncolytic viruses',
    'Lead Asset Stage/Phase': 'Discovery',
    'FW Link1':
      'Vaxalto Biotherapeutics and Mount Sinai Enter into Exclusive License Agreement for the Development and Commercialization of Novel Immunotherapeutic Oncolytic Viruses for Treatment of Cancer;;https://firstwordpharma.com/story/5239905'
  },
  {
    'Date Announced': '4-Mar-2021',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Amgen',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Five Prime Therapeutics Inc',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Total Deal Value ($m)': '1,900',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'bemarituzumab',
    'Indication(s)': 'Gastric cancer',
    'Target(s)': 'FGGR2b',
    'Deal Structure':
      "Under the terms of the merger agreement, which was approved by the Boards of Directors of both companies, Amgen will commence a tender offer to acquire all of the outstanding shares of Five Prime's common stock for $38.00 per share in cash. Following the completion of the tender offer, a wholly-owned subsidiary of Amgen will merge with Five Prime and shares of Five Prime that have not been tendered and purchased in the tender offer will be converted into the right to receive the same price per share in cash as paid in the tender offer (other than shares held by stockholders who properly demand and perfect appraisal rights under Delaware law).",
    'FW Link1':
      'Amgen to buy Five Prime for $1.9 billion, bulking up oncology pipeline;;https://firstwordpharma.com/story/5240497'
  },
  {
    'Date Announced': '4-Mar-2021',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'C2i Genomics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'QNA Dx',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'Deal Structure':
      "C2i Genomics, the world's first platform utilizing whole-genome pattern recognition for cancer intelligence, has acquired QNA Dx, a CLIA-certified sequencing laboratory located in Cambridge, MA for an undisclosed amount.",
    'FW Link1':
      'C2i Genomics Acquires CLIA-Certified Lab to Accelerate Commercialization of Cancer Intelligence Platform;;https://firstwordhealthtech.com/story/5241073'
  },
  {
    'Date Announced': '8-Mar-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Exelixis',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'WuXi Biologics',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Monoclonal antibodies, antibody-drug conjugates, bispecific antibodies',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Deal Structure':
      "Under the terms of the agreement, Exelixis will make a modest upfront payment to WuXi Bio in exchange for an exclusive license to a panel of monoclonal antibodies to a preclinically validated target, discovered based on WuXi Bio's integrated technology platforms, for the development of antibody-drug conjugate, bispecific, and certain other novel tumor-targeting biologics applications. WuXi Bio will be eligible for development and commercialization milestones, as well as tiered royalties on net sales of any potential products commercialized from the panel.",
    'FW Link1':
      'Exelixis Enters into Exclusive License Agreement with WuXi Biologics to Support Further Expansion of its Growing Oncology Biologics Pipeline;;https://firstwordpharma.com/story/5243159'
  },
  {
    'Date Announced': '8-Mar-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Cleave Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'CASI Pharmaceuticals',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '6',
    'Total Deal Value ($m)': '74',
    'Geographic Remit of Deal': 'Greater China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'CB-5339',
    'Indication(s)': 'hematological malignancies and solid tumors',
    'Target(s)': 'VCP/p97',
    'Deal Structure':
      'Under the terms of the agreement, Cleave and CASI will develop CB-5339 in both hematological malignancies and solid tumors, with CASI responsible for development and commercialization in China and associated markets. Cleave will receive a $5.5 million upfront payment and is eligible to receive up to $74 million in development and commercial milestone payments plus tiered royalties in the high-single to mid-double-digit range on net sales of CB-5339. In addition to the upfront cash payment, CASI will make a $5.5 million investment in Cleave through a convertible note.',
    'FW Link1':
      'Cleave Therapeutics Licenses First-in-Class VCP/P97 Inhibitor CB-5339 To CASI Pharmaceuticals For Greater China Region;;https://firstwordpharma.com/story/5243319'
  },
  {
    'Date Announced': '9-Mar-2021',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Takeda',
    'Country (Acquiring Company/Partner)': 'Japan',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Maverick Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Total Deal Value ($m)': '525',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'TAK-186 (MVC-101)',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'EGFR',
    'Deal Structure':
      'The acquisition follows a multi-year collaboration between Takeda and Maverick signed in 2017 to develop conditionally active T-cell engager therapies, in which Takeda received an equity stake and an exclusive right to purchase Maverick after five years. Based on the success of the lead programs and the promise of the COBRA platform, Takeda exercised its option to acquire Maverick for a pre-negotiated upfront payment as well as potential development and regulatory milestones totaling up to $525 million, subject to certain adjustments, including for Takeda’s current equity stake and Maverick debt.',
    'FW Link1':
      'Takeda elects to buy Maverick, gaining pipeline of T-cell engager therapies;;https://firstwordpharma.com/story/5243963'
  },
  {
    'Date Announced': '10-Mar-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Enveric BioSciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Diverse Biotech',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer pain',
    'FW Link1':
      'Enveric Biosciences Signs Definitive Agreement to Acquire Exclusive License for Novel Molecules from Diverse Biotech;;https://firstwordpharma.com/story/5245699'
  },
  {
    'Date Announced': '10-Mar-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Complix',
    'Country (Acquiring Company/Partner)': 'Belgium',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'i-Mab',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy',
    'Lead Asset Stage/Phase': 'Discovery',
    'Deal Structure':
      'Under the terms of the agreement, Complix will use its proprietary Alphabody platform to deliver Cell Penetrating Alphabodies (CPABs) against two immuno-oncology intracellular targets. The resulting CPABs will undergo clinical development that will be jointly managed by both companies.\n \n I-Mab will have an exclusive license to commercialize the CPABs in Greater China, with the rest of the world commercialization rights being equally owned by both companies. In return, Complix is entitled to receive an upfront payment and potential development milestones fees, as well as tiered royalties.',
    'FW Link1':
      'Complix Signs Global Drug Discovery and Development Agreement with I-Mab to Develop Cell Penetrating Alphabodies against Two Intracellular Immuno-oncology Targets;;https://firstwordpharma.com/story/5245725'
  },
  {
    'Date Announced': '15-Mar-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'UroGen Pharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Johns Hopkins University School of Medicine',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Drug delivery',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'RTGel (+ checkpoint inhibitors)',
    'Indication(s)': 'glioblastoma multiforme',
    'FW Link1':
      'UroGen Pharma Announces Sponsored Research Agreement with the Johns Hopkins University School of Medicine to Expand Immuno-Oncology Pipeline;;https://firstwordpharma.com/story/5249113'
  },
  {
    'Date Announced': '16-Mar-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Diverse Biotech',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Enveric Biosciences',
    'Country (Target Company/Partner)': 'Canada',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Cancer therapy side effects',
    'Deal Structure':
      'Under the terms of the agreement, Diverse will receive an undisclosed up-front payment and royalties on sales of commercialized compounds in the territory.',
    'FW Link1':
      'Diverse Biotech announces Strategic Transaction with Enveric Biosciences;;https://firstwordpharma.com/story/5250021'
  },
  {
    'Date Announced': '16-Mar-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Elevation Oncology',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'NeoGenomics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'seribantumab',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'NRG1',
    'FW Link1':
      'Elevation Oncology and NeoGenomics Announce Collaboration to Expand Genomic Testing for NRG1 Fusions Across Solid Tumors in Support of the Phase 2 CRESTONE Study;;https://firstwordhealthtech.com/story/5250277'
  },
  {
    'Date Announced': '16-Mar-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Celcuity',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'MD Anderson; Novartis; Puma Biotechnology',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'Tabrecta Nerlynx combination',
    'Indication(s)': 'metastatic HER2-negative breast cancer',
    'Target(s)': 'c-met, HER2',
    'Deal Structure':
      'Novartis will supply TABRECTA® and Puma will supply NERLYNX®, targeted therapies currently approved by the U.S. Food and Drug Administration to treat non-small cell lung cancer and HER2-positive breast cancer, respectively. Celcuity will provide its CELsignia Multi-Pathway Activity Test to select patients with HER2-negative metastatic breast cancer who have hyperactive HER2 and c-Met signaling pathways for the trial and will fund the patient-related trial costs.',
    'FW Link1':
      'Celcuity Announces Breast Cancer Clinical Trial Collaboration with MD Anderson, Novartis, and Puma Biotechnology to Study New Drug Regimen;;https://firstwordhealthtech.com/story/5250603'
  },
  {
    'Date Announced': '17-Mar-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Valence Discovery',
    'Country (Acquiring Company/Partner)': 'Canada',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Repare Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial intelligence',
    'Lead Asset Stage/Phase': 'Discovery',
    'Deal Structure':
      "This agreement will combine Valence's unique expertise in few-shot learning, generative chemistry, and multiparameter optimization with Repare's expertise in target identification and medicinal chemistry to rapidly optimize drug candidates against multiple potency, selectivity, safety, and pharmacology criteria.",
    'FW Link1':
      'Valence Discovery to Support Repare Therapeutics on AI-Enabled Drug Discovery in Precision Oncology;;https://firstwordhealthtech.com/story/5251563'
  },
  {
    'Date Announced': '17-Mar-2021',
    'Deal type': 'Alliance',
    'Acquiring Company/Partner': '111,Inc',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'BeiGene',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Greater China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Digital Health',
    'Deal Structure':
      'Answering the urgent call to make cancer care more efficient and patient-friendly in China, the two companies will cooperate around a "Internet + Medicine & Healthcare" model, leveraging 111\'s Internet hospital, smart supply chain network, data-driven AI solutions, such as digital advertising, and online and offline direct-to-patient (DTP) delivery of medicines to create an unique oncology management platform.',
    'FW Link1':
      '111, Inc. Enters into First-Ever Strategic Partnership with BeiGene;;https://firstwordhealthtech.com/story/5251601'
  },
  {
    'Date Announced': '17-Mar-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Merck & Co.',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'M2GEN',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'United State',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Digital Health',
    'FW Link1':
      'M2GEN Announces New Collaboration with Merck to Advance Cancer Therapies;;https://firstwordhealthtech.com/story/5251753'
  },
  {
    'Date Announced': '18-Mar-2021',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Takeda',
    'Country (Acquiring Company/Partner)': 'Japan',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Maverick Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Total Deal Value ($m)': '525',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'TAK-186 (MVC-101)',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'EGFR',
    'FW Link1':
      'Takeda to Acquire Maverick Therapeutics to Advance T-Cell Engager Therapies for Solid Tumors and Expand Novel Immuno-Oncology Portfolio;;https://firstwordpharma.com/story/5252555'
  },
  {
    'Date Announced': '18-Mar-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Pyxis Oncology',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Pfizer',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'PYX-201;PYX-203',
    'Indication(s)': 'hematologic malignancies',
    'Deal Structure':
      "Under the terms of the licensing agreement, Pfizer will provide Pyxis with a worldwide, royalty-bearing license to develop and commercialize two innovative ADC candidates, PYX-201 and PYX-203. Pfizer will receive an upfront payment and equity in Pyxis and is eligible to receive development and sales-based milestone payments and tiered royalties on potential sales. As part of this agreement, Pyxis also was granted a license to Pfizer's ADC platform, including various payload classes, linker technology and site-specific conjugation techniques for the future development of additional ADCs. Pfizer will also continue to support the development and advancement of this portfolio through an equity investment made by Pfizer Ventures.",
    'FW Link1':
      'Pyxis Oncology Announces Worldwide Licensing Agreement with Pfizer to Develop and Commercialize Multiple Antibody-Drug Conjugates;;https://firstwordpharma.com/story/5253047'
  },
  {
    'Date Announced': '19-Mar-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Boston Pharmaceuticals',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'GlaxoSmithKline',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules; monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'GSK3903371; GSK3502421',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'IL1RAP; RIPK1',
    'Deal Structure':
      'Under the agreement, Boston Pharmaceuticals will be responsible for further development of select programs through proof-of-concept (PoC). Following the completion of PoC studies, GSK will have the option to reacquire each program under pre-agreed terms for subsequent development and worldwide commercialization. \n \n If GSK exercises its repurchase option, Boston Pharmaceuticals will receive a one-time payment, as well as be eligible for approval and sales milestones and royalties. In the event GSK chooses not to reacquire a program, Boston Pharmaceuticals may continue development and potential commercialization of the program. GSK will then be eligible to receive milestones and royalty payments.',
    'FW Link1':
      'Boston Pharmaceuticals Enters into Unique Multi-Year Out-License and Option Agreement with GSK for the Advancement of Multiple Pre-Phase 2 Programs;;https://firstwordpharma.com/story/5253325'
  },
  {
    'Date Announced': '24-Mar-2021',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'NeoGenomics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Trapelo Health',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Total Deal Value ($m)': '65',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Information technology',
    'FW Link1':
      'NeoGenomics Agrees to Acquire Trapelo Health;;https://firstwordhealthtech.com/story/5256935'
  },
  {
    'Date Announced': '25-Mar-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'AllianThera Biopharma',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Insilico Medicine',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'AI',
    'FW Link1':
      'AllianThera Biopharma comes out of stealth and collaborates on AI with Insilico Medicine;;https://firstwordpharma.com/story/5257725'
  },
  {
    'Date Announced': '26-Mar-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Bright Peak Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Ajinomoto',
    'Country (Target Company/Partner)': 'Japan',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibodies',
    'Deal Structure':
      'Under the terms of the agreement, Bright Peak will make an exclusivity payment and Ajinomoto Co. is eligible to receive development, regulatory and commercial milestone payments as well as royalties on commercial sales.',
    'FW Link1':
      'Bright Peak Therapeutics Inc. and Ajinomoto Co., Inc. Enter into a Research Collaboration and License Agreement to Create Novel Immunocytokines;;https://firstwordpharma.com/story/5258873'
  },
  {
    'Date Announced': '26-Mar-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Kelun Pharmaceutical',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Ellipses Pharma',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'USA & Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Brain tumours',
    'FW Link1':
      'China’s Kelun Pharma Jumps on Licensing New Cancer Drug to UK’s Ellipses Pharma;;https://firstwordpharma.com/story/5259171'
  },
  {
    'Date Announced': '29-Mar-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Kazia Therapeutics',
    'Country (Acquiring Company/Partner)': 'Australia',
    'Region (Acquiring Company/Partner)': 'Austrailia',
    'Target Company/Partner': 'Simcere',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '11',
    'Total Deal Value ($m)': '281',
    'Geographic Remit of Deal': 'Greater China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'paxalisib',
    'Indication(s)': 'glioblastoma',
    'Target(s)': 'PI3K, Akt, mTOR',
    'Deal Structure':
      'Under the terms of the agreement, Kazia will receive an upfront payment of US$ 11 million (~AU$ 14.2 million), comprising US$ 7 million in cash and a US$ 4 million equity investment, priced at a 20% premium to recent trading. Kazia will also receive contingent milestone payments of up to US$ 281 million (~AU$ 362 million) for glioblastoma, with further milestones payable for indications beyond glioblastoma. Simcere will additionally pay to Kazia mid-teen percentage royalties on commercial sales.',
    'FW Link1':
      'Kazia Licenses Rights to Paxalisib in Greater China to Simcere, a Leading Chinese Pharmaceutical Company;;https://firstwordpharma.com/story/5259911'
  },
  {
    'Date Announced': '29-Mar-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Menarini',
    'Country (Acquiring Company/Partner)': 'Italy',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Nippon Shinyaku',
    'Country (Target Company/Partner)': 'Japan',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Japan',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Elzonris',
    'Indication(s)': 'Blastic plasmacytoid dendritic cell neoplasm (BPDCN)',
    'Target(s)': 'CD123',
    'FW Link1':
      'Menarini Group and Nippon Shinyaku Enter into an Exclusive License Agreement to Develop and Commercialize ELZONRIS (Tagraxofusp) in Japan;;https://firstwordpharma.com/story/5259933'
  },
  {
    'Date Announced': '29-Mar-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Personalis',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'MapKure',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diganostics',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'BGB-3245',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'B-RAF',
    'FW Link1':
      'Personalis Announces Collaboration with MapKure to Use Personalis’ NeXT Platform for Clinical Trials and Companion Diagnostic Development;;https://firstwordhealthtech.com/story/5260903'
  },
  {
    'Date Announced': '30-Mar-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Novartis',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'iTheranostics (affliate of SOFIE Biosciences)',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Radiopharmaceuticals',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'FAPI-46; FAPI-74',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'FAP',
    'FW Link1':
      "Novartis bolsters radioligand pipeline with rights to iTheranostics' FAP-targeting agents;;https://firstwordpharma.com/story/5261157"
  },
  {
    'Date Announced': '30-Mar-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Quibim',
    'Country (Acquiring Company/Partner)': 'Spain',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Hightlight Therapeutics',
    'Country (Target Company/Partner)': 'Spain',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Medical imaging',
    'FW Link1':
      'Quibim partners with Highlight Therapeutics to advance immunotherapy in melanoma;;https://firstwordhealthtech.com/story/5262179'
  },
  {
    'Date Announced': '30-Mar-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Y-Biologics',
    'Country (Acquiring Company/Partner)': 'Korea, South',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Pierre Fabre',
    'Country (Target Company/Partner)': 'France',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      'While Pierre Fabre will have a licensing option for the jointly found leading antibodies, Y-Biologics will have an opt-in option to participate in pre-clinical and clinical development and continue joint development of antibody drugs with Pierre Fabre.',
    'FW Link1':
      'Y-Biologics and Pierre Fabre confirms their research collaboration for the development of new immunotherapies in oncology;;https://firstwordpharma.com/story/5262281'
  },
  {
    'Date Announced': '30-Mar-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'ImmuneOncia',
    'Country (Acquiring Company/Partner)': 'Korea, South',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': '3D Medicines',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '8',
    'Total Deal Value ($m)': '463',
    'Geographic Remit of Deal': 'Greater China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibodies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'IMC-002',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'CD47',
    'Deal Structure':
      'Under the terms of the agreement, ImmuneOncia will receive an upfront payment of $8 million from 3D Medicines. Additionally, ImmuneOncia is eligible to receive up to $462.5 million upon the achievements of all future development and commercial milestones, plus tiered royalties up to double-digits on annual net sales of IMC-002 in Greater China. In exchange, 3D Medicines will receive rights to develop, manufacture and commercialize IMC-002 in Greater China. 3D Medicines is planning to file IND to NMPA in China this year.',
    'FW Link1':
      'ImmuneOncia and 3D Medicines Signed Exclusive License Agreement to Develop, Manufacture and Commercialize IMC-002 in Greater China;;https://firstwordpharma.com/story/5262323'
  },
  {
    'Date Announced': '31-Mar-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'BridgeBio Pharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Helsinn',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Total Deal Value ($m)': '2,000',
    'Geographic Remit of Deal': 'United States',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'infigratinib',
    'Indication(s)':
      'Cholangiocarcinoma, urothelial carcinoma and other FGFR-driven cancers',
    'Target(s)': 'FGFR',
    'Deal Structure':
      'Under the terms of the Agreement, BridgeBio will retain all rights to infigratinib in skeletal dysplasia, including achondroplasia. Subject to U.S. Food and Drug Administration ("FDA") approval, QED and Helsinn will co-commercialize infigratinib in oncology indications in the U.S. and will share profits and losses on a 50:50 basis. Helsinn will have exclusive commercialization rights and lead commercialization for infigratinib in non-skeletal dysplasia indications outside of the U.S., excluding China, Hong Kong and Macau, which are covered by BridgeBio\'s strategic development and commercialization collaboration with LianBio. Under the Agreement, BridgeBio will be eligible to receive more than $2 billion in upfront, regulatory and commercial milestones, as well as tiered royalties on adjusted net sales from Helsinn Group.',
    'FW Link1':
      'BridgeBio Pharma’s Affiliate QED Therapeutics and Helsinn Group Announce Strategic Collaboration to Co-Develop and Commercialize Infigratinib in Oncology;;https://firstwordpharma.com/story/5263059',
    'FW Link2':
      'Update to agreement on March 3, 2022;;https://firstwordpharma.com/story/5517771/'
  },
  {
    'Date Announced': '31-Mar-2021',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Healthech Solutions',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Varian Biopharmaceuticals',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'VAR-101;VAR-102',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'aPKCi',
    'FW Link1':
      'HEALTHTECH SOLUTIONS ANNOUNCES CONTRACT TO ACQUIRE VARIAN BIOPHARMACEUTICALS, A PRECISION ONCOLOGY COMPANY;;https://firstwordpharma.com/story/5263343'
  },
  {
    'Date Announced': '31-Mar-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Rakovina Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Edison Oncology',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Discovery',
    'Deal Structure':
      "In exchange for transferring these rights, Edison Oncology has been issued common shares representing approximately 43% of the outstanding shares of Rakovina. Edison Oncology's ownership in Rakovina is held by NewGen Therapeutics, Inc. a wholly-owned subsidiary of Edison Oncology.",
    'FW Link1':
      'Edison Oncology Partners DNA-damage Response Inhibitor Program with Rakovina Therapeutics;;https://firstwordpharma.com/story/5263525'
  },
  {
    'Date Announced': '1-Apr-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'KAHE',
    'Country (Acquiring Company/Partner)': 'Israel',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Thomas Jefferson University',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Recombinant proteins',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'DSP502',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'PD-1, TIGIT',
    'Deal Structure':
      'Under the agreement, Thomas Jefferson University is granting KAHR an exclusive license to develop and commercialize multiple new drug candidates including DSP502, a TIGITxPD1 fusion protein, and DSP216, a LILRB2xSIRPa fusion protein for immuno-oncology.',
    'FW Link1':
      'KAHR Announces Exclusive Licensing Agreement with Thomas Jefferson University for Novel Platform of Bi- and Tri-Specific Fusion Proteins for Cancer Immunotherapy;;https://firstwordpharma.com/story/5264159'
  },
  {
    'Date Announced': '5-Apr-2021',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Invitae Corporation',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Genosity',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Total Deal Value ($m)': '200',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'Deal Structure':
      'Under the terms of the agreement, Invitae will acquire Genosity for approximately $200 million, consisting of (i) approximately $120 million in cash and (ii) approximately $80 million in shares of Invitae common stock (based upon a trailing average closing price prior to the date of closing but subject to certain potential adjustments), with such stock and cash consideration subject to reduction based upon certain debt and transaction expenses of Genosity determined at closing.',
    'FW Link1':
      'Invitae to acquire Genosity to accelerate access to personalized oncology testing worldwide;;https://firstwordhealthtech.com/story/5265851'
  },
  {
    'Date Announced': '5-Apr-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Advaxis',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Columbia University',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cancer vaccine',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'ADXS-504',
    'Indication(s)': 'Prostate Cancer',
    'Target(s)': 'LLO',
    'Deal Structure':
      'Advaxis, Inc. (Nasdaq: ADXS), a clinical-stage biotechnology company focused on the development and commercialization of immunotherapy products, today announced an agreement with Columbia University Irving Medical Center to fund a Phase 1 clinical study evaluating ADXS-504 in patients with biochemically recurrent prostate cancer.',
    'FW Link1':
      'Advaxis Announces Agreement with Columbia University Irving Medical Center to Fund Phase 1 Study of ADXS-504 for the Treatment of Early Prostate Cancer Nasdaq:ADXS;;https://firstwordpharma.com/story/5265877'
  },
  {
    'Date Announced': '5-Apr-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Cyduct Diagnositcs',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Yale University',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Biomarkers',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Breast cancer',
    'Deal Structure':
      "CYduct Diagnostics, Inc. (OTC PINK:CYDX) announced that it has entered into a Sponsored Research Agreement, effective April 1, 2021, with Yale University to test and evaluate the performance of the University's nanowire biosensor technology for detecting critical protein biomarkers associated with the progression of Breast Cancer. The Agreement, which contains a detailed Statement of Work to be performed during the one-year term, also grants CYductDX an exclusive option to obtain an exclusive worldwide license in the field of use to the underlying nanowire intellectual property, including any Inventions made during the research process.",
    'FW Link1':
      'CYduct Diagnostics Enters Proteomic Research Agreement With Yale University;;https://firstwordhealthtech.com/story/5265953'
  },
  {
    'Date Announced': '6-Apr-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Hadassah',
    'Country (Acquiring Company/Partner)': 'Israel',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'MyBiotics',
    'Country (Target Company/Partner)': 'Israel',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Microbiome therapeutics',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Adverse events from PD-(L)1 inhibitors in melanoma',
    'Target(s)': 'PD-L1',
    'Deal Structure':
      'MyBiotics Pharma Ltd. http://biomx.com/, a microbiome therapeutics company, and Hadasit Medical Research Services and Development Ltd., the technology transfer office of Hadassah Medical Center, announced today that they have entered into a research collaboration and licensing agreement for the identification of microbiome-based therapeutics that will enhance the response to and reduce adverse effects of anti-PD-1 and anti-PD-L1 in melanoma patients.',
    'FW Link1':
      'Hadassah and MyBiotics to Collaborate for the Discovery of Microbiome-Based Therapy for Improving Cancer Immunotherapy;;https://firstwordpharma.com/story/5266565'
  },
  {
    'Date Announced': '6-Apr-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Volastra Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Microsoft',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Digital Health',
    'Lead Asset Stage/Phase': 'Discovery',
    'Deal Structure':
      'Volastra Therapeutics today announced it will collaborate with Microsoft to develop tools that help detect drivers of cancer metastasis. These unique digital pathology tools will accelerate the development of promising new therapies for cancer and help identify the patients most likely to benefit.',
    'FW Link1':
      'Volastra Therapeutics Partners with Microsoft to Advance Metastatic Cancer Research;;https://firstwordhealthtech.com/story/5266695'
  },
  {
    'Date Announced': '7-Apr-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Novartis',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Artios Pharma',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '20',
    'Total Deal Value ($m)': '1,500',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules, DNA damage repair',
    'Lead Asset Stage/Phase': 'Discovery',
    'Deal Structure':
      'Under the terms of the agreement, Novartis will make an up-front payment of US$20 million and provide near term research funding to support the collaboration. Artios will be eligible to receive discovery, development, regulatory and sales-based milestones, in addition to royalty payments on net sales of products commercialized by Novartis. The collaboration does not include Artios’ lead programs, ART0380, which is currently in clinical development, or ART4215, a first-in-class Pol Theta inhibitor.',
    'FW Link1':
      'Novartis continues radioligand drive via deal with Artios Pharma;;https://firstwordpharma.com/story/5267619'
  },
  {
    'Date Announced': '7-Apr-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'PsiOxus Therapeutics',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'BMS',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Gene Therapy',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'NG-641 + Opdivo',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'FAP',
    'Deal Structure':
      "The revised collaboration announced today will build upon the initial study data and will combine Opdivo with PsiOxus' NG-641. Under the terms of this agreement, PsiOxus will be responsible for conducting the Phase 1 study with patient recruitment expected to start in the third quarter of 2021.",
    'FW Link1':
      'PsiOxus Therapeutics Updates Agreement with Bristol Myers Squibb to Advance their Clinical Stage Immuno-Oncology Collaboration;;https://firstwordpharma.com/story/5267637'
  },
  {
    'Date Announced': '7-Apr-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Alkido Pharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Discovery',
    'Lead Asset(s)': 'Psilocybin',
    'Indication(s)': 'Cancer cachexia',
    'FW Link1':
      'AIkido Granted Sublicense to Technology for Targeted Psilocybin Treatment of Neuro-Inflamed Tissue in Cancer Patients;;https://firstwordpharma.com/story/5268105'
  },
  {
    'Date Announced': '7-Apr-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Silo Pharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Discovery',
    'Lead Asset(s)': 'Psilocybin',
    'Indication(s)': 'Neuroinflammation pain in cancer',
    'FW Link1':
      'Silo Pharma Finalizes Sublicense Deal for its Targeted Psilocybin Cancer Therapeutic Technology;;https://firstwordpharma.com/story/5268121'
  },
  {
    'Date Announced': '7-Apr-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Oncology Pharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Regen BioPharma',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'mRNA',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Pancreatic cancer',
    'FW Link1':
      'Oncology Pharma Signs License Agreement for mRNA Intellectual Property As It Reveals Strategic Plans for Treating Pancreatic Cancer;;https://firstwordpharma.com/story/5268435'
  },
  {
    'Date Announced': '8-Apr-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Context Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Integral Molecular',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Bispecific antibodies',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Gynecologic cancer',
    'Target(s)': 'anti-claudin 6 (CLDN6)',
    'Deal Structure':
      'Under the terms of the agreement, Integral Molecular and Context will develop CLDN6 bispecific antibodies that trigger the activation of T cells and eliminate cancer cells displaying CLND6. Context will conduct preclinical and all clinical development, as well as regulatory and commercial activities through exclusive worldwide rights to develop and commercialize the novel CLDN6 candidates. Integral Molecular will receive an undisclosed upfront payment and will be eligible to receive development, regulatory and sales milestone payments and high-single-digit to low-double-digit percent royalties on net sales. IND-enabling studies of the first BsMAb candidate are expected to commence in 2022, with advancement to clinical stage anticipated in 2023.',
    'FW Link1':
      'Context Therapeutics and Integral Molecular Enter Collaboration and License Deal for Novel Claudin 6 Antibodies for Gynecologic Cancer Therapeutic Program;;https://firstwordpharma.com/story/5269115'
  },
  {
    'Date Announced': '8-Apr-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'City of Hope',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'CytoImmune Thereapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies, cell therapy',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)':
      'Pancreatic, gastric, bladder, prostate and some lung cancers',
    'Target(s)': 'PD-L1, FLT3, PSCA',
    'Deal Structure':
      'The agreements include licenses to several patent applications related to methods to generate large numbers of fully functional natural killer (NK) cells derived from umbilical cord blood and compositions of chimeric receptors (CAR) for targeting NK cells to tumors. They include a prostate stem cell antigen (PSCA) CAR to treat pancreatic, gastric, bladder, prostate and some lung cancers, a programmed death-ligand 1 (PD-L1)+ NK cell for use in treating lung cancer, and fms-like tyrosine kinase 3 (FLT3) CAR NK cell therapy for use in treating acute myeloid leukemia.',
    'FW Link1':
      'City of Hope Enters Licensing Agreements with CytoImmune Therapeutics Inc. to Develop Portfolio of Chimeric Antigen Receptor-Natural Killer Therapies;;https://firstwordpharma.com/story/5269395'
  },
  {
    'Date Announced': '8-Apr-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Celcuity',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Pfizer',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '10',
    'Total Deal Value ($m)': '330',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'gedatolisib',
    'Indication(s)': 'Breast cancer',
    'Target(s)': 'PI3K/mTOR',
    'Deal Structure':
      "Under the terms of the licensing agreement, Pfizer provided Celcuity with a worldwide license to develop and commercialize gedatolisib. Celcuity paid an upfront license fee of $5 million of cash and $5 million of Celcuity's common stock as upfront payment. Pfizer is eligible to receive up to $330 million of development and sales-based milestone payments and tiered royalties on potential sales. Additional financial terms of the agreement were not disclosed.",
    'FW Link1':
      'Celcuity Announces Worldwide Licensing Agreement with Pfizer to Develop and Commercialize Gedatolisib, a First-in-Class PI3K/mTOR Inhibitor for Breast Cancer;;https://firstwordpharma.com/story/5269513'
  },
  {
    'Date Announced': '8-Apr-2021',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Sanofi',
    'Country (Acquiring Company/Partner)': 'France',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Tidal Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '160',
    'Total Deal Value ($m)': '470',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'mRNA, cell therapy',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Deal Structure':
      'Sanofi acquired Tidal Therapeutics for an upfront payment of $160 million and up to $310 million upon achievement of certain milestones.',
    'FW Link1':
      'Sanofi buys mRNA biotech Tidal Therapeutics for up to $470 million;;https://firstwordpharma.com/story/5269987'
  },
  {
    'Date Announced': '12-Apr-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Flagship Biosciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Leap Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Dagnostics',
    'Lead Asset Stage/Phase': 'Discovery',
    'Lead Asset(s)': 'DKN-01',
    'Indication(s)': 'Adenocarcinoma',
    'Target(s)': 'DKK1',
    'Deal Structure':
      'Flagship Biosciences, the leader in data-centric pathology and tissue analysis, and Leap Therapeutics (Nasdaq: LPTX), a biotechnology company focused on developing targeted and immuno-oncology therapeutics, today announced a partnership to use a clinically validated tumor expression assay utilizing RNAscope® and tissue image analysis. In a poster shared this week at the American Association for Cancer Research (AACR) Annual Meeting 2021, the companies presented data on the validation of a Dickkopf-1 (DKK1) RNAscope chromogenic in situ hybridization (CISH) assay and digital image analysis solution.',
    'FW Link1':
      'Flagship Biosciences and Leap Therapeutics Announce Partnership and Approach Using RNAscope and Image Analysis for Patient Enrollment;;https://firstwordhealthtech.com/story/5271911'
  },
  {
    'Date Announced': '14-Apr-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Xencor',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Bio-Techne',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Discovery',
    'Deal Structure':
      'Under the terms of this agreement, Bio-Techne grants Xencor access to a proprietary Bio-Techne antibody for use with their proprietary XmAb® protein engineering technology, which is revolutionizing the development of new cancer therapeutics such as bispecific antibodies and engineered cytokines.',
    'FW Link1':
      'Bio-Techne Announces Licensing of Proprietary Antibody to Xencor for Therapeutic Development;;https://firstwordpharma.com/story/5273501'
  },
  {
    'Date Announced': '14-Apr-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Zai Lab',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'RubrYc Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Discovery',
    'Deal Structure':
      'RubrYc Therapeutics, Inc. will receive an upfront payment and is eligible to receive future research, development and commercialization milestones on a per candidate basis, along with royalties on world-wide net sales of each product. Financial terms of the agreement were not disclosed.',
    'FW Link1':
      'RubrYc Therapeutics Announces a Research Collaboration and License Option Agreement With Zai Labs;;https://firstwordpharma.com/story/5273743'
  },
  {
    'Date Announced': '14-Apr-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Oncology Pharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Regen BioPharma',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'mRNA',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Pancreatic cancer',
    'Deal Structure':
      'Oncology Pharma, Inc. (the "Company") reported that it had been granted an exclusive worldwide license by Regen BioPharma, Inc. to develop and commercialize pancreatic cancer treatments utilizing Regen\'s proprietary intellectual property pertaining to mRNA.',
    'FW Link1':
      'Oncology Pharma, Inc. Addresses Pancreatic Cancer, the Need for an Effective Therapy and the Potential of mRNA Technology;;https://firstwordpharma.com/story/5273935'
  },
  {
    'Date Announced': '15-Apr-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Alligator Bioscience',
    'Country (Acquiring Company/Partner)': 'Sweden',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'MacroGenics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Bispecific antibodies',
    'Lead Asset Stage/Phase': 'Discovery',
    'Deal Structure':
      'Under the joint research collaboration agreement, which covers activities from candidate drug generation up until IND-enabling studies, each company will be responsible for its own costs. The parties may continue further development of the resulting bispecific molecule under a separate co-development collaboration and licensing agreement.',
    'FW Link1':
      'Alligator Bioscience and MacroGenics Enter into a Research Collaboration to Develop a Novel Immunotherapy;;https://firstwordpharma.com/story/5274791'
  },
  {
    'Date Announced': '15-Apr-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Prestige Biopharma',
    'Country (Acquiring Company/Partner)': 'Singapore',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Pharmapark',
    'Country (Target Company/Partner)': 'Singapore',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Russia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibodies',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'HD204 (biosimilar Avastin)',
    'Indication(s)':
      'metastatic colorectal cancer, advanced non-small-cell lung cancer, advanced kidney cancer, certain types of epithelial cancers and cancers of the cervix.',
    'Deal Structure':
      "The partnership arrangement includes the exclusive rights for Pharmapark to commercialize the Bevacizumab biosimilar in the Russian Federation, leveraging the company's strong sales and marketing capabilities and experience in successfully bringing new biosimilars to market. Whilst the terms of the deal are not being disclosed, Prestige BioPharma will assume responsibility for product commercial supply out of its manufacturing facilities in Osong, Korea, while Pharmapark will be responsible for local registration, sales and marketing in the Russian Federation with the option to manufacture the product in Russian Federation in line with the Russian import substitution strategy.",
    'FW Link1':
      "Prestige Biopharma and Pharmapark Announce License and Supply Agreement to Commercialize Prestige's Bevacizumab Biosimilar in the Russian Federation;;https://firstwordpharma.com/story/5274799"
  },
  {
    'Date Announced': '15-Apr-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Precision BioSciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Servier',
    'Country (Target Company/Partner)': 'France',
    'Region (Target Company/Partner)': 'Europe',
    'Upfront Value ($m)': '1',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'PBCAR0191;PBCAR19B',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'CD19',
    'Deal Structure':
      'Under the terms of the Program Purchase Agreement, Servier will receive $1.25 million in cash and Precision has agreed to waive earned, but as-yet unpaid, milestones totaling $18.75 million that would have otherwise been payable to Precision. Servier is also eligible to receive milestones and low- to mid-single-digit royalties subject to product development achievement. With respect to products directed to CD19, Servier has certain rights of negotiation if Precision elects to re-partner the programs.',
    'FW Link1':
      'Precision BioSciences Reacquires Global Rights to its Allogeneic CAR T Programs;;https://firstwordpharma.com/story/5275449'
  },
  {
    'Date Announced': '19-Apr-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Kazia Therapeutics',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Evotec',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Total Deal Value ($m)': '361',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'EVT801',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'VEGFR3',
    'Deal Structure':
      "Evotec receives a small upfront payment as well as further payments for continued support progressing EVT801 into the clinic and beyond, e.g. for biomarker development and CMC. Additionally, Evotec is eligible to receive clinical and commercial milestones of more than € 300 m as well as tiered high single-digit royalties on the net sales of EVT801, which will be shared with Sanofi, Evotec's partner for the discovery and early development of EVT801.",
    'FW Link1':
      'Evotec enters partnership with Kazia Therapeutics for clinical development of EVT801;;https://firstwordpharma.com/story/5277067'
  },
  {
    'Date Announced': '20-Apr-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'D&D Pharmatech',
    'Country (Acquiring Company/Partner)': 'Korea, South',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Yale University',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Brain tumours',
    'Target(s)': 'VEGF-C',
    'FW Link1':
      'D&D Pharmatech Announces Sponsored Research Agreement with Yale University to Optimize Novel Strategies for the Treatment of Brain Cancer and Other Diseases of the Central Nervous System;;https://firstwordpharma.com/story/5278305'
  },
  {
    'Date Announced': '20-Apr-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Immunomic Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Lineage',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '2',
    'Total Deal Value ($m)': '67',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cancer vaccine',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'glioblastoma multiforme (GBM)',
    'Deal Structure':
      'Under the terms of the agreement, Lineage will be entitled to upfront payments totaling $2 million anticipated in the first year and up to $67 million in development and commercial milestones across multiple indications and territories. Lineage also will be eligible to receive royalties up to 10% on net sales of future products.',
    'FW Link1':
      'Lineage Announces Worldwide License Agreement With Immunomic Therapeutics for an Allogeneic Cell-Based Cancer Immunotherapy Based on Its VAC Platform;;https://firstwordpharma.com/story/5278681'
  },
  {
    'Date Announced': '20-Apr-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Kaiku Health',
    'Country (Acquiring Company/Partner)': 'Israel',
    'Region (Acquiring Company/Partner)': 'Africa',
    'Target Company/Partner': 'Novartis',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Digital Health, patient monitoring',
    'Indication(s)': 'Melanoma',
    'FW Link1':
      'Kaiku Health to develop digital patient monitoring for melanoma with leading global medicines company;;https://firstwordhealthtech.com/story/5279059',
    'FW Link2':
      'Expansion of partnership announced August 31, 2022;;https://firstwordpharma.com/story/5647837/'
  },
  {
    'Date Announced': '22-Apr-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Synthekine',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Stanford University',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Discovery',
    'Lead Asset(s)': 'Solid tumours',
    'Target(s)': 'IL-12',
    'FW Link1':
      'Synthekine Licenses Additional Cytokine Programs;;https://firstwordpharma.com/story/5280925'
  },
  {
    'Date Announced': '22-Apr-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Vyant Bio',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Cellaria Inc',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial intelligence, drug development',
    'Lead Asset Stage/Phase': 'Discovery',
    'FW Link1':
      'Vyant Bio, Inc., Ordaōs Bio, and Cellaria, Inc. Announce Collaboration to Design and Qualify Biomarker-Specific Small Protein Therapeutics;;https://firstwordhealthtech.com/story/5281125'
  },
  {
    'Date Announced': '22-Apr-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Illumina',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Kartos Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'Lead Asset Stage/Phase': 'Discovery',
    'Lead Asset(s)': 'KRT-232',
    'Indication(s)': 'Blood cancers',
    'Target(s)': 'MDM2',
    'Deal Structure':
      "The initial focus of the collaboration will be the co-development of multiple CDx claims in blood cancers for Kartos' KRT-232, a potent and selective oral MDM2 inhibitor that activates p53 to drive tumor cell death in TP53 wild-type cancers.",
    'FW Link1':
      'Illumina and Kartos Therapeutics Announce New Oncology Partnership to Develop an NGS-Based TP53 Companion Diagnostic;;https://firstwordhealthtech.com/story/5281219'
  },
  {
    'Date Announced': '23-Apr-2021',
    'Deal type': 'M&A',
    'Acquiring Company/Partner':
      'Bould Opportunities (formerly Photonstart LED Group)',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Cizzle Biotechnology',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'North America',
    'Total Deal Value ($m)': '29',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'lung cancer',
    'Target(s)': 'C1Z1B biomarker',
    'FW Link1':
      'Bould Opportunities sets sights on Cizzle Biotechnology;;https://firstwordhealthtech.com/story/5281971'
  },
  {
    'Date Announced': '28-Apr-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'BMS',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Evotec',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules, protein degraders',
    'Lead Asset Stage/Phase': 'Discovery',
    'Deal Structure':
      'Bristol Myers Squibb has decided to exercise their option to extend its partnership with Evotec in the field of targeted protein degradation. Evotec and Bristol Myers Squibb (the successor in interest to Celgene) initiated this strategic drug discovery and development partnership in 2018 with the goal to identify first-in-class drug candidates initially focusing on solid tumours.',
    'FW Link1':
      'Bristol Myers Squibb Exercises Option to Extend Targeted Protein Degradation Partnership with Evotec;;https://firstwordpharma.com/story/5282487'
  },
  {
    'Date Announced': '28-Apr-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Pieris Pharmaceuticals',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Boston Pharmaceuticals',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '10',
    'Total Deal Value ($m)': '353',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Bispecific antibodies',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'PRS-342',
    'Indication(s)': 'Cancer',
    'Target(s)': '4-1BB',
    'Deal Structure':
      'Under the terms of the agreement, Boston Pharmaceuticals has exclusively licensed worldwide rights to PRS-342. Pieris will receive an upfront payment of $10 million and is further entitled to receive up to approximately $353 million in development, regulatory, and sales-based milestone payments, and tiered royalties on sales of PRS-342. Pieris will also contribute an undisclosed amount toward manufacturing activities.',
    'FW Link1':
      'Pieris Pharmaceuticals and Boston Pharmaceuticals Enter into an Exclusive Worldwide Product License for PRS-342, a 4-1BB/GPC3 Immuno-Oncology Bispecific;;https://firstwordpharma.com/story/5282869'
  },
  {
    'Date Announced': '27-Apr-2021',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Castle Biosciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Myriad Genetics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Total Deal Value ($m)': '33',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'myPath Melanoma, LLC, Laboratory',
    'Indication(s)': 'melanoma',
    'FW Link1':
      'Myriad Genetics Signs Definitive Agreement to Sell Myriad myPath Melanoma, LLC, Laboratory to Castle Biosciences;;https://firstwordhealthtech.com/story/5283519'
  },
  {
    'Date Announced': '27-Apr-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Eisai',
    'Country (Acquiring Company/Partner)': 'Japan',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Voluntis',
    'Country (Target Company/Partner)': 'France',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'Lead Asset Stage/Phase': 'Discovery',
    'Deal Structure':
      "The partners' goals are to jointly design and develop new DTx, and evaluate their benefits for patients, caregivers and healthcare professionals. Ultimately, the companies would collaborate on the commercialization of the DTx, once authorized for marketing by regulatory authorities. The new assets will be designed with the intent to be commercialized in the US, Japan and European markets. In addition to the payment made upon execution of the collaboration and licensing agreement, Eisai makes a strategic investment in Voluntis. The investment will be carried out through the reserved issuance to Eisai of 100,361 ordinary shares without pre-emptive rights for the existing shareholders of the Company. The issue price of the new shares was set at €4.15 per share. Upon settlement-delivery of the new shares, Eisai will hold ca. 1.1% of Voluntis's share capital and ca. 0.86% of voting rights.",
    'FW Link1':
      'Voluntis and Eisai to Collaborate on Digital Therapeutics for Oncology Patients;;https://firstwordpharma.com/story/5283637'
  },
  {
    'Date Announced': '27-Apr-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Guardant Health',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Daiichi Sankyo',
    'Country (Target Company/Partner)': 'Japan',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'Lead Asset(s)': 'Guardant360 CDx',
    'Indication(s)': 'NSCLC',
    'Target(s)': 'HER2',
    'Deal Structure':
      'Guardant Health, Inc. (Nasdaq: GH) today announced a strategic collaboration with Daiichi Sankyo, Inc. (Daiichi Sankyo) to pursue regulatory approval and commercialization of the Guardant360® CDx blood test as a companion diagnostic for Enhertu® (fam-trastuzumab deruxtecan-nxki), a HER2-directed antibody-drug conjugate (ADC)',
    'FW Link1':
      'Guardant Health Announces Collaboration With Daiichi Sankyo to Develop Guardant360 CDx as a Companion Diagnostic for Enhertu in Advanced Metastatic Non-Small Cell Lung Cancer;;https://firstwordhealthtech.com/story/5283995'
  },
  {
    'Date Announced': '29-Apr-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Brooklyn ImmunoTherapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Factor Bioscience; Noveillus Theapeutics',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'mRNA, cell therapy, gene editing',
    'Lead Asset Stage/Phase': 'Discovery',
    'Deal Structure':
      'Brooklyn ImmunoTherapeutics LLC (NYSE American: BTX) ("Brooklyn") today announced it has acquired an exclusive license for mRNA gene editing and cell therapies technology of Factor Bioscience Limited and Novellus Therapeutics Limited pursuant to an exercise of a previously announced option.\n \n The license includes use of an extensively patented process to develop gene editing compounds using mRNA, which preclinical data suggest demonstrate a high degree of efficiency, as well as being non-immunogenic and non-mutagenic.',
    'FW Link1':
      'Brooklyn ImmunoTherapeutics Acquires License for mRNA Technology Platform to Develop Genetically Edited Cells to Treat Multiple Cancers, Blood and Other Disorders;;https://firstwordpharma.com/story/5286117'
  },
  {
    'Date Announced': '29-Apr-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Burning Rock',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Abbisko Therapeutics',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'Lead Asset(s)': 'ABSK091',
    'Indication(s)': 'urothelial carcinoma',
    'Target(s)': 'FGFR',
    'FW Link1':
      'Burning Rock and Abbisko Therapeutics Announce Strategic Partnership on Co-development of Companion Diagnostics for ABSK091;;https://firstwordpharma.com/story/5286269'
  },
  {
    'Date Announced': '3-May-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Actuate Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Lantern Pharma',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial intelligence, drug development',
    'Deal Structure':
      'Under the terms of the collaboration, Lantern Pharma will receive upfront equity in Actuate Therapeutics subject to meeting certain conditions of the collaboration, as well as development milestones in the form of additional equity if results from the collaboration are utilized in future development efforts. No further financial details were disclosed.',
    'FW Link1':
      "Lantern Pharma and Actuate Therapeutics Announce Research & Development Collaboration Leveraging Lantern's Artificial Intelligence Platform;;https://firstwordpharma.com/story/5288455"
  },
  {
    'Date Announced': '3-May-2021',
    'Deal type': 'Alliance',
    'Acquiring Company/Partner': 'BioVaxys',
    'Country (Acquiring Company/Partner)': 'Canada',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'BioElpida',
    'Country (Target Company/Partner)': 'France',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Manufacturing',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'BXV-0918A',
    'Indication(s)': 'Stage III/Stage IV ovarian cancer',
    'FW Link1':
      'BioVaxys And BioElpida Sign Definitive Exclusive Agreement To Begin Ovarian Cancer Vaccine Bioproduction;;https://firstwordpharma.com/story/5288549'
  },
  {
    'Date Announced': '3-May-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Vivacitas Oncology',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Image Analysis Group IAG',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial intelligence, imaging',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'AR-67',
    'Indication(s)': 'glioblastoma multiforme',
    'FW Link1':
      'Vivacitas Oncology and IAG Partner to Integrate Novel Imaging Markers in the Development of AR-67 in Recurrent Glioblastoma Patients;;https://firstwordpharma.com/story/5288831'
  },
  {
    'Date Announced': '4-May-2021',
    'Deal type': 'Alliance',
    'Acquiring Company/Partner': 'Biocartis',
    'Country (Acquiring Company/Partner)': 'Belgium',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'AstraZeneca',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Idylla™ EGFR',
    'Indication(s)': 'lung cancer',
    'Target(s)': 'EGFR',
    'FW Link1':
      'Biocartis Announces New Collaboration with AstraZeneca to Expand Availability of Idylla EGFR Biomarker Testing Among Patients with Lung Cancer;;https://firstwordhealthtech.com/story/5289807'
  },
  {
    'Date Announced': '4-May-2021',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Athenex',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Kuur Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '70',
    'Total Deal Value ($m)': '185',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'KUR-501',
    'Indication(s)': 'elapsed/refractory (R/R) high risk neuroblastoma',
    'Target(s)': 'GD2',
    'FW Link1':
      'Athenex to Acquire Kuur Therapeutics to Expand Cell Therapy;;https://firstwordpharma.com/story/5290963'
  },
  {
    'Date Announced': '6-May-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Genprex',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Undisclosed cancer research centre',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Gene Therapy',
    'Lead Asset Stage/Phase': 'Discovery',
    'Target(s)': 'TUSC2',
    'Deal Structure':
      "Amendment to existing worldwide, exclusive license agreement expands Genprex's oncology franchise\n \n Newly licensed technologies include use of Genprex's TUSC2 gene therapy combined with EGFR inhibitors or other anti-cancer therapies in patients predicted to be responsive to TUSC2 therapy",
    'FW Link1':
      'Genprex In-Licenses Additional Gene Therapy Technologies for Treatment of Lung Cancer;;https://firstwordpharma.com/story/5292813'
  },
  {
    'Date Announced': '6-May-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'SpringWorks Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner':
      'Katholieke Universiteit Leuven ; Flanders Institute for Biotechnology',
    'Region (Target Company/Partner)': 'Europe',
    'Upfront Value ($m)': '11',
    'Total Deal Value ($m)': '296',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'TEAD',
    'Deal Structure':
      'Under the terms of the agreement, SpringWorks will pay KU Leuven and VIB an upfront payment of $11 million. KU Leuven and VIB are also eligible to receive up to $285 million in development, regulatory and commercial milestones from SpringWorks and tiered single-digit percentage royalties based on any future net sales.',
    'FW Link1':
      'SpringWorks Therapeutics Expands Targeted Oncology Pipeline with Exclusive Worldwide License to TEAD Inhibitor Portfolio;;https://firstwordpharma.com/story/5292923'
  },
  {
    'Date Announced': '6-May-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'BMS',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'DarwinHealth',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Target screening',
    'Lead Asset Stage/Phase': 'Discovery',
    'Deal Structure':
      'As part of the NCTI collaboration, DarwinHealth will receive an upfront payment and has the potential to receive development and commercialization milestone payments.',
    'FW Link1':
      'DarwinHealth Announces a Research Collaboration with Bristol Myers Squibb for a Novel Cancer Target Discovery (NCTI) Initiative;;https://firstwordhealthtech.com/story/5292965'
  },
  {
    'Date Announced': '6-May-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'CRISPR Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Nkarta',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Gene editing, cell therapy',
    'Lead Asset Stage/Phase': 'Discovery',
    'Target(s)': 'CD70',
    'Deal Structure':
      'CRISPR Therapeutics and Nkarta will equally share all research and development costs and profits worldwide related to the collaboration products. For each non-collaboration product candidate incorporating a gene editing target licensed from CRISPR Therapeutics, Nkarta will retain worldwide rights and pay CRISPR Therapeutics milestones and royalties on net sales. The agreement includes a three-year exclusivity period between CRISPR Therapeutics and Nkarta covering the research, development, and commercialization of allogeneic, gene-edited, donor-derived NK cells and NK+T cells.',
    'FW Link1':
      'CRISPR, Nkarta tie-up to develop engineered NK cell therapies for cancer;;https://firstwordpharma.com/story/5293315'
  },
  {
    'Date Announced': '7-May-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Oncology Pharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Regen BioPharma',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Colon cancer',
    'Target(s)': 'NR2F6',
    'FW Link1':
      'Oncology Pharma Enters into License Agreement for Small Molecules Intellectual Property for the Treatment of Colon Cancer;;https://firstwordpharma.com/story/5293741'
  },
  {
    'Date Announced': '11-May-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'AUM Biosciences',
    'Country (Acquiring Company/Partner)': 'Singapore',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Handok Inc; CMG Pharmaceutical Co Ltd',
    'Country (Target Company/Partner)': 'Korea South',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global excluding Korea',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'CHC2014',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'TRK',
    'FW Link1':
      'AUM Biosciences enters in a Strategic Collaboration with Handok and CMG Pharm to develop ‘CHC2014’, New Pan-TRK Inhibitor for Cancer Treatment;;https://firstwordpharma.com/story/5296537'
  },
  {
    'Date Announced': '13-May-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': "Dr. Reddy's Laboratories",
    'Country (Acquiring Company/Partner)': 'India',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Shenzhen Pregene',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Europe',
    'Total Deal Value ($m)': '150',
    'Geographic Remit of Deal': 'India',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'PRG1801',
    'Indication(s)': 'multiple myeloma',
    'Target(s)': 'BCMA',
    'Deal Structure':
      "Dr. Reddy's acquires the exclusive rights to commercialize PRG1801 in India, and will be responsible for future development including clinical trials, in India. Pregene will be the exclusive supplier of the core material - lentiviral vectors for manufacturing PRG1801. Under the terms of the license agreement, Pregene will receive an upfront payment and future milestone payments of USD 5 million for first indication and up to USD 7.5 million of milestone payments for subsequent indications, and is eligible to receive a double-digit royalty, up to USD 150 million, on future sales of PRG1801 in the licensed territory.",
    'FW Link1':
      "Pregene and Dr. Reddy's Announce License Agreement for Anti-BCMA CAR-T PRG1801 in India;;https://firstwordpharma.com/story/5298435"
  },
  {
    'Date Announced': '17-May-2021',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Aerpio Pharmaceuticals',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Aadi Bioscience',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'ABI-009 (FYARRO)',
    'Indication(s)': 'Cancer',
    'Target(s)': 'Tie2',
    'Deal Structure':
      'In support of the merger, Aerpio has entered into subscription agreements to raise $155 million in a Private Investment in Public Equity (PIPE) financing led by Acuta Capital Partners and KVP Capital and including Avoro Capital Advisors; Avoro Ventures; Venrock Healthcare Capital Partners; BVF Partners, L.P.; Vivo Capital; Alta Bioequities, L.P.; Rock Springs Capital; RTW Investments, LP; Acorn Bioventures; and Serrado Capital LLC as well as other undisclosed institutional investors.',
    'FW Link1':
      'Aerpio Pharmaceuticals and Aadi Bioscience Enter into a Definitive Merger Agreement;;https://firstwordpharma.com/story/5301141'
  },
  {
    'Date Announced': '17-May-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Calithera Biosciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Antengene Corporation, Ltd.',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Total Deal Value ($m)': '255',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'CB-708',
    'Target(s)': 'CD73',
    'Deal Structure':
      'Under the terms of the license agreement, Calithera will receive an upfront payment and potential development, regulatory and sales milestones of up to $255.0 million. Additionally, Calithera is eligible to receive tiered royalties on sales of the licensed product up to low double-digits. Antengene Investment Ltd, a wholly owned subsidiary of Antengene Corporation, will receive exclusive, worldwide rights to develop and commercialize CB-708.',
    'FW Link1':
      'Calithera Biosciences and Antengene Enter Worldwide License Agreement for Development & Commercialization of CB-708;;https://firstwordpharma.com/story/5301335'
  },
  {
    'Date Announced': '18-May-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'BMS',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Agenus',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '200',
    'Total Deal Value ($m)': '1,560',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Bispecific antibodies',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'AGEN1777',
    'Target(s)': 'TIGIT',
    'Deal Structure':
      'Under the agreement, Bristol Myers Squibb will become solely responsible for the development and any subsequent commercialization of AGEN1777 and its related products worldwide. Agenus will receive a $200 million upfront payment and up to $1.36 billion in development, regulatory and commercial milestones in addition to tiered double-digit royalties on net product sales. Agenus will retain options to conduct clinical studies under the development plan, to conduct combination studies with certain other Agenus pipeline assets, and also, upon commercialization, to co-promote AGEN1777 in the US',
    'FW Link1':
      "BMS shells out $200 million upfront for rights to Agenus' anti-TIGIT bispecific asset;;https://firstwordpharma.com/story/5302123"
  },
  {
    'Date Announced': '18-May-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Imugene',
    'Country (Acquiring Company/Partner)': 'Australia',
    'Region (Acquiring Company/Partner)': 'Austrailia',
    'Target Company/Partner': 'City of Hope',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Oncolytic viruses',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'CD19',
    'Deal Structure':
      'Under the terms of the licence agreement, Imugene acquires the exclusive worldwide rights to develop and commercialize the patents covering the CF33-CD19 for which it has agreed to pay City of Hope license fees comprising upfront, annual maintenance fees which are creditable against future royalty payments, performance-based consideration linked to the achievement of certain value-inflection development milestones and commercial outcomes, as well as net sales based on single digit royalty payments, and sublicencing fees.',
    'FW Link1':
      'Imugene Licenses Cd19 Oncolytic Virus From City of Hope to Turn Car T Therapy Against Solid Tumors;;https://firstwordpharma.com/story/5302467'
  },
  {
    'Date Announced': '18-May-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner':
      'Coeptis Pharmaceuticals, subsidiary of Vinings Holdings',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'VyGen-Bio',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy, diagnostics',
    'Lead Asset Stage/Phase': 'Discovery',
    'Lead Asset(s)': 'CD38-GEAR-NK',
    'Indication(s)': 'multiple myeloma',
    'Target(s)': 'CD38',
    'Deal Structure':
      "The first option agreement involves co-development rights to CD38-GEAR-NK, which is an autologous, NK cell-based investigational therapeutic derived from a patient's own cells and gene-edited to enable combination therapy with anti-CD38 mAbs, potentially minimizing the risks and side effects from CD38-positive NK cell fratricide. The first indication is expected to be multiple myeloma, an incurable cancer of plasma cells. Per the option agreement, Coeptis has paid VyGen-Bio a non-refundable fee for the exclusive option to purchase the co-development rights to the technology. Coeptis has until December 31, 2021 to exercise the option and pay the specified exercise consideration. The second option agreement involves co-development rights to CD38-Diagnostic, which is an investigational in vitro screening tool to potentially pre-determine which cancer patients are most likely to benefit from targeted anti-CD38 mAb therapies, either as monotherapy or in combination with CD38-GEAR-NK. Per the option agreement, Coeptis has paid VyGen-Bio a non-refundable fee for the exclusive option to purchase the co-development rights to the technology. Coeptis has until December 31, 2021 to exercise the option and pay the specified exercise consideration.",
    'FW Link1':
      'Coeptis Pharmaceuticals Enters into Exclusive Option Agreements with VyGen-Bio to Co-develop Technologies Designed to Improve the Treatment of CD38-Related Cancers;;https://firstwordpharma.com/story/5302625'
  },
  {
    'Date Announced': '19-May-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Nascent Biotech',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'BioRay Pharmaceutical',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '1',
    'Total Deal Value ($m)': '5',
    'Geographic Remit of Deal': 'Global excluding North & Central America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'pritumumab',
    'Indication(s)': 'Brain tumours',
    'Deal Structure':
      "Under terms of the Agreement, Nascent will receive a total of USD $5,000,000 to be paid out at various stipulated milestones. The initial payment of USD $1,000,000 was received by Nascent on April 20, 2021. A second payment of USD $750,000 will be received by Nascent upon enrollment of the 12th patient in Nascent's first Phase 1 clinical trial of Pritumumab. A third payment of USD $2,500,000 will be received by Nascent upon approval from the FDA to conduct a Phase II clinical trial of Pritumumab. The final USD $750,000 payment will be received by Nascent upon the commencement of the Phase II clinical trial.",
    'FW Link1':
      "Nascent Biotech and BioRay Pharmaceutical Announce $5,000,000 Exclusive License Agreement for Development and Commercialization of Nascent's Pritumumab;;https://firstwordpharma.com/story/5303535"
  },
  {
    'Date Announced': '19-May-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'BMS',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Exscientia',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Upfront Value ($m)': '50',
    'Total Deal Value ($m)': '1,200',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial intelligence, drug discoverty',
    'Lead Asset Stage/Phase': 'Discovery',
    'Deal Structure':
      'The agreement includes up to $50 million in upfront funding, up to $125 million in near to mid-term potential milestones, and additional clinical, regulatory and commercial payments that take the potential value of the deal beyond $1.2 billion. Exscientia will also receive tiered royalties on net sales of any marketed drug products resulting from the collaboration.\n \n This expanded collaboration builds upon Exscientia’s existing collaboration with Bristol Myers Squibb that was initiated in 2019 with Celgene prior to Celgene’s acquisition by Bristol Myers Squibb. Exscientia will take responsibility for AI-design and experimental work necessary to discover drug candidates associated with this collaboration for Bristol Myers Squibb. Molecules will be designed using Exscientia’s AI-driven drug discovery platform, which delivers optimized compounds fulfilling complex design goals faster and more effectively than traditional drug discovery.',
    'FW Link1':
      'Exscientia announces multi-target, AI-driven drug discovery collaboration with Bristol Myers Squibb;;https://firstwordhealthtech.com/story/5304167'
  },
  {
    'Date Announced': '25-May-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Celularity',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'MaxCyte',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '1',
    'Total Deal Value ($m)': '11',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy',
    'Lead Asset Stage/Phase': 'Discovery',
    'FW Link1':
      "Celularity and MaxCyte Sign Strategic Platform License to Advance Celularity's Off-the-shelf Allogeneic Cellular Therapy Product Candidates;;https://firstwordpharma.com/story/5308649"
  },
  {
    'Date Announced': '25-May-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Clarus Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'HavaH Therapeutics',
    'Country (Target Company/Partner)': 'Australia',
    'Region (Target Company/Partner)': 'Austrailia',
    'Upfront Value ($m)': '1',
    'Total Deal Value ($m)': '30',
    'Geographic Remit of Deal': 'Global excluding Australia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'CLAR-121',
    'Indication(s)': 'Breast cancer',
    'Deal Structure':
      'Under the terms of the licensing agreement, Clarus will be responsible for future global development and regulatory activities for CLAR-121, excluding Australia. Clarus will pay HavaH an upfront payment of $500,000 upon signing and HavaH may be eligible for up to $10.75 million in potential development and regulatory milestone payments. HavaH will retain the right to promote HAVAH T+Ai in Australia. Additionally, HavaH would be eligible for a modest royalty and up to $30 million in potential commercial milestones.',
    'FW Link1':
      'Clarus Therapeutics And HavaH Therapeutics Announce Licensing Agreement For Product To Treat Androgen-Dependent Inflammatory Breast Disease And Certain Forms Of Breast Cancer;;https://firstwordpharma.com/story/5308675'
  },
  {
    'Date Announced': '25-May-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'NXgenPort',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Mayo Clinic',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics, remote patient monitoring',
    'Lead Asset Stage/Phase': 'Discovery',
    'FW Link1':
      'NXgenPort Announces Collaboration with Mayo Clinic;;https://firstwordhealthtech.com/story/5308899'
  },
  {
    'Date Announced': '25-May-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Inivata',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'F-Star Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Drug screening',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Solid tumours',
    'FW Link1':
      'Inivata Announces Collaboration with F-star Therapeutics for the use of RaDaR Assay;;https://firstwordhealthtech.com/story/5309069'
  },
  {
    'Date Announced': '25-May-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'QIAGEN',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Mirati Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostic',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'adagrasib',
    'Indication(s)': 'non small cell lung cancer',
    'Target(s)': 'KRAS',
    'FW Link1':
      'QIAGEN Partners With Mirati Therapeutics Inc. to Develop KRASG12C Companion Diagnostic for Non-Small Cell Lung Cancer (NSCLC);;https://firstwordhealthtech.com/story/5309293'
  },
  {
    'Date Announced': '26-May-2021',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'AstraZeneca',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Boan Biotech, subsidiary of Luye Pharma',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibodies',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Boyounuo (bevacizumab)',
    'Indication(s)': 'Non-small cell lung cancer, colorectal cancer',
    'FW Link1':
      'Boan Biotech Grants AstraZeneca Exclusive Rights to Promote Boyounuo (Bevacizumab Injection) in Specific County Markets of China;;https://firstwordpharma.com/story/5310427'
  },
  {
    'Date Announced': '27-May-2021',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'eFFECTOR Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Locust Walk Acquisition Corp',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Total Deal Value ($m)': '235',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'tomivorsertib',
    'Indication(s)': 'Non-small cell lung cancer',
    'Target(s)': 'MNK',
    'Deal Structure':
      'The transaction includes up to $175 million in trust at LWAC (less any redemptions by existing LWAC stockholders) and a concurrent, fully committed $60 million PIPE financing of common stock issued at $10.00 per share from new and existing leading healthcare investors including founding Series A investors Abingworth, SR One, The Column Group and U.S. Venture Partners, as well as Altitude Life Science Ventures, Sectoral Asset Management, Pfizer Ventures, Alexandria Venture Investments, BioMed Ventures and Osage University Partners.',
    'FW Link1':
      'eFFECTOR Therapeutics and Locust Walk Acquisition Corp. Announce Merger Agreement to Create Publicly Listed, Next-Generation Oncology Company Developing New Class of Cancer Therapies;;https://firstwordpharma.com/story/5311129'
  },
  {
    'Date Announced': '27-May-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'ONK Therapeutics',
    'Country (Acquiring Company/Partner)': 'Ireland {Republic}',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner':
      'Walter and Eliza Hall Intitute of Medical Research (WEHI)',
    'Country (Target Company/Partner)': 'Australia',
    'Region (Target Company/Partner)': 'Austrailia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy',
    'Deal Structure':
      "Under the terms of the agreement, ONK Therapeutics has secured exclusive global rights to WEHI's patent covering the use of human NK cells lacking CISH for the purposes of researching, developing, manufacturing and commercializing NK cell therapies. The financial terms of the agreement include milestone payments and royalties on sales, the specifics of which are not disclosed.",
    'FW Link1':
      'ONK Therapeutics Secures Exclusive Global License to Patent for CISH Knockout in NK Cells for the Treatment of Cancer, from Australia’s WEHI;;https://firstwordpharma.com/story/5311181'
  },
  {
    'Date Announced': '1-Jun-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Innovent Biologics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'AnHeart Therapeutics',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Total Deal Value ($m)': '189',
    'Geographic Remit of Deal': 'Greater China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'taletrectinib',
    'Indication(s)': 'non small cell lung cancer',
    'Target(s)': 'ROS1, NTRK',
    'Deal Structure':
      'Under the terms of the agreement, Innovent will obtain exclusive rights to co-develop and commercialize taletrectinib in Greater China. AnHeart will continue to be responsible for the development of taletrectinib up to regulatory approval in mainland China and for supplying taletrectinib for both developmental and commercial purposes in Greater China. Innovent has the right to co-develop taletrectinib in Hong Kong, Macau and Taiwan up to regulatory approval.',
    'FW Link1':
      'Innovent Biologics and AnHeart Therapeutics Jointly Announce Exclusive License Agreement for Taletrectinib in Greater China;;https://firstwordpharma.com/story/5314511'
  },
  {
    'Date Announced': '1-Jun-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Corbus Pharmaceuticals',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'University of California',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '2',
    'Total Deal Value ($m)': '206',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antbodies',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'CRB601;CRB602',
    'Target(s)': 'TGFbeta',
    'Deal Structure':
      'Under the combined terms of the two exclusive licensing agreements, Corbus will pay $2,000,000 upfront and will make potential development and sales milestone payments totaling up to $206,000,000 and pay low single-digit royalties on sales.',
    'FW Link1':
      'Corbus Pharmaceuticals Licenses Two Integrin Targeting mAbs Further Expanding Pipeline into Cancer and Fibrotic Diseases;;https://firstwordpharma.com/story/5314705'
  },
  {
    'Date Announced': '1-Jun-2021',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Myriad Genetics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Castle Biosciences',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Total Deal Value ($m)': '33',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'myPath melanoma test',
    'FW Link1':
      'Myriad Genetics Completes the Sale of Myriad myPath Melanoma, LLC, Laboratory to Castle Biosciences;;https://firstwordhealthtech.com/story/5314709'
  },
  {
    'Date Announced': '1-Jun-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Zai Lab',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Mirati Therapeutics',
    'Upfront Value ($m)': '65',
    'Total Deal Value ($m)': '273',
    'Geographic Remit of Deal': 'Greater China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'adagrasib',
    'Indication(s)': 'lung cancer',
    'Target(s)': 'KRAS',
    'Deal Structure':
      'Under the terms of the agreement, Zai Lab obtains the right to research, develop, manufacture and exclusively commercialize adagrasib in Greater China. Zai Lab will support accelerated enrollment in key global, registration-enabling clinical trials of adagrasib in patients with cancer who have a KRASG12C mutation.\n \n Mirati has an option to co-commercialize in Greater China and retains full and exclusive rights to adagrasib in all countries outside of Greater China. Mirati will receive a $65 million upfront payment, with the potential to receive up to an additional $273 million in development, regulatory and sales-based milestone payments. Mirati is also eligible to receive high-teen- to low-twenties-percent tiered royalties based on annual net sales of adagrasib in Greater China.',
    'FW Link1':
      "Zai Lab nabs rights to Mirati's experimental KRAS drug adagrasib in China;;https://firstwordpharma.com/story/5315181"
  },
  {
    'Date Announced': '1-Jun-2021',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Veracyte',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'HalioDx',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Total Deal Value ($m)': '318',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'FW Link1':
      'Veracyte to Acquire HalioDx, Positioning for Global Cancer Diagnostics Growth;;https://firstwordhealthtech.com/story/5315259'
  },
  {
    'Date Announced': '2-Jun-2021',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'MorphoSys',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Constellation Pharmaceutical',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '1,400',
    'Total Deal Value ($m)': '1,700',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'pelabresib',
    'Indication(s)': 'myelofibrosis',
    'Target(s)': 'BET',
    'FW Link1':
      'MorphoSys to buy Constellation Pharmaceuticals for $1.7 billion;;https://firstwordpharma.com/story/5315871'
  },
  {
    'Date Announced': '2-Jun-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'AstraZeneca',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Proteros',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Total Deal Value ($m)': '76',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Deal Structure':
      'Under the agreement, AstraZeneca will provide research funding and Proteros will be eligible for success-based research, development, and commercial milestone payments up to 62 million EUR plus tiered royalties on annual net sales.',
    'FW Link1':
      'Proteros Enters Into Oncology Collaboration and License Agreement With AstraZeneca;;https://firstwordpharma.com/story/5315879',
    'FW Link2':
      'Expansion of agreement announced on May 16, 2022;;https://firstwordpharma.com/story/5572337/'
  },
  {
    'Date Announced': '2-Jun-2021',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'PAVmed',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Veris Health Inc ; Oncodisc Holdings LLC',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'FW Link1':
      'PAVmed Launches Digital Health Company Veris Health Inc.;;https://firstwordhealthtech.com/story/5316481'
  },
  {
    'Date Announced': '3-Jun-2021',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Bayer',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Noria Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules, radiopharmaceuticals',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Target(s)': 'PSMA',
    'FW Link1':
      'Bayer expands targeted alpha therapy portfolio with purchase of Noria, PSMA Therapeutics;;https://firstwordpharma.com/story/5317117'
  },
  {
    'Date Announced': '3-Jun-2021',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Bayer',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'PSMA therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules, radiopharmaceuticals',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Target(s)': 'PSMA'
  },
  {
    'Date Announced': '3-Jun-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Nucleix',
    'Country (Acquiring Company/Partner)': 'Israel',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'University of Texas MD Anderson Cancer Center',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'Indication(s)': 'Lung cancer',
    'FW Link1':
      'Nucleix Establishes Collaborative Research and License Agreement with MD Anderson to Develop Methylation Assays to Assess Lung Cancer Patients;;https://firstwordhealthtech.com/story/5317987'
  },
  {
    'Date Announced': '4-Jun-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Auransa',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'POLARISqb',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial intelligence, drug discovery',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Womens cancers',
    'FW Link1':
      "Auransa and POLARISqb enter research collaboration finding treatments for neglected women's diseases;;https://firstwordhealthtech.com/story/5318899"
  },
  {
    'Date Announced': '4-Jun-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'AstraZeneca',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Akoya Biosciences Inc',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Biomarkers',
    'FW Link1':
      'Akoya and AstraZeneca to Collaborate on Spatial Biology Approach to Profiling Tumor-Immune Biology;;https://firstwordhealthtech.com/story/5318963'
  },
  {
    'Date Announced': '7-Jun-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Argenx',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'J&J',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'cusatuzumab',
    'Indication(s)': 'Acute myeloid leukaemia',
    'Target(s)': 'CD70',
    'Deal Structure':
      'Cilag GmbH International, one of the Janssen Pharmaceutical Companies of Johnson & Johnson, announced today its decision not to continue the collaboration and license agreement with argenx for cusatuzumab, an investigational therapeutic antibody that targets CD70. Janssen entered into the worldwide collaboration and license agreement with argenx in December 2018 to develop and commercialize cusatuzumab in AML and potential additional indications.',
    'FW Link1':
      'J&J ends development deal with argenx for anti-CD70 antibody cusatuzumab;;https://firstwordpharma.com/story/5319751'
  },
  {
    'Date Announced': '8-Jun-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Onward Therapeutics',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Institut du Cancer de Montpellier',
    'Country (Target Company/Partner)': 'France',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Discovery',
    'Deal Structure':
      'Onward will fund this collaboration and will be able to exercise its option for multiple development candidates. ICM is eligible to receive option fees, milestones, and royalty payments. The detailed financial terms are undisclosed.',
    'FW Link1':
      'Onward Therapeutics Signs an Exclusive Collaboration and Worldwide Option and License Agreement with Institut du Cancer de Montpellier;;https://firstwordpharma.com/story/5321013'
  },
  {
    'Date Announced': '8-Jun-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Sun Pharma Advanced Research Company',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'OneThree Biotech',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial intelligence, drug discovery',
    'Lead Asset Stage/Phase': 'Discovery',
    'FW Link1':
      'OneThree Biotech Joins Forces with SPARC to Characterize a Key Oncologic Pathway;;https://firstwordhealthtech.com/story/5321687'
  },
  {
    'Date Announced': '9-Jun-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Hummingbird Bioscience',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Novogene Co Ltd',
    'Country (Target Company/Partner)': 'Singapore',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Genomic sequencing, biomarkers, monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'HMBD-001',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'HER3',
    'FW Link1':
      'Hummingbird Bioscience and Novogene enter into strategic partnership to expand precision medicine testing for individuals with NRG1-fusion driven cancers in China;;https://firstwordhealthtech.com/story/5322883'
  },
  {
    'Date Announced': '10-Jun-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Vor Biopharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Abound Bio',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'CAR-T',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Acute myeloid leukaemia',
    'FW Link1':
      'Vor to Develop Multi-Targeted CAR-Ts with Abound Bio;;https://firstwordpharma.com/story/5323865'
  },
  {
    'Date Announced': '14-Jun-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'GSK',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'iTeos Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '625',
    'Total Deal Value ($m)': '2,000',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'EOS-448',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'TIGIT',
    'Deal Structure':
      'Under the terms of the collaboration agreement, iTeos will receive an upfront payment of $625 million. iTeos will be eligible to receive up to an additional $1.45 billion in milestone payments, should the EOS-448 programme achieve certain development and commercial milestones.\n Within the collaboration, GSK and iTeos will share responsibility and costs for the global development of EOS-448 and will jointly commercialise and equally split profits in the US. Outside of the US, GSK will receive an exclusive license for commercialisation and iTeos will receive tiered royalty payments.',
    'FW Link1':
      "GSK pays $625 million upfront for rights to iTeos' anti-TIGIT antibody;;https://firstwordpharma.com/story/5325533"
  },
  {
    'Date Announced': '14-Jun-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Quadira Biosciences AG',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'VERAXA Biotech GmbH',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Lead Asset Stage/Phase': 'Discovery',
    'Deal Structure':
      'The agreement provides that VERAXA will be entitled to upfront payments and royalties from the worldwide sale of products resulting from the collaboration. Further details of the agreement were not disclosed.',
    'FW Link1':
      'VERAXA Biotech and Quadira Biosciences Enter Partnership on ADC Development;;https://firstwordpharma.com/story/5325539'
  },
  {
    'Date Announced': '14-Jun-2021',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Brooklyn ImmunoTherapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Novellus Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Total Deal Value ($m)': '125',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Gene editing, cell therapy',
    'Deal Structure':
      'Pursuant to the terms of the LOI, the parties intend to sign a definitive agreement (the "Definitive Agreement") and work to close the acquisition on or before July 15, 2021. The terms of the LOI value Novellus at $125.0 million, which is proposed to be paid by Brooklyn by a combination of $17.4 million in cash and $107.6 million in BTX common stock. Brooklyn currently has over $43 million of cash on its balance sheet to fund the cash component of the Transaction. The completion of the Transaction is subject to a number of conditions, and no assurances can be made that the parties will successfully negotiate and enter into a Definitive Agreement or that the proposed Transaction will be closed on the terms or timeframe contemplated by the LOI, or at all.',
    'FW Link1':
      'Brooklyn ImmunoTherapeutics Executes Letter of Intent to Acquire Novellus Therapeutics;;https://firstwordpharma.com/story/5325721'
  },
  {
    'Date Announced': '14-Jun-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Oncoheroes Biosciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Allarity Therapeutics A/S',
    'Country (Target Company/Partner)': 'Denmark',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'dovitnib; stenoparib',
    'Indication(s)': 'RCC; pediatric cancers',
    'Target(s)': 'pan-TKI; PARP',
    'Deal Structure':
      "Under the terms outlined in the binding terms sheets, Oncoheroes will acquire global, exclusive rights to fund and conduct further clinical development of both dovitinib and stenoparib in pediatric cancer. The complete agreements are conditioned upon Oncoheroes allocating specific funds, within a specified timeframe, to conduct the contemplated pediatric clinical development. Under the dovitinib term sheet, Oncoheroes will take responsibility for pediatric cancer clinical development activities. Upon successful completion of the studies, Allarity will reimburse Oncoheroes for clinical development costs plus a fixed profit margin and pay an undisclosed milestone upon first regulatory approval for a pediatric cancer indication. Oncoheroes will not receive any pediatric field commercialization rights unless Allarity elects not to further develop the drug for pediatric indications. Under the stenoparib term sheet, Oncoheroes will receive commercialization rights for pediatric cancers, subject to Allarity's first buy-back option, and Allarity will receive an undisclosed upfront license fee. If Allarity does not re-acquire the pediatric field rights, it will further receive certain clinical/regulatory milestone payments and royalties on sales of stenoparib in the pediatric cancer market from Oncoheroes. Allarity will support Oncoheroes' pediatric clinical trials by providing clinical-grade drug inventory at cost and by facilitating DRP® companion diagnostic screening of patients for each drug. Further financial terms of the term sheets were not disclosed.",
    'FW Link1':
      'Allarity Therapeutics and Oncoheroes Biosciences to Partner on Pediatric Cancer Development of Dovitinib and Stenoparib;;https://firstwordpharma.com/story/5325843'
  },
  {
    'Date Announced': '15-Jun-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Caris Life Sciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Elevation Oncology',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Target screening, small molecules',
    'Lead Asset Stage/Phase': 'Discovery',
    'Deal Structure':
      "Under the terms of the agreement, Caris and Elevation Oncology will jointly evaluate potential targets, some of which are not currently actionable by an existing therapeutic, identified on an ongoing basis by analyzing Caris' Whole Transcriptome Sequencing (WTS) and Whole Exome Sequencing (WES) data. The companies can then elect to initiate a novel drug discovery program for those targets, or pursue licensing or product acquisitions, while retaining exclusive access to all targets selected by the parties.",
    'FW Link1':
      'Caris, Elevation team up to identify cancer driver alterations using genomic data;;https://firstwordhealthtech.com/story/5326981'
  },
  {
    'Date Announced': '15-Jun-2021',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Exscientia',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Allcyte',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Total Deal Value ($m)': '61',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial intelligence, drug discovery',
    'Deal Structure':
      'Under the terms of the merger agreement, Exscientia will pay €50 million, comprising cash and Exscientia ordinary shares. Exscientia plans to expand the Vienna site as its hub in the European Union.',
    'FW Link1':
      'Exscientia to acquire next-generation drug screening startup Allcyte;;https://firstwordpharma.com/story/5327033'
  },
  {
    'Date Announced': '15-Jun-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'OncoMyx Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'University of Florida',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Oncolytic viruses',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'multiple myeloma',
    'FW Link1':
      'OncoMyx Announces Exclusive Option to License Intellectual Property Rights for Myxoma Virus Technology from University of Florida;;https://firstwordpharma.com/story/5327053'
  },
  {
    'Date Announced': '16-Jun-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Harbour BioMed',
    'Country (Acquiring Company/Partner)': 'Netherlands',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Dana Farber Cancer Institute',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Bispecific antibodies, cell therapy',
    'Lead Asset Stage/Phase': 'Discovery',
    'FW Link1':
      'Harbour BioMed and Dana-Farber Cancer Institute Collaborate to Advance Novel Biotherapies for Cancer Treatment;;https://firstwordpharma.com/story/5327875'
  },
  {
    'Date Announced': '18-Jun-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Zai Lab',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'MacroGenics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '25',
    'Total Deal Value ($m)': '1,400',
    'Geographic Remit of Deal': 'China, Japan, Korea',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Bispecific antibodies',
    'Lead Asset Stage/Phase': 'Discovery',
    'Target(s)': 'CD3;CD47',
    'Deal Structure':
      'The first collaboration program covers a lead research molecule that incorporates MacroGenics’ DART platform and binds CD3 and an undisclosed target that is expressed in multiple solid tumors. The next-generation CD3 component of the DART bispecific molecule has been designed to minimize cytokine-release syndrome while maintaining anti-tumor cytolytic activity. The second collaboration program will cover a target to be designated by MacroGenics. For both molecules, Zai receives commercial rights in Greater China, Japan, and Korea and MacroGenics receives commercial rights in all other territories. For the lead molecule, Zai Lab receives an option upon reaching a predefined clinical milestone to convert the regional arrangement into a global 50/50 profit share.\n \n Zai Lab also obtains exclusive, global licenses from MacroGenics to develop, manufacture and commercialize two additional molecules. For the four programs, each company will contribute intellectual property to generate either CD3- or CD47-based bispecific antibodies. Under the terms of the agreement, MacroGenics receives initial consideration from Zai Lab of $55 million, including an upfront payment of $25 million and an equity investment of $30 million in MacroGenics’ common stock at $31.30 per share. In addition, MacroGenics is eligible to receive up to $1.4 billion in potential development, regulatory and commercial milestone payments for the four programs. If products from the collaboration are commercialized, MacroGenics would also receive royalties on annual net sales in Zai Lab’s territories.',
    'FW Link1':
      'MacroGenics, Zai Lab to jointly develop bispecific antibodies in oncology;;https://firstwordpharma.com/story/5327885'
  },
  {
    'Date Announced': '17-Jun-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'FibroGen',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'HiFiBIO',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '25',
    'Total Deal Value ($m)': '1,100',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'galectin-9 candidates',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'CXCR5;CCR8',
    'Deal Structure':
      'Under the terms of the agreement, FibroGen will make a $25 million upfront payment to HiFiBiO, as well as payments upon option exercise. In addition, HiFiBiO may receive up to a total of an additional $1.1B in future option, clinical, regulatory, and commercial milestone payments across all three programs. HiFiBiO will also be eligible to receive royalties based upon worldwide net sales.\n \n FibroGen exclusively licensed all products in the Galectin-9 program and will have sole right to develop them worldwide. The lead product candidate in the Galectin-9 program is expected to enter clinical development in the first quarter of 2023. FibroGen has also obtained exclusive options to license all product candidates in HiFiBiO’s CXCR5 and CCR8 programs. Each option may be independently exercised following delivery of program-specific data to be generated by HiFiBiO. If an option is exercised, FibroGen will have the sole right to develop products from that program worldwide',
    'FW Link1':
      "FibroGen nabs rights to HiFiBiO's Galectin-9 programme as part of wider deal;;https://firstwordpharma.com/story/5328973"
  },
  {
    'Date Announced': '17-Jun-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Gilead Sciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Shoreline BioSciences',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Total Deal Value ($m)': '2,300',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy',
    'Deal Structure':
      'Under the terms of the agreement, Shoreline will receive an upfront payment and will be eligible to receive additional payments totaling over $2.3 billion as well as royalties based on achievement of certain development and commercial milestones.',
    'FW Link1':
      "Gilead bets over $2.3 billion on Shoreline's off-the-shelf cell therapies;;https://firstwordpharma.com/story/5329121"
  },
  {
    'Date Announced': '17-Jun-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Anji Pharma',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Broad Institute',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Target(s)': 'MCL1',
    'FW Link1':
      'Anji to Advance MCL1 Inhibitor Program from Broad Institute into Clinical Trials;;https://firstwordpharma.com/story/5329291'
  },
  {
    'Date Announced': '17-Jun-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Processa Pharmaceuticals',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Ocuphire Pharma',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'RX-3117',
    'Indication(s)': 'pancreatic cancer ; NSCLC',
    'Deal Structure':
      'Under the terms of the agreement, Processa has an exclusive worldwide license (excluding China), to develop, manufacture, use, commercialize and sublicense RX-3117.',
    'FW Link1':
      'Processa Pharmaceuticals enters into a licensing agreement with Ocuphire Pharma Inc., for the development of RX-3117;;https://firstwordpharma.com/story/5329371'
  },
  {
    'Date Announced': '17-Jun-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Servier',
    'Country (Acquiring Company/Partner)': 'France',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'PRISM BioLab',
    'Country (Target Company/Partner)': 'Japan',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules, drug discovery',
    'Lead Asset Stage/Phase': 'Discovery',
    'Deal Structure':
      'Under the collaboration agreement, PepMetics™ technology provided by PRISM BioLab are utilized to identify from their library or synthetize, characterize, and optimize small molecule compounds capable of binding and stimulating the activity of the target specified by Servier. Upon finding the lead compounds, Servier has the option to license the lead compounds for further optimization and clinical development.\n \n PRISM BioLab will receive collaboration fee and option fees payments under the Option Agreement, and further milestones and royalty payments under the License Agreement. Specific financial terms are not disclosed.',
    'FW Link1':
      'Servier and PRISM BioLab enter drug discovery collaboration for a novel target;;https://firstwordpharma.com/story/5329717'
  },
  {
    'Date Announced': '18-Jun-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'BMS',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Eisai',
    'Country (Target Company/Partner)': 'Japan',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '650',
    'Total Deal Value ($m)': '3,000',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugate',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'MORAb-202',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'FRα',
    'Deal Structure':
      'Under the agreement, Eisai and Bristol Myers Squibb will jointly develop and commercialize MORAb-202 in the following collaboration territories: Japan; China; countries in the Asia-Pacific region*; the United States; Canada; Europe, including the European Union and the United Kingdom; and Russia. Bristol Myers Squibb will be solely responsible for developing and commercializing the drug in regions outside of the collaboration territories. Eisai will remain responsible for the manufacturing and supply of MORAb-202 globally.\n \n Under the financial terms of the agreement, Bristol Myers Squibb will pay $650 million U.S. dollars to Eisai including $200 million U.S. dollars as payment toward Eisai research and development expenses. Eisai is also entitled to receive up to $2.45 billion U.S. dollars in potential future development, regulatory, and commercial milestones. The parties will share profits, research and development and commercialization costs in the collaboration territories and Bristol Myers Squibb will pay Eisai a royalty on sales outside of the collaboration territories. Eisai is expected to book sales of MORAb-202 in Japan, China, countries in the Asia-Pacific region, Europe and Russia. Bristol Myers Squibb is expected to book sales of MORAb-202 in the United States and Canada.',
    'FW Link1':
      'BMS, Eisai to jointly develop ADC targeting folate receptor alpha;;https://firstwordpharma.com/story/5329785'
  },
  {
    'Date Announced': '21-Jun-2021',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Rafael Holdings',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Rafael Pharmaceuticals',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'devimistat',
    'Indication(s)': 'Pancreatic cancer, AML',
    'Deal Structure':
      'Holders of Rafael Pharmaceuticals capital stock will receive shares of Rafael Holdings Class B common stock based on the formula set forth in the merger agreement amounting to approximately 48% of the currently outstanding capitalization of the company. Additional equity in the company will be issued in connection with the contemplated financing and pursuant to employment agreements (which will reduce the foregoing percentage proportionately).\n \n On closing, Rafael Holdings will acquire for $30 million the remaining membership interest in Altira Capital & Consulting, LLC that it does not already own. As a condition of closing, Rafael Holdings will consummate a capital raise of at least $50 million less the potential proceeds from sale of certain real estate assets.',
    'FW Link1':
      'Rafael Holdings Announces Strategic Merger with Rafael Pharmaceuticals to Form a Focused Late-Stage Clinical Oncology Company;;https://firstwordpharma.com/story/5331049'
  },
  {
    'Date Announced': '22-Jun-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Iksuda Therapeutics',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'LegoChem Biosciences',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Lead Asset Stage/Phase': 'Discovery',
    'Deal Structure':
      "In April 2020, Iksuda licensed global development and commercialisation rights for three ADC programmes using LCB's ADC linker/toxin platform1. Under this expanded Agreement, Iksuda has now been granted rights for three additional targets, bringing the total number of potential ADC programmes using LCB's ADC platform technologies to six. Iksuda also gains access to LCB's innovative and recently discovered novel DNA-modifying payload, supplementing LCB's proprietary tumour-activated DNA toxins which were included in last year's License Agreement.",
    'FW Link1':
      'LegoChem Biosciences and Iksuda Therapeutics expand License Agreement for development of antibody-drug conjugates;;https://firstwordpharma.com/story/5331803'
  },
  {
    'Date Announced': '22-Jun-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Cantex Pharmaceuticals',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'vTv Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'azeliragon',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'RAGE',
    'Deal Structure':
      'Under the terms of the agreement, Cantex will be responsible for the development and commercialization of azeliragon and the companies will allocate downstream profits under a tiered arrangement.',
    'FW Link1':
      'vTv Therapeutics and Cantex Pharmaceuticals Announce Strategic Licensing Agreement for Development and Commercialization of Azeliragon for Treatment of Cancer;;https://firstwordpharma.com/story/5332083'
  },
  {
    'Date Announced': '22-Jun-2021',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Blackstone Life Sciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner':
      'Intellia Therapeutics; Cellex Cell Professionals',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '250',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Gene therapy, gene editing, cell therapy',
    'Lead Asset Stage/Phase': 'Discovery',
    'Deal Structure':
      'Key components of the new company upon formation include the following highlights: The new company will have an exclusive license to combine Intellia’s CRISPR/Cas9 allogeneic platform with GEMoaB’s switchable, universal CAR T-cell platforms (UniCAR and RevCAR); GEMoaB will become a subsidiary of the newly established company and will continue to advance its clinical stage CAR T-cell programs; The new company and Intellia will also simultaneously enter into a co-development and co-funding (Co-Co) agreement to develop an allogeneic universal CAR T-cell product for an immuno-oncology indication. Intellia will have one additional option to enter into a second Co-Co agreement from selected allogeneic universal CAR T-cell therapy products that the parties will develop under the collaboration; Blackstone Life Sciences, Intellia and Cellex (and certain related entities) will each have equal ownership of the new company.',
    'FW Link1':
      'Blackstone commits $250 million to kick off new CAR-T company with Intellia, Cellex;;https://firstwordpharma.com/story/5332085'
  },
  {
    'Date Announced': '22-Jun-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Pionyr Immunotherapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Abcam',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'PY314',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'TREM2',
    'Deal Structure':
      "Pionyr Immunotherapeutics, Inc., a company developing first-in-class antibody therapeutics that increase the body's anti-tumor immunity and Abcam (AIM: ABC) (NASDAQ: ABCM), a global innovator in life science reagents and tools, announced the extension of their collaboration with a new commercial licensing agreement to support the progression of PY314, the first to the clinic of Pionyr's compounds.",
    'FW Link1':
      'Pionyr and Abcam extend partnership to evaluate TREM2-expressing cells in cancer patients;;https://firstwordpharma.com/story/5332481'
  },
  {
    'Date Announced': '23-Jun-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Aleta Biotherapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Cancer Research UK',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'ALETA-001',
    'Indication(s)': 'Blood cancers',
    'Target(s)': 'CD20',
    'Deal Structure':
      "Under the terms of the clinical development partnership, Cancer Research UK's Centre for Drug Development will fund, sponsor and conduct the first-in-human Phase 1/2a clinical trial of ALETA-001, which will be led by Dr Amit Patel's Cellular and CAR-T therapies team at The Christie NHS Foundation Trust in Manchester, UK. Aleta retain the rights to further develop and commercialize ALETA-001 and will receive a licence to the results of the clinical trial from Cancer Research UK in return for undisclosed success-based milestone and royalty payments.",
    'FW Link1':
      'Aleta Biotherapeutics and Cancer Research UK Collaborate to Advance Blood Cancer Therapy Into the Clinic;;https://firstwordpharma.com/story/5332979'
  },
  {
    'Date Announced': '23-Jun-2021',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Dante Labs',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Cambridge Caner Genomics',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial intelligence',
    'Deal Structure':
      'Dante Labs, a leading genomics and diagnostics company, today announced the acquisition of Cambridge Cancer Genomics (CCG.ai), a groundbreaking Y-Combinator company & leader in machine learning for clinical oncology, to create a single platform with unparalleled capabilities in genomics and oncology data analysis.',
    'FW Link1':
      'Dante Labs acquires Cambridge Cancer Genomics to create global excellence in precision oncology;;https://firstwordhealthtech.com/story/5333069'
  },
  {
    'Date Announced': '23-Jun-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Cerecor',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Sanford Burnham Prebys',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Deal Structure':
      'Cerecor Inc. (NASDAQ: CERC), a biopharmaceutical company focused on becoming a leader in the development and commercialization of treatments for immunology, immuno-oncology and rare genetic disorders, today announced that it has entered into an exclusive license agreement with Sanford Burnham Prebys for the worldwide development and commercialization of an immune checkpoint program. Under the terms of the agreement, Sanford Burnham Prebys will receive an up-front payment from Cerecor and is also eligible to receive additional payments based on achievement of development, regulatory and commercial milestones, sales-based royalties and a share of sublicensing income.',
    'FW Link1':
      'Cerecor Licenses Immune Checkpoint Program from Sanford Burnham Prebys Further Expanding Pipeline of Immunology and Immuno-oncology Targets;;https://firstwordpharma.com/story/5333151'
  },
  {
    'Date Announced': '24-Jun-2021',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'AbbVie',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'TeneoOne',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Bispecific antibodies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'TNB-383B',
    'Indication(s)': 'multiple myeloma',
    'Target(s)': 'BCMA, CD3',
    'Deal Structure':
      "AbbVie (NYSE: ABBV) and Teneobio, Inc. announced today that AbbVie exercised its exclusive right to acquire TeneoOne, an affiliate of Teneobio, Inc., and TNB-383B, a BCMA-targeting immunotherapeutic for the potential treatment of relapsed or refractory multiple myeloma (R/R MM). In February 2019, AbbVie and TeneoOne entered a strategic transaction to develop and commercialize TNB-383B, a bispecific antibody that simultaneously targets BCMA and CD3 and is designed to direct the body's own immune system to target and kill BCMA-expressing tumor cells. AbbVie exercised its exclusive right to acquire TeneoOne and TN",
    'FW Link1':
      'AbbVie Exercises Right to Acquire TeneoOne and Lead Asset TNB-383B for the Potential Treatment of Relapsed or Refractory Multiple Myeloma;;https://firstwordpharma.com/story/5334513'
  },
  {
    'Date Announced': '24-Jun-2021',
    'Deal type': 'Alliance',
    'Acquiring Company/Partner': 'National Cancer Care Alliance',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Deep Lens',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial intelligence',
    'Deal Structure':
      "Deep Lens and the National Cancer Care Alliance (NCCA) today announced a strategic collaboration that positions Deep Lens' VIPER™ as the preferred technology solution for clinical trial program management throughout the 17-practice, U.S.-based network. VIPER uses proprietary cloud-based technology to facilitate, triage, and accelerate the clinical trial recruitment process.",
    'FW Link1':
      'National Cancer Care Alliance Partners With Deep Lens to Position AI-Based Clinical Trial Matching Solution Viper as Preferred Technology for Network;;https://firstwordhealthtech.com/story/5334643'
  },
  {
    'Date Announced': '28-Jun-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'ImmunityBio',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'NantOmics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy, oncolytic viruses',
    'Lead Asset Stage/Phase': 'Phase I',
    'Target(s)': 'CD4;CD8;IL-5',
    'Deal Structure':
      'ImmunityBio, Inc. (NASDAQ: IBRX), a publicly traded immunotherapy company , and privately-held NantOmics today announced publication of a stepwise approach or "pipeline" for identification and validation of neoepitope and neoepitope-reactive T cells from individual patients. The identification of neoepitopes—short peptide sequences that are mutated in tumors and are capable of generating an immune response—provides critical support in the successful development of next-generation immunotherapies delivered by ImmunityBio\'s Adeno- and yeast-based platforms.',
    'FW Link1':
      'ImmunityBio and NantOmics Announce Clinical Validation of a Proprietary Method to Identify Unique Targets for Immunotherapy in Individual Breast Cancer Patients;;https://firstwordpharma.com/story/5336629'
  },
  {
    'Date Announced': '30-Jun-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Genomic Testing Cooperative',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Elevation Oncology',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'Seribantumab',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'NRG1',
    'Deal Structure':
      'Genomic Testing Cooperative, a first-in-class diagnostic company based on a cooperative business model (Co-Op) using the most recent advances in NGS technology, announced today a collaboration with Elevation Oncology to enhance identification of patients with any solid tumor harboring an NRG1 fusion who may be eligible for enrollment in the Phase 2 CRESTONE study.',
    'FW Link1':
      'Genomic Testing Cooperative Announces Collaboration with Elevation Oncology to Expand Comprehensive Genomic Testing for NRG1 Fusions Across Solid Tumors in Support of the Phase 2 CRESTONE Study;;https://firstwordhealthtech.com/story/5339205'
  },
  {
    'Date Announced': '30-Jun-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Bioasis Technology',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Oxyrane UK Ltd',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Discovery',
    'Deal Structure':
      "Bioasis is a pre-clinical, research-stage biopharmaceutical company developing a proprietary xB3™ platform technology for the delivery of therapeutics across the blood-brain barrier (BBB) and the treatment of CNS disorders in areas of high unmet medical need, including brain cancers and neurodegenerative diseases.\n \n Oxyrane is developing enhanced enzyme replacement therapies (ERT) for lysosomal storage diseases (LSDs) using a proprietary, glyco-engineered yeast expression system for efficient targeting of enzymes to the lysosome. The research collaboration will focus on combining xB3™ technology and Oxyrane's OxyCAT platform to deliver an undisclosed enhanced enzyme replacement therapy into the brain.",
    'FW Link1':
      'Bioasis Technologies and Oxyrane UK enter into a Research Collaboration;;https://firstwordpharma.com/story/5339789'
  },
  {
    'Date Announced': '1-Jul-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Navrogen',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'National Cancer Institute',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'immunosuppressed cancers',
    'Deal Structure':
      'Navrogen, Inc., a biopharmaceutical company specialized in developing therapies for cancer and immune-related disorders, announced today that it has signed a commercial evaluation license agreement with the National Cancer Institute (NCI) of the National Institutes of Health (NIH) for the commercialization of tumor targeted therapies to treat humoral immunosuppressed cancers using recombinant Pseudomonas exotoxin A (PE)-containing immunotoxins having reduced immunogenicity.',
    'FW Link1':
      'Navrogen Signs Commercial Evaluation License Agreement with The National Cancer Institute;;https://firstwordpharma.com/story/5340503'
  },
  {
    'Date Announced': '5-Jul-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'BioMed X',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Merck KGaA',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Gene therapy',
    'Lead Asset Stage/Phase': 'Discovery',
    'Deal Structure':
      'BioMed X, a leading independent research institute, announced today the start of its eighth joint research program with Merck KGaA, Darmstadt, Germany. The new program will explore the role of extrachromosomal DNA in cancer and it will complement the research of two ongoing oncology programs at BioMed X in the fields of DNA damage response and RNA splicing.',
    'FW Link1':
      'BioMed X and Merck KGaA, Darmstadt, Germany Launch New Research Program in Oncology;;https://firstwordpharma.com/story/5342441'
  },
  {
    'Date Announced': '6-Jul-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Pierre Fabre',
    'Country (Acquiring Company/Partner)': 'France',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Y-Biologics',
    'Country (Target Company/Partner)': 'Korea South',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Discovery',
    'Deal Structure':
      'The South Korean biotech company Y-Biologics and the French pharmaceutical group Pierre Fabre have entered into a license agreement granting worldwide exclusive rights to Pierre Fabre to develop and commercialize a family of human antibodies generated through the phage display human antibody Ymax®-ABL library owned by Y-Biologics. This licensing agreement results from the strong and now proven complementarity between the two companies who have been collaborating since 2018.',
    'FW Link1':
      'South-Korean biotech Y-Biologics and French mid-pharma Pierre Fabre enter into a worldwide exclusive license agreement in the highly innovative field of immuno-oncology;;https://firstwordpharma.com/story/5343385'
  },
  {
    'Date Announced': '6-Jul-2021',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Advaxis',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Biosight',
    'Country (Target Company/Partner)': 'Israel',
    'Region (Target Company/Partner)': 'Africa',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'aspacytarabine',
    'Indication(s)': 'Acute myeloid leukaemia',
    'Deal Structure':
      "Pursuant to the merger agreement, Advaxis will acquire all of the outstanding share capital of Biosight in exchange for the issuance of newly issued shares of Advaxis common stock upon closing, subject to the satisfaction or waiver of customary closing conditions, including the receipt of the required approval of the Advaxis stockholders and Biosight stockholders and certain regulatory approvals. Upon completion of the merger, Advaxis's then-current equity holders will own approximately 25% and the former Biosight equity holders will own approximately 75% percent of Advaxis's common stock, calculated on a fully diluted basis.",
    'FW Link1':
      'Advaxis and Biosight Announce Entry into Definitive Merger Agreement;;https://firstwordpharma.com/story/5343483'
  },
  {
    'Date Announced': '6-Jul-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Sanofi',
    'Country (Acquiring Company/Partner)': 'France',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner':
      'Eureka Therapeutics, Memorial Sloan Kettering Cancer Center',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Total Deal Value ($m)': '1,000',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'multiple myeloma',
    'Target(s)': 'GPRC5D',
    'Deal Structure':
      'Under the terms of the agreement, Sanofi has exclusive rights to the GPRC5D binder for non-CAR use. Eureka and MSK are eligible to receive an upfront payment and over $1 billion of potential development, regulatory and sales milestone payments. Eureka and MSK are also eligible to receive tiered royalties on net sales.',
    'FW Link1':
      'Eureka Therapeutics Announces New GPRC5D License Agreement with Sanofi to Target Multiple Myeloma;;https://firstwordpharma.com/story/5343515'
  },
  {
    'Date Announced': '7-Jul-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Immatics',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'BioCopy AG',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy, target screening',
    'Deal Structure':
      "The extension of BioCopy's highly innovative screening platform to provide insight into T cell responses in addition to B-cell responses is a valuable milestone for BioCopy. During the last months, BioCopy has been working to develop this globally unique screening, and a patent application has already been filed on the technology. The goal of this collaboration is to enable the characterization of more than 5,000 different TCR-peptide-HLA interactions simultaneously. This ultra-high-throughput approach could significantly accelerate the development of T cell based cancer therapies by facilitating large pre-clinical safety screenings.",
    'FW Link1':
      'BioCopy AG and Immatics enter into a collaboration to characterize T cell receptor - peptide-HLA interactions;;https://firstwordpharma.com/story/5344437'
  },
  {
    'Date Announced': '9-Jul-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'I-Mab',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Immorna',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'mRNA',
    'Lead Asset Stage/Phase': 'Discovery',
    'Deal Structure':
      'Both partner companies will receive undisclosed upfront and/or milestone payments stipulated in the collaboration agreements.',
    'FW Link1':
      'I-Mab Expands Emerging Portfolio of Next Generation Novel Oncology Therapeutics Through Cutting-Edge mRNA and AI Technology Platforms;;https://firstwordhealthtech.com/story/5347141'
  },
  {
    'Date Announced': '9-Jul-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'I-Mab',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'neoXBiotech',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Discovery',
    'Deal Structure':
      'Both partner companies will receive undisclosed upfront and/or milestone payments stipulated in the collaboration agreements.'
  },
  {
    'Date Announced': '12-Jul-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'RayzeBio',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': '48Hour Discover',
    'Country (Target Company/Partner)': 'Canada',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Radiopharmaceuticals',
    'Lead Asset Stage/Phase': 'Discovery',
    'Deal Structure':
      'For this collaboration project, 48Hour Discovery will identify potent ligands for a series of targets selected by RayzeBio. Under the terms of the agreement, 48HD will receive undisclosed payments. RayzeBio has exclusive rights to develop and commercialize peptides discovered under the collaboration, and an option to further expand the collaboration to include additional targets.',
    'FW Link1':
      '48Hour Discovery and RayzeBio Announce Strategic Research Collaboration to Discover & Develop Novel Peptide-Based Radiopharmaceuticals;;https://firstwordpharma.com/story/5348077'
  },
  {
    'Date Announced': '13-Jul-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Ionis Pharmaceuticals',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Bicycle Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '56',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Oligonucleotide therapeutics',
    'Lead Asset Stage/Phase': 'Discovery',
    'Target(s)': 'TfR1',
    'Deal Structure':
      'Ionis had an option for an exclusive license under the terms of a December 2020 evaluation and option agreement. The agreement granted Ionis the right to evaluate tissue-targeting TfR1 binding Bicycles as vehicles to deliver oligonucleotide therapeutics to specific organ systems and an option to obtain an exclusive license at the end of the evaluation period. Under the terms of the license and collaboration agreement, Ionis and Bicycle will collaborate to develop a pipeline of oligonucleotide therapeutic product candidates delivered using the tissue-targeting TfR1 Bicycle technology, while Bicycle retains the rights to use TfR1 Bicycles for all non-oligonucleotide therapeutic purposes, including within its existing collaboration with DDF targeting dementia.\n \n Bicycle receives a total of $45 million upfront, which includes a license fee, an option fee, and an $11 million equity investment. Bicycle is also eligible to receive development, regulatory and commercial milestone payments and royalties for each program developed under the collaboration.',
    'FW Link1':
      'Bicycle Therapeutics Enters Exclusive License and Collaboration Agreement with Ionis to Develop Targeted Oligonucleotide Therapeutics;;https://firstwordpharma.com/story/5348649'
  },
  {
    'Date Announced': '14-Jul-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Osel',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'City of Hope',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Human microbiome drugs',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'CBM588',
    'Indication(s)': 'Renal cell carcinoma',
    'Deal Structure':
      'City of Hope®, a world-renowned independent research and treatment center for cancer, diabetes and other life-threatening diseases, and Osel, Inc., a company developing live biotherapeutic products (LBPs) for diseases linked to the disruption of the human microbiome, today announced that City of Hope has granted an exclusive worldwide license to Osel for intellectual property on the novel use of a LBP CBM588 to enhance efficacy of checkpoint inhibitors to treat cancer, including metastatic renal cell carcinoma.',
    'FW Link1':
      'City of Hope and Osel Announce Exclusive License for Intellectual Property on the Use of Live Biotherapeutic Product CBM588 for Oncology Therapeutic Applications;;https://firstwordpharma.com/story/5349993'
  },
  {
    'Date Announced': '15-Jul-2021',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'XOMA',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Kuros Bioscience / Checkmate Pharmaceuticals',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Total Deal Value ($m)': '7',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'vidutolimod',
    'Indication(s)': 'melanoma',
    'Target(s)': 'TLR9',
    'Deal Structure':
      'Under the terms of the agreement, XOMA has acquired all future potential royalties from commercial sales of vidutolimod, which are tiered from high-single to double digits. XOMA could receive up to $25 million in pre-commercial milestones associated with the Kuros/Checkmate license agreement. Kuros will be eligible to receive certain sales milestone payments from XOMA based on net sales of vidutolimod.',
    'FW Link1':
      'XOMA Acquires Royalty and Milestone Interest in Checkmate’s Vidutolimod (CMP-001) from Kuros Biosciences;;https://firstwordpharma.com/story/5350759'
  },
  {
    'Date Announced': '19-Jul-2021',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'BioNTech',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Kite Pharmaceuticals',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy',
    'Deal Structure':
      'BioNTech SE (Nasdaq: BNTX, “BioNTech”) and Kite, a Gilead Company (Nasdaq: GILD, “Kite”) today announced the two companies have entered into a purchase agreement for BioNTech to acquire Kite’s solid tumor neoantigen T cell receptor (TCR) R&D platform and clinical manufacturing facility in Gaithersburg, MD. Under the terms of the agreement, Kite will receive a one-time upfront payment from BioNTech to purchase Kite’s individualized solid tumor neoantigen TCR discovery platform as well as the Gaithersburg R&D and clinical manufacturing facility. Financial terms were not disclosed.',
    'FW Link1':
      "BioNTech to buy Kite's neoantigen TCR platform, manufacturing site;;https://firstwordpharma.com/story/5352869"
  },
  {
    'Date Announced': '20-Jul-2021',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'PacBio',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Omniome',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '600',
    'Total Deal Value ($m)': '800',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Genomic sequencing',
    'Deal Structure':
      'Under the terms of the agreement, PacBio will acquire Omniome for upfront consideration of approximately $600 million consisting of 9.4 million shares of PacBio common stock and $300 million in cash, plus an additional $200 million in cash and stock payable in connection with the achievement of certain milestones, for an overall transaction valued at approximately $800 million.',
    'FW Link1':
      'PacBio to gain short-read sequencing tech via $800-million Omniome buy;;https://firstwordhealthtech.com/story/5354069'
  },
  {
    'Date Announced': '20-Jul-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'GenFleet Therapeutics',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Insilico Medicine',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial intelligence',
    'Lead Asset Stage/Phase': 'Discovery',
    'Deal Structure':
      "The agreement outlines a constructive framework where GenFleet will synergize its own R&D systems with Insilico's end-to-end AI-powered drug discovery platform, with a view to jointly addressing significant unmet medical needs and tackling novel & difficult targets in cancer therapeutics.",
    'FW Link1':
      'GenFleet Therapeutics and Insilico Medicine Announce Strategic Partnership;;https://firstwordhealthtech.com/story/5354485'
  },
  {
    'Date Announced': '21-Jul-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Exscientia',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'GT Apeiron Therapeutics',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial intelligence, small molecules',
    'Lead Asset Stage/Phase': 'Discovery',
    'Target(s)': 'CDK7',
    'Deal Structure':
      'The collaboration will leverage the patient-centric AI-first capabilities of Exscientia to accelerate the discovery of multiple small molecule therapeutic drug candidates designed to selectively treat aberrant cell cycle driven cancers and build a pipeline of CDK novel therapies. All pipeline products will be equally owned and Exscientia holds an equity stake in Apeiron.',
    'FW Link1':
      'Exscientia and GT Apeiron Therapeutics Enter Oncology Joint Venture;;https://firstwordpharma.com/story/5354917'
  },
  {
    'Date Announced': '22-Jul-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Pfizer',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Arvinas',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '650',
    'Total Deal Value ($m)': '2,050',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Protein degradation, small molecules',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'ARV471',
    'Indication(s)': 'Breast cancer',
    'Target(s)': 'CDK 4/6',
    'Deal Structure':
      'The agreement is a worldwide co-development and co-commercialization collaboration. ARV-471 is wholly owned by Arvinas and under the financial terms of the agreement, Pfizer will pay Arvinas $650 million upfront. Separately, Pfizer will invest $350 million in Arvinas, receiving approximately 3.5 million newly issued shares of Arvinas common stock, priced at a 30% premium to the 30-day volume weighted average price on July 20, 2021. This represents an equity ownership stake by Pfizer of approximately 7%. Arvinas is also eligible to receive up to $400 million in approval milestones and up to $1 billion in commercial milestones, in addition to sharing profits on ARV-471 worldwide.\nArvinas and Pfizer will jointly develop ARV-471 through a robust clinical program designed to position ARV-471 as an endocrine backbone therapy of choice across the breast cancer treatment paradigm, from the adjuvant setting through late-line metastatic disease.',
    'FW Link1':
      "Pfizer pays $650 million upfront for rights to Arvinas' protein degrader ARV-471;;https://firstwordpharma.com/story/5356109"
  },
  {
    'Date Announced': '22-Jul-2021',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Gase Energy',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Curadox Biopharma',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'CB-TD1',
    'Indication(s)': 'Drug-resistant cancers',
    'Target(s)': 'TRF',
    'Deal Structure':
      'Gase Energy, Inc. (OTCIQ: GASE) have announced the signing of a Letter of Intent for a merger with Curadox Biopharma, Inc. (CBI), a pharmaceutical company specializing in research and development of drugs designed to target and destroy diseased/infected cells, particularly drug-resistant cancer cells.',
    'FW Link1':
      'Gase Energy, Inc. Announces an LOI for a Merger With Curadox Biopharma, Inc.;;https://firstwordpharma.com/story/5356273'
  },
  {
    'Date Announced': '22-Jul-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'ProfoundBio',
    'Country (Acquiring Company/Partner)': 'Netherlands',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Synaffix',
    'Country (Target Company/Partner)': 'Netherlands',
    'Region (Target Company/Partner)': 'Europe',
    'Total Deal Value ($m)': '246',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Lead Asset Stage/Phase': 'Discovery',
    'Deal Structure':
      "The deal provides access to multiple novel linker-payload technologies developed by Synaffix and includes access to the GlycoConnect™ glycan conjugation and HydraSpace™ polar spacer technologies, both of which are known to optimize the therapeutic properties (efficacy and safety) of ADCs. Upon signature, ProfoundBio was granted non-exclusive license rights to deploy the above technologies for one therapeutic program that targets an undisclosed specific tumor associated antigen (TAA). Additionally, ProfoundBio retains license option rights to a second TAA that can be nominated later. Under the terms of the agreement, Synaffix is eligible to receive upfront and potential milestone payments worth up to $246 million, plus tiered royalties on net sales. No further financial details are disclosed. ProfoundBio will be responsible for the research, development, manufacturing and commercialization of the ADC products. Synaffix will closely support ProfoundBio's research activities and be responsible for the manufacturing of components that are specifically related to its proprietary GlycoConnect™, and HydraSpace™ technologies.",
    'FW Link1':
      'Synaffix Announces Deal with ProfoundBio Worth up to $246 Million Providing Access to Multiple Novel Linker-Payload Technologies;;https://firstwordpharma.com/story/5356507'
  },
  {
    'Date Announced': '23-Jul-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Boehringer Ingelheim',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'ImaginAb',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Radiopharmaceuticals',
    'Indication(s)': 'Cancer',
    'Target(s)': 'CD8',
    'Deal Structure':
      'The agreement, which builds on the long-term collaboration between the two companies that started in 2017 will see ImaginAb supply clinical doses of its market leading 89Zr CD8 ImmunoPET technology to Boehringer Ingelheim for use in the clinical development of its novel oncology therapeutics throughout North America and Europe. ImaginAb will receive license fees and payments for providing ongoing technical, clinical, and regulatory support to enable the successful implementation of its CD8 ImmunoPET technology. No other terms were disclosed.',
    'FW Link1':
      'ImaginAb Announces Extension Of Long-Standing Partnership With Boehringer Ingelheim;;https://firstwordpharma.com/story/5356987'
  },
  {
    'Date Announced': '23-Jul-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Norgine BV',
    'Country (Acquiring Company/Partner)': 'Netherlands',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'US WorldMeds',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal':
      'Europe, Commonwealth of Independent States, Australia and New Zealand',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset(s)': 'DFMO (eflornithine)',
    'Indication(s)': 'neuroblastoma',
    'Deal Structure':
      'Under the terms of the license agreement, Norgine will be responsible for the regulatory approval of DFMO and any subsequent clinical trials required for approval in the relevant territories. Norgine will hold marketing authorisations in the licensed territories.',
    'FW Link1':
      'Norgine B.V. and US WorldMeds enter into exclusive licensing agreement to commercialise DFMO (eflornithine) in Europe, Commonwealth of Independent States, Australia and New Zealand;;https://firstwordpharma.com/story/5356995'
  },
  {
    'Date Announced': '26-Jul-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Lantern Pharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Allarity Therapeutics',
    'Country (Target Company/Partner)': 'Denmark',
    'Region (Target Company/Partner)': 'Europe',
    'Total Deal Value ($m)': '18',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'Irofulven',
    'Indication(s)': 'Prostate Cancer',
    'Target(s)': 'TC-NER',
    'Deal Structure':
      "Under the agreement, Lantern will purchase assets and reacquire global, exclusive rights to further develop and commercialize Irofulven, and Allarity will discontinue further involvement in the Irofulven program. Allarity will provide Lantern with existing, clinical grade drug inventory, manufacturing trade secrets and know-how, and certain data from Allarity's current Phase 2 clinical study in mCRPC, along with a developed clinical protocol for the intended ERCC2/3 study. Lantern will also receive a license to utilize, in its sole discretion, Allarity's Irofulven DRP® companion diagnostic in future clinical development and commercialization of the drug. Allarity will receive an upfront payment from Lantern, development and regulatory milestone fees, which payments together total, if all milestones (including regulatory marketing approval in the U.S. and EU) are met, up to approximately U.S. $18 million, and tiered royalties on future sales of Irofulven. Further financial terms of the agreement were not disclosed.",
    'FW Link1':
      'Allarity Therapeutics and Lantern Pharma Enter into Agreement for Future Clinical Development of Irofulven;;https://firstwordpharma.com/story/5357991'
  },
  {
    'Date Announced': '27-Jul-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Byondis',
    'Country (Acquiring Company/Partner)': 'Netherlands',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Glycotope',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Lead Asset Stage/Phase': 'Discovery',
    'Deal Structure':
      'Under the terms of the platform access agreement, Byondis has gained exclusive rights to evaluate and develop antibodies against selected novel GlycoTargets, with the option to in-license these antibodies for development as antibody-drug conjugates (ADCs). Specific financial terms have not been disclosed.',
    'FW Link1':
      'Byondis and Glycotope Enter Platform Access Agreement for Discovery and Development of Antibodies Against Novel GlycoTargets;;https://firstwordpharma.com/story/5359143'
  },
  {
    'Date Announced': '27-Jul-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Ipsen',
    'Country (Acquiring Company/Partner)': 'France',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'BAKX Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '15',
    'Total Deal Value ($m)': '852',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'BKX-001',
    'Indication(s)': 'leukemia, lymphoma, solid tumors',
    'Target(s)': 'BCL-2 associated protein (BAX)',
    'Deal Structure':
      'Under the agreement, Ipsen will pay BAKX Therapeutics Inc. $14.5m upon closing, comprising an equity investment and an upfront payment, followed by up to $837.5m in milestone payments. The companies would also share equally costs and profits.',
    'FW Link1':
      'Ipsen Strengthens Its Pre-clinical Oncology Pipeline With an Exclusive Worldwide-collaboration With BAKX Therapeutics Inc. for BKX-001, Targeting the Apoptosis Pathway;;https://firstwordpharma.com/story/5359793'
  },
  {
    'Date Announced': '27-Jul-2021',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Amgen',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Teneobio',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '900',
    'Total Deal Value ($m)': '1,600',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'TNB-585',
    'Indication(s)': 'Prostate Cancer',
    'Target(s)': 'CD3',
    'Deal Structure':
      'Under the terms of the agreement, Amgen will acquire all outstanding shares of Teneobio at closing in exchange for a $900 million upfront cash payment, as well as future contingent milestone payments to Teneobio equity holders potentially worth up to an additional $1.6 billion in cash.',
    'FW Link1':
      'Amgen steps up antibody capabilities with Teneobio buy for up to $2.5 billion;;https://firstwordpharma.com/story/5359989'
  },
  {
    'Date Announced': '27-Jul-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Bridge Medicines',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Cornell University',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Discovery',
    'Target(s)': 'UBR5',
    'FW Link1':
      'Bridge Medicines Enters Exclusive License Agreement with Cornell University for UBR5 Inhibitor Program to Treat Resistant Cancers;;https://firstwordpharma.com/story/5360683'
  },
  {
    'Date Announced': '28-Jul-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'China Resources Double Crane Pharmaceutical',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Novita Pharmaceuticals',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '15',
    'Total Deal Value ($m)': '300',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'NP-G2-044',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'fascin',
    'Deal Structure':
      "Under the terms of the agreement, Novita will receive an upfront payment of $15 million and additional payments upon reaching certain development and regulatory milestones, of up to $300 million for multiple cancer indications. Novita is also eligible to receive tiered royalties based on net sales. CRDC will receive the rights to research, develop, manufacture, and exclusively commercialize Novita's fascin inhibitor in oncology applications in the Greater China region.",
    'FW Link1':
      'Novita Pharmaceuticals and China Resources Double Crane Pharmaceutical Enter into an Exclusive License Agreement to Develop and Commercialize Fascin Inhibitor in China;;https://firstwordpharma.com/story/5360967'
  },
  {
    'Date Announced': '29-Jul-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Eli Lilly',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Kumquat Bio',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '70',
    'Total Deal Value ($m)': '2,000',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Discovery',
    'Deal Structure':
      "Kumquat will receive an aggregate of $70 million consisting of a cash upfront payment and an equity investment. Kumquat is eligible to receive over $2 billion in potential milestone payments based on the achievement of preclinical, development and future commercial milestones, as well as royalties on sales of commercialized products resulting from the collaboration. Kumquat has retained development and commercialization rights in Greater China for each of the drug candidates selected by Lilly, subject to Lilly's option to co-commercialize in Greater China. Additionally, Kumquat has the option to co-develop and co-commercialize a certain number of the drug candidates selected by Lilly in the U.S.",
    'FW Link1':
      'Lilly inks immuno-oncology deal with Kumquat Bio for up to $2 billion;;https://firstwordpharma.com/story/5361239'
  },
  {
    'Date Announced': '2-Aug-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Carrick Therapeutics',
    'Country (Acquiring Company/Partner)': 'Ireland {Republic}',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Roche',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'samuracicblib and giredastrant',
    'Indication(s)': 'breast cancer',
    'Target(s)': 'CDK7',
    'Deal Structure':
      "Carrick Therapeutics, an oncology-focused biopharmaceutical company discovering and developing highly differentiated therapies, today announced a clinical collaboration with Roche to evaluate a novel combination of Carrick's samuraciclib and Roche's giredestrant in CDK4/6i resistant HR+, HER2- metastatic breast cancer. Under the agreement, each company is supplying its respective anti-cancer agent to support the trial.",
    'FW Link1':
      'Carrick Therapeutics Announces Collaboration with Roche to Evaluate Novel Samuraciclib Combination to Treat HR+ Breast Cancer;;https://firstwordpharma.com/story/5363921'
  },
  {
    'Date Announced': '2-Aug-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Qlarity Imaging',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Blackford',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'Deal Structure':
      "Qlarity Imaging, a leading innovator in artificial intelligence (AI) diagnostics and portfolio company of Paragon Biosciences, and Blackford, the provider of the dedicated platform for medical imaging applications and AI, today announced their agreement to integrate QuantX™ within Blackford's curated marketplace.",
    'FW Link1':
      'Qlarity Imaging and Blackford Partner to Bring QuantX Diagnostic AI to More Breast Radiologists via Blackford Platform, Helping More Patients in Need;;https://firstwordhealthtech.com/story/5363951'
  },
  {
    'Date Announced': '2-Aug-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Moderna',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Autolus',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'mRNA, cell therapy',
    'Lead Asset Stage/Phase': 'Discovery',
    'Deal Structure':
      'Autolus Therapeutics plc (Nasdaq: AUTL), a clinical-stage biopharmaceutical company developing next-generation programmed T cell therapies, today announced an agreement with Moderna, a biotechnology company pioneering messenger RNA (mRNA) therapeutics and vaccines, granting Moderna an exclusive license to develop and commercialize mRNA therapeutics incorporating Autolus’ proprietary binders for up to four immuno-oncology targets. Autolus would be eligible to receive an upfront payment for each target licensed by Moderna and development and commercial milestone payments for each product successfully commercialized. In addition, Autolus would be entitled to receive royalties on net sales of all products commercialized under the agreement.',
    'FW Link1':
      "Moderna gains access to Autolus' technology for mRNA immuno-oncology drugs;;https://firstwordpharma.com/story/5364023",
    'FW Link2':
      'Exercise of option announced October 12, 2022 https://firstwordpharma.com/story/5662749/'
  },
  {
    'Date Announced': '2-Aug-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Tachyon',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'AbCellera',
    'Country (Target Company/Partner)': 'Canada',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Discovery',
    'Target(s)': 'LEFTY1',
    'Deal Structure':
      'Under the terms of the agreement, AbCellera is eligible to receive milestone payments and royalties on products that are derived from its antibody discovery platform. In addition, AbCellera has the option to invest in preclinical and clinical development in exchange for an increased share of product sales.',
    'FW Link1':
      'Tachyon and AbCellera Collaborate to Develop Novel Antibody Therapeutic Targeting TGF-β Superfamily Member for the Treatment of Cancer;;https://firstwordpharma.com/story/5364749'
  },
  {
    'Date Announced': '3-Aug-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'IMPACT Therapeutics',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Burning Rock',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'senaparib',
    'Indication(s)': 'Prostate Cancer',
    'Target(s)': 'PARP',
    'Deal Structure':
      'The two companies will jointly develop CDx for a targeted oncology drug, Senaparib (IMP4297), a PARP inhibitor, for the treatment of prostate cancer globally, including CDx submissions to both the U.S. Food and Drug Administration (FDA) and the National Medical Products Administration of China (NMPA).',
    'FW Link1':
      'Burning Rock and IMPACT Therapeutics Announce a Global Strategic Partnership for Companion Diagnostics Development;;https://firstwordhealthtech.com/story/5365243'
  },
  {
    'Date Announced': '3-Aug-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Deciphera Pharmaceuticals',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Sprint Bioscience',
    'Country (Target Company/Partner)': 'Sweden',
    'Region (Target Company/Partner)': 'Europe',
    'Upfront Value ($m)': '4',
    'Total Deal Value ($m)': '277',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'Vps34',
    'Deal Structure':
      'In addition to the upfront payment, Sprint Bioscience is entitled to payments linked to predefined milestones during the project’s further development, regulatory process and commercialization of a drug. With the upfront, the total value of these payments amounts up to USD 277 million.Should the program result in an approved drug, Sprint Bioscience is also entitled to tiered percentage royalties ranging from the mid-single-digits to the low-double-digits on the sales of a future drug from the program, if approved.',
    'FW Link1':
      'Sprint Bioscience licenses the cancer drug program Vps34 to Deciphera Pharmaceuticals;;https://firstwordpharma.com/story/5365331'
  },
  {
    'Date Announced': '4-Aug-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Zai Lab',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Schrodinger',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Total Deal Value ($m)': '338',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules, DNA damage repair',
    'Lead Asset Stage/Phase': 'Discovery',
    'Deal Structure':
      "The companies will be responsible for their own research program expenses, and under the terms of the collaboration agreement, Zai Lab will make an upfront payment to Schrödinger to help fund Schrödinger's share of research costs. Following the selection of a development candidate, Zai Lab will assume primary responsibility for global development, manufacturing and commercialization. The agreement provides Schrödinger co-development and co-commercialization rights in the U.S. If Schrödinger elects to co-fund clinical development of a product candidate under the collaboration, it will be entitled to 50 percent of the profits, if any, from the commercialization of such product candidate in the United States. Schrödinger will also be eligible to receive up to approximately $338 million in preclinical, clinical, regulatory and sales-based milestone payments. Additionally, Schrödinger is entitled to receive royalties on net sales outside the U.S.",
    'FW Link1':
      'Schrödinger and Zai Lab Announce a Global R&D Collaboration in Oncology;;https://firstwordpharma.com/story/5366345'
  },
  {
    'Date Announced': '4-Aug-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Calidi Biotherapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Northwestern University',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Oncolytic viruses',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'NeuroNova-1',
    'Indication(s)': 'Brain tumours',
    'Deal Structure':
      'Under the License Agreement, Northwestern granted to Calidi a worldwide, twelve-year exclusivity for use of the clinical data in the commercial development of NSC-CRAd-S-pk7 for therapeutic and preventive uses in oncology and a right of reference to NU IND 17365 which relates to the treatment of newly diagnosed glioma.',
    'FW Link1':
      'Calidi Biotherapeutics Announces Agreement with Northwestern University for Exclusive Commercial Rights to their IND for Treatment of Malignant Glioma;;https://firstwordpharma.com/story/5366351'
  },
  {
    'Date Announced': '5-Aug-2021',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Bayer',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Vivdion',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '1,500',
    'Total Deal Value ($m)': '2,000',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Discovery',
    'Deal Structure':
      'Under the terms of the agreement, Bayer will pay an upfront consideration of USD 1.5 billion and potential success-based milestone payments of up to USD 500 million.',
    'FW Link1':
      'Bayer bolsters small-molecule capabilities with purchase of Vividion;;https://firstwordpharma.com/story/5366831'
  },
  {
    'Date Announced': '5-Aug-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Gilead Sciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Appia Bio',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Total Deal Value ($m)': '875',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Deal Structure':
      'Under the terms of the agreement, Appia Bio will be responsible for preclinical and early clinical research of two HSC-derived CAR-iNKT product candidates engineered with CARs provided by Kite. Appia Bio will receive an upfront payment, an equity investment, and additional milestone payments for a total value of up to $875 million as well as tiered royalties. Kite will be responsible for the development, manufacturing, and commercialization of the product candidates identified through the collaboration.',
    'FW Link1':
      'Kite and Appia Bio Announce Collaboration to Research and Develop Allogeneic Cell Therapies for Cancer;;https://firstwordpharma.com/story/5367397'
  },
  {
    'Date Announced': '5-Aug-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Usynova',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Insilico Medicine',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules, artificial intelligence',
    'Lead Asset Stage/Phase': 'Discovery',
    'FW Link1':
      'Insilico Medicine and Usynova Announce Strategic Partnership on Accelerating R&D of Small Molecule Innovative Drugs with AI;;https://firstwordhealthtech.com/story/5367755'
  },
  {
    'Date Announced': '5-Aug-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Sierra Oncology',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'AstraZeneca',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'AZD5153',
    'Indication(s)': 'myelofibrosis',
    'Target(s)': 'BRD4 BET',
    'Deal Structure':
      'Under the terms of the agreement, Sierra will pay AstraZeneca an upfront payment, as well as certain pre-determined development, regulatory and commercial milestones. In addition, Sierra will provide tiered royalty payments based on future commercial success. Sierra will be responsible for the initial Phase 2 trial execution and all future global development and commercialization activities.',
    'FW Link1':
      'Sierra Oncology Signs Exclusive Global In-Licensing Agreement with AstraZeneca for Novel BET Inhibitor to Expand Myelofibrosis Pipeline;;https://firstwordpharma.com/story/5368035'
  },
  {
    'Date Announced': '9-Aug-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'HUTCHMED',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Epizyme',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '25',
    'Total Deal Value ($m)': '285',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Tazverik',
    'Indication(s)': 'epithelioid sarcoma, follicular lymphoma',
    'Deal Structure':
      'Under the terms of the agreement, HUTCHMED will be responsible for the development and commercialization of TAZVERIK® in greater China. Epizyme will receive a US$25 million upfront payment and is eligible to receive up to an additional US$110 million in development and regulatory milestone payments, across up to eight potential indications, and up to an additional US$175 million in sales milestone payments. Epizyme is also eligible to receive tiered royalties of mid -teen to low-twenties-percent based on annual net sales of TAZVERIK® in Greater China. In addition, HUTCHMED receives a four-year warrant to acquire up to US$65 million of Epizyme shares at US$11.50 per share. The upfront payment will be funded by HUTCHMED from existing cash resources, and potential milestone payments and royalties are expected to be funded from future cash resources including cash from the sales of TAZVERIK®',
    'FW Link1':
      'HUTCHMED and Epizyme Announce Strategic Collaboration to Develop and Commercialize TAZVERIK (tazemetostat) in Greater China;;https://firstwordpharma.com/story/5369573'
  },
  {
    'Date Announced': '9-Aug-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Antengene',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'MindRank',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial intelligence, small molecules',
    'Lead Asset Stage/Phase': 'Discovery',
    'FW Link1':
      'Antengene and MindRank AI Enter into Collaboration to Advance the Development of Difficult-to-Drug Molecular Targets;;https://firstwordpharma.com/story/5369577'
  },
  {
    'Date Announced': '9-Aug-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Seagen',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'RemeGen',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '200',
    'Total Deal Value ($m)': '2,600',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugate',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'disitamab vedotin',
    'Target(s)': 'HER2',
    'Deal Structure':
      'Under the terms of the agreement, Seagen will make a $200 million upfront payment to exclusively license rights to disitamab vedotin for global development and commercialization, outside of RemeGen’s territory. RemeGen will retain development and commercialization rights for Asia, excluding Japan and Singapore. Seagen will lead global development and RemeGen will fund and operationalize the portion of global clinical trials attributable to its territory. RemeGen will also be responsible for all clinical development and regulatory submissions specific to its territory.\n \n Seagen will pay RemeGen up to $2.4 billion in potential total milestone payments based upon the achievement of specified development, regulatory and commercialization goals across multiple indications and products. RemeGen will be entitled to a tiered, high single digit to mid-teen percentage royalty based on net sales of disitamab vedotin in Seagen’s territory.',
    'FW Link1':
      "Seagen to pay $200 million upfront for rights to RemeGen's ADC disitamab vedotin;;https://firstwordpharma.com/story/5369611"
  },
  {
    'Date Announced': '9-Aug-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Algernon Pharmaceuticals',
    'Country (Acquiring Company/Partner)': 'Canada',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Darthmouth',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'NP-120',
    'Indication(s)': 'pancreatic cancer',
    'Target(s)': 'NMDA',
    'Deal Structure':
      'The agreement with Dartmouth includes a license initiation fee, maintenance, and milestone fees, as well as an ongoing royalty on sales.',
    'FW Link1':
      'Algernon Pharmaceuticals Announces Exclusive Licensing Agreement with Dartmouth College;;https://firstwordpharma.com/story/5369833'
  },
  {
    'Date Announced': '9-Aug-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'OncXerna',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Qiagen',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics, bispecific antibodies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'Navicixizumab',
    'Indication(s)': 'ovarian cancer',
    'Target(s)': 'DLL4, VEGF',
    'Deal Structure':
      'QIAGEN and OncXerna have agreed to collaborate to advance the Xerna™ TME panel towards IVD (in-vitro diagnostic) regulatory approval as a NGS companion diagnostic for Navicixizumab, which is being developed by OncXerna as a treatment for patients with ovarian cancer. Pursuant to the non-exclusive license, which is for research use only ("RUO") and clinical development programs, OncXerna has granted QIAGEN the rights to integrate the Xerna™ TME panel into their NGS workflow solutions, which broadens QIAGEN\'s NGS CDx custom panel development capabilities.',
    'FW Link1':
      'QIAGEN and OncXerna Therapeutics Sign Licensing and Master Companion Diagnostic Agreements;;https://firstwordpharma.com/story/5370437'
  },
  {
    'Date Announced': '10-Aug-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Tesis Labs',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Personal Genome Diagnostics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Bioinformatics, diagnostics',
    'FW Link1':
      'Tesis Labs and Personal Genome Diagnostics Announce Collaboration to Advance Cancer Profiling and Treatment;;https://firstwordhealthtech.com/story/5371351'
  },
  {
    'Date Announced': '11-Aug-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'PhotoPharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Terumo Blood',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cancer vaccine',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'Innocell therapeutic cancer vaccine',
    'Indication(s)': 'Triple-negative breast cancer',
    'FW Link1':
      'Terumo Blood and Cell Technologies and PhotonPharma announce collaboration to develop a novel cancer immunotherapy;;https://firstwordpharma.com/story/5372427'
  },
  {
    'Date Announced': '16-Aug-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Calidi Biotherapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'City of Hope; University of Chicago',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Oncolytic viruses',
    'Lead Asset Stage/Phase': 'Discovery',
    'Deal Structure':
      "This exclusive license agreement, which was executed by the University of Chicago's Polsky Center for Entrepeneurship and Innovation, transferred the COH/University of Chicago IND to Calidi for the commercial development of a licensed product. The agreement grants to Calidi commercial exclusivity in using neural stem cells with the adenovirus known as CRAd-pk-S-7 for oncolytic virotherapy.",
    'FW Link1':
      'Calidi Biotherapeutics Announces Exclusive License Agreement with City of Hope and the University of Chicago for Novel Oncolytic Virotherapy Technology;;https://firstwordpharma.com/story/5375881'
  },
  {
    'Date Announced': '16-Aug-2021',
    'Deal type': 'Alliance',
    'Acquiring Company/Partner': 'Fujifilm',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'ScreenPoint Medical',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial Intelligence, diagnostics',
    'FW Link1':
      'Fujifilm and ScreenPoint Medical Partner to Offer Providers with an AI Solution Designed for Early Breast Cancer Detection;;https://firstwordhealthtech.com/story/5375923'
  },
  {
    'Date Announced': '16-Aug-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Gracell Biotechnologies',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'FutureGen Biopharma',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Gastric cancer',
    'Target(s)': 'CLDN18.2',
    'Deal Structure':
      'Under the terms of the agreement, FutureGen will receive an upfront payment and will be eligible to receive additional payments based on the achievement of non-clinical validation, clinical development and commercialization milestones, as well as low single-digit royalties.',
    'FW Link1':
      'Gracell Biotechnologies Signs Exclusive License Agreement with FutureGen Biopharm to Develop Engineered Immune Cell Therapies Targeting Claudin 18.2 in Solid Tumors;;https://firstwordpharma.com/story/5376013'
  },
  {
    'Date Announced': '16-Aug-2021',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'HiberCell',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Genuity Science',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial intelligence, drug discovery',
    'Deal Structure':
      'Under the terms of the agreement, Genuity Science, Inc., and its two wholly owned subsidiaries, Genuity Science (Iceland) ehf and Genuity Science (Ireland) Limited, will become a wholly owned subsidiary of HiberCell.',
    'FW Link1':
      'HiberCell Expands Translational Oncology Toolbox by Acquiring Genuity Science;;https://firstwordpharma.com/story/5376107'
  },
  {
    'Date Announced': '17-Aug-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Incyte',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'InnoCare',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '35',
    'Total Deal Value ($m)': '118',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Monjuvi',
    'Indication(s)': 'DLBCL',
    'Target(s)': 'CD19',
    'Deal Structure':
      'Under the terms of the agreement, InnoCare will pay Incyte US$35 million up front, and Incyte is eligible to receive up to an additional US$82.5 million in potential development, regulatory and commercial milestones, as well as tiered royalties. InnoCare will receive the rights to develop and exclusively commercialize tafasitamab in hematology and oncology in mainland China, Hong Kong, Macau and Taiwan.',
    'FW Link1':
      'Incyte grants InnoCare rights to CD19-directed antibody Monjuvi in China;;https://firstwordpharma.com/story/5376693'
  },
  {
    'Date Announced': '17-Aug-2021',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'Shorla Pharma',
    'Country (Acquiring Company/Partner)': 'Ireland {Republic}',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'EVERSANA',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'SH-111',
    'Indication(s)': 'T-cell leukemia',
    'Deal Structure':
      'Under the terms of the agreement, Shorla maintains ownership of SH-111 as well as all financial, legal, regulatory, and manufacturing responsibilities for the product. EVERSANA receives the exclusive right to support all commercialization activities for SH-111 in partnership with Shorla.',
    'FW Link1':
      'Shorla Pharma Announces Partnership with EVERSANA to Support the Launch and Commercialization of T-cell Leukemia Treatment;;https://firstwordpharma.com/story/5377069'
  },
  {
    'Date Announced': '17-Aug-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Navrogen',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Tavotek Biotherapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Discovery',
    'Deal Structure':
      "Under the agreement, Navrogen and Tavotek will collaborate on the development of future Tavotek oncology assets while utilizing Navrogen's proprietary screening technology to identify mAb leads that are susceptible to Humoral Immuno-Oncology (HIO) factors.",
    'FW Link1':
      'Navrogen and Tavotek Biotherapeutics Announce Strategic Collaboration to Improve Therapeutic Efficacy of Antibody Pipeline Agents;;https://firstwordpharma.com/story/5377171'
  },
  {
    'Date Announced': '18-Aug-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Telix',
    'Country (Acquiring Company/Partner)': 'Australia',
    'Region (Acquiring Company/Partner)': 'Austrailia',
    'Target Company/Partner': 'Merck KGaA',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'DNA damage repair, radiopharmaceuticals',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'Unamed Merck KGaA molecule + TLX591, TLX250',
    'Indication(s)': 'Prostate cancer, renal cancer',
    'Deal Structure':
      "Telix Pharmaceuticals Limited (ASX: TLX, 'Telix', the 'Company') today announces that it has entered into a pan-cancer clinical collaboration with Merck KGaA, Darmstadt, Germany ('Merck'), to conduct combination studies with one of Merck's investigational proprietary DNA Damage Response Inhibitor (DDRi) molecules in combination with each of Telix's TLX591 (177Lu-rosopatamab) and TLX250 (177Lu-girentuximab) molecularly targeted radiation (MTR) therapeutic programs.",
    'FW Link1':
      'Telix and Merck to Commence Pan-Cancer Clinical Combination Studies;;https://firstwordpharma.com/story/5377971'
  },
  {
    'Date Announced': '18-Aug-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Werewolf Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Merck & Co.',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules, monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'WTX-124 + Keytruda',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'IL-2, PD-1',
    'Deal Structure':
      'The planned clinical trial will be conducted by Werewolf Therapeutics and is designed to evaluate the safety and preliminary efficacy of WTX-124 as a monotherapy and in combination with KEYTRUDA in patients with solid tumors.',
    'FW Link1':
      'Werewolf Therapeutics Announces Clinical Trial Collaboration with Merck on its WTX-124 INDUKINE Program;;https://firstwordpharma.com/story/5377973'
  },
  {
    'Date Announced': '18-Aug-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'J&J',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Optellum',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial intelligence, biomarkers',
    'Indication(s)': 'Lung cancer',
    'FW Link1':
      'Optellum Announces Strategic Collaboration With The Lung Cancer Initiative At Johnson & Johnson, Applying AI To Transform Early Lung Cancer Treatment;;https://firstwordhealthtech.com/story/5378107'
  },
  {
    'Date Announced': '19-Aug-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Coeptis Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'VyGen-Bio',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy, diagnostics',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'CD38-GEAR-NK',
    'Indication(s)': 'multiple myeloma',
    'Target(s)': 'CD38',
    'Deal Structure':
      "This transaction follows Coeptis' initial entry into two separate exclusive option agreements with VyGen-Bio, Inc. as announced on May 18, 2021. Per the option agreements and subsequent amendments, Coeptis paid VyGen-Bio a combination of cash and promissory notes to acquire the rights to the co-development assets.",
    'FW Link1':
      'Coeptis Therapeutics Partners with VyGen-Bio to Co-develop Two Assets Designed to Improve the Treatment of CD38-Related Cancers;;https://firstwordpharma.com/story/5379071'
  },
  {
    'Date Announced': '19-Aug-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'BriaCell',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'ImaginAb',
    'Country (Target Company/Partner)': 'Canada',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics, drug development',
    'Lead Asset Stage/Phase': 'Phase II',
    'Indication(s)': 'Breast cancer',
    'Target(s)': 'CD8',
    'Deal Structure':
      "Under the terms of the agreement, ImaginAb will supply clinical doses of its market leading CD8 ImmunoPET technology (89Zr-Df-Crefmirlimab) to BriaCell for use in a study implemented as part of BriaCell's ongoing Phase I/IIa study with Incyte in metastatic or locally recurrent breast cancer patients. Specifically, ImaginAb will receive license fees and payments for providing ongoing technical, clinical, and regulatory support to enable the successful implementation of its CD8 ImmunoPET technology. No other terms are disclosed.",
    'FW Link1':
      'BriaCell and ImaginAb Announce Collaboration to Evaluate Immunotherapy Imaging Technology in Advanced Breast Cancer;;https://firstwordpharma.com/story/5379213'
  },
  {
    'Date Announced': '20-Aug-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'BioNova Pharmaceuticals',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Insilico Medicines',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial intelligence, small molecules',
    'Lead Asset Stage/Phase': 'Discovery',
    'FW Link1':
      'Insilico Medicine Collaborates with BioNova to Develop Small Molecule Drugs for Hematologic Malignancies;;https://firstwordpharma.com/story/5379875'
  },
  {
    'Date Announced': '20-Aug-2021',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'KeChow',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Roche',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Zelboraf',
    'Indication(s)': 'melanoma',
    'Target(s)': 'BRAF',
    'FW Link1':
      'Roche and KeChow Reach a Cooperation Agreement;;https://firstwordpharma.com/story/5379977'
  },
  {
    'Date Announced': '23-Aug-2021',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Pfizer',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Trillium',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Total Deal Value ($m)': '2,300',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'TT-662, TT-661',
    'Indication(s)': 'lymphomas',
    'Target(s)': 'CD47',
    'Deal Structure':
      'Under the terms of the agreement, Pfizer will acquire all outstanding shares of Trillium not already owned by Pfizer for an implied equity value of $2.26 billion, or $18.50 per share, in cash. This represents a 118% premium to the 60-day weighted average price for Trillium.',
    'FW Link1':
      'Pfizer bolsters oncology pipeline via Trillium buy;;https://firstwordpharma.com/story/5380771'
  },
  {
    'Date Announced': '23-Aug-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Viracta Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Shenzhen Salubris Pharmaceuticals Co., Ltd.',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Total Deal Value ($m)': '4',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'nanatinostat plus valganciclovir',
    'Indication(s)': 'EBV positive lymphoma',
    'Deal Structure':
      'Pursuant to the mutual termination agreement between Viracta and Salubris, all development and commercialization rights under the license have been returned to Viracta in exchange for a $4.0 million cash payment to Salubris.',
    'FW Link1':
      'Viracta Therapeutics Reacquires Exclusive Development and Commercialization Rights for its All-Oral Combination Therapy in China;;https://firstwordpharma.com/story/5380993'
  },
  {
    'Date Announced': '25-Aug-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'JMT-Bio',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Alphamab Biopharmaceuticals',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '23',
    'Total Deal Value ($m)': '154',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Bispecific antibodies',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'KN026',
    'Indication(s)': 'Breast cancer',
    'Target(s)': 'HER2',
    'Deal Structure':
      'According to the terms of the agreement, JMT-Bio will obtain the exclusive license rights of KN026 for the development and commercialization in the indications of breast cancer and gastric or gastroesophageal junction cancers (GC/GEJ) in mainland China (excluding Hong Kong, Macau and Taiwan), and bear the cost of all planed clinical trials in territory; In addition, JMT-Bio will devote joint efforts with Alphamab to develop more potential indications of KN026 single agent or combo treatment in other solid tumors . Alphamab Oncology will receive 150 million RMB upfront payment, with up to 850 million RMB development and sales milestone payments, and will receive a double-digit royalty based on sales revenue.',
    'FW Link1':
      'Alphamab Oncology and CSPC Achieved an Licensing Cooperation on Anti-HER2 Bispecific Antibody KN026 in Mainland China;;https://firstwordpharma.com/story/5382709'
  },
  {
    'Date Announced': '25-Aug-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'iBio',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'RubrYc Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '5',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'RTX-003',
    'Target(s)': 'CD25',
    'Deal Structure':
      "As part of the agreements, iBio made an upfront $5.0 million payment to RubrYc, with an additional $2.5 million commitment for December 2021. In return, the Company will receive the RTX-003 commercialization rights, options for additional molecules developed using RubrYc's predictive algorithms, and an equity stake. RubrYc is eligible to receive certain pre-specified payments upon achievement of development milestones for IBIO-101, as well as royalties on net sales of that molecule and other licensed antibodies.",
    'FW Link1':
      'iBio Expands Immuno-Oncology Pipeline in AI Partnership with RubrYc Therapeutics;;https://firstwordpharma.com/story/5383535'
  },
  {
    'Date Announced': '25-Aug-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Flame Biosciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'NovaRock Biotherapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global excluding China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'NBL-015',
    'Target(s)': 'Claudin 18.2',
    'Deal Structure':
      "Under the terms of the Agreement, NovaRock has granted Flame Biosciences the exclusive rights to NBL-015 outside of Greater China (including mainland China, Hong Kong, Macau, and Taiwan). Flame shall be responsible for the development, regulatory approval, and commercialization of NBL-015. NovaRock will, at Flame Biosciences's expense, collaborate with Flame on the discovery and preclinical development of two new bispecific antibodies based on NovaRock's NovaTE bi-specific antibody technology platform and subsequently grant Flame Biosciences the exclusive rights to further develop, manufacture and commercialize the Licensed Products.",
    'FW Link1':
      'NovaRock Biotherapeutics Announces Exclusive License Agreement and Strategic Partnership with Flame BioSciences;;https://firstwordpharma.com/story/5383607'
  },
  {
    'Date Announced': '26-Aug-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Nuvectis Pharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'CRT Pioneer Fund LP',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'NXP800',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'HSF1',
    'FW Link1':
      'Nuvectis Pharma Raises $15 Million and In-Licenses Exclusive Worldwide Rights to Novel Selective HSF1 Pathway Inhibitor from the CRT Pioneer Fund;;https://firstwordpharma.com/story/5384371'
  },
  {
    'Date Announced': '26-Aug-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Innovent Biologics',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Bolt Biotherapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '5',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody drug conjugates',
    'Lead Asset Stage/Phase': 'Discovery',
    'Deal Structure':
      "Under the agreement, Innovent has the rights to all three programs in Greater China, and retains an option to license global rights for one program, as well as rights for all territories except North America for another program. Bolt retains the option to license global rights outside of Greater China for one program, and North American rights for another program. Innovent is responsible for all research and development costs through clinical proof-of-concept. Upon review of the initial clinical proof-of-concept data, the companies can exercise licensing options for continued development and exclusive commercialization rights in specific territories on a program-by-program basis. Bolt will receive an upfront payment of $5 million in cash from Innovent at signing and a possible future equity investment of up to $10 million. Furthermore, both Bolt and Innovent are eligible to receive additional milestone payments and royalties associated with the development and commercialization of products in each other's territories.",
    'FW Link1':
      'Bolt Biotherapeutics and Innovent Biologics Announce Collaboration to Develop Three New Oncology Boltbody ISAC Programs;;https://firstwordpharma.com/story/5384591'
  },
  {
    'Date Announced': '1-Sep-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Bellicum',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'MD Anderson Cancer Center',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy',
    'Deal Structure':
      "Bellicum Pharmaceuticals, Inc. (NASDAQ:BLCM), a leader in developing novel, controllable cellular immunotherapies for cancers, and The University of Texas MD Anderson Cancer Center today announced a global option and license agreement covering certain intellectual property and technology rights regarding Bellicum's CaspaCIDe® (inducible caspase-9, or iC9) safety switch and related technologies, and the use of rimiducid, an agent used to activate the safety switch. Under this agreement, MD Anderson will have the option to incorporate CaspaCIDe into certain cellular therapy programs. Upon exercise of each option - typically expected to be upon out-license of an MD Anderson program that incorporates iC9 - Bellicum will receive an upfront payment and will be entitled to a percentage of certain consideration paid to MD Anderson by the third party. Bellicum also will receive a single-digit-percent royalty on global sales of the product. Additional details of the financial arrangements are not disclosed. Bellicum and MD Anderson have agreed on the first two programs for development concurrent with the execution of the agreement. This agreement expands upon a previous one, which covers the use of CaspaCIDe in a specific MD Anderson cell therapy program.",
    'FW Link1':
      'Bellicum and MD Anderson Announce Additional License Agreement for Use of CaspaCIDe Safety Switch;;https://firstwordpharma.com/story/5388509'
  },
  {
    'Date Announced': '2-Sep-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Innovent Biologics',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'GenFleet',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'GFH925',
    'Indication(s)': 'KRAS G12Cm cancers',
    'Target(s)': 'KRAS',
    'Deal Structure':
      'According to the agreement, Innovent will be responsible for clinical development and commercialization of GFH925 in China, while retaining option-in right for development and commercialization outside of China as well. Following approval of a New Drug Application (NDA), Innovent will leverage its broad commercialization capability that includes an experienced commercialization team with extensive nationwide coverage to roll out GFH925, with the goal to benefit cancer patients in China. GenFleet will continue to be responsible for supplying GFH925 for both development and commercial purposes in China.',
    'FW Link1':
      'Innovent and GenFleet Announce Exclusive Global License Agreement for GFH925 (KRAS G12C Inhibitor);;https://firstwordpharma.com/story/5389173'
  },
  {
    'Date Announced': '2-Sep-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Precision BioSciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Tiziana Life Sciences',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'foralumab + CAR-T',
    'Indication(s)': 'Lymphomas',
    'Target(s)': 'CD3',
    'Deal Structure':
      'Under the terms of the agreement, Precision gains an exclusive license to use foralumab as a lymphodepletion agent in conjunction with its allogeneic CAR T therapeutics for the treatment of cancers. Precision will be responsible for the development, commercialization, and costs for use of foralumab, and Tiziana will receive upfront payment, certain milestone payments, and royalties for foralumab.',
    'FW Link1':
      'Precision BioSciences and Tiziana Life Sciences Announce Exclusive License Agreement to Evaluate Foralumab, a Novel, Fully Human Anti-CD3 Monoclonal Antibody, in Conjunction with Allogeneic CAR T Candidates for Cancer Treatment;;https://firstwordpharma.com/story/5389181'
  },
  {
    'Date Announced': '5-Sep-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Citius Pharmaceuticals',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': "Dr. Reddy's Laboratories",
    'Country (Target Company/Partner)': 'India',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '40',
    'Total Deal Value ($m)': '150',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'E777',
    'Indication(s)': 'Cutaneous T-cell lymphoma',
    'Deal Structure':
      "Under the terms of agreement, Dr. Reddy's will receive $40 million upfront upon the closing of the transaction, followed by approval milestone payment of up to $40 million related to the CTCL (cutaneous T-cell lymphoma)indication approval and up to $70 million for additional indication approvals. Further, Dr. Reddy's will receive certain sales-based milestones and tiered earn-out payments.",
    'FW Link1':
      'Dr. Reddy’s Laboratories enters into definitive agreement with Citius Pharmaceuticals, Inc. to sell its rights to anti-cancer agent E7777 (denileukin diftitox);;https://firstwordpharma.com/story/5391193'
  },
  {
    'Date Announced': '6-Sep-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'GeneQuantum',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'BrightGene',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'GQ 1007',
    'FW Link1':
      'GeneQuantum and BrighGene sign agreement for development of antibody immune agonist conjugates;;https://firstwordpharma.com/story/5391255'
  },
  {
    'Date Announced': '7-Sep-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Roche',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Adaptimmune Therapeutics',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Upfront Value ($m)': '150',
    'Total Deal Value ($m)': '3,300',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy',
    'Lead Asset Stage/Phase': 'Discovery',
    'Deal Structure':
      'Under the terms of the agreement, Adaptimmune will receive an upfront payment of $150 million and additional payments of $150 million over five years, unless the agreement is earlier terminated. In addition, Adaptimmune may be eligible to receive research, development, regulatory and commercial milestones payments potentially exceeding $3 billion in aggregate value.\n \n Adaptimmune will also receive tiered royalties on net sales in the mid-single to low-double digits. \n \n Adaptimmune has the right to opt in to a 50/50 U.S. profit/cost share on "off-the-shelf" products. If Adaptimmune elects to opt in, then Adaptimmune will be eligible to share 50 percent of profits and losses from U.S. sales on such products and is eligible to receive ex-U.S. regulatory and sales-based milestone payments, as well as royalties on ex-U.S. net sales.',
    'FW Link1':
      'Roche jumps into cancer cell therapy via Adaptimmune deal;;https://firstwordpharma.com/story/5391719'
  },
  {
    'Date Announced': '7-Sep-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Illumina',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Merck & Co.',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'Target(s)': 'PARP',
    'FW Link1':
      'Illumina Partners with Merck to Develop and Commercialize Companion Diagnostic and Research Tests for Use in Identifying Specific Cancer Mutations;;https://firstwordhealthtech.com/story/5391837'
  },
  {
    'Date Announced': '8-Sep-2021',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'Novartis',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Bio-Thera Solutions',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'US, Europe, Canada',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'BAT1706 (biosimilar Avastin)',
    'Indication(s)': 'Various',
    'Target(s)': 'VEGF',
    'Deal Structure':
      'Under the agreement, Bio-Thera will maintain responsibilities for development, manufacturing, and supply of BAT1706. Sandoz will commercialize the medicine in all markets covered by the agreement upon regulatory approval. The license covers the US, Europe, Canada and a majority of the remaining international markets that are not covered under other BAT1706 partnerships. Bio-Thera will receive an upfront payment, potential milestone payments along with royalties on product sales.',
    'FW Link1':
      'Bio-Thera Solutions Announces Commercialization and License Agreement for BAT1706, a Proposed Biosimilar Referencing Avastin (Bevacizumab);;https://firstwordpharma.com/story/5392979'
  },
  {
    'Date Announced': '8-Sep-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Diaccurate',
    'Country (Acquiring Company/Partner)': 'France',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Merck KGaA',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'M2698',
    'Indication(s)': 'Solid tumours, lymphomas',
    'Target(s)': 'PAM',
    'FW Link1':
      'Diaccurate Acquires Clinical Stage Sole-in-Class Targeted Cancer Therapy from Merck KGaA, Darmstadt, Germany;;https://firstwordpharma.com/story/5392991'
  },
  {
    'Date Announced': '8-Sep-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Huadong Medicine',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Insilico Medicine',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules, artificial intelligence',
    'Lead Asset Stage/Phase': 'Discovery',
    'FW Link1':
      'Huadong Medicine and Insilico Medicine enter co-development partnership to advance oncology drug discovery by hitting undruggable targets;;https://firstwordhealthtech.com/story/5393491'
  },
  {
    'Date Announced': '13-Sep-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Tempest Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'University of California',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Lead Asset Stage/Phase': 'Discovery',
    'FW Link1':
      'Tempest Enters into Exclusive License Agreement with the University of California for Novel Cancer Target;;https://firstwordpharma.com/story/5396441'
  },
  {
    'Date Announced': '13-Sep-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Adicet Bio',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Twist Bioscience',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy',
    'Lead Asset Stage/Phase': 'Discovery',
    'Deal Structure':
      "Under the terms of the collaboration, Twist will leverage its proprietary single chain fragment variable (scFv) and VHH (nanobody) technologies from its Library of Libraries to discover unique target-specific binders. These targeting technologies will enable Adicet Bio's engineering and discovery of unique CARs used in the generation of novel gamma delta CAR T cell products. Twist will receive an upfront technology license fee for each program as well as clinical and regulatory milestones and royalties for any product resulting from the selected targets.",
    'FW Link1':
      'Twist Bioscience Collaborates with Adicet Bio to Accelerate Discovery of Gamma Delta T Cell Cancer Therapeutics;;https://firstwordpharma.com/story/5396453'
  },
  {
    'Date Announced': '13-Sep-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Immunovia AB',
    'Country (Acquiring Company/Partner)': 'Sweden',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'JW BioScience',
    'Country (Target Company/Partner)': 'Korea South',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics, biomarkers',
    'Indication(s)': 'Pancreatic cancer',
    'Deal Structure':
      "The license agreement gives Immunovia global commercial rights for two biomarkers for early detection of pancreatic cancer. The biomarkers are also part of Immunovia, Inc.'s IMMray™ PanCan-d biomarker signature. Immunovia discovered these pancreatic cancer biomarkers independently of JW BioScience and have now fully secured intellectual property rights.\n \n The details and deal size of this contract are not disclosed as agreed by both parties.",
    'FW Link1':
      'Immunovia enters licensing agreement with JW BioScience for pancreatic cancer biomarkers;;https://firstwordhealthtech.com/story/5397073'
  },
  {
    'Date Announced': '13-Sep-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Oxilio',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Nuformix plc',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset(s)': 'NXP001',
    'Deal Structure':
      'Under the terms of the licensing agreement, Oxilio has obtained an exclusive licence to research, develop and commercialise NXP001 globally for oncology indications. Nuformix is eligible to receive an undisclosed upfront payment, development milestone payments and a royalty on net sales, capped at £2 million per annum.',
    'FW Link1':
      'Worldwide licensing agreement with Oxilio for NXP001;;https://firstwordpharma.com/story/5397091'
  },
  {
    'Date Announced': '14-Sep-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Nuvectis Pharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'University of Edinburgh',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'NXP900',
    'Target(s)': 'SRC/YES1',
    'FW Link1':
      'Nuvectis Pharma In-Licenses Exclusive Worldwide Rights to a Novel SRC/YES1 Inhibitor (NXP900) from the University of Edinburgh, Scotland;;https://firstwordpharma.com/story/5398125'
  },
  {
    'Date Announced': '16-Sep-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Endeavor BioMedicines',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Salk Institute; Sandford Burnham',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'ENV-201',
    'Target(s)': 'ULK1/2',
    'Deal Structure':
      'The license agreement provides Endeavor with exclusive worldwide rights to ENV-201, an orally available small molecule inhibitor of ULK1/2, a critical enzyme in a cellular recycling process called autophagy that is often linked to drug resistance in RAS- and LKB1-mutated cancers.',
    'FW Link1':
      'Endeavor BioMedicines Licenses ULK1/2 Inhibitor Program from Salk Institute and Sanford Burnham Prebys to Broaden Its Precision Oncology Pipeline;;https://firstwordpharma.com/story/5399875'
  },
  {
    'Date Announced': '16-Sep-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'SciClone Pharmaceuticals',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Tarveda Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Target(s)': 'PI3K',
    'Deal Structure':
      'Pursuant to the license agreement, Tarveda has granted an exclusive license permitting SciClone to develop, manufacture and commercialize a preclinical-stage product portfolio of miniature drug conjugates that consist of a phosphoinositide 3-kinase (PI3K) inhibitor (undisclosed) payload moiety, a linker and a heat shock protein 90 (HSP90) binding moiety in Greater China, including Mainland China, Hong Kong, Macau and Taiwan.',
    'FW Link1':
      'Tarveda Therapeutics and SciClone Pharmaceuticals Expand Partnership by Entering into a License Agreement for HSP90-PI3K Miniature Drug Conjugates in Greater China;;https://firstwordpharma.com/story/5400085'
  },
  {
    'Date Announced': '17-Sep-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Satisfai Health',
    'Country (Acquiring Company/Partner)': 'Canada',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'University of Augsburg',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial intelligence, diagnostics',
    'Indication(s)': 'esophageal cancer',
    'FW Link1':
      "Satisfai Health acquires global license for AI software to detect early cancer in Barrett's Esophagus;;https://firstwordhealthtech.com/story/5400871"
  },
  {
    'Date Announced': '21-Sep-2021',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Boehringer Ingelheim',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Abexxa Biologics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Deal Structure':
      "The total transaction includes an upfront payment, milestones and other consideration payments. Abexxa will continue to operate in the Arlington, Texas, area as a Boehringer Ingelheim family company, collaborating extensively with the colleagues at Boehringer Ingelheim's U.S. research site in Ridgefield, Connecticut.",
    'FW Link1':
      'Boehringer Ingelheim Acquires Abexxa Biologics to Further Expand its Research Efforts in Cancer Immunology and Novel Immunotherapeutic Approaches;;https://firstwordpharma.com/story/5402957'
  },
  {
    'Date Announced': '21-Sep-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Persephone Biosciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'J&J',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Microbiome, biomarkers',
    'Deal Structure':
      "Under the collaboration, Persephone will analyze stool samples collected as part of a Janssen oncology clinical trial using Persephone's proprietary stool collection kit, and the company will have exclusive licensing rights to any biomarkers discovered.",
    'FW Link1':
      'Persephone Biosciences Inc. Announces Collaboration with Janssen Biotech, Inc.;;https://firstwordpharma.com/story/5403345'
  },
  {
    'Date Announced': '22-Sep-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Salarius Pharmaceuticals',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Fox Chase Cancer Center',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Biomarkers',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'seclidemstat',
    'Indication(s)': 'Sarcomas',
    'Target(s)': 'LSD1',
    'Deal Structure':
      "The research, to be carried out by the laboratory of Johnathan Whetstine, PhD, the institute's director, will help identify new indications and potential biomarkers for Salarius' lead drug candidate, seclidemstat.",
    'FW Link1':
      'Salarius Pharmaceuticals and Fox Chase Cancer Center Launch Research Partnership;;https://firstwordpharma.com/story/5404123'
  },
  {
    'Date Announced': '22-Sep-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Cyclica',
    'Country (Acquiring Company/Partner)': 'Canada',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'IMPACT Therapeutics',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Deal Structure':
      "IMPACT Therapeutics will apply Cyclica's Ligand Express platform to selectively understand the targetivity profile of its novel anticancer therapeutics. The insights gained from such a collaboration will further improve and validate IMPACT Therapeutics' competitive advantage in its clinical candidate.",
    'FW Link1':
      'Cyclica and IMPACT Therapeutics Team Up to Advance Differentiated Anti-cancer Drug Development;;https://firstwordpharma.com/story/5404323'
  },
  {
    'Date Announced': '22-Sep-2021',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'Secura Bio',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Clinigen Healthcare Ltd',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Selected European countries',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Copiktra',
    'Indication(s)': 'Non-Hodgkins lymphoma',
    'Target(s)': 'PI3K',
    'FW Link1':
      'Secura Bio Announces European Distribution Agreement for COPIKTRA with Clinigen Healthcare Limited;;https://firstwordpharma.com/story/5404675'
  },
  {
    'Date Announced': '22-Sep-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'AbCellera',
    'Country (Acquiring Company/Partner)': 'Canada',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Everest Medicines',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Discovery',
    'Deal Structure':
      'Under the terms of the agreement, Everest will have the rights to develop and commercialize antibodies resulting from the collaboration. AbCellera will receive research payments and is eligible to receive from Everest downstream clinical and commercial milestone payments and royalties on net sales of products.',
    'FW Link1':
      'AbCellera and Everest Medicines Announce Multi-Target Collaboration to Advance New Antibody Therapies;;https://firstwordpharma.com/story/5404817'
  },
  {
    'Date Announced': '23-Sep-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Xuanzhu',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'SignalChem LifeSciences',
    'Country (Target Company/Partner)': 'Canada',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '13',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'SLC-391',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'AXL',
    'Deal Structure':
      'Under the terms of the agreement, Xuanzhu will pay to SignalChem an upfront fee of thirteen million US dollars (US$13,000,000). Additional development milestones and royalties have also been committed. The exclusive rights for SLC-391 for the field of oncology is for the Greater China region (Mainland China, Hong Kong, Macau and Taiwan).',
    'FW Link1':
      'Xuanzhu and SignalChem Lifesciences Announce Collaboration and Licensing Agreement for SLC-391 in Greater China;;https://firstwordpharma.com/story/5405379'
  },
  {
    'Date Announced': '26-Sep-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'GenScript ProBio',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'AskGene',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Single-domain antibody',
    'Deal Structure':
      "GenScript ProBio grants a non-exclusive global license to AskGene to use GenScript ProBio's sdAb targeting immune checkpoint target for discovery, development and commercialization of its antibody and derivative products. In the future, GenScript ProBio will be the preferred partner for CMC development, clinical and commercial manufacturing of this product.",
    'FW Link1':
      'GenScript ProBio and AskGene Enter into a Non-exclusive License of A sdAb Targeting Immune Checkpoint Target;;https://firstwordpharma.com/story/5406815'
  },
  {
    'Date Announced': '27-Sep-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Genetron',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'IMPACT Therapeutics',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Deal Structure':
      'The two parties will cooperate together on research and development for synthetic lethal inhibitors that are based on new targets, and the development of companion diagnostic products.',
    'FW Link1':
      'Genetron Health and IMPACT Therapeutics Announce Partnership to Drive Development of Synthetic Lethal Product Pipeline;;https://firstwordhealthtech.com/story/5407347'
  },
  {
    'Date Announced': '28-Sep-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Genelux',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Newsoara Biopharma Co Ltd',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '11',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Oncolytic viruses',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'Olvi-Vec',
    'Indication(s)': 'Ovarian cancer',
    'Deal Structure':
      'Under terms of the agreement, Genelux will receive up-front and near-term payments totaling $11 million and will be eligible to receive additional per-product payments of up to $160.5 million, contingent on certain development, regulatory and commercial milestones, plus tiered royalties on net sales ranging from mid-single-digit to mid-double-digit percentages. Genelux shall have an exclusive license outside of Greater China to oncolytic virus products derived by Newsoara and will pay Newsoara milestones and royalties on sales of any such products which Genelux elects to develop.',
    'FW Link1':
      'Genelux and Newsoara Announce Collaboration and License Agreement for Oncolytic Immunotherapies;;https://firstwordpharma.com/story/5407927'
  },
  {
    'Date Announced': '28-Sep-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Diaprost',
    'Country (Acquiring Company/Partner)': 'Sweden',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Radiopharm Theranostics',
    'Country (Target Company/Partner)': 'Sweden',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Radiopharmaceuticals, diagnostics',
    'Lead Asset(s)': 'hu5A10',
    'Indication(s)': 'Prostate cancer',
    'Target(s)': 'PSA',
    'Deal Structure':
      'Under the terms of the agreement RAD will have exclusive responsibility to manufacture, develop and commercialise hu5A10 worldwide. Upfront and near-term payments of US$10M are part of a financial package that over the life of the agreement could exceed US$125M, if all development and regulatory goals are met.',
    'FW Link1':
      'Diaprost Licenses its novel Theranostic Antibody, hu5A10, to Radiopharm Theranostics for Development in the Treatment and Diagnosis of Prostate Cancer.;;https://firstwordpharma.com/story/5407935'
  },
  {
    'Date Announced': '28-Sep-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Lantern Pharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Deep Lens',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial intelligence',
    'Deal Structure':
      "Under the terms of the agreement, Lantern will leverage Deep Lens' artificial intelligence clinical trial matching solution, VIPERÔ, creating an end-to-end A.I. enabled drug development pathway that is expected to accelerate trial enrollment for Lantern's planned Phase 2 clinical trial for never-smokers with non-small cell lung cancer (NSCLC), utilizing LP-300 in combination with chemotherapy.",
    'FW Link1':
      'Lantern Pharma Enters into Strategic Collaboration with Deep Lens;;https://firstwordhealthtech.com/story/5408215'
  },
  {
    'Date Announced': '28-Sep-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'GeoVax',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'PNP Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cancer vaccine',
    'Lead Asset Stage/Phase': 'Phase I/II',
    'Lead Asset(s)': 'Gedeptin',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      'Under the license, GeoVax will become the successor to PNP under its license agreement with UAB/SRI. Detailed financial terms of the transaction were not disclosed, but include a combination of upfront payments, milestone fees, and royalties on net sales.',
    'FW Link1':
      'GeoVax Expands Immuno-Oncology Pipeline with Acquisition of Clinical-Stage Cancer Program;;https://firstwordpharma.com/story/5408521'
  },
  {
    'Date Announced': '29-Sep-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Bayer',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Huma previously known as Medopad',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial intelligence',
    'Indication(s)': 'Non-small cell lung cancer',
    'FW Link1':
      'Bayer, Huma collaborate on AI to improve lung cancer diagnosis;;https://firstwordhealthtech.com/story/5409007'
  },
  {
    'Date Announced': '30-Sep-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Merck & Co.',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'medaia',
    'Country (Target Company/Partner)': 'Austria',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Austria',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial intelligence',
    'Lead Asset(s)': 'SkinScreener App',
    'Indication(s)': 'Skin cancer',
    'Deal Structure':
      'Through its cooperation with SkinScreener, MSD Austria is setting a strong example in the detection and fight against cancer. Through the partnership, all employees have the opportunity to examine skin changes and moles for signs of malignant skin changes using an innovative app.',
    'FW Link1':
      'MSD & SkinScreener join forces against skin cancer;;https://firstwordhealthtech.com/story/5409953'
  },
  {
    'Date Announced': '30-Sep-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Sutro Biopharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Merck',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '10',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cytokines',
    'Deal Structure':
      'Sutro and Merck extended its ongoing cytokine derative collaboration for an additional two years. The research extension is intended to facilitate completion of preclinical research and development activities for a second candidate, which has a novel design and approach. As part of this extension, Sutro is eligible to receive up to $10 million.',
    'FW Link1':
      'Sutro Biopharma Announces Extension of Cytokine Derivative Research Program Under Collaboration with Merck;;https://firstwordpharma.com/story/5410089'
  },
  {
    'Date Announced': '30-Sep-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'GSK',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Merck KGaA',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Fusion protein',
    'Lead Asset(s)': 'Bintrafusp alfa',
    'Indication(s)': 'Non-small cell lung cancer',
    'Target(s)': 'PD-L1, TGF-β',
    'Deal Structure':
      'GlaxoSmithKline and Merck KGaA decided to terminate their agreement to develop the bifunctional fusion protein immunotherapy bintrafusp alfa. No milestone payments were made by GlaxoSmithKline and no future milestone obligations remain.',
    'FW Link1':
      'GSK, Merck KGaA scrap deal to develop cancer drug bintrafusp alfa;;https://firstwordpharma.com/story/5410111'
  },
  {
    'Date Announced': '4-Oct-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Atara Biotherapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Pierre Fabre',
    'Country (Target Company/Partner)': 'France',
    'Region (Target Company/Partner)': 'Europe',
    'Upfront Value ($m)': '45',
    'Total Deal Value ($m)': '365',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Allogeneic T-cell therapy',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Tabelecleucel',
    'Indication(s)': 'Epstein-Barr Virus-positive cancers',
    'Deal Structure':
      'Under the terms of the agreement, Atara will receive an upfront payment of USD 45 million, and up to approximately USD 320 million in additional regulatory and sales milestone payments, plus significant double-digit tiered royalties as a percentage of net sales. Pierre Fabre will have exclusive commercial rights to tabelecleucel in Europe, Middle East, Africa, and other select emerging markets for Epstein-Barr virus (EBV)-positive cancers. Atara will retain full rights to tab-cel in other major markets, including North America, Asia Pacific, and Latin America.',
    'FW Link1':
      'Atara Biotherapeutics and Pierre Fabre Enter Strategic Collaboration to Commercialize Tabelecleucel (Tab-cel);;https://firstwordpharma.com/story/5412233',
    'FW Link2':
      'Amended terms announced September 28, 2022 https://firstwordpharma.com/story/5657689/'
  },
  {
    'Date Announced': '4-Oct-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Zai Lab',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Novocure',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'TTFields',
    'Indication(s)': 'Gastric cancer',
    'FW Link1':
      'Zai Lab and Novocure Announce Last Patient Enrolled in Phase 2 Pilot Trial of Tumor Treating Fields Plus Chemotherapy as First-Line Treatment in Gastric Cancer in China;;https://firstwordhealthtech.com/story/5412257'
  },
  {
    'Date Announced': '6-Oct-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Cothera Biosciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Adastra Pharmaceuticals',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase Ib',
    'Lead Asset(s)': 'Zotiraciclib',
    'Indication(s)': 'High-grade astrocytoma',
    'Target(s)': 'CDK9',
    'Deal Structure':
      'Financial terms of the asset purchase were not disclosed.',
    'FW Link1':
      'Cothera Bioscience Acquires Zotiraciclib from Adastra Pharmaceuticals;;https://firstwordpharma.com/story/5413781'
  },
  {
    'Date Announced': '6-Oct-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'EQRx',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Absci',
    'Country (Target Company/Partner)': 'Canada',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial intelligence',
    'Deal Structure':
      "QRx and Absci will collaborate to jointly engineer and develop several clinical candidates across multiple therapeutic areas, including oncology and immunology. At Absci's option, it may make additional investments at progressive stages of development in exchange for an increased share of product sales.",
    'FW Link1':
      'EQRx and Absci Announce Partnership to Discover and Develop Next-Generation Protein-Based Drugs;;https://firstwordpharma.com/story/5413801'
  },
  {
    'Date Announced': '6-Oct-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Mosaic ImmunoEngineering',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'University of California',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Nanoparticles',
    'FW Link1':
      'Mosaic ImmunoEngineering Expands Immuno-Oncology Platform Through New Technology Licensing Agreement with the University of California San Diego;;https://firstwordpharma.com/story/5414109'
  },
  {
    'Date Announced': '6-Oct-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Merck & Co.',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Syapse',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Real-world evidence',
    'FW Link1':
      'Syapse Enters Real-World Data Collaboration with Merck to Inform and Improve Cancer Care;;https://firstwordhealthtech.com/story/5414215'
  },
  {
    'Date Announced': '7-Oct-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Caris Life Sciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'ESSA Pharma',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'FW Link1':
      'Caris Life Sciences and ESSA Pharma Announce Liquid Biopsy Profiling Collaboration;;https://firstwordhealthtech.com/story/5414881'
  },
  {
    'Date Announced': '7-Oct-2021',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'ALX Oncology',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'SalmiBio',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '5',
    'Total Deal Value ($m)': '7',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Deal Structure':
      'Under the terms of the share purchase agreement, ALX Oncology made an initial payment to the stockholders of ScalmiBio at closing on October 4, 2021 of approximately $4.5 million in cash, net of certain expenses and adjustments, and will make an additional payment of $2.0 million in cash at the one-year anniversary of the transaction subject to certain conditions. In addition, ALX Oncology has agreed to pay certain milestones based on the clinical development of the acquired ScalmiBio technology and has also agreed to pay a low single digit royalty on net sales of any products developed from the ScalmiBio acquired technology for a defined term.',
    'FW Link1':
      'ALX Oncology Strengthens Immuno-Oncology Pipeline with Acquisition of ScalmiBio;;https://firstwordpharma.com/story/5414989'
  },
  {
    'Date Announced': '8-Oct-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Deep Lens',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Pacific Cancer Care',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial intelligence',
    'FW Link1':
      'Deep Lens and Pacific Cancer Care Enter Strategic Partnership to Improve Clinical Trial Matching for Oncology Patients;;https://firstwordhealthtech.com/story/5415793'
  },
  {
    'Date Announced': '11-Oct-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'TRACON Pharmaceuticals',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Eucure Biopharma, subsidiary of Biocytogen',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'YH001',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'CTLA4',
    'Deal Structure':
      'Under the terms of the agreement, TRACON will be responsible for the clinical development and commercialization of YH001 in multiple oncology indications in North America, with the majority of the development activities expected to occur in the U.S. TRACON will bear the costs of clinical trials and Eucure Biopharma will supply YH001. TRACON will be responsible for commercializing YH001 in multiple oncology indications in North America and will owe Eucure Biopharma escalating double digit royalties on net sales.',
    'FW Link1':
      'TRACON Pharmaceuticals and Eucure Biopharma, a Subsidiary of Biocytogen, Announce Partnership for Development of Clinical Stage CTLA-4 Antibody YH001;;https://firstwordpharma.com/story/5417053'
  },
  {
    'Date Announced': '12-Oct-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'J&J',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Alessa Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Drug delivery',
    'FW Link1':
      'Alessa Therapeutics Announces Research Collaboration with Janssen;;https://firstwordpharma.com/story/5417417'
  },
  {
    'Date Announced': '12-Oct-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Biognosys',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Cancer Scout',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial intelligence',
    'FW Link1':
      'Biognosys Supports Cancer Scout, a Large-Scale Multi-Omics Research Project to Accelerate Personalized Cancer Medicine;;https://firstwordhealthtech.com/story/5417757'
  },
  {
    'Date Announced': '12-Oct-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'GeneCentric Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Laborp',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'Deal Structure':
      'Under the agreement, GeneCentric stands to earn development milestones in addition to commercialization terms. Additionally, Labcorp has made an upfront investment in the company.',
    'FW Link1':
      'GeneCentric Therapeutics Collaborates with Labcorp to Develop Novel RNA-Based Oncology Diagnostics;;https://firstwordhealthtech.com/story/5417775'
  },
  {
    'Date Announced': '12-Oct-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'BioNova Pharmaceuticals',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Sutro Biopharma',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '4',
    'Total Deal Value ($m)': '204',
    'Geographic Remit of Deal': 'Greater China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Lead Asset(s)': 'STRO-001',
    'Indication(s)': 'Haematological cancers',
    'Target(s)': 'CD74',
    'Deal Structure':
      "Under the terms of the agreement, BioNova has the option to obtain exclusive rights to develop and commercialize STRO-001 in Greater China. BioNova will pursue the clinical development, regulatory approval, and commercialization of STRO-001 in multiple indications, including non-Hodgkin's lymphoma, multiple myeloma, and leukemia in the licensed territory. Sutro will retain development and commercial rights of STRO-001 globally outside of Greater China, including the United States. BioNova will pay to Sutro an initial licensing option payment of $4 million, with potential payments totaling up to $200 million related to option exercise, development, regulatory, and commercial milestones. Sutro will provide STRO-001 to BioNova under appropriate clinical and commercial supply service agreements. Upon commercialization, Sutro will receive tiered, double-digit royalties based on annual net sales of STRO-001 in Greater China.",
    'FW Link1':
      'Sutro Biopharma and BioNova Pharmaceuticals Enter into Collaboration for STRO-001 in Greater China;;https://firstwordpharma.com/story/5417841'
  },
  {
    'Date Announced': '12-Oct-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Emmaus Life Sciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Kainos Medicines',
    'Country (Target Company/Partner)': 'South Korea',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'KM10544',
    'Indication(s)': 'Cancer',
    'Target(s)': 'IRAK4',
    'FW Link1':
      'Emmaus Life Sciences Acquires Key Intellectual Property Rights to Novel IRAK4 Inhibitor From Kainos Medicine;;https://firstwordpharma.com/story/5417879'
  },
  {
    'Date Announced': '12-Oct-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'DarwinHealth',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Prelude Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Biomarkers',
    'FW Link1':
      'DarwinHealth Announces Scientific Collaboration with Prelude Therapeutics to Develop Novel Biomarkers for Multiple Oncology Candidates;;https://firstwordhealthtech.com/story/5418155'
  },
  {
    'Date Announced': '13-Oct-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Hemogenyx Pharmaceuticals',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Eli Lilly',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '0',
    'Total Deal Value ($m)': '1',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Bispecific antibodies',
    'Indication(s)': 'Haematological cancers',
    'Deal Structure':
      'Under the Agreement, the Company has agreed to make an up-front payment to Lilly of US$250,000. The Agreement also provides for milestone payments to Lilly of up to US$1 million through to Phase II clinical trials. Lilly is also eligible to receive substantial additional milestone payments based on the achievement of prespecified clinical, regulatory, and commercial milestones upon reaching certain minimum sales, as well as tiered royalties on sales. In addition, the Company will pay Lilly a percentage of any cash payments received in respect of any sub-licence of the licensed IP.',
    'FW Link1':
      'Hemogenyx Pharmaceuticals Announces CDX Licence Agreement;;https://firstwordpharma.com/story/5418809'
  },
  {
    'Date Announced': '13-Oct-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Geneseeq',
    'Country (Acquiring Company/Partner)': 'Canada',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Zai Lab',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Deal Structure':
      "The companies will collaborate to facilitate the R&D and commercialization of Zai Lab's cancer drugs through business partnerships in clinical trial patient recruitment, central laboratory testing, companion diagnostics development and marketing activities.",
    'FW Link1':
      'Geneseeq and Zai Lab Announce Strategic Collaboration to Advance Cancer Drug R&D;;https://firstwordpharma.com/story/5418983'
  },
  {
    'Date Announced': '14-Oct-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Exelixis',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Aurigene',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'XL114',
    'Indication(s)': "Non-Hodgkin's lymphoma",
    'Target(s)': 'CARD11-BCL10-MALT pathway',
    'Deal Structure':
      'Under the terms of the July 2019 agreement, Exelixis made an upfront payment of $10 million for exclusive options to obtain an exclusive license from Aurigene to three preexisting programs, including the compounds now known as XL102 and XL114. To exercise its option for XL114, Exelixis will make an option exercise payment to Aurigene of $10 million. Once Exelixis exercises its option for a program, Aurigene will be eligible for clinical development, regulatory and sales milestones, as well as royalties on future potential sales of the compound. Under the terms of the agreement, Aurigene retains limited development and commercial rights for India and Russia.',
    'FW Link1':
      'Exelixis In-Licenses Second Anti-Cancer Compound from Aurigene Following FDA Acceptance of Investigational New Drug Application for Phase 1 Clinical Trial in Non-Hodgkin’s Lymphoma;;https://firstwordpharma.com/story/5419863'
  },
  {
    'Date Announced': '18-Oct-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Ipsen',
    'Country (Acquiring Company/Partner)': 'France',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Accent Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '446',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Acute myeloid leukemias',
    'Target(s)': 'METTL3',
    'Deal Structure':
      'Under the agreement, Ipsen will pay up to $446m, comprising upfront payment as well as pre-clinical, clinical, regulatory, and sales-based milestone payments, plus tiered sales royalties ranging from mid-single digits to low-double digits.',
    'FW Link1':
      'Ipsen Adds Another Program Into Its Pre-Clinical R&D Oncology Pipeline Through an Exclusive Worldwide Collaboration With Accent Therapeutics, Targeting the RNA Modifying Protein, METTL3;;https://firstwordpharma.com/story/5421451'
  },
  {
    'Date Announced': '18-Oct-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Quest Diagnostics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Eli Lilly',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'abemaciclib',
    'Indication(s)': 'Breast cancer',
    'Target(s)': 'CDK4/6',
    'FW Link1':
      "Quest Diagnostics to Introduce Ki-67 IHC MIB-1 pharmDx, the First Companion Diagnostic for Eli Lilly and Company's Verzenio (abemaciclib), a CDK4/6 Inhibitor for Certain People with HR+ HER2;;https://firstwordhealthtech.com/story/5421991"
  },
  {
    'Date Announced': '18-Oct-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'CoImmune',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      "Under the terms of the agreement, CoImmune is acquiring rights to several technologies developed at MSK. MSK and CoImmune will be using the technologies to adapt CoImmune's CAR-CIK cells to treat various solid tumors.",
    'FW Link1':
      'CoImmune Announces Collaboration with Top US Cancer Center to Accelerate Development of CAR-CIK Therapies to Treat Solid Tumors;;https://firstwordpharma.com/story/5422029',
    'FW Link2':
      'Exercise of option to obtain exclusive licence announced March 20, 2023;;https://firstwordpharma.com/story/5718003/'
  },
  {
    'Date Announced': '18-Oct-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Dragonfly Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'BMS',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'CC-96191, CC-92328',
    'Indication(s)': 'Haematologic malignancies',
    'Deal Structure':
      'The company announces its sixth drug licensed by Bristol Myers Squibb, and the first patients enrolled and dosed in Phase 1 clinical trials evaluating its CC-96191 and CC-92328 NK cell engager therapies in patients with hematologic malignancies. Dragonfly receives $28 million in combined payments for these three milestones and is eligible to receive potential future milestones and royalties on net sales.',
    'FW Link1':
      'Dragonfly Therapeutics Announces Sixth Dragonfly Drug to be Licensed by Bristol Myers Squibb and Receipt of Milestone Payments Following First Patient Dosing of Two TriNKET Immunotherapies;;https://firstwordpharma.com/story/5422161'
  },
  {
    'Date Announced': '18-Oct-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Deep Bio',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'HealthCare Konnect',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial intelligence',
    'Indication(s)': 'Prostate cancer',
    'FW Link1':
      'Deep Bio and HealthCare Konnect (HCK) Enter Into Partnership to Improve Cancer Diagnosis in Switzerland & North Africa;;https://firstwordhealthtech.com/story/5422271'
  },
  {
    'Date Announced': '18-Oct-2021',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Calithera Biosciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Takeda Pharmaceuticals',
    'Country (Target Company/Partner)': 'Japan',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '45',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'Sapanisertib; mivavotinib',
    'Indication(s)': "Non-small cell lung cancer; non-Hodgkin's lymphoma",
    'Target(s)': 'TORC1/2; SYK',
    'Deal Structure':
      'The terms of the transaction include a total upfront cash payment to Takeda of $10 million and $35 million issued to Takeda in Calithera Series A preferred stock. Additionally, Takeda will be eligible to receive from Calithera clinical development, regulatory and sales milestone payments across both programs. Calithera will pay tiered royalties of high single-digits to low teens on future net sales should these candidates achieve regulatory approvals and subsequent commercial availability.',
    'FW Link1':
      'Calithera Expands Oncology Pipeline with Acquisition of Two Clinical-Stage Assets from Takeda Pharmaceuticals;;https://firstwordpharma.com/story/5422415'
  },
  {
    'Date Announced': '19-Oct-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Chugai',
    'Country (Acquiring Company/Partner)': 'Japan',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Oncolys BioPharma',
    'Country (Target Company/Partner)': 'Japan',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Oncolytic viruses',
    'Lead Asset(s)': 'OBP-301',
    'Deal Structure':
      'Upon termination of the Agreement, all rights Chugai may have with respect to OBP-301 will be returned to Oncolys. There will be no payment or receipt of milestone fees between the two companies.',
    'FW Link1':
      'Chugai Announces the Decision of the Termination of Exclusive License Agreement on OBP-301 (Telomelysin), Oncolytic Viral Immunotherapy;;https://firstwordpharma.com/story/5423041'
  },
  {
    'Date Announced': '20-Oct-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Viome Life Sciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'GSK',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules, artificial intelligence',
    'Deal Structure':
      "Under the agreement, the companies will study the connection between the gut microbiome and specific chronic disorders by performing a clinical validation trial. The expanded partnership will use targets based on Viome's discovery platform that analyses host and microbial interaction at the onset and progression of diseases. Under the original tie-up, the companies sought to research the prevention of specific chronic diseases, including a potential role for vaccines.",
    'FW Link1':
      'Viome Life Sciences Announces Expansion of Collaboration with GSK into new therapeutic interventions for Cancers and Autoimmune Diseases;;https://firstwordpharma.com/story/5423837'
  },
  {
    'Date Announced': '20-Oct-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'CytoReason',
    'Country (Acquiring Company/Partner)': 'Israel',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Merck KGaA',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'FW Link1':
      'CytoReason Collaborates with Merck KGaA, Darmstadt, Germany, on Cancer Immunotherapy Drug;;https://firstwordpharma.com/story/5423941'
  },
  {
    'Date Announced': '20-Oct-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Emergence Therapeutics',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Mablink Bioscience',
    'Country (Target Company/Partner)': 'France',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Indication(s)': 'Cancer',
    'Target(s)': 'Nectin-4',
    'Deal Structure':
      'Under the terms of the agreement, Emergence Therapeutics will use a PSARlink™ drug-linker developed by Mablink to develop an ADC targeting Nectin-4, a protein overexpressed in several severe cancers. Pursuant to the agreement, Mablink Bioscience is entitled to receive milestone and royalty payments, based on the development of the Nectin-4 ADC.',
    'FW Link1':
      'Mablink Bioscience and Emergence Therapeutics Enter Into a Licensing Agreement to Develop Antibody Drug Conjugate as a Potential Cancer Therapy;;https://firstwordpharma.com/story/5424509'
  },
  {
    'Date Announced': '21-Oct-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Specialised Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Incyte Biosciences International Sarl',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Tafasitamab; Pemazyre',
    'Indication(s)': 'DLBCL; cholangiocarcinoma',
    'Deal Structure':
      'Under the terms of the agreement, Incyte will be responsible for the development, manufacture and supply of both products and ST will be responsible for regulatory, distribution and local marketing related activities in Australia, New Zealand and Singapore.',
    'FW Link1':
      'Specialised Therapeutics Enters into a New Supply and Distribution Agreement with Incyte to Launch Two New Cancer Therapies, Tafasitamab and Pemigatinib, in Australia, New Zealand and Singapore;;https://firstwordpharma.com/story/5425211'
  },
  {
    'Date Announced': '21-Oct-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Rakuten Medical',
    'Country (Acquiring Company/Partner)': 'India',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Karkinos Healthcare',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'India',
    'Therapy Area(s)': 'Oncology',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'ASP-1929',
    'Indication(s)': 'Recurrent head and neck cancer',
    'Deal Structure':
      "Rakuten Medical and Karkinos Healthcare Private Limited announced a strategic collaboration to expand the reach of novel cancer care to India. This includes a minority stake acquisition in Karkinos Healthcare by Rakuten Medical. Rakuten Medical's investment marks Karkinos Healthcare's first international investor.",
    'FW Link1':
      'Rakuten Medical and Karkinos Healthcare Announce Strategic Partnership to Expand the Reach of Novel Cancer Care in India;;https://firstwordpharma.com/story/5425213'
  },
  {
    'Date Announced': '22-Oct-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Inivata',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Princess Margaret Cancer Center',
    'Country (Target Company/Partner)': 'Canada',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'Indication(s)': 'NSCLC',
    'FW Link1':
      'Inivata Announces Clinical Collaboration with Princess Margaret Cancer Center for the use of its Liquid Biopsy Assays;;https://firstwordhealthtech.com/story/5425923'
  },
  {
    'Date Announced': '25-Oct-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Secarna Pharmaceuticals',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner':
      'Latvian Institute of Organic Synthesis, the leading drug discovery and development center in the Baltics, the Institute of Bioinformatics (Medical University of Innsbruck) and Innoplexus AG',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Antisense oligonucleotides, artificial intelligence',
    'FW Link1':
      'Secarna Pharmaceuticals has Initiated a Transnational Scientific Consortium to Identify New Biomarkers for Personalized Cancer Immunotherapy with ASOs;;https://firstwordhealthtech.com/story/5427317'
  },
  {
    'Date Announced': '26-Oct-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Solasia',
    'Country (Acquiring Company/Partner)': 'Japan',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Nippon Kayaku',
    'Country (Target Company/Partner)': 'Japan',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'darinaparsin',
    'Indication(s)': 'Lymphomas',
    'FW Link1':
      'Solasia and Nippon Kayaku Announce License Agreement in Japan for New Drug Candidate DARINAPARSIN (SP-02);;https://firstwordpharma.com/story/5428117'
  },
  {
    'Date Announced': '26-Oct-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Bicycle Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Roche',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Total Deal Value ($m)': '10',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Peptides',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Deal Structure':
      'Pursuant to the terms of the February 2020 agreement, Genentech has exercised an option to include a new program under the agreement, triggering a $10 million payment to Bicycle.',
    'FW Link1':
      'Bicycle Therapeutics Announces Expansion of Genentech Immuno-Oncology Collaboration;;https://firstwordpharma.com/story/5428249'
  },
  {
    'Date Announced': '27-Oct-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'SpringWorks Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'GSK',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibodies',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'nirogacestat plus belantamab',
    'Indication(s)': 'Multiple myeloma',
    'Target(s)': 'BCMA',
    'Deal Structure':
      'Under the terms of the agreement, GSK is sponsoring and conducting the platform study to evaluate the safety, tolerability and preliminary efficacy of the combination and is assuming all development costs associated with the study other than expenses related to the manufacturing of nirogacestat and certain expenses related to intellectual property rights. SpringWorks and GSK have formed a joint development committee to help manage and oversee the clinical study.',
    'FW Link1':
      'SpringWorks Therapeutics Announces the Initiation of an Expanded Phase 2 Cohort and Addition of New Sub-Studies to Existing Clinical Collaboration with GSK;;https://firstwordpharma.com/story/5429249',
    'FW Link2':
      'Expansion of collaboration announced September 7, 2022;;https://firstwordpharma.com/story/5650570/'
  },
  {
    'Date Announced': '27-Oct-2021',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Takeda',
    'Country (Acquiring Company/Partner)': 'Japan',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'GammaDelta',
    'Country (Target Company/Partner)': 'Japan',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'GDX012',
    'Indication(s)': 'Acute myeloid leukemias',
    'Deal Structure':
      'The Japanese drugmaker said the acquisition includes an undisclosed, pre-negotiated upfront payment, as well as potential development and regulatory milestones.',
    'FW Link1':
      'Takeda acquires cell therapy partner GammaDelta;;https://firstwordpharma.com/story/5429399'
  },
  {
    'Date Announced': '27-Oct-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'XtalPi',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Signet Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial intelligence',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Gastric cancer',
    'FW Link1':
      'XtalPi and Signet Expand AI Drug Discovery Collaboration to Novel Cancer Target;;https://firstwordpharma.com/story/5429497'
  },
  {
    'Date Announced': '27-Oct-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Code Ocean',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Lantern Pharma',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial intelligence',
    'FW Link1':
      'Code Ocean Selected by Lantern Pharma to Power A.I. and Computational Biology Research & Data-Driven Oncology Drug Development Collaborations;;https://firstwordpharma.com/story/5429523'
  },
  {
    'Date Announced': '27-Oct-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Melax Tech',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Baylor College of Medicine',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'FW Link1':
      'Melax Tech and Baylor College of Medicine Collaborate on Cancer Natural Language Processing Project;;https://firstwordhealthtech.com/story/5429593'
  },
  {
    'Date Announced': '28-Oct-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'MaxCyte',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Nkarta',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy',
    'Deal Structure':
      "Under the terms of the agreement, Nkarta obtains non-exclusive clinical and commercial rights to use MaxCyte's Flow Electroporation® technology and ExPERT™ platform. In return, MaxCyte is entitled to receive platform licensing fees and program-related milestone payments.",
    'FW Link1':
      'MaxCyte Signs Strategic Platform License with Nkarta, Inc. to Advance Natural Killer Cell Therapy Programs;;https://firstwordpharma.com/story/5430579'
  },
  {
    'Date Announced': '28-Oct-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Densitas',
    'Country (Acquiring Company/Partner)': 'Canada',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'RAD-AID International',
    'Country (Target Company/Partner)': 'Canada',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial intelligence',
    'Indication(s)': 'Breast cancer',
    'FW Link1':
      'Densitas Deploys intelliMammo A.I. Platform in Partnership with RAD-AID International for Breast Screening Quality Assurance and Technologist Training in Guyana;;https://firstwordhealthtech.com/story/5430659'
  },
  {
    'Date Announced': '28-Oct-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Cancer Treatment Centers of America',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial intelligence',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Colorectal cancer',
    'FW Link1':
      'Cancer Treatment Centers of America in Phoenix Incorporates Artificial Intelligence System for Enhanced Colonoscopy Screenings;;https://firstwordhealthtech.com/story/5430827'
  },
  {
    'Date Announced': '29-Oct-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'MDimune',
    'Country (Acquiring Company/Partner)': 'Korea, South',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Kainos Medicines',
    'Country (Target Company/Partner)': 'Canada',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'mRNA',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Target(s)': 'FAF1',
    'FW Link1':
      'MDimune, pens FAF1 mRNA cancer drug deal with Kainos Medicine;;https://firstwordpharma.com/story/5431301'
  },
  {
    'Date Announced': '29-Oct-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Caris Precision Oncology',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'National Cancer Institute',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'FW Link1':
      'Caris Precision Oncology Alliance Partners with the National Cancer Institute, Part of the National Institutes of Health, to Expand Collaborative Clinical Research Efforts;;https://firstwordhealthtech.com/story/5431449'
  },
  {
    'Date Announced': '29-Oct-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'BridgeBio Pharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Columbia University; Mount Sinai',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'FW Link1':
      'BridgeBio Pharma Announces Collaborations with Columbia University and Mount Sinai to Develop Potential Therapies for Genetic Diseases and Cancers;;https://firstwordpharma.com/story/5431795'
  },
  {
    'Date Announced': '1-Nov-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Merck & Co.',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Synthekine',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Total Deal Value ($m)': '525',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Deal Structure':
      'Merck & Co. links up with Synthekine on cytokine therapeutics',
    'FW Link1':
      'Merck & Co. links up with Synthekine on cytokine therapeutics;;https://firstwordpharma.com/story/5432379'
  },
  {
    'Date Announced': '1-Nov-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Kinnate Biopharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Guardant Health',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Target(s)': 'BRAF;MEK',
    'Deal Structure':
      'Kinnate Biopharma Inc. in Collaboration with Guardant Health Announces Initial Findings from BRAF Kinase Alteration Genomic Landscape and Real-World Clinical Outcomes Study',
    'FW Link1':
      'Kinnate Biopharma Inc. in Collaboration with Guardant Health Announces Initial Findings from BRAF Kinase Alteration Genomic Landscape and Real-World Clinical Outcomes Study;;https://firstwordhealthtech.com/story/5433219'
  },
  {
    'Date Announced': '2-Nov-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'SAGA Diagnostics',
    'Country (Acquiring Company/Partner)': 'Sweden',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'AstraZeneca',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'Deal Structure':
      'SAGA Diagnostics enters into an assay development agreement with AstraZeneca',
    'FW Link1':
      'SAGA Diagnostics enters into an assay development agreement with AstraZeneca;;https://firstwordhealthtech.com/story/5433779'
  },
  {
    'Date Announced': '3-Nov-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Integral Molecular',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'AstraZeneca',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibodies',
    'Deal Structure':
      'Integral Molecular Enters into Antibody License Agreement with AstraZeneca for Oncology Therapeutics',
    'FW Link1':
      'Integral Molecular Enters into Antibody License Agreement with AstraZeneca for Oncology Therapeutics;;https://firstwordpharma.com/story/5434255'
  },
  {
    'Date Announced': '3-Nov-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'OncoTEX',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'University of Texas at Austin',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Deal Structure':
      'Gold Technology for Immune Response Cancer Therapy Licensed to OncoTEX'
  },
  {
    'Date Announced': '4-Nov-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Guardant Health',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'The Royal Marsden NHS Foundation Trust',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'Deal Structure':
      'Guardant Health and The Royal Marsden NHS Foundation Trust Announce Partnership to Establish First Guardant Health Liquid Biopsy Testing Service Based in the United Kingdom',
    'FW Link1':
      'Guardant Health and The Royal Marsden NHS Foundation Trust Announce Partnership to Establish First Guardant Health Liquid Biopsy Testing Service Based in the United Kingdom;;https://firstwordhealthtech.com/story/5435153'
  },
  {
    'Date Announced': '4-Nov-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Burning Rock',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'IMPACT Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'USA , China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'IMP9064',
    'Indication(s)': 'Cancer',
    'Target(s)': 'ATR',
    'Deal Structure':
      'Burning Rock Collaborates with IMPACT Therapeutics to Provide Testing Services in Developing IMP9064 in both US and China',
    'FW Link1':
      'Burning Rock Collaborates with IMPACT Therapeutics to Provide Testing Services in Developing IMP9064 in both US and China;;https://firstwordpharma.com/story/5435189'
  },
  {
    'Date Announced': '4-Nov-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Aptose',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Hanmi Pharmaceutical',
    'Country (Target Company/Partner)': 'Korea South',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '13',
    'Total Deal Value ($m)': '408',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'HM43239',
    'Indication(s)': 'myeloid malignancies',
    'Target(s)': 'SYK, FLT3',
    'Deal Structure':
      'Aptose Enters into Exclusive Worldwide License Agreement with Hanmi Pharmaceutical for Clinical-Stage Myeloid Kinome Inhibitor HM43239;;https://firstwordpharma.com/story/5435209',
    'FW Link1':
      'Aptose Enters into Exclusive Worldwide License Agreement with Hanmi Pharmaceutical for Clinical-Stage Myeloid Kinome Inhibitor HM43239;;https://firstwordpharma.com/story/5435209'
  },
  {
    'Date Announced': '4-Nov-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'XOMA',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Novartis',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Total Deal Value ($m)': '35',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibodies',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'NIS793',
    'Indication(s)': 'pancreatics cancer',
    'Target(s)': 'TGFbeta',
    'Deal Structure':
      'XOMA Earns $35 Million Milestone Payment as Anti-TGFβ Antibody Enters Phase 3 Clinical Study in Metastatic Pancreatic Cancer;;https://firstwordpharma.com/story/5435423',
    'FW Link1':
      'XOMA Earns $35 Million Milestone Payment as Anti-TGFβ Antibody Enters Phase 3 Clinical Study in Metastatic Pancreatic Cancer;;https://firstwordpharma.com/story/5435423'
  },
  {
    'Date Announced': '8-Nov-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'PDS Biotechnology',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'National Cancer Institute',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'PDS0102',
    'Indication(s)': 'Acute myeloid leukaemia, breast cancer',
    'FW Link1':
      'PDS Biotechnology Licenses Protein for the Treatment of Acute Myeloid Leukemia, Prostate and Breast Cancers from National Cancer Institute;;https://firstwordpharma.com/story/5437541'
  },
  {
    'Date Announced': '8-Nov-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Genetron',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'NeoGenomics',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Greater China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'Deal Structure':
      'Genetron Health Establishes Strategic Partnership with NeoGenomics to Drive Global Oncology Drug Research and Development;;https://firstwordhealthtech.com/story/5437653',
    'FW Link1':
      'Genetron Health Establishes Strategic Partnership with NeoGenomics to Drive Global Oncology Drug Research and Development;;https://firstwordhealthtech.com/story/5437653'
  },
  {
    'Date Announced': '8-Nov-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'I-Mab',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Roche Diagnostics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'Target(s)': 'CDx',
    'Deal Structure':
      "I-Mab and Roche Diagnostics Announce Strategic Collaboration to Co-Develop Companion Diagnostics Solutions for I-Mab's Innovative Pipeline at the 4th CIIE;",
    'FW Link1':
      "I-Mab and Roche Diagnostics Announce Strategic Collaboration to Co-Develop Companion Diagnostics Solutions for I-Mab's Innovative Pipeline at the 4th CIIE;;https://firstwordhealthtech.com/story/5437743"
  },
  {
    'Date Announced': '8-Nov-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Kronos Bio',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Tempus',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'KB-0742',
    'Indication(s)': 'Cancer',
    'Target(s)': 'CDK9',
    'Deal Structure':
      'Kronos Bio and Tempus Announce Multi-Year Collaboration to Provide Kronos Bio With Access to Tempus’ Platform',
    'FW Link1':
      'Kronos Bio and Tempus Announce Multi-Year Collaboration to Provide Kronos Bio With Access to Tempus’ Platform;;https://firstwordhealthtech.com/story/5438059'
  },
  {
    'Date Announced': '9-Nov-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Kazia Therapeutics',
    'Country (Acquiring Company/Partner)': 'Australia',
    'Region (Acquiring Company/Partner)': 'Austrailia',
    'Target Company/Partner': 'Radiomics',
    'Country (Target Company/Partner)': 'Belgium',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'EVT801',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'VEGFR3',
    'Deal Structure':
      'Kazia Therapeutics and Radiomics collaborate in development of novel therapy',
    'FW Link1':
      'Kazia Therapeutics and Radiomics collaborate in development of novel therapy;;https://firstwordhealthtech.com/story/5438157'
  },
  {
    'Date Announced': '9-Nov-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'HUYABIO',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Shanghai Jemincare Pharmaceutical',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Greater China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'SPH6162',
    'Indication(s)': 'Cancer',
    'Target(s)': 'Cdk1',
    'Deal Structure':
      'HUYABIO Announces the Exclusive License of the Wee1 Inhibitor from Shanghai Pharmaceuticals',
    'FW Link1':
      'HUYABIO Announces the Exclusive License of the Wee1 Inhibitor from Shanghai Pharmaceuticals;;https://firstwordpharma.com/story/5438197'
  },
  {
    'Date Announced': '9-Nov-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Takara Bio',
    'Country (Acquiring Company/Partner)': 'Japan',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Otsuka Pharmaceutical',
    'Country (Target Company/Partner)': 'Japan',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Gene therapy',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'TBI-1401',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Announcement of Termination of Co-Development and Exclusive Marketing Agreements with Takara Bio for Oncolytic virus HF10 and CD19-Targeted CAR Gene Therapies;',
    'FW Link1':
      'Announcement of Termination of Co-Development and Exclusive Marketing Agreements with Takara Bio for Oncolytic virus HF10 and CD19-Targeted CAR Gene Therapies;;https://firstwordpharma.com/story/5438395',
    'FW Link2':
      'Termination of agreements for NY-ESO-1 siTCR announced November 10, 2022 https://firstwordpharma.com/story/5674320/'
  },
  {
    'Date Announced': '9-Nov-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Athenex',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'National Cancer Institute',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Target(s)': 'p53, KRAS and EGFR',
    'Deal Structure':
      'Athenex Announces Exclusive License Agreement with National Cancer Institute to Utilize T Cell Receptors Targeting Mutated p53, KRAS, and EGFR for the Development of Autologous and Allogeneic Cell Therapies',
    'FW Link1':
      'Athenex Announces Exclusive License Agreement with National Cancer Institute to Utilize T Cell Receptors Targeting Mutated p53, KRAS, and EGFR for the Development of Autologous and Allogeneic Cell Therapies;;https://firstwordpharma.com/story/5438425'
  },
  {
    'Date Announced': '10-Nov-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Pleco Therapeutics',
    'Country (Acquiring Company/Partner)': 'Netherlands',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Hyloris',
    'Country (Target Company/Partner)': 'Belgium',
    'Region (Target Company/Partner)': 'Europe',
    'Total Deal Value ($m)': '10',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'PTX-061',
    'Indication(s)': 'Acute myeloid leukaemia; small cell lung cancer',
    'Deal Structure':
      'Under the terms of the agreement, Hyloris will provide €1 million in several tranches over time, automatically convertible into Pleco equity under certain conditions, together with potential funding of up to an additional €7.7 million in pre-defined R&D activities against an agreed development plan.',
    'FW Link1':
      'Pleco Therapeutics Announces €8.7m Strategic Partnership with Hyloris to Progress its Novel Plecoid Agent in Acute Myeloid Leukaemia and Small Cell Lung Cancer;;https://firstwordpharma.com/story/5439155'
  },
  {
    'Date Announced': '10-Nov-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Secarna Pharmaceuticals',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Achilles Therapeutics',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antisense oligonucleotides',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Secarna Pharmaceuticals and Achilles Therapeutics Sign Research, Option and License Agreement to Develop Optimized T Cell Therapies',
    'FW Link1':
      'Secarna Pharmaceuticals and Achilles Therapeutics Sign Research, Option and License Agreement to Develop Optimized T Cell Therapies;;https://firstwordpharma.com/story/5439393'
  },
  {
    'Date Announced': '10-Nov-2021',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Cerca Biotech',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'BioNTech Diagnostics AG',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'MammaTyper',
    'Deal Structure':
      'Cerca Biotech Acquires MammaTyper for Molecular Classification of Breast Cancer',
    'FW Link1':
      'Cerca Biotech Acquires MammaTyper for Molecular Classification of Breast Cancer;;https://firstwordhealthtech.com/story/5439613'
  },
  {
    'Date Announced': '10-Nov-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'AbbVie',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'University of Chicago',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'small molecules',
    'Deal Structure':
      'AbbVie, University of Chicago Extend Research Collaboration to Support Preclinical Oncology Research Through 2025;',
    'FW Link1':
      'AbbVie, University of Chicago Extend Research Collaboration to Support Preclinical Oncology Research Through 2025;;https://firstwordpharma.com/story/5439637'
  },
  {
    'Date Announced': '11-Nov-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Oncorus',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Gaeta Therapeutics Ltd',
    'Region (Target Company/Partner)': 'Europe',
    'Upfront Value ($m)': '0',
    'Total Deal Value ($m)': '8',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'small molecules',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'ONCR-177',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'IL1-12',
    'Deal Structure':
      'Oncorus Announces Exclusive Licensing Agreement with Gaeta Therapeutics Ltd. for Use of Locally Delivered Interleukin-12 (IL-12) via Oncolytic Viral Expression in Combination with Immune Checkpoint Inhibitors',
    'FW Link1':
      'Oncorus Announces Exclusive Licensing Agreement with Gaeta Therapeutics Ltd. for Use of Locally Delivered Interleukin-12 (IL-12) via Oncolytic Viral Expression in Combination with Immune Checkpoint Inhibitors;;https://firstwordpharma.com/story/5440441'
  },
  {
    'Date Announced': '11-Nov-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Notable Labs',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Oncoheroes Biosciences',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'small molecules',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'volasertib',
    'Indication(s)': 'acute myeloid leukemias',
    'Target(s)': 'PLK-1',
    'Deal Structure':
      'Oncoheroes Biosciences Inc. Out-licences volasertib to Notable Labs for the Global Adult Development of Its Anticancer Therapy',
    'FW Link1':
      'Oncoheroes Biosciences Inc. Out-licences volasertib to Notable Labs for the Global Adult Development of Its Anticancer Therapy;;https://firstwordpharma.com/story/5440493'
  },
  {
    'Date Announced': '11-Nov-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'PharmaMar',
    'Country (Acquiring Company/Partner)': 'Spain',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Lotus Pharmaceutical',
    'Country (Target Company/Partner)': 'Taiwan',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Taiwan',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'small molecules',
    'Lead Asset Stage/Phase': 'Discovery',
    'Lead Asset(s)': 'lurbinectedin',
    'Indication(s)': 'small cell lung cancer',
    'Deal Structure':
      'PharmaMar signs a licensing and commercialization agreement with Lotus Pharmaceutical for lurbinectedin in Taiwan;',
    'FW Link1':
      'PharmaMar signs a licensing and commercialization agreement with Lotus Pharmaceutical for lurbinectedin in Taiwan;;https://firstwordpharma.com/story/5441041'
  },
  {
    'Date Announced': '15-Nov-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Zenith Epigenentics',
    'Country (Acquiring Company/Partner)': 'Canada',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Newsoara Biopharma',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '4',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'small molecules',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'ZEN-3694',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'BET',
    'Deal Structure':
      'Zenith and Newsoara Biopharma Announce Expanded Licensing and Investment',
    'FW Link1':
      'Zenith and Newsoara Biopharma Announce Expanded Licensing and Investment;;https://firstwordpharma.com/story/5442513'
  },
  {
    'Date Announced': '16-Nov-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'SOTIO',
    'Country (Acquiring Company/Partner)': 'Czech Republic',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'LegoChem',
    'Country (Target Company/Partner)': 'South Korea',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '30',
    'Total Deal Value ($m)': '1,000',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'small molecules',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'SOT102',
    'Indication(s)': 'gastric and pancreatic cancers',
    'Target(s)': 'claudin18.2',
    'Deal Structure': 'SOTIO inks antibody-drug conjugate deal with LegoChem;',
    'FW Link1':
      'SOTIO inks antibody-drug conjugate deal with LegoChem;;https://firstwordpharma.com/story/5443239',
    'FW Link2':
      'Exercise of the first option announced November 29, 2022 https://firstwordpharma.com/story/5681041/'
  },
  {
    'Date Announced': '16-Nov-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Bright Peak Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner':
      'Livzon Mabpharm, subsideary of o Livzon Pharmaceutical',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'antibodies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'LZM009',
    'Indication(s)': 'thymic cancer',
    'Target(s)': 'PD-L1',
    'Deal Structure':
      'Bright Peak Therapeutics Licenses Rights to Livzon’s Anti-PD-1 Antibody LZM009 to Develop Novel PD-1 Targeted Immunocytokines',
    'FW Link1':
      'Bright Peak Therapeutics Licenses Rights to Livzon’s Anti-PD-1 Antibody LZM009 to Develop Novel PD-1 Targeted Immunocytokines;;https://firstwordpharma.com/story/5443623'
  },
  {
    'Date Announced': '17-Nov-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Pyramid Biosciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Voronoi',
    'Country (Target Company/Partner)': 'South Korea',
    'Region (Target Company/Partner)': 'Asia',
    'Total Deal Value ($m)': '846',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'small molecules',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'VRN-08',
    'Indication(s)': 'breast cancer, GI cancer and lung cancer',
    'Target(s)': 'MPS1',
    'Deal Structure': 'Pyramid Biosciences Expands Precision Oncology Pipeline',
    'FW Link1':
      'Pyramid Biosciences Expands Precision Oncology Pipeline;;https://firstwordpharma.com/story/5444061'
  },
  {
    'Date Announced': '17-Nov-2021',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Regen BioPharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Canary Oncoceutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Total Deal Value ($m)': '50',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'diagnostics',
    'Deal Structure':
      'Regen BioPharma, Inc. Announces Signing of a Letter of Intent to Acquire an In vitro Diagnostics Start-Up Company Canary Oncoceutics, Inc. Which is Developing Functional Cancer Tests for Personalized Medicine',
    'FW Link1':
      'Regen BioPharma, Inc. Announces Signing of a Letter of Intent to Acquire an In vitro Diagnostics Start-Up Company Canary Oncoceutics, Inc. Which is Developing Functional Cancer Tests for Personalized Medicine;;https://firstwordhealthtech.com/story/5444733'
  },
  {
    'Date Announced': '18-Nov-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Sanofi',
    'Country (Acquiring Company/Partner)': 'France',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Owkin',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Total Deal Value ($m)': '180',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'artificial intelligence',
    'Deal Structure':
      "Sanofi taps Owkin's AI platform for cancer drug development",
    'FW Link1':
      "Sanofi taps Owkin's AI platform for cancer drug development;;https://firstwordpharma.com/story/5444911"
  },
  {
    'Date Announced': '18-Nov-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Gilead Sciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Arcus Biosciences',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Total Deal Value ($m)': '725',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'antibodies',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'domvanalimab',
    'Indication(s)': 'NSCLC',
    'Target(s)': 'TIGIT',
    'Deal Structure':
      "Gilead opts in on Arcus' cancer drugs, including anti-TIGIT molecules;;https://firstwordpharma.com/story/5444919",
    'FW Link1':
      "Gilead opts in on Arcus' cancer drugs, including anti-TIGIT molecules;;https://firstwordpharma.com/story/5444919"
  },
  {
    'Date Announced': '18-Nov-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Evotec',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'EQRx',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'small molecules',
    'Deal Structure':
      'Evotec and EQRx announce integrated drug discovery and development partnership',
    'FW Link1':
      'Evotec and EQRx announce integrated drug discovery and development partnership;;https://firstwordpharma.com/story/5444925'
  },
  {
    'Date Announced': '18-Nov-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Merck KGaA',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Burining Rock Biotech Ltd',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'diagnostics',
    'Lead Asset Stage/Phase': 'Phase I',
    'Deal Structure':
      'Merck KGaA, Darmstadt, Germany and Burning Rock Collaborate on Liquid-biopsy Based CDx Development Using Burning Rock’s OncoCompass Target',
    'FW Link1':
      'Merck KGaA, Darmstadt, Germany and Burning Rock Collaborate on Liquid-biopsy Based CDx Development Using Burning Rock’s OncoCompass Target;;https://firstwordpharma.com/story/5445019'
  },
  {
    'Date Announced': '18-Nov-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Aglaia Therapeutics/Ribonexus',
    'Country (Acquiring Company/Partner)': 'France',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Pierre Fabre',
    'Country (Target Company/Partner)': 'France',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Peptides',
    'Target(s)': 'eIF4A',
    'Deal Structure':
      'Aglaia Therapeutics becomes Ribonexus and signs exclusive license agreement with Pierre Fabre on small molecules targeting eIF4A, an innovative target in oncology',
    'FW Link1':
      'Aglaia Therapeutics becomes Ribonexus and signs exclusive license agreement with Pierre Fabre on small molecules targeting eIF4A, an innovative target in oncology;;https://firstwordpharma.com/story/5445243'
  },
  {
    'Date Announced': '18-Nov-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Exact Sciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Jefferson Health',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'diagnostics',
    'Deal Structure':
      'Exact Sciences and Jefferson Health Commence Collaboration on Multi-Cancer Earlier Detection',
    'FW Link1':
      'Exact Sciences and Jefferson Health Commence Collaboration on Multi-Cancer Earlier Detection;;https://firstwordhealthtech.com/story/5445433'
  },
  {
    'Date Announced': '19-Nov-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'BridgeBio Pharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Helsinn',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Target(s)': 'GPX4',
    'Deal Structure':
      'BridgeBio Pharma and Helsinn Group Announce Strategic Collaboration to Co-Develop and Co-Commercialize BridgeBio’s Novel GPX4 Inhibitor in Multiple Cancer Tumor Types',
    'FW Link1':
      'BridgeBio Pharma and Helsinn Group Announce Strategic Collaboration to Co-Develop and Co-Commercialize BridgeBio’s Novel GPX4 Inhibitor in Multiple Cancer Tumor Types;;https://firstwordpharma.com/story/5446037'
  },
  {
    'Date Announced': '19-Nov-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'AstraZeneca',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Tempus',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'artificial intelligence',
    'Deal Structure':
      'AstraZeneca, Tempus partner to advance oncology therapeutics via AI',
    'FW Link1':
      'AstraZeneca, Tempus partner to advance oncology therapeutics via AI;;https://firstwordhealthtech.com/story/5446087'
  },
  {
    'Date Announced': '21-Nov-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Cstone Pharmaceuticals',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Jiangsu Hengrui Pharmaceuticals',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Total Deal Value ($m)': '200',
    'Geographic Remit of Deal': 'Greater China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'antibodies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'CS1002',
    'Target(s)': 'PDL1',
    'Deal Structure':
      'CStone Pharmaceuticals and Jiangsu Hengrui Pharmaceuticals announce strategic partnership and exclusive licensing agreement on anti-CTLA-4 monoclonal antibody CS1002 in Greater China',
    'FW Link1':
      'CStone Pharmaceuticals and Jiangsu Hengrui Pharmaceuticals announce strategic partnership and exclusive licensing agreement on anti-CTLA-4 monoclonal antibody CS1002 in Greater China;;https://firstwordpharma.com/story/5446417'
  },
  {
    'Date Announced': '22-Nov-2021',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'ACT Genomics',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Sanomics',
    'Country (Target Company/Partner)': 'Hong Kong',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'diagnostics',
    'Deal Structure': 'ACT Genomics merges with Sanomics',
    'FW Link1':
      'ACT Genomics merges with Sanomics;;https://firstwordhealthtech.com/story/5446835'
  },
  {
    'Date Announced': '23-Nov-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Regeneron',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Nykode',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '30',
    'Total Deal Value ($m)': '900',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'vaccines',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Regeneron partners with Nykode on cancer, infectious diseases vaccines',
    'FW Link1':
      'Regeneron partners with Nykode on cancer, infectious diseases vaccines;;https://firstwordpharma.com/story/5447207'
  },
  {
    'Date Announced': '23-Nov-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Dragonfly Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Merck',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'small molecules',
    'Target(s)': 'TriNKET',
    'Deal Structure':
      'Dragonfly Therapeutics Announces Merck Opt-In of TriNKET Immunotherapy Candidate for Patients with Solid Tumors',
    'FW Link1':
      'Dragonfly Therapeutics Announces Merck Opt-In of TriNKET Immunotherapy Candidate for Patients with Solid Tumors;;https://firstwordpharma.com/story/5447387'
  },
  {
    'Date Announced': '24-Nov-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Immunomic Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'iOncologi',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'small molecules',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'ATTAC-11',
    'Indication(s)': 'glioblastoma',
    'Deal Structure':
      'Immunomic Therapeutics Announces Collaboration With iOncologi',
    'FW Link1':
      'Immunomic Therapeutics Announces Collaboration With iOncologi;;https://firstwordpharma.com/story/5448055'
  },
  {
    'Date Announced': '29-Nov-2021',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Blueprint Medicines',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Lengo Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Total Deal Value ($m)': '215',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'small molecules',
    'Lead Asset Stage/Phase': 'Discovery',
    'Lead Asset(s)': 'LNG-451',
    'Target(s)': 'EGFR',
    'Deal Structure':
      'Blueprint bags EGFR-driven lung cancer asset with Lengo buy',
    'FW Link1':
      'Blueprint bags EGFR-driven lung cancer asset with Lengo buy;;https://firstwordpharma.com/story/5451059'
  },
  {
    'Date Announced': '30-Nov-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Mersana',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Synaffix',
    'Country (Target Company/Partner)': 'Netherlands',
    'Region (Target Company/Partner)': 'Europe',
    'Total Deal Value ($m)': '1,000',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'antibodies',
    'Lead Asset Stage/Phase': 'Discovery',
    'Lead Asset(s)': 'XMT-1660',
    'Deal Structure': 'Mersana expands ADC development deal with Synaffix;',
    'FW Link1':
      'Mersana expands ADC development deal with Synaffix;;https://firstwordpharma.com/story/5451693'
  },
  {
    'Date Announced': '30-Nov-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Taiho',
    'Country (Acquiring Company/Partner)': 'Japan',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Arcus Biosciences',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Asia except China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'antibodies',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'domvanalimab',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'TIGIT',
    'Deal Structure':
      'Taiho Pharmaceutical Exercises Option for an Exclusive License to Arcus Biosciences’ Anti-TIGIT Program in Japan and Certain Territories in Asia',
    'FW Link1':
      'Taiho Pharmaceutical Exercises Option for an Exclusive License to Arcus Biosciences’ Anti-TIGIT Program in Japan and Certain Territories in Asia;;https://firstwordpharma.com/story/5451973'
  },
  {
    'Date Announced': '30-Nov-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'EnGeneIC',
    'Country (Acquiring Company/Partner)': 'Australia',
    'Region (Acquiring Company/Partner)': 'Austrailia',
    'Target Company/Partner': 'ImunityBio',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'vaccines',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Australian Biotech EnGeneIC Strikes Landmark Deal with Major US Biotech ImmunityBio for COVID-19 Vaccine and Cancer Treatment',
    'FW Link1':
      'Australian Biotech EnGeneIC Strikes Landmark Deal with Major US Biotech ImmunityBio for COVID-19 Vaccine and Cancer Treatment;;https://firstwordpharma.com/story/5452475'
  },
  {
    'Date Announced': '30-Nov-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Immunicum',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'PCI Biotech',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'vaccines',
    'Deal Structure':
      'Immunicum and PCI Biotech Announce an Extension to Their Research Collaboration to Explore Novel Cancer Vaccination Treatments',
    'FW Link1':
      'Immunicum and PCI Biotech Announce an Extension to Their Research Collaboration to Explore Novel Cancer Vaccination Treatments;;https://firstwordpharma.com/story/5452479'
  },
  {
    'Date Announced': '30-Nov-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Whiterabbit',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Solis Mammography',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'diagnostics',
    'Deal Structure':
      'Whiterabbit Announces New Collaboration with Solis Mammography',
    'FW Link1':
      'Whiterabbit Announces New Collaboration with Solis Mammography;;https://firstwordhealthtech.com/story/5452509'
  },
  {
    'Date Announced': '30-Nov-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Jemincare',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'HUYABIO',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Greater China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'small molecules',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'JMKX1899',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'KRAS',
    'Deal Structure':
      'Jemincare Announces the Exclusive License of the Kras Inhibitor to HUYABIO',
    'FW Link1':
      'Jemincare Announces the Exclusive License of the Kras Inhibitor to HUYABIO;;https://firstwordpharma.com/story/5452537'
  },
  {
    'Date Announced': '30-Nov-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Gentron Health',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'AstraZeneca',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Greater China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'small molecules',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      'Genetron Health Announces Co-Development Agreement with AstraZeneca R&D China for Personalized MRD Tests for Solid Tumors in China'
  },
  {
    'Date Announced': '1-Dec-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Notable Labs',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'CicloMed',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'artificial intelligence',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'Fosciclopirox',
    'Indication(s)': 'Acute myeloid leukaemia',
    'Deal Structure':
      'Under the terms of the co-development agreement, CicloMed holds the primary responsibility for executing clinical trial operations while Notable is primarily focused on optimizing Notable’s predictive precision medicine platform. Both parties will be responsible for the costs associated with the on-going clinical trial.',
    'FW Link1':
      'Notable Labs strikes second AML deal for response-predicting AI;;https://firstwordhealthtech.com/story/5452815'
  },
  {
    'Date Announced': '1-Dec-2021',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Predictive Oncology',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'zPREDICTA Inc',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'artificial intelligence',
    'FW Link1':
      'Predictive Oncology Acquires zPREDICTA, Inc.;;https://firstwordpharma.com/story/5453509'
  },
  {
    'Date Announced': '2-Dec-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'ITM',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Navigo Proteins',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Radiopharmaceuticals',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'FAP',
    'Deal Structure':
      "Under the terms of the agreement, Navigo's proprietary protein engineering platform will be used to develop and select a FAP-targeting Affilin® molecule which will then be coupled with a therapeutic radioisotope provided by ITM. Following candidate selection, ITM will have the exclusive rights to further advance the radiolabeled FAP-specific Affilin® through clinical testing in potentially multiple cancer indications. The agreement also includes a non-exclusive license for diagnostic use in radio-imaging. Further details of the agreement were not disclosed.",
    'FW Link1':
      'ITM and Navigo Proteins Announce Research Collaboration to Develop FAP-targeting Radionuclide Therapy to Address Solid Tumors;;https://firstwordpharma.com/story/5454117'
  },
  {
    'Date Announced': '2-Dec-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'HUYABIO',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Shanghai Jemincare Pharmaceutical',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Greater China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'small molecules',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'JMKX1899',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'KRAS',
    'FW Link1':
      'HUYABIO Announces the Exclusive License of the KRAS Inhibitor from Shanghai Jemincare Pharmaceutical;;https://firstwordpharma.com/story/5454293'
  },
  {
    'Date Announced': '2-Dec-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'OpenBench',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Tavros Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'small molecules',
    'Lead Asset Stage/Phase': 'Discovery',
    'Deal Structure':
      "Under the terms of the agreement, OpenBench will receive payment for the identification and experimental confirmation of novel chemotypes that meet Tavros's potency and developability criteria. In exchange, Tavros gains exclusive access to OpenBench's proprietary screening technology with respect to the promising cancer target. Financial terms were not disclosed.",
    'FW Link1':
      'OpenBench and Tavros Therapeutics Announce Collaboration to Pursue First-in-class Cancer Target;;https://firstwordpharma.com/story/5454503'
  },
  {
    'Date Announced': '3-Dec-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'J&J',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'OrigiMed',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Real world data',
    'FW Link1':
      'OrigiMed announced its strategic partnership with Janssen to develop clinical innovative solutions driven by data science;;https://firstwordhealthtech.com/story/5454817'
  },
  {
    'Date Announced': '3-Dec-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Servier',
    'Country (Acquiring Company/Partner)': 'France',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Aqemia',
    'Country (Target Company/Partner)': 'France',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'artificial intelligence',
    'Deal Structure':
      'The agreement includes upfront payments and payments for potential milestones from the early-stage discovery and the clinical trials in developing the drug candidates resulting from this collaboration.',
    'FW Link1':
      'Aqemia and Servier announce AI and quantum physics-driven drug discovery collaboration in immuno-oncology;;https://firstwordhealthtech.com/story/5455147'
  },
  {
    'Date Announced': '6-Dec-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Redx Pharma',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Caris Life Science',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'RXC004',
    'Indication(s)': 'Colorectal cancer',
    'Target(s)': 'Porcupine',
    'Deal Structure': 'Financial terms have not been disclosed.',
    'FW Link1':
      'Redx and Caris Life Sciences Announce Partnership;;https://firstwordpharma.com/story/5455931'
  },
  {
    'Date Announced': '7-Dec-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Recursion',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Roche',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Upfront Value ($m)': '150',
    'Total Deal Value ($m)': '300',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial intelligence',
    'Deal Structure':
      'Under the terms of the agreement, Recursion will receive an upfront payment of $150 million and is eligible for additional performance-based research milestones. Under the collaboration, Roche and Genentech (combined) may initiate up to 40 programs, each of which, if successfully developed and commercialized, could yield more than $300M in development, commercialization and net sales milestones for Recursion, as well as tiered royalties on net sales.',
    'FW Link1':
      'Roche teams up with Recursion in multibillion dollar AI drug-discovery pact;;https://firstwordhealthtech.com/story/5457047'
  },
  {
    'Date Announced': '9-Dec-2021',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'Prestige Biopharma',
    'Country (Acquiring Company/Partner)': 'Singapore',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': "Dr. Reddy's Laboratories",
    'Country (Target Company/Partner)': 'India',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Latin America and Southeast Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'trastuzumab biosimilar',
    'Indication(s)': 'HER2 positive breast and metastatic gastric cancer',
    'Target(s)': 'HER2',
    'Deal Structure':
      "The license agreement grants Dr. Reddy's the exclusive rights to commercialize the proposed biosimilar in select countries in Latin America and Southeast Asia. Under this partnership, Prestige BioPharma will be responsible for sustainable commercial supply of HD201 from its manufacturing facilities in Osong, South Korea, while Dr. Reddy's will be responsible for local registrations, marketing and sales in the licensed territories.",
    'FW Link1':
      'Prestige BioPharma and Dr. Reddy’s announce partnership to commercialize trastuzumab biosimilar in select countries in Latin America and Southeast Asia;;https://firstwordpharma.com/story/5458769'
  },
  {
    'Date Announced': '9-Dec-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'QIAGEN',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Denovo Biopharma',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'diagnostics',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'DB102',
    'Indication(s)': 'diffuse large B-cell lymphoma (DLBCL)',
    'Target(s)': 'DGM1',
    'Deal Structure':
      "Under the agreement, QIAGEN will develop a diagnostic assay that can detect the Denovo Genomic Marker 1 (DGM1TM) in DLBCL patients, a biomarker discovered by Denovo that predicts the responsiveness to DB102. Also known as enzastaurin, Denovo's drug is a first-in-class investigational small molecule inhibitor of PKC-beta, a protein whose presence has been compellingly linked to DLBCL cases.",
    'FW Link1':
      'QIAGEN and Denovo Biopharma Partner to Develop Companion Diagnostic Test for the Treatment of Diffuse Large B-Cell Lymphoma (DLBCL);;https://firstwordhealthtech.com/story/5458947'
  },
  {
    'Date Announced': '3-Jan-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Oncoheroes Biosciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Allarity Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'dovitinib, stenoparib',
    'Indication(s)': 'Paediatric cancers',
    'Target(s)': 'PARP',
    'FW Link1':
      'Allarity Therapeutics and Oncoheroes Biosciences Sign Agreements to Advance Pediatric Cancer Development of Dovitinib and Stenoparib;;https://www.firstwordpharma.com/story/5471855/'
  },
  {
    'Date Announced': '4-Jan-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Rivaara Immun',
    'Country (Acquiring Company/Partner)': 'India',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'GC Cell',
    'Country (Target Company/Partner)': 'Korea South',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'India',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Immuncell-LC',
    'Indication(s)': 'Liver cancer',
    'Deal Structure':
      'Under the terms of the agreement, Rivaara Immune has obtained the exclusive right to develop and commercialize Immuncell-LC® within India for liver cancer. In return, GC Cell is entitled to receive an undisclosed upfront payment, as well as payments for the achievement of commercial milestones, with royalties based upon the sales. In addition, GC Cell will also receive a partial stake of Rivaara Immune and exclusive rights to supply media which is essential for the production of Immuncell-LC',
    'FW Link1':
      'GC Cell Inks First Licensing Agreement for Immuncell-LC With Rivaara Immune Private Limited in India;;https://www.firstwordpharma.com/story/5472321/'
  },
  {
    'Date Announced': '4-Jan-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Genmab',
    'Country (Acquiring Company/Partner)': 'Denmark',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Synaffix',
    'Country (Target Company/Partner)': 'Netherlands',
    'Region (Target Company/Partner)': 'Europe',
    'Upfront Value ($m)': '5',
    'Total Deal Value ($m)': '415',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody drug conjugates',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Genmab is granted exclusive research rights to utilize Synaffix ADC technologies for one drug target with the option for the worldwide development and commercialization of the resulting ADCs. Genmab has the option to exercise exclusive research and commercial licenses for additional targets. For each specific target nominated under the license agreement, Genmab gains exclusive access to Synaffix’s clinical-stage GlycoConnect™ antibody conjugation technology, HydraSpace™ polar spacer technology, as well as select toxSYN™ linker-payloads, each designed to enable ADCs with best-in-class efficacy and tolerability for the development of multiple potential therapies. Genmab will be responsible for the research, development, manufacturing and commercialization of any resulting ADC therapies. At the same time, Synaffix will support Genmab’s research activities, including manufacturing of components that are specifically related to its proprietary ADC technologies.',
    'FW Link1':
      "Genmab lands rights to Synaffix's ADC technologies;;https://www.firstwordpharma.com/story/5472393/"
  },
  {
    'Date Announced': '4-Jan-2022',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Cumberland Pharmaceuticals',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Kyowa Kirin',
    'Country (Target Company/Partner)': 'Japan',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '14',
    'Geographic Remit of Deal': 'US',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Transdermal patch',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Sancuso',
    'Indication(s)': 'CINV',
    'Deal Structure':
      'The financial terms of the acquisition include a $13.5 million payment to Kyowa Kirin upon closing, up to $3.5 million in milestones and tiered royalties of up to 10% on U.S. net product sales. Kyowa Kirin will retain international rights',
    'FW Link1':
      'Cumberland Pharmaceuticals acquires Sancuso from Kyowa Kirin North America;;https://www.firstwordpharma.com/story/5472399/'
  },
  {
    'Date Announced': '4-Jan-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'AVEO',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Merck KGaA',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'Ficlatuzumab/Erbitux combination',
    'Indication(s)': 'Head and neck cancer',
    'Target(s)': 'HGF, EGFR',
    'Deal Structure':
      "Under the terms of the agreement, Merck KGaA, Darmstadt, Germany will provide cetuximab clinical drug supply in all countries outside of the U.S. and Canada for AVEO's future registrational study, which will assess ficlatuzumab with cetuximab in HPV negative R/M HNSCC. AVEO will serve as the study sponsor and will be responsible for costs associated with the trial execution.",
    'FW Link1':
      'AVEO Oncology Announces Clinical Trial Collaboration and Supply Agreement with Merck KGaA, Darmstadt, Germany to Evaluate Ficlatuzumab and ERBITUX (cetuximab) in Patients with Recurrent or Metastatic HNSCC;;https://www.firstwordpharma.com/story/5472401/'
  },
  {
    'Date Announced': '4-Jan-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'J&J',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Genocea Biosciences',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Target screening',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Deal Structure':
      'Genocea will use its clinically validated ATLAS™ platform to characterize Janssen-identified antigens as well as assess approaches that could mitigate the impact of Inhibigens. Genocea will receive a technology access fee and full R&D funding for its work under the collaboration. The agreement includes an option for Janssen to negotiate a future strategic partnership to develop non-personalized vaccine products using Genocea’s ATLAS platform and expertise on Inhibigens.',
    'FW Link1':
      'J&J pact targets pro-tumour antigens that can stump immunotherapies;;https://www.firstwordpharma.com/story/5472593/'
  },
  {
    'Date Announced': '4-Jan-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Champions Oncology',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Alloy Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody drug conjugates',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Deal Structure':
      "The partnership will combine novel therapeutic targets identified within Champions' Lumin platform with Alloy's fully-integrated in vivo and in vitro antibody discovery, optimization, and lead development services platform to develop antibodies used in the development of next generation Antibody Drug Conjugates (ADCs). Under the terms of this agreement, Champions will wholly own the therapeutic molecule, with milestones and royalty payments owed to Alloy.",
    'FW Link1':
      'Champions Oncology Announces a Partnership with Alloy Therapeutics to Develop Therapeutic Monoclonal Antibodies for Use in ADC Programs;;https://www.firstwordpharma.com/story/5472971/'
  },
  {
    'Date Announced': '4-Jan-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'NaNotics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Mayo Clinic',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'NaNots',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Target(s)': 'PD-L1',
    'Deal Structure':
      'NaNotics will provide its core nano-depletion platform and Mayo Clinic will provide an antibody of its own design against PD-L1, which will be specific to sPD-L1 once incorporated into a NaNot.',
    'FW Link1':
      'NaNotics to Collaborate with Mayo Clinic on Nanomedicine Cancer Treatment;;https://www.firstwordpharma.com/story/5472987/'
  },
  {
    'Date Announced': '5-Jan-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Junshi Biociences',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'DotBio',
    'Country (Target Company/Partner)': 'Singapore',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Deal Structure':
      "Junshi Biosciences will obtain the license to one of DotBio's DotBody modules, to be used within the oncology field for the development of several multi-specific antibodies. DotBio will be entitled to upfront and milestone payments, as well as royalties as the molecules progress through clinical development, regulatory approvals, and commercialization.",
    'FW Link1':
      'Junshi Biosciences and DotBio Announce Collaboration to Develop Next-Generation Antibody Therapy with DotBody Module;;https://www.firstwordpharma.com/story/5473367/'
  },
  {
    'Date Announced': '5-Jan-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'NiKang Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'AVEO Oncology',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'NKT2152, Fotivda',
    'Indication(s)': 'Renal cell carcinoma',
    'Target(s)': 'HIF2α, VEGFR',
    'Deal Structure':
      'Under the terms of the agreement, NiKang will sponsor the trial and AVEO will co- fund the trial. Both companies will provide its respective drugs at no cost. The two companies will form a Joint Development Committee to oversee this collaboration.',
    'FW Link1':
      'NiKang Therapeutics and AVEO Oncology Announce a Clinical Trial Collaboration and Supply Agreement to Evaluate the Combination of NKT2152, a HIF2α Inhibitor, and FOTIVDA (tivozanib) for the Treatment of Advanced Clear Cell Renal Cell Carcinoma;;https://www.firstwordpharma.com/story/5473383/'
  },
  {
    'Date Announced': '5-Jan-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Cyclenium Pharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Vuja De Sciences',
    'Country (Target Company/Partner)': 'Canada',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Target screening',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Deal Structure':
      "The collaboration will exploit Vuja De's proprietary anti-metastatic progression in vitro and ex vivo drug discovery screening platform and Cyclenium's proprietary QUEST™ Library of next generation synthetic small-molecule macrocycles and associated optimization expertise to identify clinical candidates effective against dormant disseminated tumor cells for the prevention of metastatic cancer recurrence. Vuja De will be responsible for all screening efforts, while Cyclenium will be responsible for all medicinal chemistry efforts to generate new optimized macrocyclic compounds by employing its unique CMRT™ Technology.",
    'FW Link1':
      'Cyclenium Pharma and Vuja De Sciences Announce Oncology Drug Discovery Collaboration;;https://www.firstwordpharma.com/story/5473407/'
  },
  {
    'Date Announced': '5-Jan-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Mainz Biomed',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Socpra Sciences Santé Et Humaines',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'ColoAlert',
    'Indication(s)': 'Colorectal cancer',
    'Deal Structure':
      'Under the terms of the Technology Rights Agreement, the Company has the unilateral option to license the exclusive global rights to five gene expression biomarkers which have demonstrated a high degree of effectiveness in detecting CRC lesions including advanced adenomas ("AA"), a type of pre-cancerous polyp often attributed to this deadly disease.',
    'FW Link1':
      'Mainz Biomed Acquires Exclusive Rights to Novel mRNA Biomarkers;;https://www.firstwordhealthtech.com/story/5473477/'
  },
  {
    'Date Announced': '5-Jan-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Hibiscus BioVentures',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Karmanos',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Lead Asset Stage/Phase': 'Preclinical',
    'FW Link1':
      'Hibiscus BioVentures Partners with the Barbara Ann Karmanos Cancer Institute to Advance Translational Cancer Research;;https://www.firstwordpharma.com/story/5473535/'
  },
  {
    'Date Announced': '5-Jan-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Actinium Pharmaceuticals',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'EpicentRx',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Radiopharmaceuticals, small molecules',
    'Lead Asset(s)': 'Actimab-A, RRx-001',
    'Indication(s)': 'Acute myeloid leuakemia',
    'Target(s)': 'CD33, CD47',
    'FW Link1':
      'Actinium Pharmaceuticals, Inc. and EpicentRx Announce Strategic Research Collaboration to Combine Targeted Radiotherapies with Next Generation CD47;;https://www.firstwordpharma.com/story/5473589/'
  },
  {
    'Date Announced': '5-Jan-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Polaris Quantum Biotech',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'PhoreMost',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Target screening, AI',
    'Lead Asset Stage/Phase': 'Preclinical',
    'FW Link1':
      'POLARISqb and PhoreMost announce a multi-target collaboration to investigate next-generation cancer therapies;;https://www.firstwordpharma.com/story/5473851/'
  },
  {
    'Date Announced': '6-Jan-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Roivant Sciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Eisai',
    'Country (Target Company/Partner)': 'Japan',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'H3B-8800',
    'Indication(s)': 'myelodysplastic syndrome',
    'Target(s)': 'SF3B1',
    'Deal Structure':
      'Under the terms of the agreement, Eisai will receive a contractual up-front payment, development, and regulatory milestone payments for H3B-8800, and will also receive a certain amount of royalties on sales revenue of H3B-8800 after the launch.',
    'FW Link1':
      'Eisai Enters Into Exclusive Licensing Agreement with Roivant Concerning Investigational Anticancer Agent H3B-8800, a Splicing Modulator;;https://www.firstwordpharma.com/story/5474265/'
  },
  {
    'Date Announced': '6-Jan-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Exelixis',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Iconic Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody drug conjugates',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'XB002',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'TF',
    'Deal Structure':
      'Under the amended agreement announced today, Exelixis has acquired broad rights to use the anti-TF antibody incorporated into XB002, for any application, including conjugated to other payloads, as well as rights within oncology to a number of other anti-TF antibodies developed by Iconic, including for use in ADCs and multi-specific biologics. In exchange, Exelixis will make a one-time payment of $55 million to Iconic and will not owe any further payments to the company.',
    'FW Link1':
      'Exelixis and Iconic Therapeutics Amend Option and License Agreement for XB002, an Antibody-Drug Conjugate Targeting Tissue Factor;;https://www.firstwordpharma.com/story/5474485/'
  },
  {
    'Date Announced': '6-Jan-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'PLUS Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner':
      'The University of Texas Health Science Center at San Antonio',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Radiopharmaceuticals',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Liver cancer',
    'FW Link1':
      'Plus Therapeutics Significantly Expands Investigational Oncology Drug Pipeline;;https://www.firstwordpharma.com/story/5474647/'
  },
  {
    'Date Announced': '10-Jan-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Iksuda Therapeutics',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'LegoChem Biosciences',
    'Country (Target Company/Partner)': 'Korea South',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global (excluding China and South Korea)',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody drug conjugates',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'IKS014',
    'Target(s)': 'HER2',
    'FW Link1':
      'Iksuda Therapeutics Deepens Clinical Pipeline Through Licensing Agreement for Her2 Antibody Drug Conjugate Programme From LegoChem Biosciences;;https://www.firstwordpharma.com/story/5476109/'
  },
  {
    'Date Announced': '10-Jan-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'BMS',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Century Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '150',
    'Total Deal Value ($m)': '3,150',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Acute myeloid leuakemia, multiple-myeloma',
    'Deal Structure':
      'Century will be responsible for development candidate discovery and preclinical development activities. Thereafter, Bristol Myers Squibb will be responsible for clinical development and commercialization activities subject to Century’s co-promotion rights on certain programs.',
    'FW Link1':
      'BMS partners with Century on iPSC-derived allogeneic cell therapies;;https://www.firstwordpharma.com/story/5476131/'
  },
  {
    'Date Announced': '10-Jan-2022',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Takeda',
    'Country (Acquiring Company/Partner)': 'Japan',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Adaptate Biotherapeutics',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Target(s)': 'Gamma delta T-cells',
    'FW Link1':
      'Takeda to Acquire Adaptate Biotherapeutics to Develop Novel Gamma Delta (γδ) T Cell Engager Therapies Targeting Solid Tumors;;https://www.firstwordpharma.com/story/5476133/'
  },
  {
    'Date Announced': '10-Jan-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Moderna',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Carisma Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '80',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'chimeric antigen receptor monocyte (CAR-M) therapeutics',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Deal Structure':
      'Under the terms of the agreement, Carisma will receive a $45 million up-front cash payment and an investment by Moderna in the form of a $35 million convertible note. Carisma will receive research funding and is eligible to receive development, regulatory, and commercial milestone payments, plus royalties on net sales of any products that are commercialized under the agreement. Carisma will be responsible for the discovery and optimization of development candidates while Moderna will lead the clinical development and commercialization of therapeutics resulting from the agreement. Moderna has the option to nominate up to twelve targets for development and commercialization.',
    'FW Link1':
      'Moderna and Carisma Establish Collaboration to Develop in vivo Engineered Chimeric Antigen Receptor Monocytes (CAR-M) for Oncology;;https://www.firstwordpharma.com/story/5476135/'
  },
  {
    'Date Announced': '10-Jan-2022',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Exact Sciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'PreventionGenetics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '190',
    'Total Deal Value ($m)': '190',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'Lead Asset Stage/Phase': 'Marketed',
    'Deal Structure':
      'Under the terms of the agreement, PreventionGenetics received total consideration of $190 million, comprised of 50% in Exact Sciences common stock and 50% in cash.',
    'FW Link1':
      'Exact Sciences Acquires PreventionGenetics to Accelerate Availability of Hereditary Cancer Testing for More Patients;;https://www.firstwordhealthtech.com/story/5476161/'
  },
  {
    'Date Announced': '10-Jan-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Cullinan Oncology',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Icahn School of Medicine at Mount Sinai',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules, protein degraders',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Target(s)': 'HPK1',
    'Deal Structure':
      'The research will be conducted by leading scientists at both Cullinan and Icahn Mount Sinai. Cullinan will fund the collaboration and has an exclusive option to license the intellectual property for further development and commercialization.',
    'FW Link1':
      'Cullinan Oncology Forms Strategic Collaboration Agreement with Mount Sinai to Advance Oral HPK1 Protein Degrader for Cancer Treatment;;https://www.firstwordpharma.com/story/5476197/'
  },
  {
    'Date Announced': '10-Jan-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'BioNTech',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Crescendo Biologics',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Upfront Value ($m)': '40',
    'Total Deal Value ($m)': '790',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Target screening, antibodies',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Deal Structure':
      'Under the terms of the agreement, Crescendo will receive $40 million upfront, including a cash payment and an equity investment from BioNTech, as well as research funding for the period of the collaboration. BioNTech will be responsible for global development and hold exclusive worldwide commercialization rights on any products arising from the collaboration. Crescendo will be eligible to receive development, regulatory and commercial milestones up to a total of more than $750 million, in addition to tiered royalties on global net sales.',
    'FW Link1':
      'BioNTech and Crescendo Biologics Announce Global Collaboration to Develop Multi-specific Precision Immunotherapies;;https://www.firstwordpharma.com/story/5476231/'
  },
  {
    'Date Announced': '10-Jan-2022',
    'Deal type': 'Alliance',
    'Acquiring Company/Partner': 'Gilead Sciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Merck & Co.',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Antbody drug conjuagtes, monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Trodelvy/Keytruda',
    'Indication(s)': 'Non small cell lung cancer',
    'Target(s)': 'Trop-2/PD-1',
    'Deal Structure':
      "Gilead Sciences, Inc. (Nasdaq: GILD) today announced it has entered into two clinical trial collaboration and supply agreements with Merck (known as MSD outside of the United States and Canada) to evaluate the combination of Gilead's Trop-2 targeting antibody-drug conjugate (ADC) Trodelvy® (sacituzumab govitecan-hziy) and Merck's anti-PD-1 therapy KEYTRUDA® (pembrolizumab) in first-line metastatic non-small cell lung cancer (NSCLC). As part of the collaboration, Merck will sponsor a global Phase 3 clinical trial of Trodelvy in combination with KEYTRUDA as a first-line treatment for patients with metastatic NSCLC. Additionally, the companies recently established an agreement whereby Gilead will sponsor a Phase 2 signal-seeking study evaluating combinations that include pembrolizumab in first-line NSCLC.",
    'FW Link1':
      'Gilead Announces Clinical Trial Collaborations With Merck to Evaluate Trodelvy in Combination With KEYTRUDA in First-Line Metastatic Non-Small Cell Lung Cancer;;https://www.firstwordpharma.com/story/5476305/'
  },
  {
    'Date Announced': '10-Jan-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Neoleukin Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Merck & Co.',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Proteins, monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'NL-201/Keytruda',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'IL-2, IL-15, PD-1',
    'FW Link1':
      'Neoleukin Therapeutics Announces Clinical Collaboration with Merck to Evaluate NL-201 in Combination with KEYTRUDA (pembrolizumab);;https://www.firstwordpharma.com/story/5476309/'
  },
  {
    'Date Announced': '10-Jan-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'BMS',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'ArsenalBio',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Deal Structure':
      'Under the collaboration, ArsenalBio is discovering and building preclinical candidates against multiple targets, and Bristol Myers Squibb has the option to obtain an exclusive worldwide license to develop and commercialize preclinical candidates. Through the terms of the agreement, Bristol Myers Squibb has exercised an Expansion Option to initiate a new program, triggering an additional undisclosed financial milestone payment to ArsenalBio. Following exercise of a subsequent License Option, Bristol Myers Squibb is solely responsible for developing and commercializing the licensed candidates. ArsenalBio will remain eligible to receive additional payments associated with collaboration expansion, regulatory and sales milestones, as well as potential royalties on sales of approved products.',
    'FW Link1':
      'ArsenalBio Announces Expansion of Collaboration with Bristol Myers Squibb to Advance T Cell Therapy in Solid Tumors;;https://www.firstwordpharma.com/story/5476363/'
  },
  {
    'Date Announced': '10-Jan-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Coherus Biosciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Shanghai Junshi Biosciences',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '35',
    'Total Deal Value ($m)': '290',
    'Geographic Remit of Deal': 'United States, Canada',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'JS006',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'TIGIT',
    'Deal Structure':
      'Coherus will pay Junshi Biosciences $35 million upfront, up to $255 million in development regulatory and sales milestones, and an 18% royalty on net product revenue, subject to terms and conditions agreed between the parties.',
    'FW Link1':
      'Coherus and Junshi Biosciences Expand Immuno-Oncology Collaboration to Include TIGIT-Targeted Antibody;;https://www.firstwordpharma.com/story/5476405/'
  },
  {
    'Date Announced': '10-Jan-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'HotSpot Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Caris Life Sciences',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'CBL-B',
    'Deal Structure':
      "Insights gained from Caris' best-in-class real-world clinico-molecular matched database will be leveraged to optimize HotSpot's development programs, including cohort design, biomarker strategy, and future CDx needs and strategies. Under the terms of the agreement, patients enrolled in HotSpot's Phase 1 through Phase 3 trials will undergo longitudinal testing with Caris' comprehensive tissue and liquid molecular profiling analysis",
    'FW Link1':
      'HotSpot Therapeutics and Caris Life Sciences Announce Collaboration to Advance Precision Medicine Development for Difficult-to-Drug Therapeutic Targets;;https://www.firstwordpharma.com/story/5476741/'
  },
  {
    'Date Announced': '10-Jan-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'BioAtla',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'BMS',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Antibody drug conjugates, monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'BA3011, BA3021/Opdivo',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'AXL, ROR2, PD-1',
    'Deal Structure':
      "Under the terms of the agreement, BioAtla and Bristol Myers Squibb will collaborate on clinical trials of separate combination therapies using two of BioAtla's Conditionally Active Biologic Antibody Drug Conjugates, BA3011 and BA3021, each in combination with Opdvio. BioAtla will serve as the study sponsor and will be responsible for costs associated with the trial execution. Bristol Myers Squibb will provide Opdivo clinical drug supply for the study.",
    'FW Link1':
      'BioAtla Announces Clinical Collaboration with Bristol Myers Squibb to Study Mecbotamab Vedotin (BA3011) and Ozuriftamab Vedotin (BA3021) in Combination with Opdivo (nivolumab) for Treatment of Solid Tumors;;https://www.firstwordpharma.com/story/5477007/'
  },
  {
    'Date Announced': '10-Jan-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Sana Biotechnology',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'IASO Biotherapeutics, Innovent Biologics',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Total Deal Value ($m)': '204',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'IBI326',
    'Indication(s)': 'Multiple myeloma',
    'Target(s)': 'BCMA',
    'Deal Structure':
      'Sana obtained from IASO Bio and Innovent non-exclusive commercial rights to a clinically validated fully-human BCMA CAR construct for use in certain in vivo gene therapy and ex vivo hypoimmune cell therapy applications. IASO Bio and Innovent will receive an upfront payment and are entitled to receive up to approximately $204 million in potential development and regulatory milestone payments across up to six products, as well as royalties.',
    'FW Link1':
      'Sana Biotechnology, IASO Biotherapeutics, and Innovent Biologics Announce Non-Exclusive License Agreement for Clinically Validated BCMA CAR Construct;;https://www.firstwordpharma.com/story/5477029/'
  },
  {
    'Date Announced': '10-Jan-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Boehringer Ingelheim',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Illumina',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'Deal Structure':
      "Illumina Inc. (NASDAQ: ILMN) today announced a partnership with Boehringer Ingelheim to develop companion diagnostics (CDx) for several programs in Boehringer Ingelheim's oncology pipeline. The partnership aims to accelerate the development of therapy selection and precision medicines for patients with advanced cancer",
    'FW Link1':
      'Illumina and Boehringer Ingelheim Announce Companion Diagnostic Partnership;;https://www.firstwordpharma.com/story/5477031/'
  },
  {
    'Date Announced': '11-Jan-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Pfizer',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Dren Bio',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '25',
    'Total Deal Value ($m)': '1,025',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Bispecific antibodies',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Deal Structure':
      'Under the terms of the agreement, Pfizer made an upfront cash payment of $25 million to Dren Bio, with the Company eligible to potentially receive more than $1 billion of cash in total, including payments for the achievement of future development, regulatory, and commercial milestones. Dren Bio and Pfizer will work together to advance the selected oncology target programs through clinical candidate selection, at which point Pfizer will assume full responsibility for all remaining development, manufacturing, regulatory and commercialization activities. For each target-specific product that is globally licensed by Pfizer, Dren Bio will be eligible to receive tiered royalties on all future net sales during the term of the Agreement. Additionally, under the terms of the agreement, Pfizer also has the right to reserve and subsequently nominate additional oncology targets to license from Dren Bio, subject to additional cash payments and future royalties. Excluding products developed for targets licensed to Pfizer, Dren Bio will retain exclusive global rights for the platform including all other therapeutic targets currently in development as part of its own internal pipeline.',
    'FW Link1':
      'Dren Bio Announces Research Collaboration and License Agreement with Pfizer to Discover and Advance Multiple Therapeutic Antibodies Using its Targeted Myeloid Engager and Phagocytosis Platform for the Treatment of Cancer;;https://www.firstwordpharma.com/story/5477559/'
  },
  {
    'Date Announced': '11-Jan-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Neogene Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'National Cancer Institute',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy, gene editing',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Target(s)': 'TP53, KRAS',
    'Deal Structure':
      'Neogene has been granted worldwide rights to develop, manufacture and commercialize this TCR portfolio of autologous and allogeneic T cell therapy product candidates that are engineered with CRISPR technology for the treatment or prevention of cancer. Pursuant to the terms of the license agreement, Neogene will provide an upfront payment and certain clinical, regulatory, and sales milestone payments, as well as royalties on net sales of products covered by the license.',
    'FW Link1':
      'Neogene Therapeutics Announces Exclusive License with the National Cancer Institute for a Portfolio of T Cell Receptors (TCR) Targeting KRAS and TP53 Mutations for the Treatment of Cancer;;https://www.firstwordpharma.com/story/5477561/'
  },
  {
    'Date Announced': '11-Jan-2022',
    'Deal type': 'Alliance',
    'Acquiring Company/Partner': 'Everest Medicines',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Gilead Sciences',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Antbody drug conjuagtes, monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Trodelvy/Keytruda',
    'Indication(s)': 'Non small cell lung cancer',
    'Target(s)': 'Trop-2/PD-1',
    'Deal Structure':
      'As part of the collaboration, MSD will sponsor a global Phase 3 clinical trial of Trodelvy in combination with KEYTRUDA as a first-line treatment for patients with metastatic NSCLC. Everest Medicines will participate in the global Phase 3 study in Asia through its existing collaboration agreement with Gilead.',
    'FW Link1':
      'Everest Medicines Will Participate in a Clinical Trial with Gilead and MSD to Evaluate Trodelvy in Combination with KEYTRUDA (pembrolizumab) in First-Line Metastatic Non-Small Cell Lung Cancer;;https://www.firstwordpharma.com/story/5477577/'
  },
  {
    'Date Announced': '11-Jan-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Sana Biotechnology',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'National Institutes of Health',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy',
    'Indication(s)': 'B-cell malignancies',
    'Target(s)': 'CD22',
    'Deal Structure':
      'Under the terms of the agreement, Sana agreed to pay the NIH an upfront amount, certain milestone payments, and royalties on net sales of royalty-bearing products.',
    'FW Link1':
      'Sana Biotechnology Obtains Exclusive License from National Institutes of Health for CD22 CAR Construct;;https://www.firstwordpharma.com/story/5477635/'
  },
  {
    'Date Announced': '11-Jan-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Fusion Pharmaceuticals',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Pepscan Therapeutics',
    'Country (Target Company/Partner)': 'Netherlands',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Radiopharmaceuticals',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      'Under the agreement, Fusion has global rights to develop and commercialize any peptides discovered under the collaboration.',
    'FW Link1':
      'Fusion Pharmaceuticals Announces Research Collaboration with Pepscan to Develop Peptide-Based Radiopharmaceuticals;;https://www.firstwordpharma.com/story/5477713/'
  },
  {
    'Date Announced': '11-Jan-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Allogene Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Antion Biosciences',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Gene silencing, Cell therapy',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Deal Structure':
      "Allogene Therapeutics, Inc. (Nasdaq: ALLO), a clinical-stage biotechnology company pioneering the development of allogeneic CAR T (AlloCAR T™) products for cancer and Antion Biosciences, a Swiss cell and gene engineering company, today announced that they have entered into an exclusive collaboration and global license agreement for Antion's miRNA technology (miCAR™) to advance multiplex gene silencing as an additional tool to develop next generation allogeneic CAR T products. As part of this agreement, Antion will exclusively collaborate with Allogene on oncology products for a defined period. Allogene will also have exclusive worldwide rights to commercialize products incorporating Antion technology developed during the collaboration. Allogene will provide Antion an upfront cash payment and a preferred equity investment. Allogene will pay Antion developmental and commercial milestones and a single-digit royalty on any product sales. Allogene will also take a seat on Antion's Board of Directors",
    'FW Link1':
      'Allogene Therapeutics Announces Exclusive Collaboration and Global License Agreement with Antion Biosciences for Multiplex miCAR Technology;;https://www.firstwordpharma.com/story/5477723/'
  },
  {
    'Date Announced': '12-Jan-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Lokon Pharma',
    'Country (Acquiring Company/Partner)': 'Sweden',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Affibody',
    'Country (Target Company/Partner)': 'Sweden',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Oncolytic viruses, monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Deal Structure':
      'Lokon Pharma AB, a leading biotech company in the area of immunostimulatory gene therapy for cancer delivered by uniquely engineered oncolytic viruses, today announced a collaboration with Affibody AB ("Affibody") to combine oncolytic viruses with Affibody® molecules.',
    'FW Link1':
      'Lokon Pharma and Affibody Initiates a Collaboration to Develop Novel Cancer Therapeutics;;https://www.firstwordpharma.com/story/5478585/'
  },
  {
    'Date Announced': '13-Jan-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'AstraZeneca',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Scorpion Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '75',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Deal Structure':
      'Under the terms of the collaboration agreement, Scorpion will lead discovery and certain preclinical activities. AstraZeneca has the exclusive option to licence worldwide rights for up to three drug candidates. AstraZeneca would be responsible for development and commercialisation activities worldwide following opt-in, while Scorpion would retain the option to co-develop and co-promote up to two of these programmes in the US under certain conditions, including if AstraZeneca exercises three licence options.',
    'FW Link1':
      "AstraZeneca inks deal with Scorpion on 'undruggable' cancer targets;;https://www.firstwordpharma.com/story/5479201/"
  },
  {
    'Date Announced': '13-Jan-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Undisclosed',
    'Country (Acquiring Company/Partner)': 'Korea, South',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Bold Therapeutics',
    'Country (Target Company/Partner)': 'Canada',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'South Korea',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'BOLD-100',
    'Indication(s)': 'Solid tumours, haematological cancers',
    'Deal Structure':
      'Bold Therapeutics, a clinical-stage biopharmaceutical company developing BOLD-100, is pleased to announce an extension of the option agreement with an undisclosed publicly traded South Korean biopharmaceutical company, originally executed in May 2020, for development and commercialization rights to BOLD-100 in South Korea. The extension is expected to further solidify cooperation between these companies, as well as support the addition of five clinical trial sites in South Korea',
    'FW Link1':
      'Bold Therapeutics Extends South Korea Option Agreement for BOLD-100, a First-in-Class Anti-Resistance Therapeutic;;https://www.firstwordpharma.com/story/5479259/'
  },
  {
    'Date Announced': '13-Jan-2022',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Salarius Pharmaceuticals',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'DeuteRx',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '3',
    'Total Deal Value ($m)': '191',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'SP-3164',
    'Indication(s)': 'Solid tumours, haematological cancers',
    'Deal Structure':
      'Under the terms of the agreement, DeuteRx will receive from Salarius an upfront payment consisting of $1.5 million in cash and 1 million shares of restricted stock. Based upon the success of SP-3164, DeuteRx is also entitled to receive up to $53 million in future clinical and regulatory event-driven milestone payments and sales achievement milestone payments of up to $135 million, as well as escalating royalties on net sales. Additionally, DeuteRx is eligible to receive event-driven clinical, regulatory and sales achievement milestone payments of up to $84 million, as well as escalating royalties on net sales, for each of two future products.',
    'FW Link1':
      'Salarius Pharmaceuticals Expands Oncology Pipeline Through Strategic Acquisition of Targeted Protein Degradation Portfolio from DeuteRx;;https://www.firstwordpharma.com/story/5479589/'
  },
  {
    'Date Announced': '13-Jan-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'ITM',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Helmholtz Munich',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Radiopharmaceuticals',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'LuCaFab',
    'Indication(s)': 'Brain tumours',
    'Target(s)': 'CA XII',
    'Deal Structure':
      'Under the terms of the agreement, which formalizes an ongoing collaboration between ITM and Helmholtz Munich, ITM supports the logistics and supply of the clinical trial material and provides funding for the trial, which is designed for the treatment of up to 15 patients. ITM holds the exclusive option, which may be executed at any time, to license the compound, related worldwide patents and know-how for the manufacturing, use and application of LuCaFab from Helmholtz Munich under already agreed upon terms. The option also allows ITM to access and use trial data for research and commercial activities.',
    'FW Link1':
      'ITM and Helmholtz Munich Sign Cooperation Agreement for the Clinical Development of Radiopharmaceutical Therapy to Treat Glioblastoma Multiforme;;https://www.firstwordpharma.com/story/5479939/',
    'FW Link2':
      'Execution of option announced October 19, 2022 https://firstwordpharma.com/story/5665271/'
  },
  {
    'Date Announced': '18-Jan-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Mitsubishi Tanabe',
    'Country (Acquiring Company/Partner)': 'Japan',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'ADC Therapeutics',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Upfront Value ($m)': '30',
    'Total Deal Value ($m)': '235',
    'Geographic Remit of Deal': 'Japan',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody drug conjugates',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Zylonta',
    'Indication(s)': 'DLBCL',
    'Target(s)': 'CD19',
    'Deal Structure':
      'Under the terms of the agreement, ADC Therapeutics will receive an upfront payment of $30 million and up to an additional $205 million in milestones if certain development and commercial events are achieved. ADC Therapeutics will also receive royalties ranging in percentage from the high teens to the low twenties based on net sales of the product in Japan. MTPC will conduct clinical studies of ZYNLONTA in Japan and will have the right to participate in any global clinical studies of the product by bearing a portion of the costs of the study.',
    'FW Link1':
      'ADC Therapeutics Announces Exclusive License with Mitsubishi Tanabe Pharma Corporation to Develop and Commercialize ZYNLONTA (loncastuximab tesirine-lpyl) in Japan;;https://www.firstwordpharma.com/story/5481453/'
  },
  {
    'Date Announced': '18-Jan-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'GSK',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': '23andMe',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '50',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'GSK6097608',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'CD96',
    'Deal Structure':
      "GSK has elected to exercise its option to extend the exclusive target discovery period of the ongoing collaboration with 23andMe for an additional year to July 2023. 23andMe will receive a one-time payment of $50 million to extend the period. In addition, 23andMe has elected to take a royalty option on its joint immuno-oncology antibody collaboration program with GSK targeting CD96 (GSK6097608, a.k.a. GSK'608), currently in Phase 1 studies. GSK will be solely responsible for GSK'608's subsequent development in later-stage clinical trials, including full development costs moving forward.",
    'FW Link1':
      '23andMe Announces Extension of GSK Collaboration and Update on Joint Immuno-oncology Program;;https://www.firstwordpharma.com/story/5481581/'
  },
  {
    'Date Announced': '18-Jan-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Lantern Pharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'The Danish Cancer Research Center',
    'Country (Target Company/Partner)': 'Denmark',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'AI',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'LP-184',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'NER/HR deficiency',
    'Deal Structure':
      'The overall aim of the collaboration is to determine the most promising patient populations for future LP-100 (irofulven) and LP-184 therapy. The collaboration will focus on developing improved diagnostic tools to detect NER deficiency patient profiles more accurately. The research study conducted as part of the collaboration will use expanded mutational signature-based analyses to achieve this objective.',
    'FW Link1':
      'Lantern Pharma Announces Collaboration & Research Agreement with The Danish Cancer Society Research Center to Support Clinical Development of Drug Candidates, LP-100 and LP-184, in Solid Tumors;;https://www.firstwordpharma.com/story/5481679/'
  },
  {
    'Date Announced': '18-Jan-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Transgene',
    'Country (Acquiring Company/Partner)': 'France',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'PersonGen BioTherapeutics',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy, oncolytic viruses',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      "Under the terms of the collaboration agreement, Transgene will develop multiple new OV candidates, using its patented oncolytic virus backbone VVcopTK-RR- and its Invir.IO™ technology platform, specifically for IV administration in combination with PersonGen's TAA06 CAR-T injection. PersonGen will evaluate the efficacy of the combination to eliminate solid tumors in preclinical models.",
    'FW Link1':
      'Transgene and PersonGen Announce Collaboration to Evaluate a New Combination Therapy Against Solid Tumors;;https://www.firstwordpharma.com/story/5481997/'
  },
  {
    'Date Announced': '18-Jan-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Eisbach Bio',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner':
      'The Unversity of Texas MD Anderson Cancer Center',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Target screening, small molecules',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      'Under the terms of the agreement, Eisbach and MD Anderson will jointly determine the appropriate pathway for future development and possible commercialization on any therapies that show promise in laboratory studies.',
    'FW Link1':
      'Eisbach Bio and MD Anderson announce strategic collaboration to develop medicines targeting epigenetic machinery in oncology;;https://www.firstwordpharma.com/story/5482163/'
  },
  {
    'Date Announced': '18-Jan-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Qualigen Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'University College London',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'QN-302',
    'Indication(s)': 'Pancreatic cancer',
    'Target(s)': 'G4',
    'Deal Structure':
      'Qualigen Therapeutics, Inc. (Nasdaq: QLGN), a biotechnology company focused on developing treatments for adult and pediatric cancers with potential for Orphan Drug Designation, today announces the exclusive worldwide in-license of a genomic quadruplex (G4)-selective transcription inhibitor drug development program, including lead and back-up compounds, preclinical data and a patent estate, from University College London (UCL).',
    'FW Link1':
      'Qualigen Therapeutics Secures Worldwide Rights to G4-Selective Transcription Inhibitors from University College London to Develop as Cancer Therapeutics;;https://www.firstwordpharma.com/story/5482223/'
  },
  {
    'Date Announced': '20-Jan-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Alpha Tau Medical',
    'Country (Acquiring Company/Partner)': 'Israel',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner':
      'The University of Texas Health Science Center at San Antonio',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Radiopharmaceuticals, small molecules, monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Breast cancer',
    'Deal Structure':
      'Alpha Tau Medical Ltd. ("Alpha Tau"), the developer of the innovative alpha-radiation cancer therapy Alpha DaRT™, announced today that it has signed a sponsored research agreement with investigators at The University of Texas MD Anderson Cancer Center ("MD Anderson") to evaluate potential immune stimulation by combining Alpha DaRT™ with DNA-repair inhibitors and immune checkpoint inhibitors for the treatment of breast tumors.',
    'FW Link1':
      'Alpha Tau Announces New Research Collaboration to Study the Potential of Alpha DaRT to Enhance Immune Stimulation in the Treatment of Breast Cancer;;https://www.firstwordpharma.com/story/5483673/'
  },
  {
    'Date Announced': '20-Jan-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'TriSalus Life Sciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'University of Colorado Anschutz Medical Campus',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'SD-101',
    'Indication(s)': 'Liver cancer, pancreatic cancer',
    'Target(s)': 'TLR9',
    'Deal Structure':
      "TriSalus Life Sciences®, an immunotherapy company on a mission to extend and improve the lives of patients living with liver and pancreatic tumors, today announced a strategic research collaboration with the University of Colorado Anschutz Medical Campus to advance research of immuno-oncology treatments for patients with liver and pancreatic tumors. This collaboration will leverage TriSalus' immunotherapy platform, which integrates immunotherapeutics and innovative drug delivery technology to address the unique challenges of treating tumors in the liver and pancreas and improve outcomes for patients.​",
    'FW Link1':
      'TriSalus Life Sciences Announces Collaboration with the University of Colorado Anschutz Medical Campus to Advance Research on Innovative Immuno-oncology Treatments for Patients with Liver and Pancreatic Tumors;;https://www.firstwordpharma.com/story/5484299/'
  },
  {
    'Date Announced': '20-Jan-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Champions Oncology',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Fannin Innovation Studio',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody drug conjugates',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      'Under the terms of this agreement, Champions and Fannin will share equal ownership of the developed therapeutic and establish a joint venture after specific development milestones are met.',
    'FW Link1':
      'Champions Oncology Announces a Partnership with Fannin Innovation to Jointly Develop Therapeutic Raptamer Drug Conjugates;;https://www.firstwordpharma.com/story/5484381/'
  },
  {
    'Date Announced': '20-Jan-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Yingli Pharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner':
      'The University of Texas Health Science Center at San Antonio',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'linperlisib',
    'Indication(s)': 'Solid tumours, lymphomas',
    'Target(s)': 'PI3Kδ',
    'Deal Structure':
      'Yingli Pharma US, Inc. (the "Company" or "Yingli"), a biotechnology company developing innovative small molecules, and The University of Texas MD Anderson Cancer Center today announced a strategic alliance to advance several oncology programs from preclinical discovery through clinical development.',
    'FW Link1':
      'Yingli Pharma and MD Anderson Initiate Strategic Collaboration to Advance Development of Multiple Oncology Programs;;https://www.firstwordpharma.com/story/5484477/'
  },
  {
    'Date Announced': '24-Jan-2022',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'RadNet',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Aidance',
    'Country (Target Company/Partner)': 'Netherlands',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'AI',
    'Lead Asset Stage/Phase': 'Marketed',
    'Deal Structure':
      'Founded in 2015 and based in Amsterdam, Netherlands, Aidence is developing and deploying AI clinical applications to empower interpreting medical images and improving patient outcomes. Aidence’s first commercialized product, Veye Lung Nodules, is an AI-based solution for lung nodule detection and management. This product is CE marked in Europe, where it has a leading position for lung cancer AI screening tools. Aidence’s solution analyzes thousands of CT scans each week, with customers in seven European countries including France, the Netherlands and the United Kingdom. Aidence’s Veye solution was submitted in December for FDA 510(k) clearance in the United States. Upon successful clearance, Aidence’s solution would be available for use in the United States.',
    'FW Link1':
      'RadNet to offer prostate, lung cancer screening with two AI deals;;https://www.firstwordhealthtech.com/story/5485509/'
  },
  {
    'Date Announced': '24-Jan-2022',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'RadNet',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Quantib',
    'Country (Target Company/Partner)': 'Netherlands',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'AI',
    'Lead Asset Stage/Phase': 'Marketed',
    'Deal Structure':
      'Founded in 2012 and based in Rotterdam, Netherlands, Quantib has multiple AI-based solutions with both CE mark and FDA 510(k) clearance, including Quantib Prostate for analysis of prostate MR images and Quantib Brain and Quantib ND to quantify brain abnormalities on MRI. Quantib has customers in more than 20 countries worldwide, including the United States',
    'FW Link1':
      'RadNet to offer prostate, lung cancer screening with two AI deals;;https://www.firstwordhealthtech.com/story/5485509/'
  },
  {
    'Date Announced': '25-Jan-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': '48Hour Discovery',
    'Country (Acquiring Company/Partner)': 'Canada',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Fusion Pharmaceuticals',
    'Country (Target Company/Partner)': 'Canada',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Radiopharmaceuticals',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      "48Hour Discovery Inc. (48HD), a biopharmaceutical company specializing in peptide-derived drug discovery announced today that the company has entered into a strategic research collaboration with Fusion Pharmaceuticals. This is 48HD's first collaboration with a Canadian company. The goal of the collaboration is to discover novel, peptide-based radiopharmaceuticals for the treatment of various solid tumours.",
    'FW Link1':
      '48Hour Discovery and Fusion Pharmaceuticals to Develop Radiopharmaceuticals for the Treatment of Cancer Tumours;;https://www.firstwordpharma.com/story/5486325/'
  },
  {
    'Date Announced': '25-Jan-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Inmune Bio',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Chinese University of Hong Kong',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Head and neck cancer',
    'Target(s)': 'IL-2, IL-15',
    'Deal Structure':
      'INmune Bio, Inc. (NASDAQ: INMB) ("INMB" or "the Company"), a clinical-stage immunology company focused on developing treatments that harness the patient\'s innate immune system to fight disease, today announced that the company has entered into a pre-clinical research collaboration with Chinese University of Hong Kong (CUHK) to evaluate INKmune™ -- the company\'s pseudokine NK cell priming platform -- in nasopharyngeal cancer (NPC), a type of head and neck cancer.',
    'FW Link1':
      'INmune Bio, Inc. Announces Research Collaboration with Chinese University of Hong Kong to Evaluate INKmune in Nasopharyngeal Cancer;;https://www.firstwordpharma.com/story/5486357/'
  },
  {
    'Date Announced': '25-Jan-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'SIGA Technologies',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Bioarchitech',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules, oncolytic viruses',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'tecovirimat',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      "The research collaboration will investigate TPOXX® (tecovirimat) in combination with Bioarchitech's proprietary vaccinia-based immunotherapy platform in preclinical studies. This platform utilizes engineered antibodies and other proteins expressed within the genome of an oncolytic virus.",
    'FW Link1':
      'SIGA Announces Preclinical Oncology Research Collaboration with Bioarchitech;;https://www.firstwordpharma.com/story/5486485/'
  },
  {
    'Date Announced': '26-Jan-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'DongCheng Pharmaceutical',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'ImaginAb',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'zirconium Zr 89 crefmirlimab berdoxam',
    'Deal Structure':
      'On signing of a definitive agreement, ImaginAb will receive a license fee and will be entitled to receive potential milestone payments and up to double digit royalties for the CD8 ImmunoPET License and Commercial Partnership for Greater China Market',
    'FW Link1':
      'ImaginAb Inc. and DongCheng Pharmaceutical Group Announce Plans for a CD8 ImmunoPET Commercial Partnership;;https://www.firstwordpharma.com/story/5487043/'
  },
  {
    'Date Announced': '26-Jan-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Xiamen Biotime Biotechnology',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Adlai Nortye',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global/China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'AN4005, AN3025',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      'According to the terms of the agreement, the total amount of this cooperation will reach hundreds of millions of yuan, including the upfront payments, progress-dependent milestone payments and tiered royalties on sales.',
    'FW Link1':
      'Adlai Nortye Announces Global License-out Agreement with Biotime for Several Products Including PD-L1 Inhibitor (AN4005) and Anti-hTNFR2 Antibody (AN3025);;https://www.firstwordpharma.com/story/5487151/'
  },
  {
    'Date Announced': '26-Jan-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Hillstream BioPharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Massachusetts Chan Medical School',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Gene editing',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Solid tumours',
    'FW Link1':
      'Hillstream BioPharma and University of Massachusetts Chan Medical School Announce Research Collaboration using CRISPR and Computational Approaches to Advance The Hillstream Ferroptomic Atlas;;https://www.firstwordpharma.com/story/5487443/'
  },
  {
    'Date Announced': '26-Jan-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Ipsen',
    'Country (Acquiring Company/Partner)': 'France',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Domainex',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'FLIP',
    'FW Link1':
      'Queen’s University Belfast enters collaboration and licensing agreement with Ipsen to progress FLIP inhibitor project which has been supported by Domainex;;https://www.firstwordpharma.com/story/5487669/'
  },
  {
    'Date Announced': '26-Jan-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Servier',
    'Country (Acquiring Company/Partner)': 'France',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Epigene Labs',
    'Country (Target Company/Partner)': 'France',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'AI, target screening',
    'Lead Asset Stage/Phase': 'Preclinical',
    'FW Link1':
      'Servier partners with French start-up Epigene Labs to launch a new application in the field of oncology;;https://www.firstwordpharma.com/story/5487895/'
  },
  {
    'Date Announced': '31-Jan-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Leap Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Leica Biosystems',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Companion diagnostic',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'DKN-01',
    'Indication(s)': 'Gastric cancer',
    'Target(s)': 'DKK1',
    'Deal Structure':
      "Leica Biosystems, a cancer diagnostics company, has entered into an agreement with Leap Therapeutics (NASDAQ: LPTX), a biotechnology company, to develop a companion diagnostic to detect Dickkopf-related protein 1 (DKK1). The assay will be used to support clinical development of Leap Therapeutics' anti-DKK1 cancer therapy, DKN-01, currently being studied in clinical trials.",
    'FW Link1':
      'Leica Biosystems and Leap Therapeutics Partner on Companion Diagnostic to Advance Care for Cancer Patients;;https://www.firstwordpharma.com/story/5490023/'
  },
  {
    'Date Announced': '1-Feb-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Biogen',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Roche',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Upfront Value ($m)': '30',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Bispecific antibodies',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'Mosunetuzumab',
    'Indication(s)': 'Lymphomas',
    'Target(s)': 'CD20, CD3',
    'Deal Structure':
      'As a part of the option exercise, Biogen will pay a $30 million one-time option fee to Genentech and will pay for a portion of the mosunetuzumab development expenses incurred during 2021. Biogen will have joint decision-making rights related to development and commercialization of mosunetuzumab and Genentech will continue to lead the strategy and implementation of the program. Biogen will share in the operating profits and losses of mosunetuzumab in United States in the low to mid 30% range and is eligible to receive low single-digit royalties on sales outside the United States.',
    'FW Link1':
      "Biogen opts in on Roche's bispecific antibody mosunetuzumab;;https://www.firstwordpharma.com/story/5490537/"
  },
  {
    'Date Announced': '1-Feb-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'NexImmune',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Rutgers, The State University of New Jersey',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Neuroendocrine tumours',
    'Target(s)': 'HHLA2, B7x',
    'FW Link1':
      'NexImmune Announces Research Collaboration with Rutgers, The State University of New Jersey, Related to Neuroendocrine Tumor Targets;;https://www.firstwordpharma.com/story/5490857/'
  },
  {
    'Date Announced': '1-Feb-2022',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Becton, Dickinson and Company',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Cytognos',
    'Country (Target Company/Partner)': 'Spain',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'Lead Asset Stage/Phase': 'Marketed',
    'FW Link1':
      'BD Expands from Cancer Discovery and Diagnosis into Post-Treatment Monitoring with Acquisition of Cytognos from Vitro S.A.;;https://www.firstwordhealthtech.com/story/5490889/'
  },
  {
    'Date Announced': '1-Feb-2022',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'Debiopharm',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Aspen',
    'Country (Target Company/Partner)': 'South Africa',
    'Region (Target Company/Partner)': 'Africa',
    'Geographic Remit of Deal': 'South Africa',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Proteins',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Trelstar',
    'Indication(s)': 'Prostate cancer',
    'FW Link1':
      'DEBIOPHARM AND ASPEN PARTNER TO LAUNCH PROSTATE CANCER DRUG TRELSTAR IN SOUTH AFRICA;;https://www.firstwordpharma.com/story/5491069/'
  },
  {
    'Date Announced': '1-Feb-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Brainomix',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Boehringer Ingelheim',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'AI, clinical trial design',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Deal Structure':
      'The collaboration will focus on the development of AI tools to improve the accuracy and standardization of tumor measurement in lung cancer trials',
    'FW Link1':
      'Brainomix, Boehringer partner on AI radiomic tool to assess solid tumours;;https://www.firstwordhealthtech.com/story/5491691/'
  },
  {
    'Date Announced': '3-Feb-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'J&J',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Mersana Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '40',
    'Total Deal Value ($m)': '1,040',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody drug conjugates',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Deal Structure':
      'Mersana will collaborate with Janssen on target candidates during preclinical development, with Janssen being solely responsible for clinical development and commercialization. Mersana will receive an upfront payment of $40 million. Mersana is eligible to receive reimbursement of certain costs as well as more than $1 billion in potential milestone payments, plus mid-single-digit to low double-digit percentage royalties on worldwide net sales of ADCs against the selected targets.',
    'FW Link1':
      'J&J inks antibody-drug conjugate deal with Mersana;;https://www.firstwordpharma.com/story/5492393/'
  },
  {
    'Date Announced': '3-Feb-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Amgen',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Plexium',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Total Deal Value ($m)': '500',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules, protein degraders',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Deal Structure':
      'Under the terms of the agreement, the collaboration will initially focus on two programs with Amgen holding options to add additional programs. Plexium is eligible to receive over $500 million in success-based target access, pre-clinical, clinical, regulatory and commercial milestones, as well as tiered single-digit royalty payments, if all options are exercised. Amgen has a commercial license to each program that advances to a predefined preclinical stage of development and will be responsible for global development and commercialization.',
    'FW Link1':
      'Amgen and Plexium Announce Multi-Year, Drug Discovery Collaboration to Identify Novel Targeted Protein Degradation Therapies;;https://www.firstwordpharma.com/story/5492403/'
  },
  {
    'Date Announced': '3-Feb-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'MacroGenics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Synaffix',
    'Country (Target Company/Partner)': 'Netherlands',
    'Region (Target Company/Partner)': 'Europe',
    'Total Deal Value ($m)': '586',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody drug conjugates',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Deal Structure':
      "Under the terms of the agreement, Synaffix will be eligible to receive up to $586 million in payments spanning upfront and milestone payments across three programs plus royalties on commercial sales. The license rights of MacroGenics for each program will be designated as exclusive or non-exclusive to each program's antibody target (or to the combination of two targets, if bispecific). The first program will commence at signature, with the option to expand the collaboration to a further two programs by March 2023.",
    'FW Link1':
      'Synaffix Inks Deal with MacroGenics to Enable Next Generation ADCs;;https://www.firstwordpharma.com/story/5492915/'
  },
  {
    'Date Announced': '3-Feb-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'LISCure Biosciences',
    'Country (Acquiring Company/Partner)': 'Korea, South',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'The Scripps Research Institute',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Target(s)': 'LB-P2D',
    'FW Link1':
      'LISCure Biosciences Announces Research Collaboration with The Scripps Research Institute for Immuno-Oncology;;https://www.firstwordpharma.com/story/5493099/'
  },
  {
    'Date Announced': '7-Feb-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Varsity Pharmaceuticals',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Dana-Faber Cancer Institute',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'DNA damage repair',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'Novobiocin',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'Hormone deficiency',
    'FW Link1':
      'Varsity Pharmaceuticals Licenses Pol-Theta Inhibitor;;https://www.firstwordpharma.com/story/5494029/'
  },
  {
    'Date Announced': '7-Feb-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Bridge Biotherapeutics',
    'Country (Acquiring Company/Partner)': 'Korea, South',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'The Scripps Research Institute',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Target screening',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Deal Structure':
      'Under the terms of the multi-year collaboration agreement, Scripps will conduct research and development activities, and Bridge Biotherapeutics will retain an exclusive option to acquire worldwide license and patent rights from the research program to further develop and commercialize the technology.',
    'FW Link1':
      'Bridge Biotherapeutics and Scripps Research Launch Research Collaboration to Develop Discovery Platform and Novel Covalent Probes Based on Chemical Biology and Chemoproteomics;;https://www.firstwordpharma.com/story/5494711/'
  },
  {
    'Date Announced': '8-Feb-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'EdiGene',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Arbor Biosciences',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Gene editing',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Deal Structure':
      'Under the agreement, EdiGene will pay Arbor certain upfront, milestone payments, commercial payments upon the achievement of certain development and sales milestones, and tiered royalties on net sales of royalty-bearing EdiGene products.',
    'FW Link1':
      'EdiGene and Arbor Biotechnologies Announce Worldwide Non-exclusive License Agreement to Advance Ex Vivo Engineered Cell Therapy Programs;;https://www.firstwordpharma.com/story/5495201/'
  },
  {
    'Date Announced': '8-Feb-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'SEngine Precision Medicine',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Oncodesign',
    'Country (Target Company/Partner)': 'France',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Deal Structure':
      'SEngine and Oncodesign have initiated a joint research collaboration to evaluate the feasibility to convert the already identified Nanocyclix® inhibitor series into drug candidates that are likely to be effective in the clinic. During this initial phase, both companies will combine their respective technology platforms to advance the program. SEngine will provide research funding to Oncodesign for the initial optimization of the compound series up to a predefined level. It is anticipated that this initial phase will be followed by a larger research collaboration with an option to license at a more advanced stage.',
    'FW Link1':
      'SEngine Precision Medicine and Oncodesign Announce Collaboration Agreement for the Discovery of New Personalized Cancer Treatment for Aggressive and Untreatable Tumors;;https://www.firstwordhealthtech.com/story/5495681/'
  },
  {
    'Date Announced': '9-Feb-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Neukio Biotherapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'EdiGene',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Deal Structure':
      'Under the agreement, Neukio will develop cell therapies in certain undisclosed indications and pay EdiGene milestone payments for clinical development upon its clinical progress, and royalties after its commercialization.',
    'FW Link1':
      'EdiGene and Neukio Enter Collaboration to Develop Next-Generation Immune Cell Therapies;;https://www.firstwordpharma.com/story/5495927/'
  },
  {
    'Date Announced': '9-Feb-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Foundation Medicine',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Eli Lilly',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'Target(s)': 'RET',
    'FW Link1':
      'Foundation Medicine Announces Global Collaboration With Lilly to Develop FoundationOne CDx and FoundationOne Liquid CDx as Companion Diagnostics for RETEVMO and Loxo Oncology at Lilly’s Pipeline Programs;;https://www.firstwordpharma.com/story/5496031/'
  },
  {
    'Date Announced': '9-Feb-2022',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Imagia Cybernetics',
    'Country (Acquiring Company/Partner)': 'Canada',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Canexia Health',
    'Country (Target Company/Partner)': 'Canada',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'AI, real world data',
    'Deal Structure':
      "The merger includes C$20 million funding with participation from: BDC Capital's Women in Technology Venture Fund, Desjardins Capital, and PacBridge Capital.",
    'FW Link1':
      'Imagia Cybernetics & Canexia Health Merge to Supercharge Precision Oncology Accessibility;;https://www.firstwordhealthtech.com/story/5496215/'
  },
  {
    'Date Announced': '9-Feb-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Huadong Medicine',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'AKSO Biopharmaceutical',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Bispecific antibodies',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'AB002',
    'Target(s)': 'PD-1, IL-15',
    'Deal Structure':
      "Under the terms of the agreement, AKSO will be eligible to receive upfront and milestone payments of up to $75 million, as well as royalties from future product sales. Huadong Medicine will be responsible for clinical development, regulatory submissions and commercialization of AB002 in the region and jointly steer IND-enabling tasks with AKSO via the Joint Research Committee. AKSO will continue to be responsible for AB002's development in the rest of the world.",
    'FW Link1':
      'AKSO and Huadong Medicine Announce Strategic Collaboration to Develop and Commercialize AB002 in Asia Pacific Ex-Japan;;https://www.firstwordpharma.com/story/5496363/'
  },
  {
    'Date Announced': '10-Feb-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Hutchmed',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Genetron',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Companion diagnostic',
    'Lead Asset(s)': 'Orpathys',
    'Indication(s)': 'NSCLC',
    'Target(s)': 'MET',
    'FW Link1':
      'Genetron Health Announces Companion Diagnostic Collaboration Agreement with HUTCHMED’s ORPATHYS for NSCLC;;https://www.firstwordpharma.com/story/5496927/'
  },
  {
    'Date Announced': '15-Feb-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Adcentrx Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'AvantGen',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody drug conjugates, drug screening',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Deal Structure':
      'Under the terms of the collaboration, Adcentrx will specify targets against which AvantGen will screen for novel antibodies using its yeast display system. Adcentrx will be responsible for engineering the antibodies into ADC therapeutic candidates and has worldwide development and commercialization rights. AvantGen will be eligible to receive milestone payments for achievement of certain development milestones.',
    'FW Link1':
      'Adcentrx and AvantGen Enter a New Partnership with a Three-year, Multi-target Collaboration to Discover Antibodies for Novel Antibody-drug Conjugates;;https://www.firstwordpharma.com/story/5499379/'
  },
  {
    'Date Announced': '15-Feb-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Eli Lilly',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'ImmunoGen',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '13',
    'Total Deal Value ($m)': '1,700',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody drug conjugates',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Deal Structure':
      'As part of the agreement, Lilly will pay ImmunoGen an upfront payment of $13 million, reflecting initial targets selected by Lilly. Lilly may select a pre-specified number of additional targets, with ImmunoGen eligible to receive an additional $32.5 million in exercise fees if Lilly licenses the full number of targets. ImmunoGen is eligible to receive up to $1.7 billion in potential target program exercise fees and milestone payments based on the achievement of pre-specified development, regulatory, and commercial milestones. ImmunoGen is also eligible for tiered royalties as a percentage of worldwide commercial sales by Lilly. Lilly is responsible for all costs associated with research and development.',
    'FW Link1':
      'Lilly, ImmunoGen enter antibody-drug conjugate development deal;;https://www.firstwordpharma.com/story/5499397/'
  },
  {
    'Date Announced': '15-Feb-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'ONK Therapeutics',
    'Country (Acquiring Company/Partner)': 'Ireland {Republic}',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Intellia Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Gene editing',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Deal Structure':
      "The agreement grants ONK a non-exclusive license to Intellia's proprietary ex vivo CRISPR/Cas9-based genome editing platform and its lipid nanoparticle (LNP)-based delivery technologies to develop up to five allogeneic NK cell therapies. ONK will receive exclusive rights to certain Intellia guide RNAs (gRNAs) resulting from the collaboration for use in engineering those NK cell products. ONK will be responsible for preclinical and clinical development for the engineered NK cell therapies covered under the agreement. Intellia will be eligible to receive up to $184 million per product in development and commercial milestone payments, as well as up to mid-single digit royalties on potential future sales. In addition, the agreement grants Intellia options to co-develop and co-commercialize up to two products worldwide with rights to lead commercialization in the U.S. ONK retains lead commercialization rights ex-U.S. This co-development and co-commercialization option excludes ONK's lead product ONKT-102, which is being developed for the treatment of patients with relapsed / refractory multiple myeloma, for which ONK retains sole rights. If Intellia chooses to exercise the co-development and co-commercialization option on an investigational product, in lieu of the potential royalties and milestones, Intellia will share 50 percent of any future profit and loss generated by the product.",
    'FW Link1':
      'Intellia and ONK Therapeutics Announce Collaboration to Advance Allogeneic CRISPR-Edited NK Cell Therapies for the Treatment of Patients with Cancer;;https://www.firstwordpharma.com/story/5499603/'
  },
  {
    'Date Announced': '15-Feb-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Lantern Pharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner':
      "The Greehey Children's Cancer Research Institute (GCCRI) at University of Texas Health Science Center-San Antonio",
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'LP184',
    'Indication(s)': 'Paediatric cancers',
    'FW Link1':
      "Lantern Pharma Expands into Additional Pediatric Cancers Through a Collaboration with The Greehey Children's Cancer Research Institute (GCCRI) at University of Texas Health Science Center-San Antonio;;https://www.firstwordpharma.com/story/5499871/"
  },
  {
    'Date Announced': '15-Feb-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Champions Oncology',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'GigaMune',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Deal Structure':
      'Under the terms of the agreement, Champions will have the right to select a specified number of TCRs from the library for further, wholly owned, development. The remaining libraries will be jointly owned by Champions and GigaMune for commercialization, with commercial efforts led by Champions.',
    'FW Link1':
      'Champions Oncology Announces a Partnership with GigaMune to Discover Novel Tumor-Reactive T Cell Receptors;;https://www.firstwordpharma.com/story/5499959/'
  },
  {
    'Date Announced': '16-Feb-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Merck & Co.',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Curve Therapeutics',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Total Deal Value ($m)': '1,700',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Deal Structure':
      'Under the agreement, Curve will perform high throughput mammalian cell-based functional screening, hit characterisation, data-mining and analysis, and Microcycle® optimisation. MSD will be responsible for lead optimisation, clinical development, manufacturing and commercialisation of compounds identified through the collaboration. Under the terms of the agreement Curve will receive an upfront payment, and will be eligible to receive research, development and commercial milestones totalling up to US$ 1.7B should all five therapeutic programs succeed. Curve will also receive a royalty on net sales of any approved products resulting from the alliance.',
    'FW Link1':
      'Merck & Co. inks drug-development deal with Curve Therapeutics;;https://www.firstwordpharma.com/story/5500257/'
  },
  {
    'Date Announced': '16-Feb-2022',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'Global Medical Solutions Australia',
    'Country (Acquiring Company/Partner)': 'Australia',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Telix Pharmaceuticals',
    'Country (Target Company/Partner)': 'Australia',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Australia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Imaging',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Illuccix',
    'Indication(s)': 'Prostate cancer',
    'Target(s)': 'PET',
    'Deal Structure':
      'The initial term of the Agreement is for a period of five years. The agreement is subject to industry-standard commercial performance and termination conditions.',
    'FW Link1':
      'Telix Enters Commercial Distribution Agreement for Illuccix in Australia;;https://www.firstwordpharma.com/story/5500267/'
  },
  {
    'Date Announced': '16-Feb-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'ImmunoScape',
    'Country (Acquiring Company/Partner)': 'Singapore',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Pure MHC',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy',
    'Lead Asset Stage/Phase': 'Preclinical',
    'FW Link1':
      'ImmunoScape Inks Licensing Deal with Pure MHC to Advance Development of Novel TCR-Based Therapies;;https://www.firstwordpharma.com/story/5500307/'
  },
  {
    'Date Announced': '16-Feb-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Vanderbilt University Medical Center',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Biofourmis',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'United States',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'AI, patient monitoring',
    'FW Link1':
      'Biofourmis Selected by Vanderbilt University Medical Center for CAR T-Cell Therapy Study Utilizing Tech-Enabled Remote Patient Monitoring;;https://www.firstwordpharma.com/story/5500537/'
  },
  {
    'Date Announced': '16-Feb-2022',
    'Deal type': 'Alliance',
    'Acquiring Company/Partner': 'Paige',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Mindpeak',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'AI, diagnostics',
    'FW Link1':
      'Paige and Mindpeak Announce Partnership to Expand Access to Industry-Leading AI Software for Breast Cancer;;https://www.firstwordhealthtech.com/story/5500891/'
  },
  {
    'Date Announced': '17-Feb-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'J&J',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Remix Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '45',
    'Total Deal Value ($m)': '1,000',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules, RNA',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Deal Structure':
      'Under the terms of the agreement, Remix will receive an initial payment of $45 million in cash for upfront and research funding, and may also receive preclinical, clinical, commercial, and sales milestone payments and tiered royalties for any resulting products. In exchange, Janssen will have exclusive rights to three specific targets with applications in immunology and oncology. Remix will have the ability to opt into a portion of the costs of clinical development on one program in exchange for higher royalties. Under the agreement, Remix is eligible to receive total payments potentially exceeding $1 billion, subject to regulatory approvals and other conditions.',
    'FW Link1':
      'J&J signs RNA drug development deal with Remix Therapeutics;;https://www.firstwordpharma.com/story/5501147/'
  },
  {
    'Date Announced': '21-Feb-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'BioNTech',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Medigene',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Upfront Value ($m)': '30',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'PRAME',
    'Deal Structure':
      'Under the terms of the agreement, Medigene will receive EUR 26 million upfront, as well as research funding for the period of the collaboration. BioNTech will be responsible for global development and hold exclusive worldwide commercialization rights on all TCR therapies resulting from this research collaboration. Medigene will be eligible to receive development, regulatory and commercial milestone payments up to a triple digit million EUR amount per program in addition to tiered deferred option payments on global net sales for products based on TCRs arising from the collaboration and royalties on products utilizing at least one of the licensed technologies.',
    'FW Link1':
      'BioNTech and Medigene Announce Global Collaboration to Advance T Cell Receptor Immunotherapies Against Cancer;;https://www.firstwordpharma.com/story/5502931/'
  },
  {
    'Date Announced': '22-Feb-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Odeon Therapeutics',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'OBI Pharma',
    'Country (Target Company/Partner)': 'Taiwan',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '12',
    'Total Deal Value ($m)': '200',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody drug conjugate',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'OBI-999, OBI-833',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'Globo-H',
    'Deal Structure':
      'Under the terms of the agreement, OBI will grant Odeon exclusive rights to develop, register and commercialize OBI-999 and OBI-833 for human cancer therapy in China, Hong Kong and Macao. Odeon will issue to OBI fully paid equity equivalent to US$ 12 million upon signing. OBI is eligible for development and commercialization milestones totaling up to US$ 188 million. The company is also eligible to receive tiered double-digit royalties on net sales. Odeon will also be responsible for all development costs and all subsequent regulatory and commercialization costs of OBI-999 and OBI-833 in China, Hong Kong and Macao.',
    'FW Link1':
      'OBI Pharma Enters License Agreement with Odeon Therapeutics for the Rights of OBI-999 and OBI-833 in China, Hong Kong and Macau;;https://www.firstwordpharma.com/story/5504453/'
  },
  {
    'Date Announced': '22-Feb-2022',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Panbela Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Cancer Prevention Pharmaceuticals',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'Flynpovi',
    'Indication(s)': 'Colon cancer',
    'Deal Structure':
      "Under the terms of the agreement and plan of merger, the holders of CPP's outstanding capital stock immediately prior to the merger will receive shares of common stock of Panbela upon closing of the mergers. On a pro forma and fully diluted basis, holders of Panbela common stock are expected to own approximately 59% of the post-merger holding company and holders of CPP securities, including converted indebtedness, are expected to beneficially own approximately 41% of post-merger holding company. CPP stockholders will be eligible to receive contingent payments totaling a maximum of $60 million from milestone and royalty payments associated with the potential approval and commercialization of the lead asset.",
    'FW Link1':
      'Panbela Therapeutics, Inc. to Acquire Cancer Prevention Pharmaceuticals, Inc.;;https://www.firstwordpharma.com/story/5506079/',
    'FW Link2':
      'Closing of acquisition announced June 16, 2022;;https://firstwordpharma.com/story/5596613/'
  },
  {
    'Date Announced': '24-Feb-2022',
    'Deal type': 'Alliance',
    'Acquiring Company/Partner': 'Voluntis',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Tilak Healthcare',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Screening',
    'FW Link1':
      'Voluntis Announces Partnership with Tilak Healthcare to Help Patients with Cancer Better Manage Ocular Toxicities;;https://www.firstwordhealthtech.com/story/5511967/'
  },
  {
    'Date Announced': '28-Feb-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Huadong Medicine',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Heidelberg Pharma',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Upfront Value ($m)': '20',
    'Total Deal Value ($m)': '930',
    'Geographic Remit of Deal': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody drug conjugates',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'HDP-101',
    'Indication(s)': 'Multiple myeloma',
    'Target(s)': 'BCMA',
    'Deal Structure':
      'Under the deal, Heidelberg has granted Huadong exclusive rights to HDP-101 and HDP-103 for $20 million upfront plus milestones of up to €400 million ($449 million), as well as tiered royalties ranging from single- to low-double-digit percentages for each candidate. On its website, Heidelberg says HDP-101 has shown "strong" in vitro anti-tumour activity, and has led to complete tumour remission in mouse models for multiple myeloma "even at very low doses." Meanwhile, Huadong also gets an exclusive option for a pair of preclinical ATAC assets. These include HDP-102, which is directed against CD37 for non-Hodgkin lymphoma, and HDP-104, whose target has not been disclosed yet. The total deal value for these is worth up to €410 million ($460 million), plus tiered royalties ranging from single- to low-double-digit percentages for each. The Chinese drugmaker has a right of first negotiation to license the next two ATAC candidates for Asia as well.',
    'FW Link1':
      'Heidelberg targets Asia via ADC deal with Huadong;;https://www.firstwordpharma.com/story/5513965/'
  },
  {
    'Date Announced': '28-Feb-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Blueprint Medicines',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Proteovant Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '20',
    'Total Deal Value ($m)': '652',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'AI, screening, protein degraders',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Deal Structure':
      'Subject to the terms of the agreement, Proteovant will receive a $20 million upfront payment and will be eligible to receive up to an additional $632 million in potential research, development, regulatory and commercialization milestone payments plus tiered royalties from mid- to high-single digits on net sales on the first two program targets, subject to adjustment in specified circumstances. Of the total contingent payments, up to $105 million would be preclinical, clinical development and regulatory milestones and up to $527 million would be approval and sales milestones. Each company will be responsible for its own costs under the research plan. Should Proteovant opt in to the second program, the parties will split profits and losses of that program equally in the U.S. along with development costs and the milestone payments for the program will be reduced accordingly. Proteovant will be eligible to receive milestone payments and royalties on ex-U.S. sales. In addition, the partners may jointly extend the collaboration, with the same structure and financial terms, to two additional program targets through additional funding by Blueprint Medicines.',
    'FW Link1':
      'Blueprint Medicines and Proteovant Therapeutics Announce Strategic Collaboration to Advance Novel Targeted Protein Degrader Therapies;;https://www.firstwordhealthtech.com/story/5514375/'
  },
  {
    'Date Announced': '4-Jan-2021',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'AstraZeneca',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Luye Pharma',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Lipusu',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'The two companies will discuss how to integrate their advantages to further expand the coverage of Lipusu in both broader markets and segment markets. They will also consider the possibility of conducting collaborative clinical research on Lipusu.',
    'FW Link1':
      'Luye Pharma and AstraZeneca Join Forces Again to Explore In-depth Collaboration in the Cardiovascular and Oncology Fields;;https://firstwordpharma.com/story/5191021/'
  },
  {
    'Date Announced': '5-Jan-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'J&J',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Revitope Oncology',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Bispecific antibodies',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Revitope will collaborate with Janssen to conduct a feasibility study in the evaluation of Revitope's PrecisionGATE T cell engager platform.",
    'FW Link1':
      'Revitope Oncology Announces Strategic Collaboration with Janssen to Leverage its Unique T Cell Engager Platform;;https://firstwordpharma.com/story/5191989/'
  },
  {
    'Date Announced': '21-Dec-2021',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Sanofi',
    'Country (Acquiring Company/Partner)': 'France',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Amunix Pharmaceuticals',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '1,000',
    'Total Deal Value ($m)': '1,225',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'T cell engagers, cytokine therapies',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'AMX-818',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'HER2',
    'Deal Structure':
      'Sanofi will acquire Amunix for an upfront payment of approximately $1 billion and up to $225 million upon achievement of certain future development milestones',
    'FW Link1':
      'Sanofi bulks up immuno-oncology pipeline with Amunix buy;;https://firstwordpharma.com/story/5466265'
  },
  {
    'Date Announced': '20-Dec-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Novartis',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'BeiGene',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '300',
    'Total Deal Value ($m)': '2,895',
    'Geographic Remit of Deal': 'North America, Europe, Japan',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'TIGIT inhibitor',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'ociperlimab',
    'Indication(s)': 'Non-small-cell lung cancer',
    'Target(s)': 'TIGIT',
    'Deal Structure':
      'BeiGene will receive an upfront cash payment of $300 million along with an additional payment of $600 or $700 million upon exercise by Novartis of an exclusive time-based option prior to mid-2023 or between then and late-2023, subject to receipt of required antitrust approval. In addition, following option exercise, BeiGene is eligible to receive up to $745 million upon the achievement of regulatory approval milestones, $1.15 billion upon the achievement of sales milestones, and royalties on future sales of ociperlimab in the licensed territory.',
    'FW Link1':
      'Novartis adds TIGIT inhibitor to pipeline via BeiGene deal;;https://firstwordpharma.com/story/5465437/',
    'FW Link2':
      'Termination of agreement announced July 10, 2023;;https://firstwordpharma.com/story/5759783/'
  },
  {
    'Date Announced': '14-Dec-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'BMS',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Immatics',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Upfront Value ($m)': '150',
    'Total Deal Value ($m)': '920',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'TCR bispecific',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'IMA401',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'MAGEA4/8',
    'Deal Structure':
      'Immatics will receive an upfront payment of $150 million as well as up to $770 million in development, regulatory and commercial milestone payments, in addition to tiered double-digit royalty payments on net sales of IMA401. Immatics retains the options to co-fund US development in exchange for enhanced US royalty payments and/or to co-promote IMA401 in the US.',
    'FW Link1':
      'BMS inks deal with Immatics on cancer bispecific;;https://firstwordpharma.com/story/5461593/'
  },
  {
    'Date Announced': '23-Jul-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Hanmi Pharmaceutical',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'LegoChem Biosciences',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Cancer',
    'FW Link1':
      'Hanmi Pharm, LegoChem partner to develop bispecific antibody-based ADC;;https://firstwordpharma.com/story/5357673/'
  },
  {
    'Date Announced': '27-Jul-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'AbbVie',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Alphabet',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '500',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'PTPN2 inhibitor',
    'Lead Asset Stage/Phase': 'Phase I',
    'Indication(s)': 'Cancer',
    'Target(s)': 'PTPN2',
    'Deal Structure':
      'Calico will be responsible for research and early development until 2025, with support from AbbVie, and will advance collaboration projects into Phase IIa through 2030. Once mid-stage studies are completed, AbbVie will have the option to manage Phase III development and commercial activities. Both parties will split costs and profits equally.',
    'FW Link1':
      'AbbVie, Calico commit another $1 billion to extend partnership in age-related diseases;;https://firstwordpharma.com/story/5359307/'
  },
  {
    'Date Announced': '18-Nov-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Sanofi',
    'Country (Acquiring Company/Partner)': 'France',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Owkin',
    'Country (Target Company/Partner)': 'France',
    'Region (Target Company/Partner)': 'Europe',
    'Upfront Value ($m)': '90',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'AI',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Sanofi will invest $180 million in Owkin, with the drugmaker using the latter's AI platform to find new biomarkers and therapeutic targets, building prognostic models, and predicting response to treatment from multimodal patient data.",
    'FW Link1':
      "Sanofi taps Owkin's AI platform for cancer drug development;;https://www.firstwordhealthtech.com/story/5444913/"
  },
  {
    'Date Announced': '14-Dec-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Novartis',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Molecular Partners',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Upfront Value ($m)': '20',
    'Total Deal Value ($m)': '580',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Radioligand therapies',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Cancer',
    'Target(s)': 'Tumour-associated antigens',
    'Deal Structure':
      'Novartis will pay $20 million upfront to Molecular Partners, with the drugmaker also eligible for milestones totalling as much as $560 million and up to a low-double-digit percent of royalties. The companies will collaborate to discover DARPin-conjugated radioligand therapies that target specific tumour-associated antigens. Novartis would be responsible for all clinical development and commercialisation activities.',
    'FW Link1':
      'Novartis, Molecular Partners to develop DARPin-conjugated radioligand therapies for cancer;; https://firstwordpharma.com/story/5461537/'
  },
  {
    'Date Announced': '13-Dec-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Eli Lilly',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Foghorn Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '300',
    'Total Deal Value ($m)': '1,300',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'BRM inhibitor',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Cancer',
    'Target(s)': 'BRG1',
    'Deal Structure':
      'For the BRM programme, Foghorn will handle discovery and early research, while Eli Lilly will lead development and commercialisation efforts, with participation from Foghorn on operational activities and cost sharing. The drugmakers will equally share in the US economics, with Foghorn eligible for royalties on ex-US sales starting in the low-double-digit range and escalating into the twenties based on revenue levels. Eli Lilly will also make an equity investment of $80 million in Foghorn.',
    'FW Link1':
      'Lilly pays $300 million upfront under Foghorn cancer drug deal;;https://firstwordpharma.com/story/5460513/'
  },
  {
    'Date Announced': '5-Jan-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Bracco',
    'Country (Acquiring Company/Partner)': 'Italy',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Scintomics',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'rhPSMA technology',
    'Indication(s)': 'Prostate cancer',
    'Deal Structure':
      'Blue Earth Diagnostics exercised an option to acquire exclusive, worldwide rights to therapeutic applications of novel radiohybrid Prostate-Specific Membrane Antigen (rhPSMA) technology in prostate cancer.',
    'FW Link1':
      'Blue Earth Diagnostics Acquires Exclusive, Worldwide Rights to Therapeutic Applications of Scintomics’ Radiohybrid Prostate-Specific Membrane Antigen (rhPSMA) Technology for Prostate Cancer;;https://www.firstwordhealthtech.com/story/5192209/'
  },
  {
    'Date Announced': '11-Jan-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'BioInvent',
    'Country (Acquiring Company/Partner)': 'Sweden',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Cancer Research UK',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Anti-FcγRIIB antibody',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'BI-1206',
    'Indication(s)': 'Non-Hodgkin lymphoma, chronic lymphocytic leukaemia',
    'Target(s)': 'FcγRIIB',
    'Deal Structure':
      'The restructured agreement with CRUK releases BioInvent from obligations to pay development or commercial milestones to CRUK on BI-1206 and reduces the royalties due on net sales to low single digit levels. BioInvent will make a one-time payment of £2.5 million to CRUK.',
    'FW Link1':
      'BioInvent streamlines agreement on anti-FcγRllB antibody, BI-1206, ahead of Phase I/II data;;https://firstwordpharma.com/story/5196105/'
  },
  {
    'Date Announced': '11-Jan-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'PharmAbcine',
    'Country (Acquiring Company/Partner)': 'Korea, South',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'LegoChem Biosciences',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'PMC-403',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'Tie2 receptor',
    'Deal Structure':
      "PharmAbcine will provide PMC-403, a preclinical pipeline, to LCB so LCB can conduct a combination research using PMC-403 and LCB's proprietary ADC. This preclinical study will allow both parties to evaluate the efficacy of the combination therapy in solid tumours.",
    'FW Link1':
      'PharmAbcine announces a research collaboration with LegoChem Biosciences;;https://firstwordpharma.com/story/5196935/'
  },
  {
    'Date Announced': '11-Jan-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Mirati Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Resolution Bioscience',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'KRAS G12C inhibitor',
    'Indication(s)': 'Non-small-cell lung cancer',
    'Target(s)': 'KRAS G12C',
    'Deal Structure':
      "Resolution's ctDx liquid biopsy technology will be used to help identify NSCLC patients who may benefit from Mirati's investigational KRAS G12C inhibitor therapy.",
    'FW Link1':
      'Resolution Bioscience Announces Companion Diagnostic Collaboration with Mirati Therapeutics;;https://firstwordpharma.com/story/5197031/'
  },
  {
    'Date Announced': '11-Jan-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'AstraZeneca',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Adaptive Biotechnologies',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'T cell receptors',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Adaptive will sequence patient samples and deliver T-cell receptor (TCR)-antigen mapping data, which may inform signatures of immune response (or resistance) to cancer. Adaptive will receive from AstraZeneca quarterly payments plus sequencing and data mapping fees. In addition, AstraZeneca has an option to enter into a separate agreement with Adaptive for the development and commercialization of a companion diagnostic or therapeutic application based on T-MAP data.',
    'FW Link1':
      'Adaptive Biotechnologies to Collaborate with AstraZeneca in Mapping the Immune Response to Cancer Antigens to Potentially Inform the Development of Novel Diagnostics and Therapeutics;;https://firstwordpharma.com/story/5197179/'
  },
  {
    'Date Announced': '12-Jan-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Beactica Therapeutics',
    'Country (Acquiring Company/Partner)': 'Sweden',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'University of Dundee',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Indication(s)': 'Cancer',
    'Target(s)': 'WRN helicase',
    'Deal Structure':
      'Beactica will contribute key technical strengths in identifying and optimizing allosteric binders as well as evaluating ternary degradation complexes formed by PROTACs based on such binders.',
    'FW Link1':
      'Beactica Therapeutics and University of Dundee announce collaboration to develop WRN inhibitors to target cancer;;https://firstwordpharma.com/story/5197667/'
  },
  {
    'Date Announced': '29-Nov-2021',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Blueprint Medicines',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Lengo Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '250',
    'Total Deal Value ($m)': '465',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Precision therapy',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'LNG-451',
    'Indication(s)': 'Non-small-cell lung cancer',
    'Target(s)': 'EGFR exon 20 insertion mutations',
    'Deal Structure':
      'Blueprint Medicines acquired Lengo Therapeutics for $250 million in cash plus up to $215 million in additional potential payments based on the achievement of certain regulatory approval and sales-based milestones.',
    'FW Link1':
      'Blueprint bags EGFR-driven lung cancer asset with Lengo buy;;https://firstwordpharma.com/story/5451059'
  },
  {
    'Date Announced': '27-Dec-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Sutro Biopharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Tasly',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '40',
    'Total Deal Value ($m)': '385',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'STRO-002',
    'Indication(s)': 'Ovarian cancer, endometrial cancer',
    'Target(s)': 'FolRα',
    'Deal Structure':
      'Sutro will receive an upfront payment of $40 million and be eligible to receive potentially up to $345 million in development and commercial milestone payments. Tasly will pursue the clinical development, regulatory approval, and commercialization of STRO-002 in Greater China for ovarian and endometrial cancers, with the potential to expand to further oncological indications including NSCLC and triple-negative breast cancer. Upon commercialisation, Sutro will receive tiered, double-digit royalties based on annual net sales of STRO-002 in Greater China.',
    'FW Link1':
      'Sutro Biopharma and Tasly Biopharmaceuticals Enter into Exclusive License Agreement for STRO-002 in Greater China;;https://firstwordpharma.com/story/5469005/'
  },
  {
    'Date Announced': '22-Dec-2021',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'PharmaMar',
    'Country (Acquiring Company/Partner)': 'Spain',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Eczacibasi',
    'Country (Target Company/Partner)': 'Turkey',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Turkey',
    'Therapy Area(s)': 'Oncology',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'lurbinectedin',
    'Indication(s)': 'Small-cell lung cancer',
    'Deal Structure':
      'PharmaMar will receive a non-disclosed upfront payment and will be eligible for additional remunerations, including regulatory and sales milestone payments. PharmaMar will retain production rights and will sell the product to Eczacıbaşı for its clinical and commercial use.',
    'FW Link1':
      'PharmaMar signs a new licensing and commercialization agreement with Eczacibasi for lurbinectedin in Turkey;;https://firstwordpharma.com/story/5468323/'
  },
  {
    'Date Announced': '20-Dec-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'FibroGen',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'HiFiBiO Therapeutics',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '35',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibody',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'HFB1011',
    'Indication(s)': 'Cancer',
    'Target(s)': 'CCR8',
    'Deal Structure':
      'HiFiBiO will receive a $35 million upfront payment from FibroGen in addition to potential clinical, regulatory, and commercial milestones. HiFiBiO will also be eligible to receive royalties based upon net sales.',
    'FW Link1':
      'FibroGen Exercises Exclusive License Option for HiFiBiO’s CCR8 Program;;https://firstwordpharma.com/story/5465567/'
  },
  {
    'Date Announced': '15-Dec-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'AstraZeneca',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Transgene',
    'Country (Target Company/Partner)': 'France',
    'Region (Target Company/Partner)': 'Europe',
    'Upfront Value ($m)': '8',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Oncolytic virus',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Transgene is also eligible to receive development, regulatory and sales-based milestones payments as well as a royalty based on future commercial sales.',
    'FW Link1':
      'Transgene announces License Option Exercise by AstraZeneca for an Oncolytic Virus Generated by Transgene’s Invir.IO Platform;;https://firstwordpharma.com/story/5462481/',
    'FW Link2':
      'Termination of collaboration announced May 5, 2023;;https://firstwordpharma.com/story/5736117/'
  },
  {
    'Date Announced': '14-Dec-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'BeiGene',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Nanjing Leads Biolabs',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '30',
    'Total Deal Value ($m)': '772',
    'Geographic Remit of Deal': 'Ex-China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'LBL-007',
    'Indication(s)': 'Cancer',
    'Target(s)': 'LAG-3',
    'Deal Structure':
      'Leads Biolabs will receive $30 million upfront and is eligible to receive up to $742 million in clinical development, regulatory approval, and sales milestones, plus tiered double-digit royalties on sales in the licensed territory.',
    'FW Link1':
      'Nanjing Leads Biolabs and BeiGene Announce Worldwide License and Collaboration Agreement for LBL-007 Anti-LAG-3 Antibody; BeiGene Granted Exclusive Commercialization Rights Outside of China;;https://firstwordpharma.com/story/5462139/'
  },
  {
    'Date Announced': '14-Dec-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Numab Therapeutics',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': '3SBio',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'T cell engagers',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'NM28',
    'Indication(s)': 'Cancer',
    'Target(s)': 'mesothelin (MSLN)',
    'Deal Structure':
      'Under the terms of this collaboration, which builds on the existing relationship between the two parties established in 2019, Sunshine Guojian will have exclusive rights to develop and commercialize NM28 in the Greater China area, including mainland China, Hong Kong, Macao and Taiwan. Numab retains NM28 rights for the rest of the world.',
    'FW Link1':
      'Numab and 3SBio’s Sunshine Guojian Expand Partnership to Develop Novel T Cell Engager NM28;;https://firstwordpharma.com/story/5461577/'
  },
  {
    'Date Announced': '29-Nov-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Pfizer',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Ranok Therapeutics',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Protein degradation',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Proposed research will apply Ranok's innovative CHAMP technology to a selected cancer drug target",
    'FW Link1':
      'Ranok Therapeutics Announces Agreement with Pfizer on Targeted Protein Degradation;;https://firstwordpharma.com/story/5450919/'
  },
  {
    'Date Announced': '22-Nov-2021',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'EQRx',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Abdul Latif Jameel Health',
    'Country (Target Company/Partner)': 'Monaco',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Middle East, Turkey, Africa',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Tyrosine kinase inhibitor, monoclonal antibody',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'aumolertinib, sugemalimab',
    'Indication(s)': 'Non-small-cell lung cancer',
    'Target(s)': 'EGFR, PD-L1',
    'Deal Structure':
      "Abdul Latif Jameel Health will become EQRx's regulatory and commercial partner for aumolertinib and sugemalimab",
    'FW Link1':
      'EQRx Announces Partnership Agreement with Abdul Latif Jameel Health to Commercialize Lead Oncology Programs in Middle East, Turkey and Africa;;https://firstwordpharma.com/story/5446765/'
  },
  {
    'Date Announced': '25-Oct-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'AnaptysBio',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Sagard Healthcare Royalty Partners',
    'Country (Target Company/Partner)': 'Canada',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '250',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibody',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Jemperli (dostarlimab)',
    'Indication(s)': 'Cancer',
    'Target(s)': 'PD-1',
    'Deal Structure':
      "Sagard to receive AnaptysBio's 8% royalty on annual global net sales of Jemperli below $1 billion, plus certain future regulatory and commercial sales milestones, while AnaptysBio retains 12-25% royalties and milestones on annual global net sales at or above $1 billion",
    'FW Link1':
      'AnaptysBio Announces Agreement to Monetize Portion of JEMPERLI Royalties for $250 Million with Sagard;;https://firstwordpharma.com/story/5427413/'
  },
  {
    'Date Announced': '21-Sep-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'J&J',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Persephone Biosciences',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Biomarkers',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Persephone will analyze stool samples collected as part of a Janssen oncology clinical trial using Persephone’s proprietary stool collection kit, and the company will have exclusive licensing rights to any biomarkers discovered.',
    'FW Link1':
      'Persephone Biosciences Inc. Announces Collaboration with Janssen Biotech, Inc.;;https://www.firstwordhealthtech.com/story/5403375/'
  },
  {
    'Date Announced': '20-Sep-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Trigone Pharma',
    'Country (Acquiring Company/Partner)': 'Israel',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': '4C Biomed',
    'Country (Target Company/Partner)': 'Israel',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibody',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Bladder cancer',
    'Target(s)': 'HVEM, TNFRSF14',
    'Deal Structure':
      "The collaboration will focus on local administration of 4C Biomed's anti 4CB-1 that targets HVEM (TNFRSF14) using Trigone's proprietary technology to increase dwell time.",
    'FW Link1':
      'Trigone Pharma Announces a Strategic Collaboration with 4C Biomed to Develop a Novel Treatment for High-Grade Bladder Cancer;;https://firstwordpharma.com/story/5402083/'
  },
  {
    'Date Announced': '20-Sep-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Oncternal Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Celularity',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Monoclonal antibodies, allogeneic NK cell therapy',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'cirmtuzumab, CYNK-101',
    'Indication(s)': 'Cancer',
    'Target(s)': 'ROR1',
    'Deal Structure':
      "Celularity will explore the use of Oncternal's ROR1-targeted monoclonal antibody, cirmtuzumab, in combination with Celularity's natural killer cells. ROR1 targeted chimeric antigen receptor (CAR) gene modification will also be explored in Celularity's CYNK natural killer cell and CyCART T cell platforms in preclinical studies.",
    'FW Link1':
      'Oncternal Therapeutics and Celularity Enter into Research Collaboration to Evaluate Targeted Placental-Derived Cellular Therapies;;https://firstwordpharma.com/story/5401805/'
  },
  {
    'Date Announced': '16-Sep-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'ValiRx',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': "King's College London",
    'Country (Target Company/Partner)': 'United Kingdom',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Triple-negative breast cancer',
    'Deal Structure':
      'ValiRx will carry out a defined series of preclinical tests on the drug candidate molecule over the next nine months to validate the technology and determine suitability for commercialisation. At the conclusion of the evaluation period the company has an option to license the technology on pre-agreed terms.',
    'FW Link1':
      'ValiRx commences new evaluation agreement;;https://firstwordpharma.com/story/5400115/',
    'FW Link2':
      'Intention to proceed to full in-licensing announced July 13, 2022;;https://firstwordpharma.com/story/5614501/'
  },
  {
    'Date Announced': '9-Sep-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Ionis Pharmaceuticals',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Flamingo Therapeutics',
    'Country (Target Company/Partner)': 'Belgium',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'RNA-targeted therapies',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Cancer',
    'Target(s)': 'STAT-3, androgen receptor (AR), IRF4, MALAT1',
    'Deal Structure':
      'This alliance is an expansion of an existing collaboration with Ionis on the FLAME discovery engine. Under the terms of the agreement, Ionis is eligible to receive milestone payments and royalties on future product sales of the STAT3, AR, IRF4 and MALAT1 programs.',
    'FW Link1':
      'Flamingo Therapeutics Expands Alliance with Ionis Pharmaceuticals to Develop RNA-targeted Therapies for Oncology;;https://firstwordpharma.com/story/5394325/'
  },
  {
    'Date Announced': '31-Aug-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Jacobio Pharmaceuticals',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Hebecell',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'NK cell therapy',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Jacobio will collaborate with Hebecell to develop the next-generation natural killer cell therapy based on induced pluripotent stem cells (iPSC-NK).',
    'FW Link1':
      'Jacobio Announces Strategic Investment in Hebecell to Further Develop iPSC-NK Cell Therapy;;https://firstwordpharma.com/story/5387663/'
  },
  {
    'Date Announced': '26-Aug-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'BeyondSpring',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Hengrui Pharmaceuticals',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '30',
    'Total Deal Value ($m)': '400',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Selective immunomodulating microtubule-binding agent',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'plinabulin',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Hengrui will gain exclusive rights to commercialise and co-develop plinabulin in the Greater China markets, including mainland China, Hong Kong, Macau and Taiwan. Hengrui will receive a pre-determined percentage of the net sales in each quarter. BeyondSpring's 58%-owned subsidiary Wanchunbulin will receive the equivalent of up to 1.3B RMB (est. $200 million), including an upfront payment of 200M RMB (est. $30 million) and regulatory and sales milestones of up to 1.1 B RMB (est. $170 million). Hengrui will be responsible for all costs associated with commercialisation of plinabulin in the Territory.",
    'FW Link1':
      'BeyondSpring Announces Exclusive Commercialization and Co-development Agreement with Jiangsu Hengrui Pharmaceuticals for Plinabulin in Greater China;;https://firstwordpharma.com/story/5384045/'
  },
  {
    'Date Announced': '20-Aug-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Insilico Medicine',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'BioNova Pharmaceuticals',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Haematological malignancies',
    'Deal Structure':
      "The collaboration will leverage a BioNova discovery stage project and its knowledge and understanding of molecular targets and unmet medical needs, in combination with Insilico's artificial intelligence (AI)-powered drug discovery platform based on deep reinforcement learning technology and mass data training. The two parties will apply cutting-edge molecular design methods to improve the efficiency and success rate of drug discovery and development to develop best-in-class small molecule compounds for haematological malignancies.",
    'FW Link1':
      'Insilico Medicine Collaborates with BioNova to Develop Small Molecule Drugs for Hematologic Malignancies;;https://www.firstwordhealthtech.com/story/5379877/'
  },
  {
    'Date Announced': '16-Aug-2021',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'HiberCell',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Genuity Science',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial intelligence, machine learning',
    'Indication(s)': 'Cancer',
    'FW Link1':
      'HiberCell Expands Translational Oncology Toolbox by Acquiring Genuity Science;;https://www.firstwordhealthtech.com/story/5376105/'
  },
  {
    'Date Announced': '5-Aug-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Imugene',
    'Country (Acquiring Company/Partner)': 'Australia',
    'Region (Acquiring Company/Partner)': 'Australia',
    'Target Company/Partner': 'Celularity',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Oncolytic virus, CAR-T cell therapy',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'CyCART-19',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'CD19',
    'Deal Structure':
      "Collaboration will initially explore the therapeutic potential of a combination of Imugene's CF33-CD19 oncolytic virus (onCARlytics) and Celularity's CD19 targeting chimeric antigen receptor (CAR) placental-derived investigational T-cell therapy, CyCART-19",
    'FW Link1':
      'Imugene and Celularity Announce an Exclusive Strategic Partnership to Develop a Novel Oncolytic Virus - Allogeneic CAR T-Cell Immunotherapy Combination for the Treatment of Solid Tumors;;https://firstwordpharma.com/story/5366975/'
  },
  {
    'Date Announced': '4-Aug-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'EQRx',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'AbCellera',
    'Country (Target Company/Partner)': 'Canada',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibodies',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "The collaboration will leverage AbCellera's AI-powered antibody discovery technology to search and analyse natural immune responses to identify novel antibody drug candidates for multiple targets. The deal also includes the option for additional investment from AbCellera at progressive stages of preclinical development, clinical development, and commercialization in exchange for an increased share of product sales.",
    'FW Link1':
      'EQRx and AbCellera Announce Collaboration to Accelerate the Advancement of New Innovative Medicines;;https://firstwordpharma.com/story/5366217/'
  },
  {
    'Date Announced': '27-Jul-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'BMS',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'BridgeBio Pharma',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies, SHP2 inhibitor',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'Opdivo (nivolumab), BBP-398',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'PD-1, SHP2, KRAS',
    'Deal Structure':
      "The agreement includes evaluating the PD-1 monoclonal antibody Opdivo (nivolumab) alongside BridgeBio Pharma's SHP2 inhibitor BBP-398 to treat advanced solid tumours with KRAS mutations. The alliance includes conducting a Phase I/II study to evaluate BBP-398 in combination with Opdivo as doublet therapy, and with Opdivo plus a KRAS G12C inhibitor as triplet therapy in NSCLC with KRAS mutations, as first- and second-line treatment options.",
    'FW Link1':
      'BMS alliance to explore combining Opdivo, BridgeBio drug in KRAS-mutated cancers;;https://firstwordpharma.com/story/5359241/'
  },
  {
    'Date Announced': '21-Jul-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Exscientia',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'GT Apeiron Therapeutics',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Cancer',
    'Target(s)': 'Cyclin-dependent kinases',
    'Deal Structure':
      'The collaboration will leverage the patient-centric AI-first capabilities of Exscientia to accelerate the discovery of multiple small-molecule therapeutic drug candidates designed to selectively treat aberrant cell cycle driven cancers and build a pipeline of CDK novel therapies. All pipeline products will be equally owned and Exscientia holds an equity stake in Apeiron.',
    'FW Link1':
      'Exscientia and GT Apeiron Therapeutics Enter Oncology Joint Venture;;https://www.firstwordhealthtech.com/story/5355793/'
  },
  {
    'Date Announced': '1-Feb-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Antengene',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'WuXi Biologics',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Through this collaboration, WuXi Biologics and WuXi STA will provide services for the process development, scale-up, and GMP manufacturing of ADC drug intermediates. Antengene will focus on the discovery of the monoclonal antibody, the core element of ADCs. WuXi STA will focus on the GMP manufacturing of linkers and payloads while WuXi Biologics will provide process development for monoclonal antibody and the biological conjugation as well as the subsequent GMP manufacture.',
    'FW Link1':
      'Antengene Announces Collaboration with WuXi Biologics and WuXi STA to Advance Antibody-Drug Conjugate Candidate into Clinical-stage;;https://firstwordpharma.com/story/5213679/'
  },
  {
    'Date Announced': '3-Feb-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'JW Therapeutics',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Thermo Fisher',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'CAR-T cell therapy',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'Relmacabtagene autoleucel',
    'Indication(s)': 'B-cell lymphoma',
    'Target(s)': 'CD19',
    'Deal Structure':
      "The agreement will support the clinical development and commercial manufacturing of JW Therapeutics' CAR-T cell therapies in China, including relmacabtagene autoleucel, using Thermo Fisher's Gibco CTS Dynabeads CD3/CD28. The CTS Dynabeads platform is part of Thermo Fisher's Cell Therapy Systems (CTS) product portfolio designed to ease the transition from clinical development to commercial manufacturing of T-cell therapies.",
    'FW Link1':
      'Thermo Fisher Scientific and JW Therapeutics Announce CAR-T Partnership in China;;https://firstwordpharma.com/story/5216003/'
  },
  {
    'Date Announced': '9-Feb-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Hengrui Medicine',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Yingli Pharma',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset(s)': 'YY-20394',
    'Indication(s)': 'Cancer',
    'Target(s)': 'PI3kδ',
    'Deal Structure':
      'Hengrui Medicine will invest $20 million in Yingli Pharma as an equity investment. Yingli Pharma will grant Hengrui Medicine joint development rights and exclusive commercialization rights of PI3kδ inhibitor YY-20394 in Greater China.',
    'FW Link1':
      'Yingli Pharma and Hengrui Medicine Reach a Strategic Agreement to Jointly Explore the Oncology Market in China;;https://firstwordpharma.com/story/5220413/'
  },
  {
    'Date Announced': '12-Feb-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Merck & Co.',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Starpharma',
    'Country (Target Company/Partner)': 'Australia',
    'Region (Target Company/Partner)': 'Australia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Under the agreement, Merck will conduct a preclinical research evaluation of dendrimer based Antibody Drug Conjugates (ADCs) utilising Starpharma's proprietary DEP technology.",
    'FW Link1':
      'Starpharma signs DEP ADC Research Agreement with MSD;;https://firstwordpharma.com/story/5223731/'
  },
  {
    'Date Announced': '16-Feb-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'ValiRx',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Physiomics',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Peptide drug',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'VAL201',
    'Indication(s)': 'Prostate cancer',
    'Target(s)': 'SRC kinase',
    'Deal Structure':
      'Under the terms of the new agreement, ValiRx will benefit from Physiomics’ experience in modelling the effects of prostate cancer treatment, as well the use of the latest version of its Virtual Tumour technology. Physiomics will receive a fee (capped at £6m) of 6% of any future net revenues that may be received by ValiRx relating to its commercialisation of VAL201 and its derivatives in any indication.',
    'FW Link1':
      'ValiRx plc - Extension of Collaboration Agreement;;https://firstwordpharma.com/story/5226467/'
  },
  {
    'Date Announced': '17-Feb-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Onward Therapeutics',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'EMERCell',
    'Country (Target Company/Partner)': 'France',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'NK cell therapy',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'The significant investment, of a confidential amount, will be made in three instalments that will allow EMERCell to reach the first human clinical trials.',
    'FW Link1':
      'EMERCell Signs Strategic Partnership with Onward Therapeutics for the Development of its NK Cell Therapy Technology;;https://firstwordpharma.com/story/5227071/'
  },
  {
    'Date Announced': '22-Feb-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'MaxiVAX',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Minaris Regenerative Medicine',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell-based immunotherapy',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'MVX-ONCO-2',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      'Minaris will be responsible for the GMP manufacturing, freezing and shipping of the MVX-ONCO-2 capsules, which will be delivered to clinical sites. The scope of the manufacturing agreement includes process development for scale-up and technology transfer with the goal of supplying Phase II and III studies initially in the EU and eventually in the US.',
    'FW Link1':
      'MaxiVAX and Minaris Regenerative Medicine Enter into a Manufacturing Partnership for MVX-ONCO-2 Cancer Immunotherapy;;https://firstwordpharma.com/story/5230429/'
  },
  {
    'Date Announced': '22-Feb-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'SunRock Biopharma',
    'Country (Acquiring Company/Partner)': 'Spain',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'GenScript',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibodies',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Cancer',
    'Target(s)': 'CCR9',
    'Deal Structure':
      'GenScript ProBio will provide SunRock Biopharma a one-stop-solution from antibody discovery, cell line development, process development to GMP drug substance and drug product manufacturing services.',
    'FW Link1':
      'SunRock Biopharma and GenScript ProBio Enter into a Collaboration Agreement to Develop Novel Monoclonal Antibody Program;;https://firstwordpharma.com/story/5230787/'
  },
  {
    'Date Announced': '24-Feb-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Cybrexa Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'US National Cancer Institute',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Peptide-drug conjugate',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'CBX-12',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      'Cybrexa and the NCI will work together initially focusing on pre-clinical studies and move on to clinical development of CBX-12, if the preclinical studies support clinical development, to demonstrate its safety and efficacy in patients with a variety of solid tumors.',
    'FW Link1':
      'Cybrexa Therapeutics Enters Cooperative Research and Development Agreement (CRADA) with U.S. National Cancer Institute to Develop CBX-12 (alphalex- exatecan);;https://firstwordpharma.com/story/5233133/'
  },
  {
    'Date Announced': '25-Feb-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Debiopharm',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Genome & Company',
    'Country (Target Company/Partner)': 'South Korea',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'With Debiopharm, Genome & Company will conduct follow-up discussions on the clinical development and the licensing of newly discovered ADC candidates.',
    'FW Link1':
      'Genome & Company and Debiopharm Join Forces To Create New Highly Specific Therapies for Cancer Patients;;https://firstwordpharma.com/story/5233951/'
  },
  {
    'Date Announced': '25-Feb-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Zentalis Pharmaceuticals',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Tempus',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'ZN-c3',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'WEE1',
    'Deal Structure':
      "In harnessing Tempus' cutting-edge approach, the collaboration will initially aim to validate Zentalis' WEE1 inhibitor, ZN-c3, and its DNA damage response pathway in genetically distinct patient populations. The platform will also be used to investigate additional novel targets of cancer pathways that may be identified by Zentalis, as well as support the study of Zentalis' current product candidates across various indications.",
    'FW Link1':
      'Zentalis Pharmaceuticals and Tempus Announce Strategic Collaboration to Advance Research and Development Capabilities;;https://firstwordpharma.com/story/5234103/'
  },
  {
    'Date Announced': '26-Feb-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Daiichi Sankyo',
    'Country (Acquiring Company/Partner)': 'Japan',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'LYSA-LYSARC-CALYM',
    'Country (Target Company/Partner)': 'France, Belgium',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'valemetostat',
    'Indication(s)': 'B-cell malignancies',
    'Target(s)': 'EZH1/2',
    'Deal Structure':
      'LYSA-LYSARC will execute a multi-center, non-randomized, open-label phase 2 study to evaluate the safety and efficacy of valemetostat in six cohorts of patients with relapsed/refractory B-cell lymphoma.',
    'FW Link1':
      'Daiichi Sankyo and LYSA-LYSARC-CALYM Enter Research Collaboration for Valemetostat in Patients with Relapsed/Refractory B-Cell Lymphoma;;https://firstwordpharma.com/story/5235007/'
  },
  {
    'Date Announced': '4-Mar-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Roche',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Tempest Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'TPST-1120, Tecentriq, Avastin',
    'Indication(s)': 'Liver cancer',
    'Target(s)': 'PPAR⍺',
    'Deal Structure':
      'TPST-1120 will be evaluated in a Phase Ib/II study in combination with the standard-of-care first-line regimen of Tecentriq (atezolizumab) and Avastin (bevacizumab) in patients with advanced or metastatic HCC not previously treated with systemic therapy. Under the terms of the collaboration agreement, Roche will manage the study operations for this global multi-centre trial.',
    'FW Link1':
      'Tempest Therapeutics Announces Clinical Collaboration with Roche to Advance TPST-1120 into a Randomized Combination Study in First-line Hepatocellular Carcinoma;;https://firstwordpharma.com/story/5240625/'
  },
  {
    'Date Announced': '4-Mar-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'ALX Oncology',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'allac Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Cancer',
    'Target(s)': 'SIRPα, TLR9',
    'Deal Structure':
      'Under the terms of the agreement, ALX Oncology and Tallac will share equally in the cost of research and development and any profits or losses incurred.',
    'FW Link1':
      'ALX Oncology and Tallac Therapeutics Announce Collaboration on Novel Class of Cancer Immunotherapeutics;;https://firstwordpharma.com/story/5241187/'
  },
  {
    'Date Announced': '8-Mar-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Merck & Co.',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Dracen Pharmaceuticals',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'sirpiglenastat, Keytruda',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'glutamine, PD-1',
    'Deal Structure':
      "The companies will evaluate the safety and efficacy of sirpiglenastat (DRP-104), Dracen's glutamine antagonist, in combination with Keytruda (pembrolizumab), Merck's anti-PD-1 therapy, in patients with solid tumours.",
    'FW Link1':
      'Dracen Announces Clinical Collaboration with Merck;;https://firstwordpharma.com/story/5243077/'
  },
  {
    'Date Announced': '9-Mar-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Merck & Co.',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Surface Oncology',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibodies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'SRF388, Keytruda',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'IL-27, PD-1',
    'Deal Structure':
      "The clinical trial collaboration will investigate the combination of Surface's SRF388, an investigational antibody therapy targeting IL-27, with Merck's anti-PD-1 therapy Keytruda (pembrolizumab). This combination will be studied as a component of the first-in-human Phase I study of SRF388 and will be evaluated in patients with solid tumours, with a focus on patients with liver cancer and kidney cancer.",
    'FW Link1':
      'Surface Oncology to Collaborate with Merck on Immuno-Oncology Study Evaluating SRF388, Targeting IL-27, in Combination with KEYTRUDA (pembrolizumab) in Patients with Solid Tumors;;https://firstwordpharma.com/story/5244079/'
  },
  {
    'Date Announced': '10-Mar-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Pfizer',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Syapse',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Real-world data',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Through the Syapse Learning Health Network, Pfizer will generate insights to advance cancer outcomes research.',
    'FW Link1':
      'Syapse Expands Strategic Collaboration with Pfizer Focusing on Real-World Evidence;;https://firstwordpharma.com/story/5245869/'
  },
  {
    'Date Announced': '16-Mar-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Nurix Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': "Alex's Lemonade Stand Foundation",
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Neuroblastoma, medulloblastoma',
    'Target(s)': 'MYCN',
    'Deal Structure':
      "Nurix will collaborate with a pre-eminent global research team to bring drug discovery technology and development expertise to the effort. The program's goal is to identify and evaluate small molecules that promote targeted protein degradation of the transcription factor MYCN to potentially drive tumor destruction.",
    'FW Link1':
      'Nurix Therapeutics Announces Collaboration for the Discovery of Novel Drugs to Treat Pediatric Cancers;;https://firstwordpharma.com/story/5250091/'
  },
  {
    'Date Announced': '17-Mar-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Merck & Co.',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'M2GEN',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Health informatics',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Under the terms of the agreement, M2GEN will provide valuable patient-consented, de-identified, linked clinical and genomic data in support of Merck's oncology efforts.",
    'FW Link1':
      'M2GEN Announces New Collaboration with Merck to Advance Cancer Therapies;;https://firstwordpharma.com/story/5251499/'
  },
  {
    'Date Announced': '17-Mar-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'BeiGene',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': '111, Inc.',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Oncology management platform',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'The companies will cooperate around a "Internet + Medicine & Healthcare" model, leveraging 111\'s Internet hospital, smart supply chain network, data-driven AI solutions, such as digital advertising, and online and offline direct-to-patient (DTP) delivery of medicines to create an unique oncology management platform.',
    'FW Link1':
      '111, Inc. Enters into First-Ever Strategic Partnership with BeiGene;;https://firstwordpharma.com/story/5251603/'
  },
  {
    'Date Announced': '22-Mar-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Lead Discovery Center',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Cumulus Oncology',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules, biologics',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Each party provides targets and assets in the oncology area for collaboration and potential future company co-creation as well as bringing a wider access to funding partners and collaborators globally.',
    'FW Link1':
      'LDC and Cumulus Oncology announce collaboration;;https://firstwordpharma.com/story/5254413/'
  },
  {
    'Date Announced': '30-Mar-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'BMS',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'HUYA Bioscience',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Epigenetic immunomodifier, monoclonal antibody',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'HBI-8000, Opdivo',
    'Indication(s)': 'Melanoma',
    'Target(s)': 'Class I histone deacetylases, PD-1',
    'Deal Structure':
      'HUYABIO will be the sponsor of the trial. BMS will provide Opdivo clinical drug supply for the study.',
    'FW Link1':
      'Huyabio International Announces Global Clinical Trial Collaboration With Bristol Myers Squibb In Melanoma;;https://firstwordpharma.com/story/5261477/'
  },
  {
    'Date Announced': '31-Mar-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Outpace Bio',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Lyell Immunopharma',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immune cell therapy',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Outpace has entered into a collaboration with Lyell Immunopharma for joint research and subsequent development and commercialization by Lyell of a potential immune cell therapy for the treatment of cancer. Under the collaboration agreement, Outpace and Lyell will use Outpace’s technologies in an effort to unlock the biology of a cytokine that the companies believe has the potential to dramatically improve efficacy in treating patients with cancer, but would require careful control to avoid systemic toxicity.',
    'FW Link1':
      'Creating Next Generation Smart Cell Therapies: Outpace Bio Receives $30 Million in Series A Funding Led by ARTIS Ventures and Lyell Immunopharma Inc.;;https://firstwordpharma.com/story/5263583/'
  },
  {
    'Date Announced': '7-Apr-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Merck & Co.',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Alkermes',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'IL-2 variant immunotherapy, antibody',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'Keytruda, nemvaleukin alfa',
    'Indication(s)': 'Ovarian cancer',
    'Target(s)': 'IL-2 receptor complex, PD-1',
    'Deal Structure':
      'Under the terms of the agreement, Alkermes is responsible for conducting the phase 3 study, which is planned to initiate in the second half of 2021.',
    'FW Link1':
      'Alkermes Announces Clinical Trial Collaboration and Supply Agreement With MSD to Evaluate Nemvaleukin Alfa in Combination With KEYTRUDA in Patients With Platinum-Resistant Ovarian Cancer;;https://firstwordpharma.com/story/5267737/'
  },
  {
    'Date Announced': '8-Apr-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'TriSalus Life Sciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner':
      'The University of Texas MD Anderson Cancer Center',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'TLR9 agonist',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'SD-101',
    'Indication(s)': 'Liver cancer, pancreatic cancer',
    'Target(s)': 'TLR9',
    'Deal Structure':
      "Under the agreement, MD Anderson and TriSalus will collaborate on studies evaluating the administration of investigational SD-101 intravascularly via TriSalus' FDA cleared Pressure-Enabled Drug Delivery (PEDD) technology across a range of liver and pancreatic solid tumours. The initial study will focus on liver metastases from uveal melanoma, followed by studies focused on metastatic disease from pancreatic ductal adenocarcinoma and colorectal cancer. Programmes for hepatocellular carcinoma and locally advanced pancreatic ductal adenocarcinoma also are under development. TriSalus will provide funding and technology for the studies.",
    'FW Link1':
      'TriSalus Life Sciences and MD Anderson Announce Strategic Research Collaboration to Evaluate Treatment of Solid Tumors;;https://firstwordpharma.com/story/5269143/'
  },
  {
    'Date Announced': '12-Apr-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'GSK',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Zentalis Pharmaceuticals',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'Zejula, ZN-c3',
    'Indication(s)': 'Ovarian cancer',
    'Target(s)': 'PARP, WEE1',
    'Deal Structure':
      'Under the terms of the non-exclusive collaboration, Zentalis is responsible for conducting the study with GSK providing all required doses of Zejula (niraparib).',
    'FW Link1':
      'Zentalis Pharmaceuticals Enters into Clinical Collaboration and Supply Agreement with GlaxoSmithKline to Evaluate its Oral WEE1 Inhibitor, ZN-c3, in Combination with Niraparib, a PARP Inhibitor;;https://firstwordpharma.com/story/5271375/'
  },
  {
    'Date Announced': '14-Apr-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'aTyr Pharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Lonza',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'ATYR2810',
    'Indication(s)': 'Cancer',
    'Target(s)': 'NRP2',
    'Deal Structure':
      "Under the terms of the agreement, Lonza will utilize its Ibex Design, a fully integrated end-to-end program, to manufacture cGMP material for ATYR2810. The agreement will cover the early stages from gene to Investigational New Drug (IND) and will provide both drug substance (DS) and drug product (DP) to support toxicological studies in animals and early clinical development in humans. The scope will include process support, including cell line development, process development, and supply chain simplification, to DS and DP manufacturing at Lonza's Visp and Stein (CH) sites.",
    'FW Link1':
      'aTyr Pharma and Lonza Announce Manufacturing Agreement for aTyr’s Anti-NRP2 Antibody ATYR2810;;https://firstwordpharma.com/story/5273641/'
  },
  {
    'Date Announced': '14-Apr-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Merck & Co.',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'TILT Biotherapeutics',
    'Country (Target Company/Partner)': 'Finland',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Oncolytic adenovirus, antibody',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'Keytruda, TILT-123',
    'Indication(s)': 'Ovarian cancer',
    'Target(s)': 'TNF alpha, IL-2, PD-1',
    'Deal Structure':
      "The collaboration will evaluate TILT's adenoviral cancer immunotherapy TILT-123, a dual cytokine armed oncolytic adenovirus, in combination with Keytruda (pembrolizumab), Merck's anti-PD-1 therapy, in a Phase I dose-escalation trial in platinum-resistant or refractory ovarian cancer.",
    'FW Link1':
      'TILT Biotherapeutics and MSD to Collaborate on Clinical Trial of Adenoviral Cancer Immunotherapeutic TILT-123 in Combination with KEYTRUDA (pembrolizumab) for Ovarian Cancer;;https://firstwordpharma.com/story/5273937/'
  },
  {
    'Date Announced': '20-Jan-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Merck & Co.',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'MyID',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'United States',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'App, wearable',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Keytruda',
    'Indication(s)': 'Cancer',
    'Target(s)': 'PD-1',
    'Deal Structure':
      'Under the terms of the agreement, Merck will procure the MyID mobile app and wristband directly from MyID, and will be responsible for providing it to their Keytruda patients in the US through December 31, 2023.',
    'FW Link1':
      'MyID Launches Partnership with Merck;;https://firstwordpharma.com/story/5204819/'
  },
  {
    'Date Announced': '21-Jul-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Merck & Co.',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'NOXXON Pharma',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'Keytruda, NOX-A12',
    'Indication(s)': 'Pancreatic cancer',
    'Target(s)': 'PD-1, CXCL12',
    'Deal Structure':
      "This Phase II study will evaluate the safety and efficacy of NOX-A12, NOXXON's anti-CXCL12 agent, in combination with Keytruda and two different chemotherapy regimens in patients with microsatellite stable pancreatic cancer.",
    'FW Link1':
      'NOXXON Pharma Enters Second Clinical Collaboration With MSD to Evaluate NOX-A12 in Combination With KEYTRUDA (Pembrolizumab) in Upcoming Phase 2 Pancreatic Cancer Study;;https://firstwordpharma.com/story/5355791/'
  },
  {
    'Date Announced': '20-Jul-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'GenFleet Therapeutics',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Insilico Medicine',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'AI',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "The agreement outlines a constructive framework where GenFleet will synergize its own R&D systems with Insilico's end-to-end AI-powered drug discovery platform, with a view to jointly addressing significant unmet medical needs and tackling novel & difficult targets in cancer therapeutics.",
    'FW Link1':
      'GenFleet Therapeutics and Insilico Medicine Announce Strategic Partnership;;https://firstwordpharma.com/story/5354483/'
  },
  {
    'Date Announced': '20-Jul-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Kineta',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Samsung Biologics',
    'Country (Target Company/Partner)': 'South Korea',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'KVA12.1',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'VISTA',
    'Deal Structure':
      "Samsung Biologics will provide end-to-end CDMO service from cell line development, clinical drug substance, and drug product manufacturing services to support IND filing for KVA12.1, Kineta's anti-VISTA antibody in development for the treatment of solid tumours.",
    'FW Link1':
      'Kineta and Samsung Biologics Announce Development and Manufacturing Agreement for Anti-VISTA Antibody Immunotherapy;;https://firstwordpharma.com/story/5354229/'
  },
  {
    'Date Announced': '8-Jul-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'J&J',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Vor Biopharma',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Bi-specific antibodies, stem cell therapies',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Acute myeloid leukaemia',
    'Deal Structure':
      'Under the terms of the collaboration, Vor Biopharma will investigate the combination of these two technologies into a treatment solution, pairing Vor\'s "invisible" eHSC transplant platform with one of Janssen\'s bi-specific antibodies in development for acute myeloid leukaemia (AML).',
    'FW Link1':
      'Vor Biopharma Announces Collaboration with Janssen to Develop Engineered Hematopoietic Stem Cell Transplants Combined With a Bi-Specific Antibody Therapy for Acute Myeloid Leukemia (AML);;https://firstwordpharma.com/story/5345533/'
  },
  {
    'Date Announced': '7-Jul-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Roche',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Treos Bio',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Off-the-shelf immunotherapy, monoclonal antibody',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'Tecentriq, PolyPEPI1018',
    'Indication(s)': 'Colorectal cancer',
    'Target(s)': 'PD-L1',
    'Deal Structure':
      "The companies will evaluate the combination of PolyPEPI1018, Treos Bio's lead product candidate, and Tecentriq (atezolizumab), Roche's PD-L1-inhibitor, in patients with late-stage microsatellite stable metastatic colorectal cancer (MSS mCRC). A Phase I/II trial evaluating PolyPEPI1018 in combination with Tecentriq is expected to begin in Q4 2021.",
    'FW Link1':
      'Treos Bio Enters into Clinical Collaboration to Evaluate its Polypeptide Cancer Immunotherapy PolyPEPI1018 in Combination with Roche’s PD-L1-Inhibitor;;https://firstwordpharma.com/story/5345095/'
  },
  {
    'Date Announced': '7-Jul-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Regeneron',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Silverback Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'Libtayo, SBT6050',
    'Indication(s)': 'Gastric cancer, non-small-cell lung cancer',
    'Target(s)': 'PD-1',
    'Deal Structure':
      'Under the terms of the agreement, Silverback will expand the ongoing Phase 1/1b trial to evaluate the combination of SBT6050 and Libtayo in tumor-specific dose expansion cohorts, initially in HER2-expressing non small cell lung cancer and gastric cancer.',
    'FW Link1':
      'Silverback Therapeutics Announces Clinical Supply Agreement with Regeneron to Evaluate SBT6050 in Combination with Libtayo (cemiplimab), initially in HER2-expressing Non-Small Cell Lung and Gastric Cancers;;https://firstwordpharma.com/story/5344611/'
  },
  {
    'Date Announced': '6-Jul-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Merck & Co.',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'OncoSec Medical',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody, gene therapy',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'Keytruda, Tavo',
    'Indication(s)': 'Melanoma',
    'Target(s)': 'PD-1, IL-12',
    'Deal Structure':
      'Under the terms of the Agreement, Merck will provide Keytruda , while OncoSec will provide the investigational drug, Tavo. Each party will be responsible for its own internal costs, with OncoSec covering third party costs.',
    'FW Link1':
      'OncoSec Enters into a Collaboration Agreement with Merck for a Pivotal Global Phase 3 Study, KEYNOTE-C87, of TAVO Combined with KEYTRUDA for Late-Stage Metastatic Melanoma;;https://firstwordpharma.com/story/5344081/'
  },
  {
    'Date Announced': '6-Jul-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Novartis',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Kura Oncology',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'Alpelisib, tipifarnib',
    'Indication(s)': 'Head and neck squamous cell carcinoma',
    'Target(s)': 'PI3K, farnesyl transferase',
    'Deal Structure':
      'The Phase I/II KURRENT trial is a biomarker-defined cohort study designed to evaluate the safety, determine the recommended combination dosing and assess early anti-tumour activity of tipifarnib and alpelisib for the treatment of HNSCC patients whose tumours are dependent on HRAS and/or PI3Kα pathways.',
    'FW Link1':
      'Kura Oncology Announces Clinical Collaboration to Evaluate Tipifarnib in Combination with Alpelisib in Head and Neck Squamous Cell Carcinoma;;https://firstwordpharma.com/story/5343779/'
  },
  {
    'Date Announced': '9-Jun-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Hummingbird Bioscience',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Novogene',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics, antibody',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'HMBD-001',
    'Indication(s)': 'Cancer',
    'Target(s)': 'HER3',
    'Deal Structure':
      "The partnership will leverage Novogene's strong molecular diagnostics capabilities to expand patient identification through Next Generation Sequencing, NGS, testing in China to support the development of Hummingbird Bioscience's investigational drug candidate HMBD-001, an anti-HER3 antibody that will be evaluated for treatment of multiple tumor types, including NRG1-fusion driven cancers.",
    'FW Link1':
      'Hummingbird Bioscience and Novogene enter into strategic partnership to expand precision medicine testing for individuals with NRG1-fusion driven cancers in China;;https://firstwordpharma.com/story/5322885/'
  },
  {
    'Date Announced': '4-Jun-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'AstraZeneca',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Akoya Biosciences',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Biomarkers',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'The aim of this collaboration will be the development and implementation of predictive assays and analysis frameworks to enable AstraZeneca, and the pharmaceutical industry in general, to advance a spatial biomarker-informed drug development strategy for immunotherapy. The results could lead to increased trial success rates and advancement of precision medicine.',
    'FW Link1':
      'Akoya and AstraZeneca to Collaborate on Spatial Biology Approach to Profiling Tumor-Immune Biology;;https://firstwordpharma.com/story/5318961/'
  },
  {
    'Date Announced': '2-Jun-2021',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'Sesen Bio',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Qilu Pharmaceutical',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Fusion protein',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Vicineum',
    'Indication(s)': 'Bladder cancer',
    'Target(s)': 'EpCAM',
    'Deal Structure':
      'Under the terms of the global supply agreement, Qilu Pharmaceutical will be part of the manufacturing network for global commercial supply of Vicineum drug substance and drug product. Sesen Bio is entitled to a $2 million milestone payment upon completion of technology transfer to Qilu Pharmaceutical.',
    'FW Link1':
      'Sesen Bio Announces Global Supply Partnership with Qilu Pharmaceutical;;https://firstwordpharma.com/story/5316745/'
  },
  {
    'Date Announced': '2-Jun-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Aptamer',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'PinotBio',
    'Country (Target Company/Partner)': 'South Korea',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Drug conjugates',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Haematological cancers',
    'Deal Structure':
      'The agreement is to develop Optimer-drug conjugates for targeted drug delivery combining Aptamer Group’s Optimer technology with PinotBio’s technology using the chemotherapeutic FL-118 compound with specific linker.',
    'FW Link1':
      'Aptamer Group and PinotBio collaborate to develop new therapeutic drug conjugates;;https://firstwordpharma.com/story/5316447/'
  },
  {
    'Date Announced': '2-Jun-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'KAHR Medical',
    'Country (Acquiring Company/Partner)': 'Israel',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Samsung Biologics',
    'Country (Target Company/Partner)': 'South Korea',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Fusion protein',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'DSP502',
    'Indication(s)': 'Cancer',
    'Target(s)': 'TIGIT, PD-1',
    'Deal Structure':
      "Under the terms of the agreement, Samsung Biologics will provide its end-to-end cell line development and clinical drug substance and drug product manufacturing services as well as IND filling support for KAHR's drug candidate DSP502, a TIGITxPD1 fusion protein.",
    'FW Link1':
      'Samsung Biologics and KAHR Medical Announce Development and Manufacturing Agreement for Cancer Immunotherapy Drug Candidate;;https://firstwordpharma.com/story/5316073/'
  },
  {
    'Date Announced': '1-Jun-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Merck KGaA',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Immutep',
    'Country (Target Company/Partner)': 'Australia',
    'Region (Target Company/Partner)': 'Australia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Fusion protein',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'eftilagimod alpha, bintrafusp alfa',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'TGF-β, PD-L1, LAG-3 - MHC II',
    'Deal Structure':
      'The collaboration and supply agreement includes a Phase I/IIa clinical trial in patients with solid tumours, called INSIGHT-005.',
    'FW Link1':
      'Immutep Enters Into A New Collaboration With Merck KGaA, Darmstadt, Germany For LAG-3 Therapy, Efti;;https://firstwordpharma.com/story/5315347/'
  },
  {
    'Date Announced': '28-May-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Beroni',
    'Country (Acquiring Company/Partner)': 'Australia',
    'Region (Acquiring Company/Partner)': 'Australia',
    'Target Company/Partner': 'tella',
    'Country (Target Company/Partner)': 'Japan',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Dendritic cell vaccine',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Beroni and tella acknowledge that the resources possessed by each party and their respective affiliates such as technologies, knowledge and networks, are of high value to each other. In this context, they intend to build strategic partnerships in the field of immunotherapy for cancer treatment, such as conducting clinical trials in dendritic cell vaccine and gamma delta T cells in both Japan and China, crossing shareholding by both parties, technical exchange between both parties and other strategic developments.',
    'FW Link1':
      'Beroni Group Signs MoU with tella, Inc to Collaborate in Immunotherapy for Cancer Treatment;;https://firstwordpharma.com/story/5312527/'
  },
  {
    'Date Announced': '22-Apr-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Astellas',
    'Country (Acquiring Company/Partner)': 'Japan',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Zenith Epigenetics',
    'Country (Target Company/Partner)': 'Canada',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'Xtandi, ZEN-3694',
    'Indication(s)': 'Prostate cancer',
    'Target(s)': 'BET',
    'Deal Structure':
      'Under the terms of the agreement, Zenith will sponsor and conduct a Phase 2b randomized study to evaluate the efficacy of the combination of ZEN-3694 and enzalutamide relative to single agent enzalutamide in mCRPC patients who have progressed on a prior androgen receptor signaling inhibitor (ARSi). Astellas will supply enzalutamide for the trial and Zenith will retain all rights to ZEN-3694.',
    'FW Link1':
      'Zenith Epigenetics Announces Clinical Collaboration with Astellas;;https://firstwordpharma.com/story/5280473/'
  },
  {
    'Date Announced': '29-Apr-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Abbisko Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Burning Rock Biotech',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Companion diagnostics, small molecule',
    'Lead Asset(s)': 'ABSK091',
    'Indication(s)': 'Cancer',
    'Target(s)': 'FGFR',
    'Deal Structure':
      'The two companies will work together to develop a CDx assay for ABSK091 (formerly known as AZD4547) to advance clinical research and development of the drug. The CDx assay is designed to detect FGFR genetic alterations in patients with urothelial carcinoma.',
    'FW Link1':
      'Burning Rock and Abbisko Therapeutics Announce Strategic Partnership on Co-development of Companion Diagnostics for ABSK091;;https://www.firstwordhealthtech.com/story/5286277/'
  },
  {
    'Date Announced': '28-Apr-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Bayer',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'ESSA Pharma',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'Darolutamide, EPI-7386',
    'Indication(s)': 'Prostate cancer',
    'Target(s)': 'Androgen receptor',
    'Deal Structure':
      'Under the terms of the agreement, Bayer may sponsor and conduct a Phase I/II study to evaluate the safety, pharmacokinetics and efficacy of the combination of EPI-7386 and darolutamide in mCRPC patients. ESSA will supply EPI-7386 for the trial and will retain all rights to EPI-7386.',
    'FW Link1':
      'ESSA Pharma Announces Clinical Collaboration Agreement with Bayer to Evaluate the Combination of EPI-7386 and Darolutamide in Patients with Metastatic Castration-Resistant Prostate Cancer;;https://firstwordpharma.com/story/5284681/'
  },
  {
    'Date Announced': '4-May-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Exelixis',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'GamaMabs Pharma',
    'Country (Target Company/Partner)': 'France',
    'Region (Target Company/Partner)': 'Europe',
    'Upfront Value ($m)': '5',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'murlentamab',
    'Indication(s)': 'Cancer',
    'Target(s)': 'AMHR2',
    'Deal Structure':
      "Exelixis will pay GamaMabs $5 million upon signing of the agreement, make additional payments upon completion of closing conditions, and make additional milestone payments after closing, contingent upon various events. Once the transfer is completed, Exelixis will control 100% of GamaMabs' AMHR2 franchise technology including all assets pertaining to GamaMabs' monoclonal antibody drug product murlentamab (GM-102).",
    'FW Link1':
      'Exelixis Expands its Biotherapeutics Portfolio with Acquisition of GamaMabs Pharma’s First-in-Class Humanized Antibody Program Against a Novel Oncology Target;;https://firstwordpharma.com/story/5290503/'
  },
  {
    'Date Announced': '5-May-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'AstraZeneca',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Alchemab',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibodies, biomarkers',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Prostate cancer',
    'Deal Structure':
      "Alchemab and AstraZeneca will work together on a proof-of-concept study to build understanding of the fundamental biology of prostate cancer. Under the agreement, Alchemab's novel drug discovery platform will be used as a diagnostic tool through the identification of disease biomarkers with potential to inform the development of novel antibody-based therapeutics.",
    'FW Link1':
      'Alchemab to Collaborate With AstraZeneca to Use Alchemab’s Drug Discovery Platform for Prostate Cancer Study;;https://firstwordpharma.com/story/5291067/'
  },
  {
    'Date Announced': '5-May-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Ankyra Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Lonza',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Fusion protein',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      'Lonza will provide cell line optimization and stability assessment, process development, and drug substance and drug product manufacture for clinical supply.',
    'FW Link1':
      'Lonza and Ankyra Therapeutics Sign Agreement to Develop and Manufacture a Cytokine Fusion Protein;;https://firstwordpharma.com/story/5291947/'
  },
  {
    'Date Announced': '6-May-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Merck & Co.',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Fusion Pharmaceuticals',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody, radioimmunoconjugate',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'Keytruda, FPI-1434',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'IGF-1R, PD-1',
    'Deal Structure':
      'The planned Phase I/II combination trial will evaluate safety, tolerability and pharmacokinetics of FPI-1434 in combination with Keytruda and is expected to initiate approximately six to nine months after achieving the recommended Phase II dose in the ongoing Phase I study of FPI-1434 monotherapy. Under the terms of the agreement, Fusion will sponsor the study and Merck will supply Keytruda.',
    'FW Link1':
      "Fusion Pharmaceuticals Announces Clinical Collaboration with Merck to Evaluate Fusion's Targeted Alpha Therapy (TAT) in Combination with Merck's KEYTRUDA (pembrolizumab) in Patients With Solid Tumors Expressing IGF-1R;;https://firstwordpharma.com/story/5292727/"
  },
  {
    'Date Announced': '6-May-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'BeiGene',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Asieris Pharmaceuticals',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'APL-1202, tislelizumab',
    'Indication(s)': 'Bladder cancer',
    'Target(s)': 'MetAP2, PD-1',
    'FW Link1':
      'Asieris and BeiGene Enter into Clinical Collaboration to Evaluate Combinations of APL-1202 and Tislelizumab;;https://firstwordpharma.com/story/5292729/'
  },
  {
    'Date Announced': '5-Jan-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Hemogenyx Pharmaceuticals',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'University of Pennsylvania',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'CAR-T cell therapy',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'HEMO-CAR-T',
    'Indication(s)': 'Acute myeloid leukemia',
    'Deal Structure':
      'Under the Agreement, the Company will retain Penn to conduct translational research activities in support of the research being performed under the existing Sponsored Research Agreement.',
    'FW Link1':
      'Hemogenyx Pharmaceuticals PLC Announces CAR-T Master Translational Agreement with University of Pennsylvania;;https://firstwordpharma.com/story/5192491/'
  },
  {
    'Date Announced': '11-Jan-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'GRAIL',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Amgen',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostic',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      "The collaboration will evaluate GRAIL's methylation-based technology for the detection of minimal residual disease (MRD).",
    'FW Link1':
      'GRAIL Announces Collaborations with Amgen, AstraZeneca, and Bristol Myers Squibb to Evaluate Cancer Early Detection Technology for Minimal Residual Disease;;https://firstwordpharma.com/story/5196311/'
  },
  {
    'Date Announced': '11-Jan-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'GRAIL',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'AstraZeneca',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostic',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      "The collaboration will evaluate GRAIL's methylation-based technology for the detection of minimal residual disease (MRD).",
    'FW Link1':
      'GRAIL Announces Collaborations with Amgen, AstraZeneca, and Bristol Myers Squibb to Evaluate Cancer Early Detection Technology for Minimal Residual Disease;;https://firstwordpharma.com/story/5196311/'
  },
  {
    'Date Announced': '11-Jan-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'GRAIL',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'BMS',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostic',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      "The collaboration will evaluate GRAIL's methylation-based technology for the detection of minimal residual disease (MRD).",
    'FW Link1':
      'GRAIL Announces Collaborations with Amgen, AstraZeneca, and Bristol Myers Squibb to Evaluate Cancer Early Detection Technology for Minimal Residual Disease;;https://firstwordpharma.com/story/5196311/'
  },
  {
    'Date Announced': '11-Jan-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Illumina',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'BMS',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Companion diagnostic',
    'Lead Asset(s)': 'TruSight Oncology',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Expanding on a collaboration that began in 2018, Bristol Myers Squibb will develop a microsatellite instability CDx, as well as develop a diagnostic based on the content of TruSight Oncology 500 ctDNA. Both program expansions are planned for global use by Bristol Myers Squibb's portfolio of cancer therapeutics.",
    'FW Link1':
      'Illumina Announces New and Expanded Oncology Partnerships with Bristol Myers Squibb, Kura Oncology, Myriad Genetics, and Merck to Advance Comprehensive Genomic Profiling;;https://firstwordpharma.com/story/5196943/'
  },
  {
    'Date Announced': '11-Jan-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Illumina',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Kura Oncology',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Companion diagnostic',
    'Lead Asset(s)': 'TruSight Oncology',
    'Indication(s)': 'Head and neck squamous cell carcinoma',
    'Target(s)': 'HRAS',
    'Deal Structure':
      'The partnership with Kura Oncology is focused on building a CDx claim for HRAS mutations in Head and Neck Squamous Cell Carcinomas',
    'FW Link1':
      'Illumina Announces New and Expanded Oncology Partnerships with Bristol Myers Squibb, Kura Oncology, Myriad Genetics, and Merck to Advance Comprehensive Genomic Profiling;;https://firstwordpharma.com/story/5196943/'
  },
  {
    'Date Announced': '11-Jan-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Illumina',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Myriad Genetics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Companion diagnostic',
    'Lead Asset(s)': 'TruSight Oncology',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Illumina is partnering with Myriad, with time-limited exclusivity in certain markets, to develop and commercialise distributed kits for the assessment of HRD and for Myriad to expand its HRD service offerings, through a combination of TruSight Oncology content and Myriad's myChoice CDx test.",
    'FW Link1':
      'Illumina Announces New and Expanded Oncology Partnerships with Bristol Myers Squibb, Kura Oncology, Myriad Genetics, and Merck to Advance Comprehensive Genomic Profiling;;https://firstwordpharma.com/story/5196943/'
  },
  {
    'Date Announced': '11-Jan-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Illumina',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Merck & Co.',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostic, biomarkers',
    'Lead Asset(s)': 'TruSight Oncology',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Illumina and Merck are conducting a study focused on the expanded TruSight Oncology HRD offering.',
    'FW Link1':
      'Illumina Announces New and Expanded Oncology Partnerships with Bristol Myers Squibb, Kura Oncology, Myriad Genetics, and Merck to Advance Comprehensive Genomic Profiling;;https://firstwordpharma.com/story/5196943/'
  },
  {
    'Date Announced': '12-Jan-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'MedC Biopharma',
    'Country (Acquiring Company/Partner)': 'Canada',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Sana Healthcare',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cannabinoid-containing drug',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Cutaneous T-cell lymphoma, Sezary syndrome',
    'Deal Structure':
      'The MedC/Sana partnership will work towards establishing orphan drug designation for this product in the UK, government reimbursement for patients and market exclusivity.',
    'FW Link1':
      'MedC Biopharma and Sana Healthcare Partner to Advance Skin Oncology Platform;;https://firstwordpharma.com/story/5198289/'
  },
  {
    'Date Announced': '12-Jan-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'POINT Biopharma',
    'Country (Acquiring Company/Partner)': 'Canada',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'CanProbe',
    'Country (Target Company/Partner)': 'Canada',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Radiopharmaceutical',
    'Lead Asset(s)': 'Lu-DOTATATE',
    'Indication(s)': 'Neuroendocrine cancer',
    'Deal Structure':
      'POINT Biopharma signed a licensing and commercialisation agreement with CanProbe for exclusive rights to Lutetium - 177 Octreotate (Lu-DOTATATE) for the treatment of neuroendocrine cancer.',
    'FW Link1':
      'POINT Biopharma Signs Exclusive License Agreement with CanProbe for the Commercialization of a Neuroendocrine Tumor Treatment;;https://www.firstwordhealthtech.com/story/5198315/'
  },
  {
    'Date Announced': '13-Jan-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Astellas',
    'Country (Acquiring Company/Partner)': 'Japan',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Actinium Pharmaceuticals',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Theranostics',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "In this collaboration, the potential therapeutic effect will be assessed by combining certain targeted oncology drugs that were discovered by Astellas based on its drug discovery capabilities cultivated thus far with Actinium's nuclear medicine technology which utilises an alpha particle-emitting radioisotope3 (Actinium-225). Astellas is conducting preclinical trials of the diagnostic agents on the target molecule.",
    'FW Link1':
      'Aiming for the development of theranostics, which integrates diagnostics and therapeutics, as one of the Rx+ businesses;;https://www.firstwordhealthtech.com/story/5198991/'
  },
  {
    'Date Announced': '13-Jan-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'J&J',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'ESSA Pharma',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'Erleada, Zytiga, EPI-7386',
    'Indication(s)': 'Prostate cancer',
    'Target(s)': 'Androgen receptor',
    'Deal Structure':
      'Under the terms of the agreement, J&J may sponsor and conduct up to two Phase I/II studies evaluating the safety, tolerability and preliminary efficacy of the combination of EPI-7386 and Erleada (apalutamide), as well as the combination of EPI-7386 with Zytiga (abiraterone acetate) plus prednisone in patients with mCRPC who have failed a current second-generation antiandrogen therapy. Janssen will assume all costs associated with the studies, other than the manufacturing costs associated with the clinical drug supply of EPI-7386. The parties will form a joint oversight committee for the clinical studies, which are planned to start in 2021.',
    'FW Link1':
      'ESSA Pharma Announces Clinical Collaboration with Janssen to Evaluate EPI-7386 Combination for Patients with Metastatic Castration-Resistant Prostate Cancer;;https://firstwordpharma.com/story/5199125/',
    'FW Link2':
      'Suspension of enrolment announced October 31, 2022 https://firstwordpharma.com/story/5669237/'
  },
  {
    'Date Announced': '13-Jan-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'UroGen Pharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner':
      'The University of Texas MD Anderson Cancer Center',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immunotherapy',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'UGN-302',
    'Indication(s)': 'Bladder cancer',
    'Target(s)': 'TLR 7/8, CTLA-4',
    'Deal Structure':
      'Under the 3-year strategic collaboration, MD Anderson and UroGen will work together on designing and carrying out clinical and non-clinical studies of UGN-302. A joint steering committee will provide oversight. Funding will be provided by UroGen.',
    'FW Link1':
      'UroGen Pharma and MD Anderson Announce Strategic Research Collaboration to Advance Investigational Treatment for High-Grade Bladder Cancer;;https://firstwordpharma.com/story/5199287/'
  },
  {
    'Date Announced': '13-Jan-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Cytovia Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'National Cancer Institute',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'CAR-NK cell therapy',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Solid tumours, hepatocellular carcinoma',
    'Target(s)': 'GPC3',
    'Deal Structure':
      "Cytovia Therapeutics has signed a licensing agreement with the National Cancer Institute to apply its gene-edited iPSC-derived NK cell technology to develop GPC3 CAR NK cell therapeutics. Cytovia has also signed a Cooperative Research and Development Agreement (CRADA) with the National Cancer Institute. Under the CRADA, Cytovia will collaborate with Dr Mitchell Ho's laboratory to develop and evaluate gene-edited iPSC-derived GPC3 CAR NK cells.",
    'FW Link1':
      'Cytovia Therapeutics Partners with National Cancer Institute to Develop Novel Gene-Edited, iPSC-Derived GPC3 CAR NK Cells for the Treatment of Solid Tumors;;https://firstwordpharma.com/story/5199347/'
  },
  {
    'Date Announced': '13-Jan-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'HiberCell',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Biodesix',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Companion diagnostic, immunotherapy',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'Imprime PGG',
    'Indication(s)': 'Breast cancer',
    'Target(s)': 'Dectin-1',
    'Deal Structure':
      "Initial programme to focus on development of a companion diagnostic for HiberCell's novel immunotherapy, Imprime PGG, to aid in patient selection across multiple oncology programmes. Terms of the partnership were not disclosed.",
    'FW Link1':
      'HiberCell and Biodesix Initiate Broad Collaboration for Companion Diagnostic Discovery, Development and Commercialization;;https://firstwordpharma.com/story/5199493/'
  },
  {
    'Date Announced': '14-Jan-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Oncternal Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Lentigen Technology',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Lentiviral vectors, CAR-T cell therapy',
    'Indication(s)': 'Haematologic cancers, solid tumours',
    'Target(s)': 'ROR1',
    'Deal Structure':
      "As part of the agreement, Lentigen will reserve capacity in 2021 to manufacture, test and release GMP lentivirus to support and accelerate the development of Oncternal's CAR-T cell therapy programme.",
    'FW Link1':
      'Oncternal Therapeutics, Inc. Confirms Lentigen Technology, Inc. to Manufacture Lentiviral Vectors for Its ROR1-targeting CAR-T Cell Therapy Program;;https://firstwordpharma.com/story/5200417/'
  },
  {
    'Date Announced': '14-Jan-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Debiopharm',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Nucleai',
    'Country (Target Company/Partner)': 'Israel',
    'Region (Target Company/Partner)': 'Middle East',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Biomarkers, companion diagnostics',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "The agreement includes an initial installment to Nucleai followed by success-based milestone payments should Debiopharm integrate Nucleai's biomarkers in its clinical studies and further develop them as companion diagnostics.",
    'FW Link1':
      "Nucleai and Debiopharm Launch Research Collaboration Utilizing Nucleai's Ai-Powered Biomarker Platform;;https://firstwordpharma.com/story/5200631/"
  },
  {
    'Date Announced': '26-Jan-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Agenus',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Nelum',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Checkpoint inhibitor, hedgehog inhibitor',
    'Lead Asset(s)': 'zalifrelimab, NLM-001',
    'Indication(s)': 'Pancreatic cancer',
    'Target(s)': 'CTLA-4',
    'Deal Structure':
      'Under the terms of the agreement, Agenus will supply zalifrelimab to Nelum for the combination study. Nelum will sponsor and be responsible for the conduct of the trial, which is set to begin enrolling in 1H21.',
    'FW Link1':
      'Agenus Enters into Clinical Collaboration with Nelum for Zalifrelimab Combination;;https://firstwordpharma.com/story/5208991/'
  },
  {
    'Date Announced': '27-Jan-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Celcuity',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Pfizer',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Companion diagnostics',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'CELsignia, Vizimpro, Xalkori',
    'Indication(s)': 'Breast cancer',
    'Target(s)': 'HER2, c-Met',
    'Deal Structure':
      'Under the agreement, Sarah Cannon Research Institute will serve as the sponsor and Erika Hamilton, MD, will serve as the principal investigator of this study. Pfizer will supply VIZIMPRO and XALKORI. Celcuity will provide its CELsignia Multi-Pathway Activity Test to select patients with HER2-negative metastatic breast cancer who have hyperactive HER2 and c-Met signalling pathways for the trial and will fund the patient-related trial costs.',
    'FW Link1':
      'Celcuity Announces Breast Cancer Clinical Trial Collaboration with Sarah Cannon and Pfizer to Study New Drug Regimen;;https://firstwordpharma.com/story/5211091/'
  },
  {
    'Date Announced': '27-Jan-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'BMS',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Tempus',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Biomarkers',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'As part of the expanded agreement, Bristol Myers Squibb has licensed additional data cohorts across multiple tumour types, with access to terabytes of new data. Each company is focused on identifying better ways to treat patients with unmet needs through the analysis of multi-modal data including whole-transcriptome sequencing data.',
    'FW Link1':
      'Tempus Expands Licensing Agreement with Bristol Myers Squibb to Analyze and Discover RNA-Based Targets;;https://www.firstwordhealthtech.com/story/5211663/'
  },
  {
    'Date Announced': '3-Feb-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Affimed',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Roche',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immunotherapy',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'AFM24, Tecentriq',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'CD16A, EGFR, PD-L1',
    'Deal Structure':
      'Under the terms of the agreement, Affimed will fund and conduct a Phase I/IIa trial to investigate the combination of AFM24 and atezolizumab for the treatment of advanced solid epidermal growth factor receptor (EGFR) expressing malignancies in patients whose disease has progressed after treatment with previous anticancer therapies. Roche will supply Affimed with atezolizumab for the clinical trial. The Phase I/IIa study will establish a dosing regimen for the combination therapy and assess safety and potential activity.',
    'FW Link1':
      'Affimed Announces Collaboration with Roche to Study AFM24 in Combination with PD-L1 Checkpoint Inhibitor in EGFR Expressing Solid Tumors;;https://firstwordpharma.com/story/5215975/'
  },
  {
    'Date Announced': '8-Feb-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Deerfield Management Company',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Dana-Farber Cancer Institute',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Total Deal Value ($m)': '130',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Therapeutics, diagnostics',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Dana-Farber Cancer Institute and Deerfield Management Company have formed a major translational research partnership to accelerate the development of therapeutics and diagnostics for cancer. Deerfield has committed up to $130 million over the next 10 years to advance research at Dana-Farber and translate scientific discoveries with potential applications for patients.',
    'FW Link1':
      'Dana-Farber Cancer Institute and Deerfield Management Launch Riverway Discoveries;;https://firstwordpharma.com/story/5219211/'
  },
  {
    'Date Announced': '11-Feb-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Repertoire Immune Medicines',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Dana-Farber Cancer Institute',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'T cell therapy',
    'Indication(s)': 'HPV+ head and neck cancers',
    'Deal Structure':
      'As part of the collaboration, Dana-Farber will provide blood and tissue samples from patients with newly diagnosed, or with late-stage, recurrent HPV+ oropharyngeal cancer. Repertoire will use its DECODE platform to identify novel antigens and evaluate tumour antigen expression as well as profile the tumour microenvironment. The results of this research collaboration will inform Repertoire’s selection of tumour antigens for T cell therapy investigation that have the potential to be integrated into clinical trials.',
    'FW Link1':
      'Repertoire Immune Medicines and Dana-Farber Cancer Institute Collaborate to Identify Novel Antigen Targets Within HPV+ Head and Neck Cancers;;https://firstwordpharma.com/story/5222777/'
  },
  {
    'Date Announced': '17-Feb-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Nektar Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Merck & Co.',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immunotherapy',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'bempegaldesleukin, Keytruda',
    'Indication(s)': 'Squamous cell carcinoma of the head and neck',
    'Target(s)': 'PD-1, IL-2',
    'Deal Structure':
      "Nektar Therapeutics has entered into a clinical trial collaboration and supply agreement with Merck for a Phase II/III study of bempegaldesleukin (NKTR-214, BEMPEG) in combination with Merck's Keytruda (pembrolizumab) for first-line treatment of patients with metastatic or unresectable recurrent squamous cell carcinoma of the head and neck (SCCHN) whose tumours express PD-L1. Under the terms of the agreement, Nektar will conduct the Phase II/III study, which is expected to enrol 500 patients with metastatic or recurrent SCCHN with PD-L1 expressing tumours.",
    'FW Link1':
      "Nektar to test bempegaldesleukin with Merck & Co.'s Keytruda in SCCHN trial;;https://firstwordpharma.com/story/5227205/"
  },
  {
    'Date Announced': '17-Feb-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Theragnostics',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Essen University Hospital',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'PET imaging, radiolabelled PARP inhibitor',
    'Lead Asset(s)': 'THG-008',
    'Indication(s)': 'Cancer',
    'Target(s)': 'PARP',
    'Deal Structure':
      "Theragnostics has announced a research collaboration with Essen University Hospital to examine Theragnostics' investigational agent THG-008 for PET imaging in a range of cancers.",
    'FW Link1':
      'Theragnostics Announces a research collaboration with Essen University Hospital to study THG-008 for Oncology PET Imaging;;https://firstwordpharma.com/story/5227307/'
  },
  {
    'Date Announced': '18-Feb-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'GT Biopharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Cytovance Biologics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immunotherapy',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'GTB-3550 TriKE',
    'Indication(s)': 'Cancer',
    'Target(s)': 'NK cells',
    'Deal Structure':
      'GT Biopharma has signed an expanded GMP manufacturing agreement with Cytovance Biologics for the manufacture of all TriKEs.',
    'FW Link1':
      'GT Biopharma Transfers TriKE Manufacturing To Cytovance Biologics In Preparation For Expanded And Multiple Liquid And Solid Tumor Clinical Trials;;https://firstwordpharma.com/story/5228447/'
  },
  {
    'Date Announced': '22-Feb-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Compugen',
    'Country (Acquiring Company/Partner)': 'Israel',
    'Region (Acquiring Company/Partner)': 'Middle East',
    'Target Company/Partner': 'BMS',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immunotherapy',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'COM701, Opdivo',
    'Indication(s)':
      'Ovarian, breast, endometrial and microsatellite-stable colorectal cancers',
    'Target(s)': 'PVRIG, PD-1',
    'Deal Structure':
      "Under the terms of the amendment, Bristol Myers Squibb will continue to supply Opdivo to the Compugen-sponsored study. The Phase Ib study, a part of Compugen's COM701 monotherapy and combination therapy dose escalation and expansion programme, will examine fixed doses of COM701 and Opdivo, as determined by Compugen's Phase Ia combination dose escalation study.",
    'FW Link1':
      'Compugen Expands Clinical Collaboration Agreement with Bristol Myers Squibb with Phase 1b Combination Study of COM701 with Opdivo;;https://firstwordpharma.com/story/5230731/'
  },
  {
    'Date Announced': '22-Feb-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'NovalGen',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'HALIX',
    'Country (Target Company/Partner)': 'Netherlands',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immunotherapy, bispecific antibodies',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'NVG-111',
    'Indication(s)': 'Haematologic malignancies, solid tumours',
    'Target(s)': 'ROR1',
    'Deal Structure':
      "Within the scope of this partnership, HALIX will provide manufacturing of clinical supply of NovalGen's therapies.",
    'FW Link1':
      'NovalGen Enters Strategic Partnership with HALIX B.V. to Manufacture Clinical Trial Materials;;https://firstwordpharma.com/story/5230799/'
  },
  {
    'Date Announced': '24-Feb-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'INOVIO',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'QIAGEN',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Companion diagnostic, next-generation sequencing, immunotherapy',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'VGX-3100',
    'Indication(s)': 'Cervical dysplasia',
    'Deal Structure':
      "QIAGEN and INOVIO in 2019 announced a collaboration to develop a companion diagnostic to guide clinical decision-making for the use of INOVIO's DNA-based immunotherapy to treat cervical dysplasia caused by HPV. The new master collaboration agreement covers the development of companion diagnostics for INOVIO's HPV therapies for a range of sample types and technologies such as PCR and NGS.",
    'FW Link1':
      "INOVIO and QIAGEN expand collaboration to develop next generation sequencing (NGS) companion diagnostic for INOVIO's VGX-3100 for advanced cervical dysplasia;;https://firstwordpharma.com/story/5232953/"
  },
  {
    'Date Announced': '25-Feb-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'ESSA Pharma',
    'Country (Acquiring Company/Partner)': 'Canada',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Astellas Pharma',
    'Country (Target Company/Partner)': 'Japan',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Anti-androgen therapies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'EPI-7386, enzalutamide',
    'Indication(s)': 'Metastatic castration-resistant prostate cancer',
    'Target(s)': 'Androgen receptor',
    'Deal Structure':
      'Under the terms of the agreement, ESSA will sponsor and conduct a Phase I/II study to evaluate the safety, tolerability and preliminary efficacy of the combination of EPI-7386 and enzalutamide in mCRPC patients who have not yet been treated with second-generation antiandrogen therapies. Astellas will supply enzalutamide for the trial. ESSA will retain all rights to EPI-7386.',
    'FW Link1':
      'ESSA Pharma Announces Clinical Collaboration with Astellas to Evaluate the Combination of EPI-7386 and Enzalutamide for Patients with Metastatic Castration-Resistant Prostate Cancer;;https://firstwordpharma.com/story/5234109/'
  },
  {
    'Date Announced': '25-Feb-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'LIDDS',
    'Country (Acquiring Company/Partner)': 'Sweden',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'PharmIdea',
    'Country (Target Company/Partner)': 'Latvia',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Drug delivery, TLR agonists',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'NZ-TLR9',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'TLR9',
    'Deal Structure':
      'LIDDS announced that a collaboration agreement has been signed with the R&D and production company PharmIdea. The development of the NZ-TLR9 formulation will be the first project within the collaboration between LIDDS and PharmIdea.',
    'FW Link1':
      'LIDDS and Pharmidea signs manufacturing agreement;;https://firstwordpharma.com/story/5234397/'
  },
  {
    'Date Announced': '25-Feb-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Enveric Biosciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'PureForm Global',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cannabinoid medicines',
    'Indication(s)': 'Pain and inflammation resulting from cancer treatments',
    'Deal Structure':
      "Under the terms of the agreement, PureForm will work exclusively to support Enveric's development plans in the cancer treatment and supportive care space, with Enveric owning the intellectual property.",
    'FW Link1':
      'Enveric Biosciences Launches Development Collaboration and Supply Agreement with PureForm Global to Support Cannabinoid Clinical Programs;;https://firstwordpharma.com/story/5234463/'
  },
  {
    'Date Announced': '25-Feb-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'SHEPHERD Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Mayo Clinic',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Precision oncology',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Rhabdomyosarcoma',
    'Deal Structure':
      "As part of the collaboration, SHEPHERD and Mayo clinicians and researchers, Dr. Patricio Gargollo, Dr. Candace Granberg, and Dr. Fabrice Lucien-Matteoni, will use SHEPHERD's proprietary, next-generation precision-oncology platform, DELVE, to identify mechanisms for drug response and resistance in RMS patients, and to identify new single drug and multi-drug combinations that have the potential to impact RMS care.",
    'FW Link1':
      "SHEPHERD Therapeutics and Mayo Clinic Announce Collaboration to Advance Research in Rhabdomyosarcoma Using SHEPHERD's Precision-Oncology Platform, DELVE;;https://firstwordpharma.com/story/5234789/"
  },
  {
    'Date Announced': '1-Mar-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Enlivex Therapeutics',
    'Country (Acquiring Company/Partner)': 'Israel',
    'Region (Acquiring Company/Partner)': 'Middle East',
    'Target Company/Partner': 'Mount Sinai Health System',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Immunotherapy, off-the-shelf cell therapy, checkpoint inhibitors',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'Allocetra',
    'Indication(s)': 'Solid cancers',
    'Target(s)': 'Macrophages',
    'Deal Structure':
      'Under the strategic collaboration agreement, the parties have agreed to develop and execute a preclinical programme to investigate the potential synergies between Allocetra and commercially approved checkpoint inhibitor therapies for select solid cancers.',
    'FW Link1':
      'Enlivex and Mount Sinai Health System Announce Research Collaboration for the Development of Allocetra in Combination with Immune Checkpoint Inhibitors;;https://firstwordpharma.com/story/5237383/'
  },
  {
    'Date Announced': '3-Mar-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Oasmia Pharmaceutical',
    'Country (Acquiring Company/Partner)': 'Sweden',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Karolinska Institutet',
    'Country (Target Company/Partner)': 'Sweden',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Drug delivery',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer, ovarian carcinoma',
    'Deal Structure':
      'The collaboration will include a review of data and experimental methods to gain a deeper understanding of XR-17 and API formulations in cancer indications with a focus on ovarian carcinoma. This will be followed by an experimental planning phase, which aims to test formulations in suitable systems to generate hypotheses for subsequent evaluation.',
    'FW Link1':
      'Oasmia and Karolinska Institutet to collaborate on the biological potential of Oasmia’s proprietary drug delivery platform;;https://firstwordpharma.com/story/5240153/'
  },
  {
    'Date Announced': '8-Mar-2021',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'Cancer Access Partnership',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Biocon Biologics',
    'Country (Target Company/Partner)': 'India',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Africa, Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Biosimilars',
    'Lead Asset Stage/Phase': 'Marketed',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Biocon Biologics will join the growing list of companies in the CAP which currently includes Novartis, Pfizer, and Viatris. This expanded partnership will make 26 medications in 39 formulations affordably available to low- and middle-income countries across Africa and Asia, including key chemotherapies, hormonal therapies, biologics, and supportive therapies essential for the treatment of 30 cancers, including breast cancer.',
    'FW Link1':
      'Clinton Health Access Initiative and American Cancer Society announce agreement to expand Cancer Access Partnership;;https://firstwordpharma.com/story/5243381/'
  },
  {
    'Date Announced': '8-Mar-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Cure Therapeutics',
    'Country (Acquiring Company/Partner)': 'Korea, South',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Orgenesis',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'South Korea',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Cell and gene therapies, immunotherapies, point-of-care development',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'The teams will align to utilise Orgenesis Mobile Processing Units and Labs (OMPULs) for point-of-care development of cell and gene therapies and immunotherapies that meet regulatory and governmental approval standards to scale through to commercialisation. Process development and validation for the therapies will be completed at the Dong-a University leveraging the technologies in the Orgenesis POCare Platform to automate the processes for quality and reproducibility.',
    'FW Link1':
      'Orgenesis Mobile Processing Units and Labs (OMPULs) to be Used for Point-of-Care Production and Commercialization of Cell and Gene Therapies in Collaboration with Dong-a University Hospital and Cure Therapeutics in South Korea;;https://firstwordpharma.com/story/5243461/'
  },
  {
    'Date Announced': '9-Mar-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Genome & Company',
    'Country (Acquiring Company/Partner)': 'Korea, South',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Merck KGaA',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immuno-oncology microbiome therapeutic',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'GEN-001, avelumab',
    'Indication(s)': 'Gastric cancers',
    'Target(s)': 'PD-L1',
    'Deal Structure':
      'Under the terms of these agreements, Genome and Company will be the sponsor of the studies, and Merck KGaA, Darmstadt, Germany and Pfizer will supply avelumab for both Study 101 and Study 201. Both parties will have access to the clinical data.',
    'FW Link1':
      'Genome and Company signs second clinical trial collaboration and supply agreement (Phase 2) with Merck KGaA, Darmstadt, Germany and Pfizer for immuno-oncology microbiome study;;https://firstwordpharma.com/story/5244195/'
  },
  {
    'Date Announced': '9-Mar-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Orionis Biosciences',
    'Country (Acquiring Company/Partner)': 'Belgium',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'University of Texas MD Anderson Cancer Center',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small-molecule drugs, drug discovery',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Combining Orionis' unique high-throughput drug discovery technologies with the Therapeutics Discovery division's expertise in small-molecule therapies and translational biology, Project Helios aims to create an unparalleled collection of drug-target interaction data to enable rational drug discovery, optimisation and repurposing. The project will focus initially on developing therapies for unmet needs in oncology, with the possibility of expanding to additional therapeutic areas in the future.",
    'FW Link1':
      'MD Anderson’s Therapeutics Discovery Division, Orionis Biosciences Launch Project Helios to Advance Novel Small-Molecule Cancer Medicines;;https://firstwordpharma.com/story/5244529/'
  },
  {
    'Date Announced': '9-Mar-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Boston Immune Technologies and Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Selexis',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antagonist antibodies',
    'Lead Asset(s)': 'BITR2101',
    'Indication(s)': 'Cancer',
    'Target(s)': 'TNFR2',
    'Deal Structure':
      "Selexis and Boston Immune Technologies and Therapeutics (BITT) have entered into a service agreement to develop the cell line for BITR2101, BITT's lead tumour necrosis factor receptor 2 (TNFR2) antagonist antibody for the treatment of cancers and infectious diseases. Under the agreement, BITT will leverage Selexis' SUREtechnology Platform, a suite of proprietary modular technologies designed to overcome the complex challenges of protein-expression and deliver the high performance research cell banks necessary to pursue clinical development.",
    'FW Link1':
      'Selexis and Boston Immune Technologies and Therapeutics (BITT) Enter Service Agreement to Advance BITT’s Lead TNFR2 Oncology Candidate;;https://firstwordpharma.com/story/5244561/'
  },
  {
    'Date Announced': '10-Mar-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'I-Mab',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Complix',
    'Country (Target Company/Partner)': 'Belgium',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immuno-oncology, Alphabodies',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'The collaborations with Complix, an EU-based biotech company, and Affinity, a Shanghai-based biotech company, allow I-Mab access to cutting edge technology platforms to create next generation of novel and highly differentiated drug candidates, including Cell Penetrating Alphabodies (CPAB) for otherwise intractable intracellular drug targets and masked antibodies for targeted tumour-site activation, respectively. Both partner companies will receive undisclosed milestone fees stipulated in the partnership agreements.',
    'FW Link1':
      'I-Mab Launches Discovery Initiative to Expand Transformational Immuno-Oncology Pipeline;;https://firstwordpharma.com/story/5245485/'
  },
  {
    'Date Announced': '10-Mar-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'I-Mab',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Shanghai Affinity Biopharmaceutical Co.',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immuno-oncology, masked antibodies',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'The collaborations with Complix, an EU-based biotech company, and Affinity, a Shanghai-based biotech company, allow I-Mab access to cutting edge technology platforms to create next generation of novel and highly differentiated drug candidates, including Cell Penetrating Alphabodies (CPAB) for otherwise intractable intracellular drug targets and masked antibodies for targeted tumour-site activation, respectively. Both partner companies will receive undisclosed milestone fees stipulated in the partnership agreements.',
    'FW Link1':
      'I-Mab Launches Discovery Initiative to Expand Transformational Immuno-Oncology Pipeline;;https://firstwordpharma.com/story/5245485/'
  },
  {
    'Date Announced': '15-Mar-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Pfizer',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'National Health Research Institutes ',
    'Country (Target Company/Partner)': 'Taiwan',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Taiwan',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Precision medicine',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Pfizer Taiwan and the National Health Research Institutes (NHRI), along with two other partnering companies, recently signed a precision medicine cooperation alliance agreement, officially launching a joint initiative to develop precision cancer treatments in Taiwan. The agreement commits all parties to the promotion of research and development on testing and precision medicine treatments for gene mutations that cause cancers.',
    'FW Link1':
      "Pfizer and Taiwan's National Health Research Institutes Sign Precision Medicine Agreement;;https://firstwordpharma.com/story/5249265/"
  },
  {
    'Date Announced': '16-Mar-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Immutep',
    'Country (Acquiring Company/Partner)': 'Australia',
    'Region (Acquiring Company/Partner)': 'Oceania',
    'Target Company/Partner': 'Merck & Co.',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'United States, Australia, Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immuno-oncology',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'eftilagimod alpha, Keytruda',
    'Indication(s)': 'Head and neck squamous cell carcinoma',
    'Deal Structure':
      'Under the agreement, Immutep will conduct a new Phase IIb clinical trial in 1st line HNSCC patients.',
    'FW Link1':
      'Immutep Enters Second Collaboration With MSD For A New Randomised Phase IIB Trial In Head And Neck Cancer;;https://firstwordpharma.com/story/5250133/'
  },
  {
    'Date Announced': '16-Mar-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Geneos Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'VGXI',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Personalised immunotherapy',
    'Indication(s)': 'Hepatocellular carcinoma',
    'Target(s)': 'Neoantigens',
    'Deal Structure':
      'VGXI announced that its partner Geneos Therapeutics has executed a new purchasing contract under their current Clinical Manufacturing Supply Agreement to support the expansion of the ongoing GT-30 clinical trial.',
    'FW Link1':
      'VGXI and Geneos Therapeutics Expand Partnership for Personalized Cancer Immunotherapies;;https://firstwordpharma.com/story/5250239/'
  },
  {
    'Date Announced': '16-Mar-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Gilead Sciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'COTA',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Real-world data',
    'Indication(s)': 'Blood cancers',
    'Deal Structure':
      "COTA announced a collaboration with Kite, a Gilead company, through which COTA's real-world data will be used to accelerate Kite's drug development pipeline. Kite will work closely with oncologists, data scientists, and researchers at COTA to develop and validate real-world endpoints and accelerate the clinical development timeline for patient benefit.",
    'FW Link1':
      'COTA, Inc. and Kite Collaborate To Expand the Use of Real-World Data To Drive Faster, Effective Treatments for Cancers;;https://firstwordpharma.com/story/5250273/'
  },
  {
    'Date Announced': '16-Mar-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Elevation Oncology',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'NeoGenomics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Genomic testing, precision oncology therapeutics',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'seribantumab',
    'Indication(s)': 'Solid tumours with an NRG1 fusion',
    'Target(s)': 'HER3',
    'Deal Structure':
      'Elevation Oncology and NeoGenomics announced a collaboration to enhance identification of patients with any solid tumour harbouring an NRG1 fusion who may be eligible for enrolment in the Phase II CRESTONE study.',
    'FW Link1':
      'Elevation Oncology and NeoGenomics Announce Collaboration to Expand Genomic Testing for NRG1 Fusions Across Solid Tumors in Support of the Phase 2 CRESTONE Study;;https://firstwordpharma.com/story/5250275/'
  },
  {
    'Date Announced': '17-Mar-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'T-Cure Bioscience',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Rutgers, The State University of New Jersey',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immuno-oncology, T cell receptor therapy',
    'Lead Asset Stage/Phase': 'Phase I',
    'Indication(s)': 'Tumours expressing KK-LC-1',
    'Target(s)': 'KK-LC-1',
    'Deal Structure':
      'T-Cure Bioscience has entered into a clinical research agreement with Rutgers, The State University of New Jersey, to fund a Phase I study testing a TCR-based product candidate for the treatment of tumours expressing Kita-Kyushu lung cancer antigen 1 (KK-LC-1), such as gastric, cervical, lung, and triple negative breast cancers.',
    'FW Link1':
      'T-Cure Bioscience Enters Into Agreement With Rutgers to Conduct Clinical Research of Novel T Cell Receptor Therapy for Treatment of Variety of Cancers;;https://firstwordpharma.com/story/5251319/'
  },
  {
    'Date Announced': '17-Mar-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Repare Therapeutics',
    'Country (Acquiring Company/Partner)': 'Canada',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Valence Discovery',
    'Country (Target Company/Partner)': 'Canada',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'AI-enabled drug discovery, precision oncology',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "This agreement will combine Valence's unique expertise in few-shot learning, generative chemistry, and multiparameter optimisation with Repare's expertise in target identification and medicinal chemistry to rapidly optimise drug candidates against multiple potency, selectivity, safety, and pharmacology criteria.",
    'FW Link1':
      'Valence Discovery to Support Repare Therapeutics on AI-Enabled Drug Discovery in Precision Oncology;;https://firstwordpharma.com/story/5251565/'
  },
  {
    'Date Announced': '8-Feb-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': '4D pharma',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Merck KGaA',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Live biotherapeutic product, immunotherapy',
    'Lead Asset(s)': 'MRx0518, Bavencio',
    'Indication(s)': 'Urothelial carcinoma',
    'Deal Structure':
      'Under the collaboration, 4D pharma intends to commence a clinical trial in 2021 to evaluate Bavencio in combination with MRx0518 as a first-line maintenance therapy for patients with locally advanced or metastatic urothelial carcinoma that has not progressed with first-line platinum-containing chemotherapy.',
    'FW Link1':
      '4D pharma announces clinical trial collaboration and supply agreement with Merck KGaA, Darmstadt, Germany and Pfizer to Evaluate MRx0518 in Combination with BAVENCIO for the Treatment of Locally Advanced or Metastatic Urothelial Carcinoma;;https://firstwordpharma.com/story/5219161/'
  },
  {
    'Date Announced': '18-Mar-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Exelixis',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Merck KGaA',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Tyrosine kinase inhibitor, immunotherapy',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'XL092, Bavencio',
    'Indication(s)': 'Urothelial carcinoma',
    'Target(s)': 'VEGF receptors, kinases, PD-L1',
    'Deal Structure':
      'Exelixis is sponsoring the STELLAR-001 clinical trial, and Merck KGaA and Pfizer will provide avelumab for use in the trial.',
    'FW Link1':
      'Exelixis Announces Clinical Trial Collaboration and Supply Agreement with Merck KGaA, Darmstadt, Germany and Pfizer to Evaluate XL092 and Avelumab in Various Forms of Locally Advanced or Metastatic Urothelial Carcinoma;;https://firstwordpharma.com/story/5252129/'
  },
  {
    'Date Announced': '18-Mar-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Boston Pharmaceuticals',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'GSK',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibody, small molecule inhibitor',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'GSK3903371, GSK3502421',
    'Indication(s)': 'Cancer',
    'Target(s)':
      'Interleukin-1 receptor accessory protein, receptor interacting serine/threonine kinase 1',
    'Deal Structure':
      'Under the agreement, Boston Pharmaceuticals will be responsible for further development of select programmes through proof-of-concept (PoC). Following the completion of PoC studies, GSK will have the option to reacquire each programme under pre-agreed terms for subsequent development and worldwide commercialisation. If GSK exercises its repurchase option, Boston Pharmaceuticals will receive a one-time payment, as well as be eligible for approval and sales milestones and royalties. In the event GSK chooses not to reacquire a programme, Boston Pharmaceuticals may continue development and potential commercialisation of the programme. GSK will then be eligible to receive milestones and royalty payments.',
    'FW Link1':
      'Boston Pharmaceuticals Enters into Unique Multi-Year Out-License and Option Agreement with GSK for the Advancement of Multiple Pre-Phase 2 Programs;;https://firstwordpharma.com/story/5252239/'
  },
  {
    'Date Announced': '12-Apr-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Leap Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Flagship Biosciences',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Humanised monoclonal therapeutic antibody, DKK1 RNAscope laboratory developed test',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'DKN-01',
    'Indication(s)': 'Gastric/gastro-oesophageal junction adenocarcinoma',
    'Target(s)': 'Dickkopf-1 (DKK1)',
    'Deal Structure':
      "To select patients for their clinical study, Leap Therapeutics sends samples from the United States and the Republic of Korea to Flagship's centralised laboratory. Flagship conducts the RNAscope assay, image analysis, data analysis, and in-house pathologist review, providing the information needed to make clinical trial enrolment decisions.",
    'FW Link1':
      'Flagship Biosciences and Leap Therapeutics Announce Partnership and Approach Using RNAscope and Image Analysis for Patient Enrollment;;https://firstwordpharma.com/story/5271913/'
  },
  {
    'Date Announced': '13-Apr-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'BridgeBio Pharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Roswell Park Comprehensive Cancer Center',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Indication(s)': 'Genetically driven cancers',
    'Deal Structure':
      'Under the partnership, BridgeBio will work with scientists at Roswell Park Comprehensive Cancer Center to evaluate new research and development opportunities in oncology that have promise to advance and potentially provide clinical benefit for patients. Select therapeutic programmes may be pursued by BridgeBio, in close collaboration with Roswell Park Comprehensive Cancer Center scientists, who would remain deeply involved in the ongoing development of these investigational therapies.',
    'FW Link1':
      'BridgeBio Pharma, Inc. and Roswell Park Establish Collaboration to Translate Pioneering Research into Therapies for Genetically Driven Cancers;;https://firstwordpharma.com/story/5272311/'
  },
  {
    'Date Announced': '13-Apr-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'BridgeBio Pharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner':
      'The Lundquist Institute for Biomedical Innovation',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Indication(s)': 'Genetically driven diseases and cancers',
    'Deal Structure':
      'Under the established two-year partnership, BridgeBio will work closely with The Lundquist Institute researchers to identify programmes that may have the potential to become therapies for patients with genetically driven conditions and cancers. BridgeBio will potentially sponsor research programmes and support the development of Lundquist programmes and clinical investigations through its licensing and affiliate development model.',
    'FW Link1':
      'BridgeBio Pharma, Inc. and The Lundquist Institute Collaborate to Advance Treatments for Genetically Driven Conditions and Cancers;;https://firstwordpharma.com/story/5272321/'
  },
  {
    'Date Announced': '13-Apr-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'BridgeBio Pharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Oregon Health & Science University',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Indication(s)': 'Genetically driven conditions, including cancers',
    'Deal Structure':
      'Under the agreement, the BridgeBio team will work closely with OHSU scientists and investigators to advance potential medicines for patients with genetically driven conditions, including cancers.',
    'FW Link1':
      'BridgeBio Pharma, Inc. and Oregon Health & Science University Establish Collaboration to Translate Pioneering Research into Therapies for Genetically Driven Diseases;;https://firstwordpharma.com/story/5272327/'
  },
  {
    'Date Announced': '14-Apr-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'TILT Biotherapeutics',
    'Country (Acquiring Company/Partner)': 'Finland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Merck Sharp & Dohme',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immunotherapy, oncolytic adenovirus',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'TILT-123, Keytruda',
    'Indication(s)': 'Ovarian cancer',
    'Target(s)': 'PD-1',
    'Deal Structure':
      "TILT Biotherapeutics announced a clinical trial collaboration agreement with MSD to evaluate TILT's adenoviral cancer immunotherapy TILT-123, a dual cytokine armed oncolytic adenovirus, in combination with KEYTRUDA (pembrolizumab), MSD's anti-PD-1 therapy, in a Phase I dose escalation trial in platinum resistant or refractory ovarian cancer.",
    'FW Link1':
      'TILT Biotherapeutics and MSD to Collaborate on Clinical Trial of Adenoviral Cancer Immunotherapeutic TILT-123 in Combination with KEYTRUDA (pembrolizumab) for Ovarian Cancer;;https://firstwordpharma.com/story/5273937/'
  },
  {
    'Date Announced': '15-Apr-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Immunitas Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Lonza',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibody',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'IMT-009',
    'Indication(s)': 'Solid tumours, haematological malignancies',
    'Target(s)': 'CD161',
    'Deal Structure':
      "Under the terms of the agreement, Lonza's Ibex Design end-to-end offering will cover the development stages of IMT-009 from development candidate through early clinical studies.",
    'FW Link1':
      'Lonza and Immunitas Therapeutics Announce Manufacturing Agreement to Rapidly Advance Lead Program to the Clinic;;https://firstwordpharma.com/story/5275015/'
  },
  {
    'Date Announced': '19-Apr-2021',
    'Deal type': 'Product Acquisition',
    'Acquiring Company/Partner': 'Valo Therapeutics',
    'Country (Acquiring Company/Partner)': 'Finland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'University of Helsinki',
    'Country (Target Company/Partner)': 'Finland',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immunotherapy, vaccines, PeptiBAC',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Valo Therapeutics announced the acquisition of intellectual property rights for the proprietary 'PeptiBAC' technology from the University of Helsinki, Finland.",
    'FW Link1':
      "Valo Therapeutics Acquires University of Helsinki's PeptiBAC Cancer and Infectious Disease Vaccination Technology;;https://firstwordpharma.com/story/5277079/"
  },
  {
    'Date Announced': '21-Apr-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'ExCellThera',
    'Country (Acquiring Company/Partner)': 'Canada',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Ossium Health',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Adult stem cells, regenerative medicine',
    'Indication(s)':
      'Blood cancers, solid organ tolerisation, damage from radiation',
    'Deal Structure':
      "The collaboration plans to evaluate and advance opportunities to employ adult stem cells from deceased donors from Ossium Health's first-in-the-world bone marrow bank in conjunction with ExCellThera's ECT-001 cell expansion and rejuvenation technology, comprised of the UM171 small molecule and an optimised culture system, to treat blood cancers, improve solid organ tolerisation and repair damage from radiation.",
    'FW Link1':
      'Excellthera and Ossium Health Announce a Collaboration to Advance Their Technologies and Platforms to Improve Human Health;;https://firstwordpharma.com/story/5279781/'
  },
  {
    'Date Announced': '22-Apr-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Kartos Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Illumina',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Companion diagnostic, MDM2 inhibitor',
    'Lead Asset(s)': 'KRT-232',
    'Indication(s)': 'Blood cancers',
    'Target(s)': 'MDM2',
    'Deal Structure':
      "The initial focus of the collaboration will be the co-development of multiple CDx claims in blood cancers for Kartos' KRT-232, a potent and selective oral MDM2 inhibitor that activates p53 to drive tumour cell death in TP53 wild-type cancers.",
    'FW Link1':
      'Illumina and Kartos Therapeutics Announce New Oncology Partnership to Develop an NGS-Based TP53 Companion Diagnostic;;https://firstwordpharma.com/story/5281217/'
  },
  {
    'Date Announced': '25-Apr-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'TG Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Samsung Biologics',
    'Country (Target Company/Partner)': 'Korea, South',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibody',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'ublituximab',
    'Indication(s)': 'Chronic lymphocytic leukaemia',
    'Target(s)': 'CD20',
    'Deal Structure':
      "Samsung Biologics and TG Therapeutics announced an expansion of a large-scale contract manufacturing deal for the supply of TG Therapeutics' ublituximab, an investigational anti-CD20 monoclonal antibody.",
    'FW Link1':
      'Samsung Biologics and TG Therapeutics Expand Collaboration for the Large Scale Manufacture of Ublituximab;;https://firstwordpharma.com/story/5282495/'
  },
  {
    'Date Announced': '26-Apr-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Asher Biotherapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'ProBioGen',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immuno-oncology',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "ProBioGen announced that Asher Biotherapeutics has entered into a service agreement covering all aspects of cell line development, process development and GMP manufacturing at 1000 L bioreactor scale. ProBioGen will now advance the development of Asher's lead candidate in immuno-oncology applying its unique transposase technology DirectedLuck which greatly accelerates time-to-clinic.",
    'FW Link1':
      'Asher Biotherapeutics Contracts ProBioGen to Develop and Manufacture Their Lead Candidate;;https://firstwordpharma.com/story/5283087/'
  },
  {
    'Date Announced': '28-Apr-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Precirix',
    'Country (Acquiring Company/Partner)': 'Belgium',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Evergreen Theragnostics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Radiopharmaceutical',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'CAM-H2',
    'Indication(s)': 'HER2-positive metastatic breast and gastric cancer',
    'Deal Structure':
      "Precirix and Evergreen Theragnostics have entered into an agreement whereby Evergreen will provide US-based manufacturing for Precirix's lead product candidate, CAM-H2.",
    'FW Link1':
      'Precirix partners with Evergreen to expand North American clinical trial supply;;https://firstwordpharma.com/story/5284799/'
  },
  {
    'Date Announced': '30-Apr-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Cancer Focus Fund',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Mereo BioPharma Group',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Upfront Value ($m)': '2',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Anti-TIGIT antibody',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'etigilimab',
    'Indication(s)': 'Clear cell ovarian cancer',
    'Target(s)': 'TIGIT',
    'Deal Structure':
      'The Mereo and Cancer Focus Fund partnership will support a Phase Ib/II clinical study of etigilimab in combination with an anti-PD-1 antibody in clear cell ovarian cancer, to be conducted at MD Anderson and led by Shannon Westin, MD, MPH, associate professor of Gynecologic Oncology and Reproductive Medicine. The study will be financed by Cancer Focus Fund, in exchange for upfront consideration of $1.5 million of Mereo shares and additional payments based on the achievement of certain milestones.',
    'FW Link1':
      'Mereo BioPharma and Cancer Focus Fund Announce Partnership for Phase 1b/2 Study of Etigilimab in Clear Cell Ovarian Cancer;;https://firstwordpharma.com/story/5287421/'
  },
  {
    'Date Announced': '3-May-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Vivacitas Oncology',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Image Analysis Group',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Third generation Camptothecin',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'AR-67',
    'Indication(s)': 'Recurrent glioblastoma multiforme',
    'Deal Structure':
      'Vivacitas Oncology and Image Analysis Group (IAG) are collaborating to apply Artificial Intelligence (AI) technology and advanced imaging strategies to further the development of AR-67, a third generation Camptothecin, in patients with recurrent glioblastoma multiforme.',
    'FW Link1':
      'Vivacitas Oncology and IAG Partner to Integrate Novel Imaging Markers in the Development of AR-67 in Recurrent Glioblastoma Patients;;https://www.firstwordhealthtech.com/story/5288835/'
  },
  {
    'Date Announced': '4-May-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Plus Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Invicro',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Nanoliposome-encapsulated radionuclides',
    'Lead Asset(s)': 'Rhenium NanoLiposome (RNL)',
    'Indication(s)': 'Recurrent glioblastoma',
    'Deal Structure':
      'Under this agreement, Invicro will characterise the current manufacturing process and develop in-process manufacturing controls for the RNL active pharmaceutical ingredient (API) and final drug product and provide future clinical trial imaging support and drug development consulting.',
    'FW Link1':
      'Plus Therapeutics Announces Key RNL Development and cGMP Drug Manufacturing Collaboration Agreements;;https://firstwordpharma.com/story/5290413/'
  },
  {
    'Date Announced': '4-May-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Plus Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Eurofins BioPharma',
    'Country (Target Company/Partner)': 'Luxembourg',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Nanoliposome-encapsulated radionuclides',
    'Lead Asset(s)': 'Rhenium NanoLiposome (RNL)',
    'Indication(s)': 'Recurrent glioblastoma',
    'Deal Structure':
      'Eurofins will develop and validate test methods for purity, composition, and identity of Re-BMEDA, the API in RNL. These test methods will support release testing and compliance with cGMP requirements for new drug substances.',
    'FW Link1':
      'Plus Therapeutics Announces Key RNL Development and cGMP Drug Manufacturing Collaboration Agreements;;https://firstwordpharma.com/story/5290413/'
  },
  {
    'Date Announced': '5-May-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'OliX Pharmaceuticals',
    'Country (Acquiring Company/Partner)': 'Korea, South',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'PCI Biotech',
    'Country (Target Company/Partner)': 'Norway',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'RNAi therapeutics, intracellular delivery, immunotherapy, mRNA vaccines',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'OLX104C',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'The partnership is governed by a research collaboration agreement, under which the collaborators will perform an extensive evaluation of technology compatibility and synergy based on OLX104C (Androgenic Alopecia) preclinical studies. The companies will evaluate results achieved from this research collaboration to explore the potential for further development and partnership.',
    'FW Link1':
      'OliX Pharmaceuticals and PCI Biotech announce a collaboration to combine OliX asiRNA and PCI Biotech fimaNAc technologies;;https://firstwordpharma.com/story/5291463/'
  },
  {
    'Date Announced': '6-May-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'BMS',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'DarwinHealth',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Novel cancer target discovery',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'To facilitate the discovery of NCTs, DarwinHealth will provide Bristol Myers Squibb proprietary information residing in its drug databases, including tumour context-specific analyses of Master Regulators of distinct tumour subtypes, as well as direct upstream modulators. As part of the NCTI collaboration, DarwinHealth will receive an upfront payment and has the potential to receive development and commercialisation milestone payments.',
    'FW Link1':
      'DarwinHealth Announces a Research Collaboration with Bristol Myers Squibb for a Novel Cancer Target Discovery (NCTI) Initiative;;https://firstwordpharma.com/story/5292967/'
  },
  {
    'Date Announced': '10-May-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Checkmate Pharmaceuticals',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Regeneron Pharmaceuticals',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'TLR9 agonist, PD-1 blocking antibody',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'vidutolimod, Libtayo',
    'Indication(s)':
      'Non-melanoma skin cancers, cutaneous squamous cell carcinoma, Merkel cell carcinoma',
    'Target(s)': 'TLR9, PD-1',
    'Deal Structure':
      'Checkmate and Regeneron will collaborate on a multi-indication, Phase II, proof-of-concept clinical trial of vidutolimod in combination with cemiplimab in the following patient cohorts: (a) PD-1 treatment-naïve subjects with cutaneous squamous cell carcinoma (CSCC), (b) subjects with cutaneous squamous cell carcinoma (CSCC) that is refractory to PD-1 blockade, and (c) subjects with Merkel cell carcinoma (MCC) that is refractory to PD-1 blockade. Checkmate will be the sponsor of the clinical trial, and Regeneron will supply cemiplimab.',
    'FW Link1':
      'Checkmate Pharmaceuticals Announces Clinical Supply Agreement with Regeneron to Evaluate Vidutolimod (CMP-001) in Combination with Libtayo (cemiplimab);;https://firstwordpharma.com/story/5294929/'
  },
  {
    'Date Announced': '10-May-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Compugen',
    'Country (Acquiring Company/Partner)': 'Israel',
    'Region (Acquiring Company/Partner)': 'Middle East',
    'Target Company/Partner': 'Johns Hopkins University',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Myeloid immunomodulator',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Compugen has expanded its research collaboration with Johns Hopkins University to include studies investigating the biology of a novel myeloid target that was computationally-discovered by Compugen. The research programme, headed by Jelani Chinelo Zarif, M.S., Ph.D., Assistant Professor of Oncology and Drew Pardoll, M.D., Ph.D., Professor of Oncology, both at Johns Hopkins University School of Medicine, will explore the biological function and mechanism of this novel target, which is expressed on myeloid cells and macrophages in various cancers. The expanded research plan is intended to further evaluate and validate the role of the target in various tumours.  ',
    'FW Link1':
      'Compugen Announces Expansion of Research Collaboration with Johns Hopkins University for a Novel Myeloid Target Discovered by Compugen;;https://firstwordpharma.com/story/5294941/'
  },
  {
    'Date Announced': '10-May-2021',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'Boston Scientific',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'BWXT Medical',
    'Country (Target Company/Partner)': 'Canada',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Radiotherapy, Y-90 glass microspheres',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'TheraSphere',
    'Indication(s)': 'Hepatocellular carcinoma',
    'Deal Structure':
      'Under the terms of the agreement, BWXT Medical will invest to automate the production process at its Ottawa, Canada facility, and thereby significantly increase capacity and dependability to support a growing global demand for TheraSphere.',
    'FW Link1':
      'BWXT Medical Enters into a New Long-Term Agreement with Boston Scientific for the Manufacture of TheraSphere Y-90 Glass Microspheres;;https://firstwordpharma.com/story/5295163/'
  },
  {
    'Date Announced': '10-May-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'OncoTEX',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Sterling Pharma Solutions',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Texaphyrin-oxaliplatin conjugate, chemotherapy, targeted drug delivery',
    'Lead Asset(s)': 'OxaliTEX',
    'Indication(s)': 'Ovarian cancer',
    'Deal Structure':
      'Under the terms of the agreement, Sterling will produce clinical material in its CGMP facility in Germantown, Wisconsin, which has the capabilities to handle highly potent active pharmaceutical ingredients (HPAPIs) in fully contained manufacturing suites.',
    'FW Link1':
      'OncoTEX and Sterling Pharma Solutions Sign Clinical Manufacturing Agreement for the Development of Novel Treatment against Ovarian Cancer;;https://firstwordpharma.com/story/5295605/'
  },
  {
    'Date Announced': '11-May-2021',
    'Deal type': 'Product Acquisition',
    'Acquiring Company/Partner': 'Laevoroc Oncology',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Undisclosed',
    'Geographic Remit of Deal': 'Global excl. China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Chemotherapy',
    'Lead Asset(s)': 'LR 06',
    'Indication(s)': 'Breast, ovarian, pancreatic cancer',
    'Deal Structure':
      'The company announced the acquisition of worldwide patents (excluding China) for an oral prodrug form of gemcitabine, one of the most important chemotherapy agents worldwide.',
    'FW Link1':
      'Laevoroc Oncology Emerges from Stealth Mode Announcing Seed Financing and Acquisition of Worldwide Patents to Oral Gemcitabine Prodrug;;https://firstwordpharma.com/story/5295847/'
  },
  {
    'Date Announced': '11-May-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Navigo Proteins',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'MDimune',
    'Country (Target Company/Partner)': 'Korea, South',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell-derived vesicles, drug delivery',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      "For the current collaboration, Navigo Proteins will contribute its existing solid tumour-specific Affilin molecules to 'decorate' the surface of the exosome-like CDVs from MDimune's BioDrone technology, thereby pioneering next-generation, targeted extracellular vesicle-based therapeutics.",
    'FW Link1':
      'Navigo Proteins and MDimune Enter into a Research Collaboration on Affilin-Mediated Targeting of Extracellular Vesicles;;https://firstwordpharma.com/story/5295859/'
  },
  {
    'Date Announced': '11-May-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Numab Therapeutics',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Physiomics',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Multi-specific biotherapeutics',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Physiomics has recent significant experience of the mathematical modelling of multi-specific pharmaceuticals which it will bring to bear on an undisclosed Numab compound in this project that is expected to last around three months.',
    'FW Link1': 'Contract award;;https://firstwordpharma.com/story/5296665/'
  },
  {
    'Date Announced': '11-May-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Cellectis',
    'Country (Acquiring Company/Partner)': 'France',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Sanofi',
    'Country (Target Company/Partner)': 'France',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Allogeneic CAR-T, anti-CD52 monoclonal antibody, lymphodepletion agent',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'UCART22, UCART123, alemtuzumab',
    'Indication(s)': 'Acute lymphoblastic leukaemia, acute myeloid leukaemia',
    'Target(s)': 'CD52',
    'Deal Structure':
      "As part of the agreement, Sanofi will supply alemtuzumab to support Cellectis' clinical trials and the parties agreed to enter into discussions to execute a commercial supply of alemtuzumab under pre-agreed financial conditions.",
    'FW Link1':
      'Cellectis and Sanofi partner on alemtuzumab as lymphodepletion agent for allogeneic CAR-T;;https://firstwordpharma.com/story/5296853/'
  },
  {
    'Date Announced': '11-May-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'LianBio',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Nanobiotix',
    'Country (Target Company/Partner)': 'France',
    'Region (Target Company/Partner)': 'Europe',
    'Upfront Value ($m)': '20',
    'Total Deal Value ($m)': '240',
    'Geographic Remit of Deal':
      'Greater China, South Korea, Singapore, Thailand',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Radioenhancer',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'NBTXR3',
    'Indication(s)': 'Cancer, head and neck squamous cell carcinoma',
    'Deal Structure':
      'Under the terms of the agreement, LianBio will obtain exclusive rights to develop and commercialise NBTXR3 in Greater China, South Korea, Singapore, and Thailand. Nanobiotix will receive a $20 million upfront payment and is entitled to receive up to an aggregate of $220 million in potential contingent, development and commercialisation milestone payments. Nanobiotix will also be eligible to receive tiered, low double-digit royalties based on net sales of NBTXR3 in the licensed territories. LianBio will participate in the Nanobiotix global Phase III registrational study evaluating NBTXR3 for patients with locally advanced head and neck squamous cell carcinoma (HNSCC; head and neck cancer) by enrolling 100 patients in China in the study. In addition to the Phase III head and neck cancer study, LianBio has committed to enrol patients in four additional registrational studies conducted by Nanobiotix across indications and therapeutic combinations potentially including immunotherapy. LianBio will fund all development and commercialisation expenses in the collaboration territory, and Nanobiotix will continue to fund all development and commercialisation expenses in all other geographies.',
    'FW Link1':
      'NANOBIOTIX Partners With LianBio to Develop and Commercialize Potential First-in-Class Radioenhancer NBTXR3 Across Tumor Types and Therapeutic Combinations in China and Other Asian Markets;;https://firstwordpharma.com/story/5296881/'
  },
  {
    'Date Announced': '11-May-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Ipsen',
    'Country (Acquiring Company/Partner)': 'France',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Exelixis',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global excl. US, Japan',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecule inhibitor',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Cabometyx',
    'Indication(s)':
      'Renal cell carcinoma, hepatocellular carcinoma, differentiated thyroid cancer',
    'Deal Structure':
      'Ipsen has exercised its option to collaborate with Exelixis in the pivotal COSMIC-311 Phase III trial. COSMIC-311 is evaluating Cabometyx (cabozantinib) 60mg versus placebo in people living with radioiodine-refractory differentiated thyroid cancer (DTC) who have progressed after up to two prior vascular endothelial growth factor receptor (VEGFR)-targeted therapies. Ipsen has an exclusive collaboration agreement with Exelixis for the commercialisation of Cabometyx outside of the US and Japan. Following the decision to opt-in to the COSMIC-311 trial, Ipsen gains access to the results to support potential future regulatory submissions in its territories.',
    'FW Link1':
      'Ipsen Opts-in to Join Exelixis With Ongoing Development of Cabometyx for People Living With a Form of Thyroid Cancer, Based on Promising Interim Results;;https://firstwordpharma.com/story/5296905/'
  },
  {
    'Date Announced': '14-May-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Eisai',
    'Country (Acquiring Company/Partner)': 'Japan',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'National Cancer Center Japan',
    'Country (Target Company/Partner)': 'Japan',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Anticancer drugs',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Rare cancers, refractory cancers',
    'Deal Structure':
      'In research and development under the agreement, Eisai and the National Cancer Center will jointly conduct tumour-agnostic non-clinical research on new drug candidates created by Eisai, using J-PDX with relevant clinical and biological information, and will determine the drugs and the target cancer types to be transferred to clinical studies. After that, investigator-initiated studies will be conducted for rare cancers and refractory cancers in order to confirm clinical benefits of these new drugs, with the aim to apply for approval of them. Further, both parties will consider expanding into new drug discovery research, with establishment of PDX with tumour tissues taken from patients before and after treatment, comparative analyses of drug responsiveness and cancer genome, as well as search for new drug discovery targets and elucidation of drug resistance mechanisms. Through these efforts, both parties aim to establish a drug discovery and development research system that accelerates the development of new anticancer drugs in Japan.',
    'FW Link1':
      'Eisai and National Cancer Center Commence Joint Research and Development Project;;https://firstwordpharma.com/story/5300123/'
  },
  {
    'Date Announced': '17-May-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Refuge Biotechnologies',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'University of Texas MD Anderson Cancer Center',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'HER2 targeted CAR T-cell therapy, tumour infiltrating lymphocytes',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'RB-340',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'PD-1',
    'Deal Structure':
      "Under the agreement, MD Anderson will possess exclusive rights to apply Refuge's proprietary platform for next-generation cell engineering to its tumour infiltrating lymphocyte (TIL) programmes. MD Anderson also will co-develop Refuge's RB-340, a HER-2 targeted CAR T-cell therapy with context dependent inducible down-regulation of PD-1, including Investigational New Drug (IND) filing, Good Manufacturing Practice (GMP) production and Phase I/II clinical trials.",
    'FW Link1':
      'MD Anderson and Refuge Biotechnologies Collaborate to Advance Next-Generation Cell Therapies for Treatment of Solid Tumors;;https://firstwordpharma.com/story/5301297/'
  },
  {
    'Date Announced': '17-May-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Merck & Co.',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'BioMed X',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Under the new agreement, Merck will start up to six additional research projects at the BioMed X Institute, building on ongoing research projects in the fields of oncology (DNA damage response and RNA splicing) and autoimmunity (intestinal epithelial barrier in autoimmune diseases). The next joint global crowdsourcing project will explore mechanisms of immune senescence and mitochondrial dysfunction in regulatory T cells and other T cell subsets that are shared in human autoimmunity and ageing.',
    'FW Link1':
      'BioMed X Institute and Merck Extend Collaboration to Continue Novel Research in Oncology and Autoimmunity;;https://firstwordpharma.com/story/5301481/'
  },
  {
    'Date Announced': '19-May-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Adium Pharma',
    'Country (Acquiring Company/Partner)': 'Uruguay',
    'Region (Acquiring Company/Partner)': 'South America',
    'Target Company/Partner': 'Y-mAbs Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Latin America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibodies',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Danyelza',
    'Indication(s)': 'Relapsed/refractory high-risk neuroblastoma',
    'Target(s)': 'GD2',
    'Deal Structure':
      'Under the terms of the agreement, Adium will employ its sales and marketing expertise to distribute DANYELZA and omburtamab, if approved, in the territory. In addition, Adium will submit registration files on behalf of the Company in certain parts of the territory. All other unpartnered geographies worldwide remain with the Company. Financial details were not disclosed.',
    'FW Link1':
      'Y-mAbs Enters into Exclusive Distribution Agreement with Adium Pharma S.A. for DANYELZA (naxitamab-gqgk) and Omburtamab in Latin America;;https://firstwordpharma.com/story/5304275/'
  },
  {
    'Date Announced': '21-May-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Bicycle Therapeutics',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Physiomics',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Bicyclic peptides',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Physiomics has been awarded two further contracts by existing client Bicycle Therapeutics that it expects to be completed over the next six months. These projects relate to two of Bicycle’s lead clinical assets and build on earlier work completed by Physiomics on these assets.',
    'FW Link1': 'Contract awards;;https://firstwordpharma.com/story/5306415/'
  },
  {
    'Date Announced': '25-May-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Alloy Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Pyxis Oncology',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immune-modulating antibodies',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Under the terms of the agreement, Pyxis and Alloy intend to collaboratively fund and perform services for Kyma Therapeutics to rapidly develop programmes to a value-inflection point with the objective that the programmes will have the potential for independent financing and partnering. Pyxis and Alloy will retain certain rights to participate in the development and commercialisation of products originating from Kyma Therapeutics.',
    'FW Link1':
      'Alloy Therapeutics and Pyxis Oncology Form Kyma Therapeutics to Develop Novel Immune-Modulating Antibodies for Cancer and Autoimmune Diseases;;https://firstwordpharma.com/story/5308587/'
  },
  {
    'Date Announced': '25-May-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Xilio Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Merck & Co.',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Monoclonal antibody, tumour-selective immuno-oncology, checkpoint inhibitor',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'XTX101, Keytruda',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'CTLA-4, PD-1',
    'Deal Structure':
      'The planned clinical trial will be conducted by Xilio and is designed to evaluate the safety and efficacy of XTX101 as a monotherapy and in combination with Keytruda in patients with solid tumours.',
    'FW Link1':
      'Xilio Therapeutics Announces Clinical Trial Collaboration With Merck on Anti-CTLA-4 Monoclonal Antibody Program;;https://firstwordpharma.com/story/5308739/'
  },
  {
    'Date Announced': '25-May-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'F-star',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Inivata',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Bispecific antibodies',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      "Under the collaboration, Inivata's RaDaR technology, a personalised assay that tracks a set of up to 48 tumour-specific variants in a patient using a liquid biopsy with exceptional sensitivity, will be applied to identify and evaluate potential early biomarkers of clinical efficacy.",
    'FW Link1':
      'Inivata Announces Collaboration with F-star Therapeutics for the use of RaDaR Assay;;https://firstwordpharma.com/story/5309045/'
  },
  {
    'Date Announced': '25-May-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Mirati Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Qiagen',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Companion diagnostic, small molecule inhibitor of KRASG12C',
    'Lead Asset(s)': 'adagrasib',
    'Indication(s)': 'Non-small-cell lung cancer',
    'Target(s)': 'KRASG12C',
    'Deal Structure':
      'The agreement initially focuses on a companion diagnostic test for non-small cell lung cancer (NSCLC), and allows for further development of tests for other Mirati oncology programmes.',
    'FW Link1':
      'QIAGEN Partners With Mirati Therapeutics Inc. to Develop KRASG12C Companion Diagnostic for Non-Small Cell Lung Cancer (NSCLC);;https://firstwordpharma.com/story/5309291/'
  },
  {
    'Date Announced': '26-May-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Repertoire Immune Medicines',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Yale School of Medicine',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy',
    'Indication(s)': 'Melanoma',
    'Deal Structure':
      "The university research team will provide human T cell receptor (TCR) sequences to Repertoire, which will use its proprietary DECODE platform to determine the novel antigens that these TCRs identify. The team's deep understanding of translational science in oncology, coupled with Repertoire's platform technology, may generate unique insights leading to the development of novel immune medicines.",
    'FW Link1':
      'Repertoire Immune Medicines Enters Collaboration to Advance Novel Antigen Discovery and T Cell Receptor Research in Melanoma;;https://firstwordpharma.com/story/5309641/'
  },
  {
    'Date Announced': '26-May-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'TransThera Biosciences',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Roche',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Kinase inhibitor, checkpoint inhibitor',
    'Lead Asset(s)': 'TT-00420, atezolizumab',
    'Indication(s)': 'Gastrointestinal tract cancers',
    'Target(s)': 'PD-L1',
    'Deal Structure':
      'TransThera Biosciences has entered into a collaboration agreement with Roche to explore the combination of TT-00420 and atezolizumab for the treatment of patients in China with gastrointestinal tract cancers.',
    'FW Link1':
      'TransThera announces collaboration with Roche to evaluate TT-00420 as part of a novel combination to treat GI cancers in China;;https://firstwordpharma.com/story/5309827/'
  },
  {
    'Date Announced': '27-May-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Sapience Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'National Cancer Institute',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Peptide antagonist',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'ST101',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'C/EBPβ',
    'Deal Structure':
      "Under the collaboration, Sapience and NCI will investigate the impact of ST101 on primary and metastatic cancer and the tumour microenvironment, leveraging NCI's expertise in C/EBP biology and genetic mouse models.",
    'FW Link1':
      'Sapience Therapeutics Enters Into Collaboration with National Cancer Institute (NCI) to Study ST101 Response in Specialized Tumor Models;;https://firstwordpharma.com/story/5311783/'
  },
  {
    'Date Announced': '28-May-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Beroni',
    'Country (Acquiring Company/Partner)': 'Australia',
    'Region (Acquiring Company/Partner)': 'Oceania',
    'Target Company/Partner': 'tella',
    'Country (Target Company/Partner)': 'Japan',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Immunotherapy, dendritic cell vaccine therapy, gamma delta T cells',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Beroni and tella acknowledge that the resources possessed by each party and their respective affiliates such as technologies, knowledge and networks, are of high value to each other. In this context, they intend to build strategic partnerships in the field of immunotherapy for cancer treatment, such as conducting clinical trials in dendritic cell vaccine and gamma delta T cells in both Japan and China, crossing shareholding by both parties, technical exchange between both parties and other strategic developments.',
    'FW Link1':
      'Beroni Group Signs MoU with tella, Inc to Collaborate in Immunotherapy for Cancer Treatment;;https://firstwordpharma.com/story/5312527/'
  },
  {
    'Date Announced': '2-Jun-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Genmab',
    'Country (Acquiring Company/Partner)': 'Denmark',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Bolt Biotherapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '10',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Bispecific antibody-based conjugate therapeutics',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Under the terms of the agreement, Genmab will pay Bolt an upfront payment of USD 10 million. Genmab will also make a USD 15 million equity investment in Bolt. Bolt is eligible to receive total potential milestone payments of up to USD 285 million per therapeutic candidate exclusively developed and commercialised by Genmab, along with tiered royalties. Genmab will fully fund pre-clinical and early clinical development of all candidates. If a candidate is co-developed, development costs will be split 50:50 between the two companies, and the companies will be solely responsible for commercialisation costs in their respective territories and shall pay each other royalties on product sales.',
    'FW Link1':
      'Genmab, Bolt ink deal to develop bispecific immune-stimulating antibody conjugates;;https://firstwordpharma.com/story/5315877/'
  },
  {
    'Date Announced': '2-Jun-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Rain Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Caris Life Sciences',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Precision oncology, molecular profiling, MDM2 inhibitor',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'RAIN-32',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'MDM2',
    'Deal Structure':
      "Under the terms of the partnership, Caris will provide patient referral services using their molecular intelligence (MI) trials platform for Rain's planned Phase 2 MDM2-amplified tumour-agnostic basket trial for RAIN-32, an oral MDM2 inhibitor in patients with certain solid tumours with pre-specified MDM2 amplification levels and wild type p53.",
    'FW Link1':
      'Rain Therapeutics Announces Patient Referral Partnership for the Planned MDM2-Amplified Phase 2 Basket Trial of RAIN-32;;https://firstwordpharma.com/story/5316155/'
  },
  {
    'Date Announced': '2-Jun-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Nanjing KAEDI',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Porton Biologics',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'CAR-T cell therapies',
    'Lead Asset(s)': 'KD-025 CAR-T',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      'According to the agreement, Portonbio, as an exclusive CDMO partner, will provide KAEDI with CMC services for multiple CAR-T projects, including the process development and manufacturing of plasmids, viral vectors and CAR-T cells and IND filling (CMC part) support services.',
    'FW Link1':
      'Portonbio announced strategic cooperation with KAEDI to accelerate the R&D and production of CAR-T drugs;;https://firstwordpharma.com/story/5316841/'
  },
  {
    'Date Announced': '3-Jun-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Monopar Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Cancer Science Institute of Singapore',
    'Country (Target Company/Partner)': 'Singapore',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Camsirubicin analogue, anthracycline, chemotherapy',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'MNPR-202',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'CSI Singapore will explore how the immune system recognises cancer cells treated with MNPR-202 and related analogues in order to guide the rational design of immunotherapy-chemotherapy combinations for the treatment of cancer.',
    'FW Link1':
      'Monopar Announces New Drug Candidate, MNPR-202, and Enters into Collaboration Agreement with the National University of Singapore for Preclinical Evaluation in Cancer;;https://firstwordpharma.com/story/5318049/'
  },
  {
    'Date Announced': '3-Jun-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Rain Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Tempus',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Precision oncology, genomic analysis, MDM2 inhibitor',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'RAIN-32',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'MDM2',
    'Deal Structure':
      'Under the terms of the agreement, Tempus will provide both centralised tumour testing and patient matching services using their Connect & TIME Trial Network for the planned Phase 2 MDM2-amplified tumour-agnostic basket trial for RAIN-32, an oral MDM2 inhibitor in patients with certain solid tumours with pre-specified MDM2 amplification levels and wild type p53.',
    'FW Link1':
      'Rain Therapeutics Announces Collaboration with Tempus for Genomic Analysis in Planned MDM2-Amplified Phase 2 Basket Trial of RAIN-32;;https://firstwordpharma.com/story/5318091/'
  },
  {
    'Date Announced': '4-Jun-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Surface Oncology',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Roche',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Anti-IL-27 antibody, checkpoint inhibitor, antiangiogenic agent',
    'Lead Asset(s)': 'SRF388, atezolizumab, bevacizumab',
    'Indication(s)': 'Treatment-naïve hepatocellular carcinoma',
    'Target(s)': 'IL-27, PD-L1, VEGF',
    'Deal Structure':
      "Surface Oncology announced a clinical trial collaboration with Roche to evaluate SRF388, Surface's investigational anti-IL-27 antibody, in combination with Roche's atezolizumab and bevacizumab in patients with treatment-naïve hepatocellular carcinoma (HCC).",
    'FW Link1':
      'Surface Oncology to Collaborate with Roche on Immuno-Oncology Study Evaluating SRF388, Targeting IL-27, as Part of a Novel Combination in Patients with Advanced Treatment-naïve Hepatocellular Carcinoma;;https://firstwordpharma.com/story/5318339/'
  },
  {
    'Date Announced': '3-Jun-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Vela Diagnostics',
    'Country (Acquiring Company/Partner)': 'Singapore',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'National Cancer Centre of Singapore',
    'Country (Target Company/Partner)': 'Singapore',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Molecular diagnostic assay, EGFR tyrosine kinase inhibitors',
    'Indication(s)': 'Cancer',
    'Target(s)': 'EGFR',
    'Deal Structure':
      "Vela Diagnostics announced a collaboration with the National Cancer Centre of Singapore (NCCS) to develop a molecular diagnostic assay to predict individual patients' responses to a cancer therapy called Epidermal Growth Factor Receptor (EGFR) tyrosine kinase inhibitors (TKIs).",
    'FW Link1':
      'Vela Diagnostics Announces Collaboration with the National Cancer Centre of Singapore to Refine Novel Cancer Therapy;;https://firstwordpharma.com/story/5318351/'
  },
  {
    'Date Announced': '4-Jun-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Auransa',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Polaris Quantum Biotech',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Quantum drug design, artificial intelligence, drug discovery',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Triple-negative breast cancer, ovarian cancer',
    'Deal Structure':
      'Auransa, an artificial intelligence (AI) company developing precision medicines in areas of unmet medical needs, and Polaris Quantum Biotech, a quantum drug design company, announced a research collaboration addressing therapeutics for neglected diseases disproportionately affecting women. The partnership seeks to discover treatments that may tackle many such diseases, and their complementary expertise promises to seek solutions that elude medical research.',
    'FW Link1':
      "Auransa and POLARISqb enter research collaboration finding treatments for neglected women's diseases;;https://firstwordpharma.com/story/5318901/"
  },
  {
    'Date Announced': '4-Jun-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Sorrento Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Karolinska Institutet',
    'Country (Target Company/Partner)': 'Sweden',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'iPSC-derived NK cell-based cancer immunotherapies, CAR-NK, DAR-NK, off-the-shelf NK cell therapies',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Under the agreement, Sorrento will provide know-how in the core chimeric antigen receptor (CAR) and dimeric antigen receptor (DAR) technologies and support the collaborative effort to develop new CAR-NK and DAR-NK candidates, as well as fund the translational validation of the technologies. Multiple product candidates will be developed and tested in the initial phase of the planned work, with the goal that the candidate products will qualify for further human clinical trials.',
    'FW Link1':
      'Sorrento and Researchers at Karolinska Institutet Have Signed a Research Collaboration Agreement on iPSC-Derived Dimeric Antigen Receptor-Modified Natural Killer Cells to Bring “Off-The-Shelf" NK Cell-Based Cancer Treatments Rapidly to Patients;;https://firstwordpharma.com/story/5318945/'
  },
  {
    'Date Announced': '7-Jun-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'SpringWorks Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Seagen',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Gamma secretase inhibitor, anti-BCMA antibody',
    'Lead Asset(s)': 'nirogacestat, SEA-BCMA',
    'Indication(s)': 'Relapsed or refractory multiple myeloma',
    'Target(s)': 'BCMA, gamma secretase',
    'Deal Structure':
      'Under the terms of the agreement, Seagen will sponsor and conduct the Phase 1 study to evaluate the safety, tolerability and preliminary efficacy of the combination, and will assume all costs associated with the study, other than expenses related to the manufacturing of nirogacestat and certain expenses related to intellectual property rights. Seagen and SpringWorks will also form a joint development committee to manage the clinical study, which is expected to commence in the second half of 2021.',
    'FW Link1':
      'SpringWorks Therapeutics Announces Clinical Collaboration with Seagen to Evaluate Nirogacestat in Combination with SEA-BCMA in Patients with Relapsed or Refractory Multiple Myeloma;;https://firstwordpharma.com/story/5319879/'
  },
  {
    'Date Announced': '8-Jun-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Sun Pharma Advanced Research Company',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'OneThree Biotech',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Artificial intelligence, precision oncology, drug discovery',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "The collaboration will entail application of OneThree Biotech's Artificial Intelligence (AI) platform to fully characterise an enzyme pathway that metabolises specific salts or esters involved in oncogenesis. The two companies thus aim to facilitate the discovery and development of innovative anticancer compounds and combination therapies to advance precision oncology.",
    'FW Link1':
      'OneThree Biotech Joins Forces with SPARC to Characterize a Key Oncologic Pathway;;https://firstwordpharma.com/story/5321683/'
  },
  {
    'Date Announced': '11-Jun-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Sorrento Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Mayo Clinic',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Lymphatic drug delivery, immuno-oncology',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'Ipilimumab intralymphatic',
    'Indication(s)': 'Metastatic melanoma',
    'Target(s)': 'CTLA-4',
    'Deal Structure':
      'Sorrento Therapeutics announced a research collaboration agreement with Mayo Clinic to conduct human clinical proof of concept studies using the Sofusa Lymphatic Drug Delivery System (S-LDDS) technology across multiple products and indications.',
    'FW Link1':
      'Sorrento Announces Clinical Research Agreement With Mayo Clinic and FDA Clearance for the First Phase 1b Pilot Study Using Sofusa Lymphatic Drug Delivery Technology to Deliver Ipilimumab in Patients With Melanoma;;https://firstwordpharma.com/story/5324701/'
  },
  {
    'Date Announced': '14-Jun-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Exelixis',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'BMS',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Tyrosine kinase inhibitor, immuno-oncology therapies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'XL092, Opdivo, Yervoy, bempegaldesleukin',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'VEGFr, PD-1, CTLA-4',
    'Deal Structure':
      'Exelixis is sponsoring STELLAR-002 and Bristol Myers Squibb will provide nivolumab, ipilimumab and bempegaldesleukin for use in the trial. Nektar Therapeutics will supply bempegaldesleukin to Bristol Myers Squibb through their existing global development and commercialisation collaboration, which is evaluating nivolumab in combination with bempegaldesleukin.',
    'FW Link1':
      'Exelixis Announces Clinical Trial Collaboration and Supply Agreement with Bristol Myers Squibb to Evaluate XL092 in Combination with Immuno-oncology Therapies in Advanced Solid Tumors;;https://firstwordpharma.com/story/5325791/',
    'FW Link2':
      'Expansion of agreement announced October 4, 2022 https://firstwordpharma.com/story/5659936/'
  },
  {
    'Date Announced': '14-Jun-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Oncogen',
    'Country (Acquiring Company/Partner)': 'Malaysia',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'BrightGene Bio-Medical Technology',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'United States',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Generic products',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Under the terms of this agreement, the expertise of each company will be leveraged to create a highly efficient and effective product flow, from active pharmaceutical ingredients (API) manufacturing through to regulatory approval and distribution, that will bring high quality generic drugs to the USA market over the next few years.',
    'FW Link1':
      'Oncogen Pharma Malaysia & BrightGene Bio-Medical Technology announce strategic partnership to enter USA pharma market;;https://firstwordpharma.com/story/5326329/'
  },
  {
    'Date Announced': '15-Jun-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Linnaeus Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Merck & Co.',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'GPER agonist, anti-PD-1 therapy',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'LNS8801, Keytruda',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'GPER, PD-1',
    'Deal Structure':
      'Under the terms of the agreement, Linnaeus will conduct six additional Phase 2 cohorts evaluating the combination of LNS8801 and KEYTRUDA in several indications including NSCLC, head and neck cancer, cutaneous and uveal melanoma, among others.',
    'FW Link1':
      'Linnaeus Therapeutics Announces Expanded Clinical Collaboration with Merck to Include Multiple Additional Cohorts to Evaluate LNS8801 in Combination with KEYTRUDA in Patients with Advanced Cancer;;https://firstwordpharma.com/story/5327035/'
  },
  {
    'Date Announced': '15-Jun-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Kazia Therapeutics',
    'Country (Acquiring Company/Partner)': 'Australia',
    'Region (Acquiring Company/Partner)': 'Oceania',
    'Target Company/Partner': 'Cornell University',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Brain-penetrant inhibitor of the PI3K/Akt/mTOR pathway',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'paxalisib',
    'Indication(s)': 'Glioblastoma',
    'Target(s)': 'PI3K',
    'Deal Structure':
      "Kazia Therapeutics has entered a collaboration with the Joan & Sanford I Weill Medical College of Cornell University in the United States, to launch a phase II clinical study investigating the use of Kazia's investigational new drug, paxalisib, in combination with ketogenesis, for glioblastoma.",
    'FW Link1':
      'Kazia Enters Clinical Collaboration With Cornell University for Phase II Clinical Study Using Paxalisib in Combination With Ketogenic Diet for Glioblastoma;;https://firstwordpharma.com/story/5327125/'
  },
  {
    'Date Announced': '15-Jun-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'NanoString Technologies',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Parker Institute for Cancer Immunotherapy',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Molecular characterisation, cell therapy development',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'The collaboration will define the characteristics that make a cell therapy effective, providing a standardised approach to developing CAR-T regimens that may improve patient outcomes across all cancer types, especially solid tumours.',
    'FW Link1':
      'NanoString and Parker Institute for Cancer Immunotherapy Collaborate to Optimize Cell Therapies to Treat Cancer;;https://firstwordpharma.com/story/5327665/'
  },
  {
    'Date Announced': '16-Jun-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Apexigen',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner':
      'Nordic Society of Gynaecological Oncology - Clinical Trial Unit',
    'Country (Target Company/Partner)': 'Denmark',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Monoclonal antibody, immuno-oncology, CD40 agonist',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'sotigalimab',
    'Indication(s)': 'Ovarian cancer',
    'Target(s)': 'CD40',
    'Deal Structure':
      "Apexigen and the Nordic Society of Gynaecological Oncology - Clinical Trial Unit (NSGO-CTU) announced an agreement to initiate a collaborative Phase 2 clinical study evaluating sotigalimab, Apexigen's monoclonal antibody targeting CD40, in combination therapy for patients with recurrent BRCA wild type ovarian cancer.",
    'FW Link1':
      'Apexigen and the Nordic Society of Gynaecological Oncology – Clinical Trial Unit Announce Collaborative Phase 2 Study of Sotigalimab (APX005M) in Combination Therapy for Ovarian Cancer;;https://firstwordpharma.com/story/5328585/'
  },
  {
    'Date Announced': '22-Jun-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Inventum Genetics',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Philipps-University of Marburg',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Target discovery, biomarker discovery, genetics',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Oncology',
    'Deal Structure':
      'The agreement with the Philipps-University of Marburg gives the university the right to pursue the results achieved in its own research and to industrialise them, provided Inventum Genetics does not use the results itself. In this case, Inventum Genetics would benefit from the royalties generated by the university.',
    'FW Link1':
      'Xlife Sciences AG: Collaboration with the University of Marburg;;https://firstwordpharma.com/story/5332183/'
  },
  {
    'Date Announced': '23-Jun-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Tessa Therapeutics',
    'Country (Acquiring Company/Partner)': 'Singapore',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'A*STAR',
    'Country (Target Company/Partner)': 'Singapore',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy laboratory, cell therapies',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Jointly operated by Tessa and IMCB, the facility will harness new preclinical technologies and provide capabilities to accelerate the discovery and development of the next generation of cell therapies.',
    'FW Link1':
      'Tessa Therapeutics Announces Collaboration with A*STAR’s Institute of Molecular and Cell Biology to Form Cell Therapy Laboratory;;https://firstwordpharma.com/story/5332959/'
  },
  {
    'Date Announced': '5-Jul-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Innovent Biologics',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Laekna',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'PD-1 inhibitor, pan-AKT kinase inhibitor',
    'Lead Asset(s)': 'sintilimab, afuresertib',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'PD-1, AKT',
    'Deal Structure':
      'Under the agreement, the two companies will work together to conduct clinical studies assessing the combination of sintilimab and afuresertib (LAE002) in patients with multiple types of solid tumours that have been refractory - or failed to respond - to treatment with PD-1/PD-L1 inhibitors. The trials will evaluate the safety, pharmacokinetics, pharmacodynamics and preliminary efficacy of the combination therapy. An investigational new drug (IND) application will soon be filed with the National Medical Products Administration (NMPA) in China.',
    'FW Link1':
      'Innovent and Laekna Therapeutics Enter a Partnership Agreement to Co-Develop Combination Therapy of Sintilimab and Afuresertib in Clinical Studies in China;;https://firstwordpharma.com/story/5343365/'
  },
  {
    'Date Announced': '7-Jul-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'PureTech',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'BeiGene',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Anti-PD-1 immune checkpoint inhibitor, monoclonal antibody targeting galectin-9',
    'Lead Asset(s)': 'tislelizumab, LYT-200',
    'Indication(s)': 'Difficult-to-treat solid tumours',
    'Target(s)': 'PD-1, galectin-9',
    'Deal Structure':
      'Under the terms of the agreement, PureTech will maintain control of the LYT-200 programme, including global R&D and commercial rights. BeiGene has agreed to supply tislelizumab for use in combination with LYT-200.',
    'FW Link1':
      'PureTech Announces Clinical Trial and Supply Agreement with BeiGene to Evaluate LYT-200 and Tislelizumab in Patients with Difficult-to-Treat Solid Tumors;;https://firstwordpharma.com/story/5344561/'
  },
  {
    'Date Announced': '8-Jul-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'BridgeBio Pharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner':
      'MUSC Foundation for Research Development, Stanford University, University of Pittsburgh',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Drug discovery, drug development',
    'Indication(s)': 'Genetically driven cancers',
    'Deal Structure':
      'Research collaborations initiated with MUSC Foundation for Research Development, Stanford University and the University of Pittsburgh to identify and advance therapies for genetic diseases and cancers.',
    'FW Link1':
      'BridgeBio Pharma Announces Collaborations with Three Academic Research Institutions;;https://firstwordpharma.com/story/5345737/'
  },
  {
    'Date Announced': '9-Jul-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'I-Mab',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Immorna, neoX Biotech',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'mRNA, anti-cancer antibody therapeutics, artificial intelligence',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'The collaborations with Immorna, an mRNA biotech company, and neoX Biotech, an AI-enabled R&D biotech company, allow I-Mab the access to transformative technologies in its quest to discover and develop novel oncology therapeutics. Both partner companies will receive undisclosed upfront and/or milestone payments stipulated in the collaboration agreements.',
    'FW Link1':
      'I-Mab Expands Emerging Portfolio of Next Generation Novel Oncology Therapeutics Through Cutting-Edge mRNA and AI Technology Platforms;;https://firstwordpharma.com/story/5347139/'
  },
  {
    'Date Announced': '13-Jul-2021',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'Everest Medicines',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Medbanks Health Technology',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Service solutions',
    'Indication(s)': 'Oncology',
    'Deal Structure':
      "The Companies' collaboration will focus on leveraging the specialised nationwide direct-to-patient pharmacy network explore innovative healthcare solutions for oncology and specialty care, including a new service model to address the unmet needs of patients in these expansive disease areas. This collaboration positions Everest to enable patients greater access to its novel medicines.",
    'FW Link1':
      'Everest Medicines Announces Strategic Collaboration with Medbanks Health Technology to Develop Innovative Healthcare Service Solutions for Patients in China;;https://firstwordpharma.com/story/5351967/'
  },
  {
    'Date Announced': '19-Jul-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Ascentage Pharma',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'National Cancer Institute',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Dual inhibitor of BCL-2 and BCL-xL proteins',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'pelcitoclax',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'BCL-2/BCL-xL',
    'Deal Structure':
      "Under the terms of the CRADA, Ascentage Pharma and the NCI will collaborate on a series of clinical trials to evaluate the safety and efficacy of pelcitoclax, Ascentage Pharma's dual inhibitor of BCL-2 and BCL-xL proteins, in solid tumours based upon encouraging anti-tumour activity observed in studies conducted by Ascentage Pharma. The NCI may also conduct non-clinical correlative studies that focus on exploring the biologic activity of pelcitoclax, as well as combination studies of the compound with other targeted agents.",
    'FW Link1':
      "Ascentage Pharma Establishes Cooperative R&D Agreement (CRADA) with the National Cancer Institute for Clinical Development of Ascentage Pharma's Drug Compound pelcitoclax;;https://firstwordpharma.com/story/5353301/"
  },
  {
    'Date Announced': '21-Jul-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'POINT Biopharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Isotopia Molecular Imaging',
    'Country (Target Company/Partner)': 'Israel',
    'Region (Target Company/Partner)': 'Middle East',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Radiopharmaceutical',
    'Lead Asset(s)': 'Lutetium-177, PNT2002',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Isotopia's Lu-177 will be used at POINT's Indianapolis facility in the manufacturing of its therapeutic radioligand therapies.",
    'FW Link1':
      'POINT Biopharma and Isotopia Announce Lutetium-177 Clinical Supply Agreement;;https://firstwordpharma.com/story/5355599/'
  },
  {
    'Date Announced': '22-Jul-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Adagene',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Merck & Co.',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Anti-CTLA-4 monoclonal antibody, anti-PD-1 therapy',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'ADG116, ADG126, Keytruda',
    'Indication(s)': 'Advanced/metastatic solid tumours',
    'Target(s)': 'CTLA-4, PD-1',
    'Deal Structure':
      "The agreement includes two open-label, dose escalation and expansion clinical studies to evaluate Adagene's anti-CTLA-4 monoclonal antibody (mAb) product candidates, ADG116 and ADG126, in combination with Merck's anti-PD-1 therapy, KEYTRUDA (pembrolizumab), for patients with advanced/metastatic solid tumours.",
    'FW Link1':
      'Adagene Announces Clinical Trial Collaboration With Merck to Advance Two Anti-CTLA-4 Monoclonal Antibody Programs (ADG116 and ADG126) in Combination Therapy with KEYTRUDA (pembrolizumab);;https://firstwordpharma.com/story/5356111/'
  },
  {
    'Date Announced': '27-Jul-2021',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Valo Health',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Courier Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell-targeting technology, immunotherapy',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'OPL-0101',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'The transaction includes an upfront payment, milestones and other consideration.',
    'FW Link1':
      'Valo Health Announces the Acquisition of Courier Therapeutics;;https://www.firstwordhealthtech.com/story/5359413/'
  },
  {
    'Date Announced': '4-Aug-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'F-star',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Merck & Co.',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Tetravalent bispecific antibody, anti-PD-1 therapy',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'FS120, Keytruda',
    'Indication(s)': 'Cancer',
    'Target(s)': 'CD137, OX40, PD-1',
    'Deal Structure':
      'Under the terms of the agreement, MSD will supply KEYTRUDA for a combination arm that will be included in the adaptive Phase 1 clinical protocol of FS120, sponsored by F-star, that was initiated in December of 2020.',
    'FW Link1':
      'F-star Therapeutics Announces Collaboration with MSD to Evaluate FS120 in Combination with KEYTRUDA;;https://firstwordpharma.com/story/5366067/'
  },
  {
    'Date Announced': '5-Aug-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Roche',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Syros Pharmaceuticals',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Selective CDK7 inhibition, PD-L1 checkpoint inhibitor',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'SY-5609, atezolizumab',
    'Indication(s)': 'Colorectal cancer',
    'Target(s)': 'CDK7, PD-L1',
    'Deal Structure':
      'Under the terms of the agreement, Roche will sponsor and conduct the Phase 1/1b study to evaluate the safety, tolerability and preliminary efficacy of the combination and will assume all costs associated with the study. In exchange for providing SY-5609, Syros will receive access to the data on SY-5609 in combination with atezolizumab. Syros retains all rights to SY-5609.',
    'FW Link1':
      'Syros Announces Agreement with Roche to Evaluate SY-5609 as Part of a Novel Combination for Treatment of Colorectal Cancer;;https://firstwordpharma.com/story/5367187/'
  },
  {
    'Date Announced': '5-Aug-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Hillstream BioPharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Minotaur Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Targeted Knob biologics, tumour-targeting Quatramers',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Hillstream BioPharma announced the signing of a non-binding term sheet with Minotaur Therapeutics to advance the development of the next generation targeted Knob biologics for undruggable targets including c-MYC combined with Hillstream's tumour-targeting Quatramers.",
    'FW Link1':
      'Hillstream BioPharma and Minotaur Therapeutics Sign Term Sheet to Advance Next-Generation Targeted Knob Biologics for Undruggable Targets;;https://firstwordpharma.com/story/5367741/',
    'FW Link2':
      'Expansion of collaboration announced July 20, 2023;;https://firstwordpharma.com/story/5762930/'
  },
  {
    'Date Announced': '5-Aug-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Insilico Medicine',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Usynova',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'AI-powered drug discovery, small molecule innovative drugs',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "The agreement outlines a constructive framework where the two parties will combine the advantages of Insilico Medicine's AI-powered drug discovery platform with Usynova's experience in the small molecule innovative drug development to accelerate the development of small molecule innovative drugs, with a view to jointly addressing significant unmet medical needs and tackling novel and challenging targets in cancer and autoimmune diseases.",
    'FW Link1':
      'Insilico Medicine and Usynova Announce Strategic Partnership on Accelerating R&D of Small Molecule Innovative Drugs with AI;;https://firstwordpharma.com/story/5367745/'
  },
  {
    'Date Announced': '5-Aug-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Eczacibasi',
    'Country (Acquiring Company/Partner)': 'Turkey',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Sesen Bio',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '2',
    'Geographic Remit of Deal': 'Turkey, Northern Cyprus',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Locally administered fusion protein',
    'Lead Asset(s)': 'Vicineum',
    'Indication(s)': 'BCG-unresponsive non-muscle invasive bladder cancer',
    'Target(s)': 'EpCAM antigens',
    'Deal Structure':
      'Under the terms of the licensing agreement, Sesen granted EIP an exclusive licence to register and commercialise Vicineum in Turkey for the treatment of BCG-unresponsive NMIBC. Sesen Bio will receive an upfront payment of $1.5 million and is eligible to receive additional regulatory and commercial milestone payments. Upon commercialisation in Turkey, Sesen Bio is also entitled to receive a 30% royalty on net sales in Turkey. Sesen retains full development and commercialisation rights for Vicineum for the treatment of NMIBC in the US and the rest of the world excluding Greater China, the Middle East and North Africa (MENA) and Turkey.',
    'FW Link1':
      'Sesen Bio Announces Partnership with Eczacibasi Pharmaceuticals Marketing (EIP) for the Commercialization of Vicineum in Turkey;;https://firstwordpharma.com/story/5367749/'
  },
  {
    'Date Announced': '5-Aug-2021',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'AnPac Bio',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Roche',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cancer screening, companion diagnostics',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "According to the agreement, both parties plan to utilise AnPac Bio's Cancer Differentiation Analysis (CDA) cancer detection technology and Roche's FoundationOne CDx to form a full solution, under which customers assessed with a high cancer risk level using CDA tests and later diagnosed with cancer will receive CDx precision medicine test and service for therapy selection. The collaboration aims to benefit general population via reducing medical costs and saving more patients' lives.",
    'FW Link1':
      'AnPac Bio and Roche Pharmaceuticals China Entered into Cooperation to Explore Innovative Healthcare and Medical Solutions;;https://firstwordpharma.com/story/5368139/'
  },
  {
    'Date Announced': '6-Aug-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'BioInvent',
    'Country (Acquiring Company/Partner)': 'Sweden',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Merck & Co.',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibodies, immunotherapy',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'BI-1808, Keytruda',
    'Indication(s)': 'Solid tumours, lung cancer, ovarian cancer',
    'Target(s)': 'TNFR2, PD-1',
    'Deal Structure':
      "BioInvent International has entered into a second clinical trial collaboration and supply agreement with MSD, a tradename of Merck & Co., Inc., Kenilworth, NJ., USA, to evaluate the combination of BioInvent's BI-1808, one of its proprietary anti-TNFR2 antibodies and MSD's anti-PD-1 therapy, KEYTRUDA in a Phase 1/2a clinical trial for patients with lung cancer and ovarian cancer.",
    'FW Link1':
      'BioInvent Announces Second Clinical Trial Collaboration and Supply Agreement to Evaluate BI-1808 in Combination with KEYTRUDA (pembrolizumab) in Patients with Advanced Solid Tumors;;https://firstwordpharma.com/story/5368453/'
  },
  {
    'Date Announced': '9-Aug-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Qiagen',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'OncXerna Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'NGS companion diagnostic, immuno-oncology, bispecific antibody',
    'Lead Asset(s)': 'navicixizumab',
    'Indication(s)': 'Ovarian cancer',
    'Target(s)': 'DLL4, VEGF',
    'Deal Structure':
      "QIAGEN and OncXerna Therapeutics announced signing a master companion diagnostics (CDx) agreement to develop a NGS CDx for OncXerna's product candidate, Navicixizumab, and a non-exclusive license to the Xerna TME panel.",
    'FW Link1':
      'QIAGEN and OncXerna Therapeutics Sign Licensing and Master Companion Diagnostic Agreements;;https://www.firstwordhealthtech.com/story/5370439/'
  },
  {
    'Date Announced': '17-Aug-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Arthrosi Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Guangzhou Ruiao Biopharmaceutical Technology',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'EGFR exon 20 inhibitor, URAT1 inhibitor',
    'Lead Asset(s)': 'AR035, AR882',
    'Indication(s)': 'Non-small-cell lung cancer',
    'Target(s)': 'EGFR exon 20, URAT1',
    'Deal Structure':
      'The joint venture will be responsible for the development of AR882 in the greater China area (Mainland China, Hong Kong, Macao, and Taiwan) while Arthrosi Therapeutics, Inc. continues to lead the global development of AR882 in the gout and tophaceous gout population. In addition, the joint venture will allow Arthrosi to enter the precision medicine oncology therapeutic area by moving AR035, a potent EGFR exon 20 inhibitor targeting non-small cell lung cancer, into IND-enabling stage.',
    'FW Link1':
      'Arthrosi Establishes Joint Venture in China to Accelerate Development of Innovative Drug Portfolio;;https://firstwordpharma.com/story/5377493/'
  },
  {
    'Date Announced': '25-Aug-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'SignalRx Pharmaceuticals',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Crystec',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Dry powder inhaled delivery, PI3K-BRD4 inhibitor',
    'Lead Asset(s)': 'SF2523',
    'Indication(s)': 'Lung cancer',
    'Target(s)': 'PI3K, BRD4',
    'Deal Structure':
      'The collaboration will involve Crystec utilising its advanced mSAS (modified supercritical antisolvent) supercritical fluid particle engineering platform to achieve precise solid-state control of SF2523 while simultaneously generating stable particles that are optimised for dry powder inhaled delivery.',
    'FW Link1':
      'New collaboration to develop an inhalable SF2523 for lung cancer, lung fibrosis and SARS-CoV-2;;https://firstwordpharma.com/story/5383287/'
  },
  {
    'Date Announced': '26-Aug-2021',
    'Deal type': 'Product Acquisition',
    'Acquiring Company/Partner': 'HealthCare Royalty',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'ADC Therapeutics',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Upfront Value ($m)': '225',
    'Total Deal Value ($m)': '325',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Zynlonta',
    'Indication(s)': 'B-cell lymphoma',
    'Target(s)': 'CD19',
    'Deal Structure':
      'Under the terms of the $325 million financing agreement, ADC Therapeutics will receive $225 million upon closing, and is eligible to receive an additional $75 million upon the first commercial sale of ZYNLONTA (loncastuximab tesirine) in Europe and an additional $25 million upon the achievement of a near-term commercial milestone for ZYNLONTA. In exchange, HealthCare Royalty will receive a 7% royalty on worldwide net sales and licensing revenue from ZYNLONTA, with the exception of greater China, Singapore and South Korea, and a 7% royalty on worldwide net sales and licensing revenue from Cami (camidanlumab tesirine) (subject to limited carve-outs). Based on performance tests in 2026 and 2027, these royalty rates are subject to potential upward adjustment, up to a maximum of 10%. The total royalty payable by ADCT to HealthCare Royalty is capped in the range of 2.25x to 2.50x of the amount paid to ADCT, dependent upon the amount of royalties paid by 2029. Once the cap is hit, the agreement terminates.',
    'FW Link1':
      'ADC Therapeutics Enters Into a Financing Agreement with HealthCare Royalty for Up to $325 Million;;https://firstwordpharma.com/story/5383925/'
  },
  {
    'Date Announced': '26-Aug-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'EOC Pharma',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Chime Biologics',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immunotherapy',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'EOC202',
    'Indication(s)': 'Cancer',
    'Target(s)': 'LAG-3',
    'Deal Structure':
      "The extended partnership will enable Chime's world-class biologics development and manufacturing capability and capacity to become part of EOC's engine to fuel the EOC202's clinical development and potential commercial supply in China.",
    'FW Link1':
      'EOC Pharma and Chime Biologics Extend Partnership;;https://firstwordpharma.com/story/5384369/'
  },
  {
    'Date Announced': '26-Aug-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Merck & Co.',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Ontada',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Real-world research',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'As part of the agreement, Merck and Ontada can collaborate on RWD studies to explore new scientific evidence, advanced methods development, and assessment of efficiency in cancer care.',
    'FW Link1':
      'Ontada Signs Strategic Agreement with Merck to Facilitate the Development of Data-Driven Insights to Impact Quality of Cancer Care;;https://firstwordpharma.com/story/5384531/'
  },
  {
    'Date Announced': '27-Aug-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Immix Biopharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'BeiGene',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Tissue Specific Therapeutics, antibody',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'IMX-110, tislelizumab',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'PD-1',
    'Deal Structure':
      'Under the terms of the agreement, ImmixBio will evaluate the combination of IMX-110 with tislelizumab in a Phase 1/2a trial in patients with advanced solid tumours.',
    'FW Link1':
      'ImmixBio Announces Clinical Trial and Supply Agreement with BeiGene to Evaluate Combination of IMX-110 and Tislelizumab in Solid Tumors;;https://firstwordpharma.com/story/5385205/'
  },
  {
    'Date Announced': '2-Sep-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Plus Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'RadioMedix',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Radiopharmaceuticals',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'Rhenium-186 NanoLiposome',
    'Indication(s)':
      'Recurrent glioblastoma, leptomeningeal metastases, paediatric brain tumours',
    'Deal Structure':
      'Under the agreement, RadioMedix will produce cGMP drug product meeting all applicable requirements of the U.S. Food and Drug Administration (FDA) and similar global regulatory entities. This strategic partnership further secures the commercial supply chain for 186RNL and extends to future products under the RNL platform.',
    'FW Link1':
      'Plus Therapeutics Announces Commercial Manufacturing Supply Agreement for Its Lead Investigational Drug;;https://firstwordpharma.com/story/5389655/'
  },
  {
    'Date Announced': '7-Sep-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Therapeutic Solutions International',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Eutilogics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'United States',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immunotherapy',
    'Lead Asset(s)': 'TAST, StemVacs-V',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Therapeutic Solutions International announced the signing of a memorandum of understanding with immunotherapy company Eutilogics, Inc., the wholly owned subsidiary of clinical stage Korean company, Eutilex Co., Ltd., based on exploring synergies between technologies of both Companies.',
    'FW Link1':
      'Therapeutic Solutions International Signs Memorandum of Understanding with Clinical Stage Immunotherapy Company Eutilogics, Inc. to Explore Augmentation of Synergies between StemVacs-V and TAST;;https://firstwordpharma.com/story/5392551/'
  },
  {
    'Date Announced': '8-Sep-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Erasca',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Pfizer',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'ERK1/2 inhibitor, BRAF inhibitor',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'ERAS-007, Braftovi',
    'Indication(s)': 'BRAF V600E-mutant metastatic colorectal cancer',
    'Target(s)': 'ERK1/2, BRAF',
    'Deal Structure':
      'This agreement will support a clinical proof-of-concept study evaluating ERAS-007, an oral ERK1/2 inhibitor, in combination with encorafenib and the EGFR inhibitor cetuximab for the treatment of patients with BRAF V600E-mutant mCRC. This combination will be investigated as part of the Phase 1b/2 HERKULES-3 trial expected to initiate in the second half of 2021. Erasca will sponsor the study, and Pfizer will supply encorafenib. The two companies will form a Joint Development Committee to review the clinical trial results.',
    'FW Link1':
      'Erasca Announces Clinical Trial Collaboration and Supply Agreement with Pfizer to Evaluate ERAS-007 and Encorafenib Combination;;https://firstwordpharma.com/story/5392989/'
  },
  {
    'Date Announced': '8-Sep-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Affinia Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Lonza',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Gene therapy',
    'Lead Asset(s)': 'Anc80L65-ARSA, Anc80L65-trastuzumab',
    'Indication(s)':
      'Metachromatic leukodystrophy, brain metastases secondary to HER2+ breast cancer',
    'Deal Structure':
      'To support supply for its initial clinical programmes, Affinia Therapeutics has entered into a multi-year, non-exclusive agreement with Lonza Group in which Lonza will provide contract development and manufacturing services to Affinia Therapeutics for its initial product candidates.',
    'FW Link1':
      'Affinia Therapeutics Announces Indications for Lead Gene Therapy Programs to Advance the Treatment of Neurologic and Neuro-oncologic Diseases supported by Multi-Year Manufacturing Agreement with Lonza Group;;https://firstwordpharma.com/story/5393083/'
  },
  {
    'Date Announced': '9-Sep-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'CG Oncology',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'BMS',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Oncolytic immunotherapy, anti-PD-1 therapy',
    'Lead Asset(s)': 'CG0070, Opdivo',
    'Indication(s)': 'Metastatic urothelial cancer',
    'Target(s)': 'PD-1',
    'Deal Structure':
      'CG Oncology will sponsor the study and Bristol Myers Squibb will provide nivolumab. Additional details of the collaboration were not disclosed.',
    'FW Link1':
      'CG Oncology Announces Clinical Trial Collaboration with Bristol Myers Squibb to Evaluate Oncolytic Immunotherapy CG0070 in Combination with OPDIVO (nivolumab) in Metastatic Urothelial Cancer;;https://firstwordpharma.com/story/5394837/'
  },
  {
    'Date Announced': '13-Sep-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Radius',
    'Country (Acquiring Company/Partner)': 'Italy',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Telix',
    'Country (Target Company/Partner)': 'Australia',
    'Region (Target Company/Partner)': 'Oceania',
    'Geographic Remit of Deal': 'Italy',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Imaging',
    'Lead Asset(s)': 'Illuccix',
    'Indication(s)': 'Prostate cancer',
    'Deal Structure':
      'Under the terms of the agreement, Radius will be the exclusive commercial distributor of Illuccix in Italy, an EU5 country, for a period of three years from the national approval date.',
    'FW Link1':
      'Telix and Radius Sign Italian Distribution Agreement for Prostate Cancer Imaging;;https://firstwordpharma.com/story/5396217/'
  },
  {
    'Date Announced': '14-Sep-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Novocure',
    'Country (Acquiring Company/Partner)': 'Jersey',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Roche',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Tumour treating fields, anti-PD-L1 therapy',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'TTFields, atezolizumab',
    'Indication(s)': 'Metastatic pancreatic ductal adenocarcinoma',
    'Target(s)': 'PD-L1',
    'Deal Structure':
      'Novocure is the study sponsor and Roche is providing atezolizumab for the trial.',
    'FW Link1':
      'Novocure Announces Clinical Trial Collaboration with Roche to Evaluate Tumor Treating Fields as Part of a Novel Combination for the First-line Treatment of Metastatic Pancreatic Cancer;;https://firstwordpharma.com/story/5397415/'
  },
  {
    'Date Announced': '14-Sep-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'IO Biotech',
    'Country (Acquiring Company/Partner)': 'Denmark',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Merck & Co.',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cancer vaccine, anti-PD-1 therapy',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'IO102-IO103, Keytruda',
    'Indication(s)': 'Metastatic melanoma',
    'Target(s)': 'IDO, PD-L1, PD-1',
    'Deal Structure':
      'Under the terms of the agreement, IO Biotech will sponsor the Phase 3 trial and MSD will supply Keytruda.',
    'FW Link1':
      'IO Biotech Announces Clinical Collaboration with MSD to Evaluate IO102-IO103 with KEYTRUDA (pembrolizumab) as First-Line Treatment in Metastatic Melanoma Patients in a Phase 3 Trial;;https://firstwordpharma.com/story/5397553/'
  },
  {
    'Date Announced': '14-Sep-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'TumorGen',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'PhenoVista Biosciences',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Cell cluster imaging, microfluidic capture system, anti-metastatic drugs',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "TumorGen will use its microfluidic platform to capture MCCCs from patient blood. PhenoVista will apply its specialised cell analysis technologies to illuminate how these clusters hold together and avoid the body's immune response. Revealing these inter-cellular signals will change how we understand metastasis, leading to new therapies.",
    'FW Link1':
      'TumorGen and PhenoVista Join Forces to Illuminate Metastasis;;https://firstwordpharma.com/story/5397743/'
  },
  {
    'Date Announced': '14-Sep-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Convergent Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'POINT Biopharma',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'PSMA-targeted radioligand, monoclonal antibody conjugated with 225Ac, radiopharmaceuticals',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'PNT2002, CONV 01-α',
    'Indication(s)':
      'Progressive metastatic castration-resistant prostate cancer',
    'Target(s)': 'PSMA',
    'Deal Structure':
      "Convergent Therapeutics and POINT Biopharma Global Inc. announced a collaboration to evaluate the combination of CONV 01-α (225Ac−J591, or rosopatamab-225Ac), Convergent's antibody directed at prostate-specific membrane antigen (PSMA) coupled with Actinium-225 (225Ac), an alpha particle emitter and POINT's PSMA radioligand PNT2002 linked to Lutetium-177 (177Lu), a beta particle emitter in men with progressive metastatic castration-resistant prostate cancer (mCRPC) in a Phase I/II investigator-initiated trial.",
    'FW Link1':
      'Convergent Therapeutics and Point Biopharma Announce a Collaboration to Evaluate CONV 01-α (Rosopatamab-225Ac) in combination with PNT2002 for Progressive Metastatic Castration-Resistant Prostate Cancer;;https://firstwordpharma.com/story/5397767/'
  },
  {
    'Date Announced': '14-Sep-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'PhoreMost',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Vipergen',
    'Country (Target Company/Partner)': 'Denmark',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'DNA-encoded library (DEL)-based drug discovery',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Under the terms of the agreement, Vipergen will apply its high-fidelity DEL technology platforms to identify novel small-molecule compounds that bind to selected PhoreMost protein targets. PhoreMost will select hits for development into novel therapeutics for oncology. PhoreMost will retain exclusive rights to globally commercialise any products resulting from the collaboration. Financial details of the agreement were not disclosed.',
    'FW Link1':
      'Vipergen Establishes Research Partnership with PhoreMost Focused on DNA Encoded Library (DEL)-Based Drug Discovery;;https://firstwordpharma.com/story/5398127/'
  },
  {
    'Date Announced': '15-Sep-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'HOOKIPA Pharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Merck & Co.',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Arenaviral immunotherapeutic, anti-PD-1 therapy',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'HB-200, Keytruda',
    'Indication(s)': 'Advanced head and neck squamous cell carcinoma',
    'Target(s)': 'PD-1',
    'Deal Structure':
      "HOOKIPA Pharma has entered into a clinical collaboration and supply agreement with Merck & Co. to evaluate the combination of HB-200, a novel arenaviral immunotherapeutic, and Merck & Co.'s anti-PD-1 therapy, KEYTRUDA (pembrolizumab) as first-line treatment for patients with advanced head and neck squamous cell carcinoma (HNSCC).",
    'FW Link1':
      'HOOKIPA announces clinical collaboration with Merck & Co., Inc., Kenilworth, NJ., USA to evaluate HB-200 in combination with KEYTRUDA (pembrolizumab) in patients with advanced head and neck cancers;;https://firstwordpharma.com/story/5398567/'
  },
  {
    'Date Announced': '16-Sep-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Boehringer Ingelheim',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Amgen',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'SOS1::pan-KRAS inhibitor, KRASG12C inhibitor',
    'Lead Asset(s)': 'BI 1701963, Lumakras',
    'Indication(s)': 'KRAS G12C-driven cancers',
    'Target(s)': 'KRAS, SOS1',
    'Deal Structure':
      'Under the terms of the non-exclusive collaboration, Amgen will be the sponsor of the trial and Boehringer Ingelheim and Amgen will jointly share the costs of and oversee clinical development for the combined therapy.',
    'FW Link1':
      'Boehringer Ingelheim Enters Clinical Collaboration with Amgen to Study BI 1701963, a SOS1::pan-KRAS Inhibitor, in Combination with LUMAKRAS (sotorasib), a KRASG12C Inhibitor;;https://firstwordpharma.com/story/5399529/'
  },
  {
    'Date Announced': '16-Sep-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'LaNova Medicines',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Asymchem',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Small molecule drugs, monoclonal antibodies, double antibodies, antibody-drug conjugates',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Asymchem announced a strategic partnership agreement to provide LaNova Medicines with one-stop services such as small molecule drugs, ADC project CMCs, R&D, production and IND China-US declaration.',
    'FW Link1':
      'Asymchem and LaNova Medicines Enter into Partnership Agreement, Expanding Biological Capabilities;;https://firstwordpharma.com/story/5399927/'
  },
  {
    'Date Announced': '20-Sep-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Verastem Oncology',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Amgen',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Dual RAF/MEK inhibitor, KRAS G12C inhibitor',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'VS-6766, Lumakras',
    'Indication(s)': 'KRAS G12C-mutant non-small-cell lung cancer',
    'Target(s)': 'RAF/MEK, KRAS',
    'Deal Structure':
      "Verastem Oncology announced a clinical collaboration agreement with Amgen to evaluate the combination of VS-6766, Verastem Oncology's investigational dual RAF/MEK inhibitor, with Amgen's KRAS G12C inhibitor LUMAKRAS (sotorasib) in KRAS G12C-mutant non-small cell lung cancer (NSCLC).",
    'FW Link1':
      'Verastem Oncology and Amgen Partner to Evaluate VS-6766 in Combination with LUMAKRAS (Sotorasib) in Patients with KRAS G12C-Mutant Non-Small Cell Lung Cancer;;https://firstwordpharma.com/story/5401753/'
  },
  {
    'Date Announced': '21-Sep-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Ascelia Pharma',
    'Country (Acquiring Company/Partner)': 'Sweden',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Taiho Oncology',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Chemotherapy',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'Oncoral, Lonsurf',
    'Indication(s)': 'Gastric cancer',
    'Deal Structure':
      'As part of the agreement, Taiho Oncology will supply LONSURF as well as provide scientific expertise for the study. Depending on the results, the collaboration may be extended for further development of the two agents. Ascelia Pharma retains full development and commercialisation rights to Oncoral.',
    'FW Link1':
      'Ascelia Pharma Signs Clinical Collaboration Agreement With Taiho Oncology, Inc. for the Development of Oncoral in Combination with LONSURF;;https://firstwordpharma.com/story/5402731/'
  },
  {
    'Date Announced': '21-Sep-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Nektar',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Merck KGaA',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'IL-15 receptor agonist, PD-L1 inhibitor',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'NKTR-255, Bavencio',
    'Indication(s)': 'Urothelial carcinoma',
    'Target(s)': 'IL-15, PD-L1',
    'Deal Structure':
      'Under the new collaboration, Merck KGaA and Pfizer will include the combination of NKTR-255 plus avelumab in the new JAVELIN Bladder Medley study. The study is a recently designed global, multi-centre Phase II umbrella trial evaluating different avelumab-based combinations, compared with avelumab monotherapy, as potential maintenance therapy regimens for patients with locally advanced or metastatic UC that has not progressed with a first-line platinum-containing chemotherapy regimen. Nektar will supply NKTR-255 for the trial. Nektar and the Merck KGaA-Pfizer alliance will each maintain existing global commercial rights to their respective medicines.',
    'FW Link1':
      'Nektar Announces New Clinical Collaboration with Merck KGaA Darmstadt, Germany and Pfizer Inc. to Combine NKTR-255, a Novel Interleukin-15 Agonist, with Avelumab in the JAVELIN Bladder Medley Study;;https://firstwordpharma.com/story/5402867/'
  },
  {
    'Date Announced': '21-Sep-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Black Diamond Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'OpenEye Scientific',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Drug discovery, cloud-based molecular dynamics technology, MasterKey inhibitor cancer therapies',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'The arrangement between OpenEye and Black Diamond involves upfront payment and potential downstream economics resulting from select Black Diamond products for OpenEye.',
    'FW Link1':
      'Black Diamond Therapeutics and OpenEye Scientific Announce Collaboration to Expand MAP Drug Discovery Platform;;https://firstwordpharma.com/story/5403151/'
  },
  {
    'Date Announced': '23-Sep-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Allarity Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Lonza',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Pan-tyrosine kinase inhibitor',
    'Lead Asset(s)': 'dovitinib',
    'Indication(s)': 'Renal cell carcinoma',
    'Target(s)': 'Tyrosine kinases',
    'Deal Structure':
      "Under the terms of the agreement, Lonza will leverage its capabilities for commercial manufacturing of small-molecules and oral solid dosage forms to provide Allarity with cGMP compliant drug product supply and regulatory support towards commercialisation. Allarity will leverage Lonza's global network, technical capabilities, and integrated solution covering both drug substance and drug product. The drug substance manufacturing and particle size reduction by micronization will be performed at Lonza's facility in Visp (CH). The drug product manufacturing will take place at the Tampa, FL (US) facility.",
    'FW Link1':
      'Allarity Therapeutics and Lonza Collaborate to Manufacture Dovitinib, a Renal Cell Carcinoma Candidate;;https://firstwordpharma.com/story/5405477/'
  },
  {
    'Date Announced': '27-Sep-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Genetron',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'IMPACT Therapeutics',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Synthetic lethal inhibitors, companion diagnostic products',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'The two parties will cooperate together on research and development for synthetic lethal inhibitors that are based on new targets, and the development of companion diagnostic products.',
    'FW Link1':
      'Genetron Health and IMPACT Therapeutics Announce Partnership to Drive Development of Synthetic Lethal Product Pipeline;;https://firstwordpharma.com/story/5407317/'
  },
  {
    'Date Announced': '28-Sep-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Lantern Pharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Deep Lens',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Artificial intelligence enabled drug development pathway',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'LP-300',
    'Indication(s)': 'Cancer, non-small-cell lung cancer',
    'Deal Structure':
      "Lantern Pharma and Deep Lens have entered into a strategic collaboration that will leverage Deep Lens' artificial intelligence clinical trial matching solution, VIPERÔ, creating an end-to-end A.I. enabled drug development pathway that is expected to accelerate trial enrollment for Lantern's planned Phase 2 clinical trial for never-smokers with non-small cell lung cancer (NSCLC), utilizing LP-300 in combination with chemotherapy.",
    'FW Link1':
      'Lantern Pharma Enters into Strategic Collaboration with Deep Lens;;https://firstwordpharma.com/story/5408213/'
  },
  {
    'Date Announced': '5-Oct-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'T-Cure Bioscience',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Atlas Antibodies',
    'Country (Target Company/Partner)': 'Sweden',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Monoclonal antibodies, companion diagnostic, T-cell therapy',
    'Indication(s)': 'KK-LC-1 positive cancers',
    'Target(s)': 'KK-LC-1',
    'Deal Structure':
      'Under the terms of the agreement, Atlas Antibodies AB will provide all manufacturing and product supply for KK-LC-1 mAb for the companion diagnostic for clinical development. T-Cure is developing TCR-T therapeutics targeting Kita-Kyushu Lung Cancer Antigen 1 (KK-LC-1) for gastric, triple negative breast cancer, cervical, lung and other KK-LC-1 positive cancers.',
    'FW Link1':
      'T-Cure Bioscience, Inc. and Atlas Antibodies AB Enter a Collaboration Agreement for the Development, Manufacture, and Supply of CT83 (KK-LC-1) Monoclonal Antibodies;;https://firstwordpharma.com/story/5412837/'
  },
  {
    'Date Announced': '6-Oct-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Merck & Co.',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Syapse',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Real-world data',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "The collaboration will leverage real-world data (RWD) to drive high-quality insights that will impact patient care and advance oncology research. Under the collaboration, Merck will leverage Syapse's proprietary RWD platform.",
    'FW Link1':
      'Syapse Enters Real-World Data Collaboration with Merck to Inform and Improve Cancer Care;;https://firstwordpharma.com/story/5414213/'
  },
  {
    'Date Announced': '7-Oct-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Caris Life Sciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'ESSA Pharma',
    'Country (Target Company/Partner)': 'Canada',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Liquid biopsies, N-terminal domain androgen receptor inhibitor',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'EPI-7386',
    'Indication(s)': 'Metastatic castration-resistant prostate cancer ',
    'Target(s)': 'N-terminal domain of the androgen receptor',
    'Deal Structure':
      "Under the terms of the agreement, Caris and ESSA will evaluate patient blood samples to assess genetic profiles utilizing Caris' Whole Transcriptome Sequencing (WTS) and Whole Exome Sequencing (WES) platform. ESSA will utilize these liquid biopsies, including longitudinal data from serial samples, to better characterize the tumor biological profiles of patients in the ongoing monotherapy clinical trial of EPI-7386 in mCRPC patients.",
    'FW Link1':
      'Caris Life Sciences and ESSA Pharma Announce Liquid Biopsy Profiling Collaboration;;https://firstwordpharma.com/story/5414965/'
  },
  {
    'Date Announced': '7-Oct-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'ITM',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Navigo Proteins',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Radiopharmaceuticals, radionuclide therapy',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Under the terms of the collaboration agreement, the companies work together to develop targeted radiopharmaceutical candidates for the treatment of cancer by combining Navigo's Affilin ligands with ITM's high-quality medical radioisotopes. The partnership will now be further strengthened by the addition of another targeted radionuclide therapy project in the precision oncology field.",
    'FW Link1':
      'Navigo Proteins GmbH Announces Extension of Partnership with ITM for Targeted Radio Conjugates;;https://firstwordpharma.com/story/5414981/'
  },
  {
    'Date Announced': '7-Oct-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Schrödinger',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'University of Texas MD Anderson Cancer Center',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'WEE1 inhibitors',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Cancer',
    'Target(s)': 'WEE1',
    'Deal Structure':
      "MD Anderson and Schrödinger will jointly pursue translational studies, and Schrödinger will provide research support funding. As part of the agreement, MD Anderson is eligible to receive certain payments based on the future development and commercialization of Schrödinger's WEE1 inhibitor compounds. Schrödinger will have sole responsibility for the development, manufacture and commercialization of all compounds and products, and sole rights to all novel intellectual property that arises from this collaboration.",
    'FW Link1':
      'MD Anderson and Schrödinger Announce Strategic Research Collaboration to Accelerate Development of WEE1 Program;;https://firstwordpharma.com/story/5415225/'
  },
  {
    'Date Announced': '7-Oct-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Mirati Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Sanofi',
    'Country (Target Company/Partner)': 'France',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'KRASG12C inhibitor, SHP2 inhibitor',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'adagrasib, SAR442720',
    'Indication(s)': 'KRAS G12C-mutated lung cancer',
    'Target(s)': 'KRASG12C, SHP2',
    'Deal Structure':
      'Under the terms of the agreement, Sanofi will be responsible for sponsoring and operating the Phase 1/2 study, and jointly with Mirati, will oversee and share costs of the study.',
    'FW Link1':
      'Mirati Therapeutics to Collaborate with Sanofi on Phase 1/2 Study Evaluating Combination of adagrasib with a SHP2 Inhibitor in KRAS G12C-mutated Lung Cancer;;https://firstwordpharma.com/story/5415417/'
  },
  {
    'Date Announced': '13-Oct-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Geneseeq',
    'Country (Acquiring Company/Partner)': 'Canada',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Zai Lab',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cancer diagnostics, cancer drugs',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "The companies will collaborate to facilitate the R&D and commercialization of Zai Lab's cancer drugs through business partnerships in clinical trial patient recruitment, central laboratory testing, companion diagnostics development and marketing activities.",
    'FW Link1':
      'Geneseeq and Zai Lab Announce Strategic Collaboration to Advance Cancer Drug R&D;;https://www.firstwordhealthtech.com/story/5419015/'
  },
  {
    'Date Announced': '13-Oct-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Turning Point Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'EQRx',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Tyrosine kinase inhibitors',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'elzovantinib, aumolertinib',
    'Indication(s)':
      'EGFR mutant MET-amplified advanced non-small-cell lung cancer',
    'Target(s)': 'MET, SRC, CSF1R, EGFR',
    'Deal Structure':
      'Under the terms of the agreement, Turning Point will sponsor and conduct a Phase 1b/2 clinical trial to evaluate the safety, tolerability and preliminary efficacy of the combination regimen and will assume all costs associated with the trial. EQRx will provide aumolertinib at no cost.',
    'FW Link1':
      'Turning Point Therapeutics and EQRx Announce Clinical Collaboration to Evaluate Elzovantinib in Combination with Aumolertinib in Patients with EGFR Mutant Met-Amplified Advanced Non-Small Cell Lung Cancer;;https://firstwordpharma.com/story/5419311/'
  },
  {
    'Date Announced': '26-Oct-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Indivumed',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Biognosys',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immunopeptidomics, drug discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Indivume, and Biognosys announced that they are extending their strategic partnership to provide biopharma customers with immunopeptidomics insights for oncology drug discovery.',
    'FW Link1':
      'Indivumed and Biognosys Extend Strategic Partnership;;https://firstwordpharma.com/story/5428373/'
  },
  {
    'Date Announced': '27-Oct-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Biomica',
    'Country (Acquiring Company/Partner)': 'Israel',
    'Region (Acquiring Company/Partner)': 'Middle East',
    'Target Company/Partner': 'Rambam Health Care Campus',
    'Country (Target Company/Partner)': 'Israel',
    'Region (Target Company/Partner)': 'Middle East',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Microbiome-based immuno-oncology drug candidate, immunotherapy',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'BMC128',
    'Indication(s)':
      'Non-small-cell lung cancer, melanoma, renal cell carcinoma',
    'Target(s)': 'PD-1',
    'Deal Structure':
      "Biomica and Rambam Health Care Campus announced the signing of a clinical trial agreement (CTA) for initiating a first in-human proof-of-concept (POC) for BMC128, Biomica's drug candidate.",
    'FW Link1':
      "Biomica & Rambam Health Care Campus Sign Agreement for Clinical Trial of Biomica's Microbiome-Based Immuno-Oncology Drug;;https://firstwordpharma.com/story/5429397/"
  },
  {
    'Date Announced': '27-Oct-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Signet Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'XtalPi',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'AI drug discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Signet Therapeutics and XtalPi announced the expansion of their AI drug discovery collaboration to include a new first-in-class program against a novel cancer target identified by Signet. The two companies entered into a strategic collaboration in 2020 and successfully identified pre-clinical candidates for a new gastric cancer target in approximately six months. The follow-on project will continue to combine XtalPi's AI drug discovery platform with Signet's unique novel organoid disease models to generate pipeline candidates for Signet and advance them toward clinical trials.",
    'FW Link1':
      'XtalPi and Signet Expand AI Drug Discovery Collaboration to Novel Cancer Target;;https://www.firstwordhealthtech.com/story/5429509/'
  },
  {
    'Date Announced': '27-Oct-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Innovent Biologics',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'NeoCura',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Neoantigen vaccine, PD-1 inhibitor',
    'Lead Asset(s)': 'NEO_PLIN2101, sintilimab',
    'Indication(s)': 'Cancer',
    'Target(s)': 'PD-1',
    'Deal Structure':
      'Innovent will collaborate with NeoCura in China to assess the safety, pharmacokinetics, pharmacodynamics and preliminary efficacy of the combination therapy using sintilimab from Innovent and NEO_PLIN2101 from NeoCura in cancer patients, to advance the clinical development of combination immunotherapy for multiple solid tumours, and prepare to submit the Investigational New Drug (IND) application to the National Medical Products Administration (NMPA) in the near future.',
    'FW Link1':
      'Innovent and NeoCura Announce Strategic Collaboration to Study the Combination Therapy of Sintilimab and Neoantigen Vaccine NEO_PLIN2101 for Cancer Treatment in China;;https://firstwordpharma.com/story/5430183/'
  },
  {
    'Date Announced': '1-Nov-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Kinnate Biopharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Guardant Health',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'BRAF alterations, biomarker, real-world data',
    'Indication(s)': 'BRAF alteration-positive cancers',
    'Target(s)': 'BRAF',
    'Deal Structure':
      'Kinnate Biopharma announced a collaboration with Guardant Health focused on characterizing the prevalence of patients with advanced solid tumors bearing BRAF Class I, II and III alterations. The study will also assess real-world clinical outcomes stratified by BRAF alteration class and by treatment type.',
    'FW Link1':
      'Kinnate Biopharma Inc. in Collaboration with Guardant Health Announces Initial Findings from BRAF Kinase Alteration Genomic Landscape and Real-World Clinical Outcomes Study;;https://firstwordpharma.com/story/5433217/'
  },
  {
    'Date Announced': '4-Nov-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'IMPACT Therapeutics',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Burning Rock',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'United States, China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'ATR inhibitor',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'IMP9064',
    'Indication(s)': 'Cancer',
    'Target(s)': 'ATR',
    'Deal Structure':
      "Burning Rock Biotech announced a collaboration with IMPACT Therapeutics where Burning Rock provides testing services in connection with IMPACT Therapeutics' development of its ATR inhibitor IMP9064, which has received the Investigational New Drug (IND) clearance from the U.S. Food and Drug Administration (FDA) for the Phase I/II clinical study, and will begin soon in the U.S. The collaboration will be supported by Burning Rock's Clinical Laboratory Improvement Amendments (CLIA) certified and College of American Pathologists (CAP) accredited labs in Guangzhou, China and California, U.S.",
    'FW Link1':
      'Burning Rock Collaborates with IMPACT Therapeutics to Provide Testing Services in Developing IMP9064 in both US and China;;https://www.firstwordhealthtech.com/story/5435203/'
  },
  {
    'Date Announced': '8-Nov-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Hoth Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'WuXi STA',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Morpholino oligonucleotide therapy',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'HT-KIT',
    'Indication(s)': 'Mast cell-derived cancers',
    'Target(s)': 'KIT',
    'Deal Structure':
      'Hoth Therapeutics has entered into two contracts with STA Pharmaceutical, a subsidiary of WuXi AppTec (WuXi STA), for process development and manufacturing of the active pharmaceutical ingredient (API) and formulation of drug product for the continued development and commercialization of HT-KIT.',
    'FW Link1':
      'Hoth Therapeutics Inks API and Drug Product Contracts with WuXi STA to Advance Manufacturing of HT-KIT Cancer Therapeutic;;https://firstwordpharma.com/story/5437771/'
  },
  {
    'Date Announced': '8-Nov-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Roche',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'I-Mab',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Companion diagnostics solutions',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Under this collaboration, I-Mab and Roche Diagnostics will jointly develop companion diagnostics solutions for the innovative assets under development by I-Mab to accelerate the research and development process of innovative biologics with cutting-edge diagnosis and treatment technologies.',
    'FW Link1':
      "I-Mab and Roche Diagnostics Announce Strategic Collaboration to Co-Develop Companion Diagnostics Solutions for I-Mab's Innovative Pipeline at the 4th CIIE;;https://firstwordpharma.com/story/5437799/"
  },
  {
    'Date Announced': '8-Nov-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Ascentage Pharma',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Pfizer',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Bcl-2 selective inhibitor, CDK4/6 inhibitor',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'lisaftoclax, Ibrance',
    'Indication(s)': 'Metastatic ER+/HER2- breast cancer',
    'Target(s)': 'Bcl-2, CDK4/6',
    'Deal Structure':
      'Under the terms of the agreement, Ascentage Pharma is responsible for conducting the trial and Pfizer is responsible for supplying study drug for a global, multicenter, open-label Phase Ib/II dose-escalation and expansion study, which is designed to evaluate the safety, tolerability, and anticancer activity of lisaftoclax in combination with palbociclib in patients with metastatic breast cancer.',
    'FW Link1':
      'Ascentage Pharma Announces Clinical Trial Agreement to Evaluate the Combination of Lisaftoclax (APG-2575) and the CDK4/6 Inhibitor IBRANCE (Palbociclib) in Metastatic ER+/HER2- Breast Cancer;;https://firstwordpharma.com/story/5437813/'
  },
  {
    'Date Announced': '8-Nov-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Kronos Bio',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Tempus',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Real-world genomic and transcriptomic data, data analytics tools',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Under the agreement, Kronos Bio will have access to a number of precision medicine tools, including Tempus' modeling lab, which houses a growing repository of molecularly profiled organoids. Access to such data and tools will help Kronos Bio accelerate the preclinical and clinical development of its current and future portfolio, by for example, using organoid models to generate biomarker hypotheses that can be tested in the clinic or in virtual molecularly defined patient cohorts drawn from Tempus' extensive data sets.",
    'FW Link1':
      'Kronos Bio and Tempus Announce Multi-Year Collaboration to Provide Kronos Bio With Access to Tempus’ Platform;;https://firstwordpharma.com/story/5438063/'
  },
  {
    'Date Announced': '10-Nov-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': '9 Meters Biopharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Gustave Roussy',
    'Country (Target Company/Partner)': 'France',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Gut microbiome modulator, immuno-oncology',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'NM-102',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Under the terms of the collaboration agreement, Gustave Roussy will investigate how tumors in preclinical models may effect intestinal integrity and in turn compromise the fitness of the host's immune system and its capacity to respond to ICIs. Further work is planned to decipher the mechanism of NM-102 and its effects on intervening on the epithelial layer of the GI tract, as well as its potential translation to ICI efficacy in preclinical cancer in vivo models. The Company intends to move forward with an IND-enabling pathway.",
    'FW Link1':
      '9 Meters Biopharma, Inc. Announces Immuno-Oncology Collaboration with Gustave Roussy for NM-102, a Gut Microbiome Modulator;;https://firstwordpharma.com/story/5439665/',
    'FW Link2':
      'Continued collaboration announced November 14, 2022 https://firstwordpharma.com/story/5675086/'
  },
  {
    'Date Announced': '16-Nov-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Viewpoint Molecular Targeting',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'National Nuclear Laboratory',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'United Kingdom, Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Targeted alpha therapy, radiopharmaceutical manufacturing',
    'Lead Asset(s)': 'Pb-212',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Viewpoint Molecular Targeting has entered into a Memorandum of Understanding (MoU) with The National Nuclear Laboratory (NNL) to explore avenues to supply Pb-212 to researchers, and possibly clinicians, in the United Kingdom and Europe, using Viewpoint's proprietary isotope generator, VMT--GEN.",
    'FW Link1':
      'Viewpoint Molecular Targeting Enters Agreement with UK-Based National Nuclear Laboratory, Ltd. to Advance Development of Targeted Alpha Therapy for Cancer;;https://firstwordpharma.com/story/5443745/'
  },
  {
    'Date Announced': '17-Nov-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Abintus Bio',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Memorial Sloan Kettering Cancer Center',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Next generation signalling technology, in vivo CAR-X products',
    'Indication(s)': 'Haematologic malignancies, solid tumours',
    'Deal Structure':
      'Abintus Bio has entered into an agreement with Memorial Sloan Kettering Cancer Center for the use of its next generation signaling technology ("1XX") in Abintus\' In Vivo CAR-X products. These products have the potential to overcome the challenges of ex vivo CAR technologies and dramatically improve outcomes and access for patients with hematologic malignancies and solid tumors.',
    'FW Link1':
      'Abintus Bio Enters Technology Agreement to Enhance In Vivo-Reprogrammed CAR Cell Potency and Durability Against Hematologic Malignancies and Solid Tumors;;https://firstwordpharma.com/story/5444557/'
  },
  {
    'Date Announced': '17-Nov-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'CoImmune',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Yeda',
    'Country (Target Company/Partner)': 'Israel',
    'Region (Target Company/Partner)': 'Middle East',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Synthetic Immune Niche (SIN) technology, off-the-shelf allogenic cell products, CAR-CIK',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "CoImmune announced a research collaboration with Yeda Research and Development Company Ltd. (Yeda), the commercial arm of the Weizmann Institute of Science, to evaluate the applications of the Synthetic Immune Niche (SIN) technology, developed by Weizmann Institute's researchers to enhance the expansion and function of human cytokine induced killer (CIK) cells expressing chimeric antigen receptors (CARs).",
    'FW Link1':
      'CoImmune, Inc. Announces Research Collaboration with Yeda Research and Development Company Ltd.;;https://firstwordpharma.com/story/5444559/'
  },
  {
    'Date Announced': '17-Nov-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'OncoOne',
    'Country (Acquiring Company/Partner)': 'Austria',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'GenScript',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Monoclonal antibody drugs targeting oxMIF, immuno-oncology',
    'Indication(s)': 'Cancer',
    'Target(s)': 'oxMIF',
    'Deal Structure':
      'OncoOne and GenScript ProBio, a leading CDMO in biologics and gene cell therapy, have entered into an agreement for the cell line development, drug substance and drug product manufacturing of therapeutic and diagnostic monoclonal antibodies (mAbs) directed against the promising immuno-oncology target, oxidized macrophage migration inhibitory factor (oxMIF). The companies will combine their respective expertise in drug discovery and manufacturing to bring the resulting mAb candidates into the clinic. Further terms of the agreement have not been disclosed.',
    'FW Link1':
      'OncoOne and GenScript ProBio Enter Agreement for the Manufacturing of Monoclonal Antibody Drugs Targeting oxMIF;;https://firstwordpharma.com/story/5444563/'
  },
  {
    'Date Announced': '17-Nov-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Merck KGaA',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Burning Rock',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Mainland China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Liquid biopsy-based companion diagnostic development, MET inhibitor',
    'Lead Asset(s)': 'tepotinib',
    'Indication(s)':
      'Advanced non-small cell lung cancer (NSCLC) harbouring MET gene alterations',
    'Target(s)': 'MET',
    'Deal Structure':
      "Burning Rock Biotech announced a strategic partnership with Merck KGaA, Darmstadt, Germany, in companion diagnostics (CDx) development for the MET inhibitor tepotinib in the mainland China market. The CDx test is based on Burning Rock's self-developed next generation sequencing (NGS)-based liquid biopsy solution, OncoCompass Target panel.",
    'FW Link1':
      'Merck KGaA, Darmstadt, Germany and Burning Rock Collaborate on Liquid-biopsy Based CDx Development Using Burning Rock’s OncoCompass Target;;https://www.firstwordhealthtech.com/story/5445011/'
  },
  {
    'Date Announced': '22-Nov-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Mirati Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Verastem',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'KRASG12C inhibitor, RAF/MEK inhibitor',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'adagrasib, VS-6766',
    'Indication(s)': 'KRASG12C-mutant non-small cell lung cancer',
    'Target(s)': 'KRASG12C, RAF/MEK',
    'Deal Structure':
      'Under the terms of the agreement, Verastem Oncology and Mirati will have joint oversight of the study.',
    'FW Link1':
      'Mirati Therapeutics and Verastem Oncology Partner to Evaluate Adagrasib in Combination with VS-6766 in KRASG12C-Mutant Non-Small Cell Lung Cancer;;https://firstwordpharma.com/story/5446781/'
  },
  {
    'Date Announced': '22-Nov-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Qurient',
    'Country (Acquiring Company/Partner)': 'Korea, South',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Merck & Co.',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Axl/Mer/CSF1R inhibitor, anti-PD-1 therapy',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'Q702, Keytruda',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'Axl, Mer, CSF1R, PD-1',
    'Deal Structure':
      'Under this agreement, Qurient will conduct a phase 1b/2 study in the U.S. and Korea to evaluate safety and efficacy of Q702 and KEYTRUDA in combination for the treatment of selected advanced solid tumors, including esophageal, gastric, hepatocellular, and cervical cancers. Terms of the collaboration were not disclosed.',
    'FW Link1':
      'Qurient Announces Collaboration Agreement with MSD to Evaluate Selective Triple Inhibitor Q702 in Combination With KEYTRUDA (pembrolizumab);;https://firstwordpharma.com/story/5447215/'
  },
  {
    'Date Announced': '29-Nov-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Turnstone Biologics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Moffitt Cancer Center',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Tumour-infiltrating lymphocyte (TIL) immunotherapies',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'TIDAL-01',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      "Under the terms of the agreement, Moffitt will collaborate on the development of Turnstone's pipeline of selected TILs for the identification, enrichment and expansion of neoantigen reactive TILs in solid tumor indications, including melanoma, breast and colorectal cancers. Furthermore, Turnstone will transfer their proprietary TIL manufacturing process to Moffitt to produce cell products for upcoming clinical studies of Turnstone's TIL product candidates. Turnstone will provide financial support for research and development at Moffitt over the multi-year collaboration.",
    'FW Link1':
      'Turnstone Biologics Announces Research Collaboration with Moffitt Cancer Center to Advance Novel TIL Immunotherapies for Solid Tumor Indications;;https://firstwordpharma.com/story/5450761/',
    'FW Link2':
      'Partnership expansion announced July 6, 2022;;https://firstwordpharma.com/story/5608857/'
  },
  {
    'Date Announced': '30-Nov-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'NiKang Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Pfizer',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'HIF2α inhibitor, CDK4/6 inhibitor, PD-1 inhibitor',
    'Lead Asset(s)': 'NKT2152, palbociclib, sasanlimab',
    'Indication(s)': 'Advanced clear cell renal cell carcinoma',
    'Target(s)': 'HIF2α, CDK4/6, PD-1',
    'Deal Structure':
      'Under the terms of the agreement, NiKang will sponsor the study, and Pfizer will co-fund the trials and provide its drugs at no cost to NiKang. The two companies will form a Joint Development Committee to oversee this collaboration.',
    'FW Link1':
      'NiKang Therapeutics and Pfizer Enter Clinical Trial Collaboration and Supply Agreement to Evaluate Novel Combination Therapies for the Treatment of Advanced Clear Cell Renal Cell Carcinoma;;https://firstwordpharma.com/story/5451775/'
  },
  {
    'Date Announced': '1-Dec-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Molecular Targeting Technologies',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Evergreen Theragnostics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Radiopharmaceuticals, Lu-177 based radiotherapeutics',
    'Lead Asset(s)': 'EBTATE, EBRGD',
    'Indication(s)':
      'Neuroendocrine and other SSTR2 expressing tumours, integrin expressing cancers like glioblastoma multiforme',
    'Deal Structure':
      "Evergreen will manufacture MTTI clinical supplies from its newly commissioned facility in Springfield, NJ and will leverage the team's broad experience with Lu-177 based radiotherapeutics by providing comprehensive supply chain management from isotope sourcing to drug delivery at the clinical trial site.",
    'FW Link1':
      'MTTI partners with Evergreen to manufacture EvaThera platform of radiopharmaceuticals;;https://firstwordpharma.com/story/5453263/'
  },
  {
    'Date Announced': '1-Dec-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'J&J',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Tempus',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Artificial intelligence/machine learning, real-world evidence, drug discovery/development',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Tempus announced the expansion of its multi-year agreement with Janssen Research & Development, LLC (Janssen). The expanded scope is anchored in new AI-based work, in which Tempus will collaborate with a multidisciplinary team of data scientists at Janssen to enhance the discovery and development of therapies for oncology indications using AI/ML and real-world evidence (RWE). This agreement leverages Tempus' platform, including its multimodal data library, genomic sequencing offerings, and TIME Trial Network of clinical sites to advance the recruitment of patients.",
    'FW Link1':
      'Tempus Announces Agreement With Janssen R&D to Leverage AI/ML and Real-World Evidence to Enhance the Discovery and Development of Oncology Therapies;;https://firstwordpharma.com/story/5453545/'
  },
  {
    'Date Announced': '1-Dec-2021',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Predictive Oncology',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'zPREDICTA',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Tumour-specific in vitro 3D cell culture models for drug discovery and development',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Predictive Oncology announced the acquisition of zPREDICTA, a company pioneering tumor-specific in vitro 3D cell culture models for drug discovery and development. The acquisition immediately adds a new anticipated revenue stream to Predictive Oncology and contributes to the company's larger mission of supporting the development of new cancer therapies from drug discovery through regulatory approval. Based on zPREDICTA's historical operating results and anticipated synergies, Predictive Oncology believes the acquisition will be accretive on a short term as well as a long term basis. zPREDICTA provides its services to many leading global biotechnology and pharmaceutical companies.",
    'FW Link1':
      'Predictive Oncology Acquires zPREDICTA, Inc.;;https://www.firstwordhealthtech.com/story/5453557/'
  },
  {
    'Date Announced': '2-Dec-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Leucid Bio',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Lonza',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'CAR-T therapies, automated manufacturing',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Under the terms of the collaboration, Leucid will utilise Lonza as its preferred manufacturer, aiming to deliver high quality cell therapies quickly and cost-effectively to patients in a decentralized manufacturing model. Leucid is, in turn, one of Lonza's preferred collaborators on the Cocoon Platform, and both companies will work together to optimise and streamline the manufacturing process for Leucid's CAR-T therapies.  As a preferred collaborator, Leucid will have early access to new Cocoon Platform technologies.",
    'FW Link1':
      'Leucid Bio and Lonza Enter Strategic Collaboration to Leverage the Cocoon Platform for Automated Manufacturing of Personalised CAR T-Cells;;https://firstwordpharma.com/story/5453989/'
  },
  {
    'Date Announced': '2-Dec-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Clarity Pharmaceuticals',
    'Country (Acquiring Company/Partner)': 'Australia',
    'Region (Acquiring Company/Partner)': 'Oceania',
    'Target Company/Partner': 'Cardinal Health',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Targeted copper theranostics, radiopharmaceuticals',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Cardinal Health will provide cGMP product manufacturing of ready-to-use TCT for Clarity's U.S.-based clinical trials from its Center for Theranostics Advancement in Indianapolis, Indiana.",
    'FW Link1':
      'Clarity and Cardinal Health enter into Agreement for Targeted Copper Theranostics;;https://firstwordpharma.com/story/5454499/'
  },
  {
    'Date Announced': '3-Dec-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'OrigiMed',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'J&J',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Clinical innovative solutions, real-world evidence, companion diagnostic products, anti-tumour drugs',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Leveraging the power of real-world evidence, both parties will jointly explore to optimize clinical trials and patient enrollment, develop clinical innovative solutions, boost the development and commercialization processes of companion diagnostic products and anti-tumor drugs by virtue of their strong scientific research capabilities and extensive medical resources, thereby providing comprehensive health management solutions for patients.',
    'FW Link1':
      'OrigiMed announced its strategic partnership with Janssen to develop clinical innovative solutions driven by data science;;https://firstwordpharma.com/story/5454813/'
  },
  {
    'Date Announced': '3-Dec-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Molecular Partners',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'University of Bern',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Multispecific DARPin',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'MP0533',
    'Indication(s)': 'Acute myeloid leukaemia',
    'Target(s)': 'CD33, CD123, CD70',
    'Deal Structure':
      "Under the agreement, the researchers will work with Molecular Partners to investigate the effect and mechanism of action of the Company's DARPin candidate T-cell engager (TCE) in AML, using in vitro and in vivo models, as well as patient samples.",
    'FW Link1':
      'Molecular Partners Announces Research Collaboration with University of Bern to Develop MP0533, a Multispecific DARPin for the Treatment of AML;;https://firstwordpharma.com/story/5454875/'
  },
  {
    'Date Announced': '3-Dec-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Biomm',
    'Country (Acquiring Company/Partner)': 'Brazil',
    'Region (Acquiring Company/Partner)': 'South America',
    'Target Company/Partner': 'Lupin',
    'Country (Target Company/Partner)': 'India',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Brazil',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Biosimilar',
    'Lead Asset(s)': 'Biosimilar pegfilgrastim',
    'Indication(s)': 'Neutropenia',
    'Deal Structure':
      'Lupin announced that they have entered into an exclusive distribution and marketing agreement with Biomm SA in Brazil. Under the terms of agreement, Biomm will distribute and market biosimilar Pegfilgrastim in Brazil.',
    'FW Link1':
      'Lupin and Biomm Enter into a Distribution and Marketing Agreement for Pegfilgrastim in Brazil;;https://firstwordpharma.com/story/5454971/'
  },
  {
    'Date Announced': '3-Dec-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Servier',
    'Country (Acquiring Company/Partner)': 'France',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Aqemia',
    'Country (Target Company/Partner)': 'France',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'AI and quantum physics-driven drug discovery, immuno-oncology',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Aqemia and Servier have entered into a collaboration agreement that will use Aqemia’s technology to accelerate the discovery of small molecule therapeutic drug candidates on an undisclosed target in immuno-oncology. The agreement includes upfront payments and payments for potential milestones from the early-stage discovery and the clinical trials in developing the drug candidates resulting from this collaboration.',
    'FW Link1':
      'Aqemia and Servier announce AI and quantum physics-driven drug discovery collaboration in immuno-oncology;;https://firstwordpharma.com/story/5455151/',
    'FW Link2':
      'Extension of collaboration announced January 9, 2023;;https://firstwordpharma.com/story/5694442/'
  },
  {
    'Date Announced': '7-Dec-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'SynDevRx',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Queensland University of Technology',
    'Country (Target Company/Partner)': 'Australia',
    'Region (Target Company/Partner)': 'Oceania',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'MetAP2 inhibition, metabo-oncology',
    'Lead Asset(s)': 'evexomostat',
    'Indication(s)': 'Prostate cancer',
    'Target(s)': 'MetAP2',
    'Deal Structure':
      'The collaboration with Professor Colleen Nelson, PhD, and her team will study the role of methionine aminopeptidase 2 (MetAP2) inhibition in tumor growth in castration resistant and other treatment resistant forms of prostate cancer.',
    'FW Link1':
      'SynDevRx Announces Research Collaboration with Queensland University of Technology (QUT) to Study the Effects of SDX-7320 In Advanced Prostate Cancer Models;;https://firstwordpharma.com/story/5457059/'
  },
  {
    'Date Announced': '7-Dec-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Persephone Biosciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'J&J',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Biomarkers, precision microbiome medicines',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Colorectal cancer',
    'Deal Structure':
      "Persephone Biosciences announced a collaboration with Janssen Research & Development, LLC, (Janssen) for the colorectal cancer (CRC) arm of Persephone's ARGONAUT study. Through the agreement announced, Persephone will work with Janssen on the CRC patient arm and additional healthy individuals with varying cancer risk enrolled in the study. Financial terms related to the agreement are not being disclosed. Persephone intends to sign additional agreements covering the study's other arms.",
    'FW Link1':
      'Persephone Biosciences Announces Collaboration with Janssen for Colorectal Arm of ARGONAUT Study;;https://firstwordpharma.com/story/5457443/'
  },
  {
    'Date Announced': '7-Dec-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'NUCLIBER',
    'Country (Acquiring Company/Partner)': 'Spain',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Telix',
    'Country (Target Company/Partner)': 'Australia',
    'Region (Target Company/Partner)': 'Oceania',
    'Geographic Remit of Deal': 'Spain',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Imaging',
    'Lead Asset(s)': 'Illuccix',
    'Indication(s)': 'Prostate cancer',
    'Deal Structure':
      'Under the terms of the agreement, and subject to closing requirements being satisfied, NUCLIBER will be the overall distributor and local representative for Illuccix in Spain, an EU5 country, from the national approval date. NUCLIBER has demonstrated experience in the supply of gallium generators across Spain and has been selected according to its ability to deliver a secure and continuous supply of 68Ga necessary for commercial launch in the country.',
    'FW Link1':
      'Telix and NUCLIBER Sign Distribution Agreement for Prostate Cancer Imaging in Spain;;https://firstwordpharma.com/story/5457781/'
  },
  {
    'Date Announced': '8-Dec-2021',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Avistone Pharmaceuticals',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Pearl Biotechnology',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'c-Met inhibitor, tyrosine kinase inhibitors, targeted therapies',
    'Indication(s)': 'Non-small-cell lung cancer, glioblastoma',
    'Target(s)': 'c-Met',
    'Deal Structure':
      'Avistone Pharmaceuticals, a clinical-stage biotechnology company focused on precision oncology therapeutics, announced it has received a strategic investment of more than $200 million led by Vivo Capital with participation from Bain Capital and Primavera Capital. The investment will support the combination of Avistone with Pearl Biotechnology Co. Ltd. to create a fully-integrated targeted oncology platform that will address unmet patient needs globally.',
    'FW Link1':
      'Avistone gets $200-million infusion as it looks to combine with Pearl Biotech;;https://firstwordpharma.com/story/5457973/'
  },
  {
    'Date Announced': '8-Dec-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'SOTIO',
    'Country (Acquiring Company/Partner)': 'Czech Republic',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Merck & Co.',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'IL-15 superagonist, anti-PD-1 therapy',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'SOT101, Keytruda',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'IL-15, PD-1',
    'Deal Structure':
      'Under the terms of the agreement, SOTIO will conduct a Phase 2 open-label, multicenter study of SOT101 in combination with KEYTRUDA to evaluate efficacy and safety in patients with selected advanced or refractory solid tumors. MSD will supply KEYTRUDA for the study.',
    'FW Link1':
      'SOTIO Announces Clinical Collaboration with MSD to Evaluate IL-15 Superagonist, SOT101, in Combination with KEYTRUDA (pembrolizumab) in Patients with Solid Tumors;;https://firstwordpharma.com/story/5458021/'
  },
  {
    'Date Announced': '8-Dec-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Orange Grove Bio',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'University of Colorado Anschutz Medical Campus',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Drug development',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'The newly established partnership will aim to foster the growth of the greater Colorado biotech landscape by increasing entrepreneurship, education, and scientific translation of promising technologies. Together, the parties will work closely to identify and support opportunities for company creation and drug development in the areas of oncology, as well as autoimmune and inflammatory diseases.',
    'FW Link1':
      'The University of Colorado Anschutz Medical Campus and Orange Grove Bio to Collaborate on Development and Commercialization of Novel Therapies;;https://firstwordpharma.com/story/5458209/'
  },
  {
    'Date Announced': '9-Dec-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Qiagen',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Denovo Biopharma',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'United States',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Companion diagnostic, PKC-beta inhibitor',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'DB102',
    'Indication(s)': 'Diffuse large B-cell lymphoma',
    'Target(s)': 'Denovo Genomic Marker 1, PKC-beta',
    'Deal Structure':
      'Under the agreement, QIAGEN will develop a diagnostic assay that can detect the Denovo Genomic Marker 1 (DGM1) in DLBCL patients, a biomarker discovered by Denovo that predicts the responsiveness to DB102. QIAGEN will develop a real-time qualitative PCR companion diagnostic for the QIAGEN Rotor-Gene Q MDx instrument and apply for premarket approval (PMA) with the US-based Food and Drug Administration (FDA). The goal is to get the PMA for the test contemporaneously with Denovo receiving new drug application (NDA) approval for its DB102. The drug and the DGM1 marker are currently in a phase III trial, called ENGINE, on newly diagnosed, high-risk DLBCL patients.',
    'FW Link1':
      'QIAGEN and Denovo Biopharma Partner to Develop Companion Diagnostic Test for the Treatment of Diffuse Large B-Cell Lymphoma (DLBCL);;https://firstwordpharma.com/story/5458949/'
  },
  {
    'Date Announced': '9-Dec-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Clover Biopharmaceuticals',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Ascentage Pharma',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'China, Australia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Trimeric fusion protein, IAP antagonist',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'SCB-313, APG-1387',
    'Indication(s)': 'Peritoneal carcinomatosis',
    'Deal Structure':
      'Clover and Ascentage Pharma will jointly conduct this open-label, multicenter, Phase 1b/2 study to evaluate the safety, tolerability, pharmacokinetics/pharmacodynamics (PK/PD), and efficacy of SCB-313 in combination with APG-1387 for the treatment of patients with primary or secondary peritoneal carcinomatosis from different primary tumor origins. The trial will be conducted at multiple sites in China and Australia.',
    'FW Link1':
      'Clover and Ascentage Pharma Announce Clinical Collaboration to Evaluate Recombinant Human TRAIL-Trimer Fusion Protein, SCB-313, in Combination with IAP Antagonist, APG-1387 for the Treatment of Peritoneal Carcinomatosis;;https://firstwordpharma.com/story/5458989/'
  },
  {
    'Date Announced': '9-Dec-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Smart Immune',
    'Country (Acquiring Company/Partner)': 'France',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Memorial Sloan Kettering Cancer Center',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'CAR-T cell therapies, T-cell progenitors',
    'Indication(s)': 'Liquid and solid cancers',
    'Deal Structure':
      'As part of this collaboration, MSK will contribute to the development of the CAR-ProTcell platform with a first proof-of-concept (POC) in a mouse-to-mouse model, and Smart Immune will lead the humanized model POC.',
    'FW Link1':
      'Smart Immune Announces Research Collaboration with Memorial Sloan Kettering Cancer Center (MSK) to Expedite Development of a Next Generation CAR-T Through its Long Lasting, Exhaustion-Free CAR ProTcell Platform;;https://firstwordpharma.com/story/5459027/'
  },
  {
    'Date Announced': '10-Dec-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'SpringWorks Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'AbbVie',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Gamma secretase inhibitor, CD3 bispecific antibody directed against BCMA',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'nirogacestat, ABBV-383',
    'Indication(s)': 'Relapsed or refractory multiple myeloma',
    'Target(s)': 'Gamma secretase, BCMA, CD3',
    'Deal Structure':
      'Under the terms of the agreement, AbbVie will sponsor and conduct the Phase 1b study to evaluate the safety, tolerability, and preliminary efficacy of the combination in patients with relapsed or refractory multiple myeloma, and will assume all costs associated with the study, other than expenses related to the manufacturing of nirogacestat and certain expenses related to intellectual property rights. AbbVie and SpringWorks will also form a joint steering committee to manage the clinical study, which is expected to commence in the first half of 2022.',
    'FW Link1':
      'SpringWorks Therapeutics Announces Clinical Collaboration with AbbVie to Evaluate Nirogacestat in Combination with ABBV-383 in Patients with Relapsed or Refractory Multiple Myeloma;;https://firstwordpharma.com/story/5459761/'
  },
  {
    'Date Announced': '10-Dec-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Candel Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Partnership for Accelerating Cancer Therapies',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Biomarker analysis, oncolytic viral immunotherapy',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'CAN-2409',
    'Indication(s)': 'Non-small-cell lung cancer',
    'Deal Structure':
      'Candel Therapeutics announced a collaboration with Partnership for Accelerating Cancer Therapies (PACT) and the Cancer Immune Monitoring and Analysis Centers - Cancer Immunologic Data Commons (CIMAC-CIDC) to profile the biomarker response to a combination of CAN-2409 + valacyclovir in combination with anti-PD-1 and PD-L1 immune checkpoint inhibitors in patients with non-small cell lung cancer (NSCLC).',
    'FW Link1':
      'Candel Therapeutics and Partnership for Accelerating Cancer Therapies (PACT) to Collaborate on Lung Cancer Trial for CAN-2409;;https://firstwordpharma.com/story/5459777/'
  },
  {
    'Date Announced': '12-Dec-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Antengene',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'BMS',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'ERK1/2 inhibitor, PD-1 checkpoint inhibitor',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'ATG-017, Opdivo',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'ERK1/2, PD-1',
    'Deal Structure':
      'Under the terms of the Agreement, Antengene will sponsor and fund the study and Bristol-Myers Squibb will provide Opdivo for the combination dose escalation and combination dose expansion portions of the trial. Antengene has global commercial and development rights to ATG-017.',
    'FW Link1':
      'Antengene Announces Clinical Collaboration with Bristol Myers Squibb to Evaluate ATG-017 in Combination with Opdivo (nivolumab) in Advanced Solid Tumors;;https://firstwordpharma.com/story/5460517/'
  },
  {
    'Date Announced': '13-Dec-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Tonix Pharmaceuticals',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Columbia University',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'rTFF2-based therapy',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'TNX-1700',
    'Indication(s)': 'Gastric and colorectal cancers',
    'Deal Structure':
      'Tonix Pharmaceuticals has entered into a research collaboration with Columbia University focused on advancing recombinant trefoil factor family 2 (rTFF2)-based therapeutic candidates (TNX-1700) in the treatment of gastric and colorectal cancers.',
    'FW Link1':
      'Tonix Pharmaceuticals Announces Research Collaboration with Columbia University to Study Recombinant Trefoil Factor 2 (rTFF2)-Based Therapy (TNX-1700) for Gastric and Colorectal Cancers;;https://firstwordpharma.com/story/5461087/'
  },
  {
    'Date Announced': '13-Dec-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Crescendo Biologics',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Institute of Cancer Research',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immunotherapy, Humabody',
    'Lead Asset(s)': 'CB307',
    'Indication(s)': 'Cancer',
    'Target(s)': 'CD137, PSMA',
    'Deal Structure':
      'This new research collaboration will characterise the non-clinical pharmacology of Crescendo’s drug candidate, called CB307 – exploring its effects on cancer cells in a variety of experimental models to complement its ongoing clinical development.',
    'FW Link1':
      'ICR in new collaboration to develop ‘next generation’ immunotherapy candidate - The Institute of Cancer Research, London;;https://firstwordpharma.com/story/5461141/'
  },
  {
    'Date Announced': '13-Dec-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'AstraZeneca',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Samsung Group',
    'Country (Target Company/Partner)': 'Korea, South',
    'Region (Target Company/Partner)': 'Asia',
    'Total Deal Value ($m)': '380',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cancer immunotherapy',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "The partnership is based on an expanded deal this year in May 2021 which followed the signing of a long-term strategic agreement made in September 2020. Under the agreement, valued at approximately $380 million up from the initial $331 million for the production of drug substance and drug product, Samsung Biologics manufactures AstraZeneca's COVID-19 long-acting antibody (LAAB) combination, AZD7442, and will start to manufacture a cancer immunotherapy product from next year.",
    'FW Link1':
      'Samsung Biologics and AstraZeneca expand strategic manufacturing partnership to include COVID-19 and cancer therapy;;https://firstwordpharma.com/story/5461569/'
  },
  {
    'Date Announced': '14-Dec-2021',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'GANZIMMUN Diagnostics',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Mainz Biomed',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Germany',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostic test',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'ColoAlert',
    'Indication(s)': 'Colorectal cancer',
    'Deal Structure':
      "Under the terms of the arrangement, Mainz will co-brand ColoAlert with GD, one of the largest stool analysis labs in Germany. GD will purchase Mainz's customized polymerase chain reaction (PCR) assay kits to deliver its large network of physicians and their patients a comprehensive solution for advanced colorectal cancer prevention. These kits will be provided to GD on an on-demand basis and further expand coverage especially in Mainz, Wiesbaden and Frankfurt.",
    'FW Link1':
      'Mainz Biomed Expands ColoAlert Commercialization with GANZIMMUN Diagnostics in Europe;;https://www.firstwordhealthtech.com/story/5461859/'
  },
  {
    'Date Announced': '14-Dec-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'PharmaEssentia',
    'Country (Acquiring Company/Partner)': 'Taiwan',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Veeva Systems',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Patient data, interferon therapy',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'BESREMi',
    'Indication(s)': 'Polycythaemia vera',
    'Deal Structure':
      'Veeva Systems announced that PharmaEssentia has adopted Veeva Data Cloud to support the launch of BESREMi, a new therapy for polycythemia vera (PV), a rare blood cancer. Veeva Longitudinal Patient Data will provide essential insights to help the company reach hard-to-find patient populations through healthcare provider (HCP) targeting and patient journey analytics.',
    'FW Link1':
      'PharmaEssentia Selects Veeva Data Cloud to Support Launch of Novel Therapy for Rare, Chronic Blood Cancers;;https://firstwordpharma.com/story/5461863/'
  },
  {
    'Date Announced': '14-Dec-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Inceptua',
    'Country (Acquiring Company/Partner)': 'Luxembourg',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Elevar Therapeutics, Oasmia',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Chemotherapy agent',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Apealea',
    'Indication(s)': 'Ovarian cancer',
    'Deal Structure':
      'The European Commission (EC) and the UK Medicines and Healthcare products Regulatory Agency (MHRA) has approved the transfer of the marketing authorization for Apealea (paclitaxel micellar) from Oasmia Pharmaceutical AB to Inceptua AB. On 25 March 2020, Oasmia Pharmaceutical AB and US based Elevar Therapeutics Inc. signed a global strategic partnership deal to commercialise Apealea. On 28 December 2020, Inceptua signed a licence agreement with Elevar Therapeutics Inc. to commercialise Apealea in Europe.',
    'FW Link1':
      'Inceptua receives approval of marketing authorization transfer of Apealea (paclitaxel micellar) for the treatment of ovarian cancer;;https://firstwordpharma.com/story/5461869/'
  },
  {
    'Date Announced': '14-Dec-2021',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Synthetic Biologics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'VCN Biosciences',
    'Country (Target Company/Partner)': 'Spain',
    'Region (Target Company/Partner)': 'Europe',
    'Upfront Value ($m)': '5',
    'Total Deal Value ($m)': '75',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Oncolytic adenoviruses',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'VCN-01',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      "Total upfront consideration for the acquisition is $4.7 million in cash plus the assumption of $2.4 million of VCN liabilities. Additionally, VCN will receive shares of Synthetic Biologics' common stock representing 19.99% of the total shares outstanding of the Company's common stock. The Company has also agreed to an additional $70.3 million of payments contingent upon the achievement of future milestones, a majority of which are tied to late-stage clinical development and regulatory achievements. The transaction is expected to close during the first quarter of 2022, and is subject to, among other things, the approval by the Spanish government of the Company's acquisition of VCN under Spain's Foreign Investment Act and other customary closing conditions.",
    'FW Link1':
      'Synthetic Biologics Announces Planned Transformative Acquisition of VCN Biosciences; Developer of a Novel Oncolytic Virus Platform Targeting Pancreatic and other Solid Tumors;;https://firstwordpharma.com/story/5461901/'
  },
  {
    'Date Announced': '14-Dec-2021',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'NEC',
    'Country (Acquiring Company/Partner)': 'Japan',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'BostonGene',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'AI-based molecular and immune profiling, precision medicine',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'BostonGene Tumor Portrait Tests',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Going forward, the two companies will jointly develop the market for BostonGene Tumor Portrait Tests globally. In Japan, the companies aim to utilize NEC's healthcare and life science network in order to provide Tumor Portrait Tests to hospitals throughout the country.",
    'FW Link1':
      'BostonGene and NEC Announce Global Partnership;;https://www.firstwordhealthtech.com/story/5462401/'
  },
  {
    'Date Announced': '15-Dec-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Candel Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Bionaut Labs',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Remote-controlled microscale robots, precision delivery, oncolytic viral immunotherapies',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Brain tumours',
    'Deal Structure':
      "Bionaut Labs will apply its micro-robotic technology under the guidance of an integrated electro-magnetic control system to deliver Candel's oncolytic viruses directly to brain tumors in a minimally invasive manner. The parties will jointly research the use of the Bionaut platform in the preclinical setting with an aim of advancing to clinical trials. The agreement enables both parties to retain unencumbered rights to their respective platforms, as well as rights for future clinical development together. Terms of the agreement were not disclosed.",
    'FW Link1':
      'Bionaut Labs and Candel Therapeutics Announce Strategic Collaboration in Precision-Targeted Delivery of Oncolytic Viral Immunotherapies;;https://firstwordpharma.com/story/5462777/'
  },
  {
    'Date Announced': '16-Dec-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'J&J',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Novadiscovery',
    'Country (Target Company/Partner)': 'France',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Clinical trial simulation platform, disease models',
    'Indication(s)': 'Lung cancer',
    'Deal Structure':
      'Novadiscovery (NOVA), a leading health tech company using in silico clinical trials to predict drug efficacy and optimize clinical trial development, has expanded its collaboration with Janssen, initially entered in 2020. NOVA is now applying its collaborative clinical trial simulation platform, JINKO, to expand the existing lung cancer model to support Janssen market access and global medical affairs strategies. The companies will now work together to use the JINKO platform to add layers of biological complexity to the initial disease model and then run in silico trial simulations.',
    'FW Link1':
      'Novadiscovery expands Janssen collaboration by implementing its JINKO® clinical trial simulation platform for the development of lung cancer models;;https://firstwordpharma.com/story/5463779/'
  },
  {
    'Date Announced': '16-Dec-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Inceptor Bio',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'University of California, Santa Barbara',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'CAR-M therapy',
    'Indication(s)': 'Difficult-to-treat tumours',
    'Deal Structure':
      'Inceptor Bio, a Research Triangle Park-based next-generation cell and gene therapy biotechnology company, has executed an in-licensing agreement with the University of California, Santa Barbara (UCSB) for an investigational Chimeric Antigen Receptor Macrophage (CAR-M) therapy targeting difficult-to-treat tumors.',
    'FW Link1':
      'Inceptor Bio Enters into In-licensing Agreement with University of California, Santa Barbara For CAR-M Platform;;https://firstwordpharma.com/story/5463849/'
  },
  {
    'Date Announced': '17-Dec-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Genfit',
    'Country (Acquiring Company/Partner)': 'France',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Genoscience',
    'Country (Target Company/Partner)': 'France',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'United States, Canada, Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Autophagy/PPT1 inhibitor',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'GNS561',
    'Indication(s)': 'Cholangiocarcinoma',
    'Target(s)': 'PPT-1',
    'Deal Structure':
      'Under the agreement, Genoscience Pharma is eligible for clinical and regulatory milestone payments and tiered royalties. The first payable milestone is contingent on positive Phase 2 clinical trial results. GENFIT is also committed to take a €3 million equity stake in Genoscience Pharma through the subscription of new ordinary shares.',
    'FW Link1':
      'On heels of global strategic partnership announced today, GENFIT acquires rights to novel asset;;https://firstwordpharma.com/story/5464611/'
  },
  {
    'Date Announced': '17-Dec-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner':
      'Ventura County Hematology Oncology Specialists',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Deep Lens',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'United States',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'AI based clinical trial matching solution',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "As part of this collaboration, Ventura County Hematology Oncology Specialists will integrate Deep Lens' artificial intelligence (AI) based clinical trial matching solution and other services to identify patients for trials faster and more effectively. Deep Lens' software will pre-screen patients from Ventura County Hematology Oncology Specialists' EMR (OncoEMR) and integrate molecular data feeds from Guardant Health and Foundation Medicine, as well as all pathology feeds to automatically identify qualified patients for clinical trials. Deep Lens' pre-screening and clinical trial matching solution is provided at no cost to oncology practices.",
    'FW Link1':
      'Deep Lens and Ventura County Hematology Oncology Specialists Partner to Expand Clinical Trial Offering for Cancer Patients;;https://www.firstwordhealthtech.com/story/5464829/'
  },
  {
    'Date Announced': '20-Dec-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Xediton Pharmaceuticals',
    'Country (Acquiring Company/Partner)': 'Canada',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Helsinn',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Canada',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Truseltiq',
    'Indication(s)': 'Cholangiocarcinoma',
    'Target(s)': 'FGFR',
    'Deal Structure':
      'Under the terms of the license and distribution agreement, Helsinn Healthcare SA grants Xediton an exclusive license to distribute, promote, market, and sell TRUSELTIQ in Canada and Helsinn will supply TRUSELTIQ for commercialization in Canada.',
    'FW Link1':
      'Helsinn and Xediton sign exclusive license and distribution agreement for TRUSELTIQ (infigratinib) in Canada;;https://firstwordpharma.com/story/5465671/'
  },
  {
    'Date Announced': '21-Dec-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Menarini',
    'Country (Acquiring Company/Partner)': 'Italy',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Karyopharm',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '75',
    'Total Deal Value ($m)': '278',
    'Geographic Remit of Deal': 'Europe, Latin America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'SINE compound',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Nexpovio',
    'Indication(s)': 'Haematologic and solid tumour oncology indications',
    'Target(s)': 'XPO1, CRM1',
    'Deal Structure':
      'Under the terms of the agreement, Menarini received exclusive rights to commercialize NEXPOVIO for the treatment of oncology indications in the European Union and other European countries (including the United Kingdom), Latin America and other key countries. In exchange, Karyopharm will receive an upfront payment of $75 million (USD) in 2021 and is eligible to receive up to an additional $202.5 million in future milestones, plus tiered double-digit royalties on net sales of NEXPOVIO in the licensed territories. The drug is marketed as XPOVIO in the US.',
    'FW Link1':
      'Karyopharm and Menarini Group Enter into Exclusive License Agreement to Commercialize NEXPOVIO (selinexor) in Europe and Other Key Global Territories;;https://firstwordpharma.com/story/5466477/'
  },
  {
    'Date Announced': '21-Dec-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Precision Molecular',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Johns Hopkins University',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Astatine-211-labelled radiopharmaceutical inhibitor of PSMA',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'PMI21',
    'Indication(s)': 'Prostate cancer',
    'Target(s)': 'PSMA',
    'Deal Structure':
      'Precision Molecular, Inc. (PMI), a clinical-stage company developing targeted radiopharmaceuticals and theranostics for patients with cancer, has executed a licensing agreement with Johns Hopkins University for exclusive rights to an astatine-211-labeled inhibitor of prostate-specific membrane antigen (PSMA) which is typically elevated in prostate cancer cells.',
    'FW Link1':
      'Precision Molecular Executes License for Exclusive Rights to an Astatine-211-Labeled Radiopharmaceutical Inhibitor of Prostate-Specific Membrane Antigen for Prostate Cancer Therapeutics;;https://firstwordpharma.com/story/5466535/'
  },
  {
    'Date Announced': '22-Dec-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Telix',
    'Country (Acquiring Company/Partner)': 'Australia',
    'Region (Acquiring Company/Partner)': 'Oceania',
    'Target Company/Partner': 'Olivia Newton-John Cancer Research Institute',
    'Country (Target Company/Partner)': 'Australia',
    'Region (Target Company/Partner)': 'Oceania',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'FET-PET imaging, diagnostic agent',
    'Lead Asset(s)': '18F-FET, TLX101-CDx',
    'Indication(s)': 'Glioblastoma',
    'Deal Structure':
      'Telix is participating in the FIG Study as both a commercial collaborator and part funder, in return for access to final study data to support the further development and registration of TLX101-CDx. Telix funding will specifically support an observational study (registry) which will collect data demonstrating the value of FET-PET imaging in brain cancer patients.',
    'FW Link1':
      'Telix Collaborates with Olivia Newton-John Cancer Research Institute on Brain Cancer Imaging Study;;https://firstwordpharma.com/story/5467207/'
  },
  {
    'Date Announced': '23-Dec-2021',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'ArriVent Biopharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Aarvik Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Total Deal Value ($m)': '100',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'As part of the collaboration, Aarvik is responsible for the discovery and preclinical development of the novel molecule that is based on its unique modular platform that combines multiple target mechanisms. ArriVent will be responsible for clinical development and commercialization of the collaboration drug candidate that successfully achieves target criteria. For this program, Aarvik will receive an upfront payment and research funding, along with the opportunity for option fees, as well as development and commercial milestones for a potential total value of approximately $100 million, plus royalties.',
    'FW Link1':
      'Aarvik Therapeutics and ArriVent Biopharma Announce Research Collaboration to Advance Novel Oncology Drug Candidate Using Aarvik’s Proprietary Modular Antibody Platform;;https://firstwordpharma.com/story/5468015/'
  },
  {
    'Date Announced': '27-Dec-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Chugai',
    'Country (Acquiring Company/Partner)': 'Japan',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'National Cancer Center Japan',
    'Country (Target Company/Partner)': 'Japan',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Liquid biopsy, genomic analysis',
    'Lead Asset(s)': 'FoundationOne Liquid CDx Cancer Genomic Profile',
    'Indication(s)': 'Ovarian cancer',
    'Deal Structure':
      'Chugai Pharmaceutical Co., Ltd. announced that it signed a joint research agreement with the National Cancer Center Japan (Tokyo, President: Hitoshi Nakagama, hereafter NCC) and participated in an Asian multi-center prospective study A-TRAIN, which is led by NCC Hospital. Chugai, in cooperation with Roche, provides a blood-based liquid biopsy test FoundationOne Liquid CDx Cancer Genomic Profile for genomic analysis in the ovarian cancer cohorts of this collaborative study.',
    'FW Link1':
      'Chugai Signs Joint Research Agreement with NCC for Asian International Collaborative Prospective Study A-TRAIN;;https://firstwordpharma.com/story/5469105/'
  },
  {
    'Date Announced': '22-Apr-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Vyant Bio',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Ordaōs Bio, Cellaria',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Artificial intelligence, in vitro avatar clinical trials, drug discovery, biomarker-specific small protein therapeutics',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Vyant Bio, Inc. ("Vyant" or the "Company"), an emerging leader in novel drug discovery techniques, announces a collaboration that will seek to de-risk the development of small protein therapeutics. In partnership with Ordaōs Bio ("Ordaōs") and Cellaria, Inc. ("Cellaria"), the Company is unveiling a model for rapid iteration of therapeutic design using artificial intelligence and in vitro \'avatar clinical trials\' that will enable the design, development, and testing of potential therapeutics on specific patient populations, during preclinical development. The goal is to provide an early detection system of a drug\'s efficacy in different patient cohorts, prior to clinical trials.',
    'FW Link1':
      'Vyant Bio, Inc., Ordaōs Bio, and Cellaria, Inc. Announce Collaboration to Design and Qualify Biomarker-Specific Small Protein Therapeutics;;https://firstwordpharma.com/story/5281157/'
  },
  {
    'Date Announced': '16-Mar-2021',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Celcuity',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'University of Texas MD Anderson Cancer Center',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Diagnostic platform, patient testing/selection',
    'Lead Asset(s)': 'CELsignia Multi-Pathway Activity Test',
    'Indication(s)': 'Metastatic HER2-negative breast cancer',
    'Deal Structure':
      'Under the agreement, MD Anderson will serve as the sponsor and Bora Lim MD, a medical oncologist at Baylor College of Medicine, and Rachel Layman MD, a medical oncologist at MD Anderson, will serve as the co-principal investigators of this study. Novartis will supply TABRECTA and Puma will supply NERLYNX, targeted therapies currently approved by the U.S. Food and Drug Administration to treat non-small cell lung cancer and HER2-positive breast cancer, respectively. Celcuity will provide its CELsignia Multi-Pathway Activity Test to select patients with HER2-negative metastatic breast cancer who have hyperactive HER2 and c-Met signaling pathways for the trial and will fund the patient-related trial costs.',
    'FW Link1':
      'Celcuity Announces Breast Cancer Clinical Trial Collaboration with MD Anderson, Novartis, and Puma Biotechnology to Study New Drug Regimen;;https://firstwordpharma.com/story/5250605/'
  },
  {
    'Date Announced': '1-Mar-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Medexus',
    'Country (Acquiring Company/Partner)': 'Canada',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'NX Development Corp',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'United States',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Optical imaging agent',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Gleolan',
    'Indication(s)': 'Glioma, meningioma',
    'Deal Structure':
      "Under the terms of Medexus's new license, supply, and distribution agreement, Medexus will commercialize Gleolan in the United States and will pay NXDC annual royalty payments (tiered based on net sales relative to an annual minimum baseline and net of supply price paid) and periodic low- to mid-single-digit-million dollar milestone payments (including an upfront payment, two payments triggered by passage of time, and three payments triggered by achievement of net sales thresholds). NXDC will supply Gleolan to Medexus and will remain the sponsor of the new drug application for Gleolan on file with the U.S. Food and Drug Administration (FDA). This transaction will allow NXDC, as sponsor, to continue the company's important research and development activities, including pursuit of additional indications for Gleolan. Medexus US's exclusive commercialization rights extend to one additional indication, meningioma, with the opportunity to negotiate commercialization rights to future indications. The initial term of the parties' arrangement will extend through and including March 31, 2028 with successive two-year extension terms thereafter.",
    'FW Link1':
      'Medexus Pharmaceuticals Announces Deal for Gleolan in the United States;;https://firstwordpharma.com/story/5515701/'
  },
  {
    'Date Announced': '1-Mar-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Thermo Fisher',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Symphogen',
    'Country (Target Company/Partner)': 'Denmark',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Chromatography and mass spectrometry workflows, biopharmaceutical characterisation',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Thermo Fisher Scientific and Symphogen, an affiliate of and the antibody center of excellence within the international pharmaceutical company Servier, announce the continuation of their collaboration to provide biopharmaceutical discovery and development laboratories with innovative tools and streamlined workflows for efficient characterization of complex therapeutic proteins.',
    'FW Link1':
      'Thermo Fisher Scientific continues collaboration to support biopharmaceutical discovery and development;;https://firstwordpharma.com/story/5515689/'
  },
  {
    'Date Announced': '1-Mar-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Versameb',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Touchlight',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Doggybone DNA (dbDNA) vector technology, mRNA-based therapeutics',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'VMB-100',
    'Indication(s)': 'Oncology',
    'Deal Structure':
      'The agreement enables Versameb to use Touchlight’s proprietary doggybone DNA (dbDNA) vector technology to support the development of novel mRNA-based therapeutics addressing unmet needs in urology and oncology. This includes Versameb’s lead candidate VMB-100, a potentially first-in-class regenerative treatment for Stress Urinary Incontinence (SUI), which is expected to enter clinical development in the second half of 2022. Versameb will use Touchlight’s dbDNA technology for manufacturing and clinical supply of VMB-100 and other future pipeline products.',
    'FW Link1':
      'Touchlight and Versameb announce GMP manufacturing supply agreement;;https://firstwordpharma.com/story/5516019/'
  },
  {
    'Date Announced': '2-Mar-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Prokarium',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Lausanne University Hospital',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Microbial immunotherapy',
    'Lead Asset(s)': 'Salmonella immunotherapy',
    'Indication(s)': 'Bladder cancer',
    'Deal Structure':
      'Prokarium announced it exercised its option with Lausanne University Hospital (Centre Hospitalier Universitaire Vaudois — CHUV) for an exclusive, worldwide license for the use of Salmonella immunotherapy in the treatment of bladder cancer. In exchange for the exclusive license, Prokarium will make an upfront payment, as well as additional payments upon achievement of development and commercialization milestones, and, if any product is approved, will pay royalties on net sales.',
    'FW Link1':
      'Prokarium Exercises Option for an Exclusive Worldwide License for the Use of Salmonella Immunotherapy in the Treatment of Bladder Cancer;;https://firstwordpharma.com/story/5516291/'
  },
  {
    'Date Announced': '2-Mar-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Akeso',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Chipscreen Biosciences',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'PD-1/CTLA-4 bispecific antibody, dual immuno-checkpoint inhibitor, Aurora B/VEGFR/PDGFR /c-Kit/CSF1R inhibitor',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'Cadonilimab, Chiauranib',
    'Indication(s)': 'Extensive-stage small-cell lung cancer',
    'Target(s)': 'PD-1/CTLA-4, Aurora B/VEGFR/PDGFR /c-Kit/CSF1R',
    'Deal Structure':
      'Akeso, Inc. has entered into a collaboration agreement with Shenzhen Chipscreen Biosciences, Ltd. to conduct a Phase Ib/II clinical study of combination therapy of Cadonilimab (PD-1/CTLA-4 bispecific antibody, AK104) and Chiauranib (a highly selective Aurora B/VEGFR/PDGFR /c-Kit/CSF1R inhibitor) in patients with ES-SCLC which progressed on combination therapy of platinum-based chemotherapy and PD-(L)1 inhibitor as first-line treatment.',
    'FW Link1':
      'Akeso Announces Clinical Trial Collaboration with Chipscreen Biosciences to Evaluate Cadonilimab in Combination with Chiauranib for Extensive-Stage Small-Cell Lung Cancer;;https://firstwordpharma.com/story/5516631/'
  },
  {
    'Date Announced': '2-Mar-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Sanofi',
    'Country (Acquiring Company/Partner)': 'France',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Adagene',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '18',
    'Total Deal Value ($m)': '2,500',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Masked immuno-oncology monoclonal and bispecific antibodies',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Under the agreed terms, Adagene will be responsible for early-stage research activities, using its SAFEbody technology to develop masked versions of Sanofi's candidate antibodies. Meanwhile, Sanofi will be solely responsible for later-stage research and all clinical, product development and commercialisation activities. As part of the deal, Sanofi will make an upfront payment of $17.5 million to Adagene to advance two initial antibody candidates, followed by an option for two more candidates. In addition, Adagene is eligible to receive total milestone payments of up to $2.5 billion for advancement of the candidates, as well as tiered sales royalties.",
    'FW Link1':
      'Sanofi inks deal with Adagene to develop masked immuno-oncology antibodies;;https://firstwordpharma.com/story/5516297/'
  },
  {
    'Date Announced': '3-Mar-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Pepper Bio',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Stanford University',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Phosphoproteomics, target identification/validation',
    'Indication(s)': 'Hepatocellular carcinoma, lymphoma',
    'Deal Structure':
      "Pepper Bio announced a collaboration with the Dean Felsher Laboratory at Stanford University. Leveraging Pepper Bio's unique platform on phosphoproteomics, the two teams aim to identify and validate novel targets for hepatocellular carcinoma and lymphoma within the year.",
    'FW Link1':
      'Pepper Bio Partners with Stanford’s Felsher Lab to Identify Therapy Targets for Untreatable, MYC Addicted Lymphatic and Liver Cancers;;https://firstwordpharma.com/story/5517891/'
  },
  {
    'Date Announced': '3-Mar-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Known Medicine',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Duke University',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Oncology diagnostics inference network platform',
    'Indication(s)': 'Lung cancer',
    'Deal Structure':
      "Known Medicine announced a partnership with Duke University to predict drug efficacy for lung cancer patients. This collaboration will enable Known Medicine to optimize and validate their novel ODIN platform. Duke University collaborator, Kamran Mahmood, MD, MPH, is the lead collaborator on this initiative. Together, Known Medicine and Duke University aim to determine if Known Medicine's novel platform can be used to predict actual clinical outcomes in 75 patients.",
    'FW Link1':
      'Known Medicine Partners with Duke University to Predict Lung Cancer Response to Drug Treatments;;https://firstwordpharma.com/story/5517865/'
  },
  {
    'Date Announced': '3-Mar-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Genome & Company',
    'Country (Acquiring Company/Partner)': 'Korea, South',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Merck & Co.',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Immuno-oncology microbiome therapeutic, anti-PD-1 therapy',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'GEN-001, Keytruda',
    'Indication(s)': 'Biliary tract cancer',
    'Target(s)': 'PD-1',
    'Deal Structure':
      "Upon the execution of the agreement, Genome and Company will conduct a phase 2 clinical trial to evaluate the safety and efficacy of its immuno-oncology microbiome therapeutic, 'GEN-001', in combination with MSD's anti-PD-1 therapy, KEYTRUDA (pembrolizumab), in patients with biliary tract cancer. Genome and Company will be the sponsor of the clinical trial and MSD will supply KEYTRUDA.",
    'FW Link1':
      "Genome and Company announces Clinical Trial Collaboration with MSD to evaluate 'GEN-001' in combination of KEYTRUDA (pembrolizumab) in phase 2 clinical trial in biliary tract cancer patients;;https://firstwordpharma.com/story/5518129/"
  },
  {
    'Date Announced': '7-Mar-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Arctoris',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Evariste Technologies',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'AI-guided and robotics-powered drug discovery, small molecule kinase inhibitors',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Non-small-cell lung cancer',
    'Target(s)': 'cMET',
    'Deal Structure':
      'Arctoris, a tech-enabled biopharma company, and Evariste Technologies, an AI-drug discovery company, announced they have formed a joint venture to identify novel small molecule kinase inhibitors for treatment of patients with Non-Small Cell Lung Cancer (NSCLC). The two companies are combining their platforms for AI-guided and robotics-powered drug discovery to develop a set of novel kinase inhibitors against cMET.',
    'FW Link1':
      'Arctoris and Evariste Technologies form a joint venture to identify novel small molecule cMET inhibitors for non-small cell lung cancer;;https://firstwordpharma.com/story/5519329/'
  },
  {
    'Date Announced': '7-Mar-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Antisoma Therapeutics',
    'Country (Acquiring Company/Partner)': 'Australia',
    'Region (Acquiring Company/Partner)': 'Oceania',
    'Target Company/Partner': 'Harvard Wyss Institute',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'eRapid sensor technology, point-of-care diagnostics',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "The licensing agreement was coordinated by Harvard University's Office of Technology Development and grants the company exclusive, worldwide access to the Wyss Institute's sensor technology in the fields of respiratory viral disease, including COVID-19 and the flu, allergic responses and anaphylaxis, and cancer. The agreement enables the company to concertedly develop much-needed point-of-care diagnostics that could put results in the hands of patients and primary health practitioners in real time.",
    'FW Link1':
      'Harvard Wyss Institute’s eRapid sensor technology licensed to Antisoma Therapeutics to facilitate infectious and immune disease and cancer diagnostics;;https://www.firstwordhealthtech.com/story/5519905/'
  },
  {
    'Date Announced': '7-Mar-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Targovax',
    'Country (Acquiring Company/Partner)': 'Norway',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Agenus',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'QS-21 STIMULON adjuvant, TG mutant KRAS cancer vaccines',
    'Lead Asset(s)': 'TG01',
    'Indication(s)': 'KRAS-mutated cancers',
    'Deal Structure':
      'Under the collaboration with Targovax, Agenus will supply QS-21 STIMULON and scientific support for up to three initial TG01 clinical trials in different cancer indications. If the clinical results meet certain pre-specified performance targets, the collaboration will be extended into an exclusive pivotal development and commercialization agreement where Agenus will be entitled to tiered double-digit royalties on future TG vaccine sales.',
    'FW Link1':
      'Targovax ASA and Agenus announce collaboration on mutant KRAS cancer vaccine adjuvanted with QS-21 STIMULON;;https://firstwordpharma.com/story/5519485/'
  },
  {
    'Date Announced': '7-Mar-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'GlycoMantra',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'University of Maryland, Baltimore',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Combination therapy, using two natural bioingredients',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Drug-resistant metastatic colorectal cancer',
    'Deal Structure':
      "GlycoMantra, a University of Maryland, Baltimore (UMB) startup company developing therapeutics for unmet medical needs in prostate cancer, NASH liver fibrosis, and type 2 diabetes, has been granted worldwide, exclusive rights to a UMB technology to advance the company's pipeline of therapeutics for treating drug-resistant metastatic colorectal cancer (mCRC).",
    'FW Link1':
      "University of Maryland, Baltimore (UMB) Grants GlycoMantra Exclusive License to Advance the Company's Therapeutic Pipeline;;https://firstwordpharma.com/story/5519641/"
  },
  {
    'Date Announced': '8-Mar-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'BioNTech',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Regeneron',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'mRNA-based cancer vaccine, PD-1 inhibitor, immunotherapies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'BNT116, Libtayo',
    'Indication(s)': 'Advanced non-small-cell lung cancer',
    'Target(s)': 'PD-1',
    'Deal Structure':
      'Under the latest expanded collaboration, Regeneron and BioNTech plan to jointly test Libtayo plus BNT116 in different patient populations with advanced NSCLC, starting with Phase I/II trials in the first-line setting. The partners will split development costs for the trials equally. Meanwhile, under a separate agreement, BioNTech plans to conduct a Phase I study, dubbed LuCa-MERIT-1, evaluating the combination in further NSCLC subpopulations.',
    'FW Link1':
      'Regeneron, BioNTech to test Libtayo combo for lung cancer in expanded team up;;https://firstwordpharma.com/story/5520631/'
  },
  {
    'Date Announced': '8-Mar-2022',
    'Deal type': 'Product Acquisition',
    'Acquiring Company/Partner': 'NEC',
    'Country (Acquiring Company/Partner)': 'Norway',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'VAXIMM',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Neoantigen vaccines',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Under the agreement, NOI will acquire VAXIMM's neoantigen vaccine-related patents, license the requisite manufacturing patents, and will take over several existing contracts with key collaborators and partners. Financial terms of the agreement have not been disclosed. In 2019, the companies entered into a strategic clinical trial collaboration agreement and an equity investment agreement to develop novel personalized neoantigen cancer vaccines. VAXIMM retains rights to its first-in-class oral T-cell activation platform technology and all other product candidates, including VXM01, which is being developed for the treatment of glioblastoma.",
    'FW Link1':
      'NEC OncoImmunity Acquires VAXIMM’s Neoantigen Vaccine Development Assets;;https://firstwordpharma.com/story/5520519/'
  },
  {
    'Date Announced': '9-Mar-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Convergent Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'NorthStar Medical Radioisotopes',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Therapeutic medical radioisotope, radiopharmaceutical therapy, PSMA-targeted monoclonal antibody',
    'Lead Asset(s)': 'CONV01-α',
    'Indication(s)': 'Prostate cancer',
    'Target(s)': 'PSMA',
    'Deal Structure':
      "Under terms of the agreement, NorthStar will provide Convergent with its environmentally preferred, high purity non-carrier-added (n.c.a.) Ac-225. Convergent will use NorthStar's Ac-225 to radiolabel its lead asset, CONV01-α, a prostate-specific membrane antigen (PSMA)-targeted monoclonal antibody, currently being investigated as a potential ground-breaking treatment for prostate cancer.",
    'FW Link1':
      'NorthStar Medical Radioisotopes and Convergent Therapeutics Announce Supply Agreement for Therapeutic Radioisotope Actinium-225 (Ac-225);;https://firstwordpharma.com/story/5522061/'
  },
  {
    'Date Announced': '9-Mar-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Orange Grove Bio',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': "Cincinnati Children's Hospital Medical Center",
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Drug development, cell and gene therapies',
    'Indication(s)': 'Oncology',
    'Deal Structure':
      "Under terms of the collaboration, Orange Grove Bio will provide commercialization guidance and support to Cincinnati Children's Innovation Ventures. Orange Grove Bio's chief business operations officer, Ryan Fox, will advise on the potential of emerging technologies from the Cincinnati Children's Innovation Ventures pipeline. In addition, Orange Grove Bio leaders will be available to speak with interested post-doctoral students about internship opportunities and offer seminars for investigators and researchers.",
    'FW Link1':
      'Cincinnati Children’s Hospital Medical Center and Orange Grove Bio Establish Collaboration to Develop and Commercialize Novel Therapeutics;;https://firstwordpharma.com/story/5521829/'
  },
  {
    'Date Announced': '4-Mar-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'AstraZeneca',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Qure.ai, PATH',
    'Country (Target Company/Partner)': 'India',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnosis, screening, artificial intelligence',
    'Indication(s)': 'Lung cancer',
    'Deal Structure':
      'AstraZeneca, Qure.ai, and PATH announced a partnership to improve lung health in low- to middle-income countries. The three organizations will collaborate to establish a framework for early diagnosis of lung cancer in individuals undergoing chest screening for the detection of tuberculosis (TB), using an innovative artificial intelligence (AI) technology. This new three‑way collaboration builds on AstraZeneca and Qure.ai’s existing partnership.',
    'FW Link1':
      'AstraZeneca, Qure.ai and PATH unite to improve early detection of lung cancer in low- to middle-income countries through cutting-edge technology;;https://www.firstwordhealthtech.com/story/5521837/'
  },
  {
    'Date Announced': '9-Mar-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Cellevolve',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': "Seattle Children's Therapeutics",
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Multiplex CARs, lentiviral vector manufacturing, cell therapy',
    'Lead Asset Stage/Phase': 'Phase I',
    'Indication(s)': 'Paediatric CNS malignancies',
    'Deal Structure':
      "Cellevolve and Seattle Children's Therapeutics will collaborate on advancing the BrainChild research program, which currently includes three pediatric Phase 1 clinical trials at Seattle Children's - BrainChild-01, BrainChild-02 and BrainChild-03. Both organizations have agreed to an exclusive agreement in which Seattle Children's Therapeutics will conduct early-stage discovery and pre-clinical, Phase 1 development while Cellevolve leads Phase 2 and subsequent clinical development with key Seattle Children's Therapeutics involvement. Seattle Children's Therapeutics will receive payments upon achievement of developmental milestones, and Cellevolve will provide financial support for early-stage discovery and preclinical development and Phase 1 clinical development. Seattle Children's will also receive an equity stake in the company. Cellevolve will receive global license and worldwide rights to assets resulting from the collaboration.",
    'FW Link1':
      'Cellevolve Announces Expansive Global Collaboration with Seattle Children’s Therapeutics to Advance Research for Childhood Brain Cancers;;https://firstwordpharma.com/story/5521991/'
  },
  {
    'Date Announced': '10-Mar-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Astellas',
    'Country (Acquiring Company/Partner)': 'Japan',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Genetic Leap',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'RNA-targeted small molecules, artificial intelligence',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Under the terms of the agreement, Genetic Leap will deploy its platform to discover and validate RNA-targeted small molecules against an undisclosed oncology target selected by Astellas. The agreement provides Astellas with certain rights to intellectual property on candidates discovered and developed under the collaboration that are directed against the program target. Further details of the collaboration were not disclosed.',
    'FW Link1':
      'Genetic Leap and Astellas Establish Research Collaboration to Develop RNA-targeted Small Molecules for Oncology Target;;https://www.firstwordhealthtech.com/story/5522929/'
  },
  {
    'Date Announced': '10-Mar-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Erasca',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Eli Lilly',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'ERK1/2 inhibitor, anti-EGFR antibody',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'ERAS-007, Erbitux',
    'Indication(s)': 'BRAF V600E-mutant metastatic colorectal cancer',
    'Target(s)': 'ERK1/2, EGFR',
    'Deal Structure':
      'This agreement will support the ongoing Phase 1b/2 HERKULES-3 trial, a clinical proof-of-concept study evaluating ERAS-007, an oral ERK1/2 inhibitor, in various combinations, including with the BRAF inhibitor encorafenib (BRAFTOVI) and cetuximab for the treatment of patients with BRAF V600E-mutant metastatic colorectal cancer (mCRC). Erasca will sponsor the study, and Lilly will supply cetuximab at no cost. The two companies will form a joint committee to review the clinical trial results.',
    'FW Link1':
      'Erasca Announces Clinical Trial Collaboration and Supply Agreement with Eli Lilly and Company to Evaluate ERAS-007 and Cetuximab Combination;;https://firstwordpharma.com/story/5522863/'
  },
  {
    'Date Announced': '10-Mar-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'xCures',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Tyra Biosciences',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Biomarkers, real-world data, artificial intelligence, precision oncology',
    'Indication(s)': 'FGFR-mutant bladder cancer',
    'Deal Structure':
      "xCures announced their collaboration with Tyra Biosciences for a real-world biomarker study involving patients with FGFR-mutant bladder cancer receiving Balversa (erdafitinib) as their standard of care medical therapy. The study is supported by Tyra Biosciences, Inc. This study will investigate a patient's tumor DNA through their blood and urine samples to provide information on their treatment and what options might be available if their disease worsens.",
    'FW Link1':
      'xCures announces real world biomarker study for patients with FGFR-mutant bladder cancer receiving Balversa;;https://www.firstwordhealthtech.com/story/5523369/'
  },
  {
    'Date Announced': '10-Mar-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Gufic Biosciences',
    'Country (Acquiring Company/Partner)': 'India',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Selvax',
    'Country (Target Company/Partner)': 'Australia',
    'Region (Target Company/Partner)': 'Oceania',
    'Geographic Remit of Deal': 'India, Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immunotherapy',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Under the initiative, Gufic will undertake mutually agreed development activities in return for exclusive commercial rights for the Selvax immunotherapy in India along with an equal share of future revenues that might be derived from future sales of the product in Europe, subject to approvals by regulators in those regions.',
    'FW Link1':
      'Gufic Biosciences enters into research collaboration with Australia based Selvax; Stock climbs ~4%;;https://firstwordpharma.com/story/5523297/'
  },
  {
    'Date Announced': '10-Mar-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Carisma Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Novartis',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'HER 2 targeted CAR-M cell therapy, manufacturing',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'CT-0508',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      "Novartis said that it struck an agreement for the manufacture of Carisma Therapeutics' HER2-targeted CAR-macrophage (CAR-M) cell therapy, which is in development as a potential treatment for solid tumours. Financial terms were not disclosed, but the company said Carisma's manufacturing process will be transferred in the days ahead to the Novartis Cell Therapy site in Morris Plains, New Jersey, with clinical manufacturing slated to begin in 2023.",
    'FW Link1':
      "Novartis inks manufacturing deal for Carisma's CAR-M cell therapy;;https://firstwordpharma.com/story/5522543/"
  },
  {
    'Date Announced': '10-Mar-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Pyramid Biosciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Caris Life Sciences',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Clinical trial matching, precision medicine, TRK inhibitor',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'PBI-200',
    'Indication(s)': 'NTRK fusion solid tumours',
    'Deal Structure':
      "Pyramid Biosciences, Inc., and Caris Life Sciences (Caris) announced a collaboration to enhance identification of potential patients with NTRK fusion-driven cancers for participation in the ongoing Phase 1/2 trial (PBI-200-101) of Pyramid's lead oncology program PBI-200, a best-in-class, next-generation, highly CNS-penetrant TRK inhibitor.",
    'FW Link1':
      'Pyramid Biosciences and Caris Life Sciences Announce MI Trials Collaboration for Clinical Trial Matching in Support of Study for Patients with NTRK Fusion Solid Tumors;;https://www.firstwordhealthtech.com/story/5523073/'
  },
  {
    'Date Announced': '10-Mar-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'DepYmed',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Monash Biomedicine Discovery Institute',
    'Country (Target Company/Partner)': 'Australia',
    'Region (Target Company/Partner)': 'Oceania',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'PTP1B inhibitors, intracellular immune checkpoint',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Cancer',
    'Target(s)': 'PTP1B',
    'Deal Structure':
      'DepYmed is developing a new class of drug candidates that act by inhibiting PTP1B. The research sponsored under the new agreement aims to confirm that its drug candidates also target this intracellular checkpoint mechanism and inhibit tumor growth in preclinical cancer models.',
    'FW Link1':
      'DepYmed and Monash Biomedicine Discovery Institute to Study the Role of a New Generation of PTP1B Inhibitors against a Novel Intracellular Checkpoint in Cancer;;https://firstwordpharma.com/story/5522875/'
  },
  {
    'Date Announced': '11-Mar-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'AIkido Pharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Cedars-Sinai Medical Center',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immuno-oncology',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'The Company and Cedars-Sinai will share costs, expenses and management of the drug co-development and will leverage the talent of Cedars-Sinai Technology Ventures and the lead scientists who originated the novel treatment. The parties will also share in the proceeds of any commercialization of the treatment.',
    'FW Link1':
      'Cedars-Sinai Medical Center and AIkido Pharma Inc. to Codevelop Novel Immuno-Oncology Cancer Treatment;;https://firstwordpharma.com/story/5524133/'
  },
  {
    'Date Announced': '14-Mar-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'IDEAYA Biosciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Pfizer',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'PKC inhibitor, cMET inhibitor',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'darovasertib, crizotinib',
    'Indication(s)': 'Metastatic uveal melanoma, cMET-driven tumours',
    'Target(s)': 'PKC, cMET',
    'Deal Structure':
      'IDEAYA Biosciences has entered into additional clinical trial collaboration and supply agreements with Pfizer to support further evaluation of darovasertib and crizotinib combination therapy in a Phase 2 potential registration-enabling clinical trial in patients with metastatic uveal melanoma (MUM) and in a Phase 1 clinical trial in patients with cMET-driven tumors, such as hepatocellular carcinoma (HCC) and/or non-small cell lung cancer (NSCLC).',
    'FW Link1':
      'IDEAYA and Pfizer Expand Clinical Trial Collaboration and Supply Agreements for Evaluation of Darovasertib and Crizotinib Combination in Metastatic Uveal Melanoma and Additional cMET-Driven Tumors;;https://firstwordpharma.com/story/5524845/',
    'FW Link2':
      'Amendments to agreements announced May 16, 2023;;https://firstwordpharma.com/story/5740627/'
  },
  {
    'Date Announced': '14-Mar-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'NexImmune',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Laura and Isaac Perlmutter Cancer Center',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Artificial antigen presenting cells, neoantigen-specific CD8+ T cells',
    'Indication(s)': 'Melanoma, cancer',
    'Deal Structure':
      "The collaboration is centered around NexImmune's artificial antigen presenting cells' (aAPCs) ability to expand neoantigen-specific CD8+ T cells in apheresis material provided by melanoma patients. Dr. Jeffrey S. Weber, Deputy Director of the Laura and Isaac Perlmutter Cancer Center will guide the research and evaluation.",
    'FW Link1':
      'NexImmune Announces Melanoma Research Collaboration with NYU Langone’s Perlmutter Cancer Center;;https://firstwordpharma.com/story/5525101/'
  },
  {
    'Date Announced': '14-Mar-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Catamaran Bio',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'University of Minnesota',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Next generation cell expansion technologies, CAR-NK cell therapies',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Catamaran Bio announced an expansion and extension of its research collaboration with Branden Moriarity, PhD, of the University of Minnesota, to further advance the versatility of transposon engineering for arming CAR-NK cell therapies with functional attributes not found in earlier generations of cell therapies. Catamaran has also secured an exclusive, worldwide license to University of Minnesota patent rights covering next generation cell expansion technologies which do not require the use of feeder cells. These new technologies were invented during the parties' research collaboration which has been in place since early 2020. Cell expansion technologies which do not rely on the use of feeder cells offer the potential to improve the efficiency and decrease the cost of manufacturing CAR-NK cell therapies.",
    'FW Link1':
      'Catamaran Bio Expands Collaboration for CAR‑NK Cell Engineering with the University of Minnesota and Secures an Exclusive Patent License to Next Generation Manufacturing Technologies;;https://firstwordpharma.com/story/5525189/'
  },
  {
    'Date Announced': '14-Mar-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Imugene',
    'Country (Acquiring Company/Partner)': 'Australia',
    'Region (Acquiring Company/Partner)': 'Oceania',
    'Target Company/Partner': 'Merck & Co.',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'B-cell activating immunotherapy, anti-PD-1 therapy',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'HER-Vaxx, Keytruda',
    'Indication(s)': 'HER-2 positive gastric cancer',
    'Target(s)': 'HER-2/neu, PD-1',
    'Deal Structure':
      'Effective immediately, under the terms of the agreement, Imugene will be the sponsor of the study and will fund the clinical study from existing budgets and resources. MSD will provide pembrolizumab for the duration of the study. The agreement is for an indefinite term until final reports of the study have been completed, noting that the underlying study is anticipated to run for at least 24 months. The agreement includes customary termination and intellectual property provisions for a clinical collaboration agreement.',
    'FW Link1':
      'Imugene Announces Clinical Trial Collaboration with Merck & Co., Inc., Kenilworth, NJ., USA to Evaluate HER-Vaxx in Combination with Pembrolizumab for Treatment of Gastric Cancer;;https://firstwordpharma.com/story/5525901/'
  },
  {
    'Date Announced': '15-Mar-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Qualigen Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'University of Louisville Research Foundation',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'RAS-F platform',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'RAS',
    'Deal Structure':
      "The revised agreement expands the Company's collaboration with the research institution to develop its ongoing RAS-F platform currently in the discovery stage for solid tumors through Q1 of 2023.",
    'FW Link1':
      'Qualigen Therapeutics Extends Research Agreement with University of Louisville Research Foundation on RAS Program;;https://firstwordpharma.com/story/5526621/'
  },
  {
    'Date Announced': '16-Mar-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Sanofi',
    'Country (Acquiring Company/Partner)': 'France',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Seagen',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Under the agreed terms, the companies will co-fund global development activities and share equally in any future profits. In addition, Sanofi will make an undisclosed payment to Seagen for each of the three targets as they are selected, with the first target having already been chosen.',
    'FW Link1':
      'Sanofi, Seagen to jointly develop ADCs for cancer treatment;;https://firstwordpharma.com/story/5526955/'
  },
  {
    'Date Announced': '17-Mar-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'NexImmune',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Zephyr AI',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Target discovery and validation, T-cell-mediated therapies, artificial intelligence, machine learning, AIM nanoparticle technology',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Solid and haematological cancers',
    'Deal Structure':
      'Under the terms of the agreement, Zephyr will employ its proprietary AI and machine learning technology to identify targets suitable for activating T-cell-mediated anti-tumor responses. Specifically, Zephyr will evaluate a range of molecular and genetic data through in silico target discovery to uncover known, high potential tumor-associated antigens and neoantigens that map to specific subtypes of cancer. NexImmune will screen and validate these targets using its proprietary AIM platform. The collaboration will focus on selecting multiple cognate peptides derived from known tumor-associated antigens and neoantigens to rapidly develop new product candidates for clinical trials in patients with cancer in need of lifesaving treatments.',
    'FW Link1':
      'NexImmune and Zephyr AI Announce a Strategic Partnership in Oncology for Target Discovery and Validation;;https://firstwordpharma.com/story/5528213/'
  },
  {
    'Date Announced': '18-Mar-2022',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Apexigen',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Brookline Capital Acquisition Corp.',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Immuno-oncology, antibody-based therapeutics, antibody discovery, CD40 agonist antibody',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'sotigalimab',
    'Indication(s)': 'Cancer',
    'Target(s)': 'CD40',
    'Deal Structure':
      'Apexigen and Brookline Capital Acquisition Corp., a special purpose acquisition company ("SPAC"), announced they have entered into a definitive business combination agreement. Upon closing of the transaction, Brookline Capital Acquisition Corp. will be renamed Apexigen, Inc. (the "Combined Company") and will be led by Xiaodong Yang, M.D., Ph.D., President and Chief Executive Officer of Apexigen. The Combined Company expects to list its stock on Nasdaq under the ticker symbol "APGN".',
    'FW Link1':
      'Apexigen and Brookline Capital Acquisition Corp. Announce Business Combination Agreement to Create Publicly Listed Immuno-oncology Company;;https://firstwordpharma.com/story/5528787/'
  },
  {
    'Date Announced': '17-Mar-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Panthera Biopartners',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Roche',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'United Kingdom',
    'Therapy Area(s)': 'Oncology',
    'Lead Asset Stage/Phase': 'Phase III',
    'Indication(s)': 'Breast cancer',
    'Target(s)': 'PIK3CA',
    'Deal Structure':
      'Panthera will be recruiting from three sites in the UK in Liverpool, Reading and Newport via their partnership with Rutherford Health. This global multicentre trial which will run for up to five years is targeted at breast cancer patients with a PIK3CA mutation.',
    'FW Link1':
      'Panthera wins contract to recruit patients for Roche breast cancer study;;https://firstwordpharma.com/story/5529463/'
  },
  {
    'Date Announced': '21-Mar-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'BMS',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Volastra Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '30',
    'Total Deal Value ($m)': '1,130',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Chromosomal instability-targeted medicines',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Under the terms of the agreement, Volastra will be responsible for conducting various activities for undisclosed targets. For select targets, Volastra will conduct all research activities through development candidate selection and Bristol Myers Squibb may take on the responsibilities for all subsequent development, regulatory and commercialization activities of the development candidates under an exclusive worldwide license. Volastra will receive $30 million in an upfront payment and will also be eligible to receive up to $1.1 billion in development, regulatory and commercial milestone payments. Additionally, Volastra is entitled to receive royalties on net global sales of any product commercialized by Bristol Myers Squibb resulting from the collaboration.',
    'FW Link1':
      "BMS partners with Volastra on drugs exploiting cancer's chromosomal instability;;https://firstwordpharma.com/story/5529567/"
  },
  {
    'Date Announced': '21-Mar-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'RNAGene',
    'Country (Acquiring Company/Partner)': 'Korea, South',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'PharmAbcine',
    'Country (Target Company/Partner)': 'Korea, South',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'mRNA-based antibody therapeutics',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Under the agreement, both companies will collaborate to develop next-generation mRNA-based antibody therapeutics by utilizing the proprietary mRNA platform from RNAGENE.',
    'FW Link1':
      'RNAGENE Signs a Collaborative Research Agreement with PharmAbcine to Develop Novel mRNA Therapeutics;;https://firstwordpharma.com/story/5529921/'
  },
  {
    'Date Announced': '21-Mar-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Transcenta',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'BMS',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Humanised monoclonal antibody, anti-PD-1 therapy',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'TST001, Opdivo',
    'Indication(s)':
      'Locally advanced or metastatic gastric cancer or gastro-oesophageal junction cancer',
    'Target(s)': 'Claudin18.2, PD-1',
    'Deal Structure':
      'Under the terms of the agreement, Transcenta will be the sponsor of the trials and Bristol Myers Squibb will supply Opdivo to Transcenta for use in its combination therapy studies with TST001.',
    'FW Link1':
      'Transcenta Announces Global Clinical Collaboration with Bristol Myers Squibb to Evaluate TST001 in Combination with Opdivo in Patients with Locally Advanced or Metastatic Gastric / Gastroesophageal Junction Cancer;;https://firstwordpharma.com/story/5530273/'
  },
  {
    'Date Announced': '23-Mar-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Kirilys Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Ube Industries',
    'Country (Target Company/Partner)': 'Japan',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'CDK7 inhibitor',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'KRLS-017',
    'Indication(s)': 'Cancer',
    'Target(s)': 'CDK7',
    'Deal Structure':
      'The company licensed its lead compound, KRLS-017, from Ube Industries, Ltd., a premier Japanese chemical manufacturer.',
    'FW Link1':
      'Catalys Pacific Launches Kirilys Therapeutics as a Multi-asset Precision Oncology Company;;https://firstwordpharma.com/story/5531865/'
  },
  {
    'Date Announced': '23-Mar-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'BullFrog AI',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Johns Hopkins University',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Novel formulation of mebendazole',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'BF-222',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Under the terms of the agreement, BullFrog AI was granted an exclusive worldwide license to manufacture and use a novel formulation of mebendazole developed at the Johns Hopkins University (JHU) School of Medicine for treatment of cancer.',
    'FW Link1':
      'BullFrog AI Enters into Licensing Agreement with Johns Hopkins University for Use of Novel Formulation of Mebendazole for Treatment of Cancer;;https://firstwordpharma.com/story/5531853/'
  },
  {
    'Date Announced': '25-Mar-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Roche',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'BMS',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Digital pathology algorithms, artificial intelligence, biomarker data, assays',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Roche announced that it has entered into a collaboration with Bristol Myers Squibb to support the advancement of two assays for use in clinical trials with the development and deployment of two new digital pathology algorithms. Data from both projects will be used to aid in cancer diagnosis and to advance personalised healthcare treatment options, with the aim of improving outcomes for patients.',
    'FW Link1':
      'Roche, BMS pair up on pathology algos;;https://www.firstwordhealthtech.com/story/5533383/'
  },
  {
    'Date Announced': '28-Mar-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Avera Health',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Theralink Technologies',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Molecular profiling, assay, precision oncology',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Theralink will provide key patient-specific information about which drug targets are activated and "in use" in each patient tumor sample. This information, coupled with the genomics findings, will provide a comprehensive molecular profile for all Avera oncology patients by way of a multiomic report used for physician treatment decisions.',
    'FW Link1':
      'Avera Health and Theralink Technologies Announce Strategic Collaboration to Accelerate Adoption of Precision Oncology and Personalized Cancer Care;;https://www.firstwordhealthtech.com/story/5534535/'
  },
  {
    'Date Announced': '28-Mar-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Leonie Hill Capital',
    'Country (Acquiring Company/Partner)': 'Singapore',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'miR Scientific',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Singapore, Southeast Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Urine liquid biopsy prostate cancer test',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'miR Sentinel Prostate Cancer Test',
    'Indication(s)': 'Prostate cancer',
    'Deal Structure':
      'miR Scientific and Leonie Hill Capital announced the signing of a collaboration agreement to partner in the commercial launch of the award-winning miR Sentinel Prostate Cancer Test in Singapore and Southeast Asia.',
    'FW Link1':
      'miR Scientific and Leonie Hill Capital Announce Collaboration to Introduce Breakthrough Urine Liquid Biopsy Prostate Cancer Test in Singapore;;https://www.firstwordhealthtech.com/story/5534573/'
  },
  {
    'Date Announced': '28-Mar-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Artelo Biosciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Trinity College Dublin',
    'Country (Target Company/Partner)': 'Ireland',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'FABP inhibition',
    'Indication(s)': 'Cancer',
    'Target(s)': 'FABP',
    'Deal Structure':
      "Professor Porter's focus will be on investigating the molecular basis of fatty acid binding protein (FABP) inhibition in cancer and the potential of the ART26.12 platform for the treatment of various tumors. Artelo believes the Trinity College research initiative will refine and expand the Company's understanding of the role of certain FABP inhibitors and could conceivably translate into multiple compounds tailored to specific cancers from Artelo's library of compounds.",
    'FW Link1':
      'Artelo Expands Collaboration with Trinity College Dublin to Investigate Cellular Biology of Fatty Acid Binding Protein (FABP) Inhibition In Cancer;;https://firstwordpharma.com/story/5534541/'
  },
  {
    'Date Announced': '27-Mar-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Innovent Biologics',
    'Country (Acquiring Company/Partner)': 'china',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Eli Lilly',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Mainland China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'VEGFR2 antagonist, RET inhibitor, BTK inhibitor',
    'Lead Asset(s)': 'Cyramza, Retsevmo, pirtobrutinib',
    'Indication(s)':
      'Gastric cancer, hepatocellular carcinoma, non-small cell lung cancer, medullary thyroid cancer, thyroid cancer, chronic lymphocytic leukaemia, small lymphocytic lymphoma, mantle cell lymphoma',
    'Target(s)': 'VEGFR2, RET, BTK',
    'Deal Structure':
      'According to the agreement, Innovent has the sole commercialization rights for both Cyramza and Retsevmo, once approved in China, of which Innovent will be fully responsible for the pricing, importation, marketing, distribution and detailing of these two products. In addition, Lilly has granted a right of first negotiation to Innovent for the potential future commercialization of pirtobrutinib in China. Under the terms of the agreement, upon regulatory approvals of Cyramza in the hepatocellular carcinoma indication and Retsevmo in the non-small lung cancer indication, Innovent will make payments of US$45 million in total and then intends to commercialize Cyramza and Retsevmo in China.',
    'FW Link1':
      'Innovent and Lilly Expand Strategic Partnership in Oncology;;https://firstwordpharma.com/story/5534147/'
  },
  {
    'Date Announced': '29-Mar-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Endocyte',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'ABX advanced biochemical compounds',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Radioligand therapy',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Pluvicto',
    'Indication(s)': 'Metastatic castration-resistant prostate cancer ',
    'Deal Structure':
      'Otsuka Pharmaceutical Co., Ltd (Otsuka) is pleased to announce that ABX advanced biochemical compounds GmbH (ABX), a subsidiary of Cambridge Isotope Laboratories, Inc. (CIL), which in turn is a wholly-owned subsidiary of Otsuka, expects to receive payments and royalties from Endocyte Inc., a Novartis company, based on the U.S. FDA regulatory approval of Pluvicto (lutetium Lu 177 vipivotide tetraxetan). In 2017, ABX entered into a license agreement to license the rights to further develop and commercialize the radiopharmaceutical to Endocyte, Inc. in the U.S., which was later acquired by Novartis in 2018. ABX expects to receive payments and royalties from Endocyte Inc., based on approval and marketing milestones and the level of future sales.',
    'FW Link1':
      'Otsuka Subsidiary to Receive Payment and Royalties from Approval in U.S. of Radioligand Therapy for Metastatic Castration-Resistant Prostate Cancer- Out-licensed by ABX in Germany, an indirectly-owned subsidiary of Otsuka Pharmaceutical;;https://firstwordpharma.com/story/5535315/'
  },
  {
    'Date Announced': '29-Mar-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Sanofi',
    'Country (Acquiring Company/Partner)': 'France',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'IGM Biosciences',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '150',
    'Total Deal Value ($m)': '6,000',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'IgM antibody agonists',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Under the deal, Sanofi will make an upfront payment of $150 million, with IGM eligible to receive potential milestones of over $6 billion. As part of the collaboration, IGM will lead R&D activities and assume related costs for each oncology target, through approval of the first marketing application in the US or EU, in exchange for up to $940 million in development and regulatory milestones per oncology target. After approval, Sanofi will lead all subsequent development and commercialisation activities, with profits equally split in certain major markets and IGM eligible for tiered sales royalties in the rest of the world. Meanwhile, for each immunology/inflammation target, IGM will lead R&D activities and assume related costs, through the completion of a Phase I trial for up to two constructs, after which Sanofi will handle all future development and related costs, in exchange for up to nearly $1.1 billion in aggregate development, regulatory and commercial milestones. The companies noted that IGM is eligible to receive tiered royalties ranging from high-single digits to low-teens on global net sales.',
    'FW Link1':
      'Sanofi inks deal with IGM to develop IgM antibody agonists;;https://firstwordpharma.com/story/5535027/'
  },
  {
    'Date Announced': '30-Mar-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Sterotherapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner':
      'Moffitt Cancer Center, Florida Atlantic University',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'MMP-13 inhibitors',
    'Indication(s)': 'Multiple myeloma',
    'Target(s)': 'MMP-13',
    'Deal Structure':
      'Sterotherapeutics has entered into an exclusive agreement with Moffitt Cancer Center and Florida Atlantic University Research Corporation for a license to novel compounds and methods for the treatment of multiple myeloma. The licensed compounds are highly selective matrix metalloproteinase 13 (MMP-13) inhibitors that display robust modulation of osteoclastogenesis with excellent solubility and stability that portend to be highly effective add-on treatments to the current standard of care.',
    'FW Link1':
      'Sterotherapeutics Signs Licensing Agreement Aimed to Improve the Treatment of Multiple Myeloma;;https://firstwordpharma.com/story/5537135/'
  },
  {
    'Date Announced': '31-Mar-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Sellas',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'GenFleet Therapeutics',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '10',
    'Total Deal Value ($m)': '150',
    'Geographic Remit of Deal': 'Global excl. Greater China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecule',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'GFH009',
    'Indication(s)': 'Haematologic malignancies, solid tumours',
    'Target(s)': 'CDK9',
    'Deal Structure':
      'Under the financial terms of the agreement, SELLAS will pay to GenFleet (i) an initial payment of $10 million as an upfront license and technology transfer fee, a portion of which is payable within 30 days of the execution of the license agreement with the remainder due upon the completion of the technology transfer, (ii) development and regulatory milestone payments for up to three indications totaling up to $48 million in the aggregate, and (iii) milestone payments totaling up to $92 million in the aggregate upon the achievement of certain net sales thresholds of GFH009 in the United States and rest of the world other than Greater China in a given calendar year. SELLAS will also pay GenFleet tiered royalties based on a percentage of annual net sales of GFH009 ranging from the low to high single digits.',
    'FW Link1':
      'SELLAS Life Sciences Signs Exclusive License Agreement with GenFleet Therapeutics for Next-Generation, Highly Selective CDK9 Inhibitor;;https://firstwordpharma.com/story/5538385/'
  },
  {
    'Date Announced': '31-Mar-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Quadri Pharma',
    'Country (Acquiring Company/Partner)': 'United Arab Emirates',
    'Region (Acquiring Company/Partner)': 'Middle East',
    'Target Company/Partner': 'Jaguar Health',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal':
      'Bahrain, Kuwait, Qatar, Saudi Arabia, UAE, Oman',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antidiarrhoeal',
    'Lead Asset(s)': 'crofelemer',
    'Indication(s)': 'Cancer therapy-related diarrhoea',
    'Deal Structure':
      'Agreement grants Quadri Pharmaceuticals Store exclusive promotional, commercialization, and distribution rights in Bahrain, Kuwait, Qatar, Saudi Arabia, the United Arab Emirates, and Oman for human indications of crofelemer approved in the U.S., including future target indications.',
    'FW Link1':
      'Jaguar Health Enters Exclusive Crofelemer Distribution and License Agreement with Quadri Pharmaceuticals Store for Multiple Target Indications in Middle East Markets;;https://firstwordpharma.com/story/5537893/'
  },
  {
    'Date Announced': '30-Mar-2022',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'Cipla',
    'Country (Acquiring Company/Partner)': 'India',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'mAbxience',
    'Country (Target Company/Partner)': 'Spain',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'South Africa',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Biosimilars',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Cipla Medpro and the wholly owned subsidiary of Cipla Limited, India have finalised a partnership agreement with the global biotechnology company mAbxience, to get oncology and respiratory-related biosimilars to South Africa.',
    'FW Link1':
      'Cipla enters partnership to provide affordable oncology biosimilars to South Africa;;https://firstwordpharma.com/story/5537697/'
  },
  {
    'Date Announced': '31-Mar-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Ono Pharmaceutical',
    'Country (Acquiring Company/Partner)': 'Japan',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Numab',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Total Deal Value ($m)': '280',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Multispecific antibody',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Under the terms of the license agreement, Ono acquires intellectual property rights and exclusive global development and commercialization rights for a multispecific antibody targeting a novel immuno-oncology target. In consideration for the discovery work and the license, Numab will receive up to CHF 258 million in research funding, upfront and milestone payments plus tiered single to double digit royalties on future sales.',
    'FW Link1':
      'Numab Therapeutics and Ono Pharmaceutical Enter Development and License Agreement for Multispecific Antibody Candidate Involving Novel Immuno-Oncology Target;;https://firstwordpharma.com/story/5537389/'
  },
  {
    'Date Announced': '3-Apr-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Annexin',
    'Country (Acquiring Company/Partner)': 'Sweden',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Stanford University',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immune checkpoint inhibitor',
    'Lead Asset(s)': 'Annexin A5, ANXV',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Annexin Pharmaceuticals AB (publ) has entered into a license agreement with Stanford University, CA, USA granting Annexin Pharmaceuticals exclusive license to patent rights related to use of Annexin A5 in treatment of cancer. The license involves a novel therapeutic concept for treating cancer by administration of the Annexin A5 protein, functionally identical to Annexin Pharmaceuticals' drug candidate ANXV. The licensing terms are according to industry standards.",
    'FW Link1':
      'Annexin signs exclusive licensing deal in cancer;;https://firstwordpharma.com/story/5539769/'
  },
  {
    'Date Announced': '4-Apr-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Elypta',
    'Country (Acquiring Company/Partner)': 'Sweden',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'University of Leeds, Yorkshire Cancer Research',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'United Kingdom',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'GAGomes, metabolic biomarkers, multi-cancer early detection',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'This study, LEVANTIS-0093A (LEV93A), aims to detect any-type cancer in adults at high risk of developing cancer due to significant smoking history. Elypta will analyze blood samples prospectively collected within the Yorkshire Lung Screening Trial Biomarker sub-study and corresponding participant data prospectively collected within the Yorkshire Lung Screening Trial and the Yorkshire Kidney Screening Trial, all of which are sponsored by the University of Leeds and funded by Yorkshire Cancer Research, in collaboration with the University of Manchester, the University of Leeds, and the Leeds Teaching Hospitals NHS Trust.',
    'FW Link1':
      'Elypta Launches Multi-Cancer Early Detection study to Measure Metabolic GAGome Levels in Adults at High Risk of Cancer, in Collaboration with the University of Leeds and Yorkshire Cancer Research;;https://www.firstwordhealthtech.com/story/5540289/'
  },
  {
    'Date Announced': '4-Apr-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Genialis',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'OncXerna',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'RNA-based pan-tumour biomarker, personalised therapeutic selection, immune-targeted cancer therapies',
    'Lead Asset(s)': 'Xerna TME Panel',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Genialis announced it will leverage its proprietary ResponderID AI platform technology to provide retrospective analysis to customers and collaborators to help classify patients for targeted therapy using the OncXerna Xerna TME Panel, an RNA-based pan-tumor biomarker shown to be predictive of responses to multiple immune-targeted cancer therapies.',
    'FW Link1':
      'Genialis ResponderID to Use Xerna TME Panel to Improve Targeted Therapy Selection;;https://www.firstwordhealthtech.com/story/5540099/'
  },
  {
    'Date Announced': '4-Apr-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'MIM Software',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Videra Surgical',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Tumour bed marker, imaging, radiation therapy',
    'Lead Asset(s)': 'VeraForm',
    'Indication(s)': 'Breast cancer',
    'Deal Structure':
      "MIM Software Inc. and Videra Surgical Inc. have announced a collaboration involving Videra Surgical's VeraForm tumor bed marker and MIM Software's platform for Radiation Oncology. As part of this collaboration, MIM Software has created an automated workflow that assists with the identification and segmentation of VeraForm. This will facilitate semi- and auto-contouring of the tumor bed for radiation oncologists.",
    'FW Link1':
      'MIM Software and Videra Surgical Announce Integration for Breast Cancer Radiation Therapy;;https://www.firstwordhealthtech.com/story/5540261/'
  },
  {
    'Date Announced': '4-Apr-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Guardant Health',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Epic',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'United States',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Health record, cancer tests',
    'Lead Asset(s)':
      'Epic, Guardant SHIELD, Guardant360 CDx, Guardant360 Response, Guardant Reveal',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Guardant Health announced a partnership with Epic, the nation’s most widely used comprehensive health record, to integrate the company’s broad portfolio of cancer tests with Epic. This integration will make it easier for health systems, community healthcare providers and retail health clinics to make Guardant Health blood tests part of routine clinical care by providing direct ordering access and results delivery.',
    'FW Link1':
      'Guardant Health Announces Partnership with Epic to Streamline Access to Company’s Broad Portfolio of Cancer Tests;;https://www.firstwordhealthtech.com/story/5540305/'
  },
  {
    'Date Announced': '5-Apr-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Plus Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Medidata',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Synthetic control arm, historical clinical trial data, radiotherapeutic',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': '186RNL',
    'Indication(s)': 'Recurrent glioblastoma',
    'Deal Structure':
      "Medidata will provide the Company with a SCA based on a historical pool of anonymized HCT data to incorporate into Plus Therapeutics' planned Phase 2 trial of 186RNL in recurrent GBM.",
    'FW Link1':
      'Plus Therapeutics and Medidata Announce Expanded Clinical Trial Partnership;;https://firstwordpharma.com/story/5541295/'
  },
  {
    'Date Announced': '5-Apr-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'GSK',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'PathAI',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Digital pathology, artificial intelligence',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "PathAI and GlaxoSmithKline announced a strategic multi-year partnership to accelerate scientific research and drug development programs in oncology and non-alcoholic steatohepatitis (NASH) by leveraging PathAI's technologies in digital pathology including the use of PathAI's AIM-NASH tool.",
    'FW Link1':
      'PathAI and GlaxoSmithKline Sign Multi-Year Agreement to Accelerate Research and Drug Development;;https://firstwordpharma.com/story/5541645/'
  },
  {
    'Date Announced': '5-Apr-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'IBA',
    'Country (Acquiring Company/Partner)': 'Belgium',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'University Medical Center Groningen',
    'Country (Target Company/Partner)': 'Netherlands',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Radiotherapy',
    'Lead Asset(s)': 'ConformalFLASH',
    'Indication(s)': 'Early-stage breast cancer',
    'Deal Structure':
      'IBA and the University Medical Center Groningen (UMCG) announced a four-year research collaboration agreement towards development of a new FLASH irradiation protocol for the treatment of early-stage breast cancer. As part of the collaboration, both parties will share resources and combine expertise as well as leverage the knowledge and infrastructure of the Particle Therapy Research Center (PARTREC), a dedicated research facility functioning in synergy with the clinical UMCG Groningen Proton Therapy Center (GPTC).',
    'FW Link1':
      'IBA and UMC Groningen engage in FLASH irradiation research collaboration for breast cancer;;https://firstwordpharma.com/story/5541251/'
  },
  {
    'Date Announced': '5-Apr-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Redx Pharma',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Garvan Institute of Medical Research',
    'Country (Target Company/Partner)': 'Australia',
    'Region (Target Company/Partner)': 'Oceania',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Novel therapeutic targets in cancer-associated fibrosis, targeted therapeutics, Porcupine inhibitor',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'RXC004',
    'Indication(s)':
      'Fibrotic cancers, cancer-associated fibrosis, pancreatic cancer, metastatic colorectal cancer, biliary cancer',
    'Target(s)': 'Porcupine, ROCK2, DDR',
    'Deal Structure':
      "The collaboration aims to better understand treatments that could lead to increased patient survival in currently very poorly treated highly fibrotic cancers, such as pancreatic cancer. Through this collaboration, Redx and the Garvan will develop an enhanced understanding of cancer-associated fibrosis through detailed scientific studies utilising patient-derived tumour tissue grown in mice, which is thereby able to mimic human disease as closely as possible. The research will bring together the Garvan's research capabilities and leading preclinical cancer models with Redx's proprietary molecules in development for novel targets potentially implicated in cancer-associated fibrosis, such as Porcupine, ROCK2 and Discoidin Domain Receptors (DDR).",
    'FW Link1':
      'Redx Announces Collaboration with the Garvan Institute of Medical Research;;https://firstwordpharma.com/story/5541127/'
  },
  {
    'Date Announced': '6-Apr-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Alloy Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Tegmine',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Antibody therapeutics, cancer-associated glycan post-translational modifications',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      'Tegmine Therapeutics announced an investment from Alloy Therapeutics and a collaboration to accelerate the development of antibody therapeutics that target proteins carrying cancer-associated glycan post-translational modifications (PTMs). Tegmine will generate immunogens and cell line screening reagents which reflect patient biology and Alloy will lead the antibody discovery and hit validation.',
    'FW Link1':
      'Tegmine Therapeutics Announces Collaboration with Alloy Therapeutics to Advance Solid Tumor Treatment;;https://firstwordpharma.com/story/5542523/'
  },
  {
    'Date Announced': '6-Apr-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'AstraZeneca',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Harbour BioMed',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '25',
    'Total Deal Value ($m)': '350',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Bispecific antibody',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'HBM7022',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'Claudin18.2, CD3',
    'Deal Structure':
      'Upon the execution of the license agreement and subject to terms and conditions thereof, AstraZeneca will be granted an exclusive global license for research, development, registration, manufacturing, and commercialization of HBM7022 and shall be solely responsible for all costs and activities associated with its further development and commercialization. Pursuant to the license agreement and subject to the terms and conditions thereof, HBM shall receive an upfront payment of US$25 million with the potential for additional payments up to US$325 million pending achievement of certain development, regulatory and commercial milestones. HBM is also eligible to receive tiered royalties on net sales.',
    'FW Link1':
      "AstraZeneca bags rights to Harbour BioMed's bispecific targeting Claudin18.2, CD3;;https://firstwordpharma.com/story/5543383/"
  },
  {
    'Date Announced': '7-Apr-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Jazz Pharmaceuticals',
    'Country (Acquiring Company/Partner)': 'Ireland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Werewolf Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '15',
    'Total Deal Value ($m)': '1,275',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Engineered IFN⍺2b cytokine pro-drug',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'WTX-613',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Under the terms of the transaction, Jazz has secured exclusive global rights to WTX-613. Jazz will make an upfront payment of $15 million to Werewolf, and Werewolf is eligible to receive development, regulatory and commercial milestone payments of up to $1.26 billion. Pending approval, Werewolf is eligible to receive a tiered, mid-single-digit percentage royalty on net sales of WTX-613.',
    'FW Link1':
      'Jazz expands oncology pipeline via Werewolf deal;;https://firstwordpharma.com/story/5543501/'
  },
  {
    'Date Announced': '8-Apr-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Blueprint Medicines',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'AstraZeneca',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'EGFR tyrosine kinase inhibitors',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'BLU-945, BLU-701, osimertinib',
    'Indication(s)': 'EGFR-mutant non-small-cell lung cancer',
    'Target(s)': 'EGFR',
    'Deal Structure':
      'Blueprint Medicines announced a clinical trial supply agreement with AstraZeneca. Under the terms of the agreement, Blueprint Medicines will evaluate BLU-945 and BLU-701 in combination with osimertinib in the ongoing SYMPHONY and HARMONY trials, respectively.',
    'FW Link1':
      'Blueprint Medicines Announces BLU-945 Proof-of-Concept Data Supporting Initiation of Comprehensive Combination Development Strategy in EGFR-mutant Non-Small Cell Lung Cancer;;https://firstwordpharma.com/story/5544927/'
  },
  {
    'Date Announced': '8-Apr-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Servier',
    'Country (Acquiring Company/Partner)': 'France',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Physiomics',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Mathematical modelling, simulation, immuno-oncology combinations',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Physiomics will be providing specialist mathematical modelling services using its Virtual Tumour software platform to model and simulate the effect of a number of immuno-oncology combinations involving Servier drugs in development in pre-clinical and clinical settings. It is anticipated that the project will be completed over the course of the next 7-8 months.',
    'FW Link1':
      'Physiomics - Contract award;;https://firstwordpharma.com/story/5544509/'
  },
  {
    'Date Announced': '11-Apr-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Merck & Co.',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Artiva Biotherapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'NK cells, tri-specific NK-cell engagers',
    'Lead Asset(s)': 'AB-101',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Under the agreement, Merck will evaluate the therapeutic potential of combinations of tri-specific NK-cell engager candidates with clinical grade AB-101 NK cells generated from Artiva's AlloNK platform.",
    'FW Link1':
      'Artiva Biotherapeutics Announces Agreement with Merck to Evaluate Combinations of NK Cells with Tri-Specific NK-Cell Engagers;;https://firstwordpharma.com/story/5545927/'
  },
  {
    'Date Announced': '7-Apr-2022',
    'Deal type': 'Product Acquisition',
    'Acquiring Company/Partner': 'Ymmunobio',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'LeukoCom',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'CEACAM antibodies',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'YB-200',
    'Indication(s)': 'Melanoma, solid tumours',
    'Deal Structure':
      'Under the terms of the agreement, Ymmunobio acquires the patents and intellectual property to several CEACAM antibodies, including YB-200, which is currently in preclinical development as an immune-agonistic CEACAM1 antibody for the potential treatment of melanoma and other solid tumors. Ymmunobio AG has the rights to CEACAM1 antibodies YB-200, YB-300, YB-400 and one additional antibody targeting CEACAM8. It will also have the rights to all future CEACAM-related patents developed or secured by LeukoCom GmbH.',
    'FW Link1':
      'Ymmunobio Signs Patent Purchase Agreement with LeukoCom;;https://firstwordpharma.com/story/5546283/'
  },
  {
    'Date Announced': '10-Apr-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Telix',
    'Country (Acquiring Company/Partner)': 'Australia',
    'Region (Acquiring Company/Partner)': 'Oceania',
    'Target Company/Partner': 'Eli Lilly',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '5',
    'Total Deal Value ($m)': '230',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Radiolabelled antibody',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'olaratumab',
    'Indication(s)': 'Soft tissue sarcoma',
    'Target(s)': 'PDGFRα',
    'Deal Structure':
      "Under the terms of the agreement Telix will pay Lilly an upfront payment of US$5M (~AU$6.7M) for the grant of an exclusive licence to Lilly's intellectual property related to the development of a radiolabelled olaratumab, as well as access to material for use by Telix in initial pre-clinical and early-phase clinical studies in application to potential uses for the diagnosis and treatment of human cancers. Lilly may be eligible for up to US$225M (~AU$301M) in payments based upon the achievement of pre-specified development, regulatory and commercial milestones. Lilly would also be eligible to receive industry standard royalties on net sales. The agreement also includes an option for Lilly to be granted an exclusive licence to a radiolabelled companion diagnostic which would be developed by Telix. If exercised, Lilly will pay Telix US$5M (~AU$6.7M) and up to US$30M (~AU$40.1M) in potential development milestones, as well as industry standard royalties.",
    'FW Link1':
      'Telix Pharmaceuticals Announces Licence Agreement with Lilly for Olaratumab;;https://firstwordpharma.com/story/5545503/'
  },
  {
    'Date Announced': '11-Apr-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'NeoGenomics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Biognosys',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Proteomics solutions',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "The Global Strategic Partnership between NeoGenomics and Biognosys AG will encompass multiple strategic and commercial initiatives including NeoGenomics' labs offering access to Biognosys proteomics platforms, medical and scientific affairs-joint presentations and discussions along with global scientific and technical initiatives.",
    'FW Link1':
      'NeoGenomics Expands Its Global Strategic Partnership Initiatives with Biognosys on Multiple Next Generation Proteomics Solutions Supporting Biopharma R&D;;https://www.firstwordhealthtech.com/story/5545663/'
  },
  {
    'Date Announced': '11-Apr-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'The London Clinic',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Datar Cancer Genetics',
    'Country (Target Company/Partner)': 'India',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cancer genomics, testing',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Datar Cancer Genetics has announced its partnership with The London Clinic, the UK's leading independent charitable hospital, to provide precision oncology-based solutions to improve the detection and treatment of cancers.",
    'FW Link1':
      'Datar Cancer Genetics Enters Into Partnership with The London Clinic to Offer Enhanced Cancer Testing Services;;https://www.firstwordhealthtech.com/story/5545699/'
  },
  {
    'Date Announced': '11-Apr-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Chimeric Therapeutics',
    'Country (Acquiring Company/Partner)': 'Australia',
    'Region (Acquiring Company/Partner)': 'Oceania',
    'Target Company/Partner': 'WuXi Advanced Therapies',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Autologous CAR T-cell therapies, manufacturing',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'CHM 1101',
    'Indication(s)': 'Solid tumours, glioblastoma',
    'Deal Structure':
      "Chimeric Therapeutics has entered into a strategic manufacturing partnership with WuXi ATU, a global contract testing and manufacturing organization (CTDMO). The partnership initially focusses on Chimeric's two autologous CAR T-cell therapies for solid tumours: CHM 2101 (CDH17 CAR T), currently in late preclinical development for gastrointestinal (GI) cancers and CHM 1101 (CLTX CAR T), currently being evaluated in a single-site phase 1 clinical trial for patients with recurrent or progressive glioblastoma.",
    'FW Link1':
      'Chimeric enters strategic manufacturing partnership with WuXi Advanced Therapies;;https://firstwordpharma.com/story/5546759/'
  },
  {
    'Date Announced': '12-Apr-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Nascent Biotech',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'BioTools',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Monoclonal antibody, ROA laser-based technology',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'Pritumumab',
    'Indication(s)': 'Brain tumours',
    'Deal Structure':
      "Nascent Biotech, developer of Pritumumab (PTB), a monoclonal antibody showing promise in the treatment of brain cancer, has partnered with BioTools, Inc to clearly define PTB's higher order molecular structure (HOS).",
    'FW Link1':
      'Nascent Biotech and BioTools Partner in the Fight Against Cancer Using ROA to Define the Higher Order Structure of PTB, a Unique Monoclonal Antibody;;https://firstwordpharma.com/story/5546995/'
  },
  {
    'Date Announced': '12-Apr-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Immedica',
    'Country (Acquiring Company/Partner)': 'Sweden',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Actinium Pharmaceuticals',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '35',
    'Total Deal Value ($m)': '452',
    'Geographic Remit of Deal': 'Europe, MENA',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody radiation conjugate',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'Iomab-B',
    'Indication(s)':
      'Bone marrow transplant conditioning, acute myeloid leukaemia',
    'Target(s)': 'CD45',
    'Deal Structure':
      'Under the terms of the agreement, Actinium will receive an upfront payment of $35 million and will be eligible to receive an additional $417 million in regulatory and commercial milestones as well as royalties in the mid-twenty percent range on net sales. Immedica receives commercialization rights in Europe and MENA countries. Actinium retains all rights related to Iomab-B in the United States and the rest of the world, and will be responsible for certain clinical and regulatory activities and the manufacturing of Iomab-B.',
    'FW Link1':
      'Actinium Pharmaceuticals and Immedica Announce Commercialization Agreement for Iomab-B (131I apamistamab) in Europe, the Middle East and North Africa;;https://firstwordpharma.com/story/5547023/'
  },
  {
    'Date Announced': '13-Apr-2022',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'GSK',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Sierra Oncology',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Total Deal Value ($m)': '1,900',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecule',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'momelotinib',
    'Indication(s)': 'Myelofibrosis',
    'Target(s)': 'JAK1, JAK2, ACVR1/ALK2',
    'Deal Structure':
      'Under the terms of the agreement, the acquisition will be effected through a one-step merger in which the shares of Sierra Oncology outstanding will be cancelled and converted into the right to receive $55 per share in cash. Subject to customary conditions, including the approval of the merger by at least a majority of the issued and outstanding shares of Sierra Oncology, and the expiration or earlier termination of the waiting period under the Hart-Scott-Rodino Antitrust Improvements Act of 1976, the transaction is expected to close in the third quarter of 2022 or before.',
    'FW Link1':
      'GSK adds to cancer portfolio with deal to buy Sierra Oncology;;https://firstwordpharma.com/story/5547885/'
  },
  {
    'Date Announced': '13-Apr-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Boehringer Ingelheim',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Oxford BioTherapeutics',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Novel oncology target, antibody products',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Under the terms of the agreement, Boehringer Ingelheim will be responsible for the future development and commercialization of antibody products to the target. OBT will receive development and regulatory milestone payments as well as royalties on any future product sales. This represents the third asset to be optioned under the longstanding partnership between OBT and Boehringer Ingelheim, with the first two programs already in clinical development.',
    'FW Link1':
      'Oxford BioTherapeutics Grants Third Exclusive License to Boehringer Ingelheim for Development and Commercialization of Antibody Products to a Novel Oncology Target;;https://firstwordpharma.com/story/5548277/'
  },
  {
    'Date Announced': '13-Apr-2022',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Omega Healthcare',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'ApexonHealth, Vasta Global',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Revenue cycle management, payor solutions, artificial intelligence, clinical data management',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Omega Healthcare, a leading technology-enabled healthcare management solutions provider, announced its acquisition of ApexonHealth, an AI and automation-based revenue cycle management and payor solutions provider, and Vasta Global, a provider of real-world data abstraction and analytics for the oncology market.',
    'FW Link1':
      'Omega Healthcare completes acquisition of ApexonHealth and Vasta Global;;https://www.firstwordhealthtech.com/story/5548317/'
  },
  {
    'Date Announced': '13-Apr-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Texas Oncology',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Foundation Medicine',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'United States',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Companion diagnostic, comprehensive genomic profiling test, liquid biopsy testing',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'FoundationOne Liquid CDx',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Texas Oncology has entered into an agreement with Foundation Medicine, a pioneer in molecular profiling for cancer, to provide Texas Oncology care teams with access to its Food and Drug Administration (FDA)-approved blood-based comprehensive genomic profiling (CGP) test, FoundationOne Liquid CDx.',
    'FW Link1':
      'Texas Oncology Announces Collaboration to Provide More Cancer Patients with Access to Liquid Biopsy Testing from Foundation Medicine;;https://www.firstwordhealthtech.com/story/5548291/'
  },
  {
    'Date Announced': '15-Apr-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Otsuka',
    'Country (Acquiring Company/Partner)': 'Japan',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Osaka University',
    'Country (Target Company/Partner)': 'Japan',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Anti-CD98 heavy-chain antibody',
    'Lead Asset(s)': 'R8H283',
    'Indication(s)': 'Multiple myeloma',
    'Target(s)': 'CD98',
    'Deal Structure':
      'Osaka University and Otsuka entered into an exclusive, worldwide license agreement for Otsuka to use R8H283, a novel antibody that recognizes the CD98 heavy chain identified by Osaka University, in its pharmaceutical and medical products. Upon consummation of this agreement, Otsuka will make an up-front payment to Osaka University, as well as development and sales-milestone payments and sales royalties. Otsuka will exclusively perform non-clinical research, clinical development, manufacturing, and commercialization of products utilizing R8H283, including ethical drugs as well as regenerative medicine products such as CAR-T cell therapy products.',
    'FW Link1':
      'Osaka University and Otsuka Pharmaceutical to Enter into an Exclusive License Agreement on New Anti-tumor Antibody;;https://firstwordpharma.com/story/5549909/'
  },
  {
    'Date Announced': '18-Apr-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'AstraZeneca',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Amoy Diagnostics',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Companion diagnostics',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'The master collaboration agreement enables the Parties to collaborate in the development and commercialization of AmoyDx assays that may cover any type of indication or biomarker for companion diagnostic (CDx) use with AstraZeneca medicines globally.',
    'FW Link1':
      'AmoyDx Enters into Master Collaboration Agreement with AstraZeneca for Multiple Companion Diagnostics Programs in China, EU and Japan;;https://firstwordpharma.com/story/5550409/'
  },
  {
    'Date Announced': '18-Apr-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Biocytogen',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'CtM Bio',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody drugs, TCR-mimic, T cell engagers',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Biocytogen Pharmaceuticals (Beijing) Co., Ltd. (hereafter referred to as "Biocytogen") announced a collaboration agreement with CtM Biotech (Shanghai) Co., Ltd (hereafter referred to as "CtM Bio") to jointly develop innovative antibody drugs against intracellular tumor-associated antigens. Under this agreement, Biocytogen will be responsible for screening fully human antibodies against specific targets by leveraging the company\'s proprietary TCR-mimic antibody development platform. CtM Bio will use their proprietary T cell engager platform to co-develop T cell engagers targeting intracellular tumor-associated antigens.',
    'FW Link1':
      'Biocytogen Enters Agreement With CtM Bio to Co-develop TCR-Mimic Antibody-Based Multi-Specific T cell Engagers;;https://firstwordpharma.com/story/5550577/'
  },
  {
    'Date Announced': '19-Apr-2022',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'Mission Bio',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'S2 Genomics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Solid tumour sample preparation, single-cell DNA analysis',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      'Mission Bio, Inc., the pioneer in high-throughput single-cell DNA and multi-omics analysis, announced a co-marketing partnership with S2 Genomics, Inc., a leading developer of laboratory automation solutions for processing solid tissues for single-cell applications, to offer a single streamlined workflow for solid tumor applications from sample prep through analysis.',
    'FW Link1':
      'Mission Bio Partners with S2 Genomics to Offer Robust Solid Tumor Sample Prep for Single-Cell DNA Analysis;;https://www.firstwordhealthtech.com/story/5551789/'
  },
  {
    'Date Announced': '19-Apr-2022',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Regeneron',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Checkmate Pharmaceuticals',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Total Deal Value ($m)': '250',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immuno-oncology',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'vidutolimod',
    'Indication(s)':
      'Melanoma, non-melanoma skin cancers, head and neck cancer',
    'Target(s)': 'TLR9',
    'Deal Structure':
      'Regeneron Pharmaceuticals and Checkmate Pharmaceuticals announced a definitive agreement for the acquisition of Checkmate by Regeneron at an all-cash price of $10.50 per share of Checkmate common stock. The proposed acquisition values Checkmate at a total equity value of approximately $250 million.',
    'FW Link1':
      'Regeneron to buy Checkmate as part of immuno-oncology gambit;;https://firstwordpharma.com/story/5551433/'
  },
  {
    'Date Announced': '18-Apr-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'GeneQuantum',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Aimed Bio',
    'Country (Target Company/Partner)': 'Korea, South',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody drug conjugate',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Brain tumours',
    'Deal Structure':
      'GeneQuantum Healthcare (Suzhou) Co., Ltd., a company specializing in site-specific bioconjugation for next generation biotherapeutics, has signed an agreement with a Korean biotech company, Aimed Bio Inc., to co-develop a First-in-Class therapeutic antibody drug conjugate (ADC). Aimed Bio, a spin-off company from Samsung Medical Center, is focusing on developing innovative antibody-centric drugs for brain diseases with few treatment options. Additional details were not disclosed.',
    'FW Link1':
      'GeneQuantum and AIMEDBIO Collaborate on a First-in-Class Antibody -Drug-Conjugate;;https://firstwordpharma.com/story/5551019/'
  },
  {
    'Date Announced': '19-Apr-2022',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'Roche',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Teva',
    'Country (Target Company/Partner)': 'Israel',
    'Region (Target Company/Partner)': 'Middle East',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Chemotherapy',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Treanda',
    'Indication(s)': "Inert B-cell non-Hodgkin's lymphoma",
    'Deal Structure':
      'Roche Pharmaceuticals China has entered into an agreement with Teva Pharmaceuticals to further expand the availability of Treanda in China. The companies said the collaboration will provide a foundation for a combined medication of the drug and two by Roche to create better survival benefits for Chinese lymphoma patients.',
    'FW Link1':
      'Roche Pharma China partners with Teva Pharmaceuticals to improve lymphoma treatments;;https://firstwordpharma.com/story/5551525/'
  },
  {
    'Date Announced': '25-Apr-2022',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'Eli Lilly',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'NeoGenomics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Genetic testing',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'NeoTYPE DNA and RNA Assay',
    'Indication(s)': 'Metastatic non-small-cell lung cancer',
    'Deal Structure':
      'NeoGenomics announced that they are partnering with Eli Lilly and Company on a sponsored testing program for eligible patients with metastatic non-small cell lung cancer (NSCLC) at no cost. The Lilly Lung Cancer Sponsored Testing Program will utilize the NeoTYPE DNA and RNA Assay to provide eligible metastatic NSCLC patients with enhanced access to a targeted genomic test offering that can help physicians and patients make informed treatment decisions.',
    'FW Link1':
      'NeoGenomics Inc. Announces Partnership with Lilly for Lung Cancer Sponsored Testing Program Utilizing the NeoTYPE DNA and RNA Assay;;https://www.firstwordhealthtech.com/story/5555501/'
  },
  {
    'Date Announced': '25-Apr-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Sinorda Biomedicine',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Porton Advanced',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell and gene therapy',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'SND002',
    'Indication(s)': 'Solid tumours, colorectal cancer',
    'Deal Structure':
      'Under the agreement, Porton Advanced will provide services to accelerate the development, production and registration of breakthrough cell therapies currently being developed by Sinorda Biomedicine, including its T-cell immunotherapy product for colorectal cancer, SND002 (Sentinel lymph node T cell) and other pipelines. Porton Advanced will also work with Sinorda Biomedicine to support its cell and gene therapeutic R&D and production.',
    'FW Link1':
      'Porton Advanced and Sinorda Biomedicine Enter Strategic Collaboration to Accelerate Cell Therapy Development for Solid Tumors;;https://firstwordpharma.com/story/5555695/'
  },
  {
    'Date Announced': '25-Apr-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Turnstone Biologics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'University of Montreal Hospital Research Centre',
    'Country (Target Company/Partner)': 'Canada',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Tumour-infiltrating lymphocyte (TIL) therapy',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      "The goal of the collaboration is to identify genome alterations and tumor-specific antigens from different tumor types and to further streamline the process for identification, direct selection and expansion of Turnstone's tumor-reactive TILs, with the potential to expand this work into future clinical trials at CRCHUM.",
    'FW Link1':
      'Turnstone Biologics Announces TIL Therapy Research Collaboration with the University of Montreal Hospital Research Centre;;https://firstwordpharma.com/story/5555455/'
  },
  {
    'Date Announced': '25-Apr-2022',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Catalent',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'ERYtech Pharma',
    'Country (Target Company/Partner)': 'France',
    'Region (Target Company/Partner)': 'Europe',
    'Total Deal Value ($m)': '45',
    'Geographic Remit of Deal': 'United States',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy manufacturing',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'Graspa',
    'Indication(s)': 'Acute lymphoblastic leukaemia, pancreatic cancer',
    'Deal Structure':
      "Under the terms of an asset purchase agreement between ERYTECH and Catalent (the \"APA\"), Catalent agreed to acquire ERYTECH's state-of-the-art commercial-scale cell therapy manufacturing facility in Princeton, New Jersey, for a total consideration of $44.5 million. ERYTECH's current staff at the site of approximately 40 people will be offered Catalent's employment. The parties will also enter into a long-term supply agreement, under which Catalent will manufacture ERYTECH's lead product candidate eryaspase (GRASPA) for clinical and commercial supply in the United States. Catalent will also offer their expertise in late-stage and commercial manufacturing of advanced therapy medicinal products with respect to product characterization, commercial production, regulatory inspections, and approvals.",
    'FW Link1':
      'ERYTECH Sells U.S. Manufacturing Facility and Enters Long-Term Supply Agreement with Catalent;;https://firstwordpharma.com/story/5555065/'
  },
  {
    'Date Announced': '25-Apr-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'BioVaxys',
    'Country (Acquiring Company/Partner)': 'Canada',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Deaconess Research Institute',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Autologous haptenised tumour cell vaccine',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'BVX-0918',
    'Indication(s)': 'Late-stage ovarian cancer',
    'Deal Structure':
      'BioVaxys Technology Corp. announced that it has entered into an agreement with the Deaconess Research Institute ("DRI") to supply BioVaxys with surgically debulked tumors from Stage III/Stage IV ovarian cancer patients undergoing treatment at Deaconess Health System ("Deaconess"). DRI, based in Evansville, Indiana, is the clinical studies arm of Deaconess, a premier regional provider of health care services in the United States. Access to ovarian cancer tumor cells is a critical step enabling BioVaxys to validate the manufacturing process for BVX-0918, the Company\'s autologous haptenized tumor cell vaccine for late-stage ovarian cancer.',
    'FW Link1':
      'BioVaxys Enters Critical Tumor Cell Supply Agreement with Deaconess Research Institute for BVX-0918 Bioproduction;;https://firstwordpharma.com/story/5555913/'
  },
  {
    'Date Announced': '25-Apr-2022',
    'Deal type': 'Product Acquisition',
    'Acquiring Company/Partner': 'AI Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'EntreChem',
    'Country (Target Company/Partner)': 'Spain',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Targeted cancer therapy',
    'Lead Asset(s)': 'EC-8042 (AIT-102)',
    'Indication(s)':
      'Rare paediatric and other cancers, rhabdoid tumours, Ewing sarcoma',
    'Target(s)': 'SWI/SNF',
    'Deal Structure':
      "AI Therapeutics, Inc., an AI driven clinical-stage biopharmaceutical company developing novel therapeutics for rare diseases, announced the acquisition of EntreChem, S.L.'s investigational drug candidate EC-8042 (redesignated AIT-102), a novel targeted cancer therapy in development for rare pediatric and other cancers. Financial terms of the transaction were not disclosed.",
    'FW Link1':
      'AI Therapeutics Announces the Acquisition of Novel Cancer Drug Candidate EC-8042 (AIT-102);;https://firstwordpharma.com/story/5555901/'
  },
  {
    'Date Announced': '25-Apr-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Portage Biotech',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'National Cancer Institute, Stimunity',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'STING agonists, anti-RAGE agents, cancer vaccines',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Cancer',
    'Target(s)': 'STING, RAGE',
    'Deal Structure':
      "Under terms of the agreement, Portage, Stimunity and the NCI will collaborate with the NCI's Center for Cancer Research (CCR) to advance preclinical and potential clinical development of STING agonists and anti-Receptor for Advanced Glycation End products (RAGE) agents, for possible synergy individually or together to enhance the efficacy of cancer vaccines developed in the NCI CCR Vaccine Branch.",
    'FW Link1':
      'Portage Biotech Enters Cooperative Research and Development Agreement with U.S. National Cancer Institute and Stimunity;;https://firstwordpharma.com/story/5555699/'
  },
  {
    'Date Announced': '26-Apr-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'NexImmune',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Columbia University Irving Medical Center',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Adoptive cell therapy, patient-derived T cells',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'HPV-associated cancers',
    'Deal Structure':
      "The research will focus on the use of NexImmune's adoptive cell therapy, AIM ACT, in Columbia's patient-derived organoid (PDO) models of HPV-associated cancers, including head and neck squamous cell carcinoma.",
    'FW Link1':
      'NexImmune Announces Preclinical Research Collaboration with Columbia University Irving Medical Center’s Herbert Irving Comprehensive Cancer Center;;https://firstwordpharma.com/story/5556625/'
  },
  {
    'Date Announced': '26-Apr-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Evaxion Biotech',
    'Country (Acquiring Company/Partner)': 'Denmark',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Personalis',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Personalised cancer immunotherapy',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'EVX-01',
    'Indication(s)': 'Metastatic melanoma',
    'Deal Structure':
      "Evaxion will deploy Personalis' ImmunoID NeXT Platform in a Phase 2b trial to evaluate the efficacy and safety of Evaxion's personalized cancer immunotherapy EVX-01. The study will combine EVX-01, a neoepitope-targeting immunotherapy based on Evaxion's proprietary PIONEER AI technology, with KEYTRUDA (pembrolizumab) for the treatment of patients with metastatic melanoma.",
    'FW Link1':
      'Evaxion to Utilize Personalis’ ImmunoID NeXT Platform to Evaluate Efficacy of Combination Therapy for Late-Stage Melanoma;;https://firstwordpharma.com/story/5556741/'
  },
  {
    'Date Announced': '26-Apr-2022',
    'Deal type': 'Terminated',
    'Acquiring Company/Partner': 'Amgen',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Molecular Partners',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Upfront Value ($m)': '50',
    'Total Deal Value ($m)': '547',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Bispecific DARPin',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'MP0310',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'FAP, 4-1BB',
    'Deal Structure':
      'Amgen has decided to hand back global rights for MP0310 to Molecular Partners following a strategic review of its pipeline. Amgen paid $50 million upfront for exclusive rights to the asset when it struck its partnership with Molecular Partners back in 2018. As part of the deal, Molecular Partners was also eligible to receive up to $497 million in development, regulatory and commercial milestones, as well as double-digit tiered royalties up to the high teens.',
    'FW Link1':
      'Amgen returns DARPin drug rights to Molecular Partners;;https://firstwordpharma.com/story/5557103/'
  },
  {
    'Date Announced': '27-Apr-2022',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Caladrius Biosciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'CEND Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Targeted tissue penetration, drug delivery, cyclic peptide, cell therapy',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'CEND-1',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      'Caladrius Biosciences and Cend Therapeutics have entered into a definitive merger agreement under which Cend will merge with a wholly owned subsidiary of Caladrius in an all-stock approximate "merger of equals" transaction unanimously approved by the Boards of Directors of each company. Following closing, the combined company will be renamed Lisata Therapeutics, Inc. ("Lisata") and will trade on the Nasdaq under the ticker symbol "LSTA".',
    'FW Link1':
      'Caladrius Biosciences and Cend Therapeutics Announce Definitive Merger Agreement;;https://firstwordpharma.com/story/5557371/'
  },
  {
    'Date Announced': '18-Apr-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Ankyra Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'National Cancer Institute',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immunotherapy',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'ANK-101',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Ankyra Therapeutics has established a formal Cooperative Research and Development Agreement with the National Cancer Institute (NCI) to pursue basic, translational and clinical research using Ankyra Therapeutics’ emerging anchored immunotherapy pipeline in collaboration with NCI’s Center for Cancer Research (CCR).',
    'FW Link1':
      'Ankyra Therapeutics Announces Cooperative Research and Development Agreement with the National Cancer Institute;;https://firstwordpharma.com/story/5550283/'
  },
  {
    'Date Announced': '27-Apr-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Xenetic Biosciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'CLS Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '1',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'DNase based oncology platform, DNases',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'Neutrophil extracellular traps (NETs)',
    'Deal Structure':
      'Under the terms of the agreements, Xenetic has an exclusive license to CLS\' intellectual property, for uses of DNases in cancer, including systemic co-administration of DNases along with standard therapies, including chemotherapy, radiation and checkpoint inhibitors, or along with conventional chimeric antigen receptor (CAR) T therapies. In addition, the licenses cover "DNase-armored" CAR T therapies in which novel CAR T products are engineered to secrete DNases into the tumor microenvironment to potentially improve T-cell infiltration, activity and persistence. As part of the agreements, Xenetic will make an upfront payment of $500,000 in cash and issue 875,000 shares of common stock, and will make future payments based on the achievement of certain clinical and regulatory milestones of up to $13 million per program, as well as issue up to an additional 950,000 shares of common stock based on the achievement of certain milestones. Additionally, Xenetic will pay tiered royalty payments ranging from mid-single to low-double digits on any potential future sales, as well as a percentage share of certain consideration received by Xenetic from sublicensees.',
    'FW Link1':
      'Xenetic Biosciences Expands Oncology Pipeline with In-Licensing of DNase Based Oncology Platform Comprising Multiple Therapeutic Modalities;;https://firstwordpharma.com/story/5557397/',
    'FW Link2':
      'Signing of patent assignment announced October 11, 2022 https://firstwordpharma.com/story/5662459/'
  },
  {
    'Date Announced': '28-Apr-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'ADC Therapeutics',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'OWKIN',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Artificial intelligence, machine learning, biomarkers, antibody drug conjugates',
    'Indication(s)': 'Haematologic cancers',
    'Deal Structure':
      'Artificial intelligence (AI) startup Owkin is working with biotech company ADC Therapeutics to develop AI models to improve the treatment of hematologic cancers. The partnership will focus on using machine learning to identify biomarkers that could predict patient outcomes. The collaboration will enable researchers at ADC Therapeutics to develop targeted therapies that offer patients maximum benefit.',
    'FW Link1':
      'Owkin and ADC Therapeutics to build AI models to improve hematologic cancer treatment;;https://www.firstwordhealthtech.com/story/5559003/'
  },
  {
    'Date Announced': '28-Apr-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'KYAN Therapeutics',
    'Country (Acquiring Company/Partner)': 'Singapore',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Aesculape CRO',
    'Country (Target Company/Partner)': 'Singapore',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Drug development optimisation services',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'KYAN Therapeutics, Inc. (KYAN) and Aesculape CRO (Aesculape) announced plans to offer drug development optimization services for Pharmaceutical and Biotech companies.',
    'FW Link1':
      'KYAN Therapeutics and Aesculape CRO Partner to Offer Drug Development Biopharma Services;;https://firstwordpharma.com/story/5558443/'
  },
  {
    'Date Announced': '2-May-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Ikena Oncology',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': "Vall d'Hebron Institute of Oncology",
    'Country (Target Company/Partner)': 'Spain',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Tumour and biomarker-specific data, patient data',
    'Indication(s)': 'Cancer',
    'Target(s)': 'Hippo and RAS pathways',
    'Deal Structure':
      "Ikena Oncology and the Vall d'Hebron Institute of Oncology (VHIO) announced an alliance to enable generation and exploration of tumor and biomarker-specific data derived from specific patient populations. The resulting research from this partnership will inform the further development of novel cancer therapies, including the ongoing programs in the Hippo and RAS pathways being developed by Ikena.",
    'FW Link1':
      'Ikena Oncology and Vall d’Hebron Institute of Oncology Announce Research & Development Collaboration;;https://firstwordpharma.com/story/5561121/'
  },
  {
    'Date Announced': '2-May-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Gilead Sciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Dragonfly Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '300',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'NK cell engagers-based immunotherapy',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'DF7001',
    'Indication(s)': 'Solid tumours',
    'Target(s)': '5T4',
    'Deal Structure':
      "Gilead Sciences has inked an agreement to develop a number of Dragonfly Therapeutics' NK cell engager-based immunotherapies for oncology and inflammation indications. The deal gives Gilead an exclusive global license to Dragonfly's preclinical immunotherapy asset for solid tumours DF7001, as well as opt-in rights to other NK cell engager programmes using Dragonfly's TriNKET platform. As part of the agreement, Gilead will make a $300-million upfront payment to Dragonfly, which is also eligible for additional opt-in fees plus performance-based development, regulatory and commercial milestones. Dragonfly will be eligible to receive royalties of up to 20% on global sales as well.",
    'FW Link1':
      'Gilead puts up $300 million to advance NK cell engagers with Dragonfly;;https://firstwordpharma.com/story/5560779/'
  },
  {
    'Date Announced': '2-May-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Harpoon Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Roche',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Tri-specific T cell activating construct, immuno-oncology combination',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'HPN328, Tecentriq',
    'Indication(s)': 'Solid tumours, small-cell lung cancer',
    'Target(s)': 'DLL3, PD-L1',
    'Deal Structure':
      'Under this agreement, Harpoon is the sponsor of the anticipated clinical trials, and Roche will supply atezolizumab.',
    'FW Link1':
      'Harpoon Therapeutics and Roche to Collaborate on Clinical Trials to Study Novel Immuno-Oncology Combination for Small Cell Lung Cancer;;https://firstwordpharma.com/story/5560791/'
  },
  {
    'Date Announced': '3-May-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Massive Bio',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Amber Specialty Pharmacy',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'United States',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Artificial intelligence, digitally enabled oncology clinical trial recruitment',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Through this partnership, Amber Specialty Pharmacy will seamlessly connect patients in need for clinical trials with Massive Bio's matching and concierge enrollment services and support cancer patients and providers throughout the country.",
    'FW Link1':
      'Massive Bio and Amber Specialty Pharmacy Announce Nationwide Partnership to Revolutionize Digitally Enabled Oncology Clinical Trial Recruitment through AI Technology Platform;;https://www.firstwordhealthtech.com/story/5562509/'
  },
  {
    'Date Announced': '3-May-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'medac',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Byondis',
    'Country (Target Company/Partner)': 'Netherlands',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Anti-HER2 antibody-drug conjugate, targeted chemotherapy',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'trastuzumab duocarmazine (SYD985)',
    'Indication(s)':
      'HER2-expressing cancers, breast cancer, endometrial cancer',
    'Target(s)': 'HER2',
    'Deal Structure':
      'Under the terms of the agreement, medac receives an exclusive license to commercialize SYD985 in the EU, the UK and further European countries, in all approved indications. Byondis will receive an undisclosed upfront payment and sales royalties. Byondis will also be eligible for payments upon achievement of certain development and sales milestones.',
    'FW Link1':
      'Byondis and medac Enter Into License and Collaboration and Supply Agreements for Anti-HER2 ADC Trastuzumab Duocarmazine (SYD985);;https://firstwordpharma.com/story/5562035/'
  },
  {
    'Date Announced': '3-May-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Hansoh Pharmaceutical',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'NiKang Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '15',
    'Total Deal Value ($m)': '218',
    'Geographic Remit of Deal': 'Greater China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecule',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'NKT2152',
    'Indication(s)': 'Cancer, clear cell renal cell carcinoma',
    'Target(s)': 'HIF2α',
    'Deal Structure':
      'Under the terms of the agreement, NiKang will receive an upfront cash payment of $15 million and will be eligible to receive up to $203 million in potential development, regulatory and sales-based milestone payments, and tiered royalties. Hansoh will be responsible for all the development costs for NKT2152 in Greater China and will receive the exclusive rights to develop and commercialize NKT2152 in the region.',
    'FW Link1':
      'NiKang Therapeutics and Hansoh Pharma Announce Strategic Collaboration and License Agreement for NKT2152 in Greater China;;https://firstwordpharma.com/story/5562153/'
  },
  {
    'Date Announced': '3-May-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'NeuCen',
    'Country (Acquiring Company/Partner)': 'Taiwan',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'BioKey, ABVC BioPharma',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Total Deal Value ($m)': '3',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Clinical services, local nasal immunotherapy, novel drug entity',
    'Lead Asset(s)': 'CEN501, NEU001',
    'Indication(s)': 'Nasopharyngeal carcinoma',
    'Deal Structure':
      'ABVC Biopharma announced that BioKey, a wholly-owned subsidiary of the company based in Fremont, California, has entered into a $3.0 million clinical services contract with NeuCen BioMed Co. Ltd. to guide two NeuCen drug products, CEN501 and NEU001, through completion of Phase II clinical studies under U.S. FDA IND regulatory requirements. Under the terms of the contract, BioKey will receive payments totaling $3.0 million over a 3-year period with each payment amount to be determined by certain regulatory milestones obtained in connection with CEN501.',
    'FW Link1':
      'ABVC BioPharma Enters Into $3.0 Million Clinical Services Contract with NeuCen BioMed;;https://firstwordpharma.com/story/5562337/'
  },
  {
    'Date Announced': '4-May-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Juniper Biologics',
    'Country (Acquiring Company/Partner)': 'Singapore',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Helsinn',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal':
      'Algeria, Angola, Australia, Bahrain, Brunei, Cambodia, Egypt, India, Indonesia, Ivory Coast, Jordan, Kenya, Kuwait, Laos, Lebanon, Libya, Malaysia, Mauritius, Morocco, Myanmar, Nepal, New Zealand, Nigeria, Oman, Pakistan, Philippines, Qatar, Saudi Arabia, Seychelles, Singapore, South Africa, South Korea, Taiwan, Tanzania, Thailand, Tunisia, Sri Lanka, United Arab Emirates, Vietnam, Zimbabwe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'FGFR-selective tyrosine kinase inhibitor',
    'Lead Asset(s)': 'infigratinib',
    'Indication(s)': 'Cholangiocarcinoma',
    'Target(s)': 'FGFR 1, 2, and 3',
    'Deal Structure':
      'Juniper Biologics Pte Ltd and Helsinn Group announced the signing of an exclusive license agreement to develop and commercialise infigratinib (INN) in Australia, New Zealand, Southeast Asia and certain markets in the Middle East and Africa (see full list below) for the treatment of adults with previously treated, unresectable locally advanced or metastatic cholangiocarcinoma (CCA) with a fibroblast growth factor receptor 2 (FGFR2) fusion or other rearrangement.',
    'FW Link1':
      'Juniper Biologics signs exclusive license agreement with Helsinn for infigratinib (INN) for the emerging markets*;;https://firstwordpharma.com/story/5563051/'
  },
  {
    'Date Announced': '5-May-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Mosaic ImmunoEngineering',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Case Western Reserve University',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Plant viruses, cowpea mosaic virus-based immunotherapy',
    'Lead Asset(s)': 'MIE-101',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "The agreement grants Mosaic exclusive worldwide rights to develop and commercialize oncology treatments based on cowpea mosaic virus (CPMV), the core technology behind the Company's lead human cancer immunotherapy candidate, MIE-101, and other applications under the platform technology. Under the license agreement, Mosaic will make development and regulatory milestone payments to Case Western Reserve University and also pay tiered royalties on net sales of licensed products.",
    'FW Link1':
      'Mosaic ImmunoEngineering Announces Completion of Exclusive Technology License Agreement with Case Western Reserve University;;https://firstwordpharma.com/story/5564091/'
  },
  {
    'Date Announced': '5-May-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'AUM Biosciences',
    'Country (Acquiring Company/Partner)': 'Singapore',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Merck & Co.',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Translation inhibitor, anti-PD-1 therapy',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'AUM001, Keytruda',
    'Indication(s)': 'Microsatellite stable colorectal cancer',
    'Target(s)': 'MNK 1/2, PD-1',
    'Deal Structure':
      "AUM Biosciences has entered into a clinical trial collaboration and supply agreement with MSD, a tradename of Merck & Co., Inc., Rahway, NJ, USA, to evaluate AUM001 in combination with MSD's anti-PD-1 therapy, KEYTRUDA (pembrolizumab), in a global Phase 2 trial in patients with microsatellite stable colorectal cancer (MSS CRC).",
    'FW Link1':
      'AUM Biosciences Announces Clinical Trial Collaboration and Supply Agreement with MSD to Evaluate AUM001 in Combination with KEYTRUDA (pembrolizumab) in Colorectal Cancer;;https://firstwordpharma.com/story/5564483/'
  },
  {
    'Date Announced': '5-May-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Turning Point Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'LaNova Medicines',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '25',
    'Geographic Remit of Deal':
      'United States, ROW excl. Greater China, South Korea',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Anti-Claudin18.2 antibody drug conjugate',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'LM-302 (TPX-4589)',
    'Indication(s)': 'Gastrointestinal cancers',
    'Target(s)': 'Claudin18.2',
    'Deal Structure':
      'Turning Point Therapeutics has entered into an exclusive license agreement with LaNova Medicines Limited (LaNova) to develop and commercialize LM-302, a novel antibody drug conjugate (ADC) targeting Claudin18.2, in the U.S. and rest of the world, excluding Greater China and South Korea. Under the terms of the licensing agreement, LaNova will receive an upfront payment of $25 million and will be eligible to receive up to an additional $195 million in development and regulatory milestone payments; in addition, LaNova is eligible to receive commercial sales milestones, and tiered royalties ranging from mid-single digit to mid-teens percentages on net sales (subject to customary deductions). As part of the agreement, both parties agreed to potentially broaden the partnership by collaborating on up to three additional ADC programs.',
    'FW Link1':
      'Turning Point Therapeutics Announces Pipeline Expansion, Licensing of TPX-4589 (LM-302), A Clinical Stage Anti-Claudin18.2 Antibody Drug Conjugate for Gastrointestinal Cancers, From Lanova Medicines;;https://firstwordpharma.com/story/5564347/'
  },
  {
    'Date Announced': '5-May-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Genmab',
    'Country (Acquiring Company/Partner)': 'Denmark',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'ImaginAb',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'CD8 ImmunoPET imaging agent, immunotherapy',
    'Lead Asset(s)': 'zirconium Zr 89 crefmirlimab berdoxam',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'ImaginAb has signed a new multi-year, non-exclusive supply agreement with Genmab A/S. Under the terms of the agreement, ImaginAb will provide a non-exclusive, worldwide license to Genmab A/S to use its investigational CD8 ImmunoPET imaging technology (zirconium Zr 89 crefmirlimab berdoxam) in clinical trials.',
    'FW Link1':
      'ImaginAb Announces New Supply Agreement to Supply Genmab A/S with its investigational CD8 ImmunoPET agent;;https://firstwordpharma.com/story/5564043/'
  },
  {
    'Date Announced': '9-May-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Astellas',
    'Country (Acquiring Company/Partner)': 'Japan',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Twist Bioscience',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antagonist antibodies, immunotherapy',
    'Indication(s)': 'Cancer',
    'Target(s)':
      'Undisclosed checkpoint inhibitor pathway in the tumour microenvironment',
    'Deal Structure':
      'Under the terms of the agreement, the companies will jointly conduct research activities to identify and optimize proprietary Twist antagonist antibodies, targeting an undisclosed checkpoint inhibitor pathway in the tumor microenvironment (TME), as potential therapeutic development candidates. Astellas will have the exclusive option to license any development candidates generated as part of the collaboration. Under the terms of the agreement, Twist will receive an upfront payment from Astellas as well as an additional payment upon the exercise of the licensing option. Twist will receive payments connected to success-based clinical milestones as well as royalty payments on product sales for each licensed product. Astellas will be responsible for the development, manufacturing and commercialization of any licensed products.',
    'FW Link1':
      'Twist Bioscience Enters into Research, Exclusive Option and License Agreement with Astellas for Antibodies to Reduce Tumor Microenvironment-Mediated Immunosuppression;;https://firstwordpharma.com/story/5566789/'
  },
  {
    'Date Announced': '9-May-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Accord Healthcare',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Myovant Sciences',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Upfront Value ($m)': '50',
    'Total Deal Value ($m)': '141',
    'Geographic Remit of Deal': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'GnRH receptor antagonist',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Orgovyx',
    'Indication(s)': 'Advanced hormone-sensitive prostate cancer',
    'Target(s)': 'GnRH receptor',
    'Deal Structure':
      'Myovant Sciences and Accord Healthcare, Ltd. (Accord) have entered into an exclusive license agreement for Accord to commercialize relugolix for the treatment of advanced hormone-sensitive prostate cancer under the trade name ORGOVYX (relugolix, 120 mg) in the European Economic Area, United Kingdom, Switzerland and Turkey, with the right of first negotiation if Myovant decides to enter into licensing arrangements in countries in the Middle East, Africa and India. Under the terms of the agreement, Myovant will receive an upfront payment of $50 million and is eligible to receive commercial launch, sales-based and other milestones totaling up to $90.5 million. In addition, Myovant is eligible to receive tiered royalties from the high-teens to mid-twenties on net sales. Myovant will continue to lead the global development of relugolix and provide initial product supply to Accord. Accord will be responsible for certain local clinical development, all commercialization for its territories, and has the option to manufacture relugolix in the future.',
    'FW Link1':
      'Myovant Sciences and Accord Healthcare, Ltd. Enter into Exclusive License Agreement to Commercialize ORGOVYX for Advanced Hormone-Sensitive Prostate Cancer in Europe;;https://firstwordpharma.com/story/5566471/'
  },
  {
    'Date Announced': '9-May-2022',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Opko Health',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'ModeX Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Total Deal Value ($m)': '300',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Multi-specific antibodies, immunotherapy',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'OPKO Health announced the acquisition of ModeX Therapeutics, Inc. a privately held biotechnology company focused on developing innovative multi-specific immune therapies for cancer and infectious diseases. OPKO acquired ModeX for $300 million in OPKO common stock.',
    'FW Link1':
      'OPKO Health Acquires ModeX Therapeutics, Gains Proprietary Immunotherapy Technology with a Focus on Oncology and Infectious Diseases;;https://firstwordpharma.com/story/5566623/'
  },
  {
    'Date Announced': '9-May-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Aptahem',
    'Country (Acquiring Company/Partner)': 'Sweden',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner':
      'Örebro University, Attgeno, BioReperia, Buzzard Pharmaceuticals, Lipum, Thermo Fisher Scientific, Toleranzia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Aptamer-based therapies, drug discovery',
    'Lead Asset Stage/Phase': 'Discovery',
    'Lead Asset(s)': 'Apta-1',
    'Indication(s)': 'Breast cancer',
    'Deal Structure':
      "The newly established consortium aims to evaluate new treatments to reduce inflammation in cardiovascular diseases and breast cancer. The joint research project, titled Drug discovery targeting inflammation - novel therapeutic aspects on vascular inflammation, thrombosis and breast cancer, has obtained financing from the Swedish KK-foundation and will run over a four-year period with start in late 2022. Aptahem will primarily provide the project with its drug candidate Apta-1 and contribute with expertise competence with Dr. Luiza Jedlina as scientific advisor on aptamer-based therapies and specifically of Apta-1's inhibition of inflammation and thrombosis.",
    'FW Link1':
      'Aptahem enters new consortium financed by the KK Foundation;;https://firstwordpharma.com/story/5567059/'
  },
  {
    'Date Announced': '9-May-2022',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'Verity Pharmaceuticals',
    'Country (Acquiring Company/Partner)': 'Canada',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Cumberland Pharmaceuticals',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'United States',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Oncology support product, anti-emetic, transdermal system, 5-HT3 receptor antagonist',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Sancuso',
    'Indication(s)': 'Chemotherapy-induced nausea and vomiting',
    'Target(s)': '5-HT3 receptor',
    'Deal Structure':
      "In a co-promotion partnership with Cumberland Pharmaceuticals, Verity will feature Sancuso across the U.S. through its established oncology commercial organization and customer network. Under the terms of the agreement, Verity Pharmaceuticals, a specialty pharmaceutical company with an established commercial presence in the U.S., will promote Sancuso throughout most of the country through its national oncology sales organization. The two companies will share in the incremental contribution margin resulting from Verity Pharmaceuticals' efforts.",
    'FW Link1':
      'Cumberland Pharmaceuticals Announces Key Co-Promotion Agreement with Verity Pharmaceuticals to Expand Support for Sancuso;;https://firstwordpharma.com/story/5566729/'
  },
  {
    'Date Announced': '10-May-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Telix',
    'Country (Acquiring Company/Partner)': 'Australia',
    'Region (Acquiring Company/Partner)': 'Oceania',
    'Target Company/Partner': 'Eczacıbaşı-Monrol',
    'Country (Target Company/Partner)': 'Turkey',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Radiopharmaceutical',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'TLX250',
    'Indication(s)': 'Clear cell renal cell carcinoma',
    'Target(s)': 'CA9',
    'Deal Structure':
      'Monrol supplied Lutetium (Lu-177 n.c.a) to Telix Pharmaceuticals to manufacture clinical doses of TLX250 for STARLITE 2 Phase II study.',
    'FW Link1':
      'Monrol Is Partnering With Telix Pharmaceuticals, Supplying Lutetium nca Lu 177 for the Manufacturing of Clinical Doses for the Phase II STARLITE 2 Study At MSK and Manufacturing Clinical Doses for Telix’s Phase III ZIRCON Trial;;https://firstwordpharma.com/story/5567837/'
  },
  {
    'Date Announced': '10-May-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'AUM Biosciences',
    'Country (Acquiring Company/Partner)': 'Singapore',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Roche',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Translation inhibitor, anti-PD-L1 therapy',
    'Lead Asset(s)': 'AUM001, Tecentriq',
    'Indication(s)':
      'Solid tumours, non-small cell lung cancer, urothelial cancer',
    'Target(s)': 'MNK1/2, PD-L1',
    'Deal Structure':
      "AUM Biosciences has entered into a clinical trial collaboration and supply agreement with Roche to evaluate AUM001, the Company's novel, highly selective MNK1/2 inhibitor, in combination with atezolizumab (Tecentriq), Roche's anti-PD-L1 therapy, across multiple solid tumor indications, leading with Non-Small Cell Lung Cancer (NSCLC) and Urothelial Cancer (UC).",
    'FW Link1':
      'AUM Biosciences Enters into Collaboration with Roche for Clinical Development of AUM001 in Combination with anti-PD-L1 Therapy in Solid Tumor Indications;;https://firstwordpharma.com/story/5567993/'
  },
  {
    'Date Announced': '10-May-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Kaiku Health',
    'Country (Acquiring Company/Partner)': 'Finland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Roche',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Digital patient monitoring and management',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Under the latest agreement, Roche and Kaiku aim to deploy digital tools to provide real-time symptom management by patients and healthcare providers, and also to bring personalised cancer care to clinics and patients globally. Over the long-term, they are looking to develop and offer digital health solutions through a multi-partner ecosystem, aided by reimbursement of these digital tools in more countries in order to pave the way for accelerated adoption. The partnership also seeks to provide further clinical evidence on the impact of digital patient monitoring on clinical outcomes through evidence generation studies.',
    'FW Link1':
      'Roche deepens ties with Kaiku on digital patient monitoring;;https://www.firstwordhealthtech.com/story/5567909/'
  },
  {
    'Date Announced': '9-May-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Bixink Therapeutics',
    'Country (Acquiring Company/Partner)': 'Korea, South',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Blue Note Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'South Korea',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Prescription digital therapeutics',
    'Lead Asset(s)': 'attune, DreAMLand',
    'Indication(s)': 'Mental well-being in cancer patients',
    'Deal Structure':
      "The agreement includes attune and DreAMLand, Blue Note's lead PDTs designed to support the mental well-being of patients living with cancer. Under the terms of the agreement, Bixink will be responsible for translation, clinical studies, local regulatory approval, and commercialization of attune and DreAMLand in South Korea.",
    'FW Link1':
      'Blue Note Therapeutics and Bixink Enter Licensing Agreement for attune and DreAMLand, Prescription Digital Therapeutics for Mental Well-Being in Cancer Patients;;https://www.firstwordhealthtech.com/story/5567889/'
  },
  {
    'Date Announced': '11-May-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Targovax',
    'Country (Acquiring Company/Partner)': 'Norway',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Agenus',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Oncolytic immunotherapy, checkpoint inhibitors',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'ONCOS-102, balstilimab, botensilimab',
    'Indication(s)': 'Anti-PD1 refractory malignant melanoma',
    'Target(s)': 'PD-1, CTLA4',
    'Deal Structure':
      'Under the agreement, Targovax will be the sponsor and responsible for operational execution of the combination trial, and Agenus will provide drug supply and scientific support. Following demonstration of competitive clinical efficacy in the planned phase 2 study, the parties intend to extend the collaboration into a registrational development program.',
    'FW Link1':
      'Targovax announces clinical collaboration with Agenus for upcoming ONCOS-102 phase 2 melanoma trial;;https://firstwordpharma.com/story/5569677/'
  },
  {
    'Date Announced': '12-May-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'BMS',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'BridgeBio Pharma',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '90',
    'Total Deal Value ($m)': '905',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecule',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'BBP-398',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'SHP2',
    'Deal Structure':
      'Under the latest deal, Bristol Myers Squibb will make an upfront payment of $90 million to gain rights to develop and commercialise BBP-398, with BridgeBio also eligible to receive up to $815 million in milestones, plus tiered royalties in the low- to mid-teens. BridgeBio noted that it will retain the option to acquire higher royalties in the US in connection with funding a portion of development costs upon the initiation of registrational studies. Under the new agreement, BridgeBio will continue to lead its ongoing Phase I monotherapy and combination therapy trials, with Bristol Myers Squibb leading and funding all other development and commercial activities.',
    'FW Link1':
      "BMS gains full rights to BridgeBio's SHP2 inhibitor;;https://firstwordpharma.com/story/5570185/"
  },
  {
    'Date Announced': '12-May-2022',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'Lunaphore',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Indica Labs',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Spatial biology, digital pathology image analysis, artificial intelligence',
    'Indication(s)': 'Oncology',
    'Deal Structure':
      "Lunaphore, a Swiss life sciences company developing technology to enable spatial biology in every laboratory, and Indica Labs, a leading provider of computational pathology software and services, announced a partnership to co-market an end-to-end solution that combines Lunaphore's flagship COMET spatial biology platform and Indica's HALO and HALO AI digital pathology image analysis software.",
    'FW Link1':
      'Lunaphore and Indica Labs announce partnership to provide complete technology solution for spatial biology and image analysis;;https://www.firstwordhealthtech.com/story/5570719/'
  },
  {
    'Date Announced': '12-May-2022',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'Lannett',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Areva Pharmaceuticals',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'United States',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Injectable chemotherapeutic, generic drug',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Fludarabine Phosphate for injection USP',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Lannett Company, Inc. has entered into an agreement with Areva Pharmaceuticals to be the exclusive U.S. distributor of Fludarabine Phosphate for injection, USP, 50 mg/2mL, single dose vials. Under the agreement, Lannett will provide sales, marketing and distribution support for Fludarabine Phosphate, for which it will receive a share of the profits. Other financial terms were not disclosed.',
    'FW Link1':
      'Lannett Enters Agreement To Be Exclusive U.S. Distributor Of Fludarabine Phospate For Injection, USP;;https://firstwordpharma.com/story/5570187/'
  },
  {
    'Date Announced': '12-May-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Bio-Techne',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Nonagen',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostic panel, laboratory developed test',
    'Lead Asset(s)': 'Oncuria',
    'Indication(s)': 'Bladder cancer',
    'Deal Structure':
      "Bio-Techne Corporation and Nonagen Bioscience announced an agreement for R&D Systems, a Bio-Techne brand, to exclusively manufacture Nonagen Bioscience's Oncuria bladder cancer diagnostic panel using xMAP Luminex technology.",
    'FW Link1':
      "Bio-Techne and Nonagen Bioscience Announce Exclusive Supply Agreement for Nonagen's Oncuria Bladder Cancer Test;;https://www.firstwordhealthtech.com/story/5570315/"
  },
  {
    'Date Announced': '12-May-2022',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'Blackford',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Densitas',
    'Country (Target Company/Partner)': 'Canada',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial intelligence, imaging, screening',
    'Indication(s)': 'Breast cancer',
    'Deal Structure':
      "Densitas Inc., a global provider of A.I. technologies for digital mammography and breast screening announced an expansion of their partnership agreement with Blackford to integrate more of Densitas' solutions with the Blackford Platform. With this partnership expansion, Densitas solutions for breast cancer risk and image quality will be available through the Blackford Platform, adding to the existing breast density solution.",
    'FW Link1':
      'Densitas and Blackford Expand Partnership to Offer Breast Cancer Risk and Image Quality Solutions;;https://www.firstwordhealthtech.com/story/5570547/'
  },
  {
    'Date Announced': '12-May-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Taiho',
    'Country (Acquiring Company/Partner)': 'Japan',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Cullinan Oncology',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '275',
    'Total Deal Value ($m)': '405',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Tyrosine kinase inhibitor',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'CLN-081/TAS6417',
    'Indication(s)': 'Non-small-cell lung cancer',
    'Target(s)': 'EGFR',
    'Deal Structure':
      "Under the agreement, Taiho will acquire Cullinan Oncology's subsidiary, Cullinan Pearl, which has worldwide rights outside of Japan* to CLN-081/TAS6417, for an upfront payment to Cullinan Oncology of $275 million and up to an additional $130 million tied to EGFR exon20 non-small cell lung cancer (NSCLC) regulatory milestones. Cullinan Oncology will co-develop CLN-081/TAS6417 and will retain the option to co-commercialize CLN-081/TAS6417 in the United States together with Taiho Pharmaceutical through its U.S. subsidiary, Taiho Oncology, Inc. Taiho will commercialize CLN-081/TAS6417 in territories outside U.S. and China. Taiho and Cullinan Oncology will equally contribute to the future clinical development of CLN-081/TAS6417 in the U.S., with each receiving 50% of the profits from potential U.S. sales.",
    'FW Link1':
      'Cullinan Oncology and Taiho Pharmaceutical Announce Strategic Collaboration to Jointly Develop and Commercialize CLN-081/TAS6417 and Taiho’s Acquisition of Cullinan Pearl;;https://firstwordpharma.com/story/5570121/',
    'FW Link2':
      'Completion of agreement announced June 23, 2022;;https://firstwordpharma.com/story/5601193/'
  },
  {
    'Date Announced': '16-May-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Elicio Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Regeneron',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'KRAS-targeted cancer vaccine, PD-1 inhibitor',
    'Lead Asset(s)': 'ELI-002, Libtayo',
    'Indication(s)': 'KRAS-driven tumours',
    'Target(s)': 'KRAS, PD-1',
    'Deal Structure':
      'The study, which is expected to begin in 2023, will be conducted by Elicio Therapeutics. Each party will provide their respective agent for the trial.',
    'FW Link1':
      'Elicio Therapeutics Announces Clinical Supply Agreement with Regeneron to Evaluate ELI-002 in Combination with Libtayo (cemiplimab) in KRAS-Driven Tumors;;https://firstwordpharma.com/story/5573063/'
  },
  {
    'Date Announced': '16-May-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Athenex',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Merck & Co.',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Chemotherapy, oral administration, anti-PD-1 therapy',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'Oraxol (encequidar plus oral paclitaxel), Keytruda',
    'Indication(s)': 'Non-small-cell lung cancer',
    'Target(s)': 'P-glycoprotein, PD-1',
    'Deal Structure':
      "Athenex announced a clinical trial collaboration and supply agreement with Merck (known as MSD outside the US and Canada). The agreement applies to the expansion phase of the Phase 1 clinical trial evaluating Athenex's oral paclitaxel in combination with Merck's anti-PD-1 therapy KEYTRUDA (pembrolizumab) for certain NSCLC patients.",
    'FW Link1':
      'Athenex Announces Clinical Collaboration with Merck to Evaluate Oraxol plus KEYTRUDA (pembrolizumab) in Patients with Non-Small Cell Lung Cancer (NSCLC);;https://firstwordpharma.com/story/5572795/'
  },
  {
    'Date Announced': '16-May-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Merck & Co.',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Kelun Pharmaceutical',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '47',
    'Total Deal Value ($m)': '1,300',
    'Geographic Remit of Deal': 'Global excl. China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugate',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'SKB-264',
    'Indication(s)': 'Breast, non-small-cell lung cancer',
    'Target(s)': 'TROP-2',
    'Deal Structure':
      'Chinese drug developer Kelun Pharmaceutical said it has granted overseas development rights of an experimental oncology treatment to Merck & Co. in a deal potentially worth over $1.4 billion. Kelun will receive a non-refundable upfront payment of $47 million upon signing the contract, plus potential milestones of a maximum of $1.3 billion at different stages of the project. Once the treatment is launched, Kelun will receive a sales commission according to an agreed ratio.',
    'FW Link1':
      "China's Kelun Pharma Jumps on Licensing New Cancer Drug to Holland's Merck Sharp & Dohme;;https://firstwordpharma.com/story/5572801/"
  },
  {
    'Date Announced': '17-May-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Odimma',
    'Country (Acquiring Company/Partner)': 'France',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'ABL',
    'Country (Target Company/Partner)': 'France',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Personalised cancer immunotherapy, viral vector manufacturing',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'ODI-2001',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "ABL Europe (ABL) and Odimma Therapeutics (Odimma) have signed a development agreement. ABL will manufacture the viral component of Odimma's innovative and proprietary immunization platform ODI-2001. The GMP grade material produced by ABL will support clinical trials and early commercialization.",
    'FW Link1':
      'ABL And Odimma Therapeutics Join Forces In Personalized Cancer Immunotherapy;;https://firstwordpharma.com/story/5573851/'
  },
  {
    'Date Announced': '17-May-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Coeptis Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'University of Pittsburgh',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'CAR-T',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)':
      'Haematologic and solid tumours, breast cancer, ovarian cancer',
    'Deal Structure':
      'Coeptis Therapeutics announced entry into an exclusive option agreement with the University of Pittsburgh for the rights to three chimeric antigen receptor T cell (CAR-T) technologies that offer the potential to address a range of hematologic and solid tumors. Among the initial cancer indications under development are pre-clinical programs targeting breast cancer and ovarian cancer. Terms of the deal were not disclosed. Per the option agreement, Coeptis paid the University of Pittsburgh a non-refundable fee for the exclusive option to license the patent rights to each of the three technologies. Coeptis has until October 29, 2022, to exercise the options and pay the specified exercise considerations. The option agreement may be extended an additional six months, subject to the agreement of both parties.',
    'FW Link1':
      'Coeptis Therapeutics Enters into Exclusive Option Agreement with University of Pittsburgh for Rights to CAR-T Technologies Designed to Target Multiple Cancer Indications, Including Hematologic and Solid Tumors;;https://firstwordpharma.com/story/5573869/'
  },
  {
    'Date Announced': '17-May-2022',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'Intermountain Healthcare',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Grail',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'United States',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Multi-cancer early detection blood test',
    'Lead Asset(s)': 'Galleri',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Intermountain will offer the Galleri test to eligible health system patients at elevated risk for cancer, such as adults over 50 years old or with additional risk factors. In addition, the test will be available to eligible employees and their families who are enrolled in a company-sponsored health plan.',
    'FW Link1':
      'GRAIL and Intermountain Healthcare Expand Partnership to Offer Galleri Multi-Cancer Early Detection Blood Test to Eligible Patients in Utah;;https://www.firstwordhealthtech.com/story/5573961/'
  },
  {
    'Date Announced': '17-May-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Delfi Diagnostics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Memorial Sloan Kettering Cancer Center',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Liquid biopsy',
    'Indication(s)': 'Cancer, lung cancer',
    'Deal Structure':
      "The agreement creates a framework for Delfi and MSK researchers to work together on a range of projects studying Delfi's liquid biopsy platform, advancing their shared mission of developing high performing cancer testing.",
    'FW Link1':
      'Delfi Diagnostics Enters into Broad Research Agreement with Top U.S. Cancer Center;;https://www.firstwordhealthtech.com/story/5574143/'
  },
  {
    'Date Announced': '18-May-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Atonco',
    'Country (Acquiring Company/Partner)': 'France',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'IONETIX',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Radiopharmaceutical, alpha immunotherapy',
    'Indication(s)': 'Bladder cancer',
    'Deal Structure':
      'Ionetix through this agreement will produce astatine-211 and manufacture patient doses for clinical use, according to CGMP regulations. The agreement includes the supply of the radioisotope, astatine-211, the GMP manufacturing of the injectable doses and specifies the terms of the U.S. FDA agent agreement signed in August 2021.',
    'FW Link1':
      'IONETIX and Atonco Sign a Partnership Agreement to Fight Bladder Cancer;;https://firstwordpharma.com/story/5575163/'
  },
  {
    'Date Announced': '18-May-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'DelSiTech',
    'Country (Acquiring Company/Partner)': 'Finland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Anticancer Bioscience',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Silica-based long-acting controlled release treatments, drug delivery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'DelSiTech and Anticancer Bioscience announced a notable collaboration pertaining to the development of silica-based long-acting controlled release treatments in oncology.',
    'FW Link1':
      'DelSiTech and Anticancer Bioscience Announce a Collaboration Agreement for the Development of Long-acting Therapies in Oncology;;https://firstwordpharma.com/story/5574795/'
  },
  {
    'Date Announced': '19-May-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Myeloid Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Acuitas Therapeutics',
    'Country (Target Company/Partner)': 'Canada',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'mRNA-encoded CARs, lipid nanoparticles, LNP delivery technology',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'MT-301, MT-302',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "The companies will combine the power of Acuitas' LNP technology with Myeloid's in vivo mRNA cell programming technology to develop next-generation immunotherapies for cancer.  The initial in vivo programming technologies are based on Myeloid's proprietary CARs, that are designed to be specifically expressed by myeloid and other immune cells.",
    'FW Link1':
      'Myeloid Therapeutics Enters Into Agreement with Acuitas Therapeutics for Lipid Nanoparticle (LNP) System, Enabling the First-Ever Delivery of mRNA-Encoded CARs Directly to Humans;;https://firstwordpharma.com/story/5576157/'
  },
  {
    'Date Announced': '18-May-2022',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Inzen Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Cygnal Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecule drugs, genetic therapeutics',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Sonata Therapeutics was formed by combining two Flagship companies, Inzen Therapeutics and Cygnal Therapeutics, and builds on each company's pioneering work characterizing different inputs into the cellular microenvironment and their roles in causing disease, and developing therapeutic strategies based on these insights.",
    'FW Link1':
      'Flagship Pioneering Unveils Sonata Therapeutics to Harness Cellular Microenvironments for the Treatment of Serious Diseases;;https://firstwordpharma.com/story/5575647/'
  },
  {
    'Date Announced': '19-May-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'BioMoti',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Undisclosed',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Tumour-targeted treatment, CD95-coated long-acting paclitaxel-loaded microparticles, chemotherapy',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'BMT101',
    'Indication(s)': 'Ovarian cancer',
    'Deal Structure':
      "The collaboration agreement provides access to the partner company's expertise including its proprietary commercial manufacturing technology and an option for the global pharmaceutical company to licence BMT101 in specific territories.",
    'FW Link1':
      'BioMoti collaborates with global pharmaceutical company to develop ovarian cancer treatment and appoints John Beadle as Chairman;;https://firstwordpharma.com/story/5575799/'
  },
  {
    'Date Announced': '19-May-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'TransCode Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Massachusetts General Hospital',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Radiotheranostic technology',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Execution of an option agreement giving TransCode the right to negotiate an exclusive, worldwide, royalty-bearing license related to a radiotheranostic technology disclosed in patent application PCT/US2021/057912 entitled THERAPEUTIC, RADIOLABLED NANOPARTICLES AND METHODS OF USE THEREOF. Invented by TransCode Co-Founder and CTO, Dr. Zdravka Medarova, and her colleagues at Massachusetts General Hospital, the technology represents another potential advancement in the diagnosis and treatment of cancer.',
    'FW Link1':
      'TransCode Therapeutics Acquires Option for Radiotheranostic Technology;;https://firstwordpharma.com/story/5576211/'
  },
  {
    'Date Announced': '19-May-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Evotec',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Almirall',
    'Country (Target Company/Partner)': 'Spain',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Drug discovery, small molecules, artificial intelligence, machine learning',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Basal cell carcinoma',
    'Deal Structure':
      "The alliance will leverage Evotec's fully integrated multimodality platform and combine it with Almirall's leading expertise in Medical Dermatology. Both partners will contribute drug targets in the research process. Leveraging the Company's end-to-end integrated, AI/ML-driven EVOiR&D platform, Evotec will be responsible for drug discovery and pre-clinical development. Almirall will lead the clinical development and marketing. Under the agreement, Evotec receives an undisclosed upfront payment, research payments, as well as success-based milestones of potentially up to € 230 m per programme and royalties on net sales in the high single-digit percentage range.",
    'FW Link1':
      'Evotec and Almirall Enter into a Multi-Target Alliance in Medical Dermatology;;https://firstwordpharma.com/story/5575771/'
  },
  {
    'Date Announced': '19-May-2022',
    'Deal type': 'Terminated',
    'Acquiring Company/Partner': 'Bayer',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Atara Biotherapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '60',
    'Total Deal Value ($m)': '670',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Mesothelin-directed CAR T-cell therapies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'ATA2271',
    'Indication(s)':
      'Malignant pleural mesothelioma, non-small-cell lung cancer',
    'Target(s)': 'Mesothelin',
    'Deal Structure':
      'Upon termination of the agreement in September 2022, the rights and licenses granted by Atara to Bayer under the collaboration will be returned to Atara, and Atara will have full rights to continue the clinical development and future commercialization of its programs worldwide.',
    'FW Link1':
      'Bayer breaks off CAR-T cell therapy alliance with Atara;;https://firstwordpharma.com/story/5576533/'
  },
  {
    'Date Announced': '20-May-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Fosun Pharmaceutical',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'VerImmune',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Virus-inspired particle platform, tumour immunotherapy',
    'Lead Asset(s)': 'VERI-101',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Under the terms of the agreement, VerImmune has granted Fosun Pharma an exclusive license to VerImmune's lead AIR-ViP product candidate, VERI-101, with an option to license certain potential future AIR-ViP products arising from the development of VerImmune's pipeline for the territory of Chinese Mainland , Hong Kong and Macau Special Administration Regions and Taiwan Region. Fosun Pharma will be responsible for all clinical development and commercialization in these territories. VerImmune is eligible to receive up to $125M in total milestone payments plus tiered royalties up to the low double digits on net sales of VERI-101 in specified territories. Subject to Fosun Pharma's exercise of its option to select future AIR-ViP product candidates, VerImmune is eligible to receive additional milestone payments and royalties on net sales for each additional product candidate.",
    'FW Link1':
      'VerImmune and Fosun Pharma enter into an Exclusive License and Option Agreement for VerImmune’s Virus-inspired Particle (ViP) platform as Novel Immunotherapies in China;;https://firstwordpharma.com/story/5577243/'
  },
  {
    'Date Announced': '23-May-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Crosscope',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Farcast Biosciences',
    'Country (Target Company/Partner)': 'India',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'AI-powered pathology',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'The two companies will work together for the Prospective Evaluation of OrionAI – a revolutionary AI-based Diagnostic Assistant for Histological Examination of a variety of solid tumors beginning with Head and Neck Squamous Cell Carcinoma (HNSCC) Cancer.',
    'FW Link1':
      'Crosscope and Farcast Biosciences join efforts to reshape the precision oncology landscape via AI-powered pathology;;https://www.firstwordhealthtech.com/story/5577937/'
  },
  {
    'Date Announced': '23-May-2022',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'Micro-Tech Endoscopy',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Wision A.I.',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'United States',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'AI-assisted polyp detection software',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'EndoScreener',
    'Indication(s)': 'Colorectal cancer',
    'Deal Structure':
      'Micro-Tech will be the U.S. GI endoscopy distributor of EndoScreener, an AI-assisted polyp detection software used during colonoscopy procedures.',
    'FW Link1':
      'Micro-Tech Endoscopy Partners with Wision A.I. to Distribute its AI-assisted Polyp Detection Software in the U.S.;;https://www.firstwordhealthtech.com/story/5577967/'
  },
  {
    'Date Announced': '23-May-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Medison Pharma',
    'Country (Acquiring Company/Partner)': 'Israel',
    'Region (Acquiring Company/Partner)': 'Middle East',
    'Target Company/Partner': 'Immunocore',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal':
      'Canada, Central Eastern Europe, Israel, Australia, New Zealand',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Bispecific protein, immunotherapy',
    'Lead Asset(s)': 'Kimmtrak',
    'Indication(s)': 'Uveal melanoma',
    'Target(s)': 'gp100',
    'Deal Structure':
      "The multi-territorial agreement to help seek regulatory authorization and commercialize Immunocore's KIMMTRAK (tebentafusp -tebn) for the treatment of unresectable or metastatic uveal melanoma, a rare and aggressive form of melanoma that affects the eye, which covers Canada, twenty markets across Central Eastern Europe and Israel, will now extend to Australia and New Zealand.",
    'FW Link1':
      'Medison Pharma Announces Extension of Multi-territorial Agreement with Immunocore and Expansion into Australia and New Zealand;;https://firstwordpharma.com/story/5577969/',
    'FW Link2':
      'Expansion of agreement into Latin America announced November 9, 2022 https://firstwordpharma.com/story/5673646/'
  },
  {
    'Date Announced': '23-May-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Roche',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'KaliVir',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Oncolytic viruses',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Under the terms of the agreement, KaliVir will generate oncolytic vaccinia virus product candidates derived from the company's VET platform expressing Roche proprietary therapeutic transgenes. Roche will have exclusive license to discover, develop and commercialize the products worldwide. KaliVir will receive an upfront payment, and may be eligible to receive research, development and commercial milestone payments, as well as tiered royalties on commercial sales of products emerging from the collaboration.",
    'FW Link1':
      'KaliVir Immunotherapeutics Enters into Global Exclusive Licensing Agreement with Roche for Novel Oncolytic Viruses;;https://firstwordpharma.com/story/5578265/'
  },
  {
    'Date Announced': '23-May-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Umoja Biopharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Lupagen',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'In vivo therapeutics, cellular therapies, immuno-oncology',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Under the terms of the agreement, both parties will collaborate to evaluate VivoVec delivery using the Side CAR-T system. Lupagen will not develop or commercialize the Side CAR-T device for the delivery of viral vectors in the field of oncology during the term of the agreement. Umoja retains the right to opt in to an exclusive, worldwide agreement to develop the Side CAR-T device in the field of oncology.',
    'FW Link1':
      'Umoja Biopharma and Lupagen Announce New Collaboration to Improve Patient Experience and Access to Next-Generation In Vivo Therapeutics for Cancer;;https://firstwordpharma.com/story/5578035/'
  },
  {
    'Date Announced': '24-May-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'DongCheng Pharmaceutical',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'ImaginAb',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'CD8 ImmunoPET agent, diagnostic imaging agent',
    'Lead Asset(s)': 'zirconium Zr 89 crefmirlimab berdoxam',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'ImaginAb will receive license and milestone payments and up to double digit royalties for the CD8 ImmunoPET license and commercial partnership in the Greater China market. No other terms have been disclosed.',
    'FW Link1':
      'ImaginAb and [Yantai] DongCheng Pharmaceutical Group Sign Exclusive License Agreement to Commercialize CD8 ImmunoPET Agent in China to Serve Pharma and Healthcare Community;;https://firstwordpharma.com/story/5578611/'
  },
  {
    'Date Announced': '24-May-2022',
    'Deal type': 'Terminated',
    'Acquiring Company/Partner': 'Samsung Biologics',
    'Country (Acquiring Company/Partner)': 'Korea, South',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'AstraZeneca',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Biosimilar',
    'Lead Asset(s)': 'SAIT101',
    'Indication(s)': 'Lymphoma',
    'Deal Structure':
      'Samsung Biologics is shutting down its joint venture with AstraZeneca due to the suspension of the development of a biosimilar product. Archigen was responsible for developing SAIT101, a biosimilar of rituximab, which is used for the treatment of lymphoma.',
    'FW Link1':
      'Samsung Biologics, AstraZeneca shutting down their joint venture Archigen Biotech;;https://firstwordpharma.com/story/5578721/'
  },
  {
    'Date Announced': '25-May-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'CellBion',
    'Country (Acquiring Company/Partner)': 'Korea, South',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner':
      'Centre for Probe Development and Commercialization',
    'Country (Target Company/Partner)': 'Canada',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'PSMA-targeted radiotherapeutic',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'Lu-177-PSMA (DGUL)',
    'Indication(s)': 'Prostate cancer',
    'Target(s)': 'PSMA',
    'Deal Structure':
      "Under the terms of the agreement, CPDC will develop and manufacture CellBion's drug product for Phase II clinical supply in South Korea with the goal of expanding into North America.",
    'FW Link1':
      'The Centre for Probe Development and Commercialization expands into Asia and executes a development, manufacturing, and clinical supply agreement for 177Lu-PSMA (DGUL) With South Korea-based CellBion;;https://firstwordpharma.com/story/5579953/'
  },
  {
    'Date Announced': '25-May-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'CureVac',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'myNEO',
    'Country (Target Company/Partner)': 'Belgium',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'mRNA cancer vaccines',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Under the agreement, both companies aim to identify specific antigens found on the surface of tumors for the development of novel mRNA immunotherapies. To achieve this goal, myNEO will leverage its biological datasets and its integrated machine learning and bioinformatics platform to identify and validate specific antigen targets predicted to elicit a strong immune response.',
    'FW Link1':
      'CureVac links up with myNEO on mRNA-based cancer vaccines;;https://firstwordpharma.com/story/5579827/'
  },
  {
    'Date Announced': '25-May-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Oxford BioTherapeutics',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Agenus',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Antibody drug conjugate, anti-PD1 checkpoint inhibitor',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'OBT076, balstilimab',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      'Under the terms of the agreement, OBT will be the sponsor of the combination trial and responsible for operational execution, and Agenus will provide drug supply and scientific support.',
    'FW Link1':
      "Oxford BioTherapeutics Announces Collaboration with Agenus to Support the Clinical Development of OBT's Antibody Drug Conjugate OBT076 in combination with Agenus' CPI Balstilimab;;https://firstwordpharma.com/story/5579463/"
  },
  {
    'Date Announced': '25-May-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Picture Health',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Case Western Reserve University',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial intelligence',
    'Indication(s)': 'Lung cancer',
    'Deal Structure':
      'The university has signed an exclusive license agreement with Picture Health that aims to turn the promise of such AI tools into a reality that ultimately benefits patients around the globe.',
    'FW Link1':
      'Case Western Reserve University signs license agreement to bring artificial intelligence breakthroughs closer to cancer patient care;;https://www.firstwordhealthtech.com/story/5579947/'
  },
  {
    'Date Announced': '25-May-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Ellipses Pharma',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'SunRock',
    'Country (Target Company/Partner)': 'Spain',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Bifunctional HER3:TRAIL fusion protein',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'SRB22 (EP0017)',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'HER3, DR4, DR5',
    'Deal Structure':
      'Under the agreement, Ellipses has been granted global rights to develop and commercialise EP0017 and will assume the full cost and responsibility for the remaining pre-clinical and clinical development of the compound. In line with its strategy, Ellipses intends to out licence EP0017 for commercialisation if it proves safe and effective in clinical trials. SunRock will continue to be involved in the development of EP0017 to ensure efficient translational activities through a service agreement with Ellipses. If Ellipses out-licences EP0017, SunRock will also receive milestone payments and royalties.',
    'FW Link1':
      'Ellipses Pharma and SunRock Biopharma Enter Into a Licensing Agreement for a First-in-class Bifunctional HER3:TRAIL Fusion Protein;;https://firstwordpharma.com/story/5579447/'
  },
  {
    'Date Announced': '25-May-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Convergent Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'IONETIX',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Radiotherapies, radiopharmaceuticals',
    'Lead Asset(s)': 'CONV01-α',
    'Indication(s)': 'Prostate cancer',
    'Target(s)': 'PSMA',
    'Deal Structure':
      "Convergent Therapeutics Inc., a clinical stage radiopharmaceutical company, and IONETIX Corporation, a leading cyclotron technology, and isotope manufacturing company, announced a long-term supply agreement for the therapeutic medical radioisotope, actinium-225 (Ac-225). The agreement will support Convergent's pipeline of next-generation radiotherapies including its lead asset, CONV01-α, a prostate-specific membrane antigen (PSMA)-targeted monoclonal antibody linked to Ac-225.",
    'FW Link1':
      'Convergent Therapeutics and IONETIX Announce Supply Agreement for Therapeutic Radioisotope Actinium-225 (Ac-225);;https://firstwordpharma.com/story/5579895/'
  },
  {
    'Date Announced': '26-May-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Kidswell Bio',
    'Country (Acquiring Company/Partner)': 'Japan',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Chiome Bioscience',
    'Country (Target Company/Partner)': 'Japan',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody drugs',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Kidswell Bio Corporation (KWB) executed a collaborative research agreement with Chiome Bioscience Inc. (Chiome) on May 26, 2022 for accelerating the antibody-drug development in the oncology field by the combining knowledge and technologies of KWB and Chiome.',
    'FW Link1':
      'Announcement of collaborative research agreement with Chiome Bioscience Inc. for the development of antibody drugs;;https://firstwordpharma.com/story/5581271/'
  },
  {
    'Date Announced': '26-May-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Novocure',
    'Country (Acquiring Company/Partner)': 'Jersey',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Merck & Co.',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Tumour treating fields, anti-PD-1 therapy',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'TTFields, Keytruda',
    'Indication(s)': 'Newly diagnosed glioblastoma',
    'Deal Structure':
      "Novocure announced it has entered into a clinical trial collaboration agreement with MSD, a tradename of Merck & Co., Inc., Rahway, NJ, USA, to study the use of Tumor Treating Fields (TTFields) concomitant with MSD's anti-PD-1 therapy KEYTRUDA (pembrolizumab) for the treatment of patients with newly diagnosed glioblastoma (GBM). This is the second clinical collaboration between Novocure and MSD.",
    'FW Link1':
      'Novocure Announces Clinical Collaboration with MSD on a Registrational-Intent Study to Evaluate Tumor Treating Fields Together with KEYTRUDA (pembrolizumab) in Newly Diagnosed Glioblastoma;;https://firstwordpharma.com/story/5580539/'
  },
  {
    'Date Announced': '27-May-2022',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'UroGPO',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'KDx Diagnostics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'United States',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Non-invasive urine test, diagnostic test',
    'Lead Asset(s)': 'URO17',
    'Indication(s)': 'Bladder cancer',
    'Deal Structure':
      'UroGPO will offer reagents and information for the URO17 test to the members of their Group Purchasing Organization.',
    'FW Link1':
      'KDx Diagnostics and UroGPO Announce a Partnership to Offer the URO17 NON-INVASIVE Urine Test for Bladder Cancer in the United States;;https://www.firstwordhealthtech.com/story/5582621/'
  },
  {
    'Date Announced': '30-May-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'ANGLE',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Solaris Health, MidLantic Urology',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'In vitro diagnostic device',
    'Lead Asset(s)': 'Parsortix',
    'Indication(s)': 'Prostate cancer',
    'Deal Structure':
      'ANGLE plc has signed a master clinical study agreement with Solaris Health Holdings, LLC (Solaris) and joinder agreements with MidLantic Urology LLC, to collaborate and conduct clinical studies in prostate cancer and as a potential route to market in the United States. Together with MidLantic Urology, ANGLE will initiate clinical studies aimed at investigating the use of the Parsortix® system for the detection of prostate cancer and prediction of its severity in patients who present with an elevated prostate specific antigen (PSA) level and/or abnormal digital rectal exam.',
    'FW Link1':
      'Angle Announces Partnership with Major United States Urology Group;;https://www.firstwordhealthtech.com/story/5582979/'
  },
  {
    'Date Announced': '31-May-2022',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'Blue Note Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'OncoHealth',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'United States',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Digital therapeutic',
    'Lead Asset(s)': 'attune',
    'Indication(s)': 'Cancer-related anxiety and depression symptoms',
    'Deal Structure':
      "Through OncoHealth's telehealth platform, Iris, licensed healthcare professionals can provide Blue Note's attune to patients living with cancer.",
    'FW Link1':
      'OncoHealth and Blue Note Therapeutics Partner to Provide a Digital Therapeutic for Treating Cancer-Related Anxiety and Depression Symptoms;;https://www.firstwordhealthtech.com/story/5584411/'
  },
  {
    'Date Announced': '1-Jun-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Astellas',
    'Country (Acquiring Company/Partner)': 'Japan',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'GO Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '21',
    'Total Deal Value ($m)': '784',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibodies',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Astellas' subsidiary Xyphos Biosciences entered into an agreement with GO Therapeutics to develop next-generation immuno-oncology treatments. Under the deal, Xyphos will make an upfront cash payment of $20.5 million, with GO Therapeutics eligible to receive further milestone and contingency payments of up to $763 million. The companies will collaborate to identify antibodies with high affinity to two different glycoprotein targets and apply these antibodies to a range of therapeutic modalities. GO Therapeutics will lead efforts to discover high-affinity antibodies against the two targets, while Astellas will be responsible for research activities, clinical development and commercialisation.",
    'FW Link1':
      'Astellas teams up with GO Therapeutics to develop immuno-oncology antibodies;;https://firstwordpharma.com/story/5584783/'
  },
  {
    'Date Announced': '1-Jun-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Roche',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Repare Therapeutics',
    'Country (Target Company/Partner)': 'Canada',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '125',
    'Total Deal Value ($m)': '1,325',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecule',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'camonsertib',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'ATR',
    'Deal Structure':
      'Under the collaboration, Roche will assume development of camonsertib with the potential to expand development into additional tumors and multiple combination studies. Under the terms of the agreement, Repare will receive a $125 million upfront payment, and is eligible to receive up to $1.2 billion in potential clinical, regulatory, commercial and sales milestones, including up to $55 million in potential near-term payments, and royalties on global net sales ranging from high-single-digits to high-teens. The collaboration also provides Repare with the ability to opt-in to a 50/50 U.S. co-development and profit share arrangement, including participation in U.S. co-promotion if U.S. regulatory approval is received. If Repare chooses to exercise its co-development and profit share option, it will continue to be eligible to receive certain clinical, regulatory, commercial and sales milestone payments, in addition to full ex-U.S. royalties.',
    'FW Link1':
      "Roche puts up $125 million for Repare's targeted cancer therapy camonsertib;;https://firstwordpharma.com/story/5585791/"
  },
  {
    'Date Announced': '1-Jun-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Asher Bio',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Merck & Co.',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'CD8-targeted interleukin 2, anti-PD-1 therapy',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'AB248, Keytruda',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'CD8, PD-1',
    'Deal Structure':
      'Under the terms of the agreement, Asher Bio is responsible for conducting the Phase 1a/1b trial, which it expects to initiate in the second half of 2022.',
    'FW Link1':
      'Asher Bio Announces Clinical Trial Collaboration with Merck to Evaluate AB248 in Combination with KEYTRUDA (pembrolizumab) in Patients with Locally Advanced or Metastatic Solid Tumors;;https://firstwordpharma.com/story/5585539/'
  },
  {
    'Date Announced': '1-Jun-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Manhattan BioSolutions',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Binghamton University',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'The collaboration will utilize novel immune-stimulatory linker-payload technology invented at Binghamton University to develop novel antibody-drug conjugates (ADCs) for oncology applications. Manhattan BioSolutions will have an exclusive option to license global rights for selected linker-payload - antibody combinations targeting distinct tumor-associated antigens.',
    'FW Link1':
      'Manhattan BioSolutions Enters into a Research Collaboration And License Option Agreement with Binghamton University for the New Linker-Payload Technology;;https://firstwordpharma.com/story/5585421/'
  },
  {
    'Date Announced': '2-Jun-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Boehringer Ingelheim',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'A*STAR',
    'Country (Target Company/Partner)': 'Singapore',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Cancer-specific antibodies, antibody-drug conjugates, T-cell engagers',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Boehringer Ingelheim and A*STAR announced a global licensing agreement under which Boehringer Ingelheim will obtain exclusive worldwide rights to research, develop and commercialize products based on a panel of innovative, tumor-specific antibodies from A*STAR. Under the terms of the agreement, Boehringer Ingelheim will be responsible for further research, preclinical and clinical development as well as commercialization of targeted cancer therapies using the antibodies from A*STAR. A*STAR may receive payments totalling >100 million EUR in upfront and success-based development and commercialization milestones.',
    'FW Link1':
      'Boehringer Ingelheim Enters Global Licensing Agreement To Develop And Commercialize Innovative Antibodies From A*STAR For Targeted Cancer Therapies;;https://firstwordpharma.com/story/5586303/'
  },
  {
    'Date Announced': '2-Jun-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'AstraZeneca',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Grail',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Companion diagnostics',
    'Indication(s)': 'Early-stage cancer',
    'Deal Structure':
      "The collaboration will initially focus on developing companion diagnostic tests to identify patients with high-risk, early-stage disease, with plans to embark on numerous studies across multiple indications over the next several years. The parties are additionally planning to use GRAIL's technology to enable recruitment of patients with early-stage cancer for AstraZeneca's clinical studies. Across the projects within this collaboration, GRAIL will use its methylation platform to perform testing for patients enrolled in AstraZeneca's clinical trials. GRAIL will seek regulatory approval in key markets for the liquid biopsy companion diagnostics.",
    'FW Link1':
      'GRAIL Announces Strategic Collaboration With AstraZeneca to Develop Companion Diagnostic Tests to Enable the Treatment of Early-Stage Cancer;;https://www.firstwordhealthtech.com/story/5586107/'
  },
  {
    'Date Announced': '2-Jun-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'BMS',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Immatics',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Allogeneic cell therapy, TCR-T/CAR-T',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Under this collaboration, Bristol Myers Squibb and Immatics will develop two programs owned by Bristol Myers Squibb and both companies have an option to develop up to four additional programs each. Under the terms of this agreement, Immatics will receive an upfront payment of $60 million as well as up to $700 million per Bristol Myers Squibb program through development, regulatory and commercial milestone payments and tiered royalty payments of up to low double-digit percentages on net product sales. Immatics will be responsible for preclinical development of the initial two Bristol Myers Squibb-owned programs and will receive additional payment for certain activities that Immatics could perform at Bristol Myers Squibb’s request. Bristol Myers Squibb will assume responsibility for clinical development and commercialization activities of all Bristol Myers Squibb-owned programs thereafter. In addition, Bristol Myers Squibb and Immatics will expand their 2019 collaboration agreement focused on autologous T cell receptor-based therapy (TCR-T), with the inclusion of one additional TCR target discovered by Immatics. As part of this expansion, Immatics will receive an upfront payment of $20 million and be eligible for milestone payments and royalties.',
    'FW Link1':
      'BMS, Immatics deepen ties to develop allogeneic gamma-delta cell therapies;;https://firstwordpharma.com/story/5586019/'
  },
  {
    'Date Announced': '2-Jun-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Caris Life Sciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner':
      'Prostate Cancer Clinical Trial Consortium, Sorrento Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Genomic profiling, precision medicine',
    'Indication(s)': 'Prostate cancer',
    'Deal Structure':
      "Collaboration will comprehensively profile DNA, RNA and proteins on patient samples from the Phase 2 Maverick trial to understand mechanisms of disease response and resistance. Utilizing Caris' unique MI Profile, PCCTC investigators will profile whole exome DNA, whole transcriptome RNA, and proteins from samples collected from participants enrolled in the trial, creating a molecular blueprint to better understand mechanisms of response and resistance following therapy.",
    'FW Link1':
      'Caris Life Sciences, Prostate Cancer Clinical Trial Consortium and Sorrento Therapeutics Announce Collaboration to Advance Precision Medicine Development Using Comprehensive Genomic Profiling;;https://www.firstwordhealthtech.com/story/5586221/'
  },
  {
    'Date Announced': '2-Jun-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Regeneron',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Sanofi',
    'Country (Target Company/Partner)': 'France',
    'Region (Target Company/Partner)': 'Europe',
    'Upfront Value ($m)': '900',
    'Total Deal Value ($m)': '1,100',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immuno-oncology',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Libtayo',
    'Indication(s)':
      'Basal cell carcinoma, cutaneous squamous cell carcinoma, non-small cell lung cancer, cervical cancer',
    'Target(s)': 'PD-1',
    'Deal Structure':
      'As part of a restructuring of their immuno-oncology collaboration, Sanofi has granted Regeneron Pharmaceuticals sole rights to develop and market the PD-1 inhibitor Libtayo (cemiplimab). In exchange, Sanofi will receive an upfront payment of $900 million, plus potential milestones worth up to $200 million and royalties.',
    'FW Link1':
      'Sanofi grants global Libtayo rights to Regeneron for $900 million upfront;;https://firstwordpharma.com/story/5586017/'
  },
  {
    'Date Announced': '2-Jun-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Resilience',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'University of Texas MD Anderson Cancer Center',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapies',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'National Resilience, Inc. (Resilience) and The University of Texas MD Anderson Cancer Center announced the launch of a joint venture, the Cell Therapy Manufacturing Center, to accelerate the development and manufacturing of innovative cell therapies for patients with cancer.',
    'FW Link1':
      'Resilience and MD Anderson Launch Joint Venture to Accelerate Development and Manufacturing of Innovative Cell Therapies for Cancer;;https://firstwordpharma.com/story/5586757/'
  },
  {
    'Date Announced': '3-Jun-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Sanofi',
    'Country (Acquiring Company/Partner)': 'France',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Immunocore',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Differentiated IL-2, bispecific protein',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'SAR444245, Kimmtrak',
    'Indication(s)': 'Metastatic cutaneous melanoma',
    'Deal Structure':
      "Under the agreement, Sanofi will evaluate its precisely PEGylated, engineered version of IL-2, SAR444245, in combination with KIMMTRAK, Immunocore's novel bispecific protein targeting gp100, in HLA-A*02:01 positive patients with advanced unresectable or metastatic skin cancers as part of Sanofi's ongoing Phase 1/2 study. Under the terms of the agreement, Sanofi will be responsible for clinical development and will assume all costs associated with the study, other than expenses related to the manufacturing and supply of KIMMTRAK for which Immunocore is responsible.",
    'FW Link1':
      'Immunocore announces clinical trial collaboration with Sanofi to evaluate Sanofi’s product candidate SAR444245, non-alpha IL-2, in combination with KIMMTRAK in patients with metastatic cutaneous melanoma;;https://firstwordpharma.com/story/5587083/'
  },
  {
    'Date Announced': '3-Jun-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Imaging Endpoints',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'ImaginAb',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'CD8 imaging agent',
    'Lead Asset(s)': 'zirconium Zr 89 crefmirlimab berdoxam',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Imaging Endpoints (IE) and ImaginAb announced that after collaborating for nearly a decade they have entered into a license agreement for IE to directly offer clinical doses of ImaginAb's CD8 investigational ImmunoPET (zirconium Zr 89 crefmirlimab berdoxam) imaging agent for clinical trials as part of Imaging Endpoints' industry-leading imaging CRO services.",
    'FW Link1':
      'Imaging Endpoints (IE) and ImaginAb Inc Expand Partnership - IE to Supply Clinical Doses of CD8 Imaging Agent to BioPharma Companies;;https://firstwordpharma.com/story/5587701/'
  },
  {
    'Date Announced': '3-Jun-2022',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'BMS',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Turning Point Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Total Deal Value ($m)': '4,100',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Tyrosine kinase inhibitor',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'repotrectinib',
    'Indication(s)': 'Non-small-cell lung cancer, solid tumours',
    'Target(s)': 'ROS1, NTRK',
    'Deal Structure':
      "Bristol Myers Squibb said it will begin an all-cash tender offer at $76 per share to buy Turning Point Therapeutics for $4.1 billion, gaining the precision oncology firm's lead asset repotrectinib.",
    'FW Link1':
      'BMS inks deal to buy Turning Point Therapeutics for $4.1 billion;;https://firstwordpharma.com/story/5587073/',
    'FW Link2':
      'Completion of acquisition announced August 17, 2022;;https://firstwordpharma.com/story/5639121/'
  },
  {
    'Date Announced': '5-Jun-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Arvinas',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Foundation Medicine',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Companion diagnostic, protein degrader',
    'Lead Asset(s)': 'FoundationOne Liquid CDx, bavdegalutamide',
    'Indication(s)': 'Metastatic castration-resistant prostate cancer',
    'Target(s)': 'Androgen receptor',
    'Deal Structure':
      "Foundation Medicine announced a collaboration with Arvinas to develop FoundationOne Liquid CDx as a companion diagnostic for use with Arvinas' bavdegalutamide (ARV-110), an investigational novel PROTAC protein degrader targeting the androgen receptor (AR).",
    'FW Link1':
      'Foundation Medicine Announces Strategic Collaboration with Arvinas;;https://firstwordpharma.com/story/5587783/'
  },
  {
    'Date Announced': '6-Jun-2022',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Yumanity Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Kineta',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Immuno-oncology, VISTA blocking immunotherapy, antibodies',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'KVA12.1',
    'Indication(s)': 'Cancer',
    'Target(s)': 'VISTA, CD27, CD24',
    'Deal Structure':
      "Under the second definitive agreement, Kineta will become a wholly-owned subsidiary of Yumanity in an all-stock transaction, resulting in a combined publicly traded company re-named Kineta, Inc., that will focus on immuno-oncology and continue Yumanity's ongoing research collaboration with Merck & Co. in amyotrophic lateral sclerosis and frontotemporal lobar dementia.",
    'FW Link1':
      'Yumanity Therapeutics Announces Definitive Agreements for Two Strategic Transactions;;https://firstwordpharma.com/story/5587955/',
    'FW Link2':
      'Completion of reverse merger announced December 19, 2022;;https://firstwordpharma.com/story/5687914/'
  },
  {
    'Date Announced': '7-Jun-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'MetasTx',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'J-STAR Research',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Nnovel cancer agent',
    'Lead Asset(s)': 'MTX-101',
    'Indication(s)': 'Prostate cancer',
    'Deal Structure':
      'MetasTx has partnered with J-STAR Research, Inc in Cranbury, New Jersey. J-STAR scientists have already characterized the physical and chemical properties of MTX-101 to ensure formulation and process development can be conducted.',
    'FW Link1':
      'MetasTx LLC Raises Funds, Engages Manufacturing Partner, And Advances Phase One Development;;https://firstwordpharma.com/story/5589975/'
  },
  {
    'Date Announced': '7-Jun-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Immatics',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Editas Medicine',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'CRISPR, gene editing, gamma-delta T cell adoptive cell therapies',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "As part of the licensing agreement, Immatics gains non-exclusive rights to Editas Medicine's CRISPR technology and intellectual property. Under the terms of the agreement, Editas Medicine will be eligible to receive an undisclosed upfront cash payment as well as additional milestone payments based on development, regulatory, and commercial milestones. In addition, Immatics will pay royalties on future net sales on any products that may result from this collaboration.",
    'FW Link1':
      'Immatics and Editas Medicine Enter Strategic Research Collaboration and Licensing Agreement to Combine Gamma-Delta T Cell Adoptive Cell Therapies and Gene Editing for the Treatment of Cancer;;https://firstwordpharma.com/story/5588965/'
  },
  {
    'Date Announced': '8-Jun-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'ImaginAb',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Roche',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'anti-PD-L1 therapy, CD8 imaging agent',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'Tecentriq, zirconium Zr 89 crefmirlimab berdoxam',
    'Indication(s)': 'Non-small-cell lung cancer',
    'Target(s)': 'PD-L1',
    'Deal Structure':
      "Roche, which has licensed ImaginAb's CD8 tracer for use in ongoing clinical trials including [NCT03533283], is providing atezolizumab (Tecentriq) as a monotherapy treatment for patients with NSCLC. The drug is provided for the duration of the patient's treatment within the study or until a decision is made to remove the drug from the patient's treatment path by the treating physician.",
    'FW Link1':
      "ImaginAb and Roche have entered a clinical trial supply agreement for provision of atezolizumab (Tecentriq)1 to Non-Small Cell Lung Cancer Patients Enrolled in ImaginAb's iPREDICT Phase IIb Study;;https://firstwordpharma.com/story/5590359/"
  },
  {
    'Date Announced': '8-Jun-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Resilience',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Parker Institute for Cancer Immunotherapy',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell and gene therapies',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Together, PICI and Resilience aim to bring novel cell and gene therapies to market through the creation and incubation of new companies with a commitment of up to $50 million in funding. These startups will leverage groundbreaking technologies from across the PICI Network and have direct access to Resilience's biomanufacturing capacity, capability, and know-how. Under the agreement, Resilience will also collaborate with PICI Network investigators on challenges related to biomanufacturing and novel treatment modalities.",
    'FW Link1':
      'The Parker Institute for Cancer Immunotherapy and Resilience Announce Strategic Alliance to Develop Next-Generation Cancer Therapies;;https://firstwordpharma.com/story/5590849/'
  },
  {
    'Date Announced': '8-Jun-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'OnCusp Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Multitude Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global excl. China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody drug conjugate',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'CUSP06 (AMT-707)',
    'Indication(s)': 'Cancer',
    'Target(s)': 'CDH6',
    'Deal Structure':
      'Under the agreement, OnCusp obtains the exclusive global rights to lead development and commercialization of CUSP06 worldwide outside of Greater China. Multitude will receive an upfront payment as well as development, regulatory and sales milestone payments, and tiered royalties.',
    'FW Link1':
      'OnCusp Therapeutics and Multitude Therapeutics Enter into an Ex-China Licensing Agreement for a Potentially Highly Differentiated CDH6-Targeting Antibody Drug Conjugate;;https://firstwordpharma.com/story/5590725/'
  },
  {
    'Date Announced': '8-Jun-2022',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'CureVac',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Frame Cancer Therapeutics',
    'Country (Target Company/Partner)': 'Netherlands',
    'Region (Target Company/Partner)': 'Europe',
    'Upfront Value ($m)': '17',
    'Total Deal Value ($m)': '34',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antigen discovery, cancer vaccines',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'The total consideration for the acquisition of Frame Therapeutics is valued at €32 million. It will be paid in CureVac shares. Following a 50 percent upfront payment, the residual amount will be split across two project milestone driven steps. CureVac will expand the antigen discovery and validation activities at the Amsterdam Science Park.',
    'FW Link1':
      'CureVac Accelerates Oncology Strategy with Acquisition of Frame Cancer Therapeutics, Adding Novel Antigen Discovery Platform;;https://firstwordpharma.com/story/5591511/'
  },
  {
    'Date Announced': '9-Jun-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Olema Oncology',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Aurigene',
    'Country (Target Company/Partner)': 'India',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '8',
    'Total Deal Value ($m)': '438',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Olema Pharmaceuticals, Inc. ("Olema" or "Olema Oncology,") and Aurigene Discovery Technologies Limited ("Aurigene") announced an exclusive global license agreement to research, develop and commercialize novel small molecule inhibitors of an undisclosed oncology target. Under the terms of the agreement, Olema will make an upfront licensing payment of $8 million for rights to a pre-existing Aurigene program. Aurigene will be eligible for up to $60 million in potential clinical development and regulatory milestones and up to $370 million in potential commercial milestones, as well as royalties ranging from the mid-single digits to the low double digits based on annual net sales. During the research term, Olema will contribute funding to Aurigene to facilitate ongoing discovery efforts. Olema and Aurigene will jointly direct further preclinical work and, if successful, Olema will lead clinical development as well as regulatory and commercial activities.',
    'FW Link1':
      'Olema Oncology and Aurigene Announce Exclusive Collaboration and License Agreement to Discover and Develop Novel Cancer Therapies;;https://firstwordpharma.com/story/5591655/'
  },
  {
    'Date Announced': '13-Jun-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Evgen Pharma',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'University of Michigan',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Sulforaphane-synthetising technology, sulforaphane-based medicines',
    'Lead Asset(s)': 'SFX-01',
    'Indication(s)': 'Colorectal cancer',
    'Deal Structure':
      'The collaboration with the University of Michigan seeks to evaluate the in vivo effects of SFX-01 in models of colorectal cancer.',
    'FW Link1':
      'Collaboration with University of Michigan to evaluate SFX-01 in models of colorectal cancer;;https://firstwordpharma.com/story/5593765/'
  },
  {
    'Date Announced': '13-Jun-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Pfizer',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'MorphoSys',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'SIRPα-Fc fusion protein, CD19-directed immunotherapy',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'TTI-622, Monjuvi',
    'Indication(s)': 'Diffuse large B-cell lymphoma',
    'Target(s)': 'CD47, CD19',
    'Deal Structure':
      'Under the terms of the agreement, Pfizer will initiate a multicenter, international Phase 1b/2 study of TTI-622 with Monjuvi and lenalidomide for patients with relapsed or refractory DLBCL who are not eligible for ASCT. MorphoSys and Incyte will provide Monjuvi for the study, which will be sponsored and funded by Pfizer and is planned to be conducted in North America, Europe and Asia-Pacific.',
    'FW Link1':
      'Pfizer, MorphoSys and Incyte Enter into Clinical Trial Collaboration for Monjuvi (tafasitamab-cxix) in Combination with TTI-622, a Fusion Protein Directed Against CD47;;https://firstwordpharma.com/story/5594245/'
  },
  {
    'Date Announced': '13-Jun-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Oxford BioTherapeutics',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'ImmunoGen',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "The companies will utilize ImmunoGen's linker-payload technology directed to novel targets identified via OBT's proprietary OGAP discovery platform. The companies will support these R&D efforts through joint funding and by combining their respective proprietary technologies. As part of the agreement, OBT will receive an upfront payment from ImmunoGen, reflecting OBT's preclinical programs to be included in the partnership. In addition, once antibodies generated by OBT have been coupled with ImmunoGen's proprietary linker-payload technology, each company will have the opportunity to select one or more development programs to further develop on its own. Each company is eligible to receive milestone payments based on the achievement of pre-specified development, regulatory, and commercial milestones, as well as tiered royalties as a percentage of worldwide commercial sales, with respect to each program selected by the other company. Once a company has chosen a given program, it will be solely responsible for all R&D costs associated with the specific program.",
    'FW Link1':
      'Oxford BioTherapeutics Announces Research Collaboration with ImmunoGen to Develop Novel Antibody-Drug Conjugates;;https://firstwordpharma.com/story/5593513/'
  },
  {
    'Date Announced': '13-Jun-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Organon',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Henlius Biotech',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '73',
    'Geographic Remit of Deal': 'Global excl. China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Biosimilars',
    'Lead Asset(s)': 'HLX11, HLX14, HLX13',
    'Indication(s)':
      'Breast cancer, melanoma, renal cell carcinoma, colorectal cancer, hepatocellular carcinoma, non-small-cell lung cancer, malignant pleural mesothelioma, oesophageal cancer',
    'Target(s)': 'HER2, RANKL',
    'Deal Structure':
      'Organon will license commercialization rights for biosimilar candidates referencing Perjeta (pertuzumab, HLX11) and Prolia/Xgeva (denosumab, HLX14). Organon will acquire exclusive global commercialization rights except for China; including Hong Kong, Macau and Taiwan. The agreement also includes an option to negotiate an exclusive license for global commercialization rights for a biosimilar candidate referencing Yervoy (ipilimumab, HLX13). Ipilimumab is used for the treatment of certain patients with unresectable or metastatic melanoma, as adjuvant treatment of certain patients with cutaneous melanoma, certain patients with Renal Cell Carcinoma, Colorectal Cancer, Hepatocellular Carcinoma, Non-Small Cell Lung Cancer, Malignant Pleural Mesothelioma and Esophageal Cancer. Consideration for the transaction includes an upfront payment of $73 million as well as additional payments upon the achievement of certain development, regulatory and commercial milestones. Henlius will be responsible for development and, if approved, will supply the products to Organon.',
    'FW Link1':
      'Organon Enters into Global License Agreement to Commercialize Henlius’ Investigational Perjeta (Pertuzumab) and Prolia/Xgeva (Denosumab) Biosimilar Candidates;;https://firstwordpharma.com/story/5593525/'
  },
  {
    'Date Announced': '13-Jun-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'OncoHost',
    'Country (Acquiring Company/Partner)': 'Israel',
    'Region (Acquiring Company/Partner)': 'Middle East',
    'Target Company/Partner': 'SomaLogic',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Proteomics, precision cancer diagnostics',
    'Lead Asset(s)': 'SomaScan',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "As part of the agreement, OncoHost will license SomaLogic's SomaScan Platform to develop proteomics tests for its PROphet diagnostic system, designed to predict patient response to immunotherapy treatments and provide strategies to overcome treatment resistance. As part of the agreement, OncoHost will establish a lab in North Carolina where it will use the SomaScan Assay kits to run protein measurements on patient samples. OncoHost will also use the SomaScan Platform to develop its own laboratory-developed tests that will allow clinicians to make treatment decisions earlier in a patient's disease progression and inform choices for alternative therapies.",
    'FW Link1':
      'SomaLogic Announces License Agreement with OncoHost for the Development of Precision Cancer Diagnostics;;https://www.firstwordhealthtech.com/story/5594059/'
  },
  {
    'Date Announced': '14-Jun-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Telix',
    'Country (Acquiring Company/Partner)': 'Australia',
    'Region (Acquiring Company/Partner)': 'Oceania',
    'Target Company/Partner': 'Invicro',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial intelligence, imaging',
    'Lead Asset(s)': 'TelixAI',
    'Indication(s)': 'Prostate cancer',
    'Deal Structure':
      "Telix Pharmaceuticals has advanced a partnership with Invicro LLC (Invicro), a global, industry-leading imaging CRO, and part of REALM IDx, Inc., to develop an artificial intelligence (AI) platform to accompany Telix's PSMA-PET imaging agent, Illuccix (kit for the preparation of gallium Ga 68 gozetotide) - known as TelixAI.",
    'FW Link1':
      'Telix and Invicro Advance AI Partnership;;https://firstwordpharma.com/story/5594947/'
  },
  {
    'Date Announced': '14-Jun-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'MAIA Biotechnology',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': "Nationwide Children's Hospital",
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Telomere-targeting small molecule',
    'Lead Asset(s)': 'THIO',
    'Indication(s)': 'Non-small-cell lung cancer',
    'Deal Structure':
      'Through this research collaboration, the organizations will conduct preclinical studies to assess the efficacy and safety of THIO in combination with radiotherapy and immune checkpoint inhibitors in vitro and in vivo.',
    'FW Link1':
      'MAIA Biotechnology, Inc. Announces Research Collaboration with Nationwide Children’s Hospital to Evaluate THIO in Combination with Standard-of-Care Cancer Therapies;;https://firstwordpharma.com/story/5594963/'
  },
  {
    'Date Announced': '15-Jun-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'J&J',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Paige',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Artificial intelligence-powered biomarker test',
    'Indication(s)': 'Urothelial cancer',
    'Deal Structure':
      'Paige, a global leader in clinical AI applications in pathology, announced a collaboration with Janssen Research & Development, LLC (Janssen) to evaluate the potential of a hematoxylin and eosin (H&E)-based, artificial intelligence (AI)-powered biomarker test to predict the presence of certain actionable alterations in the fibroblast growth factor receptor (FGFR) genes in patients with advanced urothelial cancer, also known as bladder cancer. This biomarker test, developed by Janssen, is a first-of-its-kind screening tool to predict the occurrence of actionable genomic alterations. The goal is to improve rates of confirmatory molecular testing and accelerate recruitment of patients into biomarker-driven clinical trials that are enrolling patients with certain tumor FGFR mutations.',
    'FW Link1':
      'Paige teams up with J&J on AI-powered bladder cancer test;;https://www.firstwordhealthtech.com/story/5595773/'
  },
  {
    'Date Announced': '4-May-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'BMS',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Amphista Therapeutics',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Upfront Value ($m)': '30',
    'Total Deal Value ($m)': '1,280',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecule protein degraders',
    'Lead Asset Stage/Phase': 'Discovery',
    'Deal Structure':
      'Under the terms of the agreement, Bristol Myers Squibb and Amphista will work collaboratively to discover and develop small molecule protein degraders. Bristol Myers Squibb will be granted a global exclusive license to the degraders developed and will be responsible for further development and commercialization activities. Amphista will receive a $30 million upfront payment, the potential for up to $1.25 billion in performance-based milestone payments and payment for a limited expansion of the collaboration, as well as royalties on global net sales of products.',
    'FW Link1':
      'BMS, Merck KGaA ink separate deals with protein degradation player Amphista;;https://firstwordpharma.com/story/5563101/'
  },
  {
    'Date Announced': '4-May-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Merck KGaA',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Amphista Therapeutics',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Upfront Value ($m)': '41',
    'Total Deal Value ($m)': '942',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecule protein degraders',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Under the terms of the agreement, Merck and Amphista will work collaboratively to discover and develop small molecule protein degraders for an initial three targets in oncology and immunology indications. Amphista will receive an upfront payment, R&D funding and success-based milestone payments of up to €893.5 million as well as royalties in the mid-single digit range.',
    'FW Link1':
      'BMS, Merck KGaA ink separate deals with protein degradation player Amphista;;https://firstwordpharma.com/story/5563101/'
  },
  {
    'Date Announced': '14-Jun-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Genomic Testing Cooperative',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'HALO Diagnostics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Precision diagnostics',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'HALO Diagnostics, a precision diagnostics leader, has joined forces with the Genomic Testing Cooperative (GTC) to offer innovative, personalized testing to its physician network and 1M+ patients served. This solution combines HALO Diagnostics’ clinical ensemble and image-guided therapies with GTC’s genomic profiling of a patient’s DNA / RNA using liquid biopsy and tissue samples.',
    'FW Link1':
      'HALO Diagnostics and Genomic Testing Cooperative Partner to Advance Early Cancer Detection and Precision Diagnostics;;https://www.firstwordhealthtech.com/story/5595933/'
  },
  {
    'Date Announced': '15-Jun-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Pierre Fabre',
    'Country (Acquiring Company/Partner)': 'France',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Lonza',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibody',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'W0180',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'VISTA',
    'Deal Structure':
      'This collaboration is aimed at manufacturing W0180, an innovative monoclonal antibody discovered by Pierre Fabre targeting the VISTA checkpoint, currently being investigated as a single agent and in combination with pembrolizumab in a Phase I clinical trial (NCT04564417) in various solid tumors. Lonza will provide cGMP drug product (DP) manufacturing services for clinical supply from its fill and finish facility at Stein, Switzerland.',
    'FW Link1':
      'Pierre Fabre and Lonza Enter Manufacturing Agreement for W0180 Antibody Drug Product;;https://firstwordpharma.com/story/5595455/'
  },
  {
    'Date Announced': '15-Jun-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'AdventHealth Cancer Institute',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Pierian, Syapse',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'United States',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Diagnostics, genomic testing, real-world evidence',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Pierian, a global leader in advanced clinical genomics technology and services, announced with Syapse, a leading real-world evidence company, a new joint collaboration that will benefit patients of the AdventHealth Cancer Institute in Central Florida. The collaboration combines Pierian's leading-edge clinical genomics workflow, and precision medicine services with Syapse's real-world evidence solutions designed to improve outcomes for patients with cancer.",
    'FW Link1':
      'Pierian and Syapse Collaborate with Health Systems, Leveraging Data to Power Precision Oncology;;https://www.firstwordhealthtech.com/story/5595665/'
  },
  {
    'Date Announced': '15-Jun-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Affini-T Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Metagenomi',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Gene editing, cell therapies',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      "The partnership will leverage Metagenomi's proprietary gene editing systems to complement Affini-T's state-of-the-art TCR discovery and synthetic biology platforms to generate groundbreaking cell therapy products. Affini-T will have the option to exclusively license Metagenomi's technology to make gene edits in autologous TCR T cell therapies for specific tumor targets, with the option to expand non-exclusively to editing certain allogeneic approaches. In the future the parties will discuss further targets for co-development and co-commercialization. Under the terms of the agreement, Metagenomi will be entitled to receive tiered payments for each optioned cancer target plus additional milestone and royalty payments.",
    'FW Link1':
      'Affini-T Therapeutics & Metagenomi Announce Next-Generation Gene Editing Partnership to Advance Cell Therapies for Solid Tumor Patients;;https://firstwordpharma.com/story/5595799/'
  },
  {
    'Date Announced': '15-Jun-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Sanofi',
    'Country (Acquiring Company/Partner)': 'France',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Aqemia',
    'Country (Target Company/Partner)': 'France',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Artificial intelligence, quantum physics, small molecules',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'This new agreement is a follow-up to a Research Collaboration initiated at the end 2020 by Sanofi to bring the unique technologies of Aqemia to the design and discovery of novel molecules in several projects in oncology, a priority therapeutic area for Sanofi. This initial collaboration resulted in promising molecules for an oncology program, for which Sanofi and Aqemia decided to pursue joint efforts. Aqemia will take responsibility for the AI-based design of optimized molecules that fulfill several small molecule design goals among which potency and selectivity in a priority project in oncology. This collaboration includes an undisclosed upfront payment from Sanofi.',
    'FW Link1':
      'Aqemia Announces an Extension of Its First Collaboration With Sanofi About AI and Quantum Physics-driven Drug Discovery in Oncology;;https://firstwordpharma.com/story/5597135/'
  },
  {
    'Date Announced': '16-Jun-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Exelixis',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'BioInvent',
    'Country (Target Company/Partner)': 'Sweden',
    'Region (Target Company/Partner)': 'Europe',
    'Upfront Value ($m)': '25',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-based immuno-oncology therapies',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Under the terms of the agreement, Exelixis will pay BioInvent an upfront fee of $25 million in exchange for rights to select three targets identified using BioInvent's proprietary F.I.R.S.T platform and n-CoDeR library. BioInvent will be responsible for initial target and antibody discovery activities, and characterization of antibody mechanism of action. Exelixis will have the right to exercise an option to in-license any of the target programs upon identification of a development candidate directed to that target. Upon option exercise, Exelixis will pay BioInvent an option exercise fee and will assume responsibility for all future development and commercialization activities for the development candidate, including potential ADC and bispecific antibody engineering activities. In addition, BioInvent will be eligible for success-based development and commercialization milestones, as well as tiered royalties on the annual net sales of any products that are successfully commercialized under the collaboration.",
    'FW Link1':
      'Exelixis and BioInvent Establish Exclusive Option and License Agreement to Develop Novel Antibody-Based Immuno-Oncology Therapies;;https://firstwordpharma.com/story/5596539/'
  },
  {
    'Date Announced': '16-Jun-2022',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'Eli Lilly',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Sidekick Health',
    'Country (Target Company/Partner)': 'Iceland',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Digital therapeutics',
    'Indication(s)': 'Breast cancer',
    'Deal Structure':
      "The project will mark the first phase of the companies' collaboration. The launch of the DTx solution for patients with breast cancer in Germany, as well as patients on a branded Eli Lilly medication, is expected in July. The companies plan to expand the collaboration into other markets and therapeutic areas. Financial terms were not disclosed.",
    'FW Link1':
      'Sidekick, Lilly to launch DTx solution for breast cancer;;https://www.firstwordhealthtech.com/story/5596533/'
  },
  {
    'Date Announced': '15-Jun-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Wugen',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'WuXi Advanced Therapies',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immunotherapy, allogeneic NK cell therapy',
    'Lead Asset(s)': 'WU-NK-101',
    'Indication(s)': 'Acute myelogenous leukaemia, solid tumours',
    'Deal Structure':
      'WuXi ATU will provide manufacturing and testing services for WU-NK-101 to enable the delivery of this innovative cell therapy product to cancer patients.',
    'FW Link1':
      'WuXi ATU and Wugen Announce Manufacturing Partnership to Expedite the Delivery of Novel Cell Cancer Immunotherapies;;https://firstwordpharma.com/story/5596569/'
  },
  {
    'Date Announced': '15-Jun-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Department of Health - Abu Dhabi',
    'Country (Acquiring Company/Partner)': 'United Arab Emirates',
    'Region (Acquiring Company/Partner)': 'Middle East',
    'Target Company/Partner': 'Sanofi',
    'Country (Target Company/Partner)': 'France',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Research, screening, artificial intelligence, vaccines supply chain',
    'Indication(s)': 'Breast cancer, liquid tumours, blood disorders',
    'Deal Structure':
      'Key activities of the agreement include the cooperation to launch a clinical research project and patient recruitment protocol. In relation to hematology and oncology, DoH and Sanofi agreed to pursue the creation of data generation and population research projects for late-stage breast cancer, liquid tumors and blood disorders. Furthermore, both organisations will cocreate a high-risk population screening project that will support in the detection of rare metabolic diseases utilising Artificial Intelligence (AI) tools. Additionally, the collaboration will witness the launch of the Supply Chain Academy, an initiative intended to provide and enhance knowledge related to vaccines supply chain, which will be led by an International Federation of Purchasing and Supply Management (IFPSM) certified entity.',
    'FW Link1':
      'Abu Dhabi partners with the French Sanofi in four strategic healthcare areas;;https://firstwordpharma.com/story/5596637/'
  },
  {
    'Date Announced': '16-Jun-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Invectys',
    'Country (Acquiring Company/Partner)': 'France',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner':
      'University of Texas MD Anderson Cancer Center, Cell Therapy Manufacturing Center',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'CAR T cell therapy',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'HLA-G',
    'Deal Structure':
      'Together with researchers at Invectys, the CTMC team will work to develop a clinical-grade HLA-G targeted CAR T cell therapy for solid tumors that can be produced at scale. The collaboration will facilitate therapeutic development toward a Phase I clinical trial to be co-led by Naing and Samer Srour, M.D., assistant professor of Stem Cell Transplantation and Cellular Therapy at MD Anderson.',
    'FW Link1':
      'MD Anderson, Invectys and CTMC announce strategic collaboration for CAR T cell therapy development;;https://firstwordpharma.com/story/5596851/'
  },
  {
    'Date Announced': '21-Jun-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Fountain Health Insurance',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Grail',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Multi-cancer early detection blood test',
    'Lead Asset(s)': 'Galleri',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'GRAIL, LLC, a healthcare company whose mission is to detect cancer early, when it can be cured, and Fountain Health Insurance ("Fountain Health") announced a partnership that will offer Galleri, GRAIL\'s multi-cancer early detection (MCED) blood test, to Fountain Health customers at 100% coverage as part of its annual wellness benefits.',
    'FW Link1':
      'GRAIL and Fountain Health Insurance Partner to Provide Coverage of Galleri Multi-cancer Early Detection Test;;https://www.firstwordhealthtech.com/story/5598927/'
  },
  {
    'Date Announced': '21-Jun-2022',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Galapagos',
    'Country (Acquiring Company/Partner)': 'Belgium',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'CellPoint',
    'Country (Target Company/Partner)': 'Belgium',
    'Region (Target Company/Partner)': 'Europe',
    'Upfront Value ($m)': '132',
    'Total Deal Value ($m)': '237',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'CAR-T cell therapy',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Under the terms of the agreement, Galapagos is to acquire all outstanding shares of CellPoint in an all-cash transaction against payment of an upfront amount of €125 million, with an additional €100 million to be paid upon achievement of certain milestones.',
    'FW Link1':
      'Galapagos to acquire CellPoint and AboundBio to accelerate access to next-generation cell therapies;;https://firstwordpharma.com/story/5599271/'
  },
  {
    'Date Announced': '21-Jun-2022',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Galapagos',
    'Country (Acquiring Company/Partner)': 'Belgium',
    'Target Company/Partner': 'Abound Bio',
    'Country (Target Company/Partner)': 'United States',
    'Total Deal Value ($m)': '15',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody therapeutics',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Under the terms of the agreement, Galapagos is to acquire all outstanding shares of AboundBio in an all-cash transaction against payment of an amount of $14 million. ',
    'FW Link1':
      'Galapagos to acquire CellPoint and AboundBio to accelerate access to next-generation cell therapies;;https://firstwordpharma.com/story/5599271/'
  },
  {
    'Date Announced': '21-Jun-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'PrognomiQ',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'KSM',
    'Country (Target Company/Partner)': 'Israel',
    'Region (Target Company/Partner)': 'Middle East',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Biomarkers, diagnostics',
    'Indication(s)': 'Colorectal, lung, pancreatic and breast cancers',
    'Deal Structure':
      'This partnership will focus on the discovery and development of dynamic cancer biomarkers, utilizing KSM\'s unique Biobank ("Tipa Biobank"), Israel\'s biggest Biobank, for the early detection of colorectal, lung, pancreatic and breast cancers.',
    'FW Link1':
      "Maccabi's KSM Research and Innovation Center & PrognomiQ Announce Research Collaboration Aimed at Early Cancer Detection;;https://www.firstwordhealthtech.com/story/5598851/"
  },
  {
    'Date Announced': '22-Jun-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'AstraZeneca',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Biocartis',
    'Country (Target Company/Partner)': 'Belgium',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Companion diagnostic, EGFR tyrosine kinase inhibitor',
    'Lead Asset(s)': 'Idylla EGFR CDx Assay, Tagrisso',
    'Indication(s)': 'Non-small-cell lung cancer',
    'Target(s)': 'EGFR',
    'Deal Structure':
      'Under the terms of the agreement, Biocartis and AstraZeneca will co-lead the development and applicable pre-market notification or approval of the Idylla EGFR CDx Assay intended to aid in identifying patients with non-small cell lung cancer (NSCLC) who may respond to treatment with Tagrisso. In addition to FFPE tissue, Biocartis will seek to validate the use of less invasive cytology samples such as fine needle aspirates for use with the Idylla EGFR CDx Assay, to expand patient access to testing.',
    'FW Link1':
      'Biocartis Announces New Agreement with AstraZeneca for the Development and Marketing of a Companion Diagnostic Test for Tagrisso;;https://www.firstwordhealthtech.com/story/5600061/'
  },
  {
    'Date Announced': '22-Jun-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Plus Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Biocept',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cerebrospinal fluid assay, radiotherapeutic',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'CNSide, 186RNL',
    'Indication(s)': 'Leptomeningeal metastases',
    'Deal Structure':
      'Plus Therapeutics and Biocept announced a multi-year agreement to employ Biocept’s cerebrospinal fluid (CSF) assay CNSide in Plus Therapeutics’ ReSPECT-LM Phase 1/2a dose-escalation clinical trial of Rhenium-186 NanoLiposome (186RNL) for the treatment of patients with leptomeningeal metastases (LM).',
    'FW Link1':
      'Plus Therapeutics and Biocept Announce Comprehensive Laboratory Services Agreement for the ReSPECT-LM Trial;;https://www.firstwordhealthtech.com/story/5600849/'
  },
  {
    'Date Announced': '22-Jun-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'AVEO',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Eli Lilly',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'United States, Canada',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Humanised immunoglobulin G1 monoclonal antibody, anti-EGFR antibody',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'ficlatuzumab, Erbitux',
    'Indication(s)':
      'Recurrent or metastatic head and neck squamous cell carcinoma',
    'Target(s)': 'Hepatocyte growth factor, EGFR',
    'Deal Structure':
      "Under the terms of the agreement, Lilly will provide cetuximab clinical drug supply in the U.S. and Canada for AVEO's potential registrational study, which will assess ficlatuzumab with cetuximab in HPV negative R/M HNSCC. AVEO will serve as the study sponsor and will be responsible for trial execution.",
    'FW Link1':
      'AVEO Oncology Announces Clinical Trial Collaboration and Supply Agreement with Eli Lilly and Company for ERBITUX (cetuximab) in North America to Evaluate Ficlatuzumab and Cetuximab in Patients with Recurrent or Metastatic HNSCC;;https://firstwordpharma.com/story/5600507/'
  },
  {
    'Date Announced': '23-Jun-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Isologic',
    'Country (Acquiring Company/Partner)': 'Canada',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Telix',
    'Country (Target Company/Partner)': 'Australia',
    'Region (Target Company/Partner)': 'Oceania',
    'Geographic Remit of Deal': 'Canada',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Imaging agent',
    'Lead Asset(s)': 'Illuccix',
    'Indication(s)': 'Prostate cancer',
    'Deal Structure':
      'Under the terms of the agreement, Isologic will be the commercial distributor of Illuccix in Canada, for a period of four years from the Canadian regulatory approval date, subject to achievement of annual minimum sales commitments.',
    'FW Link1':
      'Telix and Isologic Sign License and Distribution Agreement for Illuccix for Canada;;https://firstwordpharma.com/story/5601323/'
  },
  {
    'Date Announced': '23-Jun-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'McKesson',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'HCA Healthcare',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Clinical research services',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "McKesson Corporation and HCA Healthcare, Inc. announced an agreement to form a joint venture combining McKesson's US Oncology Research (USOR) and HCA Healthcare's Sarah Cannon Research Institute (SCRI). Together, USOR and SCRI will create a fully integrated oncology research organization aimed at expanding clinical research, accelerating drug development and increasing availability and access to clinical trials for community oncology providers and patients, including those in underserved communities. Following the close of the transaction, McKesson will own 51% of the joint venture and have operating control. The joint venture will be governed by a Board of Directors with representation from both McKesson and HCA Healthcare. Separately, McKesson will acquire Genospace, SCRI's personalized medicine platform. By becoming a part of McKesson, Genospace will power the oncology data and analytics capabilities for the joint venture as well as enhance the ability of its provider partners to more efficiently identify the most appropriate therapies or clinical trials for their patients.",
    'FW Link1':
      'McKesson and HCA Healthcare Announce Plans to Form an Oncology Research Joint Venture to Advance Cancer Care and Increase Access to Oncology Clinical Research;;https://firstwordpharma.com/story/5601367/'
  },
  {
    'Date Announced': '24-Jun-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Turning Point Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'University of Texas MD Anderson Cancer Center',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules, kinase inhibitors',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'repotrectinib',
    'Indication(s)': 'Non-small-cell lung cancer, solid tumours',
    'Target(s)': 'ROS1, NTRK',
    'Deal Structure':
      "Turning Point Therapeutics and The University of Texas MD Anderson Cancer Center announced a strategic research and development alliance to expand the evaluation of two of Turning Point's investigational small molecules focusing on precision medicine targets well known in oncology. Under the terms of the alliance's agreement, which spans a five-year period, collaborative preclinical and clinical studies will be conducted in several solid tumors, including non-small cell lung cancers, gastrointestinal malignancies and endocrine cancers. The collaborative studies will be overseen by a joint steering committee. Turning Point will provide funding, study materials and other ongoing support throughout the term of the alliance.",
    'FW Link1':
      'Turning Point Therapeutics and MD Anderson Announce Strategic Alliance to Advance Precision Cancer Therapies;;https://firstwordpharma.com/story/5602287/'
  },
  {
    'Date Announced': '26-Jun-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Antengene',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'BeiGene',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'SINE compound, small molecule XPO1 inhibitor, anti-PD-1 checkpoint inhibitor',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'selinexor, tislelizumab',
    'Indication(s)': 'T and NK-cell lymphoma',
    'Target(s)': 'XPO1, PD-1',
    'Deal Structure':
      "Antengene Corporation Limited has entered into a clinical trial collaboration with BeiGene to evaluate the safety, pharmacokinetics, pharmacodynamics and preliminary efficacy of selinexor in combination with BeiGene's anti-PD-1 checkpoint inhibitor, tislelizumab.",
    'FW Link1':
      'Antengene Announces Clinical Trial Collaboration with BeiGene to Evaluate Selinexor in Combination with Tislelizumab in T and NK-Cell Lymphoma;;https://firstwordpharma.com/story/5602981/'
  },
  {
    'Date Announced': '27-Jun-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Hillstream BioPharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Sapien Biosciences',
    'Country (Target Company/Partner)': 'India',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Ferroptosis inducer, immune checkpoint inhibitors',
    'Lead Asset(s)': 'HSB-1216',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      'Hillstream BioPharma, Inc., a biotechnology company developing novel therapeutic candidates targeting ferroptosis, an emerging new anti-cancer mechanism resulting in iron mediated cell death for drug resistant and devastating cancers, announced a collaboration with Sapien Biosciences to evaluate the synergy of HSB-1216 with immune checkpoint inhibitors.',
    'FW Link1':
      'Hillstream BioPharma Announces Collaboration with Sapien Biosciences in Cancer Treatments;;https://firstwordpharma.com/story/5603099/'
  },
  {
    'Date Announced': '27-Jun-2022',
    'Deal type': 'Terminated',
    'Acquiring Company/Partner': 'Basilea',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Merck & Co.',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'FGFR inhibitor',
    'Lead Asset(s)': 'derazantinib',
    'Indication(s)': 'Intrahepatic cholangiocarcinoma',
    'Target(s)': 'FGFR',
    'Deal Structure':
      'With regard to the FGFR inhibitor derazantinib, the company has decided to terminate the license agreement and return the rights to Merck & Co, Inc. by the end of the year.',
    'FW Link1':
      'Basilea reports progress on the implementation of its strategy to focus on anti-infectives;;https://firstwordpharma.com/story/5602983/'
  },
  {
    'Date Announced': '27-Jun-2022',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Ipsen',
    'Country (Acquiring Company/Partner)': 'France',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Epizyme',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Total Deal Value ($m)': '247',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecule',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Tazverik',
    'Indication(s)': 'Epithelioid sarcoma, follicular lymphoma',
    'Target(s)': 'EZH2a',
    'Deal Structure':
      "Ipsen entered into a definitive merger agreement to acquire Epizyme for $1.45 per share in cash, or approximately $247 million, gaining the latter's EZH2a inhibitor Tazverik (tazemetostat). The deal, which is expected to close in the third quarter, also includes a contingent value right (CVR) of $1.00 per share. The purchase will also give Ipsen rights to Epizyme's oral SETD2 inhibitor EZM0414, which is currently being evaluated in a Phase I/Ib trial in adults with relapsed or refractory multiple myeloma and diffuse large B-cell lymphoma, as well as a portfolio of preclinical programmes focusing on epigenetic targets.",
    'FW Link1':
      'Ipsen to buy Epizyme, picking up approved cancer drug Tazverik for $247 million;;https://firstwordpharma.com/story/5602967/',
    'FW Link2':
      'Completion of acquisition announced August 12, 2022;;https://firstwordpharma.com/story/5636321/'
  },
  {
    'Date Announced': '27-Jun-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Alaunos Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'National Cancer Institute',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Personalised TCR-T cell therapy',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      "Alaunos Therapeutics has extended its Cooperative Research and Development Agreement (CRADA) with the National Cancer Institute (NCI), an institute of the National Institutes of Health, using the Alaunos Sleeping Beauty technology through January 2025. Under the terms of the CRADA, the NCI will work to generate proof of concept utilizing the Company's proprietary non-viral Sleeping Beauty technology for personalized TCR-T cell therapy.",
    'FW Link1':
      'Alaunos Therapeutics and the National Cancer Institute Extend Cooperative Research and Development Agreement for Development of Personalized TCR-T Cell Therapies to 2025;;https://firstwordpharma.com/story/5603217/'
  },
  {
    'Date Announced': '28-Jun-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Acrivon Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Akoya Biosciences',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Companion diagnostic, DNA damage response inhibitor therapy',
    'Lead Asset(s)': 'OncoSignature, ACR-368',
    'Indication(s)':
      'Solid cancers, ovarian, endometrial, and urothelial cancer',
    'Deal Structure':
      "The OncoSignature test, developed by Acrivon, will be run on Akoya's PhenoImager solution during clinical development and, pending ACR-368 approval and commercialization, will enable physicians to identify and treat the patients most likely to respond to the therapy. Akoya, in partnership with Acrivon, will develop, clinically validate, and seek regulatory co-approval for the OncoSignature test, and, pending ACR-368 approval, commercialize the test as the exclusive provider of the companion diagnostic required for prescribing ACR-368.",
    'FW Link1':
      'Akoya Biosciences to Partner with Acrivon Therapeutics for the Clinical Development of Acrivon’s Proprietary OncoSignature Test into a Companion Diagnostic;;https://www.firstwordhealthtech.com/story/5604517/'
  },
  {
    'Date Announced': '28-Jun-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Lawson Health Research Institute',
    'Country (Acquiring Company/Partner)': 'Canada',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner':
      'Centre for Probe Development and Commercialization',
    'Country (Target Company/Partner)': 'Canada',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Canada',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostic agent, PET',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'PSMA-1007',
    'Indication(s)': 'Prostate cancer',
    'Target(s)': 'PSMA',
    'Deal Structure':
      'CPDC has entered into a commercial sublicense agreement with Lawson Health Research Institute (Lawson), to utilize [18F]PSMA-1007 as a critically important prostate cancer diagnostic tool for studies involving participants residing in proximity to Lawson in the London, Ontario region.',
    'FW Link1':
      'CPDC executes its first commercial sublicensing agreement with Lawson Health Research Institute for PSMA-1007, expanding access to the prostate cancer diagnostic agent in Ontario;;https://firstwordpharma.com/story/5604345/'
  },
  {
    'Date Announced': '28-Jun-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'GenFleet Therapeutics',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'BioLineRx',
    'Country (Target Company/Partner)': 'Israel',
    'Region (Target Company/Partner)': 'Middle East',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antagonist of CXCR4',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'Motixafortide',
    'Indication(s)': 'Pancreatic ductal adenocarcinoma',
    'Target(s)': 'CXCR4',
    'Deal Structure':
      'Under the terms of the agreement, GenFleet will fully fund, design and execute a randomized Phase 2b clinical trial that will enroll approximately 200 first-line metastatic PDAC patients in China. This randomized controlled study will aim to evaluate the superiority of Motixafortide in combination with an anti-PD-1 and chemotherapy compared to chemotherapy alone, the current standard of care. As part of the collaboration, BioLineRx will supply Motixafortide, while GenFleet will supply the other study drugs for the trial. Trial oversight will be administered by a Joint Development Committee. GenFleet will be eligible to receive low-to-mid-single digit tiered percentage royalties on future Motixafortide sales, if approved.',
    'FW Link1':
      'Press Release\nBioLineRx Announces Collaboration Agreement with GenFleet Therapeutics to Further Develop Motixafortide in Pancreatic Ductal Adenocarcinoma (PDAC);;https://firstwordpharma.com/story/5604301/'
  },
  {
    'Date Announced': '28-Jun-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Ono Pharmaceutical',
    'Country (Acquiring Company/Partner)': 'Japan',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Fate Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Off-the-shelf, iPSC-derived CAR NK and CAR T-cell cancer immunotherapies',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      'Under the terms of the amended Collaboration and Option Agreement, Fate will advance iPSC-derived CAR NK and CAR T-cell product candidates to a pre-defined preclinical milestone, at which point ONO has an option to assume responsibility for worldwide development and commercialization with Fate retaining the right to jointly develop and commercialize in the United States and Europe. Fate retains all rights of manufacture of collaboration products on a global basis.',
    'FW Link1':
      'Fate Therapeutics Announces Expansion of Solid Tumor Collaboration with ONO Pharmaceutical for Off-the-Shelf, iPSC-derived CAR NK and CAR T-Cell Cancer Immunotherapies;;https://firstwordpharma.com/story/5604363/',
    'FW Link2':
      'Exercise by Ono of option to FT825/ONO-8250 announced November 7, 2022;;https://firstwordpharma.com/story/5672405/'
  },
  {
    'Date Announced': '28-Jun-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Henlius Biotech',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Palleon Pharmaceuticals',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Bifunctional sialidase therapies',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Under the terms of the agreement, Palleon will perform research and the parties will then share preclinical and global clinical development responsibilities and costs for the Bifunctional HER2-Sialidase and a second bifunctional sialidase. Henlius has an exclusive license to the two investigational therapies in China (including Hong Kong, Macau, and Taiwan), while Palleon retains all other global rights and, for the second product, receives a royalty-bearing exclusive license to Henlius' antibody technology outside China. Palleon received an upfront payment and is further eligible to receive up to $196.5 million in certain predetermined R&D and commercial milestones, in addition to royalties upon Henlius commercialization in China.",
    'FW Link1':
      'Palleon Pharmaceuticals and Henlius Enter into Strategic Collaboration to Develop Bifunctional Sialidase Therapies;;https://firstwordpharma.com/story/5604405/'
  },
  {
    'Date Announced': '28-Jun-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'IV BioHoldings',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Sonrai Analytics',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Artificial intelligence, early detection precision diagnostics',
    'Indication(s)': 'Lung cancer, breast cancer',
    'Deal Structure':
      "Sonrai's customized, advanced AI applications will bolster IVBH's analytic capabilities, allowing for improvements on clinical insights for the company's pipeline of noninvasive diagnostics for lung cancer, non-alcoholic fatty liver disease and breast cancer.",
    'FW Link1':
      "IVBH's Strategic Partnership with Sonrai Analytics to Advance Novel Early Detection Precision Diagnostics;;https://www.firstwordhealthtech.com/story/5604495/"
  },
  {
    'Date Announced': '28-Jun-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Transgene, BioInvent',
    'Country (Acquiring Company/Partner)': 'France',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Merck & Co.',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Oncolytic virus, anti-PD-1 therapy',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'BT-001, Keytruda',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'CTLA-4, PD-1',
    'Deal Structure':
      'Under the terms of the supply agreement, MSD will provide pembrolizumab to be used in combination with BT-001 in the ongoing Phase I/IIa clinical trial. BT-001 is being co-developed as part of a 50/50 collaboration on oncolytic viruses between Transgene and BioInvent.',
    'FW Link1':
      'Transgene and BioInvent Announce Clinical Trial Collaboration and Supply Agreement with MSD to Evaluate BT-001 in Combination with KEYTRUDA;;https://firstwordpharma.com/story/5604023/'
  },
  {
    'Date Announced': '27-Jun-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Astellas',
    'Country (Acquiring Company/Partner)': 'Japan',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Sutro Biopharma',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '90',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immunostimulatory antibody-drug conjugates',
    'Indication(s)': 'Cold tumours',
    'Deal Structure':
      'As part of the agreement, Sutro will engage in research and preclinical studies to identify candidate compounds, with Astellas then pursuing clinical development.',
    'FW Link1':
      'Astellas, Sutro ink immunostimulatory antibody-drug conjugate deal;;https://firstwordpharma.com/story/5604101/'
  },
  {
    'Date Announced': '29-Jun-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'HALO Diagnostics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'IncellDx',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Imaging, molecular diagnostics, biomarkers',
    'Indication(s)': 'Prostate and bladder cancers',
    'Deal Structure':
      'HALO Diagnostics, a precision diagnostics leader, has joined forces with IncellDx, a single-cell, molecular diagnostics company, to develop innovative testing for prostate and bladder cancers.',
    'FW Link1':
      'HALO Diagnostics, IncellDx Partner to Advance Novel Testing, Improve Standard of Care for Prostate and Bladder Cancers;;https://www.firstwordhealthtech.com/story/5605295/'
  },
  {
    'Date Announced': '29-Jun-2022',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'Accord BioPharma ',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'EVERSANA',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'United States',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Androgen deprivation therapy, GnRH agonist',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Camcevi',
    'Indication(s)': 'Advanced prostate cancer',
    'Deal Structure':
      'EVERSANA and Accord BioPharma, the U.S. specialty division of Intas Pharmaceuticals, Ltd., announced a partnership to support the recent launch of CAMCEVI (leuprolide) 42mg injection emulsion for the treatment of advanced prostate cancer in adults. Accord BioPharma is heading distribution in the United States.',
    'FW Link1':
      'Accord BioPharma and EVERSANA Announce Partnership to Support the Launch of CAMCEVI for the Treatment of Advanced Prostate Cancer in Adults in the U.S.;;https://firstwordpharma.com/story/5605007/'
  },
  {
    'Date Announced': '29-Jun-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'BriaCell',
    'Country (Acquiring Company/Partner)': 'Canada',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Harvard Medical School',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Target discovery, novel anti-cancer treatments',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'The research collaboration will focus on the discovery and development of novel targets to enhance tumor cell responsiveness to chemotherapy and immunotherapies in specific cancers including lung, head and neck, cervical, and bladder cancers. BriaCell will have the option to negotiate a license to innovations owned by Harvard University that arise under the one-year collaboration.',
    'FW Link1':
      'BriaCell Enters Research Agreement to Identify Novel Targets for Cancer Treatment;;https://firstwordpharma.com/story/5605191/'
  },
  {
    'Date Announced': '29-Jun-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Harbinger Health',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Sarah Cannon Research Institute',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'United States',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Early cancer detection platform, blood-based testing',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Harbinger Health, a biotechnology company pioneering the detection of early cancer, and Sarah Cannon Research Institute (SCRI) announced the initiation of a 10,000-participant clinical study designed to validate and further develop Harbinger's novel platform technology for blood-based early cancer detection.",
    'FW Link1':
      'Harbinger Health and Sarah Cannon Research Institute Launch Clinical Study to Validate and Advance Development of Harbinger’s Novel Early Cancer Detection Platform;;https://www.firstwordhealthtech.com/story/5605041/'
  },
  {
    'Date Announced': '30-Jun-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Royalty Pharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Target Company/Partner': 'Blueprint Medicines',
    'Country (Target Company/Partner)': 'United States',
    'Upfront Value ($m)': '175',
    'Total Deal Value ($m)': '340',
    'Geographic Remit of Deal': 'Outside of US (excl. Greater China)',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small moleule drugs',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Gavreto',
    'Indication(s)': 'Non-small-cell lung cancer, medullary thyroid cancer',
    'Target(s)': 'RET',
    'Deal Structure':
      'Blueprint said the Royalty Pharma agreement monetises royalties receivable from Gavreto sales by Roche outside of the US, not including China. As part of the deal, Blueprint will receive an upfront payment of $175 million, plus as much as $165 million in potential milestones based on future sales.',
    'FW Link1':
      'Blueprint Medicines inks royalty deals worth up to $1.25 billion;;https://firstwordpharma.com/story/5605899/'
  },
  {
    'Date Announced': '30-Jun-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Sixth Street',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Blueprint Medicines',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '250',
    'Total Deal Value ($m)': '910',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Kinase inhibitors',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Ayvakit/Ayvakyt',
    'Indication(s)': 'Systemic mastocytosis, gastrointestinal stromal tumours',
    'Deal Structure':
      'Sixth Street will pay Blueprint $250 million upfront for royalties on Ayvakit/Ayvakyt, which is approved in the US and Europe for both advanced systemic mastocytosis (SM) and gastrointestinal stromal tumours, as well as royalties on BLU-263, a next-generation KIT inhibitor in late-stage development for non-advanced SM. The deal also includes up to $400 million in a senior secured credit facility, with the company initially drawing down $150 million, and $260 million in a potential credit facility to support any buy-side business opportunities. ',
    'FW Link1':
      'Blueprint Medicines inks royalty deals worth up to $1.25 billion;;https://firstwordpharma.com/story/5605899/'
  },
  {
    'Date Announced': '3-Jun-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Kyowa Kirin',
    'Country (Acquiring Company/Partner)': 'Japan',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Synaffix',
    'Country (Target Company/Partner)': 'Netherlands',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Kyowa Kirin Co., Ltd. (Kyowa Kirin, TSE:4151), a global specialty pharmaceutical company that strives to create new value through the pursuit of advances in life sciences and technologies, has expanded the license agreement with Synaffix (announced in August 2021) by adding a third ADC target to its research evaluation and development efforts under this deal. In addition it exercised its option to take exclusive development and commercialization rights for an undisclosed ADC target under the terms of this agreement. Under the terms of the amended license agreement, Synaffix will receive an immediate payment of $5 million and is eligible to receive total potential payments for the first ADC program alone of up to $171m plus royalties on commercial sales.',
    'FW Link1':
      'Kyowa Kirin Expands License Agreement with Synaffix and Takes Exclusive Target Rights Based on Latest Positive ADC Data;;https://firstwordpharma.com/story/5605777/'
  },
  {
    'Date Announced': '4-Jul-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Aurigene',
    'Country (Acquiring Company/Partner)': 'India',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'EQRx',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecule drugs',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'As part of the deal, Aurigene will lead drug discovery and preclinical development efforts, while EQRx will assume responsibility for clinical development, manufacturing, regulatory and commercialisation efforts. The parties will share funding for discovery and development of the programmes, as well as costs tied to the commercialisation of any eventual drug candidates.',
    'FW Link1':
      "Dr Reddy's Laboratories: Aurigene inks drug discovery, development pact with US-based EQRx;;https://firstwordpharma.com/story/5607333/"
  },
  {
    'Date Announced': '5-Jul-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Sanofi',
    'Country (Acquiring Company/Partner)': 'France',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Skyhawk Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '54',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Small molecules, alternative modulation of RNA',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Sanofi has inked an exclusive worldwide collaboration agreement with Skyhawk Therapeutics to develop small molecules that modulate RNA splicing for challenging oncology and immunology targets. Under the collaboration agreement, Sanofi will pay Skyhawk $54 million upfront. Skyhawk will grant Sanofi exclusive licenses to worldwide intellectual property rights to candidates discovered and developed under the collaboration that are directed to program targets. Following DC status, Sanofi will assume responsibility for further development and commercialization. Skyhawk is also eligible to receive over $2 billion in potential milestone payments, as well as potential royalties on future sales.',
    'FW Link1':
      'Sanofi teams up with Skyhawk on therapies that correct RNA expression;;https://firstwordpharma.com/story/5607991/'
  },
  {
    'Date Announced': '5-Jul-2022',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'AstraZeneca',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'TeneoTwo',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '100',
    'Total Deal Value ($m)': '1,265',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Bispecific antibody T-cell engager',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'TNB-486',
    'Indication(s)':
      'B-cell non-Hodgkin lymphoma, diffuse large B-cell lymphoma, follicular lymphoma',
    'Target(s)': 'CD19, CD3',
    'Deal Structure':
      'Under the agreed terms, AstraZeneca will acquire all outstanding equity of TeneoTwo for $100 million upfront, with the latter eligible for R&D-related milestones of up to $805 million and additional payments of up to $360 million based on the achievement of certain commercial targets.',
    'FW Link1':
      'AstraZeneca to buy TeneoTwo, adding T-cell engager to cancer pipeline;;https://firstwordpharma.com/story/5607883/',
    'FW Link2':
      'Completion of acquisition announced August 11, 2022;;https://firstwordpharma.com/story/5634667/'
  },
  {
    'Date Announced': '5-Jul-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Danuo',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Enosi Life Sciences',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Greater China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Growth factor trap, TNFR1 blocker',
    'Lead Asset(s)': 'EN-2000, EN-1000',
    'Indication(s)': 'Cancer',
    'Target(s)': 'Inflammatory growth factors, TNFR1',
    'Deal Structure':
      'Danuo will provide $5M in upfront licensing fees and certain conditional funding. With technical support from Enosi, Danuo will fund development in China and provide Enosi with data to use in its own filings in ROW. Including up-front payments, Enosi estimates the value of this collaboration to be more than $100M as the Company moves through the discovery and development phases of its treatments. Both Enosi and Danuo expect the first patient to be treated in China within the next 2-3 years.',
    'FW Link1':
      'Enosi Therapeutics Partners with Danuo Science Group to Accelerate Multiple Therapeutic Programs;;https://firstwordpharma.com/story/5608587/'
  },
  {
    'Date Announced': '5-Jul-2022',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Syros Pharmaceuticals',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Tyme Technologies',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecule medicines',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'tamibarotene',
    'Indication(s)': 'Cancer, myelodysplastic syndrome',
    'Target(s)': 'RARα',
    'Deal Structure':
      'Syros Pharmaceuticals and TYME Technologies have entered into a definitive merger agreement pursuant to which Syros will acquire TYME, including its pipeline assets and net cash at closing which after accounting for wind-down and transaction expenses is currently estimated to be approximately $60 million.',
    'FW Link1':
      'Syros to Raise Approximately $190 Million Through Merger with TYME Technologies and Concurrent Private Placement;;https://firstwordpharma.com/story/5607939/'
  },
  {
    'Date Announced': '5-Jul-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'BriaCell',
    'Country (Acquiring Company/Partner)': 'Canada',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Waisman Biomanufacturing',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Off-the-shelf personalised immunotherapy',
    'Lead Asset(s)': 'Bria-Pros',
    'Indication(s)': 'Prostate cancer',
    'Deal Structure':
      'Under the terms of the agreement, Waisman will be responsible for good manufacturing practice (GMP) manufacturing of Bria-Pros for anticipated use in clinical studies.',
    'FW Link1':
      'BriaCell Partners with Waisman Biomanufacturing to Manufacture and Supply Prostate Cancer Immunotherapy;;https://firstwordpharma.com/story/5608229/'
  },
  {
    'Date Announced': '6-Jul-2022',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Portage Biotech',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Tarus Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '21',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Adenosine receptor antagonists',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'TT-10',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'A2A',
    'Deal Structure':
      'Under the terms of the agreement, Portage will acquire Tarus in exchange for 2,425,999 PRTG shares along with the assumption of $3 million of liabilities. Additionally, payments of up to $32M in Portage shares or cash would be triggered upon achievement of future development and sales milestones.',
    'FW Link1':
      'Portage Biotech Bolsters Pipeline with Acquisition of Four Candidates Targeting the Adenosine Pathway;;https://firstwordpharma.com/story/5609429/'
  },
  {
    'Date Announced': '6-Jul-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Adicon',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Guardant Health',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Comprehensive genomic profiling tests',
    'Lead Asset(s)':
      'Guardant360, GuardantOMNI, Guardant360 TissueNext, Guardant Reveal',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Financial terms of the deal were not disclosed, but Guardant will license its liquid biopsy technology to Adicon. This includes the Guardant360 and GuardantOMNI tests, as well as the Guardant360 TissueNext tissue-based biopsy for patients with any solid cancerous tumour. Additionally, Guardant Reveal will be offered to biopharmaceutical firms for early-stage cancer R&D.',
    'FW Link1':
      'Guardant to offer genomic tests for cancer drug development in China;;https://www.firstwordhealthtech.com/story/5608885/'
  },
  {
    'Date Announced': '7-Jul-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Exelixis',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Ryvu Therapeutics',
    'Country (Target Company/Partner)': 'Poland',
    'Region (Target Company/Partner)': 'Europe',
    'Upfront Value ($m)': '3',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecule STING agonists',
    'Indication(s)': 'Cancer',
    'Target(s)': 'STING',
    'Deal Structure':
      "Under the terms of the agreement, Exelixis will pay Ryvu an upfront fee of $3 million in exchange for certain rights to Ryvu's STING agonist small molecules, which Exelixis will seek to incorporate into targeted therapies such as ADCs. Exelixis will lead all research activities and, upon selection of each development candidate, will be responsible for all development and commercialization activities. Ryvu will provide expert guidance and know-how during the early research phase of the partnership, and will be eligible to receive development, regulatory and commercialization milestone payments, as well as tiered royalties on the annual net sales of any products that are successfully commercialized under the collaboration. Ryvu will also retain all development and commercial rights to develop its STING agonist portfolio as standalone small molecules.",
    'FW Link1':
      'Exelixis and Ryvu Therapeutics Establish Exclusive License Agreement to Develop Novel STING Agonist-Based Targeted Cancer Therapies;;https://firstwordpharma.com/story/5609975/'
  },
  {
    'Date Announced': '7-Jul-2022',
    'Deal type': 'Product Acquisition',
    'Acquiring Company/Partner': 'Capnopharm',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Capnomed',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Intraperitoneal drug delivery, pressurised intraperitoneal aerosol chemotherapy (PIPAC)',
    'Lead Asset(s)': 'Capnopen',
    'Indication(s)': 'Peritoneal cancer',
    'Deal Structure':
      'Capnopharm GmbH announced it has entered into an asset purchase agreement to acquire all operating assets from Capnomed GmbH.',
    'FW Link1':
      'Capnopharm announces asset purchase of Capnomed as PIPAC shows superiority over IV Chemotherapy in first randomised data presented at ASCO 2022;;https://firstwordpharma.com/story/5610051/'
  },
  {
    'Date Announced': '7-Jul-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'RefleXion',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Mirada Medical',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Imaging, image registration algorithms',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "RefleXion Medical, a therapeutic oncology company pioneering the use of biology-guided radiotherapy (BgRT)* for all stages of cancer, announced a non-exclusive, worldwide licensing agreement with Mirada Medical. The agreement allows for the integration of Mirada's RegEngine image registration algorithms into the RefleXion X1 treatment planning software.",
    'FW Link1':
      'RefleXion Announces Licensing Agreement with Mirada Medical to Advance Personalized Cancer Treatment;;https://www.firstwordhealthtech.com/story/5610127/'
  },
  {
    'Date Announced': '7-Jul-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Roche',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Medial EarlySign',
    'Country (Target Company/Partner)': 'Israel',
    'Region (Target Company/Partner)': 'Middle East',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Artificial intelligence, machine learning, lung cancer identification',
    'Lead Asset(s)': 'LungFlag',
    'Indication(s)': 'Lung cancer',
    'Deal Structure':
      'Medial EarlySign, a pioneering company developing Artificial Intelligence (AI) based clinical predictive analytics, announced the signing of a new partnership with Roche, which will focus on innovative, evidence-based solutions for early detection of lung cancer.',
    'FW Link1':
      'EarlySign Announces Strategic Collaboration Agreement with Roche on AI Solution for Early Detection of Lung Cancer;;https://www.firstwordhealthtech.com/story/5610233/'
  },
  {
    'Date Announced': '7-Jul-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Undisclosed',
    'Target Company/Partner': 'Absci',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "The collaboration calls for Absci to generate up to seven novel Bionic Antibodies incorporating nsAAs. Because these nsAAs are not among the twenty amino acids that make up all of life's proteins, they can provide unique handles for further chemical modifications when they are substituted for standard amino acids in a protein. The site-specific targeting of the chemical warheads to these nsAAs is expected to allow efficient generation of highly homogeneous and well-behaved ADC drug candidates. Financial terms are not being disclosed, but they include an upfront payment as well as future milestones and royalties associated with each program.",
    'FW Link1':
      'Absci Enters Multi-Program Collaboration with Emerging Biotech Partner;;https://firstwordpharma.com/story/5610009/'
  },
  {
    'Date Announced': '7-Jul-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'AbTis',
    'Country (Acquiring Company/Partner)': 'Korea, South',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'WuXi XDC',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Under the partnership, AbTis will have access to WuXi XDC's integrated services for linker and payload manufacturing, ADC conjugation process development, ADC formulation and drug product manufacturing process development, as well as drug substance and drug product manufacturing.",
    'FW Link1':
      'WuXi XDC and AbTis Sign Memorandum of Understanding for Development and Manufacturing of Antibody Drug Conjugates;;https://firstwordpharma.com/story/5610811/'
  },
  {
    'Date Announced': '8-Jul-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Swedish Orphan Biovitrum',
    'Country (Acquiring Company/Partner)': 'Sweden',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'ADC Therapeutics',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Upfront Value ($m)': '55',
    'Total Deal Value ($m)': '435',
    'Geographic Remit of Deal': 'Europe and select international territories',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugate',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Zynlonta',
    'Indication(s)': 'Diffuse large B-cell lymphoma',
    'Target(s)': 'CD19',
    'Deal Structure':
      'Under the agreed terms, Sobi will pay $55 million upfront for rights to Zynlonta in all haematologic and solid tumour indications outside of the US, China, Singapore and Japan. Meanwhile, Sobi will pay $50 million on approval of the therapy in the EU, where a decision is expected in the first quarter of 2023, with ADC eligible for up to $330 million in milestones, as well as sales royalties from mid-teens to mid-twenties percent. Sobi added that while ADC is responsible for clinical development of Zynlonta, the company will contribute 25% of the direct costs up to a cap of $10 million per year.',
    'FW Link1':
      "Sobi nabs rights to ADC Therapeutics' Zynlonta;;https://firstwordpharma.com/story/5610819/"
  },
  {
    'Date Announced': '12-Jul-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'LegoChem',
    'Country (Acquiring Company/Partner)': 'Korea, South',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Glycotope',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugate',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Under the terms of the agreement LCB has the right to exercise its option for worldwide exclusive rights to develop and commercialize the selected antibody as ADC, upon successful completion of a feasibility study. If LCB exercises these rights, Glycotope will receive an upfront payment as well as development and sales milestone payments plus royalties.',
    'FW Link1':
      'LegoChem Biosciences and Glycotope Announce Research Collaboration and License Agreement for an Antibody for use as Antibody Drug Conjugate;;https://firstwordpharma.com/story/5613007/'
  },
  {
    'Date Announced': '12-Jul-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Nilogen Oncosystems',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Scailyte',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Drug testing, artificial intelligence, omics',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "This long-term strategic partnership aims at integrating Nilogen Oncosytems' proprietary 3D-EXplore Platform of fresh patient tumor tissue with Scailyte's AI Platform ScaiVision to develop a robust cutting-edge omics engine for personalized drug-tumor interactions to understand drug MoA and predict tumor response in patients for clinical trials enrichment.",
    'FW Link1':
      'Nilogen Oncosystems Announces Partnership with Scailyte to Provide Clinically Relevant Translational Platform Supporting Development of Immuno-Oncology Therapeutics in Oncology Clinical Trials and Ultimately to Support Personalized Medicine for Patient;;https://www.firstwordhealthtech.com/story/5613693/'
  },
  {
    'Date Announced': '12-Jul-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Memorial Sloan Kettering Cancer Center',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'BostonGene',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Precision medicine',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "The collaboration will support pre-clinical and clinical research activities at MSK which utilize BostonGene's CLIA-certified and CAP-accredited high complexity molecular laboratory and advanced computational algorithms that identify and validate novel precision medicine approaches.",
    'FW Link1':
      'BostonGene Announces New Master Agreement with Leading Cancer Center in New York City;;https://www.firstwordhealthtech.com/story/5613417/'
  },
  {
    'Date Announced': '12-Jul-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Personalis',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'GenomOncology',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Variant analysis, genomics, molecular residual disease detection',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Personalis selected GenomOncology to facilitate variant review, interpretation, and reporting for NeXT Personal. The GO Pathology Workbench streamlines variant analysis for all panels due to its intuitive workflow and extensive knowledge base of evidence, guideline-based therapies, and biomarker-based clinical trials, providing the insights needed for clinicians to guide patient decisions.',
    'FW Link1':
      'GenomOncology Selected by Personalis to Support MRD and SNV Analysis for NeXT Personal Assay;;https://www.firstwordhealthtech.com/story/5613505/'
  },
  {
    'Date Announced': '13-Jul-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Merck & Co.',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Orion',
    'Country (Target Company/Partner)': 'Finland',
    'Region (Target Company/Partner)': 'Europe',
    'Upfront Value ($m)': '290',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Steroid synthesis inhibitor',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'ODM-208',
    'Indication(s)': 'Metastatic castration-resistant prostate cancer',
    'Target(s)': 'CYP11A1',
    'Deal Structure':
      'Under the agreement, Merck and Orion will co-develop and co-commercialise ODM-208, with the latter responsible for the manufacture of clinical and commercial supply of the oral, non-steroidal inhibitor of CYP11A1. The companies noted that as part of the transaction, both parties have an option to convert the initial agreement into a global exclusive license to Merck. If the option is exercised, Merck would assume full responsibility for all development and commercialisation expenses, with Orion eligible to receive milestone payments as well as tiered double-digit sales royalties.',
    'FW Link1':
      'Merck & Co. inks deal with Orion to develop prostate cancer drug;;https://firstwordpharma.com/story/5613997/'
  },
  {
    'Date Announced': '12-Jul-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'ACT Therapeutics',
    'Country (Acquiring Company/Partner)': 'Korea, South',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'GenScript',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'CAR-T cell therapies',
    'Indication(s)': 'Solid cancer',
    'Deal Structure':
      "GenScript ProBio and ACT Therapeutics are in the process of signing a contract for plasmid and virus vector development and production for the first pipeline of ACT Therapeutics' Advanced CAR-T Platform (ACT platform), which will also be commissioned to produce raw materials for ACT Therapeutics' subsequent pipeline. With this agreement, GenScript ProBio has become a global partner that can support ACT platforms for ACT therapeutics.",
    'FW Link1':
      'GenScript ProBio Signs MOU to Form Strategic Partnership with ACT Therapeutics to Development of New CAR-T Cell Therapies;;https://firstwordpharma.com/story/5614019/'
  },
  {
    'Date Announced': '14-Jul-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Oncoheroes Biosciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': "Vall d'Hebron Research Institute",
    'Country (Target Company/Partner)': 'Spain',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Inhibitors, Hedgehog pathway',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Paediatric sarcomas',
    'Deal Structure':
      "Oncoheroes and VHIR's collaboration will focus on the Hedgehog pathway, where recent studies led by Dr. Roma have identified novel targets for pediatric sarcomas with high therapeutic potential, not disclosed yet. The academic group has demonstrated that the inhibition of some specific targets results in killing selectively cancerous cells in both in vitro and in vivo models. Oncoheroes will rationally design new inhibitors and conduct high throughput screening campaigns for identifying new active compounds that will be later optimized in collaboration with Dr. Roma.",
    'FW Link1':
      'Vall d’Hebron Research Institute and Oncoheroes Biosciences Announced a New Partnership in Pediatric Sarcomas;;https://firstwordpharma.com/story/5615199/'
  },
  {
    'Date Announced': '14-Jul-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Memorial Sloan Kettering Cancer Center',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Repertoire Immune Medicines',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'T cell response to therapy, DECODE technology, cell therapies',
    'Indication(s)': 'HPV-associated cancers',
    'Deal Structure':
      "The research collaboration aims to identify the antigens present on tumors as well as the T cells and specific T cell receptors (TCRs) that recognize tumor-specific antigens. Using Repertoire's DECODE platform to map the adaptive immune system's response can inform the development of novel cell-based therapeutics by confirming the relevant targets for cell-based treatments. Repertoire's research collaboration with MSK will also seek to identify the TCR repertoire that is engaged and results in successful treatment outcomes with anti-PD-1 treatment and with conventional chemotherapy-radiation treatment. By identifying cancer-specific epitopes and the T cells that recognize these epitopes in successfully treated patients, it may be possible to define the optimal immunogenic profile for a proposed treatment. This map of the immune system's response could inform treatment considerations for patients with inadequately treated disease.",
    'FW Link1':
      'Repertoire Immune Medicines Enters New Research Collaboration to Discover the T Cell Response to Therapy in HPV-Associated Cancers;;https://firstwordpharma.com/story/5615149/'
  },
  {
    'Date Announced': '15-Jul-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'KaliVir',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'SIGA Technologies',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Oncolytic viral immunotherapy, antiviral drug',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'TPOXX',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "SIGA Technologies announced a collaboration with KaliVir Immunotherapeutics to make TPOXX (tecovirimat) available for use with KaliVir's proprietary oncolytic vaccinia immunotherapy platform. This novel oncolytic platform includes multiple proprietary genetic modifications that can be combined to generate a unique oncolytic virus that has been optimized for systemic delivery and anti-tumor immune stimulation. Under this partnership, SIGA is providing its TPOXX oral capsules to support future clinical programs.",
    'FW Link1':
      'SIGA Announces Oncology Collaboration with KaliVir Immunotherapeutics;;https://firstwordpharma.com/story/5615947/'
  },
  {
    'Date Announced': '18-Jul-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'PMV Pharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Merck & Co.',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Small-molecule p53 reactivator, anti-PD-1 therapy',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'PC14586, Keytruda',
    'Indication(s)': 'Advanced solid tumours',
    'Target(s)': 'p53 Y220C mutation',
    'Deal Structure':
      'PMV Pharma will evaluate PC14586 in combination with KEYTRUDA as a separate arm of the company’s ongoing Phase 1/2 PYNNACLE trial in patients with advanced solid tumors. Approximately 36 patients are expected to be enrolled in the combination arm of the trial. This combination arm will assess the safety, tolerability, pharmacokinetics, pharmacodynamics, and preliminary efficacy of PC14586 in combination with KEYTRUDA in patients with advanced solid tumors harboring a p53 Y220C mutation. Under the terms of the agreement, PMV Pharma will sponsor the trial and Merck will supply KEYTRUDA.',
    'FW Link1':
      'PMV Pharmaceuticals Announces a Clinical Trial Collaboration with Merck to Evaluate PC14586 in Combination with KEYTRUDA (pembrolizumab) in Patients with Advanced Solid Tumors;;https://firstwordpharma.com/story/5617165/'
  },
  {
    'Date Announced': '19-Jul-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Oryzon Genomics',
    'Country (Acquiring Company/Partner)': 'Spain',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'National Cancer Institute',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'LSD1 inhibitor',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'iadademstat',
    'Indication(s)': 'Solid and haematological cancers',
    'Target(s)': 'LSD1',
    'Deal Structure':
      "Under the terms of the agreement, Oryzon and the NCI will collaborate on potential further clinical development of Oryzon's clinical stage LSD1 inhibitor, iadademstat, in different types of solid and hematological cancers.",
    'FW Link1':
      'ORYZON Enters Cooperative Research and Development Agreement (CRADA) with U.S. National Cancer Institute to Develop Iadademstat in Different Cancers;;https://firstwordpharma.com/story/5618047/'
  },
  {
    'Date Announced': '19-Jul-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Clovis Oncology',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'NorthStar Medical Radioisotopes',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Therapeutic radioisotope, peptide-targeted radionuclide therapy',
    'Lead Asset(s)': 'actinium-225, FAP-2286',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'Fibroblast activation protein',
    'Deal Structure':
      "Under terms of the agreement, NorthStar will provide Clovis with its environmentally preferred, high purity non-carrier-added (n.c.a.) Ac-225. Clovis plans to use NorthStar's Ac-225 to radiolabel its lead peptide-targeted radionuclide therapeutic candidate currently in development, FAP-2286, which targets fibroblast activation protein (FAP), a promising theranostic target with expression across many tumor types.",
    'FW Link1':
      'NorthStar Medical Radioisotopes Announces Supply Agreement with Clovis Oncology for Therapeutic Radioisotope Actinium-225 (Ac-225);;https://firstwordpharma.com/story/5618225/'
  },
  {
    'Date Announced': '20-Jul-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Manhattan BioSolutions',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'National Cancer Institute',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Monoclonal antibodies, antibody-drug conjugates',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Manhattan BioSolutions has entered into two commercial evaluation license agreements with the National Cancer Institute (NCI), part of the US National Institutes of Health (NIH), to generate and validate novel antibody-drug conjugate (ADC) therapies using monoclonal antibodies developed by NCI.',
    'FW Link1':
      'Manhattan BioSolutions Signs Commercial Evaluation License Agreements with the National Institutes of Health (NIH) for Use of Monoclonal Antibodies in Novel Antibody-Drug Conjugate (ADC) Oncology Programs;;https://firstwordpharma.com/story/5618971/'
  },
  {
    'Date Announced': '20-Jul-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Takeda',
    'Country (Acquiring Company/Partner)': 'Japan',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'F-star',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Upfront Value ($m)': '1',
    'Total Deal Value ($m)': '41',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Immuno-oncology bispecific antibody, Fcab and mAb2 platforms',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Under the terms of the agreement, F-star will grant Takeda a worldwide, exclusive royalty-bearing license to research, develop, and commercialize a bispecific antibody against an immuno-oncology target using F-star's proprietary Fcab and mAb2 platforms. Takeda will be responsible for all research, development, and commercialization activities under the agreement. F-star will receive an upfront license fee of $1 million. F-star is also eligible to receive future development and commercialization milestone payments up to approximately $40 million over the course of the agreement if all milestones are achieved, plus single-digit percentage royalties on annual net sales.",
    'FW Link1':
      'F-star Therapeutics Announces a License Agreement with Takeda for a Novel Next-Generation Immuno-oncology Bispecific Antibody;;https://firstwordpharma.com/story/5619113/'
  },
  {
    'Date Announced': '21-Jul-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'ProPhase Labs',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Global BioLife',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecule PIM kinase inhibitors',
    'Lead Asset(s)': 'LB-1, LB-2',
    'Indication(s)': 'Cancer',
    'Target(s)': 'PIM kinase',
    'Deal Structure':
      "Under the terms of the license agreement, PBIO has obtained exclusive rights worldwide to develop and commercialize LB-1 and LB-2 for the treatment of cancer, inflammatory diseases or symptoms and memory related syndromes, diseases or symptoms including dementia and Alzheimer's disease.",
    'FW Link1':
      'ProPhase Labs Announces Licensing of New Investigational Cancer Compounds;;https://firstwordpharma.com/story/5619821/'
  },
  {
    'Date Announced': '21-Jul-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Artizan Biosciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner':
      'University Medical Center Utrecht, Microviable Therapeutics',
    'Country (Target Company/Partner)': 'Spain',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immune checkpoint inhibitor therapies',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Artizan Biosciences has entered into a multi-year research collaboration with the University Medical Center Utrecht in The Netherlands and Microviable Therapeutics SL to explore the role that gut microbiota may have in immune checkpoint inhibitor therapies for patients with cancer.',
    'FW Link1':
      'Artizan Biosciences Announces Multi-Year Research Collaboration with the University Medical Center Utrecht and Microviable Therapeutics SL;;https://firstwordpharma.com/story/5619867/'
  },
  {
    'Date Announced': '21-Jul-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Y-mAbs Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'ITM',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Medical radioisotope, radiopharmaceutical',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'lutetium-177, GD2-SADA: 177Lu-DOTA Complex',
    'Indication(s)': 'GD2-positive solid tumours',
    'Target(s)': 'GD2',
    'Deal Structure':
      "Under the terms of the agreement, ITM will provide its n.c.a. 177Lu for the clinical development of Y-mAbs' radiopharmaceutical candidate GD2-SADA: 177Lu-DOTA Complex.",
    'FW Link1':
      'ITM Signs Clinical Supply Agreement with Y-mAbs for n.c.a. Lutetium-177;;https://firstwordpharma.com/story/5619805/'
  },
  {
    'Date Announced': '22-Jul-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Sosei Heptares',
    'Country (Acquiring Company/Partner)': 'Japan',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Cancer Research UK',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immunotherapy, EP4 antagonist',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'HTL0039732',
    'Indication(s)': 'Cancer',
    'Target(s)': 'EP4',
    'Deal Structure':
      "Under the Clinical Trial and Licence Agreement (CTLA), Cancer Research UK's Centre for Drug Development will sponsor, design and execute a Phase I/IIa clinical trial of HTL0039732, a novel selective EP4 antagonist. Sosei Heptares will be responsible for CTA enabling activities, including GLP toxicology, IMP manufacture and other necessary pre-clinical studies in preparation for the opening of the clinical trial. Sosei Heptares holds a licence to the results generated under the trial to continue the clinical development and commercialisation of HTL0039732.",
    'FW Link1':
      'Cancer Research UK and Sosei Heptares sign agreement to advance cancer immunotherapy candidate into clinical trials;;https://firstwordpharma.com/story/5620747/'
  },
  {
    'Date Announced': '26-Jul-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'GEn1E Lifesciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'University of Maryland, Baltimore',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'ERK1/2 immunomodulators',
    'Indication(s)': 'Oncology',
    'Target(s)': 'ERK1/2',
    'Deal Structure':
      'GEn1E Lifesciences has secured an exclusive, worldwide, all-fields license from the University of Maryland, Baltimore (UMB) for patented next-generation Extracellular Signal-Regulated Kinase 1/2 (ERK1/2) immunomodulators.',
    'FW Link1':
      'GEn1E Lifesciences Adds NextGen Selective Immunomodulator Target to Portfolio, Broadens Pipeline;;https://firstwordpharma.com/story/5622961/'
  },
  {
    'Date Announced': '26-Jul-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Merck & Co.',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Kelun Pharmaceutical',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '35',
    'Total Deal Value ($m)': '936',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugate',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      'Under the terms of the agreement, Kelun-Biotech has granted MSD global, exclusive rights to develop, manufacture and commercialize an investigational ADC. Kelun-Biotech and MSD will also collaborate on the early clinical development of the investigational ADC. In return, Kelun-Biotech will receive an upfront payment of $35mm and is eligible to receive future development, approval and commercial milestone payments totaling up to $901mm, plus tiered royalties on net sales.',
    'FW Link1':
      'Merck & Co. deepens ADC ties with China-based biotech for up to $901 million;;https://firstwordpharma.com/story/5622679/'
  },
  {
    'Date Announced': '26-Jul-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Intas',
    'Country (Acquiring Company/Partner)': 'India',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Prestige BioPharma',
    'Country (Target Company/Partner)': 'Singapore',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal':
      'United States, Europe, Canada, MENA, Brazil, Mexico, South Africa, Thailand, Vietnam, Philippines, Malaysia, Singapore, Indonesia, Kyrgyzstan, Tajikistan',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Bevacizumab biosimilar',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'HD204',
    'Indication(s)': 'Cancer',
    'Target(s)': 'VEGF',
    'Deal Structure':
      'The partnership arrangement includes the exclusive rights for Intas and its affiliate Accord Healthcare that will commercialize the bevacizumab biosimilar in the US, Europe, Canada, MENA, Brazil, Mexico, South Africa, Thailand, Vietnam, Philippines, Malaysia, Singapore, Indonesia, Kyrgyzstan and Tajikistan.',
    'FW Link1':
      'Prestige Biopharma and Intas Pharmaceuticals Announce Partnership to Commercialize Bevacizumab Biosimilar in the US, Europe, Canada, MENA, Brazil, Mexico, South Africa, CIS and the SEA countries;;https://firstwordpharma.com/story/5622685/'
  },
  {
    'Date Announced': '26-Jul-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Valo Therapeutics',
    'Country (Acquiring Company/Partner)': 'Finland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'University of Helsinki',
    'Country (Target Company/Partner)': 'Finland',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immunotherapies',
    'Lead Asset(s)': 'PeptiCHIP',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Valo Therapeutics Oy (ValoTx) announced the exclusive licensing of intellectual property rights (IPR) from the University of Helsinki, Finland, for the institution's innovative PeptiCHIP technology. Following further validation by ValoTx, and the achievement of specific milestones, the IPR will transfer in full to the company.",
    'FW Link1':
      'Valo Therapeutics Acquires PeptiCHIP Technology Enabling Rapid Identification of Tumor Antigens for the Development of Personalized Immunotherapies;;https://firstwordpharma.com/story/5622489/'
  },
  {
    'Date Announced': '27-Jul-2022',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'BioSpring',
    'Country (Acquiring Company/Partner)': 'Laos',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Sailin Biopharma',
    'Country (Target Company/Partner)': 'Laos',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Anticancer drugs, oral targeted therapy',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'The acquisition is expected to be completed before the end of this year. After the acquisition and integration, BioSpring will not continue to operate the SAILIN brand, but will adopt a single product system.',
    'FW Link1':
      'Lao pharmaceutical company BioSpring announces acquisition agreement with cancer drug company Sailin Biopharma;;https://firstwordpharma.com/story/5623505/'
  },
  {
    'Date Announced': '27-Jul-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Amgen',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'IDEAYA Biosciences',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Small molecule MAT2A inhibitor, small molecule MTA-cooperative PRMT5 inhibitor, MAT2A-PRMT5 synthetic lethality combination',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'IDE397, AMG 193',
    'Indication(s)': 'MTAP-null solid tumours',
    'Target(s)': 'MAT2A, PRMT5',
    'Deal Structure':
      'Under the mutually non-exclusive clinical trial collaboration and supply agreement, IDEAYA will provide IDE397 drug supply to Amgen, who will be the sponsor of the Phase 1 clinical combination trial.  IDEAYA and Amgen will jointly share external costs of the clinical trial and will jointly oversee clinical development of the combination therapy.  IDEAYA and Amgen each retain all commercial rights to their respective compounds, including as monotherapy or as combination therapies.',
    'FW Link1':
      'IDEAYA Announces Clinical Trial Collaboration with Amgen to Evaluate MAT2A-PRMT5 Synthetic Lethality Combination in MTAP Deleted Tumors;;https://firstwordpharma.com/story/5623509/'
  },
  {
    'Date Announced': '28-Jul-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Elevation Oncology',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'CSPC Pharmaceutical',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '27',
    'Total Deal Value ($m)': '1,175',
    'Geographic Remit of Deal': 'Global (excl. Greater China)',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugate',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'EO-3021',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'Claudin18.2',
    'Deal Structure':
      'Under the terms of the agreement, Elevation Oncology will develop and commercialize EO-3021 in all global territories outside of Greater China. In exchange, CSPC will receive a one-time, upfront payment of $27 million. CSPC will also be eligible to receive up to $148 million in potential development and regulatory milestone payments and up to $1.0 billion in potential commercial milestone payments plus royalties on net sales.',
    'FW Link1':
      'Elevation inks deal for antibody-drug conjugate targeting Claudin18.2;;https://firstwordpharma.com/story/5624881/'
  },
  {
    'Date Announced': '28-Jul-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'NeoPhore',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Memorial Sloan Kettering Cancer Center',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small-molecule inhibitors of mismatch repair',
    'Indication(s)': 'Cancer',
    'Target(s)': 'DNA mismatch repair (MMR) pathway',
    'Deal Structure':
      "The objective of the collaboration is to determine the potential of NeoPhore's proprietary DNA mismatch repair inhibitor (MMRi) compounds to enhance tumour immunogenicity and induce tumour immunity.",
    'FW Link1':
      "NeoPhore Enters Three Year Research Collaboration with World Leading Oncologist's Lab at Major US Cancer Center;;https://firstwordpharma.com/story/5624599/"
  },
  {
    'Date Announced': '1-Aug-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Ipsen',
    'Country (Acquiring Company/Partner)': 'France',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Marengo Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '45',
    'Total Deal Value ($m)': '1,637',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immuno-oncology',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      'Under the agreed terms, Ipsen will make an upfront payment of $45 million to Marengo, with the latter eligible to receive additional payments if all milestones are met, as well as tiered sales royalties. Marengo will lead preclinical development of the two STAR platform-generated candidates until the submission of an investigational new drug (IND) application to the FDA, after which Ipsen will assume responsibilities for clinical development and commercialisation.',
    'FW Link1':
      'Ipsen partners with Marengo on immuno-oncology drug development;;https://firstwordpharma.com/story/5626291/'
  },
  {
    'Date Announced': '1-Aug-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'AOP Health',
    'Country (Acquiring Company/Partner)': 'Austria',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Leukos Biotech',
    'Country (Target Company/Partner)': 'Spain',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Newly discovered chemical substance',
    'Indication(s)': 'Acute myeloid leukaemia, myelodysplastic syndrome',
    'Deal Structure':
      'The agreement signed by AOP Health covers the option for developments of the newly discovered chemical substance within any treatable indication, not limited to oncological or rare diseases. As AOP Health focuses on rare, hemato-oncological cancers and has many years of experience in the development and commercialization of hemato-oncological treatments, the company will drive the further development of the substance in cooperation with Leukos based on a new mode of action.',
    'FW Link1':
      'AOP Health Starts Research for Leukemia Treatment;;https://firstwordpharma.com/story/5626277/'
  },
  {
    'Date Announced': '1-Aug-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Esperovax',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Life Magnetics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Oral RNA therapeutic delivery system, RNA therapeutics, drug delivery',
    'Indication(s)': 'Colorectal cancer',
    'Deal Structure':
      'Esperovax, a leading developer of oral mRNA biologics, and Life Magnetics, Inc., creators of RNA stabilization technology, announced the signing of an agreement to co-develop an innovative new oral RNA therapeutic delivery system for colorectal cancer patients that can be stored at room temperature.',
    'FW Link1':
      'Esperovax And Life Magnetics To Deliver New Oral Therapeutic To Colorectal Patients;;https://firstwordpharma.com/story/5626567/'
  },
  {
    'Date Announced': '1-Aug-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'BioMed X',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Merck KGaA',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Extrachromosomal DNA, anticancer therapies',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'The main objective of this research group is to develop an atlas of extrachromosomal DNA (ecDNA) in human cancer tissues and thereby trace back the mechanisms of ecDNA formation and function.',
    'FW Link1':
      'BioMed X Institute and Healthcare Business of Merck KGaA, Darmstadt, Germany, Start New Research Project in Oncology;;https://firstwordpharma.com/story/5626285/'
  },
  {
    'Date Announced': '2-Aug-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Rigel',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Forma Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '2',
    'Total Deal Value ($m)': '235',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecule',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'olutasidenib',
    'Indication(s)': 'Relapsed/refractory acute myeloid leukaemia',
    'Target(s)': 'Mutant IDH1',
    'Deal Structure':
      'Under the terms of the agreement, Forma will receive an upfront payment of $2.0 million, and is eligible to receive an additional $17.5 million upon the achievement of certain near-term regulatory, approval, and first commercial sale milestones.  In addition, Forma is eligible to receive a total of up to an additional $215.5 million in connection with the achievement of certain development and commercial milestones. Forma is also eligible to receive tiered royalties in the low-teens to mid-thirties. Moving forward, Rigel will be responsible for the potential launch and commercialization of olutasidenib in the U.S., and intends to work with potential partners to further develop and commercialize olutasidenib outside the U.S.',
    'FW Link1':
      'Rigel Pharmaceuticals and Forma Therapeutics Announce Licensing Agreement for Olutasidenib, a Novel Mutant IDH1 Inhibitor for the Potential Treatment of Relapsed or Refractory Acute Myeloid Leukemia;;https://firstwordpharma.com/story/5627901/'
  },
  {
    'Date Announced': '2-Aug-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Context Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Menarini',
    'Country (Target Company/Partner)': 'Italy',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Endocrine therapy, oral selective oestrogen receptor degrader, oral progesterone receptor antagonist',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'elacestrant, onapristone extended release',
    'Indication(s)': 'Breast cancer',
    'Target(s)': 'Oestrogen receptor, progesterone receptor',
    'Deal Structure':
      'This agreement will support the upcoming Phase 1b/2 ELONA clinical proof-of-concept trial evaluating onapristone extended release (ONA-XR), an oral progesterone receptor (PR) antagonist, in combination with elacestrant in estrogen receptor positive (ER+), PR+ HER2- metastatic breast cancer (mBC) patients who have previously been treated with a CDK4/6 inhibitor. Context will sponsor the clinical trial and Menarini will supply elacestrant at no cost.',
    'FW Link1':
      'Context Therapeutics and The Menarini Group Announce Clinical Trial Collaboration and Supply Agreement to Evaluate ONA-XR and Elacestrant Combination;;https://firstwordpharma.com/story/5627457/'
  },
  {
    'Date Announced': '2-Aug-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Xenetic Biosciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Volition',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Adoptive cell therapies, neutrophil extracellular traps test, CAR T',
    'Indication(s)': 'Cancer',
    'Target(s)': 'Neutrophil extracellular traps',
    'Deal Structure':
      'Under the terms of the collaboration agreement, Volition will fund a research program and the two parties will share proceeds from commercialization or licensing of any products arising from the collaboration.',
    'FW Link1':
      'Xenetic Biosciences, Inc. and VolitionRx Limited Collaborate to Develop NETs-Targeted Adoptive Cell Therapies for the Treatment of Cancer;;https://firstwordpharma.com/story/5627517/',
    'FW Link2':
      'Signing of patent assignment announced October 11, 2022 https://firstwordpharma.com/story/5662459/'
  },
  {
    'Date Announced': '2-Aug-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Xencor',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Caris Life Sciences',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Bispecific antibodies',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Under the terms of the agreement, Caris will apply its proprietary end-to-end discovery platform, Caris Discovery, to identify novel targets for bispecific antibody drug candidates. As part of the agreement, Xencor will receive exclusive options to research, develop and commercialize products directed to up to three targets. Caris will receive an upfront payment and will be eligible to receive up to approximately $120 million in license fees, discovery, development, regulatory and sales-based milestones, in addition to royalty payments on net sales of each product commercialized by Xencor and future rights for molecular profiling and companion diagnostics for drug candidates developed under the collaboration.',
    'FW Link1':
      'Caris Life Sciences and Xencor Enter Target Discovery Collaboration and License Agreement for Novel XmAb Bispecific Antibodies;;https://firstwordpharma.com/story/5627565/'
  },
  {
    'Date Announced': '2-Aug-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Salarius Pharmaceuticals',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Volition',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'LSD1 inhibitor, rapid epigenetic profiling',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'seclidemstat',
    'Indication(s)': 'Solid and haematologic cancers',
    'Target(s)': 'LSD1',
    'Deal Structure':
      "Salarius Pharmaceuticals and VolitionRx Limited announced the signing of a research and development collaboration to advance rapid epigenetic profiling using Volition's Nu.Q technology to support further development of Salarius' clinical stage drug, seclidemstat.",
    'FW Link1':
      'Salarius Pharmaceuticals and VolitionRx Enter into R&D Collaboration Agreement;;https://firstwordpharma.com/story/5627513/'
  },
  {
    'Date Announced': '2-Aug-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'TransCode Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'University of Texas MD Anderson Cancer Center',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'RNA therapies, diagnostics',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'TTX-MC138',
    'Indication(s)': 'Metastatic cancers',
    'Deal Structure':
      "Through the alliance, TransCode and MD Anderson scientists will collaborate on preclinical studies to further validate TransCode's therapeutic and diagnostic candidates and to expand the reach of TransCode's discovery engine. The results of these studies will inform future clinical trials with these agents, including trials to be led at MD Anderson.",
    'FW Link1':
      'TransCode Therapeutics and MD Anderson Announce Strategic Alliance to Advance RNA Therapies for Oncology;;https://firstwordpharma.com/story/5627289/'
  },
  {
    'Date Announced': '2-Aug-2022',
    'Deal type': 'Product Acquisition',
    'Acquiring Company/Partner': 'MDxHealth',
    'Country (Acquiring Company/Partner)': 'Belgium',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Exact Sciences',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '25',
    'Total Deal Value ($m)': '100',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostic',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Oncotype DX GPS',
    'Indication(s)': 'Prostate cancer',
    'Deal Structure':
      'Under the terms of the asset purchase agreement, mdxhealth acquired the Oncotype DX GPS prostate cancer business of Exact Sciences for an aggregate purchase price of up to $100 million, of which an amount of $25 million was paid in cash and an amount of $5 million will be settled through the delivery of 691,171 American Depositary Shares ("ADSs") of the Company, at a price per ADS of $7.23. Following the closing, an additional aggregate earn-out amount of up to $70 million is to be paid by mdxhealth to Exact Sciences upon achievement of certain revenue milestones related to fiscal years 2023 through 2025, with the maximum earn-out payable in relation to 2023 and 2024 not to exceed $30 million and $40 million, respectively. At the option of mdxhealth, the earn-out amounts can be settled in cash or through the issuance of additional ADSs of the Company (valued in function of a volume weighted average trading price of the Company\'s shares at the end of the relevant earn-out period) to Exact Sciences, provided that the aggregate number of shares representing the ADSs held by Exact Sciences shall not exceed more than 5% of the outstanding shares of mdxhealth.',
    'FW Link1':
      'Mdxhealth Acquires Oncotype DX GPS Prostate Cancer Business from Exact Sciences and Reports Preliminary Half Year 2022 Results;;https://www.firstwordhealthtech.com/story/5628859/'
  },
  {
    'Date Announced': '3-Aug-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Propanc Biopharma',
    'Country (Acquiring Company/Partner)': 'Australia',
    'Region (Acquiring Company/Partner)': 'Oceania',
    'Target Company/Partner': 'Universities of Jaén and Granada',
    'Country (Target Company/Partner)': 'Spain',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Proenzyme therapy',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      'Propanc will own any intellectual property developed during the research, and publishing rights, in exchange for any net future royalties from intellectual property rights.',
    'FW Link1':
      'Propanc Biopharma Establishes Joint Research Collaboration Agreement with the Universities of Jaén and Granada;;https://firstwordpharma.com/story/5628745/'
  },
  {
    'Date Announced': '3-Aug-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Roche',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Poseida Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '110',
    'Total Deal Value ($m)': '6,220',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Allogeneic CAR-T cell therapies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'P-BCMA-ALLO1',
    'Indication(s)': 'Haematologic malignancies',
    'Target(s)': 'BCMA, CD19, CD20',
    'Deal Structure':
      'The global agreement covers the development of multiple existing and novel "off-the-shelf" cell therapies against targets in multiple myeloma, B-cell lymphomas and other haematologic indications. Roche will gain either exclusive rights or options to develop and commercialise a number of allogeneic CAR-T programmes in Poseida\'s portfolio, including P-BCMA-ALLO1 and P-CD19CD20-ALLO1. Roche and Poseida also plan to create next-generation features and improvements for allogeneic CAR-T therapies. Under the agreed terms, Roche will make an upfront payment of $110 million, with Poseida eligible to receive up to another $110 million in near-term milestones and other payments over the next several years. All told, the tie-up could be worth up to $6 billion for Poseida, which would also get tiered sales royalties into the low double digits, across multiple programmes.',
    'FW Link1':
      'Roche deepens bet on cancer cell therapies via Poseida deal;;https://firstwordpharma.com/story/5628273/'
  },
  {
    'Date Announced': '4-Aug-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'BriaCell',
    'Country (Acquiring Company/Partner)': 'Canada',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'University of Maryland, Baltimore County',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Anti-cancer biologic agent, immunotherapy',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'Soluble CD80',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Under the terms of the agreement, BriaCell gains the worldwide rights to develop and commercialize sCD80, while UMBC maintains ownership of the patents. BriaCell will pay royalties to UMBC upon the commercialization of the product plus patent management costs.',
    'FW Link1':
      'BriaCell Secures License for a Promising Novel Anti-Cancer Agent;;https://firstwordpharma.com/story/5630017/'
  },
  {
    'Date Announced': '3-Aug-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Boehringer Ingelheim',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Quantro Therapeutics',
    'Country (Target Company/Partner)': 'Austria',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Transcriptomics, small-molecule inhibitors',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Target(s)': 'Oncogenic transcription factors',
    'Deal Structure':
      'QUANTRO is entitled to receive up-front payments, research and development support, milestone payments as well as royalties on products resulting from the collaboration. Financial details were not disclosed. QUANTRO retains all rights to its technology platform and to internal and proprietary drug discovery programs targeting different cancer-associated transcription factors.',
    'FW Link1':
      'QUANTRO Therapeutics collaborates with Boehringer Ingelheim to develop drug candidates interfering with transcriptional regulators;;https://firstwordpharma.com/story/5629713/'
  },
  {
    'Date Announced': '5-Aug-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Genmab',
    'Country (Acquiring Company/Partner)': 'Denmark',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'BioNTech',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Immunotherapy, monospecific antibodies, bispecific antibodies',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Under the expanded collaboration, the companies will jointly develop and commercialize, subject to regulatory approval, monospecific antibodies leveraging Genmab's proprietary HexaBody technology platform.",
    'FW Link1':
      'Genmab and BioNTech Expand Global Strategic Collaboration to Develop and Commercialize Novel Immunotherapy Candidates;;https://firstwordpharma.com/story/5630601/'
  },
  {
    'Date Announced': '3-Aug-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Radiopharm',
    'Country (Acquiring Company/Partner)': 'Australia',
    'Region (Acquiring Company/Partner)': 'Oceania',
    'Target Company/Partner': 'Lantheus',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Nanobody, imaging agent, immuno-oncology therapy',
    'Lead Asset(s)': 'NM-01',
    'Indication(s)': 'Non-small-cell lung cancer',
    'Target(s)': 'PD-L1',
    'Deal Structure':
      "Pursuant to the collaboration agreement, Lantheus will provide the diagnostic product candidate of NM-01 to Radiopharm for use in its therapeutic clinical trials. NM-01 will be used to assess PD-L1 expression during patient selection. In addition, under the agreement, Radiopharm and Lantheus have the option to expand their collaboration to additional assets and potential licensing opportunities in Radiopharm's pipeline.",
    'FW Link1':
      'Radiopharm Enters Into Strategic Collaboration With Lantheus And Assumes PD-L1 Licensing Agreement From Nanomab;;https://firstwordpharma.com/story/5630493/'
  },
  {
    'Date Announced': '3-Aug-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Radiopharm',
    'Country (Acquiring Company/Partner)': 'Australia',
    'Region (Acquiring Company/Partner)': 'Oceania',
    'Target Company/Partner': 'NanoMab',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global, China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Nanobody, imaging agent, immuno-oncology therapy',
    'Lead Asset(s)': 'NM-01',
    'Indication(s)': 'Non-small-cell lung cancer',
    'Target(s)': 'PD-L1',
    'Deal Structure':
      'In a separate, concurrent agreement, Radiopharm acquired from NanoMab the imaging rights of NM-01 for the strategic Chinese market and worldwide IP rights for any therapeutic use (previously a licencing right). Lantheus holds the exclusive imaging rights to NM-01, apart from China.',
    'FW Link1':
      'Radiopharm Enters Into Strategic Collaboration With Lantheus And Assumes PD-L1 Licensing Agreement From Nanomab;;https://firstwordpharma.com/story/5630493/'
  },
  {
    'Date Announced': '8-Aug-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'GSK',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Mersana Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '100',
    'Total Deal Value ($m)': '1,460',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugate',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'XMT-2056',
    'Indication(s)': 'HER2-expressing tumours',
    'Target(s)': 'HER2',
    'Deal Structure':
      'Along with the upfront fee, Mersana is eligible to receive up to $1.36 billion in the form of an option exercise payment and milestone payments if GSK exercises its option. Meanwhile, Mersana has retained options to profit-share and to co-promote in the US, which will see the drugmaker receive tiered royalties on net sales outside of the US. If Mersana does not elect to profit-share, it is eligible to receive double-digit tiered royalties on global net sales.',
    'FW Link1':
      'GSK inks antibody-drug conjugate deal with Mersana;;https://firstwordpharma.com/story/5632053/'
  },
  {
    'Date Announced': '10-Aug-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Roche',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'CEND Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cyclic peptide, PD-L1 checkpoint inhibitor',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'CEND-1, Tecentriq',
    'Indication(s)': 'Metastatic pancreatic ductal adenocarcinoma',
    'Deal Structure':
      'Under the terms of the agreement, Roche will be responsible for operational management of the trial while Cend and Roche share equally in the costs of the CEND-1 treatment arms in the study.',
    'FW Link1':
      'Caladrius Biosciences’ Prospective Merger Partner, Cend Therapeutics, Announces Collaboration Agreement with Roche to Evaluate CEND-1 in Combination with Immunotherapy to Treat Pancreatic Cancer;;https://firstwordpharma.com/story/5633923/'
  },
  {
    'Date Announced': '10-Aug-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Merck & Co.',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Starpharma',
    'Country (Target Company/Partner)': 'Australia',
    'Region (Target Company/Partner)': 'Oceania',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'DEP antibody-drug conjugates',
    'Deal Structure':
      'Starpharma has signed a new DEP Research Agreement with MSD. This new DEP program will generate and evaluate additional DEP Antibody Drug Conjugates (ADCs). This new agreement follows an earlier DEP Antibody Drug Conjugate (ADC) agreement that Starpharma signed with MSD in February 2021.',
    'FW Link1':
      'Starpharma signs new DEP agreement with MSD;;https://firstwordpharma.com/story/5633655/'
  },
  {
    'Date Announced': '15-Aug-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Ontada',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'BeiGene',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'United States',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Real-world evidence, cancer medicines',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'The partnership will be led by Ontada and focus on accelerating the development and implementation of RWE to improve community education on the financial impact and value of oncolytics as well as improve patient access to oncology medicines.',
    'FW Link1':
      'BeiGene Announces Strategic Alliance with Ontada to Improve U.S. Community Oncology Care;;https://firstwordpharma.com/story/5637245/'
  },
  {
    'Date Announced': '15-Aug-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Quantum Leap',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'ALX Oncology',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugate',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'evorpacept, Enhertu',
    'Indication(s)': 'Breast cancer',
    'Target(s)': 'CD47, HER2',
    'Deal Structure':
      'Sponsored by Quantum Leap, this Phase 1 (open-label), multi-center study arm will investigate evorpacept in combination with ENHERTU (fam-trastuzumab deruxtecan-nxki), a HER2 directed antibody-drug conjugate (ADC), to determine the safety, tolerability and efficacy of this drug combination. The I-SPY-P1 TRIAL will be led by Paula Pohlmann, Associate Professor of Breast Medical Oncology at The University of Texas MD Anderson Cancer Center. ALX Oncology will provide funding and supply evorpacept. As the study sponsor, Quantum Leap will be responsible for managing the trial.',
    'FW Link1':
      'ALX Oncology and Quantum Leap Healthcare Collaborative Announce the Selection of Evorpacept in the I-SPY-P1 TRIAL in Combination with Enhertu in Breast Cancer;;https://firstwordpharma.com/story/5637257/'
  },
  {
    'Date Announced': '15-Aug-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'HiFiBiO Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Novartis',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Monoclonal antibody, immune checkpoint inhibitor',
    'Lead Asset(s)': 'HFB200301, tislelizumab',
    'Indication(s)': 'Advanced solid tumours',
    'Target(s)': 'TNFR2, PD-1',
    'Deal Structure':
      'Under the terms of the agreement, HiFiBiO Therapeutics will maintain control of the HFB200301 program, including global R&D and commercial rights. Novartis has agreed to supply tislelizumab for use in combination with HFB200301.',
    'FW Link1':
      'HiFiBiO Therapeutics Announces Clinical Trial Supply Agreement to Evaluate HFB200301 in Combination with Tislelizumab in Patients with DIS Selected Advanced Solid Tumors;;https://firstwordpharma.com/story/5637251/'
  },
  {
    'Date Announced': '15-Aug-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'EpiAxis',
    'Country (Acquiring Company/Partner)': 'Australia',
    'Region (Acquiring Company/Partner)': 'Oceania',
    'Target Company/Partner': 'Peptilogics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'AI, epigenetic cancer treatment',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'The collaboration will combine EpiAxis Therapeutics’ deep epigenetic expertise and Peptilogics’ AI platform (Nautilus) to advance drug discovery for cancers that escape existing therapies through epigenetic change, including metastatic breast cancer.',
    'FW Link1':
      'EpiAxis Therapeutics & Peptilogics Enter Strategic Drug Discovery Partnership;;https://firstwordpharma.com/story/5637503/'
  },
  {
    'Date Announced': '16-Aug-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Kronos Bio',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Invivoscribe',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Companion diagnostic',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'entospletinib',
    'Indication(s)': 'NPM1-mutated acute myeloid leukaemia',
    'Target(s)': 'NPM1 mutation',
    'Deal Structure':
      "Kronos Bio and Invivoscribe announced their agreement to develop a companion diagnostic (CDx) for use with Kronos Bio's investigational therapy, entospletinib. The diagnostic will screen for the NPM1 mutation, which is present in approximately one-third of all patients with AML.",
    'FW Link1':
      "Kronos Bio and Invivoscribe Partner on Companion Diagnostic for Use with Entospletinib, Kronos Bio's Investigational Compound Being Developed for Patients with AML;;https://firstwordpharma.com/story/5638785/"
  },
  {
    'Date Announced': '16-Aug-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Merck & Co.',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Orna Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '150',
    'Total Deal Value ($m)': '3,750',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Circular RNA',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "As part of the new deal, Orna will pocket an upfront payment of $150 million, which Merck says it will expense in the third quarter. In addition, Orna could receive as much as $3.5 billion tied to milestones associated with multiple vaccines and therapeutics in development, as well as royalties on any approved products that derive from the collaboration. Merck also said it will invest $100 million of equity in Orna's recently completed Series B financing round, through which it raised $221 million. Meanwhile, Orna retains rights to its oRNA-LNP technology platform and continue to advance other wholly owned programmes in areas such as oncology and genetic disease.",
    'FW Link1':
      'Merck & Co. links up with Orna on circular RNA drugs, vaccines;;https://firstwordpharma.com/story/5638131/'
  },
  {
    'Date Announced': '16-Aug-2022',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Intelerad Medical Systems',
    'Country (Acquiring Company/Partner)': 'Canada',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'PenRad',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostic radiology',
    'Lead Asset(s)': 'PenRad, PenLung, PenTrac',
    'Indication(s)': 'Breast cancer, lung cancer',
    'Deal Structure':
      'Intelerad Medical Systems, a leading global provider of enterprise medical imaging solutions, announced its acquisition of PenRad Technologies, Inc. (PenRad), a software provider for enhancing productivity for breast imaging and lung screening.',
    'FW Link1':
      'Intelerad Acquires PenRad Technologies, Inc., Expanding Breast Imaging and Lung Screening Capabilities;;https://www.firstwordhealthtech.com/story/5638409/'
  },
  {
    'Date Announced': '15-Aug-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Gilead Sciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Everest Medicines',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '280',
    'Total Deal Value ($m)': '455',
    'Geographic Remit of Deal':
      'Greater China, South Korea, Singapore, Indonesia, Philippines, Vietnam, Thailand, Malaysia, Mongolia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugate',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Trodelvy',
    'Indication(s)': 'Breast cancer',
    'Target(s)': 'Trop-2',
    'Deal Structure':
      'Under the terms of the agreement, Gilead will make a $280 million upfront payment to Everest. In addition, Everest is eligible to receive up to $175 million in potential additional payments upon achievement of certain regulatory and commercial milestones. Gilead will also have the opportunity to recruit Everest employees working directly on the Trodelvy program.',
    'FW Link1':
      'Gilead picks up remaining global rights to Trodelvy;;https://firstwordpharma.com/story/5637811/'
  },
  {
    'Date Announced': '16-Aug-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'AbbVie',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'I-Mab',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '180',
    'Total Deal Value ($m)': '1,480',
    'Geographic Remit of Deal': 'Global excl. Greater China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Anti-CD47 monoclonal antibody',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'lemzoparlimab',
    'Indication(s)': 'Cancer',
    'Target(s)': 'CD47',
    'Deal Structure':
      'I-Mab now says the partners will continue their collaboration on the development of anti-CD47 immunotherapies, but under an amended arrangement, with more modest potential milestone payments of up to $1.3 billion. The company is also eligible for tiered royalties at rates from mid- to high-single-digit percentages on global sales outside of China for certain new anti-CD47 antibodies currently in development. I-Mab retains exclusive rights to develop and commercialise all licensed products under the deal in China.',
    'FW Link1':
      "AbbVie ends early study of I-Mab's blood cancer drug lemzoparlimab;;https://firstwordpharma.com/story/5639719/",
    'FW Link2':
      'Termination of agreement announced September 22 2023;;https://firstwordpharma.com/story/5783698/'
  },
  {
    'Date Announced': '18-Aug-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Roche',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Jemincare',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '60',
    'Total Deal Value ($m)': '650',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Androgen receptor degrader',
    'Lead Asset(s)': 'JMKX002992',
    'Indication(s)': 'Prostate cancer',
    'Target(s)': 'Androgen receptor',
    'Deal Structure':
      'Under the terms of the agreement, Genentech will be granted an exclusive license to develop and commercialize the degrader worldwide, and will be fully responsible for the development and commercialization costs. In return, Genentech will pay Jemincare a USD 60 million upfront payment. Jemincare is also entitled to receive up to USD 590 million in additional payments upon achievement of certain development, regulatory and sales-based milestone targets. Jemincare is also entitled to receive tiered royalties on net sales.',
    'FW Link1':
      'Roche bets on prostate cancer drug candidate for up to $650 million;;https://firstwordpharma.com/story/5639867/'
  },
  {
    'Date Announced': '22-Aug-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Chugai',
    'Country (Acquiring Company/Partner)': 'Japan',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Noile-Immune Biotech',
    'Country (Target Company/Partner)': 'Japan',
    'Region (Target Company/Partner)': 'Asia',
    'Total Deal Value ($m)': '145',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'PRIME CAR-T cells',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      'Under the newly executed license agreement, Noile-Immune grants Chugai the rights to use PRIME technology for the creation and research of PRIME CAR-T cells, as well as the rights to develop, manufacture and commercialize PRIME CAR-T cell products for certain targets. Chugai will pay Noile-Immune an upfront payment and technology transfer fees. Also, Chugai may potentially pay over ¥20 billion in total if predetermined development or sales milestones are achieved. If Chugai successfully launch a product using PRIME technology, it will also pay royalties on sales in addition to the aforementioned sales milestones.',
    'FW Link1':
      'Chugai and Noile-Immune Biotech Enter into a License Agreement for Noile-Immune’s PRIME CAR-T Technology;;https://firstwordpharma.com/story/5641601/'
  },
  {
    'Date Announced': '23-Aug-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Erasca',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'University of Texas MD Anderson Cancer Center ',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'ERK1/2 inhibitor, SHP2 inhibitor',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'ERAS-007, ERAS-601',
    'Indication(s)':
      'RAS/MAPK pathway-driven cancers, non-small cell lung cancer, gastrointestinal malignancies',
    'Target(s)': 'ERK1/2, SHP2',
    'Deal Structure':
      "The new strategic collaboration will enhance Erasca's and MD Anderson's evaluation of ERAS-007 and ERAS-601 in combination with investigational and standard-of-care agents, including with Erasca's proprietary pipeline programs, such as the KRAS G12D inhibitor ERAS-4. Under the terms of the five-year agreement, collaborative preclinical and clinical studies will be conducted in NSCLC, GI malignancies and additional mutually agreed-upon indications.",
    'FW Link1':
      'Erasca and MD Anderson Announce Strategic Research and Development Collaboration in RAS;;https://firstwordpharma.com/story/5642739/'
  },
  {
    'Date Announced': '24-Aug-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'LumaBridge',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Parker Institute for Cancer Immunotherapy',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immunotherapy',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'The two organizations are collaborating to provide PICI Affiliated Partners—PICI Network institutions and investigators, affiliated companies, and nonprofit collaborators—direct access to the full suite of clinical trial services from LumaBridge.',
    'FW Link1':
      'LumaBridge and Parker Institute for Cancer Immunotherapy Announce Strategic Alliance to Streamline Immunotherapy Development;;https://firstwordpharma.com/story/5643853/'
  },
  {
    'Date Announced': '24-Aug-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Servier',
    'Country (Acquiring Company/Partner)': 'France',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'GNS',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'AI, biosimulation, digital twins',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Multiple myeloma',
    'Deal Structure':
      'The collaboration will address key questions about how MM progresses in different patient subpopulations, including discovering what are the most synergistic pathways for new combination therapies and will help Servier gain insights that can advance their translational and clinical development efforts. This work will initially leverage the GNS Gemini Digital Twin for MM developed using the rich clinico-genomic data from the Multiple Myeloma Research Foundation (MMRF) CoMMpass study.',
    'FW Link1':
      'GNS and Servier Announce AI Collaboration to Advance Multiple Myeloma Drug Discovery and Clinical Development;;https://firstwordpharma.com/story/5643701/'
  },
  {
    'Date Announced': '25-Aug-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': '3T Biosciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Stanford University',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'T-cell receptor (TCR)-based therapeutics',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "As part of its launch, 3T announced it has exclusively in-licensed from Stanford University a precise antibody-based peptide-HLA therapeutic discovery platform and development-stage MAGE-A3 T-cell receptor (TCR)-T assets to rapidly expand 3T's therapeutic portfolio.",
    'FW Link1':
      '3T Biosciences Debuts with $40 Million Series A Financing;;https://firstwordpharma.com/story/5644267/'
  },
  {
    'Date Announced': '25-Aug-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'BMS',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'PathAI',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'AI-powered pathology, AI-powered diagnostics',
    'Indication(s)': 'Oncology',
    'Deal Structure':
      'PathAI, a global leader in AI-powered pathology, announced a multi-year expanded collaboration agreement with Bristol Myers Squibb. The initial work within this extended agreement will focus on key translational research in oncology, fibrosis, and immunology, with an overall goal to continue transitioning this work into clinical trials.',
    'FW Link1':
      'PathAI Announces Extension of Multi-Year Collaboration Agreement with Bristol Myers Squibb;;https://firstwordpharma.com/story/5644375/'
  },
  {
    'Date Announced': '25-Aug-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Streeterville Capital',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Jaguar Health',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '4',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antidiarrhoeal agent',
    'Lead Asset(s)': 'crofelemer',
    'Indication(s)': 'Cancer therapy-related diarrhoea',
    'Deal Structure':
      'Under the Agreement, Jaguar will immediately receive $4.0 million (the "royalty purchase price") in connection with the sale of a royalty interest to Streeterville entitling Streeterville to receive 3-fold of the royalty purchase price from future royalties on sales of crofelemer and lechlemer and certain up-front license fees and milestone payments from licensees and/or distributors, excluding any fees due to Jaguar from its crofelemer license for the European territory to Napo Therapeutics, S.p.A. (the "Royalty Payment Amount") and will pay interest on the Royalty Payment Amount at a rate of ten percent in annum until the same is paid in full. Royalty payments will initiate on January 1, 2024 and will involve minimum monthly payments.',
    'FW Link1':
      'Jaguar Health Secures Additional Capital Through Sale of Royalty Rights Related to Future Crofelemer and Lechlemer Revenue Stream;;https://firstwordpharma.com/story/5644715/'
  },
  {
    'Date Announced': '30-Aug-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Indica Labs',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner':
      'Industrial Centre for Artificial Intelligence Research in Digital Diagnostics',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'AI-based digital pathology',
    'Indication(s)': 'Colorectal cancer, metastatic cancer in lymph nodes',
    'Deal Structure':
      'Indica Labs and The Industrial Centre for Artificial Intelligence Research in Digital Diagnostics (iCAIRD) announced an agreement to collaborate on the development of an AI-based digital pathology solution for the detection of cancer within lymph nodes from colorectal surgery cases. The primary aim of the innovative research project is to develop a tool which in the future may improve the efficiency of pathology teams within the National Health Service Greater Glasgow and Clyde (NHSGGC) reporting colorectal cancer cases and the detection of metastatic cancer in lymph nodes.',
    'FW Link1':
      'Indica Labs Announces Collaboration with The Industrial Centre for Artificial Intelligence Research in Digital Diagnostics (iCAIRD) for the Development of an AI-based Algorithm for the Automated Reporting of Lymph Node Status in Colon Cancer;;https://www.firstwordhealthtech.com/story/5646891/'
  },
  {
    'Date Announced': '31-Aug-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Merck KGaA',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Guardant Health',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Real-world evidence',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Under the expanded collaboration, Merck KGaA, Darmstadt, Germany, will work closely with data scientists at Guardant Health on a variety of therapy development initiatives that utilize the genomics and clinical information accessible through the GuardantINFORM platform.',
    'FW Link1':
      'Guardant Health Expands Strategic Collaboration With Merck KGaA, Darmstadt, Germany, to Help Accelerate Development of Precision Oncology Therapeutics;;https://firstwordpharma.com/story/5647736/'
  },
  {
    'Date Announced': '1-Sep-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Juniper Biologics',
    'Country (Acquiring Company/Partner)': 'Singapore',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Helsinn',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Australia, Asia, Middle East',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Alkylating drug',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Ledaga',
    'Indication(s)': 'Mycosis fungoides-type cutaneous T-cell lymphoma',
    'Deal Structure':
      'Helsinn Group ("Helsinn") and Juniper Biologics Pte Ltd announced the signing of an exclusive distribution license and supply agreement to register, distribute, promote, market and sell LEDAGA (chlormethine gel) in Australia, Asia and the Middle East* as a topical treatment of mycosis fungoides-type cutaneous T-cell lymphoma ("MF-CTCL") in adults.',
    'FW Link1':
      'Helsinn Healthcare signs exclusive license agreement with Juniper Biologics for LEDAGA (chlormethine) in Australia, Asia and the Middle East;;https://firstwordpharma.com/story/5648273/'
  },
  {
    'Date Announced': '2-Sep-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Medipath',
    'Country (Acquiring Company/Partner)': 'France',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Ibex Medical Analytics',
    'Country (Target Company/Partner)': 'Israel',
    'Region (Target Company/Partner)': 'Middle East',
    'Geographic Remit of Deal': 'France',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial intelligence, diagnostics',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Under the new expansion agreement, Medipath will rollout Ibex's solutions to all sites in France. Pathologists at Medipath will use the Galen platform for decision support during primary diagnosis of breast, prostate and gastric biopsies, enabling them to improve diagnostic accuracy and streamline workflows via automated case prioritization, AI-powered cancer detection, reporting, pre-ordering of immunohistochemistry (IHC) and other ancillary tests and other productivity-enhancing tools.",
    'FW Link1':
      'Ibex and Medipath Sign Multi-Year, Multi-Site and Multi-Tissue Expansion of AI for Cancer Diagnosis Across France;;https://www.firstwordhealthtech.com/story/5648713/'
  },
  {
    'Date Announced': '5-Sep-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'CellOrigin',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Qilu Pharmaceutical',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immunotherapy',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'CellOrigin Biotech (Hangzhou) Co., Ltd. announced that it has made an agreement with Qilu Pharmaceutical on strategic global collaborations to develop, manufacture and commercialize a proprietary "off-the-shelf" iPSC-derived Chimeric Antigen Receptor Macrophages (CAR-iMAC) for cancer immunotherapy. Both parties will collaborate on new drug development and commercialization, and will push CAR-iMAC pipelines forward to clinical trials.',
    'FW Link1':
      'CellOrigin Biotech announced strategic global collaboration with Qilu Pharmaceutical to develop "off-the-shelf" CAR-iMAC cell therapy;;https://firstwordpharma.com/story/5649175/'
  },
  {
    'Date Announced': '6-Sep-2022',
    'Deal type': 'Terminated',
    'Acquiring Company/Partner': 'Dong-A ST',
    'Country (Acquiring Company/Partner)': 'Korea, South',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Beactica',
    'Country (Target Company/Partner)': 'Sweden',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'As part of the termination, both Dong-A ST and Beactica Therapeutics will gain exclusive global rights for further development and commercialization for different compounds series developed during the collaboration. No obligations to pay milestone payments or royalties will remain for the assets divided between Dong-A and Beactica. For a limited time, each company is entitled to a revenue share from any related future outlicensing activities by the other party. Full financial details remain undisclosed.',
    'FW Link1':
      'Dong-A ST and Beactica Therapeutics mutually agree to conclude oncology collaboration;;https://firstwordpharma.com/story/5649332/'
  },
  {
    'Date Announced': '6-Sep-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Nodus Oncology',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Lead Discovery Center',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "LDC will act as the drug discovery arm of Nodus and participate in the development of all assets in Nodus' pipeline.",
    'FW Link1':
      'Nodus Oncology launches, founded by Cumulus Oncology and in partnership with the Lead Discovery Center, to focus on developing first and best-in-class molecules inhibiting novel DNA damage response targets;;https://firstwordpharma.com/story/5649336/'
  },
  {
    'Date Announced': '6-Sep-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Emergence Therapeutics',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Synaffix',
    'Country (Target Company/Partner)': 'Netherlands',
    'Region (Target Company/Partner)': 'Europe',
    'Total Deal Value ($m)': '360',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'As part of the deal, Synaffix is eligible for up to $360 million in upfront and milestone payments plus royalties on commercial sales. The first programme will get under way at signature, but there is an option to expand the collaboration to add more targets. Emergence will be responsible for R&D, manufacturing and commercialisation of the ADCs, while Synaffix will produce the components that are specifically related to its proprietary technologies. The tie-up stems from proof-of-concept data following an initial discovery research collaboration between the companies.',
    'FW Link1':
      'Synaffix grows roster of ADC partners with new Emergence deal;;https://firstwordpharma.com/story/5649344/'
  },
  {
    'Date Announced': '7-Sep-2022',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Roche',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Good Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '250',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immunocytokines',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Cancer',
    'Target(s)': 'PD-1, IL-2',
    'Deal Structure':
      'With this acquisition, Roche will gain rights to Good Therapeutics’ innovative, conditionally active, PD-1-regulated IL-2 program and an exclusive right to the platform technology for the development of PD-1-regulated IL-2 receptor agonist therapeutics. Under the terms of the merger agreement, Roche will make an upfront cash payment of $250 million for the shares on a fully diluted basis and additional payments based on the achievement of predetermined development, regulatory, and commercial milestones.',
    'FW Link1':
      'Roche to gain PD-1 IL-2 programme via Good Therapeutics buy;;https://firstwordpharma.com/story/5649913/'
  },
  {
    'Date Announced': '7-Sep-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Indivumed',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'CELLphenomics',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial intelligence, organoid models',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "The new platform combines Indivumed's AI-driven oncology analytics with CELLphenomics' expertise in creating organoid models to identify therapeutically relevant targets and validate these targets in-vitro. The partnership will accelerate cancer drug development by linking in-silico target discovery and development with matched patient-derived tumor models for downstream validation.",
    'FW Link1':
      'European Union-Funded Project to Result in Novel Treatment Approaches for Several Solid Tumor Types;;https://firstwordpharma.com/story/5650383/'
  },
  {
    'Date Announced': '8-Sep-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Sidekick Health',
    'Country (Acquiring Company/Partner)': 'Iceland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Ypsomed',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Digital therapeutics',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'The two companies will jointly develop a set of features and behavioural interventions that support and empower patients throughout the self-injection process and beyond for areas such as oncology, immunology or metabolic diseases.',
    'FW Link1':
      'Sidekick, Ypsomed DTx-device pairing to reduce pain of self-medication;;https://www.firstwordhealthtech.com/story/5650524/'
  },
  {
    'Date Announced': '8-Sep-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'NexImmune',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Selexis',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'T cell modulating nanotechnology, AIM injectable nanoparticles',
    'Indication(s)': 'Rare cancers',
    'Deal Structure':
      "Selexis SA, a JSR Life Sciences company, and NexImmune Inc., a clinical-stage biotechnology company developing unique approaches to T cell immunotherapies, announced that they have signed a service agreement to develop cell lines targeting rare cancers and autoimmune diseases and to advance two HLAs that can be used to expand treatments to more patients. Per the agreement, NexImmune will leverage Selexis' SUREtechnology Platform, a suite of cell line development technologies that significantly reduce the time, effort, and costs associated with developing high-performance mammalian cell lines.",
    'FW Link1':
      'Selexis and NexImmune Sign Service Agreement to Advance Multiple Immunotherapies Targeting Rare Cancers and Autoimmune Disorders;;https://firstwordpharma.com/story/5650603/'
  },
  {
    'Date Announced': '8-Sep-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Virogin Biotech',
    'Country (Acquiring Company/Partner)': 'Canada',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'University of Texas MD Anderson Cancer Center',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Oncolytic viruses, immunotherapies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'VG201',
    'Indication(s)': 'Advanced cancers',
    'Target(s)': 'CEA',
    'Deal Structure':
      "The agreement brings together MD Anderson's clinical trials expertise and infrastructure with Virogin's innovative pipeline of investigational oncolytic viruses. The five-year collaboration will support the clinical development of Virogin's therapies with multiple clinical trials in various cancer types to evaluate the safety and clinical benefit of these therapies and to identify prognostic biomarkers of response. Together with the clinical trials, the collaboration also will support translational research at MD Anderson to uncover valuable insights into the anti-tumor activity of virotherapies and the role of various payloads in changing the tumor-microenvironment.",
    'FW Link1':
      'Virogin Biotech and MD Anderson Announce Strategic Collaboration to Accelerate Oncolytic Virus Research and Development for Treating Advanced Cancers;;https://firstwordpharma.com/story/5650791/'
  },
  {
    'Date Announced': '7-Sep-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'OncoResponse',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Regeneron',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'OR2805, Libtayo',
    'Indication(s)': 'Advanced cancer',
    'Target(s)': 'CD163, PD-1',
    'Deal Structure':
      "The supply agreement will support the evaluation of Libtayo in combination with OR2805, a fully human monoclonal antibody identified from an elite cancer responder using OncoResponse's proprietary B-cell discovery platform.",
    'FW Link1':
      'OncoResponse Announces Clinical Supply Agreement with Regeneron to Evaluate OR2805 in Combination with Libtayo (cemiplimab) in Patients with Advanced Cancer;;https://firstwordpharma.com/story/5650416/'
  },
  {
    'Date Announced': '12-Sep-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Relay Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Foundation Medicine',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Companion diagnostic, small molecules',
    'Lead Asset(s)': 'FoundationOne CDx, RLY-4008',
    'Indication(s)': 'FGFR2-mutated cholangiocarcinoma, solid tumours',
    'Target(s)': 'FGFR2',
    'Deal Structure':
      "Foundation Medicine and Relay Therapeutics announced a collaboration to develop FoundationOne CDx as a companion diagnostic for RLY-4008, the company's investigational FGFR2 inhibitor. If the therapy and companion diagnostic are approved, FoundationOne CDx would be used to identify patients with FGFR2 fusions and select rearrangements in CCA who may be appropriate for treatment with RLY-4008.",
    'FW Link1':
      'Foundation Medicine and Relay Therapeutics Collaborate to Develop FoundationOne CDx as a Companion Diagnostic for Relay’s Investigational FGFR2 Inhibitor, RLY-4008;;https://firstwordpharma.com/story/5651696/'
  },
  {
    'Date Announced': '12-Sep-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'DRI Healthcare Trust',
    'Country (Acquiring Company/Partner)': 'Canada',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'AnaptysBio',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '35',
    'Total Deal Value ($m)': '45',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Zejula',
    'Indication(s)':
      'Ovarian cancer, fallopian tube cancer, primary peritoneal cancer, endometrial cancer',
    'Target(s)': 'PARP',
    'Deal Structure':
      'AnaptysBio has received a $35 million upfront payment for the sale of its 1% royalty on global net sales of Zejula which, due to reductions relating to third party royalties, is paid at an effective rate of 0.5%. The company is also eligible to receive a further $10 million from DRI upon FDA approval of Zejula for the treatment of endometrial cancer, for which the drug is currently in a fully-enrolled ongoing Phase 3 study, to the extent that such approval occurs on or before December 31, 2025.',
    'FW Link1':
      'AnaptysBio Announces Sale of Zejula Royalties for Up To $45 Million;;https://firstwordpharma.com/story/5651713/'
  },
  {
    'Date Announced': '13-Sep-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'METiS Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Voronoi',
    'Country (Target Company/Partner)': 'Korea, South',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '2',
    'Total Deal Value ($m)': '482',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Indication(s)': 'Class II and Class III BRAF mutant cancer',
    'Target(s)': 'RAF',
    'Deal Structure':
      "Under the terms of this license agreement, METiS will receive exclusive license to develop and commercialize Voronoi's pan-RAF inhibitor program worldwide. In exchange, Voronoi will receive up to $1.7 million in upfront cash and success-based near-term discovery milestones. Voronoi will also be eligible for success-based payment up to $480.5 million in development, regulatory and sales milestones and tiered royalties based on annual net sales.",
    'FW Link1':
      'METiS Announces Pan-RAF Inhibitor License Agreement with Voronoi;;https://firstwordpharma.com/story/5651967/'
  },
  {
    'Date Announced': '13-Sep-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Strata Oncology',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Pfizer',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Biomarkers',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Braftovi, Mektovi, Lorbrena',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Pfizer will provide Braftovi (encorafenib), Mektovi (binimetinib), and Lorbrena (lorlatinib) for up to six new cohorts of patients with early-stage lung, melanoma, colorectal, and other cancers who have evidence of micrometastatic disease after initial treatment. Pfizer is already providing Strata with Braftovi (encorafenib), Mektovi (binimetinib), Lorbrena (lorlatinib), Talzenna (talazoparib), and Inlyta (axitinib) for evaluation in four late-stage cohorts of the Strata PATH trial.',
    'FW Link1':
      'Strata Oncology Announces Expansion of Clinical Collaboration with Pfizer for Strata PATH Trial into Early-Stage Cancer;;https://www.firstwordhealthtech.com/story/5652103/'
  },
  {
    'Date Announced': '13-Sep-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Medicenna',
    'Country (Acquiring Company/Partner)': 'Canada',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Merck & Co.',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immuno-oncology',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'MDNA11, Keytruda',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'CD122, PD-1',
    'Deal Structure':
      "Under the terms of the clinical trial supply and collaboration agreement, Medicenna will sponsor the study and Merck will supply KEYTRUDA. The two companies will establish a Joint Development Committee to optimally advance the study's combination arm.",
    'FW Link1':
      'Medicenna Announces Clinical Collaboration with Merck to evaluate MDNA11 in combination with KEYTRUDA (pembrolizumab) in ABILITY Trial;;https://firstwordpharma.com/story/5652006/'
  },
  {
    'Date Announced': '14-Sep-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Qurient',
    'Country (Acquiring Company/Partner)': 'Korea, South',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Merck & Co.',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immuno-oncology',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'Q901, Keytruda',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'CDK7, PD-1',
    'Deal Structure':
      'Under this agreement, Qurient will conduct a phase 1/2 study in the U.S. to evaluate safety and efficacy of Q901 in combination with KEYTRUDA for the treatment of selected advanced solid tumors. Terms of the collaboration were not disclosed.',
    'FW Link1':
      'Qurient Announces Collaboration Agreement with MSD to Evaluate Selective CDK7 Inhibitor Q901 in Combination With KEYTRUDA (pembrolizumab);;https://firstwordpharma.com/story/5652742/'
  },
  {
    'Date Announced': '14-Sep-2022',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Maxpro Capital Acquisition',
    'Country (Acquiring Company/Partner)': 'Taiwan',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Apollomics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'vebreltinib',
    'Indication(s)':
      'Non-small-cell lung cancer, other advanced tumours with c-Met alterations',
    'Target(s)': 'c-Met',
    'Deal Structure':
      'Apollomics and Maxpro Capital Acquisition Corp. announced a definitive agreement for a business combination (the "Transaction" or the "Business Combination") that would result in Apollomics becoming a publicly traded company on the Nasdaq Global Market ("Nasdaq").',
    'FW Link1':
      'Apollomics Inc., a Late-Stage Clinical Biopharmaceutical Company to be Listed on Nasdaq Through Business Combination with Maxpro Capital Acquisition Corp.;;https://firstwordpharma.com/story/5652540/'
  },
  {
    'Date Announced': '14-Sep-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'BriaCell',
    'Country (Acquiring Company/Partner)': 'Canada',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Caris Life Sciences',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Biomarkers',
    'Lead Asset Stage/Phase': 'Phase I',
    'Indication(s)': 'Breast cancer',
    'Deal Structure':
      'Under the terms of the agreement, Caris will help BriaCell with efficient patient identification, accelerating enrollment for its current Phase I/II clinical trial in advanced metastatic breast cancer of certain genetically defined subgroups.',
    'FW Link1':
      'BriaCell Partners with Caris Life Sciences to Expand Patient Outreach and Molecular Profiling;;https://firstwordpharma.com/story/5652664/'
  },
  {
    'Date Announced': '15-Sep-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'IN8bio',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'University of Louisville',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'INB-400',
    'Indication(s)': 'Glioblastoma',
    'Deal Structure':
      "The collaboration provides IN8bio exclusive access to a state-of-the-art GMP manufacturing facility and is structured to support the Company's anticipated INB-400 Phase 2 clinical program in glioblastoma.",
    'FW Link1':
      'IN8bio Partners with the Dunbar CAR T-Cell Program at the University of Louisville as the Manufacturing Center for INB-400;;https://firstwordpharma.com/story/5652994/'
  },
  {
    'Date Announced': '15-Sep-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Caravan Biologix',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'MDimune',
    'Country (Target Company/Partner)': 'Korea, South',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Drug delivery',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      'Caravan Biologix, Inc., a developer of novel cell-derived vesicle (CDV) therapeutics targeting specific cancers and gene therapy-related diseases, announced that it has entered into an agreement with Seoul-based MDimune Inc. to apply their nanovesicle technology to enhance the effects of chimeric antigen receptor-expressing natural killer (CAR-NK) cells on various solid tumor cancers.',
    'FW Link1':
      'Caravan Biologix, Inc. Collaborates with MDimune on Cell-Derived NanoVesicles for Cancer;;https://firstwordpharma.com/story/5653092/'
  },
  {
    'Date Announced': '15-Sep-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'CARTEXELL',
    'Country (Acquiring Company/Partner)': 'Korea, South',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Integral Molecular',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'CAR-T cell therapies',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'Claudin 18.2',
    'Deal Structure':
      'Under the terms of the agreement, Integral Molecular will provide an exclusive worldwide license to CARTEXELL to use the panel of high-affinity, high-specificity, and fully humanized CLND18.2 MAbs for the development of CAR-T cell therapies against solid tumors including gastric, lung, pancreatic and esophageal cancers. CARTEXELL will be solely responsible for all research, development, and commercial activities.',
    'FW Link1':
      'Integral Molecular Licenses Claudin 18.2 Monoclonal Antibodies to CARTEXELL for Oncology Cell Therapies;;https://firstwordpharma.com/story/5653037/'
  },
  {
    'Date Announced': '15-Sep-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Merck & Co.',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Syapse',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Real-world data',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Merck will utilize Syapse's real-world data platform across multiple tumor types with a focus on better understanding the critical stage in the patient journey leading up to a diagnosis and beginning treatment.",
    'FW Link1':
      'Syapse Enters Collaboration with Merck Using Real-World Evidence to Better Understand Cancers;;https://firstwordpharma.com/story/5653003/'
  },
  {
    'Date Announced': '16-Sep-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Marengo Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'National Cancer Institute',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Bi-functional fusion antibodies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'STAR0602',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'TCR Vβ',
    'Deal Structure':
      "The NCI and its collaborators at Marengo Therapeutics, Inc. will conduct preclinical and clinical investigations of Marengo Therapeutics' selective immune activation antibody repertoire in cancer and other T cell-related diseases.",
    'FW Link1':
      'Marengo Therapeutics Advances First-In-Class Selective T cell Activator, STAR0602, into Human Trials in Solid Tumors and Enters Cooperative Research and Development Agreement with the National Cancer Institute;;https://firstwordpharma.com/story/5653315/'
  },
  {
    'Date Announced': '16-Sep-2022',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Roquefort Therapeutics',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Oncogeni',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Total Deal Value ($m)': '6',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy, siRNA',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Roquefort Therapeutics is pleased to announce the completion of its acquisition of the entire issued share capital of Oncogeni Limited ("Oncogeni") for an aggregate consideration of £5,500,000 satisfied by the issue of 50,000,000 new ordinary shares in the Company ("Consideration Shares") to the shareholders of Oncogeni (the "Acquisition").',
    'FW Link1':
      'Roquefort Therapeutics: Acquisition Completion, Admission & Voting Rights;;https://firstwordpharma.com/story/5653310/'
  },
  {
    'Date Announced': '20-Sep-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Puma Biotechnology',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Takeda',
    'Country (Target Company/Partner)': 'Japan',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '7',
    'Total Deal Value ($m)': '294',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'alisertib',
    'Indication(s)': 'Breast cancer, small-cell lung cancer',
    'Target(s)': 'Aurora kinase A',
    'Deal Structure':
      'Under the terms of the agreement, Puma will assume sole responsibility for the global development and commercialization of alisertib. Takeda will receive an upfront license fee of $7 million and is eligible to receive potential future milestone payments of up to $287.3 million upon Puma’s achievement of certain regulatory and commercial milestones over the course of the agreement, as well as tiered royalty payments for any net sales of alisertib.',
    'FW Link1':
      'Takeda licenses alisertib to Puma Biotech for up to $294 million;;https://firstwordpharma.com/story/5654664/'
  },
  {
    'Date Announced': '20-Sep-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Shuttle Pharmaceuticals',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'TCG GreenChem',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Radiation sensitiser',
    'Lead Asset(s)': 'Ropidoxuridine',
    'Indication(s)': 'Brain tumours, sarcomas, pancreatic cancers',
    'Deal Structure':
      'In conjunction with manufacturing Ropidoxuridine, TCG GreenChem will perform process research, development and optimization work for Shuttle Pharma related to Ropidoxuridine and create working standards of starting materials and intermediates to support the qualitative/quantitative analysis of the drug reaction progress, determination of impurities, total mass balance and assay yields of the reactions. Shuttle Pharma will own all intellectual property and improvements developed through the Manufacturing Agreement.',
    'FW Link1':
      'Shuttle Pharmaceuticals Enters into Manufacturing Agreement for Ropidoxuridine;;https://firstwordpharma.com/story/5654467/'
  },
  {
    'Date Announced': '20-Sep-2022',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Cellistic',
    'Country (Acquiring Company/Partner)': 'Belgium',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Celyad Oncology',
    'Country (Target Company/Partner)': 'Belgium',
    'Region (Target Company/Partner)': 'Europe',
    'Total Deal Value ($m)': '6',
    'Geographic Remit of Deal': 'Belgium',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy',
    'Deal Structure':
      "Under the terms of an asset purchase agreement between Celyad Oncology and Cellistic, Cellistic agreed to acquire Celyad Oncology's Manufacturing Business Unit in Mont-Saint-Guibert, Belgium, for a total consideration of €6 million. Celyad Oncology's experienced manufacturing team will join Cellistic.",
    'FW Link1':
      'Cellistic and Celyad Oncology Announce GMP Cell Therapy Manufacturing Operations Transaction;;https://firstwordpharma.com/story/5654310/'
  },
  {
    'Date Announced': '20-Sep-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Jupiter Wellness',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Rejoy',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Topical formulation',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'JW-500',
    'Indication(s)':
      'Nipple neuropathies and associated sexual problems in women that have been treated for breast cancer',
    'Deal Structure':
      'Jupiter Wellness has signed an exclusive licensing agreement with Rejoy, Inc., a California Corporation, to develop prescription products for the treatment of nipple neuropathies and associated sexual problems in women that have been treated for breast cancer. The exclusive license includes issued patents and technology, including formulations.',
    'FW Link1':
      'Jupiter Wellness Signs Exclusive License Agreement With Rejoy Corporation;;https://firstwordpharma.com/story/5654422/'
  },
  {
    'Date Announced': '20-Sep-2022',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'Accord BioPharma ',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'StoryMD',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Digital health information',
    'Indication(s)': 'Prostate cancer',
    'Deal Structure':
      "Accord BioPharma has signed on as StoryMD's very first corporate partner, to support StoryMD's advanced prostate cancer content, allowing Accord BioPharma to provide a comprehensive, user-friendly tool to help patients and their caregivers navigate advanced prostate cancer.",
    'FW Link1':
      'Accord BioPharma Partners with StoryMD to Deliver Advanced Prostate Cancer Educational Content to Clinicians and Patients;;https://www.firstwordhealthtech.com/story/5654469/'
  },
  {
    'Date Announced': '20-Sep-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'NETRIS Pharma',
    'Country (Acquiring Company/Partner)': 'France',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Orano, Centre Léon Bérard',
    'Country (Target Company/Partner)': 'France',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody radio-conjugates',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'NP137',
    'Indication(s)': 'Cancer',
    'Target(s)': 'Netrin-1',
    'Deal Structure':
      'NETRIS Pharma has entered into a scientific collaboration agreement with Orano and the Centre Léon Bérard (CLB) to develop novel Antibody Radio-Conjugates for the treatment of cancer. Under the terms of the agreement, planned to be completed within one year, NETRIS Pharma, Orano and CLB will contribute their unique respective expertise in anti-netrin-1 biology and mode-of-action, radioisotope and nuclear based products, and preclinical and clinical oncology expertise to develop new compounds for clinical use.',
    'FW Link1':
      'NETRIS Pharma, Orano and Centre Léon Bérard Announce Scientific Collaboration to Develop Novel Antibody Radio-Conjugate Therapies;;https://firstwordpharma.com/story/5654319/'
  },
  {
    'Date Announced': '20-Sep-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'SillaJen',
    'Country (Acquiring Company/Partner)': 'Korea, South',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Basilea',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Upfront Value ($m)': '14',
    'Total Deal Value ($m)': '334',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'BAL0891',
    'Indication(s)': 'Cancer',
    'Target(s)': 'TTK, PLK1',
    'Deal Structure':
      'Under the asset purchase agreement Basilea is selling its intellectual property rights generated under the license and collaboration agreement with NTRC. In addition, Basilea is sub-licensing its rights and obligations under the license and collaboration agreement with NTRC to SillaJen. Under the terms of the agreement, Basilea will receive upfront and near-term milestone payments of USD 14 million. Basilea is also eligible to receive further payments of up to approximately USD 320 million upon the achievement of predefined development, regulatory and sales milestones and tiered royalties on net sales starting in the single digit range going up to double-digits. Basilea remains responsible for making milestone and royalty payments to NTRC according to the license and collaboration agreement with NTRC.',
    'FW Link1':
      'Basilea announces acquisition of rights for oncology asset BAL0891 by SillaJen and updates financial guidance;;https://firstwordpharma.com/story/5654305/'
  },
  {
    'Date Announced': '21-Sep-2022',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'iBio',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'RubrYc Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '1',
    'Total Deal Value ($m)': '6',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody',
    'Lead Asset(s)': 'IBIO-101',
    'Indication(s)': 'Cancer',
    'Target(s)': 'CD25',
    'Deal Structure':
      'iBio announced that it closed on the acquisition of substantially all of the assets of its partner, RubrYc Therapeutics, Inc. ("RubrYc") after it entered into a definitive asset purchase agreement (the "Purchase Agreement").',
    'FW Link1':
      'iBio Acquires RubrYc Therapeutics’ AI Drug Discovery Platform and Pipeline;;https://www.firstwordhealthtech.com/story/5655155/'
  },
  {
    'Date Announced': '21-Sep-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Celltrion',
    'Country (Acquiring Company/Partner)': 'Korea, South',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Abpro',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Total Deal Value ($m)': '1,750',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Bispecific antibody',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'ABP 102',
    'Indication(s)':
      'HER2+ cancer, including breast, gastric and pancreatic cancer',
    'Target(s)': 'HER2, CD3',
    'Deal Structure':
      'Through this global partnership, Abpro will receive payments from Celltrion of up to $1.75 Billion, including an equity investment, development and commercial milestone payments and worldwide profit sharing. Celltrion will be in charge of the development of ABP 102 following the completion of in vitro studies by Abpro and will have world-wide commercialization rights.',
    'FW Link1':
      'Abpro Finalizes Strategic Partnership with Celltrion for Worldwide Development and Commercialization of a Cancer Treatment Bispecific Antibody;;https://firstwordpharma.com/story/5654943/'
  },
  {
    'Date Announced': '21-Sep-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Day One Biopharmaceuticals',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Foundation Medicine',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Companion diagnostic, small molecules',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'FoundationOne CDx, tovorafenib',
    'Indication(s)': 'Paediatric low-grade glioma',
    'Target(s)': 'RAF',
    'Deal Structure':
      "Foundation Medicine and Day One Biopharmaceuticals announced a collaboration to develop FoundationOne®CDx as a companion diagnostic for Day One's lead investigational therapy, tovorafenib(DAY101).",
    'FW Link1':
      'Foundation Medicine and Day One Biopharmaceuticals Announce Global Collaboration to Advance Pediatric Cancer Care;;https://www.firstwordhealthtech.com/story/5655007/'
  },
  {
    'Date Announced': '21-Sep-2022',
    'Deal type': 'Terminated',
    'Acquiring Company/Partner': 'Allogene Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Servier',
    'Country (Target Company/Partner)': 'France',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Allogeneic CAR T-cell therapy',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'ALLO-501A, ALLO-501, UCART19',
    'Indication(s)': 'Haematological malignancies',
    'Target(s)': 'CD19',
    'Deal Structure':
      'Allogene revealed in a US securities filing that Servier is discontinuing its collaboration with the company on therapies directed against CD19, including the allogeneic CAR-T cell product candidates UCART19, ALLO-501 and ALLO-501A. According to the filing, the discontinuation does not impact Allogene\'s exclusive US license for the candidates, but does allow it to exercise an option to license the CD19 products outside the US, with Servier requesting that this be done "within a limited timeframe."',
    'FW Link1':
      'Servier pulls out of Allogene pact for CD19-directed therapies;;https://firstwordpharma.com/story/5655182/'
  },
  {
    'Date Announced': '21-Sep-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Coeptis Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'University of Pittsburgh',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Multi-antigen CAR T-cell therapy',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Haematologic and solid tumours',
    'Deal Structure':
      'Coeptis Therapeutics announced entry into an exclusive license agreement with the University of Pittsburgh for the rights to a chimeric antigen receptor T cell (CAR T) technology - SNAP-CAR - designed to target multiple antigens simultaneously and potentially address a range of hematologic and solid tumors, including breast and ovarian cancer.',
    'FW Link1':
      'Coeptis Therapeutics Enters Exclusive License Agreement with University of Pittsburgh for Rights to SNAP-CAR, a "Multi-Antigen" CAR T Technology;;https://firstwordpharma.com/story/5654900/',
    'FW Link2':
      'Expansion of agreement to include SNAP-CAR NK cell technology announced October 11, 2023;;https://firstwordpharma.com/story/5788868/'
  },
  {
    'Date Announced': '21-Sep-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Merck KGaA',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Nerviano',
    'Country (Target Company/Partner)': 'Italy',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'NMS-293',
    'Indication(s)': 'BRCA-mutated tumours, recurrent glioblastoma',
    'Target(s)': 'PARP1',
    'Deal Structure':
      'Under the collaboration deal, Merck will pay upfront and option exercise fees of up to $65 million to Nerviano, which is also eligible to receive unspecified milestones and tiered royalties on net sales. If the option is exercised, Nerviano will grant to Merck exclusive rights to research, develop, manufacture and commercialise NMS-293. During the option period, the companies will collaborate on clinical development, with Nerviano conducting and funding global clinical trials.',
    'FW Link1':
      'Merck KGaA, Nerviano team up on next-gen PARP inhibitor;;https://firstwordpharma.com/story/5654866/'
  },
  {
    'Date Announced': '26-Sep-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Seagen',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'LAVA Therapeutics',
    'Country (Target Company/Partner)': 'Netherlands',
    'Region (Target Company/Partner)': 'Europe',
    'Upfront Value ($m)': '50',
    'Total Deal Value ($m)': '700',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Bispecific antibody',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'LAVA-1223',
    'Indication(s)': 'EGFR-expressing solid tumours',
    'Target(s)': 'EGFR',
    'Deal Structure':
      "Under the deal, Seagen will make an upfront payment of $50 million, with LAVA eligible to receive milestones of up to approximately $650 million. In addition to the upfront payment and milestones, LAVA is eligible to receive sales royalties ranging from the single digits to the mid-teens if LAVA-1223 reaches the market. Under the agreed terms, Seagen also has the opportunity to negotiate rights to apply LAVA's Gammabody platform on up to two additional tumour targets.",
    'FW Link1':
      'Seagen, LAVA enter deal for bispecific T-cell engager targeting EGFR tumours;;https://firstwordpharma.com/story/5656479/'
  },
  {
    'Date Announced': '26-Sep-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'TILT Biotherapeutics',
    'Country (Acquiring Company/Partner)': 'Finland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Merck & Co.',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Oncolytic immunotherapy, immuno-oncology',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'TILT-123, Keytruda',
    'Indication(s)': 'Non-small-cell lung cancer',
    'Target(s)': 'PD-1',
    'Deal Structure':
      "TILT Biotherapeutics has signed a second clinical trial collaboration and supply agreement with MSD, a tradename of Merck & Co., Inc., Rahway, NJ, USA, for a new clinical trial evaluating TILT-123, its oncolytic adenovirus armed with two human cytokines, in combination with KEYTRUDA (pembrolizumab), MSD's anti-PD-1 therapy.",
    'FW Link1':
      'TILT Biotherapeutics Collaborates with MSD on Immunotherapy Clinical Trial in Checkpoint Inhibitor Refractory Non-Small Cell Lung Cancer;;https://firstwordpharma.com/story/5656469/'
  },
  {
    'Date Announced': '26-Sep-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Ildong Pharmaceutical',
    'Country (Acquiring Company/Partner)': 'Korea, South',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'SeraNovo',
    'Country (Target Company/Partner)': 'Netherlands',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'SeraNovo BV (Leiden, The Netherlands) is pleased to announce the closing of a multi compound deal with Ildong Pharmaceutical (Seoul, South Korea), a pharmaceutical company engaged in the research and development of small molecules in the field of neuroscience and oncology.',
    'FW Link1':
      'SeraNovo and Ildong Pharmaceutical to Collaborate on Research Projects;;https://firstwordpharma.com/story/5656583/'
  },
  {
    'Date Announced': '26-Sep-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'CellOrigin',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Qilu Pharmaceutical',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'CAR-iMAC cell therapy',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      'CellOrigin Biotech (Hangzhou) Co., Ltd. announced it has made a global strategic collaboration agreement with Qilu Pharma to develop, manufacture and commercialize proprietary "off-the-shelf" induced pluripotent stem cell- (iPSC) derived Chimeric Antigen Receptor Macrophages (CAR-iMAC) for cancer immunotherapy. The collaboration will take advantages of technologies and expertise from both parties, as well as integrate capabilities of R&D, manufacturing and marketing to develop CAR-iMAC clinical products aiming for solid tumors.',
    'FW Link1':
      'CellOrigin Biotech Announces Global Strategic Collaboration with Qilu Pharma to Develop "Off-the-Shelf" CAR-iMAC Cell Therapy;;https://firstwordpharma.com/story/5656467/'
  },
  {
    'Date Announced': '27-Sep-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Sanofi',
    'Country (Acquiring Company/Partner)': 'France',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Scribe Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '25',
    'Total Deal Value ($m)': '1,000',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'CRISPR genome editing, NK cell therapies',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'The agreement grants Sanofi non-exclusive rights to Scribe’s proprietary CRISPR platform of wholly owned enzymes to create ex vivo NK cell therapies. Under the terms of the agreement, Scribe will receive $25 million in upfront payment and be eligible to potentially receive more than $1 billion in payments based on development and commercial milestones, as well as tiered royalties on net future sales on any products that may result from this research agreement.',
    'FW Link1':
      "Sanofi to utilise Scribe's CRISPR platform to develop cancer cell therapies;;https://firstwordpharma.com/story/5656855/"
  },
  {
    'Date Announced': '27-Sep-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Eyam',
    'Country (Acquiring Company/Partner)': 'Canada',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'University of British Columbia',
    'Country (Target Company/Partner)': 'Canada',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibodies',
    'Indication(s)': 'Leukaemia, lymphoma',
    'Deal Structure':
      'Eyam Vaccines and Immunotherapeutics (Eyam), has announced the acquisition of a new Option to license the patents related to an antibody biologic cancer therapeutic from the University of British Columbia (UBC). The Option includes a portfolio of biologic patents and patent applications. The Option allows Eyam the right to negotiate an exclusive licensing agreement with UBC for the further development and commercialization of the technology.',
    'FW Link1':
      'Eyam Acquires Option for Novel Antibody Biologic Therapeutics for the Treatment of Leukemia and Lymphoma;;https://firstwordpharma.com/story/5657148/'
  },
  {
    'Date Announced': '27-Sep-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Roche',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'ArsenalBio',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '70',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'T-cell therapies',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Under the agreed terms, ArsenalBio will receive $70 million upfront, along with possible R&D and commercial milestones. Genentech and ArsenalBio said they will work together "to study effective T-cell-based modifications and develop new understandings of their effects through preclinical analysis." Both companies indicated they will leverage what they learn through the collaboration in the development of future therapeutic candidates.',
    'FW Link1':
      'Roche teams up with ArsenalBio on T-cell therapies for oncology;;https://firstwordpharma.com/story/5656941/'
  },
  {
    'Date Announced': '27-Sep-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Zai Lab',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Seagen',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '30',
    'Geographic Remit of Deal': 'Mainland China, Hong Kong, Macau, and Taiwan',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugate',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Tivdak',
    'Indication(s)': 'Cervical cancer',
    'Target(s)': 'Tissue factor',
    'Deal Structure':
      'Under the terms of the agreement, Seagen will receive an upfront payment of $30 million, as well as development, regulatory, and commercial milestone payments, and tiered royalties on net sales of TIVDAK in the Zai Lab territory. Based on the existing TIVDAK co-development and co-commercialization collaboration between Seagen and Genmab (Nasdaq: GMAB), all upfront, milestone payments, and royalties will be shared 50/50 with Genmab.',
    'FW Link1':
      'Seagen and Zai Lab Announce Regional Strategic Collaboration and License Agreement for TIVDAK (tisotumab vedotin-tftv);;https://firstwordpharma.com/story/5656897/'
  },
  {
    'Date Announced': '28-Sep-2022',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'GENinCode',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Abcodia',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Total Deal Value ($m)': '1',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'ROCA',
    'Indication(s)': 'Ovarian cancer',
    'Deal Structure':
      'GENinCode has acquired ovarian cancer company Abcodia for a maximum £1 million. The acquisition hands the buyer Abcodia’s algorithmic technology for the Risk Assessment of Ovarian Cancer Algorithm (ROCA) test.',
    'FW Link1':
      'Abcodia and its ovarian cancer test acquired by Oxford company;;https://www.firstwordhealthtech.com/story/5657402/'
  },
  {
    'Date Announced': '28-Sep-2022',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'GE Healthcare',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Susan G. Komen',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Educational programmes',
    'Indication(s)': 'Breast cancer',
    'Deal Structure':
      "Susan G. Komen and GE Healthcare are partnering for the first time to support the Susan G Komen's Metastatic Breast Cancer (MBC) Impact Series. In continuing its support of Susan G. Komen and efforts to improve outcomes for the breast cancer community, GE Healthcare has made a commitment to help Komen provide critical educational programs for the MBC community. GE Healthcare's investment will support the Susan G. Komen's MBC Impact Series to ensure that people living with MBC and their loved ones have a safe and collaborative space to gather information to help make decisions for improved physical and emotional health.",
    'FW Link1':
      'Susan G. Komen and GE Healthcare Partner to Improve Lives and Raise Awareness of Advancements in Diagnostics and Treatment Options for Metastatic Breast Cancer;;https://firstwordpharma.com/story/5657498/'
  },
  {
    'Date Announced': '3-Oct-2022',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'TradeUP',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Estrella',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'T-cell immunotherapy',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'EB103',
    'Indication(s)': 'Blood cancers',
    'Target(s)': 'CD19',
    'Deal Structure':
      'Estrella Biopharma, Inc. ("Estrella"), a preclinical-stage biopharmaceutical company focusing on cancer therapeutics, and TradeUP Acquisition Corp. ("TradeUP") (NASDAQ: UPTD), a publicly traded special purpose acquisition company, announced that they have entered into a definitive business combination agreement on September 30, 2022 that will result in Estrella becoming a publicly listed company. Upon closing of the transaction, TradeUP will be renamed "Estrella Immunopharma, Inc." (the "Combined Company").',
    'FW Link1':
      'Estrella Biopharma, Inc., a biopharmaceutical company to Become a Publicly Traded Company Via Merger with TradeUP Acquisition Corp.;;https://firstwordpharma.com/story/5659444/'
  },
  {
    'Date Announced': '4-Oct-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'BMS',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Autolus',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'The agreement grants Bristol Myers Squibb access to incorporate Autolus’ proprietary RQR8 safety switch into an initial set of selected cell therapy programs on a target-by-target basis for the treatment of cancer, with an option for Bristol Myers Squibb to incorporate the RQR8 safety switch in additional cell therapy programs beyond the initial set of selected programs. Under the terms of the agreement, Autolus will receive an upfront payment for access to the RQR8 safety switch for the initial set of cell therapy programs with the potential for near term option exercise fees and development milestone payments. In addition, Autolus would be entitled to receive royalties on net sales of all Bristol Myers Squibb cell therapy products that incorporate the RQR8 safety switch.',
    'FW Link1':
      'Autolus Therapeutics Announces Collaboration with Bristol Myers Squibb for Use of Autolus’ Proprietary Safety Switch System;;https://firstwordpharma.com/story/5660106/'
  },
  {
    'Date Announced': '4-Oct-2022',
    'Deal type': 'Product Acquisition',
    'Acquiring Company/Partner': 'ANEW MEDICAL',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Undisclosed',
    'Geographic Remit of Deal': 'Germany',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Generics, anticancer drugs',
    'Lead Asset Stage/Phase': 'Marketed',
    'Indication(s)': 'Colorectal, gastric, lung cancer, solid tumours',
    'Deal Structure':
      'ANEW MEDICAL, INC. ("ANEW" or "the Company") (OTCPK: LEAS) announced that it has acquired five market-approved anti-cancer drugs approved for sale in Germany. The Market Authorizations (MA\'s) are for four of the drugs that comprise the "FOLFOX" and "FOLFIRI" multi-drug regimens used in treatment of metastatic colorectal and gastric cancer and in two of the drugs are used to treat metastatic lung cancer. The drugs are important in the treatment of many solid tumors in both childhood and adult cancers.',
    'FW Link1':
      'ANEW MEDICAL, INC. Acquires Five Drug Approvals with Manufacturing in Europe;;https://firstwordpharma.com/story/5660175/'
  },
  {
    'Date Announced': '6-Oct-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'NexImmune',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'NIH',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy',
    'Indication(s)': 'Human T-cell leukaemia virus type 1, Epstein-Barr virus',
    'Deal Structure':
      'The collaboration will focus on enriching and expanding virus-specific T cell populations and determining their activity against infected human cell lines. The goal of this collaboration is to develop adoptive cell therapies that may benefit patients afflicted with immunological disorders related to these viral infections. Initially, we will focus our efforts on studying Epstein-Barr virus (EBV) and Human T-cell Leukemia Virus, type 1 (HTLV-1).',
    'FW Link1':
      'NexImmune Announces Research Collaboration with National Institute of Neurological Disorders and Stroke (NINDS) of the National Institutes of Health (NIH);;https://firstwordpharma.com/story/5661034/'
  },
  {
    'Date Announced': '6-Oct-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Kartos Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Tempus',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Companion diagnostic, small molecules',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'navtemadlin',
    'Indication(s)': 'Merkel cell carcinoma',
    'Target(s)': 'MDM2',
    'Deal Structure':
      'Tempus announced a new collaboration to develop a companion diagnostic (CDx) test with Kartos Therapeutics, a clinical stage biopharmaceutical company, in support of its ongoing Phase II study of navtemadlin (KRT-232). Tempus’ CDx test, which will be developed on Tempus’ xT platform, will be used to identify patients with TP53 wild-type (TP53WT) Merkel cell carcinoma (MCC) who may be eligible for treatment with navtemadlin.',
    'FW Link1':
      'Tempus Announces Companion Diagnostic Collaboration with Kartos Therapeutics;;https://firstwordpharma.com/story/5661097/'
  },
  {
    'Date Announced': '7-Oct-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Cline',
    'Country (Acquiring Company/Partner)': 'Sweden',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'IFLAI',
    'Country (Target Company/Partner)': 'Sweden',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'AI software, diagnostics',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Cline Scientific announces that it initiates a project with software company IFLAI to develop custom AI-based software for the cancer diagnostic project, CellRACE. Cline and IFLAI have agreed on a first-step project to create a tailored solution to the automated analysis of data gathered in the CellRACE project to aid in CellRACE's further development. This project will be completed in Q4 of 2022. After this, the Cline team will test the software and gather data on a range of tumor cell types. In a following project, this data will be used to enhance the system as it can “learn” a wide range of cancer phenotypes and then ultimately be used as part of an integrated algorithm and measurement system to determine cancer metastatic potential from a patient sample.",
    'FW Link1':
      'Cline starts collaboration with IFLAI in development of AI software for CellRACE project;;https://www.firstwordhealthtech.com/story/5661328/'
  },
  {
    'Date Announced': '10-Oct-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Immune-Onc Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'BeiGene',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immunotherapy, checkpoint inhibitors',
    'Lead Asset(s)': 'IO-108, IO-202, tislelizumab',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'LILRB2, LILRB4, PD-1',
    'Deal Structure':
      'Immune-Onc Therapeutics has entered into a clinical trial collaboration and supply agreement with BeiGene to evaluate Immune-Onc’s first-in-class myeloid checkpoint inhibitors, IO-108 and IO-202, in combination with BeiGene’s anti-PD-1 antibody, tislelizumab, as part of its clinical development programs in China. Under the terms of the collaboration, Immune-Onc will sponsor and fund the IO-108 and IO-202 clinical trials in China, and BeiGene will provide tislelizumab. Immune-Onc retains global development and commercial rights to IO-108 and IO-202.',
    'FW Link1':
      'Immune-Onc Therapeutics Enters into Clinical Collaboration with BeiGene in China;;https://firstwordpharma.com/story/5661730/'
  },
  {
    'Date Announced': '11-Oct-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'ONI',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'University of Pennsylvania',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'CAR-T therapies',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'The collaboration aims to enhance the understanding of CAR-T therapies using super-resolution microscopy, identifying predictive biomarkers and characteristics related to CAR-T treatment efficacy and safety, with the goal of improving patient outcomes.',
    'FW Link1':
      'ONI and the University of Pennsylvania Start Research Collaboration to Identify Unique Biomarkers and Characteristics to Guide Next Generation CAR-T Development;;https://firstwordpharma.com/story/5662031/'
  },
  {
    'Date Announced': '11-Oct-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Fujirebio',
    'Country (Acquiring Company/Partner)': 'Japan',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Self-screen',
    'Country (Target Company/Partner)': 'Netherlands',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'PreCursor-M+',
    'Indication(s)': 'Cervical cancer',
    'Deal Structure':
      'Fujirebio Europe and Self-screen B.V. announced a commercial collaboration around the distribution of the PreCursor-M+ methylation-specific molecular IVD assay from Self-screen B.V.',
    'FW Link1':
      "Fujirebio Europe Receives Distribution Rights to Market Self-screen's PreCursor-M+ Methylation-specific Molecular Assay, a Powerful Solution to Identify Women at High Risk for Cervical Cancer;;https://www.firstwordhealthtech.com/story/5662068/"
  },
  {
    'Date Announced': '11-Oct-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Compass Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Merck & Co.',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Monoclonal antibody, immune checkpoint inhibitor',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'CTX-471, Keytruda',
    'Indication(s)':
      'Non-small-cell lung cancer, melanoma, small-cell lung cancer, mesothelioma, head and neck cancer',
    'Target(s)': 'CD137, PD-1',
    'Deal Structure':
      'Under the agreement, Compass is the study sponsor, and Merck will provide the clinical supply of KEYTRUDA; the companies will form a Joint Development Committee to review the clinical trial results.',
    'FW Link1':
      'Compass Therapeutics Announces Clinical Collaboration with Merck to Evaluate CTX-471 in Combination with KEYTRUDA (pembrolizumab);;https://firstwordpharma.com/story/5662153/'
  },
  {
    'Date Announced': '12-Oct-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Inceptor Bio',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Avectas',
    'Country (Target Company/Partner)': 'Ireland',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'CAR-T therapies',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      "Under the agreement, Inceptor will utilize Avectas' SOLUPORE technology as an alternative to electroporation for engineering T cells with the goal to yield a healthier T cell product.",
    'FW Link1':
      'Inceptor Bio and Avectas Announce Collaboration to Improve Manufacturing of CAR-T Cell Therapies for the Treatment of Solid Tumors;;https://firstwordpharma.com/story/5662823/'
  },
  {
    'Date Announced': '12-Oct-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Merck & Co.',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Moderna',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Personalised cancer vaccine',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'mRNA-4157/V940',
    'Indication(s)': 'Melanoma',
    'Deal Structure':
      'Under the agreement, originally established in 2016 and amended in 2018, Merck will pay Moderna $250 million to exercise its option for personalized cancer vaccines including mRNA-4157/V940 and will collaborate on development and commercialization. The payment will be expensed by Merck in the third quarter of 2022 and included in its non-GAAP results. Merck and Moderna will share costs and any profits equally under this worldwide collaboration.',
    'FW Link1':
      "Merck & Co. opts to jointly develop Moderna's personalised cancer vaccine;;https://firstwordpharma.com/story/5662644/"
  },
  {
    'Date Announced': '12-Oct-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Cancer Research UK',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Physiomics',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'CAR-T cell engager',
    'Lead Asset(s)': 'ALETA-001',
    'Indication(s)': 'Blood cancers',
    'Deal Structure':
      'Physiomics plc (AIM: PYC), the consultancy company offering expertise in mathematical modelling and simulation to enable smarter decision making in drug development for the biopharma industry, has signed an agreement to work with Cancer Research UK. Physiomics will use its PKPD modelling capabilities to support the Cancer Research UK sponsored early phase clinical development of ALETA-001, a CAR-T cell engager candidate for the treatment of blood cancers developed by Aleta Biotherapeutics.',
    'FW Link1':
      'Physiomics - Contract award;;https://firstwordpharma.com/story/5662775/'
  },
  {
    'Date Announced': '12-Oct-2022',
    'Deal type': 'Terminated',
    'Acquiring Company/Partner': 'GSK',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'CRT Pioneer Fund',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'SRA737',
    'Indication(s)': 'Cancer',
    'Target(s)': 'Checkpoint kinase 1',
    'Deal Structure':
      'Sareum Holdings plc (AIM: SAR), a clinical stage biotechnology company developing next generation kinase inhibitors for autoimmune disease and cancer, announces that it has been informed by Sierra Oncology, Inc. (“Sierra”) (a subsidiary of GSK plc (“GSK”)) that it intends to return the rights for SRA737 to the CRT Pioneer Fund LP ("CPF"). CPF acquired worldwide commercial rights to the programme in 2013 as part of a co-development agreement with Sareum. SRA737 was licensed by CPF to Sierra in September 2016.',
    'FW Link1':
      'Sareum: Update on SRA737;;https://firstwordpharma.com/story/5662630/'
  },
  {
    'Date Announced': '12-Oct-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'VBI Vaccines',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Agenus',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cancer vaccine, monoclonal antibody',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'VBI-1901, balstilimab',
    'Indication(s)': 'Primary glioblastoma',
    'Target(s)': 'gB, pp65, PD-1',
    'Deal Structure':
      'VBI Vaccines and Agenus announced a collaboration to evaluate the combination of VBI-1901, VBI’s cancer vaccine immunotherapeutic, and balstilimab, Agenus’ monoclonal antibody (mAb) targeting the programmed death receptor-1 (PD-1) protein, in primary glioblastoma (GBM) patients as part of the adaptive platform trial, INSIGhT. Under the agreement, VBI will be the study sponsor and will be responsible for operational execution of the combination trial, and Agenus will provide drug supply and scientific support.',
    'FW Link1':
      'VBI Vaccines and Agenus Announce Collaboration to Evaluate VBI-1901 in Combination with Anti-PD-1 Balstilimab in a Phase 2 Study in Primary Glioblastoma Patients;;https://firstwordpharma.com/story/5662745/'
  },
  {
    'Date Announced': '12-Oct-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Mirati Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Aadi',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'adagrasib, nab-sirolimus',
    'Indication(s)': 'Non-small-cell lung cancer and other solid tumours',
    'Target(s)': 'KRASG12C, mTOR',
    'Deal Structure':
      'Mirati Therapeutics and Aadi Bioscience announced a clinical collaboration to evaluate the combination of adagrasib, a KRASG12C selective inhibitor, and nab-sirolimus, a small molecule mTOR inhibitor complexed with human albumin in KRAS G12C mutant non-small cell lung cancer (NSCLC) and other solid tumors. Under the terms of the collaboration agreement, Mirati will be responsible for sponsoring and operating the Phase 1/2 study, and jointly with Aadi, will oversee and share the cost of the study.',
    'FW Link1':
      'Mirati Therapeutics and Aadi Bioscience Partner to Evaluate the Combination of Adagrasib with Nab-sirolimus in Patients with Advanced Non-Small Cell Lung Cancer and Other Solid Tumors with a KRAS[G12C] Mutation;;https://firstwordpharma.com/story/5662825/'
  },
  {
    'Date Announced': '12-Oct-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Bayer',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Tavros Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '18',
    'Total Deal Value ($m)': '930',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Tavros will receive $17.5 million upfront as part of the deal, plus up to $430.5 million in milestones for four initial programmes, as well as certain royalties, over an initial five-year term. Meanwhile, Bayer will also have rights to opt-in to up to five more targets, which could see Tavros pocket another $482 million in additional payments.',
    'FW Link1':
      'Bayer in pursuit of oncology targets via $930-million Tavros deal;;https://firstwordpharma.com/story/5662660/'
  },
  {
    'Date Announced': '12-Oct-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Alphabet',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Terray Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Under the terms of the agreement, Terray and Calico will identify small molecule leads against a set of targets nominated by Calico using the Terray tNova platform, with Calico subsequently assuming responsibility for development and commercialization. Terray will receive an upfront payment and is eligible to receive milestones as well as tiered royalties on net sales.',
    'FW Link1':
      'Terray Therapeutics and Calico Enter into a Multi-Target Collaboration to Discover Small Molecule Therapeutics for Age-Related Diseases;;https://firstwordpharma.com/story/5662988/'
  },
  {
    'Date Announced': '13-Oct-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Jacobio Pharma',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Merck KGaA',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'China, Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules, monoclonal antibodies',
    'Lead Asset(s)': 'JAB-21822, Erbitux',
    'Indication(s)': 'Colorectal cancer',
    'Target(s)': 'KRAS G12C, EGFR',
    'Deal Structure':
      'This clinical study aims to evaluate the efficacy of JAB-21822 in combination with cetuximab in patients with KRAS G12C-mutated colorectal cancer. Jacobio is the sponsor of the combination study, and Merck will provide cetuximab for clinical trials in China and Europe under the collaboration agreement.',
    'FW Link1':
      'Jacobio Pharma to Collaborate with Merck on Clinical Trial of JAB-21822 in Combination with Cetuximab;;https://firstwordpharma.com/story/5663064/'
  },
  {
    'Date Announced': '13-Oct-2022',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Imara',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Enliven Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'ELVN-001, ELVN-002',
    'Indication(s)':
      'Chronic myeloid leukaemia, lung cancer, HER2-driven tumour types',
    'Target(s)': 'BCR-ABL, HER2',
    'Deal Structure':
      'Enliven Therapeutics, Inc. (Enliven), a clinical-stage precision oncology company focused on the discovery and development of next-generation small molecule kinase inhibitors, and Imara Inc. (Nasdaq: IMRA) (Imara) announced that they have entered into a definitive merger agreement to combine the companies in an all-stock transaction. The combined company will focus on advancing Enliven’s pipeline of precision oncology product candidates. Upon completion of the merger, which is subject to approval by Imara’s and Enliven’s stockholders, the combined company is expected to operate under the name Enliven Therapeutics, Inc. and trade on the Nasdaq Global Select Market under the ticker symbol ELVN.',
    'FW Link1':
      'Enliven Therapeutics and Imara Announce Merger Agreement;;https://firstwordpharma.com/story/5663456/'
  },
  {
    'Date Announced': '17-Oct-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Statera Biopharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Holobeam Technologies',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostic imaging',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Under the terms of the MOU, Statera will acquire a 25% interest in Holobeam for access to their technology to assist in the identification of the cancers before they clinically manifest themselves and assist in the targeting of Statera drugs specifically to the site of the cancer in concentrations that would not be possible under normal dosing. The MOU provides for an exclusivity period of sixty (60) days for negotiating and finalizing a definitive agreement.',
    'FW Link1':
      'Statera Biopharma Announces Signing of a Binding MOU with Holobeam Technologies to Gain Access to Holobeam’s Disruptive Diagnostic Imaging and Therapeutic Technology for Cancer Patients;;https://firstwordpharma.com/story/5664411/'
  },
  {
    'Date Announced': '17-Oct-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Gilead',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'MacroGenics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '60',
    'Total Deal Value ($m)': '1,760',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Bispecific antibodies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'MGD024',
    'Indication(s)':
      'Blood cancers, acute myeloid leukaemia, myelodysplastic syndromes',
    'Target(s)': 'CD123, CD3',
    'Deal Structure':
      "Gilead Sciences and MacroGenics announced an exclusive option and collaboration agreement to develop MGD024, an investigational, bispecific antibody that binds CD123 and CD3 using MacroGenics' DART platform, and two additional bispecific research programs. The collaboration agreement grants Gilead the option to license MGD024, a potential treatment for certain blood cancers, including acute myeloid leukemia (AML) and myelodysplastic syndromes (MDS). MacroGenics will be responsible for the ongoing Phase 1 study for MGD024 during which Gilead may elect to exercise its option to license the program at predefined decision points. As part of the agreement, Gilead will pay MacroGenics an upfront payment of $60 million and MacroGenics will be eligible to receive up to $1.7 billion in target nomination, option fees, and development, regulatory and commercial milestones. MacroGenics will also be eligible to receive tiered, double-digit royalties on worldwide net sales of MGD024 and a flat royalty on worldwide net sales of products under the two research programs.",
    'FW Link1':
      'Gilead inks deal with MacroGenics on bispecific antibodies;;https://firstwordpharma.com/story/5664038/'
  },
  {
    'Date Announced': '17-Oct-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Kineta',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Merck & Co.',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies, immunotherapy',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'KVA12123, Keytruda',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'VISTA, PD-1',
    'Deal Structure':
      'Under this collaboration, Kineta will evaluate the safety, tolerability, pharmacokinetics and anti-tumor activity of KVA12123 (formerly KVA12.1), its novel anti-VISTA monoclonal antibody, alone and in combination with KEYTRUDA (pembrolizumab), Merck’s anti-PD-1 therapy, in patients with advanced solid tumors.',
    'FW Link1':
      'Kineta Announces Clinical Collaboration with Merck to Evaluate KVA12123 in Combination with KEYTRUDA (pembrolizumab) in Cancer Patients with Advanced Solid Tumors;;https://firstwordpharma.com/story/5664040/'
  },
  {
    'Date Announced': '17-Oct-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Immune-Onc Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Regeneron',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies, immunotherapy',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'IO-108, Libtayo',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'LILRB2, PD-1',
    'Deal Structure':
      'Under the terms of the agreement, Immune-Onc will sponsor and fund the planned clinical trials and Regeneron will provide cemiplimab (Libtayo). Immune-Onc retains global development and commercial rights to IO-108.',
    'FW Link1':
      'Immune-Onc Therapeutics Initiates Expansion Cohorts for IO-108 and Enters into Clinical Supply Agreement with Regeneron;;https://firstwordpharma.com/story/5664091/'
  },
  {
    'Date Announced': '17-Oct-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Ferring',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Blackstone Life Sciences',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Gene therapy',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'nadofaragene firadonavec',
    'Indication(s)': 'Bladder cancer',
    'Deal Structure':
      'As part of this restructuring, Ferring will now assume full and sole control of FerGene and nadofaragene firadonavec. The restructured agreement allows for a return on capital already invested by Blackstone, and also provides Blackstone with the option to make a passive financial investment in nadofaragene firadonavec upon achievement of a specified regulatory event in exchange for a royalty interest.',
    'FW Link1':
      'Ferring Pharmaceuticals and Blackstone Life Sciences restructure novel gene therapy collaboration;;https://firstwordpharma.com/story/5664402/'
  },
  {
    'Date Announced': '17-Oct-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Day One Biopharmaceuticals',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'National Cancer Institute',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'tovorafenib',
    'Indication(s)': 'Solid tumours, haematologic cancers',
    'Target(s)': 'RAF',
    'Deal Structure':
      'Under the terms of the CRADA, NCI investigators will have the opportunity to study tovorafenib in CTEP-sponsored trials to be conducted by NCI funded extramural clinical networks in several solid tumor and hematologic cancers based upon encouraging anti-tumor activity observed in previous studies.',
    'FW Link1':
      'Day One Announces Cooperative Research and Development Agreement with National Cancer Institute to Expand Development of Tovorafenib (DAY101);;https://firstwordpharma.com/story/5664187/'
  },
  {
    'Date Announced': '17-Oct-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Cyclotek',
    'Country (Acquiring Company/Partner)': 'Australia',
    'Region (Acquiring Company/Partner)': 'Oceania',
    'Target Company/Partner': 'ImaginAb',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Australia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Imaging agent',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': '89Zr crefmirlimab berdoxam',
    'Indication(s)': 'Cancer',
    'Target(s)': 'CD8',
    'Deal Structure':
      "ImaginAb, Inc., a global biotechnology company focused on developing 89Zr crefmirlimab berdoxam (CD8 ImmunoPET) imaging agent and radiopharmaceutical therapies (RPT) products, announced the completion of a Master Services Agreement with Cyclotek, a leading radiopharmaceutical manufacturer in Australia and New Zealand, for the commercial manufacturing and distribution of its investigational CD8 ImmunoPET agent in Australia. Under the terms of the agreement, Cyclotek will initially supply patient-specific unit doses of the imaging agent from its Melbourne-based radiopharmacy to clinical sites across Australia, for ImaginAb's clinical trials and the clinical sites for immunotherapy studies sponsored by pharma and biotech companies that have licensed CD8 ImmunoPET.",
    'FW Link1':
      'ImaginAb and Cyclotek Enter into Manufacturing and Distribution Agreement for CD8 ImmunoPET Agent in Australia;;https://firstwordpharma.com/story/5664030/'
  },
  {
    'Date Announced': '18-Oct-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'GSK',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Tempus',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial intelligence',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'GSK and Tempus, a US-based precision medicine company, have entered into a three-year collaboration agreement that provides GSK with access to Tempus’ AI-enabled platform, including its library of de-identified patient data. Through its leading Artificial Intelligence and Machine Learning (AI/ML) capability, GSK will work together with Tempus to improve clinical trial design, speed up enrolment and identify drug targets. This will contribute to GSK’s R&D success rate and provide patients with more personalised treatment faster. The new collaboration builds from the existing relationship between the companies that began in 2020 on clinical trial enrolment of patients with certain types of cancer. It will now expand GSK’s access to de-identified patient data bringing greater scale and detail.',
    'FW Link1':
      'GSK Announces Expanded Collaboration with Tempus in Precision Medicine to Accelerate R&D;;https://www.firstwordhealthtech.com/story/5664790/'
  },
  {
    'Date Announced': '18-Oct-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Ikena Oncology',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'AstraZeneca',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'IK-930, Tagrisso',
    'Indication(s)': 'Non-small-cell lung cancer',
    'Target(s)': 'TEAD, EGFR',
    'Deal Structure':
      'Under the clinical trial collaboration agreement AstraZeneca will provide Ikena with osimertinib non-exclusively for evaluation in combination with IK-930 in patients with EGFRm resistant NSCLC.',
    'FW Link1':
      'Ikena Oncology Provides Research & Development Update on IK-930 Program Targeting the Hippo Pathway;;https://firstwordpharma.com/story/5664788/'
  },
  {
    'Date Announced': '18-Oct-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Syncromune',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Biocytogen',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibodies, immunotherapy',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'YH002',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'OX40',
    'Deal Structure':
      'Under the terms of the agreement, Syncromune will acquire global rights of development and commercialization of the intratumoral combination therapy containing Eucure’s YH002 and two other active ingredients as part of the Syncrovax therapy. Pursuant to the agreement, Eucure has the potential to receive hundreds of millions of US dollars, including an upfront cash payment that reflects the projected clinical value of the molecules, significant development and regulatory milestone payments, as well as royalties and other incentives based on the long-term commercial value of the Syncrovax combination therapy. Eucure will be responsible for drug manufacturing and supply, and Syncromune will be responsible for clinical development and commercialization.',
    'FW Link1':
      'Syncromune, Inc. Enters into an Exclusive Worldwide License Agreement with Eucure Biopharma Co., Ltd., to Develop and Commercialize YH002 (OX40 Antibody) and Two Undisclosed Active Ingredients as Components of the Syncrovax Immunotherapy Platform;;https://firstwordpharma.com/story/5664757/'
  },
  {
    'Date Announced': '18-Oct-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Elicera',
    'Country (Acquiring Company/Partner)': 'Sweden',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Josep Carreras Leukaemia Research Institute',
    'Country (Target Company/Partner)': 'Spain',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'CAR T-cell therapy',
    'Indication(s)': 'Ewing sarcoma',
    'Deal Structure':
      'Elicera Therapeutics AB (publ) (“Elicera”), a clinical stage cell and gene therapy company developing next generation immuno-oncological treatments based on enhanced oncolytic viruses and CAR T-cells, announced that it has entered a Material Transfer Agreement with the Josep Carreras Leukaemia Research Institute (“JCLRI”) in Spain for use of the iTANK platform in arming CAR T-cells for the treatment of Ewing Sarcoma. The agreement is the first to be executed as part of Elicera’s strategy of finding collaborators and licensing partners for iTANK. The agreement does not grant the JCLRI any intellectual property rights to the iTANK-platform, and any patentable inventions arising from the project shall become the joint property of Elicera and the JCLRI.',
    'FW Link1':
      'Elicera Therapeutics enters first international collaboration involving the iTANK platform with a Spanish research institution;;https://firstwordpharma.com/story/5665011/'
  },
  {
    'Date Announced': '18-Oct-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Volastra Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'OpenBench',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Under the terms of the success-driven agreement, OpenBench will bear the cost and scientific risk of finding potent and developable chemical series that inhibit Volastra's target. OpenBench will receive payment upon successful discovery and subsequent assignment to Volastra of series that meet predetermined preclinical milestones. Volastra will then optimize and develop these series towards the clinic. During the term of the collaboration, Volastra will be the exclusive beneficiary of OpenBench's proprietary screening technology with respect to the cancer target in question. Financial terms were not disclosed.",
    'FW Link1':
      'OpenBench Teams Up with Volastra to Discover Early Research Hits;;https://firstwordpharma.com/story/5665044/'
  },
  {
    'Date Announced': '18-Oct-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Mendus',
    'Country (Acquiring Company/Partner)': 'Sweden',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Minaris Regenerative Medicine',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell-based vaccine, immunotherapy',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'DCP-001',
    'Indication(s)': 'Acute myeloid leukaemia, ovarian cancer',
    'Deal Structure':
      'Mendus AB, a biopharmaceutical company focused on immunotherapies addressing tumor recurrence, announced an agreement to enable the technology transfer for manufacturing of the company’s lead development program DCP-001 with Minaris Regenerative Medicine GmbH, a leading contract development and manufacturing service provider for the cell and gene therapy industry. DCP-001 is currently being evaluated in the ADVANCE II Phase 2 clinical trial to prevent cases of tumor recurrence in Acute Myeloid Leukemia (AML) and in the ALISON Phase 1 clinical trial in ovarian cancer.',
    'FW Link1':
      'Mendus secures manufacturing partner for establishing potential pivotal trial-stage and commercial production of DCP-001;;https://firstwordpharma.com/story/5664629/'
  },
  {
    'Date Announced': '18-Oct-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Synaffix',
    'Country (Acquiring Company/Partner)': 'Netherlands',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Cristal Therapeutics',
    'Country (Target Company/Partner)': 'Netherlands',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Under the terms of the agreement, Synaffix will obtain exclusive rights to the CliCr technology, to combine with its proprietary ADC platform technology. This further expands the GlycoConnect ADC tool kit by significantly enhancing the versatility across cytotoxic payloads and supports further expansion into antibody-based targeted gene therapy and immune cell engager applications.',
    'FW Link1':
      'Synaffix Licenses CliCr Chemistry from Cristal Therapeutics to Fortify Best-in-Class ADC Technology Offering;;https://firstwordpharma.com/story/5664649/'
  },
  {
    'Date Announced': '18-Oct-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Eureka Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'NIH',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'T-cell therapies',
    'Indication(s)': 'Neuroblastoma',
    'Target(s)': 'GPC2',
    'Deal Structure':
      'Eureka Therapeutics, Inc., a clinical-stage biotechnology company developing novel T cell therapies to treat cancer, announced it has entered into a license agreement with the National Cancer Institute (NCI), part of the National Institutes of Health, for a glypican 2 (GPC2) binding domain to be used for the potential development and commercialization of ARTEMIS T cell therapies for patients with neuroblastoma, a rare cancer that affects the developing nervous system. The therapy also has expansion potential in medulloblastoma and small cell lung cancer, among several other pediatric and adult cancers that express an abundance of the GPC2 protein on their cell surface.',
    'FW Link1':
      'Eureka Therapeutics Announces License with the National Cancer Institute to Advance GPC2 ARTEMIS T Cell Therapy for Neuroblastoma;;https://firstwordpharma.com/story/5664936/'
  },
  {
    'Date Announced': '18-Oct-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Siemens Healthineers',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Bracco',
    'Country (Target Company/Partner)': 'Italy',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostic imaging',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': '18F-rhPSMA-7.3',
    'Indication(s)': 'Prostate cancer',
    'Target(s)': 'PSMA',
    'Deal Structure':
      'Blue Earth Diagnostics, a Bracco company and recognized leader in the development and commercialization of innovative PET radiopharmaceuticals, announced the signing of a data-sharing agreement with Siemens Healthineers and the University Hospital of the Technical University of Munich (TUM) for anonymized 18F-rhPSMA-7.3 clinical data and images. Siemens Healthineers plans to evaluate the data to enhance its analytics and artificial intelligence (AI)-based algorithms for prostate cancer across its advanced PET/CT imaging software. 18F-rhPSMA-7.3 is an investigational radiohybrid (rh) Prostate-Specific Membrane Antigen-targeted PET imaging agent in development by Blue Earth Diagnostics for diagnostic imaging of newly diagnosed and biochemically recurrent prostate cancer. The agreement covers use of anonymized 18F-rhPSMA-7.3 PET/CT data from TUM by Siemens Healthineers to evaluate workflow improvements for image acquisition and interpretation; dose optimization; detection of small metastases and recurrent tumors; evaluation of patients in earlier stages of disease progression and stratification by low PSA levels; and, together with histopathology, assessment of the sensitivity of results. The data will also be used to define and optimize 18F-rhPSMA-7.3 workflow prior to PSMA-targeted radioligand therapy in prostate cancer, to assess its theranostic potential in therapeutic dose planning and the likelihood of treatment success.',
    'FW Link1':
      'Blue Earth Diagnostics Announces Data-sharing Agreement with Siemens Healthineers and the University Hospital of Technical University of Munich (TUM) for 18F-rhPSMA-7.3, an Investigational PET Imaging Agent, to Support AI-based Algorithms Development;;https://firstwordpharma.com/story/5665085/'
  },
  {
    'Date Announced': '18-Oct-2022',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'TriNetX',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'OncologyInformationService',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Real-world data',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'TriNetX, the global network of healthcare organizations driving real-world research to accelerate the development of new therapies, announced the acquisition of OncologyInformationService e.K. (OIS) and its subsidiary CancerDataNet GmbH (CDN), a German-based company and the subsidiary a Swiss-based company. Both companies provide real-world data (RWD) driven solutions across Europe.',
    'FW Link1':
      'TriNetX Acquires European Regulatory-Grade Real-World Evidence Leader OncologyInformationService and CancerDataNet;;https://www.firstwordhealthtech.com/story/5664979/'
  },
  {
    'Date Announced': '18-Oct-2022',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'LG Chem',
    'Country (Acquiring Company/Partner)': 'Korea, South',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'AVEO',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Total Deal Value ($m)': '566',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules, monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Fotivda',
    'Indication(s)': 'Renal cell carcinoma',
    'Target(s)': 'VEGF',
    'Deal Structure':
      "LG Chem entered into a definitive agreement to acquire AVEO Oncology for $15 per share in cash, or an equity value of $566 million. The deal will give LG Chem's life sciences division a commercial footprint in the US, whilst diversifying its pipeline with a number of cancer therapies. AVEO currently markets the VEGF receptor tyrosine kinase inhibitor Fotivda (tivozanib) in the US, where the drug was approved last year for third-line relapsed or refractory advanced renal cell carcinoma (RCC) in adults.",
    'FW Link1':
      'LG Chem to buy AVEO, gaining cancer drug Fotivda;;https://firstwordpharma.com/story/5664617/',
    'FW Link2':
      'Completion of acquisition announced January 19;;https://firstwordpharma.com/story/5698373/'
  },
  {
    'Date Announced': '19-Oct-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'JAMP Pharma',
    'Country (Acquiring Company/Partner)': 'Canada',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Alvotech',
    'Country (Target Company/Partner)': 'Iceland',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Canada',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Biosimilars',
    'Lead Asset(s)': 'AVT33, AVT16',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Alvotech (NASDAQ: ALVO), a global biotech company specializing in the development and manufacture of biosimilar medicines for patients worldwide, and the JAMP Pharma Group (JAMP Pharma), a Canadian owned pharmaceutical company headquartered in the Montreal area, announced that the companies have expanded their exclusive partnership to commercialize biosimilars developed and manufactured by Alvotech, by adding two biosimilar candidates from Alvotech’s pipeline: AVT16 a biosimilar for an immunology product and AVT33, a biosimilar for an oncology product. Alvotech will be responsible for the development and commercial supply of the biosimilar candidates. In exchange for milestone payments and future sales royalties, JAMP Pharma will receive exclusive rights to commercialize Alvotech’s biosimilars in Canada, leveraging JAMP Pharma’s strong sales, marketing capabilities and experience in successfully commercializing new biosimilars in the fast-growing Canadian market.',
    'FW Link1':
      'Alvotech and JAMP Pharma Expand Exclusive Partnership Adding Two Biosimilar Candidates for Canadian Market, Bringing New Options for Patients in Specialty Care;;https://firstwordpharma.com/story/5665177/'
  },
  {
    'Date Announced': '19-Oct-2022',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Ayala',
    'Country (Acquiring Company/Partner)': 'Israel',
    'Region (Acquiring Company/Partner)': 'Middle East',
    'Target Company/Partner': 'Advaxis',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules, immunotherapies',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'AL102',
    'Indication(s)': 'Desmoid tumours',
    'Target(s)': 'Gamma secretase',
    'Deal Structure':
      'Ayala Pharmaceuticals, Inc. (Nasdaq: AYLA) (Ayala), a clinical-stage oncology company focused on developing and commercializing small molecule therapeutics for patients suffering from rare tumors and aggressive cancers and Advaxis, Inc. (OTCQX: ADXS) (Advaxis), a biotechnology company devoted to the discovery, development and commercialization of immunotherapies based on a technology which uses engineered Listeria monocytogenes, announced that they have entered into a definitive merger agreement. The merger would result in a combined company that will focus predominantly on the development and commercialization of Ayala’s lead program AL102 for the treatment of desmoid tumors and Advaxis’s candidate ADXS-504 in development for prostate cancer.',
    'FW Link1':
      'Advaxis and Ayala Pharmaceuticals Enter into Merger Agreement;;https://firstwordpharma.com/story/5665194/',
    'FW Link2':
      'Completion of acquisition announced January 19;;https://firstwordpharma.com/story/5698193/'
  },
  {
    'Date Announced': '19-Oct-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Jazz Pharmaceuticals',
    'Country (Acquiring Company/Partner)': 'Ireland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Zymeworks',
    'Country (Target Company/Partner)': 'Canada',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '50',
    'Total Deal Value ($m)': '1,760',
    'Geographic Remit of Deal': 'Global excl. certain Asia/Pacific territories',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Bispecific antibody',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'zanidatamab',
    'Indication(s)': 'Biliary tract cancer, gastroesophageal adenocarcinoma',
    'Target(s)': 'HER2',
    'Deal Structure':
      'Under the terms of the agreement, Jazz will receive an exclusive license to develop and commercialize zanidatamab in the United States, Europe, Japan and all other territories except for those Asia/Pacific territories that Zymeworks previously licensed to BeiGene, Ltd. Zymeworks is eligible to receive a $50 million upfront payment, following receipt of the clearance relating to the United States Hart-Scott Rodino Antitrust Improvements Act of 1976 (such clearance, the "HSR Clearance"), and should Jazz decide to continue the collaboration following readout of the top-line clinical data from HERIZON-BTC-01, a second, one-time payment of $325 million. Zymeworks is also eligible to receive up to $525 million upon the achievement of certain regulatory milestones and up to $862.5 million in potential commercial milestone payments, for total potential payments of up to $1.76 billion. Pending approval, Zymeworks is eligible to receive tiered royalties between 10% and 20% on Jazz\'s net sales.',
    'FW Link1':
      "Jazz gains certain rights to Zymeworks' HER2-targeted bispecific zanidatamab;;https://firstwordpharma.com/story/5665172/"
  },
  {
    'Date Announced': '20-Oct-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Gilead',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Refuge Biotechnologies',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'CAR T-cell therapies',
    'Indication(s)': 'Blood cancers',
    'Deal Structure':
      'Kite, a Gilead Company (Nasdaq: GILD) and Refuge Biotechnologies, Inc. (“Refuge”), announced that Kite has entered into an exclusive, worldwide license agreement with Refuge, a synthetic biology company for cancer immunotherapy, for exclusive rights to utilize Refuge’s proprietary gene expression platform to develop potential treatments for blood cancers. Kite will have an exclusive license to Refuge’s intellectual property portfolio for use in blood cancers, as well as a library of synthetic gene expression programs for these indications. Refuge will retain all rights and programs related to solid tumor indications. Under the terms of the agreement, Kite will be responsible for all costs and activities related to research, development, manufacturing and commercialization. Kite will also make an upfront payment to Refuge and Refuge will be eligible to receive potential performance-based regulatory milestone payments.',
    'FW Link1':
      'Kite and Refuge Biotechnologies Announce Exclusive License Agreement for Investigational Gene Expression Platform for Blood Cancers;;https://firstwordpharma.com/story/5665859/'
  },
  {
    'Date Announced': '20-Oct-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Novartis',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Medicines Patent Pool',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Tasigna',
    'Indication(s)': 'Chronic myeloid leukaemia',
    'Target(s)': 'BCR-ABL',
    'Deal Structure':
      'Novartis signed a voluntary licensing agreement with the Medicines Patent Pool (MPP) to allow generic versions of the oral chronic myeloid leukaemia treatment Tasigna (nilotinib) to be sold in a number of low- and middle-income countries. Specifically, through the agreement, selected companies will have the opportunity to develop, manufacture and supply generic versions of Tasigna in certain markets, including seven middle-income countries such as Egypt, Indonesia and Pakistan, where patents on the product are pending or in force.',
    'FW Link1':
      'Novartis to allow Tasigna generics under "first-of-its-kind" deal with MPP;;https://firstwordpharma.com/story/5665713/',
    'FW Link2':
      'Sublicence agreements announced June 22, 2023;;https://firstwordpharma.com/story/5754059/'
  },
  {
    'Date Announced': '20-Oct-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Roche',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'HOOKIPA Pharma',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '25',
    'Total Deal Value ($m)': '955',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Arenaviral immunotherapy',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'HB-700',
    'Indication(s)': 'KRAS-mutated cancers',
    'Target(s)': 'KRAS',
    'Deal Structure':
      'Through the collaboration, HOOKIPA will conduct research and early clinical development through Phase 1b for HB-700, a novel investigational arenaviral immunotherapy for the treatment of KRAS-mutated cancers. Upon the completion of the Phase 1b trial, Roche has the right to assume development responsibility and to commercialize licensed products across multiple indications upon approval. The agreement also includes an option for Roche to license a second arenaviral cancer immunotherapy. Under the terms of the agreement, HOOKIPA will receive an upfront payment of $25 million. Roche will have the option to expand the initial collaboration by adding an additional product candidate, whereafter HOOKIPA will receive an additional $15 million payment at option exercise. Including this option payment, HOOKIPA is eligible for research, development and commercialization milestone-based payments for HB-700 and the additional product candidate totaling up to approximately $930 million. Upon commercialization, HOOKIPA is eligible to receive tiered royalties of a high single-digit to mid-teens percentage on the worldwide net sales of HB-700 and the additional product candidate.',
    'FW Link1':
      'Roche partners with HOOKIPA on arenaviral immunotherapy for KRAS-mutated cancers;;https://firstwordpharma.com/story/5665755/'
  },
  {
    'Date Announced': '20-Oct-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Erasca',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Pfizer',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'ERAS-007, Ibrance',
    'Indication(s)':
      'KRAS- and NRAS-mutant colorectal cancer, KRAS-mutant pancreatic cancer',
    'Target(s)': 'ERK1/2, CDK4/6',
    'Deal Structure':
      'This agreement will support a clinical proof-of-concept study evaluating ERAS-007, an oral ERK1/2 inhibitor, in combination with palbociclib for the treatment of patients with KRAS- and NRAS-mutant colorectal cancer (CRC) and KRAS-mutant pancreatic ductal adenocarcinoma (PDAC). The combination is currently being investigated as part of the ongoing Phase 1b/2 HERKULES-3 master protocol clinical trial in patients with gastrointestinal (GI) malignancies. Erasca is sponsoring the study, and Pfizer is supplying palbociclib at no cost.',
    'FW Link1':
      'Erasca Announces Clinical Trial Collaboration and Supply Agreement with Pfizer to Evaluate ERAS-007 and Palbociclib Combination;;https://firstwordpharma.com/story/5665927/'
  },
  {
    'Date Announced': '24-Oct-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Sauvie',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Rutgers, The State University of New Jersey',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'NK cell engager',
    'Indication(s)': 'Solid and haematologic tumours',
    'Deal Structure':
      'Sauvie Inc. (the "Company"), a mission-driven biopharmaceutical company focused on sustainably building an immuno-oncology company, announced Sauvie\'s subsidiary, Sauvie BiKE LLC, has entered into an exclusive license agreement with Rutgers, The State University of New Jersey ("Rutgers") for the development and worldwide commercialization rights of a bi-specific natural killer (NK) cell engager technology in the field of oncology. The exclusive license granted to the Company relates to a camelid nanobody that has demonstrated high affinity and high specificity to NK cells and has the potential to target multiple antigens of solid and hematologic tumors.',
    'FW Link1':
      'Sauvie Inc. Enters into an Exclusive Worldwide License Agreement to Develop and Commercialize a Nanobody-based NK Cell Engager;;https://firstwordpharma.com/story/5667035/'
  },
  {
    'Date Announced': '24-Oct-2022',
    'Deal type': 'Terminated',
    'Acquiring Company/Partner': 'GSK',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Lyell Immunopharma',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'T-cell therapies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'LYL132',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'NY-ESO-1',
    'Deal Structure':
      "GSK is discontinuing its development of product candidates targeting NY-ESO-1, including the second-generation candidates incorporating Lyell Immunopharma's genetic and epigenetic reprogramming technologies LYL132 and LYL331. The termination will take effect on December 24.",
    'FW Link1':
      'GSK bows out of NY-ESO-1 collaboration with Lyell;;https://firstwordpharma.com/story/5667075/'
  },
  {
    'Date Announced': '25-Oct-2022',
    'Deal type': 'Terminated',
    'Acquiring Company/Partner': 'GSK',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Adaptimmune',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'T-cell therapies',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'lete-cel',
    'Indication(s)': 'Sarcoma, solid tumours',
    'Target(s)': 'NY-ESO, PRAME',
    'Deal Structure':
      "Adaptimmune Therapeutics confirmed in statement that GSK is handing back rights to lete-cel, which is due to yield pivotal data in sarcoma in late 2023, and a preclinical asset targeting PRAME. Adaptimmune and GSK announced their collaboration in 2014 for up to five programmes including the first programme, NY-ESO. GSK later exercised its option to exclusively license Adaptimmune's NY-ESO SPEAR T-cell therapy programme, following which two further targets, including PRAME, were nominated as collaboration programme targets.",
    'FW Link1':
      'GSK bows out of NY-ESO-1 collaboration with Lyell;;https://firstwordpharma.com/story/5667075/',
    'FW Link2':
      'Transition agreement announced April 11, 2023;;https://firstwordpharma.com/story/5726435/'
  },
  {
    'Date Announced': '24-Oct-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'ValiRx',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': "King's College London",
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Peptide',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'CLX001',
    'Indication(s)': 'Triple-negative breast cancer',
    'Deal Structure':
      'Under the IP License, ValiRx will provide the necessary funding to Cytolytix for further preclinical development of the technology. The IP License also covers milestone and royalty payments between the parties, should the project be sub-licensed in the future. As the major shareholder, ValiRx will lead the development of CLX001 with oversight provided by the Cytolytix Board, which comprises representative from ValiRx, KCL and the Inventors.',
    'FW Link1':
      'Cytolytix Subsidiary Launched and IP License signed with Kings College London;;https://firstwordpharma.com/story/5666808/'
  },
  {
    'Date Announced': '25-Oct-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Blau Farmaceutica',
    'Country (Acquiring Company/Partner)': 'Brazil',
    'Region (Acquiring Company/Partner)': 'South America',
    'Target Company/Partner': 'JSR Life Sciences',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Biosimilars',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'The agreement, Blau’s most recent move into the biosimilars market, is a multi-product and multi-year deal to develop products and licenses IP for four biosimilar assets targeting indications in oncology, inflammation, and blood disorders. Similis Bio will provide full processes and associated IP for tech transfer to Blau, including cell lines, analytical data and methodologies, and upstream/downstream processes. Blau will utilize the licensed IP for GMP manufacturing and clinical development and seek regulatory approval. Blau has exclusive worldwide rights and intent to sell in America by itself and partner with local organizations in other regions. The agreement is structured with upfront payments and long-term royalties upon commercial approval for sale. Blau and Similis expect the total value of the contract to exceed USD 100 million. The Companies are not disclosing additional financial terms.',
    'FW Link1':
      'Similis Bio Partners With Blau Farmaceutica to Co-Develop Four Biosimilar Programs;;https://firstwordpharma.com/story/5667512/'
  },
  {
    'Date Announced': '25-Oct-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Genmab',
    'Country (Acquiring Company/Partner)': 'Denmark',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Scancell',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibody',
    'Indication(s)': 'Cancer',
    'Target(s)': 'Glycans',
    'Deal Structure':
      'Genmab has been granted the exclusive right to develop and commercialise the Scancell antibody in multiple novel potential therapeutic products for any and all potential disease areas, excluding cell therapy applications. Scancell will be eligible to receive upfront and potential development and commercialisation milestone payments, as well as royalties on products sold.',
    'FW Link1':
      'Scancell signs Commercial License Agreement with Genmab;;https://firstwordpharma.com/story/5667178/'
  },
  {
    'Date Announced': '26-Oct-2022',
    'Deal type': 'Product Acquisition',
    'Acquiring Company/Partner': 'Boryung',
    'Country (Acquiring Company/Partner)': 'Korea, South',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Lilly',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'South Korea',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Chemotherapy',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Alimta',
    'Indication(s)': 'Non-small-cell lung cancer',
    'Deal Structure':
      'South Korean drug maker Boryung has acquired the selling rights of Alimta, a non-small cell lung cancer treatment developed by Eli Lilly, for the local market. The acquisition agreement also included the copyright and licensing rights of Alimta in South Korea.',
    'FW Link1':
      'Boryung acquires Korean rights to Eli Lilly’s lung cancer drug;;https://firstwordpharma.com/story/5667799/'
  },
  {
    'Date Announced': '26-Oct-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Candel Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'University of Pennsylvania',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Viral immunotherapies, CAR-T cell therapies',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      'Candel Therapeutics, Inc., a clinical stage biopharmaceutical company developing viral immunotherapies, announced a collaboration with the University of Pennsylvania (Penn) to study the impact of novel viral immunotherapies based on Candel’s proprietary enLIGHTEN Discovery Platform to strengthen the effects of Penn’s CAR-T cell therapies in solid tumor models. Under the terms of the agreement, the parties will collaborate to use novel engineered viruses that break down barriers to CAR-T therapies and to evaluate the effects of these combinations in tumor models. The two parties will retain full ownership of their existing intellectual property and retain rights to proceed towards clinical trials to study the impact of these combinations in clinical trials.',
    'FW Link1':
      'Candel Therapeutics Partners with the University of Pennsylvania’s Center for Cellular Immunotherapies to Study Combinations of Novel Viral Immunotherapy and CAR-T Cell Therapy in Solid Tumor Models;;https://firstwordpharma.com/story/5667912/'
  },
  {
    'Date Announced': '27-Oct-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'SAB Biotherapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Emergent BioSolutions',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Polyclonal antibodies',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Under the terms of the agreement, Emergent will provide end-to-end Good Manufacturing Practice (cGMP) manufacturing services to SAB, including process development and manufacturing clinical investigational drug product to support SAB’s clinical programs, and commercial manufacturing services upon regulatory approval of SAB’s therapeutics. The agreement also provides the opportunity for Emergent to utilize SAB’s novel DiversitAb platform, the only one in the world that produces fully-human polyclonal antibodies utilizing transchromosomic cows, for future development of undisclosed programs. Financial details of the agreement were not disclosed.',
    'FW Link1':
      'SAB Biotherapeutics Announces Exclusive Manufacturing Partnership with Emergent BioSolutions;;https://firstwordpharma.com/story/5668270/'
  },
  {
    'Date Announced': '27-Oct-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'BMS',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Obsidian Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapies',
    'Indication(s)': 'Cancer',
    'Target(s)': 'CD40L',
    'Deal Structure':
      "This multi-year collaboration extension provides Bristol Myers Squibb with the exclusive option to in-license worldwide rights for cell therapy candidates incorporating Obsidian's cytoDRiVE technology to control the expression of CD40L for the treatment of cancer. Under the terms of the agreement, Obsidian is eligible to receive potential future milestone and royalty payments.",
    'FW Link1':
      'Obsidian Therapeutics Announces Extension of Multi-Year Collaboration Agreement with Bristol Myers Squibb;;https://firstwordpharma.com/story/5668298/'
  },
  {
    'Date Announced': '27-Oct-2022',
    'Deal type': 'Product Acquisition',
    'Acquiring Company/Partner': 'Sagard Healthcare Partners',
    'Country (Acquiring Company/Partner)': 'Canada',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Agios Pharmaceuticals',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Total Deal Value ($m)': '132',
    'Geographic Remit of Deal': 'United States',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Tibsovo',
    'Indication(s)': 'Acute myeloid leukaemia, cholangiocarcinoma',
    'Target(s)': 'IDH1',
    'Deal Structure':
      'Agios has sold its rights to 5% royalties on U.S. net sales of Servier’s TIBSOVO (ivosidenib tablets) to Sagard for a one-time payment of $131.8 million. In 2021, Agios completed the sale of its oncology portfolio – including TIBSOVO – to Servier. Agios retains its rights to a potential future milestone payment of $200 million for vorasidenib, as well as 15% royalties on U.S. net sales of vorasidenib.',
    'FW Link1':
      'Agios and Sagard Healthcare Partners Announce $131.8 Million Purchase Agreement for TIBSOVO Royalty;;https://firstwordpharma.com/story/5668602/'
  },
  {
    'Date Announced': '27-Oct-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'AstraZeneca',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Invitae',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Real-world data',
    'Indication(s)': 'Cholangiocarcinoma',
    'Deal Structure':
      "Invitae announced a partnership with AstraZeneca to use Invitae's Ciitizen natural history data in a retrospective and prospective study of patients diagnosed with cholangiocarcinoma, a rare bile duct cancer. This partnership will enable sharing of high-quality, patient-consented data from the patient community of the Cholangiocarcinoma Foundation (CCF), a leading patient advocacy group whose mission is to find a cure and improve the quality of life for those with cholangiocarcinoma.",
    'FW Link1':
      'Invitae Announces Partnership for Real-World Data Sharing to Inform Rare Cancer Research;;https://www.firstwordhealthtech.com/story/5668516/'
  },
  {
    'Date Announced': '27-Oct-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'JW Therapeutics',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': '2seventy bio',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'MAGE-A4',
    'Deal Structure':
      'Under the terms of the agreement, 2seventy bio will grant JW Therapeutics a license for the MAGE-A4 cell therapy in the Chinese mainland, Hong Kong (China), and Macao (China). JW Therapeutics will be responsible for development, manufacturing, and commercialization within China. 2seventy bio is eligible to receive milestones and royalties on product revenues in China. Additionally, 2seventy bio may leverage the early clinical data generated under the collaboration to support development in other geographies. The detailed arrangements are not disclosed.',
    'FW Link1':
      '2seventy bio and JW Therapeutics Announce Strategic Partnership to Accelerate the Research and Development of T Cell-based Immunotherapies;;https://firstwordpharma.com/story/5668226/'
  },
  {
    'Date Announced': '31-Oct-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'ISU ABXIS',
    'Country (Acquiring Company/Partner)': 'Korea, South',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Biocytogen',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Tri-specific antibodies',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'YH003',
    'Indication(s)': 'Cancer',
    'Target(s)': 'CD40',
    'Deal Structure':
      'Under the terms of the agreement, ISU ABXIS will utilize the sequence of YH003, Biocytogen’s humanized agonistic anti-CD40 antibody currently in phase II clinical trials, to construct a few groups of tri-specific antibodies and develop cancer drugs for multiple indications using its technology platform. Eucure Biopharma will receive an upfront payment, milestone payments and royalties.',
    'FW Link1':
      'Eucure Biopharma, a Subsidiary of Biocytogen, Announces Partnership with ISU ABXIS for the Development of Tri-specific Antibodies using YH003 Antibody Sequence;;https://firstwordpharma.com/story/5669689/'
  },
  {
    'Date Announced': '1-Nov-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Ono Pharmaceutical',
    'Country (Acquiring Company/Partner)': 'Japan',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Memo Therapeutics',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibodies',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Under the terms of the agreement, MTx will leverage its best-in-class microfluidic single-cell molecular cloning and screening technologies to discover antibodies for immuno-oncology targets. Ono will acquire intellectual property rights and worldwide exclusive rights to develop and commercialize therapeutic drug candidates with antibodies resulting from the collaboration. MTx will receive an upfront payment, research funding during the collaboration period, clinical development and sales milestones, as well as royalties on future sales.',
    'FW Link1':
      'Memo Therapeutics AG and Ono Pharmaceutical Enter Antibody Discovery Partnership for Immuno-oncology Targets;;https://firstwordpharma.com/story/5669700/'
  },
  {
    'Date Announced': '1-Nov-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'SpringWorks Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Applied BioMath',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset(s)': 'nirogacestat',
    'Indication(s)': 'Multiple myeloma',
    'Target(s)': 'Gamma secretase, BCMA',
    'Deal Structure':
      "In this collaboration, Applied BioMath is developing a model of SpringWorks' investigational oral selective small molecule gamma secretase inhibitor (GSI), nirogacestat, in combination with an agent that targets B-cell maturation antigen (BCMA) for the treatment of multiple myeloma. ",
    'FW Link1':
      'Applied BioMath, LLC Announces Collaboration with SpringWorks Therapeutics, Inc. for Systems Pharmacology Modeling in Multiple Myeloma;;https://firstwordpharma.com/story/5670088/'
  },
  {
    'Date Announced': '1-Nov-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Exelixis',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Sairopa',
    'Country (Target Company/Partner)': 'Netherlands',
    'Region (Target Company/Partner)': 'Europe',
    'Upfront Value ($m)': '40',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibody',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'ADU-1805',
    'Indication(s)': 'Cancer',
    'Target(s)': 'SIRPα',
    'Deal Structure':
      'Under the terms of the agreement, Exelixis will make an upfront payment to Sairopa of $40 million and an additional $70 million in near-term milestones for an option to obtain an exclusive, worldwide license to develop and commercialize ADU-1805 and other anti-SIRPα antibodies, and for certain expenses to be incurred by Sairopa in conducting prespecified phase 1 clinical studies of ADU-1805 during the option period. Sairopa is eligible to receive additional success-based development milestone payments during the option period. Following the completion of the prespecified clinical studies, Exelixis has the right to exercise its option for an option exercise fee of $225 million. If Exelixis exercises the option, Sairopa is eligible to receive additional payments upon achievement of specified clinical, commercial and net sales milestones, as well as tiered royalties on net sales worldwide.',
    'FW Link1':
      'Exelixis and Sairopa Establish Exclusive Clinical Development Collaboration and Option Agreement to Develop ADU-1805, a Potentially Best-in-Class Monoclonal Antibody Targeting SIRPα;;https://firstwordpharma.com/story/5670166/'
  },
  {
    'Date Announced': '1-Nov-2022',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Atossa Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Dynamic Cell Therapies',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'CAR-T cell therapies',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Atossa will acquire shares equal to 19.99% of the outstanding capital stock of DCT as of today for a cash payment of $2 million (in addition to $3 million previously paid to DCT).',
    'FW Link1':
      'Atossa Therapeutics, Inc. to Acquire Stake in Privately Held Dynamic Cell Therapies as Part of Overall CAR-T Strategy;;https://firstwordpharma.com/story/5670094/'
  },
  {
    'Date Announced': '1-Nov-2022',
    'Deal type': 'Product Acquisition',
    'Acquiring Company/Partner': 'Exelixis',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Cybrexa Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '60',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Peptide-drug conjugate',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'CBX-12, alphalex exatecan',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'Topoisomerase I',
    'Deal Structure':
      'Under the terms of the agreement, Exelixis will pay Cybrexa an upfront fee of $60 million in exchange for the right to acquire CBX-12 pending certain Phase 1 results and to fund certain development and manufacturing expenses incurred by Cybrexa to advance an agreed development plan. Cybrexa may also be eligible to receive up to an additional $642.5 million, including development, regulatory, and commercial milestone payments, as well as a fee for the acquisition of CBX-12 upon evaluation of a pre-specified clinical data package.',
    'FW Link1':
      'Exelixis inks deal for dibs on peptide-drug conjugate CBX-12;;https://firstwordpharma.com/story/5670142/'
  },
  {
    'Date Announced': '2-Nov-2022',
    'Deal type': 'Product Acquisition',
    'Acquiring Company/Partner': 'Twentyeight-Seven Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Basilea',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Upfront Value ($m)': '1',
    'Total Deal Value ($m)': '354',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Cancer',
    'Target(s)': 'CLK kinases',
    'Deal Structure':
      'Basilea Pharmaceutica Ltd. announced that it has entered into an asset purchase agreement with Twentyeight-Seven Therapeutics, Inc. (“28-7”) for novel inhibitors of CLK kinases, developed by Basilea, that target aberrant splicing of RNA in cancer. Under the terms of the agreement, Basilea will receive an upfront payment of CHF 1 million and potential near-term milestone payments of CHF 2 million. Basilea is eligible to receive further payments of up to CHF 351 million upon the achievement of predefined development, regulatory and sales milestones.',
    'FW Link1':
      'Basilea announces sale of preclinical oncology program to Twentyeight-Seven Therapeutics;;https://firstwordpharma.com/story/5670275/'
  },
  {
    'Date Announced': '3-Nov-2022',
    'Deal type': 'Terminated',
    'Acquiring Company/Partner': 'Guerbet',
    'Country (Acquiring Company/Partner)': 'France',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Merative',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'Indication(s)': 'Liver cancer, prostate cancer',
    'Deal Structure':
      "Guerbet announced an agreement with Merative formalizing the termination of their collaboration initiated in July 2018. This partnership aimed to design, develop and market software solutions to help diagnose and monitor liver and prostate cancers. The termination follows Merative's strategic shift in how it delivers artificial intelligence within its imaging product portfolio.The new Asset Assignment and License Agreement grants Guerbet the ability and autonomy to pursue research and development, and plan for the market launch of Artificial Intelligence solutions. Assets developed during the partnership, including source code for application software, Artificial Intelligence algorithms and the associated intellectual property, are being transferred to Guerbet.",
    'FW Link1':
      'Guerbet: Guerbet announces a change in governance for its Artificial Intelligence projects;;https://www.firstwordhealthtech.com/story/5671221/'
  },
  {
    'Date Announced': '3-Nov-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'IO Biotech',
    'Country (Acquiring Company/Partner)': 'Denmark',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Merck & Co.',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immunotherapy',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'IO102-IO103, Keytruda',
    'Indication(s)': 'Resectable tumours',
    'Target(s)': 'IDO, PD-L1, PD-1',
    'Deal Structure':
      'Under the terms of the agreement, IO Biotech will sponsor the Phase 2 trial and Merck will supply pembrolizumab. IO Biotech maintains global commercial rights to IO102-IO103.',
    'FW Link1':
      'IO Biotech Announces New Clinical Collaboration with Merck to Evaluate Neoadjuvant and Adjuvant IO102-IO103 in Combination With KEYTRUDA (pembrolizumab) in a Phase 2, Multi-cohort Trial in Patients with Resectable Tumors;;https://firstwordpharma.com/story/5670960/'
  },
  {
    'Date Announced': '3-Nov-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Affimed',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Artiva Biotherapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'NK cell therapy',
    'Lead Asset(s)': 'AFM13, AB-101',
    'Indication(s)': 'CD30-positive lymphomas',
    'Target(s)': 'CD30, CD16A',
    'Deal Structure':
      'Under the terms of the agreement, Affimed and Artiva will pursue the development of the AFM13/AB-101 combination treatment in the United States on a co-exclusive basis. Affimed will lead regulatory activities through Phase 2 and any confirmatory studies. Affimed will be responsible for funding clinical study costs through Phase 2, while Artiva will be responsible for the costs of supplying AB-101 and IL-2 for such studies. If accelerated approval is obtained, the companies will share confirmatory study costs on a 50/50 basis. Both companies will retain commercialization and distribution rights and book sales for their respective products. Affimed will be responsible for promotional activities and expenses of the combination therapy. Pursuant to the agreement, revenues from the combination will be shared, with Affimed receiving 67% of the combination therapy revenue and Artiva receiving 33%.',
    'FW Link1':
      'Affimed and Artiva Biotherapeutics Announce Partnership to Advance Combination Therapy of Innate Cell Engager (ICE) AFM13 and Off-the-Shelf Allogeneic NK Cell Therapy AB-101;;https://firstwordpharma.com/story/5671146/'
  },
  {
    'Date Announced': '3-Nov-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Exelixis',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Catalent',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '30',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      'Under the terms of the new agreement, Exelixis will make an upfront payment to Catalent of $30 million in exchange for rights to the three biologics programs. Exelixis will fund the development work conducted by Catalent until development candidate selection is complete, after which Exelixis will assume responsibility for conducting preclinical, clinical, and commercial activities. Catalent will be eligible for development and sales milestone payments, as well as sales-based royalties.',
    'FW Link1':
      'Exelixis and Catalent Enter into New License Agreement for Three Antibody-Drug Conjugate Programs with the Potential to Accelerate Exelixis’ Biologics Pipeline;;https://firstwordpharma.com/story/5670966/'
  },
  {
    'Date Announced': '7-Nov-2022',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Fulgent Genetics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Fulgent Pharma',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Total Deal Value ($m)': '100',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecule',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'FID-007',
    'Indication(s)': 'Head and neck cancer',
    'Deal Structure':
      'Under the terms of the agreement, Fulgent Genetics acquired Fulgent Pharma for a total purchase price of approximately $100 million, subject to adjustments, to be paid with a combination of cash on hand and shares of common stock of Fulgent Genetics.',
    'FW Link1':
      'Fulgent Genetics Acquires Fulgent Pharma, Creating a New Paradigm in Precision Medicine for The Company;;https://firstwordpharma.com/story/5672545/'
  },
  {
    'Date Announced': '7-Nov-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Scancell',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Vaccitech',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Peptide vaccines',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'Modi-2',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Scancell Holdings plc has in-licensed the SNAPvax technology from Vaccitech plc, a clinical-stage biopharmaceutical company engaged in the discovery and development of novel immunotherapies and vaccines. This agreement will allow Scancell to formulate and manufacture Modi-2, with the aim of initiating a Phase 1 clinical study in cancer patients in 2024.',
    'FW Link1':
      'Scancell in-licenses Vaccitech technology to advance Modi-2 towards the clinic;;https://firstwordpharma.com/story/5672271/'
  },
  {
    'Date Announced': '7-Nov-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Incyte',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Mirati Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'INCB99280, adagrasib',
    'Indication(s)': 'KRASG12C-mutated solid tumours',
    'Target(s)': 'PD-L1, KRASG12C',
    'Deal Structure':
      'Under the terms of the agreement, Incyte will initiate and sponsor the Phase 1/1b study of INCB99280 and adagrasib in patients with KRASG12C-mutated solid tumors. Mirati will supply Incyte with adagrasib for the study.',
    'FW Link1':
      'Incyte And Mirati Therapeutics Enter Into Clinical Trial Collaboration and Supply Agreement to Evaluate INCB99280 and Adagrasib in Patients with KRASG12C-Mutated Solid Tumors;;https://firstwordpharma.com/story/5672070/'
  },
  {
    'Date Announced': '7-Nov-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Tessa Therapeutics',
    'Country (Acquiring Company/Partner)': 'Singapore',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Baylor College of Medicine',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Allogeneic CAR-T cell therapies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'TT11X',
    'Indication(s)': 'CD30-positive lymphomas',
    'Target(s)': 'CD30',
    'Deal Structure':
      'Tessa Therapeutics Ltd. (Tessa), a clinical-stage cell therapy company developing next-generation cancer treatments for hematological malignancies and solid tumors, announced the execution of an exclusive agreement with Baylor College of Medicine for worldwide commercial rights to the allogeneic Epstein-Barr virus specific T-cell (EBVST) technology platform developed jointly by Tessa’s Scientific Co-Founder, Malcolm Brenner, M.D., Ph.D., and his colleagues Cliona Rooney Ph.D. and Helen Heslop M.D., D.Sc. at Baylor College of Medicine. Tessa is currently advancing a pipeline of products that utilize CD30.CAR-modified EBVSTs, including its lead allogeneic cell therapy, TT11X, which is being co-developed for the treatment of relapsed or refractory CD30-positive lymphomas.',
    'FW Link1':
      'Tessa Therapeutics, Baylor College of Medicine Execute Agreement For Global Commercial Rights to ‘Off-the-Shelf’ CAR-T Platform;;https://firstwordpharma.com/story/5672217/'
  },
  {
    'Date Announced': '8-Nov-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Portage Biotech',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Merck & Co.',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Invariant natural killer T-cell agonist',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'PORT-2, Keytruda',
    'Indication(s)': 'Non-small-cell lung cancer',
    'Target(s)': 'PD-1',
    'Deal Structure':
      'The collaboration will evaluate Portage’s lead invariant natural killer T cell (iNKT) agonist, PORT-2, in combination with KEYTRUDA® (pembrolizumab), Merck’s anti-PD-1 (programmed death receptor-1) therapy, for patients with front-line as well as PD-1 refractory non-small cell lung cancer (NSCLC). Under the terms of the agreement, Merck will be providing KEYTRUDA for Portage Biotech’s IMPORT-201 trial, a Phase 1/2 study of PORT-2 for patients with NSCLC and advanced melanoma (also known as KEYNOTE E69). The two companies will establish a Joint Development Committee to optimally evaluate the study’s combination arms.',
    'FW Link1':
      'Portage Biotech Announces Clinical Trial Collaboration Agreement with Merck;;https://firstwordpharma.com/story/5672940/'
  },
  {
    'Date Announced': '8-Nov-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'FairWinds Bio',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Mayo Clinic',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cancer vaccines',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      'MGFB, a subsidiary of FairWinds Bio (FWB), has entered into a patent license agreement with Mayo Clinic to advance experimental cancer vaccine therapeutics based on a novel platform developed over the past 20 years by Richard Vile, Ph.D., Mayo Clinic Professor of Immunology as well as several colleagues. Founding equity ownership in MGFB includes FWB, University of Leeds and Mayo Clinic.',
    'FW Link1':
      'MGFB announces agreement with Mayo Clinic for development of novel therapeutic cancer vaccines;;https://firstwordpharma.com/story/5672792/'
  },
  {
    'Date Announced': '8-Nov-2022',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'CNBX Pharmaceuticals',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'TaGeza',
    'Country (Target Company/Partner)': 'Israel',
    'Region (Target Company/Partner)': 'Middle East',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'CNBX Pharmaceuticals Inc. announced that it has acquired a controlling interest in TaGeza Biopharmaceuticals Ltd.',
    'FW Link1':
      'CNBX to Enter the Cancer Immunotherapy Market After Acquiring a Controlling Interest in TaGeza Biopharma;;https://firstwordpharma.com/story/5673243/'
  },
  {
    'Date Announced': '8-Nov-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'AstraZeneca',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Alfaisal University',
    'Country (Target Company/Partner)': 'Saudi Arabia',
    'Region (Target Company/Partner)': 'Middle East',
    'Geographic Remit of Deal': 'Saudi Arabia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'Indication(s)': 'Lung cancer',
    'Deal Structure':
      'AstraZeneca and Alfaisal University signed a memorandum of understanding that sets out a new national programme to reduce lung cancer-related mortality in Saudi Arabia. Through the initiative, AstraZeneca and Alfaisal University will focus on three key objectives: optimising lung cancer diagnosis, maximising identification of patients with early stage non-small cell lung cancer, and educating the public about the symptoms and risks of lung cancer.',
    'FW Link1':
      'AstraZeneca, Alfaisal University team up to reduce lung cancer mortality;;https://firstwordpharma.com/story/5673548/'
  },
  {
    'Date Announced': '10-Nov-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Azra AI',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Massive Bio',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial intelligence',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Massive Bio, a leader in AI-powered cancer clinical trial enrollment, announced a strategic partnership with Azra AI, a healthcare technology company that uses proprietary AI software to identify cancer diagnoses in real time and accelerate the patient care process. Combined with Massive Bio’s ability to surface personalized clinical trial options for patients, the two companies working together provide early identification and precise treatment options, further improving cancer clinical care.',
    'FW Link1':
      'Massive Bio and Azra AI to Expand AI’s Potentially Lifesaving Impact on Cancer Patients;;https://www.firstwordhealthtech.com/story/5674209/'
  },
  {
    'Date Announced': '10-Nov-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Parthenon Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'ImaginAb',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Imaging agent',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'CD8 ImmunoPET, PRTH-101',
    'Indication(s)': 'Cancer',
    'Target(s)': 'CD8, DDR1',
    'Deal Structure':
      'The companies have entered into a multi-year, non-exclusive license and supply agreement. Under the agreement terms, Parthenon will use ImaginAb’s CD8 ImmunoPET imaging technology in its Phase 1 trial evaluating its lead compound, PRTH-101, which is expected to begin in 2023. Under the terms of the agreement, ImaginAb will supply clinical doses of its investigational CD8 ImmunoPET technology to Parthenon Therapeutics for use in the clinical development of PRTH-101 at clinical trial sites across the United States. Initially, Parthenon Therapeutics will investigate CD8 status in a Phase I dose escalation clinical trial of PRTH-101, both in monotherapy and in combination with anti-PD(L)1 therapy. The trial is expected to initiate in 2023, with the ability to add dose expansion cohorts at a later date to explore additional rational combination opportunities and tumor types. ImaginAb will receive payments for providing dose manufacturing and ongoing technical, clinical and regulatory support to enable the successful implementation of its CD8 ImmunoPET technology into Parthenon Therapeutics’ clinical trials.',
    'FW Link1':
      'Parthenon Therapeutics and ImaginAb Announce License and Supply Agreement to Advance New Class of Anti-Cancer Therapies’ Clinical Development Program;;https://firstwordpharma.com/story/5674143/'
  },
  {
    'Date Announced': '10-Nov-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Moderna',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Harbour BioMed',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Nucleic acid-based immunotherapies',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Harbour BioMed (the "Company") announced that Nona Biosciences, a subsidiary wholly owned by the Company, had entered into a license and collaboration agreement with ModernaTX, Inc. The strategic collaboration will focus on discovering and developing nucleic acid-based immunotherapies for select oncology targets using the Company\'s proprietary heavy chain only antibody discovery platform (HCAb). Upon the execution of the agreement and subject to terms and conditions thereof, Moderna will be granted an exclusive sub-licensable license to exploit a panel of sequences against multiple targets, derived from the Company\'s HCAb platform, to develop products using nucleic acids. Moderna will assume full responsibility for all upcoming development, manufacturing, regulatory, and commercialization activities. Moderna would also be granted an option to obtain an exclusive sub-licensable license to exploit sequences against additional targets. Pursuant to the agreement, Nona Biosciences shall receive an upfront payment, and potential milestone payments based on pending achievement of certain regulatory, development, and sales milestones, and tiered royalties.',
    'FW Link1':
      'Harbour BioMed Enters into a License and Collaboration Agreement with Moderna;;https://firstwordpharma.com/story/5674754/'
  },
  {
    'Date Announced': '14-Nov-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Apollo Therapeutics',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Institute of Cancer Research',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cancer medicines',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Apollo Therapeutics (‘Apollo’), a portfolio biopharmaceutical company focusing on translational biology and drug development, and The Institute of Cancer Research, London, a world-leading cancer research organisation, announce they have entered into a strategic collaboration to discover and develop new cancer medicines. The collaboration combines the strengths and resources of both organisations to bring forward the development of novel therapies for cancer patients worldwide.',
    'FW Link1':
      'Apollo Therapeutics and The Institute of Cancer Research enter into collaboration for cancer drug development;;https://firstwordpharma.com/story/5675012/'
  },
  {
    'Date Announced': '14-Nov-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Exscientia',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'University of Texas MD Anderson Cancer Center',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial intelligence, small molecules',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Exscientia plc and The University of Texas MD Anderson Cancer Center announced a strategic collaboration to align the patient-centric artificial intelligence (AI) capabilities of Exscientia with the drug discovery and development expertise of MD Anderson in order to advance novel small-molecule oncology therapies. Under the agreement terms, Exscientia and MD Anderson will jointly contribute to and support each program designated to move forward.',
    'FW Link1':
      'Exscientia and MD Anderson Launch Strategic Collaboration to Leverage AI in Developing Novel Oncology Treatments;;https://firstwordpharma.com/story/5675135/'
  },
  {
    'Date Announced': '14-Nov-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'COTA',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Google',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Data analytics',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'COTA, Inc. , an oncology real-world data and analytics company, announced a new partnership with Google Cloud to develop algorithms that will extract and make sense of unstructured data from electronic health records (EHRs). The companies will apply machine learning and natural language processing to curate text fields like clinician notes, transforming them into structured fields that can be used for research and analytics.',
    'FW Link1':
      'COTA Partners with Google Cloud to Transform Cancer Care;;https://www.firstwordhealthtech.com/story/5675227/'
  },
  {
    'Date Announced': '15-Nov-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Roche',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Jnana',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '50',
    'Total Deal Value ($m)': '2,050',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Under the terms of the agreement, Jnana will receive an upfront payment of $50 million, significant near-term milestone payments, and additional potential future payments that could exceed $2 billion, as well as tiered royalties. Jnana will conduct discovery and preclinical activities against multiple cancer, immune-mediated and neurological disease targets, and Roche will be responsible for development and commercialization of any resulting products.',
    'FW Link1':
      'Roche, Jnana form second partnership to develop drugs against challenging targets;;https://firstwordpharma.com/story/5675662/'
  },
  {
    'Date Announced': '15-Nov-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Affini-T Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'ElevateBio',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'T-cell therapies',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'KRAS',
    'Deal Structure':
      "Affini-T will leverage ElevateBio BaseCamp's LentiPeak lentiviral vector technology platform and cell product manufacturing capabilities to progress its investigational T cell therapies targeting core oncogenic drivers into clinical development",
    'FW Link1':
      "ElevateBio and Affini-T Therapeutics Announce Partnership to Advance Affini-T's T Cell Therapy Programs Targeting Core Oncogenic Drivers;;https://firstwordpharma.com/story/5675872/"
  },
  {
    'Date Announced': '14-Nov-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Hansoh Pharmaceuticals',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Biotheus',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '7',
    'Total Deal Value ($m)': '205',
    'Geographic Remit of Deal': 'Greater China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Bispecific antibody',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'PM1080',
    'Indication(s)': 'Cancer',
    'Target(s)': 'EGFR/MET',
    'Deal Structure':
      'Under the terms of the agreement, Hansoh will be granted by Biotheus the exclusive rights to develop, commercialize and manufacture PM1080 for the treatment of cancer, and shall assume all the costs accordingly in the territory. In return, Biotheus will receive 50 million CNY and is entitled to receive up to ~1.4 billion CNY for future development, regulatory and commercialization milestones, plus tiered royalty payments based on net sales.',
    'FW Link1':
      'Biotheus has entered into a license and collaboration agreement with Hansoh Pharma for its EGFR/MET bispecific antibody in the Greater China territory;;https://firstwordpharma.com/story/5675650/'
  },
  {
    'Date Announced': '15-Nov-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'DynamiCure',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'ImaginAb',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Imaging agent',
    'Lead Asset(s)': 'CD8 ImmunoPET',
    'Indication(s)': 'Cancer',
    'Target(s)': 'CD8',
    'Deal Structure':
      'Under the agreement, ImaginAb will license and supply clinical doses of its investigational CD8 ImmunoPET to DynamiCure Biotechnology for use in its clinical trial.',
    'FW Link1':
      'ImaginAb Executes License and Supply Agreement for CD8 ImmunoPET Technology with DynamiCure Biotechnology;;https://firstwordpharma.com/story/5675656/'
  },
  {
    'Date Announced': '16-Nov-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Sunshine Biopharma',
    'Country (Acquiring Company/Partner)': 'Canada',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Undisclosed',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Anticancer mRNA',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'K1.1',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Sunshine Biopharma Inc., a pharmaceutical company offering and researching life-saving medicines in a variety of therapeutic areas, including oncology and antivirals announced that it has entered into a collaboration agreement with one of North America’s leading lipid nanoparticle (“LNP”) companies. The purpose of the collaboration is to advance the development of Sunshine Biopharma’s mRNA-based anticancer macromolecule, K1.1. The Company’s collaboration partner will prepare two LNP formulations (K1.1a and K1.1b) of Sunshine Biopharma’s in-house developed antineoplastic mRNA, K1.1.',
    'FW Link1':
      'Sunshine Biopharma Enters Into a Collaboration Agreement with a Leading Lipid Nanoparticle Formulation Company for Its K1.1 Anticancer mRNA Project;;https://firstwordpharma.com/story/5676410/'
  },
  {
    'Date Announced': '16-Nov-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Zydus Lifesciences',
    'Country (Acquiring Company/Partner)': 'India',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'OncoStem',
    'Country (Target Company/Partner)': 'India',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Prognostic test',
    'Lead Asset(s)': 'CanAssist Breast',
    'Indication(s)': 'Breast cancer',
    'Deal Structure':
      'Zydus Lifesciences Ltd., a global innovation driven healthcare company announced that it will be exclusively marketing CanAssist Breast, an innovative and a highly advanced prognostic test for breast cancer patients in the early stages to help clinicians decide whether the patient needs chemotherapy or not.',
    'FW Link1':
      'Zydus Lifesciences gets exclusive marketing rights for CanAssist Breast- an innovative prognostic test for breast cancer patients;;https://www.firstwordhealthtech.com/story/5676275/'
  },
  {
    'Date Announced': '17-Nov-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Regeneron',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'CytomX Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '30',
    'Total Deal Value ($m)': '2,030',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Bispecific antibodies',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Under the agreement, Regeneron and CytomX will collaborate on the discovery activities to identify and validate conditionally active bispecific antibodies. Regeneron will be responsible for funding preclinical and clinical development and commercialization activities. CytomX will receive an upfront payment of $30 million and will be eligible to receive future target nomination payments and preclinical, clinical, and commercial milestones of up to $2 billion. CytomX is also eligible to receive tiered global net sales royalties.',
    'FW Link1':
      'Regeneron, CytomX join forces to develop conditionally-activated bispecific cancer drugs;;https://firstwordpharma.com/story/5676725/'
  },
  {
    'Date Announced': '17-Nov-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Calyx',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Neosoma',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial intelligence',
    'Lead Asset(s)': 'Neosoma HGG',
    'Indication(s)': 'Glioblastoma',
    'Deal Structure':
      "Through this partnership, Calyx Medical Imaging extends Neosoma HGG (High-Grade Glioma), a novel AI-based neuro-oncology software device to its worldwide customers who are developing treatments for glioblastoma, the most common and challenging, malignant primary brain cancer. This partnership will extend to other AI-based solutions being developed by Neosoma and reflects Calyx's strategy of partnering with best-in-class technology providers to offer innovative imaging biomarkers required to find new treatments for unmet medical needs.",
    'FW Link1':
      'Calyx Medical Imaging Advances Neuro-oncology Treatment Development through AI;;https://www.firstwordhealthtech.com/story/5677138/'
  },
  {
    'Date Announced': '17-Nov-2022',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'Sirona Medical',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'RevealDX',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial intelligence',
    'Lead Asset(s)': 'RevealAI-Lung',
    'Indication(s)': 'Lung cancer',
    'Deal Structure':
      "Sirona Medical, the leader in cloud-native radiology workflow software, announced a comprehensive distribution and collaboration partnership with RevealDX and the integration of Nines' FDA-cleared automated measurement tool recently acquired by Sirona to improve end to end care for patients with lung nodules. The partnership, first noted in 2021, will enable RevealDx to integrate their CE-marked RevealAI-Lung algorithm into Sirona's unified radiology platform, making it easier for radiologists to implement the AI model into practice.",
    'FW Link1':
      'Sirona Medical partners with RevealDX to transform lung cancer screening with a comprehensive suite of reimbursed AI tools.;;https://www.firstwordhealthtech.com/story/5676796/'
  },
  {
    'Date Announced': '17-Nov-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Alloy Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Normunity',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibodies',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Alloy Therapeutics, a biotechnology ecosystem company, announced an antibody discovery collaboration spanning multiple precision immuno-oncology programs for Normunity, a biotech company that recently announced its $65 million Series A financing. The collaboration will support the advancement of Normunity’s immune normalizers, antibody therapies designed to target novel mechanisms that free the body’s normal immunity to fight cancer, which are conceived through a unique biotech-academic collaboration with a renowned immuno-oncology university research lab.',
    'FW Link1':
      'Alloy Therapeutics and Normunity Sign Multi-Program Antibody Discovery Collaboration in Immuno-Oncology;;https://firstwordpharma.com/story/5676798/'
  },
  {
    'Date Announced': '18-Nov-2022',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'NeoGenomics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'ImmunoGen',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'United States',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Companion diagnostic, antibody-drug conjugate',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'VENTANA FOLR1 RxDx Assay, Elahere',
    'Indication(s)': 'Ovarian cancer',
    'Target(s)': 'Folate receptor alpha',
    'Deal Structure':
      "Through the FR-ASSIST program, sponsored by ImmunoGen, Inc., (Nasdaq:IMGN), EOC patients will have access to the FOLR1 IHC CDx test (VENTANA FOLR1 RxDx Assay) performed by NeoGenomics to measure the expression of a protein called folate receptor alpha (FRα). The FR-ASSIST program was launched in conjunction with the recent U.S. Food and Drug Administration's approval of ELAHERE (mirvetuximab soravtansine-gynx), ImmunoGen's antibody-drug conjugate (ADC) targeting FRα in patients with platinum-resistant EOC. The new testing program is sponsored entirely by ImmunoGen and provides eligible EOC patients with FRα-expression testing through NeoGenomics at no cost, regardless of insurance coverage or test results.",
    'FW Link1':
      'NeoGenomics and ImmunoGen Launch New Program Giving Ovarian Cancer Patients Access to Novel FR Biomarker Testing to Support the Launch of ELAHERE;;https://firstwordpharma.com/story/5677600/'
  },
  {
    'Date Announced': '17-Nov-2022',
    'Deal type': 'Terminated',
    'Acquiring Company/Partner': 'GSK',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Immatics',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Upfront Value ($m)': '50',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'T-cell receptor therapeutics',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'GSK will terminate its collaboration with Immatics later this year, the third agreement that the UK drugmaker is pulling out of in the area of cell therapies for cancer. In 2020, GSK paid $50 million upfront to kickstart a partnership with Immatics on the development of next-generation T-cell receptor (TCR) therapeutics targeting multiple cancer indications, including two initial programmes. Immatics said it was notified by GSK of its decision to terminate the deal on October 24, with the agreement set to end on December 26.',
    'FW Link1':
      "Immatics becomes latest victim of GSK's cell therapy retreat;;https://firstwordpharma.com/story/5677594/"
  },
  {
    'Date Announced': '10-Nov-2022',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Nordic Nanovector',
    'Country (Acquiring Company/Partner)': 'Norway',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'APIM Therapeutics',
    'Country (Target Company/Partner)': 'Norway',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Anticancer peptide',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'ATX-101',
    'Indication(s)': 'Solid tumours, ovarian cancer, sarcoma',
    'Target(s)': 'PCNA',
    'Deal Structure':
      'Nordic Nanovector ASA (OSE: NANOV) ("Nordic Nanovector" or the "Company") and all shareholders of APIM Therapeutics ("APIM"), a privately held, clinical-stage cancer-focused biotechnology company, announce that they have entered into a definitive merger agreement to combine the companies in an all-stock transaction (the "Agreement"). Subject to completion of the Agreement, Nordic Nanovector will purchase shares in APIM, and become the parent entity of APIM (the "Transaction").',
    'FW Link1':
      'Nordic Nanovector and APIM Therapeutics Announce Merger Agreement;;https://firstwordpharma.com/story/5674111'
  },
  {
    'Date Announced': '21-Nov-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Umoja Biopharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'IASO Biotherapeutics',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapies',
    'Indication(s)': 'Haematological malignancies, acute myeloid leukaemia',
    'Deal Structure':
      'Umoja Biopharma, Inc., an immuno-oncology company pioneering off-the-shelf, integrated therapeutics that reprogram immune cells to treat patients with solid and hematologic malignancies and IASO Biotherapeutics (“IASO Bio”), a clinical-stage biopharmaceutical company engaged in discovering, developing, and manufacturing innovative medicines, announced that they have entered into a research agreement to evaluate Umoja’s iCIL platform with IASO’s best-in-class CARs. The collaboration will focus on bringing off-the-shelf therapies to patients with hematological malignancies, initially acute myeloid leukemia (AML), with a goal of increasing patient accessibility.',
    'FW Link1':
      'Umoja Biopharma and IASO Biotherapeutics Announce Research Collaboration to Bring Off-the-Shelf Therapies to Patients with Hematological Malignancies;;https://firstwordpharma.com/story/5678189/'
  },
  {
    'Date Announced': '22-Nov-2022',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Biocare Medical',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Empire Genomics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Biocare Medical, a leading provider of innovative, automated immunohistochemistry (IHC) and FISH (Fluorescent in situ hybridization) instrumentation and reagents, announces the acquisition of Empire Genomics, a market leader in molecular biomarkers to aid in cancer research and diagnostics.',
    'FW Link1':
      'Biocare Medical Acquires Empire Genomics, Expanding Molecular Portfolio to Over 1 Million Biomarkers and Adding Rapid Biomarker Development to Lead Cancer Research;;https://www.firstwordhealthtech.com/story/5678678/'
  },
  {
    'Date Announced': '23-Nov-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Pan Cancer T',
    'Country (Acquiring Company/Partner)': 'Netherlands',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'NecstGen',
    'Country (Target Company/Partner)': 'Netherlands',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapies, TCR-T therapies',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      'Pan Cancer T B.V., a biotech spin-off from Erasmus MC dedicated to the discovery and development of novel second generation TCR-T therapies against solid tumors, and NecstGen B.V., The Netherlands Center for the Clinical Advancement of Stem Cells and Gene Therapy, announced a partnership to advance Pan Cancer T’s innovative therapies into the clinic. Under the agreement, NecstGen will provide process and analytical development capabilities.',
    'FW Link1':
      'Pan Cancer T and NecstGen Collaborate to Accelerate Novel TCR-T Therapies into Clinical Development;;https://firstwordpharma.com/story/5679262/'
  },
  {
    'Date Announced': '22-Nov-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Aurobindo',
    'Country (Acquiring Company/Partner)': 'India',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Yifan Pharmaceutical',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'United States',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Recombinant fusion protein',
    'Lead Asset(s)': 'Ryzneuta',
    'Indication(s)': 'Chemotherapy-induced neutropenia',
    'Deal Structure':
      'Evive will be responsible for the ongoing development, manufacturing, registration, and supply of Ryzneuta, while Acrotech will use its sales and commercialization capabilities to market and distribute Ryzneuta in the US. In addition, both companies may conduct additional development of the Product to explore further opportunities for Ryzneuta.',
    'FW Link1':
      'Evive Enters License Agreement with Acrotech Biopharma to Commercialize Ryzneuta in the United States;;https://firstwordpharma.com/story/5679198/'
  },
  {
    'Date Announced': '23-Nov-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Cizzle Biotechnology',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner':
      'International Co-Innovation Centre for Advanced Medical Technology',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'Indication(s)': 'Lung cancer',
    'Target(s)': 'CIZ1B',
    'Deal Structure':
      'The new MOU reflects progress by iCCAMT to implement a clinical pilot study to evaluate optimised assays using a monoclonal antibody that Cizzle Biotechnology has developed that specifically detects CIZ1B, which is highly associated with stage 1 lung cancer. The initial pilot clinical studies will be conducted at the Cancer Hospital Chinese Academy of Medical Sciences, National Cancer Center of China. These results will have the potential to provide a platform for developing full commercial tests that can be made available throughout China.',
    'FW Link1':
      'Cizzle Biotechnology: Clinical Pilot Study for Early Lung Cancer Detection in China;;https://firstwordpharma.com/story/5679167/'
  },
  {
    'Date Announced': '24-Nov-2022',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'Grünenthal',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Kyowa Kirin',
    'Country (Target Company/Partner)': 'Japan',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '83',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Abstral, PecFent, Moventig, Adcal-D3',
    'Indication(s)': 'Cancer pain',
    'Deal Structure':
      "Kyowa Kirin Co., Ltd., a Japan-based global specialty pharmaceutical company, and Grünenthal GmbH, a global, science-based, privately-owned pharmaceutical company, have signed a Joint Venture Collaboration for Kyowa Kirin International's established medicines portfolio. The Joint Venture Collaboration comprises 13 brands across 6 therapeutic areas primarily focused on pain management, including Abstral and PecFent for breakthrough cancer pain, Moventig for opioid-induced constipation, and Adcal-D3 for osteoporosis. Grünenthal has agreed to pay approximately €80 million upfront plus royalties over the term of the collaboration. Grünenthal will make an additional payment upon purchase of the remaining share and the intellectual property (IP) of the portfolio. Subject to approval, Grünenthal will own a 51 percent majority share in the new company. Kyowa Kirin will own a 49 percent share and will initially retain the IP related to the portfolio. Grünenthal intends to purchase the remaining 49 percent share and the IP at the beginning of 2026.",
    'FW Link1':
      'Grünenthal and Kyowa Kirin International announce Joint Venture Collaboration for established medicines portfolio;;https://firstwordpharma.com/story/5679568/'
  },
  {
    'Date Announced': '24-Nov-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'XtalPi',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'CK Life Sciences',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial intelligence, tumour vaccines',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'XtalPi announced that they are embarking on a strategic collaboration with CK Life Sciences, a member of the CK Hutchison Group. XtalPi and CK Life Sciences will leverage their respective expertise to jointly develop a novel AI tumour vaccine R&D platform to improve the discovery and design capabilities of tumour vaccines and accelerate the development of more vaccine types. The goal of this collaboration is to realize precision treatment for patients worldwide.',
    'FW Link1':
      'XtalPi and CK Life Sciences to collaborate in AI-empowered tumour vaccine research and development, opening a new paradigm for scientific innovation;;https://firstwordpharma.com/story/5679957/'
  },
  {
    'Date Announced': '25-Nov-2022',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'Microsoft',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Kheiron Medical Technologies',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial intelligence, diagnostics',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Mia',
    'Indication(s)': 'Breast cancer',
    'Deal Structure':
      'Kheiron Medical Technologies announced a new technology collaboration with Microsoft that is already supporting the detection of breast cancers alongside radiologists. Kheiron, which has pioneered the development and deployment of an AI solution called Mia to help radiologists find breast cancer earlier, is leveraging the power and scale of Microsoft’s Azure Cloud to enable the global reach and impact of its technology.',
    'FW Link1':
      'Kheiron Medical Technologies and Microsoft Join Forces To Support Breast Cancer Detection;;https://www.firstwordhealthtech.com/story/5680018/'
  },
  {
    'Date Announced': '28-Nov-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Farma Mondo',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Helsinn',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Estonia, Latvia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Akynzeo',
    'Indication(s)': 'Chemotherapy-induced nausea and vomiting',
    'Deal Structure':
      'Under the terms of the agreement, Farma Mondo will manage the commercialization of AKYNZEO in Estonia and Latvia.',
    'FW Link1':
      'Farma Mondo Group and Helsinn sign exclusive partnership to commercialize AKYNZEO in the Baltic region;;https://firstwordpharma.com/story/5680364/'
  },
  {
    'Date Announced': '28-Nov-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'iCAD',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Google',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial intelligence, imaging',
    'Indication(s)': 'Breast cancer',
    'Deal Structure':
      'Under the definitive agreement, Google has licensed its AI technology for breast cancer and personalized risk assessment to iCAD. iCAD will apply the licensed technology to further improve its 3D and 2D AI algorithms and will commercialize developed products. In addition to licensing Google Health’s mammography AI technology, iCAD will also leverage Google Cloud’s secure, scalable infrastructure to begin transforming its delivery model from an on-premise suite of solutions to cloud-hosted solutions.',
    'FW Link1':
      'Google teams up with iCAD to improve breast cancer screening with AI;;https://www.firstwordhealthtech.com/story/5680500/'
  },
  {
    'Date Announced': '29-Nov-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Immutep',
    'Country (Acquiring Company/Partner)': 'Australia',
    'Region (Acquiring Company/Partner)': 'Oceania',
    'Target Company/Partner': 'Merck KGaA',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immunotherapy',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'eftilagimod alpha, Bavencio',
    'Indication(s)': 'Urothelial cancer',
    'Target(s)': 'LAG-3, PD-L1',
    'Deal Structure':
      'Under the Agreement, Immutep and Merck KGaA, Darmstadt, Germany will jointly fund the INSIGHT-005 study. It will be conducted by the Institute of Clinical Cancer Research, Krankenhaus Nordwest (IKF) as part of the investigator-initiated INSIGHT platform for studies investigating efti in different combination treatments and routes of administration. INSIGHT consists of 5 different arms from stratums A to E (INSIGHT-005 is Stratum E).',
    'FW Link1':
      'Immutep Enters into Second Clinical Trial Collaboration Agreement with Merck KGaA, Darmstadt, Germany, and Pfizer for New Combination Study of its First-in-Class LAG-3 Candidate, Eftilagimod Alpha, and Avelumab to Treat Urothelial Cancer;;https://firstwordpharma.com/story/5680877/'
  },
  {
    'Date Announced': '29-Nov-2022',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'AstraZeneca',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Neogene Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '200',
    'Total Deal Value ($m)': '320',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'T-cell receptor therapies',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      'AstraZeneca will acquire all outstanding equity of Neogene for a total consideration of up to $320m, on a cash and debt free basis. This will include an initial payment of $200m on deal closing, and a further up to $120m in both contingent milestones-based and non-contingent consideration. Neogene will operate as a wholly owned subsidiary of AstraZeneca, with operations in Amsterdam, the Netherlands and California, US.',
    'FW Link1':
      'AstraZeneca expands cancer cell therapy pipeline with Neogene deal;;https://firstwordpharma.com/story/5680710/',
    'FW Link2':
      'Completion of acquisition announced January 16, 2023;;https://firstwordpharma.com/story/5696819/'
  },
  {
    'Date Announced': '29-Nov-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Turnstone Biologics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'NIH',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Oncolytic viruses',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      'Under the terms of the CRADA, the parties will evaluate the impact of Turnstone’s proprietary oncolytic viruses on increasing the immune response to tumor neoantigens in clinical patient samples. Turnstone will be responsible for manufacturing and administering its viral immunotherapies to patients and subsequent tissue collection. NCI investigators will use NCI-developed methods and proprietary in vitro techniques to study lymphocytes derived from these patients, characterize their specificity and evaluate their persistence. The parties will jointly analyze data and exchange information and expertise to advance the development of oncolytic viruses as an improved method for the generation of Selected TILs.',
    'FW Link1':
      'Turnstone Biologics Enters Cooperative Research and Development Agreement with the National Cancer Institute to Study TIL Therapy in Combination with Viral Immunotherapy;;https://firstwordpharma.com/story/5680751/'
  },
  {
    'Date Announced': '30-Nov-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'BioNTech',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Ryvu Therapeutics',
    'Country (Target Company/Partner)': 'Poland',
    'Region (Target Company/Partner)': 'Europe',
    'Upfront Value ($m)': '21',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Indication(s)': 'Cancer',
    'Target(s)': 'STING',
    'Deal Structure':
      "Ryvu will receive €40 million ($41.3 million) from BioNTech as part of the deal, consisting of a €20-million upfront payment plus an equity investment also worth €20 million. In addition, Ryvu is eligible for R&D as well as commercial milestones, and could pick up low-single-digit royalties on annual sales of any products that emerge from the partnership. In exchange, BioNTech gains an exclusive global license to develop and commercialise Ryvu's STING agonist portfolio as standalone small molecules, including as monotherapy and in therapeutic combinations. The pair will also jointly undertake drug discovery and research projects to develop multiple small-molecule programmes directed at exclusive targets of BioNTech's choosing. These will mainly focus on immune modulation in oncology, but could potentially be applied in other disease areas too. BioNTech will be able to option global rights to these programmes at the development candidate stage.",
    'FW Link1':
      "BioNTech looks to Ryvu's STING assets to bolster cancer immunotherapies;;https://firstwordpharma.com/story/5681336/"
  },
  {
    'Date Announced': '30-Nov-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Erasca',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Pierre Fabre',
    'Country (Target Company/Partner)': 'France',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'ERAS-007, Braftovi',
    'Indication(s)': 'BRAF V600E-mutant metastatic colorectal cancer',
    'Target(s)': 'ERK1/2, BRAF',
    'Deal Structure':
      'This agreement will support a clinical proof-of-concept trial evaluating ERAS-007, an oral ERK1/2 inhibitor, in combination with encorafenib and the anti-EGFR antibody cetuximab for the treatment of patients with BRAF V600E-mutant metastatic colorectal cancer (mCRC). This combination is being investigated as part of the ongoing Phase 1b/2 HERKULES-3 master protocol in patients with gastrointestinal (GI) malignancies. Erasca will sponsor the trial, and Pierre Fabre will supply encorafenib in the Pierre Fabre territories which include Europe and Asia Pacific (excluding Japan and South Korea). Erasca previously signed CTCSAs with Pfizer and Eli Lilly to evaluate encorafenib and cetuximab in combination with ERAS-007',
    'FW Link1':
      'Erasca Announces Clinical Trial Collaboration and Supply Agreement with Pierre Fabre to Evaluate ERAS-007 and Encorafenib Combination;;https://firstwordpharma.com/story/5681479/'
  },
  {
    'Date Announced': '30-Nov-2022',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Liminatus Pharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Iris Acquisition Corp',
    'Country (Target Company/Partner)': 'Cayman Islands',
    'Region (Target Company/Partner)': 'Carribean',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immunotherapy',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'GCC vaccine',
    'Indication(s)': 'Cancer',
    'Target(s)': 'GCC, CD47',
    'Deal Structure':
      'Liminatus Pharma LLC (“Liminatus”), a clinical-stage biopharmaceutical company developing novel, immune-modulating cancer therapies and Iris Acquisition Corp (“Iris”) (Nasdaq: IRAA), a publicly traded special purpose acquisition company (“SPAC”) formed for the purpose of acquiring or merging with one or more businesses, announced they have entered into a definitive business combination agreement. Upon closing of the transaction, the combined company will be renamed “Liminatus Pharma, Inc.”. The combined company’s common stock is expected to be listed on the Nasdaq Capital Market. The transaction funding includes commitments for a $15 million common stock PIPE financing and a $25 million convertible note financing to further support Liminatus’ business growth strategy.',
    'FW Link1':
      'Liminatus Pharma LLC and Iris Acquisition Corp to combine to incorporate and accelerate much-needed cancer treatments;;https://firstwordpharma.com/story/5681812/'
  },
  {
    'Date Announced': '2-Dec-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Immunocore',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Gadeta',
    'Country (Target Company/Partner)': 'Netherlands',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Gamma-delta T-cell receptor therapies',
    'Indication(s)': 'Solid tumours, colorectal cancer',
    'Deal Structure':
      'Under the terms of the agreement, Immunocore and Gadeta will collaborate on ‘201 γδ-TCR target discovery, and Immunocore will have the option to develop ImmTAC therapies derived from the ‘201 TCR as part of the research collaboration. Immunocore has an option for an exclusive license to further research, develop and commercialize an ImmTAC candidate from the collaboration. Gadeta is eligible to receive upfront, near-term option fee and research milestone payments. If Immunocore exercises its option for an exclusive license, Gadeta is eligible to receive development milestone and commercial milestone payments and is also entitled to receive royalties on sales of the product.',
    'FW Link1':
      'Immunocore links up with Gadeta on gamma-delta TCRs;;https://firstwordpharma.com/story/5682439/'
  },
  {
    'Date Announced': '5-Dec-2022',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'RhoVac',
    'Country (Acquiring Company/Partner)': 'Sweden',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Chosa',
    'Country (Target Company/Partner)': 'Denmark',
    'Region (Target Company/Partner)': 'Europe',
    'Total Deal Value ($m)': '4',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'LiPlaCis',
    'Indication(s)': 'Breast cancer',
    'Deal Structure':
      'RhoVac AB (“RhoVac” or the “Company”) has entered into an agreement to acquire all shares in the Danish privately owned oncology company Chosa ApS (“Chosa”) for a purchase price of approximately SEK 46 million to be paid with newly issued shares in RhoVac. The commercial result of the Transaction is that RhoVac changes its name to Chosa Oncology AB (publ) and continues Chosa’s business as a listed company on Spotlight Stock Market (“Spotlight”), after the approval process with Spotlight has been completed.',
    'FW Link1':
      'RhoVac AB conducts conditional reverse takeover of Chosa ApS;;https://firstwordpharma.com/story/5682776/',
    'FW Link2':
      'Completion of acquisition announced January 18, 2023;;https://firstwordpharma.com/story/5697789/'
  },
  {
    'Date Announced': '6-Dec-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Novartis',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'MorphoSys',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Upfront Value ($m)': '23',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Under the terms of the agreement, Novartis will assume full responsibility for all subsequent research, development and commercialization activities for the program. As part of the agreement, MorphoSys receives an immediate upfront payment of $23 million. On achievement of development, regulatory and commercial milestones, MorphoSys will be eligible to receive milestone payments from Novartis in addition to mid-single- to low-double-digit royalties on program net sales.',
    'FW Link1':
      'MorphoSys Out Licenses Pre-Clinical Oncology Program;;https://firstwordpharma.com/story/5683579/'
  },
  {
    'Date Announced': '6-Dec-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Hummingbird Bioscience',
    'Country (Acquiring Company/Partner)': 'Singapore',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Caris Life Sciences',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibodies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'HMBD-001',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'HER3',
    'Deal Structure':
      "Caris Life Sciences and Hummingbird Bioscience announced a strategic collaboration to advance clinical development of Hummingbird Bioscience's anti-HER3 therapy, HMBD-001. Leveraging Caris' extensive real-world clinico-genomic database to support clinical trial design, Hummingbird Bioscience will be better able to prospectively identify patients with molecular biomarkers that are potentially associated with response to HMBD-001.",
    'FW Link1':
      'Caris Life Sciences and Hummingbird Bioscience Enter Into Collaboration to Advance Clinical Development of Anti-HER3 Therapy;;https://firstwordpharma.com/story/5683397/'
  },
  {
    'Date Announced': '6-Dec-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Nykode Therapeutics',
    'Country (Acquiring Company/Partner)': 'Norway',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Merck & Co.',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cancer vaccine, immunotherapy',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'VB10.16, Keytruda',
    'Indication(s)': 'HPV16-positive head and neck cancer',
    'Target(s)': 'HPV16 E6/E7, PD-1',
    'Deal Structure':
      'Nykode Therapeutics ASA (OSE: NYKD), a clinical-stage biopharmaceutical company dedicated to the discovery and development of novel immunotherapies, announced that it has entered into a clinical collaboration and supply agreement with MSD (Merck & Co., Inc., Rahway, NJ, USA), to evaluate Nykode’s wholly-owned lead candidate, VB10.16, in combination with MSD’s anti-PD-1 therapy KEYTRUDA (pembrolizumab) in a Phase 1/2a trial in patients with HPV16-positive head and neck cancer. Under the terms of the agreement, Merck will supply KEYTRUDA. Nykode retains all commercial rights to VB10.16 worldwide.',
    'FW Link1':
      'Nykode Therapeutics Announces Clinical Collaboration with MSD to Evaluate VB10.16 in Combination With KEYTRUDA (pembrolizumab) in Patients with HPV16-Positive Head and Neck Cancer;;https://firstwordpharma.com/story/5683386/'
  },
  {
    'Date Announced': '6-Dec-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Summit Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Akeso',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '500',
    'Total Deal Value ($m)': '5,000',
    'Geographic Remit of Deal': 'United States, Canada, Europe, Japan',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Bispecific antibodies',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'ivonescimab',
    'Indication(s)': 'Non-small-cell lung cancer, solid tumours',
    'Target(s)': 'PD-1, VEGF',
    'Deal Structure':
      'The definitive partnership calls for Summit to receive the rights to develop and commercialize ivonescimab (SMT112) in the United States, Canada, Europe, and Japan. Akeso will retain development and commercialization rights for the rest of the regions including China. In exchange for these rights, Summit will make an upfront payment of $500 million. The total potential deal value is $5.0 billion, as Akeso will be eligible to receive regulatory and commercial milestones of up to $4.5 billion. In addition, Akeso will be eligible to receive low double-digit royalties on net sales.',
    'FW Link1':
      "Summit pays $500 million upfront for rights to Akeso's PD-1/VEGF bispecific;;https://firstwordpharma.com/story/5683203/",
    'FW Link2':
      'Deal closed on January 20;;https://firstwordpharma.com/story/5698536/'
  },
  {
    'Date Announced': '7-Dec-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Replimune',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Roche',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Oncolytic immunotherapies, monoclonal antibodies',
    'Lead Asset(s)': 'RP2, RP3, atezolizumab, bevacizumab',
    'Indication(s)': 'Colorectal cancer, hepatocellular carcinoma',
    'Target(s)': 'CTLA-4, PD-L1, VEGF',
    'Deal Structure':
      'Specifically, the companies will collaborate in third-line (3L) CRC and in first- and second-line (1L & 2L) HCC. Under the terms of the agreement, the companies will share costs and Roche will supply its currently approved drugs, atezolizumab and bevacizumab for 2L HCC and 3L CRC combined with RP3. Roche will also supply atezolizumab and bevacizumab for 1L HCC combined with RP3, and for 3L CRC combined with RP2. Approximately 30 patients will be enrolled within each cohort. Replimune will have responsibility for operationalizing the clinical trial.',
    'FW Link1':
      'Replimune Enters into Clinical Collaboration Agreement with Roche for the Development of RP3 In Colorectal Cancer and Hepatocellular Carcinoma;;https://firstwordpharma.com/story/5683698/'
  },
  {
    'Date Announced': '7-Dec-2022',
    'Deal type': 'Terminated',
    'Acquiring Company/Partner': 'Sanofi',
    'Country (Acquiring Company/Partner)': 'France',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Revolution Medicines',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '50',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'RMC-4630',
    'Indication(s)': 'RAS-addicted cancers',
    'Target(s)': 'SHP2',
    'Deal Structure':
      'Following termination, Revolution Medicines will regain all global rights granted to Sanofi under the agreement, including decision-making regarding research and development, and rights to all commercial proceeds from RMC-4630, a SHP2 inhibitor drug candidate in development for the treatment of patients with certain RAS-addicted cancers. The companies plan to collaborate to transition all Sanofi’s rights and obligations related to RMC-4630 back to Revolution Medicines over the first half of 2023.',
    'FW Link1':
      'Sanofi walks away from SHP2 pact with Revolution Medicines;;https://firstwordpharma.com/story/5684031/'
  },
  {
    'Date Announced': '7-Dec-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Aarvik Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'NJ Bio',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Aarvik has an exclusive license to commercialize the novel proprietary payloads from NJ Bio, as well as any materials or products containing the payloads, and can acquire ownership and all rights upon completion of option payments. As part of the agreement, Aarvik will pay NJ Bio an upfront payment, option payments, as well as future success-based milestone and royalty payments for products utilizing the payloads. NJ Bio will also provide all conjugation services for the payloads. Aarvik will partner with NJ Bio to manufacture materials for preclinical studies and clinical trials.',
    'FW Link1':
      'Aarvik Therapeutics Announces a Global Novel Payload Agreement with NJ Bio, Inc.;;https://firstwordpharma.com/story/5683909/'
  },
  {
    'Date Announced': '7-Dec-2022',
    'Deal type': 'Terminated',
    'Acquiring Company/Partner': 'Daiichi Sankyo',
    'Country (Acquiring Company/Partner)': 'Japan',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Gilead',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Japan',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'CAR T-cell therapy',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Yescarta',
    'Indication(s)': 'B-cell lymphoma',
    'Target(s)': 'CD19',
    'Deal Structure':
      'Kite Pharma, Inc., a Gilead Company, (hereafter Kite) and Daiichi Sankyo Co., Ltd. (hereafter, Daiichi Sankyo) jointly announced the revision of their 2017 partnership agreement, which gave Daiichi Sankyo exclusive rights to develop, manufacture and commercialize Yescarta (axicabtagene ciloleucel) in Japan. Kite was acquired by Gilead Sciences, Inc. later in 2017 after the Daiichi Sankyo partnership agreement. Daiichi Sankyo and Kite have now agreed that the Marketing Authorization for Yescarta will be transferred to Gilead Sciences K.K., the Japan subsidiary of Gilead Sciences, Inc., in 2023. A Kite Cell Therapy Business Unit at Gilead Sciences K.K. will manage the sales and promotion activities of the product in Japan after the Marketing Authorization transfer.',
    'FW Link1':
      'Kite and Daiichi Sankyo Announce Changes to YESCARTA CAR T-Cell Therapy Licensing Agreement in Japan;;https://firstwordpharma.com/story/5684009/'
  },
  {
    'Date Announced': '8-Dec-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Phanes Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'BeiGene',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'PT199, tislelizumab',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'CD73, PD-1',
    'Deal Structure':
      "Phanes Therapeutics, Inc. (Phanes), a clinical stage biotech company focused on innovative drug discovery and development in oncology, announced a clinical supply agreement with BeiGene, Ltd. (NASDAQ: BGNE) to evaluate the safety and efficacy of Phanes' PT199, a differentiated anti-CD73 monoclonal antibody in combination with BeiGene's tislelizumab, an anti-PD-1 monoclonal antibody in the Phase I clinical study (NCT05431270) for the treatment of multiple advanced solid tumors.",
    'FW Link1':
      'Phanes Therapeutics Announces Clinical Supply Agreement with BeiGene to Evaluate PT199 in combination with Tislelizumab;;https://firstwordpharma.com/story/5684500/'
  },
  {
    'Date Announced': '8-Dec-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Guardant Health',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'AstraZeneca',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Companion diagnostic',
    'Lead Asset(s)': 'Guardant360 CDx',
    'Indication(s)': 'ESR1-mutated metastatic breast cancer',
    'Target(s)': 'ESR1',
    'Deal Structure':
      'Guardant Health, Inc. (Nasdaq: GH), a leading precision oncology company, announced it has entered into a collaboration with AstraZeneca to pursue the development, regulatory approval and commercialization of the Guardant360 CDx blood test as a companion diagnostic to identify patients with ESR1-mutated metastatic breast cancer. The initiative is part of a larger strategic collaboration investigating the use of liquid biopsy as a tool to inform early therapy intervention.',
    'FW Link1':
      'Guardant Health Announces Collaboration With AstraZeneca to Develop Companion Diagnostic to Identify Patients With ESR1-mutated Metastatic Breast Cancer;;https://www.firstwordhealthtech.com/story/5684594/'
  },
  {
    'Date Announced': '9-Dec-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Gilead',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Arcellx',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '225',
    'Total Deal Value ($m)': '4,225',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'CAR T-cell therapy',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'CART-ddBCMA',
    'Indication(s)': 'Multiple myeloma',
    'Target(s)': 'BCMA',
    'Deal Structure':
      'The deal, expected to close in the first quarter of 2023, comes with a $225-million upfront payment and $100-million equity investment in Arcellx, plus up to $3.9 billion in total contingent consideration. Upon closing, Arcellx will receive an upfront cash payment of $225 million and $100 million equity investment. Both companies will share development, clinical trial, and commercialization costs for CART-ddBCMA and will jointly commercialize and split U.S. profits 50/50. Outside the US, Kite will commercialize the product and Arcellx will receive royalties on sales. Kite will be responsible for the development and commercialization costs for any product under the collaboration that is not co-commercialized. After completion of the technical transfer, Kite will be responsible for manufacturing.',
    'FW Link1':
      "Gilead puts up $225 million to advance Arcellx's BCMA-directed CAR-T;;https://firstwordpharma.com/story/5684699/",
    'FW Link2':
      'Closing of agreement announced January 30, 2023;;https://firstwordpharma.com/story/5700839/'
  },
  {
    'Date Announced': '9-Dec-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'ImmunoGen',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Gilead',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugate, monoclonal antibody',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'pivekimab sunirine, magrolimab',
    'Indication(s)': 'Acute myeloid leukaemia',
    'Target(s)': 'CD123, CD47',
    'Deal Structure':
      'ImmunoGen, Inc. (Nasdaq: IMGN), a leader in the expanding field of antibody-drug conjugates (ADCs) for the treatment of cancer, announced a clinical collaboration with Gilead Sciences, Inc. (Nasdaq: GILD) to evaluate the safety and anti-leukemia activity of pivekimab sunirine (pivekimab) in combination with magrolimab, a potential, first-in-class, investigational CD47 inhibitor, in patients with relapsed or refractory (R/R) CD123-positive acute myeloid leukemia (AML).',
    'FW Link1':
      'ImmunoGen Announces Clinical Collaboration with Gilead to Evaluate Pivekimab Sunirine in Combination with Magrolimab in Relapsed/Refractory Acute Myeloid Leukemia;;https://firstwordpharma.com/story/5684678/'
  },
  {
    'Date Announced': '9-Dec-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Erasca',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Novartis',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Upfront Value ($m)': '20',
    'Total Deal Value ($m)': '380',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'naporafenib',
    'Indication(s)': 'RAS/MAPK pathway-driven tumours, NRAS mutant melanoma',
    'Target(s)': 'BRAF, CRAF',
    'Deal Structure':
      'Under the terms of the license agreement, in exchange for an exclusive worldwide license to develop and commercialize naporafenib, Erasca will pay to Novartis a one-time upfront cash payment of $20 million and $80 million of shares in Erasca common stock at a price of $6.50 per share. Novartis is eligible to receive up to $80 million in cash upon the achievement of regulatory milestones covering two indications in the United States, Europe, and Japan, as well as up to $200 million in cash upon the achievement of sales milestones. Novartis is also eligible to receive a low single-digit percentage royalty on net sales of naporafenib.',
    'FW Link1':
      'Erasca Announces Exclusive Worldwide License for Pan-RAF Inhibitor Naporafenib;;https://firstwordpharma.com/story/5684725/'
  },
  {
    'Date Announced': '12-Dec-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'PharmAbcine',
    'Country (Acquiring Company/Partner)': 'Korea, South',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Merck & Co.',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibodies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'PMC-309, Keytruda',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'VISTA, PD-1',
    'Deal Structure':
      'Under the terms of the agreement, PharmAbcine will sponsor a Phase I study in Australia to evaluate the safety and clinical efficacy of the combination therapy for the treatment of multiple advanced solid tumors, and MSD will supply KEYTRUDA.',
    'FW Link1':
      'PharmAbcine Announces Collaboration Agreement with MSD to Evaluate Anti-VISTA Antibody PMC-309 in Combination with KEYTRUDA (pembrolizumab);;https://firstwordpharma.com/story/5685438/'
  },
  {
    'Date Announced': '12-Dec-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Novartis',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Clovis Oncology',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '50',
    'Total Deal Value ($m)': '681',
    'Geographic Remit of Deal':
      'Global (excl. Europe, Russia, Turkey, and Israel)',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Targeted radionuclide',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'FAP-2286',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'Fibroblast activation protein',
    'Deal Structure':
      'Prior to the Chapter 11 filing, and subject to Bankruptcy Court approval, the Company entered into a “stalking horse” purchase and assignment agreement with Novartis Innovative Therapies AG (“Novartis”) to acquire substantially all of the rights of the Company to its pipeline clinical candidate, FAP-2286, as a therapeutic agent for an upfront payment of $50 million and up to an additional $333.75 million upon the successful achievement of specified development and regulatory milestones and $297 million in later sales milestones. Clovis holds US and global rights to FAP-2286, excluding in Europe, Russia, Turkey and Israel, under an agreement entered into in 2019 with 3B Pharmaceuticals.',
    'FW Link1':
      'Clovis offloads targeted radionuclide therapy to Novartis, files for bankruptcy;;https://firstwordpharma.com/story/5685196/'
  },
  {
    'Date Announced': '12-Dec-2022',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'GigCapital5',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'QT Imaging',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Imaging',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'QTscan',
    'Indication(s)': 'Breast cancer',
    'Deal Structure':
      'GigCapital5, Inc. (NYSE: GIA), a publicly traded special purpose acquisition company (“GigCapital5”), and QT Imaging, Inc. (“QT Imaging™”), a medical imaging company that develops novel products for body imaging, announced that they have entered into a definitive business combination agreement that would result in QT Imaging becoming a publicly listed company subject to closing. Upon closing of the transaction, the combined company will be named QT Imaging Holdings, Inc. and will be traded on the NYSE under the new ticker symbol “QTI”.',
    'FW Link1':
      'QT Imaging and GigCapital5 Announce Signing of Business Combination Agreement to Bring Breast and Full Body Imaging Solutions to the Public Markets;;https://www.firstwordhealthtech.com/story/5685386/'
  },
  {
    'Date Announced': '11-Dec-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Nitrase',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'HitGen',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Target(s)': 'Nitrases',
    'Deal Structure':
      'HitGen will apply its DNA-encoded library (DEL) technology platform centered around the design, synthesis and screening of DELs to discover compounds that bind to certain targets that are of interest to Nitrase. Under the terms of the agreement, HitGen will receive an undisclosed upfront payment and will be eligible for milestone payments from Nitrase.',
    'FW Link1':
      'HitGen Announces Research Agreement with Nitrase Therapeutics Focused on DNA-Encoded Library Based Drug Discovery;;https://firstwordpharma.com/story/5685199/'
  },
  {
    'Date Announced': '12-Dec-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Launchpad Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Black Diamond Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibodies',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'In connection with the transaction, Black Diamond contributed undisclosed early discovery-stage antibody programs and granted Launchpad a license to use Black Diamond’s Mutation-Allostery-Pharmacology (MAP) Drug Discovery Engine to discover, develop and commercialize large molecule therapeutics. In exchange, Black Diamond received a minority ownership stake in Launchpad.',
    'FW Link1':
      'Black Diamond Therapeutics Announces Spinout of Launchpad Therapeutics, Inc., an Antibody-Focused Precision Oncology Company;;https://firstwordpharma.com/story/5685272/'
  },
  {
    'Date Announced': '13-Dec-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Envisagenics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner':
      'Cancer Research Horizons, Queen Mary University of London',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial intelligence, RNA therapeutics',
    'Indication(s)': 'Haematopoietic cancers',
    'Deal Structure':
      'Envisagenics, an Artificial Intelligence ("AI")-driven biotechnology company that delivers therapies for RNA splicing diseases, announced a research collaboration agreement with Cancer Research Horizons, Cancer Research UK\'s innovation engine, and Queen Mary University of London. Envisagenics will leverage its SpliceCore AI platform and use Queen Mary\'s de-identified data to further explore the role of alternative splicing in hematopoietic cancers for research and development.',
    'FW Link1':
      'Envisagenics, Cancer Research Horizons, and Queen Mary University of London Announce Strategic Collaboration Agreement;;https://firstwordpharma.com/story/5686138/'
  },
  {
    'Date Announced': '13-Dec-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Bio Farma',
    'Country (Acquiring Company/Partner)': 'Indonesia',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Merck & Co.',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Indonesia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Vaccines',
    'Lead Asset Stage/Phase': 'Marketed',
    'Indication(s)': 'Human papillomavirus, cervical cancer',
    'Deal Structure':
      "Indonesia will produce Merck & Co.'s vaccines for human papillomavirus (HPV), Honesti Basyir, the chief of its state-owned pharmaceutical company Bio Farma, said on Tuesday, in a bid to combat HPV-linked cervical cancer in the country. Basyir signed a technology transfer deal with Merck to help produce the HPV vaccines in the country.",
    'FW Link1':
      "Indonesia to produce Merck's HPV vaccines to combat cervical cancer;;https://firstwordpharma.com/story/5685992/"
  },
  {
    'Date Announced': '13-Dec-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Biosion',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'ImmunoGen',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Biosion USA, Inc. (Biosion), a global R&D biotechnology company, announced an exploratory research collaboration to create antibody-drug conjugates (ADCs) for the treatment of cancer with ImmunoGen, Inc. (Nasdaq: IMGN), a leader in developing next generation ADCs. In the joint research effort, Biosion will leverage its proprietary SynTracer high-throughput (HT) endocytosis platform to generate highly selective antibodies to targets allocated by each company and ImmunoGen will provide their proprietary linker-payload technology to create novel ADCs.',
    'FW Link1':
      'Biosion Announces Research Collaboration with ImmunoGen to Create Novel Antibody-Drug Conjugates;;https://firstwordpharma.com/story/5685826/'
  },
  {
    'Date Announced': '13-Dec-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Therabest',
    'Country (Acquiring Company/Partner)': 'Korea, South',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Glycotope',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'iPSC-derived NK cell therapy, immuno-cytokine',
    'Lead Asset(s)': 'TB-100, GT-00AxIL15',
    'Indication(s)': 'Triple-negative breast cancer',
    'Deal Structure':
      'Therabest USA. Inc, Therabest Korea (Therabest) and Glycotope GmbH (Glycotope) have signed an agreement to assess the clinical development of Therabest’s EiNK (Enhanced iPSC-derived NK) cell therapy, TB-100, in combination with Glycotope’s immuno-cytokine, GT-00AxIL15 in triple-negative breast cancer (TNBC) patients.',
    'FW Link1':
      'Therabest and Glycotope to assess Therabest’s iPSC-derived NK cell product TB-100 in combination with Glycotope’s GT-00AxIL15 immuno-cytokine for development in triple negative breast cancer;;https://firstwordpharma.com/story/5685831/'
  },
  {
    'Date Announced': '14-Dec-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Immix Biopharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Undisclosed',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'CAR T-cell therapy',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'NXC-201 (formerly HBI10101)',
    'Indication(s)': 'Multiple myeloma',
    'Target(s)': 'BCMA',
    'Deal Structure':
      'Immix Biopharma, Inc. (Nasdaq: IMMX) (“ImmixBio”, “Company”, “We” or “Us”), a biopharmaceutical company pioneering Tissue-Specific Therapeutics (TSTx) targeting oncology and immuno-dysregulated diseases, announced that it has in-licensed BCMA-targeted next-generation CAR-T therapy NXC-201 (formerly HBI0101). Immix Biopharma has formed a wholly-owned subsidiary, Nexcella, Inc., to develop and potentially commercialize NXC-201.',
    'FW Link1':
      'Immix Biopharma in-licenses NXC-201, BCMA-targeted Next-Generation CAR-T Therapy Demonstrating High Complete Response Rate in Heavily Pre-Treated Multiple Myeloma (71% Complete Responses) and AL Amyloidosis (100% Complete Responses);;https://firstwordpharma.com/story/5686547/'
  },
  {
    'Date Announced': '14-Dec-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Solve Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'University of California San Diego',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibodies',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'The company has signed a licensing agreement with the University of California San Diego for a series of antibodies that show high reactivity with tumors and no or low reactivity with normal tissues, which may enable the selective killing of tumor cells and a broad therapeutic index.',
    'FW Link1':
      'Solve Therapeutics Announces Mission to Advance Novel Antibody-Based Therapies Against Cancer-Specific Targets;;https://firstwordpharma.com/story/5686753/'
  },
  {
    'Date Announced': '14-Dec-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'AstraZeneca',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Clinithink',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'United Kingdom',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial intelligence, diagnostics',
    'Indication(s)': 'Lung cancer',
    'Deal Structure':
      'Clinithink, the company that has developed the world’s first Healthcare AI capable of truly understanding unstructured medical notes, has partnered with AstraZeneca Oncology UK in a new project that aims to find patients with lung cancer at an early stage where treatment can be more effective. Using AI based technology, the first stage of the project is a retrospective analysis of patients’ unstructured electronic medical records that will test whether it is possible and cost-effective to use the AI technology - machine learning (ML) and natural language processing (NLP) - to identify both symptomatic and asymptomatic patients who are in early stages of the disease. The outputs will then be used to develop predictive models that flag high risk individuals at a much earlier, more treatable, stage of disease, improving survival rates and driving down the intensity of treatment needed.',
    'FW Link1':
      "AstraZeneca AI project seeks early lung cancer clues buried in doctor's notes;;https://www.firstwordhealthtech.com/story/5686584/"
  },
  {
    'Date Announced': '14-Dec-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Oxford Biomedica',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Undisclosed',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Gene therapy',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Oxford Biomedica Solutions LLC, an AAV manufacturing and innovation company, announced that it has signed agreements with three additional U.S. based, biotechnology companies. Under these additional agreements, Oxford Biomedica Solutions will provide its full platform offering to support the new partners’ gene therapy programs, which cover a broad range of indications, including CNS, autoimmune, oncology, muscular, and rare metabolic disease.',
    'FW Link1':
      'Oxford Biomedica Solutions forms new partnerships with three biotechnology companies;;https://firstwordpharma.com/story/5686643/'
  },
  {
    'Date Announced': '13-Dec-2022',
    'Deal type': 'Product Acquisition',
    'Acquiring Company/Partner': 'Lotus Pharmaceuticals',
    'Country (Acquiring Company/Partner)': 'Taiwan',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Lilly',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Total Deal Value ($m)': '62',
    'Geographic Remit of Deal': 'Taiwan',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Chemotherapy',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Alimta',
    'Indication(s)':
      'Non-small-cell lung cancer, malignant pleural mesothelioma',
    'Deal Structure':
      'Lotus Pharmaceuticals (1795:TT, the “Company”), a multinational pharmaceutical company, announced that its Board of Directors has approved the acquisition of the trademark, marketing authorization, and manufacturing know-how of Pemetrexed 100mg and 500mg under the brand name of Alimta in Taiwan for US$62 million from Eli Lilly and Company (“Lilly”). Immediately upon closing the transaction, the Company will take over Alimta business in Taiwan.',
    'FW Link1':
      'Lotus acquires Alimta (Pemetrexed) in Taiwan from Lilly;;https://firstwordpharma.com/story/5687027/'
  },
  {
    'Date Announced': '19-Dec-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Sanofi',
    'Country (Acquiring Company/Partner)': 'France',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Innate Pharma',
    'Country (Target Company/Partner)': 'France',
    'Region (Target Company/Partner)': 'Europe',
    'Upfront Value ($m)': '27',
    'Total Deal Value ($m)': '1,455',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'NK cell engager',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'B7H3',
    'Deal Structure':
      "Sanofi has licensed a natural killer (NK) cell engager programme targeting B7H3 from Innate Pharma's ANKET platform, while the expanded deal also gives Sanofi the option to add up to two additional ANKET targets. As part of the new terms, it will receive €25 million ($27 million) upfront from Sanofi, and is also eligible for up to €1.35 billion ($1.43 billion) in total milestones plus royalties.",
    'FW Link1':
      'Sanofi expands NK cell engager deal with Innate;;https://www.firstwordpharma.com/story/5687875/'
  },
  {
    'Date Announced': '20-Dec-2022',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Gilead',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Tmunity Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'CAR T cell therapy',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'CART-TnMUC1',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'The purchase of the University of Pennsylvania spinout for an undisclosed amount will provide it with rapid manufacturing processes, as well as a set of preclinical and clinical assets, including an "armoured" technology platform that it says could potentially be applied to a variety of CAR-T\'s to enhance anti-tumour activity.',
    'FW Link1':
      'Gilead brings next-gen CAR-T developer Tmunity into the fold;;https://www.firstwordpharma.com/story/5688359/',
    'FW Link2':
      'Completion of acquisition announced February 22, 2023;;https://firstwordpharma.com/story/5708380/'
  },
  {
    'Date Announced': '19-Dec-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Roche',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Biogen',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Bispecific antibodies',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'glofitamab',
    'Indication(s)': 'B-cell non-Hodgkin’s lymphoma ',
    'Target(s)': 'CD20, CD3',
    'Deal Structure':
      "Under the terms of the agreement, Biogen will have no payment obligations and will receive tiered royalties on potential net sales of glofitamab within the US as part of the companies long-standing collaboration on antibodies targeting CD20. Roche's Genentech unit will have sole decision-making rights on the commercialisation of glofitamab within the US and, in the event of approval, Biogen is eligible to receive tiered royalties in the mid-single digits range on potential net sales of glofitamab within the US.",
    'FW Link1':
      'Biogen Reaches Agreement with Genentech to Receive Royalties on the Potential Commercialization of a Late-Stage Bispecific Antibody as Part of Anti-CD20 Collaboration;;https://www.firstwordpharma.com/story/5687884/'
  },
  {
    'Date Announced': '15-Dec-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Doer Biologics',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Merck & Co.',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody, immunotherapy',
    'Lead Asset(s)': 'DR30303, Keytruda',
    'Indication(s)': 'Gastric or gastro-oesophageal junction cancer',
    'Target(s)': 'Claudin18.2, PD-1',
    'Deal Structure':
      'Under this agreement, Doer Bio will conduct a clinical study based on a mutually- agreed and finalized protocol, to evaluate the safety and efficacy of DR30303 in combination with KEYTRUDA in patients with Claudin18.2-positive, locally advanced unresectable or metastatic gastric or gastroesophageal junction (GC/GEJ) cancer.',
    'FW Link1':
      'Zhejiang Doer Biologics Announces Clinical Trial Collaboration with MSD to Evaluate DR30303 in Combination with KEYTRUDA (pembrolizumab) in Patients with Gastric or Gastroesophageal Junction Cancer;;https://firstwordpharma.com/story/5687349/'
  },
  {
    'Date Announced': '16-Dec-2022',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Prenetics',
    'Country (Acquiring Company/Partner)': 'Hong Kong',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'ACT Genomics',
    'Country (Target Company/Partner)': 'Taiwan',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics, genomics',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Under the terms of the deal, Prenetics will issue close to 20 million of its class A ordinary shares and pay $20 million in cash for the ACT stake.',
    'FW Link1':
      'Prenetics broadens footprint in cancer genetics with ACT stake grab;;https://www.firstwordhealthtech.com/story/5687357/'
  },
  {
    'Date Announced': '16-Dec-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Adagene',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Roche',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibodies, immunotherapies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'ADG126, atezolizumab, bevacizumab',
    'Indication(s)': 'Hepatocellular carcinoma',
    'Target(s)': 'CTLA-4, PD-L1, VEGF',
    'Deal Structure':
      'Under the collaboration, Roche will sponsor and conduct a randomized phase 1b/2 multi-national trial to evaluate the efficacy, safety and pharmacokinetics of ADG126 in combination with bevacizumab and atezolizumab, versus atezolizumab and bevacizumab alone, initially in 60 patients. Each company is supplying its respective anti-cancer agent(s) to support the trial. Adagene will retain global development and commercialization rights to ADG126. Additional financial details of the collaboration were not disclosed.',
    'FW Link1':
      'Adagene Announces Clinical Trial Collaboration to Evaluate Anti-CTLA-4 SAFEbody ADG126 in Combination with Roche’s Standard-of-Care for First-Line Advanced Liver Cancer;;https://firstwordpharma.com/story/5687352/'
  },
  {
    'Date Announced': '16-Dec-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Redx Pharma',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Merck & Co.',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules, antibodies, immunotherapies',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'RXC004, Keytruda',
    'Indication(s)': 'Biliary cancer',
    'Target(s)': 'Porcupine enzyme, PD-1',
    'Deal Structure':
      'Redx, the clinical-stage biotechnology company focused on discovering and developing novel, small molecule, highly targeted therapeutics for the treatment of cancer and fibrotic disease, announces that the Company has entered into a clinical trial collaboration and supply agreement with MSD (Merck & Co., Inc.,  Rahway, NJ, USA), for the supply of KEYTRUDA (pembrolizumab), MSD’s anti-PD-1 therapy, to be used in the combination arm of Redx’s ongoing PORCUPINE2 Phase 2 clinical study evaluating RXC004 as a potential treatment for patients with biliary cancer.',
    'FW Link1':
      'Redx Signs Clinical Trial Collaboration and Supply Agreement with MSD to evaluate RXC004 in combination with KEYTRUDA (pembrolizumab) in PORCUPINE2 Study in Biliary Cancer;;https://firstwordpharma.com/story/5687376/'
  },
  {
    'Date Announced': '18-Dec-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'BioNTech',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Yonglin Healthcare Foundation',
    'Country (Target Company/Partner)': 'Taiwan',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'mRNA-based cancer immunotherapies, vaccines',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Terry Gou, founder of the foundation and manufacturing giant Hon Hai Precision Industry, said that the Yonglin Healthcare Foundation will partner with BioNTech to develop mRNA-based cancer immunotherapies.',
    'FW Link1':
      'BioNTech to work with Terry Gou’s Yonglin on cancer;;https://firstwordpharma.com/story/5687843/'
  },
  {
    'Date Announced': '19-Dec-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'GenFleet Therapeutics',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Merck KGaA',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules, antibodies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'GFH925, Erbitux',
    'Indication(s)': 'Non-small-cell lung cancer',
    'Target(s)': 'KRAS G12C, EGFR',
    'Deal Structure':
      'GenFleet Therapeutics, a clinical-stage biotechnology company focusing on cutting-edge therapies in oncology and immunology, announced that it has entered into a clinical trial collaboration and supply agreement with Merck to start a clinical study of the combination therapy of GFH925 with ERBITUX (cetuximab) as a potential frontline treatment among NSCLC patients harboring KRAS G12C mutation in a multi-center Phase Ib/II trial in Europe. Under the terms of the agreement, GenFleet will conduct an open-label study of the combination therapy in previously untreated advanced NSCLC patients. Merck will provide clinical drug supplies of cetuximab in this multi-center trial in Europe.',
    'FW Link1':
      'GenFleet Therapeutics and Merck Enter into Trial Collaboration to Initiate Study Investigating Combination Therapy of GFH925 (KRAS G12C inhibitor) and ERBITUX (cetuximab);;https://firstwordpharma.com/story/5688018/'
  },
  {
    'Date Announced': '19-Dec-2022',
    'Deal type': 'Product Acquisition',
    'Acquiring Company/Partner': 'ProPhase',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Stella Diagnostics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '5',
    'Total Deal Value ($m)': '7',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'Lead Asset(s)': 'BE-Smart',
    'Indication(s)': 'Oesophageal cancer',
    'Deal Structure':
      'ProPhase Labs, Inc. “ProPhase”, a growth oriented and diversified diagnostics, genomics and biotech company, announced that it has entered into an asset purchase agreement to acquire from Stella Diagnostics, Inc. (“Stella”) world-wide exclusive rights to Stella’s BE-Smart Esophageal Pre-Cancer diagnostic screening test and related intellectual property assets for approximately $4.5 million dollars, comprised of approximately $3.5 million in cash and $1 million in ProPhase common stock. Under the terms of the agreement, an additional $2 million of Company common stock may be issued to Stella in the future upon the achievement of a revenue-based commercial milestone within the five-year period following the closing. Stella will also receive a 5% royalty based on adjusted gross margin generated from the commercialization of the intellectual property acquired from Stella.',
    'FW Link1':
      'ProPhase Labs Acquires Rights to Novel Esophageal Cancer Test;;https://www.firstwordhealthtech.com/story/5687971/',
    'FW Link2':
      'Completion of acquisition announced January 5, 2023;;https://www.firstwordhealthtech.com/story/5693498/'
  },
  {
    'Date Announced': '19-Dec-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Carrick Therapeutics',
    'Country (Acquiring Company/Partner)': 'Ireland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Menarini',
    'Country (Target Company/Partner)': 'Italy',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'samuraciclib, elacestrant',
    'Indication(s)': 'Metastatic breast cancer',
    'Target(s)': 'CDK7, oestrogen receptor',
    'Deal Structure':
      "This agreement covers the execution of a Phase 2 clinical trial to evaluate the novel combination of Carrick's samuraciclib (CT7001), an oral and first-in-class inhibitor of CDK7, and Menarini's oral selective estrogen receptor degrader (SERD), elacestrant, in patients with CDK4/6i resistant HR+, HER2- metastatic breast cancer. Menarini and Carrick will jointly sponsor the clinical trial.",
    'FW Link1':
      'Carrick Therapeutics and The Menarini Group Announce Clinical Trial Collaboration to Evaluate Samuraciclib and Elacestrant Combination;;https://firstwordpharma.com/story/5688097/'
  },
  {
    'Date Announced': '19-Dec-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Ordaōs',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Yatiri Bio',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Proteomics, mini-proteins',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Acute myeloid leukaemia',
    'Deal Structure':
      "Yatiri Bio will utilize its rich knowledge base of global proteomics from AML patient samples and matched model systems to identify two cell surface therapeutic targets for high-risk AML. Ordaōs, using their proprietary Ordaōs Design Engine, will create a panel of in silico de novo protein candidates and will be responsible for in vitro validation of those leads. Once developed, target leads will be tested in Yatiri Bio's portfolio of matched and patient-derived cellular models, ProteoModels, informed with clinical proteome data and extensive ex-vivo testing of Standard of Care (SoC) therapies. Once the targets are validated, Ordaōs and Yatiri Bio will jointly develop these candidates further for an IND submission.",
    'FW Link1':
      'Ordaōs Announces a Joint Development Agreement to Create Novel Therapeutics for AML;;https://firstwordpharma.com/story/5688074/'
  },
  {
    'Date Announced': '20-Dec-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'RenovoRx',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'xCures',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Chemotherapy, drug delivery',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'RenovoGem',
    'Indication(s)': 'Pancreatic cancer',
    'Deal Structure':
      'Through this collaboration, xCures will help eligible pancreatic cancer patients access a Phase III clinical study entitled, Targeted Intra-arterial Gemcitabine vs. Continuation of IV Gemcitabine plus Nab-Paclitaxel following Induction with sequential IV Gemcitabine plus Nab-Paclitaxel and Radiotherapy for Unresectable Locally Advanced Pancreatic Cancer (TIGeR-PaC).',
    'FW Link1':
      'xCures partners with RenovoRx to assist patients in accessing RenovoRx’s TIGeR-PaC study;;https://firstwordpharma.com/story/5688567/'
  },
  {
    'Date Announced': '19-Dec-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Kazia Therapeutics',
    'Country (Acquiring Company/Partner)': 'Australia',
    'Region (Acquiring Company/Partner)': 'Oceania',
    'Target Company/Partner': 'QIMR Berghofer Medical Research Institute',
    'Country (Target Company/Partner)': 'Australia',
    'Region (Target Company/Partner)': 'Oceania',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecule',
    'Lead Asset(s)': 'paxalisib',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'PI3K, mTOR',
    'Deal Structure':
      'Kazia Therapeutics has announced a research collaboration with QIMR Berghofer Medical Research Institute (an Australia-based cancer research center) to investigate the utility of its lead asset, paxalisib (PI3K/mTOR inhibitor) in solid tumors. The collaboration plans to build on previously conducted research, including the potential use of paxalisib as an immune modulator in solid tumors.',
    'FW Link1':
      'Kazia Therapeutics (KZIA): Collaboration May Expand Utility to Combo Therapies;;https://firstwordpharma.com/story/5688335/'
  },
  {
    'Date Announced': '20-Dec-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'HealthCare Royalty',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Atara Biotherapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '31',
    'Geographic Remit of Deal': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Allogeneic T-cell therapy',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Ebvallo',
    'Indication(s)':
      'Epstein Barr virus positive post transplant lymphoproliferative disease',
    'Deal Structure':
      'Under the terms of the agreement, Atara will receive a $31 million upfront payment from HCRx. In exchange, HCRx will receive rights to specified royalties and milestones under the Pierre Fabre commercialization agreement for Europe and other territories. The total royalties and milestones payable to HCRx are capped between 185 percent and 250 percent of the total investment amount by HCRx, dependent upon the timing of such royalties and milestones. Additionally, Atara retains existing milestone payments from Pierre Fabre of $10 million for Ebvallo approval in Europe and $30 million for Atara’s initiation of the EU Marketing Authorization Application transfer to Pierre Fabre from the previously announced commercialization agreement and subsequent update announced in September 2022.',
    'FW Link1':
      'Atara Biotherapeutics Announces $31 Million Royalty Interest Financing Agreement with HealthCare Royalty;;https://firstwordpharma.com/story/5688712/'
  },
  {
    'Date Announced': '21-Dec-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Jazz Pharmaceuticals',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Zymeworks',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '325',
    'Total Deal Value ($m)': '1,800',
    'Geographic Remit of Deal':
      'US, Europe, Japan and all other territories, except for those in Asia/Pacific',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Biparatopic antibody ',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'zanidatamab',
    'Indication(s)': 'HER2-expressing cancers',
    'Target(s)': 'HER2',
    'Deal Structure':
      "Jazz will now make a one-time payment of $325 million to Zymeworks in the fourth quarter to exercise its option to continue with an exclusive license for zanidatamab, also known as ZW25, in the US, Europe, Japan and all other territories, except for those in Asia/Pacific that Zymeworks had already licensed to BeiGene. In addition, Zymeworks stands to receive tiered royalties between 10% and 20% on Jazz's net sales of zanidatamab, if it obtains approval.",
    'FW Link1':
      'Jazz sticks with Zymeworks after anti-HER2 antibody opt-in;;https://firstwordpharma.com/story/5688887/'
  },
  {
    'Date Announced': '22-Dec-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Merck KGaA',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Mersana Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '30',
    'Total Deal Value ($m)': '830',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates ',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Target(s)': 'STING',
    'Deal Structure':
      'Mersana will receive $30 million upfront, and is eligible for up to $800 million in milestones, plus tiered royalties up to the low double-digit percentages on net sales. As part of the agreement, Mersana will oversee certain discovery activities and limited preclinical manufacturing and supply obligations, while Merck will be solely responsible for other preclinical work, clinical development and potential commercialisation of the candidates.',
    'FW Link1':
      'Merck KGaA, Mersana team up on STING-agonist ADCs;;https://firstwordpharma.com/story/5689699/'
  },
  {
    'Date Announced': '22-Dec-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Merck & Co.',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Kelun-Biotech',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '175',
    'Total Deal Value ($m)': '9,300',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates ',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Merck & Co. agreed to pay $175 million upfront under a deal with Kelun-Biotech potentially to develop seven preclinical antibody-drug conjugates (ADCs) for cancer. The deal gives Merck exclusive global licenses to research, develop, manufacture and commercialise multiple investigational ADCs, as well as exclusive licensing options on additional candidates, while Kelun retains the right to certain licensed and option ADCs for China, Hong Kong and Macau. Along with the upfront payment, Kelun stands to gain milestones of up to $9.3 billion if it chooses not to keep rights for the option ADCs in those three territories, and if all candidates ultimately obtain regulatory approval. It is also eligible for tiered sales royalties, and Merck said it would make an unspecified equity investment in Kelun as well.',
    'FW Link1':
      'Merck & Co. bets big on antibody-drug conjugate pact with Kelun;;https://firstwordpharma.com/story/5689388/',
    'FW Link2':
      'Deal coming into effect announced February 15, 2023;;https://firstwordpharma.com/story/5706688/',
    'FW Link3':
      "Merck & Co.'s decision to pull back on some cancer drug development with Kelun announced October 23, 2023;;https://firstwordpharma.com/story/5792445/"
  },
  {
    'Date Announced': '29-Dec-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Gilead',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Jounce Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Total Deal Value ($m)': '67',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'GS-1811',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'CCR8',
    'Deal Structure':
      'Under the original deal on the drug, formerly known as JTX-1811, Gilead made an upfront payment of $85 million, with Jounce eligible to receive up to $645 million in milestones. Jounce noted that as part of the amended agreement, it is no longer eligible to receive any further milestone payments linked to GS-1811, or high single digit to mid-teens sales royalties. Gilead will be solely responsible for all further research, development and commercialisation of the drug, which is currently in Phase I development for patients with solid tumours.',
    'FW Link1':
      'Jounce takes the cash under amended deal with Gilead on anti-CCR8 antibody;;https://firstwordpharma.com/story/5690691/'
  },
  {
    'Date Announced': '3-Jan-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'PDS Biotechnology',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Merck KGaA',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Upfront Value ($m)': '5',
    'Total Deal Value ($m)': '121',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Fusion protein',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'M9241',
    'Indication(s)': 'Cancer',
    'Target(s)': 'IL-12',
    'Deal Structure':
      "As part of the deal, Merck gets $5 million in cash upfront and is entitled to up to $11 million in development and regulatory milestones, including first commercial sales for the first two indications, and up to $105 million in commercial milestones, plus a 10% royalty on future sales. In addition, Merck will receive $5 million worth of PDS Biotech's common stock based on the share closing price on December 30. PDS Biotech will assume responsibility for future development, commercialisation and manufacturing of M9241, which was also previously known as NHS-IL12.",
    'FW Link1':
      'Merck KGaA licenses IL-12 tumour-targeted cytokine to PDS Biotech;;https://firstwordpharma.com/story/5692172/'
  },
  {
    'Date Announced': '5-Jan-2023',
    'Deal type': 'Terminated',
    'Acquiring Company/Partner': 'J&J',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Fate Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '50',
    'Total Deal Value ($m)': '3,000',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'CAR NK- and CAR T-cell therapies',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'The partnership was centred on developing induced pluripotent stem cell (iPSC)-derived CAR NK- and CAR T-cell therapies. Two product candidates targeting "high-value, clinically-validated haematology antigens" were set to move into the clinic this year. Fate had received $50 million upfront as part of the deal, as well as a $50-million equity investment, while Johnson & Johnson was on the hook for up to $3 billion in potential milestones.',
    'FW Link1':
      'Fate sinks after termination of J&J tie-up, staff layoffs;;https://firstwordpharma.com/story/5693596/'
  },
  {
    'Date Announced': '5-Jan-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Moderna',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'CytomX Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '35',
    'Total Deal Value ($m)': '1,200',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'mRNA-based conditionally activated therapies',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Moderna has inked an agreement focused on creating mRNA-based conditionally activated therapies for oncology and non-oncology conditions with the help of CytomX Therapeutics' Probody platform. Under the arrangement, Moderna and CytomX will work together on discovery and pre-clinical development, while Moderna will lead clinical and sales activities for any therapeutics that emerge from the collaboration. The deal also gives Moderna an option to participate in a future equity financing by CytomX.",
    'FW Link1':
      'Moderna teams up with CytomX in mRNA pact potentially worth over $1.2 billion;;https://firstwordpharma.com/story/5693562/'
  },
  {
    'Date Announced': '5-Jan-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'GSK',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'WuXi Biologics',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '40',
    'Total Deal Value ($m)': '1,460',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'T-cell engaging antibodies',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Cancer',
    'Target(s)': 'CD3',
    'Deal Structure':
      "GSK entered into a licensing agreement for up to four bi- and multi-specific T-cell engaging (TCE) antibodies developed using WuXi Biologics' technology platforms. Specifically, GSK will gain an exclusive global license for the research, development, manufacturing and commercialisation of a preclinical bispecific antibody that crosslinks tumour cells and T-cells by targeting a tumour-associated antigen (TAA) on tumour cells and CD3 expression on T-cells. The deal also includes up to three additional preclinical TCE antibodies currently at an earlier discovery stage.",
    'FW Link1':
      'GSK licenses preclinical T-cell engagers from WuXi Biologics;;https://firstwordpharma.com/story/5693075/'
  },
  {
    'Date Announced': '10-Jan-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'AbbVie',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Anima Biotech',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '42',
    'Total Deal Value ($m)': '582',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'mRNA biology modulators',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "The partnership is centred on using Anima Biotech's mRNA Lightning platform to develop mRNA biology modulators for three targets across oncology and immunology. Anima will use its platform to discover mRNA biology modulators against the collaboration targets, providing AbbVie exclusive rights to license and further develop and commercialise the programmes. Anima is also eligible for more milestones based on achieving commercial targets, as well as tiered sales royalties. Meanwhile, AbbVie has an option to expand the collaboration with up to three additional targets under the same terms as the initial agreement, potentially boosting the value of the deal.",
    'FW Link1':
      'AbbVie commits up to $582 million in mRNA modulator deal with Anima;;https://firstwordpharma.com/story/5695155/'
  },
  {
    'Date Announced': '5-Jan-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Esperovax',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Ginkgo Bioworks',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Circular RNA-based therapeutics',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Colorectal cancer',
    'Deal Structure':
      'Initially, Ginkgo and Esperovax will work to develop circRNAs harboring payloads to specifically target colorectal cancer by inducing cell death only in cancerous cells.',
    'FW Link1':
      'Esperovax and Ginkgo Bioworks Announce Partnership to Develop Circular RNA-based Therapeutics;;https://firstwordpharma.com/story/5693256/'
  },
  {
    'Date Announced': '5-Jan-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'IntoCell',
    'Country (Acquiring Company/Partner)': 'Korea, South',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'ADC Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Under the agreement, IntoCell will provide proprietary drug-linkers developed using their proprietary Ortho-Hydroxy Protected Aryl Sulfate (OHPASTM) system and payload modification technology (PMT). ADC Therapeutics will conjugate its antibodies to IntoCell's drug-linker and perform in vitro, in vivo, and toxicology experiments. Upon successful completion of the evaluation, ADC Therapeutics will have an option to license the technology to develop ADCs for up to six targets for global development and commercialization. IntoCell reserves all global rights for its platform technologies beyond ADC development for targets reserved by ADC Therapeutics and will receive a fee upon signing of the MTA. For each target option exercised by ADCT, IntoCell is also entitled to receive a target selection fee, potential development, regulatory, and commercial milestone payments, as well as royalties in the future upon successful achievement of each applicable milestone.",
    'FW Link1':
      'IntoCell Enters into Development and License Option Agreement with ADC Therapeutics;;https://firstwordpharma.com/story/5693080/'
  },
  {
    'Date Announced': '6-Jan-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'AbbVie',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Immunome',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '30',
    'Total Deal Value ($m)': '1,300',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibodies',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Under the terms of the agreement, Immunome will grant AbbVie the option to purchase worldwide rights for up to 10 novel target-antibody pairs arising from the selected tumors. Immunome will receive an upfront payment of $30M and will be eligible to receive additional platform access payments in the aggregate amount of up to $70M based on AbbVie's election for Immunome to continue research using its Discovery Engine. Immunome is also eligible to receive development and first commercial sale milestones of up to $120M per target with respect to certain products derived from target-antibody pairs that AbbVie elects to purchase, with potential for further sales-based milestones as well as tiered royalties on global sales. ",
    'FW Link1':
      'AbbVie and Immunome Announce Strategic Collaboration to Discover Multiple Novel Oncology Targets;;https://firstwordpharma.com/story/5693851/'
  },
  {
    'Date Announced': '9-Jan-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Boehringer Ingelheim',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': '3T Biosciences',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Total Deal Value ($m)': '268',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immunotherapies',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Under the agreement, Boehringer Ingelheim will provide patient-derived T-cell receptor (TCR) data to fuel 3T's target discovery efforts to identify antigens using its 3T TRACE discovery platform. 3T will receive an upfront payment and research and development support, and is eligible for discovery, preclinical, clinical, regulatory, and commercial milestones totaling $268 million in addition to royalties on future Boehringer Ingelheim product sales. Boehringer Ingelheim is eligible to receive royalties on future product sales by 3T Biosciences arising from the agreement.\n",
    'FW Link1':
      'Boehringer Ingelheim and 3T Biosciences Join Forces to Develop Next-Generation Cancer Immunotherapies;;https://firstwordpharma.com/story/5694441/'
  },
  {
    'Date Announced': '16-Jan-2023',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'Huadong Medicine',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'CARsgen Therapeutics',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '30',
    'Total Deal Value ($m)': '182',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'CAR T-cell therapy',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'zevorcabtagene autoleucel',
    'Indication(s)': 'Multiple myeloma',
    'Target(s)': 'BCMA',
    'Deal Structure':
      'Under the agreed terms, CARsgen will receive an upfront payment of CNY 200 million ($30 million) and is also eligible for regulatory and commercial milestones up to CNY 1.025 billion ($152 million). CARsgen noted that it will continue to be responsible for the development, regulatory approval and manufacturing of zevor-cel in China.',
    'FW Link1':
      'CARsgen, Huadong Medicine ink deal to sell CAR T-cell product in China;;https://firstwordpharma.com/story/5696830/'
  },
  {
    'Date Announced': '17-Jan-2023',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Elicio Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Angion Biomedica',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cancer vaccine',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'ELI-002',
    'Indication(s)': 'KRAS-driven tumours',
    'Target(s)': 'KRAS',
    'Deal Structure':
      'If stockholders approve the transaction, Elicio shareholders will receive newly issued shares of Angion common stock. Once the deal is finalised, current Angion stockholders are expected to hold approximately 34.5% of the new company, while Elicio stockholders will own the remainder. The deal has received unanimous backing by the boards of directors of both companies and is expected to close in the second quarter.',
    'FW Link1':
      'Elicio, Angion agree to combine to advance KRAS-targeting cancer vaccine;;https://firstwordpharma.com/story/5697168/',
    'FW Link2':
      'Completion of merger announced June 1, 2023;;https://firstwordpharma.com/story/5747097/'
  },
  {
    'Date Announced': '17-Jan-2023',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Leap Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Flame Biosciences',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibodies',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'FL-301, DKN-01',
    'Indication(s)': 'Cancer',
    'Target(s)': 'Claudin18.2, DKK1',
    'Deal Structure':
      "Leap will issue shares of its common stock to Flame shareholders and has also agreed to pay them 80% of after-tax proceeds from any post-merger deals to out-license or sell Flame's early-stage anti-IL-1b antibodies, FL-101 or FL-103. If the merger is finalised – which requires the approval of Leap shareholders – Leap said Flame's stockholders will own 58% of the combined entity, or 47% on a fully diluted basis.",
    'FW Link1':
      'Leap picks up anti-Claudin assets via Flame merger;;https://firstwordpharma.com/story/5697263/'
  },
  {
    'Date Announced': '21-Dec-2022',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'Y-mAbs Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'WEP Clinical',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibody',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Danyelza',
    'Indication(s)': 'Neuroblastoma',
    'Target(s)': 'GD2',
    'Deal Structure':
      'Y-mAbs Therapeutics, Inc. announced that it has entered into a distribution agreement with WEP Clinical Ltd. in connection with an early access program for DANYELZA (naxitamab-gqgk) 40mg/10mL Injection in Europe.',
    'FW Link1':
      'Y-mAbs Announces Partnership Regarding Early Access Program for DANYELZA (naxitamab-gqgk) in Europe;;https://firstwordpharma.com/story/5688941/'
  },
  {
    'Date Announced': '21-Dec-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'ORIC Pharmaceuticals',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Pfizer',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecule, bispecific antibody',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'ORIC-533, elranatamab',
    'Indication(s)': 'Multiple myeloma',
    'Target(s)': 'CD73, BCMA, CD3',
    'Deal Structure':
      'ORIC and Pfizer have entered into the clinical development collaboration to leverage Pfizer’s global development capabilities and expertise to enhance the clinical development program for ORIC-533, an oral small molecule inhibitor of CD73. Through the agreement, the parties plan to potentially advance ORIC-533 into a Phase 2 combination study with elranatamab, Pfizer’s investigational B-cell maturation antigen (BCMA) CD3-targeted bispecific antibody in development for the treatment of multiple myeloma. ORIC will maintain full economic ownership and control of ORIC-533. In conjunction with the investment, Jeff Settleman, Ph.D., Chief Scientific Officer, Oncology Research & Development, Pfizer, will join ORIC’s Scientific Advisory Board.',
    'FW Link1':
      'ORIC Pharmaceuticals Announces Clinical Development Collaboration with Pfizer for ORIC-533 in Multiple Myeloma and Concurrent $25 Million Equity Investment by Pfizer;;https://firstwordpharma.com/story/5689220/'
  },
  {
    'Date Announced': '19-Dec-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'XtalPi',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Experimental Drug Development Centre',
    'Country (Target Company/Partner)': 'Singapore',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial intelligence, automation',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Non-small-cell lung cancer',
    'Deal Structure':
      'In this collaboration, XtalPi joins forces with EDDC to apply its AI technology, automation platform, and expert domain knowledge to discover promising candidates against a NSCLC target chosen by EDDC. XtalPi will leverage its quantum physics and AI-driven platform to generate and screen through millions of molecules in de novo drug design. The predicted top-performing molecules with desirable drug properties will be validated and optimized in its robotics-powered synthesis and testing lab through iterations. EDDC will offer valuable insight and data on the novel NSCLC target, further accelerating the drug discovery process and propelling the program towards development.',
    'FW Link1':
      'XtalPi and EDDC collaborate in AI-empowered drug discovery program for Non-Small Cell Lung Cancer;;https://firstwordpharma.com/story/5688981/'
  },
  {
    'Date Announced': '22-Dec-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'InSitu Biologics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Mayo Clinic',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Prolonged-release drug delivery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'InSitu Biologics, Inc. has entered into an agreement with the Mayo Foundation for Medical Education and Research to further develop its prolonged-release drug delivery technology for anti-cancer therapeutics. InSitu Biologics has developed an industry-leading platform technology for loading large doses of a variety of medications into a localized delivery matrix. Once injected, medication elutes from the multi-phase matrix over an extended period. The collaboration with Mayo is both a research and commercial endeavor, and will accelerate InSitu Biologics’ work in adapting this technology for anti-cancer therapeutics. This partnership will encompass traditional cancer medications as well as newly developed novel agents. Mayo now holds a financial interest in InSitu Biologics and its platform technology.',
    'FW Link1':
      'InSitu Biologics Announces License Agreement With Mayo Clinic For Co-Development of Anti-Cancer Therapeutics;;https://firstwordpharma.com/story/5689572/'
  },
  {
    'Date Announced': '26-Dec-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Hikma',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Junshi Biosciences',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Middle East and North Africa',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibody',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'toripalimab',
    'Indication(s)': 'Cancer',
    'Target(s)': 'PD-1',
    'Deal Structure':
      'Under the terms of the agreement, Hikma has an exclusive license to develop and commercialize toripalimab injection in all its MENA markets. In addition, Hikma has the right of first negotiation for the future commercialization of three under development drugs in MENA.',
    'FW Link1':
      'Hikma and Junshi Biosciences sign exclusive licensing agreement for cancer treatment drug toripalimab for the Middle East and North Africa region;;https://firstwordpharma.com/story/5690338/'
  },
  {
    'Date Announced': '27-Dec-2022',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Fosun Pharmaceutical',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Henlius Biotech',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'United States',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibody',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Hansizhuang',
    'Indication(s)': 'Small-cell lung cancer',
    'Target(s)': 'PD-1',
    'Deal Structure':
      'According to the terms of the agreement, Fosun Pharma will have the right to commercialise HANSIZHUANG upon approval in the US, and Henlius will retain responsibility for development, manufacturing, and supply. Henlius will receive RMB 1 billion as upfront payment and milestone payments and is entitled to receive royalties in the partnered territory.',
    'FW Link1':
      'Henlius and Fosun Pharma Entered into an Exclusive License Agreement for HANSIZHUANG in the US;;https://firstwordpharma.com/story/5690690/'
  },
  {
    'Date Announced': '26-Dec-2022',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Antengene',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Merck & Co.',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecule, monoclonal antibody',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'ATG-037, Keytruda',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'CD73, PD-1',
    'Deal Structure':
      'Antengene Corporation Limited ("Antengene" SEHK: 6996.HK) announced it has entered into a global clinical collaboration with MSD (Merck & Co., Inc., Rahway, NJ, USA) on a multicenter, open-label, Phase I dose-finding study of ATG-037 as a monotherapy and in combination with MSD\'s anti-PD-1 therapy, KEYTRUDA (pembrolizumab), in patients with locally advanced or metastatic solid tumors (the STAMINA-001 study). Under the terms of the Agreement, the study will be conducted by Antengene, and MSD will provide KEYTRUDA for the combination portions of the trial.',
    'FW Link1':
      'Antengene Enters into a Global Clinical Collaboration with MSD to Evaluate ATG-037 (CD73 Inhibitor) in Combination with KEYTRUDA (pembrolizumab);;https://firstwordpharma.com/story/5690572/'
  },
  {
    'Date Announced': '2-Jan-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Orion',
    'Country (Acquiring Company/Partner)': 'Finland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Alligator Bioscience',
    'Country (Target Company/Partner)': 'Sweden',
    'Region (Target Company/Partner)': 'Europe',
    'Upfront Value ($m)': '1',
    'Total Deal Value ($m)': '508',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Bispecific antibodies',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Under the initial agreement signed in 2021, Alligator employs its proprietary phage display libraries and RUBY bispecific platform to develop immuno-oncology product candidates based on design criteria identified by Orion. Alligator remains eligible for development, approval and sales milestone payments of up to €469 million across the three potential programs, in addition to royalties if Orion exercises its options to continue development and commercialization of the resulting product candidates. The initiation of the second program provides Alligator Bioscience with an upfront payment of €1.25 million, including the fee for its validated binders being made available, with an additional €500,000 payment being due to Alligator Bioscience when the technical feasibility of the bispecific antibody has been demonstrated in relation to the validated binders.',
    'FW Link1':
      'Alligator Bioscience and Orion Corporation announce the initiation of the second program of their Immuno-oncology Research Collaboration and License Agreement;;https://firstwordpharma.com/story/5691945/',
    'FW Link2': 'Initial agreement;;https://firstwordpharma.com/story/5378105'
  },
  {
    'Date Announced': '4-Jan-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Inhibrx',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'NorthStar Medical Radioisotopes',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Radiopharmaceuticals',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Under this agreement with Inhibrx, NorthStar will support the development of a prespecified number of Inhibrx’s novel biologic products by providing the Ac-225 supply and access to its integrated radiopharmaceutical contract development and manufacturing organization (CDMO) services. NorthStar will also prepare Inhibrx patient doses for clinical studies and, upon approval, may manufacture and supply radionuclides for Inhibrx’s commercial use.',
    'FW Link1':
      'NorthStar Medical Radioisotopes and Inhibrx Enter into Collaboration Agreement for the Development and Production of Radiopharmaceutical Candidates;;https://firstwordpharma.com/story/5692858/'
  },
  {
    'Date Announced': '4-Jan-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Invenio Imaging',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'J&J',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial intelligence, imaging',
    'Lead Asset(s)': 'NIO Lung Cancer Reveal',
    'Indication(s)': 'Lung cancer',
    'Deal Structure':
      'Invenio Imaging, Inc., a leader in intraoperative fresh tissue imaging and artificial intelligence, announced that it has entered into a research collaboration agreement with the Lung Cancer Initiative at Johnson & Johnson to develop and validate the NIO Lung Cancer Reveal image analysis algorithm to assist physicians in the evaluation of bronchoscopic lung biopsies.',
    'FW Link1':
      'Invenio Imaging Announces Clinical Research Collaboration Agreement;;https://www.firstwordhealthtech.com/story/5692801/'
  },
  {
    'Date Announced': '4-Jan-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'TAE Life Sciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'AIVITA Biomedical',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Boron neutron capture therapy',
    'Indication(s)': 'Glioblastoma',
    'Deal Structure':
      'Under the terms of the partnership, AIVITA will provide TICs isolated and expanded from glioblastoma bulk tumors in sufficient quantities as cryopreserved live cell lines to be used by TAE Life Sciences for therapeutic target identification. Among the tumor targets to be identified are cancer biomarkers and biomarkers discovered through genomic analysis that may be novel or unique to an individual patient.',
    'FW Link1':
      'TAE Life Sciences and AIVITA Biomedical Announce Strategic Partnership to Advance Development of Target Boron Drugs for Glioblastoma;;https://firstwordpharma.com/story/5692684/'
  },
  {
    'Date Announced': '5-Jan-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Xencor',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Caris Life Sciences',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Total Deal Value ($m)': '187',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Bispecific antibodies, multi-specific antibodies',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Building upon the initial August 2022 agreement, this expanded collaboration will increase the number of targets and cancer types that Caris will interrogate with its proprietary, multi-omics discovery engine platform, Caris Discovery. Novel targets and their accompanying validation packages will serve as the substrate for drug candidate development through Xencor's XmAb platform. Under the terms of the new agreement, Xencor will receive exclusive worldwide rights to research, develop and commercialize products directed to up to three targets discovered under the collaboration. Caris will receive an upfront payment and will be eligible to receive up to $187 million in development and commercial milestone payments. Caris will also be eligible to receive royalties on net sales of commercialized products resulting from the collaboration as well as future rights for molecular profiling and companion diagnostics.",
    'FW Link1':
      'Caris Life Sciences and Xencor Expand Target Discovery Collaboration for Novel XmAb Antibody Drug Candidates;;https://firstwordpharma.com/story/5693337/'
  },
  {
    'Date Announced': '5-Jan-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'C2i Genomics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'AstraZeneca',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Diagnostics, whole-genome sequencing, artificial intelligence',
    'Indication(s)': 'Solid cancers',
    'Deal Structure':
      'C2i Genomics, a cancer intelligence company, announced an extension of a collaboration with AstraZeneca. The two companies have collaborated to evaluate the potential of whole-genome minimal residual disease (MRD) testing across solid cancers, with the goal of enhancing oncology treatment and supporting clinical trial recruitment and monitoring. C2i genomics has completed AstraZeneca’s BeyondBio Innovation Hub’s program for Israeli start-ups. Using artificially generated samples to reproduce varying levels of circulating tumor DNA (ctDNA) expected to be found in tumors, this collaboration provided early evidence that the C2i assay can sensitively detect ctDNA (down to 0.002% allelic frequency). Building on this work, AstraZeneca will further evaluate and validate the C2i Genomics platform across a panel of patient-derived samples using their in-house sequencing capabilities and expertise.',
    'FW Link1':
      'C2i Genomics Announces Successful Evaluation of Whole Genome-Based Residual Disease Test Across Multiple Solid Cancer models;;https://www.firstwordhealthtech.com/story/5693579/'
  },
  {
    'Date Announced': '4-Jan-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Adcendo',
    'Country (Acquiring Company/Partner)': 'Denmark',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Duality Biologics',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Indication(s)': 'Mesenchymal cancers',
    'Target(s)': 'uPARAP',
    'Deal Structure':
      "Under the terms of this agreement, Adcendo will be granted the license of Duality's proprietary DITAC (Duality Immune Toxin Antibody Conjugates) platform for its lead uPARAP-ADC program in mesenchymal cancers. Both parties are discussing to expand the license to cover additional targets selected by Adcendo. Duality to receive upfront payment, development, regulatory and commercial milestones, and tiered royalties.",
    'FW Link1':
      'Duality Biologics Announces License Agreement of its Next-generation ADC platform with Adcendo;;https://firstwordpharma.com/story/5693077/'
  },
  {
    'Date Announced': '5-Jan-2023',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Ensoma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Twelve Bio',
    'Country (Target Company/Partner)': 'Denmark',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Gene editing, CRISPR-Cas',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'The company also announced it has entered into a definitive agreement to acquire Twelve Bio ApS, a gene editing company pioneering the therapeutic application of next-generation CRISPR-Cas technology.',
    'FW Link1':
      'Ensoma Announces $85 Million Financing and Agreement to Acquire Twelve Bio to Advance Portfolio of In Vivo Engineered Cellular Medicines;;https://firstwordpharma.com/story/5693199/'
  },
  {
    'Date Announced': '5-Jan-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Nouscom',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Merck & Co.',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immunotherapy',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'NOUS-209, Keytruda',
    'Indication(s)': 'Colorectal cancer',
    'Target(s)': 'PD-1',
    'Deal Structure':
      "Nouscom, a clinical stage immuno-oncology company developing off-the-shelf and personalized immunotherapies, announced that it has entered into a clinical trial collaboration and supply agreement with MSD (Merck & Co., Inc., Rahway, NJ, USA), to evaluate Nouscom's wholly-owned lead candidate, NOUS-209, in combination with MSD's anti-PD-1 therapy KEYTRUDA (pembrolizumab) versus KEYTRUDA alone in randomized Phase 2 trials in patients with Mismatch Repair/Microsatellite Instable High (dMMR/MSI-H) Metastatic Colorectal Cancer (CRC). Under the terms of the agreement, MSD will supply KEYTRUDA. Nouscom retains all worldwide commercial rights to NOUS-209.",
    'FW Link1':
      'Nouscom Announces Clinical Trial Collaboration and Supply Agreement with MSD to Evaluate NOUS-209 in combination with KEYTRUDA (pembrolizumab) in a Phase 2 Randomized Trials in dMMR/MSI-High Metastatic Colorectal Cancer;;https://firstwordpharma.com/story/5693092/'
  },
  {
    'Date Announced': '5-Jan-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Triumvira Immunologics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Merck & Co.',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Autologous cell therapy, immunotherapy',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'TAC01-HER2, Keytruda',
    'Indication(s)': 'HER2-positive solid tumours',
    'Target(s)': 'HER2, PD-1',
    'Deal Structure':
      "Triumvira Immunologics announced a clinical trial collaboration and supply agreement with Merck (known as MSD outside the U.S. and Canada). Triumvira's ongoing TACTIC-2 trial will evaluate the use of its novel autologous cell therapy TAC01-HER2 as a monotherapy but also in combination with Merck's anti-PD-1 therapy KEYTRUDA (pembrolizumab) for the treatment of HER2-positive solid tumors. Merck will supply Triumvira with KEYTRUDA for the trial and the two companies will form a Joint Development Committee to review the clinical trial results.",
    'FW Link1':
      'Triumvira Immunologics Establishes Collaboration with Merck to Evaluate TAC01-HER2 Cell Therapy in Combination with KEYTRUDA (pembrolizumab) in Patients with HER2-positive Solid Tumors;;https://firstwordpharma.com/story/5693404/'
  },
  {
    'Date Announced': '5-Jan-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Simnova',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Orna Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Greater China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'in situ CAR therapy, circular RNA, lipid nanoparticles',
    'Lead Asset(s)': 'ORN-101',
    'Indication(s)': 'Cancer',
    'Target(s)': 'CD19',
    'Deal Structure':
      "Under the terms of the agreement, Simnova will gain access in greater China to certain programs built on Orna's breakthrough isCAR technology, including Orna's lead anti-CD19 in situ CAR (isCAR) program, ORN-101. In addition, Orna will gain access to investigator-initiated clinical trials in China to accelerate the validation of select programs in patients. Orna will receive an upfront payment from Simnova and be eligible for development, regulatory, and sales milestones as well as royalties on any approved products derived from the collaboration.",
    'FW Link1':
      "Simnova and Orna Therapeutics Collaborate to Advance Orna's Next Generation Circular RNA Technology in China;;https://firstwordpharma.com/story/5693351/"
  },
  {
    'Date Announced': '5-Jan-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Sensei Biotherapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Regeneron',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies, immunotherapy',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'SNS-101, Libtayo',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'VISTA, PD-1',
    'Deal Structure':
      'The supply agreement supports the evaluation of SNS-101, a conditionally active VISTA-blocking antibody, in combination with Libtayo in a Phase 1/2 trial in solid tumors. Under the terms of the agreement, Sensei will sponsor and fund the planned clinical trial and Regeneron will provide Libtayo. Sensei will maintain global development and commercial rights to SNS-101. Regeneron develops and commercializes Libtayo globally.',
    'FW Link1':
      'Sensei Biotherapeutics Announces Clinical Supply Agreement with Regeneron for Phase 1/2 Clinical Trial Evaluating SNS-101, a Conditionally Active VISTA-blocking Antibody, in Combination with Libtayo (cemiplimab) in Solid Tumors;;https://firstwordpharma.com/story/5693418/'
  },
  {
    'Date Announced': '6-Jan-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'BioNTech',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'UK Government',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'United Kingdom',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Personalised mRNA immunotherapies, vaccines',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'As part of the MoU, the Company and the UK Government plan to accelerate trial site and patient recruitment for clinical candidates of BioNTech’s pipeline for personalized mRNA cancer immunotherapies and infectious disease vaccines. To achieve this, the parties plan to utilize the UK’s clinical trial network, genomics and health data assets. The next steps of the collaboration will be the selection of candidates, trial sites and the set-up of a development plan with the aim of being ready to enroll the first cancer patient in the second half of 2023. BioNTech intends to design and roll out randomized trials in adjuvant or metastatic settings with registrational potential for the Company’s personalized mRNA cancer immunotherapies in the UK. BioNTech plans to invest in a UK Research and Development (“R&D”) hub in Cambridge with an expected capacity of more than 70 highly skilled scientists, the first to commence R&D by the end of the first quarter 2023. In addition, the Company will strengthen its UK footprint by setting up a regional headquarter in London to accommodate employees in global and regional supporting functions including Regulatory, Medical, Intellectual Property and Legal. As part of the MoU, BioNTech will remain the local sponsor of current and upcoming new clinical trials of its programs in the UK and will design the clinical trial protocols.',
    'FW Link1':
      'BioNTech Announces Strategic Partnership with UK Government to Provide up to 10,000 Patients with Personalized mRNA Cancer Immunotherapies by 2030;;https://firstwordpharma.com/story/5693816/'
  },
  {
    'Date Announced': '6-Jan-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Regeneron',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': '2seventy bio',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy, antibodies, bispecifics',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      'To support this expanded clinical development plan Regeneron will make a $20 million equity investment in 2seventy bio at a 50% premium and another approximately $20 million in near-term pre-clinical and clinical milestones. The parties will continue sharing costs for these activities in a manner largely consistent with the existing agreement, with Regeneron covering 75% of certain preclinical costs necessary to study combinations and 100% of the costs for the arms of clinical studies that include Regeneron agents through regulatory approval. For other programs, cost-sharing will follow the existing 50/50 cost sharing agreement.',
    'FW Link1':
      '2seventy bio Announces Expanded Translational Collaboration with Regeneron to Develop New Cell Therapy-Based Combinations for Solid Tumors;;https://firstwordpharma.com/story/5693952/'
  },
  {
    'Date Announced': '9-Jan-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Prokarium',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Ginkgo Bioworks',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'RNA therapeutics, drug delivery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'In collaboration with Prokarium, Ginkgo will aim to engineer a Salmonella-based bactofection platform for the delivery of RNA payloads to treat cancer patients, building on existing capabilities in RNA therapeutics, viral-based gene therapy and bacterial therapeutics.',
    'FW Link1':
      'Prokarium and Ginkgo Bioworks Announce Partnership to Discover Multiple Targets for RNA Therapeutics and Immuno-oncology;;https://firstwordpharma.com/story/5694627/'
  },
  {
    'Date Announced': '9-Jan-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Roche',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Kronos Bio',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '20',
    'Total Deal Value ($m)': '574',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Target(s)': 'Transcription factors',
    'Deal Structure':
      'Kronos Bio will lead discovery and research activities to a defined preclinical point when Genentech will have the exclusive right to pursue further preclinical and clinical development and commercialization. Under the terms of the agreement, Kronos Bio will receive an upfront payment of $20 million and be eligible for additional payments, which could total up to $554 million, based on reaching certain milestones, including discovery, preclinical, clinical and commercial milestones, as well as tiered royalties on any potential products that are commercialized as a result of the collaboration.',
    'FW Link1':
      'Kronos Bio Announces Discovery Collaboration with Genentech to Advance Novel Therapies Against Transcriptional Targets in Oncology;;https://firstwordpharma.com/story/5694513/'
  },
  {
    'Date Announced': '9-Jan-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Sonnet BioTherapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Roche',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immune enhancer, immunotherapy',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'SON-1010, Tecentriq',
    'Indication(s)': 'Ovarian cancer',
    'Target(s)': 'PD-L1',
    'Deal Structure':
      'Sonnet and Roche have entered into a Master Clinical Trial and Supply Agreement (MCSA), along with ancillary Quality and Safety Agreements, to study the safety and efficacy of the combination of SON-1010 and atezolizumab in a platinum-resistant ovarian cancer (PROC) patient setting. Further, the companies would provide SON-1010 and atezolizumab, respectively, for use in the Phase 1b/Phase 2a safety and efficacy study.',
    'FW Link1':
      'Sonnet BioTherapeutics Announces a Collaboration with Roche for the Clinical Evaluation of SON-1010 with atezolizumab in Ovarian Cancer;;https://firstwordpharma.com/story/5694588/'
  },
  {
    'Date Announced': '9-Jan-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Context Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Lonza',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Bispecific antibody',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'CTIM-76',
    'Indication(s)': 'Claudin 6-positive cancers',
    'Target(s)': 'Claudin 6',
    'Deal Structure':
      'Under the terms of the agreement, Lonza will provide manufacturability assessment, gene and cell line construction, and process development. The drug substance will be manufactured at Lonza’s Slough (UK) site, and the drug product will be manufactured at the Stein and Visp (CH) sites. Context will leverage Lonza’s expertise in developing and manufacturing complex proteins, as well as the extensive regulatory competence and manufacturing network.',
    'FW Link1':
      'Context Therapeutics and Lonza Enter Manufacturing Agreement for Bispecific Antibody Targeting Claudin 6-Positive Cancers;;https://firstwordpharma.com/story/5694676/'
  },
  {
    'Date Announced': '9-Jan-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'AmMax',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Evopoint',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global, excl. Greater China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugate',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      'AmMax has entered into an exclusive option with Evopoint for a worldwide license, excluding Greater China, for the development and commercialization of a novel ADC for treating solid tumors. Under the exclusive option agreement, the parties have pre-negotiated licensing terms and will work together to complete the upcoming IND filing and design subsequent dose-escalating clinical studies.',
    'FW Link1':
      'AmMax Bio Enters into an Exclusive Option Agreement with Evopoint Biosciences to License a Next Generation Antibody Drug Conjugate (ADC) for the Treatment of Solid Tumors;;https://firstwordpharma.com/story/5694673/'
  },
  {
    'Date Announced': '9-Jan-2023',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'First Light Acquisition Group',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Calidi Biotherapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Oncolytic virotherapies, allogeneic stem cell-based delivery',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'CLD-101',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Calidi Biotherapeutics, Inc. (“Calidi” or the “Company”), a clinical-stage biotechnology company that is pioneering the development of allogeneic cell-based delivery of oncolytic viruses, and First Light Acquisition Group (“FLAG”) (NYSE American: FLAG), a special purpose acquisition company organized to acquire or merge with one or more businesses, announced they have entered into a definitive merger agreement to create a public company focused on developing oncolytic viral therapies with stem cell-based delivery platforms to treat a wide range of cancers with significant unmet needs. Upon closing of the transaction, anticipated to occur in the second quarter of 2023, the combined company will be named Calidi Biotherapeutics, Inc. and led by Allan Camaisa, CEO and Chairman of the Board. In addition, the combined company’s common stock intends to list on the NYSE American under the ticker “CLDI.”',
    'FW Link1':
      'Calidi Biotherapeutics and First Light Acquisition Group (FLAG) Announce Merger Agreement to Create a Publicly Listed, Clinical-Stage Biotechnology Company Utilizing Stem Cell-Based Platforms to Revolutionize Oncolytic Virotherapies;;https://firstwordpharma.com/story/5694638/'
  },
  {
    'Date Announced': '17-Jan-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'TC Biopharm',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'MD Anderson Cancer Center',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Gamma-delta T cells ',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "The collaboration brings together MD Anderson's clinical trial infrastructure and the translational research expertise of its immunotherapy platform with the clinical data of TC BioPharm's targeted gamma-delta T cell oncology pipeline. Under the terms of the three-year agreement, the research teams will work together on both preclinical and clinical studies to evaluate the potential for gamma-delta T cell therapies and to better understand the behaviors of these cells in patients. ",
    'FW Link1':
      'TC BioPharm Announces Strategic Collaboration to Advance Gamma Delta T Cells in Oncology;;https://firstwordpharma.com/story/5697288/'
  },
  {
    'Date Announced': '17-Jan-2023',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'hC Bioscience',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': '4SR Biosciences',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'tRNA-based therapies',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'hC Bioscience, a pioneer in the development of tRNA-based therapeutics, has acquired 4SR Biosciences to combine technologies, IP, and know-how to advance the development of first-in-class tRNA-based therapeutics. Their technologies will be used to discover and optimize tRNAs capable of overcoming genetic errors to treat genetic disease and cancer. ',
    'FW Link1':
      'hC Bioscience and 4SR Biosciences Join Forces to Expand Platform Development of tRNA-Based Therapies;;https://firstwordpharma.com/story/5697478/\n'
  },
  {
    'Date Announced': '13-Jan-2023',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': "Dr. Reddy's Laboratories",
    'Country (Acquiring Company/Partner)': 'India',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Pfizer',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Primcyv',
    'Indication(s)': 'Breast cancer',
    'Target(s)': 'CDK 4/6',
    'Deal Structure':
      'Dr. Reddy’s Laboratories acquired the trademark rights of the breast cancer drug Primcyv (palbociclib) from Pfizer for use in the Indian market. The first-in-class CDK 4/6 inhibitor is indicated in combination with an aromatase inhibitor for the first-line treatment of adult patients with HR+, HER2- metastatic breast cancer. Since May 2022, Dr. Reddy’s has been marketing the drug in collaboration with Pfizer. Following the trademark rights acquisition, Dr. Reddy’s will manufacture the Active Pharmaceutical Ingredient (API) and finished drug at its facilities, with the in-house product retailing in the Indian market at a reduction of 85% from the current MRP.',
    'FW Link1':
      'Dr. Reddy’s launches in-house palbociclib (PRIMCYV) to widen access to high-quality breast cancer drug;;https://firstwordpharma.com/story/5696579/'
  },
  {
    'Date Announced': '11-Jan-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Stada Arzneimittel',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Adalvo',
    'Country (Target Company/Partner)': 'Malta',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Adalvo and STADA have extended their strategic partnership by recently signing licensing deals for five additional molecules. The agreements give STADA commercial rights to the molecules within Europe. Among the active ingredients covered by recently-signed deals are several molecules for oncology and diabetes, therapeutic categories that are forecast to be among the largest and fastest-growing within the global pharma sector over the coming years. The extension to the long-standing strategic partnership between Adalvo and STADA involves a variety of delivery forms, including difficult-to-make formulations.',
    'FW Link1':
      'Adalvo and STADA extend their strategic partnership;;https://firstwordpharma.com/story/5695525/'
  },
  {
    'Date Announced': '10-Jan-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Exelixis',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Ajinomoto',
    'Country (Target Company/Partner)': 'Japan',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'As part of the license agreement, Exelixis will have the right to use the AJICAP technology to support its aim of advancing multiple ADCs with the potential for higher efficacy and lower toxicity than currently available options. Ajinomoto Co. is eligible to receive development, regulatory and commercial milestone payments as well as royalties on commercial sales.',
    'FW Link1':
      'Ajinomoto and Exelixis Enter Into a License Agreement to Discover and Develop Novel Antibody-Drug Conjugates for the Treatment of Cancer;;https://firstwordpharma.com/story/5695158/'
  },
  {
    'Date Announced': '9-Jan-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'GreenLight Biosciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'EpiVax',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'mRNA-based vaccines',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Under this collaboration, the companies will design and develop new personalized mRNA cancer vaccine candidates using GreenLight and EpiVax Therapeutics technology platforms. Together, GreenLight and EpiVax Therapeutics will leverage their respective expertise and jointly develop and commercialize potential novel personalized mRNA-based vaccine candidates for a wide range of oncology indications.',
    'FW Link1':
      'GreenLight Biosciences and Epivax Therapeutics Sign Exclusive Collaboration Agreement to Develop Personalized Cancer Vaccines;;https://firstwordpharma.com/story/5694546/'
  },
  {
    'Date Announced': '10-Jan-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Karyopharm',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Foundation Medicine',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Companion diagnostic, small molecules',
    'Lead Asset(s)': 'FoundationOne CDx, Xpovio',
    'Indication(s)': 'Endometrial cancer',
    'Target(s)': 'XPO1',
    'Deal Structure':
      'Foundation Medicine, Inc., a pioneer in molecular profiling for cancer, announced a global collaboration with Karyopharm Therapeutics Inc. to develop FoundationOne®CDx as a companion diagnostic for selinexor, which is being evaluated as a front-line maintenance therapy following systemic therapy in patients with advanced or recurrent TP53 wild-type endometrial cancer.',
    'FW Link1':
      'Foundation Medicine Announces Collaboration with Karyopharm Therapeutics to Develop FoundationOne CDx as a Companion Diagnostic for XPOVIO (selinexor);;https://firstwordpharma.com/story/5695247/'
  },
  {
    'Date Announced': '11-Jan-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Cancer Research UK',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Physiomics',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Mathematical modelling',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Physiomics plc (AIM: PYC), the consultancy using mathematical models to support the development of drug treatment regimens and personalised medicine solutions, is pleased to announce that it has entered into a further contract with its existing client Cancer Research UK. Physiomics was first retained in July 2019 by Bicycle Therapeutics and Cancer Research UK to conduct Pharmacokinetics (PK), analyses (what the body does to the drug) of clinical data arising from the Cancer Research UK-sponsored Phase I/IIa clinical trial in solid tumours with the Bicycle Therapeutics’ program, BT1718. The follow-on project announced today will be contracted directly with Cancer Research UK and represents the second such directly contracted project following the award completion last month of a contract for PKPD (pharmacodynamics, what the drug does to the body) modelling relating to the Cancer Research UK-sponsored clinical trial of ALETA-001, a CAR-T cell engager candidate for the treatment of blood cancers developed by Aleta Biotherapeutics.',
    'FW Link1':
      'Physiomics - Contract announcement;;https://www.firstwordhealthtech.com/story/5695725/'
  },
  {
    'Date Announced': '11-Jan-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Valo Therapeutics',
    'Country (Acquiring Company/Partner)': 'Finland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Univercells',
    'Country (Target Company/Partner)': 'Belgium',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Oncolytic adenovirus',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'VALO-D102',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Valo Therapeutics Oy (ValoTx), the developer of novel, adaptable immunotherapy platforms for cancer and infectious diseases, announced it has selected Exothera S.A., a full-service Contract Development and Manufacturing Organization (CDMO) specialized in gene therapy, vaccines, and oncolytic viruses, to develop a large-scale manufacturing process for clinical development of its oncolytic adenovirus, VALO-D102. Exothera will perform tech transfer, analytical development, and scale-up to initiate manufacturing of clinical material for EU Phase II clinical trials in its GMP facility in Jumet, Belgium - one of the biggest dedicated viral vector manufacturing facilities in Europe.',
    'FW Link1':
      'Valo Therapeutics selects Exothera to develop large-scale oncolytic Adenovirus manufacturing;;https://firstwordpharma.com/story/5695529/'
  },
  {
    'Date Announced': '12-Jan-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Juniper Biologics',
    'Country (Acquiring Company/Partner)': 'Singapore',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Caris Life Sciences',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Southeast Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Molecular profiling, diagnostics',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Singapore-headquartered Juniper Biologics Pte Ltd, a science-led healthcare company focused on commercialising novel therapies, has been granted distribution rights for Caris Life Sciences® (Caris)' molecular profiling services in South East Asia.",
    'FW Link1':
      'Juniper Biologics Partners With Caris Life Sciences For Molecular Profiling Distribution In South East Asia;;https://firstwordpharma.com/story/5696018/'
  },
  {
    'Date Announced': '13-Jan-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Genetik',
    'Country (Acquiring Company/Partner)': 'Singapore',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Seno Medical',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Southeast Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Imaging, diagnostics',
    'Lead Asset(s)': 'Imagio',
    'Indication(s)': 'Breast cancer',
    'Deal Structure':
      'Seno Medical has entered into an exclusive distributor agreement with Genetik, Inc., to market, sell and service the company’s groundbreaking Imagio® Opto-Acoustic/Ultrasound (OA/US) Breast Imaging System. This distributor agreement – a first for Seno – will enable Genetik to regionally distribute the Imagio® System in Singapore, where the distributor is headquartered.',
    'FW Link1':
      'Seno Medical Inks Exclusive Agreement with Genetik as Distributor for Imagio Breast Imaging System in Southeast Asia;;https://www.firstwordhealthtech.com/story/5696701/'
  },
  {
    'Date Announced': '13-Jan-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Greater Manchester Cancer Alliance',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'AstraZeneca, Qure.ai',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Artificial intelligence, diagnostics, radiology',
    'Indication(s)': 'Lung cancer',
    'Deal Structure':
      "An initiative to improve earlier diagnosis of lung cancer has been launched by AstraZeneca UK, Qure.ai and Greater Manchester Cancer Alliance today. The project – which is part of AstraZeneca’s ambition to deliver the best possible patient outcomes – will see an artificial intelligence system read 250,000 chest X-rays of people in Greater Manchester and evaluate whether the technology can support radiologists in making faster and more accurate diagnoses of lung cancer. The pilot being launched in Manchester today builds on AstraZeneca and Qure.ai's existing partnership which has existed since 2020 through the A.Catalyst Network; a global network of more than 20 AstraZeneca health innovation hubs committed to advancing cutting-edge science and building a sustainable future.",
    'FW Link1':
      'AstraZeneca UK, Qure.ai and Greater Manchester Cancer Alliance Join Forces to Battle Lung Cancer Waiting Times Using Artificial Intelligence (AI) Technology;;https://firstwordpharma.com/story/5697335/'
  },
  {
    'Date Announced': '17-Jan-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Erasmus Medical',
    'Country (Acquiring Company/Partner)': 'Netherlands',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'AIM ImmunoTech, AstraZeneca',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibody, dsRNA, immuno-modulator',
    'Lead Asset(s)': 'Imfinzi, Ampligen',
    'Indication(s)': 'Pancreatic cancer',
    'Target(s)': 'PD-L1, TLR-3',
    'Deal Structure':
      'AIM ImmunoTech Inc. announced it has entered into an external sponsored collaborative clinical research agreement with Erasmus MC and AstraZeneca. Under the agreement, Erasmus MC is planning to perform an investigator-initiated clinical study, entitled “Combining anti-PD-L1 immune checkpoint inhibitor durvalumab with TLR-3 agonist rintatolimod in patients with metastatic pancreatic ductal adenocarcinoma for therapy effect. DURIPANC Study,” in which it will use both Study Drugs provided by AstraZeneca and AIM ImmunoTech.',
    'FW Link1':
      'AIM ImmunoTech Enters into Pancreatic Cancer Clinical Research Agreements with AstraZeneca and Erasmus Medical Center;;https://firstwordpharma.com/story/5697428/'
  },
  {
    'Date Announced': '18-Jan-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Strata Oncology',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Gilead',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugate, biomarkers',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Trodelvy',
    'Indication(s)': 'Cancer',
    'Target(s)': 'Trop-2',
    'Deal Structure':
      'Strata Oncology, Inc. a next-generation precision oncology company enabling smarter and earlier cancer treatment, announced that Gilead Sciences, Inc. has agreed to collaborate on theStrata Precision Indications for Approved THerapies (Strata PATH™) trial by providing TRODELVY® (Sacituzumab govitecan-hziy) for eligible patients with cancer.',
    'FW Link1':
      'Strata Oncology Announces Addition of Gilead as Strata PATH Trial Collaborator;;https://firstwordpharma.com/story/5697892/'
  },
  {
    'Date Announced': '18-Jan-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Teon Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Merck & Co.',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immune response modifier, immunotherapy',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'TT-816, Keytruda',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'CB2, PD-1',
    'Deal Structure':
      'Teon Therapeutics (Teon) announced that it has entered into the clinical trial collaboration agreement with Merck (known as MSD outside the United States and Canada), a leader in immuno-oncology (IO). The agreement is for the combination arm of Teon’s ongoing, two-armed, open-label, dose escalation and expansion clinical study and will evaluate Teon’s oral, immune response modifier, TT-816, in combination with Merck’s anti-PD-1 therapy, KEYTRUDA® (pembrolizumab), for patients with advanced solid tumors.',
    'FW Link1':
      'Teon Therapeutics Announces Clinical Trial Collaboration With Merck to Evaluate TT-816, a Novel Oral Immune Response Modifier, in Combination with KEYTRUDA (pembrolizumab);;https://firstwordpharma.com/story/5697803/'
  },
  {
    'Date Announced': '18-Jan-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Oncology Care Partners',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'VieCure',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'United States',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Artificial intelligence, informatics, data analytics',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'VieCure announced a new strategic partnership with value-based care organization, Oncology Care Partners (“OCP”), which will assist OCP in its provision of world-class care to patients across its growing U.S. network. This collaboration will support OCP’s providers in creating personalized treatment plans using VieCure’s Halo Intelligence Platform and help OCP to advance its commitment to overall health equity for the patients that they serve.',
    'FW Link1':
      'VieCure Aligns with Oncology Care Partners to Accelerate the Adoption of Value-Based Cancer Care;;https://www.firstwordhealthtech.com/story/5697909/'
  },
  {
    'Date Announced': '18-Jan-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Freenome',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Geisinger',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics, multiomics, real-world data',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Freenome, a privately held biotech company, announced that Pennsylvania-based Geisinger is joining as a study partner for the Sanderson Study, a study for the detection of multiple cancers that pairs multiomics with real-world data. Geisinger will recruit patients across its network to participate in the study.',
    'FW Link1':
      'Freenome Partners With Geisinger For The Sanderson Study;;https://www.firstwordhealthtech.com/story/5697917/'
  },
  {
    'Date Announced': '19-Jan-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Shoreline Biosciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Editas Medicine',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Gene editing, cell therapy',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'EDIT-202',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Under the terms of the agreement, Shoreline will obtain an exclusive license to Editas Medicine’s interest in SLEEK gene editing knock-in technology for use in Shoreline’s iNK platform and for oncology in its iMACs platform, and on a non-exclusive basis for iMACs in other indications. Shoreline will also receive a non-exclusive license for the use of Editas Medicine’s engineered AsCas12a enzyme. As part of the transaction, Shoreline will acquire EDIT-202, Editas Medicine’s preclinical multiplexed edited iNK cell medicine for the potential treatment of solid tumors, as well as an additional iNK program under development and certain related manufacturing technologies. Shoreline will pay Editas Medicine an upfront payment at the close of the transaction. Additionally, Editas Medicine is eligible to receive future development and commercial milestone and royalty payments for each of the iNK programs and for future programs engineered with the gene editing technologies.',
    'FW Link1':
      'Editas Medicine and Shoreline Biosciences Enter into Definitive Agreement for Shoreline to Acquire Editas’ iNK Cell Franchise and Related Gene Editing Technologies;;https://firstwordpharma.com/story/5698154/'
  },
  {
    'Date Announced': '19-Jan-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Boehringer Ingelheim',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Foundation Medicine',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Companion diagnostic, small molecules',
    'Lead Asset(s)': 'FoundationOne CDx, BI 907828',
    'Indication(s)': 'Biliary tract cancer',
    'Target(s)': 'MDM2',
    'Deal Structure':
      'Foundation Medicine, Inc., a pioneer in molecular profiling for cancer, announced a global collaboration with Boehringer Ingelheim to develop the company’s tissue-based comprehensive genomic profiling test, FoundationOne®CDx, as a companion diagnostic for Boehringer Ingelheim’s investigational MDM2-p53 antagonist, BI 907828, in the United States, Japan and European Union.',
    'FW Link1':
      'Foundation Medicine Announces Global Collaboration with Boehringer Ingelheim to Advance Biliary Tract Cancer Care;;https://firstwordpharma.com/story/5698255/'
  },
  {
    'Date Announced': '23-Jan-2023',
    'Deal type': 'Product Acquisition',
    'Acquiring Company/Partner': 'Iovance Biotherapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Clinigen',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Upfront Value ($m)': '206',
    'Total Deal Value ($m)': '258',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immunotherapy',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Proleukin',
    'Indication(s)': 'Renal cell carcinoma, melanoma',
    'Deal Structure':
      'Clinigen Limited (‘Clinigen’) (‘the Company’), the global pharmaceutical services company, has entered into an agreement to divest Proleukin® (interleukin-2 (aldesleukin)), to Iovance Biotherapeutics, Inc. (NASDAQ: IOVA) (‘Iovance’), a late-stage biotechnology company developing novel T cell-based cancer immunotherapies (tumor infiltrating lymphocyte (TIL) and peripheral-blood lymphocyte (PBL)). Terms of the agreement include an upfront payment of £166.7 million, a £41.7 million milestone payment upon first approval of lifileucel in advanced melanoma, and double-digit Proleukin® global sales royalties from Iovance to Clinigen. The transaction is expected to close in the first quarter of 2023, subject to required regulatory approvals and clearances and other customary closing conditions.',
    'FW Link1':
      'Clinigen divests Proleukin to Iovance Biotherapeutics for £166.7 million;;https://firstwordpharma.com/story/5698807/'
  },
  {
    'Date Announced': '23-Jan-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Takeda',
    'Country (Acquiring Company/Partner)': 'Japan',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'HUTCHMED',
    'Country (Target Company/Partner)': 'Hong Kong',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '400',
    'Total Deal Value ($m)': '1,130',
    'Geographic Remit of Deal': 'Global excl. China, Hong Kong and Macau',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecule',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'fruquintinib',
    'Indication(s)': 'Colorectal cancer',
    'Target(s)': 'VEGFR-1, -2, -3',
    'Deal Structure':
      'Under the terms of the agreement, Takeda will receive an exclusive worldwide license to develop and commercialize fruquintinib from HUTCHMED Limited in all indications and territories outside of mainland China, Hong Kong and Macau. Subject to the terms of the agreement, HUTCHMED Limited will be eligible to receive up to US$1.13 billion, including US$400 million upfront on closing of the agreement, and up to US$730 million in additional potential payments relating to regulatory, development and commercial sales milestones, as well as royalties on net sales.',
    'FW Link1':
      "Takeda to pay $400 million upfront for ex-China rights to HUTCHMED's fruquintinib;;https://firstwordpharma.com/story/5698762/",
    'FW Link2':
      'Closing of agreement announced March 14, 2023;;https://firstwordpharma.com/story/5716009/'
  },
  {
    'Date Announced': '24-Jan-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Immedica',
    'Country (Acquiring Company/Partner)': 'Sweden',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Helsinn',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal':
      'Portugal, Spain, France, Switzerland, Netherlands, Belgium, Luxembourg, Liechtenstein',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Akynzeo, Aloxi',
    'Indication(s)': 'Chemotherapy-induced nausea and vomiting',
    'Target(s)': '5-HT3, NK1',
    'Deal Structure':
      'Under the licence and distribution agreement Immedica will commercialise AKYNZEO® in Portugal, Spain, France, Switzerland, the Netherlands, Belgium, Luxembourg and Liechtenstein, ALOXI® in Switzerland, Belgium, Liechtenstein.',
    'FW Link1':
      'Helsinn and Immedica enter exclusive partnership for commercialisation of cancer supportive care products in core European markets;;https://firstwordpharma.com/story/5699282/'
  },
  {
    'Date Announced': '24-Jan-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Ibex Medical Analytics',
    'Country (Acquiring Company/Partner)': 'Israel',
    'Region (Acquiring Company/Partner)': 'Middle East',
    'Target Company/Partner': 'AstraZeneca, Daiichi Sankyo,',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial intelligence, diagnostics',
    'Indication(s)': 'Breast cancer',
    'Target(s)': 'HER2',
    'Deal Structure':
      'Ibex Medical Analytics (Ibex), the leader in AI-powered cancer diagnostics, announced an agreement with AstraZeneca and Daiichi Sankyo, for the development, clinical validation and early adoption of an AI-powered product to aid pathologists with an accurate and reproducible assessment of HER2 immunohistochemistry (IHC) scoring in breast cancer patients. As part of this collaboration, Ibex will work with AstraZeneca and Daiichi Sankyo to develop and clinically validate its HER2 IHC scoring product and generate evidence that further supports adoption of the technology.',
    'FW Link1':
      'Ibex Medical Analytics Enters Collaboration with AstraZeneca and Daiichi Sankyo to Develop AI-based HER2 Scoring Product;;https://www.firstwordhealthtech.com/story/5699411/'
  },
  {
    'Date Announced': '24-Jan-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Thermo Fisher Scientific',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'AstraZeneca',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Companion diagnostic, small molecule',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Tagrisso',
    'Indication(s)': 'Non-small-cell lung cancer',
    'Target(s)': 'EGFR',
    'Deal Structure':
      'As part of a global, multiyear agreement, Thermo Fisher Scientific today announced it is working with AstraZeneca to develop a solid tissue and blood-based companion diagnostic (CDx) test for Tagrisso (osimertinib). The CDx will help identify patients with non-small cell lung cancer (NSCLC) who may be eligible for treatment with Tagrisso by identifying tumors that exhibit epidermal growth factor receptor (EGFR) alterations including exon 21 L858R mutations, exon 19 deletions or T790M mutations.',
    'FW Link1':
      'Thermo Fisher Scientific Partners with AstraZeneca to Develop Solid Tissue and Blood-Based Companion Diagnostic Test for Tagrisso;;https://firstwordpharma.com/story/5699417/'
  },
  {
    'Date Announced': '25-Jan-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'HK inno.N',
    'Country (Acquiring Company/Partner)': 'Korea, South',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'mAbxience',
    'Country (Target Company/Partner)': 'Spain',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'South Korea',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibody, biosimilar',
    'Lead Asset(s)': 'denosumab',
    'Indication(s)': 'Osteoporosis, cancer-related skeletal complications',
    'Target(s)': 'RANKL',
    'Deal Structure':
      'HK inno.N said it has signed an exclusive license agreement with Spain’s mAbxience to market the latter’s Prolia (ingredient: denosumab) biosimilar, a preventive treatment for osteoporosis and cancer-related skeletal complications, in Korea. Under the contract, HK inno.N will exclusively market two types of denosumab biosimilars – 60 mg and 120 mg — from mAbxience, and will apply for domestic approval and proceed with sales.',
    'FW Link1':
      "HK inno.N licenses in mAbxience's denosumab biosimilar;;https://firstwordpharma.com/story/5699706/"
  },
  {
    'Date Announced': '25-Jan-2023',
    'Deal type': 'Product Acquisition',
    'Acquiring Company/Partner': 'Ono Pharmaceutical',
    'Country (Acquiring Company/Partner)': 'Japan',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'KSQ Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'DNA damage response, CRISPRomics',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Under the terms of the agreement, Ono will acquire information and associated research assets related to multiple research-stage oncology programs and develop optimized therapeutic drug candidates acting on the novel oncology therapeutic targets identified by KSQ. Ono will retain worldwide exclusive development and commercialization rights to any drug candidates to be discovered by Ono. Ono will pay to KSQ up-front and potential R&D milestone payments (up to double-digit millions) with potential sales milestone (amounting up to the triple-digit millions), as well as royalties based on global net sales.',
    'FW Link1':
      "Ono picks up oncology assets tapped via KSQ's CRISPRomics tech;;https://firstwordpharma.com/story/5699724"
  },
  {
    'Date Announced': '26-Jan-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Goodbody Health',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Datar Cancer Genetics',
    'Country (Target Company/Partner)': 'India',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'United Kingdom',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Datar Cancer Genetics (Datar), a world-leading liquid biopsy company, announced its partnership with Goodbody Health Limited (Goodbody Health) to offer its innovative Circulating Tumor Cell (CTC) based multi-cancer screening solution Trucheck in the UK.',
    'FW Link1':
      'Datar Cancer Genetics Partners with Goodbody Health to Offer Innovative Cancer Screening;;https://www.firstwordhealthtech.com/story/5700160/'
  },
  {
    'Date Announced': '25-Jan-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Cell BioEngines',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Icahn School of Medicine at Mount Sinai',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immune cell-based therapies',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Under the agreement, Cell BioEngines will utilize Icahn Mount Sinai’s technology platform to develop discrete immune cell-based therapies, such as subtypes of dendritic cells (DC), NK cells, and macrophages, previously inaccessible to cancer patients. Under the terms of the agreement, Icahn Mount Sinai will be eligible to receive an undisclosed upfront cash payment as well as additional milestones based on development, regulatory, and commercial milestones.',
    'FW Link1':
      'Cell BioEngines Enters into an Exclusive Worldwide Agreement to Develop and Commercialize Potent Immune Cell States to Cure Cancer;;https://firstwordpharma.com/story/5700082/'
  },
  {
    'Date Announced': '26-Jan-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Boryung',
    'Country (Acquiring Company/Partner)': 'Korea, South',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'SN BioScience',
    'Country (Target Company/Partner)': 'Korea, South',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'South Korea',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Generics, chemotherapy, nanoparticles',
    'Lead Asset(s)': 'SNA-001',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Through this contract, Boryeong will have exclusive manufacturing and distribution rights in Korea for "SNA-001." SN BioScience will receive milestones and double-digit royalties according to the development stage. SN BioScience also signed a domestic distribution agreement with Boryeong in February 2021 for SNB-101, a polymer nanoparticle new drug, and expects a variety of cooperations in the field of anticancer drugs in the future.',
    'FW Link1':
      'SN BioScience and Boryung Announce the Licensing Agreement on Generic Version of Abraxane;;https://firstwordpharma.com/story/5700087/'
  },
  {
    'Date Announced': '26-Jan-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'J&J',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Evotec',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Total Deal Value ($m)': '350',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immune-based therapies',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Johnson & Johnson's Janssen unit entered into an agreement with Evotec to develop first-in-class targeted immune-based therapies for oncology. Under the deal, Evotec will receive research funding, an undisclosed upfront payment and potential milestones of more than $350 million, as well as tiered sales royalties. According to Evotec, the collaboration will use its integrated discovery and development capabilities, and manufacturing optimisation processes, including an option for the GMP manufacture of the immune-based therapies. The companies will work closely together during the preclinical R&D phase, with Janssen assuming full responsibility for clinical development and commercialisation.",
    'FW Link1':
      'J&J links up with Evotec on targeted immune-based cancer therapies;;https://firstwordpharma.com/story/5700092/'
  },
  {
    'Date Announced': '27-Jan-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Bridge Biotherapeutics',
    'Country (Acquiring Company/Partner)': 'Korea, South',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Pinotbio',
    'Country (Target Company/Partner)': 'Korea, South',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Indication(s)': 'Lung cancer',
    'Deal Structure':
      'Bridge Biotherapeutics and Pinotbio announced that they will jointly develop new antibody-drug conjugates (ADCs) to treat lung cancer. The companies noted that they will seek candidate medicines for new ADCs over the next two years. If Bridge Biotherapeutics provides a "target" for lung cancer, Pinotbio will cooperate by linking the target to a drug using its own platform.',
    'FW Link1':
      'Bridge Biotherapeutics, Pinotbio develop lung cancer treatment;;https://firstwordpharma.com/story/5700569/'
  },
  {
    'Date Announced': '31-Jan-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Hillstream BioPharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Dana-Farber Cancer Institute',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibodies',
    'Indication(s)': 'Cancer',
    'Target(s)': 'MUC1-C',
    'Deal Structure':
      'Dana-Farber has granted under an exclusive option agreement to Hillstream Biopharma Inc., certain of its proprietary technology which if converted to an exclusive license agreement, will allow Hillstream to develop anti-MUC1-C antibodies to selectively deliver Hillstream’s Quatramer-based lead candidate HSB-1216 targeting CSC by the induction of ferroptosis. This approach combining HSB-1216 with conjugation to MUC1-C antibodies is highly synergistic for the elimination of CSCs, which is needed for long term responses and cures.',
    'FW Link1':
      'Hillstream BioPharma Signs an Exclusive Option Agreement to Advance Next-Generation Anti-MUC1-C Agents for Drug Resistant Cancers;;https://firstwordpharma.com/story/5701216/'
  },
  {
    'Date Announced': '31-Jan-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Ymmunobio',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Nagoya University',
    'Country (Target Company/Partner)': 'Japan',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibodies',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'YB-800',
    'Indication(s)': 'Gastro-intestinal cancers, solid tumours',
    'Target(s)': 'NPTXR',
    'Deal Structure':
      'Ymmunobio AG, a preclinical stage biotech company specializing in the development of innovative treatments for cancer patients, has secured ownership to neuronal pentraxin receptor (NPTXR) antibodies, which have shown utility for developing therapeutics and for novel diagnostic tools in the treatment of several gastro-intestinal cancers, including gastric cancer. Ymmunobio signed the transfer ownership agreement in 2022 with Nagoya University for the license to NPTXR antibodies, which were invented by Professor Mitsuro Kanda, Department of Gastroenterological Surgery, Nagoya University, Japan.',
    'FW Link1':
      'Ymmunobio Signs Transfer Ownership Agreement with Nagoya University for NPTXR Antibodies;;https://firstwordpharma.com/story/5701480/'
  },
  {
    'Date Announced': '31-Jan-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Coeptis Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'University of Pittsburgh',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'SNAP-CAR T cells',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'HER2-positive cancers',
    'Target(s)': 'HER2',
    'Deal Structure':
      'Under the terms of the sponsor research agreement, the University of Pittsburgh will conduct pre-clinical research on the SNAP-CAR technology necessary to enable the filing of an Investigational New Drug (IND) application for clinical trials involving SNAP-CAR T cells targeting HER2-positive cancers.',
    'FW Link1':
      'Coeptis Therapeutics Enters into Sponsored Research Agreement with the University of Pittsburgh to Advance SNAP-CAR Development Program;;https://firstwordpharma.com/story/5701310/'
  },
  {
    'Date Announced': '30-Jan-2023',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'Mark Cuban Cost Plus Drug Company',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Witty Health',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Generics, digital health',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'The Mark Cuban Cost Plus Drug Company, PBC (Cost Plus Drugs) and OncoPower (which is owned by Witty Health, Inc), announce a collaboration to build on OncoPower’s cancer care support with the launch of a Medication Savings Suite, which leverages Cost Plus Drugs competitive pricing on generic medication to seamlessly allow the cancer patients that use OncoPower to look up pricing and order less expensive medications. This Medication Savings Suite integrates with OncoPower’s Pill Reminder tool, creating an end-to-end experience for the patient that improves treatment adherence, provides cost transparency, engages the patient at home, and is completely free, opening up affordable access to supportive care for every cancer patient.',
    'FW Link1':
      'Mark Cuban Cost Plus Drug Company and OncoPower Team Up to Bring Medication Adherence and Cost Savings to Cancer Patients;;https://www.firstwordhealthtech.com/story/5701601/'
  },
  {
    'Date Announced': '31-Jan-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'CARsgen Therapeutics',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Roche',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'AB011, Tecentriq',
    'Indication(s)': 'Gastric cancer',
    'Target(s)': 'Claudin18.2, PD-L1',
    'Deal Structure':
      "Under the terms of the agreement, Roche will be responsible for operation and conduct of the trial while both companies co-share the costs of the AB011 treatment arms in the study. As part of the clinical collaboration, CARsgen's proprietary CLDN18.2 IHC test kit, which has showed excellent specificity and sensitivity profiles, will be applied to evaluate CLDN18.2 expression in the gastric cancer patients.",
    'FW Link1':
      'CARsgen Announces Collaboration Agreement to Evaluate AB011 in Combination with PD-L1 Checkpoint Inhibitor to Treat Gastric Cancer;;https://firstwordpharma.com/story/5701242/'
  },
  {
    'Date Announced': '1-Feb-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Endevica Bio',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Orbit Discovery',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Peptide therapeutics',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cachexia',
    'Target(s)': 'G-protein coupled receptors',
    'Deal Structure':
      'Orbit Discovery Limited (Orbit), a leader in the discovery of therapeutic peptide hits, announced it has entered into a multi target Research Agreement with Endevica Bio Inc., (Endevica), a company dedicated to creating first-in-class therapeutics for cachexia caused by cancer and other chronic conditions, with its lead compound having proven safe and well-tolerated in its Phase 1 trials. The collaboration aims to accelerate Endevica’s development of advanced G-protein coupled receptor (GPCR)-targeting therapeutics on receptor sets both novel and complimentary to its lead compound. The agreement covers activities ranging from hit ID to development of cell-based assays, with an option for Endevica to further develop the peptide hits resulting from the screening activities.',
    'FW Link1':
      'Orbit Discovery and Endevica Bio Enter Multi-target Collaboration to Advance Development of Cachexia Therapeutics;;https://firstwordpharma.com/story/5701731/'
  },
  {
    'Date Announced': '1-Feb-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Federation Bio',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'University of Texas MD Anderson Cancer Center',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Microbiome-based therapy',
    'Indication(s)': 'Immunotherapy-resistant cancers',
    'Deal Structure':
      'Under the agreement, MD Anderson and Federation Bio intend to rationally design a complex consortium of bacteria derived from a donor fecal sample that has a demonstrated ability to improve immunotherapy responses in cancer patients via FMT in a clinical trial. Federation Bio will use its proprietary ACT platform to manufacture the consortium from purified cell lines, generating a therapy that comprises the full metabolic complexity of the identified microbiome and is optimized for therapeutic benefit.',
    'FW Link1':
      'MD Anderson and Federation Bio Announce Collaboration to Develop Novel Microbiome Treatment for Patients with Immunotherapy-Resistant Cancers;;https://firstwordpharma.com/story/5701869/'
  },
  {
    'Date Announced': '2-Feb-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'AnHeart Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Guardant Health',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'United States, European Union',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Companion diagnostics, small molecule',
    'Lead Asset(s)': 'Guardant360 CDx, Guardant360 TissueNext, taletrectinib',
    'Indication(s)': 'ROS1-positive non-small-cell lung cancer',
    'Target(s)': 'ROS1',
    'Deal Structure':
      'Guardant Health Inc. (Nasdaq: GH), a leading precision oncology company, and AnHeart Therapeutics, a clinical-stage global biopharmaceutical company committed to developing novel precision oncology therapeutics, announced a strategic collaboration on the development, regulatory approval and commercialization of the Guardant360 CDx and Guardant360TissueNext assays as companion diagnostics for taletrectinib in the United States and European Union. Taletrectinib is an investigational ROS1 inhibitor for the treatment of adult patients with advanced or metastatic ROS1-positive non-small cell lung cancer (NSCLC).',
    'FW Link1':
      'Guardant Health and AnHeart Therapeutics Announce Collaboration to Develop Guardant360 CDx and Guardant360 TissueNext as Companion Diagnostics for Taletrectinib in Advanced or Metastatic ROS1-Positive Non-Small Cell Lung Cancer;;https://firstwordpharma.com/story/5702446/'
  },
  {
    'Date Announced': '3-Feb-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Moderna',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Personalis',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Personalised mRNA vaccine',
    'Lead Asset(s)': 'mRNA-4157/V940',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Personalis, Inc., a leader in advanced genomics for precision oncology, and Moderna, Inc., a biotechnology company pioneering messenger RNA (mRNA) therapeutics and vaccines, announced the companies have signed a new agreement to continue using the Personalis NeXT Platform as part of upcoming clinical studies evaluating mRNA-4157/V940, an investigational personalized cancer vaccine, jointly developed by Moderna and Merck, known as MSD outside of the United States and Canada. The platform, which was also utilized in the vaccine candidate’s Phase 2b clinical study, will be used to sequence genomic information from a patient’s tumor sample to identify the unique genetic mutations that are most likely to generate a tailored antitumor response.',
    'FW Link1':
      'Personalis and Moderna Sign New Agreement to Leverage NeXT Platform in Personalized mRNA Cancer Vaccine Clinical Trials;;https://firstwordpharma.com/story/5702766/'
  },
  {
    'Date Announced': '3-Feb-2023',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'Gavi, the Vaccine Alliance',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Government of India',
    'Country (Target Company/Partner)': 'India',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'India',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Vaccines',
    'Indication(s)': 'Human papillomavirus, cervical cancer',
    'Deal Structure':
      'Gavi, the Vaccine Alliance and the Government of India have established a new three-year partnership that aims to reach millions of children in the country with life-saving vaccines. Through the strategic partnership, Gavi will provide US$ 250 million in funding to identify and vaccinate children who have not received a single routine vaccine, strengthen existing health systems and help the country introduce the human papillomavirus (HPV) vaccine and typhoid conjugate vaccine (TCV) into the national routine immunisation schedule.',
    'FW Link1':
      'Gavi and Government of India establish new partnership to protect millions of children by 2026;;https://firstwordpharma.com/story/5702777/'
  },
  {
    'Date Announced': '6-Feb-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Actinium Pharmaceuticals',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'National Cancer Institute',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody radiation conjugate',
    'Lead Asset(s)': 'Actimab-A',
    'Indication(s)': 'Acute myeloid leukaemia, haematologic malignancies',
    'Target(s)': 'CD33',
    'Deal Structure':
      "Under the terms of the CRADA, the NCI will serve as the regulatory sponsor for any clinical trials mutually approved by both parties to study Actimab-A while Actinium will be responsible for supplying and distributing Actimab-A to participating clinical sites and providing additional support as needed. The CRADA will provide broad support for the development of Actimab-A alone or in combination with chemotherapy, immunotherapy, targeted agents and other novel combinations, in line with Actinium's strategy of leveraging Actimab-A's targeted radiotherapy mechanism to elicit synergistic effects.",
    'FW Link1':
      'Actinium Signs Cooperative Research and Development Agreement with National Cancer Institute to Further Enhance Clinical and Non-clinical Development of Actimab-A for the Treatment of Acute Myeloid Leukemia and Other Hematologic Malignancies;;https://firstwordpharma.com/story/5703326/'
  },
  {
    'Date Announced': '6-Feb-2023',
    'Deal type': 'Terminated',
    'Acquiring Company/Partner': 'BMS',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Dragonfly Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'IL-12 immunotherapy',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'DF6002',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      'Dragonfly Therapeutics, Inc., a clinical stage biotechnology company developing novel immunotherapies, announced it now owns all rights to develop DF6002, its novel interleukin-12 (IL-12) cytokine investigational immunotherapy program, which is in Phase 1 clinical development, with dose escalation progressing successfully in monotherapy and in combination with nivolumab, in the U.S. and in Europe. Clinical development is currently being carried out by Bristol Myers Squibb, but will be transferred to Dragonfly over the coming weeks.',
    'FW Link1':
      'BMS hands back rights for IL-12 cytokine to Dragonfly;;https://firstwordpharma.com/story/5703339/'
  },
  {
    'Date Announced': '6-Feb-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Sensei Biotherapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'National Cancer Institute',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'SNS-101',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'VISTA',
    'Deal Structure':
      'Under the terms of the CRADA, Sensei will collaborate with the NCI Center for Immuno-Oncology Co-Directors, Jeffrey Schlom, Ph.D., and James Gulley, M.D., Ph.D., and conduct preclinical studies to assess the mechanism of action of SNS-101 in combination with novel therapeutic modalities. The goal of this collaborative effort between Sensei and the NCI is to further elucidate the role of VISTA in immune checkpoint resistance and expand the potential of SNS-101 as a combination therapy beyond anti-PD-1.',
    'FW Link1':
      'Sensei Biotherapeutics Signs Cooperative Research and Development Agreement (CRADA) with the National Cancer Institute (NCI) to Expand Development Plan for SNS-101, a Conditionally Active VISTA-blocking Antibody;;https://firstwordpharma.com/story/5703321/'
  },
  {
    'Date Announced': '6-Feb-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Chong Kun Dang',
    'Country (Acquiring Company/Partner)': 'Korea, South',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Synaffix',
    'Country (Target Company/Partner)': 'Netherlands',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "The agreement provides CKD a license for global, target-specific rights to Synaffix's proprietary ADC technology, adding a new ADC drug candidate to CKD's pipeline. CKD will have access to Synaffix's ADC technologies, comprising GlycoConnect, HydraSpace and an undisclosed linker-payload from the toxSYN platform. Under the terms of the agreement, Synaffix has received an upfront payment and is eligible to receive further milestone payments plus royalties on commercial sales. CKD will be responsible for the research, development, manufacturing and commercialization of the ADCs. Synaffix will be responsible for the manufacturing of components that are specifically related to its proprietary technologies.",
    'FW Link1':
      'Chong Kun Dang Pharm Licenses Synaffix ADC Technology, Adds Novel ADC Candidate to Oncology Pipeline;;https://firstwordpharma.com/story/5703230/'
  },
  {
    'Date Announced': '6-Feb-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Presage Biosciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Pure Biologics',
    'Country (Target Company/Partner)': 'Poland',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Biologics',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Presage Biosciences, a biotechnology company whose mission is understanding the complexity of drug response in the tumor microenvironment, announced it has entered into a new research collaboration with Poland-based biopharmaceutical company Pure Biologics to evaluate two of Pure Biologics' very early stage assets in a Phase 0 intratumoral microdosing study. Presage's CIVO platform will be used to evaluate drug mechanism of action, potential indicators of efficacy, and drug combinations.",
    'FW Link1':
      'Presage Announces Collaboration with Pure Biologics to Investigate Oncology Agents in Early Development with CIVO Technology;;https://firstwordpharma.com/story/5703345/'
  },
  {
    'Date Announced': '7-Feb-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Wellmarker Bio',
    'Country (Acquiring Company/Partner)': 'Korea, South',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Merck & Co.',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibodies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'WM-A1-3389, Keytruda',
    'Indication(s)': 'Non-small-cell lung cancer',
    'Target(s)': 'PD-1',
    'Deal Structure':
      "Under the agreement, WMBIO will sponsor the Phase 1 (or Phase 1b) clinical trial for WM-A1-3389, a novel therapeutic antibody for Non-Small Cell Lung Cancer (NSCLC) patients with low or no PD-L1 expression, in combination with KEYTRUDA (pembrolizumab), MSD's anti-PD-1 therapy. Some NSCLC patients with low or no PD-L1 expression have shown limited response to treatment with immunotherapies alone and there is a high unmet need in these patient populations. WMBIO is also planning to expand the target patient group to other solid tumor indications.",
    'FW Link1':
      'Wellmarker Bio Announces Clinical Trial Collaboration with MSD to evaluate WM-A1-3389 in combination with KEYTRUDA (pembrolizumab);;https://firstwordpharma.com/story/5703624/'
  },
  {
    'Date Announced': '8-Feb-2023',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'Novarad',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Ikonopedia',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostic imaging',
    'Indication(s)': 'Breast cancer',
    'Deal Structure':
      "Novarad Corporation announces a partnership with Ikonopedia to enhance clinical excellence with a complete breast care platform, NovaMG Pro. The solution utilizes both an efficient and easy-to-use viewer and next-generation software tracking and reporting. With Ikonopedia's modern cloud-based breast imaging reporting system, and Novarad's advanced viewing and image management technology, the Novarad-Ikonpedia partnership is a dramatic step into the future of better breast imaging.",
    'FW Link1':
      'Novarad partners with Ikonopedia to provide radiologists the next-generation in breast imaging solutions;;https://www.firstwordhealthtech.com/story/5704161/'
  },
  {
    'Date Announced': '9-Feb-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Absci',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Saint John’s Cancer Institute',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immunotherapy, artificial intelligence',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      'Through the partnership, Absci will integrate SJCI’s extensive clinically annotated molecular data assets into Absci’s target discovery platform. The novel targets will be screened and assessed for parameters important to drug development and for functional and mechanistic validation using Absci’s industry-leading AI capabilities, catalyzing and accelerating SJCI’s discovery and development of promising cancer drug targets and therapies. As a component of the partnership, Absci and SJCI may also choose to further expand the collaboration through the development of therapeutic candidates towards IND-enabling studies and clinical trials, as well as conducting early-stage clinical trials for newly discovered therapeutics at St. John’s Cancer Institute.',
    'FW Link1':
      'St. John’s Cancer Institute Partners with Absci to Expedite Development of Cancer Therapies;;https://firstwordpharma.com/story/5704843/'
  },
  {
    'Date Announced': '10-Feb-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Nykode Therapeutics',
    'Country (Acquiring Company/Partner)': 'Norway',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'GOG Foundation',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'United States',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Therapeutic vaccine',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'VB10.16',
    'Indication(s)': 'HPV16-positive cervical cancer',
    'Deal Structure':
      'Nykode Therapeutics ASA (OSE: NYKD), a clinical-stage biopharmaceutical company dedicated to the discovery and development of novel immunotherapies, announced a collaboration with the GOG Foundation, Inc. to conduct a clinical trial of VB10.16 in combination with an immune checkpoint inhibitor for the treatment of advanced cervical cancer in the U.S.',
    'FW Link1':
      'Nykode Therapeutics Announces Collaboration with The GOG Foundation, Inc. to Conduct the VB-C-04 Trial in Advanced Cervical Cancer;;https://firstwordpharma.com/story/5705036/'
  },
  {
    'Date Announced': '10-Feb-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Mythic Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Nona Biosciences',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Through the collaboration, Nona Biosciences will provide Mythic Therapeutics with access to its proprietary fully human heavy chain only antibody (HCAb) transgenic mice platform and antibody generation services to serve as input for Mythic Therapeutics' proprietary FateControl antibody engineering approach to generate next-generation ADCs for a wide range of cancers.",
    'FW Link1':
      'Nona Biosciences Enters into HCAb Based Antibody Discovery Collaboration Agreement with Mythic Therapeutics;;https://firstwordpharma.com/story/5705034/'
  },
  {
    'Date Announced': '13-Feb-2023',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'Cleveland Diagnostics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Quest Diagnostics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'United States',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'IsoPSA',
    'Indication(s)': 'Prostate cancer',
    'Deal Structure':
      'Through an agreement with Quest Diagnostics (NYSE: DGX), the nation’s leading provider of diagnostic information services, patients will be able to access IsoPSA and, once ordered by their physician, provide a blood specimen for testing at one of over 2,100 Quest Diagnostics patient service center locations nationwide later this year. With its national logistics network, Quest will transport the specimens to Cleveland Diagnostics’ laboratory for testing. The parties expect physicians will be able to begin offering the test to patients through Quest Diagnostics in the second quarter of 2023.',
    'FW Link1':
      'Cleveland Diagnostics Announces Agreement with Quest Diagnostics to Expand Patient Access to IsoPSA Prostate Cancer Testing;;https://www.firstwordhealthtech.com/story/5705555/'
  },
  {
    'Date Announced': '13-Feb-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'LegoChem',
    'Country (Acquiring Company/Partner)': 'Korea, South',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Elthera',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibody, antibody-drug conjugate',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      'LegoChem Biosciences Inc. (KOSDAQ:141080) (hereinafter “LegoChemBio”) announced on February 13, 2023 a license agreement with Elthera AG, a biotechnology company in Switzerland, to develop and commercialize a novel antibody-drug conjugate (ADC) therapy using a monoclonal antibody developed by Elthera. The licensed asset is a monoclonal antibody with a target expressed in a variety of solid tumors including pancreatic, ovarian, breast, lung, and colorectal cancer. Under the terms of the agreement, LegoChemBio will be responsible for the future development and commercialization of any products incorporating this antibody. Elthera will receive an upfront payment and is eligible to receive progress-dependent development and regulatory milestone payments as well as cumulative commercial milestone payments. Elthera will additionally receive royalties on net product sales.',
    'FW Link1':
      'LegoChemBio Signs License Agreement to Acquire First-in-class Antibody From Elthera;;https://firstwordpharma.com/story/5705496/'
  },
  {
    'Date Announced': '13-Feb-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Corbus Pharmaceuticals',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'CSPC Pharma',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '8',
    'Total Deal Value ($m)': '693',
    'Geographic Remit of Deal':
      'United States, Canada, European Union, United Kingdom, Australia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugate',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'CRB-701 (SYS6002)',
    'Indication(s)': 'Nectin-4-positive solid tumours',
    'Target(s)': 'Nectin-4',
    'Deal Structure':
      'The agreement covers exclusive commercialization rights to CRB-701 in the United States, Canada, the European Union (including the European Free Trade Area), the United Kingdom, and Australia. CSPC will retain all rights to SYS6002 in the remaining global markets. Corbus and CSPC will work collaboratively to execute the clinical development of CRB-701 with Corbus responsible for the clinical development in the US and other licensed territories. CSPC will receive an upfront payment of $7.5 million. CSPC will also be eligible to receive royalties on net sales and up to $130 million in potential development and regulatory milestone payments and $555 million in potential commercial milestone payments.',
    'FW Link1':
      'Corbus Pharmaceuticals expands oncology pipeline with the addition of a clinical stage Nectin-4 targeting Antibody Drug Conjugate (ADC);;https://firstwordpharma.com/story/5705571/'
  },
  {
    'Date Announced': '13-Feb-2023',
    'Deal type': 'Product Acquisition',
    'Acquiring Company/Partner': 'Fusion Pharmaceuticals',
    'Country (Acquiring Company/Partner)': 'Canada',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'RadioMedix',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Radiopharmaceutical, small molecule',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': '225Ac-PSMA I&T',
    'Indication(s)': 'Prostate cancer',
    'Target(s)': 'PSMA',
    'Deal Structure':
      'Fusion Pharmaceuticals Inc. (Nasdaq: FUSN) ("Fusion"), a clinical-stage oncology company focused on developing next-generation targeted alpha therapies ("TATs") as precision medicines, announced the acquisition from RadioMedix, Inc. ("RadioMedix") of the investigational new drug application ("IND") for an ongoing Phase 2 clinical trial (the "TATCIST" trial) evaluating 225Ac-PSMA I&T, a small molecule targeting prostate specific membrane antigen ("PSMA") expressed on prostate cancers. Following the closing, the alpha-emitting radiopharmaceutical being evaluated in the TATCIST trial will be known as FPI-2265.',
    'FW Link1':
      'Fusion Pharmaceuticals to Acquire Phase 2 Program for 225Ac-PSMA I&T, a Radiopharmaceutical Targeting Metastatic Castrate Resistant Prostate Cancer;;https://firstwordpharma.com/story/5705772/'
  },
  {
    'Date Announced': '13-Feb-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Hillstream BioPharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Applied Biomedical Science Institute',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibodies',
    'Indication(s)': 'Drug resistant cancers',
    'Target(s)': 'HER2, HER3',
    'Deal Structure':
      'ABSI has granted under an exclusive option agreement to Hillstream, certain of its proprietary technology which if converted to an exclusive license agreement, will allow Hillstream to develop HER2 and HER3 antibodies, including multi-specific and Quatramer- based therapeutics incorporating portions of the antibodies.',
    'FW Link1':
      'Hillstream BioPharma Signs Exclusive Option Agreement with ABSI to License Technology for HER2 and HER3 to be Developed for Potential Treatments Against Drug Resistant Cancers;;https://firstwordpharma.com/story/5705576/'
  },
  {
    'Date Announced': '14-Feb-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Invasight',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Eurofins',
    'Country (Target Company/Partner)': 'Luxembourg',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Eurofins Discovery, the leading provider of products and services to the drug discovery industry, announced its collaboration with Invasight, a Switzerland-based biotech developing cancer therapies. The collaboration focuses on advancing first-in-class, small molecule, protein-protein interaction antagonists (PPIAs) against a protein that causes cancer cell invasion. Invasight is teaming with Eurofins Discovery's DiscoveryOne, an integrated drug discovery platform that provides expert comprehensive support to progress programs from concept to preclinical development and beyond. Invasight will combine its own breakthrough technology, ACINDA (Anti-cancer Cell Invasion Drug Discovery Accelerator), which closely mimics the in vivo tumor microenvironment, with Eurofins Discovery's fully integrated capabilities that include medicinal chemistry, in vitro pharmacology, ADME-PK, biomarkers and industry-leading safety pharmacology, to progress to a development candidate.",
    'FW Link1':
      'Eurofins Discovery Collaborating with Invasight on Cancer Therapies;;https://firstwordpharma.com/story/5706230/'
  },
  {
    'Date Announced': '14-Feb-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'JSR Life Sciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Novel351k',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Total Deal Value ($m)': '100',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Biosimilars',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'JSR Life Sciences, LLC (“JSR”) announced that Similis Bio (“Similis”), its business unit focused on biosimilar development, has announced plans to enter into a partnership with Novel351k to co-develop three biosimilar programs that address a wide range of hard-to-treat diseases. Under the initial agreement, Similis will be responsible for cell line development, analytics, process development, and cGMP production, leading to pharmacokinetics/pharmacodynamic clinical trials. Novel351k will leverage its experience with regulatory and clinical strategies to accelerate the development of the programs toward commercialization. The companies anticipate that the partnership will progress to late-stage development activities and commercial manufacturing, pending a successful outcome of early-stage trials. The therapeutics developed under the partnership have a current annual market value exceeding $15B. The co-development agreement has a projected value of $100MM over more than ten years, including development milestones and royalties.',
    'FW Link1':
      'Similis Bio Partners With Novel351k to Develop Multiple Biosimilar Programs Targeting Cancer and Autoimmune Diseases;;https://firstwordpharma.com/story/5706066/'
  },
  {
    'Date Announced': '14-Feb-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'TargTex',
    'Country (Acquiring Company/Partner)': 'Portugal',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'IAG',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Artificial intelligence, quantitative imaging, nanosuspension',
    'Indication(s)': 'Glioblastoma multiforme',
    'Deal Structure':
      'TargTex SA, a pre-clinical stage biopharmaceutical company focused on developing localized therapies for solid tumors and IAG, Image Analysis Group, a leading global medical imaging company are collaborating to apply Artificial Intelligence (AI) and Quantitative imaging to further the development of TargTex investigational product, a hydrogel dispersed drug nanosuspension used as adjunct-to-surgery in a single dose therapy, in patients with glioblastoma multiforme (GBM). In this collaboration, the parties will utilize AI and advanced quantitative image analysis to identify early treatment changes in GBM patients and development of predictive response markers.',
    'FW Link1':
      'TargTex to partner with IAG to apply AI to assess the effects of a novel clinical candidate in Glioblastoma Patients;;https://firstwordpharma.com/story/5706173/'
  },
  {
    'Date Announced': '13-Feb-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Cullinan Oncology',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Harbour BioMed',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '25',
    'Total Deal Value ($m)': '588',
    'Geographic Remit of Deal': 'United States',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Bispecific antibody',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'HBM7008 (CLN-418)',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'B7H4, 4-1BB',
    'Deal Structure':
      'Under the agreement, Cullinan Oncology will pay Harbour BioMed an upfront license fee of $25 million at closing for the exclusive right to develop and commercialize CLN-418/HBM7008 in the U.S. Harbour BioMed will be eligible to receive up to $148M in development and regulatory milestones plus up to an additional $415M in sales-based milestones as well as tiered royalties up to high teens on potential U.S. commercial sales.',
    'FW Link1':
      'Cullinan Oncology Licenses U.S. Rights to the First Clinical-Stage B7H4 x 4-1BB Bispecific Immune Activator from Harbour BioMed;;https://firstwordpharma.com/story/5705919/'
  },
  {
    'Date Announced': '14-Feb-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Gritstone',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'National Cancer Institute',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'T-cell therapy, vaccine',
    'Lead Asset(s)': 'SLATE-KRAS',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'KRAS',
    'Deal Structure':
      'Gritstone bio, Inc. (Nasdaq: GRTS), a clinical-stage biotechnology company working to develop the world’s most potent vaccines, announced that it has entered into a clinical trial agreement with the National Cancer Institute (NCI), an institute of the National Institutes of Health, to evaluate an autologous T cell therapy expressing a T cell receptor targeting mutated KRAS in combination with Gritstone’s KRAS-directed vaccine candidate, SLATE-KRAS, in a Phase 1 study. Under the terms of the agreement, NCI will identify patients with metastatic cancer that are eligible for adoptive cell transfer based on the presence of a G12V or G12D KRAS mutation (KRASmut). Gritstone will provide the SLATE-KRAS vaccine as requested by NCI for the trial.',
    'FW Link1':
      'Gritstone bio and the National Cancer Institute (NCI) Establish a Clinical Trial Agreement to Evaluate a Neoantigen Cell Therapy-Vaccine Combination;;https://firstwordpharma.com/story/5706054/'
  },
  {
    'Date Announced': '15-Feb-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Immix Biopharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Undisclosed',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'United States',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'CAR-T cell therapy',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'NXC-201',
    'Indication(s)': 'Multiple myeloma',
    'Target(s)': 'BCMA',
    'Deal Structure':
      'Nexcella, Inc. (“Nexcella”, “Company”), a biopharmaceutical company engaged in the discovery and development of novel cell therapies for oncology and other indications, announced that it has entered into a manufacturing agreement with a well-known United States Good Manufacturing Practice (GMP) cell therapy manufacturer that will supply a US Phase 1b/2 clinical trial of NXC-201 in relapsed/refractory multiple myeloma and AL amyloidosis.',
    'FW Link1':
      'Nexcella Enters into U.S. GMP Manufacturing Agreement to Expand Ongoing NXC-201 Phase 1b/2 Clinical Trial to the U.S.;;https://firstwordpharma.com/story/5706661/'
  },
  {
    'Date Announced': '15-Feb-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Xilis',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'University of Texas MD Anderson Cancer Center',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Drugs and cell therapies',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Under the agreement, the two organizations aim to advance drug development and discovery projects utilizing the MOS platform, which enables translational research on patient-derived micro tumors with new capabilities and at a scale not possible with current in vivo models. If successful, this platform may offer opportunities for third-party collaborations to guide the development of new drugs and cell therapies.',
    'FW Link1':
      'Xilis and MD Anderson Announce Strategic Collaboration to Advance Novel Technology and Accelerate Therapeutic Development;;https://firstwordpharma.com/story/5706565/'
  },
  {
    'Date Announced': '15-Feb-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Ipsen',
    'Country (Acquiring Company/Partner)': 'France',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Université de Montréal, IRICoR',
    'Country (Target Company/Partner)': 'Canada',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Solid tumours, haematological cancers',
    'Deal Structure':
      'Ipsen, Université de Montréal and IRICoR announce that Ipsen has exercised its option to acquire exclusive rights to a pre-clinical stage program with potential oncology applications. This license agreement is the result of a fruitful collaboration established between Ipsen, Université de Montréal and IRICoR in May 2020. Ipsen will now assume all development activities and commercialization of the drug candidate globally. Under the terms of the license agreement the Université de Montréal will receive an upfront payment and will be eligible to additional development and commercial milestones, as well as royalties on net sales.',
    'FW Link1':
      'Ipsen, Université de Montréal and IRICoR broaden existing oncology partnership, with an exclusive license agreement and two new discovery-stage programs;;https://firstwordpharma.com/story/5706541/'
  },
  {
    'Date Announced': '15-Feb-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Ipsen',
    'Country (Acquiring Company/Partner)': 'France',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Université de Montréal, IRICoR',
    'Country (Target Company/Partner)': 'Canada',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'In addition, Ipsen, Université de Montréal and IRICoR are announcing they are further expanding their strategic partnership by entering into a new multi-year collaboration and option agreement for two discovery-stage programs in oncology. Under the terms of the new collaboration and option agreement, the team of interdisciplinary drug discovery scientists at the Institute for Research in Immunology and Cancer (IRIC) at the Université de Montréal will be responsible for the identification, synthesis, and advancement of high-quality therapeutic compounds up to drug candidate stage. Ipsen will pay the Université de Montréal an upfront payment and research funding for two compounds that reach the drug candidate stage. Should Ipsen decide to exercise the option, Ipsen would assume all development activities and commercialization of drug candidates globally. Additionally, the Université de Montréal would receive further payments if key development, and commercial milestones are met, and will also be entitled to mid-single to up to low double-digit-tiered royalties for any product commercialized.',
    'FW Link1':
      'Ipsen, Université de Montréal and IRICoR broaden existing oncology partnership, with an exclusive license agreement and two new discovery-stage programs;;https://firstwordpharma.com/story/5706541/'
  },
  {
    'Date Announced': '15-Feb-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'GAIA',
    'Country (Acquiring Company/Partner)': 'Japan',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'TreeFrog Therapeutics',
    'Country (Target Company/Partner)': 'France',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Allogeneic NK-like cell therapies',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      'GAIA BioMedicine, a Japan-based clinical-stage company pioneering allogeneic NK-like cell therapies against solid tumors, and TreeFrog Therapeutics, a global biotechnology company advancing a pipeline of cell therapies based on the biomimetic C-Stem technology platform, announced they have entered into a collaboration for the expansion of donor-derived NK-like cells.',
    'FW Link1':
      'GAIA BioMedicine and TreeFrog Therapeutics Announce Collaboration for the Expansion of Allogeneic NK-like Cells Against Solid Tumors;;https://firstwordpharma.com/story/5706663/'
  },
  {
    'Date Announced': '16-Feb-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'LIfT BioSciences',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Minaris Regenerative Medicine',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Allogeneic neutrophil progenitor based cell therapy',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'N-LIfT',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'LIfT BioSciences, a pioneering pre-clinical stage company moving into first-in-human trials with an allogeneic neutrophil progenitor based cell therapy, and Minaris Regenerative Medicine GmbH, a leading global contract development and manufacturing service provider for the cell and gene therapy industry, have entered into a development and manufacturing partnership for N-LIfT, LIfT’s first-in-class neutrophil progenitor based Leukocyte Infusion Therapy for the treatment of various cancer indications, including Pancreatic Cancer, Lung Cancer and other solid tumours. Under the terms of the agreement, Minaris, in conjunction with LIfT BioSciences are confident they can develop a Good Manufacturing Practice (GMP)-compliant manufacturing process to supply LIfT’s clinical trial programmes in Europe, currently anticipated to start in Q1 2024.',
    'FW Link1':
      'LIfT BioSciences and Minaris Regenerative Medicine Enter into a Development and Manufacturing Partnership for N-LIfT Allogeneic Neutrophil Based Cell Therapy;;https://firstwordpharma.com/story/5707034/'
  },
  {
    'Date Announced': '16-Feb-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'M2GEN',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Zephyr AI',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Machine learning, artificial intelligence, real-world data',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'This collaboration leverages Zephyr AI’s industry-leading machine learning platform, and M2GEN’s unmatched real-world data set (RWD) to create a best-in-class oncology discovery product. In this partnership, M2GEN will license its expansive RWD to Zephyr AI, providing the latter with access to the richest clinicogenomic data set available in oncology today. In return, Zephyr AI will become a bioinformatics partner for M2GEN, providing the company with AI and machine learning capabilities to enhance their products and services. Together, this partnership will yield critical insights enabling both companies to discover new cancer treatments and expanded use cases, better identify new targets, and improve clinical trial design.',
    'FW Link1':
      'M2GEN and Zephyr AI Announce Partnership to Better Identify Treatments and Cures for Patients with Cancer;;https://www.firstwordhealthtech.com/story/5706948/'
  },
  {
    'Date Announced': '20-Feb-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Randox',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Roquefort Therapeutics',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global excl. Japan',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibodies, diagnostics',
    'Indication(s)': 'Cancer',
    'Target(s)': 'Midkine',
    'Deal Structure':
      "In accordance with the terms of the Randox Licence Agreement, Roquefort Therapeutics has granted an exclusive worldwide licence (excluding Japan,) for a period of ten years to utilise its Midkine antibodies in the field of medical diagnostics. Randox and Roquefort Therapeutics will engage in collaborative research programs to identify new cancer diagnostics that will be treatable with the Company's Midkine therapeutics. The commercial terms of the Randox Licencing Agreement remain confidential; however, Roquefort Therapeutics estimates that the total overall transaction value to the Company over the life of the agreement is in excess of £5 million.",
    'FW Link1':
      'Roquefort Therapeutics: Randox Licence and Royalty Agreement;;https://firstwordpharma.com/story/5707785/'
  },
  {
    'Date Announced': '20-Feb-2023',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'Korea United Pharm',
    'Country (Acquiring Company/Partner)': 'Korea, South',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Undisclosed',
    'Country (Target Company/Partner)': 'Saudi Arabia',
    'Region (Target Company/Partner)': 'Middle East',
    'Geographic Remit of Deal': 'Saudi Arabia',
    'Therapy Area(s)': 'Oncology',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Korea United Pharm announced that it signed a contract worth $4.5 million to supply nine types of medicines, including 11 cancer drugs, to Saudi pharmaceutical companies.',
    'FW Link1':
      'Korea United Pharm signs contract to supply medicines to Saudi Arabia;;https://firstwordpharma.com/story/5707829/'
  },
  {
    'Date Announced': '21-Feb-2023',
    'Deal type': 'Product Acquisition',
    'Acquiring Company/Partner': 'Mainz Biomed',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Uni Targeting Research',
    'Country (Target Company/Partner)': 'Norway',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostic',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'ColoAlert',
    'Indication(s)': 'Colorectal cancer',
    'Deal Structure':
      'Mainz Biomed N.V. (NASDAQ:MYNZ) (“Mainz Biomed” or the “Company”), a molecular genetics diagnostic company specializing in the early detection of cancer, announced the execution of its option from Uni Targeting Research AS to acquire all of the previously licensed scientific intellectual property (“IP”) for its flagship product ColoAlert, a highly efficacious, and easy-to-use detection test for colorectal cancer (“CRC”) being commercialized across Europe.',
    'FW Link1':
      'Mainz Biomed Acquires Entire Intellectual Property Portfolio for its Colorectal Cancer Diagnostic Test Program;;https://www.firstwordhealthtech.com/story/5708030/'
  },
  {
    'Date Announced': '21-Feb-2023',
    'Deal type': 'Product Acquisition',
    'Acquiring Company/Partner': 'Mainz Biomed',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'SOCPRA Sciences Sante et Humaines',
    'Country (Target Company/Partner)': 'Canada',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'mRNA biomarkers',
    'Indication(s)': 'Colorectal cancer',
    'Deal Structure':
      'Simultaneously, the Company also exercised its exclusive option with SOCPRA Sciences Sante et Humaines S.E.C. (“SOCPRA”), to outright purchase IP, including a pending patent, associated with a portfolio of novel gene expression (mRNA) biomarkers that have demonstrated ability to detect CRC lesions, including advanced adenomas (“AA”), a type of pre-cancerous polyp often attributed to this deadly disease.',
    'FW Link1':
      'Mainz Biomed Acquires Entire Intellectual Property Portfolio for its Colorectal Cancer Diagnostic Test Program;;https://www.firstwordhealthtech.com/story/5708030/'
  },
  {
    'Date Announced': '21-Feb-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'PreComb Therapeutics',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'mimiX Biotherapeutics',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Personalised drug profiling',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'The two companies announced they are partnering to accelerate the process of personalized cancer drug profiling leveraging the potential of both company’s proprietary technologies. PreComb  is pioneering personalized cancer profiling with their proprietary 3DTwin® technology. By using mimiX’s Sound-Induced Morphogenesis (SIM) technology to make efficient use of patient’s tumor cells and accelerate in vitro tissue production, PreComb aims to provide more effective and targeted treatment options for patients. Efficient use of primary tumor tissue will enable frequent use of tumor biopsies for personalized drug testing unlocking a billion-dollar market.',
    'FW Link1':
      'PreComb and mimiX biotherapeutics announce co-operation to reshape personalized cancer drug profiling;;https://firstwordpharma.com/story/5708273/'
  },
  {
    'Date Announced': '21-Feb-2023',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Kinnate Biopharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Kinnjiu Biopharma',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Total Deal Value ($m)': '24',
    'Geographic Remit of Deal':
      "People's Republic of China, Hong Kong, Macau, Taiwan",
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Kinnate Biopharma Inc. (Nasdaq: KNTE) (“Kinnate”), a clinical-stage precision oncology company, announced that it has acquired ownership stake of Kinnjiu Biopharma Inc. (“Kinnjiu”) previously held by the Series A investors using a combination of Kinnate shares and cash. Kinnate retains Kinnjiu’s cash, intellectual property and other assets, including key personnel and the legal entity structure. Kinnjiu was formed in 2021 as part of Kinnate’s joint venture to develop and commercialize its most advanced kinase inhibitors in the PRC, Hong Kong, Macau and Taiwan. In the transaction, Kinnate purchased all issued and outstanding Series A preferred shares of Kinnjiu from funds affiliated with OrbiMed and Foresite Capital Management for $24 million, using a combination of cash and shares of common stock of Kinnate. The transaction was approved by the independent directors of Kinnate.',
    'FW Link1':
      "Kinnate Biopharma Inc. Announces Acquisition of Ownership Stake from Series A Investors of the China Joint Venture, Kinnjiu Biopharma Inc., and Initiation of Phase 1 Clinical Trial for Exarafenib (KIN-2787) in People's Republic of China;;https://firstwordpharma.com/story/5708294/"
  },
  {
    'Date Announced': '22-Feb-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Ordaōs',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'FatiAbGen',
    'Country (Target Company/Partner)': 'Korea, South',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Monoclonal antibodies, artificial intelligence, machine learning',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "As part of this collaboration, Ordaōs will employ their proprietary in silico Design Engine to optimize the overall safety and efficacy of FatiAbGen's existing monoclonnal anitbodies. The Ordaōs Design Engine, which leverages the power of multitask, metalearning AI, can rapidly enhance FatiAbGen's existing antibodies indicated for a number of oncology conditions. FatiAbGen will then be responsible for developing these antibodies in pre-clinical study.",
    'FW Link1':
      'Ordaōs and FatiAbGen Partner to Optimize Therapeutics for Challenging Oncology Conditions;;https://firstwordpharma.com/story/5708536/'
  },
  {
    'Date Announced': '22-Feb-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'TransCode Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'BRAIN Biotech',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'CRISPR nuclease, drug delivery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "The objective of the JDA is to co-develop a platform technology that combines a Class 2 CRISPR nuclease, the cell-killing G-dase E, developed by BRAIN Biotech's Akribion Genomics unit with TransCode's TTX nucleic acid delivery platform for the treatment of cancer.",
    'FW Link1':
      'TransCode Therapeutics and BRAIN Biotech join forces to develop a CRISPR-derived technology platform for cancer treatment;;https://firstwordpharma.com/story/5708516/'
  },
  {
    'Date Announced': '23-Feb-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'AstraZeneca',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Keymed, Lepu Biopharma',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '63',
    'Total Deal Value ($m)': '1,163',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugate',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'CMG901',
    'Indication(s)': 'Gastric cancer, solid tumours',
    'Target(s)': 'Claudin 18.2',
    'Deal Structure':
      'AstraZeneca and KYM Biosciences Inc.i have entered into a global exclusive licence agreement for CMG901, a potential first-in-class antibody drug conjugate (ADC) targeting Claudin 18.2, a promising therapeutic target in gastric cancer. Under the licence agreement, AstraZeneca will be responsible for the research, development, manufacture and commercialisation of CMG901 globally. AstraZeneca will make an upfront payment of $63m on transaction closing and additional development and sales-related milestone payments of up to $1.1bn to KYM Biosciences as well as tiered royalties up to low double digits.',
    'FW Link1':
      'AstraZeneca adds second Claudin 18.2-targeting asset to pipeline;;https://firstwordpharma.com/story/5708761/',
    'FW Link2':
      'Completion of licence agreement announced March 30, 2023;;https://firstwordpharma.com/story/5722188/'
  },
  {
    'Date Announced': '23-Feb-2023',
    'Deal type': 'Terminated',
    'Acquiring Company/Partner': 'Roche',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Blueprint Medicines',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '775',
    'Total Deal Value ($m)': '1,702',
    'Geographic Remit of Deal': 'Global excl. Greater China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Gavreto',
    'Indication(s)': 'Non-small-cell lung cancer, thyroid cancer',
    'Target(s)': 'RET',
    'Deal Structure':
      'Blueprint Medicines Corporation (NASDAQ: BPMC) announced it will regain global commercialization and development rights to GAVRETO (pralsetinib), excluding Greater China, following a decision by Roche to discontinue the collaboration agreement between the companies for GAVRETO for strategic reasons. Under the terms of the agreement, the termination will be effective 12 months from the notification date of February 22, 2023. During the transition period, Blueprint Medicines and Roche are mutually committed to ensuring a smooth transition process with no anticipated interruptions or changes to patient access. In addition, the company will explore options to advance and simplify the continued global commercialization and development of GAVRETO.',
    'FW Link1':
      'Roche cuts ties with Blueprint on Gavreto;;https://firstwordpharma.com/story/5708794/'
  },
  {
    'Date Announced': '23-Feb-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'RORA Biologics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Case Western Reserve University',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell-based therapies',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'The agreement gives RORA-Bio exclusive international rights to T-cell memory stem-cell (RORA cells) technologies developed by Rafick Sekaly, formerly a professor at the CWRU School of Medicine, and co-director of the Center for AIDS Research Proteomics and Systems Biology Core. The exclusive worldwide license is in exchange for equity and other payments for products developed with the technology.',
    'FW Link1':
      'Case Western Reserve University completes exclusive license agreement with Atlanta-based RORA Biologics for cancer and HIV therapy technologies;;https://firstwordpharma.com/story/5708960/'
  },
  {
    'Date Announced': '23-Feb-2023',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'Xspray Pharma',
    'Country (Acquiring Company/Partner)': 'Sweden',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'EVERSANA',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'United States',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset(s)': 'Dasynoc',
    'Indication(s)': 'Chronic myeloid leukaemia, acute lymphatic leukaemia',
    'Deal Structure':
      "Xspray Pharma AB, (NASDAQ Stockholm: XSPRAY) has signed an agreement with EVERSANA to support the U.S. launch and commercialization of the company's first innovative cancer therapy Dasynoc for the treatment of chronic myeloid leukemia (CML) and acute lymphatic leukemia (ALL). While maintaining financial and strategic control, Xspray Pharma will grant EVERSANA exclusive commercialization access to support its Dasynoc launch, aimed at the second half of 2023. Under the agreement, EVERSANA will provide Xspray with a dedicated commercialization team with deep experience in the successful commercialization of cancer drugs including TKI-products. This agreement provides Xspray with access to EVERSANA's seasoned commercial leaders and allows for a short launch period while optimizing its launch budget. EVERSANA will additionally support Xspray in the areas of market access, agency services, clinical and commercial field teams, medical science liaisons, patient services and compliance.",
    'FW Link1':
      'Xspray Pharma Partners with EVERSANA for the U.S. Launch and Commercialization of its Lead Product, Dasynoc (XS004) for the Treatment of Chronic Myeloid Leukemia (CML) and Acute Lymphatic Leukemia (ALL);;https://firstwordpharma.com/story/5708768/'
  },
  {
    'Date Announced': '23-Feb-2023',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'MEI Pharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Infinity Pharmaceuticals',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules, immuno-oncology',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'eganelisib',
    'Indication(s)': 'Head and neck squamous cell carcinoma, solid tumours',
    'Target(s)': 'PI3K-gamma',
    'Deal Structure':
      'Under the terms of the merger agreement, Infinity will become a wholly owned subsidiary of MEI Pharma. Pursuant to an exchange ratio set forth in the merger agreement, the pre-merger MEI Pharma shareholders are expected to own approximately 58.0% and pre-merger Infinity shareholders are expected to own approximately 42.0% of the outstanding equity of the combined company immediately following the merger.',
    'FW Link1':
      'MEI Pharma and Infinity Pharmaceuticals Announce Definitive Merger Agreement to Advance Three Promising Clinical Oncology Candidates;;https://firstwordpharma.com/story/5708816/'
  },
  {
    'Date Announced': '23-Feb-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'BullFrog AI',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'J. Craig Venter Institute',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Oncolytic virus, artificial intelligence, machine learning',
    'Indication(s)': 'Colorectal cancer',
    'Deal Structure':
      'BullFrog AI Holdings, Inc. (NASDAQ:BFRG; BFRGW) ("Bullfrog AI" or the "Company"), a digital technology company using machine learning to usher in a new era of precision medicine, announced a co-development arrangement with the J. Craig Venter Institute (JCVI), a world-leading institution in genomics research and innovation, for the design and validation of a synthetic HSV-1 (herpes simplex type 1) virus particle targeting colorectal cancer.',
    'FW Link1':
      'BullFrog AI Partners with J. Craig Venter Institute to Develop Colorectal Cancer Therapeutic;;https://firstwordpharma.com/story/5708777/'
  },
  {
    'Date Announced': '27-Feb-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Chipscreen Biosciences',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Biocytogen',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '6',
    'Total Deal Value ($m)': '86',
    'Geographic Remit of Deal': 'Greater China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Bispecific antibodies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'YH008',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'PD-1, CD40',
    'Deal Structure':
      'Biocytogen Pharmaceuticals (Beijing) Co., Ltd. ("Biocytogen", HKEX: 02315) announced that its wholly owned subsidiary, Eucure (Beijing) Biopharma Co., Ltd. (“Eucure Biopharma”), has reached an exclusive licensing agreement with Chipscreen NewWay Biosciences (“Chipscreen NewWay”), a holding subsidiary of Shenzhen Chipscreen Biosciences Co., Ltd. (“Chipscreen Biosciences”, SSE: 688321) for the clinical development and commercialization of bispecific antibody YH008 in Greater China (including Mainland China, Hong Kong, Macau and Taiwan). Eucure Biopharma retains YH008’s global rights to develop and commercialize YH008 outside Greater China. Under the agreement, Chipscreen NewWay will pay Eucure Biopharma an upfront payment of 40 million RMB, a potential development milestone payment of up to 360 million RMB, a potential sales milestone payment of up to 196 million RMB, as well as tiered royalties on net sales.',
    'FW Link1':
      'Biocytogen’s Subsidiary Eucure Biopharma and Chipscreen Biosciences’ Holding Subsidiary Chipscreen NewWay Biosciences Enter into Greater China License Agreement for Bispecific Antibody YH008;;https://firstwordpharma.com/story/5709960/'
  },
  {
    'Date Announced': '27-Feb-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Lantern Pharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'TTC Oncology',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial intelligence, small molecule',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'TTC-352',
    'Indication(s)': 'Breast cancer',
    'Target(s)': 'Oestrogen receptor',
    'Deal Structure':
      'The collaboration will focus on leveraging RADR AI insights to advance TTC Oncology’s first- and best-in-class drug candidate TTC-352 for recurrent ER+ breast cancer patients and additional patient populations potentially identified by RADR. Under the terms of the collaboration, Lantern Pharma is receiving an exclusive right to license TTC-352, including any collaboration intellectual property (IP), during an exclusive option period. Additionally, Lantern and TTC will each participate in upfront, milestone, and royalty payments in the event a third party licenses IP resulting from the collaboration. No further financial details were disclosed.',
    'FW Link1':
      'Lantern Pharma and TTC Oncology Establish AI Collaboration to Expand the Clinical Development of Drug Candidate TTC-352;;https://firstwordpharma.com/story/5710112/'
  },
  {
    'Date Announced': '28-Feb-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Celltrion',
    'Country (Acquiring Company/Partner)': 'Korea, South',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Genemedicine',
    'Country (Target Company/Partner)': 'Korea, South',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Oncolytic virus, antibody, biosimilar',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Both parties will develop cancer drugs by attaching an oncolytic virus developed by Genemedicine to the antibody Herzuma, a biosimilar of the breast and stomach cancer antibody Herceptin.',
    'FW Link1':
      'Celltrion teams up with bio venture to develop new anti-cancer drug;;https://firstwordpharma.com/story/5710698/'
  },
  {
    'Date Announced': '1-Mar-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Celanese',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Alessa Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Drug delivery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Celanese Corporation (NYSE: CE), a global specialty materials and chemical company, and Alessa Therapeutics announced their collaboration for developing a new oncology drug product leveraging Alessa’s expertise on sustained localized drug delivery and the Celanese VitalDose EVA drug delivery platform. The collaboration will focus on developing a localized and sustained-release drug delivery system for oncology patients that will limit systemic side effects and improve overall treatment.',
    'FW Link1':
      'Celanese Announces Collaboration with Alessa Therapeutics for the Advancement of Oncology Treatments;;https://firstwordpharma.com/story/5711323/'
  },
  {
    'Date Announced': '2-Mar-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Allist Pharmaceuticals',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Abbisko',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'ABK3376',
    'Indication(s)':
      'Non-small-cell lung cancer, central nervous system metastases',
    'Target(s)': 'EGFR',
    'Deal Structure':
      'Abbisko Therapeutics Co., Ltd. ("Abbisko Therapeutics" hereafter) announced that it has entered into an exclusive Out-License Agreement (the "Agreement" hereafter) with Shanghai Allist Pharmaceuticals Co., Ltd., ("Allist" hereafter) for the further development, manufacture, use, and sales of (EGFR-TKI) ABK3376 in China (Mainland China, Hong Kong, Macau, and Taiwan). Abbisko Therapeutics also grants Allist a time-limited option to expand the licensed Territory worldwide in accordance with the terms and conditions agreed upon by both parties. Under the terms of the Agreement, Abbisko Therapeutics will receive an upfront payment, potential development and commercial milestones for US$ 187.9 million, and tiered royalty payments.',
    'FW Link1':
      'Abbisko Therapeutics Announces an Out-License Agreement with Allist for ABK3376;;https://firstwordpharma.com/story/5711702/'
  },
  {
    'Date Announced': '2-Mar-2023',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Flamingo Therapeutics',
    'Country (Acquiring Company/Partner)': 'Belgium',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Dynacure',
    'Country (Target Company/Partner)': 'France',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antisense oligonucleotides',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'danvatirsen',
    'Indication(s)': 'Head and neck squamous cell carcinoma',
    'Target(s)': 'STAT3',
    'Deal Structure':
      'Flamingo Therapeutics ("Flamingo") and Dynacure, announced that the companies have signed a definitive merger agreement to combine their pipelines and expertise to create a leading oncology company.  The combined company will advance Flamingo\'s lead programs: danvatirsen in a Phase II trial PEMDA-HN in Head & Neck cancer (danvatirsen in combination with pembrolizumab) and other indications; and FTX-001 targeting MALAT-1 in cancer (IND ready).',
    'FW Link1':
      'Flamingo Therapeutics and Dynacure Announce Merger to Create Leading RNA Therapeutics Company Focused on Clinical Oncology;;https://firstwordpharma.com/story/5711596/'
  },
  {
    'Date Announced': '2-Mar-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'SiSaf',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'University of Leipzig',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Micro interfering RNAs',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Pancreatic cancer',
    'Deal Structure':
      'RNA delivery and therapeutics company SiSaf Ltd announces its collaboration with the University of Leipzig, Germany, to develop Bio-Courier targeted micro interfering RNAs (miRNA) for the treatment of cancer, with an initial focus on pancreatic cancer. The collaboration will combine SiSaf’s expertise in RNA delivery using its Bio-Courier silicon-stabilized hybrid lipid nanoparticles (sshLNPs) and the University of Leipzig’s expertise in miRNA targeting and therapeutic approaches in cancer, led by Professor Achim Aigner. SiSaf will develop miRNA Bio-Courier formulations that will be tested in pancreatic cancer models in Professor Aigner’s laboratory. Under the terms of the agreement SiSaf has an exclusive option to acquire a worldwide license to a patent by the University. Financial terms of the agreement are not disclosed.',
    'FW Link1':
      'SiSaf and the University of Leipzig Partner to Develop Bio-Courier Targeted miRNA for the Treatment of Pancreatic Cancer;;https://firstwordpharma.com/story/5711586/'
  },
  {
    'Date Announced': '6-Mar-2023',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Adaptimmune',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'TCR² Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'afami-cel',
    'Indication(s)': 'Solid tumours, synovial sarcoma',
    'Target(s)': 'MAGE-A4',
    'Deal Structure':
      'Adaptimmune Therapeutics plc (Nasdaq: ADAP) and TCR² Therapeutics Inc. (Nasdaq: TCRR), announced entry into a definitive agreement under which Adaptimmune will combine with TCR² in an all-stock transaction to create a preeminent cell therapy company focused on treating solid tumors.',
    'FW Link1':
      'Adaptimmune and TCR² Therapeutics Announce Strategic Combination to Create a Preeminent Cell Therapy Company for Solid Tumors;;https://firstwordpharma.com/story/5712570/'
  },
  {
    'Date Announced': '2-Mar-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Debiopharm',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Novo Nordisk',
    'Country (Target Company/Partner)': 'Denmark',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'FT-3171 (Debio 0432)',
    'Indication(s)': 'Cancer',
    'Target(s)': 'USP1',
    'Deal Structure':
      "Debiopharm (www.debiopharm.com), an independent Swiss-based, biopharmaceutical company aiming to develop tomorrow's standard-of-care treatments to cure cancer and infectious diseases, announced having obtained the global rights for FT-3171, a small molecule USP1 inhibitor program targeting a novel DNA damage repair (DDR) pathway from Novo Nordisk. FT-3171 was developed by Forma Therapeutics, which was acquired by Novo Nordisk in 2022, and is currently in late preclinical development. FT-3171 (Debio 0432) could potentially be deployed to combat multiple tumor types in poly ADP ribose pathway inhibitor-sensitive and resistant settings.",
    'FW Link1':
      'Debiopharm Extends Their DNA Damage Repair Footprint With New Oncology Pipeline Entry;;https://firstwordpharma.com/story/5711588/'
  },
  {
    'Date Announced': '6-Mar-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Hummingbird Bioscience',
    'Country (Acquiring Company/Partner)': 'Singapore',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Cancer Research UK',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibodies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'HMBD-001',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'HER3',
    'Deal Structure':
      'Hummingbird Bioscience, a data-driven precision biotherapeutics company discovering and developing transformative biologic medicines for hard-to-treat diseases, and Cancer Research Horizons, the innovation arm of Cancer Research UK (CRUK), announced that Hummingbird Bioscience has exercised its option to license in the first-in-human (FIH) clinical trial results of HMBD-001, a differentiated and potentially best-in-class HER3-targeting antibody.',
    'FW Link1':
      'Hummingbird Bioscience Exercises Its Option to License the Clinical Trial Results from Cancer Research UK to Support Further Development of HMBD-001;;https://firstwordpharma.com/story/5712738/'
  },
  {
    'Date Announced': '7-Mar-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Caris Life Sciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Incyte',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Precision medicine, biomarkers',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "The partnership will apply Caris' data and analytics tools, comprehensive molecular tissue and liquid profiling services, and clinical trial enrollment program capabilities across two therapeutic programs initially, with the option for Incyte to expand to four total programs. Under the terms of the agreement, Incyte will leverage Caris' data insights and analytics capabilities to discover novel biomarkers and optimize clinical positioning strategies for its oncology programs. For drug candidates developed under the collaboration, patients enrolled in Incyte-led clinical trials will undergo longitudinal testing with Caris' comprehensive tissue and liquid molecular profiling assays. Incyte will also leverage Caris' biomarker-driven patient selection for clinical trials including options to partner on developing companion diagnostics for programs in the partnership.",
    'FW Link1':
      "Caris Life Sciences and Incyte Enter Into Broad Precision Medicine Partnership to Advance Incyte's Oncology Pipeline;;https://firstwordpharma.com/story/5713189/"
  },
  {
    'Date Announced': '7-Mar-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'AstraZeneca',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Personalis',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics, personalised testing',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Personalis, Inc. (Nasdaq: PSNL), a leader in advanced genomics for precision oncology, announced it will continue its collaboration with AstraZeneca (LSE/STO/Nasdaq: AZN), a global science-led biopharmaceutical company, to explore ultra-sensitive molecular residual disease (MRD) measurement, including clinically relevant and personalized variant tracking, for clinical research and drug development.',
    'FW Link1':
      'Personalis Selected for Clinical Research After Successful Evaluation of Whole Genome-Based Molecular Residual Disease Assay;;https://www.firstwordhealthtech.com/story/5713202/'
  },
  {
    'Date Announced': '7-Mar-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Volastra Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Amgen',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global excl. China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'sovilnesib',
    'Indication(s)': 'Ovarian cancer, breast cancer, solid tumours',
    'Target(s)': 'KIF18A',
    'Deal Structure':
      'Amgen granted an exclusive worldwide license, excluding China, for its oral small-molecule KIF18A inhibitor sovilnesib to Volastra Therapeutics. Under the agreed terms, Amgen will receive an unspecified upfront mix of cash and equity, as well as downstream milestones and royalties. Sovilnesib, also known as AMG650, is currently in Phase I development for the treatment of platinum-resistant high-grade serous ovarian cancer, triple-negative breast cancer and other solid tumours with TP53 mutations.',
    'FW Link1':
      'Amgen out-licenses KIF18A inhibitor sovilnesib to Volastra;;https://firstwordpharma.com/story/5713190/'
  },
  {
    'Date Announced': '7-Mar-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Exscientia',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Charité – Universitätsmedizin Berlin',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial intelligence, precision medicine',
    'Indication(s)': 'Haematological cancer',
    'Deal Structure':
      'Under the collaboration, Charité will evaluate the potential of Exscientia’s precision medicine platform to select better treatments and improve outcomes for patients with haematological cancers. Each patient sample will be prospectively evaluated with a broad panel of marketed drugs, including drugs that may not yet be approved for the indication, to determine a comparative response profile, ex vivo. Charité will also establish a biobank of viably cryopreserved blood, bone marrow and lymph node tissues to support further technology development and preclinical research projects at both Exscientia and Charité.',
    'FW Link1':
      'Exscientia Announces Collaboration with Charité to Advance Development of Precision Medicine Platform;;https://www.firstwordhealthtech.com/story/5713227/'
  },
  {
    'Date Announced': '7-Mar-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Roche',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner':
      'Cancer Awareness Prevention and Early Detection Trust',
    'Country (Target Company/Partner)': 'India',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'India',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'Indication(s)': 'Cervical cancer',
    'Deal Structure':
      'Roche Diagnostics India has signed a Memorandum of Understanding with the Cancer Awareness Prevention and Early Detection Trust (CAPED), towards tackling cervical cancer in the country. As part of the partnership, CAPED will look to conduct about 100 awareness workshops in companies across Mumbai, Delhi, Chennai, Hyderabad, and Bangalore. These interactions would seek to educate and encourage women employees to understand the benefits of HPV DNA screening, recommend screening frequency, etc.',
    'FW Link1':
      'Roche and CAPED join hands to increase screening for cervical cancer;;https://www.firstwordhealthtech.com/story/5713824/'
  },
  {
    'Date Announced': '8-Mar-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'COTA',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Genomic Testing Cooperative',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Real-world data, genomics data, precision oncology',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'COTA, Inc., an oncology real-world data and analytics company, announced a new partnership with Genomic Testing Cooperative (GTC), a molecular testing company. The two companies are joining forces to integrate COTA’s deep clinical oncology real-world data with GTC’s comprehensive genomics testing data. This strategic and oncology-specific partnership, grounded in deep comprehensive clinical data, will support more precise, personalized cancer research particularly across early discovery and translational epidemiology functions.',
    'FW Link1':
      'In Move to Power Precision Oncology for Patients, COTA and Genomic Testing Cooperative Form New Data Partnership;;https://www.firstwordhealthtech.com/story/5713742/'
  },
  {
    'Date Announced': '8-Mar-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Tessa Therapeutics',
    'Country (Acquiring Company/Partner)': 'Singapore',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'National Cancer Institute',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Allogeneic CAR-T cell therapy',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'TT11X',
    'Indication(s)': 'Non-Hodgkin lymphoma',
    'Target(s)': 'CD30',
    'Deal Structure':
      'Under terms of the CRADA, Tessa will collaborate with the NCI’s Division of Cancer Treatment and Diagnosis (DCTD) to identify potential opportunities to expand the applicability of TT11X as a treatment of non-Hodgkin lymphoma. In this collaboration, NCI Cancer Therapy Evaluation Program (CTEP) will serve as the regulatory sponsor and conduct mutually approved clinical trials through NCI funded clinical network groups and using drug supply and other necessary support provided by Tessa.',
    'FW Link1':
      'Tessa Therapeutics Enters into Cooperative Research and Development Agreement (CRADA) with the U.S. National Cancer Institute;;https://firstwordpharma.com/story/5713695/'
  },
  {
    'Date Announced': '9-Mar-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'UCB',
    'Country (Acquiring Company/Partner)': 'Belgium',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Cancer Research UK',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibodies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'UCB6114, UCB4594',
    'Indication(s)': 'Cancer',
    'Target(s)': 'gremlin-1, HLA-G',
    'Deal Structure':
      'Under the terms of the collaboration, Cancer Research UK’s Centre for Drug Development will appoint the chief clinical and scientific investigators, and will lead the design, preparation, sponsorship and delivery of Phase 1/2 clinical trials for both UCB antibody candidates.  UCB will continue to manufacture both investigational antibody candidates, complete the ongoing UCB6114 clinical study (ONC001) and perform other supporting activities. UCB will retain exclusive rights to further develop and commercialise both assets and will receive a licence to the results of the clinical trials from Cancer Research UK in return for undisclosed success-based milestone and royalty payments. Financial details of the partnership were not disclosed.',
    'FW Link1':
      'Cancer Research UK Signs Multi-Project Collaboration With UCB To Advance Oncology Antibody Candidates;;https://firstwordpharma.com/story/5714177/'
  },
  {
    'Date Announced': '8-Mar-2023',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Sino Biopharmaceutical',
    'Country (Acquiring Company/Partner)': 'Hong Kong',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'F-star',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Total Deal Value ($m)': '161',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Bispecific antibodies',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'FS118',
    'Indication(s)':
      'Head and neck cancer, non-small cell lung cancer, diffuse large B-cell lymphoma',
    'Target(s)': 'LAG-3, PD-L1',
    'Deal Structure':
      'invoX Pharma Limited (“invoX”), a U.K.-based wholly-owned subsidiary of Sino Biopharmaceutical Limited (“Sino Biopharm”) (HKEX 1177 HK) with an advancing pipeline of innovative products, announces that it has successfully completed the acquisition of F-star Therapeutics, Inc. (“F-star”) (NASDAQ:FSTX), a clinical-stage biopharmaceutical company pioneering bispecific antibodies in immunotherapy, for an aggregate cash consideration of approximately $161 million (or $7.12 per share).',
    'FW Link1':
      'invoX Pharma Completes Acquisition of F-star Therapeutics, Inc.;;https://firstwordpharma.com/story/5714178/'
  },
  {
    'Date Announced': '9-Mar-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Servier',
    'Country (Acquiring Company/Partner)': 'France',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Qiagen',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Companion diagnostics',
    'Indication(s)': 'Acute myeloid leukaemia',
    'Target(s)': 'Mutant IDH1',
    'Deal Structure':
      "Servier, a global pharmaceutical group, announced it has entered into a strategic partnership with QIAGEN, a leading global provider of Sample to Insight solutions that enable customers to gain valuable molecular insights from samples containing the building blocks of life, to develop a companion diagnostic test that detects IDH1 mutations. This test will be for use with Servier's marketed and investigational targeted treatments in Acute Myeloid Leukemia (AML).",
    'FW Link1':
      "Servier Announces Partnership with QIAGEN to Develop New mIDH1 Companion Diagnostic Test to Support Servier's Onco-hematology Portfolio;;https://firstwordpharma.com/story/5714180/"
  },
  {
    'Date Announced': '13-Mar-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Genovac',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'North Dakota State University',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibodies',
    'Indication(s)': 'Melanoma, pancreatic cancer',
    'Target(s)': 'RAGE',
    'Deal Structure':
      'Specifically, the collaboration will explore potential therapeutic uses of RAGE-specific antibodies, with an initial focus on melanoma and pancreatic cancer. Under the research agreement, Leclerc and Vetter will perform preclinical evaluation and characterization of Genovac-generated antibodies by way of biophysical characterization (epitope definition, binding kinetics, thermodynamics) and cell-based evaluation (inhibition of RAGE function in cells). Genovac will use its advanced genetic immunization technologies and the Beacon Platform to discover high-quality, high-affinity, RAGE-specific antibodies while providing additional functional characterization and production at its laboratories in the NDSU Research & Technology Park.',
    'FW Link1':
      'Public-Private Collaboration Takes Aim at Hard-to-Target Cancers;;https://firstwordpharma.com/story/5715679/'
  },
  {
    'Date Announced': '13-Mar-2023',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Pfizer',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Seagen',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Total Deal Value ($m)': '43,000',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Adcetris, Padcev, Tivdak',
    'Indication(s)': 'Cancer',
    'Target(s)': 'CD30, Nectin-4, tissue factor',
    'Deal Structure':
      'Pfizer Inc. (NYSE: PFE) and Seagen Inc. (Nasdaq: SGEN) announced that they have entered into a definitive merger agreement under which Pfizer will acquire Seagen, a global biotechnology company that discovers, develops and commercializes transformative cancer medicines, for $229 in cash per Seagen share for a total enterprise value of $43 billion. The Boards of Directors of both companies have unanimously approved the transaction.',
    'FW Link1':
      'Pfizer to buy Seagen for $43 billion, betting on cancer drug growth;;https://firstwordpharma.com/story/5715294/'
  },
  {
    'Date Announced': '13-Mar-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Bennu Biotherapeutics',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Porton Advanced',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'T-cell therapies',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      'On March 7th, 2023, Suzhou Porton Advanced Solutions Ltd. (Porton Advanced) and Bennu Biotherapeutics (Bennubio) announced a strategic partnership to advance cell and gene therapy R&D pipelines. The partnership aims to accelerate the development of T-cell therapies for tumors and autoimmune diseases.',
    'FW Link1':
      'Porton Advanced and Bennu Biotherapeutics Announced a Strategic Partnership to Accelerate Cell Therapy Development for Solid Tumors;;https://firstwordpharma.com/story/5715441/'
  },
  {
    'Date Announced': '14-Mar-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Celltrion',
    'Country (Acquiring Company/Partner)': 'Korea, South',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Genuv',
    'Country (Target Company/Partner)': 'Korea, South',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibodies',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Under the deal, the companies will collaborate on discovering up to two antibodies using Genuv's Shine Mouse antibody discovery platform and engage in joint R&D. If Celltrion adopts Genuv's candidate substance, a milestone payment of up to KRW 33 billion ($25.3 million) per case will be made to Genuv. If Celltrion's cumulative sales reach KRW 10 trillion ($7.65 billion) after commercialisation, the sales milestone will increase to KRW 900 billion ($689 million).",
    'FW Link1':
      'Celltrion, Genuv sign agreement for antibody joint R&D;;https://firstwordpharma.com/story/5715995/'
  },
  {
    'Date Announced': '14-Mar-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Simcere',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Merck & Co.',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'SIM0235, Keytruda',
    'Indication(s)': 'Solid tumours, cutaneous T-cell lymphoma',
    'Target(s)': 'TNFR2, PD-1',
    'Deal Structure':
      "Simcere Pharmaceutical Group Limited (2096.HK) (Simcere), an innovative global biopharmaceutical company, announced that Simcere Zaiming, an innovative oncology pharmaceutical company of Simcere has entered into a clinical collaboration agreement with MSD (Merck & Co., Inc., Rahway, NJ, USA) to evaluate the combination of SIM0235, a potential first-in-class humanized anti-tumor necrosis factor receptor 2 (TNFR2) monoclonal antibody, and MSD's anti-PD-1 therapy,  KEYTRUDA (pembrolizumab), in patients with advanced solid tumors and cutaneous T-cell lymphoma (CTCL). Under the terms of the agreement, MSD will supply KEYTRUDA and collaborate with Simcere Zaiming on this study. Simcere Zaiming retains all worldwide commercial rights to SIM0235.",
    'FW Link1':
      'Simcere Zaiming Announces Clinical Collaboration to Evaluate SIM0235, a TNFR2 Monoclonal Antibody, in Combination with KEYTRUDA (pembrolizumab) in a Phase 1 Trial in Advanced Solid Tumors and Cutaneous T-cell Lymphoma;;https://firstwordpharma.com/story/5716100/'
  },
  {
    'Date Announced': '14-Mar-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Catamaran Bio',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'OmniaBio',
    'Country (Target Company/Partner)': 'Canada',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Allogeneic CAR-NK cell therapies',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      'Catamaran Bio Inc., a biotechnology company developing off-the-shelf NK cell therapies to treat cancer, and OmniaBio Inc., a contract development and manufacturing organization (CDMO) focused on cell and gene therapy, announced their partnership to develop and manufacture Catamaran Bio’s allogeneic chimeric antigen receptor (CAR)-NK cell therapies for the treatment of solid tumors.',
    'FW Link1':
      'Catamaran Bio selects OmniaBio as partner to develop and manufacture allogeneic CAR-NK cell therapies;;https://firstwordpharma.com/story/5716082/'
  },
  {
    'Date Announced': '14-Mar-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Perspective Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'PharmaLogic',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Theranostics, radiopharmaceuticals',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'VMT01, VMT-α-NET',
    'Indication(s)': 'Metastatic melanoma, neuroendocrine tumours',
    'Deal Structure':
      'PharmaLogic Holdings Corp. ("PharmaLogic" or "the Company"), a contract development and manufacturing organization and radiopharmaceutical manufacturer, announces the signature of a Master Services Agreement with Viewpoint Molecular Targeting, Inc., a wholly-owned subsidiary of Perspective Therapeutics, Inc. ("Perspective") (NYSE AMERICAN: CATX), for the development and production of theranostic candidates VMT-01 and VMT-α-NET. The radiopharmaceuticals are currently in development for the diagnosis and treatment of metastatic melanoma and neuroendocrine tumors (NETs), respectively. Under the terms of the agreement, PharmaLogic will produce and supply doses of VMT-01 and VMT-α-NET for use in Perspective\'s early-stage clinical trials. This will complement the existing radiopharmaceutical GMP capability that Perspective already has at its mid-west site.',
    'FW Link1':
      'Perspective Therapeutics and PharmaLogic announce collaboration for the development and production of theranostics for cancer care;;https://firstwordpharma.com/story/5716102/'
  },
  {
    'Date Announced': '14-Mar-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Eureka Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'National Cancer Institute',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibodies',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'mesothelin',
    'Deal Structure':
      'Eureka Therapeutics, Inc., a clinical-stage biotechnology company developing novel T-cell therapies to treat cancer, announced it has entered into a license agreement with the National Cancer Institute (NCI), part of the National Institutes of Health, to develop and commercialize a novel antibody targeting mesothelin (MSLN) in combination with Eureka’s proprietary ARTEMIS T-cell receptor platform. Eureka plans to continue preclinical development of the antibody to support an investigational new drug (IND) application with the US Food and Drug Administration (FDA).',
    'FW Link1':
      'Eureka Therapeutics Licenses Promising Antibody Targeting Mesothelin from the National Cancer Institute;;https://firstwordpharma.com/story/5715918/'
  },
  {
    'Date Announced': '14-Mar-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Neogap',
    'Country (Acquiring Company/Partner)': 'Sweden',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Simsen Diagnostics',
    'Country (Target Company/Partner)': 'Sweden',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Personalised cancer diagnostics',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "The biotechnology company Neogap and the cancer diagnostics company Simsen Diagnostics have initiated a collaboration aimed at improving personalised cancer diagnostics by examining circulating tumor DNA in the blood of patients participating in Neogap's phase I/II clinical trial. The goal is to develop more precise and effective diagnostic tools for cancer.",
    'FW Link1':
      'Neogap and Simsen Diagnostics enter collaboration to improve personalised cancer diagnostics;;https://firstwordpharma.com/story/5715969/'
  },
  {
    'Date Announced': '15-Mar-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Prelude Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'BeiGene',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset(s)': 'PRT2527, zanubrutinib',
    'Indication(s)': 'Haematologic malignancies',
    'Target(s)': 'CDK9, BTK',
    'Deal Structure':
      'Prelude Therapeutics Incorporated (Prelude) (Nasdaq: PRLD), a clinical-stage precision oncology company, announced a clinical trial collaboration with BeiGene, for future evaluation of its investigational CDK9 inhibitor, PRT2527, in combination with BeiGene’s BTK inhibitor, zanubrutinib, in hematologic malignancies. Under terms of the clinical trial collaboration agreement, BeiGene will provide zanubrutinib to Prelude, and Prelude will retain all global operational, development and commercialization rights and responsibilities for PRT2527.',
    'FW Link1':
      'Prelude Therapeutics Announces Clinical Trial Collaboration with BeiGene to Evaluate PRT2527 in Combination with Zanubrutinib in Hematologic Cancers;;https://firstwordpharma.com/story/5716560/'
  },
  {
    'Date Announced': '15-Mar-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Owlstone Medical',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Bicycle Therapeutics',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'Indication(s)': 'Cancer, lung cancer',
    'Deal Structure':
      'Under the Agreement, the Companies will investigate the potential of combining technologies and methods to develop antigen-targeted diagnostic probes that use bicyclic peptides as their targeting mechanism linked with Owlstone’s Exogenous Volatile Organic Compound (EVOC) probes. Work will initially focus on lung cancer screening as the first proof of principle for the broader opportunity by promoting selective accumulation of the probe at the tumor for increased signal and enhanced specificity.',
    'FW Link1':
      'Owlstone Medical Enters Partnership with Bicycle Therapeutics for the Development of Antigen-Targeted EVOC Probes for Early Cancer Detection;;https://firstwordpharma.com/story/5716722/'
  },
  {
    'Date Announced': '15-Mar-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'ImmunoPrecise',
    'Country (Acquiring Company/Partner)': 'Canada',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Libera Bio',
    'Country (Target Company/Partner)': 'Spain',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Antibodies, nanotechnology, drug delivery, artificial intelligence',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Talem and Libera Bio will jointly develop novel antibodies for use with MPN delivery, with the goal of offering them to larger pharma companies to conduct late-stage development and commercialization. The work launches with the investigation of two intracellular targets with very high unmet patient needs to offer options to the oncologists treating them.',
    'FW Link1':
      'ImmunoPrecise Antibodies’ Subsidiary Talem Therapeutics Announces a Multi-Target AI-Driven Antibody Discovery Collaboration with Libera Bio;;https://firstwordpharma.com/story/5716668/'
  },
  {
    'Date Announced': '23-Feb-2023',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Redx Pharma',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Jounce Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules, biologics, immunotherapy',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'RXC007',
    'Indication(s)': 'Cancer',
    'Target(s)': 'ROCK2',
    'Deal Structure':
      "The day after announcing significant job cuts, Jounce Therapeutics said Thursday that it will combine with Redx Pharma via an all share merger that is expected to close next quarter. Under the transaction, Redx shareholders will own approximately 63% of the combined entity, with Jounce investors holding the remaining 37%. The new entity will focus on the development of Redx's selective ROCK2 inhibitor RXC007, which is currently being assessed in a Phase IIa study in idiopathic pulmonary fibrosis, with top-line data expected in the first quarter of 2024. In addition, Redx is investigating RXC004 in mid-stage trials for Wnt-ligand dependent cancers, while the GI-targeted ROCK inhibitor RXC008 for fibrostenotic Crohn's disease is scheduled to enter clinical development next year.",
    'FW Link1':
      'Struggling Jounce to be swallowed by Redx;;https://firstwordpharma.com/story/5708773',
    'FW Link2':
      'Jounce dropping Redx deal in favour of Concentra takeover bid announced March 27, 2023;;https://firstwordpharma.com/story/5720650/'
  },
  {
    'Date Announced': '16-Mar-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Leucid Bio',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Great Ormond Street Hospital',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'CAR-T cell therapy',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'LEU011',
    'Indication(s)': 'Solid tumours, haematological malignancies',
    'Target(s)': 'NKG2D',
    'Deal Structure':
      'Under the terms of the agreement GOSH will work with Leucid at the state-of-the-art cleanroom facilities at the Zayed Centre for Research into Rare Disease in Children and provide quality assurance services required for manufacture of clinical product for the LEU011 first-in-man study, and potentially, other Leucid clinical programmes. Each Party will retain ownership of background IP and Leucid (subject to contractual obligations) will own all Intellectual Property Rights arising from the Manufacturing Services performed.',
    'FW Link1':
      'Leucid Bio and Great Ormond Street Hospital enter commercial agreement at new gene therapy manufacturing centre;;https://firstwordpharma.com/story/5716873/'
  },
  {
    'Date Announced': '16-Mar-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Leap Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'BeiGene',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'DKN-01, tislelizumab',
    'Indication(s)': 'Gastric cancer',
    'Target(s)': 'DKK1, PD-1',
    'Deal Structure':
      "Leap Therapeutics, Inc. (Nasdaq:LPTX), a biotechnology company focused on developing targeted and immuno-oncology therapeutics, announced that BeiGene's option under the Exclusive Option and License Agreement between Leap and BeiGene granting rights in certain Asian territories to DKN-01, Leap's anti-DKK1 monoclonal antibody, has expired in accordance with the terms of the agreement. Leap and BeiGene will continue to collaborate on the ongoing Part C of the DisTinGuish trial, a randomized controlled trial of DKN-01 in combination with BeiGene's anti-PD-1 antibody, tislelizumab, and chemotherapy in first-line gastric cancer patients, as a clinical collaboration with BeiGene supplying tislelizumab.",
    'FW Link1':
      'Leap Therapeutics Provides Update on BeiGene Option Agreement for DKN-01;;https://firstwordpharma.com/story/5717204/'
  },
  {
    'Date Announced': '16-Mar-2023',
    'Deal type': 'Terminated',
    'Acquiring Company/Partner': 'BeiGene',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Leap Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '3',
    'Total Deal Value ($m)': '132',
    'Geographic Remit of Deal': 'Asia (excl. Japan), Australia, New Zealand',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibody',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'DKN-01',
    'Indication(s)': 'Gastric cancer',
    'Target(s)': 'DKK1',
    'Deal Structure':
      "Leap Therapeutics, Inc. (Nasdaq:LPTX), a biotechnology company focused on developing targeted and immuno-oncology therapeutics, announced that BeiGene's option under the Exclusive Option and License Agreement between Leap and BeiGene granting rights in certain Asian territories to DKN-01, Leap's anti-DKK1 monoclonal antibody, has expired in accordance with the terms of the agreement. Leap and BeiGene will continue to collaborate on the ongoing Part C of the DisTinGuish trial, a randomized controlled trial of DKN-01 in combination with BeiGene's anti-PD-1 antibody, tislelizumab, and chemotherapy in first-line gastric cancer patients, as a clinical collaboration with BeiGene supplying tislelizumab.",
    'FW Link1':
      'Leap Therapeutics Provides Update on BeiGene Option Agreement for DKN-01;;https://firstwordpharma.com/story/5717204/'
  },
  {
    'Date Announced': '17-Mar-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Predictive Oncology',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Cancer Research UK',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Artificial intelligence, machine learning, small molecules',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Cancer',
    'Target(s)': 'Glutaminase',
    'Deal Structure':
      'Predictive Oncology Inc. (NASDAQ: POAI) together with Cancer Research Horizons, the innovation engine at the core of the world’s largest private funder of cancer research, Cancer Research UK (CRUK), announce that they have partnered to drive the development of oncology drugs utilizing Predictive Oncology’s PEDAL platform. The collaboration will utilize Predictive Oncology’s PEDAL technology to evaluate Cancer Research Horizons’ pre-clinical drug inhibitors of Glutaminase in order to determine which cancer types and patient populations are most likely to respond to treatment with these compounds. The agreement bridges non-profit and industry, including revenue sharing for compounds developed and offers the potential for Cancer Research Horizons to incorporate PEDAL into future drug discovery processes.',
    'FW Link1':
      'Predictive Oncology and Cancer Research Horizons have partnered to pursue development of cancer drugs;;https://firstwordpharma.com/story/5717412/'
  },
  {
    'Date Announced': '17-Mar-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Scailyte',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Turnstone Biologics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Biomarkers, artificial intelligence, tumor-infiltrating lymphocyte (TIL) therapy',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'TIDAL-01',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      "Under the terms of the agreement, Scailyte and Turnstone will collaborate with the goal of associating highly sensitive molecular biomarker patterns in TILs with clinical responses to TIDAL-01, Turnstone's lead-selected TIL therapy candidate. TIDAL-01 currently is being evaluated in two Phase 1b clinical studies in multiple indications, including melanoma, breast cancer, and colorectal cancer. Turnstone will collect TIL samples during the manufacture of patient-specific tumor-reactive TILs, and clinical samples after treatment with TIDAL-01. Scailyte will generate detailed transcript, epitope, and single-cell TCR sequencing datasets from the samples. These samples will be processed using ScaiVision for automated pattern recognition and interpretation of single-cell transcriptomics and proteomics data to identify clinically relevant biomarker signatures in solid tumors.",
    'FW Link1':
      'Scailyte Announces Collaboration With Turnstone Biologics to Identify Biomarker Signatures in Solid Tumors With TIL Therapy;;https://firstwordpharma.com/story/5717529/'
  },
  {
    'Date Announced': '20-Mar-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'BioNTech',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'OncoC4',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '200',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'ONC-392',
    'Indication(s)':
      'Solid tumours, non-small-cell lung cancer, ovarian cancer',
    'Target(s)': 'CTLA-4',
    'Deal Structure':
      "BioNTech agreed to pay $200 million upfront under an exclusive global license and collaboration agreement to develop OncoC4's next-generation anti-CTLA-4 monoclonal antibody candidate ONC-392, the companies said Monday. The deal also includes undisclosed milestone payments for OncoC4, as well as double-digit tiered royalties.",
    'FW Link1':
      'BioNTech adds anti-CTLA-4 drug to pipeline via OncoC4 deal;;https://firstwordpharma.com/story/5717814/'
  },
  {
    'Date Announced': '17-Mar-2023',
    'Deal type': 'Terminated',
    'Acquiring Company/Partner': 'Daiichi Sankyo',
    'Country (Acquiring Company/Partner)': 'Japan',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Zymeworks',
    'Country (Target Company/Partner)': 'Canada',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '2',
    'Total Deal Value ($m)': '150',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Bispecific antibodies',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Daiichi Sankyo and Zymeworks terminated a partnership they struck in 2016, the latter company disclosed in a US securities filing, without providing details about the reason for the break-up. The cross-licensing agreement focused on immuno-oncology gave Daiichi Sankyo access to Zymeworks' Azymetric and EFECT platforms to develop a bispecific antibody therapeutic. Under the deal, Zymeworks granted Daiichi Sankyo a worldwide antibody sequence pair-specific exclusive license to research, develop and market certain products. Financial terms were not provided at the time, but Zymeworks disclosed in its Securities and Exchange Commission filing that it stood to gain nearly $150 million in milestone and other payments, as well as tiered royalties ranging from the low-single digits up to 10% on product sales.",
    'FW Link1':
      'Daiichi Sankyo, Zymeworks break off bispecific antibody pact;;https://firstwordpharma.com/story/5717911/'
  },
  {
    'Date Announced': '22-Mar-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Andira',
    'Country (Acquiring Company/Partner)': 'Canada',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'University of British Columbia',
    'Country (Target Company/Partner)': 'Canada',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cannabinoids',
    'Indication(s)': 'Breast cancer',
    'Deal Structure':
      'Andira Pharmaceuticals announces it will carry out pharmacologic characterization of its lead compounds for metastatic breast cancer through a collaborative research program with the University of British Columbia (UBC), Vancouver. Leading the research program will be Dr. Karla Williams, PhD, Canada Research Chair in Oncology, Michael Smith Foundation for Health Research Scholar, and Assistant Professor at the UBC Faculty of Pharmaceutical Sciences. The effects of proprietary cannabinoid compositions will be characterized within live breast cancer tumors and metastases using a specialized research system housed in the Williams Laboratory.',
    'FW Link1':
      'Andira Advances Lead Cannabinoid Compositions for Metastatic Breast Cancer through a Collaborative Research Agreement with Canada Research Chair in Oncology Dr. Karla Williams at the University of British Columbia;;https://firstwordpharma.com/story/5719013/'
  },
  {
    'Date Announced': '22-Mar-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Takeda',
    'Country (Acquiring Company/Partner)': 'Japan',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Sino Biopharmaceutical',
    'Country (Target Company/Partner)': 'Hong Kong',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Bispecific antibodies',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Under the terms of the licence agreement, F-star will grant Takeda a worldwide, exclusive royalty-bearing licence to research, develop, and commercialize a bispecific antibody directed towards an undisclosed immuno-oncology target using F-star’s proprietary Fcab and mAb2 platforms. Takeda will be responsible for all research, development and commercialisation activities under the agreement. F-star will receive an undisclosed upfront licence fee and is eligible to receive potential future development and commercialization milestone payments, plus royalties on annual net sales.',
    'FW Link1':
      'InvoX Pharma’s F-star Announces Licence Agreement With Takeda for Second Novel Next-Generation Immuno-Oncology Bispecific Antibody;;https://firstwordpharma.com/story/5718940/'
  },
  {
    'Date Announced': '22-Mar-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Jacobio Pharma',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Merck & Co.',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'JAB-BX102, Keytruda',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'CD73, PD-1',
    'Deal Structure':
      "Jacobio Pharma (1167.HK) announced it has entered into a clinical collaboration with Merck & Co., Inc., Rahway, NJ, USA to evaluate the combination of Jacobio's CD73 monoclonal antibody JAB-BX102 in combination with Merck & Co., Inc., Rahway, NJ, USA's anti-PD-1 therapy, KEYTRUDA (pembrolizumab). The clinical study will evaluate the clinical effect of JAB-BX102 in combination with KEYTRUDA for the treatment of advanced solid tumors. Under the terms of the agreement, Merck & Co., Inc., Rahway, NJ, USA will provide KEYTRUDA.",
    'FW Link1':
      'Jacobio announces clinical collaboration to evaluate CD73 monoclonal antibody JAB-BX102 in combination with KEYTRUDA (pembrolizumab) for patients with cancer;;https://firstwordpharma.com/story/5718894/'
  },
  {
    'Date Announced': '22-Mar-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'BostonGene',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Little Warrior Foundation',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics, liquid biopsy assays',
    'Indication(s)': 'Sarcomas',
    'Deal Structure':
      'BostonGene announced a collaboration with Little Warrior Foundation to drive the discovery, validation, and implementation of novel liquid biopsy solutions into sarcoma clinical practice. Broad liquid biopsy panels have limited applicability for individual cancer types due to the unique molecular landscape of each cancer type; therefore, Little Warrior Foundation has partnered with BostonGene to develop sarcoma-specific cfDNA and cfRNA liquid biopsy assays to increase the sensitivity and accuracy of detecting sarcoma-related molecular alterations and fusions. In this collaboration, BostonGene will develop sarcoma-specific cfDNA and cfRNA liquid biopsy assays, aiming at implementing them into clinical practice for both pediatric and adult sarcomas.',
    'FW Link1':
      'BostonGene and Little Warrior Foundation Collaborate to Bring Novel Liquid Biopsy Solutions into Clinical Practice;;https://www.firstwordhealthtech.com/story/5719058/'
  },
  {
    'Date Announced': '22-Mar-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Massive Bio',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'NeoGenomics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Artificial intelligence, diagnostics, biomarkers, precision medicine',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Massive Bio, Inc., a private AI-enabled oncology startup that provides virtual and in-person concierge services for cancer patients, and NeoGenomics, Inc. (NASDAQ: NEO), a leading provider of cancer-focused genetic testing services and global oncology contract research services, announced a collaboration with the goal of accelerating the development of new cancer therapies and ultimately improving the lives of millions of cancer patients around the world. NeoGenomics will identify patients in real time who may be eligible for clinical trials based on biomarker status. Following initial contact and outreach provided directly from NeoGenomics to the treating physician, Massive Bio will help obtain patient consent and expediate additional screening and potential enrollment. This partnership will help to quickly identify patients eligible for clinical trials and help patients and providers make an informed decision regarding their potential treatment avenue.',
    'FW Link1':
      'Massive Bio and NeoGenomics Announce Collaboration to Accelerate Oncology Drug Discovery and Improve Patient Care;;https://www.firstwordhealthtech.com/story/5718981/'
  },
  {
    'Date Announced': '22-Mar-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Medtronic, Cosmo Pharmaceuticals',
    'Country (Acquiring Company/Partner)': 'Ireland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Nvidia',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial intelligence, diagnostics',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'GI Genius',
    'Indication(s)': 'Colorectal cancer',
    'Deal Structure':
      "Medtronic plc (NYSE: MDT), a global leader in healthcare technology, announced Cosmo Pharmaceuticals and NVIDIA plan to integrate NVIDIA's AI technologies into the GI Genius™ intelligent endoscopy module — the first FDA-cleared, AI-assisted colonoscopy tool to help physicians detect polyps that can lead to colorectal cancer. Developed and manufactured by Cosmo Pharmaceuticals, GI Genius™ was designed with the ability to host multiple AI algorithms. Now, with the addition of NVIDIA's Holoscan and IGX technologies, Cosmo is introducing an Innovation Center website, enabling third-party developers with a cloud-based platform to efficiently train and validate their own AI models with the intent to eventually distribute them through the GI Genius™ module.",
    'FW Link1':
      'Medtronic to boost AI innovation with new platform introduction;;https://www.firstwordhealthtech.com/story/5719016/'
  },
  {
    'Date Announced': '23-Mar-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Onconova Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Pangea Biomed',
    'Country (Target Company/Partner)': 'Israel',
    'Region (Target Company/Partner)': 'Middle East',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Small molecules, artificial intelligence, biomarkers',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'rigosertib',
    'Indication(s)':
      'Non-small-cell lung cancer, squamous cell carcinoma complicating recessive dystrophic epidermolysis bullosa, solid cancers',
    'Deal Structure':
      'The collaboration will leverage Pangea Biomed’s proprietary algorithmic platform, ENLIGHT, with the goal of identifying biomarkers of response to Onconova’s proprietary investigational product candidate rigosertib. Onconova and Pangea Biomed will chart genetic interactions related to PLK1 to identify a biomarker of response to rigosertib based on its inhibitory activity against this protein. The ENLIGHT platform will then be applied to generate additional genetic interaction maps around other pathways targeted by rigosertib. Per a collaboration agreement between the companies, Onconova retains all rights to rigosertib and will own intellectual property that may result from the research collaboration.',
    'FW Link1':
      'Onconova Therapeutics and Pangea Biomed Enter into Research Collaboration to Identify Biomarkers of Response to Rigosertib;;https://firstwordpharma.com/story/5719510/'
  },
  {
    'Date Announced': '23-Mar-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Ono Pharmaceutical',
    'Country (Acquiring Company/Partner)': 'Japan',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Macomics',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibodies',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Macomics Ltd, a leader in macrophage drug discovery, announces that it has entered into a worldwide drug discovery collaboration agreement with Ono Pharmaceutical Co., Ltd. (Osaka, Japan; President and CEO: Gyo Sagara; “Ono”) to develop new immuno-oncology antibody drugs against a novel macrophage target of interest in cancer. Under the terms of the agreement, Macomics will identify and characterise antibody candidates against the novel target of interest using its ENIGMAC macrophage drug discovery platform. Ono will have an exclusive option to license global rights to the candidates for further development and commercialization. Macomics will receive an up-front payment, R&D funding, and success-based milestone payments, as well as tiered royalties based on global net sales.',
    'FW Link1':
      'Macomics and Ono Pharmaceutical Partner to Discover and Develop Macrophage-targeting Antibody Therapy for the Treatment of Cancer;;https://firstwordpharma.com/story/5719348/'
  },
  {
    'Date Announced': '23-Mar-2023',
    'Deal type': 'Terminated',
    'Acquiring Company/Partner': 'Gilead',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Pionyr Immunotherapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibodies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'PY159, PY314',
    'Indication(s)': 'Solid tumours, cancer',
    'Target(s)': 'TREM1, TREM2',
    'Deal Structure':
      'Pionyr and Gilead Sciences, Inc. (Nasdaq: GILD) have mutually agreed to change their 2020 exclusive option agreements. Gilead has waived its exclusive option to acquire Pionyr and certain other rights under the 2020 agreements. Gilead will retain its 49% equity stake in Pionyr as well as its right, under certain conditions, to review new data as it emerges. Pionyr is now free to pursue fundraising and partnering opportunities as part of its ongoing strategy. In 2020, Gilead paid $275 million for its equity stake in Pionyr and agreed to fund Pionyr’s clinical and research activities in exchange for an exclusive option to acquire Pionyr for a pre-set price and contingent payments. As part of this agreement, Gilead waives its exclusive option to acquire Pionyr. Pionyr will be responsible for ongoing development of its pipeline, which includes PY159, PY314 and PY265.',
    'FW Link1':
      'Pionyr Immunotherapeutics and Gilead Change Exclusive Option Agreement;;https://firstwordpharma.com/story/5719398/'
  },
  {
    'Date Announced': '27-Mar-2023',
    'Deal type': 'Terminated',
    'Acquiring Company/Partner': 'Pfizer',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Merck KGaA',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Upfront Value ($m)': '850',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Bavencio',
    'Indication(s)':
      'Urothelial carcinoma, renal cell carcinoma, Merkel cell carcinoma',
    'Target(s)': 'PD-L1',
    'Deal Structure':
      'Merck KGaA said Monday that its agreement with Pfizer covering the anti-PD-L1 antibody Bavencio (avelumab) has ended, with the German drugmaker regaining global rights to the product. The companies partnered in 2014 to co-develop and co-commercialise Bavencio. Pfizer paid $850 million upfront under the deal, with Merck eligible to receive regulatory and commercial milestone payments up to approximately $2 billion. According to Merck, the current profit share will be replaced by a 15% royalty to Pfizer on net sales of Bavencio. The German drugmaker will take full control of the global commercialisation of Bavencio effective June 30, and while the companies will continue to conduct their respective ongoing clinical trials, Merck will assume all future R&D activities.',
    'FW Link1':
      'Merck KGaA and Pfizer part ways on Bavencio;;https://firstwordpharma.com/story/5720565/'
  },
  {
    'Date Announced': '27-Mar-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'OncoVerity',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'argenx',
    'Country (Target Company/Partner)': 'Netherlands',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'cusatuzumab',
    'Indication(s)': 'Acute myeloid leukaemia',
    'Target(s)': 'CD70',
    'Deal Structure':
      'OncoVerity, a pioneer in applying advanced multiomic, data driven patient stratification to the development of human therapeutics in oncology, has secured worldwide licensing rights to cusatuzumab from argenx and received $30M in funding from argenx and a joint venture of UCHealth and University License Equity Holdings, Inc. on the University of Colorado Anschutz Medical Campus for its continued development. Cusatuzumab is a first-in-class anti-CD70 antibody that has generated promising results in early acute myeloid leukemia (AML) human studies. OncoVerity will optimize cusatuzumab’s continued development in AML and explore its application in other cancers.',
    'FW Link1':
      'OncoVerity, Inc. Announces Exclusive Worldwide Licensing Rights to Cusatuzumab and Appoints Max Colao as Chief Executive Officer;;https://firstwordpharma.com/story/5720975/'
  },
  {
    'Date Announced': '27-Mar-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Earli',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Particle Works',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'DNA-lipid nanoparticles',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Particle Works, a leading provider of innovative microfluidic nanoparticle synthesis platforms, is supporting Earli Inc. in its mission to detect, locate and treat cancers at the earliest stages. San Francisco-based Earli will benefit from Particle Works' highly controlled and automated technologies – the Automated Library Synthesis (ALiS) System and Automated Nanoparticle (ANP) System – to accelerate and optimize DNA-LNP formulation screening for potential cancer treatments.",
    'FW Link1':
      'Particle Works Partners with Earli to Develop DNA-LNPs for Early-Stage Cancer Treatment;;https://firstwordpharma.com/story/5720891/'
  },
  {
    'Date Announced': '27-Mar-2023',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Concentra Biosciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Jounce Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immunotherapy',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'vopratelimab, JTX-8064, pimivalimab',
    'Indication(s)': 'Cancer',
    'Target(s)': 'ICOS (CD278), LILRB2, PD-1',
    'Deal Structure':
      'Jounce Therapeutics said Monday it has accepted a sweetened offer from one of its largest shareholders, Concentra Biosciences, and scrapped plans to combine with Redx Pharma. Concentra, which is controlled by Tang Capital Partners, agreed to acquire Jounce shares it does not already own for $1.85 apiece in cash, along with contingent value rights (CVRs) tied to proceeds from any license or disposition of some of its legacy programmes.',
    'FW Link1':
      'Jounce drops Redx deal in favour of Concentra takeover bid;;https://firstwordpharma.com/story/5720650/'
  },
  {
    'Date Announced': '27-Mar-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Brenus Pharma',
    'Country (Acquiring Company/Partner)': 'France',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'EFS',
    'Country (Target Company/Partner)': 'France',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immunotherapy',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'STC-1010',
    'Indication(s)': 'Colorectal cancer',
    'Deal Structure':
      "The French Blood Establishment (EFS) and Brenus Pharma announce their strategic partnership in the bioproduction of innovative therapy drugs (ITD), highlighting French expertise. This collaboration is part of a shared vision: developing the French bioproduction sector nationally and internationally. This public-private partnership aims for a long-term perspective and will lead in 2023 to the production of clinical batches for the Fist-In-Human study of STC-1010, Brenus Pharma's lead candidate, targeting colorectal cancer.",
    'FW Link1':
      'French Blood Establishment (EFS) & Brenus Pharma Partner for the GMP Production of STC-1010, an Innovative Therapy Against Colorectal Cancer;;https://firstwordpharma.com/story/5720869/'
  },
  {
    'Date Announced': '27-Mar-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'BJ Bioscience',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Merck & Co.',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Fusion protein, monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'BJ-001, Keytruda',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'PD-1',
    'Deal Structure':
      "BJ Bioscience, Inc. BJ Bioscience, a biotech company focusing on discovery and development of novel biologics for cancer immunotherapy, announced that it entered into a clinical trial collaboration and supply agreement with MSD (Merck & Co., Inc., Rahway, NJ, USA). The collaboration will allow for the evaluation of safety and efficacy of BJ-001, a tumor-targeting IL-15 fusion protein, in combination with MSD's anti-PD-1 therapy KEYTRUDA (pembrolizumab) in an ongoing Phase 1 clinical trial.",
    'FW Link1':
      'BJ Bioscience Announces Clinical Collaboration with MSD to Evaluate BJ-001 in Combination with KEYTRUDA (pembrolizumab);;https://firstwordpharma.com/story/5721163/'
  },
  {
    'Date Announced': '28-Mar-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Astellas',
    'Country (Acquiring Company/Partner)': 'Japan',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'MIMETAS',
    'Country (Target Company/Partner)': 'Netherlands',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immuno-oncology',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'MIMETAS, a global leader in human disease modeling announces that it has entered into a strategic partnership with Astellas Pharma Inc., a global pharmaceutical company. The partnership comprises utilizing MIMETAS’ comprehensive tumor models to support the next generation of immuno-oncological therapies. Under the partnership, Astellas will receive access to MIMETAS immune oncological disease assays. MIMETAS will be eligible for upfront and milestone payments.',
    'FW Link1':
      'MIMETAS and Astellas Enter into Strategic Partnership on Immuno-Oncology;;https://firstwordpharma.com/story/5721509/'
  },
  {
    'Date Announced': '28-Mar-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Orient Europharma',
    'Country (Acquiring Company/Partner)': 'Taiwan',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Shenzhen Hepalink',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Total Deal Value ($m)': '11',
    'Geographic Remit of Deal': 'Taiwan',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'oregovomab',
    'Indication(s)': 'Ovarian cancer',
    'Target(s)': 'CA125',
    'Deal Structure':
      'According to the terms of the agreement, OncoVent agreed to grant exclusive rights to OEP, which will be responsible for the commercialization of oregovomab, an immunotherapy drug candidate, in Taiwan, including related regulatory applications and necessary clinical trials. OncoVent is entitled to a number of payments from OEP under the license agreement, including a one-off non-refundable upfront payment upon the effective date of the license agreement, multiple regulatory milestone payments, and multiple commercial milestone payments, in a total transaction amount of up to US$11.2 million. OEP also agreed to purchase oregovomab from OncoVent at a price equal to an agreed percentage to the average sale price of oregovomab in Taiwan. In addition, OncoVent granted OEP the right of first refusal for the exclusive sales, marketing, and distribution right in Hong Kong and Macau.',
    'FW Link1':
      'OncoVent, a Subsidiary of Hepalink, Enters into a License and Distribution Agreement with Orient EuroPharma for the Immunotherapeutic Candidate Oregovomab;;https://firstwordpharma.com/story/5721167/'
  },
  {
    'Date Announced': '28-Mar-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Boehringer Ingelheim',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Covant Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '10',
    'Total Deal Value ($m)': '481',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Target(s)': 'ADAR1',
    'Deal Structure':
      'Covant Therapeutics, an innovative covalent drug discovery company, has entered into an exclusive research collaboration and worldwide licensing agreement with Boehringer Ingelheim covering Covant’s ADAR1 program. The companies jointly aim to develop a novel small molecule immunotherapy targeting ADAR1 to transform the lives of cancer patients. Under the terms of the agreement, Covant will be responsible for the discovery of ADAR1 small molecule inhibitors. In turn, Covant will receive an upfront payment of $10 million and will be eligible for up to $471 million in additional milestone payments along with tiered royalties on global sales.',
    'FW Link1':
      'Covant and Boehringer Ingelheim Collaborate to Develop a Novel ADAR1 Inhibitor for Use in Cancer Patients;;https://firstwordpharma.com/story/5721284/'
  },
  {
    'Date Announced': '28-Mar-2023',
    'Deal type': 'Product Acquisition',
    'Acquiring Company/Partner': 'Morphogenesis',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'TυHURA Biopharma',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Morphogenesis Inc., a Phase 3 clinical stage biopharmaceutical company developing novel personalized cancer vaccines, announced that it has entered into a definitive asset purchase agreement, in a stock for stock transaction, to acquire TυHURA’s novel ADCs targeting MDSCs to modulate tumor microenvironment immunosuppression. The technologies were developed by researchers at Moffitt Cancer Center, West Virginia Research Corporation and TυHURA. Through this acquisition, Morphogenesis now has exclusive worldwide license rights to TυHURA’s patents and patented technologies related to the ADC platform.',
    'FW Link1':
      'Morphogenesis Inc. Acquires TυHURA Biopharma’s First-in-Class Antibody Drug Conjugates (ADCs) Technology Designed to Overcome Acquired Resistance to Cancer Immunotherapy;;https://firstwordpharma.com/story/5721315/'
  },
  {
    'Date Announced': '28-Mar-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Junshi Biosciences',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'China Medical System',
    'Country (Target Company/Partner)': 'Hong Kong',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal':
      'Southeast Asia (Thailand, Brunei, Cambodia, Indonesia, Laos, Malaysia, Myanmar, the Philippines, Vietnam)',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset(s)': 'toripalimab',
    'Indication(s)': 'Cancer',
    'Target(s)': 'PD-1',
    'Deal Structure':
      'Shanghai Junshi Biosciences Co., Ltd (“Junshi Biosciences”) and Rxilient Biotech announced the collaboration on the development and commercialization of the anti-PD-1 monoclonal antibody drug, toripalimab through joint venture in 9 Southeast Asian nations, including Thailand, Brunei, Cambodia, Indonesia, Laos, Malaysia, Myanmar, the Philippines, and Vietnam. According to the terms of the agreement, Rxilient Biotech will subscribe to the newly issued shares of Excellmab for US$4,999,999, and Junshi Biosciences will pay for the subscription amount under the Shareholders Agreement by way of granting Excellmab with the relevant rights under the License Agreement. Upon completion of the issuance, Rxilient Biotech and Junshi Biosciences will own 60% and 40% of Excellmab, respectively. Excellmab will be responsible for the development, medical affairs, production of finished products, and commercialization of relevant products within the cooperation territory. The profits available for distribution by Excellmab will be distributed in proportion to the respective shareholdings of the two parties. Excellmab will also obtain a right of first negotiation for the commercialization of four other products under research as stipulated in the License Agreement in the cooperation territory. According to the progress of toripalimab’s R&D and other projects, Junshi Biosciences may receive a milestone payment of up to approximately US$4.52 million, plus a certain percentage of royalties on net sales.',
    'FW Link1':
      'Junshi Biosciences Announces Establishment of Joint Venture with Rxilient Biotech to Jointly Develop and Commercialize Toripalimab in Southeast Asia;;https://firstwordpharma.com/story/5721720/'
  },
  {
    'Date Announced': '29-Mar-2023',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'Prostatype Genomics',
    'Country (Acquiring Company/Partner)': 'Sweden',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Eurofins',
    'Country (Target Company/Partner)': 'Luxembourg',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Spain, Portugal',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Genetic test',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Prostatype',
    'Indication(s)': 'Prostate cancer',
    'Deal Structure':
      "Prostatype Genomics begins collaboration with Eurofins Megalab, leader in clinical analysis on the Spanish laboratory market and part of Eurofins, one of the world's largest laboratory groups present in Europe, USA, Asia, and Latin America. The agreement means that Eurofins Megalab will manage all steps in the laboratory process required to analyze the Prostatype® test on the Spanish and Portuguese markets. The collaboration is another step in Prostatype Genomics' work to further accelerate commercialization in Europe. The agreement will enter into force immediately.",
    'FW Link1':
      'Prostatype Genomics enters collaboration with Spanish Eurofins Megalab;;https://www.firstwordhealthtech.com/story/5721968/'
  },
  {
    'Date Announced': '30-Mar-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Integra',
    'Country (Acquiring Company/Partner)': 'Spain',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Predictive Oncology',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Gene therapy',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Predictive Oncology (NASDAQ: POAI), a science-driven company on the leading edge of oncology drug discovery and development, today announces it is partnering with Integra Therapeutics, a company focused on engineering the next generation of gene writing tools to cure diseases. The collaboration was created to pursue a novel method to enhance Integra Therapeutics’ ability to use gene editing for future cancer therapies.',
    'FW Link1':
      'Predictive Oncology Announces Collaboration with Integra Therapeutics to Advance Gene Therapy;;https://firstwordpharma.com/story/5722447/'
  },
  {
    'Date Announced': '30-Mar-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Astellas',
    'Country (Acquiring Company/Partner)': 'Japan',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'ImmunoPrecise',
    'Country (Target Company/Partner)': 'Canada',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibodies, artificial intelligence',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'ImmunoPrecise Antibodies Ltd. (NASDAQ: IPA) (“ImmunoPrecise” or “IPA” or the “Company”) an AI-driven biotherapeutic research and technology company announced a research collaboration and exclusive option license agreement with Xyphos Biosciences, Inc. (a wholly owned subsidiary of Astellas Pharma Inc., “Astellas”). Under the terms of the agreement, the companies will jointly conduct research activities to identify and optimize proprietary LENSai in silico generated antibodies, targeting an undisclosed target in the tumor microenvironment (TME), as potential therapeutic development candidates. Targeting this molecule has the potential to markedly enhance anti-tumor immunity with other Astellas therapies including chimeric antigen receptor-based (CAR) technologies. Astellas will have the exclusive option to license any development candidates generated as part of the collaboration.',
    'FW Link1':
      'ImmunoPrecise’s Subsidiary Talem Enters into Exclusive Research Collaboration and License Option Agreement with Astellas;;https://firstwordpharma.com/story/5722401/'
  },
  {
    'Date Announced': '30-Mar-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Omega Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Roche',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'mRNA therapeutic, monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'OTX-2002, Tecentriq',
    'Indication(s)': 'Hepatocellular carcinoma',
    'Target(s)': 'MYC, PD-L1',
    'Deal Structure':
      'Omega Therapeutics, Inc. (Nasdaq: OMGA) (“Omega”), a clinical-stage biotechnology company pioneering the development of a new class of programmable epigenetic mRNA medicines, announced a clinical supply agreement with Roche to evaluate OTX-2002, its lead candidate in development for the treatment of MYC-driven hepatocellular carcinoma (HCC), in combination with Roche’s anti-PD-L1 therapy, atezolizumab, as part of Omega’s Phase 1/2 MYCHELANGELO™ I clinical trial. Under the terms of this agreement, Roche will supply atezolizumab and Omega will evaluate the combination as part of the overall conduct of the trial.',
    'FW Link1':
      'Omega Therapeutics Announces Clinical Supply Agreement to Evaluate the Combination of OTX-2002 and Atezolizumab in Hepatocellular Carcinoma;;https://firstwordpharma.com/story/5722236/'
  },
  {
    'Date Announced': '30-Mar-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'China Medical System',
    'Country (Acquiring Company/Partner)': 'Hong Kong',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Cosmo Pharmaceuticals',
    'Country (Target Company/Partner)': 'Ireland',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostic drug',
    'Lead Asset(s)': 'Lumeblue',
    'Indication(s)': 'Colorectal cancer',
    'Deal Structure':
      'Cosmo Pharmaceuticals N.V. (SIX: COPN, XETRA: C43) (“Cosmo”) announced the territory expansion of the license agreement for Lumeblue™ with China Medical System Holdings Limited (“CMS”) (SEHK: 867). The expansion goes beyond the existing licensed territories (Greater China including the mainland China, Hong Kong, Macao, and Taiwan) to include several countries belonging to the “Pan-Asia” region. This territory expansion is following the successful results reported from the phase III clinical trial of Lumeblue™ in China. Cosmo will continue to be the exclusive supplier of Lumeblue™ also for these regions.',
    'FW Link1':
      'Cosmo announces territory expansion agreement for Lumeblue (Methylene Blue MMX) with China Medical System Holdings;;https://firstwordpharma.com/story/5722600/'
  },
  {
    'Date Announced': '3-Apr-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Enlivex Therapeutics',
    'Country (Acquiring Company/Partner)': 'Israel',
    'Region (Acquiring Company/Partner)': 'Middle East',
    'Target Company/Partner': 'BeiGene',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy, monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'Allocetra, tislelizumab',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'PD-1',
    'Deal Structure':
      'Enlivex Therapeutics Ltd. (Nasdaq: ENLV, “Enlivex”), a clinical-stage macrophage reprogramming immunotherapy company, announced a clinical collaboration with BeiGene. to evaluate the safety and efficacy of Allocetra™, an investigational macrophage-reprogramming cell therapy, in combination with tislelizumab, an anti-PD-1 immune checkpoint inhibitor, for the treatment of patients with advanced-stage solid tumors. Under the terms of the clinical collaboration agreement, Enlivex has agreed to amend its ongoing Phase I/II trial in patients with advanced-stage solid tumors to include evaluation of Allocetra in combination with tislelizumab. The Phase I/II trial is a multicenter, open-label, dose escalation trial that is expected to enroll up to 48 patients with advanced solid tumors across two trial stages. Stage 1 of the trial will examine escalating doses of Allocetra™ monotherapy administered intravenously (IV) or intraperitoneally (IP) once a week for three consecutive weeks. Stage 2 will evaluate escalating doses of Allocetra administered IV or IP and combined with anti-PD1 therapy. BeiGene will provide the clinical supply of tislelizumab for the trial.',
    'FW Link1':
      'Enlivex Announces Clinical Collaboration to Evaluate Combinations of Allocetra and PD-1 Inhibitor Tislelizumab for Patients with Solid Tumors;;https://firstwordpharma.com/story/5723658/'
  },
  {
    'Date Announced': '3-Apr-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Genenta',
    'Country (Acquiring Company/Partner)': 'Italy',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Ospedale San Raffaele',
    'Country (Target Company/Partner)': 'Italy',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'Temferon',
    'Indication(s)': 'Solid tumours, glioblastoma multiforme',
    'Deal Structure':
      "Genenta Science (NASDAQ: GNTA), a clinical-stage immuno-oncology company developing a cell-based platform harnessing the power of hematopoietic stem cells to provide durable and safe treatments for solid tumors, announced that the license agreement with Ospedale San Raffaele (OSR) has been amended and restated to provide access to all solid tumor indications, subject to governmental consent as described below. The amended and restated license agreement reinforces Genenta's long-term strategic relationship with OSR, who contributes unique and valuable expertise to the development of cell-based therapies. Genenta has exclusive worldwide commercial rights to Temferon™, which was originally developed by a team led by Genenta co-founder Luigi Naldini in the SR-Tiget laboratories, a world-leading cell and gene therapy research institution formed in a joint venture between OSR and Fondazione Telethon.",
    'FW Link1':
      'Genenta Announces Extension of License Agreement with Ospedale San Raffaele to All Solid Tumor Indications;;https://firstwordpharma.com/story/5723636/'
  },
  {
    'Date Announced': '3-Apr-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Alaunos Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Precigen',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'T-cell receptor therapy',
    'Indication(s)': 'Cancer, human papillomavirus',
    'Deal Structure':
      'From Form 8-K: On April 3, 2023, Precigen, Inc., (the "Company"), entered into an amended and restated exclusive license agreement (the "License Agreement"), with Alaunos Therapeutics ("Alaunos"). The License Agreement amends and replaces the terms of that certain Exclusive License Agreement by and between the Company and Alaunos, dated October 5, 2018. Pursuant to the terms of the License Agreement, the Company has granted Alaunos an exclusive, worldwide, royalty-free, sub-licensable license to research, develop and commercialize T-cell receptor products, designed for neoantigens for the treatment of cancer or the treatment and prevention of human papilloma virus, or HPV, to the extent that the primary reason for such treatment or prevention is to prevent cancer, which is referred to as the HPV Field. The Company has also granted Alaunos an exclusive, worldwide, royalty-free, sub-licensable license for certain patents relating to the Sleeping Beauty technology to research, develop and commercialize TCR Products for both neoantigens and shared antigens for the treatment of cancer and in the HPV Field. The Company also granted Alaunos certain non-exclusive rights with respect to shared antigens, NK cells and gamma delta T-cells.',
    'FW Link1':
      'Precigen Regains Exclusive Rights to Proven CAR-T Targets, CD19 and BCMA, to Enable Unencumbered Development and Commercialization of UltraCAR-T;;https://firstwordpharma.com/story/5723801/'
  },
  {
    'Date Announced': '3-Apr-2023',
    'Deal type': 'Terminated',
    'Acquiring Company/Partner': 'Alaunos Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Precigen',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'CAR-T cell therapy, gene therapy',
    'Indication(s)': 'Cancer',
    'Target(s)': 'CD19, BCMA',
    'Deal Structure':
      'Precigen, Inc. (Nasdaq: PGEN), a biopharmaceutical company specializing in the development of innovative gene and cell therapies to improve the lives of patients, announced the amendment of its exclusive license agreement with Alaunos Therapeutics, Inc. (Alaunos). With this amendment, Precigen has the unique ability to utilize the clinically validated UltraCAR-T platform for unencumbered development and commercialization of two proven CAR-T targets, CD19 and B-cell maturation antigen (BCMA). Precigen also regained exclusive rights to its interleukin (IL)-12 gene therapy, including application through the off-the-shelf AdenoVerse™ immunotherapy platform, paving the way for potential future treatments in oncology given the important role of IL-12 cytokines in targeting many types of tumors such as HPV-associated cancers. Precigen maintains the right to pursue non-neoantigen T-cell receptors (TCRs). As part of the amendment, all milestone payments and royalties between the parties have been eliminated.',
    'FW Link1':
      'Precigen Regains Exclusive Rights to Proven CAR-T Targets, CD19 and BCMA, to Enable Unencumbered Development and Commercialization of UltraCAR-T;;https://firstwordpharma.com/story/5723801/'
  },
  {
    'Date Announced': '3-Apr-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Medigene',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'National Cancer Institute',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'T-cell therapies',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      'Medigene AG (Medigene, FSE: MDG1, Prime Standard), an early stage immuno-oncology platform company focusing on the discovery and development of T cell immunotherapies for solid tumors, and the National Cancer Institute (NCI) will collaborate to evaluate the potential of Medigene’s proprietary T cell receptors (TCRs) to be used in new cell constructs for the treatment of solid tumors.',
    'FW Link1':
      'Medigene enters Cooperative Research and Development Agreement with the National Cancer Institute;;https://firstwordpharma.com/story/5723689/'
  },
  {
    'Date Announced': '3-Apr-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'BioNTech',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Duality Biologics',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '170',
    'Total Deal Value ($m)': '1,670',
    'Geographic Remit of Deal': 'Global excl. China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'DB-1303, DB-1311',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'HER2',
    'Deal Structure':
      'BioNTech announced Monday it is entering the antibody drug conjugate (ADC) field by agreeing exclusive license and collaboration agreements with DualityBio for two ADC products outside of the Chinese market. Under the terms of the agreements, DualityBio will receive upfront payments for both asset licenses totaling $170 million, and additional development, regulatory and commercial milestone payments for both assets, potentially totaling over $1.5 billion. The German biotech company will gain access to DB-1303, which is Duality’s lead candidate and a topoisomerase-1 inhibitor-based ADC which targets HER2. It is currently undergoing Phase II evaluation for HER2-expressing advanced solid tumours and has received FDA fast track designation. The second candidate is also a topoisomerase-1 inhibitor-based ADC known as DB-1311. It is in preclinical testing for solid tumours and has an as-yet unidentified target. ',
    'FW Link1':
      'BioNTech jumps on the ADC bandwagon;;https://firstwordpharma.com/story/5723359/'
  },
  {
    'Date Announced': '3-Apr-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Panbela',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Johns Hopkins University School of Medicine',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset(s)': 'ivospemin',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Panbela Therapeutics, Inc. (Nasdaq: PBLA), a clinical stage biopharmaceutical company developing disruptive therapeutics for the treatment of patients with cancer, announced a new research agreement with the Johns Hopkins University School of Medicine. The collaboration is intended to expand the development of Panbela’s investigative agent ivospemin (SBP-101), including activity in models of ovarian and other cancer types, further evaluations into mechanism of action, and potential combination with CPP-1X and standard of care agents.',
    'FW Link1':
      'Panbela Announces New Research Agreement with Johns Hopkins University School of Medicine;;https://firstwordpharma.com/story/5723677/'
  },
  {
    'Date Announced': '4-Apr-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Pierre Fabre',
    'Country (Acquiring Company/Partner)': 'France',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Scorpion Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Total Deal Value ($m)': '618',
    'Geographic Remit of Deal': 'Global excl. United States, Canada, Japan',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'STX-721, STX-241',
    'Indication(s)': 'Non-small-cell lung cancer',
    'Target(s)': 'EGFR',
    'Deal Structure':
      'Under the terms of the agreement, Scorpion and Pierre Fabre will lead clinical development of STX-721 and STX-241, respectively. Scorpion retains commercialisation rights to both assets in the US, Canada and Japan, while Pierre Fabre will be responsible for commercialisation activities in all other territories, with a focus on Europe and China. Pierre Fabre will pay Scorpion a combined $65 million in the form of an upfront payment and upon the achievement of near-term milestones. Scorpion is also eligible to receive up to a total of $553 million in potential milestones plus tiered percentage royalties on a licensed product-by-licensed product basis.',
    'FW Link1':
      'No sting, all win as Scorpion signs lung cancer collaboration with Pierre Fabre;;https://firstwordpharma.com/story/5724012/'
  },
  {
    'Date Announced': '4-Apr-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Absci',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'M2GEN',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Artificial intelligence, bioinformatics, real-world data, immunotherapy',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Absci Corporation (Nasdaq: ABSI), a generative AI drug creation company, and M2GEN, an oncology bioinformatics company with the most advanced lifetime-consented clinicogenomics data to accelerate discovery research, announced a partnership to create new cancer medicines and bring them to market at unprecedented speed. Absci’s generative AI drug creation platform will tap into M2GEN’s clinical and molecular data set, ORIEN AVATAR (AVATAR), to accelerate the creation of therapeutics for a range of malignancies and patient profiles, bringing AI drug creation to the fight against cancer.',
    'FW Link1':
      'Absci Partners with Bioinformatics Company M2GEN to Accelerate Drug Creation for Oncology;;https://firstwordpharma.com/story/5724462/'
  },
  {
    'Date Announced': '5-Apr-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Incyte',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Biotheryx',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '7',
    'Total Deal Value ($m)': '360',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Protein degraders',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Under the terms of the agreement, Biotheryx will utilize its distinctive PRODEGY platform to identify and initially develop molecular glue degraders for multiple historically undruggable oncology targets. For the initial target, Biotheryx will receive a technology access fee of $7 million plus up to an additional $6 million in potential research and development funding from Incyte for costs associated with the collaboration. Biotheryx is also eligible to receive potential future regulatory and commercial milestones of up to $347 million plus tiered single-digit royalties on global net product sales for the initial target. Incyte will be solely responsible for further development and commercialization of any molecular glue degraders discovered by Biotheryx's PRODEGY platform. Additionally, under the terms of the agreement, the collaboration can be expanded under the same financial terms. Further financial terms of the deal were not disclosed.",
    'FW Link1':
      'Incyte sets sights on undruggable cancer targets in pact with Biotheryx;;https://firstwordpharma.com/story/5724730/'
  },
  {
    'Date Announced': '6-Apr-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Evopoint Biosciences',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Merck & Co.',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules, monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'XNW5004, Keytruda',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'EZH2, PD-1',
    'Deal Structure':
      'Evopoint Biosciences Co., Ltd.(“Evopoint”), a biopharmaceutical company focused on discovering and developing innovative and transformative medicines, announced a collaboration with MSD (Merck & Co., Inc., Rahway, NJ, USA) to evaluate Evopoint’s XNW5004, a selective EZH2 inhibitor, in combination with KEYTRUDA®(pembrolizumab), MSD’s anti-PD-1 therapy, in a Phase Ib/II study in Chinese patients with advanced solid tumors. Under the terms of the agreement, Evopoint will sponsor the Phase Ib/II trial and MSD will supply KEYTRUDA®. Evopoint maintains the global rights to XNW5004.',
    'FW Link1':
      'Evopoint Announces Clinical Trial Collaboration Agreement with MSD for the Study of XNW5004 in Combination with KEYTRUDA (pembrolizumab) in Advanced Solid Tumors;;https://firstwordpharma.com/story/5725211/'
  },
  {
    'Date Announced': '6-Apr-2023',
    'Deal type': 'Product Acquisition',
    'Acquiring Company/Partner': 'Pharma&',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Clovis Oncology',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '70',
    'Total Deal Value ($m)': '135',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Rubraca',
    'Indication(s)': 'Cancer',
    'Target(s)': 'PARP',
    'Deal Structure':
      "Clovis Oncology disclosed in a US securities filing that it reached an agreement to sell certain assets and rights associated with the cancer treatment Rubraca (rucaparib) to Pharma& Schwiez as part of the former's ongoing bankruptcy proceedings. Late last year, Clovis filed for Chapter 11 bankruptcy after having warned that it did not have enough cash to maintain operations. According to the Securities and Exchange Commission filing, Pharma& submitted the highest bid at an auction conducted as part of the bankruptcy proceedings, agreeing to pay $70 million upfront, along with an additional $50 million in specified regulatory milestones and $15 million in later sales-related milestones. Meanwhile, Dr. Reddy's Laboratories has been selected as the back-up bidder in case the transactions with Pharma& fall through.",
    'FW Link1':
      'Clovis finds a buyer for Rubraca amid bankruptcy proceedings;;https://firstwordpharma.com/story/5725489/'
  },
  {
    'Date Announced': '10-Apr-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Ultima Genomics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Genome Insight',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics, whole genome sequencing',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Ultima Genomics, Inc. and Genome Insight, Inc. have signed an agreement to collaborate to bring affordable whole genome sequencing solutions to cancer patients. As part of the agreement, Genome Insight will join the early access program for Ultima's high-throughput NGS instrument platform, the UG 100™, and develop an optimized version of its whole genome bioinformatics solution for Ultima's sequencing technology. The combination of Genome Insight's proprietary bioinformatics with Ultima's low-cost sequencing will make whole genome sequencing an affordable reality for cancer patients.",
    'FW Link1':
      'Ultima, Genome Insight partner on low-cost whole genome sequencing;;https://www.firstwordhealthtech.com/story/5725845/'
  },
  {
    'Date Announced': '11-Apr-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'BMS',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Foundation Medicine',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Companion diagnostics, small molecules',
    'Lead Asset(s)': 'FoundationOne CDx, repotrectinib',
    'Indication(s)': 'Non-small-cell lung cancer, solid tumours',
    'Target(s)': 'ROS1, TRK',
    'Deal Structure':
      'Foundation Medicine, Inc., a pioneer in molecular profiling for cancer announced an expanded collaboration with Bristol Myers Squibb (NYSE: BMY) to develop Foundation Medicine’s tissue-based test, FoundationOne®CDx as a companion diagnostic for Bristol Myers Squibb’s investigational tyrosine kinase inhibitor, repotrectinib.',
    'FW Link1':
      'Foundation Medicine and Bristol Myers Squibb Expand Partnership to Focus on Companion Diagnostic Development;;https://firstwordpharma.com/story/5726460/'
  },
  {
    'Date Announced': '11-Apr-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Xenetic Biosciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'The Scripps Research Institute',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immuno-oncology, CAR-T cell therapy',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Pancreatic carcinoma, solid tumours',
    'Deal Structure':
      'Xenetic Biosciences, Inc. (NASDAQ:XBIO) ("Xenetic" or the "Company"), a biopharmaceutical company focused on advancing innovative immune-oncology technologies addressing hard to treat cancers, announced it has entered into a Research Funding and Option Agreement (the "Agreement") with The Scripps Research Institute ("TSRI") to advance the development of the Company\'s systemic DNase program as well as its DNase-armored CAR T program. The systemic DNase program is expected to be evaluated in combination with existing therapies for the potential treatment of pancreatic carcinoma and other solid tumor indications. Under the terms of the Scripps Research agreement, in addition to advancing Xenetic\'s existing intellectual property, Xenetic has an option to acquire an exclusive license to any new intellectual property arising from the DNase research program. Xenetic is working toward its planned first-in-human study to evaluate DNase combined with immune checkpoint inhibitors or chemotherapy.',
    'FW Link1':
      'Xenetic Biosciences, Inc. Announces Research and Development Collaboration with The Scripps Research Institute to Advance DNase Platform;;https://firstwordpharma.com/story/5726446/'
  },
  {
    'Date Announced': '11-Apr-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Specialised Therapeutics Asia',
    'Country (Acquiring Company/Partner)': 'Singapore',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'CTTQ-Akeso, Akeso',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Australia, Singapore, Southeast Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset(s)': 'Anniko',
    'Indication(s)': 'Nasopharyngeal carcinoma, cancer',
    'Target(s)': 'PD-1',
    'Deal Structure':
      'Independent biopharmaceutical company Specialised Therapeutics Asia Pte Ltd (ST) will partner with CTTQ-Akeso (Shanghai) Biomed. Tech. Co., Ltd. (CTTQ-Akeso)（jointly established by Akeso, Inc. (9926.HK, Akeso) and Chia Tai Tianqing Pharmaceutical Group Co., Ltd. to commercialise a new immuno-oncology therapy in Australia, Singapore and across Southeast Asia. The therapy ANNIKO® (penpulimab) is an anti-PD1 monoclonal antibody currently under regulatory review by the US FDA for nasopharyngeal carcinoma – a difficult to treat form of head and neck cancer. Under the terms of the arrangement, ST will be responsible for all marketing, regulatory and distribution activities in its key regions of Australia, Singapore and across Southeast Asia. CTTQ-Akeso retains the rights of conducting any development work in relation to ANNIKO and Akeso retains all rights to product manufacture and supply.',
    'FW Link1':
      'Specialised Therapeutics signs partnership agreement with Akeso Inc. and CTTQ-Akeso to commercialise new anti-PD1 antibody in Australia and Southeast Asia;;https://firstwordpharma.com/story/5726684/'
  },
  {
    'Date Announced': '12-Apr-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Leucid Bio',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'ImaginAb',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Imaging agent',
    'Lead Asset(s)': 'CD8 ImmunoPET',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'CD8',
    'Deal Structure':
      "Under the terms of the agreement, ImaginAb will license and supply clinical doses of ImaginAb's investigational CD8 ImmunoPET tracer, 89Zr crefmirlimab berdoxam, to Leucid for use in its basket study in solid tumors, with LEU011 targeting NKG2DL, Autologous CAR T-cells.",
    'FW Link1':
      'ImaginAb Executes New License and Supply Agreement for CD8 ImmunoPET Technology with Leucid Bio;;https://firstwordpharma.com/story/5726759/'
  },
  {
    'Date Announced': '12-Apr-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Microbiotica',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Merck & Co.',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Live biotherapeutics, monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'MB097, Keytruda',
    'Indication(s)': 'Melanoma',
    'Target(s)': 'PD-1',
    'Deal Structure':
      'Under the terms of the agreement, Microbiotica will conduct a phase 1b clinical trial to evaluate the safety and tolerability, and initial signs of clinical activity of its live biotherapeutic product, MB097, in combination with KEYTRUDA® (pembrolizumab), MSD’s anti-PD-1 therapy, in melanoma patients with primary resistance to an anti-PD-1-containing immunotherapy. Microbiotica will be the study sponsor and MSD will supply KEYTRUDA®.',
    'FW Link1':
      'Microbiotica Announces Clinical Trial Collaboration with MSD to Evaluate MB097 in Combination with KEYTRUDA (pembrolizumab) in a Phase 1b Clinical Trial in Melanoma;;https://firstwordpharma.com/story/5726904/'
  },
  {
    'Date Announced': '12-Apr-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'ImmunOs Therapeutics',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'ProBioGen',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Immunotherapy, multi-functional fusion protein',
    'Lead Asset(s)': 'IOS-1002',
    'Indication(s)': 'Cancer',
    'Target(s)': 'LILRB1, LILRB2, KIR3DL1',
    'Deal Structure':
      "ProBioGen and ImmunOs Therapeutics announce the extension of their partnership for further large-scale manufacturing of clinical material for IOS-1002, ImmunOs' lead program for the treatment of cancer. ProBioGen previously developed the cell line and the manufacturing process and provided the initial clinical material for ImmunOs' innovative biologic.",
    'FW Link1':
      'ProBioGen and ImmunOs Therapeutics Team Up to Deliver Innovative Therapy for Cancer Patients;;https://firstwordpharma.com/story/5727053/'
  },
  {
    'Date Announced': '12-Apr-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'ESSA Pharma',
    'Country (Acquiring Company/Partner)': 'Canada',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'J&J',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'EPI-7386, apalutamide, abiraterone acetate',
    'Indication(s)': 'Prostate cancer',
    'Target(s)': 'Androgen receptor, androgen biosynthesis',
    'Deal Structure':
      'Under the terms of the Agreement, ESSA will sponsor and conduct a Phase 1 clinical trial evaluating the safety, pharmacokinetics, drug-drug interactions, and preliminary anti-tumor activity of EPI-7386 when administered in combination with either apalutamide or abiraterone acetate plus prednisone. Janssen will supply apalutamide and abiraterone acetate. ESSA will retain all rights to EPI-7386.',
    'FW Link1':
      'ESSA Pharma Announces Clinical Trial Support Agreement with Janssen to Evaluate EPI-7386 Combinations in Patients with Prostate Cancer;;https://firstwordpharma.com/story/5726874/'
  },
  {
    'Date Announced': '12-Apr-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Takeda',
    'Country (Acquiring Company/Partner)': 'Japan',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Adaptive Biotechnologies',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cancer medicines',
    'Indication(s)': 'Lymphoid malignancies',
    'Deal Structure':
      'Adaptive Biotechnologies Corporation (Nasdaq: ADPT), a commercial stage biotechnology company that aims to translate the genetics of the adaptive immune system into clinical products to diagnose and treat disease, announced it has entered into a translational collaboration with Takeda to use its clonoSEQ® Assay to assess minimal residual disease (MRD) to facilitate the development and commercialization of Takeda’s pipeline of treatments for patients with lymphoid malignancies. As part of the collaboration, MRD status based on Adaptive’s clonoSEQ Assay may be used as an endpoint in certain clinical trials to assess the depth and duration of response to Takeda’s investigational medicines in patients with lymphoid malignancies. This multi-year agreement will cover existing and future programs and adds to Adaptive’s growing list of translational collaborations with pharmaceutical companies. Adaptive will receive an upfront payment and will be eligible to receive milestone payments upon achievement of specific milestones in certain geographies. Specific financial terms of the agreement will not be disclosed.',
    'FW Link1':
      'Adaptive Biotechnologies Announces Translational Collaboration with Takeda to Measure Minimal Residual Disease with Its clonoSEQ Assay Across Its Hematologic Malignancy Pipeline;;https://firstwordpharma.com/story/5727017/'
  },
  {
    'Date Announced': '13-Apr-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Pyramid Biosciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'GeneQuantum',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '20',
    'Total Deal Value ($m)': '1,000',
    'Geographic Remit of Deal': 'Global excl. Greater China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'GQ1010',
    'Indication(s)': 'Cancer',
    'Target(s)': 'Trop-2',
    'Deal Structure':
      'Pyramid Biosciences, Inc., a privately-held, clinical-stage biotechnology company focused on developing transformative medicines for patients with cancer, announced that it has entered into an exclusive license agreement with GeneQuantum Healthcare (Suzhou) Co. Ltd, a privately-held biotechnology company based in China, to develop and commercialize GQ1010, a potential best-in-class antibody drug conjugate (ADC) targeting TROP2, worldwide except for Greater China (mainland China, Hong Kong, Macau, and Taiwan). GQ1010 has shown a highly differentiated preclinical profile, and is anticipated to enter a first-in-human trial within the next 12 months. Under the terms of the agreement, Pyramid Biosciences will develop and commercialize GQ1010 in exchange for an upfront payment of $20 million and up to an additional $1 billion of milestone payments. GeneQuantum would also be eligible to receive tiered royalties ranging from mid-single digit to low double digits on net sales.',
    'FW Link1':
      "Pyramid Bio bets on GeneQuantum's ADC candidate for over $1 billion;;https://firstwordpharma.com/story/5727433/"
  },
  {
    'Date Announced': '13-Apr-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Flare Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Caris Life Sciences',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Small molecules, molecular profiling, clinico-genomic real-world data, companion diagnostics',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Under the terms of the agreement, Flare will leverage Caris' industry-leading next generation sequencing technology for the molecular testing of patients treated with Flare's assets. In addition, Flare will receive access to Caris' data insights and analytics capabilities to accelerate oncology drug discovery, identify novel biomarkers and optimize clinical positioning strategies for their pipeline. Flare will also leverage Caris' biomarker-driven patient selection for clinical trials, allowing Caris the first option to develop a companion diagnostic for any drug candidate developed as part of the collaboration.",
    'FW Link1':
      "Caris Life Sciences and Flare Therapeutics Announce Strategic Preferred Portfolio Partnership to Advance Flare's Precision Oncology Pipeline;;https://firstwordpharma.com/story/5727691/"
  },
  {
    'Date Announced': '13-Apr-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Crescendo Biologics',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'ANGLE',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'T-cell enhancers, bispecifics',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'CB307',
    'Indication(s)': 'PSMA-positive solid tumours, prostate cancer',
    'Target(s)': 'PSMA, CD137',
    'Deal Structure':
      'ANGLE plc (AIM:AGL)(OTCQX:ANPCY), a world-leading liquid biopsy company, is delighted to announce that it has signed a contract with a new customer, Crescendo Biologics Limited ("Crescendo"). Crescendo is a UK-based, clinical stage immuno-oncology company with an extensive proprietary pipeline of novel, targeted T cell enhancing Humabody® therapeutics. Crescendo will use ANGLE\'s recently launched Portrait Flex assay in its ongoing Phase 1 clinical trial (NCT04839991) investigating the safety and efficacy of CB307, Crescendo\'s first-in-class prostate-specific membrane antigen (PSMA) x CD137 half-life extended bispecific, for the treatment of patients with PSMA positive solid tumours.',
    'FW Link1':
      'Angle PLC Announces Pharma Services Contract with Crescendo Biologics;;https://firstwordpharma.com/story/5727302/'
  },
  {
    'Date Announced': '13-Apr-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Affini-T Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Memorial Sloan Kettering Cancer Center',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'T-cell therapy',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      'Affini-T Therapeutics, Inc., a precision immunotherapy company unlocking the power of T cells against oncogenic driver mutations, announced a licensing agreement with MSK. As a result of the collaboration, which includes a sponsored research agreement with MSK’s Klebanoff lab, Christopher A. Klebanoff, M.D., Associate Member and Attending Physician, Laboratory Head, Human Oncology and Pathogenesis Program, and Member Investigator, Center for Cell Engineering at MSK, will join Affini-T as a Scientific Co-founder and Advisor.',
    'FW Link1':
      'Affini-T Therapeutics Announces Licensing Agreement with Memorial Sloan Kettering Cancer Center to Expand Company’s Pipeline of Oncogenic Driver Programs;;https://firstwordpharma.com/story/5727575/'
  },
  {
    'Date Announced': '14-Apr-2023',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'AstraZeneca',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'MedSol AI Solutions',
    'Country (Target Company/Partner)': 'South Africa',
    'Region (Target Company/Partner)': 'Africa',
    'Geographic Remit of Deal': 'South Africa',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial intelligence, diagnostics',
    'Indication(s)': 'Breast cancer',
    'Deal Structure':
      "AstraZeneca is partnering with South African healthtech firm MedSol AI Solutions to help promote the use of artificial intelligence (AI) in the detection of breast cancer via a Wi-Fi ultrasound probe, TechCabal reported Friday. The Melusi Breast AI rapid detection app will be rolled out in rural clinics to help detect disease early. The partnership is part of AstraZeneca's Africa Health Innovation Hub initiative.",
    'FW Link1':
      'AstraZeneca bringing breast cancer detection to South Africa using AI;;https://www.firstwordhealthtech.com/story/5728104/'
  },
  {
    'Date Announced': '17-Apr-2023',
    'Deal type': 'Product Acquisition',
    'Acquiring Company/Partner': 'Araris Biotech',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'ARS Pharmaceuticals',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibodies, antibody-drug conjugates',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'Nectin-4',
    'Deal Structure':
      'Under the terms of the agreement Araris will acquire ARS’ antibody against Nectin-4. The deal enables Araris to combine its proprietary linker technology with ARS’ GLP-tox ready Nectin-4 antibody, in order to develop targeted ADC therapeutics, with various linker-payloads, for the treatment of solid tumors of high unmet medical need.',
    'FW Link1':
      'Araris Biotech AG Acquires ARS Pharmaceutical’s Nectin-4 Antibody;;https://firstwordpharma.com/story/5728727/'
  },
  {
    'Date Announced': '17-Apr-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Genmab',
    'Country (Acquiring Company/Partner)': 'Denmark',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'argenx',
    'Country (Target Company/Partner)': 'Netherlands',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibodies',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Genmab is stepping outside of its core area of oncology by teaming up with argenx to jointly develop therapeutic antibodies with applications in immunology. The multiyear collaboration between Genmab and argenx will utilise the drugmakers' antibody engineering expertise, with each party having access to the other's suite of proprietary antibody technologies. The deal will initially focus on two differentiated targets – one in immunology and the other in cancer – with the potential to expand to more. Genmab and argenx will equally share costs, as well as any potential future profits, although further financial details were not disclosed.",
    'FW Link1':
      'Genmab steps outside comfort zone via immunology deal with argenx;;https://firstwordpharma.com/story/5728426/'
  },
  {
    'Date Announced': '17-Apr-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Radiopharm',
    'Country (Acquiring Company/Partner)': 'Australia',
    'Region (Acquiring Company/Partner)': 'Oceania',
    'Target Company/Partner': 'TerThera',
    'Country (Target Company/Partner)': 'Netherlands',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Radiotherapeutics',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'RAD 602',
    'Indication(s)': 'Brain cancer',
    'Target(s)': 'Protein tyrosine phosphatase mu',
    'Deal Structure':
      'Radiopharm Theranostics (ASX:RAD, “Radiopharm” or the “Company”), a developer of radiopharmaceutical products for both diagnostic and therapeutic uses, is pleased to announce an agreement with TerThera to supply the Company with Terbium-161 (Tb-161). The Tb-161 isotope will be linked to a proprietary peptide to form RAD 602, a radiotherapeutic that is being developed by Radiopharm to target protein tyrosine phosphatase mu (PTPmu or PTPμ). PTPμ is expressed in cancer cells but has limited expression in healthy tissue. Radiopharm will initiate a Phase I dose escalating trial of RAD 602 for treatment of patients with brain cancers during the fourth quarter of calendar 2023. The initial order for preclinical use is expected in April 2023, with the first order for clinical use expected in October 2023. The effective date of the supply agreement is 17 April 2023 and there are no material preconditions. The supply agreement is for an initial period of three years and may be extended for additional two years, unless agreed otherwise by either party. Cancellation provisions are at industry standard rates. Radiopharm will own all data generated and all inventions and discoveries made or conceived from its clinical trials.',
    'FW Link1':
      'Radiopharm Theranostics Enters Into Supply Agreement with TerThera for Terbium-161 Isotope;;https://firstwordpharma.com/story/5728593/',
    'FW Link2':
      'Expansion of agreement announced August 24, 2023;;https://firstwordpharma.com/story/5774244/'
  },
  {
    'Date Announced': '18-Apr-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'BullFrog AI',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Johns Hopkins University',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset(s)': 'Prodrug compositions of mebendazole',
    'Indication(s)': 'Glioblastoma, cancer',
    'Deal Structure':
      'BullFrog AI Holdings, Inc. (Nasdaq: BFRG) ("Bullfrog AI" or the "Company"), a digital technology company using machine learning to usher in a new era of precision medicine, today announced an exclusive license agreement with Johns Hopkins University for prodrug compositions of mebendazole. The exclusive license was signed on October 13, 2022. The world-wide, royalty-bearing license includes the rights to commercialize N-substituted prodrugs of mebendazole that demonstrate improved solubility and bioavailability relative to the parent compound. The license covers prodrug compositions and use for treating disease as claimed in multiple US and worldwide patent applications.',
    'FW Link1':
      'BullFrog AI Announces Licensing Agreement with Johns Hopkins University for Oncology Asset;;https://firstwordpharma.com/story/5729460/'
  },
  {
    'Date Announced': '18-Apr-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'NeoPhore',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Institute of Cancer Research',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules, immuno-oncology',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      'Scientists at The Institute of Cancer Research, London, are teaming up with small molecule neoantigen immuno-oncology company NeoPhore to develop new potential cancer drugs. Under a new strategic research collaboration, an ICR team led by Professor Chris Lord will investigate the effect of NeoPhore’s inhibitors on tumours in the laboratory. The inhibitors are small molecules that target proteins involved in DNA mismatch repair (MMR), an important process in cells that corrects mistakes that are introduced to DNA when they divide.',
    'FW Link1':
      'Immuno-oncology collaboration to develop innovative new type of cancer drug;;https://firstwordpharma.com/story/5729214/'
  },
  {
    'Date Announced': '18-Apr-2023',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Interactive Capital Partners',
    'Country (Acquiring Company/Partner)': 'Canada',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'SkinJect',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Drug delivery, chemotherapy',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'SkinJect',
    'Indication(s)':
      'Non-melanoma skin cancer, basal cell carcinoma, squamous cell carcinoma',
    'Deal Structure':
      'Interactive Capital Partners Corporation (the "Company") is pleased to announce that it has entered into a letter of intent with SkinJect, Inc. ("SkinJect"), effective April 17, 2023, to complete a proposed reverse takeover transaction and apply for listing on the TSX Venture Exchange ("TSXV") (the "Proposed Business Combination"). Upon completion of the Proposed Business Combination, (i) SkinJect will become a wholly-owned operating subsidiary of the Company, (ii) the Company is expected to be renamed "Medicus Pharma" (the "Resulting Issuer") and will carry on the business of pharmaceutical drug development focused initially on SkinJect, and (iii) shareholders of SkinJect and subscribers in the Concurrent Financing (defined below) will exchange their shareholdings for a substantial majority equity interest in the Resulting Issuer.',
    'FW Link1':
      'Interactive Capital Partners Corporation Announces Proposed Business Combination With SkinJect, Inc.;;https://firstwordpharma.com/story/5729341/'
  },
  {
    'Date Announced': '19-Apr-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Oncotelic Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Vectara',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Artificial intelligence, immunotherapy, RNA therapeutics, small molecules',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Oncotelic Therapeutics, Inc. (“Oncotelic”, “We” or the “Company”) (OTCQB:OTLC) announced a strategic partnership with Vectara, a company specializing in innovative Natural Language Understanding (NLU) technologies, to accelerate the development of novel therapies for the treatment of cancer, infectious disease, and aging.',
    'FW Link1':
      'Oncotelic Therapeutics and Vectara Announce Strategic Partnership to Transform Pharmaceutical Research with Large Language Model Technology;;https://firstwordpharma.com/story/5729620/'
  },
  {
    'Date Announced': '19-Apr-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Oxford BioTherapeutics',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner':
      "Groupe d'Oncologie Radiothérapie Tête Et Cou (GORTEC)",
    'Country (Target Company/Partner)': 'France',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'OBT076',
    'Indication(s)': 'Adenoid cystic carcinoma',
    'Target(s)': 'CD205',
    'Deal Structure':
      "Oxford BioTherapeutics Ltd. (OBT), a clinical stage oncology company with a pipeline of immuno-oncology and antibody-drug conjugate (ADC)-based therapies, announces it has entered into a collaboration with Groupe d'Oncologie Radiothérapie Tête Et Cou (GORTEC), an established, internationally renowned, European oncology and radiotherapy consortium specialising in clinical and laboratory research specifically for the benefit of head and neck cancer patients, to undertake a new Phase 1b trial investigating OBT’s lead asset, OBT076, in patients with Adenoid Cystic Carcinoma (ACC).",
    'FW Link1':
      'Oxford BioTherapeutics announces Phase 1b trial in collaboration with GORTEC to investigate OBT076 in Adenoid Cystic Carcinoma (ACC) of the head and neck;;https://firstwordpharma.com/story/5729563/'
  },
  {
    'Date Announced': '20-Apr-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'LG Chem',
    'Country (Acquiring Company/Partner)': 'Korea, South',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Samyang Holdings',
    'Country (Target Company/Partner)': 'Korea, South',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Drug delivery, mRNA therapeutics',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Samyang Holdings and LG Chem have announced their recent execution of strategic partnership agreement on Thursday, April 20th, for development of novel mRNA-based cancer therapeutics that utilizes Samyang\'s proprietary drug delivery technology. Under the terms of the agreement, Samyang Holdings grants non-exclusive rights to "NanoReady" technology and is responsible for supplying its key components to LG Chem, and LG Chem will be implementing "NanoReady" technology to develop innovative mRNA-based cancer therapeutics with maximal therapeutic effects. In return, LG Chem is required to pay upfront and a series of milestone payments upon completion of each developmental objective as in the terms agreed by both parties.',
    'FW Link1':
      'Samyang Holdings Enters Partnership with LG Chem for the Development of Innovative Cancer Therapeutics;;https://firstwordpharma.com/story/5730321/'
  },
  {
    'Date Announced': '20-Apr-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'SkylineDx',
    'Country (Acquiring Company/Partner)': 'Netherlands',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'NeraCare',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics, proteomics',
    'Lead Asset(s)': 'Immunoprint',
    'Indication(s)': 'Melanoma',
    'Deal Structure':
      "SkylineDx, an innovative diagnostics company focused on research & development of molecular diagnostics for oncology and inflammatory diseases, announced its first non-academic strategic partnership with NeraCare GmbH from Frankfurt, Germany to co-develop a test to help life science companies bring lifesaving melanoma therapies to market. The agreement includes an exclusive co-development and licensing agreement for Immunoprint®. SkylineDx and NeraCare will work together to accelerate the development of Immunoprint®, while leveraging SkylineDx's global network, and discovery and validation expertise. The companies will present prospective and archival validation data on the potential impact of Immunoprint® for patient selection at the 2023 European Association of Dermato-Oncology congress.",
    'FW Link1':
      'SkylineDx and NeraCare enter a strategic partnership to advance precision medicine for early-stage melanoma;;https://www.firstwordhealthtech.com/story/5730437/'
  },
  {
    'Date Announced': '20-Apr-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'BMS',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Tubulis',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Upfront Value ($m)': '23',
    'Total Deal Value ($m)': '1,000',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      "Bristol Myers Squibb entered into an agreement with Tubulis potentially worth over $1 billion to develop differentiated antibody-drug conjugates (ADCs) to treat solid tumours, the companies announced Thursday. Under the deal, Bristol Myers Squibb will gain exclusive rights to Tubulis' Tubutecan payloads and P5 conjugation platform. As part of the agreement, Bristol Myers Squibb will make an upfront payment of $22.75 million to Tubulis, with the latter eligible to receive over $1 billion in milestones plus royalties on marketed products. The drugmakers noted that following antibody target selection by Bristol Myers Squibb, Tubulis will provide its linker-payload to generate a uniquely matched ADC for each antibody. Bristol Myers Squibb will assume sole responsibility for development, manufacturing and commercialisation.",
    'FW Link1':
      'BMS tabs Tubulis to develop solid tumour antibody-drug conjugates;;https://firstwordpharma.com/story/5730074/'
  },
  {
    'Date Announced': '11-Apr-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Algok Bio',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'BTG',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'idetrexed',
    'Indication(s)': 'Ovarian cancer',
    'Target(s)': 'Folate receptor alpha',
    'Deal Structure':
      "Algok Bio's exclusive license agreement with BTG grants the former the rights to develop and commercialize idetrexed worldwide. Under the agreement, Algok Bio will make an upfront payment to BTG and additional payments contingent on achieving specific regulatory and sales milestones, as well as tiered royalty payments. Algok Bio will soon initiate pivotal studies for registering idetrexed for ovarian cancer treatment. Additional clinical programs exploring other indications and combination regimens with standard care will also be pursued.",
    'FW Link1':
      'Algok Bio Enters into Exclusive Worldwide License Agreement with BTG to Develop and Commercialize Idetrexed, a Clinical-Stage Folate Receptor-Targeted Oncology Drug;;https://firstwordpharma.com/story/5730564/'
  },
  {
    'Date Announced': '24-Apr-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Cizzle Biotechnology',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'University of York',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Biomarkers, diagnostics',
    'Indication(s)': 'Cancer, lung cancer',
    'Deal Structure':
      "Cizzle Biotechnology, the UK based diagnostics developer, is pleased to announce that following successful results from its current research programme with the University of York, due to end this year, that it has today signed a new research agreement with the University for the further development and validation of its CIZ 1B biomarker technology for early stage cancer diagnosis and other potential applications in cancer therapy. This includes the evaluation of the Company's proprietary biomarker for detecting a range of cancers in addition to the existing programme for early lung cancer detection. This new agreement will extend the work being conducted at the university until 25th September 2024 and follows the successful programme initially announced on 17 September 2021 and further extended to June 2023. As in previous agreements, Cizzle Biotechnology will own all intellectual property rights arising from the work which strengthens the Company's position in creating new solutions for early cancer diagnostics and therapeutic tools.",
    'FW Link1':
      'New Research Agreement with the University of York for Cancer Diagnosis and Therapy;;https://firstwordpharma.com/story/5731083/'
  },
  {
    'Date Announced': '25-Apr-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'ALX Oncology',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Sanofi',
    'Country (Target Company/Partner)': 'France',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Fusion proteins, monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'evorpacept, Sarclisa',
    'Indication(s)': 'Multiple myeloma',
    'Target(s)': 'CD47, CD38',
    'Deal Structure':
      'Under the terms of the agreement, Sanofi will conduct a Phase 1/2 study to evaluate the safety, efficacy, pharmacokinetics and biomarker data of evorpacept in combination with SARCLISA and dexamethasone in patients with RRMM. Part 1 will evaluate dosing of evorpacept in combination with standard doses of SARCLISA and dexamethasone to identify a recommended dose. Part 2 will investigate the efficacy and safety of this combination in an expanded population of patients with RRMM. ALX Oncology will supply evorpacept and Sanofi will supply SARCLISA for this study.',
    'FW Link1':
      'ALX Oncology Announces Clinical Trial Collaboration with Sanofi to Evaluate Evorpacept in Combination with SARCLISA (isatuximab-irfc) in Patients with Multiple Myeloma;;https://firstwordpharma.com/story/5731794/'
  },
  {
    'Date Announced': '25-Apr-2023',
    'Deal type': 'Terminated',
    'Acquiring Company/Partner': 'TRACON Pharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'I-Mab',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immuno-oncology, antibodies',
    'Lead Asset(s)': 'uliledlimab',
    'Indication(s)': 'Cancer',
    'Target(s)': 'CD73',
    'Deal Structure':
      'I-Mab (the "Company") (Nasdaq: IMAB), a clinical-stage biopharmaceutical company committed to the discovery, development, and commercialization of novel biologics, announced the positive outcome of its arbitration with TRACON Pharmaceuticals ("Tracon") relating to (1) the collaboration agreement to co-develop I-Mab\'s proprietary CD73 antibody, uliledlimab (TJD5) (the "TJD5 Agreement"), and (2) the collaboration agreement to potentially co-develop I-Mab\'s bi-specific antibodies (the "BsAb Agreement"). The arbitration award concluded the multi-year long dispute between I-Mab and Tracon. The award determined that the TJD5 Agreement has been terminated for a pre-agreed termination fee of $9 million plus interest payable by I-Mab pursuant to the original TJD5 Agreement, and, therefore Tracon has no rights to share any future economics with I-Mab. The arbitration award completely denied Tracon\'s groundless damages claim of over $200 million for any breach under the BsAb Agreement and awarded no damages to Tracon. The Tribunal also confirmed the termination of the BsAb Agreement. Based on the arbitration award, I-Mab will bear a portion of Tracon\'s legal fees and costs, totaling approximately $13.5 million.',
    'FW Link1':
      'I-Mab Announces Positive Outcome in Arbitration Relating to Agreements with Tracon;;https://firstwordpharma.com/story/5731668/'
  },
  {
    'Date Announced': '25-Apr-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Guardant Health',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Parker Institute for Cancer Immunotherapy',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Biomarkers, immunotherapy',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Guardant Health, Inc. (Nasdaq: GH), a leading precision oncology company, and the Parker Institute for Cancer Immunotherapy (PICI), a network of the largest concentration of immuno-oncology (IO) expertise in the world, announced a research collaboration agreement to study the correlation between molecular cancer biomarkers and patient response to immunotherapy treatment across more than 14 different types of cancer. Under the agreement, Guardant Health will analyze patient blood samples from PICI’s RADIOHEAD (Resistance Drivers for Immuno-Oncology Patients Interrogated by Harmonized Molecular Datasets) prospective study of 1,200 individuals on standard-of-care immune checkpoint inhibitor (ICI) treatment regimens in community hospitals. The testing, which will be conducted with GuardantINFINITY, will provide a multi-dimensional analysis of each patient’s tumor response based on both genomic and epigenomic profiling. The study will use blood samples drawn at multiple points during the treatment regimen and will assess the connection between the presence of specific cancer biomarkers and real-world clinical outcomes, including survival and immune-related adverse events.',
    'FW Link1':
      'Guardant Health and Parker Institute for Cancer Immunotherapy (PICI) launch research collaboration to study connection between cancer biomarkers and immunotherapy treatment response;;https://firstwordpharma.com/story/5731993/'
  },
  {
    'Date Announced': '25-Apr-2023',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'D-1553',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Spectrum Pharmaceuticals',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '248',
    'Total Deal Value ($m)': '291',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Growth factors',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Rolvedon',
    'Indication(s)': 'Chemotherapy-induced neutropenia',
    'Deal Structure':
      "Spectrum Pharmaceuticals agreed to be acquired by Assertio, less than six months after failing to secure FDA approval of poziotinib for lung cancer patients harbouring HER2 exon 20 insertion mutations. The deal announced Tuesday includes a stock component worth $248 million to Spectrum's shareholders, as well as contingent value rights (CVRs) totalling $43 million linked to the company's sole marketed drug Rolvedon (eflapegrastim-xnst).",
    'FW Link1':
      'Spectrum to be swallowed by Assertio;;https://firstwordpharma.com/story/5731677/',
    'FW Link2':
      'Completion of acquisition announced August 1, 2023;;https://firstwordpharma.com/story/5766010/'
  },
  {
    'Date Announced': '27-Apr-2023',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Healthwell Acquisition',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Starton Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Continuous drug delivery',
    'Lead Asset(s)': 'STAR-LLD',
    'Indication(s)': 'Multiple myeloma, chronic lymphocytic leukaemia',
    'Deal Structure':
      'Starton Therapeutics, Inc. (“Starton” or “the Company”), a clinical-stage biotechnology company focused on transforming standard-of-care therapies with proprietary continuous delivery technology, and Healthwell Acquisition Corp. I (Nasdaq: HWEL) (“Healthwell”), a special purpose acquisition company, announced that they have entered into a definitive business combination agreement (the “Business Combination Agreement”) pursuant to which, among other things, HWEL Holdings Corp., a newly formed wholly-owned subsidiary of Healthwell (“Pubco”), has agreed to acquire Starton and become a publicly traded company (the “Transaction”). Upon the closing of the Transaction, which is expected to occur during the second half of 2023, Pubco will be renamed Starton Holdings Corp., and each share of common stock and warrant of Healthwell will be exchanged, on a one-for-one basis, for shares of common stock and warrants of Pubco. After the consummation of the Transaction, the common stock and warrants of Pubco are expected to be listed on the Nasdaq and Starton and Healthwell will each become wholly-owned subsidiaries of Pubco.',
    'FW Link1':
      'Starton Therapeutics Inc. and Healthwell Acquisition Corp. I to Combine and Create Publicly Traded Leader in Proprietary Continuous Delivery Technology for Cancer Treatments;;https://firstwordpharma.com/story/5732952/'
  },
  {
    'Date Announced': '27-Apr-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Generate:Biomedicines',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'University of Texas MD Anderson Cancer Center',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Protein therapeutics, artificial intelligence',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)':
      'Cancer, small-cell lung cancer, non-small-cell lung cancer',
    'Deal Structure':
      'Generate:Biomedicines and The University of Texas MD Anderson Cancer Center announced a strategic collaboration to jointly discover and co-develop protein therapeutics for up to five oncology targets in advanced cancers, including small-cell and non-small-cell lung cancer. Under the co-development and commercialization agreement, MD Anderson and Generate:Biomedicines will each contribute toward creating optimized, potentially best-in-class therapeutics that can rapidly advance into proof-of-concept clinical trials. Under the agreement terms, Generate:Biomedicines and MD Anderson will share research and development expenses as well as funds generated through commercialization of products that emerge from the collaboration. The organizations also anticipate that MD Anderson will serve as a site and recommend lead investigators for Phase I and II clinical trials of any jointly developed therapeutic product candidates.',
    'FW Link1':
      'Generate:Biomedicines and MD Anderson Enter Co-Development and Commercialization Agreement to Accelerate Novel Protein Therapeutics for Oncology Using Generative AI;;https://firstwordpharma.com/story/5732959/'
  },
  {
    'Date Announced': '27-Apr-2023',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Quest Diagnostics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Haystack Oncology',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '300',
    'Total Deal Value ($m)': '450',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics, liquid biopsy',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Quest Diagnostics (NYSE: DGX), the nation's leading provider of diagnostic information services, and Haystack Oncology, an early-stage oncology company focused on minimal residual disease (MRD) testing to aid in the early, accurate detection of residual or recurring cancer and better inform therapy decisions, announced a definitive agreement for Quest to acquire Haystack in an all-cash equity transaction. Under the terms of the agreement, Quest will pay $300 million in cash at closing, net of cash acquired, and up to an additional $150 million on achieving future performance milestones. The company expects the acquisition to be modestly dilutive to earnings over the next three years and accretive by 2026. The transaction is expected to be completed in the second quarter, subject to customary regulatory review.",
    'FW Link1':
      'Quest Diagnostics to Acquire Haystack Oncology, Adding Sensitive Liquid Biopsy Technology for Improving Personalized Cancer Care to Oncology Portfolio;;https://www.firstwordhealthtech.com/story/5732793/',
    'FW Link2':
      'Completion of acquisition announced June 21, 2023;;https://www.firstwordhealthtech.com/story/5753539/'
  },
  {
    'Date Announced': '27-Apr-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Tavros Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'OpenBench',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Tavros Therapeutics, Inc., a precision oncology platform company leading the new frontier of targeted therapies exploiting tumors’ genetic vulnerabilities, and OpenBench, Inc., a screening platform pioneering success-driven small molecule discovery partnerships, announced the companies have entered into a collaboration agreement to discover small molecule modulators of up to five oncology targets across an initial 18-month term. The agreement expands the companies’ original collaboration to pursue a first-in-class cancer target, announced in December 2021. Under the terms of the agreement, OpenBench will receive an upfront payment for their services and payment upon the identification and experimental confirmation of novel small molecule chemotypes that meet Tavros' potency and developability criteria. In exchange, Tavros gains exclusive access to OpenBench's proprietary screening technology with respect to the promising cancer targets. Financial terms were not disclosed.",
    'FW Link1':
      'Tavros Therapeutics and OpenBench Expand Strategic Collaboration for Small Molecule Drug Discovery;;https://firstwordpharma.com/story/5733148/'
  },
  {
    'Date Announced': '1-May-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Sanofi',
    'Country (Acquiring Company/Partner)': 'France',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Roche',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Clinical trial data, electronic data capture, electronic health records',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Flatiron Health announced a new collaboration with Sanofi in their pursuit to redesign the clinical trial experience for sponsors, sites, and patients in the therapeutic area of oncology. The multi-study collaboration between Sanofi and Flatiron will focus on improving clinical trial data acquisition, delivery, and quality through site-facing technology that digitally transfers data captured in the electronic health record (EHR) directly into the electronic data capture (EDC) system through Flatiron Health’s EHR-to-EDC product, Flatiron Clinical Pipe™. Clinical trial sites will be able to reduce time spent on both manual, high-volume data entry and downstream data resolution issues, so that they have more time to spend with patients.',
    'FW Link1':
      'Flatiron Health Embarks on Strategic Collaboration with Sanofi to Make Clinical Trials More Efficient by Streamlining Data Acquisition at the Point of Care;;https://firstwordpharma.com/story/5733931/'
  },
  {
    'Date Announced': '1-May-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'BMS',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Immatics',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Upfront Value ($m)': '15',
    'Total Deal Value ($m)': '505',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'TCR-T cell therapy',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      'Bristol Myers Squibb exercised its first option and entered into a global license agreement with Immatics for the most advanced TCR-T product candidate from the companies’ ongoing collaboration to develop four TCR-based adoptive cell therapies targeting solid tumors. Immatics to receive an option payment of $15 million and is eligible for additional up to $490 million in milestone payments in addition to tiered royalties on net sales of the product.',
    'FW Link1':
      'Immatics Announces First Bristol Myers Squibb Opt-in of TCR-T Candidate from Ongoing Multi-target Strategic Collaboration;;https://firstwordpharma.com/story/5733882/'
  },
  {
    'Date Announced': '2-May-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'J&J',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Cellular Biomedicine Group',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '245',
    'Geographic Remit of Deal': 'Global excl. Greater China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'CAR-T therapy',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'C-CAR039, C-CAR066',
    'Indication(s)': "B-cell malignancies, non-Hodgkin's lymphoma",
    'Target(s)': 'CD20, CD19',
    'Deal Structure':
      "Johnson & Johnson's Janssen Biotech unit will pay $245 million upfront as part of an agreement with Cellular Biomedicine Group (CBMG) to develop CD20-directed autologous CAR T-cell therapies for the treatment of B-cell malignancies, the companies announced Tuesday. The deal covers C-CAR066, as well as the bi-specific C-CAR039, which also targets CD19. Under the agreed terms, CBMG will grant Janssen a global license to develop and commercialise the CAR-T assets, except in Greater China, where the companies will negotiate an option for Janssen to market the products. Along with the upfront payment, CBMG is eligible for additional future milestones, as well as tiered sales royalties worldwide, excluding Greater China.",
    'FW Link1':
      'J&J nabs rights to anti-CD20 CAR-T therapies from Cellular Biomedicine;;https://firstwordpharma.com/story/5734514/'
  },
  {
    'Date Announced': '1-May-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Curium',
    'Country (Acquiring Company/Partner)': 'France',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Curasight',
    'Country (Target Company/Partner)': 'Denmark',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics, imaging',
    'Indication(s)': 'Prostate cancer',
    'Deal Structure':
      'Under the terms of the agreement, Curasight will develop its proprietary uTRACE® technology for use in prostate cancer until regulatory approval is granted in the EU and USA. Curium will have responsibility for the commercial manufacture of uTRACE® and world-wide commercialization. Curasight is eligible to receive up to USD 70 mn in development and commercial milestones as well as double-digit percentage royalties on sales in major markets upon eventual commercialization.',
    'FW Link1':
      'Curasight and Curium announce global partnership for uTRACE in prostate cancer;;https://www.firstwordhealthtech.com/story/5734543/'
  },
  {
    'Date Announced': '2-May-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'BrightPath',
    'Country (Acquiring Company/Partner)': 'Japan',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Artisan Bio',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'iNKT cell therapy, CRISPR gene editing',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      "Under this agreement, BrightPath will pay Artisan an upfront payment and research milestones for the development of CRISPR guides for the editing of BrightPath's iNKT cells. BrightPath has the right to a commercial license for the STAR-CRISPR guides and nuclease for a license fee plus development milestones, sales milestones, and a royalty on future products.",
    'FW Link1':
      "BrightPath Biotherapeutics and Artisan Bio Announce Research and Licensing Agreement to Accelerate Development of BrightPath's Novel Allogeneic iPSC Derived Cell Therapy Platform across Multiple Indications;;https://firstwordpharma.com/story/5734877/"
  },
  {
    'Date Announced': '2-May-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Plus Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Piramal Pharma',
    'Country (Target Company/Partner)': 'India',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Radiotherapeutics',
    'Lead Asset(s)': 'rhenium (186Re) obisbemeda',
    'Indication(s)': 'Central nervous system cancer',
    'Deal Structure':
      'Plus Therapeutics, Inc. (Nasdaq: PSTV) (the “Company”), a clinical-stage pharmaceutical company developing targeted radiotherapeutics with advanced platform technologies for central nervous system cancers, today announced an expansion of its collaboration with Piramal Pharma Solutions (PPS) to produce additional cGMP liposome intermediate drug product to meet the increase in demand for its lead investigational targeted radiotherapeutic, rhenium (186Re) obisbemeda, for ongoing and planned clinical trials. The new supply agreement builds on the Master Services Agreement which the Company and PPS entered into in 2021 for the development, manufacture and supply of rhenium (186Re) obisbemeda.',
    'FW Link1':
      'Plus Therapeutics Expands Collaboration with Piramal Pharma Solutions to Meet Increase in Investigational Drug Demand for Ongoing and Planned Clinical Trials;;https://firstwordpharma.com/story/5734526/'
  },
  {
    'Date Announced': '2-May-2023',
    'Deal type': 'Product Acquisition',
    'Acquiring Company/Partner': 'Eterna Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Exacis Biotherapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapy',
    'Indication(s)': 'Haematologic and solid tumours',
    'Deal Structure':
      'Eterna Therapeutics Inc. (Nasdaq: ERNA) (“Eterna” or the “Company”), a life science company committed to realizing the potential of mRNA cell engineering to provide patients with transformational new medicines, announced the acquisition of the global immuno-oncology platform of Exacis Biotherapeutics (“Exacis”). The acquisition complements Eterna’s core business with a pipeline of allogeneic immuno-oncology products under development for the treatment of hematologic and solid tumors and provides Eterna with an exclusive global license to produce an unlimited number of mRNA-engineered natural killer (NK) and T-cell therapies derived from induced pluripotent stem cells (iPSCs). The terms of the acquisition include an upfront payment in shares of Eterna common stock, as well as milestone and other potential payments totaling up to $49.0 million.',
    'FW Link1':
      'Eterna Therapeutics Acquires Allogeneic Immuno-Oncology Platform from Exacis Biotherapeutics;;https://firstwordpharma.com/story/5734720/'
  },
  {
    'Date Announced': '3-May-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'iOnctura',
    'Country (Acquiring Company/Partner)': 'Netherlands',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Clavius Pharmaceuticals',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'IOA-359',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'TGF-beta pathway',
    'Deal Structure':
      'iOnctura, a clinical-stage biotech developing selective cancer therapies against targets that play critical roles in multiple tumor survival pathways, today announces it has exercised an exclusive option with Clavius Pharmaceuticals, adding the novel oral TGF-β pathway inhibitor, IOA-359, to its pipeline. Under the terms of the licence agreement, iOnctura is solely responsible for the global development and commercialisation of the small molecule inhibitor.',
    'FW Link1':
      'iOnctura expands pipeline and receives development grant from Health Holland and KWF;;https://firstwordpharma.com/story/5735000/'
  },
  {
    'Date Announced': '4-May-2023',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'Paige',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Visiopharm',
    'Country (Target Company/Partner)': 'Denmark',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics, artificial intelligence',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Paige, a global leader in end-to-end digital pathology solutions and clinical AI applications to assist in diagnosing cancer, announced it is working with Visiopharm, an artificial intelligence (AI)-driven precision pathology software provider, to bring a wider range of AI products to doctors and patients. Visiopharm’s AI applications will be deployed and natively integrated into the Paige Platform to be accessed through FullFolio™, Paige’s AI-powered clinical worklist, and viewed within FullFocus®, Paige’s whole-slide image viewer. This deep integration offers users greater diagnostic efficiency and quality insights when reviewing IHC-stained slides and creates a seamless user experience for Visiopharm’s image analysis applications, to help pathologists deliver faster, more accurate and more reproducible cancer diagnoses.',
    'FW Link1':
      'Paige and Visiopharm Work Together to Deliver Advanced AI Cancer Diagnostic Support Through the Paige Platform;;https://www.firstwordhealthtech.com/story/5735761/'
  },
  {
    'Date Announced': '4-May-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Novartis',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Adalvo',
    'Country (Target Company/Partner)': 'Malta',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'United States',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Generic drugs',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Sandoz, a global leader in generic pharmaceuticals and biosimilars, announced that it has signed a distribution and collaboration agreement with Adalvo for exclusive Sandoz rights to commercialize six products in the US across key therapeutic areas, including antifungal/antibiotic, oncology and pulmonary. These products, which are slated for near- to mid-term launches beginning in 2024, have a total addressable market size of approximately USD 3 bn, further advancing the Sandoz product pipeline in the key US generics market.',
    'FW Link1':
      'Sandoz to exclusively commercialize six products in the US, reinforcing global leadership position in off-patent medicines;;https://firstwordpharma.com/story/5735501/'
  },
  {
    'Date Announced': '4-May-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'OncoHost',
    'Country (Acquiring Company/Partner)': 'Israel',
    'Region (Acquiring Company/Partner)': 'Middle East',
    'Target Company/Partner': 'BGN Technologies',
    'Country (Target Company/Partner)': 'Israel',
    'Region (Target Company/Partner)': 'Middle East',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics, immunotherapy',
    'Indication(s)': 'Cancer',
    'Target(s)': 'PD-1',
    'Deal Structure':
      'OncoHost, a technology company transforming the approach to precision medicine for improved patient outcomes, announced that it has signed an option agreement with BGN Technologies, the technology transfer company of Ben-Gurion University of the Negev (BGU), to receive a license for a novel biosensing technology called IcAR (Immuno-checkpoint Artificial Reporter). IcAR was developed by a team of researchers from BGU.',
    'FW Link1':
      'OncoHost Collaborates with BGN Technologies to Predict Cancer Patient Response to Anti-PD-1 Treatment with IcAR Biosensing Tech;;https://www.firstwordhealthtech.com/story/5735689/'
  },
  {
    'Date Announced': '4-May-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Bicycle Therapeutics',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'DKFZ',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Radiopharmaceuticals',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Bicycle Therapeutics plc (NASDAQ: BCYC), a biotechnology company pioneering a new and differentiated class of therapeutics based on its proprietary bicyclic peptide (Bicycle®) technology, announced that it has entered into a collaboration with the German Cancer Research Center (DKFZ), to develop and discover Bicycle® radio conjugates (BRCs) for a range of oncology targets.',
    'FW Link1':
      'Bicycle Therapeutics Announces Collaboration With the German Cancer Research Center, to Discover and Develop Wholly Owned Bicycle Radio Conjugates for a Range of Oncology Targets;;https://firstwordpharma.com/story/5735586/'
  },
  {
    'Date Announced': '4-May-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'XNK Therapeutics',
    'Country (Acquiring Company/Partner)': 'Sweden',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Undisclosed',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'NK cell therapy, antibodies',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'XNK04',
    'Indication(s)': 'Hepatocellular carcinoma',
    'Target(s)': 'PD-L1',
    'Deal Structure':
      'XNK Therapeutics AB ("XNK") announced that the company\'s autologous natural killer (NK) cell therapy candidate XNK04 will be evaluated in combination with an ADCC competent PD-L1 inhibitor in liver cancer under a research agreement with a global pharma company. XNK retains all commercial rights to XNK04.',
    'FW Link1':
      'XNK Therapeutics Enters into Research Agreement with Global Pharma Company;;https://firstwordpharma.com/story/5735499/'
  },
  {
    'Date Announced': '7-May-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Dr. Reddy’s',
    'Country (Acquiring Company/Partner)': 'India',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Junshi Biosciences',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Total Deal Value ($m)': '728',
    'Geographic Remit of Deal':
      'Brazil, Mexico, Colombia, Argentina, Peru, Chile, Panama, Uruguay, India, South Africa, Australia, New Zealand',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset(s)': 'toripalimab',
    'Indication(s)': 'Cancer',
    'Target(s)': 'PD-1',
    'Deal Structure':
      'Under the license and commercialization agreement, Junshi Biosciences will grant a licence to Dr. Reddy’s to develop and exclusively commercialize toripalimab in Brazil, Mexico, Colombia, Argentina, Peru, Chile, Panama, Uruguay, India and South Africa1. Dr. Reddy’s may elect to expand the scope of the license to cover Australia, New Zealand and nine other countries2. Under the terms of the agreement, Junshi Biosciences also grants Dr. Reddy’s the exclusive right of first negotiation for commercialization, in the event that Junshi Biosciences determines to grant any third party the rights to commercialize two other products as agreed in the agreement in one or more countries within the total 21 countries of Dr. Reddy’s Territory3. Junshi Biosciences may receive up to an aggregate of US$728.3 million for upfront payment, potential expansion of Dr. Reddy’s Territory and milestone payment, plus double-digit percentage of royalties on the net sales of products containing toripalimab.',
    'FW Link1':
      'Junshi Biosciences Announces Collaboration with Dr. Reddy’s to Develop and Commercialize Toripalimab in 21 Countries;;https://firstwordpharma.com/story/5736650/'
  },
  {
    'Date Announced': '5-May-2023',
    'Deal type': 'Terminated',
    'Acquiring Company/Partner': 'AstraZeneca',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Transgene',
    'Country (Target Company/Partner)': 'France',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Oncolytic viruses',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'AstraZeneca decided to terminate an agreement with Transgene focused on the development of oncolytic viruses following a "strategic review" of its pipeline. The deal, signed in 2019, covered the co-development of five armed oncolytic vaccinia virus candidates utilising Transgene\'s Invir.IO next-generation viral platform. In 2021, AstraZeneca exercised its first license option under the collaboration for an oncolytic virus integrating an undisclosed transgene. Transgene said Friday that following the termination of the agreement, it will regain global rights to the oncolytic virus drug candidate, which has been cleared to begin clinical studies in the US. The company noted that the ending of the deal will have "limited impact" on its financial guidance.',
    'FW Link1':
      'AstraZeneca pulls plug on oncolytic virus collaboration with Transgene;;https://firstwordpharma.com/story/5736117/',
    'FW Link2':
      'Exercise of first licence option announced December 15, 2021;;https://firstwordpharma.com/story/5462481/'
  },
  {
    'Date Announced': '7-May-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Bayer',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Telix',
    'Country (Target Company/Partner)': 'Australia',
    'Region (Target Company/Partner)': 'Oceania',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics, small molecules',
    'Lead Asset(s)': 'Illuccix, darolutamide',
    'Indication(s)': 'Prostate cancer',
    'Target(s)': 'Androgen receptor',
    'Deal Structure':
      "Telix Pharmaceuticals Limited (ASX: TLX, Telix, the Company) announces that it has entered into an agreement with Bayer AG (Bayer) to supply Illuccix® (TLX591-CDx, kit for the preparation of gallium Ga 68 gozetotide injection)[1] for the Phase III ARASTEP study. This global study is investigating the efficacy of Bayer's androgen receptor inhibitor (ARi) darolutamide plus androgen deprivation therapy (ADT) versus ADT alone in hormone-sensitive prostate cancer, in patients with high-risk biochemical recurrence who have no evidence of metastatic disease by conventional imaging and a positive PSMA-PET/CT[2] at baseline.",
    'FW Link1':
      'Telix to Supply Bayer with Illuccix for Global Phase III Prostate Cancer Study;;https://firstwordpharma.com/story/5736681/'
  },
  {
    'Date Announced': '7-May-2023',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'AstraZeneca',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner':
      'Cancer Council of the Philippines, Philippine Business for Social Progress',
    'Country (Target Company/Partner)': 'Phillipines',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Philippines',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial intelligence, diagnostics',
    'Indication(s)': 'Lung cancer',
    'Deal Structure':
      'AstraZeneca has partnered with the Cancer Council of the Philippines (CCPH) and the Philippine Business for Social Progress (PBSP) to allow 500,000 Filipinos to be screened against lung cancer using an artificial intelligence (AI)-assisted chest X-ray technology.',
    'FW Link1':
      'AI-assisted X-ray to be used in lung cancer screening;;https://www.firstwordhealthtech.com/story/5736680/'
  },
  {
    'Date Announced': '8-May-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Eisai',
    'Country (Acquiring Company/Partner)': 'Japan',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Bliss Biopharmaceutical',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Total Deal Value ($m)': '2,000',
    'Geographic Remit of Deal': 'Global excl. Greater China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'BB-1701',
    'Indication(s)': 'HER2-expressing solid tumours',
    'Target(s)': 'HER2',
    'Deal Structure':
      "Under the terms of the agreement, BlissBio will receive upfront and milestone payments, and BlissBio and Eisai will conduct co-development activities related to BB-1701 through an option period. Upon Eisai's exercise of its option to enter into a strategic collaboration license of BB-1701, BlissBio will receive an option exercise payment and be eligible for development and commercial milestone payments up to an aggregate of $2 billion, as well as royalties on sales. Upon the exercise of the option, Eisai will receive worldwide (excluding Greater China) rights to develop and commercialize BB-1701.",
    'FW Link1':
      'Eisai bets on BlissBio ADC for up to $2 billion in milestones;;https://firstwordpharma.com/story/5736800/'
  },
  {
    'Date Announced': '9-May-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Roche',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Zion Pharma',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Total Deal Value ($m)': '680',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'ZN-A-1041',
    'Indication(s)':
      'Brain metastases in patients with HER2-positive metastatic breast cancer',
    'Target(s)': 'HER2',
    'Deal Structure':
      'After a transition period, Roche will be responsible for the further development, manufacturing, and commercialization of ZN-A-1041 globally. Zion will receive up to $70 million USD in upfront and near-term milestone payments pending achievement of certain milestone events. Zion will also be eligible for up to $610 million USD in additional payments following achievement of certain development, regulatory, and sales-based milestone events by ZN-A-1041, as well as tiered royalties on sales.',
    'FW Link1':
      "Roche grabs rights to Zion's brain-penetrant HER2 drug;;https://firstwordpharma.com/story/5737374/"
  },
  {
    'Date Announced': '8-May-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'FibroGen',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Fortis Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates, biomarkers',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'FOR46, PET46',
    'Indication(s)': 'Prostate cancer, CD46-expressing cancers',
    'Target(s)': 'CD46',
    'Deal Structure':
      'FibroGen, Inc. (Nasdaq: FGEN) and Fortis Therapeutics announced that FibroGen has entered into an exclusive license with Fortis Therapeutics for FOR46, a potential first-in-class Phase 1 antibody-drug conjugate (ADC) targeting a novel epitope on CD46. FOR46 is being developed for the treatment of metastatic castration-resistant prostate cancer (mCRPC) and is being explored for use in other CD46 expressing cancers. As part of the clinical development strategy, FibroGen will continue Fortis Therapeutics’ work to develop a PET-based biomarker utilizing a radiolabeled version of the targeting antibody (PET46) for patient selection. Under the terms of the agreement, there is no upfront consideration. FibroGen will conduct and fund future research, development, and manufacturing of FOR46 and PET46. During the four-year evaluation period, FibroGen has the option to acquire Fortis Therapeutics for $80 million. In addition, Fortis is eligible to receive up to a total of $200 million based on various regulatory approvals.',
    'FW Link1':
      'FibroGen Enters into Exclusive License for FOR46 with Fortis Therapeutics;;https://firstwordpharma.com/story/5737125/'
  },
  {
    'Date Announced': '8-May-2023',
    'Deal type': 'Terminated',
    'Acquiring Company/Partner': 'EQRx',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'CStone Pharmaceuticals',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global excl. Greater China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'sugemalimab, nofazinlimab',
    'Indication(s)': 'Lung cancer, hepatocellular carcinoma',
    'Target(s)': 'PD-L1, PD-1',
    'Deal Structure':
      'EQRx has provided notice to CStone Pharmaceuticals (CStone) of its termination of the license agreement for sugemalimab and nofazinlimab. CStone will regain rights for the research, development and commercialization of sugemalimab and nofazinlimab outside Greater China. The parties are in discussions regarding their respective transition obligations.',
    'FW Link1':
      'EQRx cuts workforce as it looks to reboot business;;https://firstwordpharma.com/story/5737093/'
  },
  {
    'Date Announced': '9-May-2023',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'Pfizer',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Thermo Fisher',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Latin America, Africa, Middle East, Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics, next-generation sequencing',
    'Indication(s)': 'Lung cancer, breast cancer',
    'Deal Structure':
      "Pfizer and Thermo Fisher Scientific Inc. (NYSE: TMO) today announced they have entered into a collaboration agreement to help increase local access to next-generation sequencing (NGS)-based testing for lung and breast cancer patients in more than 30 countries across Latin America, Africa, the Middle East and Asia where advanced genomic testing has previously been limited or unavailable. Under the agreement, Thermo Fisher will identify local labs that will be using the company's NGS technology and ensure they have the necessary infrastructure, trained staff, and quality control measures to meet industry standards for NGS testing services for breast and lung cancer. Pfizer will explore ways to enable affordable patient access for NGS testing for these types of cancer and work to raise healthcare provider awareness regarding the benefits of advanced testing. The two companies will continue to evaluate opportunities to expand geographically and to expand testing for other types of cancer.",
    'FW Link1':
      'Thermo Fisher Scientific & Pfizer Partner to Expand Localized Access to Next-Generation Sequencing-Based Testing for Cancer Patients in International Markets;;https://firstwordpharma.com/story/5737633/'
  },
  {
    'Date Announced': '9-May-2023',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Gilead',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'XinThera',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'XIN5104',
    'Indication(s)': 'Cancer',
    'Target(s)': 'PARP1',
    'Deal Structure':
      'Gilead Sciences, Inc. (Nasdaq: GILD) announced the acquisition of all outstanding shares of XinThera, a privately held biotech company in San Diego. Through the acquisition, Gilead gains rights to a portfolio of small molecule inhibitors targeting PARP1 for oncology and MK2 for inflammatory diseases that could enter clinical trials later this year. The financial terms of the agreement were not disclosed.',
    'FW Link1':
      'Gilead tacks on some early PARP1, MK2 assets via XinThera buy;;https://firstwordpharma.com/story/5737375/'
  },
  {
    'Date Announced': '11-May-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Ariceum',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'UCB',
    'Country (Target Company/Partner)': 'Belgium',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Radiopharmaceuticals',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      "Under the terms of the research collaboration, UCB and Ariceum will utilize each other's proprietary technology platforms to enable the discovery of peptide-radioisotope conjugates as potential therapeutics for immune-related diseases and cancer. Ariceum will also gain access to UCB's expertise to discover novel synthetic macrocyclic peptides using its mRNA-display technology platform, ExtremeDiversity™. UCB will benefit from Ariceum's expertise in the field of radiochemistry and labelling technology to enhance its ability to explore how this technology might lead to the discovery of highly differentiated products for immune-related diseases. Both companies will have the opportunity to explore several targets under this research collaboration.",
    'FW Link1':
      'UCB and Ariceum Therapeutics sign a Strategic Research Collaboration to Discover New Modalities for the Treatment of Immune-related Diseases and Cancer;;https://firstwordpharma.com/story/5738522/'
  },
  {
    'Date Announced': '11-May-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Aspira Women’s Health',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Dana-Farber Cancer Institute',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'Indication(s)': 'Ovarian cancer',
    'Deal Structure':
      'Aspira Women’s Health Inc. (Nasdaq: AWH), a bio-analytical based women’s health company focused on gynecologic disease, announced it has signed an exclusive licensing agreement with the Dana-Farber Cancer Institute (“DFCI”) for the design and development of a new noninvasive miRNA-based ovarian cancer diagnostic tool. The new test will utilize circulating microRNAs, either alone or in conjunction with proteins or other factors, to identify ovarian cancer in women with adnexal masses. It will be offered to healthcare providers as an expansion of the Company’s OvaSuite porfolio.',
    'FW Link1':
      'Aspira Women’s Health Signs an Exclusive Licensing Agreement with Dana Farber Cancer Institute for the Development of microRNA-based Ovarian Cancer Test;;https://www.firstwordhealthtech.com/story/5739029/'
  },
  {
    'Date Announced': '11-May-2023',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'AmerisourceBergen',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Patient Discovery',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'United States',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Real-world data',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Patient Discovery Solutions, a leader in health equity solutions that harness the power of patient-centered technology and real-world data to help achieve better health outcomes, joins global healthcare company AmerisourceBergen's Trusted Vendor Program. The collaboration enables community oncology practices, hospitals, and health systems nationwide access to Patient Discovery's Equitable Care Platform, allowing care providers to proactively identify and address social determinants of health to better inform providers and help improve outcomes for cancer patients.",
    'FW Link1':
      'Patient Discovery Partners with AmerisourceBergen for Cancer Care Equity;;https://www.firstwordhealthtech.com/story/5738636/'
  },
  {
    'Date Announced': '12-May-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'AstraZeneca',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'LaNova Medicines',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Total Deal Value ($m)': '600',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'LM-305',
    'Indication(s)': 'Multiple myeloma',
    'Target(s)': 'GPRC5D',
    'Deal Structure':
      'Under the terms of the licensing agreement, AstraZeneca will be granted an exclusive global license to research, develop, and commercialize LM-305. LaNova Medicines is eligible to receive an upfront and near-term payments of up to $55 million and additional development and commercial milestone payments of up to $545 million, as well as tiered royalties on net sales worldwide.',
    'FW Link1':
      'LaNova Medicines Announces Global Exclusive License Agreement with AstraZeneca for LM-305, a Novel GPRC5D-Targeting Antibody Drug Conjugate;;https://firstwordpharma.com/story/5739608/'
  },
  {
    'Date Announced': '15-May-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Hummingbird Bioscience',
    'Country (Acquiring Company/Partner)': 'Singapore',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Merck KGaA',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'HMBD-001, cetuximab',
    'Indication(s)': 'Squamous non-small-cell lung carcinoma',
    'Target(s)': 'HER3, EGFR',
    'Deal Structure':
      "Under this collaboration, Hummingbird Bioscience will evaluate the safety, tolerability, and anti-tumor activity of its potentially best-in-class HER3-targeting antibody, HMBD-001, in combination with standard of care chemotherapy with or without Merck's cetuximab, anti-EGFR mAb, in patients with sqNSCLC in a Phase 1b clinical trial, to be initiated in the second half of 2023. Merck will supply cetuximab for the Hummingbird Bioscience-sponsored study and both parties will retain all commercial rights to their respective compounds.",
    'FW Link1':
      'Hummingbird Bioscience and Merck Enter Collaboration to Evaluate HMBD-001 In Squamous Non-Small Cell Lung Carcinoma;;https://firstwordpharma.com/story/5740082/'
  },
  {
    'Date Announced': '15-May-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Res Nova Bio',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Cure Stat Rx',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immunotherapy',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'FloraStilbene',
    'Indication(s)': 'Breast cancer',
    'Deal Structure':
      'Therapeutic Solutions International (TSOI) spin-off Res Nova Bio announced a collaboration with Cure Stat Rx, a premiere compounding Pharmacy in the manufacture of initiate doses of FloraStilbene™ for a planned 12 patient Phase I/II trial aimed at assessing immunomodulatory activity of the drug candidate in advanced breast cancer patients.',
    'FW Link1':
      'Therapeutic Solutions International Spin-Off Res Nova Bio Partners with Cure Stat Rx to Manufacture First in Class Breast Cancer Immunotherapy Product FloraStilbene™;;https://firstwordpharma.com/story/5740227/'
  },
  {
    'Date Announced': '15-May-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'BiOneCure',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Nanjing Leads Biolabs',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      'BiOneCure Therapeutics, Inc. has announced a strategic collaboration with Nanjing Leads Biolabs to develop a range of innovative anti-drug conjugates (ADCs) for treating solid tumors.',
    'FW Link1':
      'BiOneCure Therapeutics and Nanjing Leads Biolabs Join Forces to Develop Innovative Anti-Drug Conjugates (ADCs) for Solid Tumor Treatment;;https://firstwordpharma.com/story/5740188/'
  },
  {
    'Date Announced': '15-May-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'NUCLIDIUM',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'PharmaLogic',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'United States',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Radiopharmaceuticals, theranostics',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      "NUCLIDIUM and PharmaLogic Holdings announced that they have entered into a strategic collaboration agreement for the production and clinical supply of 61Cu in the United States to accelerate the development of NUCLIDIUM's theranostic pipeline. Under the terms of the agreement, NUCLIDIUM will provide PharmaLogic with the scientific know-how, proprietary technology, and raw material to enable PharmaLogic to safely and accurately produce high-quality 61Cu-radionuclides and radiopharmaceuticals in their manufacturing facilities throughout the US. The first product batch is planned to be manufactured in 2023 at PharmaLogic's location in Los Angeles, California. NUCLIDIUM will retain all rights to its proprietary platforms and technologies. Financial details of the collaboration were not disclosed.",
    'FW Link1':
      'NUCLIDIUM and PharmaLogic Form Strategic Partnership for Sustainable Development of Novel Copper-based Theranostics to Diagnose and Treat Solid Tumours;;https://firstwordpharma.com/story/5740054/'
  },
  {
    'Date Announced': '16-May-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Magle Group',
    'Country (Acquiring Company/Partner)': 'Sweden',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Chosa Oncology',
    'Country (Target Company/Partner)': 'Sweden',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cancer vaccines',
    'Lead Asset(s)': 'onilcamotide',
    'Indication(s)': 'Cancer',
    'Target(s)': 'RhoC',
    'Deal Structure':
      'CHOSA Oncology AB, today announces that the company has out-licensed the clinical-stage therapeutic cancer vaccine candidate onilcamotide to Magle Group. Under the licensing agreement, which covers onilcamotide and related intellectual properties, CHOSA Oncology AB will receive an upfront payment to recover costs related to patent fees. Magle Group shall take over the patent maintenance alongside a royalty obligation following the potential future commercialization of the product.',
    'FW Link1':
      'CHOSA Oncology AB out-license clinical-stage cancer vaccine candidate to Magle Group;;https://firstwordpharma.com/story/5740545/'
  },
  {
    'Date Announced': '16-May-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Astellas',
    'Country (Acquiring Company/Partner)': 'Japan',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Sony',
    'Country (Target Company/Partner)': 'Japan',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Astellas entered into a research agreement with Sony to develop antibody-drug conjugates (ADCs) employing the latter's KIRAVIA Backbone as a linker to deliver cancer drugs to targeted cells in a stable manner. Financial terms of the collaboration were not disclosed. The two companies jointly began exploratory research of new linker technology aimed at creating a new ADC platform in July 2022, and the expected profile was obtained in feasibility studies using human cancer cells. Under this agreement, Sony and Astellas will jointly develop and optimize a new ADC platform using the KIRAVIA Backbone as a linker. In addition, Astellas will conduct non-clinical trials of development candidates. Furthermore, in order to build a drug discovery platform not limited to ADC, the two companies have agreed to continue discussions on expanding research partnerships to create new value by combining Sony’s cutting-edge technology with Astellas’ renowned pharmaceutical capabilities.",
    'FW Link1':
      'Astellas partners with Sony to develop ADC platform for cancer;;https://firstwordpharma.com/story/5740544/'
  },
  {
    'Date Announced': '16-May-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Personalis',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Ono Pharmaceutical, National Cancer Center',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Biomarkers, personalised testing, monoclonal antibodies',
    'Lead Asset(s)': 'nivolumab',
    'Indication(s)': 'Rectal cancer',
    'Target(s)': 'PD-1',
    'Deal Structure':
      'Personalis, Inc. (Nasdaq: PSNL), National Cancer Center, and Ono Pharmaceutical Co., Ltd. announced they have entered into a collaborative agreement to examine the efficacy and safety of nivolumab, an immune checkpoint inhibitor, for resectable rectal cancer with mismatch repair deficiency (dMMR). As part of the collaboration on the VOLTAGE-2 study, an exploratory analysis will be conducted to evaluate specific biomarkers such as minimal residual disease (MRD) status that may have prognostic or predictive value for patient care. Under the agreement, National Cancer Center Hospital East (NCCHE) will recruit patients and conduct the clinical trial, Ono will provide nivolumab, andPersonalis will perform MRD and biomarker testing. Biomarker research will be conducted throughout the study, including sample analysis from both tumor lesion tissue and plasma circulating tumor DNA (ctDNA). The Personalis NeXT Personal® platform will be used to correlate MRD status with standard of care imaging and drug response data by monitoring variances in ctDNA. Tissue samples will be analyzed by the Personalis ImmunoID NeXT® platform to capture tumor molecular profile and tumor microenvironment features to better understand immunotherapy responses.',
    'FW Link1':
      'Personalis, National Cancer Center Hospital East, and Ono Collaborate to Better Predict Immunotherapy Response for Rectal Cancer;;https://firstwordpharma.com/story/5740728/'
  },
  {
    'Date Announced': '17-May-2023',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'BeiGene',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'The Max Foundation',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Brukinsa',
    'Indication(s)': 'Chronic lymphocytic leukaemia',
    'Target(s)': 'BTK',
    'Deal Structure':
      'The Max Foundation (Max), a global nonprofit organization dedicated to accelerating health equity by delivering medication, technology, and supportive services to patients worldwide, BeiGene, a global biotechnology company, and the BeiGene Foundation, a nonprofit charitable foundation, announced a collaboration to provide access to BRUKINSA (zanubrutinib) for the treatment of adult patients with chronic lymphocytic leukemia (CLL) in 29 low- and middle-income countries over the next three years. This collaboration advances each organization’s focus on patient access by combining Max’s expertise and infrastructure with a donated product from BeiGene, and a grant from the BeiGene Foundation.',
    'FW Link1':
      'The Max Foundation, BeiGene, and the BeiGene Foundation Announce Collaboration to Advance Health Equity by Providing Access to BRUKINSA for the Treatment of Chronic Lymphocytic Leukemia in Low- and Middle-Income Countries;;https://firstwordpharma.com/story/5741183/'
  },
  {
    'Date Announced': '17-May-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Servier',
    'Country (Acquiring Company/Partner)': 'France',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Aitia',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial intelligence',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Pancreatic cancer',
    'Deal Structure':
      "Under the terms of the collaboration, Aitia and Servier will work together to focus on translational questions to discover, validate, and strive to develop novel drug targets and drug candidates in pancreatic cancer through utilization of Aitia's Pancreatic Cancer Digital Twins and Servier's pre-clinical assays, therapeutic creation platforms, and expertise. Servier will receive an exclusive option to research, develop, and commercialize products directed to several novel drug targets.",
    'FW Link1':
      'Aitia Enters into Multi-Year AI-Driven Drug Discovery and Drug Simulation Collaboration with Servier for Pancreatic Cancer;;https://www.firstwordhealthtech.com/story/5741501/'
  },
  {
    'Date Announced': '17-May-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Takeda',
    'Country (Acquiring Company/Partner)': 'Japan',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'KSQ Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immuno-oncology, CRISPR',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'KSQ Therapeutics, (“KSQ”), a clinical-stage biotechnology company developing cancer therapies using its proprietary CRISPRomics® discovery platform, announced that it has expanded its strategic collaboration with Takeda to research and validate novel tumor-intrinsic targets. Under the terms of the agreement, Takeda will provide KSQ with an upfront payment and an investment in the double-digit millions of dollars. KSQ is also eligible to receive up to $510 million in future payments if all milestones are achieved during the term of the agreement, plus royalties on potential net sales of any commercial product resulting from the collaboration.',
    'FW Link1':
      'KSQ Therapeutics and Takeda Expand Strategic Immuno-Oncology Collaboration to Identify and Validate Novel Tumor Targets;;https://firstwordpharma.com/story/5741168/'
  },
  {
    'Date Announced': '18-May-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'RLS',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Evergreen Theragnostics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'United States',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'Lead Asset(s)': 'Octevy',
    'Indication(s)': 'Neuroendocrine tumours',
    'Deal Structure':
      'RLS (USA) Inc., the third-largest nuclear medicine pharmacy network in the US, and Evergreen Theragnostics, Inc., a radiopharmaceutical company focused on improving the available options for cancer patients, announced a new agreement for RLS to prepare, sell and distribute doses of the drug candidate OCTEVY™ to PET customers across the US, if the product is approved by the Food and Drug Administration (FDA). Under the terms of the agreement, RLS will reconstitute, radiolabel, and deliver OCTEVY™ doses (if approved by FDA) ordered by hospitals and independent PET centers.',
    'FW Link1':
      'RLS And Evergreen Theragnostics Launch Partnership For Sale And Distribution Of Neuroendocrine Tumor Diagnostic Octevy (Kit For Preparation Of GA 68 Dotatoc Injection), Pending FDA Approval;;https://firstwordpharma.com/story/5741800/'
  },
  {
    'Date Announced': '18-May-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Otsuka',
    'Country (Acquiring Company/Partner)': 'Japan',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Arcus Biosciences, Gilead',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies, small molecules',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'domvanalimab, AB308, zimberelimab, etrumadenant',
    'Indication(s)':
      'Non-small-cell lung cancer, gastrointestinal tract cancer',
    'Target(s)': 'TIGIT, PD-1, adenosine receptor',
    'Deal Structure':
      'Taiho Pharmaceutical Co., Ltd. (“Taiho”) announced that it has entered into a Clinical Trial Agreement with Arcus Biosciences, Inc. (“Arcus”) and Gilead Sciences, Inc. (“Gilead”) related to the Arcus programs which Taiho had previously obtained rights to develop and commercialize in Japan and certain other territories in Asia (excluding China). Based on this agreement, Taiho will be eligible to participate in certain global clinical trials conducted not only by Arcus but also by Gilead for programs which Taiho has exercised its option rights under the Taiho and Arcus Option and License Agreement contracted in 2017. These optioned programs currently are the anti-TIGIT program (domvanalimab and AB308), the anti-PD-1 monoclonal antibody program (zimberelimab), and the adenosine receptor antagonist program (etrumadenant). The Clinical Trial Agreement is expected to further accelerate the development of these Arcus in-licensed products in Japan. Taiho will participate in two global phase 3 clinical trials, STAR-121 trial in non-small cell lung cancer, and STAR-221 trial in upper gastrointestinal tract cancer, both of which are expected to be initiated in Japan during 2023.',
    'FW Link1':
      'Taiho Pharmaceutical Enters into a Clinical Trial Agreement with Arcus Biosciences and Gilead Sciences;;https://firstwordpharma.com/story/5741712/'
  },
  {
    'Date Announced': '18-May-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'AstraZeneca',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Revvity',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Gene editing, cell therapies',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Revvity, Inc. (NYSE: RVTY), announced a new license agreement with AstraZeneca (LSE/STO/Nasdaq: AZN) for the technology underlying its Pin-point™ base editing system, a next-generation modular gene editing platform with a strong safety profile. Non-exclusive agreement provides AstraZeneca access to proprietary gene editing technology to help advance its work in cell therapy.',
    'FW Link1':
      'Revvity Announces New License Agreement for Next-Generation Base Editing Technology;;https://firstwordpharma.com/story/5741729/'
  },
  {
    'Date Announced': '18-May-2023',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Oncomatryx',
    'Country (Acquiring Company/Partner)': 'Spain',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'TUBE Pharma',
    'Country (Target Company/Partner)': 'Austria',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Oncomatryx has acquired Tube Pharmaceuticals GmbH, the developer of the Cytolysin toxic payload. The multimillion €uros transaction is a strategic decision by Oncomatryx that will foster its leadership in the field of ADCs targeting the tumor microenvironment. It culminates a decade-long alliance between Oncomatryx and Tube that has already rendered clinical-stage ADCs targeting the Tumor Microenvironment. Oncomatryx now owns the comprehensive portfolio of cytolysins synthesized by Tube and the cutting-edge technology developed in its laboratories. Led by Dr. Wolfgang Richter, the Austrian company has more than 15 years of experience and has positioned itself as a top-level benchmark in the generation of novel ADC payloads.',
    'FW Link1':
      'Oncomatryx acquires Tube Pharmaceuticals GmbH to consolidate its pioneering pipeline of ADCs targeting the tumor microenvironment;;https://firstwordpharma.com/story/5741785/'
  },
  {
    'Date Announced': '18-May-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Asieris Pharmaceuticals',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'UroViu',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics, imaging',
    'Indication(s)': 'Bladder cancer',
    'Deal Structure':
      'Asieris Pharmaceuticals (688176), a global biopharma company specializing in discovering, developing and commercializing innovative drugs for the treatment of genitourinary tumors and other related diseases, announced a strategic collaboration with UroViu Corporation ("UroViu"). Asieris Pharmaceuticals will obtain exclusive global rights to UroViu\'s patented technology of portable single-use cystoscope in the field of fluorescent imaging. Under the agreement, Asieris Pharmaceuticals will use this patented technology in combination with specific optical imaging agents to conduct research on the application of non-white light imaging technology in the diagnosis and postoperative monitoring of None-Muscle Invasive Bladder Cancer (NMBC). At the same time, Asieris Pharmaceuticals will be responsible for the global commercialization of the sterile single-use fluorescent Cystoscope.',
    'FW Link1':
      'Asieris Deepens Strategic Partnership with UroViu to Further Develop Integrated Diagnosis and Treatment Platform for Bladder Cancer;;https://firstwordpharma.com/story/5741740/'
  },
  {
    'Date Announced': '22-May-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Panbela',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Moffitt Cancer Center',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'eflornithine',
    'Indication(s)': 'Non-small-cell lung cancer',
    'Deal Structure':
      'Panbela Therapeutics, Inc. (Nasdaq: PBLA), a clinical stage company developing disruptive therapeutics for the treatment of patients with urgent unmet medical needs, announced it has entered into a clinical trial agreement with Moffitt Cancer Center for a Phase I/II program in STK11 mutant non-small cell lung cancer (NSCLC). The initial goal of the Phase I trial will be to ascertain the maximum tolerated dose of eflornithine, while evaluating efficacy and then moving into a Phase II efficacy trial. We anticipate data from the Phase I trial by the end of this year with a look to start the Phase II trial at the end of the year or early 2024.',
    'FW Link1':
      'Panbela Announces Clinical Trial with Moffitt Cancer Center for Phase I/II Program in STK11 Mutant Non-Small Cell Lung Cancer;;https://firstwordpharma.com/story/5743061/'
  },
  {
    'Date Announced': '23-May-2023',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'CohBar',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Morphogenesis',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Personalised cancer vaccines, mRNA vaccines, bi-functional antibody-drug conjugates',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'IFx-Hu2.0',
    'Indication(s)': 'Merkel cell carcinoma',
    'Deal Structure':
      'CohBar, Inc. (NASDAQ: CWBR) (“CohBar” or the “company”) and Morphogenesis, Inc. (“Morphogenesis”), a privately-held Phase 2/3 clinical-stage biotechnology company developing novel personalized cancer vaccines and tumor microenvironment modulators to overcome resistance to current immunotherapies, announced that they have entered into a definitive agreement for an all-stock transaction forming a company combining expertise and resources to advance a late-stage oncology pipeline. The combined company will focus on advancing Morphogenesis’ two technologies that seek to overcome the major obstacles that limit the effectiveness of current immunotherapies in treating cancer. The combined company is expected to operate under the name “TuHURA Biosciences, Inc.” and to trade on The Nasdaq Capital Market (“Nasdaq”). The transaction is expected to close in the third quarter of 2023.',
    'FW Link1':
      'CohBar, Inc. and Morphogenesis, Inc. Enter into Definitive Merger Agreement to Advance an Innovative Late-stage Clinical Immuno-oncology Pipeline of Therapies to Overcome Resistance to Cancer Immunotherapy;;https://firstwordpharma.com/story/5743629/'
  },
  {
    'Date Announced': '23-May-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Pathos',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Novo Nordisk',
    'Country (Target Company/Partner)': 'Denmark',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'FT-7051',
    'Indication(s)': 'Prostate cancer',
    'Target(s)': 'CBP/p300',
    'Deal Structure':
      'Pathos AI, Inc. (www.pathos.com), a biotechnology company focused on revolutionizing precision medicine in cancer by harnessing the power of machine learning to transform drug development, announced that it has entered into a worldwide license agreement to develop FT-7051, a small molecule CBP/p300 inhibitor program from Novo Nordisk as Pathos’ first clinical-stage asset in its pipeline.',
    'FW Link1':
      'Pathos Launches Precision Oncology Pipeline With License of First Phase I Program, a CBP/p300 Inhibitor;;https://firstwordpharma.com/story/5743732/'
  },
  {
    'Date Announced': '25-May-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Boehringer Ingelheim',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Oxford BioTherapeutics',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immunotherapy',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Oxford BioTherapeutics Ltd. (OBT), a clinical stage oncology company with a pipeline of immuno-oncology and antibody-drug conjugate (ADC)-based therapies, announced that it has extended its second multi-year collaboration with Boehringer Ingelheim for an additional two years. With this extension further programs will be added into the existing collaboration, with the goal to enable the development of novel cancer immunotherapies in areas of high unmet patient need. Under the terms of the extended agreement, Boehringer Ingelheim is responsible for the development and commercialization of treatment candidates that interact with the novel targets identified by OGAP®. OBT will receive research funding as well as success-based development and regulatory milestone payments and royalties on potential future product sales.',
    'FW Link1':
      'Oxford BioTherapeutics and Boehringer Ingelheim Agree on 2 Year Extension to their Second Multi-Year Collaboration in Cancer Immunology;;https://firstwordpharma.com/story/5744363/'
  },
  {
    'Date Announced': '25-May-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Almirall',
    'Country (Acquiring Company/Partner)': 'Spain',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Centre for Genomic Regulation',
    'Country (Target Company/Partner)': 'Spain',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Preclinical models, drug discovery',
    'Indication(s)': 'Non-melanoma skin cancer',
    'Deal Structure':
      'Almirall, S.A. (ALM), a global biopharmaceutical company focused on medical dermatology, has entered into a research collaboration with the Centre for Genomic Regulation (CRG), an international biomedical research institute of excellence. The partnership aims to develop and characterize novel preclinical models that will help identify new treatments for non-melanoma skin cancer (NMSC).',
    'FW Link1':
      'Almirall and the Centre for Genomic Regulation (CRG) collaborate to develop and characterize novel preclinical models for non-melanoma skin cancer;;https://firstwordpharma.com/story/5744484/'
  },
  {
    'Date Announced': '25-May-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'NeoMatrix',
    'Country (Acquiring Company/Partner)': 'Italy',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': '4basebio',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Neoantigen cancer vaccines',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Under the agreement, 4basebio will supply clinical and preclinical DNA materials into Neomatrix’ neoantigen cancer vaccine program. The program is expected to start patient enrolment in early 2024.  Neomatrix will gain access to 4basebio‘s proprietary DNA products including hpDNA™ and oeDNA™. 4basebio will manufacture and provide the personalised clinical grade DNA products, the key component in Neomatrix’ immunotherapy cancer treatment.',
    'FW Link1':
      'Neomatrix Product Supply Agreement;;https://firstwordpharma.com/story/5744632/'
  },
  {
    'Date Announced': '25-May-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Artios Pharma',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'ANGLE',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase I',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'ANGLE plc (AIM:AGL)(OTCQX:ANPCY), a world-leading liquid biopsy company, is delighted to announce that its first bespoke assay development customer, Artios Pharma (Artios), has signed a new contract to utilise two DNA Damage Response assays developed by ANGLE in a Phase 1 clinical trial expected to commence shortly. Artios is a clinical-stage biotech company pioneering the development of small molecule therapeutics that target the DNA Damage Response (DDR) process in order to treat patients with a broad range of cancers. Artios has an extensive DDR focused pipeline and strategic partnerships with global pharma companies.',
    'FW Link1':
      'Angle Announces Pharma Services Contract with Artios Pharma;;https://firstwordpharma.com/story/5744509/'
  },
  {
    'Date Announced': '1-May-2023',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'CellReady',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Marker Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapies',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Under the terms of the non-dilutive agreement, CellReady will purchase certain cell manufacturing assets from Marker for approximately $19 million in cash and reduce Marker’s overhead by about $11 million annually by employing Marker’s manufacturing, development, quality, and regulatory affairs personnel, and assuming the leases for Marker’s Houston-based manufacturing and research and development facilities. The parties anticipate the transaction will close on June 26, 2023. CellReady also agreed to enter into a long-term contract with Marker wherein CellReady will perform a wide variety of services for Marker including research and development, manufacturing, and regulatory activity in support of Marker’s clinical trials.',
    'FW Link1':
      'Marker Therapeutics Announces Comprehensive Non-Dilutive Agreement With CellReady;;https://firstwordpharma.com/story/5733899/'
  },
  {
    'Date Announced': '30-May-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Sun Pharmaceutical',
    'Country (Acquiring Company/Partner)': 'India',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Philogen',
    'Country (Target Company/Partner)': 'Italy',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Europe, Australia, New Zealand',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immunotherapy',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'Nidlegy',
    'Indication(s)': 'Skin cancers',
    'Deal Structure':
      'Under the terms of the agreement, Sun Pharma will have exclusive rights to commercialise Nidlegy™ for indications of skin cancers in the territories of Europe, Australia and New Zealand. Philogen will complete pivotal clinical trials for the product in Europe, pursue Marketing Authorization with the regulatory authorities and manufacture commercial supplies. Sun Pharma will be responsible for commercialization activities. The two partner companies will share post-commercialization economics in about 50:50 ratio. Other financial terms were not disclosed. Philogen will retain the IP rights for Nidlegy™ for other territories and indications other than skin cancers.',
    'FW Link1':
      'Sun Pharma and Philogen enter into an Exclusive Distribution, License, and Supply Agreement for Commercializing specialty product, NIDLEGY in Europe, Australia and New Zealand;;https://firstwordpharma.com/story/5745765/'
  },
  {
    'Date Announced': '30-May-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'AstraZeneca',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Kiyatec',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Screening, drug development',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'AstraZeneca and Kiyatec, the leader in clinically correlated functional precision oncology, have entered into a multifaceted research agreement to assess therapeutic efficacy of undisclosed preclinical assets using Kiyatec’s 3D spheroid screening platform, KIYA-PredictTM.',
    'FW Link1':
      'Kiyatec and AstraZeneca Announce a Multifaceted Research Agreement to Generate Novel Preclinical Data Using Kiyatec’s 3D Spheroid Screening Platform, KIYA-Predict;;https://firstwordpharma.com/story/5745997/'
  },
  {
    'Date Announced': '30-May-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Adcendo',
    'Country (Acquiring Company/Partner)': 'Denmark',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Duality Biologics',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "In January 2023, Adcendo announced an agreement to license Duality's proprietary, industry leading DITAC (Duality Immune Toxin Antibody Conjugates) linker-payload platform for its lead uPARAP-ADC program in mesenchymal cancers. Under the new MTA and Option License Agreement, Adcendo has the opportunity to nominate ADCs against two novel ADC targets. The new agreement further broadens and expands the existing collaboration between Adcendo and Duality. Based on the agreement, new targets will be evaluated under MTA with Duality's linker-payload platform, designed to generate ADCs with superior safety profiles, sustainable payload delivery and release in tumors, and efficient bystander killing of antigen low and negative cells. Following evaluation, Adcendo has the option to gain access to Duality's next generation ADC platform.",
    'FW Link1':
      'Adcendo ApS Announces Option License Agreement with Duality Biologics to Enhance Optionality to Further Expand First-in-Class ADC Pipeline;;https://firstwordpharma.com/story/5745766/'
  },
  {
    'Date Announced': '30-May-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Betta Pharmaceuticals',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'C4 Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '10',
    'Total Deal Value ($m)': '392',
    'Geographic Remit of Deal': 'Greater China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules, protein degradation',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'CFT8919',
    'Indication(s)': 'Non-small-cell lung cancer',
    'Target(s)': 'EGFR L858R',
    'Deal Structure':
      'Under the terms of the agreement, C4T expects to receive $35 million, which includes $10 million in upfront cash as well as a $25 million one-time equity investment, to be completed following the receipt of required regulatory approvals and other customary closing conditions. Additionally, C4T is eligible for up to $357 million in potential milestones and low to mid-double-digit percent royalties on net sales in the licensed territories. Betta will be responsible for the development, manufacturing and commercialization of CFT8919 in the licensed territories and is eligible to receive low single-digit percent royalties on net sales outside of Greater China. C4T retains the right to develop and commercialize CFT8919 in all territories outside of Greater China.',
    'FW Link1':
      'C4 Therapeutics and Betta Pharmaceuticals Announce Exclusive Licensing Agreement for the Development and Commercialization in Greater China of CFT8919, an Orally Bioavailable BiDAC Degrader of EGFR L858R for NSCLC;;https://firstwordpharma.com/story/5745886/'
  },
  {
    'Date Announced': '30-May-2023',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'Lupin',
    'Country (Acquiring Company/Partner)': 'India',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Enzene Biosciences',
    'Country (Target Company/Partner)': 'India',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'India',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Biosimilars, monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'cetuximab',
    'Indication(s)': 'Head and neck cancer',
    'Target(s)': 'EGFR',
    'Deal Structure':
      'Global pharma major Lupin Limited (Lupin) announced a strategic collaboration with Enzene Biosciences to launch Cetuximab in India, the first biosimilar developed for Cetuximab. This collaboration marks a significant milestone in expanding therapeutic options and fostering innovation in the critical area of treatment of head and neck cancer. Cetuximab has received approval from the Drug Controller General of India (DCGI) for its effectiveness in treating head and neck cancer, particularly Squamous Cell Carcinoma of the Head and Neck (SCCHN), making it a crucial solution for patients with recurrent locoregional or metastatic conditions. Cetuximab is available as a 100mg vial.',
    'FW Link1':
      'Lupin Announces the Launch of Cetuximab;;https://firstwordpharma.com/story/5745764/'
  },
  {
    'Date Announced': '31-May-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'University of Sheffield',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Physiomics',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Preclinical models, drug discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Physiomics plc (AIM: PYC), a leading mathematical modelling company supporting oncology drug development and personalised medicine solutions, is pleased to announce that it is supporting The University of Sheffield (the “University”) with research into an insect (Drosophila melanogaster) model of cancer treatment that could be used to screen potential new drugs prior to pre-clinical testing in more traditional mouse models of cancer.  This new approach could provide important insights into the development of new cancer therapies as well as potentially reduce the number of pre-clinical animals involved in the drug development process.',
    'FW Link1':
      'Collaboration with The University of Sheffield;;https://firstwordpharma.com/story/5746352/'
  },
  {
    'Date Announced': '31-May-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Yuhan',
    'Country (Acquiring Company/Partner)': 'Korea, South',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'J INTS BIO',
    'Country (Target Company/Partner)': 'Korea, South',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '2',
    'Total Deal Value ($m)': '325',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'JIN-A04',
    'Indication(s)': 'Non-small-cell lung cancer',
    'Target(s)': 'HER2',
    'Deal Structure':
      "Yuhan Corporation (CEO, Wook-Je Cho) announced on the 30th of May that it has signed an in-license agreement with J INTS BIO (CEO, Anna Jo) for a Tyrosine Kinase Inhibitor (TKI) targeting HER2, 'JIN-A04'. The total contract size is 325 million dollars, and it includes an upfront payment of 1.9 million dollars and milestone payments according to future development, permits, and sales.",
    'FW Link1':
      'Yuhan Corporation and J INTS BIO inked a licensing agreement for a New Drug Candidate targeting HER2 NSCLC;;https://firstwordpharma.com/story/5746182/'
  },
  {
    'Date Announced': '31-May-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Adlai Nortye',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Nucleai',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Biomarkers, small molecules, artificial intelligence',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'buparlisib',
    'Indication(s)': 'Metastatic squamous cell carcinoma of head and neck',
    'Deal Structure':
      'Nucleai, a leader in AI-powered spatial biology, and Adlai Nortye, a global biopharmaceutical company focused on developing innovative immuno-oncology therapies, announced a strategic partnership to identify, validate and test novel H&E-based biomarkers across Adlai Nortye’s Phase 2 and 3 clinical trials. The collaboration will initially focus on identifying subjects that could derive benefit from Buparlisib treatment in metastatic Squamous Cell Carcinoma of Head and Neck (SCCHN) patients and will expand to other indications and clinical trials.',
    'FW Link1':
      'Nucleai and Adlai Nortye Partner to Identify and Validate Novel Spatial Biomarkers Across Adlai Clinical Trials;;https://firstwordpharma.com/story/5746592/'
  },
  {
    'Date Announced': '1-Jun-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Eikon Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Seven and Eight Biopharmaceuticals',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immunotherapy',
    'Lead Asset Stage/Phase': 'Phase I',
    'Indication(s)': 'Cancer',
    'Target(s)': 'TLR7/8',
    'Deal Structure':
      'Seven and Eight Biopharmaceuticals Inc., a clinical stage biotechnology company focused on developing proprietary novel immuno-oncology therapies to activate the immune system against cancer, announced that it has signed an exclusive licensing agreement with Eikon Therapeutics. As part of the agreement, Eikon Therapeutics is awarded global rights to develop and commercialize the company’s novel immune modulator agonists targeting toll-like receptor 7 and 8 (TLR7/8). The exclusive licensing agreement also includes development of novel Toll-like receptor ligand antibody conjugation (TLAC) molecules.',
    'FW Link1':
      'Seven and Eight Biopharmaceuticals Announces Exclusive Clinical License Agreement with Eikon Therapeutics to Develop and Commercialize TLR7/8 dual agonists;;https://firstwordpharma.com/story/5747138/'
  },
  {
    'Date Announced': '1-Jun-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Eikon Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'IMPACT Therapeutics',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global excl. Greater China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'IMP1734',
    'Indication(s)': 'Cancer',
    'Target(s)': 'PARP1',
    'Deal Structure':
      'IMPACT Therapeutics ("Impact"), a biopharmaceutical company focusing on the discovery and development of targeted anti-cancer therapeutics based on synthetic lethality, and Eikon Therapeutics ("Eikon"), a biotechnology company that is advancing breakthrough therapeutics through the purposeful integration of engineering and science today announced that the companies have entered into a global license and collaboration agreement for PARP1 selective inhibitors including IMP1734, which is anticipated to enter into Phase I clinical study in 2023. Under the collaboration agreement, Eikon received an exclusive license from Impact to co-develop, register, manufacture, and commercialize IMP1734 and other PARP1 selective inhibitors globally, excluding Greater China (mainland China, Hong Kong, Macau, and Taiwan).',
    'FW Link1':
      'Impact Therapeutics Entered into Global Partnership with Eikon Therapeutics to Develop and Commercialize PARP1 Selective Inhibitors;;https://firstwordpharma.com/story/5747042/'
  },
  {
    'Date Announced': '1-Jun-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Philogen',
    'Country (Acquiring Company/Partner)': 'Italy',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Merck & Co.',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immunocytokines, monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'L19IL2, L19TNF, Nidlegy, Keytruda',
    'Indication(s)': 'Melanoma',
    'Target(s)': 'PD-1',
    'Deal Structure':
      "Philogen S.p.A., a clinical-stage biotechnology company focused on the development of innovative medicines based on tumor-targeting antibodies and small molecule ligands, announces that it has entered into a Clinical Trial Collaboration and Supply Agreement with MSD (Merck & Co., Inc., Rahway, NJ, USA). Under the terms of the supply agreement, MSD provides their anti-PD-1 therapy, KEYTRUDA® (pembrolizumab), to be evaluated in combination with Philogen's immunocytokines L19IL2, L19TNF, and Nidlegy™ in a randomized Phase II clinical trial. The study provides an opportunity to explore the combination of immunocytokines and PD-1 blockade in stage III and IV unresectable melanoma patients who failed prior checkpoint inhibitor therapies.",
    'FW Link1':
      'Philogen Announces Clinical Trial Collaboration with MSD;;https://firstwordpharma.com/story/5746724/'
  },
  {
    'Date Announced': '1-Jun-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Bayer',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Cedilla Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Cancer',
    'Target(s)': 'CyclinE1/CDK2',
    'Deal Structure':
      'Bayer and Cedilla Therapeutics, a biotechnology company bringing a new dimension to precision oncology, announced an exclusive license agreement to develop and commercialize Cedilla Therapeutics’ CyclinE1/CDK2 complex inhibitors which selectively address oncogenic drivers. Under the terms of the agreement, Bayer will receive full rights on the development and commercialization of Cedilla’s CyclinE1/CDK2 complex inhibitors. Cedilla will receive an upfront payment and potential development and commercial-based milestone payments. Cedilla will also be eligible to receive royalties on medicines based on their technology commercialized by Bayer. Financial details have not been disclosed.',
    'FW Link1':
      'Bayer to acquire exclusive license from Cedilla Therapeutics on selective inhibitors in pre-clinical precision oncology;;https://firstwordpharma.com/story/5746732/'
  },
  {
    'Date Announced': '1-Jun-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Nykode Therapeutics',
    'Country (Acquiring Company/Partner)': 'Norway',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Roche',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Therapeutic cancer vaccines, monoclonal antibodies',
    'Lead Asset(s)': 'VB10.16, Tecentriq',
    'Indication(s)': 'Cervical cancer',
    'Target(s)': 'PD-L1',
    'Deal Structure':
      'Nykode Therapeutics ASA (OSE: NYKD), a clinical-stage biopharmaceutical company dedicated to the discovery and development of novel immunotherapies, today announced that it has expanded the clinical collaboration and supply agreement with Roche to cover evaluation of VB10.16, Nykode’s wholly owned off-the-shelf therapeutic cancer vaccine candidate, in combination with Roche’s cancer immunotherapy atezolizumab in patients with advanced cervical cancer who have progressed on pembrolizumab plus chemotherapy +/- bevacizumab as first line treatment. The VB-C-04 trial is expected to be initiated in the U.S. in the fourth quarter of 2023 with registrational intent, which provides a potential fast-to-market path. Under the terms of the agreement, Nykode will sponsor and fund the planned clinical trial, and Roche will provide atezolizumab. Nykode retains all commercial rights to VB10.16 worldwide. The VB-C-04 trial will be conducted together with GOG Foundation, a U.S.-based not-for-profit organization with expertise in bringing best-in-class new treatments to patients.',
    'FW Link1':
      'Nykode Therapeutics Announces Expansion of Clinical Collaboration with Roche to Evaluate VB10.16 in Combination with anti-PD-L1 in the next trial in Advanced Cervical Cancer;;https://firstwordpharma.com/story/5746734/'
  },
  {
    'Date Announced': '1-Jun-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Shasqi',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'J&J',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Click chemistry, tumour-targeted therapies',
    'Lead Asset(s)': 'SQL70',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Shasqi, Inc. (“Shasqi”), a clinical-stage biotech company, whose mission is to revolutionize cancer treatment with click chemistry, announced it has entered into a research collaboration with Johnson & Johnson Enterprise Innovation Inc. Through the collaboration, Shasqi will apply its novel tumor-targeted Click Activated Protodrugs Against Cancer (CAPAC®) platform to the development of new cancer therapies. The collaboration will focus on Shasqi’s intratumorally injected biopolymer (SQL70) and a co-development committee has been formed with the aim of identifying the next clinical candidate.',
    'FW Link1':
      'Shasqi Announces Research Collaboration to Transform Targeted Cancer Treatment;;https://firstwordpharma.com/story/5747182/'
  },
  {
    'Date Announced': '1-Jun-2023',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Lonza',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Synaffix',
    'Country (Target Company/Partner)': 'Netherlands',
    'Region (Target Company/Partner)': 'Europe',
    'Upfront Value ($m)': '108',
    'Total Deal Value ($m)': '172',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Lonza, a global manufacturing partner to the pharmaceutical, biotech and nutraceutical markets, announced it has acquired Synaffix B.V. (Synaffix), a biotechnology company focused on commercializing its clinical-stage technology platform for the development of ADCs. Synaffix revenues and margins will be recognized within the Lonza business from the date of acquisition. As a Lonza company, Synaffix will continue to operate under the Synaffix name and further expand its operations in Oss (NL) to cater for further innovation and growth. The acquisition comprises an initial financial consideration of €100 million in cash and up to €60 million in additional performance-based consideration.',
    'FW Link1':
      'Lonza to Acquire Synaffix and Strengthen Antibody-Drug Conjugates Offering;;https://firstwordpharma.com/story/5746730/'
  },
  {
    'Date Announced': '1-Jun-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Innovent Biologics',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Merck KGaA',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules, monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'IBI351 (GFH925), Erbitux',
    'Indication(s)': 'Non-small-cell lung cancer',
    'Target(s)': 'KRAS G12C, EGFR',
    'Deal Structure':
      'Under the agreement, Innovent will conduct a Phase 1b study to evaluate the anti-tumor activity and safety of the combination therapy of IBI351(GFH925) with cetuximab in Chinese patients with advanced or metastatic NSCLC harboring KRASG12C mutation. Merck KGaA, Darmstadt, Germany will provide clinical drug supplies of cetuximab in this multi-center trial in China.',
    'FW Link1':
      'Innovent Enters into Clinical Trial Collaboration with Merck KGaA, Darmstadt, Germany Investigating Combination Therapy of IBI351 (KRASG12C Inhibitor) and Cetuximab (ERBITUX)for KRASG12C-mutated NSCLC in China;;https://firstwordpharma.com/story/5747349/'
  },
  {
    'Date Announced': '2-Jun-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Pint-Pharma',
    'Country (Acquiring Company/Partner)': 'Austria',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'PharmaEssentia',
    'Country (Target Company/Partner)': 'Taiwan',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal':
      'Brazil, Argentina, Colombia, Chile, Peru, Ecuador, Mexico',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Interferon therapy',
    'Lead Asset(s)': 'Besremi',
    'Indication(s)': 'Polycythaemia vera',
    'Deal Structure':
      'PharmaEssentia Corporation (TPEx:6446), a leading fully integrated biopharmaceutical company in Taiwan, announced that it has entered into an exclusive license agreement with Pint-Pharma GmbH for the registration and promotion of BESREMi® (ropeginterferon alfa-2b-njft) for the treatment of polycythemia vera (PV), a rare blood cancer, in Brazil, Argentina, Colombia, Chile, Peru, Ecuador, and Mexico. Under the terms of the agreement, PharmaEssentia may be eligible for certain milestone payments and royalties based on sales. Pint-Pharma will be responsible for obtaining and maintaining all marketing authorizations and for commercializing BESREMi in the region. PharmaEssentia will continue to be responsible for the supply of BESREMi.',
    'FW Link1':
      'PharmaEssentia Selects Pint-Pharma as Commercial Partner for BESREMi (ropeginterferon alfa-2b-njft) in Latin America;;https://firstwordpharma.com/story/5747496/'
  },
  {
    'Date Announced': '2-Jun-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Massive Bio',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Precision Cancer Consortium',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Artificial intelligence, clinical trial matching, genomics, precision oncology',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Massive Bio also announced that it has been selected by the Precision Cancer Consortium (PCC) to optimise clinical trial matching through its AI analytics tools. The consortium currently includes biopharmaceutical companies AstraZeneca, Bayer, Eli Lilly, GSK, Johnson & Johnson, Novartis and Roche. The collaboration will incorporate PCC member clinical trial protocols and patient inclusion/exclusion criteria into existing machine-learning matching algorithms in Massive Bio's clinical trial matching service SYNERGY-AI. The company said this will enhance the quality and efficiency of matching patients to trials across sponsor programmes and improve patient access to targeted next-generation sequencing testing and tailored interventions. Under the agreement, Massive Bio will design and pilot a tool for prospectively matching patients through genomic testing and clinical data to a set of ongoing biomarker-driven clinical trials. The companies will also consider larger-scale or real-world applications for further development.",
    'FW Link1':
      'Massive Bio unveils GPT-4-powered chatbots to expand access to cancer trials;;https://www.firstwordhealthtech.com/story/5747515/'
  },
  {
    'Date Announced': '3-Jun-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Massive Bio',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'The Oncology Institute',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Artificial intelligence, clinical trial matching, precision oncology',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Under this visionary collaboration, Massive Bio will support TOI with evaluating the eligibility of patients for TOI's active clinical trial portfolio, utilizing their cooperative business model driven by AI and precision medicine. By leveraging sophisticated algorithms and comprehensive diagnostic information, Massive Bio will empower patients and ordering physicians at TOI with clinical decision-support and clinical trial matching services.",
    'FW Link1':
      'Massive Bio and The Oncology Institute (TOI) Forge Partnership to Revolutionize Cancer Care and AI-enabled Cancer Research;;https://www.firstwordhealthtech.com/story/5747741/'
  },
  {
    'Date Announced': '5-Jun-2023',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'CERo Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Phoenix Biotech Acquisition',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'T-cell therapeutics',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'CER-1236',
    'Indication(s)': 'Haematologic malignancies',
    'Deal Structure':
      'CERo Therapeutics, Inc. (“CERo”), an innovative immunotherapy company seeking to advance the next generation of engineered T cell therapeutics that employ phagocytic mechanisms, and Phoenix Biotech Acquisition Corp. (NASDAQ:PBAX) (“PBAX”), a special purpose acquisition company formed for the purpose of acquiring or merging with one or more businesses, announced they have entered into a definitive business combination agreement. Upon closing of the transaction, anticipated to occur in the second half of 2023, the combined company will be named CERo Therapeutics Holdings, Inc. and will be led by CERo and PBAX’s founding members, including Daniel Corey, M.D., CERo’s CEO, Chris Ehrlich, PBAX’s CEO, and Brian G. Atwood, the Chairman of PBAX.',
    'FW Link1':
      'CERo Therapeutics and Phoenix Biotech Acquisition Announce Merger Agreement to Create Public Company Advancing the Development of Next Generation Engineered T Cell Therapeutics;;https://firstwordpharma.com/story/5748116/'
  },
  {
    'Date Announced': '5-Jun-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'BMS',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'MEDiC Life Sciences',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'CRISPR functional genomics, target discovery',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      'MEDiC Life Sciences (“MEDiC”), a Silicon Valley biotech startup, announced that it entered into a research collaboration with Bristol Myers Squibb (NYSE:BMY). In the collaboration, MEDiC is using its scalable 3-dimensional tumor models to perform CRISPR functional genomics screens in solid tumor indications of interest to Bristol Myers Squibb. MEDiC is also using its immune cell and tumor cell co-culture platform to identify novel genes relevant to immune cell killing of cancer cells. Under the terms of the agreement, MEDiC will receive upfront payments for the research collaboration and will be eligible for additional option payments on a target-by-target basis. MEDiC is also eligible for future development and regulatory milestone payments.',
    'FW Link1':
      'MEDiC Life Sciences Announces Collaboration with Bristol Myers Squibb on Tumor Target Discovery;;https://firstwordpharma.com/story/5748185/'
  },
  {
    'Date Announced': '6-Jun-2023',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'CureMatch',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'xCures',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial intelligence, precision oncology',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'CureMatch, Inc., a healthcare technology company that leverages artificial intelligence (AI) to power precision medicine support for oncology, is proud to announce a partnership with xCures, Inc., to connect cancer patients and physicians with optimal investigational or approved therapies. CureMatch will leverage the xCures technology platform to create a comprehensive outline of a cancer patient’s medical history and further enhance its precision cancer treatment recommendation capabilities.',
    'FW Link1':
      'CureMatch and xCures Partner to Revolutionize the Standard for Precision Cancer Treatment Processes;;https://www.firstwordhealthtech.com/story/5748906/'
  },
  {
    'Date Announced': '6-Jun-2023',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Nordic Nanovector',
    'Country (Acquiring Company/Partner)': 'Norway',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Thor Medical',
    'Country (Target Company/Partner)': 'Norway',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Radiopharmaceuticals, radionuclides',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Nordic Nanovector ASA (OSE: NANOV) (“Nordic Nanovector” or the “Company”) and Thor Medical AS (“Thor Medical”), a privately held company focused on the production of alpha-emitting radionuclides for cancer treatment, announce that they have entered into a definitive share exchange agreement (the “Agreement”) to combine the two companies in an all-share transaction (the “Transaction”). The agreed exchange ratio in the Transaction is on a 50-50 basis. Following completion of the Transaction, Nordic Nanovector will own at least 98% of the outstanding shares in Thor Medical. Assuming that Nordic Nanovector acquires all of the shares in Thor Medical in the Transaction, then the former shareholders of Thor Medical will own 50% of the post-transaction combined entity and existing Nordic Nanovector shareholders will own 50%. Scatec Innovation AS, currently the largest shareholder in Thor Medical, will become the largest shareholder in the combined company after the Transaction with approximately 24.5% of the issued shares.',
    'FW Link1':
      'Nordic Nanovector announces agreement to acquire Thor Medical, an emerging producer of alpha-emitters for radiopharmaceuticals to treat cancer;;https://firstwordpharma.com/story/5748764/'
  },
  {
    'Date Announced': '7-Jun-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'ValiRx',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'University of Barcelona',
    'Country (Target Company/Partner)': 'Spain',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules, peptidomimetics',
    'Indication(s)': 'Cancer',
    'Target(s)': 'KRAS',
    'Deal Structure':
      "ValiRx Plc (AIM: VAL), a life science company focusing on early-stage cancer therapeutics and women's health, is pleased to announce that the evaluation agreement announced on 14 February 2022 from Barcelona University has been expanded to a broader collaboration including additional molecules targeting KRAS (Kirsten RAt Sarcoma) as possible drugs for treating cancer.",
    'FW Link1':
      'Expanded agreement with University of Barcelona;;https://firstwordpharma.com/story/5749145/'
  },
  {
    'Date Announced': '8-Jun-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Mendus',
    'Country (Acquiring Company/Partner)': 'Sweden',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'NorthX Biologics',
    'Country (Target Company/Partner)': 'Sweden',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapies',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'vididencel',
    'Indication(s)': 'Acute myeloid leukaemia',
    'Deal Structure':
      'Mendus AB (“Mendus” publ; IMMU.ST), a biopharmaceutical company focused on immunotherapies addressing tumor recurrence, announces that it has entered into a manufacturing alliance with NorthX Biologics AB (“NorthX”). NorthX is a Contract Development and Manufacturing Organization (CDMO) and serves as the National Swedish Innovation Hub for the GMP manufacture of biologics used in vaccines, gene therapy and other advanced therapy medicinal products (ATMPs). Mendus and NorthX will co-establish cell therapy manufacturing capabilities in Sweden, which will be used for late-stage development and commercial manufacturing of Mendus’ lead asset vididencel.',
    'FW Link1':
      'Mendus and NorthX Biologics to enter into strategic cell therapy manufacturing alliance supported by new investor Flerie;;https://firstwordpharma.com/story/5749403/'
  },
  {
    'Date Announced': '8-Jun-2023',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'IMBdx',
    'Country (Acquiring Company/Partner)': 'Korea, South',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'AstraZeneca',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global (excl. United States)',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Diagnostics, liquid biopsy, precision oncology',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'AlphaLiquid HRR test',
    'Indication(s)': 'Prostate cancer',
    'Deal Structure':
      'IMBdx Inc., a premier precision cancer diagnostic company accredited by CAP, announced its plans to broaden its collaboration with AstraZeneca, a leading global biopharmaceutical company. This expansion will advance the shift from tissue to liquid biopsy, offering more options for metastatic castration-resistant prostate cancer (mCRPC) patients through the AlphaLiquid®HRR test within the PROSPER 2.0 project driven. The collaboration aims to improve accessibility to targeted therapies for mCRPC patients in international markets outside the US. As part of the PROSPER 2.0 project, IMBdx and AstraZeneca are jointly exploring how to increase clinician and patient awareness of 1) the clinical utility of ctDNA testing for mCRPC patients to identify those who would benefit from targeted therapies and 2) the detection capability of HRR gene alterations through AlphaLiquid®HRR ctDNA testing.',
    'FW Link1':
      'IMBdx Expands Targeted Therapies Agreement Following Successful Evaluation of Blood-Based Genomic Profiling Assay in Prostate Cancer;;https://www.firstwordhealthtech.com/story/5749692/'
  },
  {
    'Date Announced': '8-Jun-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'EMRIS Pharma',
    'Country (Acquiring Company/Partner)': 'Israel',
    'Region (Acquiring Company/Partner)': 'Middle East',
    'Target Company/Partner': 'Hadasit, Yissum',
    'Country (Target Company/Partner)': 'Israel',
    'Region (Target Company/Partner)': 'Middle East',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules, drug delivery',
    'Indication(s)': 'Skin toxicities caused by EGFR inhibitors',
    'Deal Structure':
      'Hadasit, and Yissum, has spun off EMRIS Pharma in collaboration with NGT Healthcare2 VC.  EMRIS Pharma is pioneering novel small molecule-based topical compounds that can improve both quality of life and treatment compliance for cancer patients. Emris, founded by NGT Healthcare2 VC, licensed the technology from Hadasit, the Technology Transfer Company of Hadassah Medical Organization and Yissum, the Technology Transfer Company of the Hebrew University in Jerusalem, Israel.  License terms include various payments such as license fees, milestones payments and royalties.',
    'FW Link1':
      'EMRIS Pharma, the Recent Spin-off of Hadasit and Yissum, Launches the Development of a New Innovative topical drug for the Treatment of Skin Toxicities, Improving Treatment and Quality of Life for Cancer Patients;;https://firstwordpharma.com/story/5749594/'
  },
  {
    'Date Announced': '8-Jun-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Aviko Radiopharmaceuticals',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Neutron Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Radiation therapy',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Aviko Radiopharmaceuticals, a Deerfield Management-founded biotechnology company developing medicines to unlock the potential of boron neutron capture therapy (BNCT), and Neutron Therapeutics, the leading provider of accelerator-based neutron systems for targeted radiation therapy of solid tumors, announced the formation of a strategic partnership to advance BNCT to treat cancer. The partnership aligns the companies’ efforts to build the first BNCT treatment center in the United States and to establish additional BNCT facilities through collaborations with premier academic medical centers.',
    'FW Link1':
      'Aviko Radiopharmaceuticals and Neutron Therapeutics Form Strategic Partnership to Advance Boron Neutron Capture Therapy;;https://firstwordpharma.com/story/5749695/'
  },
  {
    'Date Announced': '12-Jun-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Simcere',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Mass General Brigham',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Drug development',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Simcere Pharmaceutical Group Limited (2096.HK) (Simcere) announced a Sponsored Research Agreement with Mass General Brigham. Under the agreement up to eight research groups at Mass General Brigham will be funded on select projects over the course of two years. The agreement aims to establish a mutually beneficial partnership that will advance scientific research and create new treatment options for patients with cancer, autoimmune and CNS diseases. By partnering with Mass General Brigham, Simcere gains access to cutting-edge research and leading clinical capabilities which will help accelerate the company's global R&D efforts. In return, this collaboration will support the biopharma community by providing additional resources for medical research and clinical trials.",
    'FW Link1':
      'Simcere Pharmaceutical Group Announces Sponsored Research Agreement with Boston-based Mass General Brigham;;https://firstwordpharma.com/story/5750567/'
  },
  {
    'Date Announced': '12-Jun-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Curigin',
    'Country (Acquiring Company/Partner)': 'Korea, South',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Charles River Laboratories',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Gene therapy',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'CA102',
    'Indication(s)': 'Bladder cancer',
    'Deal Structure':
      "Charles River Laboratories International, Inc. (NYSE: CRL) and Curigin, a Korean biotechnology company developing innovative oncolytic ribonucleic acid interference (RNAi) gene therapies, announced a collaboration for adenoviral vector production. The gene therapy developer will leverage Charles River's market-leading expertise in contract development and manufacturing organization (CDMO) solutions to support its preclinical and clinical trials.",
    'FW Link1':
      'Charles River and Curigin Collaborate to Produce Oncolytic RNAi Gene Therapy;;https://firstwordpharma.com/story/5750672/'
  },
  {
    'Date Announced': '13-Jun-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Luminary Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'University of North Carolina',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'CAR-T therapies',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'CSPG4',
    'Deal Structure':
      'Luminary Therapeutics (Luminary Tx) and University of North Carolina (UNC) have entered into a formal licensing agreement that includes the exclusive rights to develop a novel CAR-T target – ‘CSPG4” (chimeric antigen receptor) and an optimized method of signaling dual targeting CAR’s in the pursuit of unleashing CAR-T therapies as a viable treatment option for solid tumors. The optimized signaling method was discovered by Gian Pietro Doti, MD, a professor at the University of North Carolina School of Medicine Microbiology and Immunology.',
    'FW Link1':
      'Luminary and UNC enter into a License Agreement for an Optimized Dual Targeting CAR designed for solid tumors;;https://firstwordpharma.com/story/5751173/'
  },
  {
    'Date Announced': '13-Jun-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Valo Therapeutics',
    'Country (Acquiring Company/Partner)': 'Finland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Texcell',
    'Country (Target Company/Partner)': 'France',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antigen-coated oncolytic viruses',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'PeptiCRAd-1',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Valo Therapeutics Oy (ValoTx), the developer of novel, adaptable immunotherapies for cancer, announces a collaboration with global contract research organization, Texcell, to investigate immune responses to PeptiCRAd-1 in its Phase 1 clinical study. ValoTx’s PeptiCRAd-1 combines the power of a tumor-specific adenovirus (expressing immune-stimulatory molecules CD40L and OX40L) with immunogenic tumor peptides derived from NY-ESO-1 and MAGE-A3 proteins thereby generating tumor-specific, cytotoxic T-cells that will attack and kill cancer cells.',
    'FW Link1':
      'Valo Therapeutics and Texcell Announce Research Collaboration to Evaluate Immune Responses to PeptiCRAd-1 in Phase I Clinical Study;;https://firstwordpharma.com/story/5750854/'
  },
  {
    'Date Announced': '13-Jun-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Panbela',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'University of Texas MD Anderson Cancer Center',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules, CAR-T therapies',
    'Lead Asset(s)': 'eflornithine, ivospemin',
    'Indication(s)': 'Haematologic malignancies, large B-cell lymphoma',
    'Target(s)': 'CD19',
    'Deal Structure':
      'Panbela Therapeutics, Inc . (Nasdaq: PBLA), a clinical stage company developing disruptive therapeutics for the treatment of patients with urgent unmet medical needs, announced it has entered into a sponsored research agreement with The University of Texas MD Anderson Cancer Center for the evaluation of polyamine metabolic inhibitor therapies in combination with CAR-T cell therapies in preclinical models. The initial goal of these studies will be to ascertain if eflornithine and/or ivospemin treatment will augment CAR-T mediated cytotoxicity against CD19+ large B-cell lymphoma (LBCL) cell lines.',
    'FW Link1':
      'Panbela Announces Sponsored Research Agreement to Evaluate Polyamine Metabolic Inhibitor Therapy in Combination with CAR-T Cell Therapy;;https://firstwordpharma.com/story/5751083/'
  },
  {
    'Date Announced': '14-Jun-2023',
    'Deal type': 'Product Acquisition',
    'Acquiring Company/Partner': 'ValiRx',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Imagen Therapeutics',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Total Deal Value ($m)': '0',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Screening',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'London, UK - ValiRx Plc (AIM: VAL), a life science company focusing on early-stage cancer therapeutics and women\'s health, is pleased to announce it has acquired the scientific assets of Imagen Therapeutics Limited ("Imagen") from its appointed liquidators. Imagen was a pharma services company offering a patient-derived screening platform suitable for large global clients. Total consideration paid for the Imagen assets was a single cash payment of £170,000, paid to the liquidators on signature of the purchase agreement from the Company\'s existing resources. ',
    'FW Link1':
      'Asset Purchase - Imagen Therapeutics;;https://firstwordpharma.com/story/5751517/'
  },
  {
    'Date Announced': '15-Jun-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Astellas',
    'Country (Acquiring Company/Partner)': 'Japan',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Cullgen',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '35',
    'Total Deal Value ($m)': '1,900',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Protein degradation',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Breast cancer, solid tumours',
    'Deal Structure':
      "Under the agreement, the companies will combine Cullgen's uSMITE targeted protein degradation platform featuring novel E3 ligands with Astellas' drug discovery capabilities. The partners will work to jointly identify clinical development candidates, with Astellas then having an option to exclusively license rights for further development and commercialisation. Meanwhile, Cullgen will have an option during the initial stages of clinical development to participate equally in the costs, profits and losses, and to co-promote partnered products in the US for the lead programme, previously identified as a cell cycle protein degrader for the treatment of breast cancer and other solid tumours. Cullgen will receive an upfront payment of $35 million as part of the deal, and is eligible for an additional $85 million if Astellas exercises its option on the lead programme. Overall, Cullgen could pocket more than $1.9 billion if Astellas exercises all of its license options and all milestones are achieved, as well as sales royalties.",
    'FW Link1':
      'Astellas adds to protein degrader pipeline with Cullgen deal;;https://firstwordpharma.com/story/5751742/'
  },
  {
    'Date Announced': '16-Jun-2023',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Coherus BioSciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Surface Oncology',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Total Deal Value ($m)': '65',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibodies',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'SRF388',
    'Indication(s)': 'Lung cancer, liver cancer',
    'Target(s)': 'IL-27',
    'Deal Structure':
      "Coherus BioSciences on Friday announced that it will acquire Surface Oncology through an all-stock transaction valued at up to $65 million. The deal, expected to close in the third quarter, bolsters Coherus' pipeline with the addition of SRF388, an IL-27-targeted antibody currently in Phase II for lung and liver cancer, and the CCR8-targeted antibody SFR114, which is in Phase I/II testing as a monotherapy for advanced solid tumours.",
    'FW Link1':
      'Coherus bulks up immuno-oncology pipeline with latest buy;;https://firstwordpharma.com/story/5752189/'
  },
  {
    'Date Announced': '16-Jun-2023',
    'Deal type': 'Terminated',
    'Acquiring Company/Partner': 'ValiRx',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Hokkaido University',
    'Country (Target Company/Partner)': 'Japan',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Peptides',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "ValiRx Plc (AIM: VAL), a life science company focusing on early-stage cancer therapeutics and women's health, announces the conclusion of the evaluation agreement with Hokkaido University, as announced on 16 December 2021 and extended on 6 December 2022. Under this agreement, ValiRx committed to conducting a range of experiments following initial confirmation of the synthetic route and analysis. After overcoming initial challenges in synthesis and purification of the peptide, testing has been conducted on the product. However, the profile of the product has been found to be unsuitable for further development at this stage and the evaluation will now cease.  As agreed, the data generated under the evaluation agreement will be provided to the originating academics at Hokkaido University to enable them to optimise the molecular profile.  There is no further commitment to the project by ValiRx.",
    'FW Link1':
      'ValiRx: Conclusion of Hokkaido Evaluation Agreement;;https://firstwordpharma.com/story/5752178/'
  },
  {
    'Date Announced': '16-Jun-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Cantex Pharmaceuticals',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Georgetown University',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset(s)': 'azeliragon',
    'Indication(s)': 'Cancer treatment-related cognitive decline',
    'Target(s)': 'RAGE',
    'Deal Structure':
      'vTv Therapeutics Inc. (Nasdaq: VTVT), a clinical stage biopharmaceutical company focused on the development of cadisegliatin (TTP399) as an adjunctive therapy to insulin for the treatment of type 1 diabetes ("T1D"), announced that Cantex Pharmaceuticals, Inc. recently obtained an exclusive worldwide license from Georgetown University for intellectual property related to the potential use of azeliragon to treat, prevent or alleviate cancer treatment-related cognitive decline.',
    'FW Link1':
      'vTv Therapeutics Partner Cantex Pharmaceuticals Licenses Exclusive Rights to Intellectual Property from Georgetown University for Azeliragon as a Potential Treatment for Cancer-Related Cognitive Decline;;https://firstwordpharma.com/story/5752260/'
  },
  {
    'Date Announced': '19-Jun-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Department of Health – Abu Dhabi',
    'Country (Acquiring Company/Partner)': 'United Arab Emirates',
    'Region (Acquiring Company/Partner)': 'Middle East',
    'Target Company/Partner': 'AbbVie, M42',
    'Geographic Remit of Deal': 'United Arab Emirates',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Personalised medicine, genomics',
    'Indication(s)': 'Multiple myeloma, non-small-cell lung cancer',
    'Deal Structure':
      'The Department of Health – Abu Dhabi (DoH), the regulator of the healthcare sector in the Emirate, has signed a Memorandum of Understanding (MoU) with AbbVie Biopharmaceutical and M42 to elevate collaborative efforts in tailoring personalised medicine and genomics in Abu Dhabi, the capital of the United Arab Emirates (UAE). The three parties aim to advance precision medicine in the diagnosis and treatment of multiple myeloma and non-small cell lung cancer. DoH seeks to drive research that will contribute to policy changes and guideline implementation which will enable broader patient access to innovative approaches in healthcare.',
    'FW Link1':
      'Department of Health - Abu Dhabi, M42 and AbbVie enter a strategic partnership to advance personalised medicine and genomics;;https://firstwordpharma.com/story/5752720/'
  },
  {
    'Date Announced': '20-Jun-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Welton Pharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Lubrizol',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Excipients, small molecules, chemotherapeutic agents',
    'Lead Asset(s)': 'Apisolex, SN-38',
    'Indication(s)': 'Colorectal cancer, associated gastro-intestinal cancers',
    'Target(s)': 'topoisomerase I',
    'Deal Structure':
      'The Lubrizol Corporation announces the licensing of its novel excipient polymer technology, Apisolex™ excipient, to Welton Pharma (Welton). The license allows Welton to use the Apisolex excipient worldwide to develop and commercialize a novel formulation of SN-38 to treat colorectal and associated gastro-intestinal cancers.',
    'FW Link1':
      'Lubrizol Licenses Award-Winning Apisolex Excipient to Welton Pharma to Develop a Novel Cancer Therapeutic;;https://firstwordpharma.com/story/5753171/'
  },
  {
    'Date Announced': '21-Jun-2023',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Telix',
    'Country (Acquiring Company/Partner)': 'Australia',
    'Region (Acquiring Company/Partner)': 'Oceania',
    'Target Company/Partner': 'Lightpoint Medical',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Radio-guided surgery, molecular imaging, artificial intelligence, radiopharmaceuticals',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'SENSEI',
    'Indication(s)': 'Cancer, prostate cancer',
    'Deal Structure':
      "Telix Pharmaceuticals Limited (ASX: TLX, Telix, the Company) announces it has entered into an agreement to acquire Lightpoint Medical and its SENSEI® radio-guided surgery business. Lightpoint Medical is a United Kingdom-based medical device company specialising in the intra-operative detection of targeted radiopharmaceuticals. The Lightpoint SENSEI business will be integrated into Telix and operate as a new surgeon-focused business unit, driving the ongoing development of SENSEI combined with Telix's pharmaceutical pipeline. This new business unit will also support development of Telix's other related medical device and software technologies, such as Telix's recently acquired artificial intelligence platform.",
    'FW Link1':
      'Telix to Expand Late-Stage Urologic Pipeline with Acquisition of Lightpoint Medical;;https://firstwordpharma.com/story/5753386/'
  },
  {
    'Date Announced': '21-Jun-2023',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Neogap',
    'Country (Acquiring Company/Partner)': 'Sweden',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'TCER Oncology',
    'Country (Target Company/Partner)': 'Sweden',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Personalised immunotherapy',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Swedish biotechnology company Neogap Therapeutics, which is developing personalised immunotherapy, announces its acquisition of the remaining 30 per cent of the shares in its subsidiary, TCER Oncology AB. This acquisition will broaden Neogap's patent portfolio and strengthen the company's pipeline of potential drug candidates, further consolidating Neogap's position in the field.",
    'FW Link1':
      'Neogap acquires TCER Oncology AB, strengthening its position in personalised immunotherapy;;https://firstwordpharma.com/story/5753353/'
  },
  {
    'Date Announced': '22-Jun-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Stanford University',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Intonation Research Laboratories',
    'Country (Target Company/Partner)': 'India',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Drug development',
    'Indication(s)': 'Cancerous neuroendocrine tumours',
    'Deal Structure':
      'Stanford University’s Innovative Medicines Accelerator (IMA) and Intonation Research Laboratories (Intonation) have formed a collaboration to develop treatments that target cancerous neuroendocrine tumors, or tumors that form from hormone-releasing cells. The goal of the collaboration is to reduce the time and resources it takes to translate a biomedical breakthrough into a clinically and commercially viable medicine.',
    'FW Link1':
      'Stanford University’s Innovative Medicines Accelerator and Intonation Research Laboratories (Intonation) form a collaboration to fight cancerous neuroendocrine tumors;;https://firstwordpharma.com/story/5754049/'
  },
  {
    'Date Announced': '22-Jun-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Eugia, Hetero, Dr. Reddy’s, BrightGene',
    'Country (Acquiring Company/Partner)': 'India',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Medicines Patent Pool',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal':
      'India, Egypt, Guatemala, Indonesia, Morocco, Pakistan, Philippines, Tunisia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Generic drugs, small molecules',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'nilotinib',
    'Indication(s)': 'Chronic myeloid leukaemia',
    'Target(s)': 'BCR-ABL',
    'Deal Structure':
      'The Medicines Patent Pool (MPP) announced that it has signed sublicence agreements with three India-based companies, Eugia, Hetero and Dr. Reddy’s Laboratories, along with Indonesian-based company BrightGene to manufacture generic versions of Novartis’ cancer treatment nilotinib that is used for the treatment of chronic myeloid leukaemia (CML). These are the first sublicence agreements that MPP has signed for a cancer treatment and are the result of a licence agreement signed between MPP and Novartis Pharma AG in October 2022 for their patented cancer medicine. The selected generic manufacturers can manufacture generic versions of nilotinib in India and seven middle-income countries and supply it in 44 territories included in the licence through a non-exclusive licence agreement, subject to local regulatory authorisation. The licence includes the opportunity to develop and supply generic version of nilotinib in seven middle-income countries, namely Egypt, Guatemala, Indonesia, Morocco, Pakistan, the Philippines and Tunisia, where patents on the product are pending or in force.',
    'FW Link1':
      'Four manufacturers sign sublicence agreements with the Medicines Patent Pool to produce generic versions of nilotinib for the treatment of chronic myeloid leukaemia;;https://firstwordpharma.com/story/5754059/'
  },
  {
    'Date Announced': '22-Jun-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Prime Medicine',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Cimeio Therapeutics',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immunotherapy, gene editing',
    'Indication(s)': 'Acute myeloid leukaemia, myelodysplastic syndrome',
    'Deal Structure':
      'Under the terms of the agreement, Prime Medicine will develop a Prime Editor for Cimeio’s CD117 shielding variant that will then be evaluated by both companies. If the research collaboration is successful, the companies will grant exclusive license options to each other for their technology. Prime Medicine will receive an exclusive option to license Cimeio’s cell shielding technology for CD117-shielded HSC transplant, as well as in vivo editing of CD117-shielded HSCs, for genetic diseases, not including Sickle cell disease. Cimeio will receive an exclusive option to license Prime Medicine’s Prime Editing technology for its CD117-shielded allogeneic HSC product for use in AML and MDS, and, potentially, a second shielding protein for use in AML and MDS. If the companies exercise their exclusive license options, they will each be eligible to receive economics on net sales of licensed products. Specific financial terms were not disclosed.',
    'FW Link1':
      'Prime Medicine and Cimeio Therapeutics Announce Research Collaboration to Develop Prime Edited Shielded-Cell & Immunotherapy Pairs for Genetic Diseases, Acute Myeloid Leukemia and Myelodysplastic Syndrome;;https://firstwordpharma.com/story/5754002/'
  },
  {
    'Date Announced': '22-Jun-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'IUCT-Oncopole',
    'Country (Acquiring Company/Partner)': 'France',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Cell-Easy',
    'Country (Target Company/Partner)': 'France',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell-based therapies',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'IUCT-Oncopole and Cell-Easy SAS (Cell-Easy) announced the signing of a Memorandum of Understanding to enhance their strategic collaborative relationship and open access to advanced cell-based therapies. This partnership aims to drive innovation in public health and improve access to promising new cancer therapies for patients.',
    'FW Link1':
      'IUCT-Oncopole and Cell-Easy sign a MOU to accelerate cancer patient access to advanced cell-based therapy;;https://firstwordpharma.com/story/5754102/'
  },
  {
    'Date Announced': '23-Jun-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Jubilant',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Evergreen Theragnostics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'United States',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'Lead Asset(s)': 'Octevy',
    'Indication(s)': 'Neuroendocrine tumours',
    'Deal Structure':
      'Under the terms of the agreement, upon regulatory approval, Jubilant will reconstitute, radiolabel, and deliver OCTEVY™ doses ordered by hospitals and independent PET imaging centers in the United States.',
    'FW Link1':
      'Jubilant Radiopharma and Evergreen Theragnostics Announce Collaboration for the Sale and Distribution of Neuroendocrine Tumor Diagnostic OCTEVY, (Kit for Preparation of Ga 68 DOTATOC Injection), pending FDA approval in 2H 2023;;https://firstwordpharma.com/story/5754246/'
  },
  {
    'Date Announced': '25-Jun-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Innovent Biologics',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'RemeGen',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibodies, antibody-drug conjugates',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'Tyvyt, RC88, RC108',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'PD-1, mesothelin, c-Met',
    'Deal Structure':
      'Under the agreement, Innovent will provide clinical drug supplies of TYVYT® (sintilimab injection) during the clinical trial collaboration. RemeGen will conduct Phase 1/2a clinical studies to evaluate the anti-tumor activity and safety of the combination therapy of TYVYT® (sintilimab injection) with RC88 or RC108 in Chinese patients with advanced solid tumors.',
    'FW Link1':
      'Innovent and RemeGen Enter into Clinical Trial Collaboration Investigating Combination Therapy of TYVYT (sintilimab injection) and Novel ADC Candidates for Advanced Solid Tumors in China;;https://firstwordpharma.com/story/5754696/'
  },
  {
    'Date Announced': '27-Jun-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'CoImmune',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Memorial Sloan Kettering Cancer Center',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'CAR-CIK cell therapy',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'CMN-009',
    'Indication(s)': 'Solid tumours, small-cell lung cancer',
    'Target(s)': 'DLL3',
    'Deal Structure':
      'CoImmune, Inc., a clinical stage immuno-oncology company working to redefine cancer treatment using best-in-class cellular immunotherapies, announced it has exercised its option to obtain an exclusive license in the Delta-like Ligand 3 (DLL3)-targeted, allogeneic Chimeric Antigen Receptor-Cytokine Induced Killer (CAR-CIK) cell therapy field to interleukin-18 (IL-18) Armored CAR technology under a prior agreement with Memorial Sloan Kettering Cancer Center (MSK). The company is coupling the technology with allogeneic CIK cells and launched a development program focused on solid tumors with initial trials planned in small cell lung cancer (SCLC).',
    'FW Link1':
      'CoImmune Obtains License to Target DLL3 with IL-18 Armored CAR Technology;;https://firstwordpharma.com/story/5755533/'
  },
  {
    'Date Announced': '27-Jun-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Mestag Therapeutics',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'VIB',
    'Country (Target Company/Partner)': 'Belgium',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibodies',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Mestag Therapeutics ('Mestag'), an immunotherapy company focusing on fibroblast-immune interactions, and VIB, the leading life science research institute in Flanders, today announced entering into an agreement under which Mestag obtains the exclusive worldwide rights to research, develop and commercialize a panel of single domain antibodies, also known as Nanobodies®, to an undisclosed target that plays a central role in anti-cancer immunity. Under the terms of the agreement, Mestag will be responsible for the research, development and commercialization of the antibodies, which are the outcome of many years of leading-edge research within VIB. In return, VIB will receive an upfront payment, future success-based milestones and royalty payments.",
    'FW Link1':
      'Mestag Therapeutics and VIB enter into an exclusive partnership in oncology;;https://firstwordpharma.com/story/5755222/'
  },
  {
    'Date Announced': '29-Jun-2023',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Immunome',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'MorphImmune',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibodies, small molecules, radiotherapy',
    'Indication(s)': 'Cancer',
    'Target(s)': 'IL-38, TLR7, FAP',
    'Deal Structure':
      'Immunome (Nasdaq: IMNM), a biopharmaceutical company utilizing a proprietary human memory B-cell platform to discover and develop antibody therapeutics to improve patient care, and Morphimmune, a private biotechnology company focused on developing targeted oncology therapeutics, announced that they have entered into a definitive merger agreement. The Boards of Directors of both companies have approved the all-stock transaction. The combined company, which will operate as Immunome and retain the same ticker symbol, will be headquartered in Seattle, WA and will maintain cost-efficient research lab facilities in Exton, PA and West Lafayette, IN.',
    'FW Link1':
      'Immunome and Morphimmune Announce Definitive Merger Agreement and Simultaneous Private Placement Investment of $125 Million to Develop Targeted Cancer Therapies;;https://firstwordpharma.com/story/5756320/'
  },
  {
    'Date Announced': '29-Jun-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'CytoMed Therapeutics',
    'Country (Acquiring Company/Partner)': 'Singapore',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'University of Texas MD Anderson Cancer Center',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'CAR-T therapies',
    'Indication(s)': 'Acute myeloid leukaemia, breast cancer',
    'Deal Structure':
      "The research collaboration will focus on using gamma-delta T-cells to treat acute myeloid leukemia (AML) and breast cancer. The goal of the two-year CytoMed-MD Anderson Cancer Center collaboration is to develop new treatments for these AML and breast cancer patients at an affordable price point. Led by Dr. Venkata Lokesh Battula, associate professor of Leukemia at MD Anderson, the research teams will evaluate CytoMed's allogenic gamma-delta T-cells on various subtypes of AML and breast cancer in vitro and in vivo.",
    'FW Link1':
      'CytoMed Therapeutics Collaborates With The University of Texas MD Anderson Cancer Center to Advance Its Unique CAR-T Technology;;https://firstwordpharma.com/story/5756242/'
  },
  {
    'Date Announced': '29-Jun-2023',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Lilly',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Emergence Therapeutics',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'ETx-22',
    'Indication(s)': 'Solid tumours, bladder cancer, breast cancer',
    'Target(s)': 'Nectin-4',
    'Deal Structure':
      'Heidelberg Pharma AG (FSE: HPHA) announced that Eli Lilly and Company will purchase the shares in Emergence Therapeutics AG, Duisburg, Germany, (Emergence), including shares from the conversion of the Emergence convertible bonds, that are held by its subsidiary Heidelberg Pharma Research GmbH as part of Eli Lilly and Company’s acquisition of all of the outstanding shares of Emergence.',
    'FW Link1':
      'Heidelberg Pharma Sells Minority Shareholding in Emergence Therapeutics;;https://firstwordpharma.com/story/5756739/'
  },
  {
    'Date Announced': '30-Jun-2023',
    'Deal type': 'Product Acquisition',
    'Acquiring Company/Partner': 'DRI Healthcare Trust',
    'Country (Acquiring Company/Partner)': 'Canada',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Eisai',
    'Country (Target Company/Partner)': 'Japan',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '85',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'elacestrant',
    'Indication(s)': 'Breast cancer',
    'Target(s)': 'Oestrogen receptor',
    'Deal Structure':
      'Eisai Co., Ltd. (Headquarters: Tokyo, CEO: Haruo Naito, “Eisai”) announced that it has entered into an agreement to transfer all future economic rights for elacestrant (generic name), approved for the treatment for breast cancer in the United States, to DRI Healthcare Trust (Headquarters: Ontario, Canada, “DRI Healthcare”). Elacestrant is a selective estrogen receptor degrader discovered by Eisai. In 2006, Eisai granted Radius Health, Inc (Headquarters: Massachusetts, United States) an exclusive worldwide license (with Japan included in 2015) concerning the research, development, manufacture and marketing of the given compound and, in return, had the rights to receive certain financial payments, including milestones, and royalties on net sales of elacestrant, etc. In this agreement with DRI Healthcare, Eisai transfers to DRI Healthcare all of its future economic rights. Eisai will receive an upfront payment of 85 million U.S. dollars for the transfer.',
    'FW Link1':
      'Eisai Transfers All Future Economic Rights for Elacestrant, a Selective Estrogen Receptor Degrader, to DRI Healthcare;;https://firstwordpharma.com/story/5756789/'
  },
  {
    'Date Announced': '30-Jun-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Leads Biolabs, BeiGene',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Chime Biologics',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset(s)': 'LBL-007',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'LAG-3',
    'Deal Structure':
      "Chime Biologics, a leading CDMO that enables its partners' success in biologics, announced that it has established 3-way strategic cooperation with Leads Biolabs and BeiGene to accelerate LBL-007 mAb development and manufacturing for speedy clinical advancement.",
    'FW Link1':
      'Chime Biologics Announces Strategic Cooperation with Leads Biolabs and BeiGene to Advance LBL-007 mAb Development and Manufacturing Globally;;https://firstwordpharma.com/story/5756813/'
  },
  {
    'Date Announced': '4-Jul-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Pfizer',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Samsung Group',
    'Country (Target Company/Partner)': 'Korea, South',
    'Region (Target Company/Partner)': 'Asia',
    'Total Deal Value ($m)': '897',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Biosimilars',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Pfizer has finalised a pair of deals worth a combined $897 million for Samsung Biologics to manufacture products for the pharmaceutical company. Samsung Biologics made the disclosures on Tuesday, saying the arrangements would see it produce biosimilar products at its new Plant 4 facility in South Korea.',
    'FW Link1':
      'Pfizer taps Samsung Biologics in manufacturing deals worth $897 million;;https://firstwordpharma.com/story/5757698/'
  },
  {
    'Date Announced': '5-Jul-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Takeda',
    'Country (Acquiring Company/Partner)': 'Japan',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'F-star',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Total Deal Value ($m)': '1,000',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Multi-specific antibodies',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Under the latest deal, F-star and Takeda will jointly research and develop novel Fcab domains against undisclosed immuno-oncology targets. The Japanese drugmaker will receive an exclusive global licence to antibodies incorporating Fcab domains arising from the collaboration, while F-star will retain rights to antibodies incorporating certain other Fcab domains. Along with an undisclosed upfront payment and research funding, Takeda will make milestone payments to F-star of up to approximately $1 billion across multiple programmes. F-star is also eligible to receive sales royalties on any commercial product resulting from the licence.',
    'FW Link1':
      'Takeda again turns to F-star, this time for multi-specific cancer immunotherapies;;https://firstwordpharma.com/story/5757946/'
  },
  {
    'Date Announced': '5-Jul-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Pheon Therapeutics',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Biocytogen',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibodies, antibody-drug conjugates',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Under the terms of the agreement, Pheon will develop and commercialize an antibody developed using Biocytogen’s proprietary RenMice™ platforms. Biocytogen will receive an upfront payment and is eligible for development and commercial milestone payments, as well as single-digit royalties on net sales.',
    'FW Link1':
      'Biocytogen Pharmaceuticals Enters Into License Agreement With Pheon Therapeutics;;https://firstwordpharma.com/story/5757955/'
  },
  {
    'Date Announced': '5-Jul-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'BioNTech',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'UK Government',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'United Kingdom',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Personalised mRNA-based immunotherapies, vaccines',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'The government has signed a long term partnership agreement with the German-based company BioNTech – which previously developed a world leading COVID-19 vaccine with Pfizer – to ensure more patients can benefit from personalised cancer treatments. This includes UK-based clinical trials intended to help treat patients through the use of precision immunotherapies which work by stimulating the immune system to recognise and eliminate cancer cells. The aim is to provide access to personalised treatments for up to 10,000 patients by 2030. BioNTech SE has already begun conducting clinical trials in the UK. Further trials will be launching although the majority of patients are expected to be enrolled from 2026 onwards. To help deliver this research, BioNTech plans to set up new laboratories in Cambridge with an expected capacity of more than 70 highly skilled scientists as well as a new regional hub for the United Kingdom.',
    'FW Link1':
      'Major agreement to deliver new cancer vaccine trials;;https://firstwordpharma.com/story/5758128/'
  },
  {
    'Date Announced': '6-Jul-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Carrick Therapeutics',
    'Country (Acquiring Company/Partner)': 'Ireland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Arvinas, Pfizer',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'samuraciclib, vepdegestrant',
    'Indication(s)': 'Breast cancer',
    'Target(s)': 'CDK7, oestrogen receptor',
    'Deal Structure':
      'This agreement covers the execution of a Phase 1b/2 clinical trial to evaluate the novel combination of Carrick’s samuraciclib (CT7001), an oral and first-in-class inhibitor of CDK7, and Arvinas’ vepdegestrant (ARV-471), an investigational oral PROTAC® (PROteolysis TArgeting Chimera) estrogen receptor protein degrader being developed in collaboration with Pfizer, in patients who have received prior CDK4/6i, with ER+, HER2- metastatic breast cancer. Under the terms of the agreement, Arvinas will be the regulatory sponsor of the study in the U.S. and Pfizer will be the acting sponsor for the study conducted in the U.S., as well as the regulatory and acting sponsor of the study outside of the U.S. The three parties will collaborate through a Joint Development Committee. It is anticipated that the Phase 1b/2 trial will be initiated in the second half of 2023.',
    'FW Link1':
      'Carrick Therapeutics Announces Clinical Trial Collaboration with Arvinas and Pfizer to Evaluate Samuraciclib and Vepdegestrant Combination;;https://firstwordpharma.com/story/5758515/'
  },
  {
    'Date Announced': '6-Jul-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Lyell Immunopharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'MaxCyte',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapies, electroporation',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      'Under the terms of the agreement, Lyell Immunopharma obtains non-exclusive clinical and commercial rights to use MaxCyte’s Flow Electroporation® technology and ExPERT™ platform. In return, MaxCyte is eligible to receive platform licensing fees, clinical milestone payments and sales-based payments.',
    'FW Link1':
      'MaxCyte Signs Strategic Platform License with Lyell Immunopharma;;https://www.firstwordhealthtech.com/story/5758595/'
  },
  {
    'Date Announced': '6-Jul-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Hillstream BioPharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Applied Biomedical Science Institute',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Antibodies, bi-specific antibodies, antibody-drug conjugates',
    'Indication(s)':
      'Breast cancer, gastric cancer, lung cancer, ovarian cancer',
    'Target(s)': 'HER2, HER3',
    'Deal Structure':
      'Hillstream BioPharma, Inc. (Nasdaq: HILS) ("Hillstream" or the "Company"), a biotechnology company developing therapeutic candidates targeting drug resistant and devastating cancers using immuno-oncology targeted novel biologics, announced the signing of an exclusive agreement with Applied Biomedical Science Institute (ABSI) to license technology for human antibodies targeting novel HER2 and HER3 Conformational Epitopes. The goal is to develop proprietary multi-format biologics, including bi-specific antibodies, and antibody drug conjugates (ADCs).',
    'FW Link1':
      'Hillstream BioPharma Licenses Technology to Develop Proprietary HER2 and HER3 Antibody Drug Conjugates against Drug-Resistant Breast, Lung, Gastric, and Ovarian Cancers;;https://firstwordpharma.com/story/5758516/'
  },
  {
    'Date Announced': '6-Jul-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Shorla Oncology',
    'Country (Acquiring Company/Partner)': 'Ireland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Undisclosed',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'United States',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Chemotherapy',
    'Lead Asset(s)': 'PIP-101',
    'Indication(s)': 'Leukaemia',
    'Deal Structure':
      'Shorla Oncology (‘Shorla’), a U.S.-Ireland pharmaceutical company, announced it has entered into a licensing agreement and strategic partnership with a U.K.-based innovative drug development and manufacturing company. Under the terms of the agreement, Shorla will obtain an exclusive license from the developer to register and commercialize PIP-101, the first palatable oral solution of the related chemotherapeutic agent in the U.S. Under the terms of the agreement, the developer will continue to manufacture PIP-101. Once Shorla has obtained approval of its New Drug Application (NDA), the company will be responsible for the timely launch, commercialization and sales of PIP-101 in the U.S.',
    'FW Link1':
      'Shorla Oncology Announces Licensing Agreement and Strategic Partnership for Rights to Market Chemotherapy Drug, PIP-101, in the United States;;https://firstwordpharma.com/story/5758560/'
  },
  {
    'Date Announced': '6-Jul-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'GeneQuantum',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Aimed Bio',
    'Country (Target Company/Partner)': 'Korea, South',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'GeneQuantum Healthcare announced an expansion of its partnership with South Korean biotech Aimed Bio, a spin-off from Samsung Medical Centre, to jointly develop up to five new antibody-drug conjugate (ADC) drugs to address unmet clinical needs. The companies first teamed up last year on the joint development of an FGFR3-targeting ADC dubbed GQ1011.',
    'FW Link1':
      'GeneQuantum, Aimed Bio deepen ADC drug alliance;;https://firstwordpharma.com/story/5758714/'
  },
  {
    'Date Announced': '9-Jul-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'RemeGen',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Innovent Biologics',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Antibody-drug conjugates, monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'RC88, RC108, sintilimab',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'Mesothelin, c-MET, PD-1',
    'Deal Structure':
      'RemeGen Co., Ltd. ("RemeGen" or "the Company") (HKG: 9995, SHA: 688331), a fully-integrated commercial-stage biotechnology company, announced a clinical research and supply agreement with Innovent Biologics ("Innovent")  (HKEX: 01801). This collaboration will focus on conducting clinical trials exploring combination therapies of the novel antibody-drug conjugates (ADCs), RC88 (targeting MSLN) and RC108 (targeting c-MET), along with the PD-1 inhibitor sintilimab injection (TYVYT®). According to the agreement, Innovent will supply clinical drug supplies of sintilimab during the clinical trial collaboration, while RemeGen will conduct Phase I/IIa clinical studies in China. The trials will assess the safety, tolerability, and preliminary efficacy of RC88 or RC108 combined with sintilimab in Chinese cancer patients.',
    'FW Link1':
      'RemeGen and Innovent Collaborate on Clinical Trials to Evaluate the Potential of RC88 and RC108 Combined with PD-1 Therapy for Advanced Solid Tumors;;https://firstwordpharma.com/story/5759383/'
  },
  {
    'Date Announced': '10-Jul-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'J&J',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Nanobiotix',
    'Country (Target Company/Partner)': 'France',
    'Region (Target Company/Partner)': 'Europe',
    'Upfront Value ($m)': '30',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Radioenhancers',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'NBTXR3',
    'Indication(s)': 'Head and neck cancer, solid tumours',
    'Deal Structure':
      'As part of the deal, Janssen will acquire an exclusive worldwide license for NBTXR3, excluding territories previously licensed to LianBio. Nanobiotix will receive an upfront cash licensing fee of $30 million, plus in-kind regulatory and development support for NANORAY-312 worth up to another $30 million. Nanobiotix will maintain operational control of that trial and all other ongoing studies, along with NBTXR3 manufacture, clinical supply and initial commercial supply. The radioenhancer is also being tested as a systemic agent in combination with anti-PD-1 inhibitors for patients with metastatic cancers. Meanwhile, Johnson & Johnson will be fully responsible for an initial Phase II study evaluating NBTXR3 for patients with Stage 3 lung cancer and will have the right to assume control of studies currently led by Nanobiotix. Nanobiotix stands to gain up to $1.8 billion in potential milestones. The agreement also includes a framework for additional success-based milestone payments of up to $650 million for five new indications that may be developed by Janssen at its sole discretion, and up to $220 million per indication that may be developed by Nanobiotix in alignment with Janssen. Nanobiotix will receive tiered double-digit royalties on net sales of NBTXR3 as well, if the radioenhancer is approved. Separately, the company is eligible to receive up to $30 million in equity investments from Johnson & Johnson.',
    'FW Link1':
      'J&J inks licensing deal for Nanobiotix radioenhancer NBTXR3;;https://firstwordpharma.com/story/5759147/'
  },
  {
    'Date Announced': '10-Jul-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'BeiGene',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Duality Biologics',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      'BeiGene (NASDAQ: BGNE; HKEX: 06160; SSE: 688235), a global biotechnology company, and DualityBio, a next-generation ADC company, announced an agreement for BeiGene to acquire an exclusive option for a global clinical and commercial license to an investigational, preclinical ADC therapy for patients with select solid tumors. Under the terms of the agreement, DualityBio will receive an upfront payment, and will be eligible for a payment contingent upon BeiGene exercising its option and additional payments based upon the achievement of certain development, regulatory, and commercial milestones, totaling up to $1.3 billion, in addition to tiered royalties. Upon exercising its option, BeiGene will hold global clinical, manufacturing, and commercial rights while DualityBio will perform all research activities through IND-enabling studies and support future IND filings by BeiGene.',
    'FW Link1':
      'BeiGene teams up with DualityBio on preclinical ADC asset for solid tumours;;https://firstwordpharma.com/story/5759156/'
  },
  {
    'Date Announced': '10-Jul-2023',
    'Deal type': 'Terminated',
    'Acquiring Company/Partner': 'Novartis',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'BeiGene',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '300',
    'Total Deal Value ($m)': '2,895',
    'Geographic Remit of Deal': 'North America, Europe, Japan',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'ociperlimab',
    'Indication(s)': 'Non-small-cell lung cancer',
    'Target(s)': 'TIGIT',
    'Deal Structure':
      'BeiGene disclosed in a US securities filing that it is regaining full global rights to develop and manufacture ociperlimab following the termination of its licensing agreement with Novartis. The Swiss drugmaker had paid $300 million upfront in 2021 to license rights to ociperlimab, an experimental TIGIT inhibitor with intact Fc function, as part of a deal that included up to $1.8 billion in potential milestones.',
    'FW Link1':
      'BeiGene regains TIGIT drug after Novartis passes on option;;https://firstwordpharma.com/story/5759783/'
  },
  {
    'Date Announced': '11-Jul-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Monopar Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'NUS',
    'Country (Target Company/Partner)': 'Singapore',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Radiopharmaceuticals, monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'MNPR-101',
    'Indication(s)': 'Cancer, soft tissue sarcoma',
    'Target(s)': 'urokinase plasminogen activator receptor (uPAR)',
    'Deal Structure':
      'Monopar Therapeutics Inc. (Nasdaq: MNPR), a clinical-stage biopharmaceutical company focused on developing innovative treatments for cancer, announced a collaboration with the Cancer Science Institute of Singapore (CSI Singapore) at the National University of Singapore (NUS) to evaluate radiopharmaceutical versions of MNPR-101 in several aggressive cancers. MNPR-101 is a novel, first-in-class humanized monoclonal antibody to the urokinase Plasminogen Activator Receptor (uPAR).',
    'FW Link1':
      'Monopar Announces MNPR-101 Radiopharma Collaboration Agreement with National University of Singapore;;https://firstwordpharma.com/story/5760013/'
  },
  {
    'Date Announced': '11-Jul-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'CellCentric',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Pfizer',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset(s)': 'inobrodib',
    'Indication(s)': 'Multiple myeloma, haematological malignancies',
    'Target(s)': 'p300/CBP',
    'Deal Structure':
      'CellCentric, an innovative UK-based biotechnology company, announces a strategic investment from Pfizer including $25m to help finance further development of inobrodib, its first-in-class p300/CBP inhibitor to treat specific types of cancer. Pfizer will support CellCentric with its own clinical development program in multiple myeloma (MM) for 2024, alongside ongoing trials in specific haematological malignancies, through its Pfizer Ignite offering. As part of the collaboration, Astrid Ruefli-Brasse, Vice President, Tumour Biology, Pfizer Worldwide Research & Development, will join CellCentric’s Scientific Advisory Board and Sriram Krishnaswami, Vice President and Development Head, Multiple Myeloma, Pfizer Global Product Development, will serve as an advisor to CellCentric.',
    'FW Link1':
      'CellCentric announces $25m strategic investment from Pfizer;;https://firstwordpharma.com/story/5759799/'
  },
  {
    'Date Announced': '12-Jul-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'LianBio',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'AstraZeneca',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'BBP-398, osimertinib',
    'Indication(s)': 'Non-small-cell lung cancer',
    'Target(s)': 'SHP2, EGFR',
    'Deal Structure':
      'LianBio (Nasdaq: LIAN), a biotechnology company dedicated to bringing innovative medicines to patients in China and other major Asian markets, announced a clinical supply agreement with AstraZeneca in China to evaluate the safety and efficacy of BBP-398, an investigational SHP2 inhibitor, in combination with AstraZeneca’s osimertinib, an epidermal growth factor receptor (EGFR) inhibitor, in a Phase 1 clinical study for the treatment of patients with non-small cell lung cancer (NSCLC) with EGFR mutations.',
    'FW Link1':
      'LianBio Announces Clinical Supply Agreement with AstraZeneca in China to Evaluate BBP-398 in Combination with Osimertinib in Patients with Non-Small Cell Lung Cancer with EGFR Mutations;;https://firstwordpharma.com/story/5760332/'
  },
  {
    'Date Announced': '13-Jul-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Roche',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'KSQ Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'KSQ-4279',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'USP1',
    'Deal Structure':
      "Roche and KSQ Therapeutics said Thursday they have entered into a worldwide license and collaboration agreement for the development and commercialisation of the latter's KSQ-4279 candidate, which is being studied as a treatment for solid tumours. Roche will assume development of the potentially first-in-class, selective small molecule inhibitor of USP1, a protein regulating DNA damage response (DDR). Financial terms of the deal were not disclosed. Under the agreement, KSQ will receive an unspecified upfront payment and is also eligible for additional milestone and royalty payments. Roche gains a global license and will be fully responsible for the further development of KSQ-4279 in 2024.",
    'FW Link1':
      "Roche assumes development of KSQ's USP1 inhibitor for cancer;;https://firstwordpharma.com/story/5760752/"
  },
  {
    'Date Announced': '13-Jul-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Vivan Therapeutics',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner':
      'Institute of Cancer Research, Bellvitge Biomedical Research Institute',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules, artificial intelligence',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Target(s)': 'KRAS',
    'Deal Structure':
      'Researchers are set to to combine expertise in drug discovery, artificial intelligence (AI), and experimental cancer models and platforms in a new programme to design the precision cancer drugs of the future. Under an academic and commercial collaboration between The Institute of Cancer Research, London, the Bellvitge Biomedical Research Institute (IDIBELL), Barcelona, and the company Vivan Therapeutics, a multi-disciplinary team will seek to create drugs that can counteract drug resistance. The team will have a particular focus on creating drugs that could target KRAS, a notorious cancer-driving protein. The research team aims to design and develop small molecules that could simultaneously target multiple weaknesses in cancers with faults in KRAS.',
    'FW Link1':
      'New AI drug discovery collaboration aims to design new precision cancer drugs;;https://firstwordpharma.com/story/5760937/'
  },
  {
    'Date Announced': '13-Jul-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'InnoCare',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'ArriVent Biopharma',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset(s)': 'ICP-189, furmonertinib',
    'Indication(s)': 'Non-small-cell lung cancer',
    'Target(s)': 'SHP2, EGFR',
    'Deal Structure':
      'Under the agreement, InnoCare and ArriVent will jointly conduct a clinical study to evaluate the anti-tumor activity and safety of ICP-189 combined with furmonertinib in patients with advanced non-small cell lung cancer (NSCLC).',
    'FW Link1':
      'InnoCare and ArriVent Announce Clinical Development Collaboration;;https://firstwordpharma.com/story/5761116/'
  },
  {
    'Date Announced': '17-Jul-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'InxMed',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'GeneQuantum',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "GeneQuantum granted non-exclusive licensing of its proprietary  conjugation technology to InxMed, supporting the development of the next generation of targeted conjugate ADC drugs. According to the agreement, GeneQuantum will provide InxMed with its independently developed, globally leading technologies in site-specific glycan conjugation, stable linker small molecule technology, and intelligent continuous conjugation production platform. This will support InxMed in developing innovative ADC drugs. The agreement was signed following the successful concept validation results of the candidate drugs developed by both parties. After the agreement is signed, GeneQuantum will receive an upfront payment and will be eligible for milestone payments related to specific research and development progress and sales. They will also receive royalties based on the net commercial sales of the ADC products. After the agreement is signed, InxMed will have the right to use GeneQuantum's enzymatic site-specific conjugation technology on the candidate molecules mentioned above. InxMed will be responsible for the research, development, manufacturing, and commercialization of the ADC candidate molecules.",
    'FW Link1':
      'GeneQuantum and InxMed have reached a technology licensing collaboration, providing strong support for innovative ADC drug research and development;;https://firstwordpharma.com/story/5761545/'
  },
  {
    'Date Announced': '17-Jul-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Cumulus Oncology',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'leadXpro',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Cumulus Oncology (“Cumulus”) and leadXpro AG (“leadXpro”) announce they have signed a research collaboration focused on the discovery and development of small molecules targeting an undisclosed and novel cancer-focused G protein-coupled receptor (GPCR). A GPCR portfolio company, created by Cumulus Oncology, focused on novel GPCR targets on tumours and the tumour microenvironment, will have the option to license the Lead compound series as well as resulting intellectual property from the research collaboration. Under the license terms, leadXpro will retain an economic interest in the Lead compound series.',
    'FW Link1':
      'Cumulus Oncology and leadXpro Announce Collaboration to Develop Small Molecules against Novel Cancer-Focused GPCR Targets;;https://firstwordpharma.com/story/5761423/'
  },
  {
    'Date Announced': '18-Jul-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Massive Bio',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'CureMatch',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Genomics, artificial intelligence',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Massive Bio, an AI-powered platform connecting patients with clinical trials and CureMatch, a healthcare technology company leveraging Knowledge Representation & Reasoning (KRR) AI for precision medicine support for oncology, and have announced a transformative partnership aimed at revolutionizing cancer treatment through advanced genomics, artificial intelligence, and expansive clinical trial accessibility. Massive Bio and CureMatch will leverage their cutting-edge technologies to provide oncologists and their patients unprecedented access to potentially lifesaving clinical trials, personalized cancer treatments, and comprehensive reports on the most effective therapies based on individual patients' profiles.",
    'FW Link1':
      'Massive Bio and CureMatch Announce Groundbreaking Partnership to Revolutionize Cancer Treatment;;https://firstwordpharma.com/story/5762196/'
  },
  {
    'Date Announced': '19-Jul-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'BioNTech',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Biotheus',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Bispecific antibodies, monoclonal antibodies',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Under the terms of the agreement, Biotheus will grant BioNTech worldwide, exclusive options to a preclinical-stage bispecific antibody and a clinical-stage monoclonal antibody for cancer therapy. In addition, Biotheus will grant BioNTech exclusive licenses to existing panels of VHH binders against multiple targets along with options to request Biotheus to generate new panels of VHH binders against targets nominated by BioNTech. In exchange, BioNTech will provide Biotheus with an upfront payment and following option exercise on Biotheus' preclinical-stage bispecific antibody, Biotheus will also be eligible for clinical, regulatory, and commercial milestone payments and tiered single digit royalties.",
    'FW Link1':
      'Biotheus Announces Strategic Research Collaboration and Worldwide License Agreement with BioNTech;;https://firstwordpharma.com/story/5762718/'
  },
  {
    'Date Announced': '20-Jul-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'RenovoRx',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Imugene',
    'Country (Target Company/Partner)': 'Australia',
    'Region (Target Company/Partner)': 'Oceania',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Oncolytic viruses, drug delivery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'RenovoRx, Inc. ("RenovoRx") (Nasdaq: RNXT), a clinical-stage biopharmaceutical company developing targeted combination therapies, andImugene Ltd ("Imugene") (ASX: IMU), a clinical-stage immuno-oncology company, announced a strategic research collaboration to optimize the delivery of Imugene\'s oncolytic virus therapy with RenovoRx\'s TAMP (Trans-Arterial Micro-Perfusion) therapy platform for the treatment of difficult-to-access tumors.',
    'FW Link1':
      'RenovoRx and Imugene Announce Collaboration to Deliver Oncolytic Virus Therapy Using Proprietary Trans-Arterial Micro-Perfusion (TAMP) Platform;;https://firstwordpharma.com/story/5762733/'
  },
  {
    'Date Announced': '25-Jul-2023',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'Illumina',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Pillar Biosciences',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Next-generation sequencing, genomic profiling',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Illumina Inc. (NASDAQ: ILMN), a global leader in DNA sequencing and array-based technologies, and Pillar Biosciences Inc., the pioneers of Decision Medicine™, announced a strategic partnership to make Pillar's suite of oncology assays commercially available globally as part of the Illumina portfolio of oncology products. The agreement will result in an unprecedented offering of complementary next-generation sequencing solutions that will enhance the efficiency, accuracy, and cost-effectiveness of oncology testing through advanced sequencing techniques, improving patient access to personalized cancer treatment options.",
    'FW Link1':
      'Illumina and Pillar Biosciences partner to improve access to personalized cancer treatment options;;https://www.firstwordhealthtech.com/story/5764236/'
  },
  {
    'Date Announced': '25-Jul-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'HaemaLogiX',
    'Country (Acquiring Company/Partner)': 'Australia',
    'Region (Acquiring Company/Partner)': 'Oceania',
    'Target Company/Partner': 'Peter MacCallum Cancer Centre',
    'Country (Target Company/Partner)': 'Australia',
    'Region (Target Company/Partner)': 'Oceania',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'CAR-T therapies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'KMA.CAR-T',
    'Indication(s)': 'Multiple myeloma',
    'Target(s)': 'Kappa Myeloma Antigen (KMA)',
    'Deal Structure':
      "Clinical stage Australian biotech company HaemaLogiX Ltd is pleased to announce it has entered into a co-development agreement with the world leading Peter MacCallum Cancer Centre (Peter Mac) to conduct the first in human Phase I trial of HaemaLogiX's CAR-T immunotherapy, KMA.CAR-T, for kappa-type multiple myeloma. Under the agreement, HaemaLogiX brings its patented KappaMab technology to the collaboration with Peter Mac to develop the technology, method of manufacturing and conduct the trial.",
    'FW Link1':
      'HaemaLogiX and Peter MacCallum Cancer Centre Announce Myeloma CAR-T Phase I Clinical Trial Agreement;;https://firstwordpharma.com/story/5764189/'
  },
  {
    'Date Announced': '25-Jul-2023',
    'Deal type': 'Terminated',
    'Acquiring Company/Partner': 'Roche',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'SQZ Biotech',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapies',
    'Indication(s)': 'HPV 16-positive solid tumours',
    'Deal Structure':
      'SQZ Biotechnologies Company (OTC: SQZB) (the “Company” or “SQZ”) announced that Roche has determined that it will not exercise its option for HPV 16 positive solid tumors under the SQZ-APC-HPV program. SQZ Biotechnologies will regain full clinical development and future commercialization rights for its programs targeting HPV 16 positive tumors. The Company intends to explore potential strategic partnerships to support the advancement of its oncology programs and platforms.',
    'FW Link1':
      'SQZ Biotechnologies Provides Update on Collaboration with Roche;;https://firstwordpharma.com/story/5764177/'
  },
  {
    'Date Announced': '25-Jul-2023',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'AstraZeneca',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Amoy Diagnostics',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Companion diagnostics',
    'Indication(s)': 'Ovarian cancer',
    'Deal Structure':
      "China's Amoy Diagnostics is teaming up with a unit of AstraZeneca to supply companion diagnostics for detecting ovarian cancer in Chinese patients, Yicai Global reported. Kudos Pharmaceutical will use Amoy's homologous recombination deficiency detection device as a companion diagnostic for its ovarian cancer drug olaparib developed with Merck Sharp & Dohme.",
    'FW Link1':
      "China's Amoy to Provide AstraZeneca's Unit With Companion Diagnostics for Cancer Therapy;;https://www.firstwordhealthtech.com/story/5764126/"
  },
  {
    'Date Announced': '26-Jul-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Canadian Nuclear Laboratories',
    'Country (Acquiring Company/Partner)': 'Canada',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner':
      'Sylvia Fedoruk Canadian Centre for Nuclear Innovation',
    'Country (Target Company/Partner)': 'Canada',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Medical isotopes, radiopharmaceuticals',
    'Lead Asset(s)': 'Actinium-225',
    'Indication(s)': 'Cancer, prostate cancer',
    'Deal Structure':
      'Canadian Nuclear Laboratories (CNL), Canada’s premier nuclear science and technology laboratory, is pleased to announce that it has signed an agreement with the Sylvia Fedoruk Canadian Centre for Nuclear Innovation, Inc. (Fedoruk Centre), that will significantly increase the global supply of Actinium-225, a ground-breaking new medical isotope. Under terms of the new agreement, CNL will develop and supply targets that will be irradiated by the Fedoruk Centre and returned to CNL for further processing, and then ship the material to another long-term partner, ITM, for refinement and distribution. This partnership will significantly increase the annual global supply of Actinium-225, enabling continued research and clinical trials.',
    'FW Link1':
      'CNL Announces Project to Significantly Increase Production of Rare Medical Isotope Actinium-225, a Promising New Weapon in the Fight Against Cancer;;https://firstwordpharma.com/story/5764743/'
  },
  {
    'Date Announced': '26-Jul-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Nexo Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'University of Texas MD Anderson Cancer Center',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Nexo Therapeutics emerged from stealth on Wednesday with $60 million in Series A financing led by founding investor Versant Ventures. In tandem, the start-up also announced a multi-year strategic research collaboration with The University of Texas MD Anderson Cancer Center focused on developing new therapies against previously undruggable cancer targets.',
    'FW Link1':
      'Nexo emerges with $60 million and inks multi-year cancer collab;;https://firstwordpharma.com/story/5764409/'
  },
  {
    'Date Announced': '9-Aug-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Merck & Co.',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Otsuka',
    'Country (Target Company/Partner)': 'Japan',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '35',
    'Total Deal Value ($m)': '535',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Target(s)': 'p53',
    'Deal Structure':
      "Under the agreement, Otsuka's Astex Pharmaceuticals unit will provide Merck & Co. with lead compounds for further optimisation and preclinical development. Meanwhile, Merck has an exclusive global license to research, develop and commercialise candidates arising from the collaboration, with the company funding all work. Astex will receive an upfront payment of $35 million and is eligible for milestone payments totalling approximately $500 million per programme, as well as tiered sales royalties on sales.",
    'FW Link1':
      "Merck & Co. deepens cancer tie-up with Otsuka's Astex unit;;https://firstwordpharma.com/story/5768331/"
  },
  {
    'Date Announced': '7-Aug-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Astellas',
    'Country (Acquiring Company/Partner)': 'Japan',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Poseida Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '25',
    'Total Deal Value ($m)': '50',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'CAR-T therapies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'P-MUC1C-ALLO1',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'MUC1-C',
    'Deal Structure':
      "Astellas will invest a total of $50 million, including $25 million to acquire 8,333,333 shares of common stock of Poseida (approximately 8.8% of the outstanding common stock of Poseida) at $3.00 per share in a private placement and a one-time $25 million payment for a right of exclusive negotiation and first refusal to license one of Poseida's clinical stage programs: P-MUC1C-ALLO1, an allogeneic CAR-T cell therapy in development for multiple solid tumour indications.",
    'FW Link1':
      "Astellas and Poseida Therapeutics Announce Strategic Investment to Support Poseida's Commitment to Redefining Cancer Cell Therapy;;https://www.firstwordpharma.com/story/5768080/"
  },
  {
    'Date Announced': '1-Aug-2023',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Revolution Medicines',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'EQRx',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "The number of shares in the all-stock buyout will be based on an exchange-ratio formula equal to roughly 7.7 million Revolution shares, plus shares equal to $870 million divided by a price that is a 6% discount to the five-day weighted average of Revolution's share price closer to the stockholder vote.",
    'FW Link1':
      "EQRx's attempted revolution ends in acquisition by Revolution Medicines;;https://www.firstwordpharma.com/story/5766229/"
  },
  {
    'Date Announced': '7-Aug-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'BioNTech',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Duality Biologics ',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global (excluding China)',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'DB-1305',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'Trop2',
    'Deal Structure':
      'DualityBio will receive upfront payments, additional development, regulatory and commercial milestone payments, and single-digit to low-double digit tiered royalties on net sales of DB-1305. BioNTech will hold commercial rights globally (excluding Mainland China, Hong Kong Special Administrative Region and Macau Special Administrative Region), while DualityBio will retain commercial rights for Mainland China, Hong Kong Special Administrative Region and Macau Special Administrative Region.',
    'FW Link1':
      'DualityBio Expands Global Strategic Partnership with BioNTech to Accelerate Development of a Third Antibody-Drug Conjugate Therapeutics for Solid Tumors;;https://firstwordpharma.com/story/5768071/'
  },
  {
    'Date Announced': '31-Jul-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Replimune',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Incyte ',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Oncolytic immunotherapy',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'RP1, INCB99280',
    'Indication(s)': 'Cutaneous squamous cell carcinoma',
    'Target(s)': 'PD-L1',
    'Deal Structure':
      'Under the terms of the agreement, Incyte will initiate and sponsor the clinical trial of INCB99280 and RP1 in patients with high risk, resectable cutaneous squamous cell carcinoma (CSCC), with the clinical trial expected to initiate in early 2024. Replimune will supply Incyte with RP1 for the study and share equally in the costs of the study.',
    'FW Link1':
      'Replimune and Incyte Enter into Clinical Trial Collaboration and Supply Agreement to Evaluate RP1 and INCB99280 in Patients with Cutaneous Squamous Cell Carcinoma;;https://firstwordpharma.com/story/5765955/\n'
  },
  {
    'Date Announced': '1-Aug-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Renaissance Pharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'St. Jude Children’s Research Hospital ',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'Hu14.18',
    'Indication(s)': 'Neuroblastoma',
    'Target(s)': 'GD2',
    'Deal Structure':
      'Under the terms of the licensing agreement, Renaissance Pharma has secured exclusive development, manufacturing and commercialisation rights to US, Canada, Europe, China, Japan and Turkey.',
    'FW Link1':
      'Renaissance Pharma launches today and announces the in-licensing of Hu14.18K322A (Hu14.18) from St. Jude Children’s Research Hospital for the treatment of High-Risk Neuroblastoma;;https://firstwordpharma.com/story/5766217/'
  },
  {
    'Date Announced': '9-Aug-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Sino Biopharmaceutical ',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Inventisbio',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'D-1553',
    'Indication(s)': 'Cancer',
    'Target(s)': 'KRAS G12C',
    'Deal Structure':
      "Sino Biopharmaceutical's Chia-Tai Tianqing Pharmaceutical subsidiary entered into an exclusive license and cooperation agreement with Inventisbio, pursuant to which Chia-Tai Tianqing was granted an exclusive license by Inventisbio to develop, register, manufacture and commercialize D-1553 in Mainland China. In addition, based on potential future cooperation in data sharing, Chia-Tai Tianqing will be granted a certain percentage of rights outside Mainland China in due course.",
    'FW Link1':
      'Voluntary Announcement Entering Into Exclusive License And Cooperation Agreement With Inventisbio;;https://firstwordpharma.com/story/5768995/'
  },
  {
    'Date Announced': '31-Jul-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'FivepHusion',
    'Country (Acquiring Company/Partner)': 'Australia',
    'Region (Acquiring Company/Partner)': 'Oceania',
    'Target Company/Partner': 'Allarity Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Chemotherapy, companion diagnostics',
    'Lead Asset(s)': 'Deflexifol, DRP-5FU',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      'Under this agreement, Allarity will support FivepHusion’s future clinical development of Deflexifol for the treatment of solid tumors by using certain of Allarity’s drug-specific DRP companion diagnostics, including its validated DRP-5FU companion diagnostic, to potentially select patients for enrollment and treatment in clinical trials of Deflexifol as a monotherapy and in combination with other drugs. Financial terms of the clinical collaboration, option and potential license are not disclosed. If FivepHusion exercises its option right and receives a license to the Allarity DRP companion diagnostic technology related to Deflexifol, Alliarty will receive certain milestone payments triggered by regulatory approvals of Deflexifol and attainment of drug sales benchmarks.',
    'FW Link1':
      'Allarity Therapeutics and FivepHusion Announce Collaboration to Support Clinical Development of Deflexifol with DRP Companion Diagnostics;;https://firstwordpharma.com/story/5765831/'
  },
  {
    'Date Announced': '10-Aug-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Biocartis',
    'Country (Acquiring Company/Partner)': 'Belgium',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Lilly',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Biomarkers, molecular diagnostics',
    'Indication(s)': 'Non-small-cell lung cancer',
    'Deal Structure':
      'Biocartis Group NV (the ‘Company’ or ‘Biocartis’), a global innovative molecular diagnostics company (Euronext Brussels: BCART) announced that they have entered into a new post-commercial collaboration program with Lilly, a global pharmaceutical company. This program will explore the advantages of adding the Biocartis IdyllaTM Platform to a molecular diagnostics workflow in global clinical labs via a study in hospitals across Spain. The focus will be to understand how the Idylla™ Platform can contribute to a more expeditious and comprehensive approach to identify targetable alterations when compared to various current clinical workflows for NSCLC patients.',
    'FW Link1':
      'Biocartis Partners with Lilly to Explore Biomarker Testing for NSCLC Patients using the Idylla Platform;;https://www.firstwordhealthtech.com/story/5769647/'
  },
  {
    'Date Announced': '10-Aug-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Hikma',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Rakuten Medical',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'Middle East and North Africa',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Photoimmunotherapy',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Under the terms of the agreement, Hikma has an exclusive license to commercialize products in Rakuten Medical's pipeline using its photoimmunotherapy technology platform, Alluminox™, in all its MENA markets.",
    'FW Link1':
      'Rakuten Medical and Hikma Sign Exclusive Licensing Agreement for Alluminox Platform Cancer Treatment in the Middle East and North Africa;;https://firstwordpharma.com/story/5769561/'
  },
  {
    'Date Announced': '10-Aug-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'XNK Therapeutics',
    'Country (Acquiring Company/Partner)': 'Sweden',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Undisclosed',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'NK cell therapy',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'XNK02',
    'Indication(s)': 'Acute myeloid leukaemia',
    'Deal Structure':
      'XNK Therapeutics AB ("XNK") announced that the company has entered into an agreement with a US biotechnology company. Under the agreement the parties will perform preclinical evaluation of XNK\'s autologous NK cell therapy candidate XNK02 in combination with a drug candidate currently in clinical development by the US company. The combination will initially be tested against acute myeloid leukemia (AML).',
    'FW Link1':
      'XNK Therapeutics enters into agreement with US-based biotechnology company;;https://firstwordpharma.com/story/5769416/'
  },
  {
    'Date Announced': '15-Aug-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Gilead',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Tentarix Biotherapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Total Deal Value ($m)': '306',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Protein therapeutics',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Gilead Sciences, Inc. (Nasdaq:GILD) and Tentarix Biotherapeutics announced that the companies established three multi-year collaborations leveraging Tentarix’s proprietary Tentacles™ platform to discover and develop multi-functional, conditional protein therapeutics for oncology and inflammatory diseases. Across the three collaborations, Tentarix will receive upfront payments and an equity investment from Gilead totaling $66 million. In addition, Gilead has the option to acquire up to three select Tentarix subsidiaries containing the programs developed under the collaborations for $80 million per subsidiary.',
    'FW Link1':
      'Gilead teams up with Tentarix on protein therapeutics;;https://firstwordpharma.com/story/5770903/'
  },
  {
    'Date Announced': '9-Aug-2023',
    'Deal type': 'Terminated',
    'Acquiring Company/Partner': 'Incyte',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Syros Pharmaceuticals',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '10',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Gene control',
    'Indication(s)': 'Myeloproliferative neoplasms',
    'Deal Structure':
      'Incyte informed Syros Pharmaceuticals that it plans to terminate an agreement between the companies to develop therapies for myeloproliferative neoplasms. Syros disclosed in a recent regulatory filing that it received notice from Incyte on August 9 of its intention to end the deal "for convenience." The 2018 agreement called for Syros to use its gene-control platform to identify novel therapeutic targets with a focus on myeloproliferative neoplasms, with Incyte receiving options to license drugs against up to seven validated targets. Under the deal, Incyte paid $10 million upfront, as well as purchasing $10 million in Syros stock. Syros was also eligible for up to $54 million in target selection and option exercise fees along with over $100 million in milestones per target, plus sales royalties.',
    'FW Link1':
      'Incyte bins blood cancer deal with Syros;;https://firstwordpharma.com/story/5770365/'
  },
  {
    'Date Announced': '16-Aug-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Day One Biopharmaceuticals',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Sprint Bioscience',
    'Country (Target Company/Partner)': 'Sweden',
    'Region (Target Company/Partner)': 'Europe',
    'Upfront Value ($m)': '3',
    'Total Deal Value ($m)': '316',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Cancer',
    'Target(s)': 'VRK1',
    'Deal Structure':
      "Day One Biopharmaceuticals said that it reached an agreement giving it an exclusive global license to develop small-molecule candidates stemming from Sprint Bioscience's VRK1 programme for cancers with high unmet need. Under the agreed terms, Day One will make an upfront payment of $3 million to Sprint and also reimburse the company for preclinical R&D expenses. In addition, Sprint will be eligible for milestones of up to about $313 million plus single-digit royalties pending achievement of certain R&D, regulatory and commercial outcomes.",
    'FW Link1':
      'Day One inks licensing pact with Sprint Bio for cancer drugs targeting VRK1;;https://firstwordpharma.com/story/5771308/'
  },
  {
    'Date Announced': '10-Aug-2023',
    'Deal type': 'Product Acquisition',
    'Acquiring Company/Partner': 'CNX Therapeutics',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Clinigen',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cancer support therapies, small molecules',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Cardioxane, Savene, Totect, Ethyol',
    'Indication(s)':
      'Cancer supportive care, breast cancer, ovarian cancer, head and neck cancer',
    'Deal Structure':
      'Clinigen Limited (‘Clinigen’), the global pharmaceutical services company, has completed the divestment of four cancer supportive care products from its established brands portfolio to CNX Therapeutics, a speciality pharmaceutical company with a focus on improving access to medicines that are critical to the well-being of patients. Under the terms of the agreement, (the financial terms for which are not being disclosed), Clinigen will divest four medicines; Cardioxane®, Savene®, Totect® and Ethyol®, all of which help mitigate the side effects patients may experience when treated with other cancer therapies.',
    'FW Link1':
      'Clinigen divests global rights to four cancer support therapies to CNX Therapeutics;;https://firstwordpharma.com/story/5769797/'
  },
  {
    'Date Announced': '1-Aug-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'CASI Pharmaceuticals',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Mundipharma, Acrotech',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules, chemotherapy',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Folotyn',
    'Indication(s)': 'Peripheral T-cell lymphoma',
    'Target(s)': 'Dihydrofolate reductase',
    'Deal Structure':
      'CASI Pharmaceuticals, Inc. (Nasdaq: CASI), a biopharmaceutical company focused on developing and commercializing innovative therapeutics and pharmaceutical products, announced the execution of an Assignment Agreement (the "Agreement") with Mundipharma International Corporation Limited ("MICL"), Mundipharma Medical Company ("MMCo"), and Acrotech Biopharma Inc. ("Acrotech") for the commercialization of FOLOTYN® (Pralatrexate) in the People\'s Republic of China ("China"). Under the terms of the Agreement, CASI will assume MICL\'s rights and obligations with respect to the commercialization of FOLOTYN® in China.',
    'FW Link1':
      'Mundipharma International Corporation Limited, Mundipharma Medical Company, and Acrotech Biopharma Inc. Enter Agreement to Transfer License of FOLOTYN (Pralatrexate) in China to CASI Pharmaceuticals;;https://firstwordpharma.com/story/5766649/'
  },
  {
    'Date Announced': '8-Aug-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Edison Oncology',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Cancer Help Desk',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules, chemotherapy',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'Orotecan',
    'Indication(s)': 'Paediatric recurrent solid tumours',
    'Deal Structure':
      'Cancer Help Desk, Inc., a 501(c)(3) nonprofit that combines compassion, expert knowledge, and evidence-based science to offer personalized education, resources, and treatment choices to people dealing with cancer, is pleased to announce it has partnered with Edison Oncology Holding Corp., a company established to develop and commercialize novel therapies targeting the fight against cancer, to conduct a pilot project. Through the pilot, Cancer Help Desk will provide education and access to clinical trial information for caregivers and patients (aged 1-30) with pediatric recurrent solid tumors currently being treated with intravenous irinotecan, who may be eligible for the clinical trial of Orotecan®, Edison’s novel oral formulation of irinotecan.',
    'FW Link1':
      'Cancer Help Desk Partners with Edison Oncology for Pilot Project Serving Patients with Pediatric Recurrent Solid Tumors;;https://firstwordpharma.com/story/5768563/'
  },
  {
    'Date Announced': '9-Aug-2023',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Renovaro Biosciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'GEDi Cube',
    'Country (Target Company/Partner)': 'Netherlands',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Artificial intelligence, diagnostics, cell and gene therapies, immunotherapy',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Renovaro BioSciences Inc. (NASDAQ:RENB) (formerly NASDAQ: ENOB), an advanced, pre-clinical biotechnology firm in cell, gene and immunotherapy focused on solid tumors with short life expectancy, has executed a binding, exclusive letter of intent to merge a subsidiary with the cutting-edge health AI company GEDi Cube Intl Ltd. The combined company would aim to accelerate diagnosis, enhance treatment effectiveness, discover new therapies, and expand access to life-saving technologies for cancer and other diseases.',
    'FW Link1':
      'AI Company GEDi Cube and Renovaro Biosciences Announce a Binding, Exclusive Letter of Intent to Merge, Accelerating Fight Against Cancer;;https://firstwordpharma.com/story/5769084/',
    'FW Link2':
      'Definitive agreement to combine announced September 29 2023;;https://firstwordpharma.com/story/5785494/'
  },
  {
    'Date Announced': '21-Aug-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Moderna',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'CARsgen Therapeutics',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cancer vaccines, CAR-T therapies',
    'Lead Asset(s)': 'CT041, mRNA cancer vaccine',
    'Indication(s)': 'Cancer',
    'Target(s)': 'Claudin18.2',
    'Deal Structure':
      'Moderna has entered a partnership that seeks to pair its mRNA cancer vaccine encoding for the Claudin18.2 protein with CARsgen Therapeutics\' autologous Claudin18.2 CAR T-cell candidate CT041 (satricabtagene autoleucel). According to the companies, the collaboration "contemplates conducting preclinical studies and a Phase I clinical trial" to evaluate CT041 with Moderna\'s off-the-shelf mRNA cancer jab. Financial details of the arrangement announced Monday were not disclosed, but the companies noted that CT041 is currently being investigated as a potential treatment for gastric, pancreatic and other cancers of the digestive system.',
    'FW Link1':
      'Moderna turns to CARsgen to develop mRNA cancer vaccine, CAR-T combo;;https://firstwordpharma.com/story/5772525/'
  },
  {
    'Date Announced': '10-Aug-2023',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'Hansoh Pharmaceutical',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Antengene',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '28',
    'Total Deal Value ($m)': '102',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Xpovio',
    'Indication(s)': 'Multiple myeloma',
    'Target(s)': 'XPO1',
    'Deal Structure':
      'Under the terms of the agreement, Antengene will continue to be responsible for research and development, regulatory approvals and affairs, product supply, and distribution of XPOVIO®, while Hansoh Pharma will be exclusively responsible for commercialization of XPOVIO® in the mainland of China. Antengene will receive up to RMB200 million of upfront payments, RMB100 million of which shall be received upon signing, and pursuant to the Agreement and subject to the terms and conditions thereof, Antengene shall be eligible to receive up to RMB100 million of the remaining upfront payments, and up to RMB535 million in milestone payments from Hansoh Pharma. Antengene will continue to record revenues from sales of XPOVIO® in the mainland of China and Hansoh Pharma will charge a service fee to Antengene.',
    'FW Link1':
      'Antengene Enters into Commercialization Partnership with Hansoh Pharma for First/Only-in-Class XPO1 Inhibitor XPOVIO (selinexor) in the Mainland of China;;https://firstwordpharma.com/story/5769935/'
  },
  {
    'Date Announced': '22-Aug-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Simcere',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Mabpharm',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset(s)': 'CMAB009',
    'Indication(s)': 'Colorectal cancer',
    'Target(s)': 'EGFR',
    'Deal Structure':
      'According to the agreement, Simcere Zaiming will obtain the exclusive commercial rights to MabPharm’s anti-EGFR monoclonal antibody CMAB009 in China.',
    'FW Link1':
      'Simcere Zaiming Partners with MabPharm on anti-EGFR Monoclonal Antibody CMAB009 in China;;https://firstwordpharma.com/story/5774263/'
  },
  {
    'Date Announced': '28-Aug-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Verastem',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'GenFleet Therapeutics',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Total Deal Value ($m)': '626',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'RAS pathway-driven cancers',
    'Target(s)': 'RAS',
    'Deal Structure':
      "Verastem Oncology has entered into a collaboration to advance oncology discovery programmes targeting RAS pathway-driven cancers based on GenFleet Therapeutics' discovery platform. The deal's risk-sharing structure will give Verastem the flexibility of a milestone-based option to license up to three compounds. The terms of the agreement include combined upfront, research support and option payments to GenFleet of $11.5 million for the first programme, with a potential total deal size across all three programmes up to $625.5 million, excluding royalties if Verastem exercises its in-license options. In return, Verastem obtains exclusive rights for a license to each of the compounds after successful completion of pre-determined milestones in Phase I trials. The licenses would give Verastem development and commercialisation rights outside of China while GenFleet would retain those rights within China.",
    'FW Link1':
      'Verastem teams up with GenFleet on drugs for RAS-driven cancers;;https://firstwordpharma.com/story/5774982/'
  },
  {
    'Date Announced': '7-Aug-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Cardiff Oncology',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Pfizer',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'onvansertib',
    'Indication(s)': 'Colorectal cancer',
    'Target(s)': 'PLK1',
    'Deal Structure':
      "The clinical activities of the company's new CRDF-004 trial in first-line RAS-mutated mCRC will be conducted with support from Pfizer Ignite. This expands the relationship established in November 2021 when Pfizer made an equity investment in Cardiff Oncology and nominated Adam Schayowitz, Ph.D., Vice President & Medicine Team Group Lead for Breast Cancer, Colorectal Cancer and Melanoma, Pfizer Global Product Development as a Scientific Advisory Board member. Cardiff Oncology will maintain full economic ownership and control of onvansertib.",
    'FW Link1':
      'Cardiff Oncology Announces New Lead Program in First-Line Metastatic Colorectal Cancer and Expanded Pfizer Relationship;;https://firstwordpharma.com/story/5768226/'
  },
  {
    'Date Announced': '22-Aug-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Celcuity',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Bayer',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'gedatolisib, Nubeqa',
    'Indication(s)': 'Prostate cancer',
    'Target(s)': 'PI3K, mTOR, androgen receptor',
    'Deal Structure':
      'Celcuity Inc. (Nasdaq: CELC), a clinical-stage biotechnology company pursuing development of targeted therapies for oncology, announced that it has entered into a clinical trial collaboration and supply agreement with Bayer AG for Celcuity’s Phase 1b/2 clinical trial of gedatolisib and Nubeqa® (darolutamide) in patients with metastatic castration resistant prostate cancer (mCRPC). As part of the supply agreement, Bayer will provide Nubeqa to Celcuity at no cost.',
    'FW Link1':
      'Celcuity Enters into a Clinical Trial Collaboration and Supply Agreement with Bayer to Provide Nubeqa (darolutamide) for Planned Phase 1b/2 Clinical Trial;;https://firstwordpharma.com/story/5773308/'
  },
  {
    'Date Announced': '10-Aug-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Boostimmune',
    'Country (Acquiring Company/Partner)': 'Korea, South',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'WuXi PharmaTech',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibodies, antibody-based therapies',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Under the collaboration, Boostimmune will have access to WuXi Biologics' integrated discovery services and extensive expertise in antibody discovery, lead optimization, in vitro screening and characterization, as well as in vivo tests. WuXi Biologics will be the exclusive service partner to support Boostimmune in generating different modality candidates, including monoclonal antibodies, bispecific antibodies and recombinant proteins.",
    'FW Link1':
      'WuXi Biologics and Boostimmune Sign MOU for Exclusive Research and Discovery Services;;https://firstwordpharma.com/story/5769936/'
  },
  {
    'Date Announced': '14-Aug-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'DRI Healthcare Trust',
    'Country (Acquiring Company/Partner)': 'Canada',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Radius Health',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '130',
    'Total Deal Value ($m)': '140',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Orserdu',
    'Indication(s)': 'Breast cancer',
    'Target(s)': 'Oestrogen receptor',
    'Deal Structure':
      'Radius Pharmaceuticals, Inc., a wholly owned subsidiary of Radius Health, Inc. (“Radius” or the “Company”), a specialty biopharmaceutical company focused on bone health, announced today that it has entered into a Royalty Purchase Agreement for a portion of Radius’s royalty and commercial milestone stream in elacestrant, the first and only treatment approved for ER+/HER2-, ESR1- mutated advanced or metastatic breast cancer, with DRI Healthcare Acquisitions LP (“DRI Healthcare”). Under the terms of the Royalty Purchase Agreement, Radius will receive an upfront payment of $130 million, is eligible to receive an additional $10 million from DRI upon the occurrence of certain events, and retains over $200 million in potential commercial milestones payable from the Menarini Group. DRI Healthcare will receive a portion of the elacestrant royalty stream and rights to some of the initial commercial milestones from the Menarini Group, who entered into a global licensing agreement with Radius for the development and commercialization rights to elacestrant in July 2020. As part of the agreement with Menarini Group, Radius receives development and commercial milestone payments and a tiered royalty on global net sales.',
    'FW Link1':
      'Radius Announces Royalty Purchase Agreement With DRI Healthcare to Sell a Portion of Its Royalty and Commercial Milestone Interest in elacestrant for up to $140 Million;;https://firstwordpharma.com/story/5770460/'
  },
  {
    'Date Announced': '15-Aug-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'CytoMed Therapeutics',
    'Country (Acquiring Company/Partner)': 'Singapore',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Hangzhou CNK Therapeutics',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'CAR-T therapies',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'CytoMed Therapeutics Limited (NASDAQ: GDTC) ("CytoMed" or "Company"), a Singapore-based biopharmaceutical company focused on harnessing its proprietary technologies to develop novel donor-derived cell-based immunotherapies for the treatment of various cancers, announces that it signed a Memorandum of Understanding (MOU) with Hangzhou CNK Therapeutics Co., Ltd (CNK). With this agreement, CytoMed can utilize CNK\'s PiggyBac technology to permanently graft the Chimeric Antigen Receptor (CAR) gene into its gamma delta (γδ) T cells via a non-viral gene editing method. The MOU has a duration of one year, with the option to renew, and is subject to a definitive agreement and customary closing conditions. There remains a potential opportunity for a joint venture to conduct clinical trials in China.',
    'FW Link1':
      'CytoMed Therapeutics Announces China Research Collaboration to Enhance its Allogeneic CAR γδ T In Vivo Persistency;;https://firstwordpharma.com/story/5771117/'
  },
  {
    'Date Announced': '1-Sep-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'J&J',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Intelligent OMICS',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial intelligence',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Haematological cancers',
    'Deal Structure':
      'Intelligent OMICS Ltd (Intellomx), a leading biotechnology company that applies artificial intelligence (AI) to accelerate drug discovery, announced a target discovery collaboration with Janssen Research & Development, LLC (Janssen), one of the Janssen Pharmaceutical Companies of Johnson & Johnson, to evaluate novel biological targets for the treatment of haematological cancers. The agreement was facilitated by Johnson & Johnson Innovation. Under the terms of the agreement, Intelligent OMICS will receive an upfront payment and research support from Janssen, as well as potential milestone payments based on the successful development of products resulting from the collaboration.',
    'FW Link1':
      'Intelligent OMICS Ltd announce collaboration to leverage AI for novel target discovery in haematological cancers;;https://firstwordpharma.com/story/5776970/'
  },
  {
    'Date Announced': '31-Aug-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Merck KGaA',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Physiomics',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Mathematical modelling, DNA damage/repair agents',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Physiomics plc (AIM: PYC), a leading mathematical modelling company, supporting development of oncology drugs and personalised medicine solutions, is pleased to announce that it has been awarded a further contract by existing client Merck KGaA that it expects to be completed over the next three months.  The project will involve pre-clinical and clinical modelling and simulation of anti-cancer regimens involving combinations of DNA damage/ repair agents and follows on from previous projects commissioned by Merck KGaA.',
    'FW Link1':
      'Physiomics - Merck contract award;;https://firstwordpharma.com/story/5776559/'
  },
  {
    'Date Announced': '15-Aug-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'TAE Life Sciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Biddle Sawyer, Kinentia Biosciences',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'United States',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Boron neutron capture therapy',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'TC220',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "TAE Life Sciences has announced a landmark agreement with Biddle Sawyer to serve as the exclusive supplier of TC220, a novel boronated amino acid analog drug for boron neutron capture therapy (BNCT). As part of this exclusive agreement, Biddle Sawyer has enlisted contract research and manufacturing organization Kinentia Biosciences to refine and streamline the drug's manufacturing process, and ultimately produce the drug in their state-of-the-art GMP facility. Kinentia Biosciences will be the exclusive manufacturer of TC220 for the US market.",
    'FW Link1':
      'TAE Life Sciences Signs Biddle Sawyer to Manufacture Boronated TC220 Drug for BNCT Cancer Treatment;;https://firstwordpharma.com/story/5771115/'
  },
  {
    'Date Announced': '15-Aug-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Imugene',
    'Country (Acquiring Company/Partner)': 'Australia',
    'Region (Acquiring Company/Partner)': 'Oceania',
    'Target Company/Partner': 'Precision BioSciences',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '21',
    'Total Deal Value ($m)': '227',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'CAR-T therapies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'Azercabtagene Zapreleucel',
    'Indication(s)': 'Cancer, large B-cell lymphoma',
    'Deal Structure':
      'Precision BioSciences, Inc. (Nasdaq: DTIL), a clinical stage gene editing company developing ARCUS®-based in vivo gene editing therapies, announced completion of a strategic transaction with Imugene Limited (ASX: IMU) for global rights to Azercabtagene Zapreleucel (azer-cel), Precision’s lead allogeneic CAR T candidate, for cancer. Imugene will assume ongoing clinical execution for azer-cel in the large B-cell lymphoma (LBCL) population who have relapsed following autologous CAR T treatment. The license also includes an option to develop up to three other cancer research programs in the future. In exchange for global rights to azer-cel for cancer, as well as Precision BioSciences’ CAR T infrastructure and its experienced cell therapy teams, Precision will receive upfront economics valued at $21 million (all figures in USD) consisting of cash and equity. In addition, Precision is eligible for an $8 million near-term payment in cash and equity upon successful completion of the phase 1b dosing in the CAR T relapsed LBCL patient population. For azer-cel, Precision is eligible to receive up to $198 million in additional milestone payments and double-digit royalties on net sales. For each additional research program selected by Imugene, Precision is eligible for up to $145 million in milestone payments and tiered royalties on net sales. ',
    'FW Link1':
      'Precision BioSciences Completes Strategic Transaction with Imugene for Azer-Cel in Cancer;;https://firstwordpharma.com/story/5771216/'
  },
  {
    'Date Announced': '7-Sep-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Seagen',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Nurix Therapeutics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '60',
    'Total Deal Value ($m)': '3,460',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Degrader-antibody conjugates, antibody-drug conjugation, targeted protein degradation',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Nurix Therapeutics announced Thursday that it struck a multi-year partnership with Seagen to advance a portfolio of medicines called degrader-antibody conjugates (DACs) to treat cancer. As part of the deal, Nurix will receive a $60-million upfront payment from Seagen and is eligible for up to $3.4 billion in potential milestones across multiple programmes, along with mid-single- to low-double-digit tiered royalties on product sales. Nurix also retains an option for US profit sharing and co-promotion on two products stemming from the collaboration.',
    'FW Link1':
      'Seagen teaming up with Nurix on degrader-antibody conjugates;;https://firstwordpharma.com/story/5778659/'
  },
  {
    'Date Announced': '17-Aug-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Qualigen Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Translational Drug Development',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'QN-302',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'G-quadruplex (G4)',
    'Deal Structure':
      'Qualigen Therapeutics, Inc. (Nasdaq: QLGN), a clinical-stage therapeutics company focused on developing treatments for adult and pediatric cancers with potential for Orphan Drug Designation, announced it is partnering with Translational Drug Development (TD2) as the contract research organization (CRO) to conduct the Phase 1 clinical development of lead drug candidate QN-302. Qualigen received US FDA IND clearance earlier this month to initiate a Phase 1 clinical trial of QN-302, a small molecule G-Quadruplex (G4)-selective transcription inhibitor, for the treatment of advanced or metastatic solid tumors.',
    'FW Link1':
      'Qualigen Therapeutics Partners with TD2 for Phase 1 Clinical Development of QN-302 for the Treatment of Advanced or Metastatic Solid Tumors;;https://firstwordpharma.com/story/5771835/'
  },
  {
    'Date Announced': '31-Aug-2023',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'HiRO',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Courante Oncology',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'United States',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Clinical research provider',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Harvest Integrated Research Organization (HiRO), an innovation global CRO, is pleased to announce the successful acquisition of Courante Oncology, a US-based full-service clinical research provider specializing in oncology product development. As a reputable boutique CRO with a strong track record of delivering high-quality, expedited clinical trials in the Asia-Pacific, HiRO's acquisition of Courante Oncology marks a significant milestone in its global expansion. This strategic move further strengthens HiRO's capabilities in managing global studies, extending its presence into the US market.",
    'FW Link1':
      'HiRO acquires US CRO Courante Oncology, strengthens global footprint and operation capabilities;;https://firstwordpharma.com/story/5776749/'
  },
  {
    'Date Announced': '21-Aug-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Vidac Pharma',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Sheba Medical Center',
    'Country (Target Company/Partner)': 'Israel',
    'Region (Target Company/Partner)': 'Middle East',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'CAR-T therapies',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Vidac Pharma announces a Research Collaboration and License Agreement with Sheba Medical Center on modification of Tumor microenvironment (TME) induced by its New Chemical Entities in order to facilitate Immunological treatment of Tumors (CAR-T treatment). One of the problems encountered in these treatments is the Lactate producing Hyper Glycolysis at the TME an unfavorable environment for CAR-T treatment. The new Drug candidates of Vidac Pharma reverse the Warburg effect i.e. the Hyper Glycolysis causing Lactate accumulation. The group of Dr. Elad Jacoby, Head of Pediatric Cell Therapy, at Sheba's Safra Children's Hospital will collaborate with the Vidac Pharma R&D group directed by Dr. Yuval Sagiv to study possible synergy between their approaches.",
    'FW Link1':
      'Vidac Pharma announces signature of a Research Collaboration and License Agreement with Sheba Hospital in Israel;;https://firstwordpharma.com/story/5772521/'
  },
  {
    'Date Announced': '21-Aug-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'AstraZeneca',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'PicnicHealth',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'United States',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Real-world data',
    'Indication(s)': 'Breast cancer',
    'Deal Structure':
      'PicnicHealth also announced a multi-year partnership with AstraZeneca to advance real-world data development for early breast cancer (stages I-III). PicnicHealth will build an innovative registry to generate longitudinal real-world data from a cohort of consenting U.S. patients diagnosed with stage I-III breast cancers. The registry, which is open for enrollment, will empower participants by organizing and centralizing their complete medical record into an easy-to-use portal, while also enabling patients to contribute their de-identified data to advance breast cancer research.',
    'FW Link1':
      'PicnicHealth Announces Entry into Market for Oncology-Focused Real-World Data;;https://www.firstwordhealthtech.com/story/5772625/'
  },
  {
    'Date Announced': '5-Sep-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Parthenon Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'XtalPi',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial intelligence, antibodies',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      "XtalPi, a global leader in AI-powered drug discovery, and Parthenon Therapeutics, a clinical-stage biotechnology company dedicated to reprogramming the tumor microenvironment, announced their collaboration today to discover novel therapeutic antibodies for the treatment of solid tumors. Under this agreement, XtalPi will grant Parthenon Therapeutics access to its proprietary XupremAb™ antibody discovery platform, which is a collection of solutions that leverage AI and wet lab techniques to deliver therapeutic antibody candidates with superior efficacy and developability profiles. Parthenon Therapeutics and XtalPi will utilize multiple approaches in the XupremAb™ platform for deeper interrogation of the immune repertoire and generating a large, consolidated dataset, which will be further mined by XtalPi's proprietary algorithms for exceptional candidates.",
    'FW Link1':
      'XtalPi and Parthenon Enter Into AI-Powered Antibody Discovery Partnership;;https://firstwordpharma.com/story/5777801/'
  },
  {
    'Date Announced': '5-Sep-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Portage Biotech',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Merck & Co.',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules, monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'PORT-6, PORT-7, Keytruda',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'A2A, A2B, PD-1',
    'Deal Structure':
      'Portage Biotech Inc. (NASDAQ: PRTG), a clinical-stage immuno-oncology company advancing novel multi-targeted therapies for use as monotherapy and in combination, announced that it has entered into a clinical trial collaboration agreement with Merck (known as MSD outside the US and Canada) to evaluate Portage’s next-generation adenosine antagonists in combination with KEYTRUDA® (pembrolizumab), Merck’s anti-PD-1 (programmed death receptor-1) therapy, for patients with solid tumors. The collaboration will explore Portage’s adenosine 2A receptor (A2AR) antagonist, PORT-6, its adenosine 2B receptor (A2BR) antagonist, PORT-7, individually and together in combination with KEYTRUDA in prostate, renal, head and neck, colorectal, endometrial, ovarian and non-small cell lung cancers. Under the terms of the agreement, Merck will provide KEYTRUDA for Portage Biotech’s ADPORT-601, an adaptive Phase 1a/1b trial which plans to integrate proprietary biomarkers for selecting patients with high adenosine expression in order to identify those more likely to respond and have potential to benefit most from treatment.',
    'FW Link1':
      'Portage Biotech Announces Collaboration with Merck to Evaluate Two Next-Generation Adenosine Antagonists in Combination with KEYTRUDA (Pembrolizumab) in Solid Tumors;;https://firstwordpharma.com/story/5777785/'
  },
  {
    'Date Announced': '6-Sep-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'OncoNano Medicine',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Regeneron',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules, monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'ONM-501, Libtayo',
    'Indication(s)': 'Solid tumours, lymphomas',
    'Target(s)': 'STING, PD-1',
    'Deal Structure':
      'OncoNano Medicine, Inc. announced a clinical trial supply agreement with Regeneron for the use of Libtayo® (cemiplimab), a PD-1 inhibitor, in the combination stage of the first human trial of ONM-501, a dual-activating STING (STimulator of INterferon Genes) agonist and lead therapeutic development candidate. The ONM-501 first-in-human trial (NCT06022029) is a multicenter Phase 1a/b dose escalation and dose expansion study of intratumoral ONM-501 as monotherapy and in combination with Libtayo in patients with advanced solid tumors and lymphomas. OncoNano is the sponsor of the clinical trial, and Regeneron will supply cemiplimab.',
    'FW Link1':
      'OncoNano and Regeneron Enter into Clinical Trial Supply Agreement for Use of Libtayo (cemiplimab) in ONM-501 Study;;https://firstwordpharma.com/story/5778206/'
  },
  {
    'Date Announced': '6-Sep-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'KSQ Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'CTMC',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapies',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'KSQ-001EX, KSQ-004EX',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'SOCS1, Regnase-1',
    'Deal Structure':
      'KSQ Therapeutics, Inc. ("KSQ"), a clinical-stage biotechnology company developing cancer therapies using its proprietary CRISPRomics® discovery platform, and CTMC, a joint venture between National Resilience, Inc. and MD Anderson Cancer Center, announced a strategic collaboration to expedite the development of KSQ\'s two lead engineered tumor-infiltrating lymphocyte (eTIL®) programs, KSQ-001EX and KSQ-004EX, both of which are advancing toward clinical studies for the treatment of solid tumors.',
    'FW Link1':
      'KSQ Therapeutics and CTMC Announce Strategic Collaboration to Accelerate the Development of Novel Engineered Tumor Infiltrating Lymphocyte (eTIL) Therapies for the Treatment of Solid Tumors;;https://firstwordpharma.com/story/5778425/'
  },
  {
    'Date Announced': '6-Sep-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Dizal Pharmaceutical',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Anova',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'DZD9008',
    'Indication(s)': 'Non-small-cell lung cancer',
    'Target(s)': 'EGFR',
    'Deal Structure':
      'Anova Enterprises, Inc. (Anova), an organization dedicated to accelerating promising treatments to market with its transformative AnovaOS™ technology platform, and the industry’s first collaborative ‘learning system’, announced a partnership with Dizal Pharmaceuticals (Dizal) to accelerate the development of DZD9008 for patients with EGFR Exon20ins mutant NSCLC.',
    'FW Link1':
      'Anova Partners with Dizal (Jiangsu) Pharmaceuticals to Accelerate Development of DZD9008 for Patients with Advanced or Metastatic NSCLC with EGFR Exon20 insertion Mutation;;https://firstwordpharma.com/story/5778419/'
  },
  {
    'Date Announced': '7-Sep-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Cell BioEngines',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Icahn School of Medicine at Mount Sinai',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapies, stem cells',
    'Lead Asset Stage/Phase': 'Phase I',
    'Indication(s)': 'Blood and bone marrow cancer',
    'Deal Structure':
      'Cell BioEngines, Inc. is strengthening its Intellectual Property portfolio with a patent licensed from the Icahn School of Medicine at Mount Sinai, in New York, NY, for the use of allogenic hematopoietic stem cell expansion and transplantation technology in blood and bone marrow cancer. The large-scale generation of expanded cord-blood stem cells as an allogeneic donor source for adults with hematological malignancies is based on technology developed by Mount Sinai faculty and licensed to Cell BioEngines. Mount Sinai and Mount Sinai faculty, including Dr. Hoffman and Patrima Chaurasia, PhD, Assistant Clinical Professor of Medicine (Hematology and Medical Oncology) at Icahn Mount Sinai, have a financial interest in Cell BioEngines pursuant to the Mount Sinai Intellectual Property Policy.',
    'FW Link1':
      'Cell BioEngines Licenses Patent for Allogeneic Hematopoietic Cell Transplantation in Oncology;;https://firstwordpharma.com/story/5778841/'
  },
  {
    'Date Announced': '7-Sep-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'b.e. Imaging',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Curium',
    'Country (Target Company/Partner)': 'France',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Switzerland',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics, imaging',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Pylclari',
    'Indication(s)': 'Prostate cancer',
    'Target(s)': 'PSMA',
    'Deal Structure':
      'Curium, a world leader in nuclear medicine, announced that it has entered into an exclusive rights agreement with b.e. Imaging, for the distribution of PYLCLARI™ (INN: Piflufolastat (18F) formerly known as (18F)-DCFPyL), indicated for the detection of prostate-specific membrane antigen (PSMA) positive lesions with positron emission tomography in adults with prostate cancer. Under the terms of the agreement, b.e. Imaging will be responsible for obtaining marketing authorization from Swiss competent authority Swissmedic, and will have exclusive distribution rights for PYLCLARI™ across Switzerland.',
    'FW Link1':
      'Curium Announces Exclusive Distribution Agreement in Switzerland with b.e. Imaging for PYLCLARI, an Innovative 18F-PSMA Pet Tracer Indicated in Adults with Prostate Cancer;;https://firstwordpharma.com/story/5778499/'
  },
  {
    'Date Announced': '11-Sep-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Moderna',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Immatics',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Upfront Value ($m)': '120',
    'Total Deal Value ($m)': '1,820',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'mRNA, bispecifics, cell therapies, cancer vaccines',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'IMA203',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Moderna agreed to pay $120 million upfront as part of a collaboration that will pair its expertise in mRNA technology alongside Immatics' T-cell receptor (TCR) platform to develop cancer therapeutics, the companies announced Monday. The agreement, potentially worth over $1.8 billion, will tackle various therapeutic modalities including bispecifics, cell therapy and cancer vaccines. Aside from the upfront payment by Moderna, Immatics is eligible for milestones that could exceed $1.7 billion, as well as tiered royalties on global net sales of TCER products and certain vaccine products that arise from the partnership. Immatics has an option to enter into a global profit and loss share arrangement for the most advanced TCER. Meanwhile, Moderna will lead clinical development and commercialisation efforts for cancer vaccines and TCER therapeutics resulting from the deal. Immatics will handle preclinical studies and a potential Phase I trial investigating IMA203 together with the PRAME mRNA vaccine to further enhance T-cell responses. Each company also retains full ownership of its investigational PRAME compound, and the parties will fund the clinical study on a cost-sharing basis.",
    'FW Link1':
      'Moderna commits up to $1.8 billion in cancer therapeutics deal with Immatics;;https://firstwordpharma.com/story/5779428/'
  },
  {
    'Date Announced': '8-Sep-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Plus Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Biocept',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics',
    'Lead Asset(s)': 'CNSide',
    'Indication(s)': 'Central nervous system cancers',
    'Deal Structure':
      "Plus Therapeutics, Inc. (Nasdaq: PSTV) (the “Company”), a clinical-stage pharmaceutical company developing targeted radiotherapeutics with advanced platform technologies for central nervous system cancers, announced it has expanded its supply agreement with Biocept by acquiring an option to exclusively license Biocept's CNSide, a CSF-based tumor cell capture and enumeration platform, for patients receiving CNS radiotherapy. The option ensures that Plus Therapeutics has ongoing expanded access to the diagnostic CSF assay for patients with CNS cancers and can further develop and improve the assay specifically for patients with CNS cancers receiving radiotherapy. Under the terms of the agreement, CNSide developer, Biocept, has granted Plus Therapeutics a non-exclusive license to its CNSide cell enumeration assay for Plus’ investigational therapy for leptomeningeal metastases (LM), rhenium (186Re) obisbemeda, and an option for an exclusive license to the assay. In exchange, Plus Therapeutics will provide $150,000 of its common stock to Biocept. Prior to January 1, 2025, Plus Therapeutics will have the option for exclusivity for the field of radiotherapy in exchange for a $1.0 million payment, to be exercised at Plus Therapeutics’ discretion.",
    'FW Link1':
      'Plus Therapeutics Enters into License Agreement for Cerebrospinal Fluid Tumor Cell Enumeration Assay;;https://www.firstwordhealthtech.com/story/5778984/'
  },
  {
    'Date Announced': '11-Sep-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Orano Med',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Orbit Discovery',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Radiopharmaceuticals',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Orano Med SAS, “Orano Med”, a biotechnology company developing Lead-212 (212Pb) Targeted Alpha Therapy against cancer, and Orbit Discovery Ltd, “Orbit”, a leader in the discovery of therapeutic peptide hits, announced that they have entered into a collaboration to discover specific Peptide Receptor Radionuclide Therapies against cancer cells and advance the development of novel radiopharmaceuticals. Under the terms of the agreement, Orbit will deploy its bead-based peptide display engine to discover peptide leads specific to targets related to specific tumours.',
    'FW Link1':
      'Orano Med and Orbit Discovery Enter Collaboration to Discover Novel Targeted Radioligand Therapies for Cancer;;https://firstwordpharma.com/story/5779566/'
  },
  {
    'Date Announced': '12-Sep-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Dewpoint',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Chemify',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Artificial intelligence, condensate-modifying drugs',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Target(s)': 'Condensates',
    'Deal Structure':
      "Dewpoint Therapeutics and Chemify announced a partnership to develop a suite of digitally discovered and automatically synthesized novel molecules on Chemify's programmable chemistry platform against condensate targets of interest in Dewpoint's oncology and neurodegeneration pipeline. Under the partnership, Chemify will design and deliver novel compounds to augment Dewpoint's advanced oncology and neurodegeneration pipeline. Dewpoint may choose to exercise an option to acquire the compounds designed by Chemify. In exchange, Chemify will receive pre-negotiated, success-driven clinical and regulatory milestones and tiered royalties.",
    'FW Link1':
      'Dewpoint Therapeutics Partners with Chemify to Apply Groundbreaking Chemistry AI to Radically Accelerate the Discovery of Molecules Targeting Cancer and Neurodegeneration;;https://www.firstwordhealthtech.com/story/5780193/'
  },
  {
    'Date Announced': '12-Sep-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Key Oncologics',
    'Country (Acquiring Company/Partner)': 'South Africa',
    'Region (Acquiring Company/Partner)': 'Africa',
    'Target Company/Partner': 'PharmaMar',
    'Country (Target Company/Partner)': 'Spain',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal':
      'South Africa, Namibia, Zimbabwe, Mozambique, Swaziland, Lesotho, Botswana',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Chemotherapy',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Zepzelca',
    'Indication(s)': 'Small-cell lung cancer',
    'Deal Structure':
      'PharmaMar (MSE:PHM) has announced today that it has signed a licensing agreement with Key Oncologics (Pty) Ltd.,  for the commercialization and distribution of Zepzelca® (lurbinectedin), its marine-derived anti-tumor compound for Small Cell Lung Cancer (SCLC), in South Africa, Namibia, Zimbabwe, Mozambique, Swaziland, Lesotho and Botswana. PharmaMar will retain exclusive production rights, while Key Oncologics (Pty) Ltd will apply for marketing approval and will have the right to market the product on an exclusive basis.',
    'FW Link1':
      'PharmaMar licences lurbinectedin to Key Oncologics (Pty) Ltd for its marketing and distribution in Africa;;https://firstwordpharma.com/story/5780171/'
  },
  {
    'Date Announced': '12-Sep-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Exelixis',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Insilico Medicine',
    'Country (Target Company/Partner)': 'Hong Kong',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '80',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'ISM3091',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'USP1',
    'Deal Structure':
      'Under terms of the agreement, Insilico granted Exelixis an exclusive global license to develop and commercialise ISM3091, as well as other USP1-targeting compounds, in exchange for an upfront payment of $80 million. Insilico is also eligible for future development, commercial and sales-based milestone payments, plus tiered royalties.',
    'FW Link1':
      "Exelixis inks licensing deal for Insilico's AI-designed cancer drug;;https://firstwordpharma.com/story/5780025/"
  },
  {
    'Date Announced': '12-Sep-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Undisclosed',
    'Target Company/Partner': 'Wellmarker Bio',
    'Country (Target Company/Partner)': 'Korea, South',
    'Region (Target Company/Partner)': 'Asia',
    'Total Deal Value ($m)': '528',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibodies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'WM-A1-3389',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'The latest contract, which includes upfront payments and staged milestones, amounts to 700 billion won ($528 million), with separate royalties. The company did not disclose the identity of the contracting party. With this agreement, Wellmarker Bio has granted exclusive global rights for the clinical development and commercialization of WM-A1-3389.',
    'FW Link1':
      'Wellmarker Bio signs worth of $528 mn tech transfer deal;;https://firstwordpharma.com/story/5779981/'
  },
  {
    'Date Announced': '12-Sep-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Traverse Biotech',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Genmab',
    'Country (Target Company/Partner)': 'Denmark',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Bispecific antibodies',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Traverse Biotech, a US-based biotechnology company focused on the development of immunotherapies from international biopharma companies, announces that it has signed a license agreement with Genmab under which Traverse will develop and commercialize a bispecific antibody for cancer immunotherapy. This next-generation antibody was created using Genmab's proprietary DuoBody® bispecific antibody technology platform as a targeted treatment candidate for cancers expressing an undisclosed tumor-associated antigen.",
    'FW Link1':
      'Traverse Biotech announces a world-wide license agreement with Genmab to develop and commercialize a novel cancer bispecific antibody;;https://firstwordpharma.com/story/5779933/'
  },
  {
    'Date Announced': '12-Sep-2023',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Pierre Fabre',
    'Country (Acquiring Company/Partner)': 'France',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Vertical Bio',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'VERT-002',
    'Indication(s)': 'Non-small-cell lung cancer',
    'Target(s)': 'c-MET',
    'Deal Structure':
      'Pierre Fabre Laboratories, the French pharmaceutical and dermo-cosmetic company, announced the acquisition of Vertical Bio AG, a developer of novel cancer therapies. This first acquisition of a biotechnology company allows Pierre Fabre Laboratories to add VERT-002 to its oncology discovery pipeline. VERT-002 is a monoclonal antibody with a novel and differentiated mechanism of action, acting as a degrader of c-MET. This target is a known disease driver in patients suffering from Non-Small Cell Lung Cancer (NSCLC) with mutations or amplification of MET. Deal terms were not disclosed.',
    'FW Link1':
      'Pierre Fabre Laboratories Acquires Vertical Bio and its Innovative Targeted Therapy Candidate for Patients Suffering From Non-Small Cell Lung Cancer With MET Alteration;;https://firstwordpharma.com/story/5780263/'
  },
  {
    'Date Announced': '13-Sep-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Incyte',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'AbCellera',
    'Country (Target Company/Partner)': 'Canada',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibodies',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'AbCellera (Nasdaq:ABCL) announced that it has entered into a strategic collaboration with Incyte (Nasdaq:INCY) to discover and develop therapeutic antibodies in oncology. Under the financial terms of the agreement, Incyte has the right to develop and commercialize therapeutic antibodies resulting from the collaboration. AbCellera will receive research payments and is eligible to receive downstream clinical and regulatory milestone payments and royalties on net sales of products.',
    'FW Link1':
      'AbCellera Announces Collaboration with Incyte to Accelerate the Discovery and Development of Therapeutic Antibodies in Oncology;;https://firstwordpharma.com/story/5780574/'
  },
  {
    'Date Announced': '13-Sep-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Apmonia Therapeutics',
    'Country (Acquiring Company/Partner)': 'France',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'SATT Nord',
    'Country (Target Company/Partner)': 'France',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Peptides',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'AP-04',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'pro-cathepsin-D',
    'Deal Structure':
      'Apmonia Therapeutics, a biotechnology company developing new therapeutic strategies aimed at reprogramming the tumor microenvironment by targeting elements of the extracellular matrix, announced the signature of a new licensing agreement and the launch of a co-development program with SATT Nord (technology transfer office of universities in the North of France). Apmonia Therapeutics has obtained exclusive worldwide rights on LBC peptides, now also referred as AP-04, a program currently in preclinical development with the potential to generate first-in-class candidates for the treatment of various solid tumors, notably triple-negative breast cancer.',
    'FW Link1':
      'Apmonia Therapeutics announces a new licensing agreement with SATT Nord to strengthen its portfolio of innovative peptides for oncology;;https://firstwordpharma.com/story/5780664/'
  },
  {
    'Date Announced': '17-Sep-2023',
    'Deal type': 'Product Acquisition',
    'Acquiring Company/Partner': 'Sauvie',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Double Bond Pharmaceutical',
    'Country (Target Company/Partner)': 'Sweden',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Chemotherapy, drug delivery',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'SI-053',
    'Indication(s)': 'Glioblastoma',
    'Deal Structure':
      'Double Bond Pharmaceutical International AB (publ) (“DBP”) and Vivo Biopharma LLC (“Vivo”), a wholly owned subsidiary of Sauvie Inc. (“Sauvie”) announced that they have entered into an asset purchase, collaboration and transition agreements for DBP’s SI-053 development product and related assets. SI-053 is a locally acting formulation of temozolomide for the treatment of glioblastoma and it received Orphan Drug Designation from the European Medicines Agency in 2016. Upon the close of the transaction, Vivo will acquire SI-053 and related assets to advance the clinical program with DBP’s collaboration to support Vivo’s efforts. Based on SI-053 achieving certain clinical, regulatory and commercial milestones, DBP is entitled to receive more than $150 million of milestone and royalty payments from Vivo. The closing is conditioned by Vivo completing financing. Both parties anticipate closing the transaction by Q1, 2024.',
    'FW Link1':
      'DBP International and Vivo Biopharma Enter into Asset Purchase and Collaboration agreements to Develop and Commercialize a novel Glioblastoma Treatment;;https://firstwordpharma.com/story/5781513/'
  },
  {
    'Date Announced': '18-Sep-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Boundless Bio',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Taiho',
    'Country (Target Company/Partner)': 'Japan',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'BBI-355, Lytgobi',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'CHK1, FGFR',
    'Deal Structure':
      'Boundless Bio, a clinical stage, next-generation precision oncology company interrogating extrachromosomal DNA (ecDNA) biology to deliver transformative therapies to patients with previously intractable oncogene amplified cancers, announced that it has entered into a clinical trial collaboration and supply agreement with Taiho Oncology, Inc. (Taiho) for Taiho’s pan-FGFR inhibitor LYTGOBI® (futibatinib) for use in combination with BBI-355 in a clinical trial of patients with locally advanced or metastatic solid tumors with oncogene amplifications. Under the terms of the agreement, Taiho will provide futibatinib clinical drug supply at no cost for Boundless Bio’s ongoing Phase 1/2 clinical trial (POTENTIATE), which will assess BBI-355 in combination with certain selected targeted therapies, including futibatinib, in patients with specific oncogene amplified solid tumors.',
    'FW Link1':
      'Boundless Bio Announces Clinical Trial Collaboration and Supply Agreement with Taiho Oncology to Evaluate LYTGOBI (futibatinib) in Combination with BBI-355 in Patients with FGFR Amplified Solid Tumors;;https://firstwordpharma.com/story/5781798/'
  },
  {
    'Date Announced': '17-Sep-2023',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'BMS',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Samsung Group',
    'Country (Target Company/Partner)': 'Korea, South',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibodies',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Undisclosed',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Samsung Biologics (KRX: 207940.KS) announced a new agreement with Bristol Myers Squibb (NYSE: BMY) for large-scale manufacturing of a Bristol Myers Squibb commercial antibody cancer drug substance. Bristol Myers Squibb and Samsung Biologics have an existing manufacturing agreement for a commercial antibody cancer drug and have expanded the strategic relationship over time. Under the terms of the new agreement, Samsung Biologics will provide drug substance manufacturing for an antibody cancer drug substance at the company's latest and largest biomanufacturing facility, Plant 4, in Songdo, South Korea.",
    'FW Link1':
      'Samsung Biologics announces expanded strategic agreement with Bristol Myers Squibb to manufacture an antibody cancer drug substance;;https://firstwordpharma.com/story/5781529/'
  },
  {
    'Date Announced': '18-Sep-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Hemogenyx Pharmaceuticals',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Prevail InfoWorks',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'CAR-T therapies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Indication(s)': 'Acute myeloid leukaemia',
    'Target(s)': 'FLT3',
    'Deal Structure':
      'The Company\'s wholly owned subsidiary, Hemogenyx Pharmaceuticals LLC, has also signed a Master Service and Technology Agreement ("MSTA") with Prevail InfoWorks, Inc. ("InfoWorks"), a Philadelphia, PA based Contract Research Organization (CRO) and affiliate of Prevail Partners. Under the terms of the MSTA, InfoWorks is to provide clinical services and technologies for the Company\'s upcoming Phase I study of its anti-FLT3 chimeric antigen receptor-redirected T cells ("CAR-T cells") in subjects with relapsed/refractory acute myeloid leukemia (AML). Services include clinical site coordination, project management, data management, clinical monitoring, and pharmacovigilance (safety management) services, and the use of InfoWorks\' integrated real-time data analytics platform, The Single Interface®, for clinical support and real-time data analysis. The MSTA has an initial term of 40 months, and Hemogenyx Pharmaceuticals LLC has agreed an initial statement of works relating to the Phase I AML study.',
    'FW Link1':
      'Hemogenyx Pharmaceuticals PLC Announces Strategic Investment from Prevail Partners, LLC;;https://firstwordpharma.com/story/5781536/'
  },
  {
    'Date Announced': '19-Sep-2023',
    'Deal type': 'Terminated',
    'Acquiring Company/Partner': 'Novartis',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'BeiGene',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '650',
    'Geographic Remit of Deal':
      'United States, Canada, Mexico, European Union, United Kingdom, Norway, Switzerland, Iceland, Liechtenstein, Russia, Japan',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Tevimbra',
    'Indication(s)': 'Oesophageal squamous cell carcinoma',
    'Target(s)': 'PD-1',
    'Deal Structure':
      'Novartis and BeiGene mutually agreed to terminate their agreement surrounding Tevimbra (tislelizumab), with all rights to the anti-PD-1 monoclonal antibody returning to the Chinese drugmaker. The news came the same day that European regulators approved the drug for the treatment of adults with unresectable, locally advanced or metastatic oesophageal squamous cell carcinoma (ESCC) after prior platinum-based chemotherapy. In 2021, Novartis paid $650 million upfront for rights to Tevimbra in several major markets outside China, but BeiGene said Tuesday that it has now regained full rights with no royalty payments due to the Swiss drugmaker.',
    'FW Link1':
      'Despite securing EU approval, Novartis returns rights to BeiGene’s anti-PD-1 drug Tevimbra;;https://firstwordpharma.com/story/5781942/'
  },
  {
    'Date Announced': '19-Sep-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': '1ST Biotherapeutics',
    'Country (Acquiring Company/Partner)': 'Korea, South',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Merck & Co.',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules, monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'FB849, Keytruda',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'HPK1, PD-1',
    'Deal Structure':
      '1ST Biotherapeutics, Inc., a clinical-stage biotechnology company focused on the development of novel small molecule therapeutics in neurodegenerative diseases, immuno-oncology, and rare diseases, announced a Clinical Trial Collaboration and Supply Agreement (CTCSA) with MSD, a tradename of Merck & Co., Inc., Rahway, NJ, USA, to evaluate FB849 in combination with MSD’s anti-PD-1 therapy KEYTRUDA® (pembrolizumab) in a Phase I/II clinical trial for the treatment of patients with advanced solid tumors. Under the terms of the agreement, MSD will provide KEYTRUDA to be used in combination with FB849 for the planned Phase I/II study.',
    'FW Link1':
      '1ST Biotherapeutics Announces Clinical Trial Collaboration and Supply Agreement with MSD to Evaluate FB849 in Combination With KEYTRUDA (pembrolizumab);;https://firstwordpharma.com/story/5782104/'
  },
  {
    'Date Announced': '19-Sep-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Boundless Bio',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Lilly',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'BBI-355, Verzenio',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'CHK1, CDK4/6',
    'Deal Structure':
      'Boundless Bio, a clinical stage, next-generation precision oncology company interrogating extrachromosomal DNA (ecDNA) biology to deliver transformative therapies to patients with previously intractable oncogene amplified cancers, announced a clinical trial collaboration and supply agreement with Eli Lilly and Company for supply of their CDK4/6 inhibitor Verzenio® (abemaciclib) for use in combination with BBI-355 in a clinical trial of patients with locally advanced or metastatic solid tumors with oncogene amplifications. Under the terms of the agreement, Lilly will provide abemaciclib clinical drug supply at no cost for Boundless Bio’s ongoing Phase 1/2 clinical trial (POTENTIATE), which will assess BBI-355 in combination with certain selected targeted therapies, including abemaciclib, in patients with specific oncogene amplified solid tumors.',
    'FW Link1':
      'Boundless Bio Announces Clinical Trial Collaboration and Supply Agreement with Lilly to Evaluate VERZENIO (abemaciclib) in Combination with BBI-355 in Patients with CDK4 or CDK6 Amplified Solid Tumors;;https://firstwordpharma.com/story/5782347/'
  },
  {
    'Date Announced': '20-Sep-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Merck KGaA',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Exscientia',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Upfront Value ($m)': '20',
    'Total Deal Value ($m)': '694',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial intelligence, small molecules',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Exscientia will use its AI-driven precision drug design and discovery capabilities to uncover small-molecule candidates across oncology, neuroinflammation and immunology. Exscientia noted that three potential first-in-class or best-in-class targets have been identified as the initial focus of the partnership, with Merck having the option to identify additional targets in oncology and immunology or other mutually agreed disease areas. Merck will make an upfront payment of $20 million, with Exscientia eligible for milestone payments of up to $674 million for the three initial programmes. In addition, Exscientia stands to receive sales royalties ranging from the mid-single digits to low-double digits.',
    'FW Link1':
      'Merck KGaA seeks pipeline boost from AI-based deals with BenevolentAI, Exscientia;;https://firstwordpharma.com/story/5782484/'
  },
  {
    'Date Announced': '20-Sep-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Merck KGaA',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'BenevolentAI',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Total Deal Value ($m)': '594',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial intelligence, small molecules',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'BenevolentAI will leverage its suite of AI chemistry design tools to deliver small-molecule drug development candidates into the Merck pipeline with an initial focus on three targets in oncology, neurology and immunology. BenevolentAI will be responsible for advancing compounds through hit identification to the preclinical stage, with Merck taking over further work. BenevolentAI is eligible for payments of up to $594 million from Merck, including a low double-digit million-dollar upfront payment, as well as tiered sales royalties.',
    'FW Link1':
      'Merck KGaA seeks pipeline boost from AI-based deals with BenevolentAI, Exscientia;;https://firstwordpharma.com/story/5782484/'
  },
  {
    'Date Announced': '20-Sep-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'RefleXion',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': '3B Pharmaceuticals',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Radiopharmaceuticals',
    'Lead Asset(s)': 'RXM-4768 (3BP-4768)',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'Fibroblast activation protein (FAP)',
    'Deal Structure':
      'RefleXion Medical, Inc., a therapeutic oncology company and 3B Pharmaceuticals (3BP) GmbH, a biotechnology company developing targeted radiopharmaceutical drugs and diagnostics for cancer detection and treatment, announced an exclusive licensing agreement for RefleXion to develop and commercialize a specific 3BP molecule to direct its SCINTIX™ biology-guided radiotherapy. Under the terms of the agreement, RefleXion has worldwide, exclusive and sublicensable rights to continue development and subsequent commercialization of RXM-4768 for use with SCINTIX biology-guided radiotherapy. Terms of the transaction also include upfront and annual license payments to 3BP. Upon achievement of certain regulatory and commercial milestones, additional payments and commercial royalties would be due to 3BP. Further terms of the agreement were not disclosed.',
    'FW Link1':
      'RefleXion Acquires Global Rights to Radiopharmaceutical Molecule from 3B Pharmaceuticals to Treat High Unmet Need Cancers Including Brain, Pancreatic and Liver;;https://firstwordpharma.com/story/5782753/'
  },
  {
    'Date Announced': '20-Sep-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Roche',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Orionis Biosciences',
    'Country (Target Company/Partner)': 'Belgium',
    'Region (Target Company/Partner)': 'Europe',
    'Upfront Value ($m)': '47',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Orionis Biosciences, a privately held life sciences company with an integrated drug discovery and chemical biology platform, announced a multi-year collaboration with Genentech, a member of the Roche Group, to discover novel small molecule medicines for challenging targets in major disease areas, including oncology and neurodegeneration. Under the terms of the agreement, Orionis will be responsible for the discovery and optimization of molecular glues for Genentech’s designated targets, while Genentech will be responsible for subsequent later-stage preclinical, clinical development, regulatory filing, and commercialization of such small molecules. Orionis will receive an upfront payment of $47 million and is eligible for development milestone payments, as well as commercial and net sales milestone payments that could exceed $2 billion and a tiered royalty upon sale of collaboration products.',
    'FW Link1':
      'Orionis teams up with Roche on molecular glue degraders;;https://firstwordpharma.com/story/5782721/'
  },
  {
    'Date Announced': '21-Sep-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Debiopharm',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'SunRock',
    'Country (Target Company/Partner)': 'Spain',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Target(s)': 'HER2, HER3',
    'Deal Structure':
      'The agreement offers Debiopharm licensing options to further develop SunRock’s antibodies integrating Debiopharm’s innovative linker technologies, Multilink™ and AbYlink™, to create innovative antibody drug conjugates (ADCs). Novel ADCs will be developed with capacity to target tumor-specific antigens to fight cancers with high unmet need, including those tumor types expressing HER2/HER3.',
    'FW Link1':
      'Debiopharm and SunRock Biopharma Partner to Advance Antibody Drug Conjugates for Hard-to-treat Cancers;;https://firstwordpharma.com/story/5782823/'
  },
  {
    'Date Announced': '20-Sep-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Lixte Biotechnology',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'GSK',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules, monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'LB-100, dostarlimab',
    'Indication(s)': 'Ovarian clear cell carcinoma',
    'Target(s)': 'PP2A, PD-1',
    'Deal Structure':
      'LIXTE Biotechnology Holdings, Inc. (Nasdaq: LIXT) (“LIXTE”), announced a Phase 1b collaborative clinical trial to assess whether adding Lixte’s LB-100 to GSK’s programmed death receptor-1 (PD-1)-blocking monoclonal antibody, dostarlimab, may enhance the effectiveness of immunotherapy in the treatment of ovarian clear cell carcinoma (OCCC). The clinical trial is sponsored by The University of Texas - MD Anderson Cancer Center and will be conducted at MD Anderson and will also be open at Northwestern University’s Robert H. Lurie Comprehensive Cancer Center. LIXTE will provide LB-100; GSK will provide dostarlimab and financial support for the clinical trial.',
    'FW Link1':
      'LIXTE Biotechnology Announces a Supported Collaborative Trial to Study LIXTE’s First-in-Class PP2A Inhibitor, LB-100, Plus GSK’s Immunotherapy, Dostarlimab, in Clear-Cell Ovarian Cancer;;https://firstwordpharma.com/story/5782506/'
  },
  {
    'Date Announced': '21-Sep-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Replay',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'JURA Bio',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'TCR-NK cell therapies, artificial intelligence, machine learning',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'JURA Bio, a biotechnology company developing immune-based therapeutics using machine learning and synthetic biology, announced a research collaboration with Syena, a cell therapy product company and subsidiary of Replay, a genome writing company, to develop T cell receptor (TCR) based therapies. JURA Bio will receive an upfront payment as well as research funding for the period of the partnership. The details of the financial terms of the agreement were not disclosed. If the option is exercised, Replay and its cell therapy product company Syena will be responsible for global development and hold exclusive worldwide commercialization rights on all TCR-NK therapies resulting from the partnership. JURA Bio will be eligible to receive development, regulatory, and commercial milestone payments in addition to tiered deferred option payments on global net sales for products based on TCRs arising from the collaboration, as well as royalties on products utilizing at least one of the licensed technologies.',
    'FW Link1':
      'JURA Bio Announces Partnership With Replay Product Company Syena to Advance T Cell Receptor NK Therapies in Cancer;;https://firstwordpharma.com/story/5782996/'
  },
  {
    'Date Announced': '22-Sep-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Merck KGaA',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Institute of Cancer Research',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'The Institute of Cancer Research and science and technology company Merck KGaA have renewed their strategic alliance in the discovery and development of new cancer drugs. Scientists in the Centre for Cancer Drug Discovery at the ICR will work with researchers at Merck on a range of related projects, each of which aim to discover and develop new small-molecule inhibitors of an exciting new class of cancer targets. The multi-target, multi-year collaboration involves significant financial and staff commitments from both parties, including Merck funding nine full-time research posts at the ICR.',
    'FW Link1':
      'ICR and Merck renew strategic alliance in discovery and development of innovative new small-molecule cancer drugs;;https://firstwordpharma.com/story/5783288/'
  },
  {
    'Date Announced': '24-Sep-2023',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'Roche',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Hadassah-University Medical Center',
    'Country (Target Company/Partner)': 'Israel',
    'Region (Target Company/Partner)': 'Middle East',
    'Geographic Remit of Deal': 'Israel',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostics, genomic profiling',
    'Lead Asset Stage/Phase': 'Marketed',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "A collaboration agreement has been signed between the Hadassah-University Medical Center in Jerusalem's Ein Kerem and Roche that will promote personalized cancer treatment based on genomic profiling, The Jerusalem Post reported. Under the agreement, comprehensive genomic profiling tests will be provided for Hadassah patients using the AVENIO kit for solid tumor diagnostics and Foundation Medicine tests that will be sent to labs abroad.",
    'FW Link1':
      'Israel makes cancer treatment breakthrough with genomic profiling;;https://firstwordpharma.com/story/5783658/'
  },
  {
    'Date Announced': '22-Sep-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Panolos',
    'Country (Acquiring Company/Partner)': 'Korea, South',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Chime Biologics',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Immuno-oncology, multi-specific therapeutic proteins',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'PB203',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'VEGF, PIGF, PD-L1',
    'Deal Structure':
      'Panolos Bioscience has entered into a collaboration with Chime Biologics for a novel multi-specific protein drug, PB203, for solid tumors (PDAC/ICC), which is in the preclinical stage with plans to conduct clinical trial Phase I in the US. Chime Biologics will provide an one-stop biologic CMC solution for the development and manufacturing of PB203 from cell line development, process development, and GMP manufacturing service.',
    'FW Link1':
      'Chime Biologics Announced Global Strategic Cooperation with Panolos Bioscience to Advance Multi-specific Therapeutic Proteins Development;;https://firstwordpharma.com/story/5783638/'
  },
  {
    'Date Announced': '25-Sep-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Glycotope',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Max Delbrück Center',
    'Country (Target Company/Partner)': 'Germany',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibodies, CAR-T therapies, NK cells',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      'Glycotope GmbH (Glycotope) and the Max Delbrück Center for Molecular Medicine in the Helmholtz Association have signed an agreement to explore the potential of combining Glycotope’s antibodies against protein/carbohydrate combined glyco-epitopes (GlycoTargets) with chimeric antigen receptor (CAR) technology developed by the Max Delbrück Center. In the scope of the newly established collaboration, highly tumor-specific antibodies developed by Glycotope will be combined with the CAR technology of the Max Delbrück Center to analyze their suitability for the treatment of solid tumors.',
    'FW Link1':
      'Glycotope and Max Delbrück Center enter into research collaboration to explore combination of GlycoTargets and CAR technology;;https://firstwordpharma.com/story/5783673/'
  },
  {
    'Date Announced': '22-Sep-2023',
    'Deal type': 'Terminated',
    'Acquiring Company/Partner': 'AbbVie',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'I-Mab',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '180',
    'Total Deal Value ($m)': '1,300',
    'Geographic Remit of Deal': 'Global excl. Greater China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibodies',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'lemzoparlimab',
    'Indication(s)': 'Myelodysplastic syndrome, acute myelocytic leukaemia',
    'Target(s)': 'CD47',
    'Deal Structure':
      'AbbVie decided to exit its agreement with I-Mab to develop certain CD47 antibody compounds, including the programme’s lead drug lemzoparlimab. AbbVie paid $180 million upfront in 2020 as part of a collaboration potentially worth up to $1.7 billion for rights to lemzoparlimab outside of China, although the partnership was later tweaked with milestones downgraded to up to $1.3 billion. I-Mab explained that the termination of the deal, which will take effect on November 20, is based “in its entirety” on the earlier study halt, as well as a “strategic decision” by AbbVie.',
    'FW Link1':
      'AbbVie ditches I-Mab’s anti-CD47 monoclonal antibody lemzoparlimab;;https://firstwordpharma.com/story/5783698/'
  },
  {
    'Date Announced': '25-Sep-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Pierre Fabre',
    'Country (Acquiring Company/Partner)': 'France',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'HitGen',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Pierre Fabre Laboratories and Vernalis (R&D) Ltd ("Vernalis"), a fully owned subsidiary of HitGen Inc., are pleased to announce a long-term partnership to identify pre-clinical candidates against multiple oncology targets. Under the terms of the agreement, Vernalis will use its expertise to enable drug discovery against an undisclosed portfolio of targets and use fragment and structure-based methods to identify small molecules that modulate their activity. This research will be combined with the oncology experience of Pierre Fabre Laboratories to identify drug candidates for treatment of a range of cancers for further development and commercialisation by Pierre Fabre Laboratories. The research at Vernalis will be funded by Pierre Fabre Laboratories who will also pay research and clinical milestones and royalties on marketed products. The detailed terms of the agreement are not disclosed.',
    'FW Link1':
      'Pierre Fabre Laboratories and Vernalis announce a drug discovery collaboration in oncology;;https://firstwordpharma.com/story/5783672/'
  },
  {
    'Date Announced': '26-Sep-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Beactica',
    'Country (Acquiring Company/Partner)': 'Sweden',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'NIH',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Cancer',
    'Target(s)': 'TEAD',
    'Deal Structure':
      'Beactica Therapeutics AB, the Swedish precision oncology company, announced that it has entered into a research collaboration agreement with the National Center for Advancing Translational Sciences (NCATS), one of 27 institutes and centers at the U.S. National Institutes of Health (NIH). The collaboration will focus on the translation of novel proteolysis-targeting degraders of TEAD under development by Beactica for treatment of cancer. Under the agreement, NCATS will gain access to proprietary targeted degraders of TEAD from Beactica to evaluate their efficacy in disease-relevant preclinical models. NCATS will also map systematically the drug-combination landscape for selected preclinical candidates by performing a high-throughput drug-combination screen using a collection of about 3,000 oncology-focused, mechanistically annotated drugs.',
    'FW Link1':
      'Beactica Therapeutics announces collaboration with the National Center for Advancing Translational Sciences;;https://firstwordpharma.com/story/5783963/'
  },
  {
    'Date Announced': '27-Sep-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Cellular Biomedicine Group',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Nektar',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Cell therapies, TIL therapies, immunotherapeutics',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'C-TIL051, NKTR-255',
    'Indication(s)': 'Non-small-cell lung cancer',
    'Target(s)': 'IL-15',
    'Deal Structure':
      'Under the new collaboration, CBMG will add NKTR-255 to its ongoing CBMG-sponsored Phase 1 clinical trial evaluating C-TIL051 in NSCLC patients who have relapsed on or were refractory to anti-PD-1 therapy, which is being conducted at Duke Cancer Institute (NCT05676749). The study is expected to enroll a total of 20 patients. Nektar will contribute NKTR-255 supply for the study. Nektar and CBMG will each maintain existing global rights to their respective investigational medicines.',
    'FW Link1':
      'Nektar Therapeutics Announces New Clinical Study Collaboration with Cellular Biomedicine Group Inc. to Evaluate NKTR-255 in Combination with C-TIL051 in Advanced Non-Small Cell Lung Cancer;;https://firstwordpharma.com/story/5784780/'
  },
  {
    'Date Announced': '26-Sep-2023',
    'Deal type': 'Terminated',
    'Acquiring Company/Partner': 'AbbVie',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Caribou Biosciences',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '40',
    'Total Deal Value ($m)': '340',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'CAR-T therapies',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "AbbVie decided to end an agreement with Caribou Biosciences focused on the development of next-generation, off-the-shelf CAR-T cell therapies. Under the agreement – signed in 2021 – AbbVie made an upfront payment of $40 million, along with an equity investment in Caribou, to gain access to the latter's CRISPR genome editing platform. The deal, which also included potential milestones of up to $300 million, aimed to overcome the rejection of allogeneic CAR-T cells by engineering the therapies to withstand host immune attack.",
    'FW Link1':
      'AbbVie ends agreement with Caribou, pulling plug on yet another oncology deal;;https://firstwordpharma.com/story/5784507/'
  },
  {
    'Date Announced': '27-Sep-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Ono Pharmaceutical',
    'Country (Acquiring Company/Partner)': 'Japan',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Adimab',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibodies, bispecific antibodies',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Ono Pharmaceutical Co., Ltd. (Osaka, Japan; President and CEO: Gyo Sagara; "Ono") announced that it has entered into a drug discovery collaboration agreement with Adimab, LLC (Lebanon, New Hampshire, USA; CEO: Philip Chase; "Adimab"), the global leader in the discovery and optimization of fully human monoclonal and bispecific antibodies, to discover and develop innovative antibody drugs in the oncology field. Under the terms of the agreement, Adimab will discover novel therapeutic antibodies against multiple targets selected by Ono and generate bispecific antibody product candidates. Ono will evaluate and develop such candidates at the pre-clinical and clinical stages. Ono will have an option to obtain exclusive rights to globally develop, manufacture and commercialize the candidates to be generated through this collaboration. Ono will pay to Adimab an up-front payment, R&D funding, success-based milestone on research, clinical development and regulatory progress, as well as tiered royalties based on net sales.',
    'FW Link1':
      'Ono Enters into a Drug Discovery Collaboration Agreement with Adimab to Discover Novel Antibody Drugs in the Oncology Field;;https://firstwordpharma.com/story/5784500/'
  },
  {
    'Date Announced': '27-Sep-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'BioCity Biopharma',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'AstraZeneca',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'BC3402, Imfinzi',
    'Indication(s)': 'Hepatocellular carcinoma',
    'Target(s)': 'TIM-3, PD-L1',
    'Deal Structure':
      "BioCity Biopharma and AstraZeneca signed an agreement to collaborate on a Phase Ib/II clinical study to evaluate the safety and efficacy of BioCity's BC3402, a monoclonal antibody (mAb) targeting the T cell immunoglobulin and mucin domain-containing protein 3, also known as TIM-3, in combination with AstraZeneca's anti-PD-L1 mAb IMFINZI (durvalumab) for the treatment of advanced hepatocellular carcinoma (HCC) in China. BioCity will lead the trial, which has received IND approval by the National Medical Products Administration (NMPA).",
    'FW Link1':
      'BioCity Signs Collaboration Agreement with AstraZeneca on BC3402, a monoclonal antibody targeting anti-TIM-3, in Combination with IMFINZI (durvalumab) for the Treatment of Advanced Hepatocellular Carcinoma in China;;https://firstwordpharma.com/story/5784883/'
  },
  {
    'Date Announced': '30-Sep-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Alentis Therapeutics',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'KYAN Technologies, CELLphenomics',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibodies, artificial intelligence',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Target(s)': 'Claudin-1',
    'Deal Structure':
      'Through this collaboration, all three organizations will leverage their respective expertise and resources to advance Claudin-1 expression research to new heights. KYAN Technologies will bring its cutting-edge Optim.AI™ platform, a revolutionary technology that employs small data AI and experimentally derived datasets. Optim.AI™ solves large and complex search spaces to identify and rank combination treatments across the spectrum of biological models. Alentis Therapeutics will contribute its extensive experience in disease research and drug development, while CELLPhenomics, a renowned provider of complex patient-derived cell culture models will provide hyper-characterized models tailored specifically for CLDN1 expression studies and subsequent compound screening.',
    'FW Link1':
      'Advancing Claudin-1 Expression Research with Cutting-edge Technologies;;https://firstwordpharma.com/story/5785784/'
  },
  {
    'Date Announced': '3-Oct-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Henlius Biotech',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'OncoHost',
    'Country (Target Company/Partner)': 'Israel',
    'Region (Target Company/Partner)': 'Middle East',
    'Geographic Remit of Deal': 'United States',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'serplulimab',
    'Indication(s)': 'Small-cell lung cancer',
    'Target(s)': 'PD-1',
    'Deal Structure':
      "Henlius USA will utilize OncoHost's PROphet platform to identify exploratory, correlative biomarkers for patients recruited in its comprehensive Phase III clinical trial (NCT05468489). The trial is evaluating the efficacy and safety of Serplulimab plus chemotherapy (carboplatin - etoposide) in previously untreated US-based patients with Extensive-Stage Small Cell Lung Cancer (ES-SCLC).",
    'FW Link1':
      "OncoHost's PROphet Platform to Support Henlius US-Based Phase III Trial for Patients with Extensive-Stage Small Cell Lung Cancer;;https://firstwordpharma.com/story/5786542/"
  },
  {
    'Date Announced': '2-Oct-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner':
      'Aviko Radiopharmaceuticals, Neutron Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Leo Cancer Care',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Boron neutron capture therapy',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Aviko Radiopharmaceuticals, a Deerfield Management-founded biotechnology company developing medicines to unlock the potential of boron neutron capture therapy (BNCT), and Neutron Therapeutics, a leading developer of accelerator-based neutron systems for targeted radiation therapy of solid tumors, announced an exclusive, strategic partnership with Leo Cancer Care, a company developing upright patient positioning systems for radiation therapy. The goal of the partnership between the three companies is to expand BNCT as a potential treatment for different types of cancer.',
    'FW Link1':
      'Aviko Radiopharmaceuticals and Neutron Therapeutics Form Exclusive, Strategic Partnership with Leo Cancer Care to Expand Development of Boron Neutron Capture Therapy for Treating Cancer;;https://firstwordpharma.com/story/5786189/'
  },
  {
    'Date Announced': '3-Oct-2023',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Lilly',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'POINT Biopharma',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Total Deal Value ($m)': '1,400',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Radiopharmaceuticals, radioligand therapies',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'PNT2002, PNT2003',
    'Indication(s)':
      'Prostate cancer, gastroenteropancreatic neuroendocrine tumours',
    'Target(s)': 'PSMA, SSTR',
    'Deal Structure':
      'Eli Lilly entered into a definitive agreement to acquire POINT Biopharma for $12.50 per share in cash, or approximately $1.4 billion, in a move designed to broaden its oncology portfolio with the addition of radioligand therapies.',
    'FW Link1':
      'Lilly to enter radioligand therapy space with POINT Biopharma buy;;https://firstwordpharma.com/story/5786357/'
  },
  {
    'Date Announced': '3-Oct-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'GSK',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Promega',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Companion diagnostics, monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Jemperli',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'PD-1',
    'Deal Structure':
      'Promega Corporation announced it plans to develop and commercialize a microsatellite instability (MSI) companion diagnostic (CDx) IVD kit with GSK to identify adult cancer patients with MSI-H solid tumors who may be eligible for potential treatment with GSK’s Jemperli (dostarlimab-gxly).',
    'FW Link1':
      'Promega to Develop Microsatellite Instability (MSI) Companion Diagnostic IVD Kit in Collaboration With GSK;;https://www.firstwordhealthtech.com/story/5786481/'
  },
  {
    'Date Announced': '4-Oct-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Ankyra Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Merck & Co.',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Anchored immunotherapy, drug delivery, monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'ANK-101, Keytruda',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'PD-1',
    'Deal Structure':
      'Ankyra Therapeutics, a clinical biotechnology company developing a new form of local immunotherapy termed “anchored immunotherapy,” announced a clinical trial collaboration and supply agreement with Merck (known as MSD outside of the US and Canada) to evaluate ANK-101 in combination with Merck’s anti-PD-1 therapy, KEYTRUDA® (pembrolizumab) following the completion of a first-in-human phase I study of ANK-101 alone in patients with advanced solid tumors.',
    'FW Link1':
      'Ankyra Therapeutics Announces Clinical Trial Collaboration and Supply Agreement with Merck to Evaluate ANK-101 in Combination with KEYTRUDA (pembrolizumab) in Patients with Advanced Solid Tumors;;https://firstwordpharma.com/story/5786878/'
  },
  {
    'Date Announced': '4-Oct-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'BriaCell',
    'Country (Acquiring Company/Partner)': 'Canada',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'New York Cancer & Blood Specialists',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Geographic Remit of Deal': 'United States',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immunotherapy',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'Bria-IMT',
    'Indication(s)': 'Breast cancer',
    'Deal Structure':
      'BriaCell Therapeutics Corp. (Nasdaq: BCTX, BCTXW) (TSX: BCT) (“BriaCell” or the “Company”), a clinical-stage biotechnology company that develops novel immunotherapies to transform cancer care, is pleased to announce a partnership with New York Cancer & Blood Specialists (NYCBS), a group of leading community cancer centers in the United States, to evaluate the Bria-IMT™ combination with a checkpoint inhibitor in its pivotal Phase 3 registration study in advanced breast cancer patients in New York.',
    'FW Link1':
      'BriaCell Partners with New York Cancer & Blood Specialists (NYCBS) for Pivotal Phase 3 Study of Bria-IMT in Advanced Breast Cancer;;https://firstwordpharma.com/story/5786932/'
  },
  {
    'Date Announced': '8-Oct-2023',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'AstraZeneca',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner':
      'Digital Economy and Society Ministry, Digital Economy Promotion Agency',
    'Country (Target Company/Partner)': 'Thailand',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Thailand',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial intelligence, screening',
    'Indication(s)': 'Lung cancer',
    'Deal Structure':
      'The Digital Economy and Society Ministry and the Digital Economy Promotion Agency (Depa) have joined forces with AstraZeneca (Thailand) to boost digital healthcare innovation within the Thai public health system. The latest collaboration involves a memorandum of understanding to extend the usage of artificial intelligence (AI) for preliminary lung cancer screenings.',
    'FW Link1':
      'AstraZeneca and Thai agencies collaborate to boost AI in healthcare;;https://www.firstwordhealthtech.com/story/5787838/'
  },
  {
    'Date Announced': '8-Oct-2023',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'BMS',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Mirati',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Total Deal Value ($m)': '5,800',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Krazati',
    'Indication(s)': 'Non-small-cell lung cancer',
    'Target(s)': 'KRAS',
    'Deal Structure':
      'Bristol Myers Squibb agreed to acquire Mirati Therapeutics for $58 per share in cash, representing a total equity value of $4.8 billion, along with a contingent value right (CVR) payment of $1 billion, the companies announced on Sunday. The transaction, which has been approved by both companies\' boards, is expected to close in the first half of next year. Through the transaction, Bristol Myers Squibb will gain the KRAS inhibitor Krazati (adagrasib) and have access to "several promising clinical assets" that it said will complement its oncology pipeline.',
    'FW Link1':
      'BMS to buy Mirati for up to $5.8 billion, adding KRAS inhibitor to oncology portfolio;;https://firstwordpharma.com/story/5787849/'
  },
  {
    'Date Announced': '10-Oct-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Hikma',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'CanariaBio',
    'Country (Target Company/Partner)': 'Korea, South',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'Middle East and North Africa',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'oregovomab',
    'Indication(s)': 'Ovarian cancer',
    'Target(s)': 'CA125',
    'Deal Structure':
      'CanariaBio Inc. and Hikma MENA FZE, part of Hikma Pharmaceuticals PLC (Hikma), announced the signing of distribution and license agreement for oregovomab in the Middle East and North Africa (MENA) region.',
    'FW Link1':
      'CanariaBio and Hikma announce the signing of distribution and license agreement for oregovomab in MENA Region;;https://firstwordpharma.com/story/5788149/'
  },
  {
    'Date Announced': '10-Oct-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Diakonos Oncology',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Cellipont',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Dendritic cell vaccines',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'DOC1021',
    'Indication(s)': 'Glioblastoma multiforme',
    'Deal Structure':
      'Cellipont Bioservices, a leading CDMO in cell therapy development and manufacturing and Diakonos Oncology, announced that they have entered into an agreement for the Process Development & cGMP Manufacturing of DOC1021, an autologous dendritic cell vaccine being manufactured for the treatment of glioblastoma multiforme (GBM) and other cancer indications.',
    'FW Link1':
      'Cellipont Bioservices and Diakonos Oncology Corporation Collaborate to Develop Groundbreaking Therapy for Glioblastoma;;https://firstwordpharma.com/story/5788604/'
  },
  {
    'Date Announced': '12-Oct-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'BioNTech',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'MediLink Therapeutics',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '70',
    'Total Deal Value ($m)': '1,000',
    'Geographic Remit of Deal': 'Global excl. China, Hong Kong, Macau',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Target(s)': 'HER3',
    'Deal Structure':
      "Under the terms of the agreement, MediLink will grant BioNTech exclusive global rights for the development, manufacturing, and commercialization of one of MediLink's ADC assets excluding Mainland China, Hong Kong Special Administrative Region, and Macau Special Administrative Region. In exchange, BioNTech will provide MediLink with an upfront payment totaling of $70 million and additional development, regulatory and commercial milestone payments potentially totaling over $1 billion.",
    'FW Link1':
      'BioNTech eyes next-gen ADCs for cancer via MediLink pact;;https://firstwordpharma.com/story/5789175/'
  },
  {
    'Date Announced': '24-Aug-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'XNK Therapeutics',
    'Country (Acquiring Company/Partner)': 'Sweden',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Karolinska University Hospital',
    'Country (Target Company/Partner)': 'Sweden',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'NK cell therapy',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'XNK02',
    'Indication(s)': 'Acute myeloid leukaemia',
    'Deal Structure':
      'XNK Therapeutics AB ("XNK") announced that the company has entered into a research collaboration with the Karolinska University Hospital, Sweden, to evaluate the suitability of the company\'s autologous natural killer (NK) cell therapy candidate XNK02 as a novel therapy for treatment of acute myeloid leukemia (AML). The research collaboration complements the current collaboration with the University of Texas MD Anderson Cancer Center to study XNK02 in AML patient material from various stages of the disease.',
    'FW Link1':
      'XNK Therapeutics enters into research collaboration with Karolinska University Hospital in acute myeloid leukemia (AML);;https://firstwordpharma.com/story/5773847/'
  },
  {
    'Date Announced': '31-Aug-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'BriaCell',
    'Country (Acquiring Company/Partner)': 'Canada',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Weill Cornell Medicine',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Immunotherapy',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'Bria-IMT',
    'Indication(s)': 'Breast cancer',
    'Deal Structure':
      'BriaCell Therapeutics Corp. (Nasdaq: BCTX, BCTXW) (TSX: BCT) (“BriaCell” or the “Company”), a clinical-stage biotechnology company that develops novel immunotherapies to transform cancer care, announces that it has accepted a letter of intent from Dr. Massimo Cristofanilli, Director of Breast Medical Oncology and Associate Director of Precision Medicine in the Sandra and Edward Meyer Cancer Center at Weill Cornell Medicine, outlining the parties’ plans and commitment, upon regulatory approval, to initiate a Phase 2 investigator-initiated clinical study to evaluate BriaCell’s novel immunotherapy, Bria-IMT™, in combination with a check point inhibitor (CPI), in early stage, newly diagnosed, high-risk triple negative breast cancer (TNBC) patients in the neoadjuvant setting.',
    'FW Link1':
      'BriaCell Accepts Letter of Intent from Weill Cornell Medicine Outlining Plans to Initiate a Clinical Trial of Bria-IMT in High-Risk Early-Stage Triple Negative Breast Cancer;;https://firstwordpharma.com/story/5776537/'
  },
  {
    'Date Announced': '12-Oct-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Specialised Therapeutics',
    'Country (Acquiring Company/Partner)': 'Singapore',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'CanariaBio',
    'Country (Target Company/Partner)': 'Korea, South',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal':
      'Australia, New Zealand, Singapore, Thailand, Vietnam, Brunei, Malaysia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'oregovomab',
    'Indication(s)': 'Ovarian cancer',
    'Target(s)': 'CA125',
    'Deal Structure':
      'Under the terms of the arrangement, ST will be responsible for all commercial, medical, regulatory and distribution activities for oregovomab in its key territories of Australia, New Zealand, Singapore, Thailand, Vietnam, Brunei and Malaysia. CanariaBio will be responsible for the manufacture and supply of oregovomab to ST.',
    'FW Link1':
      'Specialised Therapeutics signs exclusive license agreement with CanariaBio for new ovarian cancer therapy;;https://firstwordpharma.com/story/5789445/'
  },
  {
    'Date Announced': '15-Oct-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Ascentage Pharma',
    'Country (Acquiring Company/Partner)': 'China',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'AstraZeneca',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules',
    'Lead Asset Stage/Phase': 'Phase III',
    'Lead Asset(s)': 'APG-2575, Calquence',
    'Indication(s)': 'Chronic lymphocytic leukaemia/small lymphocytic lymphoma',
    'Target(s)': 'Bcl-2, BTK',
    'Deal Structure':
      'Ascentage Pharma (6855.HK), a global biopharmaceutical company engaged in developing novel therapies for cancer, chronic hepatitis B (CHB), and age-related diseases, announced that it has entered into a clinical collaboration with AstraZeneca Investment (China) Co., Ltd. (or "AstraZeneca"). The two companies will jointly conduct a registrational Phase III study of the Bcl-2 inhibitor, APG-2575 (lisaftoclax), in combination with AstraZeneca\'s Bruton\'s tyrosine kinase (BTK) inhibitor, CALQUENCE® (acalabrutinib), in treatment-naive patients with first-line chronic lymphocytic leukemia/small lymphocytic lymphoma (CLL/SLL).',
    'FW Link1':
      'Ascentage Pharma and AstraZeneca Enter into Clinical Collaboration on the Registrational Phase III Study of Bcl-2 Inhibitor Lisaftoclax in Combination with BTK Inhibitor Acalabrutinib in Treatment-Naïve Patients with First-Line CLL/SLL;;https://firstwordpharma.com/story/5790034/'
  },
  {
    'Date Announced': '16-Oct-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Antiverse',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'GlobalBio',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibodies, artificial intelligence',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Cancer',
    'Target(s)': 'PD-1',
    'Deal Structure':
      'Antiverse Ltd (Antiverse), a biotechnology company developing a computational antibody drug discovery platform, and GlobalBio, Inc. (GlobalBio), an antibody engineering company developing methods to engineer improved and more developable therapeutic antibodies, announced they will be extending their collaboration to advance immune checkpoint inhibitors in cancer therapy. The initial collaboration successfully resulted in the generation of a panel of anti-PD-1 antibodies with diverse binding and functional profiles, with two candidates from this panel now entering preclinical development.',
    'FW Link1':
      'Antiverse and GlobalBio, Inc. Extend Collaboration to Advance Antibody Cancer Therapeutics;;https://firstwordpharma.com/story/5790231/'
  },
  {
    'Date Announced': '16-Oct-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'SOTIO',
    'Country (Acquiring Company/Partner)': 'Czech Republic',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Lonza',
    'Country (Target Company/Partner)': 'Switzerland',
    'Region (Target Company/Partner)': 'Europe',
    'Total Deal Value ($m)': '740',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "SOTIO will gain access to Synaffix's ADC technologies, GlycoConnect, HydraSpace and toxSYN linker-payloads, in an initial ADC program with the option to expand research and commercial licenses into two additional programs at a later date. Under the terms of the agreement, Synaffix will be eligible to receive up to $740 million in payments spanning signature, target nomination and milestone payments plus additional royalties on commercial sales. SOTIO will be responsible for the research, development, and commercialization of the ADCs. Synaffix will be responsible for the manufacturing of components that are specifically related to its proprietary technologies.",
    'FW Link1':
      'SOTIO licences antibody-drug conjugate tech from Synaffix;;https://firstwordpharma.com/story/5789943/'
  },
  {
    'Date Announced': '16-Oct-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Cimeio Therapeutics',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'University of Pennsylvania',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'CAR-T therapies',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Blood and bone marrow cancers',
    'Target(s)': 'CD45',
    'Deal Structure':
      'Cimeio Therapeutics announced a preclinical research collaboration with Saar Gill, M.D., Ph.D., an associate professor of medicine and a researcher in the Center for Cellular Immunotherapies in the Perelman School of Medicine at the University of Pennsylvania, to research and evaluate a novel universal immunotherapy with potential to treat multiple blood and bone marrow cancers. The Penn team will combine Cimeio Therapeutics’ proprietary epitope-editing and CD45-targeting technologies with Penn Medicine’s epitope-editing and CAR T cell technology to create a CD45-targeting CAR T cell therapy and paired epitope-edited hematopoietic stem cell (HSC). Given that CD45 is highly expressed on nearly all blood cells, this therapy could be a universal CAR T cell therapy for blood cancers.',
    'FW Link1':
      'Cimeio Therapeutics Announces Research Collaboration With the University of Pennsylvania to Conduct Preclinical Studies of a CD45 Universal CAR T Cell Therapy for Patients With Blood and Bone Marrow Cancers;;https://firstwordpharma.com/story/5790272/'
  },
  {
    'Date Announced': '17-Oct-2023',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'Bracco',
    'Country (Acquiring Company/Partner)': 'Italy',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Sinotau',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Diagnostic imaging, radiopharmaceuticals',
    'Lead Asset(s)': 'flotufolastat',
    'Indication(s)': 'Prostate cancer',
    'Target(s)': 'PSMA',
    'Deal Structure':
      'Blue Earth Diagnostics Ltd, a Bracco company and recognised leader in the development and commercialisation of innovative PET radiopharmaceuticals, announced that it has signed an exclusive strategic agreement with Sinotau Pharmaceutical Group for the positron emission tomography (PET) imaging agent flotufolastat (18F) injection (formerly referred to as 18F-rhPSMA-7.3) in prostate cancer. Under the terms of the agreement this will be a close strategic partnership, with ongoing technology and regulatory support, aiming to support the use of flotufolastat (18F) for men with prostate cancer in China. It marks an important stage in the Blue Earth Diagnostics’ strategy to make flotufolastat (18F) available to patients and clinicians globally.',
    'FW Link1':
      'Blue Earth Diagnostics Ltd Collaborates with Sinotau Pharmaceutical Group to Bring Prostate Cancer PET Diagnostic Imaging Agent, Flotufolastat (18F) Injection, to China;;https://firstwordpharma.com/story/5790442/'
  },
  {
    'Date Announced': '17-Oct-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'ImmunOs Therapeutics',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Merck & Co.',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Immunotherapy, multi-functional fusion proteins, monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'IOS-1002, Keytruda',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'LILRB1 (ILT2), LILRB2 (ILT4), KIR3DL1, PD-1',
    'Deal Structure':
      "ImmunOs Therapeutics AG, a biopharmaceutical company pioneering HLA-based therapeutics to create transformational medicines for cancer and inflammation, announced a clinical trial collaboration and supply agreement with Merck & Co., Inc., Rahway, NJ, USA to evaluate its lead compound IOS-1002 in combination with Merck & Co., Inc., Rahway, NJ, USA's anti-PD-1 therapy, KEYTRUDA (pembrolizumab), in an ongoing Phase 1a/b clinical trial for the treatment of patients with advanced solid tumors.",
    'FW Link1':
      'ImmunOs Therapeutics Announces Clinical Trial Collaboration with Merck & Co., Inc., Rahway, NJ, USA;;https://firstwordpharma.com/story/5790428/'
  },
  {
    'Date Announced': '17-Oct-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Roche',
    'Country (Acquiring Company/Partner)': 'Switzerland',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Monte Rosa',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '50',
    'Total Deal Value ($m)': '2,000',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Small molecules, protein degraders',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Monte Rosa Therapeutics, Inc. (Nasdaq: GLUE), a clinical-stage biotechnology company developing novel molecular glue degrader (MGD)-based medicines, announced it has entered into a strategic collaboration and licensing agreement with global healthcare leader Roche to discover and develop MGDs against targets in cancer and neurological diseases previously considered impossible to drug. Under the terms of the agreement, Monte Rosa Therapeutics will receive an upfront payment of $50 million, and is eligible to receive future preclinical, clinical, commercial and sales milestone payments that could exceed $2 billion, as well as tiered royalties. The parties also agreed on a mechanism to expand the collaboration on multiple targets within the first two years. In that case, additional payments for nomination, preclinical, clinical, commercial and sales milestones are due, as well as tiered royalties on the resulting products. Monte Rosa Therapeutics will lead discovery and preclinical activities against multiple select cancer and neurological disease targets to a defined point. Roche gains the right to exclusively pursue further preclinical and clinical development of the compounds. Monte Rosa retains full ownership of its pipeline programs.',
    'FW Link1':
      'Monte Rosa Therapeutics Announces Strategic Collaboration with Roche to Discover Novel Molecular Glue Degraders Targeting Cancer and Neurological Diseases;;https://firstwordpharma.com/story/5790457/'
  },
  {
    'Date Announced': '17-Oct-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Servier',
    'Country (Acquiring Company/Partner)': 'France',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'OWKIN',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Artificial intelligence, precision therapeutics',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Servier, a global independent pharmaceutical group, and Owkin, a French American biotech focused on applying artificial intelligence (AI) to drug discovery, development, and diagnostics, announced an innovative partnership to use AI to advance and accelerate better-targeted therapies across multiple disease areas, including oncology.',
    'FW Link1':
      'Servier partners with Owkin with the aim of discovering and developing AI-driven precision therapeutics;;https://firstwordpharma.com/story/5790477/'
  },
  {
    'Date Announced': '17-Oct-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Elevar Therapeutics',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Hengrui',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Total Deal Value ($m)': '1,000',
    'Geographic Remit of Deal': 'Global excl. Greater China Region and Korea',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset(s)': 'camrelizumab',
    'Indication(s)': 'Hepatocellular carcinoma',
    'Target(s)': 'PD-1',
    'Deal Structure':
      'Elevar Therapeutics, Inc., a majority-owned subsidiary of HLB Co., Ltd. and fully integrated biopharmaceutical company dedicated to elevating treatment experiences and outcomes for patients who have limited or inadequate therapeutic options, and Jiangsu Hengrui Pharmaceuticals Co., Ltd. (Hengrui Pharma), one of China’s largest pharmaceutical companies, announced a global licensing agreement that grants Elevar rights to commercialize and develop Hengrui Pharma’s anti-PD-1 antibody camrelizumab in combination with rivoceranib for unresectable hepatocellular carcinoma (uHCC) worldwide, excluding Greater China Region and Korea. Under the terms of the agreement, Elevar will pay Hengrui Pharma up to $600 million of sales milestones and a double-digit percentage royalty on camrelizumab net sales. The total estimated 10-year payout could be up to $1 billion.',
    'FW Link1':
      'Elevar Therapeutics and Jiangsu Hengrui Pharma Announce Global Commercialization Licensing Agreement for PD-1 Inhibitor Camrelizumab in Combination with Rivoceranib for uHCC;;https://firstwordpharma.com/story/5790743/'
  },
  {
    'Date Announced': '18-Oct-2023',
    'Deal type': 'Marketing Alliance',
    'Acquiring Company/Partner': 'Memorial Sloan Kettering Cancer Center',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'SOPHiA Genetics, AstraZeneca',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Diagnostics, genomic testing, artificial intelligence',
    'Lead Asset(s)': 'MSK-ACCESS, MSK-IMPACT',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "SOPHiA GENETICS (Nasdaq: SOPH), a category-defining software company and a global leader in data-driven medicine announced a collaboration with Memorial Sloan Kettering Cancer Center (MSK), a top cancer treatment and research institution, and AstraZeneca (LSE/STO/Nasdaq: AZN) to bring high quality, comprehensive cancer testing to a global scale. Through the newly announced partnership, SOPHiA GENETICS, MSK, and AstraZeneca will create a decentralized global network for cancer testing, including underserved regions where access to testing remains scarce. The partnership will provide MSK's proprietary liquid biopsy and solid tumor cancer tests – MSK-ACCESS® and MSK-IMPACT® – to organizations via the decentralized, technology-agnostic SOPHiA DDM™ Platform. This offering will be rapidly deployed by SOPHiA GENETICS with support from AstraZeneca's global footprint. Through SOPHiA GENETICS' cloud-based platform, which provides highly accurate and reliable data and insights, institutions around the world – and in traditionally under-resourced and under-served areas – will have the power to make data-driven decisions.",
    'FW Link1':
      'SOPHiA GENETICS Enters New Collaboration with Memorial Sloan Kettering Cancer Center and AstraZeneca to Address Global Inequalities in Comprehensive Cancer Care;;https://www.firstwordhealthtech.com/story/5791088/'
  },
  {
    'Date Announced': '19-Oct-2023',
    'Deal type': 'M&A',
    'Acquiring Company/Partner': 'Ayala',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Biosight',
    'Country (Target Company/Partner)': 'Israel',
    'Region (Target Company/Partner)': 'Middle East',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Chemotherapy',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'aspacytarabine',
    'Indication(s)': 'Acute myeloid leukaemia',
    'Deal Structure':
      'Ayala Pharmaceuticals, Inc. (OTCQX: ADXS), a clinical-stage oncology company, announced the closing of its merger with Biosight, Ltd. (“Biosight”), pursuant to which Ayala acquired Biosight. The combined company will operate under the name Ayala Pharmaceuticals, Inc., and its shares will continue to trade on the OTCQX under Ayala’s current ticker symbol (“ADXS”).',
    'FW Link1':
      'Ayala Pharmaceuticals Announces Closing of Merger with Biosight;;https://firstwordpharma.com/story/5791566/'
  },
  {
    'Date Announced': '19-Oct-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Endeavor BioMedicines',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Hummingbird Bioscience',
    'Country (Target Company/Partner)': 'Singapore',
    'Region (Target Company/Partner)': 'Asia',
    'Total Deal Value ($m)': '430',
    'Geographic Remit of Deal': 'Global',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'HMBD-501',
    'Indication(s)': 'HER3-expressing tumours',
    'Target(s)': 'HER3',
    'Deal Structure':
      'The license agreement provides Endeavor with exclusive rights to HMBD-501, a next-generation HER3-targeted ADC with an exatecan payload that has been optimized for safety and efficacy. Under the terms of the license, Hummingbird Bio will be eligible to receive upfront and milestone payments up to $430 million, plus royalties on net sales.',
    'FW Link1':
      'Endeavor BioMedicines Enters License Agreement with Hummingbird Bioscience for Worldwide Rights to HMBD-501, a Next Generation HER3-Targeted Antibody-Drug Conjugate (ADC);;https://firstwordpharma.com/story/5791355/'
  },
  {
    'Date Announced': '20-Oct-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'GSK',
    'Country (Acquiring Company/Partner)': 'United Kingdom',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Hansoh Pharmaceutical',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '85',
    'Total Deal Value ($m)': '1,570',
    'Geographic Remit of Deal': 'Global excl. China, Hong Kong, Macau, Taiwan',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'HS-20089',
    'Indication(s)': 'Solid tumours, gynaecologic cancers',
    'Target(s)': 'B7-H4',
    'Deal Structure':
      "GSK has reached a deal giving it exclusive worldwide rights to develop and commercialise Hansoh Pharma's B7-H4-targeted antibody-drug conjugate (ADC). Under the terms of this agreement, GSK will pay an $85 million upfront payment. In addition, Hansoh will be eligible to receive up to $1.485 billion in success-based milestones for HS-20089. Upon commercialisation of HS-20089, GSK will also pay tiered royalties on global net sales outside of China’s mainland, Hong Kong, Macau, and Taiwan.",
    'FW Link1':
      'GSK inks licensing deal with Hansoh for ADC targeting B7-H4;;https://firstwordpharma.com/story/5791874/'
  },
  {
    'Date Announced': '19-Oct-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Merck & Co.',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Daiichi Sankyo',
    'Country (Target Company/Partner)': 'Japan',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '4,000',
    'Total Deal Value ($m)': '22,000',
    'Geographic Remit of Deal': 'Global excl. Japan',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)':
      'patritumab deruxtecan, ifinatamab deruxtecan, raludotatug deruxtecan',
    'Indication(s)':
      'Solid tumours, non-small-cell lung cancer, small-cell lung cancer, ovarian cancer',
    'Target(s)': 'HER3, B7-H3, CDH6',
    'Deal Structure':
      "Merck & Co. agreed to pay Daiichi Sankyo $4 billion upfront, in a deal potentially worth as much as $22 billion, to advance three of the Japanese drugmaker's antibody-drug conjugate (ADCs) candidates across multiple tumour types. As part of the agreement, the companies will jointly develop and commercialise patritumab deruxtecan, ifinatamab deruxtecan and raludotatug deruxtecan globally, except for Japan where Daiichi Sankyo has exclusive rights. In addition to the upfront payment, Merck has agreed to pay Daiichi Sankyo $1.5 billion in continuation payments over the next 24 months, and may make additional payments of up to $16.5 billion related to the achievement of future sales milestones, putting the total potential value at up to $22 billion. Daiichi Sankyo will be solely responsible for manufacturing and supply, and it will also generally book worldwide sales.",
    'FW Link1':
      'Merck & Co. shells out $4 billion upfront in mega ADC pact with Daiichi Sankyo;;https://firstwordpharma.com/story/5791694/'
  },
  {
    'Date Announced': '20-Oct-2023',
    'Deal type': 'Product Acquisition',
    'Acquiring Company/Partner': 'Alaya.bio',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Ixaka',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'CAR-T therapies',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Alaya.bio, an in vivo gene delivery biotech company, announces the acquisition of all assets previously owned by Ixaka France, a preclinical-stage immunotherapy biotech company specializing in in vivo CAR T-cell therapy. Alaya.bio intends to leverage its combined assets to significantly simplify the way CAR-T cell therapies are being developed, manufactured and administered. This acquisition accelerates Alaya.bio's programs significantly, providing data showing preclinical proof of concept of the anti-tumoral efficacy of in vivo engineered CAR T cells in immunocompetent mouse models. It includes a broad and robust patent portfolio, preclinical data, and exclusive licenses for polymeric nanoparticle technology. In addition, Alaya.bio obtained a fully equipped laboratory space, and state-of-the-art viral vector bioproduction facilities, optimally positioning Alaya.bio for collaborations and accelerated development of its in vivo gene delivery platform.",
    'FW Link1':
      'Alaya.bio Acquires Key Assets From Ixaka France, Accelerating the Progress of Its Novel In Vivo CAR-T Immunotherapy Platform;;https://firstwordpharma.com/story/5791720/'
  },
  {
    'Date Announced': '23-Oct-2023',
    'Deal type': 'Terminated',
    'Acquiring Company/Partner': 'Merck & Co.',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Kelun-Biotech',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Antibody-drug conjugates',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Sichuan Kelun Pharmaceutical disclosed in a regulatory filing that its partner Merck & Co. decided to discontinue joint development of two preclinical cancer therapies, driving down the Chinese drugmaker's shares by 10% on Monday. The collaboration between Merck and Kelun was hammered out late last year and expanded on an initial alliance centred on the TROP2-targeting ADC candidate MK-2870, also known as SKB-264, with an additional ADC added a few months later. The heavily backloaded deal announced last December came with an upfront payment of $175 million, and had a total value of over $9 billion, to develop seven other preclinical ADCs for cancer. Kelun noted that cooperation with Merck on the other seven ADC candidates – three of which are in clinical trials, including MK-2870 in Phase III – remains unaffected.",
    'FW Link1':
      'Merck & Co. pulls back on some cancer drug development with Kelun;;https://firstwordpharma.com/story/5792445/'
  },
  {
    'Date Announced': '24-Oct-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Ono Pharmaceutical',
    'Country (Acquiring Company/Partner)': 'Japan',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Turbine',
    'Country (Target Company/Partner)': 'United Kingdom',
    'Region (Target Company/Partner)': 'Europe',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Artificial intelligence, cell simulation',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Turbine, a computational biology company that deploys empirically validated in silico cell simulations to guide biopharma R&D, announced that it has entered into a collaboration with Ono Pharmaceutical Co., Ltd (Osaka, Japan, “Ono”) to identify and validate novel targets within one of Ono’s priority domains of cancer biology. Under the terms of the agreement, Turbine will apply its end-to-end, interpretable cell simulation technology, the Simulated Cell™ platform, to the in silico discovery of novel targets for potential therapeutic development. Turbine will also conduct in vitro mechanism validation of identified targets in its state-of-the-art laboratory facility and manage in vivo validation studies. Turbine will receive payments upon execution of the agreement and for the completion of the target identification screen on its platform including in vitro and in vivo validation. Additionally, Turbine will be eligible for milestone payments based on the progress of drug development and commercialization by Ono.',
    'FW Link1':
      'Turbine Establishes Research Collaboration with Ono Pharmaceutical to Identify and Validate Novel Oncology Targets;;https://firstwordpharma.com/story/5793013/'
  },
  {
    'Date Announced': '24-Oct-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Lilly',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Elektrofi',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Upfront Value ($m)': '20',
    'Total Deal Value ($m)': '170',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Drug delivery, biologics',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      'Eli Lilly entered into a research collaboration and license agreement with Elektrofi focused on developing next-generation medicines that can be delivered subcutaneously. Elektrofi’s technology is designed to allow the at-home subcutaneous self-administration of biologic therapies. Under the deal, Eli Lilly will make an upfront payment of $20 million for exclusive rights to three targets. Elektrofi is eligible to receive over $150 million in milestones for each target, as well as tiered sales royalties up to a mid-single digit. Eli Lilly will be responsible for clinical development and commercialisation of each product, while it has the option to expand the agreement to up to two other targets for additional payments.',
    'FW Link1':
      'Lilly buddies up with Elektrofi on subcutaneous delivery of biologics;;https://firstwordpharma.com/story/5792999/'
  },
  {
    'Date Announced': '23-Oct-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Kiromic BioPharma',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Beverly Hills Cancer Center',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'Deltacel',
    'Indication(s)': 'Non-small-cell lung cancer',
    'Deal Structure':
      'Kiromic BioPharma, Inc. (OTC PINK: KRBP) (“Kiromic” or the “Company”), a clinical-stage, fully-integrated biotherapeutics company using its proprietary DIAMOND® artificial intelligence and data mining platform to develop cell therapies with a focus on immuno-oncology, announces the execution of a clinical trial agreement with Beverly Hills Cancer Center (BHCC) to conduct its Deltacel-01 Phase 1 Study. Pursuant to the agreement, BHCC, one of Southern California’s esteemed private cancer centers, is anticipated to serve as the inaugural clinical site for the evaluation of Deltacel™ (KB-GDT-01), Kiromic’s allogeneic, off-the-shelf, Gamma Delta T-cell (GDT) therapy, in patients with non-small cell lung cancer (NSCLC).',
    'FW Link1':
      'Kiromic BioPharma and Beverly Hills Cancer Center Enter Clinical Trial Agreement;;https://firstwordpharma.com/story/5792706/'
  },
  {
    'Date Announced': '24-Oct-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Moderna',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Caris Life Sciences',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Clinico-genomics, artificial intelligence, mRNA medicines',
    'Indication(s)': 'Cancer',
    'Deal Structure':
      "Through this multi-year agreement, Moderna will leverage Caris' vast library of de-identified, multi-modal data solutions derived from whole exome sequencing, whole transcriptome sequencing and protein analyses along with claims data to enhance development strategies for Moderna's oncology pipeline, including facilitation of optimal clinical trial design, discovery of novel biomarkers and characterization of resistance mechanisms.",
    'FW Link1':
      'Caris Life Sciences and Moderna Announce Multi-Year Strategic Partnership to Advance mRNA-Based Oncology Therapeutics;;https://firstwordpharma.com/story/5793084/'
  },
  {
    'Date Announced': '25-Oct-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Medigene',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'AGC Biologics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Cell therapies',
    'Lead Asset Stage/Phase': 'Preclinical',
    'Lead Asset(s)': 'MDG1015',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'NY-ESO-1/LAGE-1a',
    'Deal Structure':
      'AGC Biologics, a leading global Biopharmaceutical Contract Development and Manufacturing Organization (CDMO), announced a new service agreement with Medigene AG (Medigene, the “Company”, FSE: MDG1, Prime Standard). Under the agreement, AGC Biologics is providing autologous production for a next-generation-therapy product focused on the treatment of solid cancers, supporting Medigene’s IND filing and clinical trials.',
    'FW Link1':
      'AGC Biologics Signs TCR-T Cell Services Agreement to Support Medigene’s New Cell Therapy Product;;https://firstwordpharma.com/story/5793544/'
  },
  {
    'Date Announced': '26-Oct-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Unravel Biosciences',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'MeCo Diagnostics',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Biomarkers, artificial intelligence, precision medicines',
    'Lead Asset Stage/Phase': 'Discovery',
    'Indication(s)': 'Prostate cancer',
    'Deal Structure':
      'MeCo Diagnostics Holdings, Inc. (“MeCo”) and Unravel Biosciences, Inc. (“Unravel”) are partnering to leverage their highly complementary platforms to rapidly enhance the response rate of prostate cancer patients through stratification biomarkers combined with novel therapeutics. They will combine AI-enabled discovery with proprietary datasets to generate highly predictive biomarkers of treatment response and neoadjuvant therapies.',
    'FW Link1':
      'MeCo Diagnostics and Unravel Biosciences announce a prostate cancer biomarker and drug discovery partnership;;https://firstwordpharma.com/story/5794410/'
  },
  {
    'Date Announced': '26-Oct-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Georgiamune',
    'Country (Acquiring Company/Partner)': 'United States',
    'Region (Acquiring Company/Partner)': 'North America',
    'Target Company/Partner': 'Verily',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Real-world data, immune mapping, biomarkers, antibodies',
    'Lead Asset Stage/Phase': 'Phase I',
    'Lead Asset(s)': 'GIM-122',
    'Indication(s)': 'Solid tumours',
    'Deal Structure':
      'Georgiamune, a privately held, clinical stage biotechnology company, and Verily, an Alphabet precision health technology company, announced a strategic partnership to advance novel therapeutics for patients with cancer. The partnership will focus on deploying Verily’s advanced molecular and real-world evidence solutions with the intention to support the development of Georgiamune’s therapeutic candidates through the discovery of predictive and outcome biomarkers. The two companies will also focus on driving more efficient clinical development by incorporating clinical trial data and longitudinal real-world data sources.',
    'FW Link1':
      'Georgiamune and Verily Announce Strategic Partnership to Advance Novel Cancer Treatment;;https://firstwordpharma.com/story/5794413/'
  },
  {
    'Date Announced': '27-Oct-2023',
    'Deal type': 'Licensing',
    'Acquiring Company/Partner': 'Intas',
    'Country (Acquiring Company/Partner)': 'India',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'Henlius Biotech',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Upfront Value ($m)': '45',
    'Total Deal Value ($m)': '196',
    'Geographic Remit of Deal': 'Europe, India',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Monoclonal antibodies',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'serplulimab',
    'Indication(s)': 'Small cell lung cancer',
    'Target(s)': 'PD-1',
    'Deal Structure':
      'Shanghai Henlius Biotech, Inc. (2696.HK) has entered into an exclusive license agreement with Intas Pharmaceuticals Limited ("Intas") for the development and commercialisation in Europe and India for several indications including ES-SCLC, and specific formulation of HANSIZHUANG (serplulimab injection), Henlius\' novel anti-PD-1 mAb. Under the terms of the agreement, Henlius will be responsible for clinical development, manufacturing and supply upon launch and will receive a €42 million upfront payment, up to €43 million in regulatory milestones, up to €100 million in commercial sales milestones, and double-digit royalties on net profit from Intas in the licensed territory.',
    'FW Link1':
      "Henlius Deepens Collaboration with Intas to bring Henlius' Novel anti-PD-1 mAb Serplulimab to Europe and India;;https://firstwordpharma.com/story/5794555/"
  },
  {
    'Date Announced': '27-Oct-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Lunit',
    'Country (Acquiring Company/Partner)': 'Korea, South',
    'Region (Acquiring Company/Partner)': 'Asia',
    'Target Company/Partner': 'University of Texas MD Anderson Cancer Center',
    'Country (Target Company/Partner)': 'United States',
    'Region (Target Company/Partner)': 'North America',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology':
      'Artificial intelligence, immunotherapy, biomarkers',
    'Lead Asset Stage/Phase': 'Marketed',
    'Lead Asset(s)': 'Keytruda',
    'Indication(s)': 'Cancer',
    'Target(s)': 'PD-1',
    'Deal Structure':
      "Lunit (KRX:328130.KQ), a leading provider of AI-powered solutions for cancer diagnostics and therapeutics, announced a research collaboration with The University of Texas MD Anderson Cancer Center to analyze the use of Merck's (known as MSD outside the US and Canada) Keytruda (pembrolizumab), a type of immunotherapy drug used in cancer treatment, in multiple cancer types. The project will use Lunit's proprietary AI solution for tissue data analysis, Lunit SCOPE IO.",
    'FW Link1':
      'Lunit Enters into Research Collaboration to Explore the Use of AI to Improve the Effectiveness of Immunotherapy;;https://www.firstwordhealthtech.com/story/5794596/'
  },
  {
    'Date Announced': '30-Oct-2023',
    'Deal type': 'R&D Alliance',
    'Acquiring Company/Partner': 'Boehringer Ingelheim',
    'Country (Acquiring Company/Partner)': 'Germany',
    'Region (Acquiring Company/Partner)': 'Europe',
    'Target Company/Partner': 'Burning Rock',
    'Country (Target Company/Partner)': 'China',
    'Region (Target Company/Partner)': 'Asia',
    'Geographic Remit of Deal': 'China',
    'Therapy Area(s)': 'Oncology',
    'Drug Modality/Technology': 'Companion diagnostics, small molecules',
    'Lead Asset Stage/Phase': 'Phase II',
    'Lead Asset(s)': 'brigimadlin',
    'Indication(s)': 'Solid tumours',
    'Target(s)': 'MDM2-p53',
    'Deal Structure':
      "Burning Rock Biotech Limited (NASDAQ: BNR and LSE: BNR, the “Company” or “Burning Rock”) is pleased to announce that the Company and Boehringer Ingelheim officially have signed the Master Service Agreement (MSA) in the field of oncology companion diagnostics. This agreement aims to provide Chinese patients with safer, more efficient, and precise treatment options and diagnostic methods, thereby promoting innovation and development in cancer treatment. This partnership will primarily focus on advancing the clinical trials related to Boehringer Ingelheim's MDM2-p53 antagonist, brigimadlin (BI 907828), and the development of companion diagnostic products in China.",
    'FW Link1':
      'Burning Rock and Boehringer Ingelheim Achieved a Master Service Agreement in Oncology Companion Diagnostics;;https://www.firstwordhealthtech.com/story/5795133/'
  }
]
