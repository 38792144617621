import { connect } from 'react-redux'
import {
  requestInsertInterest,
  requestDeleteInterest
} from 'src/modules/MyInterests/reducer.js'
import { paywallRedirectFlow } from 'src/modules/Benefits/reducer.js'
import { treeToArray } from 'src/utils/index.js'
import _get from 'lodash/get'
import RiverHeader from 'src/modules/River/components/RiverHeader'

const mapStateToProps = state => ({
  userInterests: treeToArray(
    _get(state.session.user, 'interests.stated', {}),
    (category, tag) => ({
      category,
      tag
    })
  )
})

const mapDispatchToProps = dispatch => ({
  requestAddInterestToUser: interest =>
    dispatch(requestInsertInterest(interest)),
  requestDeleteInterestToUser: interest =>
    dispatch(requestDeleteInterest(interest)),
  paywallRedirection: data => dispatch(paywallRedirectFlow(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(RiverHeader)
