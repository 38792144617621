import { takeEvery, takeLatest, call, all, put } from 'redux-saga/effects'
import { actions } from './reducer.js'
import { notificationsEnqueue } from 'src/modules/Notifications/reducer.js'
import { logPush } from 'src/modules/ContactUs/reducer.js'
import { notificationDuration as duration } from 'src/constants/index.js'
import { logout } from 'src/modules/Session/actions'
import FW5MLError from 'src/services/FW5ML/FW5MLError.js'
import { ACCESS_DENIED } from 'src/services/FW5ML/errorCodes.js'

import { licenseTypes } from 'src/services/FW5ML/parsers/UserParser.js'
import { setNewsletterSettings } from 'src/modules/MyNewsletters/reducer.js'
import { actions as paywallActions } from 'src/modules/Paywall/slice.js'
import { siteName } from 'src/utils/oneCodeBase.js'

//TODO: rework this entire component file it gives me pain anytime I see it
function* listNewsletters(services, action) {
  const NewslettersRepository = services('NewslettersRepository')
  const Session = services('Session')
  const Piwik = services('Piwik')
  try {
    const user = yield call([Session, 'userLoad'])
    if (!user)
      throw new Error('Please sign in to get your available newsletters')
    const data = yield call(
      [NewslettersRepository, 'getNewslettersByRole'],
      user.license
    )
    yield put(actions.successListNewsletters(data))
  } catch (e) {
    if (e instanceof FW5MLError) {
      const code = e.getCode()
      if (code === ACCESS_DENIED) {
        yield put(logout({ reload: false, redirect: '/sign-in' }))
        yield put(
          notificationsEnqueue({
            message: 'Please sign in and try again.'
          })
        )
        return
      }
    }
    yield put(
      logPush(
        'Newsletters List',
        `Error while requesting Newsletters: ${e.message}`
      )
    )
    yield put(
      notificationsEnqueue({
        message: `Unable to load your newsletters. Please refresh the page and try again.`,
        duration
      })
    )
    yield call(
      [Piwik, 'track'],
      'notification',
      'error',
      'could-not-load-newsletters'
    )
    yield put(actions.errorListNewsletters(e.message))
  }
}

function* listAllNewsletters(services, action) {
  const NewslettersRepository = services('NewslettersRepository')
  const Session = services('Session')
  const Piwik = services('Piwik')
  try {
    const user = yield call([Session, 'userLoad'])
    if (!user)
      throw new Error('Please sign in to get your available newsletters')
    const data = yield call([NewslettersRepository, 'getNewsletters'])
    yield put(actions.successListAllNewsletters(data))
  } catch (e) {
    console.log(e)
    if (e instanceof FW5MLError) {
      const code = e.getCode()
      if (code === ACCESS_DENIED) {
        yield put(logout({ reload: false, redirect: '/sign-in' }))
        yield put(
          notificationsEnqueue({
            message: 'Please sign in and try again.'
          })
        )
        return
      }
    }
    yield put(
      logPush(
        'Newsletters List',
        `Error while requesting Newsletters: ${e.message}`
      )
    )
    yield put(
      notificationsEnqueue({
        message: `Unable to load all newsletters. Please refresh the page and try again.`,
        duration
      })
    )
    yield call(
      [Piwik, 'track'],
      'notification',
      'error',
      'could-not-load-newsletters'
    )
    yield put(actions.errorListAllNewsletters(e.message))
  }
}

function* getRegion(services, action) {
  const UserRepository = services('UserRepository')
  const Session = services('Session')

  const siteId = process.env.REACT_APP_SITE_ID

  try {
    const user = yield call([Session, 'userLoad'])
    if (!user)
      throw new Error('Please sign in to get the region of the country')
    const data = yield call(
      [UserRepository, 'getRegionByCountry'],
      user?.address?.country ?? '',
      siteId
    )
    yield put(actions.successGetRegionByCountry(data))
  } catch (e) {
    console.log(e)
    if (e instanceof FW5MLError) {
      const code = e.getCode()
      if (code === ACCESS_DENIED) {
        yield put(logout({ reload: false, redirect: '/sign-in' }))
        yield put(
          notificationsEnqueue({
            message: 'Please sign in and try again.'
          })
        )
        return
      }
    }
    yield put(
      logPush('Regions List', `Error while requesting Region: ${e.message}`)
    )
    yield put(
      notificationsEnqueue({
        message: `Unable to load the Region. Please refresh the page and try again.`,
        duration
      })
    )
    yield put(actions.errorGetRegionByCountry(e.message))
  }
}

function* listMyNewsletters(services, action) {
  const UserRepository = services('UserRepository')
  const Piwik = services('Piwik')
  try {
    const newsletters = yield call([UserRepository, 'getNewsletters'])
    yield put(actions.successListSubscribedNewsletters(newsletters))
  } catch (e) {
    if (e instanceof FW5MLError) {
      const code = e.getCode()
      if (code === ACCESS_DENIED) {
        yield put(logout({ reload: false, redirect: '/sign-in' }))
        yield put(
          notificationsEnqueue({
            message: 'Please sign in and try again.'
          })
        )
        return
      }
    }
    yield put(
      logPush(
        'Newsletters List',
        `Error while requesting Newsletters: ${e.message}`
      )
    )
    yield put(
      notificationsEnqueue({
        message: `Unable to load your newsletters. Please refresh the page and try again.`,
        duration
      })
    )
    yield call(
      [Piwik, 'track'],
      'notification',
      'error',
      "could-not-load-user's-newsletters"
    )
    yield put(actions.errorListSubscribedNewsletters(e.message))
  }
}

function* insertNewsletters(services, action) {
  const UserRepository = services('UserRepository')
  const Piwik = services('Piwik')

  const { payload: newsletters } = action
  try {
    let ids = []

    for (let nl of newsletters) {
      ids = [...ids, nl.id]
    }

    yield call([UserRepository, 'insertNewsletter'], ids)

    // Save profile newsletter settings
    const { data: profile } = yield call([UserRepository, 'getProfile'])
    if (profile.newsletter_settings) {
      yield put(
        setNewsletterSettings({
          newsletterSettings: profile.newsletter_settings
        })
      )
    }
    for (let nl of newsletters) {
      yield put(actions.successInsertNewsletters(nl))
    }
    for (let id of ids) {
      yield call([Piwik, 'track'], 'account', 'update', 'insert-newsletter', {
        id
      })
    }
  } catch (e) {
    console.log(e)
    if (e instanceof FW5MLError) {
      const code = e.getCode()
      if (code === ACCESS_DENIED) {
        yield put(logout({ reload: false, redirect: '/sign-in' }))
        yield put(
          notificationsEnqueue({
            message: 'Please sign in and try again.'
          })
        )
        return
      }
    }
    let ids, name
    for (let nl of newsletters) {
      ids.push(nl.id)
      name.push(nl.name)
    }
    yield put(
      logPush(
        'Account Update',
        `Error while deleting newsletter in: ${e.message}`
      )
    )
    yield put(
      notificationsEnqueue({
        message: `Unable to add ${name} to your newsletters. Please refresh the page and try again.`,
        duration
      })
    )
    yield call(
      [Piwik, 'track'],
      'notification',
      'error',
      `could-not-add-${name}-to-user's-newsletters`
    )
    yield put(actions.errorInsertNewsletters({ ids, error: e.message }))
  }
}

function* insertNewsletter(services, action) {
  const UserRepository = services('UserRepository')
  const Piwik = services('Piwik')
  const { payload: newsletter } = action

  const Session = services('Session')

  try {
    // check user subsciption
    // block them if plusOnly and they are free
    // we need to show paywall
    //
    const { id, name, plusOnly } = newsletter
    const user = yield call([Session, 'userLoad'])
    const { license } = user

    if (licenseTypes.LICENSE_PLUS !== license && plusOnly) {
      // yeild put

      yield put(
        paywallActions.updatePaywallVisible({
          visible: true,
          type: 'plus_feature',
          text: `${name} is exclusive to FirstWord ${siteName}+ subscribers!`,
          subtype: 'plus_newsletter'
        })
      )

      yield put(actions.errorInsertNewsletter({ id }))
      return
    }
    yield call([UserRepository, 'insertNewsletter'], id)

    // Save profile newsletter settings
    const { data: profile } = yield call([UserRepository, 'getProfile'])
    if (profile.newsletter_settings) {
      yield put(
        setNewsletterSettings({
          newsletterSettings: profile.newsletter_settings
        })
      )
    }

    yield put(actions.successInsertNewsletter(newsletter))
    yield call([Piwik, 'track'], 'account', 'update', 'insert-newsletter', {
      id
    })
  } catch (e) {
    console.log(e)
    if (e instanceof FW5MLError) {
      const code = e.getCode()
      if (code === ACCESS_DENIED) {
        yield put(logout({ reload: false, redirect: '/sign-in' }))
        yield put(
          notificationsEnqueue({
            message: 'Please sign in and try again.'
          })
        )
        return
      }
    }
    const { id, name } = newsletter
    yield put(
      logPush(
        'Account Update',
        `Error while deleting newsletter in: ${e.message}`
      )
    )
    yield put(
      notificationsEnqueue({
        message: `Unable to add ${name} to your newsletters. Please refresh the page and try again.`,
        duration
      })
    )
    yield call(
      [Piwik, 'track'],
      'notification',
      'error',
      `could-not-add-${name}-to-user's-newsletters`
    )
    yield put(actions.errorInsertNewsletter({ id, error: e.message }))
  }
}

function* deleteNewsletter(services, action) {
  const UserRepository = services('UserRepository')
  const Piwik = services('Piwik')
  const { payload: newsletter } = action
  try {
    const { id } = newsletter
    yield call([UserRepository, 'deleteNewsletter'], id)

    // Save profile newsletter settings
    const { data: profile } = yield call([UserRepository, 'getProfile'])
    if (profile.newsletter_settings) {
      yield put(
        setNewsletterSettings({
          newsletterSettings: profile.newsletter_settings
        })
      )
    }

    yield put(actions.successDeleteNewsletter(newsletter))
    yield call([Piwik, 'track'], 'account', 'update', 'remove-newsletter', {
      id
    })
  } catch (e) {
    console.log(e)
    if (e instanceof FW5MLError) {
      const code = e.getCode()
      if (code === ACCESS_DENIED) {
        yield put(logout({ reload: false, redirect: '/sign-in' }))
        yield put(
          notificationsEnqueue({
            message: 'Please sign in and try again.'
          })
        )
        return
      }
    }
    const { id, name } = newsletter
    yield put(
      logPush(
        'Account Update',
        `Error while deleting newsletter in: ${e.message}`
      )
    )
    yield put(
      notificationsEnqueue({
        message: `Unable to remove ${name} from your newsletters. Please refresh the page and try again.`,
        duration
      })
    )
    yield call(
      [Piwik, 'track'],
      'notification',
      'error',
      `could-not-remove-${name}-from-user's-newsletters`
    )
    yield put(actions.errorDeleteNewsletter({ id, error: e.message }))
  }
}

function* udpateDeliveryTime(services, action) {
  const UserRepository = services('UserRepository')
  const Piwik = services('Piwik')
  const { payload: newsletter } = action
  try {
    const { newsletterUuid, deliveryTime } = newsletter
    yield call(
      [UserRepository, 'updateDeliveryTime'],
      newsletterUuid,
      deliveryTime
    )
    yield put(actions.successUpdateDeliveryTime(newsletter))
    yield put(actions.closeUpdateDeliveryTime(newsletterUuid))
    yield call([Piwik, 'track'], 'account', 'update', 'delivery-time', {
      newsletterUuid,
      deliveryTime
    })
    yield put(
      notificationsEnqueue({
        message: 'Delivery time updated successfully.',
        duration
      })
    )
  } catch (e) {
    console.log(e)
    if (e instanceof FW5MLError) {
      const code = e.getCode()
      if (code === ACCESS_DENIED) {
        yield put(logout({ reload: false, redirect: '/sign-in' }))
        yield put(
          notificationsEnqueue({
            message: 'Please sign in and try again.'
          })
        )
        return
      }
    }
    const { name } = newsletter
    yield put(
      logPush(
        'Account Update',
        `Error while updating delivery time in: ${e.message}`
      )
    )
    yield put(
      notificationsEnqueue({
        message: `Unable to update ${name} delivery time. Please refresh the page and try again.`,
        duration
      })
    )
    yield call(
      [Piwik, 'track'],
      'notification',
      'error',
      `could-not-update-${name}-delivery-time`
    )
    yield put(actions.errorUpdateDeliveryTime({ error: e.message }))
  }
}

export default function* watchUpdate(services) {
  yield all([
    takeEvery('ACCOUNT_LIST_NEWSLETTER__REQUEST', listNewsletters, services),
    takeLatest(
      'ACCOUNT_LIST_ALL_NEWSLETTER__REQUEST',
      listAllNewsletters,
      services
    ),
    takeEvery(
      'ACCOUNT_LIST_SUBSCRIBED_NEWSLETTER__REQUEST',
      listMyNewsletters,
      services
    ),
    takeEvery('ACCOUNT_INSERT_NEWSLETTER__REQUEST', insertNewsletter, services),
    takeEvery('ACCOUNT_DELETE_NEWSLETTER__REQUEST', deleteNewsletter, services),
    takeEvery(
      'ACCOUNT_INSERT_NEWSLETTERS__REQUEST',
      insertNewsletters,
      services
    ),
    takeEvery(
      'ACCOUNT_UPDATE_DELIVERY_TIME__REQUEST',
      udpateDeliveryTime,
      services
    ),
    takeEvery('ACCOUNT_GET_REGION_BY_COUNTRY__REQUEST', getRegion, services)
  ])
}
