import React from 'react'
import Page from 'src/components/Page/index.js'
import sharedStyles from 'src/modules/Static/Static.module.sass'
import styles from 'src/modules/Static/components/MarketingSpecsPage/MarketingSpecsPage.module.sass'
import Typography from 'src/components/ui/Typography/index.js'
import { HashLink } from 'react-router-hash-link'
import imagery from 'src/modules/Static/components/MarketingSpecsPage/marketingSpecsMapping'
import Metadata from 'src/components/Metadata/index.js'
import FullImage from 'src/components/FullImage/index.js'
import SectionHeading from 'src/components/SectionHeading/index.js'
import cx from 'classnames'

const MarketingSpecsPage = () => {
  const getImage = type => {
    //Return an image from the set
    if (imagery[type]) return imagery[type]
    //Return one image from the set as default
    return imagery['DisplayAds']
  }
  return (
    <Page className={cx(sharedStyles.static, styles.marketingSpecsPage)}>
      <Metadata title="Marketing Solutions Specifications" />
      <SectionHeading
        title="Marketing Solutions Specifications"
        gutterBottom
        paragraph
      />
      <div className={styles.section}>
        <Typography
          type="body1"
          className={cx(styles.body, styles.paragraph)}
          align="center"
        >
          Specifications for FirstWord’s marketing solutions are below. For
          questions, please contact{' '}
          <a
            className={styles.link}
            href="mailto:advertising@firstwordpharma.com"
          >
            advertising@firstwordpharma.com
          </a>
          .
        </Typography>
        <div className={styles.solutionsContainer}>
          <HashLink smooth to="#displayAds-scroll">
            <FullImage className={styles.image} src={getImage('DisplayAds')} />
          </HashLink>
          <HashLink smooth to="#newsletters-scroll">
            <FullImage className={styles.image} src={getImage('Newsletters')} />
          </HashLink>
          <HashLink smooth to="#customEBlast-scroll">
            <FullImage
              className={styles.image}
              src={getImage('CustomEBlast')}
            />
          </HashLink>
          <HashLink smooth to="#eventSponsor-scroll">
            <div id="displayAds-scroll"></div>
            <FullImage
              className={styles.image}
              src={getImage('EventSponsor')}
            />
          </HashLink>
        </div>
      </div>
      <div>
        <div className={styles.section}>
          <header className={styles.header}>
            <Typography
              className={cx(styles.title, styles.orange)}
              type="title"
            >
              Web Display Ads
            </Typography>
          </header>
          <Typography
            type="body1"
            className={cx(styles.body, styles.paragraph)}
          >
            <b>Submitting Creative Materials:</b> Creative is due 5 business
            days prior to going live.
          </Typography>
          <Typography
            type="body1"
            className={cx(styles.body, styles.paragraph)}
          >
            When submitting creative, please include the following items:
            <ul className={styles.noMargin}>
              <li>Image or Animated Image</li>
              <li>Redirect URL</li>
            </ul>
          </Typography>
        </div>
        <div className={styles.subwrapper}>
          <div className={styles.section}>
            <Typography type="title" className={styles.subheader}>
              Medium Rectangle
            </Typography>
            <div className={styles.itemImgContainer}>
              <FullImage
                className={styles.image}
                src={getImage('MediumRectangle')}
              />
            </div>
            <div>
              <Typography type="body1" className={styles.body}>
                <b>Dimensions:</b> 300px x 250px
              </Typography>
              <Typography type="body1" className={styles.body}>
                <b>File Formats:</b> GIF, JPG/JPEG, 24-bit PNG
              </Typography>
              <Typography type="body1" className={styles.body}>
                <b>Max File Size:</b> 150 kB
              </Typography>
            </div>
          </div>
          <div className={styles.section}>
            <Typography type="title" className={styles.subheader}>
              Leaderboard
            </Typography>
            <div className={styles.itemImgContainer}>
              <FullImage
                className={styles.image}
                src={getImage('Leaderboard')}
              />
            </div>
            <div>
              <Typography type="body1" className={styles.body}>
                <b>Dimensions:</b> 728px x 90px
              </Typography>
              <Typography type="body1" className={styles.body}>
                <b>File Formats:</b> GIF, JPG/JPEG, 24-bit PNG
              </Typography>
              <Typography type="body1" className={styles.body}>
                <b>Max File Size:</b> 150 kB
              </Typography>
            </div>
          </div>
          <div className={styles.section}>
            <Typography type="title" className={styles.subheader}>
              Enhanced Billboard
            </Typography>
            <div className={styles.itemImgContainer}>
              <FullImage className={styles.image} src={getImage('Enhanced')} />
            </div>
            <div>
              <Typography type="body1" className={styles.body}>
                <b>Dimensions:</b> 970px x 250px
              </Typography>
              <Typography type="body1" className={styles.body}>
                <b>File Formats:</b> GIF, JPG/JPEG, 24-bit PNG
              </Typography>
              <Typography type="body1" className={styles.body}>
                <b>Max File Size:</b> 150 kB
              </Typography>
            </div>
          </div>
          <div className={styles.section}>
            <Typography type="title" className={styles.subheader}>
              Advertorial
            </Typography>
            <div className={styles.itemImgContainer}>
              <FullImage
                className={styles.image}
                src={getImage('AdvertorialWebsite')}
              />
            </div>
            <div>
              <Typography type="body1" className={styles.body}>
                <b>Logo:</b> 100px width max
              </Typography>
              <Typography type="body1" className={styles.body}>
                <b>Headline:</b> 50 characters max
              </Typography>
              <div id="newsletters-scroll"></div>
              <Typography type="body1" className={styles.body}>
                <b>Body:</b> 150 characters max
              </Typography>
              <Typography type="body1" className={styles.body}>
                <i>*Character counts include spaces</i>
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className={styles.section}>
          <header className={styles.header}>
            <Typography
              className={cx(styles.title, styles.orange)}
              type="title"
            >
              Newsletters
            </Typography>
          </header>
          <Typography
            type="body1"
            className={cx(styles.body, styles.paragraph)}
          >
            <b>Submitting Creative Materials:</b> Creative is due 5 business
            days prior to going live.
          </Typography>
          <Typography
            type="body1"
            className={cx(styles.body, styles.paragraph)}
          >
            When submitting creative, please include the following items:
            <ul className={styles.noMargin}>
              <li>Image or Ad Copy</li>
              <li>Redirect URL</li>
            </ul>
          </Typography>
        </div>
        <div className={styles.subwrapper}>
          <div className={styles.section}>
            <Typography type="title" className={styles.subheader}>
              Display Banner
            </Typography>
            <div className={styles.itemImgContainer}>
              <FullImage
                className={styles.image}
                src={getImage('NewsletterBanner')}
              />
            </div>
            <div>
              <Typography type="body1" className={styles.body}>
                <b>Dimensions:</b> 600px x 100px
              </Typography>
              <Typography type="body1" className={styles.body}>
                <b>File Formats:</b> GIF, JPG/JPEG, 24-bit PNG
              </Typography>
              <Typography type="body1" className={styles.body}>
                <b>Max File Size:</b> 150 kB
              </Typography>
            </div>
          </div>
          <div className={styles.section}>
            <Typography type="title" className={styles.subheader}>
              Advertorial
            </Typography>
            <div className={styles.itemImgContainer}>
              <FullImage
                className={styles.image}
                src={getImage('AdvertorialNewsletter')}
              />
            </div>
            <div>
              <Typography type="body1" className={styles.body}>
                <b>Logo:</b> 100px width max
              </Typography>
              <Typography type="body1" className={styles.body}>
                <b>Headline:</b> 50 characters max
              </Typography>
              <Typography type="body1" className={styles.body}>
                <b>Body:</b> 150 characters max
              </Typography>
              <Typography type="body1" className={styles.body}>
                <i>*Character counts include spaces</i>
              </Typography>
            </div>
          </div>
          <div className={styles.section}>
            <Typography type="title" className={styles.subheader}>
              Sponsored Headline
            </Typography>
            <div className={styles.itemImgContainer}>
              <FullImage className={styles.image} src={getImage('Sponsored')} />
            </div>
            <div id="customEBlast-scroll"></div>
            <div>
              <Typography type="body1" className={styles.body}>
                <b>Headline:</b> 100 characters max
              </Typography>
              <Typography type="body1" className={styles.body}>
                <i>*Character counts include spaces</i>
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className={styles.section}>
          <header className={styles.header}>
            <Typography
              className={cx(styles.title, styles.orange)}
              type="title"
            >
              Custom eBlasts
            </Typography>
          </header>
          <Typography
            type="body1"
            className={cx(styles.body, styles.paragraph)}
          >
            <b>Submitting Creative Materials:</b> Creative is due 5 business
            days prior to the email being deployed.
          </Typography>
          <Typography
            type="body1"
            className={cx(styles.body, styles.paragraph)}
          >
            When submitting creative, please include the following items:
            <ul className={styles.noMargin}>
              <li>HTML File</li>
              <li>Subject Line</li>
              <li>Preferred send day and time</li>
            </ul>
          </Typography>
        </div>
        <div className={styles.subwrapper}>
          <div className={styles.section}>
            <Typography type="body1" className={styles.body}>
              <b>File Format: </b> HTML
            </Typography>
            <Typography type="body1" className={styles.body}>
              <b>Dimensions:</b> 600px width max
            </Typography>
            <Typography type="body1" className={styles.body}>
              <b>Max File Size:</b> 300k width
            </Typography>
            <Typography type="body1" className={styles.body}>
              <b>Images:</b> All images must be hosted by the client
            </Typography>
            <div id="eventSponsor-scroll"></div>
          </div>
        </div>
        <Typography type="body1" className={cx(styles.body, styles.paragraph)}>
          <i>
            *Please do not include any unsubscribe verbiage or links, this will
            be added by FirstWord
          </i>
        </Typography>
      </div>
      <div>
        <div className={styles.section}>
          <header className={styles.header}>
            <Typography
              className={cx(styles.title, styles.orange)}
              type="title"
            >
              Event Sponsor
            </Typography>
          </header>
          <Typography
            type="body1"
            className={cx(styles.body, styles.paragraph)}
          >
            <b>Submitting Creative Materials:</b> Creative is due 5 business
            days prior to the event going live.
          </Typography>
          <Typography
            type="body1"
            className={cx(styles.body, styles.paragraph)}
          >
            When submitting creative, please include the following items:
            <ul className={styles.noMargin}>
              <li>Image Files</li>
              <li>Redirect URL</li>
              <li>MP4 File</li>
            </ul>
          </Typography>
        </div>
        <div className={styles.subwrapper}>
          <div className={styles.section}>
            <Typography type="title" className={styles.subheader}>
              Logo
            </Typography>
            <div>
              <Typography type="body1" className={styles.body}>
                <b>Dimensions:</b> 600px x 600px
              </Typography>
              <Typography type="body1" className={styles.body}>
                <b>File Formats:</b> GIF, JPG/JPEG, 24-bit PNG
              </Typography>
              <Typography type="body1" className={styles.body}>
                <b>Max File Size:</b> 150 kB
              </Typography>
            </div>
          </div>
          <div className={styles.section}>
            <Typography type="title" className={styles.subheader}>
              Banner Ad
            </Typography>
            <div>
              <Typography type="body1" className={styles.body}>
                <b>Dimensions:</b> 1280px x 1280px
              </Typography>
              <Typography type="body1" className={styles.body}>
                <b>File Formats:</b> GIF, JPG/JPEG, 24-bit PNG
              </Typography>
              <Typography type="body1" className={styles.body}>
                <b>Max File Size:</b> 150 kB
              </Typography>
            </div>
          </div>
          <div className={styles.section}>
            <Typography type="title" className={styles.subheader}>
              On-Air/On-Screen Video
            </Typography>
            <div>
              <Typography type="body1" className={styles.body}>
                <b>Max Length:</b> 15 seconds
              </Typography>
              <Typography type="body1" className={styles.body}>
                <b>File Format:</b> MP4
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Typography
          type="body1"
          className={cx(styles.body, styles.paragraph)}
          align="center"
        >
          For any questions or to request examples, please contact{' '}
          <a
            className={styles.link}
            href="mailto:advertising@firstwordpharma.com"
          >
            advertising@firstwordpharma.com
          </a>
          .
        </Typography>
      </div>
    </Page>
  )
}

export default MarketingSpecsPage
