import React, { Fragment } from 'react'
import { takeEvery, put, all, call } from 'redux-saga/effects'
import { reset } from 'redux-form'
import { reauthAndRetryCall } from 'src/utils/sagaHelpers.js'
import { actions } from './reducer.js'
import { notificationsEnqueue } from 'src/modules/Notifications/reducer.js'

function* sendContactUs(services, { payload }) {
  const ContactUsRepository = services('ContactUsRepository')
  const Piwik = services('Piwik')
  const Session = services('Session')
  const deviceId = yield call([Session, 'getCookie'], 'PSL_DEVICE_ID')
  const user = yield call([Session, 'userLoad'])

  const { message } = payload
  try {
    yield call([Piwik, 'track'], 'button', 'submit', 'contact-us')
    yield reauthAndRetryCall(services, [ContactUsRepository, 'sendMessage'], {
      ...payload,
      message,
      deviceId,
      user
    })
    yield put(actions.logClear())
    yield put(actions.successSendMessage())
    yield put(reset('contact-us'))
    yield put(notificationsEnqueue({ message: 'Your message has been sent.' }))
    return true
  } catch (e) {
    yield put(actions.errorSendMessage(e.message))
    yield put(
      notificationsEnqueue({
        message: (
          <Fragment>
            Unable to submit contact form. Email us at{' '}
            <a href="mailto:customer.service@firstwordpharma.com">
              customer.service@firstwordpharma.com
            </a>
          </Fragment>
        )
      })
    )
    yield call(
      [Piwik, 'track'],
      'notification',
      'error',
      'could-not-submit-contact-form'
    )
  }
}

function* sendContactToConsultant(services, { payload }) {
  const ContactUsRepository = services('ContactUsRepository')
  const Piwik = services('Piwik')
  const { hideNotifications = false } = payload

  try {
    yield reauthAndRetryCall(
      services,
      [ContactUsRepository, 'sendZohoRequest'],
      {
        ...payload
      }
    )
    yield put(actions.logClear())
    yield put(actions.successSendMessage())
    yield put(reset('contact-us'))
    if (!hideNotifications) {
      yield put(
        notificationsEnqueue({ message: 'Your message has been sent.' })
      )
    }
    return true
  } catch (e) {
    yield put(actions.errorSendMessage(e.message))
    yield put(
      notificationsEnqueue({
        message: (
          <Fragment>
            Unable to submit contact form. Email us at{' '}
            <a href="mailto:customer.service@firstwordpharma.com">
              customer.service@firstwordpharma.com
            </a>
          </Fragment>
        )
      })
    )
    yield call(
      [Piwik, 'track'],
      'notification',
      'error',
      'could-not-submit-contact-form'
    )
  }
}

export default function* watchContactUs(services) {
  yield all([takeEvery('CONTACTUS__REQUEST', sendContactUs, services)])
  yield all([
    takeEvery('CONSULTANT_CONTACT_REQUEST', sendContactToConsultant, services)
  ])
}
