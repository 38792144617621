import { takeEvery, all, call, put } from 'redux-saga/effects'
import { actions } from './reducer.js'
import { logPush } from 'src/modules/ContactUs/reducer.js'
import { notificationsEnqueue } from 'src/modules/Notifications/reducer.js'
import { notificationDuration as duration } from 'src/constants/index.js'
import { logout } from 'src/modules/Session/actions'
import FW5MLError from 'src/services/FW5ML/FW5MLError.js'
import {
  ACCESS_DENIED,
  FWREPORTS_NO_USER_MATCH
} from 'src/services/FW5ML/errorCodes.js'

function* listReports(services, action) {
  const FWReports = services('FWReportsRepository')
  const Session = services('Session')
  try {
    const user = yield call([Session, 'userLoad'])
    if (!user) throw new Error('Please sign in to get your available reports')
    const reports = yield call([FWReports, 'getUserReports'])
    yield put(actions.successListReports(reports))
  } catch (e) {
    if (e instanceof FW5MLError) {
      const code = e.getCode()
      if (code === ACCESS_DENIED) {
        yield put(logout({ reload: false, redirect: '/sign-in' }))
        yield put(
          notificationsEnqueue({
            message: 'Please sign in and try again.'
          })
        )
        return
      } else if (code === FWREPORTS_NO_USER_MATCH) {
        // Do not display any toast notification with error since the errors is on screen already
        yield put(actions.errorListReports(e.message))
        return
      }
    }
    yield put(
      logPush(
        'FWReports List',
        `Error while requesting FWReports: ${e.message}`
      )
    )
    yield put(
      notificationsEnqueue({
        message: `Unable to load your reports. Please refresh the page and try again.`,
        duration
      })
    )
    yield put(actions.errorListReports(e.message))
  }
}

export default function* watchUpdate(services) {
  yield all([takeEvery('ACCOUNT_LIST_REPORTS__REQUEST', listReports, services)])
}
