const PAGES = {
  'river/all-reports': 'river_my-reports',
  'river/upcoming': 'river_upcoming-reports',
  story: 'article',
  default: 'home'
}

export const isValidIconSize = iconSize => {
  return Boolean(iconSize?.match(/^\d+px$/g))
}
export const getNumericIconSize = iconSize => {
  if (!isValidIconSize) return
  return Number(iconSize?.replace(/px/, ''))
}

export const isvalidMaxIconSize = iconSize => {
  const validatedIconSize = getNumericIconSize(iconSize)
  return validatedIconSize <= 60
}

export const getMaxIconSize = iconSize =>
  isvalidMaxIconSize(iconSize) ? iconSize : 60

export const getIconSizePX = (size, iconsSize = '', options = {}) => {
  if (iconsSize && isValidIconSize(iconsSize)) {
    if (isvalidMaxIconSize(iconsSize)) return iconsSize
    return '60px'
  }
  if (!iconsSize) {
    return options[size] ?? '50px'
  }
}

export const isIconShowing = (iconProps = {}) => {
  const iconValues = Object.keys(iconProps)
  return iconValues.length > 0 && iconProps?.visible === true
}

export const getPage = pathname => {
  let page = ''
  if (pathname.includes('river/all-reports')) {
    page = PAGES['river/all-reports']
  } else if (pathname.includes('river/upcoming')) {
    page = PAGES['river/upcoming']
  } else if (pathname.includes('story')) {
    page = PAGES.story
  } else {
    page = PAGES.default
  }

  return page
}
