import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import Icon from 'src/components/ui/Icon/index.js'
import classNames from 'classnames/bind'
import styles from './ArticleGroup.module.sass'
import ViewMoreBox from 'src/components/ViewMoreBox/index.js'

const cx = classNames.bind(styles)

const ArticleGroup = ({ label, children, viewMoreLink = '/' }) => {
  const [open, setOpen] = useState(false)
  const handleClick = () => setOpen(!open)
  return (
    <Fragment>
      <div className={styles.groupLabel} onClick={handleClick}>
        {label}
        <Icon
          className={styles.groupIcon}
          icon={open ? 'carat-d' : 'carat-r'}
          colorTier="secondary"
          button
        />
      </div>
      <div className={cx(styles.group, { open })}>
        {children}
        <div className={cx(styles.footer)}>
          <ViewMoreBox to={viewMoreLink}>View More</ViewMoreBox>
        </div>
      </div>
    </Fragment>
  )
}

ArticleGroup.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
  viewMoreLink: PropTypes.string
}

export default ArticleGroup
