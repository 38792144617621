import React from 'react'
import PropTypes from 'prop-types'
import StoryRiver from 'src/components/StoryRiver/index.js'
import View from 'src/components/View/index.js'
import ViewMoreBox from 'src/components/ViewMoreBox/index.js'
import StoryExcerpt from 'src/components/StoryExcerpt/index.js'
import {
  ProtectedArea,
  ShowFor
} from 'src/components/LicenseProtected/index.js'
import { Link } from 'react-router-dom'
import Teaser from 'src/components/Teaser/index.js'
import Title from 'src/components/RecommendedStoriesTitle.js'
import styles from 'src/styles/common.module.sass'
import { siteName } from 'src/utils/oneCodeBase.js'

const MyStoriesRiver = ({
  view,
  viewsError,
  viewsLoading,
  imageSize,
  getArticleLink,
  onTypeClick,
  flex = 'column'
}) => {
  const teaserSource =
    imageSize === 'small'
      ? '/assets/blurred-recommended-mobile.jpg'
      : '/assets/blurred-recommended.jpg'
  return (
    <StoryRiver title={<Title />} titleType="title">
      <ProtectedArea>
        <ShowFor authenticated>
          <View
            view={view}
            error={viewsError}
            loading={viewsLoading}
            render={(story, i) => (
              <StoryExcerpt
                key={story.id}
                id={story.id}
                to={getArticleLink(story)}
                tag={story.tag}
                title={story.title}
                body={story.teaser}
                published={story.published}
                type={story.type}
                typeTo={`/river/type/${story.type}`}
                congress={story.congress}
                plus={story.flags.isPaid}
                image={i === 0 ? story.image : null}
                onTypeClick={onTypeClick}
                last={i === view.stories.length - 1}
                likes={story.likes}
                likedByProfile={story.likedByProfile}
              />
            )}
          />
          <ViewMoreBox to="/river/my-fw" data-piwik-class="view-more-link">
            View More <Title />
          </ViewMoreBox>
        </ShowFor>
        {flex === 'column' && (
          <ShowFor anonymous>
            <Teaser src={teaserSource}>
              <Link to="/register" className={styles.link}>
                Register
              </Link>{' '}
              in order to benefit from FirstWord {siteName}'s recommendation
              technology helping you discover news and content of interest to
              you.
            </Teaser>
          </ShowFor>
        )}
      </ProtectedArea>
    </StoryRiver>
  )
}

MyStoriesRiver.propTypes = {
  view: PropTypes.object,
  viewsError: PropTypes.string,
  viewsLoading: PropTypes.bool,
  imageSize: PropTypes.string,
  getArticleLink: PropTypes.func,
  onTypeClick: PropTypes.func,
  flex: PropTypes.string
}

export default MyStoriesRiver
