import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import Page from 'src/components/Page/index.js'
import CaptchaForm from 'src/modules/Captcha/components/CaptchaForm'
import commonStyles from 'src/styles/common.module.sass'
import styles from './CaptchaPage.module.sass'
import cx from 'classnames'

const CaptchaPage = ({ requestCaptchaLogin, busy, token }) => {
  if (!token) {
    return <Redirect to="/sign-in" />
  }
  return (
    <Page className={cx(commonStyles.content, styles.page)}>
      <CaptchaForm onSuccess={requestCaptchaLogin} busy={busy} />
    </Page>
  )
}

CaptchaPage.propTypes = {
  requestCaptchaLogin: PropTypes.func,
  busy: PropTypes.bool,
  token: PropTypes.string
}

export default CaptchaPage
