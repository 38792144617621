import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Spinner from 'src/components/ui/Spinner/index.js'
import SavedSearch from 'src/modules/MySavedSearches/components/SavedSearch'
import { savedSearch as savedSearchPropType } from 'src/utils/propTypes.js'
import Typography from 'src/components/ui/Typography/index.js'
import styles from './SavedSearches.module.sass'

class SavedSearches extends Component {
  componentDidMount() {
    this.props.requestSavedSearches()
  }
  render() {
    const {
      loadingSavedSearches = false,
      savedSearchesConfig = {},
      searches = [],
      deleting = [],
      onDelete = () => {
        /* NOOP */
      },
      onReplay = () => {
        /* NOOP */
      },
      onUpdate = () => {}
    } = this.props
    const {
      search = '',
      email_notification = '',
      is_editable: isEditable = true,
      is_removable: isRemovable = true
    } = savedSearchesConfig
    return loadingSavedSearches ? (
      <Spinner fillColor="transparent" strokeColor="rgba(213,213,213,1)" />
    ) : (
      searches.length > 0 && (
        <table className={styles.container}>
          <thead>
            <tr>
              <th>
                {search && <Typography type="subheading">{search}</Typography>}
              </th>
              <th>
                {email_notification && (
                  <Typography type="subheading">
                    {email_notification}
                  </Typography>
                )}
              </th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {searches.map(search => (
              <SavedSearch
                key={search.search_id}
                isEditable={isEditable}
                hasEmailNotification={email_notification}
                isRemovable={isRemovable}
                search={search}
                deleting={deleting.indexOf(search.search_id) > -1}
                onReplay={e => onReplay(search)}
                onDelete={e => onDelete(search)}
                onUpdate={e => onUpdate(search)}
              />
            ))}
          </tbody>
        </table>
      )
    )
  }
}

SavedSearches.propTypes = {
  /**
   * Called to request the current list of saved searches
   */
  requestSavedSearches: PropTypes.func,
  /**
   * True if the list of saved searches is being requested
   */
  loadingSavedSearches: PropTypes.bool,
  /**
   * Array of the current list of saved searches
   */
  searches: PropTypes.arrayOf(savedSearchPropType),
  /**
   * Array of the ids of the saved searches currently being deleted
   */
  deleting: PropTypes.arrayOf(PropTypes.number),
  /**
   * Called to request a saved search to be replayed
   */
  onDelete: PropTypes.func,
  /**
   * Called to request a saved search to be deleted
   */
  onReplay: PropTypes.func,
  /**
   * Config
   */
  savedSearchesConfig: PropTypes.object,
  /**
   * on Update
   */
  onUpdate: PropTypes.func
}

export default SavedSearches
