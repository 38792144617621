import React from 'react'
import Page from 'src/components/Page/index.js'
import ContactUsForm from 'src/modules/ContactUs/ContactUsFormContainer'
import commonStyles from 'src/styles/common.module.sass'
import cx from 'classnames'
import styles from './ContactUsPage.module.sass'

const ContactUsPage = props => (
  <Page>
    <div className={cx(commonStyles.content, styles.page)}>
      <ContactUsForm {...props} />
    </div>
  </Page>
)

export default ContactUsPage
