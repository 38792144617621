import React, { useContext, useMemo } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { getConfig } from 'src/modules/Config/selectors'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

// Session
import { SessionContext } from 'src/modules/Session/context'

// Icon Components
import DownloadActionButton from 'src/components/ActionButtons/components/DownloadActionButton'
import LearnMoreActionButton from 'src/components/ActionButtons/components/LearnMoreActionButton'
import LikeActionButton from 'src/components/ActionButtons/components/LikeActionButton'
import NotifyActionButton from 'src/components/ActionButtons/components/NotifyActionButton'
import ShareActionButton from 'src/components/ActionButtons/components/ShareActionButton'

// action helpers
import { getIconSizePX, isIconShowing } from './helpers'

// styles
import styles from './ActionButtons.module.sass'
import cx from 'classnames'

const ICON_SIZES = {
  small: '30px',
  medium: '40px',
  big: '50px'
}

const IconComponent = IconWrappedComponent => {
  return function customizedIconComponent(props) {
    const iconsSize = getIconSizePX(
      props?.sharedProps.size,
      props?.sharedProps.iconsExactSize,
      ICON_SIZES
    )

    return isIconShowing(props) ? (
      <div className={styles.buttonIconContainer}>
        <IconWrappedComponent
          {...props}
          iconSize={iconsSize}
          textAlign={props?.sharedProps.textAlign}
        />
      </div>
    ) : null
  }
}

const DownloadIcon = React.memo(IconComponent(DownloadActionButton))
const LearnMoreIcon = React.memo(IconComponent(LearnMoreActionButton))
const LikeIcon = React.memo(IconComponent(LikeActionButton))
const NotifyMeIcon = React.memo(IconComponent(NotifyActionButton))
const ShareIcon = React.memo(IconComponent(ShareActionButton))

const ActionButtons = props => {
  const {
    singleStory = false,
    downloadProps = {
      iconText: '',
      visible: true
    },
    learnMoreProps = {
      active: true,
      visible: true
    },
    reportSettings = {},
    likeProps = {
      action: () => {},
      likes: 0,
      likedByProfile: false
    },
    notifyProps = {
      action: () => {},
      notified: 0
    },
    shareProps = {
      active: true,
      visible: true
    },
    sharedButtonsConfig = {},
    learnMoreURL = '',
    articleId,
    articleTitle,
    articleBody = ''
  } = props

  const { authenticated, user } = useContext(SessionContext)
  const actionButtonsSettings = useSelector(
    getConfig('action_buttons_settings') || {},
    shallowEqual
  )

  const sharedConfig = useMemo(
    () => ({
      size: 'small',
      iconsExactSize: '',
      align: '',
      axis: 'row',
      textAlign: 'besideRight',
      ...sharedButtonsConfig
    }),
    [sharedButtonsConfig]
  )

  return (
    <div
      className={cx(
        styles.actionButtonsContainer,
        styles[sharedConfig.align],
        styles[sharedConfig.axis]
      )}
    >
      {reportSettings?.upcoming && (
        <NotifyMeIcon
          {...notifyProps}
          sharedProps={sharedConfig}
          className={styles.test}
          singleStory={singleStory}
          user={user}
          active={true}
          visible={true}
          articleId={articleId}
          authenticated={authenticated}
          {...reportSettings}
        />
      )}

      {!reportSettings?.upcoming && (
        <DownloadIcon
          {...downloadProps}
          sharedProps={sharedConfig}
          authenticated={authenticated}
          active={authenticated}
          buttonType="download"
          articleId={articleId}
          reportSettings={reportSettings}
        />
      )}

      {authenticated && (
        <ShareIcon
          {...shareProps}
          sharedProps={sharedConfig}
          articleUrl={learnMoreURL}
          articleId={articleId}
          articleTitle={articleTitle}
          actionButtonsSettings={actionButtonsSettings}
          articleBody={articleBody}
          buttonType="share"
        />
      )}

      {authenticated && (
        <LikeIcon
          {...likeProps}
          sharedProps={sharedConfig}
          actionButtonsSettings={actionButtonsSettings}
          articleId={articleId}
          singleStory={singleStory}
          active={true}
          visible={true}
        />
      )}
      <Link to={learnMoreURL} className={styles.linkAction}>
        <LearnMoreIcon
          {...learnMoreProps}
          sharedProps={sharedConfig}
          buttonType="information"
          articleId={articleId}
        />
      </Link>
    </div>
  )
}

export default React.memo(ActionButtons)

ActionButtons.propTypes = {
  downloadProps: PropTypes.object,
  learnMoreProps: PropTypes.object,
  likeProps: PropTypes.object,
  notifyProps: PropTypes.object,
  shareProps: PropTypes.object,
  sharedButtonsConfig: PropTypes.object,
  learnMoreURL: PropTypes.string,
  reportSettings: PropTypes.object,
  articleId: PropTypes.any,
  articleTitle: PropTypes.string,
  singleStory: PropTypes.bool,
  articleBody: PropTypes.string
}
