import React from 'react'
import PropTypes from 'prop-types'
import SectionHeading from 'src/components/SectionHeading/index.js'
import { ArticlesList } from 'src/modules/GatedLandingPage/pages/Landing'

const AdditionalResources = ({
  additionalResources = {},
  handleTracking = () => {},
  articlesHtml = {}
}) => {
  return (
    <section style={{ marginTop: '30px' }}>
      {additionalResources.title && (
        <SectionHeading
          title={additionalResources.title}
          gutterBottom
          paragraph
        />
      )}
      {additionalResources.articles &&
        additionalResources.articles.length > 0 && (
          <ArticlesList
            articles={additionalResources.articles}
            handleTracking={handleTracking}
            articlesHtml={articlesHtml}
          />
        )}
    </section>
  )
}

AdditionalResources.propTypes = {
  additionalResources: PropTypes.object,
  handleTracking: PropTypes.func,
  articlesHtml: PropTypes.object
}

export default AdditionalResources
