import PropTypes from 'prop-types'

const ShowIn = ({ children, site }) =>
  process.env.REACT_APP_SITE_ID === site ? children : null

ShowIn.propTypes = {
  children: PropTypes.node,
  site: PropTypes.string
}

export default ShowIn
