import React from 'react'
import PropTypes from 'prop-types'
import Icon from 'src/components/ui/Icon/index.js'
import ReactMarkdown from 'react-markdown'
import styles from './Notice.module.sass'

const Notice = ({ visible, onDismiss, content }) => {
  const style = visible ? { display: 'block' } : { display: 'hidden' }
  return (
    <div style={style} className={styles.notice}>
      <div className={styles.container}>
        <div className={styles.body}>
          <ReactMarkdown children={content} />
        </div>
        <Icon
          className={styles.close}
          onClick={onDismiss}
          button
          icon="svg/material-design-icons/navigation/close"
          colorTier="knockout"
        />
      </div>
    </div>
  )
}

Notice.propTypes = {
  visible: PropTypes.bool,
  onDismiss: PropTypes.func,
  content: PropTypes.string
}

export default Notice
