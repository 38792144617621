import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  paywallVisible: false,
  type: null,
  blurArticle: false,
  subtype: null,
  featureText: ''
}

const paywallSlice = createSlice({
  name: 'paywallReducer',
  initialState,
  reducers: {
    removePaywall: state => {
      state.paywallVisible = false
      state.type = null
      state.blurArticle = false
      state.featureText = ''
    },
    updatePaywallVisible: (state, { payload }) => {
      const {
        visible,
        type,
        blurArticle = false,
        text = '',
        subtype = null
      } = payload
      state.paywallVisible = visible
      state.blurArticle = blurArticle
      state.featureText = text
      state.type = type
      state.subtype = subtype
    }
  }
})

export const { actions, reducer } = paywallSlice
export const { removePaywall, updatePaywallVisible } = actions

export const getPaywallVisible = state => state.paywallReducer.paywallVisible
export const getType = state => state.paywallReducer.type
export const getBlur = state => state.paywallReducer.blurArticle
export const getFeatureText = state => state.paywallReducer.featureText || null
export const getSubtype = state => state.paywallReducer.subtype || null

export default reducer
