const ContactUsRepository = (Fw5MLApi, Device) => ({
  async sendMessage({ deviceId = null, user, ...payload }) {
    const device = {}
    // Attach deviceId if exists otherwise attach blackbox
    device.device_id = deviceId ? `${deviceId}` : null
    const url = user?.premiumAccountConsultant?.active
      ? `/contact/premium`
      : `/contact`
    const req = await Fw5MLApi.fetch(url, 'POST', {
      device,
      ...payload
    })
    return req?.data
  },

  async sendZohoRequest(payload) {
    const req = await Fw5MLApi.fetch('/consultant/followup', 'post', payload)
    return req?.data
  }
})

export default ContactUsRepository
