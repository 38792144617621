import { useDispatch } from 'react-redux'
import { piwikRequest } from 'src/modules/Piwik/reducer.js'
import { useLocation } from 'react-router-dom'

const usePiwik = () => {
  const dispatch = useDispatch()
  const location = useLocation()

  const updateCustomVar = (key, value) => {
    dispatch(
      piwikRequest({
        customVarUpdate: {
          key,
          value
        }
      })
    )
  }

  const handleTracking = (category, action, name) => {
    dispatch(
      piwikRequest({
        tracking: {
          category,
          action,
          name
        }
      })
    )
  }

  const trackArticleId = () => {
    if (location.pathname.includes('story')) {
      const splitUrl = location.pathname.match(/story\/(\d+)/)
      if (splitUrl) {
        updateCustomVar('article_id', splitUrl[1])
      }
    }
  }

  const handleTrackingWithArticleId = (category, action, name) => {
    trackArticleId()
    handleTracking(category, action, name)
  }

  const handleTrackingWithArticleIdProp = (
    category,
    action,
    name,
    articleId
  ) => {
    updateCustomVar('article_id', articleId)
    handleTracking(category, action, name)
  }

  return {
    updateCustomVar,
    handleTracking,
    trackArticleId,
    handleTrackingWithArticleId,
    handleTrackingWithArticleIdProp
  }
}

export default usePiwik
