import asyncMeta from 'src/modules/AsyncFlags/metaCreator'
import { createReducer, createAction } from '@reduxjs/toolkit'

export const requestListReports = createAction(
  'ACCOUNT_LIST_REPORTS_REQUEST',
  null,
  asyncMeta
)
export const successListReports = createAction(
  'ACCOUNT_LIST_REPORTS__SUCCESS',
  null,
  asyncMeta
)
export const errorListReports = createAction(
  'ACCOUNT_LIST_REPORTS__ERROR',
  null,
  asyncMeta
)

export const actions = {
  requestListReports,
  successListReports,
  errorListReports
}

const initialState = {
  reports: [],
  loading: false,
  error: ''
}

const myReportsReducer = createReducer(initialState, {
  [requestListReports]: state => {
    state.loading = true
    state.reports = []
  },
  [successListReports]: (state, { payload: reports }) => {
    state.loading = false
    state.reports = reports
  },
  [errorListReports]: (state, { payload: error }) => {
    state.loading = false
    state.error = error
  }
})

export default myReportsReducer
