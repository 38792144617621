import { compose } from 'redux'
import { connect } from 'react-redux'
import MediaEventWrapper from 'src/modules/River/components/MediaEventWrapper'

const mapDispatchToProps = dispatch => ({})

const mapStateToProps = state => ({
  ads: state.ads
})

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  MediaEventWrapper
)
