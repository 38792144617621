import { createAction, handleActions } from 'redux-actions'
import asyncMeta from 'src/modules/AsyncFlags/metaCreator'

export const setCaptchaLoginMeta = createAction(
  'SET_CAPTCHA_LOGIN_META',
  null,
  asyncMeta
)

export const successCaptchaLogin = createAction(
  'CAPTCHA_LOGIN__SUCCESS',
  null,
  asyncMeta
)
export const errorCaptchaLogin = createAction(
  'CAPTCHA_LOGIN__ERROR',
  null,
  asyncMeta
)
export const requestCaptchaLogin = createAction(
  'CAPTCHA_LOGIN__REQUEST',
  null,
  asyncMeta
)

export const actions = {
  setCaptchaLoginMeta,
  requestCaptchaLogin,
  successCaptchaLogin,
  errorCaptchaLogin
}

const defaultState = {
  meta: {
    token: null,
    type: null,
    redirect: null,
    nlEventId: null,
    nlCampaignId: null
  }
}

export default handleActions(
  {
    SET_CAPTCHA_LOGIN_META: (
      state,
      {
        payload: {
          token,
          type,
          redirect = null,
          nlEventId = null,
          nlCampaignId = null
        }
      }
    ) => ({
      ...state,
      meta: {
        token,
        type,
        redirect,
        nlEventId,
        nlCampaignId
      }
    }),
    CAPTCHA_LOGIN__SUCCESS: state => ({
      ...state,
      meta: {
        token: null,
        type: null,
        redirect: null,
        nlEventId: null,
        nlCampaignId: null
      }
    })
  },
  defaultState
)
