import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import Page from 'src/components/Page/index.js'
import MyAccountForm from 'src/modules/MyAccount/containers/MyAccountFormContainer'
import MyInterests from 'src/modules/MyInterests/containers/MyInterestsContainer'
import MySavedSearches from 'src/modules/MySavedSearches/containers/MySavedSearchesContainer'
import MyNewsletters from 'src/modules/MyNewsletters/containers/MyNewslettersContainer'
import commonStyles from 'src/styles/common.module.sass'
import Tab from 'src/components/ui/Tabs/Tab.js'
import Tabs from 'src/components/ui/Tabs/Tabs.js'
import styles from './MyAccountPage.module.sass'
import { Route, Link, Redirect } from 'react-router-dom'
import { SessionContext } from 'src/modules/Session/context'
import { licenseTypes } from 'src/services/FW5ML/parsers/UserParser.js'
import { getConfig } from 'src/modules/Config/selectors'
import { getPaywallRedirectUrl } from 'src/modules/Benefits/selectors'
import cx from 'classnames'

const MyAccountPage = ({ history }) => {
  const { userHasLicense = () => false } = useContext(SessionContext)
  const config = useSelector(getConfig('my_account_settings')) || {}
  const {
    my_information = {},
    tabs = [],
    my_newsletters = {},
    saved_searches = {},
    my_interests = {}
  } = config
  const fields = my_information?.fields
  const { hide_top_section = false } = my_newsletters
  const paywallRedirectUrl = useSelector(getPaywallRedirectUrl()) || '/benefits'

  return (
    <Page>
      <div className={cx(commonStyles.content, styles.page)}>
        <div className={styles.tabContainer}>
          <Tabs
            value={history.location.pathname}
            className={styles.tabs}
            mode="scroll"
          >
            {tabs.map(tab =>
              tab.plus ? (
                userHasLicense(licenseTypes.LICENSE_PLUS) && (
                  <Tab
                    key={tab.key}
                    showsActiveBar
                    className={styles.tab}
                    data-piwik-class="my-account-tab"
                    value={`/my-account/${tab.to}`}
                  >
                    <Link to={`/my-account/${tab.to}`}>{tab.key}</Link>
                  </Tab>
                )
              ) : (
                <Tab
                  key={tab.key}
                  showsActiveBar
                  className={styles.tab}
                  data-piwik-class="my-account-tab"
                  value={`/my-account/${tab.to}`}
                >
                  <Link to={`/my-account/${tab.to}`}>{tab.key}</Link>
                </Tab>
              )
            )}
          </Tabs>
        </div>
        <Route
          path="/my-account/info"
          render={routerProps => (
            <MyAccountForm fields={fields} {...routerProps} />
          )}
        />
        <Route
          path="/my-account/interests"
          render={routerProps => {
            return my_interests?.for_all === true ||
              userHasLicense(licenseTypes.LICENSE_PLUS) ? (
              <MyInterests {...routerProps} />
            ) : (
              <Redirect to={{ pathname: paywallRedirectUrl }} />
            )
          }}
        />
        <Route
          path="/my-account/searches"
          render={routerProps => {
            return saved_searches?.for_all === true ||
              userHasLicense(licenseTypes.LICENSE_PLUS) ? (
              <MySavedSearches {...routerProps} />
            ) : (
              <Redirect to={{ pathname: paywallRedirectUrl }} />
            )
          }}
        />
        <Route
          path="/my-account/newsletters"
          render={routerProps => (
            <MyNewsletters hideTopNewslettersSection={hide_top_section} />
          )}
        />
      </div>
    </Page>
  )
}

MyAccountPage.propTypes = {
  history: PropTypes.object
}

export default MyAccountPage
