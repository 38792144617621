import React, { useContext } from 'react'
import { SessionContext } from 'src/modules/Session/context'
import AnonReports from 'src/modules/MyReports/components/AnonReports/index.js'
import NoReportsFound from 'src/modules/MyReports/components/NoReportsFound/index.js'
import River from 'src/modules/River/components/River'

const MyReports = props => {
  const {
    authenticated,
    checked: sessionReady,
    user
  } = useContext(SessionContext)

  const reportsFound = user?.report_count && user?.report_count > 0

  // Wait for session to be ready before evaluating
  if (!sessionReady) return

  // If use is anonymous
  if (!authenticated) {
    return <AnonReports {...props} />
  }

  // User is logged in and does not have reports
  if (!reportsFound) {
    return <NoReportsFound {...props} />
  }
  // User is logged in and has reports
  return <River />
}

export default MyReports
