import React from 'react'
import Page from 'src/components/Page/index.js'
import SectionHeading from 'src/components/SectionHeading/index.js'
import List from 'src/components/ui/List/List.js'
import ListItem from 'src/components/ui/List/ListItem.js'
import Typography from 'src/components/ui/Typography/index.js'
import styles from 'src/modules/Static/Static.module.sass'
import cx from 'classnames'
import Metadata from 'src/components/Metadata/index.js'
import { siteName } from 'src/utils/oneCodeBase.js'

const AdvertisePage = () => {
  const mediaKitLink =
    siteName === 'HealthTech'
      ? 'https://firstword.wufoo.eu/forms/media-kit-request/'
      : 'https://info.firstwordreports.com/firstword_communications_media_kit/?utm_source=fwpharma&utm_medium=internal&campaign_name=fwpharma_advertise'
  return (
    <Page className={cx(styles.static, styles.advertise)}>
      <Metadata
        title="Advertise With Us"
        description="Use FirstWord Communications to Reach Your Target Audience"
      />
      <SectionHeading title="Advertise With Us" gutterBottom paragraph />
      <Typography
        type="title"
        className={styles.paragraph}
        gutterBottom
        paragraph
      >
        Use FirstWord Communications to Reach Your Target Audience
      </Typography>
      <Typography
        type="body1"
        className={styles.paragraph}
        gutterBottom
        paragraph
      >
        For more than 15 years, the FirstWord Group has been an innovative
        industry intelligence leader serving over 260,000 Pharma and HealthTech
        professionals worldwide.
      </Typography>
      <Typography
        type="body1"
        className={styles.paragraph}
        gutterBottom
        paragraph
      >
        Using our data and trusted in-house content creation capabilities, we'll
        deliver your message to your target audience through a variety of
        customised solutions:
      </Typography>
      <List className={styles.list} listStyle="outside">
        <ListItem>Custom email blasts</ListItem>
        <ListItem>Newsletter and site sponsorships</ListItem>
        <ListItem>Reports</ListItem>
        <ListItem>Webinars</ListItem>
        <ListItem>Podcasts</ListItem>
        <ListItem>Interview-based articles</ListItem>
        <ListItem>Other opportunities</ListItem>
      </List>
      <Typography
        type="body1"
        className={styles.paragraph}
        gutterBottom
        paragraph
      >
        Offering continuous analysis on today's trends, developments and
        breakthroughs, the FirstWord {siteName} responsive website, multiple
        specialised newsletters and direct-targeting capabilities allow us to
        maximise engagement on a global scale, meeting your specific digital
        marketing needs.
      </Typography>
      <Typography
        type="body1"
        className={styles.paragraph}
        gutterBottom
        paragraph
      >
        Whether your goal is brand awareness, thought leadership, lead
        generation, or other, teaming up with FirstWord Communications will
        enable you to achieve the results you expect and maximise your ROI.
      </Typography>
      <Typography
        type="body1"
        className={styles.paragraph}
        gutterBottom
        paragraph
      >
        <strong>
          Learn more on how FirstWord Communications can support your success:
        </strong>
      </Typography>
      <a className={styles.mediaKitButton} href={mediaKitLink}>
        Request Media Kit
      </a>
      <Typography
        type="body1"
        className={styles.paragraph}
        gutterBottom
        paragraph
      >
        <b>Or contact:</b>
        <br />
        Erin MacDermut
        <br />
        Senior Consultant
        <br />
        erin.macdermut@firstwordgroup.com
        <br />
        +1 919-622-5469
      </Typography>
    </Page>
  )
}

export default AdvertisePage
