import React, { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import Page from 'src/components/Page/index.js'
import commonStyles from 'src/styles/common.module.sass'
import Typography from 'src/components/ui/Typography/index.js'
import cx from 'classnames'
import styles from '../deals.module.sass'
import Table from '../components/Table'
import List from 'src/components/ui/List/List.js'
import ListItem from 'src/components/ui/List/ListItem.js'
import MobileTable from '../components/MobileTable'
import { SessionContext } from 'src/modules/Session/context'
import { licenseTypes } from 'src/services/FW5ML/parsers/UserParser.js'
import { history } from 'src/Router.js'
import { historyHandler } from 'src/utils/index.js'
import { getPaywallRedirectUrl } from 'src/modules/Benefits/selectors'

const DealsFlowComponent = ({
  deal,
  text,
  columns,
  columnsMap,
  mobileColumns,
  columnsMobileMap,
  notes = []
}) => {
  const { userHasLicense = () => false } = useContext(SessionContext)
  const [isDesktop, setIsDesktop] = useState(false)

  // Set paywall redirect url
  const paywallRedirectUrl = useSelector(getPaywallRedirectUrl()) || '/benefits'

  useEffect(() => {
    setIsDesktop(window.matchMedia('(min-width: 768px)').matches)
  }, [])

  if (!userHasLicense(licenseTypes.LICENSE_PLUS)) {
    historyHandler(paywallRedirectUrl, '', history, 'push')
    return <></>
  }

  return (
    <Page hideMenu={true}>
      <div className={cx(commonStyles.content, styles.dealsPage)}>
        <article className="SingleStory_SingleStory__1vEAy">
          <main className="SingleStory_body__2sKfU">
            {Array.isArray(text) ? (
              text.map((t, i) => (
                <Typography
                  key={`SingleStory_body__2sKfU-Typography-${i}`}
                  pography
                  type="body1"
                  className={styles.paragraph}
                  paragraph
                >
                  {t}
                </Typography>
              ))
            ) : (
              <Typography type="body1" className={styles.paragraph} paragraph>
                {text}
              </Typography>
            )}
          </main>
        </article>
        {!isDesktop ? (
          <MobileTable
            styles={styles}
            deal={deal}
            columns={mobileColumns}
            columnsMap={columnsMobileMap}
          />
        ) : (
          <Table
            styles={styles}
            deal={deal}
            columns={columns}
            columnsMap={columnsMap}
          />
        )}
        {notes.length > 0 && (
          <section className={styles.notes}>
            <Typography type="subheading">{'Notes:'}</Typography>
            <List className={styles.list} listStyle="outside">
              {notes.map((note, i) => (
                <ListItem key={i}>{note}</ListItem>
              ))}
            </List>
          </section>
        )}
      </div>
    </Page>
  )
}

DealsFlowComponent.propTypes = {
  deal: PropTypes.object,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  columns: PropTypes.array,
  columnsMap: PropTypes.object,
  mobileColumns: PropTypes.array,
  columnsMobileMap: PropTypes.object,
  notes: PropTypes.array
}

export default DealsFlowComponent
