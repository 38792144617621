import React, { Fragment } from 'react'
import Uncap from 'src/components/Uncap/index.js'
import {
  rivers,
  iavRiver,
  conferenceNewsRiver,
  peopleNewsRiver,
  recentNews,
  recentReports,
  typeKOLorPhysicianRiver
} from './contentRivers'
import _get from 'lodash/get'
import { SIDEBAR_INTERESTS_AND_FILTERS } from 'src/modules/River/constants/sidebars.js'
import { getExtraFilters } from './filterLabels'

export default {
  [rivers.MY_FW]: () => ({
    title: (
      <Fragment>
        <Uncap>MyFW+</Uncap> stories
      </Fragment>
    ),
    stringTitle: 'MyFW+ stories',
    sidebar: 'interests',
    hideSubHeader: true,
    showGroupByInterestTabs: true,
    showMedicalAbstractsToggle: false
  }),
  [rivers.EVENTS]: () => ({
    title: 'Upcoming Events',
    hideFilterSections: ['type', 'publication_date'],
    viewCount: false,
    showMedicalAbstractsToggle: false,
    showSingleFeaturedContent: false,
    mediaEventConfig: {
      groupByDates: true
    }
  }),
  [rivers.RESOURCES]: () => ({
    title: 'Resources',
    hideFilterSections: ['type', 'publication_date'],
    viewCount: false,
    showMedicalAbstractsToggle: false,
    showSingleFeaturedContent: false,
    mediaEventConfig: {}
  }),
  [rivers.STORY_WATCH]: ({ args } = {}) => {
    const titleOverrides = {
      'Focus on Gene/CAR-T Cell Therapy': 'Gene/Car-T Cell Therapy',
      'Emerging markets': 'Emerging Markets',
      'Focus on Biosimilars': 'Biosimilars'
    }
    const title = _get(titleOverrides, args, args)
    return {
      title: (
        <Fragment>
          StoryWatch - <Uncap>{title}</Uncap>
        </Fragment>
      ),
      stringTitle: `StoryWatch - ${title}`,
      sidebar: SIDEBAR_INTERESTS_AND_FILTERS,
      applyDefaultFilters: ['fw_story_watch'],
      hideFilterSections: ['fw_story_watch'],
      viewCount: false,
      showMedicalAbstractsToggle: false
    }
  },
  [rivers.TOP_STORIES]: () => ({
    title: 'Top Stories',
    hideFilterSections: ['type'],
    viewCount: false,
    showMedicalAbstractsToggle: false,
    showSingleFeaturedContent: true
  }),
  [rivers.IAV]: () => ({
    title: 'Insight, Analysis & Views',
    hideFilterSections: [],
    applyDefaultFilters: ['type'],
    defaultFilters: iavRiver,
    viewCount: false,
    showMedicalAbstractsToggle: false,
    showSingleFeaturedContent: true
  }),
  [rivers.CONFERENCE_NEWS]: () => ({
    title: 'Conference News',
    hideFilterSections: [],
    applyDefaultFilters: ['type'],
    defaultFilters: conferenceNewsRiver,
    viewCount: false,
    showMedicalAbstractsToggle: false,
    showSingleFeaturedContent: true
  }),
  [rivers.PEOPLE_NEWS]: () => ({
    title: 'People News',
    hideFilterSections: [],
    applyDefaultFilters: ['type'],
    defaultFilters: peopleNewsRiver,
    viewCount: false,
    showMedicalAbstractsToggle: false,
    showSingleFeaturedContent: true
  }),
  [rivers.CONDITION]: () => ({
    title: 'Condition',
    defaultFilters: recentNews,
    viewCount: false,
    showMedicalAbstractsToggle: false,
    showSingleFeaturedContent: true
  }),
  [rivers.MEDICAL_MEETINGS]: () => ({
    title: 'Medical Meetings',
    defaultFilters: recentNews,
    viewCount: false,
    showMedicalAbstractsToggle: false,
    showSingleFeaturedContent: true
  }),
  [rivers.MARKET_SEGMENT]: () => ({
    title: 'Market Segments',
    defaultFilters: recentNews,
    viewCount: false,
    showMedicalAbstractsToggle: false,
    showSingleFeaturedContent: true
  }),
  [rivers.PRODUCT]: () => ({
    title: 'Product',
    defaultFilters: recentNews,
    viewCount: false,
    showMedicalAbstractsToggle: false,
    showSingleFeaturedContent: true
  }),
  [rivers.COMPANY]: () => ({
    title: 'Company',
    defaultFilters: recentNews,
    viewCount: false,
    showMedicalAbstractsToggle: false,
    showSingleFeaturedContent: true
  }),
  [rivers.REGULATORY]: () => ({
    title: 'Regulatory Market and Agency',
    defaultFilters: recentNews,
    viewCount: false,
    showMedicalAbstractsToggle: false,
    showSingleFeaturedContent: true
  }),
  [rivers.RELATED_NEWS]: () => ({
    title: 'Related News',
    hideFilterSections: ['type'],
    viewCount: false,
    showMedicalAbstractsToggle: false,
    showSingleFeaturedContent: true
  }),
  [rivers.RELATED_ANALYSIS]: () => ({
    title: 'Related Analysis',
    hideFilterSections: ['type'],
    viewCount: false,
    showMedicalAbstractsToggle: false,
    showSingleFeaturedContent: true
  }),
  [rivers.RECENT_NEWS]: () => ({
    title: 'All News',
    hideFilterSections: ['type', 'publication_date'],
    applyDefaultFilters: ['type'],
    defaultFilters: recentNews,
    viewCount: false,
    showMedicalAbstractsToggle: false,
    showSingleFeaturedContent: true
  }),
  [rivers.RECENT_REPORTS]: () => ({
    title: 'Recent Reports',
    hideFilterSections: ['publication_date'],
    applyDefaultFilters: ['type'],
    defaultFilters: recentReports,
    viewCount: false,
    showMedicalAbstractsToggle: false,
    showSingleFeaturedContent: true
  }),
  [rivers.TAG]: ({ args = '' } = {}) => {
    const match = args.match(/^(\w+)\/(.*)$/)
    const [, , tag] = match === null ? [null, null, args] : match
    return {
      title: tag,
      excludeFilterValues: [tag],
      viewCount: true,
      includeRelatedTags: true,
      showMedicalAbstractsToggle: false,
      showFeaturedContent: true
    }
  },
  [rivers.TYPE]: ({ args = '' } = {}) => {
    if (getExtraFilters(args)) {
      return {
        title: args,
        applyDefaultFilters: ['type'],
        defaultFilters: typeKOLorPhysicianRiver[args],
        viewCount: false,
        showMedicalAbstractsToggle: false,
        showSingleFeaturedContent: true
      }
    }
    return {
      title: args,
      excludeFilterValues: [args],
      viewCount: false,
      showMedicalAbstractsToggle: false,
      showSingleFeaturedContent: true
    }
  },
  [rivers.SEARCH]: () => ({
    title: 'Search Results',
    viewCount: true,
    cancelLoadRiver: true,
    showMedicalAbstractsToggle: false,
    showExternalArticlesToggle: true,
    showFeaturedContent: true,
    getFeaturedContentTag: true
  }),
  [rivers.DEFAULT_RIVER]: () => ({
    title: 'search',
    viewCount: false,
    cancelLoadRiver: true,
    showMedicalAbstractsToggle: false
  })
}
