import React from 'react'
import Ripple from 'src/components/ui/Ripple/index.js'
import ViewMoreLink from 'src/components/ViewMoreLink/index.js'
import styles from './ViewMoreBox.module.sass'

const ViewMoreBox = props => (
  <div className={styles.container}>
    <Ripple className={styles.ripple}>
      <ViewMoreLink {...props} />
    </Ripple>
  </div>
)

export default ViewMoreBox
