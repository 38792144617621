import React, { useContext } from 'react'
import { TitleContext } from 'src/modules/Title/context'

export const withProps = mapInjectedProps => WrappedComponent => props =>
  <WrappedComponent {...mapInjectedProps(props)} {...props} />

export const withTitle = WrappedComponent => props => {
  const setStoryTitle = useContext(TitleContext)
  return <WrappedComponent {...props} setStoryTitle={setStoryTitle} />
}
