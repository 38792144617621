import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Checkbox from 'src/components/ui/Checkbox/index.js'
import styles from './ExternalSiteArticlesToggle.module.sass'

const ExternalSiteArticlesToggle = ({ label, loading, onToggle, query }) => {
  const [externalSiteArticles, setExternalSiteArticles] = useState(false)
  const hndToggle = () => {
    setExternalSiteArticles(!externalSiteArticles)
    onToggle(!externalSiteArticles)
  }

  // Set default setting value when doing a new search
  useEffect(() => {
    return () => {
      setExternalSiteArticles(false)
    }
  }, [query])

  return (
    <>
      {!loading && (
        <Checkbox
          altTheme={styles}
          label={label}
          name="settings_show_external_site_articles_in_river"
          hasDivider={false}
          checked={externalSiteArticles}
          disabled={loading}
          onChange={hndToggle}
        />
      )}
    </>
  )
}

ExternalSiteArticlesToggle.propTypes = {
  label: PropTypes.string,
  loading: PropTypes.bool,
  onToggle: PropTypes.func,
  query: PropTypes.string
}

export default ExternalSiteArticlesToggle
