import React from 'react'
import PropTypes from 'prop-types'
import { matchSorter } from 'match-sorter'

const filtersMap = {
  between: NumberRangeColumnFilter,
  '>': SliderColumnFilter,
  equals: SelectColumnFilter
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter(id, filter, onChange, deal) {
  // console.log('preFilteredRows', preFilteredRows);
  // Calculate the options for filtering
  // using the preFilteredRows
  // const options = React.useMemo(() => {
  const options = new Set()
  deal
    // .filter((r) => r.id === id)
    .forEach(row => {
      if (row[id]) options.add(row[id])
    })

  // Render a multi-select box
  return (
    <select
      onChange={event => onChange(event.target.value)}
      style={{ width: '100%' }}
      value={filter ? filter.value : 'all'}
      className="deals-db"
    >
      <option value="">All</option>
      {Array.from(options)
        .sort()
        .map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
    </select>
  )
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
function SliderColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id }
}) {
  // Calculate the min and max
  // using the preFilteredRows

  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    preFilteredRows.forEach(row => {
      min = Math.min(row.values[id], min)
      max = Math.max(row.values[id], max)
    })
    return [min, max]
  }, [id, preFilteredRows])

  return (
    <>
      <input
        type="range"
        min={min}
        max={max}
        className="deals-db"
        value={filterValue || min}
        onChange={e => {
          setFilter(parseInt(e.target.value, 10))
        }}
      />
      <button onClick={() => setFilter(undefined)}>Off</button>
    </>
  )
}

SliderColumnFilter.propTypes = {
  column: PropTypes.object
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id }
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    preFilteredRows.forEach(row => {
      min = Math.min(row.values[id], min)
      max = Math.max(row.values[id], max)
    })
    return [min, max]
  }, [id, preFilteredRows])

  return (
    <div
      style={{
        display: 'flex'
      }}
    >
      <input
        value={filterValue[0] || ''}
        type="number"
        onChange={e => {
          const val = e.target.value
          setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]])
        }}
        placeholder={`Min (${min})`}
        style={{
          width: '70px',
          marginRight: '0.5rem'
        }}
      />
      to
      <input
        value={filterValue[1] || ''}
        type="number"
        onChange={e => {
          const val = e.target.value
          setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined])
        }}
        placeholder={`Max (${max})`}
        style={{
          width: '70px',
          marginLeft: '0.5rem'
        }}
      />
    </div>
  )
}

NumberRangeColumnFilter.propTypes = {
  column: PropTypes.object
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val

function sortNumber(value) {
  value = value === null || value === undefined ? -Infinity : value
  if (typeof value === 'string') {
    value = parseInt(value.replace(/[^\w\.@-]/g, ''))
  }
  return value
}

export function formatColumns(columns = [], deal) {
  columns.map(d => {
    switch (true) {
      case d.filterMap === 'equals':
        d.filterMethod = (filter, row) => {
          if (filter.value === 'all') {
            return true
          }
          return row[filter.id] === filter.value
        }

        d.Filter = ({ column: { id }, filter, onChange }) =>
          filtersMap[d.filterMap](id, filter, onChange, deal)

        break

      case d.sortType === 'date':
        d.sortMethod = (a, b) => {
          a = new Date(a).getTime()
          b = new Date(b).getTime()
          return b < a ? 1 : -1
        }
        break

      case d.sortType === 'number':
        d.sortMethod = (a, b) => {
          const a1 = sortNumber(a)
          const b1 = sortNumber(b)
          if (a1 > b1) return 1
          if (a1 < b1) return -1
          return 0
        }
        break

      default:
    }
  })
  return columns
}

export default filtersMap
