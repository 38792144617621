import { createSelector } from 'reselect'
import {
  ANON_MAX_ARTICLES,
  ANON_MAX_ARTICLES_EXPIRE_DAYS
} from 'src/constants/index.js'

export const getConfigState = state => state.config
export const getConfig = type =>
  createSelector(getConfigState, config => {
    try {
      return config[type].data
    } catch (e) {
      return false
    }
  })

export const getPrompts = () =>
  createSelector(getConfigState, config => {
    return config['dougallFw']?.data?.sections?.popularPrompts.prompts || []
  })

export const getFaqs = () =>
  createSelector(getConfigState, config => {
    return config['dougallFw']?.data?.sections?.faq || []
  })

export const getPageAndTemplateConfig = () =>
  createSelector(getConfigState, config => {
    try {
      const pages = config.pages.data
      const templates = config.templates.data
      const webpageContent = config.webpage_content.data

      const page = pages.find(
        page =>
          page.uri_path === window.location.pathname ||
          page?.matches?.find(match => window.location.pathname.includes(match))
      )
      if (!page.template) {
        const webpageContentKey = page.uri_path.replace('/', '')
        return webpageContent[webpageContentKey]
      }

      return templates[page.template]
    } catch (e) {
      return false
    }
  })

export const getAnonArticleLimitSettings = () =>
  createSelector(getConfig('account_settings'), config => {
    let anonArticleLimit = ANON_MAX_ARTICLES
    let anonArticleLimitExpiry = ANON_MAX_ARTICLES_EXPIRE_DAYS
    let registerPopUpFlow = null
    const { anonymous_user = null, showRegisterPaywall = false } = config
    const hideArticleLimit = anonymous_user?.hide_article_limit || false

    try {
      if (anonymous_user) {
        // anonArticleLimit reset value
        if (
          anonymous_user.article_limit &&
          parseInt(anonymous_user.article_limit) > 0
        ) {
          anonArticleLimit = parseInt(anonymous_user.article_limit)
        }

        // anonArticleLimitExpiry reset value
        if (
          anonymous_user.article_limit_expiry_days &&
          parseInt(anonymous_user.article_limit_expiry_days) > 0
        ) {
          anonArticleLimitExpiry = parseInt(
            anonymous_user.article_limit_expiry_days
          )
        }

        // registerPopUpFlow reset value
        if (anonymous_user.register_popup) {
          registerPopUpFlow = anonymous_user.register_popup
        }
        console.log()
      }
    } catch (e) {
      console.log(e)
    }
    return {
      anonArticleLimit,
      anonArticleLimitExpiry,
      registerPopUpFlow,
      showRegisterPaywall,
      hideArticleLimit
    }
  })

export const getConfigFilteredTags = ({ interests = [], tagCategory = null }) =>
  createSelector(getConfig('content_settings'), config => {
    try {
      const { hidden_tags = [] } = config

      return interests.filter(interest => {
        return !hidden_tags.find(hidden_tag => {
          const { category, tag } = hidden_tag
          return (
            category &&
            tag &&
            category === (tagCategory ?? interest.category) &&
            tag === (!interest.tag ? interest : interest.tag)
          )
        })
      })
    } catch (e) {
      return interests
    }
  })
