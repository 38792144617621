import { createAction, handleActions } from 'redux-actions'
import asyncMeta from 'src/modules/AsyncFlags/metaCreator'

export const successAccountSettingsLoad = createAction(
  'ACCOUNT_SETTINGS_LOAD__SUCCESS',
  null,
  asyncMeta
)
export const errorAccountSettingsLoad = createAction(
  'ACCOUNT_SETTINGS_LOAD__ERROR',
  null,
  asyncMeta
)
export const requestAccountSettingsLoad = createAction(
  'ACCOUNT_SETTINGS_LOAD__REQUEST',
  null,
  asyncMeta
)

export const successAccountSettingsUpdate = createAction(
  'ACCOUNT_SETTINGS_UPDATE__SUCCESS',
  null,
  asyncMeta
)
export const errorAccountSettingsUpdate = createAction(
  'ACCOUNT_SETTINGS_UPDATE__ERROR',
  null,
  asyncMeta
)
export const requestAccountSettingsUpdate = createAction(
  'ACCOUNT_SETTINGS_UPDATE__REQUEST',
  null,
  asyncMeta
)

export const actions = {
  requestAccountSettingsUpdate,
  successAccountSettingsUpdate,
  errorAccountSettingsUpdate,
  requestAccountSettingsLoad,
  successAccountSettingsLoad,
  errorAccountSettingsLoad
}

const defaultState = {
  settings: {}
}

export default handleActions(
  {
    ACCOUNT_SETTINGS_LOAD__SUCCESS: (state, { payload }) => ({
      settings: payload
    }),
    ACCOUNT_SETTINGS_UPDATE__SUCCESS: (state, { payload }) => ({
      settings: {
        ...state.settings,
        ...payload
      }
    })
  },
  defaultState
)
