import React from 'react'
import PropTypes from 'prop-types'
import styles from './CircularProgress.module.sass'
import { between } from 'airbnb-prop-types'
import classNames from 'classnames/bind'

const CircularProgress = ({
  strokeBackgroundColor = 'none',
  progress,
  size = 64,
  strokeWidth = 4,
  fillColor,
  strokeColor,
  altTheme,
  className,
  style,
  colorTier = 'primary'
}) => {
  const spinnerSize = 50
  const radius = 20
  const themeStyles = { ...styles, ...altTheme }
  const cx = classNames.bind(themeStyles)
  const dashArray =
    progress !== null && progress >= 0 ? radius * Math.PI * 2 : null
  const dashOffset =
    progress !== null && progress >= 0
      ? Math.max(dashArray - (dashArray * progress) / 100, 0)
      : null
  return (
    <div
      className={cx(themeStyles.loader, className)}
      style={{ ...{ minHeight: size + 15 }, ...style }}
    >
      <svg
        className={cx(themeStyles.circular, {
          indeterminate: !progress && progress !== 0
        })}
        style={{ width: size, height: size }}
        viewBox={`${spinnerSize / 2} ${
          spinnerSize / 2
        } ${spinnerSize} ${spinnerSize}`}
      >
        <circle
          fill={fillColor}
          cx={spinnerSize}
          cy={spinnerSize}
          r={radius + strokeWidth / 2}
          style={{ fill: fillColor }}
        />
        <circle
          fill="none"
          cx={spinnerSize}
          cy={spinnerSize}
          r={radius}
          strokeWidth={strokeWidth}
          style={{ stroke: strokeBackgroundColor }}
        />
        <circle
          className={cx(themeStyles.path, { [colorTier]: colorTier })}
          cx={spinnerSize}
          cy={spinnerSize}
          r={radius}
          fill="none"
          strokeWidth={strokeWidth}
          strokeMiterlimit="10"
          stroke={strokeColor}
          style={{
            stroke: strokeColor,
            strokeDasharray: dashArray,
            strokeDashoffset: dashOffset
          }}
        />
      </svg>
    </div>
  )
}

CircularProgress.propTypes = {
  strokeBackgroundColor: PropTypes.string,
  progress: between({ gte: 0, lte: 100 }),
  size: PropTypes.number,
  strokeWidth: between({ gte: 1, lte: 10 }),
  fillColor: PropTypes.string,
  strokeColor: PropTypes.string,
  altTheme: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  style: PropTypes.object,
  className: PropTypes.string,
  colorTier: PropTypes.oneOf([
    '',
    'action',
    'primary',
    'secondary',
    'tertiary',
    'default',
    'knockout',
    'text-primary',
    'text-secondary'
  ])
}

export default CircularProgress
