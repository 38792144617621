import { siteName } from 'src/utils/oneCodeBase.js'

/**
 * The function `getConfigModules` imports a JavaScript file from a specific path based on each site Config
 * and returns its contents, or an empty object if there is an error.
 */
export const getConfigModules = filePath => {
  try {
    return require(`src/siteConfig/${siteName}/${filePath}`)
  } catch (error) {
    console.log(siteName, filePath)
    console.error(`Error importing JavaScript file: ${error}`)
    return {}
  }
}
